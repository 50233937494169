/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import { messaging, isBrowserSupported } from 'utils/firebase';
import { getToken, onMessage } from 'firebase/messaging';
import { useDispatch, useSelector } from 'react-redux';
import APIRequest from 'apiRequest';
import { CREATE_FCM_DEVICE } from 'urls';
import CHOICES from 'choices';
import { getLeadUrlForNotification } from 'slices/userNotificationSlice';

const { WEB } = CHOICES.DeviceChoices;

export default function Firebase() {
  const info = useSelector((state) => state.myInfo.myInformation);
  const dispatch = useDispatch();
  const userId = info ? info.id : null;
  const setupFirebase = async () => {
    console.log('SETUP firebase');
    /**
     * Need to pass the service worker that has the firebase code
     */
    const sw = await navigator.serviceWorker.getRegistration();
    console.log({ sw });
    getToken(messaging, {
      serviceWorkerRegistration: sw
    })
      .then((token) => {
        if (token) {
          const payload = {
            device_type: WEB,
            is_active: true,
            registration_id: token,
            user: userId
          };
          new APIRequest()
            .post(CREATE_FCM_DEVICE, payload, {
              'Content-Type': 'multipart/form-data'
            })
            .then(() => {
              console.log(' FCM registered! ');
            })
            .catch((err) => {
              console.log(err);
            });
        }
      })
      .catch((e) => {
        console.log(e);
      });
    onMessage(messaging, (payload) => {
      const notificationTitle = payload.notification.title;
      const notificationOptions = {
        body: payload.notification.body,
        icon: '/eb-logo.png'
      };
      if (!('Notification' in window)) {
        console.log('This browser does not support system notifications');
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === 'granted') {
        // If it's okay let's create a notification
        const notification = new Notification(
          notificationTitle,
          notificationOptions
        );
        console.log('This browser supports system notifications', payload);

        const { lead_id, click_action, priority } = payload.data;
        console.log(payload);
        switch (priority) {
          case 'lead_id':
            if (!lead_id) {
              return;
            }
            dispatch(
              getLeadUrlForNotification({ leadId: lead_id }, (data) => {
                // console.log('Lead url api response', data);
                notification.onclick = function (event) {
                  event.preventDefault();
                  window.open(data.lead_url, '_blank');
                  notification.close();
                };
              })
            );
            break;
          case 'click_action':
            if (!click_action) {
              return;
            }
            // console.log('click_action', data);
            notification.onclick = function (event) {
              event.preventDefault();
              window.open(click_action, '_blank');
              notification.close();
            };
            break;
          default:
            break;
        }
      }
    });
  };
  /**
   * Run this as soon as the user has logged in
   */
  useEffect(() => {
    if (!navigator.serviceWorker || !isBrowserSupported || !userId) {
      return;
    }
    setupFirebase();
  }, [userId]);

  return <></>;
}

import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';
import React from 'react';
import { ReactComponent as IconBorderCheckCircle } from 'assets/icons/icon-border-check-mapping.svg';
import { ReactComponent as IconBorderCrossCircle } from 'assets/icons/icon-red-cross.svg';
import { isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';

const MappedFeildsStatus = ({ open, setOpen }) => {
  const { onUploadeSucessMessage, onUploadeErrorMessage } = useSelector(
    (state) => state.uploadContactsSettings
  );
  return (
    <Modal.Main open={open}>
      <Modal.Header title="Upload File" onClose={() => setOpen(!open)} />
      <Modal.Body className="overflow-y-auto">
        <React.Fragment>
          <div className="justify-center flex">
            {!isEmpty(onUploadeSucessMessage) ? (
              <IconBorderCheckCircle />
            ) : (
              <IconBorderCrossCircle />
            )}
          </div>
          <p className="text-lg font-semibold text-center mt-4">
            {!isEmpty(onUploadeErrorMessage)
              ? onUploadeErrorMessage
              : onUploadeSucessMessage}
          </p>
          <div className="flex justify-end mt-20 pr-30">
            <CustomColorButton
              className="btn btn--primary btn--wide-md text-base-1"
              onClick={() => setOpen(false)}
              label={!isEmpty(onUploadeSucessMessage) ? 'Done' : 'Close'}
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default MappedFeildsStatus;

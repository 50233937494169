import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { getMyInfo } from 'slices/myInfoSlice';
import { CONNECT_EBOT_TO_EBOX } from 'urls/confirmEbotToEbox';

export const connectEbotToEboxSlice = createSlice({
  name: 'connectEbotToEbox'
});

export default connectEbotToEboxSlice.reducer;

export const connectEbotToEbox = (payload, callback) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .post(CONNECT_EBOT_TO_EBOX, payload)
        .then((res) => {
          if (callback) {
            dispatch(getMyInfo());
            callback(res);
          }
        })
        .catch((error) => {
          if (callback) {
            callback(error);
          }
        });
    } catch (e) {
      // console.log(e);
    }
  };
};

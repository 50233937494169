/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import HeaderItem from 'modules/enquirybox/channels/components/layout/header';
import WebFormScript from './webFormScript';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedWebform } from 'slices/webformSlice';
import { Loader } from 'components/loader';
// import { getMyInfo } from 'slices/myInfoSlice';
import { updateWebFormData } from 'slices/webformSlice';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import WebFormChannelName from './webFormChannelName';
import AutomateStuff from '../../automationStuff/templates/index';
import DeleteChannel from '../deleteChannel';
import CHOICES from 'choices';

const ConnectedSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { channelId } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [channelName, setChannelName] = useState('');
  const [webFormData, setWebformData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { openSuccessSnackBar } = useSnackbar();

  const onSuccess = (data) => {
    setWebformData(data);
    setChannelName(data?.connected_channel?.name);
    setIsLoading(false);
  };

  const getFormData = () => {
    setIsLoading(true);
    dispatch(
      getConnectedWebform({ subOrgId: subOrganizationId, channelId }, onSuccess)
    );
  };

  useEffect(() => {
    getFormData();
  }, [channelId]);

  const onSave = async (data) => {
    const formData = {
      name: data.name,
      is_connected: webFormData.connected_channel.is_connected,
      subOrgId: subOrganizationId,
      channelId: channelId
    };
    dispatch(
      updateWebFormData(
        {
          subOrgId: subOrganizationId,
          channelId: channelId,
          body: formData
        },
        (data) => {
          onSuccess(data);
          openSuccessSnackBar('Success');
        }
      )
    );
  };

  const onDeleteSuccess = () => {
    // dispatch(getMyInfo());
    openSuccessSnackBar('Channel Removed');
    navigate('/dashboard/enquirybox/settings');
  };

  if (isLoading)
    return (
      <div className="flex justify-center item-center">
        <Loader />
      </div>
    );

  return (
    <div>
      <h2 className="text-2xl font-semibold text-center">Channel Settings</h2>
      <div className="card-box pt-5 px-6 pb-6 mt-8 relative">
        <WebFormChannelName onSave={onSave} channelName={channelName} />
      </div>
      <div className="card-box px-6 py-4 mt-10">
        <div>
          <HeaderItem title="To connect, copy this code and add it to your website" />
          <WebFormScript scriptCode={webFormData} isConnected />
        </div>
      </div>

      <AutomateStuff channelType={CHOICES.ChannelChoices.WEBFORM} />

      <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
    </div>
  );
};

export default React.memo(ConnectedSetting);

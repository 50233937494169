import React from 'react';
import { ReactComponent as HandSvg } from 'assets/icons/icon-hand-small.svg';

const EmptySelectedTemplate = ({ onCreate }) => {
  return (
    <div className="p-4 w-1/2 text-sm font-normal flex-1">
      <figure className="flex justify-center mt-8">
        <HandSvg />
      </figure>
      <h3 className="mt-2 text-sm font-semibold text-center">
        Select a template to preview
        <br />
        <span className="text-primary cursor-pointer" onClick={onCreate}>
          or create a new one
        </span>
      </h3>
    </div>
  );
};

export default EmptySelectedTemplate;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
// import { ReactComponent as UserIcon } from 'assets/icons/icon-team-chat-user.svg';
// import { ReactComponent as ActiveIcon } from 'assets/icons/icon-team-chat-user-active.svg';
// import iconSearch from 'assets/icons/icon-search.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { route } from 'utils/route';
import iconSettings from 'assets/icons/icon-settings.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as FilterIcon } from 'assets/icons/icon-channel-filter.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close-black.svg';
import DropDownWrapper from 'components/dropdown/wrapper';
import CHOICES from 'choices';
import { useDispatch } from 'react-redux';
import {
  getFilteredNotificationList,
  setIsFiltered
} from 'slices/userNotificationSlice';
import useClickOutside from 'hooks/useOutsideClick';
import Avatar from 'components/avatar';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';

const Header = ({ activeTab }) => {
  const nodeRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userId } = useParams();
  const [showFilter, setShowFilter] = useState(false);
  const [type, setType] = useState('');
  const { myInformation } = useSelector((state) => state.myInfo);
  const { avatar, name } = myInformation;

  const options = CHOICES.SystemNotificationTypeChoices.CHOICE_LIST;

  useClickOutside(() => setShowFilter(false), nodeRef.current);

  useEffect(() => {
    dispatch(setIsFiltered(false));
  }, []);

  const callack = () => {
    dispatch(setIsFiltered(true));
    setShowFilter(false);
  };

  const onSelectOption = (type, label) => {
    setType(label);
    dispatch(
      getFilteredNotificationList({
        userId: userId,
        notification_type: type,
        callback: callack
      })
    );
  };

  // const renderIcon = () => {
  //   return !type ? <FilterIcon /> : <CloseIcon />;
  // };

  const onFilterClick = () => {
    setShowFilter(!showFilter);
  };

  const resetFilter = () => {
    dispatch(setIsFiltered(false));
    setType(null);
  };

  const OnSettingsClick = () => {
    navigate(`${route.dashboard}${route.teamChat}/${userId}/settings`);
  };

  return (
    <div
      className="dashboard__main__header"
      style={{ padding: '27px 38px 21px 41px' }}
    >
      <div className="flex items-center gap-4">
        <Avatar
          src={avatar}
          isOnline={true}
          initial={name}
          singleChar
          initialStyle={{ fontSize: 24 }}
          style={{ width: 50, height: 50 }}
          activeIconStyle={{ width: '19px', height: '19px' }}
        />
        <h2 className="font-semibold text-xl">Your feed</h2>
      </div>
      <div className="search-container" ref={nodeRef}>
        {/* <img
          src={iconSearch}
          alt="icon-search"
          className="cursor-pointer"
          style={{ height: 18, width: 18 }}
        />
        <code className="bg-grey-400 mx-4" style={{ width: 1, height: 26 }} /> */}
        <CustomColorButtonWithLogo
          label="Settings"
          className={`btn--xs gap-1 border border-basic-button mr-1`}
          onClick={OnSettingsClick}
          renderSvg={() => {
            return (
              <img
                src={iconSettings}
                alt="icon-settings"
                className="cursor-pointer"
                style={{ height: 11, width: 13 }}
              />
            );
          }}
          style={{ fontSize: 13, fontWeight: 500 }}
        />
        {/* <NavLink
          to={`${route.dashboard}${route.teamChat}/${userId}/settings`}
          className="flex items-center cursor-pointer"
        >
          <img
            src={iconSettings}
            alt="icon-settings"
            className="cursor-pointer mr-4"
            style={{ height: 18, width: 17 }}
          />
        </NavLink> */}
        {activeTab !== 2 && (
          <div className="flex items-center">
            <CustomColorButtonWithLogo
              render={() => {
                return (
                  <div className="flex items-center">
                    {isEmpty(type) ? (
                      <FilterIcon onClick={onFilterClick} className="mr-1" />
                    ) : (
                      <CloseIcon onClick={resetFilter} className="mr-1" />
                    )}
                    {isEmpty(type) ? (
                      <p onClick={onFilterClick}>Filter</p>
                    ) : (
                      <p onClick={onFilterClick}>{type}</p>
                    )}
                  </div>
                );
              }}
              className={`${
                !isEmpty(type) ? 'btn--filter-active' : 'btn--default'
              } gap-1`}
              style={{
                border: '1px solid rgba(230, 228, 225, 0.8)',
                fontWeight: 500
              }}
            />
          </div>
        )}
        {activeTab !== 2 && (
          <DropDownWrapper
            className="dropdown-menu--feed-filter p-2 px-1"
            open={showFilter}
            style={{
              right: '38px',
              left: 'auto',
              width: '230px',
              top: '70px'
            }}
          >
            <div>
              {options.map((item, index) => {
                const { label, value } = item;
                if (!['Task Added', 'Task Reminder'].includes(label)) {
                  return (
                    <div
                      className={`dropdown-menu__list gap-4 ${
                        type === label ? 'selected' : ''
                      }`}
                      key={index}
                      onClick={() => onSelectOption(value, label)}
                    >
                      <span className="text-sm">{label}</span>
                    </div>
                  );
                }
              })}
            </div>
          </DropDownWrapper>
        )}
      </div>
    </div>
  );
};

export default Header;

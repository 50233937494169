/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import { useDispatch } from 'react-redux';
import { cancelSmsPlan } from 'slices/subscription/slice';
import { useSnackbar } from 'components/Snackbar';
import { getAllConnectedChannels } from 'slices/channelsSlice';

const CancelSmsSubscription = ({ open, setShowCancelModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const closeModal = () => {
    setShowCancelModal(false);
  };

  const onCancelAccount = async () => {
    setIsLoading(true);
    dispatch(
      cancelSmsPlan({
        successCb: () => {
          setIsLoading(false);
          closeModal();
          dispatch(getAllConnectedChannels());
          openSuccessSnackBar('Cancelled');
        },
        errorCb: () => {
          setIsLoading(false);
          openErrorSnackBar('Failed');
        }
      })
    );
  };

  return (
    <Modal.Main className="modal--upgrade-notification" open={open}>
      <div className="modal__body px-11 py-10">
        <h1 className="text-25 font-bold text-center">
          Are you sure you want to cancel?
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            If you cancel, your SMS number will be deleted once your balance has
            been used up.
          </p>
          {/* <p className="text-lg leading-6 mt-6">
            Your account will be closed immediately and you can export your
            data.
          </p> */}
        </div>
        <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
          <p className="text-lg font-medium text-center">
            It’s still not too late to keep it
          </p>
          <div className="mt-4">
            <CustomColorButton
              label="Keep my auto top up"
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer mb-3"
              style={{ fontWeight: '700' }}
              onClick={closeModal}
            />
            <CustomColorButton
              label="Upgrade to another bundle"
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
              onClick={closeModal}
            />
            <CustomColorButton
              label="No thanks, cancel it"
              className="w-full bg-white text-lg mt-3 cursor-pointer relative"
              style={{
                color: '#F03636',
                fontWeight: '400',
                border: '1px solid #F03636',
                padding: '10px 18px'
              }}
              isLoading={isLoading}
              enableLoader
              loaderStyle={{ marginLeft: 'none' }}
              onClick={onCancelAccount}
            />
          </div>
        </div>
      </div>
    </Modal.Main>
  );
};

export default CancelSmsSubscription;

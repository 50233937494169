export const route = {
  dashboard: '/dashboard',
  enquirybox: '/enquirybox',
  channel: '/channel',
  box: '/eb-box',
  settings: '/settings',
  channels: '/channels',
  report: '/report',
  enquiryBot: '/enquiry-bot',
  teamChat: '/team-chat',
  boxes: '/boxes',
  enquiryBin: '/enquiry-bin',
  addBoxTemplate: '/add-box-template',
  teamReport: '/team',
  incomingReport: '/incoming',
  boxReport: '/box',
  myProfile: '/my-profile',
  fileLibrary: 'file-library',
  toolBox: '/tool-box',
  confirmEbotToEbox: '/confirm_ebot_to_ebox_connection',
  chatbotAi: '/chatbot-ai'
};

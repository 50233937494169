/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Modal from 'components/modal';
import { useDispatch } from 'react-redux';
import CustomColorButton from 'components/buttons/customColorButton';
import {
  setPostExpiryActiveModal,
  setShowSubscriptionModal
} from 'slices/subscription/slice';
import { PAYMENT_INCOMPLETE } from 'utils/constants';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';

const PaymentNotCompleted = ({
  open = false,
  isLoading,
  handleFreeTrialPlan,
  handleCurrentTrialPlan,
  currentPlan
}) => {
  const dispatch = useDispatch();
  return (
    <Modal.Main className="modal--upgrade-notification show" open={open}>
      <div className="modal__body px-8 py-10">
        <h1 className="text-25 font-bold text-center">
          Please complete your payment
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6 mt-6">
            You have not yet completed the payment to start your plan. Please
            complete your payment below.
          </p>
        </div>
        <div className="bg-grey-90 border-radius-14 py-4 pb-4 px-6 mt-6">
          <div className="my-2">
            <CustomColorButton
              label="Complete payment"
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
              onClick={() => {
                dispatch(setShowSubscriptionModal(true));
                dispatch(setPostExpiryActiveModal(PAYMENT_INCOMPLETE));
              }}
            />
          </div>
        </div>
        {/* whenever user wants to try free trial, send is_free_plan to true,
         so new plan for user is created in backend */}
        {isEmpty(currentPlan.plan) && (
          <div className="mt-5 text-center">
            <p
              className="text-lg leading-6 mt-6 cursor-pointer"
              onClick={() => handleFreeTrialPlan()}
            >
              Not sure, want to try it out first?
              <span className="font-medium pl-1">
                Start a free trial
                {isLoading && <Loader />}
              </span>
            </p>
          </div>
        )}
        {/* whenever currentPlan.plan is null, send is_free_plan to false(meaning reset the plan and 
          set plan to it's previous plan in backend) */}
        {!isEmpty(currentPlan.plan) && (
          <div className="mt-5 text-center px-4">
            <p
              className="text-lg leading-6 mt-6 cursor-pointer"
              onClick={() => handleCurrentTrialPlan()}
            >
              <span className="font-medium pr-1 ">Maybe later,</span>
              I’ll stick with the {currentPlan?.plan?.plan_name} plan for now
              {isLoading && <Loader />}
            </p>
          </div>
        )}
      </div>
    </Modal.Main>
  );
};

export default PaymentNotCompleted;

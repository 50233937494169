/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
import Loader from 'components/loader';

const ToolboxVideoContent = ({ src }) => {
  const [isLoading, setIsLoading] = useState(true);
  return (
    <>
      <div className="product-update-video-container">
        {isLoading && <Loader />}
        {src && (
          <iframe
            src={src}
            onLoad={() => {
              console.log('LOADED');
              setIsLoading(false);
            }}
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen={true}
            title="iframe"
            className="cursor-pointer"
            style={{
              width: '100%',
              height: '100%',
              border: '10px solid #fff',
              borderRadius: '20px',
              backgroundColor: '#fff'
            }}
          />
        )}
      </div>
    </>
  );
};

export default ToolboxVideoContent;

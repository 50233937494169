/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import CustomColorButton from 'components/buttons/customColorButton';
import ErrorMessage from 'components/errorMessage';
import TextInputField from 'components/inputs/textField';
import Modal from 'components/modal';
import useClickOutside from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getCustomBoxtemplates,
  setBoxIcon,
  setboxName
} from 'slices/customBoxSlice';
import { route } from 'utils/route';
import { isEmpty } from 'utils/utils';
import EmojiModal from 'modules/dashboard/module/addCustomBox/modules/EmojiModal';
import { updateCardDesigne } from 'slices/cardDesignSettingsSlice';
import { renameBox } from 'slices/boxes/slice';
import { useSnackbar } from 'components/Snackbar';

const RenameBox = ({
  open,
  onClose,
  handleCreateBox,
  setIsCreateLoading,
  isCreateLoading,
  iconStyle,
  box
}) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [boxName, setBoxName] = useState(box.box_name);
  const [selectedEmoji, setSelectedEmoji] = useState(box.box_avatar);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nodeRef = useRef();
  const { openSuccessSnackBar } = useSnackbar();
  // const icon =
  // const { boxName, boxTemplates } = useSelector((state) => state.addcustomBox);

  useEffect(() => {
    if (box) {
      setBoxName(box.box_name);
      setSelectedEmoji(box.box_avatar);
    }
  }, [box, open]);

  const onSuccess = () => {
    openSuccessSnackBar('success');
    setIsLoading(false);
    onCancel();
  };

  const handleUpdateBox = () => {
    const formData = {
      box_id: box.id,
      data: {
        box_name: boxName,
        avatar: selectedEmoji,
        sub_organization: subOrganizationId
      }
    };
    dispatch(renameBox(formData, onSuccess));
  };

  const onUpdate = () => {
    if (!isEmpty(boxName) && boxName.length < 30) {
      setIsLoading(true);
      handleUpdateBox();
    } else {
      setError('please enter valid box name, maximum 30 characters allowed ');
    }
  };
  useClickOutside(() => setOpenEmoji(false), nodeRef.current);

  const onEmojiSelect = (data) => {
    dispatch(setBoxIcon(data.emoji));
    setSelectedEmoji(data.emoji);
    setOpenEmoji(false);
  };

  const getIcon = (icon) => {
    if (icon?.includes('https://'))
      return <img src={icon} alt="icn" style={iconStyle} />;
    if (!icon?.includes('https://'))
      return <div dangerouslySetInnerHTML={{ __html: icon }} />;
    return <></>;
  };

  const onCancel = () => {
    setboxName('');
    setSelectedEmoji('');
    setError('');
    onClose();
  };

  return (
    <Modal.Main open={open} className="modal--channels-request">
      <Modal.Header
        title="Change box name and icon"
        onClose={isLoading ? () => {} : onCancel}
      />
      <Modal.Body className="pb-10 overflow-y-auto">
        <React.Fragment>
          <div className="">
            <label className="input-field-label">Box name</label>
            <TextInputField
              name="box-name"
              placeholder="Give it a name that reflects what you will put in it"
              value={boxName}
              onChange={(evt) => setBoxName(evt.target.value)}
            />
          </div>
          {!isEmpty(error) && <ErrorMessage message={error} />}
          <div className="mt-25" ref={nodeRef}>
            <label className="input-field-label">Add an icon</label>
            <figure
              className="avatar-container avatar-container--contain cursor-pointer"
              style={{
                width: 70,
                height: 70,
                fontSize: '40px',
                boxShadow: 'inset 0px 0px 0px 1px rgb(0 0 0 / 5%)'
              }}
              onClick={() => setOpenEmoji(!openEmoji)}
            >
              {getIcon(selectedEmoji)}
            </figure>
            {openEmoji && (
              <div>
                <EmojiModal
                  hideSearch={true}
                  className="mt-1"
                  setOpenEmoji={setOpenEmoji}
                  onEmojiSelect={onEmojiSelect}
                />
              </div>
            )}
          </div>
          <div className="flex items-center gap-2 justify-end mt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--wide-md-40 btn--cancel text-base-1 mr-3"
              onClick={onCancel}
              isDisable={isLoading}
            />
            <CustomColorButton
              label="Update"
              className="btn--wide-md-40 text-base-1 bg-primary text-white mr-3"
              onClick={onUpdate}
              enableLoader
              isLoading={isLoading}
              loaderInline
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default RenameBox;

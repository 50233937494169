import React from 'react';
import PropTypes from 'prop-types';

const FieldListItem = (props) => {
  const { name } = props;
  return (
    <div className="w-[50%] min-w-[50%]">
      <div
        className="w-full flex items-center px-5 py-1 bg-grey-300 rounded-lg truncate"
        style={{
          boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)',
          height: 42
        }}
      >
        <label className="text-base-1 truncate">{name}</label>
      </div>
    </div>
  );
};

FieldListItem.propTypes = {
  name: PropTypes.string
};

export default FieldListItem;

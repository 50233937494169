import { urlRegex } from 'components/fieldsAndValidation';
import { isEmpty } from './utils';

export const openingMessageOptions = [
  {
    id: 1,
    value: "Welcome to Smiles Dental, I'm Emma here to assist with your enquiry"
  },
  {
    id: 2,
    value: 'What would you like to do?'
  }
];

export const starterOptions = [
  {
    id: 1,
    value: 'Book a consultation'
  },
  {
    id: 2,
    value: 'Enquire about a treatment'
  },
  {
    id: 3,
    value: 'Request a call back'
  },
  {
    id: 4,
    value: 'Something else'
  }
];

export const showNameEmail = (userName, email) => {
  if (userName && email) {
    return `${userName} (${email})`;
  }
  return '';
};

export const checkUrl = (url) => {
  return urlRegex.test(url);
};
export const defaultpersonalityErrors = {
  name: 'Name cannot be empty',
  persona: 'Persona cannot be empty',
  desired_outcome: 'Desired outcome cannot be empty',
  unknown_question_response: 'Unknown response cannot be empty'
};

// export const defaultWelcomeErrors = {
//   message: 'You must have at least one welcome message',
//   option: 'You must have at least one starter message'
// };

export const defaultDataCollectionErrors = {
  data_field: 'You must have at least one piece of data',
  gdpr_field: 'You must have at least one validation in place',
  compliance_message: 'Compliance message cannot be empty',
  privacy_policy_link: 'privacy link cannot be empty'
};
export const defaultBrainErrors = {
  content: 'Please feed your bots with at lease one type of information below'
};
export const getErrorFields = (data) => {
  return !isEmpty(data)
    ? Object.fromEntries(Object.entries(data).filter(([, val]) => val !== true))
    : {};
};
export const checkBrainData = (data) => {
  return Object.fromEntries(
    Object.entries(data).filter(([, val]) => val !== 0)
  );
};
export const showFilteredFields = (newdata, errors) => {
  if (!isEmpty(newdata)) {
    let getFields = getErrorFields(newdata);
    return Object.keys(getFields).reduce((acc, item) => {
      acc[item] = errors[item];
      return acc;
    }, {});
  }
};

export const getErrorLength = (data) => {
  return !isEmpty(data) ? Object.keys(getErrorFields(data)).length : 0;
};

export function deleteFromObject(keyPart, obj) {
  for (var k in obj) {
    // Loop through the object
    if (~k.indexOf(keyPart)) {
      // If the current key contains the string we're looking for
      delete obj[k]; // Delete obj[key];
    }
  }
}

export const isFileValid = (file) => {
  const maxFileSize = 100 * 1024 * 1024;
  if (!file) {
    return {
      message: 'Please select a file',
      isValid: false
    };
  }
  const allowedFileTypes = [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'text/csv'
  ];
  if (!allowedFileTypes.includes(file.type)) {
    return {
      message: 'Only Excel and CSV files are allowed.',
      isValid: false
    };
  }
  if (file.size > maxFileSize) {
    return {
      message: 'File size exceeds the maximum limit of 100MB',
      isValid: false
    };
  }
  return {
    message: 'Valid file',
    isValid: true
  };
};

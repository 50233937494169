/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConversationHeader from 'modules/enquirybox/ebox/module/box/conversation/header';
import { useParams, Navigate } from 'react-router-dom';
import {
  getLeadConversation,
  fetchReplyById,
  getLeadData,
  setFetchConversation
} from 'slices/conversationSlice';
import { Loader } from 'components/loader';
import { setShowInboxActions } from 'slices/eboxLead';
import Actions from 'modules/enquirybox/ebox/module/box/conversation/actions/conversationActions';
import ChatFooterInputBox from '../../footer/chatFooter/main';
import CHOICES from 'choices';
import EbotContainer from '../../components/ebotContainer';
import EmailBody from '../../email/new/index';
import TaskContainer from '../../components/taskContainer';
import NoteContainer from '../../components/noteContainer';
import SmsMessageContainer from '../component/smsMessageContainer';
import { animateScroll } from 'react-scroll';
import ImageModalContainer from 'modules/modals/ImageModal';
import { route } from 'utils/route';
import { isEmpty, timeFormatter } from 'utils/utils';
import { handleScroll } from 'modules/enquirybox/helper/scrollConversation';

const JUST_TO_STORE_DATA = CHOICES.LeadReplyChoice.JUST_TO_STORE_DATA;
const SMS_REPLY = CHOICES.LeadReplyChoice.SMS_REPLY;
const NOTE_REPLY = CHOICES.LeadReplyChoice.NOTE_REPLY;
const TASK_REPLY = CHOICES.LeadReplyChoice.TASK_REPLY;
const MOVED_TO_BIN = CHOICES.LeadStatusChoices.MOVED_TO_BIN;
const EMAIL_REPLY = CHOICES.LeadReplyChoice.EMAIL_REPLY;

const EnquiryBotConversation = (props) => {
  const { isBin, isFacebook, isInsta } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const { conversation, activeLead, showCardNotFound, fetchConversation } =
    useSelector((state) => state.leadConversation);
  const { showInboxActions } = useSelector((state) => state.leads);
  const { showImagePreview } = useSelector((state) => state.app);

  const callback = () => {
    setIsLoading(false);
  };

  const loadLeadConversation = () => {
    setIsLoading(true);
    dispatch(getLeadConversation({ leadId: cardId }, callback));
  };

  const resetReplyStore = () => {
    dispatch(setShowInboxActions(true));
  };

  const scrollDown = () => {
    animateScroll.scrollToBottom({
      containerId: 'Conversation',
      smooth: true,
      duration: 0
    });
  };

  const loadConversationCallback = () => {
    scrollDown();
  };

  const onReplySentSuccess = () => {
    dispatch(getLeadConversation({ leadId: cardId }, loadConversationCallback));
    dispatch(getLeadData({ leadId: cardId }));
  };

  useEffect(() => {
    loadLeadConversation();
    resetReplyStore();
  }, []);

  useEffect(() => {
    if (fetchConversation) {
      dispatch(
        getLeadConversation({ leadId: cardId }, () => {
          setTimeout(() => {
            handleScroll(conversation);
          }, 300);
          dispatch(setFetchConversation(false));
        })
      );
    }
    return () => {
      dispatch(setFetchConversation(false));
    };
  }, [fetchConversation]);

  const isContainEmailReply = () => {
    const emailReply = conversation?.results.map((result) => {
      if (result?.reply_type === EMAIL_REPLY) {
        return true;
      }
    });
    if (emailReply.includes(true)) {
      return true;
    } else {
      false;
    }
  };

  useEffect(() => {
    if (!isLoading && isContainEmailReply()) {
      setTimeout(() => {
        handleScroll(conversation);
      }, 300);
    }
  }, [isLoading, conversation.results]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     setTimeout(() => {
  //       handleScroll(conversation);
  //     }, 300);
  //   }
  // }, [isLoading, conversation.results]);

  if (isLoading) return <Loader />;

  const showMoreActions = conversation?.results?.some(
    (item) => item.is_reply_sent === true
  );

  if (showCardNotFound) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-not-found`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-not-found`}
      />
    );
  }

  if (!isEmpty(activeLead) && activeLead?.status === MOVED_TO_BIN && !isBin) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-moved-to-bin`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-moved-to-bin`}
      />
    );
  }

  return (
    <>
      <ConversationHeader activeLead={activeLead} isBin={isBin} />
      <div
        className="chat-message-body"
        style={{ padding: 25 }}
        id="Conversation"
      >
        {conversation.results.map((item, idx) => {
          if (item.reply_type === JUST_TO_STORE_DATA) {
            return (
              <EbotContainer
                id={item.id}
                key={item.id}
                conversation={conversation.results}
                data={item}
                isLastreply={
                  showInboxActions && idx === conversation?.results.length - 1
                }
                avatarUrl={item?.lead?.bot_avatar_url}
              />
            );
          }
          if (EMAIL_REPLY === item.reply_type)
            return (
              <div
                key={item.id}
                style={{
                  marginBottom:
                    showInboxActions && idx === conversation?.results.length - 1
                      ? '80px'
                      : '25px'
                }}
              >
                <EmailBody id={item.id} data={item} isCollapse={false} />
              </div>
            );
          if (NOTE_REPLY === item.reply_type)
            return (
              // <div key={item.id} id={item.id}></div>
              <div
                key={item.id}
                style={{
                  marginBottom:
                    showInboxActions && idx === conversation?.results.length - 1
                      ? '80px'
                      : '25px'
                }}
              >
                <NoteContainer
                  created_at={timeFormatter(item.created_at)}
                  data={item.note}
                  createdBy={item.created_by}
                  isBin={isBin}
                  item={item}
                  idx={idx}
                />
              </div>
            );
          if (TASK_REPLY === item.reply_type)
            return (
              <div key={item.id} id={item.id}></div>
              // <div
              //   key={item.id}
              //   style={{
              //     marginBottom:
              //       showInboxActions && idx === conversation?.results.length - 1
              //         ? '80px'
              //         : '25px'
              //   }}
              // >
              //   <TaskContainer
              //     created_at={timeFormatter(item.created_at)}
              //     data={item.task}
              //     createdBy={item.created_by}
              //     isBin={isBin}
              //     item={item}
              //   />
              // </div>
            );
          if (SMS_REPLY === item.reply_type)
            return (
              <SmsMessageContainer
                key={item.id}
                item={item}
                isReplyRecieved={item.is_reply_received}
                isReplySent={item.is_reply_sent}
                index={idx}
                conversation={conversation.results}
                showInboxActions={showInboxActions}
                id={item.id}
              />
            );
          return (
            <div
              key={item.id}
              id={item.id}
              style={{
                marginBottom:
                  showInboxActions && idx === conversation?.results.length - 1
                    ? '80px'
                    : '25px'
              }}
            />
          );
        })}
      </div>

      {showInboxActions && !isBin && (
        <Actions
          onOpenEditor={() => dispatch(setShowInboxActions(false))}
          showMoreActions={showMoreActions}
          // onBinIt={() => setShowDelete(true)}
        />
      )}
      {!showInboxActions && !isBin && (
        <ChatFooterInputBox
          onClose={() => dispatch(setShowInboxActions(true))}
          isFacebook={isFacebook}
          isInsta={isInsta}
          onReplySentSuccess={onReplySentSuccess}
          hideAttachment={true}
          hideImage={true}
        />
      )}
      <ImageModalContainer open={showImagePreview} />
    </>
  );
};

export default EnquiryBotConversation;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Quill from 'components/quill';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAllEditorState,
  setEmailBody,
  setIsForceClose,
  setIsSoftClose
} from 'slices/editor/slice';
import { setIsReplyLoading } from 'slices/facebook/slice';
import { isEditorEmpty, isEmpty } from 'utils/utils';
import APIRequest from 'apiRequest';
import { SEND_SMS_REPLY } from 'urls/sms';
import CHOICES from 'choices';
import { useParams } from 'react-router-dom';
import {
  changeLeadStatus,
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads,
  setActiveNewLead
} from 'slices/eboxLead';
import { getLeadData, setActiveLead } from 'slices/conversationSlice';
import {
  changeBoxLeadStatus,
  composeStageLeads,
  getStageLeads
} from 'slices/boxes/slice';
import { useSnackbar } from 'components/Snackbar';
import { useAnalytics } from 'use-analytics';

const REPLY_LATER = CHOICES.LeadStatusChoices.REPLY_LATER;
const VIEWED = CHOICES.LeadStatusChoices.VIEWED;
const SMS_REPLY = CHOICES.LeadReplyChoice.SMS_REPLY;

const ReplyBySms = ({
  isFooterExpanded,
  hideImage,
  hideAttachment,
  onClose,
  onReplySentSuccess
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [error, setError] = useState({});
  const [, setAttachments] = useState([]);
  const { emailBody } = useSelector((state) => state.editor);
  const { isReplyLoading } = useSelector((state) => state.fb);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { activeNewLead } = useSelector((state) => state.leads);
  const { is_sms_connected } = useSelector(
    (state) => state.myInfo.myInformation
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [length, setLength] = useState(0);
  const [smsCount, setSmsCount] = useState();
  const { id: current_user } = useSelector(
    (state) => state.myInfo.myInformation
  );
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const isSmsConversation =
    is_sms_connected && (activeLead?.phone_number ? true : false);

  const updateLeadStatusIfInReplyLater = async () => {
    if (activeLead.status === REPLY_LATER) {
      const data = {
        lead: cardId,
        is_current: true,
        status: VIEWED
      };
      if (!isBox) {
        // await Promise.all([
        //   dispatch(changeLeadStatus({ body: data })),
        //   dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        // ]);
      }
      if (isBox) {
        await Promise.all([
          dispatch(changeBoxLeadStatus({ body: data, stageId })),
          dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        ]);
      }
    }
  };

  const onSuccess = () => {
    dispatch(setIsSoftClose(false));
    dispatch(setIsForceClose(false));
    updateLeadStatusIfInReplyLater();
    dispatch(setEmailBody(''));
    dispatch(setActiveLead({ ...activeLead, draft_reply: {} }));
    updateLeadCardsData();
    dispatch(setIsReplyLoading(false));
    openSuccessSnackBar('Reply sent');
    track(
      'reply_sent',
      {
        reply_type: 'Sms'
      },
      {
        groupId: subOrganizationId
      }
    );
    onClose();
    onReplySentSuccess();
  };

  // updating the cards status because after the sending the reply right side card data should update
  const updateLeadCardsData = async () => {
    if (!isBox) {
      // if (VIEWED === activeLead?.status) {
      //   dispatch(getReadLeads());
      // }
      // if (REPLY_LATER === activeLead?.status) {
      //   dispatch(getReplyLaterLeads());
      //   //updating the status even after calling the reply later api status is not updating
      // }
      // if (activeNewLead === activeLead?.id) {
      //   dispatch(setActiveNewLead());
      //   dispatch(getUnReadLeads());
      //   dispatch(getReadLeads());
      // }
    }
    if (isBox) {
      if (activeLead.status !== REPLY_LATER) {
        await dispatch(getStageLeads({ stageId }))
          .then((res) => {
            dispatch(composeStageLeads({ stageId, results: res.data }));
            dispatch(getLeadData({ leadId: cardId }));
          })
          .catch((err) => {
            console.log();
          });
      }
    }
  };

  const onSubmit = async () => {
    dispatch(setIsReplyLoading(true));
    if (isEditorEmpty(emailBody)) {
      let err = {};
      err = {
        ...err,
        emailBody: 'Please add message'
      };
      dispatch(setIsReplyLoading(false));
      setError(err);
    } else {
      var temp = document.createElement('div');
      temp.innerHTML = emailBody;
      var sanitized = temp.textContent || temp.innerText;
      const value = sanitized;
      let data = {
        lead: cardId,
        message: value,
        is_in_draft_state: false,
        is_reply_sent: true,
        message_sent_by: current_user,
        reply_type: SMS_REPLY,
        sms_count: smsCount
      };
      if (!isEmpty(activeLead?.draft_reply)) {
        data = {
          ...data,
          lead_reply_id: activeLead?.draft_reply?.id
        };
      }
      await new APIRequest()
        .post(SEND_SMS_REPLY, data)
        .then((res) => {
          onSuccess();
        })
        .catch((err) => {
          console.log({ err });
          dispatch(setIsReplyLoading(false));
          openErrorSnackBar('Error');
        });
    }
  };

  const onUseTemplate = (subject, body, attachments) => {
    dispatch(setEmailBody(body));
    setAttachments(attachments);
  };

  return (
    <div className="chat-message-footer__body">
      <div className={`chat-reply ${error.emailBody ? 'error-editor' : ''}`}>
        <Quill
          onChange={(data) => {
            setError({ ...error, emailBody: null });
            dispatch(setEmailBody(data));
          }}
          value={emailBody}
          showFontStyle={false}
          placeholder="Type a reply..."
          setAttachments={setAttachments}
          isLoading={isReplyLoading}
          isFooterExpanded={isFooterExpanded}
          onSend={onSubmit}
          hideImage={hideImage}
          hideAttachment={hideAttachment}
          errorMessage={error.emailBody}
          isFbOrInsta={false}
          isRefetch={true}
          onUseTemplate={onUseTemplate}
          setLength={setLength}
          length={length}
          enableCharacterLimit
          maxCharacters={160}
          showCharLimitTop={true}
          isSms={true}
          isSmsConversation={isSmsConversation}
          setSmsCount={setSmsCount}
          isAutoFocus={true}
        />
      </div>
    </div>
  );
};

export default ReplyBySms;

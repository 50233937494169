/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useMemo } from 'react';

const initializeFb = (appId, isFacebookLeads) => {
  return new Promise((resolve, _reject) => {
    if (typeof window.FB !== 'undefined') {
      window.FB.init({
        appId: appId,
        cookie: true,
        xfbml: true,
        version: 'v15.0'
      });
      resolve();
    } else {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: appId,
          cookie: true,
          xfbml: true,
          version: 'v15.0'
        });
        // window.FB.AppEvents.logPageView();
        resolve();
      };

      (function (d, s, id) {
        let js;
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
          return;
        }
        js = d.createElement(s);
        js.id = id;
        js.src = 'https://connect.facebook.net/en_US/sdk.js';
        fjs.parentNode.insertBefore(js, fjs);
      })(document, 'script', 'facebook-jssdk');
    }
  });
};

const useFacebook = (appId = false, isFacebookLeads) => {
  const [fb, setFB] = useState([]);
  const [isReady, setReady] = useState(false);

  const initFacebook = async () => {
    await initializeFb(appId, isFacebookLeads);
    if (typeof window.FB !== 'undefined') {
      setFB(window.FB);
      setReady(true);
    }
  };

  useMemo(() => {
    if (appId) initFacebook();
  }, [appId]);

  return [fb, isReady];
};

export default useFacebook;

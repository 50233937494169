import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import starAvatarIcon from 'assets/icons/sup-avatar-star-blue.svg';
import userEmptyState from 'assets/icons/icon-user-empty-state.svg';
import { isEmpty } from 'utils/utils';
import Avatar from 'components/avatar';
import { numberFormat } from 'utils/number';

const TopSalesPerformer = (props) => {
  const { isShowEmptyState, data } = props;
  return (
    <div
      className="card-box-shadow w-full p-5 pb-3 ml-3 mr-3"
      style={{ width: '50%' }}
    >
      <div className="flex items-center justify-between">
        <CustomToolTip
          style={{ width: 'max-content' }}
          tooltipStyle={{
            width: 240,
            top: -75,
            bottom: 'initial',
            left: -20,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="The team member with the highest number of converted sales."
        >
          <h4 className="font-semibold letterspacing24em">
            Top sales performer
          </h4>
        </CustomToolTip>

        <div className="bg-violet-700 border-radius-20 px-3 py-1 text-11 text-white font-semibold letterspacing08em">
          Top performer
        </div>
      </div>
      {isShowEmptyState ? (
        <div className="flex items-center mt-6">
          <figure
            className="avatar-container avatar-container--contain"
            style={{
              width: 68,
              height: 68,
              backgroundColor: '#FAF9F8',
              borderWidth: 4,
              borderColor: '#A495FF'
            }}
          >
            <img src={userEmptyState} />
            <img
              src={starAvatarIcon}
              className="absolute"
              style={{
                width: 'initial',
                height: 'initial',
                top: -14,
                right: -16
              }}
            />
          </figure>
          <div className="ml-4">
            <div className="data-overlay opacity-6"></div>
            <div
              className="data-overlay  mt-2"
              style={{ width: 130, height: 12 }}
            ></div>
          </div>
        </div>
      ) : (
        <>
          {!isEmpty(data) && (
            <div className="flex items-center mt-6">
              <Avatar
                style={{
                  width: 68,
                  height: 68,
                  padding: 4,
                  background:
                    'linear-gradient(135.77deg, #A495FF 15.24%, #6B58E1 85.9%)'
                }}
                src={data?.user?.avatar}
                initial={data?.user?.name || data?.user?.first_name}
                singleChar
                renderOuterIcon={() => {
                  return (
                    <img
                      src={starAvatarIcon}
                      className="absolute"
                      style={{
                        width: 'initial',
                        height: 'initial',
                        top: -11,
                        right: -13
                      }}
                    />
                  );
                }}
              />
              <div className="ml-4">
                <h3 className="font-semibold">
                  {data?.user?.name || data?.user?.first_name}
                </h3>
                <p className="text-sm font-medium text-primary mt-1">
                  £{numberFormat(data?.sales_value, 2)} in sales
                </p>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default TopSalesPerformer;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { getObjectEntries, isEmpty } from 'utils/utils';
import { STEP_TYPES_MAP as STM } from 'utils/stepTypes';
import ImageMCQCarousel from '../imageCarousel';
import _get from 'lodash/get';
import { SUPPORTED_FORMATS } from 'utils/file';
import DownloadLink from 'components/downloadLink';
import iconDownload from 'assets/icons/icon-download.svg';
import CustomToolTip from 'components/CustomTooltip';
import { fileIcons } from 'components/constants';
import { formatDateTime } from 'utils/dateFormat';

const DEFAULT_TRANSPARENCY = 0.25;

const DateRangeFormatter = (d) => {
  const [date1, date2] = d.split(',');
  const date = `${formatDateTime(date1, 'dd-mm-yyyy')} - ${formatDateTime(
    date2,
    'dd-mm-yyyy'
  )}`;
  return date;
};

const getStepOptions = (dealConversation) => {
  return getObjectEntries(_get(dealConversation, 'step.options', {}))
    .map((e) => ({ [e[0]]: e[1] }))
    .filter((option) => {
      let matchIndex = dealConversation.options.findIndex(
        (id) => id === Object.keys(option)[0]
      );
      return matchIndex >= 0;
    })
    .map((Obj) => {
      const id = Object.keys(Obj)[0];
      const [
        optionValue,
        imageUrl,
        isUploaded,
        isRedirect,
        redirectUrl,
        advanceOptions,
        hasAdvanceOptions
      ] = Obj[id];
      return {
        id,
        text: optionValue,
        url: imageUrl,
        isUploaded: isUploaded,
        isRedirect,
        redirectUrl,
        advanceOptions,
        hasAdvanceOptions
      };
    });
};

const EbotContainer = (props) => {
  const { data, isLastreply, avatarUrl } = props;
  const [conversationMessage, setConversationMessage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [stepOptions, setStepOptions] = useState({});
  const [current, setCurrent] = useState(0);
  const [imageMcqColors, setImageMcqColors] = useState({
    background: 'rgb(251, 190, 77)',
    card_text: '#ffffff',
    text: 'rgb(31, 30, 30)'
  });

  let avatar =
    'https://screenshots-demo-eb.s3-eu-west-1.amazonaws.com/botimage.svg';

  useEffect(() => {
    evaluteData(data);
  }, []);

  const evaluteData = (data) => {
    if (data.message && typeof data.message === 'string') {
      let tempData = {};
      tempData = data.message;
      try {
        tempData = JSON.parse(tempData);
      } catch (e) {
        console.log(e);
      }
      setConversationMessage(tempData);
      const steps = getStepOptions(tempData);
      setStepOptions(steps);
    }
    setIsLoading(false);
  };

  if (isLoading) return <></>;

  return (
    <>
      {!isEmpty(conversationMessage) && (
        <div>
          {!isEmpty(conversationMessage?.messages) && (
            <>
              {conversationMessage?.messages.map((item, index) => {
                const message = item
                  .replaceAll(/<p/g, '<div')
                  .replaceAll(/<\/p/g, '</div');
                return (
                  <div
                    className="chat-message-list chat-message-list--sm flex"
                    style={{ marginBottom: 25 }}
                    key={index}
                  >
                    <figure
                      className="general-settings__card-list__avatar-container"
                      style={{ maxHeight: 32, maxWidth: 32, marginRight: 6 }}
                    >
                      <img
                        src={avatarUrl ? avatarUrl : avatar}
                        alt="profile-pic"
                        loading="lazy"
                        style={{
                          objectFit: 'cover',
                          height: '100%',
                          width: '100%'
                        }}
                      />
                    </figure>
                    <div className="flex justify-end">
                      <div
                        className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-grey-90"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15
                        }}
                        dangerouslySetInnerHTML={{ __html: message }}
                      />
                    </div>
                  </div>
                );
              })}
            </>
          )}
          {!isEmpty(conversationMessage?.text) && (
            <>
              {(conversationMessage?.step?.step_type === STM.IMAGE_MCQ.id ||
                conversationMessage?.step?.step_type === STM.CAROUSEL.id) && (
                <div
                  className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
                  style={
                    isLastreply && isEmpty(conversationMessage?.post_messages)
                      ? { marginBottom: 80 }
                      : { marginBottom: 25 }
                  }
                >
                  <div className="flex justify-end">
                    <ImageMCQCarousel
                      items={stepOptions.map((item) => {
                        const { advanceOptions } = item;
                        return {
                          id: item.id,
                          title: item.text,
                          url: item.url,
                          text_color:
                            item.hasAdvanceOptions && advanceOptions.text_color
                              ? advanceOptions.text_color
                              : '#ffffff',
                          sub_title: item.hasAdvanceOptions
                            ? advanceOptions.sub_title
                            : '',
                          button_text: '',
                          highlight_text: '',
                          unit: item.hasAdvanceOptions
                            ? advanceOptions.unit
                            : '',
                          overlay_color:
                            item.hasAdvanceOptions &&
                            advanceOptions.overlay_color
                              ? advanceOptions.overlay_color
                              : '#000000',
                          overlay_opacity: item.hasAdvanceOptions
                            ? advanceOptions.overlay_opacity
                            : DEFAULT_TRANSPARENCY,
                          price: item.hasAdvanceOptions
                            ? advanceOptions.price
                            : ''
                        };
                      })}
                      cardSize={350}
                      buttonColor={imageMcqColors.background}
                      buttonTextColor={imageMcqColors.text}
                      currency={''}
                      buttonHoverColor={imageMcqColors.background}
                      activeItem={current}
                      onActiveItemChange={(index) => setCurrent(index)}
                    />
                  </div>
                </div>
              )}
              {conversationMessage?.step?.step_type === STM.FILE_UPLOAD.id && (
                <>
                  <div
                    className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
                    style={
                      isLastreply && isEmpty(conversationMessage?.post_messages)
                        ? { marginBottom: 80 }
                        : { marginBottom: 25 }
                    }
                  >
                    <div className="flex justify-end">
                      {!isEmpty(conversationMessage?.text) &&
                        conversationMessage.text.split(',').map((url) => {
                          if (
                            !SUPPORTED_FORMATS.includes(url.split('.').pop())
                          ) {
                            return (
                              <div
                                className="cursor-pointer flex items-center text-base-1 text-grey-900 border border-grey-400 bg-automation"
                                key={url}
                                style={{
                                  borderRadius: 10,
                                  paddingTop: 6,
                                  paddingBottom: 6,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  maxWidth: '40%',
                                  minWidth: 80
                                }}
                              >
                                <p className="text-13 text-grey-900 mr-3">
                                  File
                                </p>
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className="cursor-pointer flex items-center text-base-1 text-grey-900 border border-grey-400 bg-automation"
                                key={url}
                                style={{
                                  borderRadius: 10,
                                  paddingTop: 6,
                                  paddingBottom: 6,
                                  paddingLeft: 15,
                                  paddingRight: 15,
                                  maxWidth: '40%',
                                  minWidth: 80
                                }}
                              >
                                <p className="text-13 text-grey-900 mr-3">
                                  File
                                </p>
                                <CustomToolTip
                                  position="top"
                                  tooltipText="download"
                                >
                                  <DownloadLink to={url}>
                                    <img
                                      className={`secondary-icon secondary-icon__visible`}
                                      src={iconDownload}
                                      alt="download"
                                    />
                                  </DownloadLink>
                                </CustomToolTip>
                              </div>
                            );
                          }
                        })}
                    </div>
                  </div>
                </>
              )}
              {conversationMessage?.step?.step_type === STM.CALENDAR.id && (
                <>
                  {!isEmpty(conversationMessage.text) && (
                    <div
                      className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
                      style={
                        isLastreply &&
                        isEmpty(conversationMessage?.post_messages)
                          ? { marginBottom: 80 }
                          : { marginBottom: 25 }
                      }
                    >
                      <div className="flex justify-end">
                        <div
                          className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-automation"
                          style={{
                            borderRadius: 10,
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingLeft: 15,
                            paddingRight: 15
                          }}
                        >
                          {formatDateTime(
                            conversationMessage.text,
                            'dd-mm-yyyy'
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {conversationMessage?.step?.step_type === STM.DATE_RANGE.id && (
                <>
                  {!isEmpty(conversationMessage.text) && (
                    <div
                      className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
                      style={
                        isLastreply &&
                        isEmpty(conversationMessage?.post_messages)
                          ? { marginBottom: 80 }
                          : { marginBottom: 25 }
                      }
                    >
                      <div className="flex justify-end">
                        <div
                          className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-automation"
                          style={{
                            borderRadius: 10,
                            paddingTop: 6,
                            paddingBottom: 6,
                            paddingLeft: 15,
                            paddingRight: 15
                          }}
                        >
                          {DateRangeFormatter(conversationMessage.text)}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}
              {conversationMessage?.step?.step_type !== STM.IMAGE_MCQ.id &&
                conversationMessage?.step?.step_type !== STM.FILE_UPLOAD.id &&
                conversationMessage?.step?.step_type !== STM.CAROUSEL.id &&
                conversationMessage?.step?.step_type !== STM.CALENDAR.id &&
                conversationMessage?.step?.step_type !== STM.DATE_RANGE.id && (
                  <div
                    className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
                    style={
                      isLastreply && isEmpty(conversationMessage?.post_messages)
                        ? { marginBottom: 80 }
                        : { marginBottom: 25 }
                    }
                  >
                    <div className="flex justify-end">
                      <div
                        className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-automation"
                        style={{
                          borderRadius: 10,
                          paddingTop: 6,
                          paddingBottom: 6,
                          paddingLeft: 15,
                          paddingRight: 15
                        }}
                      >
                        {conversationMessage?.text}
                      </div>
                    </div>
                  </div>
                )}
            </>
          )}
          <div
            className="chat-message-list chat-message-list--sm flex"
            style={isLastreply ? { marginBottom: 80 } : { marginBottom: 25 }}
            id={data.id}
          >
            {!isEmpty(conversationMessage?.post_messages) && (
              <figure
                className="general-settings__card-list__avatar-container"
                style={{ maxHeight: 32, maxWidth: 32, marginRight: 6 }}
              >
                <img
                  src={avatarUrl ? avatarUrl : avatar}
                  alt="profile-pic"
                  loading="lazy"
                  style={{ objectFit: 'cover', height: '100%', width: '100%' }}
                />
              </figure>
            )}
            {!isEmpty(conversationMessage?.post_messages) && (
              <div className="flex justify-end">
                <div
                  className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-grey-90"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15
                  }}
                  dangerouslySetInnerHTML={{
                    __html: conversationMessage?.post_messages
                  }}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default EbotContainer;

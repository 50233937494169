import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import NameItem from '../nameItem';
import EmailItem from '../emailItem';
import PhoneItem from '../phoneItem';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSelector } from 'react-redux';
import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';

const ContactDetails = (props) => {
  const { avatarName, name, email, phone, src, isBin, isSms } = props;
  const { isLoading } = useSelector((state) => state.question);

  return (
    <div className="bg-grey-300 border border-grey-400 border-radius-14 relative">
      {isLoading && <OverLayLoader />}
      <div className="flex gap-6 p-6">
        {isSms ? (
          <IconMyProfile width={70} height={70} />
        ) : (
          <Avatar
            src={src}
            initial={avatarName}
            initialStyle={{ fontSize: '30px' }}
            bigger
            singleChar
            cover
          />
        )}
        <div className="width-fill-available">
          <NameItem name={name} isBin={isBin} />
          <EmailItem email={email} isBin={isBin} />
          <PhoneItem phone={phone ? phone : ''} isBin={isBin} />
        </div>
      </div>
    </div>
  );
};

ContactDetails.propTypes = {
  avatarName: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string
};

export default ContactDetails;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ConversationHeader from 'modules/enquirybox/ebox/module/box/conversation/header';
import CustomToolTip from 'components/CustomTooltip';
import Avatar from 'components/avatar';
import EmailBody from '../../email/new/index';
import SmsMessageContainer from '../component/smsMessageContainer';
import choices from 'choices';
import { isEmpty, timeFormatter } from 'utils/utils';
import { Navigate, useLocation, useParams } from 'react-router-dom';
import {
  getLeadConversation,
  getLeadData,
  setActiveTab,
  setFetchConversation
} from 'slices/conversationSlice';
import {
  getAllWebformMappingFields,
  getAllWebformMappingQuestions,
  getWebformMappingQuestions
} from 'slices/webformMappingSlice';
import { getLeadLabel } from 'slices/labelsSlice';
import { Loader } from 'components/loader';
import ZapierContainer from '../../components/zapierContainer';
import ChatFooterInputBox from '../../footer/chatFooter/main';
import Actions from 'modules/enquirybox/ebox/module/box/conversation/actions/conversationActions';
import ImageModalContainer from 'modules/modals/ImageModal';
import {
  setEditorActiveTab,
  setShowContactModal,
  setShowInboxActions
  // changeLeadStatus
} from 'slices/eboxLead';
import { animateScroll } from 'react-scroll';
import queryString from 'query-string';
import {
  setAssignUser,
  setEditableTaskId,
  setTaskDate,
  setTaskDateType,
  setTaskTime,
  setTaskTitle
} from 'slices/task/slice';
import { setEditedNote, setIsEditText } from 'slices/noteSlice';
import { REPLY, TOMORROW } from 'utils/constants';
import { handleScroll } from 'modules/enquirybox/helper/scrollConversation';
import NoteContainer from '../../components/noteContainer';
import { route } from 'utils/route';

const EMAIL_REPLY = choices.LeadReplyChoice.EMAIL_REPLY;
const JUST_TO_STORE_DATA = choices.LeadReplyChoice.JUST_TO_STORE_DATA;
const NOTE_REPLY = choices.LeadReplyChoice.NOTE_REPLY;
const TASK_REPLY = choices.LeadReplyChoice.TASK_REPLY;
const SMS_REPLY = choices.LeadReplyChoice.SMS_REPLY;
const MOVED_TO_BIN = choices.LeadStatusChoices.MOVED_TO_BIN;
const NORMAL_REPLY = choices.LeadReplyChoice.NORMAL_REPLY;

const ZapierConversation = (props) => {
  const { isBin } = props;
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const dispatch = useDispatch();
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const messageRef = useRef();
  const [isLoading, setIsLoading] = useState(true);
  const [isFieldsLoading, setIsFieldsLoading] = useState(true);
  const { activeLead, conversation, fetchConversation, showCardNotFound } =
    useSelector((state) => state.leadConversation);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { showInboxActions } = useSelector((state) => state.leads);
  const { showImagePreview } = useSelector((state) => state.app);
  const { editorChannel } = useSelector((state) => state.editor);

  useEffect(() => {
    if (queryParams && queryParams?.activeTab === 'task') {
      dispatch(setShowContactModal(true));
      dispatch(setActiveTab(TASK));
    }
  }, [queryParams]);

  const loadLeadConversation = () => {
    setIsLoading(true);
    setIsFieldsLoading(true);
    dispatch(getLeadConversation({ leadId: cardId }, callback));
  };

  const resetReplyStore = () => {
    dispatch(setShowInboxActions(true));
    dispatch(setEditorActiveTab(REPLY));
    dispatch(setTaskDateType(TOMORROW));
    dispatch(setTaskDate(null));
    dispatch(setTaskTime('9:00am'));
    dispatch(setAssignUser({}));
    dispatch(setTaskTitle(''));
    dispatch(setEditableTaskId(false));
    dispatch(setEditedNote(null));
    dispatch(setIsEditText(''));
  };

  const loadAllQuestions = () => {
    dispatch(
      getWebformMappingQuestions(() => {
        setIsLoading(false);
      })
    );
    dispatch(
      getAllWebformMappingQuestions(() => {
        setIsLoading(false);
      })
    );
  };

  const loadLabelCallback = () => {
    setIsLoading(false);
    setIsFieldsLoading(false);
  };

  const loadAllLabels = (subOrgId) => {
    dispatch(getLeadLabel({ subOrgId: subOrgId, callback: loadLabelCallback }));
  };

  const callback = (data) => {
    loadAllQuestions();
    if (activeLead) {
      loadAllFields(data);
    }
    loadAllLabels(subOrganizationId);
  };

  const loadFieldsCallback = () => {
    setIsFieldsLoading(false);
  };

  const loadAllFields = (data) => {
    if (data?.results[0]?.form?.id) {
      dispatch(
        getAllWebformMappingFields(
          { formId: data?.results[0]?.form?.id },
          loadFieldsCallback
        )
      );
    }
  };

  useEffect(() => {
    loadLeadConversation();
    resetReplyStore();
  }, []);

  // const scrollToBottom = () => {
  //   const temp = messageRef.current;
  //   if (temp) {
  //     temp.scrollTop = temp.scrollHeight;
  //   }
  // };

  // useEffect(() => {
  //   if (!isLoading && !isFieldsLoading) {
  //     setTimeout(() => scrollToBottom(), 0);
  //   }
  // }, [cardId, isLoading, isFieldsLoading]);

  const scrollDown = () => {
    animateScroll.scrollToBottom({
      containerId: 'Conversation',
      smooth: true,
      duration: 0
    });
  };

  useEffect(() => {
    if (!showInboxActions) {
      scrollDown();
    }
  }, [showInboxActions]);

  const loadConversationCallback = () => {
    scrollDown();
  };

  useEffect(() => {
    if (fetchConversation) {
      dispatch(
        getLeadConversation({ leadId: cardId }, () => {
          setTimeout(() => {
            handleScroll(conversation);
          }, 300);
          dispatch(setFetchConversation(false));
        })
      );
    }
    return () => {
      dispatch(setFetchConversation(false));
    };
  }, [fetchConversation]);

  const onReplySentSuccess = () => {
    dispatch(getLeadConversation({ leadId: cardId }, loadConversationCallback));
    dispatch(getLeadData({ leadId: cardId }));
  };

  const onClose = () => {
    dispatch(setShowInboxActions(true));
  };

  useEffect(() => {
    if (!isLoading && !isFieldsLoading) {
      setTimeout(() => {
        handleScroll(conversation);
      }, 300);
    }
  }, [isLoading, isFieldsLoading]);

  if (isLoading || isFieldsLoading) return <Loader />;

  const showMoreActions = conversation?.results?.some(
    (item) => item.is_reply_sent === true
  );

  if (showCardNotFound) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-not-found`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-not-found`}
      />
    );
  }

  if (!isEmpty(activeLead) && activeLead?.status === MOVED_TO_BIN && !isBin) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-moved-to-bin`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-moved-to-bin`}
      />
    );
  }

  return (
    <>
      <ConversationHeader activeLead={activeLead} isBin={isBin} />
      <div
        className="chat-message-body"
        style={{ padding: 25 }}
        id="Conversation"
        ref={messageRef}
      >
        {!isEmpty(conversation) &&
          !isEmpty(conversation.results) &&
          conversation.results.map((item, idx) => {
            if (item.reply_type === NORMAL_REPLY)
              return (
                <div
                  key={item.id}
                  className="chat-message-list flex"
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                >
                  <CustomToolTip
                    style={{ height: '35px' }}
                    position="right"
                    tooltipText={
                      item?.sent_by_automation
                        ? 'Automated Message'
                        : item?.lead_name || item?.lead?.first_name
                    }
                    // renderAvatar={() => (
                    //   <Avatar
                    //     initial={item?.lead_name}
                    //     smaller
                    //     singleChar
                    //     contain
                    //     border={false}
                    //   />
                    // )}
                  >
                    <Avatar
                      initial={
                        item?.sent_by_automation
                          ? 'A M'
                          : item?.lead.name || item?.lead?.first_name
                      }
                      small
                      singleChar={!item?.sent_by_automation}
                      contain
                      border={false}
                      style={{
                        background: item?.sent_by_automation ? '#53BAC0' : ''
                      }}
                    />
                  </CustomToolTip>
                  <ZapierContainer
                    onClick={() => setShowMapFields(true)}
                    time={item?.created_at}
                    isFieldsMapped={item?.form?.is_mapped}
                    data={item}
                    // isCollapse={idx !== conversation?.results.length - 1}
                    isCollapse={false}
                    isBin={isBin}
                  />
                </div>
              );
            if (EMAIL_REPLY == item.reply_type)
              return (
                <div
                  key={item.id}
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                >
                  <EmailBody
                    id={item.id}
                    data={item}
                    // isCollapse={idx !== conversation?.results.length - 1}
                    isCollapse={false}
                  />
                </div>
              );
            if (NOTE_REPLY === item.reply_type)
              return (
                // <div key={item.id} id={item.id}></div>
                <div
                  key={item.id}
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                >
                  <NoteContainer
                    item={item}
                    created_at={timeFormatter(item.created_at)}
                    data={
                      item.note || {
                        sent_by_automation: item?.sent_by_automation,
                        description: item?.message,
                        updated_at: item?.updated_at
                      }
                    }
                    createdBy={
                      item.created_by || { first_name: item?.lead_name } || {
                        first_name: item?.lead?.first_name
                      }
                    }
                    isBin={isBin}
                    idx={idx}
                  />
                </div>
              );
            if (TASK_REPLY === item.reply_type)
              return (
                <div key={item.id} id={item.id}></div>
                // <div
                //   key={item.id}
                //   style={{
                //     marginBottom:
                //       showInboxActions &&
                //       idx === conversation?.results.length - 1
                //         ? '80px'
                //         : '25px'
                //   }}
                // >
                //   <TaskContainer
                //     created_at={timeFormatter(item.created_at)}
                //     data={item.task}
                //     createdBy={item.created_by}
                //     isBin={isBin}
                //     item={item}
                //   />
                // </div>
              );
            if (SMS_REPLY === item.reply_type)
              return (
                <SmsMessageContainer
                  key={item.id}
                  item={item}
                  isReplyRecieved={item.is_reply_received}
                  isReplySent={item.is_reply_sent}
                  index={idx}
                  conversation={conversation.results}
                  showInboxActions={showInboxActions}
                  id={item.id}
                />
              );
            return (
              <div
                key={item.id}
                id={item.id}
                style={{
                  marginBottom:
                    showInboxActions && idx === conversation?.results.length - 1
                      ? '80px'
                      : '25px'
                }}
              />
            );
          })}
      </div>
      {showInboxActions && !isBin && (
        <Actions
          onOpenEditor={() => dispatch(setShowInboxActions(false))}
          showMoreActions={showMoreActions}
          // onBinIt={() => setShowDelete(true)}
          isBox={isBox}
        />
      )}
      {!showInboxActions && !isBin && (
        <ChatFooterInputBox
          onReplySentSuccess={onReplySentSuccess}
          cardId={cardId}
          onClose={onClose}
          hideAttachment={editorChannel === 'Sms' ? true : false}
          hideImage={editorChannel === 'Sms' ? true : false}
        />
      )}
      <ImageModalContainer open={showImagePreview} />
    </>
  );
};

export default ZapierConversation;

import TextInputField from 'components/inputs/textField';
import React, { useEffect, useRef, useState } from 'react';
import SaveAndcancel from './SaveAndcancel';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import {
  updateFbPixelId,
  updateGA4
} from 'slices/ai-assistant/deploy/websiteLinks/tracking/slice';
import { useParams } from 'react-router-dom';
import ErrorMessage from 'components/errorMessage';
import { useSnackbar } from 'components/Snackbar';

const InputFiled = (props) => {
  const { placeholder, name } = props;

  const { ga4_code } = useSelector((state) => state.analytics.googleAnalytics);
  const { fb_key } = useSelector((state) => state.analytics.fbPixelId);
  const [showCancelAndSave, setShowAndCancelAndSave] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [gaValue, setGAValue] = useState('');
  const [fbPixelId, setFbPixelId] = useState('');
  const [error, setError] = useState({});
  const nodeRef = useRef(null);
  const { assistant_id } = useParams();
  const inputRef = useRef(null);

  const handleCancel = () => {
    setShowAndCancelAndSave(false);
    setError({});
  };

  useClickOutside(handleCancel, nodeRef.current);
  const dispatch = useDispatch();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const successCb = () => {
    setIsLoading(false);
    openSuccessSnackBar('Saved');
    setShowAndCancelAndSave(false);
  };

  const failedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('Failed');
  };

  const GA4_Save = () => {
    if (gaValue !== '' && gaValue !== null) {
      if (gaValue !== ga4_code) {
        setIsLoading(true);
        const payload = {
          botId: assistant_id,
          payload: {
            has_ga4: true,
            ga4_code: gaValue,
            events_ga4: ['3']
          }
        };
        dispatch(updateGA4(payload, successCb, failedCb));
      } else {
        inputRef.current.focus();
        setError({
          ...error,
          ga4Error: 'New Value and Old value should not be same'
        });
      }
    } else {
      inputRef.current.focus();
      setError({ ...error, ga4Error: 'This filed is required' });
    }
  };

  const FbPixelSave = () => {
    if (fbPixelId !== '' && fbPixelId !== null) {
      if (fbPixelId !== fb_key) {
        setIsLoading(true);
        const data = {
          botId: assistant_id,
          payload: {
            fb_key: fbPixelId,
            has_fb: true,
            fb_events: ['3']
          }
        };
        dispatch(updateFbPixelId(data, successCb, failedCb));
      } else {
        inputRef.current.focus();
        setError({
          ...error,
          fbPixelIdError: 'New ID and old ID should not be same'
        });
      }
    } else {
      inputRef.current.focus();
      setError({ ...error, fbPixelIdError: 'This field is required' });
    }
  };

  const handleChange = (e) => {
    if (name === 'google_analytics') {
      setGAValue(e.target.value);
      if (e.target.value !== '') setError({ ...error, ga4Error: '' });
      else setError({ ...error, ga4Error: 'This filed is required' });
    } else {
      setFbPixelId(e.target.value);
      if (e.target.value !== '') setError({ ...error, fbPixelIdError: '' });
      else setError({ ...error, fbPixelIdError: 'This filed is required' });
    }
  };

  useEffect(() => {
    if (ga4_code !== null && ga4_code !== undefined) setGAValue(ga4_code);
    if (fb_key !== null && fb_key !== undefined) setFbPixelId(fb_key);
  }, [ga4_code, fb_key, showCancelAndSave]);

  return (
    <div className="mt-3 relative" ref={nodeRef}>
      <TextInputField
        autoComplete={'off'}
        name={name}
        className="input-field input-field--border2 text-15 input-field--h42"
        style={{ color: '#4C5254', paddingRight: 160 }}
        placeholder={placeholder}
        value={name === 'google_analytics' ? gaValue : fbPixelId}
        onClick={() => setShowAndCancelAndSave(true)}
        onChange={handleChange}
        error={error.ga4Error ? true : error.fbPixelIdError ? true : false}
        ref={inputRef}
      />
      {showCancelAndSave && (
        <SaveAndcancel
          handleCancel={handleCancel}
          handleSave={name === 'google_analytics' ? GA4_Save : FbPixelSave}
          isLoading={isLoading}
        />
      )}
      {error.ga4Error && (
        <ErrorMessage className="absolute" message={error.ga4Error} />
      )}
      {error.fbPixelIdError && (
        <ErrorMessage className="absolute" message={error.fbPixelIdError} />
      )}
    </div>
  );
};

export default InputFiled;

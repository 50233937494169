import React from 'react';
// import iconAutomationthen from 'assets/icons/icon-automation-then-icon.svg';
// import QuillMentions from 'components/quill/mentions';
// import Quill from 'components/quill';
// import ReplyEmail from 'modules/enquirybox/ebox/module/box/conversation/footer/replyEmail';
// import ChatFooterInputBox from 'modules/enquirybox/ebox/module/box/conversation/footer/chatFooter/main';
// import SendEmail from '../templates/sendEmail';
import SendEmailEditor from '../components/replayEmail';
import { useSelector } from 'react-redux';
import choices from 'choices';
import Select from 'components/inputs/select';
import Avatar from 'components/avatar';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
// import TextInputField from 'components/inputs/textField';
import { getChannelIcon } from 'utils/channels';
import { animateScroll } from 'react-scroll';

const AUTORESPONDER_EMAIL = choices.AutomationChoice.AUTORESPONDER_EMAIL;
const ASSIGN_MEMBER = choices.AutomationChoice.ASSIGN_MEMBER;
const FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER =
  choices.AutomationChoice.FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER;
const MOVE_TO_BOX = choices.AutomationChoice.MOVE_TO_BOX;
const AUTORESPONDER_MESSAGE = choices.AutomationChoice.SMS;

const { WEBFORM, FACEBOOK_LEAD_ADS, FACEBOOK, SMS, INSTAGRAM, EBOT, ZAPIER } =
  choices.ChannelChoices;

const AutomationStuffThen = ({
  setSelectedOption,
  options,
  assignedUserInfo,
  channelType,
  setLength,
  length,
  setSmsCount,
  setSelectedStage,
  selectedStage,
  box_stages,
  title,
  tempChannelType,
  changeAutomationName,
  automationName
}) => {
  // const [openFirstThen, setOpenFirstThen] = useState(false);
  const { typeofAutomation, isPreview } = useSelector(
    (state) => state.automationStuff
  );

  const onChangeUser = (value) => {
    if (automationName.includes('{{team_member}}')) {
      changeAutomationName(
        automationName.replace('{{team_member}}', value.label)
      );
    } else {
      changeAutomationName(
        automationName.replace(assignedUserInfo.label, value.label)
      );
    }
  };

  const onChangeBox = (value) => {
    if (automationName.includes('{{box_name}}')) {
      changeAutomationName(automationName.replace('{{box_name}}', value.label));
    } else {
      changeAutomationName(
        automationName.replace(assignedUserInfo.label, value.label)
      );
    }
  };

  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'automationContainer', // id
        smooth: true,
        duration: 0
      });
    }, 100);
  };

  return (
    <div id="thenId">
      <div className="box-settings__automation-template__automation-stuff px-5 pt-5 pb-6 mb-3">
        <div className="flex justify-between">
          <div className="flex">
            <figure
              className="border-radius-10 flex justify-center items-center bg-white"
              style={{
                border: '1px solid rgba(0, 0, 0, 0.05)',
                height: 50,
                width: 52
              }}
            >
              <img
                src={getChannelIcon(typeofAutomation)}
                alt=""
                // style={{ width: 52, height: 50 }}
              />
            </figure>
            <div className="ml-4">
              <h4 className="font-semibold">{title}</h4>
              <p className="text-sm text-grey-800">
                {(typeofAutomation === AUTORESPONDER_EMAIL &&
                  channelType === WEBFORM) ||
                channelType === FACEBOOK_LEAD_ADS ||
                channelType === EBOT ||
                channelType === ZAPIER
                  ? 'Send an email'
                  : AUTORESPONDER_EMAIL &&
                    (channelType === FACEBOOK ||
                      channelType === SMS ||
                      channelType === INSTAGRAM)
                  ? 'Send this automated reply when an enquiry comes in'
                  : typeofAutomation === ASSIGN_MEMBER
                  ? 'Assign the enquiry to this team member automatically'
                  : typeofAutomation === MOVE_TO_BOX &&
                    'Move the enquiry to this box when it comes in automatically'}
              </p>
            </div>
          </div>
        </div>
        {/* {channelType === SMS && (
          <TextInputField
            name="then"
            placeholder=""
            className="input-field--md mt-4"
            value="Send an sms"
            readOnly={true}
          />
        )} */}
        {typeofAutomation === AUTORESPONDER_EMAIL ||
        typeofAutomation === FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER ||
        typeofAutomation === AUTORESPONDER_MESSAGE ? (
          <div className="mt-3">
            <SendEmailEditor
              channelType={channelType}
              setLength={setLength}
              length={length}
              setSmsCount={setSmsCount}
              tempChannelType={tempChannelType}
            />
          </div>
        ) : (
          <div className="relative mt-4">
            <form autoComplete="off">
              <div onClick={scrollDown}>
                <Select
                  name="automation-then"
                  options={options}
                  renderIcon={() => (
                    <Avatar
                      className="mr-2"
                      src={assignedUserInfo?.avatarPic}
                      border={false}
                      initial={assignedUserInfo?.label}
                      initialStyle={{ fontSize: 'smaller' }}
                      smaller={typeofAutomation === MOVE_TO_BOX ? false : true}
                      smallest={typeofAutomation === MOVE_TO_BOX ? true : false}
                      full
                    />
                  )}
                  onChange={(value) => {
                    setSelectedOption(value);
                    handleScrollIntoview('automationContainer', 'end');
                    if (typeofAutomation === MOVE_TO_BOX) {
                      onChangeBox(value);
                      setSelectedStage(null);
                    }
                    if (typeofAutomation === ASSIGN_MEMBER) {
                      onChangeUser(value);
                    }
                  }}
                  dropDownClassName="p-2"
                  inputClassName="input-field--md pl-10"
                  style={
                    !isEmpty(assignedUserInfo) ? { paddingLeft: '55px' } : {}
                  }
                  avatarStyle={
                    typeofAutomation === MOVE_TO_BOX ? { width: '22px' } : {}
                  }
                  isAvatarSelect={true}
                  value={assignedUserInfo?.label}
                  selectedValue={assignedUserInfo}
                  isShowUserDetails={typeofAutomation !== MOVE_TO_BOX}
                  isSelectDisabled={isPreview}
                  disabled={isPreview}
                />
              </div>
              {assignedUserInfo && typeofAutomation === MOVE_TO_BOX && (
                <div className="mt-4" onClick={scrollDown}>
                  <Select
                    placeholder="Select the stage to move to"
                    name="automation-then"
                    options={box_stages}
                    onChange={(value) => setSelectedStage(value)}
                    dropDownClassName="p-2"
                    inputClassName="input-field--md"
                    value={selectedStage ? selectedStage?.label : ''}
                    selectedValue={selectedStage}
                    isShowUserDetails={typeofAutomation !== MOVE_TO_BOX}
                    labelDividerText="Select stage"
                    isSearchInsideDropDown={false}
                    isSelectDisabled={isPreview}
                    disabled={isPreview}
                  />
                </div>
              )}
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationStuffThen;

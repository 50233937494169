/* eslint-disable react-hooks/exhaustive-deps */
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
// import AssigneTeamMember from './assigneTeamMember';
import SendEmail from './sendEmail';
import Toggle from 'components/inputs/toggle';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  sendAutomation,
  setAvaliableAutoamtions,
  setOnEdit,
  setTypeofAutomation
} from 'slices/automationStuffSlice';
import { useParams } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import EmailAutomation from '../../automationStuff/templates/sendEmail/emailAutomationSetup';
import AssigneTeamMember from './assigneTeamMember';
import choices from 'choices';
import AssigneToteamSetup from '../../automationStuff/templates/assigneTeamMember/assigneToteamSetup';
import MoveEnquiriesToBoxSetup from '../../automationStuff/templates/moveEnquiries/moveEnquiriesSetup';
import HeaderItem from 'modules/enquirybox/channels/components/layout/header';
import { Loader } from 'components/loader';
import MoveEnquiriesToBox from './moveEnquiries';

const AUTORESPONDER_EMAIL = choices.AutomationChoice.AUTORESPONDER_EMAIL;
const AUTORESPONDER_MESSAGE = choices.AutomationChoice.SMS;
const ASSIGN_MEMBER = choices.AutomationChoice.ASSIGN_MEMBER;
const MOVE_TO_BOX = choices.AutomationChoice.MOVE_TO_BOX;
const FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER =
  choices.AutomationChoice.FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER;
const { WEBFORM, FACEBOOK, INSTAGRAM, FACEBOOK_LEAD_ADS, SMS, EBOT, ZAPIER } =
  choices.ChannelChoices;

const AutomationTemplates = ({ channelType }) => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { channelId } = useParams();
  const [setupType, setSetupType] = useState('');
  const { register } = useForm({
    mode: 'onChange'
  });
  const [isLoading, setIsLoading] = useState(false);

  const { availableAutomations } = useSelector(
    (state) => state.automationStuff
  );
  const { typeofAutomation } = useSelector((state) => state.automationStuff);

  useEffect(() => {
    dispatch(setTypeofAutomation(''));
  }, [channelId]);

  const callback = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (!typeofAutomation) {
      const payload = {
        type: 'get',
        data: {
          channelId: channelId
        }
      };
      dispatch(sendAutomation(payload, callback));
    }
  }, [typeofAutomation, channelId]);

  const handleAutomationStatus = (evt, selectedItem) => {
    const payload = { id: selectedItem.id, is_active: evt.target.checked };
    dispatch(sendAutomation({ type: 'put', data: payload }));
    if (!isEmpty(availableAutomations)) {
      const updatedavAilableAutomations = availableAutomations.map(
        (item, index) => {
          if (item?.id === selectedItem.id) {
            return { ...item, is_active: evt.target.checked };
          }
          return item;
        }
      );
      dispatch(setAvaliableAutoamtions([...updatedavAilableAutomations]));
    }
    if (evt.target.checked) {
      openSuccessSnackBar(`Automation turned on`, 'large');
    } else {
      openSuccessSnackBar(`Automation turned off`, 'large');
    }
  };
  const handleAutomationEdit = (item) => {
    dispatch(setTypeofAutomation(item?.automation_type));
    dispatch(setOnEdit(item));
  };
  return (
    <>
      {!typeofAutomation && (
        <div className="card-box px-9 py-6 mt-10">
          <div>
            <HeaderItem title="Automate stuff" />
            <div className="box-settings__automation-template">
              <p className="box-settings__automation-template__text text-sm mt-4 mb-4">
                Automatically do the following things when an enquiry comes into
                this channel
              </p>
              {!isEmpty(availableAutomations) && (
                <span className="text-xs text-gray-800 mt-4 mb-4">
                  Your automations
                </span>
              )}
              {!isEmpty(availableAutomations) &&
                !isLoading &&
                availableAutomations.map((item, index) => {
                  return (
                    <div
                      className={`box-settings__automation-template__automation-stuff mt-2 py-3 px-3 flex justify-between items-center`}
                      key={index}
                    >
                      <div className="font-semibold text-base">
                        {item.automation_name}
                      </div>
                      <div className="flex items-center">
                        <span
                          className="text-primary text-sm cursor-pointer"
                          onClick={() => handleAutomationEdit(item)}
                        >
                          Edit
                        </span>
                        <Toggle
                          id={item.id}
                          register={register}
                          key={index}
                          onChange={(evt) => handleAutomationStatus(evt, item)}
                          checked={item.is_active}
                        />
                      </div>
                    </div>
                  );
                })}
              {isLoading && <Loader />}

              <span className="text-xs text-gray-800 mt-6 ">Templates</span>
              {isEmpty(setupType) && (
                <>
                  <SendEmail
                    setSetupType={setSetupType}
                    setupType={setupType}
                    channelType={channelType}
                  />
                  {channelType !== SMS && (
                    <AssigneTeamMember
                      setSetupType={setSetupType}
                      setupType={setupType}
                      channelType={channelType}
                    />
                  )}
                  {(channelType !== SMS || channelType !== ZAPIER) && (
                    <MoveEnquiriesToBox
                      setSetupType={setSetupType}
                      setupType={setupType}
                      channelType={channelType}
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
      {(typeofAutomation === AUTORESPONDER_EMAIL ||
        typeofAutomation === FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER ||
        typeofAutomation === AUTORESPONDER_MESSAGE) &&
        (channelType === WEBFORM ||
          channelType === FACEBOOK ||
          channelType === INSTAGRAM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === SMS ||
          channelType === EBOT ||
          channelType === ZAPIER) && (
          <EmailAutomation channelType={channelType} />
        )}
      {typeofAutomation === ASSIGN_MEMBER && (
        <AssigneToteamSetup channelType={channelType} />
      )}
      {typeofAutomation === MOVE_TO_BOX && (
        <MoveEnquiriesToBoxSetup channelType={channelType} />
      )}
    </>
  );
};

export default AutomationTemplates;

export const backgroundColor = [
  '#607CFF',
  '#CA94FF',
  '#11A1AE',
  '#FBBE4D',
  '#2F57E6',
  '#FFCE56',
  '#A4D146',
  '#5D92B0',
  '#6558BB',
  '#595775',
  '#583E2E',
  '#A7414A',
  '#A37C27',
  '#888C46',
  '#F4874B',
  '#720017',
  '#0294A5',
  '#9199BE',
  '#8D2F23',
  '#714E3D',
  '#EE6C81',
  '#0878A4',
  '#107050',
  '#8D2D56',
  '#2B193E',
  '#52733B',
  '#328DAA',
  '#D57030',
  '#2A3132',
  '#693D3D'
];

export const hoverBackgroundColor = [...backgroundColor];

export const alphaColors = [
  '#A0E7E5',
  '#FBE7C6',
  '#FFAEBC',
  '#F8AFA6',
  '#94C973',
  '#C0C0C0',
  '#FFCD58',
  '#DCBAA9',
  '#97BCC7',
  '#7EC8E3'
];

export const getColorShade = (idx) => {
  const max = 10;
  const index = Math.round(idx % max);
  return alphaColors[index];
};

export const lightenDarkenColor = (col, amt) => {
  var usePound = false;
  if (col[0] === '#') {
    col = col.slice(1);
    usePound = true;
  }
  var num = parseInt(col, 16);
  var r = (num >> 16) + amt;
  if (r > 255) r = 255;
  else if (r < 0) r = 0;
  var b = ((num >> 8) & 0x00ff) + amt;
  if (b > 255) b = 255;
  else if (b < 0) b = 0;
  var g = (num & 0x0000ff) + amt;
  if (g > 255) g = 255;
  else if (g < 0) g = 0;
  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16);
};

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import InputField from 'components/inputs/textField';
import { ReactComponent as IconUser } from 'assets/icons/icon-user-round-grey.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';
import CustomColorButton from 'components/buttons/customColorButton';
import { ReactComponent as Email } from 'assets/icons/Icon-Email-22.svg';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import { SEND_EMAIL_VERIFICATION, UPDATE_SENDER_NAME } from 'urls/emailAddress';
import APIRequest from 'apiRequest';
import { useSnackbar } from 'components/Snackbar';
import { getEmailVerificationProvider } from 'slices/emailAddress/slice';

const ConnectEmail = ({
  onCancel,
  setIsLoading,
  isConnect,
  setIsConnect,
  setIsSetup
}) => {
  const { email_provider } = useSelector((state) => state.emailAddress);
  const [error, setError] = useState({});
  const [userName, setUserName] = useState(
    email_provider?.sender_name || email_provider?.user?.name || ''
  );
  const { openSuccessSnackBar } = useSnackbar();
  const dispatch = useDispatch();

  const onSubmit = () => {
    if (isEmpty(userName)) {
      setError({ name: 'Please provide name' });
      return;
    }
    if (!email_provider?.is_ses_verified_for_sending) {
      sendVerification({
        sender_name: userName
      });
    } else {
      onSave({
        sender_name: userName
      });
    }
  };

  const sendVerification = (formData) => {
    setIsLoading(true);
    new APIRequest().post(SEND_EMAIL_VERIFICATION, formData).then((res) => {
      openSuccessSnackBar('Verifcation email sent', 'large');
      dispatch(getEmailVerificationProvider());
      setIsLoading(false);
      onCancelClick();
    });
  };

  const onCancelClick = () => {
    setIsConnect(false);
    onCancel();
  };

  const onUserNameChange = (e) => {
    setUserName(e.target.value);
    if (isEmpty(e.target.value)) {
      setError({ name: 'Please provide name' });
    } else {
      setError({});
    }
  };

  const onSave = (formData) => {
    setIsLoading(true);
    new APIRequest().post(UPDATE_SENDER_NAME, formData).then((res) => {
      openSuccessSnackBar('Updated Successfully');
      setIsLoading(false);
      onCancelClick();
    });
  };

  return (
    <div
      className="list-hover__container"
      style={{
        padding: '1.25rem',
        background: '#FAF9F8',
        borderBottomLeftRadius: 7,
        borderBottomRightRadius: 7
      }}
    >
      <figure className="general-settings__card-list__avatar-container p-2">
        <Email width={22} />
      </figure>
      <div className="ml-4">
        <h4 className="text-base-1 font-semibold">
          Connect your own email address
        </h4>
        {isConnect && (
          <p className="text-sm text-grey-700 mt-2">
            <span className="font-medium text-black">
              Add the name you would like the customer to see when they receive
              an email from you.
            </span>
            For example (James from Business Name) or alternatively just your
            first and last name
          </p>
        )}
        {!isConnect && (
          <PrimaryButton
            className="btn--xs mt-3"
            label="Connect"
            style={{ paddingLeft: 18, paddingRight: 18 }}
            onClick={() => setIsConnect(true)}
          />
        )}
        {isConnect && (
          <>
            <div style={{ width: '320px' }}>
              <div className="mt-3">
                <InputField
                  id="email-provider-name"
                  name="name"
                  placeholder="Your name"
                  renderIcon={() => <IconUser />}
                  icon={true}
                  value={userName}
                  error={error.name}
                  onChange={onUserNameChange}
                >
                  {error.name && <Error message={error.name} />}
                </InputField>
              </div>
              <div className="mt-3">
                <InputField
                  id="email-provider-email"
                  name="email"
                  renderIcon={() => <EmailIcon width={14} />}
                  placeholder="Email"
                  icon={true}
                  value={email_provider?.user?.email}
                  disabled
                />
              </div>

              <div className="flex mt-5">
                <CustomColorButton
                  label="Cancel"
                  className="btn--wide-md-40 btn--cancel text-base-1 mr-3 cursor-pointer"
                  onClick={onCancelClick}
                />
                {!email_provider?.is_ses_verified_for_sending ? (
                  <PrimaryButton
                    // className="mt-3"
                    label={
                      email_provider?.is_data_submitted
                        ? 'Resend verification'
                        : 'Send Verification'
                    }
                    // style={{ paddingLeft: 18, paddingRight: 18 }}
                    onClick={onSubmit}
                  />
                ) : (
                  <PrimaryButton
                    label={'Save'}
                    // style={{ paddingLeft: 18, paddingRight: 18 }}
                    onClick={onSubmit}
                  />
                )}
              </div>
            </div>
            {!email_provider?.is_ses_verified_for_sending && (
              <div
                className="p-5 px-6 mt-5"
                style={{
                  background: '#F8EFD7',
                  borderRadius: 10
                }}
              >
                <p className="text-sm text-black">
                  We will send you a verification email. In the email there is a
                  verification link that you need to click. Once you click it,
                  you are verified and you replies will now be sent from that
                  email address.
                </p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ConnectEmail;

export const AUTH_TOKEN = 'authToken';
export const GMAIL = 'gmail';
export const MICROSOFT = 'microsoft';
export const EMAIL = 'email';
export const ALL = 'all';
export const ENQUIRIES = 'enquiries';
export const ASSIGNED_TO = 'assignedTo';
export const REPLY = 'Reply';
export const NOTE = 'Note';
export const TASK = 'Task';
export const TOMORROW = 'Tomorrow';
export const MONDAY = 'Monday';
export const CUSTOM_DATE = 'Custom date';
export const INVITED_EMAIL = 'invitedEmail';
export const SOCIAL_AUTH_ERROR = 'socialAuthError';
export const USER_INVITATION_LINK = 'invitationLink';
export const SUB_ORG_ID = 'subOrgId';
export const BUSY = 'Busy';
export const AVAILABLE = 'Available';
export const CALL = 'Call';
export const FOLLOW_UP = 'Follow up';
export const OTHER = 'Other';
export const CONTACT = 'Contact';
export const HISTORY = 'History';
export const FILE = 'File';
export const WHOEVER__IS_AVAILABLE = 'Whoever is available';
export const EBOT_EMAIL = 'ebotEmail';
export const BOT_ID = 'botId';
export const DELETE_ACCOUNT = 'Delete_Account';
export const CANCEL_ACCOUNT = 'Cancel_Account';
export const PAYMENT_INCOMPLETE = 'Payment Incomplete';
export const LOGOUT = 'Log_out';
export const ENQUIRYBOX_PLAN_TYPE = 'enquirybox_plan_type';
export const NOTHING = 'nothing';

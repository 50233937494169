import { isEmpty } from 'utils/utils';

const Message = ({ messages }) => {
  return (
    <>
      {!isEmpty(messages)
        ? messages.map((message, i) => {
            return (
              <div className="chat-bot-list__bubble" key={i}>
                {message}
              </div>
            );
          })
        : []}
    </>
  );
};

export default Message;

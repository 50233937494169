import ConfettiExplosion from 'react-confetti-explosion';

function Confetti(props) {
  return (
    <div className="flex items-center justify-center w-100">
      <ConfettiExplosion
        force={0.6}
        duration={2000}
        particleCount={150}
        width={1600}
        {...props}
      />
    </div>
  );
}

export default Confetti;

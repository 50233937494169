import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import enquiryConvertedSales from 'assets/icons/icon-converted-sales.svg';
import arrowUp from 'assets/icons/arrow-up-green.svg';
import arrowDown from 'assets/icons/icon-report-decrease.svg';
import { numberFormat } from 'utils/number';

const EnquiriesConvertedToSales = (props) => {
  const {
    total,
    totalPrice,
    percentage,
    isHidePercentage,
    selectedDay,
    hideCount
  } = props;
  return (
    <div
      className="w-full card-box-shadow p-5 mr-3 ml-3"
      style={{ width: '50%' }}
    >
      <CustomToolTip
        style={{ width: '100%' }}
        tooltipStyle={{
          width: 222,
          top: -97,
          bottom: 'initial',
          left: -25,
          transform: 'none',
          textAlign: 'left'
        }}
        position="top"
        tooltipText="The total number of enquiries that were converted from an enquiry into a sale."
      >
        <div className="flex items-center">
          <figure
            className="avatar-container avatar-container--contain bg-grey-300"
            style={{
              width: 38,
              height: 38,
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
          >
            <img src={enquiryConvertedSales} width={17} height={17} />
          </figure>

          <p className="letterspacing24em font-semibold ml-3">Total sales</p>
        </div>
      </CustomToolTip>
      <div className="flex items-center justify-between mt-5">
        <span className="text-28 font-bold letterspacing24em">
          {hideCount ? 0 : total}
          <span className="text-13 text-grey-800 font-normal">
            £{hideCount ? 0 : numberFormat(totalPrice, 2)}
            {/* £{totalPrice} */}
          </span>
        </span>
        {!isHidePercentage && (
          <div className="flex items-center">
            {percentage > 0 && <img src={arrowUp} />}
            {percentage < 0 && <img src={arrowDown} />}
            <span
              className={`text-13 font-semibold ${
                percentage >= 0 ? 'text-green-900' : 'text-red-900'
              } letterspacing24em`}
              style={{ marginLeft: 2 }}
            >
              {percentage && !hideCount
                ? parseFloat(percentage).toFixed(2).replace(/\.00$/, '')
                : 0}
              %
            </span>
            <span className="text-xs text-grey-800 ml-1 letterspacing24em">
              than{' '}
              {selectedDay === 'Last 7 days'
                ? 'last week'
                : selectedDay === 'This month to date'
                ? 'last month'
                : 'last date'}
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default EnquiriesConvertedToSales;

import React from 'react';
import PropTypes from 'prop-types';
import bookopen from 'assets/icons/book_open.svg';

const GuideLink = (props) => {
  const { url } = props;
  return (
    <div className="flex py-5 px-3 mb-5 bg-[#FCFBF8] text-[14px] rounded-xl">
      <img src={bookopen} alt="book open icon" />
      <div className="pl-2">
        Need help or have a question? Read our&nbsp;
        <span
          onClick={() => {
            window.open(url, '_blank').focus();
          }}
          className="text-[#005C6F] font-medium cursor-pointer"
        >
          step by step setup guide
        </span>
      </div>
    </div>
  );
};

GuideLink.propTypes = {
  url: PropTypes.string
};

GuideLink.defaultProps = {
  url: ''
};

export default GuideLink;

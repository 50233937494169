import React, { useState, useCallback, useEffect, useRef } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';

const pixelRatio = window.devicePixelRatio || 1;

const ImageCrop = ({
  src,
  onComplete,
  cropPositionX,
  cropPositionY,
  cropWidthInPercent,
  width,
  ...props
}) => {
  const [crop, setCrop] = useState({
    unit: '%',
    width: typeof cropWidthInPercent === 'number' ? cropWidthInPercent : 30,
    aspect: 10 / 10,
    x: typeof cropPositionX === 'number' ? cropPositionX : undefined,
    y: typeof cropPositionY === 'number' ? cropPositionY : undefined
  });
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  useEffect(() => {
    if (!completedCrop || !imgRef.current) {
      return;
    }

    const image = imgRef.current;
    const canvas = document.createElement('canvas');
    const crop = completedCrop;

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );
    onComplete(canvas);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [completedCrop]);

  return (
    <ReactCrop
      src={src}
      onImageLoaded={onLoad}
      crop={crop}
      onChange={(c) => setCrop(c)}
      onComplete={(c) => setCompletedCrop(c)}
      style={{ width: '80%' }}
      {...props}
    />
  );
};

export default ImageCrop;

import React from 'react';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import ButtonWithLogo from 'components/buttons/buttonWithLogo';
import GmailLogo from 'assets/icons/icon-gmail.svg';
import MicroSoftLogo from 'assets/icons/icon-microsoft.svg';
import InputField from 'components/inputs/textField';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import Back from 'components/back';
import Break from 'components/break/text';
import { ReactComponent as IconUser } from 'assets/icons/icon-user.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';

const PersonalInfo = () => {
  const formSchema = getFormSchema(['firstName', 'lastName', 'email']);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const onSubmit = () => {};

  return (
    <HeaderLayout>
      <CardLayout>
        <CardTitle title="Your personal info" />
        <div className="w-full mt-10">
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              name="firstName"
              placeholder="First Name"
              renderIcon={() => <IconUser />}
              icon={true}
              error={errors.firstName && touchedFields.firstName}
              register={register}
            >
              {errors.firstName && touchedFields.firstName && (
                <Error message={errors.firstName.message} />
              )}
            </InputField>
            <div className="mt-3">
              <InputField
                name="lastName"
                placeholder="Last Name"
                renderIcon={() => <IconUser />}
                icon={true}
                error={errors.lastName && touchedFields.lastName}
                register={register}
              >
                {errors.lastName && touchedFields.lastName && (
                  <Error message={errors.lastName.message} />
                )}
              </InputField>
            </div>
            <div className="mt-3">
              <InputField
                name="email"
                renderIcon={() => <EmailIcon />}
                placeholder="test@gmail.com"
                icon={true}
                error={errors.email && touchedFields.email}
                register={register}
              >
                {errors.email && touchedFields.email && (
                  <Error message={errors.email.message} />
                )}
              </InputField>
            </div>
            <div className="mt-8 mb-4">
              <button className="btn btn--grey w-full mt-6">
                Continue with email address
              </button>
            </div>
            <Break label="OR" />
            <div className="w-full mt-8">
              <ButtonWithLogo logo={GmailLogo} label="Sign up with Google" />
              <div className="mt-4">
                <ButtonWithLogo
                  logo={MicroSoftLogo}
                  label="Sign up with Microsoft"
                />
              </div>
              <PrimaryButton
                type="submit"
                className="w-full mt-6"
                label="Continue"
              />
              <Back className="mt-8 mx-auto" showIcon />
            </div>
          </form>
        </div>
      </CardLayout>
    </HeaderLayout>
  );
};

export default PersonalInfo;

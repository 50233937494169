/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import PropTypes from 'prop-types';
import iconReminderInActive from 'assets/icons/icon-reminder.svg';
import { useDispatch } from 'react-redux';
import { getLeadConversation } from 'slices/conversationSlice';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
import TaskUser from './user';
import TaskComplete from './complete';
import TaskOptions from './options';
import {
  formatDateTime,
  prepareDateAndTime,
  isDateAndTimeOverdue
} from 'utils/dateFormat';
import choices from 'choices';
// import AvatarContainer from '../avatarContainer';
import CustomToolTip from 'components/CustomTooltip';
import Avatar from 'components/avatar';
import { ReactComponent as AutomateIcon } from 'assets/icons/icon-automated-email-icon.svg';

const COMPLETED = choices.TaskStatusChoices.COMPLETED;

const TaskContainer = (props) => {
  const { created_at, data, createdBy, isBin, item } = props;
  const { cardId } = useParams();
  const dispatch = useDispatch();

  const getLeadsConversation = () => {
    dispatch(getLeadConversation({ leadId: cardId }));
  };

  const getDueDateTime = (due_date) => {
    const { date, time } = prepareDateAndTime(due_date, { minute: false });
    return `${formatDateTime(date, 'mmm dd')} at ${time}`;
  };

  const CompletedTask = ({ data }) => {
    return (
      <>
        <div className="flex justify-end gap-1">
          <div
            className="task-container flex items-center py-2 pr-2 pl-4"
            style={{
              maxWidth: '80%',
              fontSize: '15px'
            }}
          >
            <p className="truncate">Task completed 👍</p>
          </div>
          <CustomToolTip
            position="left"
            tooltipText={
              item?.sent_by_automation
                ? 'Automated Task'
                : createdBy?.first_name
            }
            // renderAvatar={() => (
            //   <Avatar
            //     src={
            //       user?.avatar
            //         ? user.avatar
            //         : user?.customer_avatar
            //         ? user?.customer_avatar
            //         : null
            //     }
            //     initial={user?.first_name}
            //     border={false}
            //     smallest
            //     cover
            //     singleChar
            //     initialStyle={{
            //       fontSize: '14px'
            //     }}
            //   />
            // )}
          >
            <Avatar
              src={
                createdBy?.avatar
                  ? createdBy.avatar
                  : createdBy?.customer_avatar
                  ? createdBy?.customer_avatar
                  : null
              }
              initial={item?.sent_by_automation ? 'A T' : createdBy?.first_name}
              border={false}
              cover
              small
              singleChar
            />
          </CustomToolTip>
          {/* <AvatarContainer user={createdBy} /> */}
        </div>
      </>
    );
  };

  if (!isEmpty(data) && data.status === COMPLETED) {
    return <CompletedTask data={data} />;
  }

  return (
    <>
      {!isEmpty(data) && (
        <>
          <div className="flex justify-end gap-1">
            <div
              className="task-container py-2 pr-2 pl-4"
              style={{
                maxWidth: '80%'
              }}
            >
              <div className="flex justify-between item-center mb-2">
                <p style={{ fontSize: '15px', overflowWrap: 'anywhere' }}>
                  {data.note}
                </p>
                <TaskOptions
                  data={data}
                  getLeadsConversation={getLeadsConversation}
                  isBin={isBin}
                />
              </div>
              <div
                className="flex justify-between items-end"
                style={{
                  minWidth: '430px'
                }}
              >
                <div className="flex justify-start items-center">
                  <div className="mr-2">
                    <TaskUser
                      data={data}
                      cardId={cardId}
                      getLeadsConversation={getLeadsConversation}
                      isBin={isBin}
                    />
                  </div>
                  <TaskComplete
                    data={data}
                    getLeadsConversation={getLeadsConversation}
                    isBin={isBin}
                  />
                </div>
                <div>
                  {isDateAndTimeOverdue(data.due_date) ? (
                    <p className="text-red-900 text-xs font-semibold ml-1 mr-1">
                      Overdue
                    </p>
                  ) : (
                    <p className="text-xs text-grey-700 ml-1 mr-1">
                      {`Due ${getDueDateTime(data.due_date)}`}
                    </p>
                  )}
                </div>
              </div>
            </div>
            <CustomToolTip
              position="left"
              tooltipText={
                item?.sent_by_automation
                  ? 'Automated Task'
                  : createdBy?.first_name
              }
              // renderAvatar={() => (
              //   <Avatar
              //     src={
              //       user?.avatar
              //         ? user.avatar
              //         : user?.customer_avatar
              //         ? user?.customer_avatar
              //         : null
              //     }
              //     initial={user?.first_name}
              //     border={false}
              //     smallest
              //     cover
              //     singleChar
              //     initialStyle={{
              //       fontSize: '14px'
              //     }}
              //   />
              // )}
            >
              <Avatar
                src={
                  createdBy?.avatar
                    ? createdBy.avatar
                    : createdBy?.customer_avatar
                    ? createdBy?.customer_avatar
                    : null
                }
                initial={
                  item?.sent_by_automation ? 'A T' : createdBy?.first_name
                }
                border={false}
                cover
                small
                style={{
                  backgroundColor: item?.sent_by_automation
                    ? 'rgb(83, 186, 192)'
                    : ''
                }}
                singleChar={!item?.sent_by_automation}
              />
            </CustomToolTip>
            {/* <AvatarContainer user={createdBy} /> */}
          </div>
          {item?.sent_by_automation && (
            <div className="flex mr-9 justify-end mt-1 items-center mb-6">
              <AutomateIcon style={{ marginRight: 5 }} />
              <div className="text-xs text-grey-700 mr-2">Automated Task</div>
              <code
                style={{
                  background: '#C4C2C0',
                  width: '3.5px',
                  height: '3.5px',
                  borderRadius: '50%'
                }}
              ></code>
              <p className="text-xs text-grey-700 ml-1">{created_at}</p>
            </div>
          )}
          {!item?.sent_by_automation && (
            <div className="flex mr-9 justify-end mt-1 items-center mb-6">
              <img
                src={iconReminderInActive}
                style={{ width: 10, height: 10 }}
              />
              <p className="text-xs text-grey-700 ml-1 mr-1">Task</p>
              <code
                style={{
                  width: 4,
                  height: 4,
                  backgroundColor: '#C4C2C0',
                  borderRadius: '50%'
                }}
              ></code>
              <p className="text-xs text-grey-700 ml-1">{created_at}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

TaskContainer.propTypes = {
  created_at: PropTypes.string,
  created_by: PropTypes.object
};

export default TaskContainer;

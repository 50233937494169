import React from 'react';
import enquiryOutline from 'assets/icons/icon-assign-enquiries-outline.svg';
import { isEmpty } from 'utils/utils';
import BoxStageEmptyState from 'modules/enquirybox/components/reportsConditionalState/boxStageEmptyState';

const TotalEnquiriesPerStage = (props) => {
  const { data, isPublic = false, total, hideCount } = props;

  return (
    <div className="card-box px-5 py-4 mt-8">
      <div className="flex items-center pr-4">
        <figure
          className="avatar-container avatar-container--contain bg-grey-300"
          style={{
            width: 38,
            height: 38,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <img src={enquiryOutline} width={17} height={17} />
        </figure>

        <p className="ml-3 font-semibold letterspacing24em">
          Total enquiries per stage
        </p>
      </div>
      <div className="relative">
        <table className="table-reports">
          <thead
            style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}
          >
            <tr>
              <td>Stages</td>
              <td className="">Total</td>
              <td className="">Total value</td>
            </tr>
          </thead>
          {total !== 0 ? (
            <tbody className={`${isPublic ? 'card-box-report-sales' : ''}`}>
              {!isEmpty(data) &&
                data.map((item, idx) => {
                  const {
                    stage_name,
                    total_enquiries,
                    total_enquiries_percentage,
                    total_sale_value
                  } = item;
                  return (
                    <tr
                      key={idx}
                      style={{
                        display: 'table',
                        width: '100%',
                        tableLayout: 'fixed'
                      }}
                    >
                      <td className="text-sm font-semibold flex-1">
                        {stage_name}
                      </td>
                      <td>
                        <span className="text-sm font-bold">
                          {hideCount ? 0 : total_enquiries}
                        </span>
                        <span className="text-13 text-grey-600 font-medium ml-3">
                          {hideCount
                            ? 0
                            : total_enquiries_percentage
                            ? parseFloat(total_enquiries_percentage)
                                .toFixed(2)
                                .replace(/\.00$/, '')
                            : 0}
                          %
                        </span>
                      </td>
                      <td className="text-sm font-bold">
                        {hideCount ? 0 : total_sale_value}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          ) : (
            <BoxStageEmptyState />
          )}
        </table>
      </div>
    </div>
  );
};

export default TotalEnquiriesPerStage;

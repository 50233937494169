import { ReactComponent as BackIcon } from 'assets/icons/icon-arrow-left-green.svg';
import { Link } from 'react-router-dom';
import { route } from 'utils/route';

const ChannelBack = () => {
  return (
    <Link
      className="flex items-center mb-5 cursor-pointer"
      to={`${route.channels}/connect-channel`}
    >
      <BackIcon />
      <span className="ml-2 text-base font-bold text-primary">
        Back to channels
      </span>
    </Link>
  );
};

export default ChannelBack;

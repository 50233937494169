/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import EmailConversationUserName from '../EmailUser';
import AttachmentItem from '../AttachmentItem';

import { isEmpty } from 'utils/utils';

const Sender = ({ expandEmail = true, data }) => {
  const { message_sent_by } = data;
  const [finalMessage, setFinalMessage] = useState('');

  useEffect(() => {
    if (!isEmpty(data) && data.is_reply_received) {
      let message = data.message.split('*');
      for (let i = 0; i < message.length; i++) {
        if (i % 2 !== 0) {
          message[i] = `<strong>${message[i]}</strong>`;
        }
      }
      setFinalMessage(message.join(''));
    }
  }, [data]);

  return (
    <div className="email-body" style={{ border: ' 1px solid #E6E4E1' }}>
      <div className="item" style={{ paddingBottom: 0 }}>
        <div
          className={`flex items-center ${
            data.is_reply_sent ? 'item' : ''
          } pb-4`}
        >
          <p className=" text-13 font-bold">Subject:</p>
          <p className="text-13 font-bold ml-2">{data.subject}</p>
        </div>
        {data?.is_reply_sent && (
          <div className="item">
            <div className="flex items-center">
              <p className=" text-13 text-grey-600">From:</p>
              <p className="text-13 text-grey-900 ml-2">
                <span>
                  {message_sent_by?.first_name} {message_sent_by?.last_name}
                </span>
              </p>
            </div>
            {expandEmail && (
              <>
                {!isEmpty(data.cc) && (
                  <div className="flex flex-start my-3">
                    <p className="text-13 text-grey-600">Cc:</p>
                    <div className="flex items-center flex-wrap ml-2">
                      {data.cc.map((val, idx) => {
                        return (
                          <div className="flex items-center" key={idx}>
                            <EmailConversationUserName
                              editMode={false}
                              name={val}
                            />
                            {data.cc.length - 1 !== idx && (
                              <p className="pre">{', '}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {!isEmpty(data.bcc) && (
                  <div className="flex items-center my-3">
                    <p className="text-13 text-grey-600">Bcc:</p>
                    <div className="flex items-center flex-wrap ml-2">
                      {data.bcc.map((val, idx) => {
                        return (
                          <div className="flex items-center" key={idx}>
                            <EmailConversationUserName
                              editMode={false}
                              name={val}
                            />
                            {data.bcc.length - 1 !== idx && (
                              <p className="pre">{', '}</p>
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="mt-3">
        {data.is_reply_received ? (
          <>
            {finalMessage.includes('<script') ? (
              <div className="pre replySent-list-style-none">
                {finalMessage}
              </div>
            ) : (
              <div
                className="pre replySent-list-style-none"
                dangerouslySetInnerHTML={{ __html: finalMessage }}
              />
            )}
          </>
        ) : (
          <div
            className="replySent-list-style-none"
            style={{ wordWrap: 'break-word' }}
            dangerouslySetInnerHTML={{ __html: data.message }}
          />
        )}

        {/* <p className="text-13 text-grey-900">
          Hi there! 👋 Glad you're trying out Enquirybox! This is an
          automated.
          <br />
          <br />I hope curabitur magna massa, tristique bibendum finibus nec,
          consectetur ut urna. Sed nec libero feugiat, tincidunt arcu.
        </p> */}
      </div>
      {/* <div className="mt-5">
        <p className="text-13 text-grey-600" style={{ marginBottom: 4 }}>
          Best regards
        </p>
        <p className="text-13 text-grey-900 font-bold">Enquirybox Team</p>
      </div> */}
      {!isEmpty(data.attachments) && expandEmail && (
        <div>
          <h3 className=" flex text-13 text-grey-900 font-bold mt-5 mb-2">
            Attachments
          </h3>
          <div className="item__attachment">
            {data.attachments.map((attachment, idx) => {
              return (
                <AttachmentItem
                  key={idx}
                  attachment={attachment}
                  message={true}
                />
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default Sender;

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';

const HistoryLayout = (props) => {
  const { icon, title, time, renderData, className } = props;

  return (
    <div className="flex">
      <div>
        <Avatar
          src={icon}
          smaller
          style={{ background: '#005C6F' }}
          imgStyle={{ width: '14px', height: '17px' }}
        />
      </div>
      <div className="history__right-side border br-reply ml-2">
        <div className={`flex justify-between ${className}`}>
          <div className="text-xs font-semibold text-grey-800">{title}</div>
          <div className="text-grey-700 text-xs">{time}</div>
        </div>
        {renderData && typeof renderData === 'function' && renderData()}
      </div>
    </div>
  );
};
HistoryLayout.propTypes = {
  title: PropTypes.string,
  time: PropTypes.string,
  desc: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string
};
export default HistoryLayout;

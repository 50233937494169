import { createSlice } from '@reduxjs/toolkit';
import { GET_LEAD_CONVERSATION, GET_LEAD_DATA, GET_LEAD_REPLY } from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import { setSelectedLabels } from './labelsSlice';
import { isEmpty } from 'utils/utils';
import choices from 'choices';

// const NORMAL_REPLY = choices.LeadReplyChoice.NORMAL_REPLY
// const EMAIL_REPLY = choices.LeadReplyChoice.EMAIL_REPLY
import { GET_ACTIVE_SIGNATURE } from 'urls';

const { EmailSignatureChoice } = choices;

export const leadConversation = createSlice({
  name: 'conversation',
  initialState: {
    activeLead: {},
    conversation: {},
    conversationMessage: {},
    webformFieldList: [],
    showCardNotFound: false,
    isModalOpen: false,
    activeTab: 1,
    hover: false,
    fetchConversation: false
  },
  reducers: {
    setActiveLead: (state, action) => {
      state.activeLead = action.payload;
    },
    setConversation: (state, action) => {
      state.conversation = action.payload;
    },
    setConversationMessage: (state, action) => {
      state.conversationMessage = action.payload;
    },
    setWebformFieldList: (state, action) => {
      state.webformFieldList = action.payload;
    },
    setShowCardNotFound: (state, action) => {
      state.showCardNotFound = action.payload;
    },
    setIsModalOpen: (state, action) => {
      state.isModalOpen = action.payload;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setHover: (state, action) => {
      state.hover = action.payload;
    },
    setFetchConversation: (state, action) => {
      state.fetchConversation = action.payload;
    }
  }
});

export const {
  setActiveLead,
  setConversation,
  setConversationMessage,
  setWebformFieldList,
  setShowCardNotFound,
  setIsModalOpen,
  setActiveTab,
  setHover,
  setFetchConversation
} = leadConversation.actions;

export const getLeadData = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { leadId } = payload;
      return new APIRequest()
        .get(replaceUrl(GET_LEAD_DATA, 'leadId', leadId))
        .then((resp) => {
          if (resp.status === 200) {
            if (isEmpty(resp.data?.results)) {
              dispatch(setShowCardNotFound(true));
            } else {
              dispatch(setShowCardNotFound(false));
            }
            dispatch(setActiveLead(resp?.data?.results[0]));
            if (resp?.data?.results[0]?.labels) {
              dispatch(setSelectedLabels(resp?.data?.results[0]?.labels));
            } else {
              dispatch(setSelectedLabels([]));
            }
            if (successCb) {
              successCb(resp?.data);
            }
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb(err);
          }
          console.log(err);
        });
    } catch (e) {
      console.log(' e', e);
    }
  };
};

export const getLeadConversation = (payload, callback, successCb) => {
  return async (dispatch) => {
    try {
      const { leadId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_LEAD_CONVERSATION, 'leadId', leadId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setConversation(res.data));
            // if (res.data?.results[0].mapped_message) {
            //   if (
            //     res.data?.results[0].mapped_message &&
            //     typeof res.data?.results[0].mapped_message === 'string'
            //   ) {
            //     let tempData = {};
            //     let tempList = [];
            //     tempData = res.data?.results[0].mapped_message.replaceAll(
            //       "'",
            //       '"'
            //     );
            //     tempData = JSON.parse(tempData);
            //     Object.keys(tempData).forEach((item) => {
            //       if (!['sub_data', 'form_name'].includes(item)) {
            //         tempList.push(tempData[item]);
            //       }
            //     });
            //     const finalList = tempList.reduce((acc, obj) => {
            //       Object.keys(obj).forEach((key) => {
            //         acc.push({ label: key, [key]: obj[key] });
            //       });
            //       return acc;
            //     }, []);
            //     dispatch(setWebformFieldList(finalList));
            //     dispatch(setConversationMessage(tempData));
            //   }
            // } else {
            //   if (
            //     res.data?.results[0].message &&
            //     typeof res.data?.results[0].message === 'string'
            //   ) {
            //     let tempData = {};
            //     let tempList = [];

            //     tempData = res.data?.results[0].message.replaceAll("'", '"');
            //     console.log(tempData)
            //     try {
            //       tempData = JSON.parse(tempData);
            //     } catch (e) {
            //       console.log(e)
            //     }
            //     Object.keys(tempData).forEach((item) => {
            //       if (!['sub_data', 'form_name'].includes(item)) {
            //         tempList.push(tempData[item]);
            //       }
            //     });
            //     const finalList = tempList.reduce((acc, obj) => {
            //       Object.keys(obj).forEach((key) => {
            //         acc.push({ label: key, [key]: obj[key] });
            //       });
            //       return acc;
            //     }, []);
            //     dispatch(setWebformFieldList(finalList));
            //     dispatch(setConversationMessage(tempData));
            //   }
            // }

            if (callback) {
              callback(res.data);
            }
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchReplyById = (payload, callback, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { replyId } = payload;
      const {
        leadConversation: { conversation }
      } = getState();

      new APIRequest()
        .get(replaceUrl(GET_LEAD_REPLY, 'replyId', replyId))
        .then((res) => {
          if (res.status === 200) {
            const temp = { ...conversation };
            temp.results = [...conversation.results];
            temp.results.push(res.data);
            dispatch(setConversation(temp));

            if (callback) {
              callback(res.data);
            }
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const fetchUserSignature = (payload, callback) => {
  return async () => {
    try {
      const { userId } = payload;

      new APIRequest()
        .get(`${GET_ACTIVE_SIGNATURE}?user_id=${userId}`)
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

const s3BucketUrl =
  'https://ebox-static-files.s3.eu-west-1.amazonaws.com/logos';

const prepareDefaultSignature = (data = {}) => {
  const {
    address,
    company,
    email,
    full_name,
    job_title,
    phone_number,
    sign_off_message,
    website,
    font,
    attachments,
    image_details
  } = data;

  let attach = [];
  const imageSize =
    typeof image_details === 'string' ? JSON.parse(image_details) : {};

  if (Array.isArray(attachments)) {
    attachments.forEach((item) => {
      let style = {};
      if (imageSize[item.id]) {
        style = `
          height: ${imageSize[item.id].height}px;
          width: ${imageSize[item.id].width}px;
        `;
      } else {
        style = `
        max-width: 300px;
        height: 150px;
      `;
      }
      attach.push(`<div style="${style} margin-top: 0.5rem;">
      <img src="${item.file_url}" style="${style}" />
      </div>`);
    });
  }

  return `
  <div style="font-family:${font};">
    ${
      sign_off_message
        ? `<div style="font-size: 13px;font-weight: 500; color: #4C5254;">
    ${sign_off_message}
    </div>`
        : ''
    }
    ${
      full_name
        ? `<div style="font-size: 14px;margin-bottom: 5px;margin-top: 5px;font-weight: 600; color: #2B3639;">
    ${full_name}
    </div>`
        : ''
    }
    ${
      job_title
        ? `<div style="margin-bottom: 5px;font-weight: 500; color: #4C5254;">
    ${job_title}
    </div>`
        : ''
    }
    ${
      email
        ? `<div style="display: flex; flex-direction:row; align-items: center; margin-top: 0.5rem;">
        <div style="margin-right: 10px; display: flex;">
            <img src="${s3BucketUrl}/icon-email-grey-700.png" alt="icon-email" style="width: 13px; height: 13px; margin-top: 4px;">
        </div>
        <span style="font-size: 13px; line-height: 1.25rem; font-weight: 500; color: #2B3639;">
        ${email}
        </span>
    </div>`
        : ''
    }
    ${
      phone_number
        ? `<div style="display: flex; flex-direction:row; align-items: center; margin-top: 0.5rem;">
        <div style="margin-right: 10px; display: flex;">
            <img src="${s3BucketUrl}/icon-phone.png" alt="icon-email" style="width: 13px; height: 13px; margin-top: 4px;">
        </div>
        <span style="font-size: 13px; line-height: 1.25rem; font-weight: 500; color: #2B3639;">
        ${phone_number}
        </span>
    </div>`
        : ''
    }
    ${
      company
        ? `<div style="display: flex; flex-direction:row; align-items: center; margin-top: 0.5rem;">
        <div style="margin-right: 10px; display: flex;">
            <img src="${s3BucketUrl}/icon-fresh-smiles.png" alt="icon-email" style="width: 13px; height: 13px; margin-top: 4px;">
        </div>
        <span style="font-size: 13px; line-height: 1.25rem; font-weight: 500; color: #2B3639;">
        ${company}
        </span>
    </div>`
        : ''
    }
    ${
      website
        ? `<div style="display: flex; flex-direction:row; align-items: center; margin-top: 0.5rem;">
        <div style="margin-right: 10px; display: flex;">
            <img src="${s3BucketUrl}/icon-globe.png" alt="icon-email" style="width: 13px; height: 13px; margin-top: 4px;">
        </div>
        <span style="font-size: 13px; line-height: 1.25rem; font-weight: 500; color: #2B3639;">
        ${website}
        </span>
    </div>`
        : ''
    }
    ${
      address
        ? `<div style="display: flex; flex-direction:row; align-items: center; margin-top: 0.5rem;">
        <div style="margin-right: 10px; display: flex;">
            <img src="${s3BucketUrl}/icon-map.png" alt="icon-email" style="width: 13px; height: 13px; margin-top: 4px;">
        </div>
        <span style="font-size: 13px; line-height: 1.25rem; font-weight: 500; color: #2B3639;">
        ${address}
        </span>
    </div>`
        : ''
    }
    ${attach.join('')}
    </div>
  `;
};

export const handleChangeSignature = (userId, editorRef, callback) => {
  return async (dispatch) => {
    try {
      dispatch(
        fetchUserSignature({ userId }, (data) => {
          let signature = '';
          if (data.signature_type === EmailSignatureChoice.DEFAULT) {
            signature = prepareDefaultSignature(data);
          } else {
            signature = data.message;
          }
          if (!signature) {
            editorRef.dom.remove('user-signature');
            const current_content = editorRef.getContent();
            callback(current_content, '');
            return;
          }
          editorRef.dom.remove('user-signature');
          if (isEmpty(editorRef.getContent())) {
            editorRef.setContent('<br><br>');
          } else {
            editorRef.setContent(`${editorRef.getContent()}<br>`);
          }
          if (!editorRef.dom.get('user-signature')) {
            editorRef.dom.add(
              editorRef.getBody(),
              'div',
              {
                id: 'user-signature',
                contenteditable: false
              },
              signature
            );
            editorRef.dom.addClass('user-signature', 'ebox-noneditable');
            const current_content = editorRef.getContent();
            callback(current_content, signature);
          } else {
            editorRef.dom.remove('user-signature');
            editorRef.dom.add(
              editorRef.getBody(),
              'div',
              {
                id: 'user-signature',
                contenteditable: false
              },
              signature
            );
            editorRef.dom.addClass('user-signature', 'ebox-noneditable');
            const current_content = editorRef.getContent();
            callback(current_content, signature);
          }
        })
      );
    } catch (e) {
      console.log(e);
    }
  };
};

export default leadConversation.reducer;

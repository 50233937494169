/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import SectionHeader from 'modules/dashboard/component/sections/header';
import SectionItem from 'modules/dashboard/component/sections';
import { route } from 'utils/route';
import { NavLink } from 'react-router-dom';
import { getAllBoxes } from 'slices/boxes/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconEbBox } from 'assets/icons/icon-sidebar-eb-box.svg';
import {
  setTempDateFormat,
  setTempEnd_Date,
  setTempStart_date
} from 'slices/reports/slice';
import moment from 'moment';

const BoxesSideNav = () => {
  const dispatch = useDispatch();
  const { boxes } = useSelector((state) => state.boxes);

  useEffect(() => {
    dispatch(getAllBoxes());
  }, []);

  const onClick = () => {
    dispatch(setTempStart_date(moment().startOf('month').format('YYYY-MM-DD')));
    dispatch(
      setTempEnd_Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    );
    dispatch(setTempDateFormat('This month to date'));
  };

  return (
    <div className="pt-0">
      <div className="flex flex-col">
        <div onClick={onClick}>
          <NavLink
            to={`${route.report}${route.boxReport}/all-box`}
            className={`flex justify-between py-3 bg-color-on-hover bg-color-on-hover--bg-grey-100`}
          >
            <div className="flex items-center">
              <IconEbBox width={16} height={16} />
              <h2 className="text-15 font-semibold text-grey-900 ml-3">
                All Boxes
              </h2>
            </div>
          </NavLink>
        </div>
        <div className="bg-grey-400 mb-6 mt-4" style={{ height: 1 }}></div>
        <SectionHeader label="Box Reports" />
        {boxes?.map((box) => {
          return (
            <SectionItem
              key={box.id}
              to={`${route.report}${route.boxReport}/${box.type_of_box}/${box.id}/snapshot`}
              icon={box.box_avatar}
              label={box.box_name}
              iconStyle={{ width: 20 }}
              iconString
            />
          );
        })}
      </div>
    </div>
  );
};

export default BoxesSideNav;

import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BackArrow from '../../components/backArrow';
import { useDispatch, useSelector } from 'react-redux';
import OverLayLoader from 'components/loader/overLayLoader';
import AddQAndA from 'modules/chatbotAi/modal/AddQAndA';
import { listAllQna, uploadMultipleQna } from 'slices/ai-assistant/qna/slice';
import { isFileValid } from 'utils/aiAssistant';
import { useSnackbar } from 'components/Snackbar';
import ListQna from './listQna';

const QuestionsAndAnswers = () => {
  const { assistant_id } = useParams();
  const dispatch = useDispatch();
  const qnaData = useSelector((state) => state.qna.qnaData);
  const { openSuccessSnackBar } = useSnackbar();
  const [qnaModal, setQnaModal] = useState(false);
  const { currentVersionId } = useSelector((state) => state.assistant);
  const id = useSelector((state) => state?.myInfo?.myInformation?.id);
  const [isLoading, setIsLoading] = useState(false);

  const handleResponse = () => {
    openSuccessSnackBar('Question and answers uploaded');
    dispatch(listAllQna(assistant_id));
    setIsLoading(false);
  };
  const handleFailed = (error) => {
    setIsLoading(false);
    swal({
      title: 'Error',
      text: error?.response?.data?.message,
      icon: 'error',
      button: {
        text: 'Ok',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      }
    });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const checkValidFile = isFileValid(file);
    setIsLoading(true);
    if (checkValidFile.isValid) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('edited_by_id', id);
      formData.append('version_id', currentVersionId);
      dispatch(
        uploadMultipleQna(formData, assistant_id, handleResponse, handleFailed)
      );
      e.target.value = null;
    } else {
      swal({
        title: 'Error',
        text: checkValidFile.message,
        icon: 'error',
        button: {
          text: 'Ok',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dispatch(listAllQna(assistant_id));
  }, []);
  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <div>
        <BackArrow />
        <div className="" style={{ padding: 28 }}>
          <div className="file-upload-container">
            <h4 className="font-semibold letterspacing24em">
              Add Questions and Answers
            </h4>
            <p className="text-grey-800 text-xs mt-2 letterspacing24em">
              Upload multiple questions at once or add one by one
            </p>

            <div className="flex items-center justify-center mt-4">
              <div className="">
                <input
                  name="file-upload"
                  type="file"
                  id="uploadMultiple"
                  className="hidden"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileUpload}
                />
                <label
                  htmlFor="uploadMultiple"
                  className="btn btn--blue-outline btn--h30 cursor-pointer"
                >
                  Upload multiple
                </label>
              </div>
              <div className="or-text">or</div>
              <button
                className="btn btn--blue-outline btn--h30"
                onClick={() => setQnaModal(true)}
              >
                Add one by one
              </button>
            </div>
            <div className="text-grey-800 text-xs mt-4">
              Max file size <span className="font-semibold">100MB</span> (Excel,
              CSV)
            </div>
            <div className="text-grey-800 text-xs mt-3">
              <a className="text-grey-800 text-xs font-medium underline cursor-pointer">
                Download
              </a>{' '}
              our Q&A upload template
            </div>
          </div>
          {qnaData?.length !== 0 && <ListQna />}
        </div>
        <AddQAndA
          open={qnaModal}
          setQnaModal={setQnaModal}
          currentVersionId={currentVersionId}
          id={id}
        />
      </div>
    </>
  );
};

export default QuestionsAndAnswers;

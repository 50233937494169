import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import TextArea from 'components/inputs/textarea';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { addProduct } from 'slices/ai-assistant/productInfo/slice';
import { useParams } from 'react-router-dom';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import CHOICES from 'choices';
import { useSnackbar } from 'components/Snackbar';
import Select from 'components/inputs/select';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty } from 'utils/utils';

const AddProductInfo = (props) => {
  const { assistant_id } = useParams();
  const { open, setInfoModal, currentVersionId, id } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { currenciesData } = useSelector((state) => state.businessInfoSettings);
  const [selectedValue, setSelectedValue] = useState({});
  const dispatch = useDispatch();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const formSchema = {
    name: string().required('Please provide name'),
    price: string()
      .required('Please provide price')
      .test('greater than zero', 'Price must be greater than zero', (value) => {
        return parseFloat(value) > 0;
      }),
    description: string().required('Please provide description'),
    bookingLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid URL link',
      excludeEmptyString: true
    }),
    moreinfoLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid URL link',
      excludeEmptyString: true
    }),
    productOrServiceLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid URL link',
      excludeEmptyString: true
    })
  };
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const CurrencyOptions = currenciesData.map((item, index) => {
    return {
      label: `${CHOICES.CurrencyChoice.CHOICES[`${item?.code}`]}        -  ${
        item?.name
      }`,
      value: CHOICES.CurrencyChoice.CHOICES[`${item?.code}`],
      icon: item?.image_link
    };
  });
  useEffect(() => {
    if (!isEmpty(currenciesData)) {
      setSelectedValue({
        value: `${
          CHOICES.CurrencyChoice.CHOICES[`${currenciesData[0]?.code}`]
        }`,
        label: `${
          CHOICES.CurrencyChoice.CHOICES[`${currenciesData[0]?.code}`]
        }`,
        icon: currenciesData[0]?.image_link
      });
    }
  }, [currenciesData]);

  const handleCurrencyChange = (currency) => {
    setSelectedValue(currency);
  };

  const handleResponse = () => {
    setIsLoading(false);
    setInfoModal(false);
    openSuccessSnackBar('Product added');
    reset({
      name: '',
      price: 0,
      currency: 'GBP',
      description: '',
      bookingLink: '',
      productOrServiceLink: '',
      moreinfoLink: ''
    });
  };
  const handleFailed = () => {
    setIsLoading(false);
    openErrorSnackBar('Error in adding product');
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    const {
      name,
      price,
      description,
      bookingLink,
      moreinfoLink,
      productOrServiceLink
    } = data;
    let payload = {
      version_id: currentVersionId,
      edited_by_id: id,
      name,
      price: parseFloat(price),
      currency: selectedValue?.value,
      description: description,
      link1: bookingLink,
      link2: moreinfoLink,
      link3: productOrServiceLink
    };
    dispatch(addProduct(payload, assistant_id, handleResponse, handleFailed));
  };

  const handlePriceInputChange = (e) => {
    const { value } = e.target;

    const numericValue = value.replace(/[^\d.]+/g, '');
    const parts = numericValue.split('.');

    if (parts.length > 1) {
      const integerPart = parts[0];
      let fractionalPart = parts[1].slice(0, 2);

      e.target.value = `${integerPart}.${fractionalPart}`;
    } else {
      e.target.value = numericValue;
    }
  };

  return (
    <Modal.Main open={open} className="open modal">
      <Modal.Header
        title="Add a product or service"
        onClose={() => setInfoModal(false)}
      />
      <Modal.Body style={{ padding: '20px 30px 30px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <label className="block text-base-1 font-semibold mb-1">Name</label>
            <InputField
              name="name"
              className="input-field input-field--border2 input-field--h42 bordergrey-500"
              placeholder="Add your product or service name"
              error={errors.name && touchedFields.name}
              register={register}
              autoComplete={'off'}
            />
            {errors.name && touchedFields.name && (
              <ErrorMessage message={errors?.name?.message} />
            )}
          </div>
          <div className="mt-5">
            <label className="text-md font-semibold">Price</label>
            <div className="flex  justify-between">
              <div
                className={`flex justify-between items-center
              }`}
              >
                <div className="relative">
                  <div>
                    <Select
                      name="currency"
                      isHaveIcon={true}
                      options={CurrencyOptions}
                      iconStyle={{ width: 28, height: 18, marginRight: 10 }}
                      inputClassName={`input-field--sm-38  
                    ${selectedValue?.label ? '!pl-[50px]' : ''}`}
                      dropDownClassName="p-2 !w-[300px]"
                      value={selectedValue?.label}
                      selectedIconStyle={{
                        position: 'absolute',
                        top: 10,
                        zIndex: 1,
                        left: 8,
                        width: '35px'
                      }}
                      renderIcon={() => {
                        return (
                          <img
                            style={{
                              position: 'absolute',
                              top: 10,
                              zIndex: 1,
                              left: 8,
                              width: '35px'
                            }}
                            src={selectedValue?.icon}
                          />
                        );
                      }}
                      selectedIcon={selectedValue?.icon}
                      onChange={(value) => handleCurrencyChange(value)}
                      inputStyle={{ paddingLeft: 46 }}
                      placeholder="Select currency"
                      disabled={false}
                      readOnly={true}
                      style={{
                        cursor: 'auto',
                        width: 130,
                        height: 41,
                        border: '1.5px solid #c3c2c0'
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="w-[340px]">
                <InputField
                  name="price"
                  className="input-field input-field--border2 input-field--h42"
                  placeholder="Add the price"
                  error={errors.price && touchedFields.price}
                  register={register}
                  autoComplete={'off'}
                  type="text"
                  onInput={handlePriceInputChange}
                />
              </div>
            </div>
            {errors.price && touchedFields.price && (
              <ErrorMessage
                message={errors?.price?.message}
                className="ml-[138px]"
              />
            )}
          </div>
          <div className="mt-5">
            <label className="block text-base-1 font-semibold mb-1">
              Description
            </label>
            <TextArea
              name="description"
              rows="2"
              cols="3"
              placeholder="Write a description for your product or service"
              className="input-field--border2 resize-none"
              error={errors.description && touchedFields.description}
              register={register}
              autoComplete="off"
            ></TextArea>
            {errors.description && touchedFields.description && (
              <ErrorMessage message={errors?.description?.message} />
            )}
          </div>
          <div className="mt-5">
            <label className="block text-base-1 font-semibold mb-1">
              Add your links{' '}
            </label>
            <InputField
              name="bookingLink"
              className="input-field input-field--border2 input-field--h42"
              placeholder="Add a booking link"
              error={errors.bookingLink && touchedFields.bookingLink}
              register={register}
              autoComplete={'off'}
            />
            {errors.bookingLink && touchedFields.bookingLink && (
              <ErrorMessage message={errors?.bookingLink?.message} />
            )}
            <InputField
              name="moreinfoLink"
              className="input-field input-field--border2 input-field--h42 mt-2"
              placeholder="Add a link for more information"
              error={errors.moreinfoLink && touchedFields.moreinfoLink}
              register={register}
              autoComplete={'off'}
            />
            {errors.moreinfoLink && touchedFields.moreinfoLink && (
              <ErrorMessage message={errors?.moreinfoLink?.message} />
            )}
            <InputField
              name="productOrServiceLink"
              className="input-field input-field--border2 input-field--h42 mt-2"
              placeholder="Add a link for product or service image"
              error={
                errors.productOrServiceLink &&
                touchedFields.productOrServiceLink
              }
              register={register}
              autoComplete={'off'}
            />
            {errors.productOrServiceLink &&
              touchedFields.productOrServiceLink && (
                <ErrorMessage message={errors?.productOrServiceLink?.message} />
              )}
          </div>
          <div className="flex items-center justify-end mt-6">
            <button
              className="btn btn--secondary btn--md btn--h42 min-w-120"
              onClick={() => setInfoModal(false)}
            >
              Cancel
            </button>
            {/* <input type="submit" /> */}
            <PrimaryButton
              type="submit"
              label="Add"
              className="btn btn--primary btn--md btn--h42 min-w-120 ml-3"
              isLoading={isLoading}
              enableLoader={true}
              loaderInline={true}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

export default AddProductInfo;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import lock from 'assets/icons/lock2.svg';

const PaymentFailed = ({
  open = false,
  planMode,
  paymentNotCompleted = false
}) => {
  return (
    <Modal.Main className="modal--upgrade-notification show" open={open}>
      <div className="modal__body px-11 py-10">
        <h1 className="text-25 font-bold text-center">
          {paymentNotCompleted
            ? 'Your payment is not completed'
            : 'Your payment has failed'}
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            The payment has failed for your monthly subscription due to an issue
            with your card.
          </p>
          <p className="text-lg leading-6 mt-6">
            Please update the card you have on file to resume your subscription.
          </p>
        </div>
        <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
          <p className="text-lg font-medium text-center">
            Current card on file ending in 0435
          </p>
          <div className="mt-4">
            <CustomColorButton
              label="Update your card details"
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
            />
          </div>
        </div>
        <div className="flex items-center justify-center mt-5">
          <img src={lock} alt="lock-icon" />
          <p
            className="text-xs ml-2"
            style={{ color: '#828282', marginBottom: 0 }}
          >
            Secure update using Stripe{' '}
          </p>
        </div>
      </div>
    </Modal.Main>
  );
};

export default PaymentFailed;

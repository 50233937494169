import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  DELETE_WEBSITE_LINK,
  GET_WEBSITE_LINKS,
  POST_NEW_WEBSITE_LINK
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';

const initialState = {
  websiteLinks: []
};

const reducers = {
  setWebsiteLinks: (state, action) => {
    state.websiteLinks = action.payload;
  },
  resetWebsiteLinksData: () => initialState
};

export const websiteLinks = createSlice({
  name: 'websiteLinks',
  initialState,
  reducers
});

export const { setWebsiteLinks, resetWebsiteLinksData } = websiteLinks.actions;
export default websiteLinks.reducer;

export const addWebsiteLink = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const assistant_id = payload.assistant_id;
    const { websiteLinks } = getState().websiteLinks;
    const { id } = getState().myInfo.myInformation;
    const { currentVersionId } = getState().assistant;
    const formData = {
      version_id: currentVersionId,
      website_link: payload.websiteLink,
      edited_by_id: id
    };
    try {
      new APIRequest()
        .post(
          replaceUrl(POST_NEW_WEBSITE_LINK, 'aiBotId', assistant_id),
          formData
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setWebsiteLinks([...websiteLinks, res.data.data[0]]));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};

export const getWebsiteLinks = (assistant_id, callback) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_WEBSITE_LINKS, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setWebsiteLinks(res.data));
            if (callback) {
              callback();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (callback) callback();
        });
    } catch (err) {
      console.log(err);
      if (callback) callback();
    }
  };
};

export const deleteWebsiteLink = (id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { currentVersionId } = getState().assistant;
    const { websiteLinks } = getState().websiteLinks;
    const payload = {
      version_id: currentVersionId
    };
    try {
      new APIRequest()
        .delete(replaceUrl(DELETE_WEBSITE_LINK, 'websiteLinkId', id), payload)
        .then((res) => {
          if (res.status === 200) {
            const newUrlList = websiteLinks.filter((obj) => {
              if (obj.id !== res.data.website_link_id) return obj;
            });
            dispatch(setWebsiteLinks(newUrlList));
            dispatch(setVersionStatus(res.data.version_status));
            if (successCb) successCb();
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};

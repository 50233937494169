/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import CustomColorButton from 'components/buttons/customColorButton';
import ListItem from 'modules/enquirybox/channels/components/layout/listItem';
import { ReactComponent as BackIcon } from 'assets/icons/icon-arrow-left-green.svg';
import { ReactComponent as TickIcon } from 'assets/icons/icon-round-check-green.svg';
import { route } from 'utils/route';
import { Link } from 'react-router-dom';
import { useState } from 'react';

const EnquiryBotChannel = () => {
  const [activeTab, setActiveTab] = useState(true);

  return (
    <>
      {activeTab ? (
        <div className="toolbox__main overflow-overlay channels">
          <div
            className="card-container--new connect-new"
            style={{ width: '710px', padding: '28px 24px 24px 24px' }}
          >
            <div className="flex justify-center">
              <div>
                <Link
                  className="flex items-center cursor-pointer ml-8"
                  to={`${route.channels}/connect-channel`}
                >
                  <BackIcon />
                  <span className="ml-2 text-base font-bold text-primary">
                    Back to channels
                  </span>
                </Link>
                <iframe
                  src="https://player.vimeo.com/video/817353100?h=514657c98e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                  webkitallowfullscreen="true"
                  mozallowfullscreen="true"
                  allowFullScreen={true}
                  title="iframe"
                  className="cursor-pointer"
                  style={{
                    width: '100%',
                    height: '420px'
                  }}
                ></iframe>
                <h3 className="mt-1 font-semibold text-lg">
                  Turn your website visitors into customers with EnquiryBot
                </h3>
                <p className="mt-2 text-sm text-grey-800">
                  Our Free website chatbot proactively greets your website
                  visitors, replicating the same personal touch as you would in
                  person. Its main purpose is to increase the number of new
                  enquiries from your website traffic. Our Free website chatbot
                  proactively greets your website visitors, replicating the{' '}
                </p>

                <ul className="mt-5">
                  <ListItem
                    title="Add the code below to your website."
                    className="text-sm text-grey-900 font-medium"
                    isPrimaryText=" Here are directions on how to do that"
                  />
                  <ListItem
                    title="Once added, put a test enquiry through a form"
                    className="text-sm text-grey-900 font-medium mt-3"
                  />
                  <ListItem
                    title="Once received in EnquiryBox, map your fields and name your form"
                    className="text-sm text-grey-900 font-medium mt-3"
                  />
                </ul>

                <div
                  className="pt-5 pl-6 pr-6 pb-6 mt-5"
                  style={{ borderRadius: '14px', backgroundColor: '#EEF7FC' }}
                >
                  <span className="text-30 font-bold line-height-initial">
                    £98
                  </span>
                  <span className="text-sm font-medium">
                    &nbsp;&nbsp;/ month
                  </span>
                  <p className="mt-3" style={{ fontSize: '14px' }}>
                    Please note, you need to be on a paid EnquiryBox plan to
                    purchase this add on.
                  </p>
                  <div className="flex justify-start gap-2">
                    {/* <CustomColorButton
                      label="Sign up now"
                      className="mt-4 text-white"
                      style={{
                        borderRadius: '8px',
                        backgroundColor: '#039855',
                        padding: '11px 27px'
                      }}
                      onClick={() => setActiveTab(false)}
                      isDisable={true}
                    /> */}
                    <CustomColorButton
                      label="Book a demo"
                      className="mt-4 border border-primary bg-white text-primary"
                      style={{
                        padding: '11px 27px'
                      }}
                      onClick={() => {
                        window.open(
                          'https://calendly.com/enquirybot/enquirybox',
                          '_blank'
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="toolbox__main overflow-overlay channels">
            <div
              className="card-container--new connect-new"
              style={{
                width: '602px',
                height: '391px',
                padding: '28px 36px 44px 36px'
              }}
            >
              <Link
                className="flex items-center cursor-pointer"
                to={route.channels}
              >
                <BackIcon />
                <span className="ml-2 text-base font-bold text-primary">
                  Back to channels
                </span>
              </Link>
              <div className="flex justify-start gap-2 mt-6">
                <TickIcon />
                <span style={{ fontSize: '18px', fontWeight: '600px' }}>
                  You are all signed up!
                </span>
              </div>

              <p className="mt-5 text-grey-800" style={{ fontSize: '14px' }}>
                If you have not already spoken to our team about the design of
                your EnquiryBot, please schedule in a call by clicking the
                button below.
              </p>
              <p className="mt-5 text-grey-800" style={{ fontSize: '14px' }}>
                On the call we'll discuss the design of your EnquiryBot so that
                it helps you get more enquiries and save more time by helping
                customers.
              </p>
              <CustomColorButton
                label="Schedule a call"
                className="mt-6 border bg-primary text-white"
                style={{
                  padding: '11px 27px'
                }}
              />
              <p className="mt-5 text-grey-800" style={{ fontSize: '14px' }}>
                If you have already spoken to our team, we will be in touch
                shortly with the link to your new EnquiryBot schedule.{' '}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default EnquiryBotChannel;

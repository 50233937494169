/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import TextInputField from 'components/inputs/textField';
import HeaderItem from 'modules/enquirybox/channels/components/layout/header';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';

const WebFormChannelName = (props) => {
  const { onSave, channelName } = props;

  const formSchema = {
    ...getFormSchema(['channelName'])
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields, isDirty }
  } = useForm({
    defaultValues: { channelName: channelName },
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  useEffect(() => {
    reset({ channelName: channelName });
  }, [channelName]);

  const onChannelSubmit = (data) => {
    onSave({
      name: data.channelName
    });
  };

  return (
    <>
      <form onSubmit={handleSubmit(onChannelSubmit)} autoComplete="off">
        <div
          className={`${isDirty ? 'flex justify-between items-start' : ''}`}
          style={{ height: '36px' }}
        >
          <HeaderItem title="Channel name" />
          {isDirty && (
            <PrimaryButton
              className="btn--xs"
              type="submit"
              label="Save"
              style={{ paddingTop: '3px', paddingBottom: '3px' }}
            />
          )}
        </div>
        <div className="mt-3">
          {/* {showError && <Error message="Name cannot be empty" className="mb-2" />} */}
          <TextInputField
            name="channelName"
            className="input-field--md"
            register={register}
            error={errors.channelName && touchedFields.channelName}
          />
          {errors.channelName && touchedFields.channelName && (
            <Error message={errors.channelName.message} />
          )}
        </div>
      </form>
    </>
  );
};

WebFormChannelName.propTypes = {
  isShowSave: PropTypes.bool,
  showError: PropTypes.bool,
  onSave: PropTypes.func,
  channelName: PropTypes.string,
  onChange: PropTypes.func
};

export default WebFormChannelName;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CHOICES from 'choices';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import { isEmpty } from 'lodash';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DateQuestionAdded from './component/date';
import DropdownQuestionAdded from './component/dropdown';
import OptionsQuestionAdded from './component/options';
import TextQuestionAdded from './component/text';
import ValueQuestionAdded from './component/value';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-white.svg';
import {
  setIsShowQuestionsToAdd,
  setQuestionsData
} from 'slices/questionSlice';

const { TEXT, DROPDOWN, DATETIME, MCQ, VALUE, MCQ_MUTIPLE_SELECT, DATE_RANGE } =
  CHOICES.QuestionChoices;

const QuestionsAdded = ({ data, isBin }) => {
  const dispatch = useDispatch();
  const { AllAnswer, questionsData } = useSelector((state) => state.question);

  const getQuestionsAnswer = (id) => {
    if (!isEmpty(AllAnswer?.results)) {
      const filteredAnswers = AllAnswer?.results.filter(
        (item) => item.question === id
      );
      return filteredAnswers;
    } else {
      return [];
    }
  };

  const onAddQuestion = () => {
    dispatch(setIsShowQuestionsToAdd(true));
  };

  const deleteQuestion = (id) => {
    const remainingQuestions = questionsData.filter((item) => item.id !== id);
    dispatch(setQuestionsData(remainingQuestions));
  };

  const renderData = (item, index) => {
    switch (item.kind) {
      case TEXT:
        return (
          <TextQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      case DROPDOWN:
        return (
          <DropdownQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      case DATETIME:
        return (
          <DateQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      case MCQ:
        return (
          <OptionsQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      case VALUE:
        return (
          <ValueQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      case MCQ_MUTIPLE_SELECT:
        return (
          <OptionsQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
            index={index}
          />
        );
      case DATE_RANGE:
        return (
          <DateQuestionAdded
            questionData={item}
            title={item.text}
            key={item.id}
            answer={getQuestionsAnswer(item.id)}
            deleteQuestion={deleteQuestion}
            isBin={isBin}
          />
        );
      default:
        <></>;
    }
  };
  return (
    <div className="mt-4">
      <div className="flex justify-between">
        <h3 className="font-semibold text-lg">Questions</h3>
        <PrimaryWithLogo
          label="Add question"
          renderSvg={() => <PlusIcon />}
          onClick={onAddQuestion}
          disabled={isBin}
        />
      </div>
      {data?.map((item, index) => {
        return renderData(item, index);
      })}
    </div>
  );
};

export default QuestionsAdded;

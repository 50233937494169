import React from 'react';
import PropTypes from 'prop-types';

const DropDownWrapper = (props) => {
  const { open, style, children, className, dropDownref, ...rest } = props;
  return (
    <div
      ref={dropDownref}
      className={`dropdown-menu ${className} ${open ? 'open' : ''}`}
      style={{
        right: '25px',
        left: 'auto',
        width: '500px',
        top: '30px',
        transition: `all 300ms ease-in 0s`,
        ...style
      }}
      {...rest}
    >
      {children}
    </div>
  );
};

DropDownWrapper.defaultProps = {
  style: { padding: '18px 8px 8px' }
};

DropDownWrapper.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

DropDownWrapper.defaultProps = {
  style: {
    padding: '18px 8px 8px'
  }
};
export default React.memo(DropDownWrapper);

import React from 'react';
import timeOfEnquiry from 'assets/icons/icon-time-of-enquiry.svg';
import CustomToolTip from 'components/CustomTooltip';

const EnquiryTime = (props) => {
  const {
    duringBusinessHourTotal,
    duringBusinessHourPercentage,
    outsideBusinessHourTotal,
    outsideBusinessHourPercentage,
    hideCount
  } = props;
  return (
    <div
      className="card-box-shadow w-full p-5 pb-3 ml-3 mr-3"
      style={{ width: '50%' }}
    >
      <div className="flex items-center pr-4">
        <figure
          className="avatar-container avatar-container--contain bg-grey-300"
          style={{
            width: 38,
            height: 38,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <img src={timeOfEnquiry} width={17} height={17} />
        </figure>
        <CustomToolTip
          style={{ width: '100%' }}
          tooltipStyle={{
            width: 250,
            top: -100,
            bottom: 'initial',
            left: -58,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="The time of day in which you enquiries come in. To setup or change your business hours, click here. "
        >
          <p className="letterspacing24em font-semibold ml-3">
            Time of enquiry
          </p>
        </CustomToolTip>
      </div>
      <ul className="mt-3">
        <li className="flex items-center justify-between source-report__card-list">
          <div className="text-sm font-medium pr-4">During business hours</div>
          <div className="flex items-center font-bold">
            {hideCount ? 0 : duringBusinessHourTotal}
            <span className="text-13 text-grey-600 font-medium ml-1">
              {duringBusinessHourPercentage && !hideCount
                ? parseFloat(duringBusinessHourPercentage)
                    .toFixed(2)
                    .replace(/\.00$/, '')
                : 0}
              %
            </span>
          </div>
        </li>
        <li className="flex items-center justify-between source-report__card-list">
          <div className="text-sm font-medium pr-3">
            Outside of business hours
          </div>
          <div className="flex items-center font-bold">
            {hideCount ? 0 : outsideBusinessHourTotal}
            <span className="text-13 text-grey-600 font-medium ml-1">
              {outsideBusinessHourPercentage && !hideCount
                ? parseFloat(outsideBusinessHourPercentage)
                    .toFixed(2)
                    .replace(/\.00$/, '')
                : 0}
              %
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default EnquiryTime;

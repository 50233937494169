/* eslint-disable no-unused-vars */
import React from 'react';
import { ReactComponent as HandSvg } from 'assets/icons/icon-hand.svg';

const EmptyState = () => {
  return (
    <div
      className="text-center mt-24"
      style={{
        width: '300px',
        margin: 'auto',
        marginTop: '100px'
      }}
    >
      <figure className="flex justify-center">
        <HandSvg />
      </figure>
      <h3 className="mt-2 text-lg font-semibold">Pick up another enquiry</h3>
    </div>
  );
};

export default EmptyState;

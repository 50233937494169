import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChannelsConnectedEmptyState from '../../components/channelsConnectedEmpty';
import FacebookLeadsHome from './home';
import FbLeadAdsSettings from './settings';
// import AnimationWrapper from 'animation/wrapper';
import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
import Forms from 'modules/channels/components/forms';

import FbLeadAdsConnectedSettings from 'modules/channels/components/fbLeadAdsSettings';

const { FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const FacebookLeads = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route path="/pending/:channelId" element={<FacebookLeadsHome />} />
      <Route path="/connected" element={<ChannelsConnectedEmptyState />} />
      <Route path="/connected/:channelId" element={<FbLeadAdsSettings />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={FACEBOOK_LEAD_ADS} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<FbLeadAdsConnectedSettings channelType={FACEBOOK_LEAD_ADS} />}
      />
      <Route
        path="/connected/:channelId/forms"
        element={<Forms channelType={FACEBOOK_LEAD_ADS} />}
      />
      {/* </Route> */}
    </Routes>
  );
};

export default FacebookLeads;

import React from 'react';
import ReportSettings from '../../../components/reportSetting';
import { route } from 'utils/route';
import { useParams } from 'react-router-dom';
import { ReactComponent as SnapshotIcon } from 'modules/enquirybox/assets/icons/icon-report-snapshot.svg';
import { ReactComponent as TeamIcon } from 'modules/enquirybox/assets/icons/icon-report-team-performance.svg';
import { ReactComponent as ChannelIcon } from 'modules/enquirybox/assets/icons/icon-report-channel.svg';
import { ReactComponent as ScheduledIcon } from 'modules/enquirybox/assets/icons/icon-report-scheduled.svg';
import { ReactComponent as IntegrationIcon } from 'modules/enquirybox/assets/icons/icon-report-integrations.svg';

const ReportLink = () => {
  const { channelId } = useParams();

  return (
    <ul>
      <ReportSettings
        to={`${route.dashboard}${route.channel}/${channelId}/snapshot`}
        title="Snapshot"
        description="Full snapshot of your enquiries"
        renderSvg={() => <SnapshotIcon />}
      />
      <ReportSettings
        to={`${route.dashboard}${route.channel}/${channelId}/channels`}
        title="Channel reports"
        description="Invoices, payment method, upgrade"
        renderSvg={() => <ChannelIcon />}
      />
      <ReportSettings
        to={`${route.dashboard}${route.channel}/${channelId}/team-performance`}
        title="Team performance reports"
        description="Add and manage questions"
        renderSvg={() => <TeamIcon />}
      />
      <ReportSettings
        to={`${route.dashboard}${route.channel}/${channelId}/scheduled`}
        title="Scheduled reports"
        description="Manage third party Integrations"
        renderSvg={() => <ScheduledIcon />}
      />
      <ReportSettings
        to={`${route.dashboard}${route.channel}/${channelId}/integrations`}
        title="Reporting Integrations"
        description="Connect Google ads & analytics"
        renderSvg={() => <IntegrationIcon />}
      />
    </ul>
  );
};

export default ReportLink;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Indicator = (props) => {
  const { className, style } = props;
  return (
    <code className={`${styles.color_indicator} ${className}`} style={style} />
  );
};

Indicator.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

Indicator.defaultProps = {
  className: '',
  style: {}
};

export default Indicator;

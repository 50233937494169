/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes, { bool } from 'prop-types';
import InputField from 'components/inputs/textField';
import useClickOutside from 'hooks/useOutsideClick';
import { Controller } from 'react-hook-form';
import { ReactComponent as SelectIcon } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as SelectedCheck } from 'assets/icons/icon-select-green-check.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search-grey.svg';
import Avatar from 'components/avatar';
import { getIcon, isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';

const Select = (props) => {
  const {
    register,
    setValue,
    control,
    selectedValue,
    options,
    name,
    value,
    inputClassName,
    placeholder,
    error,
    onChange,
    onBlur,
    required,
    renderIcon,
    showGreenBorder,
    dropDownClassName,
    isAvatarSelect,
    isWhenSelect,
    iconStyle,
    isHaveIcon,
    selectedIconStyle,
    containerStyle,
    inputStyle,
    showText,
    text,
    onSubmit,
    selectedAnswer,
    readOnly,
    extraOption,
    labelDividerText,
    isSearchInsideDropDown,
    labelDividerTextClass,
    disabled,
    showSelectIcon,
    isBin,
    resetValue,
    isModalOpen,
    isSelectDisabled,
    isShowUserDetails,
    avatarStyle,
    overflow,
    ...rest
  } = props;

  const { myInformation } = useSelector((state) => state.myInfo);
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    !isSearchInsideDropDown ? value : ''
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [dropDownSerchValue, setDropDownSerchValue] = useState();
  const [selectedAnswerValue, setSelectedAnswerValue] =
    useState(selectedAnswer);
  const nodeRef = useRef();

  useEffect(() => {
    setSelectedAnswerValue(selectedAnswer);
  }, [selectedAnswer]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (resetValue) {
      setInputValue('');
    }
  }, [resetValue, isModalOpen]);

  useEffect(() => {
    if (!isSearchInsideDropDown) {
      setInputValue(value);
    }
  }, [value, isSearchInsideDropDown]);

  useClickOutside(() => setOpen(false), nodeRef.current);

  const toggle = () => {
    setOpen(!open);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (!isSearchInsideDropDown) {
      setInputValue(value);
    } else {
      setDropDownSerchValue(value);
    }

    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      )
    );
    if (setValue) {
      setValue(name, value, { shouldTouch: true });
    }
  };

  const isSelected = (optionValue, value) => {
    if (value && typeof value == 'object') {
      if (value.label === optionValue) return 'selected';
    }
    if (value && typeof value == 'string') {
      if (value === optionValue) return 'selected';
    }
  };

  const onOptionSelect = (option, onChange, onSubmit) => {
    if (control && setValue) {
      setValue(name, option.label, { shouldTouch: true });
    }
    if (onSubmit) {
      onSubmit(option);
    }
    if (onChange) {
      onChange(option);
    }
    if (isHaveIcon && isAvatarSelect) {
      setSelectedIcon(option.icon);
    }
    setInputValue(option.label);
    if (!isEmpty(selectedAnswerValue)) {
      setSelectedAnswerValue(option.label);
    }
    if (isSearchInsideDropDown) {
      toggle();
    }
  };

  const ControlledComponent = () => {
    return (
      <Controller
        control={control}
        name={name}
        rules={{ required }}
        render={({ field: { onChange, onBlur, value } }) => {
          return (
            <div
              style={
                open ? { height: 250, position: 'absolute', width: '100%' } : {}
              }
            >
              <div
                data-testid="select-controlled-dropdown"
                className={`dropdown-menu p-2 ${
                  open && !isSelectDisabled ? 'open' : ''
                } ${dropDownClassName ? dropDownClassName : ''} ${
                  overflow ? 'overflow-auto h-64' : ''
                }`}
                onBlur={onBlur}
              >
                {filteredOptions.map((option, idx) => {
                  return (
                    <div
                      data-testid="select-controlled-dropdown-list"
                      key={idx}
                      className={`dropdown-menu__list ${isSelected(
                        option?.label,
                        value
                      )}`}
                      onClick={() => onOptionSelect(option, onChange, onSubmit)}
                    >
                      {isHaveIcon && (
                        <img src={option?.icon} alt="icon" style={iconStyle} />
                      )}
                      {option.label}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        }}
      />
    );
  };

  return (
    <div
      data-testid="search-container"
      className="relative"
      onClick={isBin ? () => {} : !isSearchInsideDropDown ? toggle : () => {}}
      ref={nodeRef}
      style={containerStyle}
    >
      <InputField
        data-testid="search-input"
        name={name}
        className={`input-field--select ${inputClassName}`}
        placeholder={placeholder}
        value={
          !isEmpty(selectedAnswerValue) ? selectedAnswerValue : inputValue || ''
        }
        onChange={onSearch}
        onBlur={onBlur}
        error={error}
        selectedIcon={selectedIcon ? selectedIcon : null}
        showSuccessBorder={showGreenBorder}
        selectedIconStyle={selectedIconStyle}
        style={selectedIcon ? inputStyle : {}}
        register={register}
        readOnly={readOnly}
        disabled={disabled}
        onClick={isBin ? () => {} : toggle}
        {...rest}
      />
      {showText ? (
        <div className="input-select-svg-dropdown text-13 font-semibold text-primary cursor-pointer">
          {text}
        </div>
      ) : (
        <div className="input-select-svg-dropdown">
          {!disabled && <SelectIcon />}
          {disabled && showSelectIcon && <SelectIcon />}
        </div>
      )}
      {showGreenBorder && (
        <div className="input-select-svg-checked">
          <SelectedCheck />
        </div>
      )}
      {renderIcon && (
        <div className="input-select-svg-icon">{renderIcon()}</div>
      )}
      {control && <ControlledComponent />}
      {!control && !isWhenSelect && !isAvatarSelect && (
        <div
          style={
            open ? { height: 250, position: 'absolute', width: '100%' } : {}
          }
        >
          <div
            data-testid="select-dropdown-menu"
            className={`dropdown-menu ${open && !isSelectDisabled ? 'open' : ''}
          ${dropDownClassName ? dropDownClassName : ''}
          `}
            onBlur={onBlur}
          >
            {labelDividerText && (
              <span
                className={` text-xs font-medium ${labelDividerTextClass}`}
                style={{ padding: '9px 12px', marginBottom: '10px' }}
              >
                {labelDividerText}
              </span>
            )}
            {filteredOptions.map((option, idx) => {
              return (
                <div
                  key={idx}
                  className={`dropdown-menu__list ${isSelected(
                    option?.label,
                    selectedValue?.label
                  )}
                `}
                  onClick={() => onOptionSelect(option, onChange, onSubmit)}
                >
                  {isHaveIcon && getIcon(option?.icon, iconStyle)}
                  {option?.label}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!control && isAvatarSelect && (
        <div
          style={
            open ? { height: 280, position: 'absolute', width: '100%' } : {}
          }
        >
          <div
            data-testid="select-dropdown-menu"
            className={`dropdown-menu ${open && !isSelectDisabled ? 'open' : ''}
          ${dropDownClassName ? dropDownClassName : ''}
          `}
            onBlur={onBlur}
          >
            {filteredOptions.map((option, idx) => {
              return (
                <div
                  key={idx}
                  className={`dropdown-menu__list ${isSelected(
                    option?.label,
                    selectedValue?.label
                  )}`}
                  onClick={() => onOptionSelect(option, onChange)}
                >
                  <Avatar
                    className="mr-2"
                    src={option.avatarPic}
                    border={false}
                    initial={option.label}
                    initialStyle={{ fontSize: 'inherit' }}
                    style={avatarStyle}
                    auto
                    smaller
                    full
                  />
                  {option.label}
                  {isShowUserDetails && myInformation?.id === option?.value && (
                    <span className="text-13 font-medium text-grey-600 ml-1">
                      You
                    </span>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      )}
      {!control && isWhenSelect && (
        <div
          style={
            open ? { height: 250, position: 'absolute', width: '100%' } : {}
          }
        >
          <div
            data-testid="select-dropdown-menu"
            className={`dropdown-menu ${open && !isSelectDisabled ? 'open' : ''}
          ${dropDownClassName ? dropDownClassName : ''}
        `}
            onBlur={onBlur}
            style={{ maxHeight: '231px' }}
          >
            {isSearchInsideDropDown && (
              <div className="mt-3 mb-2" style={{ padding: '0px 8px' }}>
                <InputField
                  data-testid="search-input"
                  name={'search'}
                  className={`input-field--select ${inputClassName}`}
                  placeholder={'Search'}
                  value={dropDownSerchValue}
                  onChange={onSearch}
                  selectedIconStyle={selectedIconStyle}
                  renderIcon={() => (
                    <div
                      className="input-svg-wrapper"
                      style={{ top: '-7px', left: '-4px' }}
                    >
                      <SearchIcon />
                    </div>
                  )}
                  style={{ paddingLeft: '40px !important' }}
                />
              </div>
            )}
            {extraOption && (
              <>
                <div
                  className={`dropdown-menu__list ${isSelected(
                    extraOption?.label,
                    selectedValue?.label
                  )}`}
                  onClick={() => onOptionSelect(extraOption, onChange)}
                >
                  {extraOption?.label}
                </div>
              </>
            )}
            <div className="overflow-y-auto" style={{ height: '160px' }}>
              {labelDividerText && (
                <span
                  className={` text-xs font-medium ${labelDividerTextClass}`}
                  style={{ padding: '9px 12px', marginBottom: '10px' }}
                >
                  {labelDividerText}
                </span>
              )}
              {filteredOptions.map((option, idx) => {
                return (
                  <div
                    key={idx}
                    className={`dropdown-menu__list mt-2 ${isSelected(
                      option?.label,
                      selectedValue?.label
                    )}`}
                    onClick={() => onOptionSelect(option, onChange)}
                  >
                    {option.label}
                  </div>
                );
              })}
            </div>
          </div>
          <div
            data-testid="select-dropdown-menu"
            style={{ height: open ? '200px' : '' }}
            className={`dropdown-menu ${
              dropDownClassName ? dropDownClassName : ''
            } relative py-10
        `}
          ></div>
        </div>
      )}
    </div>
  );
};

Select.propTypes = {
  open: PropTypes.bool.isRequired,
  isAvatarSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  renderIcon: PropTypes.func,
  showGreenBorder: PropTypes.bool,
  isHaveIcon: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
  isWhenSelect: bool,
  isSelectDisabled: bool,
  isShowUserDetails: PropTypes.bool,
  avatarStyle: PropTypes.object,
  overflow: PropTypes.bool
};

Select.defaultProps = {
  open: false,
  placeholder: 'Select',
  inputClassName: '',
  register: () => {},
  required: false,
  isHaveIcon: false,
  showText: false,
  text: '',
  isWhenSelect: false,
  showSelectIcon: false,
  isSelectDisabled: false,
  isShowUserDetails: false,
  avatarStyle: {},
  overflow: false
};

export default Select;

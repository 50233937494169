import React from 'react'; //  { useState }
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';
import { ReactComponent as ArrowRight } from 'assets/icons/icon-arrow-right.svg';
import { ReactComponent as ArrowLeft } from 'assets/icons/arrow-left.svg';
// import { ReactComponent as Close } from 'assets/icons/icon-close-white.svg';
import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';
import styles from './index.module.scss';
import figureStyle from 'components/avatar/index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setHover } from 'slices/conversationSlice';
const ActiveUserBullet = (props) => {
  const { label, setIsModalOpen, isModalOpen, lead, isSms } = props;
  const dispatch = useDispatch();
  const { hover } = useSelector((state) => state.leadConversation);

  // const [hover, setHover] = useState(false);

  const onMouseEnter = () => dispatch(setHover(true));
  const onMouseLeave = () => dispatch(setHover(false));

  const onClick = () => {
    setIsModalOpen(!isModalOpen);
  };

  const getActiveStyle = () => {
    if (isModalOpen) {
      return {
        background: '#039855'
      };
    }
    return {};
  };

  return (
    <div
      className="inline-flex items-center border border-grey-400 cursor-pointer"
      style={{
        minHeight: 38,
        borderRadius: 30,
        paddingTop: 4,
        paddingBottom: 4,
        paddingLeft: 4,
        paddingRight: 10,
        maxWidth: '135px',
        minWidth: '135px',
        ...getActiveStyle()
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {isSms ? (
        <figure
          className={`${figureStyle.avatar_container} ${figureStyle.smaller} ${
            figureStyle.full
          } ${
            hover
              ? `${styles.avatar_hide}`
              : isModalOpen
              ? `${styles.avatar_hide}`
              : `${styles.avatar_show}`
          }`}
        >
          <IconMyProfile />
        </figure>
      ) : (
        <Avatar
          src={lead?.customer_avatar}
          initial={lead?.name || lead?.first_name}
          border={false}
          singleChar
          smaller
          cover
          className={`${
            hover
              ? `${styles.avatar_hide}`
              : isModalOpen
              ? `${styles.avatar_hide}`
              : `${styles.avatar_show}`
          }`}
        />
      )}
      <p
        className="text-13 font-medium ml-2 whitespace-nowrap overflow-ellipsis overflow-hidden"
        style={{ color: '#292929', width: '100%' }}
      >
        {!hover && !isModalOpen && <span>{label}</span>}
        {hover && !isModalOpen && (
          <span className="text-primary">View Contact</span>
        )}
        {isModalOpen && (
          <span className="text-primary flex">
            <span
              className={`${isModalOpen ? 'conversation-back-svg' : ''}`}
              style={{ padding: '5px 5px 5px 0' }}
            >
              <ArrowLeft />
            </span>
            <span
              style={isModalOpen ? { color: '#FFFFFF', fontWeight: '500' } : {}}
            >
              Conversation
            </span>
          </span>
        )}
      </p>
      <div
        className={`ml-2 ${
          hover ? `${styles.focus}` : isModalOpen ? styles.focus : ''
        }`}
      >
        {!isModalOpen && <ArrowRight />}
        {/* {isModalOpen && <Close />} */}
      </div>
    </div>
  );
};

ActiveUserBullet.propTypes = {
  src: PropTypes.string,
  label: PropTypes.string,
  isSms: PropTypes.bool
};

ActiveUserBullet.defaultProps = {
  isSms: false
};

export default ActiveUserBullet;

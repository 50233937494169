/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import UpgradePlanNotification from 'modules/modals/UpgradePlanNotification';
import { useSelector } from 'react-redux';
import {
  setShowSubscriptionModal,
  setShowDeleteAccountModal,
  setShowEnquiriesExpiredModal,
  setShowCancelAccountModal
} from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';
import useIsFreeEnquiriesExpired from 'hooks/useIsFreeEnquiriesExpired';
import CancelAccount from '../cancelAccount';
import DeleteAccount from '../deleteAccount';
import choices from 'choices';

const { CANCELLED } = choices.SubscriptionStatusChoices;

const FreeEnquiriesExpired = () => {
  const dispatch = useDispatch();
  const { isFreeEnquiriesExpired } = useIsFreeEnquiriesExpired();
  const { showEnquiriesExpiredModal, freeEnquiriesCount, currentPlan } =
    useSelector((state) => state.subscription);
  const {
    payment_failed = false,
    payment_completed = true
    // subscription_status = 1
  } = currentPlan || {};
  const { myInformation } = useSelector((state) => state.myInfo);

  useEffect(() => {
    if (payment_failed === false && payment_completed === false) {
      return;
    }
    if (
      isFreeEnquiriesExpired &&
      myInformation.subscription_status === CANCELLED
    ) {
      dispatch(setShowDeleteAccountModal(true));
      return;
    }
    if (isFreeEnquiriesExpired && currentPlan?.payment_failed === false) {
      dispatch(setShowEnquiriesExpiredModal(true));
    }
  }, [freeEnquiriesCount]);

  const onCancel = () => {
    dispatch(setShowEnquiriesExpiredModal(false));
    dispatch(setShowCancelAccountModal(true));
  };

  const onShowPlans = () => {
    dispatch(setShowEnquiriesExpiredModal(!showEnquiriesExpiredModal));
    dispatch(setShowSubscriptionModal(true));
  };

  return (
    <div
      style={{
        zIndex: 9999999999999
      }}
    >
      <UpgradePlanNotification
        open={showEnquiriesExpiredModal}
        onCancel={onCancel}
        onShowPlans={onShowPlans}
      />
      <CancelAccount />
      <DeleteAccount />
    </div>
  );
};

export default FreeEnquiriesExpired;

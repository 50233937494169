/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { ADD_WHATSAPP_TO_WAITLIST } from 'urls/toolbox';

export const toolboxSlice = createSlice({
  name: 'toolbox'
});

export default toolboxSlice.reducer;

export const AddWhatsAppToWaitList = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, callback } = payload;
      new APIRequest()
        .post(replaceUrl(ADD_WHATSAPP_TO_WAITLIST, 'subOrgId', subOrgId))
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

import pdfIcon from 'assets/icons/icon-file-upload-pdf.svg';
import pngIcon from 'assets/icons/icon-file-png.svg';
import docxIcon from 'assets/icons/icon-file-upload-docx.svg';
import defaultIcon from 'assets/icons/icon-file-upload-default.svg';
import jpegIcon from 'assets/icons/icon-file-jpeg.svg';
import xlsIcon from 'assets/icons/icon-file-xls.svg';
import { isEmpty } from './utils';

const FILE_SIZE = 4 * 1024 * 1024;
export const SUPPORTED_FORMATS = [
  'pdf',
  'doc',
  'ppt',
  'docx',
  'png',
  'jpeg',
  'jpg',
  'xlsx',
  'csv',
  'xml',
  'xls',
  'pptx'
];

export const fileIcons = {
  pdf: pdfIcon,
  png: pngIcon,
  docs: docxIcon,
  docx: docxIcon,
  doc: docxIcon,
  jpeg: jpegIcon,
  jpg: pngIcon,
  xlsx: xlsIcon,
  csv: defaultIcon,
  xml: xlsIcon,
  xls: xlsIcon,
  pptx: xlsIcon,
  ppt: xlsIcon
};

export const getFileExtension = (url) => {
  if (!url) return 'ppt';
  let urlSplit = url?.split('.') || [];
  return urlSplit?.slice(-1)[0].toLowerCase();
};

export const getFbFileExtension = (url) => {
  if (url.includes('.pdf')) {
    return 'pdf';
  }
  if (url.includes('.doc')) {
    return 'doc';
  }
  if (url.includes('.csv')) {
    return 'csv';
  }
  if (url.includes('.pptx')) {
    return 'pptx';
  }
  if (url.includes('.docx')) {
    return 'docx';
  }
  if (url.includes('.docs')) {
    return 'docs';
  }
  if (url.includes('.ppt')) {
    return 'ppt';
  }
  if (url.includes('.xlsx')) {
    return 'xlsx';
  }
  if (url.includes('.xls')) {
    return 'xls';
  }
  if (url.includes('.xml')) {
    return 'xml';
  }
};

export const getFbFileName = (url) => {
  let urlSplit = url?.split('/') || [];
  let getFileName = urlSplit[urlSplit.length - 1]?.split('?') || [];
  return !isEmpty(getFileName) ? getFileName[0] : 'File';
};

export const isValidFileFormat = (type) => {
  const extension = getFileExtension(type);
  return SUPPORTED_FORMATS.some((format) => {
    return extension.toLowerCase().includes(format);
  });
};

export const isValidFileSize = (fileSize) => {
  return fileSize <= FILE_SIZE;
};

export const convertAttachmentsStructure = (attachments = []) => {
  return attachments.map((item) => {
    return { ...item, isUploading: false };
  });
};

import { Route, Routes } from 'react-router-dom';
import EditProductInfo from './editProductInfo';
import ProductInfo from '.';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getCurrenciesData } from 'slices/businessInfoSlice';

const ProductHome = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState();

  const getCurrencies = () => {
    setIsLoading(true);
    Promise.all([dispatch(getCurrenciesData())]).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  if (isLoading) {
    return <></>;
  }
  return (
    <Routes>
      <Route path="/" element={<ProductInfo />}></Route>
      <Route path="/:productId/*" element={<EditProductInfo />} />
    </Routes>
  );
};

export default ProductHome;

import React from 'react';
import { ReactComponent as FbLeadAdsIcon } from 'assets/icons/icon-fb-lead-channels-new.svg';
import { ReactComponent as InstaIcon } from 'assets/icons/Icon-button-Insta.svg';
import { ReactComponent as FbIcon } from 'assets/icons/icon-button-fb.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as ReconnectErrorIcon } from 'assets/icons/icon-reconnect-error.svg';
import { ReactComponent as LearnMoreIcon } from 'assets/icons/icon-whatsapp-learn-more.svg';
import { ReactComponent as NewTabIcon } from 'assets/icons/icon-new-tab-white.svg';
import CHOICES from 'choices';

const { FACEBOOK, INSTAGRAM, FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const Reconnect = (props) => {
  const { channelType, description, onReconnect, isLoading } = props;
  return (
    <div className="border border-grey-400 border-radius-14 py-5 px-6">
      <div
        className="py-3 px-4 rounded-lg flex items-center"
        style={{
          border: '1px solid rgba(240, 68, 56, 0.25)',
          background: 'rgba(240, 68, 56, 0.07)'
        }}
      >
        <ReconnectErrorIcon />
        <p className="ml-3 text-13 text-red-900">{description}</p>
      </div>
      <CustomColorButtonWithLogo
        label="Reconnect your account"
        className={`btn--sm ${
          channelType === FACEBOOK
            ? 'btn--facebook'
            : channelType === INSTAGRAM
            ? 'btn--instagram'
            : 'btn--facebook'
        } gap-2 mt-5 text-15`}
        renderSvg={() =>
          channelType === FACEBOOK ? (
            <FbIcon />
          ) : channelType === INSTAGRAM ? (
            <InstaIcon />
          ) : (
            <FbLeadAdsIcon />
          )
        }
        renderRightSvg={() => <NewTabIcon />}
        onClick={onReconnect}
        isLoading={isLoading}
        enableLoader={true}
        loaderInline={true}
        isLoaderWhite={true}
      />
      <p className="mt-4 text-sm text-grey-800">
        A new tab will open and ask you to login with{' '}
        {channelType === FACEBOOK
          ? 'Facebook'
          : channelType == INSTAGRAM
          ? 'Instagram'
          : channelType === FACEBOOK_LEAD_ADS
          ? 'Facebook lead ads'
          : ''}
      </p>
      <div
        className="flex rounded-lg py-6 px-5 items-center mt-7"
        style={{ background: 'rgba(217, 217, 217, 0.26)' }}
      >
        <LearnMoreIcon className="mr-2" />
        <p className="text-sm">
          Having trouble connecting this channel? Read our{' '}
          <span className="font-medium text-primary">
            troubleshooting guide
          </span>
        </p>
      </div>
    </div>
  );
};

export default Reconnect;

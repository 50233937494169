import React from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import IncomingEnquiryReport from 'modules/enquirybox/reports/modules/report/incomingenquiryreport';
import SourceReport from 'modules/enquirybox/reports/modules/report/sourcereport';
import SalesReport from 'modules/enquirybox/reports/modules/report/salesreport';
import ReportHeader from '../component/reportHeader';
import NavLink from '../component/navLink';
import { ReactComponent as IconIncoming } from 'assets/icons/icon-incoming-header-report.svg';
import { route } from 'utils/route';
import Wrapper from '../wrapper';
import usePlanAccess from 'hooks/usePlanAccess';

const Incoming = () => {
  const { has_sales_report, has_source_report } = usePlanAccess();
  return (
    <section className="dashboard__main">
      <ReportHeader
        title="All Boxes report"
        renderSvg={() => <IconIncoming />}
      />
      <div
        className="dashboard__main__body"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <div className="dashboard__main__body__section-left">
          <NavLink isIncoming={true} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Routes>
            <Route
              path="/"
              element={
                <Navigate
                  to={`${route.report}${route.boxReport}/all-box/incoming-enquiry-report`}
                />
              }
            />
            <Route
              path="/incoming-enquiry-report"
              element={
                <Wrapper>
                  <IncomingEnquiryReport />
                </Wrapper>
              }
            />
            <Route
              path="/sales-report"
              element={
                <Wrapper showUpgradeTopbar={!has_sales_report}>
                  <SalesReport hideCount={!has_sales_report} />
                </Wrapper>
              }
            />
            <Route
              path="/source-report"
              element={
                <Wrapper showUpgradeTopbar={!has_source_report}>
                  <SourceReport hideCount={!has_source_report} />
                </Wrapper>
              }
            />
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default Incoming;

import React, { useEffect } from 'react';
import computer from 'assets/icons/icon-ai-computer.svg';
import home from 'assets/icons/icon-ai-home.svg';
import chat from 'assets/icons/icon-ai-chat-color.svg';
import file from 'assets/icons/icon-ai-file.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getBrainCount } from 'slices/ai-assistant/brain/slice';
import { setBrainErrors } from 'slices/ai-assistant/assistant/slice';
import { isEmpty } from 'utils/utils';

const BrainTab = () => {
  const { assistant_id } = useParams();
  const brainData = useSelector((state) => state.brainPage.brainData);
  const brainErrors = useSelector((state) => state.assistant.brainErrors);
  const dispatch = useDispatch();
  const errorMain = useSelector((state) => state.assistant.errorMain);

  useEffect(() => {
    dispatch(getBrainCount(assistant_id));
  }, []);

  const botsBrainList = [
    {
      id: 'website-links',
      icon: computer,
      title: 'Website links',
      desc: 'Add your entire website or links to specific pages',
      count: brainData.website_links
    },
    {
      id: 'product-info',
      icon: home,
      title: 'Product or service information',
      desc: 'Add files and documents like brochures & product info',
      count: brainData?.product_or_service
    },
    {
      id: 'q-and-a',
      icon: chat,
      title: 'Questions and answers',
      desc: 'Add files and documents like brochures & product info',
      count: brainData?.qna
    },
    {
      id: 'files',
      icon: file,
      title: 'Files',
      desc: 'Add files and documents like brochures & product info',
      count: brainData?.file_content
    }
  ];

  const navigate = useNavigate();

  useEffect(() => {
    // checking if save is clicked or not
    if (!isEmpty(errorMain) && 'brain' in errorMain) {
      // checking if braindata is empty
      if (!isEmpty(brainData)) {
        let getLength = Object.fromEntries(
          Object.entries(brainData).filter(([, val]) => val !== 0)
        );
        // checking if any data is saved in brain tab
        if (Object.keys(getLength)?.length > 0) {
          dispatch(
            setBrainErrors({
              content: true
            })
          );
        }
        // if no data is saved and brain tab is non empty else block will run
        else {
          dispatch(
            setBrainErrors({
              content: false
            })
          );
        }
      }
      // if brain tab has no data and is empty else block will run
      else {
        dispatch(
          setBrainErrors({
            content: false
          })
        );
      }
    }
    // if save is not clicked else will run
    else {
      dispatch(setBrainErrors({}));
    }
  }, [brainData]);

  return (
    <section className="" style={{ padding: '25px 0' }}>
      <div style={{ padding: '0 38px 40px' }}>
        <label className="input-field-label mb-0">
          Feed your bots brain with information
        </label>
        <p className="text-grey-800 text-base mt-3">
          Below is a list of the information that has been learned and synced by
          your bots brain
        </p>
        {/* checking whether brainerrors is present and content is false whenever save is clicked */}
        {!isEmpty(brainErrors) &&
          'content' in brainErrors &&
          !brainErrors?.content && (
            <p
              className="text-base mt-3"
              style={{
                color: '#F55E5E',
                fontFamily: 'SF Pro Text'
              }}
            >
              Please feed your bots brain with at least one type of information
              below
            </p>
          )}

        <ul className="bots-brain-list-wrapper">
          {botsBrainList.map((item, index) => (
            <li
              className="bots-brain-list"
              key={index}
              onClick={() =>
                navigate(
                  `/chatbot-ai/assistant/${assistant_id}/brain/${item.id}`
                )
              }
            >
              <div
                className="flex items-center justify-center flex-shrink-0"
                style={{ minWidth: 33 }}
              >
                <img src={item.icon} alt="icon" />
              </div>
              <div className="flex flex-col ml-4 pr-8">
                <h4 className="text-xl font-semibold">{item.title}</h4>
                <p className="text-grey-800">{item.desc}</p>
              </div>
              <div
                className="flex items-center justify-center flex-shrink-0 w-9 h-9 text-2xl font-semibold ml-auto rounded-full"
                style={{ backgroundColor: '#FCFBF8' }}
              >
                {/* {item.id === 'website-links' && brainData?.website_links}
                {item.id === 'product-info' && brainData?.product_or_service}
                {item.id === 'questions-and-answers' && brainData?.qna}
                {item.id === 'files' && brainData?.file_content}
                {item.id === 'copy-and-pasted-or-written-text' && brainData?.written_text} */}
                {item.count}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};

export default BrainTab;

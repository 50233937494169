import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { NavLink } from 'react-router-dom';
import {
  getVersionList,
  setBrainErrors,
  setCurrentVersionId,
  setDataCollectionErrors,
  setErrorMain,
  setHeaderErrorCount,
  setIsError,
  setPersonalityErrors,
  setVersionStatus,
  setWelcomeErrors
} from 'slices/ai-assistant/assistant/slice';
import Img from 'components/img';
import dotIcon from 'assets/icons/threeDontIcon.svg';
import useClickOutside from 'hooks/useOutsideClick';
import dupliateIcon from 'assets/icons/duplicateIcon.svg';
import deleteIcon from 'assets/icons/deleteIcon.svg';
import {
  getAllPersonalityFields,
  setAvatarUrl
} from 'slices/ai-assistant/personality/slice';
import { resetPersonalityFields } from 'slices/ai-assistant/personality/slice';
import Avatar from 'components/avatar';
import { resetWelcomePage } from 'slices/ai-assistant/welcome/slice';
import { resetDataCollection } from 'slices/ai-assistant/dataCollection/slice';
import { resetFileData } from 'slices/ai-assistant/fileUpload/slice';
import { resetProductInfoFields } from 'slices/ai-assistant/productInfo/slice';
import { resetWebsiteLinksData } from 'slices/ai-assistant/websiteLinks/slice';
import { resetQnaData } from 'slices/ai-assistant/qna/slice';

const BotListing = (props) => {
  const {
    id, //botversion id
    name,
    avatar,
    industry,
    item,
    handleDelete,
    handleDuplicate,
    lastIndex,
    callOnMount,
    callOnUnmount,
    isActiveBot
  } = props;
  const dispatch = useDispatch();
  const nodeRef = useRef();
  const [open, setOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const parentRef = useRef();

  const clickOutside = () => {
    setOpen(false);
    setIsActive(false);
  };

  useClickOutside(clickOutside, nodeRef.current);

  useEffect(() => {
    const divHeight = parentRef.current.offsetHeight;
    callOnMount(divHeight);
    return () => {
      callOnUnmount(divHeight);
    };
  }, []);

  const handleClick = () => {
    dispatch(setErrorMain({}));
    dispatch(setPersonalityErrors({}));
    dispatch(setWelcomeErrors({}));
    dispatch(setDataCollectionErrors({}));
    dispatch(setBrainErrors({}));
    dispatch(setHeaderErrorCount(0));
    dispatch(setCurrentVersionId(item.id));
    dispatch(setVersionStatus(item?.version_status));
    dispatch(getVersionList(item?.version_bot.id));
    dispatch(getAllPersonalityFields(item?.version_bot.id));
    // if(item.status === PUBLISHED){
    //   dispatch(getVersionList(item.id));
    // }
    dispatch(resetPersonalityFields());
    dispatch(resetWelcomePage());
    dispatch(resetDataCollection());
    dispatch(resetFileData());
    dispatch(resetProductInfoFields());
    dispatch(resetWebsiteLinksData());
    dispatch(resetQnaData());
    dispatch(setIsError({}));
  };

  useEffect(() => {
    if (isActiveBot) dispatch(setAvatarUrl(avatar?.s3_url));
  }, [isActiveBot]);

  return (
    <>
      <div
        key={item.id}
        className="relative bot-assistant-list-hover"
        ref={parentRef}
        id={item.version_bot.id}
      >
        <NavLink
          className={({ isActive, isPending }) =>
            isPending
              ? 'bot-assistant-list'
              : isActive
              ? 'bot-assistant-list active'
              : 'bot-assistant-list'
          }
          to={`/chatbot-ai/assistant/${id}`} //bot_verison id destructured in parent component
          onClick={handleClick}
        >
          <div className="flex" style={{ maxWidth: '95%' }}>
            <Avatar
              className="avatar-container avatar-container--cover"
              style={{
                width: 40,
                height: 40,
                boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
              }}
              src={avatar?.s3_url}
              alt="profile"
              singleChar
              initial={name}
            />

            <div className="ml-3">
              <h3 className="text-lg font-semibold">{name}</h3>
              {/* <p className="text-grey-800">Website EnquiryBot</p> */}
              <p className="text-grey-800">{industry}</p>
            </div>
          </div>
        </NavLink>
        <div
          ref={nodeRef}
          style={{
            position: 'absolute',
            top: '22px',
            right: '12px'
          }}
        >
          <div
            className={`ai_assistant_delete_icon ${isActive ? 'active' : ''}`}
            onClick={() => {
              setIsActive(!isActive);
              setOpen(!open);
            }}
          >
            <img
              className="mt-3"
              src={dotIcon}
              style={{ marginLeft: '0.35rem' }}
            />
          </div>
          <div>
            <div
              className={`dropdown-menu dropdown-menu--more ${
                open ? 'open' : ''
              }`}
              style={
                lastIndex
                  ? {
                      top: '-82px'
                    }
                  : {}
              }
            >
              <div
                className="dropdown-menu__list"
                onClick={() => {
                  setOpen(false);
                  setIsActive(false);
                  handleDuplicate(id);
                }}
              >
                <Img
                  src={dupliateIcon}
                  alt="duplicate"
                  className="mr-2"
                  style={{ width: 16, height: 16 }}
                />
                Duplicate
              </div>
              <div
                className="dropdown-menu__list"
                onClick={() => {
                  setIsActive(false);
                  setOpen(false);
                  handleDelete(id);
                }}
              >
                <Img
                  src={deleteIcon}
                  alt="delete"
                  className="mr-2"
                  style={{ width: 16, height: 16 }}
                />
                <span style={{ color: 'var(--red-900, #F04438)' }}>Delete</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BotListing;

/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { ReactComponent as PrintIcon } from 'assets/icons/icon-report-print.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/icon-report-share.svg';
import { ReactComponent as ShareIconWhite } from 'assets/icons/icon-report-share-white.svg';
import { ReactComponent as IconOut } from 'assets/icons/icon-out.svg';
import CustomToolTip from 'components/CustomTooltip';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import TextInputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import useClickOutside from 'hooks/useOutsideClick';
import GuideDropdown from 'components/guideDropdown';
import { reportGuideOptions } from 'utils/guide';

const ReportHeader = (props) => {
  const { renderSvg, title } = props;
  const location = useLocation();
  const { box_id } = useParams();
  const dropdownRef = useRef();
  const { openSuccessSnackBar } = useSnackbar();
  const [openShareDropdown, setOpenShareDropdown] = useState(false);
  const { tempStartDate, tempEndDate, tempDateFormat } = useSelector(
    (state) => state.reports
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useClickOutside(() => setOpenShareDropdown(false), dropdownRef.current);

  const report_type = location.pathname.includes('incoming-enquiry-report')
    ? 'incoming_report'
    : location.pathname.includes('sales-report')
    ? 'sales_report'
    : location.pathname.includes('source-report')
    ? 'source_report'
    : location.pathname.includes('box')
    ? 'box_report'
    : 'team_report';

  const onShareIt = () => {
    setOpenShareDropdown(!openShareDropdown);
  };

  const PUBLIC_REPORT_LINK = `${process.env.REACT_APP_PUBLIC_REPORT_URL}/${report_type}?date_format=${tempDateFormat}&startDate=${tempStartDate}&endDate=${tempEndDate}&subOrgId=${subOrganizationId}&boxId=${box_id}`;

  const onCopyLink = () => {
    navigator.clipboard.writeText(PUBLIC_REPORT_LINK);
    openSuccessSnackBar('Report share link copied', 'large');
  };

  const handleOpenLink = () => {
    window.open(PUBLIC_REPORT_LINK);
  };
  const ContentChildren = () => {
    return (
      <>
        <CustomColorButtonWithLogo
          label="Share"
          className={`btn--xs-28 text-13 ml-2 gap-1 ${
            openShareDropdown ? 'bg-primary text-white' : ''
          }`}
          renderSvg={() =>
            openShareDropdown ? <ShareIconWhite /> : <ShareIcon />
          }
          style={{ fontWeight: 500, padding: '4px 8px 4px 8px' }}
          onClick={onShareIt}
        />
        <div
          className={`dropdown-menu ${openShareDropdown ? 'open' : ''} right-0`}
          style={{
            minWidth: 357,
            maxWidth: 357,
            left: 'auto',
            top: 63,
            right: 30
          }}
        >
          <div className="py-4 px-5 text-15 text-grey-800">
            Copy the link below to share this live report. No login is required
            to view the report.
          </div>
          <div className="bg-grey-90 pt-4 px-5 pb-5">
            <div className="flex">
              <h3 className="text-13 font-semibold mb-2">Report share link</h3>
              <div
                className="justify-end ml-auto cursor-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenLink();
                }}
              >
                <CustomToolTip
                  position="top"
                  tooltipText="Open in new tab"
                  tooltipStyle={{ maxWidth: 160, left: '30%' }}
                >
                  <IconOut />
                </CustomToolTip>
              </div>
            </div>

            <TextInputField
              name="shareReport"
              className="input-field input-field--md-38"
              style={{ fontSize: 13, paddingRight: 80 }}
              placeholder=""
              value={PUBLIC_REPORT_LINK}
              readOnly
            >
              <PrimaryButton
                type="submit"
                label="Copy"
                style={{
                  position: 'absolute',
                  top: 4,
                  right: 5,
                  padding: '6px 18px'
                }}
                className="btn--xs-28 text-xs"
                onClick={onCopyLink}
              />
            </TextInputField>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="dashboard__main__header"
      style={{ padding: '20px 35px 20px 40px', height: 91 }}
    >
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center">
          <figure
            className="avatar-container avatar-container--contain"
            style={{ width: 50, height: 50 }}
          >
            {renderSvg && typeof renderSvg === 'function' && renderSvg()}
          </figure>
          <div className="ml-5">
            <h3 className="text-grey-900 text-xl font-bold">{title}</h3>
          </div>
        </div>
        <div className="flex items-center" ref={dropdownRef}>
          <div className="search-container">
            {/* <div className="flex items-center cursor-pointer">
              <PrintIcon />
              <p className="text-13 font-medium ml-1">Print</p>
            </div> */}
            <GuideDropdown
              description="Simple reports to give you a clear idea of how your business and team are performing."
              guideOptions={reportGuideOptions}
            />
            {!openShareDropdown && (
              <CustomToolTip
                position="bottom"
                tooltipText="Share a link to this report. No login needed to view it."
                tooltipStyle={{ maxWidth: 160, left: '30%' }}
              >
                <ContentChildren />
              </CustomToolTip>
            )}
            {openShareDropdown && <ContentChildren />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportHeader;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/icon-edit-primary-color.svg';
import { useSnackbar } from 'components/Snackbar';
// import { getLeadData } from 'slices/conversationSlice';
import { updateContactDetailsInBox } from 'slices/boxes/slice';
import { getLeadData } from 'slices/conversationSlice';
import { setIsLoading, updateContactDetails } from 'slices/questionSlice';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmailValid, isEmpty } from 'utils/utils';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';

const EmailItem = ({ email, isBin }) => {
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [emailValue, setEmailValue] = useState(email);
  const [inputError, setInputError] = useState('');
  const { activeLead } = useSelector((state) => state.leadConversation);

  useEffect(() => {
    if (!isEmpty(activeLead)) {
      setEmailValue(activeLead?.email);
    }
  }, [activeLead]);

  const callback = () => {
    setIsEdit(false);
    // setIsEmailLoading(false);
    dispatch(setIsLoading(false));
    // dispatch(getLeadData({ leadId: cardId }));
  };

  // const onEmailSubmit = async (data) => {
  //   setEmailValue(data.email);
  //   setIsEmailLoading(true);
  //   const formData = {
  //     email: data.email
  //   };
  //   if (isBox) {
  //     dispatch(
  //       updateContactDetailsInBox({
  //         body: formData,
  //         leadId: cardId,
  //         callback: callback
  //       })
  //     );
  //   }
  //   if (!isBox) {
  //     dispatch(
  //       updateContactDetails({
  //         body: formData,
  //         leadId: cardId,
  //         callback: callback
  //       })
  //     );
  //   }
  //   dispatch(setIsLoading(false));
  //   dispatch(getLeadData({ leadId: cardId }));
  // };

  const onChange = (e) => {
    setInputError('');
    const value = e.target.value;
    setEmailValue(value);
  };

  const onEmailSubmit = (e) => {
    e.preventDefault();
    if (!isEmpty(emailValue)) {
      if (emailValue === email) {
        setIsEdit(false);
      } else {
        if (isEmailValid(emailValue)) {
          dispatch(setIsLoading(true));
          const formData = {
            email: emailValue
          };
          // dispatch(
          //   updateContactDetails({
          //     body: formData,
          //     leadId: cardId,
          //     callback: callback
          //   })
          // );
          if (isBox) {
            dispatch(
              updateContactDetailsInBox({
                body: formData,
                leadId: cardId,
                callback: callback
              })
            );
          }
          if (!isBox) {
            dispatch(
              updateContactDetails({
                body: formData,
                leadId: cardId,
                callback: callback
              })
            );
          }
        } else {
          setInputError('email is not valid');
        }
      }
    } else {
      setInputError('email is required');
    }
  };

  const onCancel = () => {
    setIsEdit(false);
    setInputError('');
    setEmailValue(email);
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(email);
    openSuccessSnackBar('Email copied');
  };

  return (
    <>
      <form autoComplete="off">
        {isEdit ? (
          <div className="relative">
            <TextInputField
              name="email"
              className="input-field--md"
              onChange={onChange}
              value={emailValue || ''}
              error={!isEmpty(inputError) ? true : false}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onEmailSubmit(e);
                }
              }}
              placeholder=""
            />
            <CustomColorButton
              className="btn--xs btn--input-cancel btn--input-cancel--sm btn--grey text-xs"
              label="Cancel"
              onClick={onCancel}
            />
            <PrimaryButton
              label="Save"
              className="btn--xs btn--copy btn--copy--xs text-xs"
              onClick={(e) => {
                onEmailSubmit(e);
              }}
            />
          </div>
        ) : (
          <div
            className="flex justify-between items-center bg-color-on-hover bg-color-on-hover--sm__left bg-color-on-hover--bg-grey-100 bg-color-on-hover--br-6 mt-1"
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
            style={{ paddingBottom: 6, paddingTop: 6 }}
          >
            <p>
              {showEditIcon && !isEmpty(email) ? (
                <span
                  className="font-medium text-sm text-primary"
                  onClick={copyToClipboard}
                >
                  Click to copy:{' '}
                </span>
              ) : (
                <span className="font-medium text-sm">Email: </span>
              )}
              <span className="text-sm font-normal">{emailValue}</span>
            </p>
            {showEditIcon && !isBin && (
              <EditIcon
                className="cursor-pointer mr-3"
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
        )}
      </form>
    </>
  );
};

export default EmailItem;

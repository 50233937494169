import TextArea from 'components/inputs/textarea';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import TextInputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'utils/utils';
import { useForm } from 'react-hook-form';
import { ReactComponent as RedDeleteIcon } from 'assets/icons/icon-bin-it-red.svg';
import BackArrow from '../../components/backArrow';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import CustomColorButton from 'components/buttons/customColorButton';
import OverLayLoader from 'components/loader/overLayLoader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import Avatar from 'components/avatar';
import { showNameEmail } from 'utils/aiAssistant';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import { deleteQna, getQna, updateQna } from 'slices/ai-assistant/qna/slice';
import { useSnackbar } from 'components/Snackbar';
import { formatDateTime } from 'utils/dateFormat';

const EditQna = () => {
  const { qnaId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const singleQnaData = useSelector((state) => state.qna.singleQnaData);
  const id = useSelector((state) => state?.myInfo?.myInformation?.id);
  const updateQnaCallback = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getQna(qnaId, updateQnaCallback));
  }, []);

  const formSchema = {
    question: string().required('Please provide question'),
    answer: string().required('Please provide answer'),
    link: string().matches(urlFieldValidation, {
      message: 'Please provide valid link url',
      excludeEmptyString: true
    })
  };

  const {
    register,
    reset,
    getValues,
    formState: { errors, touchedFields }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(object().shape(formSchema))
    // mode: 'all'
  });
  const { name: userName, email, avatar } = getValues('last_edited_by') || {};

  useEffect(() => {
    if (!isEmpty(singleQnaData)) {
      const {
        question,
        answer,
        link,
        last_edited_on,
        times_used,
        last_edited_by
      } = singleQnaData;
      reset({
        question,
        answer,
        link,
        last_edited_on,
        last_edited_by,
        times_used
      });
    }
    return () => {
      reset({
        question: '',
        answer: '',
        link: ''
      });
    };
  }, [singleQnaData]);

  const successCb = (type) => {
    setIsLoading(false);
    if (type === 'update') {
      openSuccessSnackBar('Updated Successfully');
    } else if (type === 'delete') {
      openSuccessSnackBar('Deleted Successfully');
    }
    navigate(-1);
  };

  const onRemove = () => {
    setIsLoading(true);
    dispatch(deleteQna(qnaId, successCb, failedCb));
  };

  const failedCb = () => {
    setShowDelete(false);
    setIsLoading(false);
  };
  const onSubmit = async () => {
    if (!isEmpty(errors)) return;
    setIsLoading(true);
    const { question, answer, link } = getValues();

    const payload = {
      question,
      answer,
      link,
      edited_by_id: id // loggedin user id from myInfo
    };
    dispatch(updateQna(payload, qnaId, successCb, failedCb));
  };

  const showAvatar = () => {
    if (!isEmpty(getValues('last_edited_by'))) {
      if (!isEmpty(avatar) || !isEmpty(userName)) {
        return (
          <Avatar
            className="avatar-container avatar-container--full mr-2"
            style={{
              width: 22,
              height: 22,
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
            src={avatar}
            initial={userName}
            singleChar
            small
            initialStyle={{ fontSize: '12px' }}
          />
        );
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <BackArrow>
        <div className="flex">
          <CustomColorButtonWithLogo
            label="Delete"
            renderSvg={() => <RedDeleteIcon />}
            className="w-[99.6px] bg-[#FEF2F2] !font-medium h-[42px] text-[16.8px] text-[#EB4F54] gap-1 mr-2 hover:bg-[#f8d4d5]"
            style={{ padding: '7px 12px' }}
            onClick={() => setShowDelete(true)}
            enableLoader
            isLoading={false}
          />
          <CustomColorButton
            label="Save & Sync"
            className="btn--wide-md-40 text-base-1 bg-[#5e60ed94] h-[42px] text-white mr-3 cursor-pointer relative hover:bg-[#585aebc2] "
            onClick={onSubmit}
            type="submit"
            enableLoader={isLoading}
          />
        </div>
      </BackArrow>
      <div className="mx-auto p-0 mt-5" style={{ width: 670 }}>
        <div className="mt-4">
          <label className="block text-base-1 font-semibold mb-1">
            Question
          </label>
          <TextInputField
            autoComplete={'off'}
            name="question"
            type="text"
            placeholder="Add your question here"
            className="input-field input-field--border2 input-field--h48"
            error={errors.question && touchedFields.question}
            register={register}
          />
          {errors.question && touchedFields.question && (
            <ErrorMessage message={errors?.question?.message} />
          )}
        </div>
        <div className="mt-4">
          <label className="block text-base-1 font-semibold mb-1">Answer</label>
          <TextArea
            name="answer"
            placeholder="Add your answer here"
            className="input-field--border2 resize-none"
            error={errors.answer && touchedFields.answer}
            register={register}
          ></TextArea>
          {errors.answer && touchedFields.answer && (
            <ErrorMessage message={errors?.answer?.message} />
          )}
        </div>
        <div className="mt-4">
          <label className="block text-base-1 font-semibold mb-1">
            Add a link
          </label>
          <TextInputField
            autoComplete={'off'}
            name="link"
            type="text"
            placeholder="Add a link to more info or a booking page"
            className="input-field input-field--border2 input-field--h48"
            error={errors.link && touchedFields.link}
            register={register}
          />
          {errors.link && touchedFields.link && (
            <ErrorMessage message={errors?.link?.message} />
          )}
        </div>
        <div className="mt-7 p-8 border border-box-border border-radius-10">
          <div className="flex flex-wrap -mx-4">
            <div className="col-4 px-4" style={{ color: '#727272' }}>
              Last edited by
            </div>
            <div className="col-8 px-4">
              <div className="flex items-center">
                {showAvatar()}
                <p className={!isEmpty(avatar) ? 'ml-2' : ''}>
                  {showNameEmail(userName, email)}
                </p>
              </div>
            </div>
            <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
              Last edited on
            </div>
            <div className="col-8 px-4 mt-5">
              <p className="">
                {!isEmpty(singleQnaData.last_edited_on) &&
                  formatDateTime(singleQnaData.last_edited_on, 'dd mmm, yyyy')}
              </p>
            </div>
            <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
              Times used in answers
            </div>
            <div className="col-8 px-4 mt-5">
              <p className="">{singleQnaData.times_used}</p>
            </div>
          </div>
        </div>
        <DeleteConfirmation
          showConfirmation={showDelete}
          onCancel={() => setShowDelete(false)}
          onConfirm={() => onRemove()}
          title="Are you sure you want to delete?"
          description=""
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default EditQna;

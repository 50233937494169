/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useRef, useState, useEffect } from 'react';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnswer,
  DeleteQuestion,
  updateAnswer,
  updateChoiceSet
} from 'slices/questionSlice';
import QuestionHeader from '../../../../component/questionHeader';
import iconAUD from 'assets/icons/icon-currency-aud.svg';
import iconUSD from 'assets/icons/icon-currency-us-dollar.svg';
import iconEURO from 'assets/icons/icon-currency-euro.svg';
import iconAED from 'assets/icons/icon-currency-aed.svg';
import iconPOUND from 'assets/icons/icon-currency-pound.svg';
import { Loader } from 'components/loader';
import { isEmpty, isNumberWithDecimal } from 'utils/utils';
import TextInputField from 'components/inputs/textField';
import CHOICES from 'choices';
import _debounce from 'lodash.debounce';
import Error from 'components/errorMessage';
import { useParams } from 'react-router-dom';
import useClickOutside from 'hooks/useOutsideClick';
import OverLayLoader from 'components/loader/overLayLoader';
import { getStageContractValue } from 'slices/boxes/slice';
import { getCurrencyText } from 'utils/currency';

const { GBP, EUR, USD, AUD, AED } = CHOICES.CurrencyChoices;

const ValueQuestionAdded = ({
  questionData,
  title,
  answer,
  deleteQuestion,
  isBin
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId } = useParams();
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValue, setInputValue] = useState(
    !isEmpty(answer) ? answer[0]?.answer?.description : ''
  );
  const [error, setError] = useState('');
  const { myInformation } = useSelector((state) => state.myInfo);
  const isBox = !isEmpty(stageId);
  useClickOutside(() => setOpen(false), dropdownRef.current);

  useEffect(() => {
    if (!isEmpty(answer)) {
      setInputValue(answer[0]?.answer?.description);
    }
  }, [answer]);

  const renderIcon = (value) => {
    switch (value) {
      case GBP:
        return iconPOUND;
      case EUR:
        return iconEURO;
      case USD:
        return iconUSD;
      case AUD:
        return iconAUD;
      case AED:
        return iconAED;
      default:
        return <></>;
    }
  };
  const CurrencyOptions = CHOICES.CurrencyChoices.CHOICE_LIST.map((item) => {
    return {
      label: item.label,
      value: item.value,
      icon: renderIcon(item.value)
    };
  });

  const getByPlaceholderText = (questionData) => {
    switch (questionData?.choice_set[0]?.text) {
      case GBP:
        return '£ 0.00';
      case EUR:
        return '€ 0.00';
      case USD:
        return '$ 0.00';
      case AUD:
        return 'AU$ 0.00';
      case AED:
        return ' د.إ 0.00';
      default:
        return '0.00';
    }
  };

  // const onAnswerChange = (value) => {
  //   setInputValue(value);
  //   onHandleSubmit(value);
  // };

  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = () => {
    setIsLoading(false);
    if (stageId) {
      dispatch(getStageContractValue({ stageId }));
    }
  };

  const onDeleteCallback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    deleteQuestion(questionId);
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(
      DeleteQuestion({ questionId: questionId, callback: onDeleteCallback })
    );
  };

  const onAnswerChange = (value) => {
    setInputValue(value);
    setError('');
  };

  const onSubmit = () => {
    const previousAnswer = answer[0]?.answer?.description;
    if (previousAnswer === inputValue) return;
    if (inputValue === '') {
      setError('Answer cannot be empty');
    } else {
      if (isEmpty(answer)) {
        setIsLoading(true);
        const formData = {
          answer: { description: inputValue },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(createAnswer({ body: formData, stageId, callback }));
      } else {
        setIsLoading(true);
        const formData = {
          answer: { description: inputValue },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(
          updateAnswer({
            body: formData,
            answerId: answer[0]?.id,
            callback,
            stageId
          })
        );
      }
    }
  };

  // const onHandleSubmit = useCallback(_debounce(onSubmit, 1500), []);

  const UpdateChoiceCallback = () => {
    setOpen(false);
    setIsLoading(false);
  };

  const onOptionSelect = async (option) => {
    if (questionData?.choice_set[0]?.text === option?.value) {
      setOpen(false);
    } else {
      setIsLoading(true);
      const formData = {
        text: option?.value,
        position: 0
      };
      dispatch(
        updateChoiceSet({
          body: formData,
          choiceId: questionData?.choice_set[0]?.id,
          callback: UpdateChoiceCallback
        })
      );
    }
  };

  return (
    <>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          showConfirmation={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this question?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ height: 'max-content' }}
          isLoading={isLoading}
        />
      )}
      <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
        {isLoading && <OverLayLoader />}
        <QuestionHeader
          label={title}
          isShowMoreIcon={true}
          onDelete={onDeleteQuestion}
          id={questionData.id}
          isBin={isBin}
          hideOptions={true}
        />
        <form className="py-4 px-5" autoComplete="off">
          <div className="relative" style={{ width: '60%' }}>
            <TextInputField
              name="answer"
              className="input-field--xs-34"
              placeholder={getByPlaceholderText(questionData)}
              value={inputValue}
              error={error ? true : false}
              onChange={({ target }) => {
                if (isNumberWithDecimal(target.value)) {
                  onAnswerChange(target.value);
                }
              }}
              onBlur={onSubmit}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSubmit();
                }
              }}
              readOnly={isBin}
            />
            <div
              className="input-select-svg-dropdown text-13 font-semibold text-primary cursor-pointer"
              // onClick={() => setOpen(!open)}
              ref={dropdownRef}
            >
              {getCurrencyText(questionData?.choice_set[0]?.text)}
            </div>
            {/* <div
              data-testid="select-controlled-dropdown"
              className={`dropdown-menu p-2 ${open ? 'open' : ''} p-2`}
            >
              {CurrencyOptions.map((option, idx) => {
                return (
                  <div
                    data-testid="select-controlled-dropdown-list"
                    key={idx}
                    className={`dropdown-menu__list`}
                    onClick={() => onOptionSelect(option)}
                  >
                    <img
                      src={option.icon}
                      alt="icon"
                      style={{ width: 28, height: 18, marginRight: 10 }}
                    />
                    {option.label}
                  </div>
                );
              })}
            </div> */}
          </div>
          {error && <Error message={error} />}
        </form>
      </div>
    </>
  );
};

export default ValueQuestionAdded;

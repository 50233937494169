/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import Header from './header';
import ReplyEmail from '../replyEmail';
import ReplyFacebook from '../replyFacebook';
import Notes from '../notes';
import { useSelector, useDispatch } from 'react-redux';
import Task from '../task';
import { REPLY, NOTE, TASK } from 'utils/constants';
import { resetTaskStore } from 'slices/task/slice';
import { SEND_FB_INSTA_REPLY } from 'urls/facebook';
import { replaceUrl } from 'utils/urlReplace';
import { resetNoteStore } from 'slices/noteSlice';
import { setEditorActiveTab, setShowInboxActions } from 'slices/eboxLead';
import APIRequest from 'apiRequest';
import { LEADS_REPLY } from 'urls';
import { removeSignatureFromContent } from 'utils/dom';
import { sanatizeMessageBody } from 'utils/utils';
import {
  setAllEditorState,
  setIsForceClose,
  setIsSoftClose,
  setEmailBody,
  setEditorChannel
} from 'slices/editor/slice';

import { setActiveLead } from 'slices/conversationSlice';
import choices from 'choices';
import { isEmpty, isEditorEmpty } from 'utils/utils';
import Helper from './helper';
import { getLeadConversation } from 'slices/conversationSlice';
import { animateScroll } from 'react-scroll';
import ReplyBySms from '../replyBySms';
import { SEND_SMS_REPLY } from 'urls/sms';

const { EMAIL_REPLY } = choices.LeadReplyChoice;

const tabs = {
  reply: { id: 'reply', placeholder: 'Message...' },
  note: { id: 'note', placeholder: 'Message1111...' },
  task: {
    id: 'task',
    placeholder: 'Type what you want to be reminded about...'
  }
};

const ChatFooterInputBox = ({
  onClose,
  onReplySentSuccess,
  isFacebook,
  hideAttachment = false,
  hideImage = false,
  isInsta,
  isSmsConversation
}) => {
  const dispatch = useDispatch();
  const { cardId } = useParams();
  // const [boxStageId,setBoxStageId] = useState(stageId)
  const [isFooterExpanded, setIsFooterExpanded] = useState(false);
  const { editorActiveTab } = useSelector((state) => state.leads);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { showContactModal } = useSelector((state) => state.leads);
  const [showHelper, setShowHelper] = useState(false);
  const { isNudgeActive } = useSelector((state) => state.myInfo);
  const { is_sms_connected } = useSelector(
    (state) => state.myInfo.myInformation
  );
  const isSms = is_sms_connected && !isEmpty(activeLead?.phone_number);
  const node = useRef();
  const editorRef = useRef();
  const subTabRef = useRef();
  const activeLeadRef = useRef();
  const { editorChannel } = useSelector((state) => state.editor);
  subTabRef.current = editorChannel;
  editorRef.current = useSelector((state) => state.editor);
  activeLeadRef.current = activeLead;

  const emptyEditor = {
    emailBody: '',
    subject: '',
    ccEmail: [],
    bccEmail: [],
    attachments: []
  };

  async function onUnmountSaveFacebookInstaEditorContent(editor) {
    if (editor.is_soft_close) {
      draftMessage(editor);
    }
    if (editor.is_force_close) {
      draftMessage(editor);
    }
  }

  async function onUnmountSmsEditorContent(editor) {
    if (editor.is_soft_close) {
      draftSmsMessage(editor);
    }
    if (editor.is_force_close) {
      draftSmsMessage(editor);
    }
  }

  const getDraftMessageData = (editor) => {
    var temp = document.createElement('div');
    temp.innerHTML = editor.emailBody;
    var sanitized = temp.textContent || temp.innerText;
    const value = sanitized;
    let data = {
      message: value,
      is_in_draft_state: true
    };
    if (isEmpty(value)) {
      data = {
        ...data,
        delete_draft: true,
        is_in_draft_state: false
      };
    }
    if (isSms) {
      data = {
        ...data,
        lead: cardId
      };
    }
    if (activeLeadRef.current?.draft_reply?.is_in_draft_state) {
      data = {
        ...data,
        lead_reply_id: activeLeadRef.current?.draft_reply?.id
      };
      if (isEmpty(value)) {
        data = {
          ...data,
          delete_draft: true
        };
      }
    }
    return data;
  };

  const draftMessage = async (editor) => {
    if (!activeLeadRef.current?.draft_reply?.is_in_draft_state) {
      if (isEmpty(sanatizeMessageBody(editor.emailBody))) {
        return;
      }
    }
    return await new APIRequest()
      .post(
        replaceUrl(SEND_FB_INSTA_REPLY, 'leadId', cardId),
        getDraftMessageData(editor)
      )
      .then((res) => {
        if (editor.is_soft_close) {
          dispatch(
            setActiveLead({
              ...activeLeadRef.current,
              draft_reply: res?.data
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const draftSmsMessage = async (editor) => {
    if (!activeLeadRef.current?.draft_reply?.is_in_draft_state) {
      if (isEmpty(sanatizeMessageBody(editor.emailBody))) {
        return;
      }
    }
    return await new APIRequest()
      .post(SEND_SMS_REPLY, getDraftMessageData(editor))
      .then((res) => {
        if (editor.is_soft_close) {
          dispatch(
            setActiveLead({
              ...activeLeadRef.current,
              draft_reply: res?.data
            })
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'Conversation',
        smooth: true,
        duration: 0
      });
    }, 100);
  };

  useEffect(() => {
    dispatch(setIsForceClose(true));
    dispatch(setIsSoftClose(false));
    return () => {
      if (isSms) {
        if (subTabRef.current === 'Email') {
          onUnmountSaveEditorContent(editorRef.current);
        } else {
          onUnmountSmsEditorContent(editorRef.current);
        }
      } else {
        if (
          (!isFacebook && !isInsta) ||
          ((isFacebook || isInsta) && subTabRef.current === 'Email')
        ) {
          onUnmountSaveEditorContent(editorRef.current);
        } else if ((isFacebook || isInsta) && subTabRef.current !== 'Email') {
          onUnmountSaveFacebookInstaEditorContent(editorRef.current);
        }
      }
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  // useEffect(() => {
  //   if (isFacebook) {
  //     dispatch(setEditorChannel('Facebook'));
  //   }
  //   if (isInsta) {
  //     dispatch(setEditorChannel('Instagram'));
  //   }
  // }, [isFacebook, isInsta]);

  const editorDimentions = useMemo(() => {
    let temp = {};
    if (isFooterExpanded) {
      return temp;
    }
    if (editorActiveTab === REPLY && !isFacebook && !isInsta && !isSms) {
      return {
        minHeight: '380px',
        maxHeight: '380px'
      };
    }
    if (
      editorActiveTab === REPLY &&
      (isFacebook || isInsta || isSms) &&
      editorChannel !== 'Email'
    ) {
      return {
        minHeight: '160px',
        maxHeight: '160px'
      };
    } else if (
      editorActiveTab === REPLY &&
      (isFacebook || isInsta || isSms) &&
      editorChannel === 'Email'
    ) {
      return {
        minHeight: '380px',
        maxHeight: '380px'
      };
    }
    return temp;
  }, [
    isFooterExpanded,
    isFacebook,
    isInsta,
    editorActiveTab,
    editorChannel,
    isSms
  ]);

  const handleClick = (e) => {
    if (node.current?.contains(e.target)) {
      return;
    }
  };

  const onExpandClick = () => {
    setIsFooterExpanded(!isFooterExpanded);
  };

  const getDraftEmailData = (editor) => {
    let data = {
      message: removeSignatureFromContent(editor.emailBody),
      lead: cardId,
      subject: editor.subject,
      cc: editor.ccEmail,
      bcc: editor.bccEmail,
      message_sent_by: editor.selectedFromEmail.user_id,
      to: activeLeadRef.current.email,
      add_attachments: editor.attachments.map((attachment) => {
        const file = {};
        (file['file_name'] = attachment.file_name),
          (file['file_url'] = attachment.file_url);
        return file;
      }),
      reply_type: EMAIL_REPLY,
      is_in_draft_state: true
    };
    if (activeLeadRef.current?.draft_reply?.is_in_draft_state) {
      data = {
        ...data,
        lead_reply_id: activeLeadRef.current?.draft_reply?.id
      };
    }
    return data;
  };

  const checkIfEditorIsEmpty = (editor) => {
    const { emailBody, subject, ccEmail, bccEmail, attachments } = editor;
    if (
      isEditorEmpty(emailBody) &&
      isEmpty(subject) &&
      isEmpty(ccEmail) &&
      isEmpty(bccEmail) &&
      isEmpty(attachments)
    ) {
      return true;
    }
    return false;
  };

  async function draftEmail(cb) {
    let data = {};
    if (isEmpty(activeLeadRef.current?.draft_reply)) {
      if (checkIfEditorIsEmpty(editorRef.current)) {
        return;
      }
      data = getDraftEmailData(editorRef.current);
    }
    if (!isEmpty(activeLeadRef.current?.draft_reply)) {
      if (checkIfEditorIsEmpty(editorRef.current)) {
        data = { ...getDraftEmailData(editorRef.current), delete_draft: true };
      } else {
        data = getDraftEmailData(editorRef.current);
      }
    }
    return await new APIRequest()
      .post(LEADS_REPLY, data)
      .then((res) => {
        dispatch(setAllEditorState(emptyEditor));
        if (editorRef.current.is_soft_close) {
          dispatch(
            setActiveLead({
              ...activeLeadRef.current,
              draft_reply: res?.data
            })
          );
          if (cb) cb();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  async function onUnmountSaveEditorContent() {
    if (editorRef.current.is_soft_close) {
      draftEmail();
    }
    if (editorRef.current.is_force_close) {
      draftEmail();
    }
  }

  const onReplyClose = async (option) => {
    dispatch(setIsForceClose(false));
    dispatch(setIsSoftClose(true));
    dispatch(resetTaskStore());
    dispatch(resetNoteStore());
    setTimeout(() => {
      dispatch(setEditorActiveTab(REPLY));
      onClose();
    }, 0);
  };

  // show the helper box when reply is sent only when nudge is active and is in box
  const onReplySent = () => {
    if (isNudgeActive) {
      setShowHelper(true);
      getConversation();
    } else {
      onReplySentSuccess();
      onClose();
    }
  };

  const getConversation = () => {
    dispatch(
      getLeadConversation({ leadId: cardId }, () => {
        scrollDown();
      })
    );
  };

  const onHelperClose = () => {
    dispatch(setShowInboxActions(true));
  };

  // const onSuccessClose = () => {
  //   if (!stageId) {
  //     onClose();
  //   }
  // };

  const deleteDraft = async () => {
    if (!activeLeadRef.current?.draft_reply?.id) return;
    const payload = {
      lead_reply_id: activeLeadRef.current.draft_reply.id,
      add_attachments: [],
      delete_draft: true
    };
    await new APIRequest()
      .post(LEADS_REPLY, payload)
      .then((res) => {
        dispatch(setAllEditorState(emptyEditor));
        const temp = JSON.parse(JSON.stringify(activeLeadRef.current));
        temp.draft_reply = null;
        dispatch(setActiveLead(temp));
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onChannelChange = (type) => {
    dispatch(setEmailBody(''));
    deleteDraft();
    dispatch(setEditorChannel(type));
  };
  if (showHelper) {
    return <Helper onClose={onHelperClose} />;
  }

  return (
    <div
      className={`chat-message-footer chat-message-footer${
        editorActiveTab === REPLY
          ? ''
          : editorActiveTab === NOTE
          ? '__note'
          : '__reminder'
      } ${
        isFooterExpanded && editorActiveTab === REPLY ? 'footer-expanded' : ''
      } ${showContactModal ? 'hidden' : ''} `}
      style={editorDimentions}
    >
      <Header
        activeTab={editorActiveTab}
        setSubTab={onChannelChange}
        subTab={editorChannel}
        tabs={tabs}
        isFooterExpanded={isFooterExpanded}
        onExpandClick={() => onExpandClick()}
        isFacebook={isFacebook}
        isInsta={isInsta}
        onClose={onReplyClose}
        isSms={isSms}
      />
      {editorActiveTab === REPLY && (
        <>
          {editorChannel === 'Email' && (
            <ReplyEmail
              onClose={() => {}}
              onReplySentSuccess={onReplySent}
              isFooterExpanded={isFooterExpanded}
              draft={activeLeadRef.current?.draft_reply}
            />
          )}
          {(editorChannel === 'Facebook' || editorChannel === 'Instagram') && (
            <ReplyFacebook
              isFooterExpanded={isFooterExpanded}
              onClose={() => {}}
              onReplySentSuccess={onReplySent}
              hideAttachment={hideAttachment}
              hideImage={hideImage}
            />
          )}
          {editorChannel === 'Sms' && (
            <ReplyBySms
              isFooterExpanded={isFooterExpanded}
              onClose={() => {}}
              onReplySentSuccess={onReplySent}
              hideAttachment={hideAttachment}
              hideImage={hideImage}
              isSmsConversation={isSmsConversation}
            />
          )}
        </>
      )}
      {editorActiveTab === NOTE && <Notes autoScroll={scrollDown} />}
      {editorActiveTab === TASK && <Task autoScroll={scrollDown} />}
    </div>
  );
};

ChatFooterInputBox.propTypes = {
  onClose: PropTypes.func
};

ChatFooterInputBox.defaultProps = {
  onClose: () => {}
};

export default ChatFooterInputBox;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { useNavigate } from 'react-router-dom';
import { useForgotPasswordMutation } from 'services/authApi';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import InputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import Back from 'components/back';
// import { ErrorToast } from 'components/toast';
import ResetLinkSent from './resetLink';
import ErrorRenderer from 'components/errorMessage/renderer';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';

const ForgotPassword = () => {
  const formSchema = getFormSchema(['email']);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });
  const navigate = useNavigate();
  const [forgotPassword, { isSuccess, isError, error, isLoading }] =
    useForgotPasswordMutation();

  // useEffect(() => {
  //   if (isError) {
  //     onError();
  //   }
  // }, [isError]);

  // const onError = () => {
  //   error?.data?.non_field_errors.forEach((err) => {
  //     ErrorToast(err);
  //   });
  // };

  const onSubmit = async (data) => {
    const formData = {
      email: data.email
    };
    await forgotPassword(formData);
  };

  if (isSuccess) {
    return <ResetLinkSent isResetPassword={true} />;
  }

  return (
    <HeaderLayout>
      <CardLayout style={{ paddingLeft: '6rem', paddingRight: '6rem' }}>
        <CardTitle
          title="Forgot your password?"
          subTitle="Enter the email you use for this account and we'll send you a reset
        password link."
        />
        <div className="mt-10">
          {isError && (
            <div className="mb-5">
              <ErrorRenderer
                className="mt-0"
                error={error?.data?.non_field_errors || error?.data?.email}
              />
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <InputField
              name="email"
              placeholder="Email"
              renderIcon={() => <EmailIcon />}
              icon={true}
              error={errors.email && touchedFields.email}
              register={register}
            />
            <PrimaryButton
              label="Email me my reset link"
              type="submit"
              className="w-full mt-6"
              isLoading={isLoading}
              enableLoader={true}
            />
          </form>
        </div>
        <div className="text-center mt-5 text-13 text-grey-800">
          if you don’t see your reset email check your spam for an email from{' '}
          <span className="text-primary font-medium cursor-pointer">
            notifications@enquirybox.io
          </span>
        </div>
        <Back className="mt-8 mx-auto" onClick={() => navigate(-1)} showIcon />
      </CardLayout>
    </HeaderLayout>
  );
};

export default ForgotPassword;

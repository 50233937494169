import React, { useState } from 'react';
import PropTypes from 'prop-types';

const InputWithIcon = (props) => {
  const {
    icon,
    inputClassName,
    className,
    iconClassName,
    small,
    medium,
    large,
    rightIcon,
    rightIconClassName,
    onRightIconClick,
    ...rest
  } = props;
  const [isInputFocused, setIsInputFocused] = useState(false);

  const getSize = () => {
    if (large) return 'input-field--lg';
    if (medium) return 'input-field--md';
    if (small) return 'input-field--sm';
  };

  return (
    <div
      className={`input-field input-field--prepend no-hover ${
        isInputFocused ? 'input-field--prepend--focus' : ''
      } ${getSize()} ${className}`}
    >
      {icon && (
        <img
          data-testid="input-icon"
          src={icon}
          alt="icon"
          className={iconClassName}
        />
      )}
      <input
        data-testid="input-with-icon"
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        className={inputClassName}
        autoComplete="off"
        {...rest}
      />
      {rightIcon && (
        <img
          data-testid="input-icon"
          src={rightIcon}
          alt="icon"
          className={rightIconClassName}
          onClick={onRightIconClick}
        />
      )}
    </div>
  );
};

InputWithIcon.propTypes = {
  className: PropTypes.string,
  iconClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  icon: PropTypes.string,
  rightIcon: PropTypes.string,
  rightIconClassName: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool
};

InputWithIcon.defaultProps = {
  className: '',
  iconClassName: '',
  inputClassName: '',
  rightIcon: '',
  small: true,
  medium: false,
  large: false
};

export default InputWithIcon;

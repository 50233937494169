/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import DueDays from './dueDay';
import TaskUser from './assign';
import Error from 'components/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';
import TaskType from 'modules/enquirybox/ebox/module/box/conversation/footer/task/footer/call';
import { setTaskType, setTaskTypeTitle } from 'slices/task/slice';
import CHOICES from 'choices';

const TaskAutomation = ({ condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const errors = automationErrors[index];
  const {
    data: { note, due_days_value, due_in, assigned_to, task_type }
  } = condition;
  const isEdit = !isEmpty(condition.data.task_id);
  const [task, setTask] = useState(note);
  const [user, setUser] = useState({});
  const [dueType, setDueType] = useState(due_in);
  const [dueValue, setDueValue] = useState(due_days_value);
  // const { taskType } = useSelector((state) => state.tasks);

  useEffect(() => {
    dispatch(setTaskType(task_type));
    dispatch(setTaskTypeTitle(CHOICES.TasksTypeChoices.CHOICES[task_type]));
  }, [task_type]);

  const updateThen = () => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.note) {
      let result = omit(errorObj[index], ['note']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        note: task,
        due_in: dueType,
        due_days_value: dueValue,
        assigned_to: user?.user_id,
        task_type: task_type
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  useEffect(() => {
    updateThen();
  }, [task, user, dueType, dueValue]);

  return (
    <>
      <div className="chat-message-footer" style={{ margin: 0 }}>
        <div
          className="chat-message-footer__body bg-white"
          style={{ height: 'calc(100% - 4px)', borderRadius: 10 }}
        >
          <div className="chat-reply">
            <textarea
              onChange={(e) => setTask(e.target.value)}
              value={task}
              rows="2"
              cols="5"
              placeholder="Write the name of the task"
              className="text-base-1 text-grey-900 resize-none w-full px-2 py-2 border-none outline-none"
              style={{ height: '68px', fontFamily: 'Roboto', fontSize: '14px' }}
            />
            <div className="chat-message-footer__footer flex items-center justify-between">
              <div className="flex items-center gap-2">
                <DueDays
                  dueType={dueType}
                  dueValue={dueValue}
                  setDueType={setDueType}
                  setDueValue={setDueValue}
                  isEdit={isEdit}
                />
                <TaskUser assigned_to={assigned_to} setUser={setUser} />
                <TaskType />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Error message={errors?.note} />
    </>
  );
};

export default React.memo(TaskAutomation);

import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/utils';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close-green.svg';
import { Loader } from 'components/loader';

const Selected = ({ selectedOptions, handleRemove, isLoading }) => {
  return (
    <div className="hide-scroll dashboard__main__header__full-right--label-container">
      {isLoading && (
        <div className="mb-2">
          <Loader loaderInline smaller />{' '}
        </div>
      )}
      {!isEmpty(selectedOptions) &&
        selectedOptions.map((item, index) => {
          return (
            <div key={index}>
              <div
                className="bg-grey-90 rounded-md flex items-center py-1 px-2 cursor-pointer mr-1"
                style={{ border: '1px solid rgba(0, 92, 111, 0.2)' }}
              >
                <p className="text-13 font-medium mr-2 truncate">
                  {item.label_name}
                </p>
                <CloseIcon
                  width={8}
                  height={8}
                  onClick={() => handleRemove(item.id)}
                />
              </div>
            </div>
          );
        })}
    </div>
  );
};

Selected.propTypes = {
  selectedOptions: PropTypes.arrayOf(PropTypes.object)
};

export default Selected;

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

const ChannelOption = (props) => {
  const {
    icon,
    title,
    description,
    paid,
    commingSoon,
    to,
    channel_type,
    isDisabled
  } = props;
  const { myInformation } = useSelector((state) => state.myInfo);
  const navigate = useNavigate();

  const [active, setActive] = useState(false);

  const onMouseEnter = () => {
    if (!isDisabled) {
      setActive(true);
    }
  };

  const onMouseLeave = () => setActive(false);

  const { boot, show } = useIntercom();

  const onConnect = () => {
    if (channel_type === 10) {
      boot({ name: myInformation?.name });
      setTimeout(() => {
        let element = document.getElementById('intercom-container');
        console.log(element);
        if (element) {
          element.style.display = 'block';
        }
      }, 500);

      show();
    } else {
      navigate(to);
    }
  };

  return (
    <div
      className={`channels-item ${active ? 'active' : ''}`}
      onMouseLeave={onMouseLeave}
      onMouseOver={onMouseEnter}
    >
      {paid && <div className="paid-on">Paid Add on</div>}
      {commingSoon && <div className="coming-soon">Comming soon</div>}
      <div className="channel-icon" style={{ backgroundColor: 'transparent' }}>
        <img src={icon} alt="channel-icon" />
      </div>
      <div className="channel-title">{title}</div>
      <div className="channel-description">{description}</div>
      <div className="channel-connect">
        <div className="connect-button" onClick={onConnect}>
          {channel_type === 10 ? 'Suggest' : 'Connect'}
        </div>
      </div>
    </div>
  );
};

ChannelOption.defaultProps = {
  icon: '',
  title: 'Website Forms',
  description: 'More leads from your website with your free chatbot',
  paid: false,
  commingSoon: false,
  to: '/channels'
};

export default ChannelOption;

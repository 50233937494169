import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL, CREATE_BUSINESS_INFO_RTK, INVITE_USER_RTK } from 'urls';
import { ApiHeaders } from '../headerSetup';
import { replaceUrl } from 'utils/urlReplace';

export const organizationApi = createApi({
  reducerPath: 'organizationApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: ApiHeaders
  }),
  endpoints: (builder) => ({
    createBusiness: builder.mutation({
      query: (body) => {
        return {
          url: CREATE_BUSINESS_INFO_RTK,
          method: 'post',
          body
        };
      }
    }),
    inviteUser: builder.mutation({
      query: ({ body, subOrgId }) => {
        return {
          url: replaceUrl(INVITE_USER_RTK, 'subOrgId', subOrgId),
          method: 'post',
          body
        };
      }
    })
  })
});

export const { useCreateBusinessMutation, useInviteUserMutation } =
  organizationApi;

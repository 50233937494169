/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from 'react';
import iconNewAutomation from 'assets/icons/icon-new-automation-icon.svg';
import InputField from 'components/inputs/textField';
import { isEmpty } from 'utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'components/inputs/select';
import MultiSelect from 'modules/boxes/components/multiSelect';
import { ReactComponent as SelectIcon } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as FaceBookAutomationIcon } from 'assets/icons/facebook_automation.svg';
import { ReactComponent as SMSIcon } from 'assets/icons/icon-sms-automation.svg';
import { ReactComponent as InstagramAutomationIcon } from 'assets/icons/instagram_automation.svg';
import { ReactComponent as WebformAutomationIcon } from 'assets/icons/icon-webform-automation.svg';
import { ReactComponent as ZapierIcon } from 'assets/icons/icon-channel-zapier.svg';

import choices from 'choices';
import { useParams } from 'react-router-dom';
import {
  setConnectedChannel,
  setAutomationStage
} from 'slices/boxAutomation/slice';
const { FACEBOOK, FACEBOOK_LEADGEN, WEB_FORMS, INSTAGRAM, SMS } =
  choices.LeadChannelChoice;
const { ZAPIER } = choices.ChannelChoices;

const BoxAutomationStuffWhen = () => {
  const dispatch = useDispatch();
  const { connected_channel, automationStage } = useSelector(
    (state) => state.boxAutomation
  );

  const { allConnectedChannels } = useSelector((state) => state.channels);
  const [channelMenuOpen, setChannelMenuOpen] = useState(false);
  const { currentBox } = useSelector((state) => state.boxes);
  const { box_id } = useParams();

  const allConnectedChannelsUpdated = allConnectedChannels.map((channel) => {
    if (channel.channel.channel_type === FACEBOOK_LEADGEN) {
      return {
        ...channel,
        name: `${channel.name} (Lead Ads)`
      };
    }
    return channel;
  });
  const connected_channel_updated = connected_channel.map((channel) => {
    if (channel.id === 'any_channel') {
      return channel;
    }
    if (channel.channel.channel_type === FACEBOOK_LEADGEN) {
      if (channel.name.includes('(Lead Ads)')) {
        return channel;
      }
      return {
        ...channel,
        name: `${channel.name} (Lead Ads)`
      };
    }
    return channel;
  });

  const box_stages = useMemo(() => {
    return currentBox.boxstage_set.map((stage) => {
      return { label: stage.stage_name, value: stage.id };
    });
  }, [box_id, currentBox]);

  const onChannelMenuToggle = (status) => {
    setChannelMenuOpen(status);
  };

  const isChannelSelectedAndMenuClosed = () => {
    if (channelMenuOpen) return true;
    if (!channelMenuOpen && isEmpty(connected_channel_updated)) return true;
    if (!channelMenuOpen && !isEmpty(connected_channel_updated)) return false;
    return false;
  };

  const getChannelIcon = (option) => {
    const type = option.channel ? option.channel.channel_type : option.value;
    switch (type) {
      case FACEBOOK:
        return <FaceBookAutomationIcon />;
      case INSTAGRAM:
        return <InstagramAutomationIcon />;
      case FACEBOOK_LEADGEN:
        return <FaceBookAutomationIcon />;
      case WEB_FORMS:
        return <WebformAutomationIcon />;
      case SMS:
        return <SMSIcon />;
      case ZAPIER:
        return <ZapierIcon />;
      default:
        return <WebformAutomationIcon />;
    }
  };

  const onChannelSelect = (data) => {
    if (isEmpty(data)) {
      dispatch(
        setConnectedChannel([{ name: 'Any channel', id: 'any_channel' }])
      );
      return;
    }
    dispatch(setConnectedChannel(data));
  };

  const onStageSelect = (value) => {
    dispatch(setAutomationStage(value));
  };

  if (isEmpty(box_stages)) return <></>;

  return (
    <div
      className={`${
        !isEmpty(connected_channel_updated)
          ? 'box-settings__automation-card'
          : 'box-settings__automation-template__automation-stuff'
      } mt-4 px-5 pt-5 pb-6`}
    >
      <div className="flex">
        <img src={iconNewAutomation} alt="" style={{ width: 52, height: 50 }} />
        <div className="ml-4">
          <h4 className="font-semibold">When...</h4>

          <p className="text-sm text-grey-800">
            This automation is started when the following occurs
          </p>
        </div>
      </div>
      <div className="relative mt-4">
        <form autoComplete="off">
          <div className="mb-3">
            <InputField
              name="move to"
              placeholder="An enquiry move to a particular stage"
              value={'An enquiry move to a particular stage'}
              disabled={true}
              style={{ fontSize: 14 }}
            />
          </div>
          <Select
            name="automation-when"
            options={box_stages}
            onChange={onStageSelect}
            dropDownClassName="p-3"
            inputClassName="input-field--md"
            isWhenSelect={true}
            value={
              !isEmpty(automationStage) ? automationStage.label : 'Select Stage'
            }
            selectedValue={automationStage}
            labelDividerText={'Select Stage'}
            labelDividerTextClass={'text-grey-700'}
            style={{ paddingRight: '35px' }}
            readOnly
            showSelectIcon
          />
          {automationStage && isChannelSelectedAndMenuClosed() && (
            <div className="mt-3">
              <div className="text-grey-900 font-semibold text-13 ml-1 mb-2">
                And the enquiry is from this channel
              </div>
              <MultiSelect
                name="automation-when"
                options={[...allConnectedChannelsUpdated]}
                value={''}
                selectedOptions={connected_channel_updated}
                onChange={onChannelSelect}
                inputClassName="input-field--md "
                dropDownClassName="p-3"
                placeholder={'Select channel'}
                labelDividerText={'Or select a specific channels below'}
                labelDividerTextClass={'text-grey-700'}
                style={{ paddingRight: '35px' }}
                onMenuToggle={onChannelMenuToggle}
                labelKey="name"
                valueKey="id"
                renderIcon={getChannelIcon}
                extraOption={{ name: 'Any channel', id: 'any_channel' }}
              />
            </div>
          )}
          {!isEmpty(connected_channel_updated) && !channelMenuOpen && (
            <>
              <MultiSelect
                name="automation-when"
                options={[...allConnectedChannelsUpdated]}
                value={''}
                selectedOptions={connected_channel_updated}
                onChange={onChannelSelect}
                inputClassName="input-field--md "
                dropDownClassName="p-3"
                labelDividerText={'Or select a specific channels below'}
                labelDividerTextClass={'text-grey-700'}
                style={{ paddingRight: '35px' }}
                hideInput={true}
                labelKey="name"
                valueKey="id"
                renderIcon={getChannelIcon}
                isSearchInsideDropDown
                extraOption={{ name: 'Any channel', id: 'any_channel' }}
                showdropdownIcon={false}
                renderChildren={(toggle) => (
                  <div
                    className="bg-white border-radius-10 border border-grey-400 py-3 px-4 mt-4 cursor-pointer"
                    onClick={() => toggle()}
                  >
                    <div className="text-13 font-semibold relative">
                      <p>And the enquiry is from this channel</p>
                      <div
                        className="input-select-svg-dropdown ml-auto"
                        style={{ right: 4 }}
                      >
                        <SelectIcon />
                      </div>
                    </div>
                    {connected_channel_updated?.map((option, index) => {
                      return (
                        <div
                          key={`channel-${index}`}
                          className="mt-3 bg-grey-100 rounded-lg p-3"
                        >
                          <div className="flex">
                            {option.id !== 'any_channel' && (
                              <>
                                <figure
                                  className="avatar-container"
                                  style={{ width: 15 }}
                                >
                                  {getChannelIcon(option)}
                                </figure>
                                <p className="ml-3 font-semibold text-sm">
                                  {option.name}
                                </p>
                              </>
                            )}
                            {option.id == 'any_channel' && (
                              <p className="font-semibold text-sm">
                                {option.name}
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              />
            </>
          )}
        </form>
      </div>
    </div>
  );
};

export default BoxAutomationStuffWhen;

import React from 'react';
import PropTypes from 'prop-types';
import Error from 'components/errorMessage';

const RenderError = ({ error, className }) => {
  return (
    <div className="flex flex-col justify-center items-center">
      {typeof error !== 'string' &&
        error?.map((err) => {
          return <Error key={err} message={err} className={className} />;
        })}
      {typeof error === 'string' && (
        <Error message={error} className={className} />
      )}
    </div>
  );
};

RenderError.propTypes = {
  error: PropTypes.array.isRequired || PropTypes.string.isRequired,
  className: PropTypes.string
};

export default RenderError;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  setError,
  setRedirect,
  setSignUpChoice,
  setAuthToken
} from 'slices/authSlice';
// import { gapi, loadAuth2 } from 'gapi-script';
import { useDispatch, useSelector } from 'react-redux';
import choices from 'choices';
import ButtonWithLogo from 'components/buttons/buttonWithLogo';
import GmailLogo from 'assets/icons/icon-gmail.svg';
import { useSocialSignMutation } from 'services/authApi';
// import { SuccessToast } from 'components/toast';
import { redirectPostCreds } from 'utils/redirect';
import { GoogleLogin } from '@react-oauth/google';
import { isEmpty } from 'utils/utils';
import queryString from 'query-string';
import { INVITED_EMAIL, SUB_ORG_ID } from 'utils/constants';
import storageFactory, { getPartnerId } from 'utils/localstorage';

const GOOGLE = choices.SignupChoice.GOOGLE;

const GoogleAuthentication = (props) => {
  const { signIn, signUp, onError, invitedAccount, fireAnalytics } = props;
  const socialCredentials = useSelector(
    (state) => state.social.socialCredentials
  );
  const [userInfo, setUserInfo] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [socialSign, { data, isSuccess, isError, error }] =
    useSocialSignMutation();
  const { analytics } = useSelector((state) => state.segments);
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);

  // useEffect(() => {
  //   if (socialCredentials) {
  //     const googleClientId = socialCredentials.google.clientId;
  //     const setAuth2 = async () => {
  //       const auth2 = await loadAuth2(gapi, googleClientId, '');
  //       if (auth2.isSignedIn.get()) {
  //         updateUser(auth2.currentUser.get());
  //         attachSignin(document.getElementById('google-btn'), auth2);
  //       } else {
  //         attachSignin(document.getElementById('google-btn'), auth2);
  //       }
  //     };
  //     setAuth2();
  //   }
  // }, [userInfo, socialCredentials]);

  useEffect(() => {
    if (!isEmpty(queryParams?.email)) {
      localStorage.setItem(INVITED_EMAIL, queryParams?.email);
      localStorage.setItem(SUB_ORG_ID, queryParams?.sub_organization_id);
    }
  }, [queryParams]);

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
    if (isError) {
      onError(error?.data);
    }
  }, [isSuccess, isError]);

  // const updateUser = (currentUser) => {
  //   setUserInfo(currentUser);
  // };

  const onSuccess = () => {
    if (invitedAccount) {
      if (fireAnalytics) {
        fireAnalytics(data);
      }
    }
    const redirectUri = data?.frontend_redirect_uri;
    dispatch(setAuthToken(data?.token));
    // SuccessToast('Success');
    dispatch(setRedirect(redirectUri));
    navigate(redirectPostCreds(redirectUri));
    localStorage.removeItem(INVITED_EMAIL);
  };

  const socialSignUp = async (data) => {
    await socialSign(data);
  };

  const onSuccessLogin = (data) => {
    const tokenId = data.credential;
    dispatch(setSignUpChoice(GOOGLE));
    const invitedEmail = localStorage.getItem(INVITED_EMAIL);
    const subOrgId = localStorage.getItem(SUB_ORG_ID);
    const partnerId = storageFactory().getItem(getPartnerId());
    if (signUp) {
      const data = {
        auth_id_token: tokenId,
        which_social_auth: GOOGLE,
        is_this_signup_request: true
      };
      if (partnerId) {
        data['partner_id'] = partnerId;
      }
      if (!isEmpty(invitedEmail) && !isEmpty(subOrgId)) {
        data['invited_email'] = invitedEmail;
        data['sub_organization_id'] = subOrgId;
      }
      socialSignUp(data);
    }
    if (signIn) {
      const data = {
        auth_id_token: tokenId,
        which_social_auth: GOOGLE,
        is_this_signup_request: false
      };
      socialSignUp(data);
    }
  };

  // const attachSignin = (element, auth2) => {
  //   auth2.attachClickHandler(
  //     element,
  //     {},
  //     (googleUser) => {
  //       updateUser(googleUser);
  //       const tokenId = gapi.auth2
  //         .getAuthInstance()
  //         .currentUser.get()
  //         .getAuthResponse().id_token;
  //       dispatch(setSignUpChoice(GOOGLE));
  //       if (signUp) {
  //         const data = {
  //           auth_id_token: tokenId,
  //           which_social_auth: GOOGLE,
  //           is_this_signup_request: true
  //         };
  //         socialSignUp(data);
  //       }
  //       if (signIn) {
  //         const data = {
  //           auth_id_token: tokenId,
  //           which_social_auth: GOOGLE,
  //           is_this_signup_request: false
  //         };
  //         socialSignUp(data);
  //       }
  //     },
  //     (error) => {
  //       console.log(JSON.stringify(error));
  //     }
  //   );
  // };

  useEffect(() => {
    return dispatch(setError(null));
  }, [userInfo]);

  useEffect(() => {
    setTimeout(() => {
      let iframedata = document.getElementsByTagName('iframe');
      if (iframedata && iframedata[0]) {
        iframedata[0].style.width = '190px';
        iframedata[0].style.margin = '0px';
      }
    }, [1000]);
  }, []);

  return (
    <div className="relative">
      <div className="absolute opacity-0 top-0" style={{ height: 45 }}>
        <GoogleLogin
          size="large"
          text="sign in"
          onSuccess={(credentailResponse) => {
            onSuccessLogin(credentailResponse);
          }}
        />
      </div>
      <ButtonWithLogo
        id="google-btn"
        logo={GmailLogo}
        label="Google"
        // onClick={onClick}
      />
    </div>
  );
};

GoogleAuthentication.propTypes = {
  signIn: PropTypes.bool,
  signUp: PropTypes.bool,
  onError: PropTypes.func,
  invitedAccount: PropTypes.bool
};

GoogleAuthentication.defaultProps = {
  signIn: false,
  signUp: false,
  onError: () => {},
  invitedAccount: false
};

export default GoogleAuthentication;

import React, { useState } from 'react';
// import pdfIcon from 'assets/icons/icon-file-upload-pdf.svg';
import deleteIcon from 'assets/icons/icon-trash.svg';
import { isEmpty } from 'utils/utils';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { fileIcons, getFileExtension } from 'utils/file';
import { useSnackbar } from 'components/Snackbar';
import { Loader } from 'components/loader';
import { useDispatch } from 'react-redux';
import { deleteUploadedFile } from 'slices/automationStuffSlice';

const RenderAttachments = ({
  files,
  setUploadedFiles,
  isChannelSettings,
  isAutomateStuff
}) => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [fileToBeDeleted, setFileToBeDeleted] = useState(null);
  const [isLoader, setIsLoader] = useState(false);

  const onDeleteAttachments = () => {
    setIsLoader(true);
    if (!fileToBeDeleted.isFileLibrary) {
      dispatch(deleteUploadedFile({ fileId: fileToBeDeleted?.id }))
        .then(() => {
          const postFileDeletion = files.filter(
            (f) => f.file_url !== fileToBeDeleted.file_url
          );
          setUploadedFiles(postFileDeletion);
          setFileToBeDeleted(null);
          setIsLoader(false);
          openSuccessSnackBar('File deleted successfully', 'large');
        })
        .catch((err) => {
          setIsLoader(false);
          openErrorSnackBar('failed to delete');
        });
    } else {
      const postFileDeletion = files.filter(
        (f) => f.file_url !== fileToBeDeleted.file_url
      );
      setUploadedFiles(postFileDeletion);
      setFileToBeDeleted(null);
      setIsLoader(false);
      openSuccessSnackBar('File deleted successfully', 'large');
    }
  };

  const onDelete = (file) => {
    setFileToBeDeleted(file);
  };

  if (isEmpty(files)) return <div />;

  return (
    <>
      <DeleteConfirmation
        showConfirmation={!isEmpty(fileToBeDeleted)}
        onCancel={() => {
          setFileToBeDeleted(null), setIsLoader(false);
        }}
        onConfirm={onDeleteAttachments}
        title="Are you sure you want to delete this attachment?"
        description=""
        isLoading={isLoader}
      />
      <div className="attachments-wrapper">
        <p className="attachments-title">Attachments</p>
        <div className="attachments-container">
          {files.map((file) => {
            return (
              <div
                key={file.file_url}
                className="attachments"
                style={
                  isChannelSettings || isAutomateStuff
                    ? { maxWidth: '90%', minWidth: '90%' }
                    : {}
                }
              >
                <figure>
                  <img
                    src={fileIcons[getFileExtension(file?.file_url)]}
                    alt="doc-img"
                  />
                </figure>
                <div
                  className="label-container"
                  style={
                    isChannelSettings || isAutomateStuff
                      ? { maxWidth: '85%', minWidth: '85%' }
                      : {}
                  }
                >
                  <p className="label">{file?.file_name}</p>
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className="trash cursor-pointer"
                    onClick={() => onDelete(file)}
                  />
                </div>
              </div>
            );
          })}
          {isLoader && <Loader />}
        </div>
      </div>
    </>
  );
};

export default RenderAttachments;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ColourPickup from './colourPicker';
import CurrencyPicker from './currencyPicker';
import { useDispatch, useSelector } from 'react-redux';
import { TaskTimes } from 'modules/enquirybox/ebox/module/box/conversation/footer/task/footer/helper';
import {
  getBusinessInfoData,
  setBusinessInfoData,
  updateBusinessInfoData
} from 'slices/businessInfoSlice';
import { useSnackbar } from 'components/Snackbar';
import OfficeHours from './officeHours';

const BusinesInfoSettings = (props) => {
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { businessInfoData } = useSelector(
    (state) => state.businessInfoSettings
  );
  const [isLoading, setIsLoading] = useState(false);

  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const updateOfficeHours = (index, id, isOpen, startTime, closingTime) => {
    const businessInfoFilterdOfficeData = businessInfoData.office_hours.map(
      (item, ind) => {
        if (index === ind) {
          return {
            ...item,
            is_on: isOpen,
            start_time_str: startTime,
            end_time_str: closingTime
          };
        } else {
          return item;
        }
      }
    );
    dispatch(
      setBusinessInfoData({
        ...businessInfoData,
        office_hours: [...businessInfoFilterdOfficeData]
      })
    );
  };

  const structuredTimings = TaskTimes?.map((item) => {
    return { label: item?.title, value: item?.title };
  });

  useEffect(() => {
    const payload = {
      subOrgId: subOrganizationId
    };
    dispatch(getBusinessInfoData(payload));
  }, [subOrganizationId]);

  const handleSave = () => {
    setIsLoading(true);
    const payload = {
      subOrgId: subOrganizationId,
      data: {
        office_hours: businessInfoData?.office_hours?.map((item) => {
          return {
            id: item?.id,
            is_on: item.is_on,
            start_time: item?.start_time_str,
            end_time: item?.end_time_str
          };
        })
      }
    };
    dispatch(updateBusinessInfoData(payload, onSuccess, onError));
  };

  const onSuccess = () => {
    setIsLoading(false);
    openSuccessSnackBar('Sucess');
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
  };

  return (
    <div className=" ">
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Business info</h2>
        <p className="text-grey-800 text-base-1 mt-2">
          Opening hours, brand colours
        </p>
      </div>

      <ColourPickup brandColor={businessInfoData.brand_color} />
      <CurrencyPicker currency={businessInfoData?.currency} />
      <OfficeHours
        businessInfoData={businessInfoData}
        handleSave={handleSave}
        structuredTimings={structuredTimings}
        updateOfficeHours={updateOfficeHours}
        isLoading={isLoading}
      />
    </div>
  );
};

export default BusinesInfoSettings;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  isEmpty,
  isEditorEmpty,
  sanatizeMessageBody,
  isEditorOnlyContainsUserSignature
} from 'utils/utils';
import AuomationThen from './then/then';
import AutomationWhen from './when';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import AutomationNameComp from './automationName';
import { useSelector, useDispatch } from 'react-redux';
import {
  resetAutomationStore,
  addBoxAutomation,
  setAutomationErrors,
  updateBoxAutomation,
  getBoxAutomations
} from 'slices/boxAutomation/slice';
import { useParams } from 'react-router-dom';
import choices from 'choices';
import OverLayLoader from 'components/loader/overLayLoader';
import { omit } from 'lodash';
import { useSnackbar } from 'components/Snackbar';
import { getBoxData, setBoxes } from 'slices/boxes/slice';
import ErrorMessage from 'components/errorMessage';

const {
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  DELAY_TIMER,
  FACEBOOK,
  INSTAGRAM,
  MOVE_TO_BOX,
  SMS,
  NOTES,
  TASK
} = choices.BoxAutomationChoice;

const Automation = (props) => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { box_id } = useParams();
  const { boxes } = useSelector((state) => state.boxes);
  const [isLoading, setIsLoading] = useState(false);
  const { myInformation } = useSelector((state) => state.myInfo);
  const [selectedThenOptions, setSelectedThenOptions] = useState([
    { selectedAction: '', actionData: {} }
  ]);
  const {
    then,
    connected_channel,
    automation_name,
    automationStage,
    selectedAutomation,
    automationErrors,
    isAutomationActive
  } = useSelector((state) => state.boxAutomation);
  const { taskType } = useSelector((state) => state.tasks);

  const isAnyChannel = () => {
    const anyChannel = connected_channel.find(
      (channel) => channel.id === 'any_channel'
    );
    return !isEmpty(anyChannel);
  };

  const cleanSendFrom = (from) => {
    if (typeof from === 'object') {
      if (from.user_id) {
        return from.user_id;
      }
    }
    return from;
  };

  const getConnectedChannelIds = () => {
    return connected_channel
      .filter((channel) => channel.id !== 'any_channel')
      .map((channel) => channel.id);
  };
  var error = automationErrors;

  const buildThenData = (thenAutomation, thenCopy, index) => {
    // if nothing is selected in then part
    if (isEmpty(thenAutomation)) {
      error = { ...error, [index]: { category: 'Please select' } };
    }
    // if the last automation is delay timer
    if (
      !isEmpty(then) &&
      then[then.length - 1]?.automation_type === DELAY_TIMER
    ) {
      error = {
        ...error,
        last_automation: 'Last automation cannot be delay timer'
      };
    }
    // data check for assign member
    if (thenAutomation.automation_type == ASSIGN_MEMBER) {
      if (thenAutomation.data.assign_user) {
        const { assign_user } = thenAutomation.data;
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            assign_user: assign_user.value || assign_user.id
          }
        };
        thenCopy[index] = data;
      } else {
        error = { ...error, [index]: { assign_user: true } };
      }
    }
    if (thenAutomation.automation_type == AUTORESPONDER_EMAIL) {
      if (thenAutomation.data) {
        const { subject, message, send_from, signature } = thenAutomation.data;
        let emailError = {};
        if (isEmpty(subject)) {
          emailError = { ...emailError, subject: 'Please provide subject' };
        }
        if (isEditorEmpty(message)) {
          emailError = { ...emailError, message: 'Please provide message' };
        }
        if (isEditorOnlyContainsUserSignature(message, signature)) {
          emailError = { ...emailError, message: 'Please provide message' };
        }
        if (!isEmpty(emailError)) {
          error = { ...error, [index]: emailError };
        }
        // thenAutomation
        if (thenAutomation?.data?.type) {
          omit(thenAutomation.data, ['type']);
        }
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            message: message
              .replaceAll(/<p/g, '<div')
              .replaceAll(/<\/p/g, '</div'),
            send_from: cleanSendFrom(send_from),
            attachments: thenAutomation.data.attachments.map((file) => file.id),
            signature: undefined // Signature is added temporarily for validation purposes, remove from payload
          }
        };
        thenCopy[index] = data;
      }
    }
    if (thenAutomation.automation_type == DELAY_TIMER) {
      if (thenAutomation.data) {
        const { delay_kind, delay_value } = thenAutomation.data;
        let delayError = {};
        if (isEmpty(delay_kind)) {
          delayError = {
            ...delayError,
            delay_kind: 'Please select delay kind'
          };
        }
        if (isEmpty(delay_value)) {
          delayError = {
            ...delayError,
            delay_value: 'Please provide delay time'
          };
        }
        if (!isEmpty(delay_value) && delay_value <= 0) {
          delayError = {
            ...delayError,
            delay_value: 'Delay time must be greater then or equal to 1'
          };
        }
        if (!isEmpty(delayError)) {
          error = { ...error, [index]: delayError };
        }
        thenCopy[index] = {
          ...thenAutomation,
          position: thenAutomation.position || index
        };
      }
    }
    if (thenAutomation.automation_type == MOVE_TO_BOX) {
      if (thenAutomation.data) {
        const { move_to_box, move_to_stage } = thenAutomation.data;
        let boxError = {};
        if (isEmpty(move_to_box)) {
          boxError = { ...boxError, move_to_box: 'Please select box' };
        }
        if (isEmpty(move_to_stage)) {
          boxError = { ...boxError, move_to_stage: 'Please select stage' };
        }
        if (!isEmpty(boxError)) {
          error = { ...error, [index]: boxError };
        }
        thenCopy[index] = thenAutomation;
      }
    }
    if (thenAutomation.automation_type == FACEBOOK) {
      if (thenAutomation.data) {
        const { message } = thenAutomation.data;
        let facebookError = {};
        if (isEmpty(message)) {
          facebookError = { ...facebookError, message: 'Please add message' };
        }
        if (!isEmpty(facebookError)) {
          error = { ...error, [index]: facebookError };
        }
        const sanatizedMessage = sanatizeMessageBody(
          thenAutomation.data.message
        );
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            message: sanatizedMessage
          }
        };
        thenCopy[index] = data;
      }
    }
    if (thenAutomation.automation_type == INSTAGRAM) {
      if (thenAutomation.data) {
        const { message } = thenAutomation.data;
        let facebookError = {};
        if (isEmpty(message)) {
          facebookError = { ...facebookError, message: 'Please add message' };
        }
        if (!isEmpty(facebookError)) {
          error = { ...error, [index]: facebookError };
        }
        const sanatizedMessage = sanatizeMessageBody(
          thenAutomation.data.message
        );
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            message: sanatizedMessage
          }
        };
        thenCopy[index] = data;
      }
    }

    // for sms
    if (thenAutomation.automation_type == SMS) {
      if (thenAutomation.data) {
        const { message } = thenAutomation.data;
        let smsError = {};
        const sanatizedMessage = sanatizeMessageBody(
          thenAutomation.data.message
        );
        if (isEmpty(sanatizedMessage)) {
          smsError = { ...smsError, message: 'Please provide message' };
        }
        if (!isEmpty(smsError)) {
          error = { ...error, [index]: smsError };
        }
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            message: sanatizedMessage,
            raw_sms_message: thenAutomation.data.message
          }
        };
        thenCopy[index] = data;
      }
    }
    // For Notes
    if (thenAutomation.automation_type == NOTES) {
      if (thenAutomation.data) {
        const { description } = thenAutomation.data;
        let notesError = {};
        if (isEmpty(description)) {
          notesError = { ...notesError, description: 'Please add note' };
        }
        if (!isEmpty(notesError)) {
          error = { ...error, [index]: notesError };
        }
        thenCopy[index] = {
          ...thenAutomation,
          position: thenAutomation.position || index
        };
      }
    }
    // For Task
    if (thenAutomation.automation_type == TASK) {
      if (thenAutomation.data) {
        const { note } = thenAutomation.data;
        let taskError = {};
        if (isEmpty(note)) {
          taskError = { ...taskError, note: 'Please add task description' };
        }
        if (!isEmpty(taskError)) {
          error = { ...error, [index]: taskError };
        }
        const data = {
          ...thenAutomation,
          position: thenAutomation.position || index,
          data: {
            ...thenAutomation.data,
            due_days_value: thenAutomation.data.due_days_value || 1,
            assigned_to: thenAutomation.data.assigned_to,
            task_type: taskType
          }
        };
        thenCopy[index] = data;
      }
    }
    dispatch(setAutomationErrors(error));
  };

  const loadCallback = (data) => {
    const updatedIndex = boxes.findIndex((item) => item.id === data?.id);
    const updatedBoxData = [
      ...boxes.slice(0, updatedIndex),
      data,
      ...boxes.slice(updatedIndex + 1)
    ];
    dispatch(setBoxes(updatedBoxData));
  };

  const onNewAutomationSuccess = () => {
    dispatch(getBoxAutomations({ query: { box_id } }));
    dispatch(
      getBoxData({
        boxId: box_id,
        callback: loadCallback
      })
    );
    dispatch(resetAutomationStore());
    setIsLoading(false);
    openSuccessSnackBar('Success');
  };

  const onUpdateAutomationSuccess = () => {
    dispatch(getBoxAutomations({ query: { box_id } }));
    dispatch(
      getBoxData({
        boxId: box_id,
        callback: loadCallback
      })
    );
    dispatch(resetAutomationStore());
    setIsLoading(false);
    openSuccessSnackBar('Success');
  };

  function buildDataToSave() {
    const when = {
      automation_name,
      box: box_id,
      stage: automationStage?.value,
      any_channel: isAnyChannel(),
      connected_channel: getConnectedChannelIds(),
      is_active: isAutomationActive
    };
    let thenCopy = [];
    if (!automation_name) {
      error = { ...error, automation_name: 'Please provide automation name' };
    }
    then.forEach((option, index) => {
      buildThenData(option, thenCopy, index, error);
    });
    const payload = {
      data: {
        ...when,
        then: thenCopy
      }
    };
    if (!isEmpty(error)) {
      return;
    }
    setIsLoading(true);
    if (isEmpty(selectedAutomation)) {
      dispatch(addBoxAutomation(payload, onNewAutomationSuccess));
    } else {
      dispatch(updateBoxAutomation(payload, onUpdateAutomationSuccess));
    }
  }

  const onSubmit = (data) => {
    buildDataToSave({ name: data.headder });
  };

  const onCancel = () => {
    dispatch(resetAutomationStore());
  };

  return (
    <div className="relative">
      <div className="mb-[50px]">
        <div className="card-box mt-6 pt-5 px-6 pb-6">
          <AutomationNameComp />
          <AutomationWhen />
          {!isEmpty(connected_channel) &&
            then?.map((condition, index) => {
              return (
                <AuomationThen
                  key={`then-${index}`}
                  setSelectedOption={setSelectedThenOptions}
                  selectedOption={selectedThenOptions}
                  index={index}
                  condition={condition}
                />
              );
            })}
          <div
            className="flex justify-between items-center"
            id={`boxThen-${box_id}`}
          >
            <div>
              {automationErrors.last_automation && (
                <ErrorMessage message={automationErrors.last_automation} />
              )}
            </div>
            <div className="flex justify-end mt-4">
              <CustomColorButton
                className="btn--xs btn--grey  mr-2"
                label="Cancel"
                onClick={onCancel}
                type="button"
              />
              {!isEmpty(connected_channel) && (
                <PrimaryButton
                  className="btn--xs"
                  label="Save"
                  onClick={onSubmit}
                  isLoading={isLoading}
                  loaderInline={true}
                  enableLoader={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Automation;

import React from 'react';
import Modal from 'components/modal';

const UploadImage = (props) => {
  const { open, setOpen, handleFileUplad, handleDropFile } = props;

  return (
    <>
      <Modal.Main open={open} className="modal--upload-profile-pic">
        <Modal.Header
          title="Upload Image"
          onClose={() => {
            setOpen(false);
          }}
        />
        <Modal.Body style={{ padding: '25px 30px 30px' }}>
          <div
            className="upload-profile-pic-container"
            onDrop={(e) => handleDropFile(e)}
            onDragOver={(e) => handleDropFile(e)}
          >
            <h4 className="text-sm font-semibold">
              Click to Upload or Drag and Drop
            </h4>
            <p className="text-grey-800 text-xs mt-2">
              Max file size <span className="text-xs font-medium">100MB</span>
            </p>
            <input
              type="file"
              id="uploadPic"
              className="upload-profile-pic__input"
              onChange={handleFileUplad}
              accept="image/png, image/jpeg"
            />
            <label
              htmlFor="uploadPic"
              className="upload-profile-pic__label mt-5"
            >
              Upload
            </label>
          </div>
          <div className="flex items-center justify-end mt-12">
            <button
              className="btn btn--secondary btn--md min-w-120 text-15"
              onClick={() => setOpen(false)}
            >
              Cancel
            </button>
            <button className="btn btn--primary btn--md min-w-120 text-15 ml-3">
              Upload
            </button>
          </div>
        </Modal.Body>
      </Modal.Main>
    </>
  );
};

export default UploadImage;

import CHOICES from 'choices';
import CustomToolTip from 'components/CustomTooltip';
import React from 'react';
import { useDispatch } from 'react-redux';
import {
  setEmailData,
  setIsPreview,
  setOnEdit,
  setTypeofAutomation
} from 'slices/automationStuffSlice';
import { setAttachments } from 'slices/editor/slice';

const {
  FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER,
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  MOVE_TO_BOX,
  SMS: SMS_REPLY
} = CHOICES.AutomationChoice;

const AutomationType = (props) => {
  const { label, automation_type } = props;
  const dispatch = useDispatch();

  const renderTooltipText = (type) => {
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return 'Automatically send a reply to the enquiry';
      case ASSIGN_MEMBER:
        return 'Assign the enquiry to a specific team member';
      case MOVE_TO_BOX:
        return 'Move the enquiry to a specific Box automatically';
      case SMS_REPLY:
        return 'Automatically send a message to the enquiry';
      case FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER:
        return 'Automatically send a message to the enquiry';
      default:
        return '';
    }
  };

  return (
    <div className="mt-2">
      <CustomToolTip
        position="top"
        tooltipText={renderTooltipText(automation_type)}
      >
        <div
          className="bg-grey-300 border border-grey-400 rounded-lg mr-3 text-15 font-semibold cursor-pointer"
          style={{ padding: '10px 16px' }}
          onClick={() => {
            dispatch(setIsPreview(false));
            dispatch(setTypeofAutomation(automation_type));
            dispatch(setOnEdit({}));
            dispatch(
              setEmailData({
                send_from: '',
                subject: '',
                message: '',
                cc: [],
                bcc: []
              })
            );
            dispatch(setAttachments([]));
          }}
        >
          {label}
        </div>
      </CustomToolTip>
    </div>
  );
};

export default AutomationType;

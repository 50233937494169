import CreateChatAi from './create';
import BotAIMain from './assistant/home';
import { Routes, Route } from 'react-router-dom';
import Wrapper from './wrapper';

const DashBoard = () => {
  return (
    <section className="dashboard__main dashboard__main--create-bot">
      <Routes>
        <Route path="/*" element={<CreateChatAi />} />
        <Route path="/create/*" element={<CreateChatAi />} />
        <Route
          path="/assistant/:assistant_id/*"
          element={
            <Wrapper>
              <BotAIMain />
            </Wrapper>
          }
        />
      </Routes>
    </section>
  );
};

export default DashBoard;

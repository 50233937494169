import React from 'react';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch } from 'react-redux';
import {
  setEmailData,
  setOnEdit,
  setTypeofAutomation
} from 'slices/automationStuffSlice';
import choices from 'choices';
import { setAttachments } from 'slices/editor/slice';

const AUTORESPONDER_EMAIL = choices.AutomationChoice.AUTORESPONDER_EMAIL;
const FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER =
  choices.AutomationChoice.FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER;
const { WEBFORM, FACEBOOK, SMS, EBOT, INSTAGRAM, FACEBOOK_LEAD_ADS, ZAPIER } =
  choices.ChannelChoices;

const SendEmail = ({ channelType }) => {
  const dispatch = useDispatch();
  return (
    <div
      className={`box-settings__automation-template__automation-stuff mt-2  py-3 px-3 flex justify-between items-center`}
    >
      <div className="font-semibold text-base">
        Send an autoresponder{' '}
        {channelType === WEBFORM ||
        channelType === EBOT ||
        channelType === ZAPIER
          ? 'email'
          : (channelType === FACEBOOK ||
              channelType === INSTAGRAM ||
              channelType === SMS) &&
            'message'}
      </div>
      <PrimaryButton
        className="btn--xs  flex items-center"
        label="setup"
        onClick={() => {
          dispatch(
            setTypeofAutomation(
              channelType === WEBFORM ||
                channelType === FACEBOOK_LEAD_ADS ||
                channelType === SMS ||
                channelType === EBOT ||
                channelType === ZAPIER
                ? AUTORESPONDER_EMAIL
                : (channelType === FACEBOOK || channelType === INSTAGRAM) &&
                    FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER
            )
          );
          dispatch(setOnEdit({}));
          dispatch(
            setEmailData({
              send_from: '',
              subject: '',
              message: '',
              cc: [],
              bcc: []
            })
          );
          dispatch(setAttachments([]));
        }}
      />
    </div>
  );
};

export default SendEmail;

import React, { useEffect, useState } from 'react';
import EmailBody from './emailBody';
import { isEmpty, timeFormatter } from 'utils/utils';
import Avatar from 'components/avatar';
import { ReactComponent as AutomateIcon } from 'assets/icons/icon-automated-email-icon.svg';
import CustomToolTip from 'components/CustomTooltip';
// import iconExpand from 'assets/icons/icon-expand.svg';
import CHOICES from 'choices';
import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';

const SMS = CHOICES.LeadChannelChoice.SMS;

const Email = ({ data, isCollapse }) => {
  const [isCollapsed, setIsCollapsed] = useState(isCollapse);
  // const [showExpandIcon, setShowExpandIcon] = useState(null);
  const [finalMessage, setFinalMessage] = useState('');

  useEffect(() => {
    if (!isEmpty(data) && data.is_reply_received) {
      let message = data.message.split('*');
      for (let i = 0; i < message.length; i++) {
        if (i % 2 !== 0) {
          message[i] = `<strong>${message[i]}</strong>`;
        }
      }
      setFinalMessage(message.join(''));
    }
  }, [data]);
  return (
    <div
      className={`chat-message-list ${
        data.is_reply_sent ? 'chat-message-list--sender' : ''
      } flex`}
      id={data.id}
    >
      {data?.lead?.channel === SMS && data?.is_reply_received ? (
        <CustomToolTip
          position="right"
          tooltipText={data?.lead?.first_name || data?.lead?.name}
          style={{ height: '35px' }}
        >
          <figure
            className="general-settings__card-list__avatar-container"
            style={{ maxHeight: 32, maxWidth: 32 }}
          >
            <IconMyProfile style={{ width: 18, height: 18 }} />
          </figure>
        </CustomToolTip>
      ) : (
        <CustomToolTip
          position={data.is_reply_sent ? 'left' : 'right'}
          style={{ height: '35px' }}
          tooltipText={
            data?.sent_by_automation
              ? 'Automated Email'
              : data.is_reply_sent
              ? data.message_sent_by?.first_name
              : data?.lead?.name
          }
          // renderAvatar={() => (
          //   <Avatar
          //     src={
          //       data.is_reply_sent
          //         ? data?.message_sent_by?.avatar
          //         : data?.lead?.customer_avatar
          //     }
          //     initial={
          //       data.is_reply_sent
          //         ? data.message_sent_by?.first_name
          //         : data?.lead?.name
          //     }
          //     border={false}
          //     smaller
          //     contain
          //     initialStyle={{
          //       marginBottom: 0
          //     }}
          //     singleChar
          //   />
          // )}
        >
          <Avatar
            src={
              data?.sent_by_automation
                ? null
                : data.is_reply_sent
                ? data?.message_sent_by?.avatar
                : data?.lead?.customer_avatar
            }
            initial={
              data?.sent_by_automation
                ? 'A E'
                : data.is_reply_sent
                ? data.message_sent_by?.first_name
                : data?.lead?.name
            }
            border={false}
            small
            contain
            initialStyle={{
              marginBottom: 0
            }}
            singleChar={!data?.sent_by_automation}
            style={{ background: data?.sent_by_automation ? '#53BAC0' : '' }}
          />
        </CustomToolTip>
      )}
      <div
        className="content"
        style={
          data.is_reply_sent
            ? { marginRight: 6, width: 'calc(100% - 10%)' }
            : { marginLeft: 6, width: 'calc(100% - 10%)' }
        }
      >
        {/* <EmailConversationBody sender={true} /> */}
        {isCollapsed ? (
          <div
            className="inline-block border border-grey-400 text-base-1 text-grey-900 cursor-pointer"
            style={{
              borderRadius: 10,
              padding: '12px 24px 12px 16px',
              backgroundColor: data?.sent_by_automation
                ? '#FEF8F6'
                : data.is_reply_sent
                ? '#FEF8F6'
                : '#EEF7FC',
              width: '90%'
            }}
            // onClick={() => setIsCollapsed(false)}
            // onMouseEnter={() => setShowExpandIcon(data.id)}
            // onMouseLeave={() => setShowExpandIcon(null)}
          >
            <div className="flex justify-between items-center">
              <div
                style={{ maxWidth: '90%', maxHeight: 40 }}
                className="overflow-y-hidden"
              >
                <h3 className="font-semibold">Subject: {data?.subject}</h3>
                <div>
                  {data.is_reply_received ? (
                    <p
                      className="pre font-medium text-13 truncate"
                      dangerouslySetInnerHTML={{ __html: finalMessage }}
                    />
                  ) : (
                    <p
                      className="font-medium text-13 truncate"
                      dangerouslySetInnerHTML={{ __html: data.message }}
                    />
                  )}
                </div>
              </div>
              {/* {showExpandIcon === data.id && (
                <CustomToolTip position="top" tooltipText="Expand">
                  <img
                    src={iconExpand}
                    className="cursor-pointer"
                    alt="icon-expand"
                    // onClick={() => setIsCollapsed(false)}
                  />
                </CustomToolTip>
              )} */}
            </div>
          </div>
        ) : (
          <EmailBody
            data={data}
            isCollapsed={isCollapsed}
            setIsCollapsed={setIsCollapsed}
          />
        )}
        <div className="flex items-center gap-2">
          {data?.sent_by_automation && (
            <>
              <AutomateIcon style={{ marginTop: 6 }} />
              <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
                Automated Email
              </div>
              <code
                style={{
                  background: '#C4C2C0',
                  width: '3.5px',
                  height: '3.5px',
                  borderRadius: '50%',
                  marginTop: '7px'
                }}
              ></code>
            </>
          )}
          <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
            {timeFormatter(data.created_at)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Email;

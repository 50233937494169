const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// url to get all the task outcome
export const GET_ALL_TASK_OUTCOME = `${BASE_API_URL}/tasks/update-task-outcome/`;

// url to get all the new tasks in lead
export const GET_ALL_NEW_TASKS = `${BASE_API_URL}/tasks/tasks/new_tasks/`;

// url to get all the completed tasks in lead
export const GET_ALL_COMPLETED_TASKS = `${BASE_API_URL}/tasks/tasks/completed_tasks/`;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import TextInputField from 'components/inputs/textField';
import Select from 'components/inputs/select';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import CHOICES from 'choices';
import { getFormSchema } from 'components/fieldsAndValidation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { requiredMsg } from 'utils/messages';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ConnectSmsChannel } from 'slices/sms/slice';
import {
  getAllConnectedChannels,
  getAllPendingChannels
} from 'slices/channelsSlice';
import { getMyInfo } from 'slices/myInfoSlice';
import { useSnackbar } from 'components/Snackbar';
import { route } from 'utils/route';
import { isEmpty } from 'utils/utils';
import swal from 'sweetalert';
import { useAnalytics } from 'use-analytics';
import { getSmsDetails } from 'slices/subscription/slice';

const ConfirmBusinessDetails = ({ open, onCancel, plan_id }) => {
  const options = CHOICES.CountryChoices.CHOICE_LIST;
  const navigate = useNavigate();
  // const { channelId } = useParams();
  const dispatch = useDispatch();
  const [postcode, setPostCode] = useState('');
  // const [selectedCountry] = useState('1');
  const [selectedCountry, setSelectedCountry] = useState({
    label: 'United Kingdom',
    value: 1
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState({});
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { openSuccessSnackBar } = useSnackbar();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const formSchema = {
    ...getFormSchema(['businessName']),
    streetName: string().strict().required(requiredMsg('street name')),
    city: string().strict().required(requiredMsg('city')),
    region: string().strict().required(requiredMsg('region'))
  };
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  useEffect(() => {
    reset();
    setPostCode('');
  }, [open]);

  const callback = (data) => {
    dispatch(getAllPendingChannels());
    dispatch(getAllConnectedChannels());
    dispatch(getMyInfo());
    dispatch(getSmsDetails());
    // track('channel_created', {
    //   channel_name: data?.name
    // });
    openSuccessSnackBar('Connected');
    // navigate(
    //   `${route.dashboard}${route.enquirybox}/settings/sms/connected/${data?.id}`
    // );
    setIsLoading(false);
    reset();
    onCancel();
  };

  const onPostcodeChange = (value) => {
    delete isError['Post code'];
    setPostCode(value);
  };

  const onOptionChange = (data) => {
    delete isError['Country'];
    setSelectedCountry(data);
  };

  const checkIsValid = () => {
    let err = {};
    let isValid = true;
    if (isEmpty(postcode)) {
      err = {
        ...err,
        'Post code': 'Post code cannot be empty'
      };
      isValid = false;
    }
    if (isEmpty(selectedCountry)) {
      err = {
        ...err,
        Country: 'selected Country cannot be empty'
      };
      isValid = false;
    }
    setIsError(err);
    return isValid;
  };

  const errorCallback = (err) => {
    setIsLoading(false);
    swal({
      title: 'Error',
      text: err?.response?.data?.error,
      icon: 'error',
      button: {
        text: 'Ok',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      }
    });
  };

  const onSubmit = async (data) => {
    const isValid = checkIsValid();
    if (isValid) {
      setIsLoading(true);
      const formData = {
        address: {
          customer_name: data.businessName,
          street: data.streetName,
          city: data.city,
          region: data.region,
          postal_code: postcode,
          iso_country: selectedCountry.value
        },
        sms_plan_id: plan_id
      };
      dispatch(
        ConnectSmsChannel({
          body: formData,
          subOrgId: subOrganizationId,
          callback,
          errorCallback
        })
      );
    }
  };

  return (
    <Modal.Main open={open}>
      <Modal.Header
        title="Confirm your business details"
        onClose={() => {
          setIsError({});
          reset();
          onCancel();
        }}
      />
      <Modal.Body>
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div>
            <label className="text-15 font-semibold">Business Name</label>
            <TextInputField
              name="businessName"
              className="mt-1 input-field--md-38"
              placeholder="Add the name you refer to your business as"
              register={register}
              error={errors.businessName && touchedFields.businessName}
            />
          </div>
          <div className="mt-4">
            <label className="text-15 font-semibold">Business Address</label>
            <TextInputField
              name="streetName"
              className="mt-1 input-field--md-38"
              placeholder="Street name"
              register={register}
              error={errors.streetName && touchedFields.streetName}
            />
            <TextInputField
              name="city"
              className="input-field--md-38 mt-2"
              placeholder="City / Town"
              register={register}
              error={errors.city && touchedFields.city}
            />
            <TextInputField
              name="region"
              className="input-field--md-38 mt-2"
              placeholder="County / region"
              register={register}
              error={errors.region && touchedFields.region}
            />
            <TextInputField
              name="phone"
              className="input-field--md-38 mt-2"
              placeholder="Postcode"
              value={postcode}
              error={!isEmpty(isError) && !isEmpty(isError['Post code'])}
              onChange={(e) => onPostcodeChange(e.target.value)}
            />
            {/* <TextInputField
              name="country"
              className="input-field--md-38 mt-2"
              placeholder=""
              value={selectedCountry}
              readOnly
            /> */}
            <Select
              open={true}
              options={options}
              inputClassName="input-field--type2 mt-2"
              style={{ padding: '7px 15px' }}
              name="country"
              value={selectedCountry.label}
              selectedValue={selectedCountry}
              error={!isEmpty(isError) && !isEmpty(isError['Country'])}
              onChange={onOptionChange}
              dropDownClassName="p-2"
            />
          </div>
          <div className="flex items-center justify-end mt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--secondary btn--md min-w-120"
              onClick={() => {
                setIsError({});
                reset();
                onCancel();
              }}
            />
            <PrimaryButton
              className="btn--md min-w-120 ml-3"
              label="Confirm"
              enableLoader
              isLoading={isLoading}
              loaderInline
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

export default ConfirmBusinessDetails;

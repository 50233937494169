import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import enquiryOutline from 'assets/icons/icon-assign-enquiries-outline.svg';

const EnquirySource = (props) => {
  const {
    paidTotal,
    paidPercentage,
    unPaidTotal,
    unPaidPercentage,
    hideCount
  } = props;
  return (
    <div
      className="card-box-shadow w-full p-5 pb-3 ml-3 mr-3"
      style={{ width: '50%' }}
    >
      <div className="flex items-center pr-4">
        <figure
          className="avatar-container avatar-container--contain bg-grey-300"
          style={{
            width: 38,
            height: 38,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <img src={enquiryOutline} width={17} height={17} />
        </figure>
        <CustomToolTip
          style={{ width: '100%' }}
          tooltipStyle={{
            width: 250,
            top: -100,
            bottom: 'initial',
            left: -58,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="Enquiries from paid ads sources like facebook ads, google ads etc. Non paid ad sources is anything else"
        >
          <p className="letterspacing24em font-semibold ml-3">
            Source of enquiries
          </p>
        </CustomToolTip>
      </div>
      <ul className="mt-3">
        <li className="flex items-center justify-between source-report__card-list">
          <div className="text-sm font-medium pr-4">From paid AD sources</div>
          <div className="flex items-center font-bold">
            {hideCount ? 0 : paidTotal}
            <span className="text-13 text-grey-600 font-medium ml-1">
              {paidPercentage && !hideCount
                ? parseFloat(paidPercentage).toFixed(2).replace(/\.00$/, '')
                : 0}
              %
            </span>
          </div>
        </li>
        <li className="flex items-center justify-between source-report__card-list">
          <div className="text-sm font-medium pr-3">From non paid sources</div>
          <div className="flex items-center font-bold">
            {hideCount ? 0 : unPaidTotal}
            <span className="text-13 text-grey-600 font-medium ml-1">
              {unPaidPercentage && !hideCount
                ? parseFloat(unPaidPercentage).toFixed(2).replace(/\.00$/, '')
                : 0}
              %
            </span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default EnquirySource;

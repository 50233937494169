/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import CustomColorButton from 'components/buttons/customColorButton';
import ErrorMessage from 'components/errorMessage';
import TextInputField from 'components/inputs/textField';
import Modal from 'components/modal';
import useClickOutside from 'hooks/useOutsideClick';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getCustomBoxtemplates,
  setBoxIcon,
  setboxName
} from 'slices/customBoxSlice';
import { route } from 'utils/route';
import { isEmpty } from 'utils/utils';
import EmojiModal from './modules/EmojiModal';

const AddCustomBoxModal = ({
  open,
  onClose,
  handleCreateBox,
  setIsCreateLoading,
  isCreateLoading
}) => {
  const [openEmoji, setOpenEmoji] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('&#127795;');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nodeRef = useRef();

  const { boxName, boxTemplates } = useSelector((state) => state.addcustomBox);

  // useEffect(() => {
  //   if (isEmpty(boxTemplates)) {
  //     dispatch(getCustomBoxtemplates());
  //   }
  // }, [boxTemplates]);

  const handleAddBoxTelmpate = () => {
    if (!isEmpty(boxName) && boxName.length < 30) {
      setIsCreateLoading(true);
      handleCreateBox();
      setError('');
      setSelectedEmoji('&#127795;');
    } else {
      setError('please enter valid box name, maximum 30 characters allowed ');
    }
  };
  useClickOutside(() => setOpenEmoji(false), nodeRef.current);

  const onEmojiSelect = (data) => {
    dispatch(setBoxIcon(data.emoji));
    setSelectedEmoji(data.emoji);
    setOpenEmoji(false);
  };

  return (
    <Modal.Main open={open} className="modal--channels-request">
      <Modal.Header
        title="Add a box"
        onClose={() => {
          onClose();
          dispatch(setboxName(''));
          dispatch(setBoxIcon('🌳'));
          setError('');
        }}
      />
      <Modal.Body className="pb-10 overflow-y-auto">
        <React.Fragment>
          <div className="">
            <label className="input-field-label">Box name</label>
            <TextInputField
              name="box-name"
              placeholder="Give it a name that reflects what you will put in it"
              value={boxName}
              onChange={(evt) => dispatch(setboxName(evt.target.value))}
            />
          </div>
          {!isEmpty(error) && <ErrorMessage message={error} />}
          <div className="mt-25" ref={nodeRef}>
            <label className="input-field-label">Add an icon</label>
            <figure
              className="avatar-container avatar-container--contain cursor-pointer"
              style={{
                width: 70,
                height: 70,
                fontSize: '40px',
                boxShadow: 'inset 0px 0px 0px 1px rgb(0 0 0 / 5%)'
              }}
              onClick={() => setOpenEmoji(!openEmoji)}
            >
              <div dangerouslySetInnerHTML={{ __html: selectedEmoji }}></div>
            </figure>
            {openEmoji && (
              <div>
                <EmojiModal
                  hideSearch={true}
                  className="mt-1"
                  setOpenEmoji={setOpenEmoji}
                  onEmojiSelect={onEmojiSelect}
                />
              </div>
            )}
          </div>

          <div className="flex items-center gap-2 justify-end mt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--wide-md-40 btn--cancel text-base-1 mr-3"
              onClick={() => {
                onClose();
                dispatch(setboxName(''));
                dispatch(setBoxIcon('🌳'));
                setError('');
              }}
            />
            <CustomColorButton
              label="Add"
              className="btn--wide-md-40 text-base-1 bg-primary text-white mr-3"
              onClick={() => handleAddBoxTelmpate()}
              enableLoader
              isLoading={isCreateLoading}
              loaderInline
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default AddCustomBoxModal;

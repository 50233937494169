import ErrorMessage from 'components/errorMessage';
import React from 'react';
import UserInfoForm from './UserInfoForm';
import PrimaryButton from 'components/buttons/primary';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSelector } from 'react-redux';

const DataCollectionBox = (props) => {
  const { handleSubmit, onSubmit, register, touchedFields, errors, isLoading } =
    props;
  const { userDataThatBotNeeds } = useSelector((state) => state.assistant);
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className="flex flex-col rounded-md p-1 mt-2 w-[500px] relative"
          style={{
            backgroundColor: '#ffffd8',
            boxShadow: '0px 4px 10px #e5e6f1',
            border: '1px solid #DADADA'
          }}
        >
          {userDataThatBotNeeds?.map((data, index) => {
            const fieldName = data.field_name;
            const mandatory = data.is_mandatory;
            const fieldType = data.field_type;
            return (
              <div key={index}>
                {fieldType === 'date' ? (
                  <div className="relative">
                    <input
                      name={fieldName}
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        height: '42px',
                        borderColor:
                          errors[fieldName] && touchedFields[fieldName]
                            ? 'red'
                            : '#e6e4e1'
                      }}
                      type="date"
                      className="border border-1px solid px-4 mb-6"
                      placeholder={mandatory ? `${fieldName}*` : fieldName}
                      {...register(fieldName)}
                    />
                    <div className="absolute top-[57%]">
                      {errors[fieldName] && touchedFields[fieldName] && (
                        <ErrorMessage
                          className="ml-1 mb-2"
                          message={errors[fieldName].message}
                        />
                      )}
                    </div>
                  </div>
                ) : fieldType === 'time' ? (
                  <div className="relative">
                    <input
                      name={fieldName}
                      type="time"
                      style={{
                        width: '100%',
                        borderRadius: '8px',
                        height: '42px',
                        borderColor:
                          errors[fieldName] && touchedFields[fieldName]
                            ? 'red'
                            : '#e6e4e1'
                      }}
                      className="border border-1px px-4 mb-6"
                      placeholder={mandatory ? `${fieldName}*` : fieldName}
                      {...register(fieldName)}
                    />
                    <div className="absolute top-[57%]">
                      {errors[fieldName] && touchedFields[fieldName] && (
                        <ErrorMessage
                          className="ml-1 mb-2"
                          message={errors[fieldName].message}
                        />
                      )}
                    </div>
                  </div>
                ) : fieldType === 'bool' ? (
                  <div className="relative">
                    <div
                      className="flex items-center mb-6 w-full bg-white h-[42px] px-[16px]"
                      style={
                        errors[fieldName] && touchedFields[fieldName]
                          ? { border: '1px solid red', borderRadius: '8px' }
                          : { border: '1px solid #e6e4e1', borderRadius: '8px' }
                      }
                    >
                      <label
                        className="mr-5"
                        style={{ color: '#A9A9A9', fontSize: '18px' }}
                      >
                        {mandatory ? `${fieldName}*` : fieldName}
                      </label>
                      <div className="flex">
                        <div className="mr-10   ">
                          <input
                            type="radio"
                            name={fieldName}
                            value="yes"
                            {...register(fieldName)}
                          />
                          <span className="ml-2">Yes</span>
                        </div>
                        <div>
                          <input
                            name={fieldName}
                            type="radio"
                            value="no"
                            {...register(fieldName)}
                          />
                          <span className="ml-2">No</span>
                        </div>
                      </div>
                    </div>
                    <div className="absolute top-[90%]">
                      {errors[fieldName] && touchedFields[fieldName] && (
                        <ErrorMessage
                          className="ml-1 mb-2"
                          message={errors[fieldName].message}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <UserInfoForm
                    placeholder={mandatory ? `${fieldName}*` : fieldName}
                    name={fieldName}
                    register={register}
                    errors={errors}
                    touchedFields={touchedFields}
                  />
                )}
              </div>
            );
          })}
          <PrimaryButton
            className="h-[42px]"
            label="Submit"
            type="submit"
            style={{ backgroundColor: '#52c26d' }}
            disabled={isLoading ? true : false}
            isLoading={isLoading ? isLoading : false}
          />
          {isLoading && <OverLayLoader />}
        </div>
      </form>
    </>
  );
};

export default DataCollectionBox;

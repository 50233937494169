import React, { useEffect, useState, useRef } from 'react';
import iconAutomationthen from 'assets/icons/icon-automation-then-icon.svg';
import iconDots from 'assets/icons/icon-three-dots.svg';
import iconDelete from 'assets/icons/icon-Delete.svg';
import { deleteThen, removeThen } from 'slices/boxAutomation/slice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import useClickOutside from 'hooks/useOutsideClick';
import { useSnackbar } from 'components/Snackbar';
import { isEmpty } from 'utils/utils';

const getData = (condition) => {
  // const type = condition.automation_type
  return { then_task_id: condition.automation_task_id };
};
const ThenHeader = ({ description, condition, index }) => {
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const headerRef = useRef();
  const optionRef = useRef();
  const { openSuccessSnackBar } = useSnackbar();

  useClickOutside(() => setShowMoreDropdown(false), optionRef.current);

  useEffect(() => {
    return () => {
      headerRef.current = false; // clean up function
    };
  }, []);

  const onSuccess = () => {
    if (headerRef.current) {
      setShowDeleteConfirmation(false);
      setShowMoreDropdown(false);
    }
    openSuccessSnackBar('Delete success');
    setIsLoading(false);
  };

  const onDelete = () => {
    if (condition.automation_task_id) {
      setIsLoading(true);
      const data = getData(condition);
      dispatch(
        deleteThen(
          { query: { then_task_id: data.then_task_id }, index, box_id },
          onSuccess
        )
      );
    } else {
      setIsLoading(true);
      dispatch(removeThen({ index }, onSuccess));
    }
  };

  const checkIfConditionIsEmpty = () => {
    if (index === 0 && isEmpty(condition)) return false;
    return true;
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        onConfirm={onDelete}
        title="Are you sure you want to delete this automation?"
        description=""
        isLoading={isLoading}
      />
      <div className="flex justify-between" ref={headerRef}>
        <div className="flex">
          <img
            src={iconAutomationthen}
            alt=""
            style={{ width: 52, height: 50 }}
          />
          <div className="ml-4">
            <h4 className="font-semibold">Then...</h4>
            <p className="text-sm text-grey-800">{description}</p>
          </div>
        </div>
        {checkIfConditionIsEmpty() && (
          <div
            className="relative"
            ref={optionRef}
            onClick={() => setShowMoreDropdown(!showMoreDropdown)}
          >
            <img
              src={iconDots}
              alt=""
              className="cursor-pointer"
              style={{ width: 16, height: 4 }}
            />
            <div
              className={`dropdown-menu dropdown-menu--more ${
                showMoreDropdown ? 'open' : ''
              }`}
              style={{ top: 20 }}
            >
              <div
                className="dropdown-menu__list red-text"
                onClick={() => setShowDeleteConfirmation(true)}
              >
                <img
                  src={iconDelete}
                  alt=""
                  className="mr-2"
                  style={{ width: 16, height: 16 }}
                />
                Delete
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

ThenHeader.defaultProps = {
  description: 'This action will happen automatically'
};

export default ThenHeader;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useMemo } from 'react';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import AutomationStuffWhen from '../../components/when';
import AutomationStuffThen from '../../components/then';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import {
  setTypeofAutomation,
  getFbLeadsAdsConnectedChannels,
  setEmailError,
  getConnectedChannel,
  sendAutomation,
  getAllEnquiryAndSubEnquiry,
  setIsPreview
} from 'slices/automationStuffSlice';
import CHOICES from 'choices';
import ErrorMessage from 'components/errorMessage';

const { FACEBOOK, FACEBOOK_LEAD_ADS, WEBFORM, INSTAGRAM, EBOT, ZAPIER, SMS } =
  CHOICES.ChannelChoices;

const MoveEnquiriesToBoxSetup = ({
  channelType,
  title,
  tempChannelType,
  changeAutomationName
}) => {
  const parentRef = useRef();
  const { connectedChannels, enquiryAndSubEnquiries, isPreview } = useSelector(
    (state) => state.automationStuff
  );
  const { typeofAutomation } = useSelector((state) => state.automationStuff);
  const [assignedUserInfo, setAssignedUserInfo] = useState();
  const [selectedStage, setSelectedStage] = useState(null);
  const { onEdit } = useSelector((state) => state.automationStuff);
  const [selectedChannelOption, setSelectedChannelOption] = useState();
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const [automationName, setAutomationName] = useState(title);
  const [isError, setIsError] = useState('');
  const [filterdConnectedChannel, setFilteredConnectedChannel] = useState([]);
  const [selectedBotOptions, setSelectedBotOptions] = useState([]);
  const { boxes } = useSelector((state) => state.boxes);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const moveToOptions = boxes?.map((item) => {
    return {
      label: item?.box_name,
      value: item?.id,
      avatarPic: item?.box_avatar
    };
  });
  const box_stages = useMemo(() => {
    if (assignedUserInfo) {
      const box = boxes.find((box) => box.id === assignedUserInfo.value);
      if (box) {
        return box.boxstage_set.map((stage) => {
          return {
            label: stage?.stage_name,
            value: stage?.id
          };
        });
      }
    }
    return [];
  }, [assignedUserInfo]);

  useEffect(() => {
    if (isEmpty(onEdit)) {
      setAssignedUserInfo();
      setSelectedChannelOption();
      setSelectedBotOptions([]);
      setSelectedStage(null);
    }
  }, [onEdit]);

  useEffect(() => {
    setAutomationName(title);
  }, [title]);

  useEffect(() => {
    if (channelType === EBOT) {
      const payload = {
        subOrgId: subOrganizationId,
        channelId: channelId
      };
      dispatch(getAllEnquiryAndSubEnquiry(payload));
    }
  }, [channelType]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === CHOICES.AutomationChoice.MOVE_TO_BOX &&
      channelType === EBOT
    ) {
      const selectedOptionId = onEdit?.ebot_options.map((item) => item.id);
      setSelectedBotOptions(selectedOptionId);
    }
  }, [onEdit]);

  useEffect(() => {
    if (!isEmpty(onEdit) && !isEmpty(box_stages)) {
      const stage = box_stages.find(
        (stage) => stage.value === onEdit.move_to_stage
      );
      setSelectedStage(stage);
    }
  }, [box_stages]);

  useEffect(() => {
    if (!isEmpty(connectedChannels)) {
      setFilteredConnectedChannel(
        connectedChannels?.map((item) => {
          return {
            label: item?.name,
            value: item?.id
          };
        })
      );
      // !isEmpty(newConnectdChannel) && setFilteredConnectedChannel(newConnectdChannel)
    }
  }, [connectedChannels]);

  useEffect(() => {
    if (!isEmpty(onEdit)) {
      if (channelType === WEBFORM) {
        if (onEdit?.any_web_form) {
          setSelectedChannelOption({
            value: 'anyWebform',
            label: 'Any WebForm'
          });
        } else {
          const selectedForm = filterdConnectedChannel.filter((item) => {
            return item.value === onEdit?.forms;
          });
          setSelectedChannelOption(selectedForm[0]);
        }
      }
      if (channelType === FACEBOOK_LEAD_ADS) {
        if (!onEdit.fb_lead_ads_form) {
          setSelectedChannelOption({ value: null, label: 'Any Form' });
        } else {
          const selectedForm = filterdConnectedChannel.filter((item) => {
            return item.value === onEdit?.fb_lead_ads_form;
          });
          setSelectedChannelOption(selectedForm[0]);
        }
      }
      if (onEdit?.move_to_box) {
        const selectedBox = moveToOptions.filter((item) => {
          return item.value === onEdit.move_to_box;
        });
        setAssignedUserInfo(selectedBox[0]);
      }
    }
  }, [filterdConnectedChannel]);

  useEffect(() => {
    const payload = {
      channelId
    };
    if (channelType === WEBFORM) {
      dispatch(getConnectedChannel(payload));
    } else if (channelType === FACEBOOK_LEAD_ADS) {
      dispatch(getFbLeadsAdsConnectedChannels(payload));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(assignedUserInfo)) {
      setIsError('');
    }
  }, [assignedUserInfo]);

  useEffect(() => {
    if (
      channelType === FACEBOOK ||
      channelType === INSTAGRAM ||
      channelType === ZAPIER ||
      channelType === SMS
    ) {
      setSelectedChannelOption({ value: 'anyMessage', label: 'Any Message' });
    }
  }, []);

  const checkIsValid = () => {
    let isValid = true;
    let err = {};
    // if (
    //   !isEmpty(automationName) &&
    //   (automationName.length < 4 || automationName.length > 20)
    // ) {
    //   isValid = false;
    // }
    if (isEmpty(automationName)) {
      err = {
        ...err,
        automationName: 'Please add and save your automation name'
      };
      isValid = false;
    }
    if (!assignedUserInfo) {
      setIsError('Please select the box to move the enquiry');
      isValid = false;
    }
    if (assignedUserInfo && !selectedStage) {
      setIsError('Please select the stage to move the enquiry');
      isValid = false;
    }
    dispatch(setEmailError(err));
    return isValid;
  };

  const handleSave = () => {
    if (
      channelType === WEBFORM ||
      channelType === FACEBOOK_LEAD_ADS ||
      channelType === EBOT
    ) {
      const isValid = checkIsValid();
      if (isValid) {
        const payload = {
          type: 'post',
          data: {
            any_web_form:
              selectedChannelOption?.value === 'anyWebform' ||
              selectedChannelOption?.label === 'Any Form',
            automation_type: typeofAutomation,
            automation_name: automationName,
            is_active: true,
            connected_channel: channelId,
            move_to_box: assignedUserInfo.value,
            move_to_stage: selectedStage.value
          }
        };
        if (
          selectedChannelOption?.value !== 'anyWebform' &&
          channelType !== FACEBOOK_LEAD_ADS
        ) {
          payload.data['forms'] = selectedChannelOption?.value;
        }
        if (channelType === FACEBOOK_LEAD_ADS) {
          payload.data['fb_lead_ads_form'] = selectedChannelOption?.value;
        }
        if (channelType === EBOT) {
          payload.data['ebot_options'] = selectedBotOptions;
        }
        if (!isEmpty(onEdit)) {
          payload['type'] = 'put';
          payload['data']['id'] = onEdit.id;
          payload['data']['is_active'] = onEdit.is_active;
        }
        dispatch(sendAutomation(payload));
      }
    } else if (
      channelType === FACEBOOK ||
      channelType === INSTAGRAM ||
      channelType === ZAPIER ||
      channelType === SMS
    ) {
      const isValid = checkIsValid();
      if (isValid) {
        const payload = {
          type: 'post',
          data: {
            // any_web_form: selectedChannelOption?.value === 'anyWebform',
            automation_type: typeofAutomation,
            automation_name: automationName,
            is_active: true,
            connected_channel: channelId,
            move_to_box: assignedUserInfo.value,
            move_to_stage: selectedStage.value
          }
        };
        if (!isEmpty(onEdit)) {
          payload['type'] = 'put';
          payload['data']['id'] = onEdit.id;
          payload['data']['is_active'] = onEdit.is_active;
        }
        dispatch(sendAutomation(payload));
      }
    }
  };

  return (
    <>
      <div className="mt-6" ref={parentRef}>
        {(channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === ZAPIER ||
          channelType === EBOT ||
          tempChannelType === SMS) && (
          <AutomationStuffWhen
            options={
              channelType === EBOT
                ? enquiryAndSubEnquiries
                : filterdConnectedChannel
            }
            selectedOption={
              channelType === EBOT ? selectedBotOptions : selectedChannelOption
            }
            setSelectedOption={
              channelType === EBOT
                ? setSelectedBotOptions
                : setSelectedChannelOption
            }
            automationName={automationName}
            setAutomationName={setAutomationName}
            channelType={channelType}
            parentRef={parentRef}
            onEdit={onEdit}
            tempChannelType={tempChannelType}
          />
        )}
        {(selectedChannelOption || !isEmpty(selectedBotOptions)) && (
          <AutomationStuffThen
            options={moveToOptions}
            assignedUserInfo={assignedUserInfo}
            setSelectedOption={setAssignedUserInfo}
            selectedStage={selectedStage}
            setSelectedStage={setSelectedStage}
            box_stages={box_stages}
            title="Move to a Box"
            changeAutomationName={changeAutomationName}
            automationName={automationName}
          />
        )}
        {isError && <ErrorMessage message={isError} />}
        <div className="flex justify-end mt-2">
          <CustomColorButton
            className="btn--xs btn--grey mr-2"
            label={`${isPreview ? 'Close Preview' : 'Cancel'}`}
            onClick={() => {
              dispatch(setIsPreview(false));
              dispatch(setTypeofAutomation(''));
            }}
          />
          {(selectedChannelOption || !isEmpty(selectedBotOptions)) &&
            !isPreview && (
              <PrimaryButton
                className="btn--xs"
                label="Save"
                onClick={() => handleSave()}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default MoveEnquiriesToBoxSetup;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-icon.svg';
import {
  deleteFile,
  getFileLibraryData,
  setFileData,
  uploadFilesSettings
} from 'slices/fileLibrary/slice';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as FileLibraryIcon } from 'assets/icons/icon-settings-file-library.svg';
import { isEmpty } from 'utils/utils';
import Uploader from 'modules/modals/attachFiles/upload';
// import Uploader from './component/uploader';
import FileItem from './component/FileItem';
import useClickOutside from 'hooks/useOutsideClick';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { Loader } from 'components/loader';
import { useSnackbar } from 'components/Snackbar';
import { stubFalse } from 'lodash';
import usePlanAccess from 'hooks/usePlanAccess';

const FileLibrary = () => {
  const fileNode = useRef();
  const dispatch = useDispatch();
  const { has_file_library } = usePlanAccess();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [isUploadFail, setIsUploadFail] = useState(false);
  const [openEdit, setOpenEdit] = useState(null);
  const [uploadFile, setUploadFile] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showMoreDropdown, setShowMoreDropdown] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { fileData } = useSelector((state) => state.fileLibrary);
  const [localFileData, setLocalFileData] = useState(fileData);

  useClickOutside(() => setShowMoreDropdown(null), fileNode.current);

  useEffect(() => {
    setLocalFileData(fileData);
  }, [fileData]);

  const isEmptyData =
    isEmpty(fileData) || isEmpty(fileData?.attachments) ? true : false;

  const allFilesCallback = () => {
    setIsLoading(false);
  };

  const loadAllFiles = (subOrgId) => {
    setIsLoading(true);
    dispatch(
      getFileLibraryData({ subOrgId: subOrgId, callback: allFilesCallback })
    );
  };

  useEffect(() => {
    loadAllFiles(subOrganizationId);
  }, []);

  const callback = (data) => {
    dispatch(setFileData(data));
    setUploadFile(false);
    setIsBtnLoading(false);
    setUploadedFiles([]);
    setIsUploadFail(false);
    openSuccessSnackBar('File uploaded successfully', 'large');
  };

  const errorCallback = (e) => {
    setIsBtnLoading(false);
    openErrorSnackBar('Failed');
    setIsUploadFail(true);
  };

  const onUpload = (file) => {
    setIsBtnLoading(true);
    if (!isUploadFail) {
      setUploadedFiles([...uploadedFiles, ...file]);
    }
    const attachedFiles = file.map((item) => {
      return {
        file_name: item?.file_name,
        file_url: item?.file_url,
        file_size: item.file_size
      };
    });
    const formData = {
      sub_organization: subOrganizationId,
      attachments: attachedFiles,
      name: ''
    };
    dispatch(uploadFilesSettings({ body: formData }, callback, errorCallback));
  };

  const onDelete = () => {
    setIsBtnLoading(true);
    const formData = {
      fileId: openDeleteModal,
      callback: deleteCallback
    };
    dispatch(deleteFile(formData));
  };

  const deleteCallback = (id) => {
    const remainingFiles = fileData?.attachments.filter(
      (item) => item.id !== id
    );
    dispatch(setFileData({ ...localFileData, attachments: remainingFiles }));
    setIsBtnLoading(false);
    openSuccessSnackBar('File deleted successfully', 'large');
    setOpenDeleteModal(null);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteConfirmation
        showConfirmation={openDeleteModal !== null ? true : false}
        onCancel={() => setOpenDeleteModal(null)}
        onConfirm={onDelete}
        title="Are you sure you want to delete this file?"
        description="Once deleted you cannot get it back"
        isLoading={isBtnLoading}
      />
      <Uploader
        open={uploadFile}
        isBtnLoading={isBtnLoading}
        onClose={() => setUploadFile(false)}
        filesList={uploadedFiles}
        onUpload={(file) => {
          onUpload(file);
        }}
        isFileLibrary={true}
      />
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">File library</h2>
        <p className="text-grey-800 text-15 mt-3">
          Store files for quick access when replying to enquiries
        </p>
      </div>
      <div style={{ marginTop: 55 }}>
        <div className="flex justify-between">
          <h4 className="font-semibold text-lg ml-3">Files</h4>
          <PrimaryWithLogo
            label="Upload file"
            className={
              has_file_library ? '' : 'btn--blocked cursor-not-allowed'
            }
            onClick={() => {
              if (has_file_library) {
                setUploadFile(true);
              }
            }}
            renderSvg={() => {
              return <PlusIcon />;
            }}
            style={{ fontWeight: 600, padding: '5px 12px' }}
          />
        </div>
        <div className="mt-4" ref={fileNode}>
          {isEmptyData && (
            <div className="pt-3 text-center">
              <div className="flex justify-center mb-5">
                <figure
                  className="bg-grey-300 flex justify-center items-center"
                  style={{ width: 71, height: 71, borderRadius: '50%' }}
                >
                  <FileLibraryIcon />
                </figure>
              </div>
              <span className="font-medium">You currently have no files</span>
              <p className="text-15 text-grey-800 mt-3">
                Store files for quick access
              </p>
            </div>
          )}
          {!isEmpty(fileData) &&
            fileData?.attachments.map((item) => {
              return (
                <FileItem
                  key={item.id}
                  id={item.id}
                  title={item.file_name}
                  file_url={item.file_url}
                  setShowMoreDropdown={setShowMoreDropdown}
                  showMoreDropdown={showMoreDropdown}
                  setOpenEdit={setOpenEdit}
                  openEdit={openEdit}
                  setOpenDeleteModal={setOpenDeleteModal}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default FileLibrary;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import iconEdit from 'assets/icons/icon-edit.svg';
import iconNewAutomation from 'assets/icons/icon-new-automation-icon.svg';
import InputField from 'components/inputs/textField';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import Error from 'components/errorMessage';
import { requiredMsg } from 'utils/messages';
import { useForm } from 'react-hook-form';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';
import Select from 'components/inputs/select';
import choices from 'choices';
import CustomToolTip from 'components/CustomTooltip';
import CheckboxOptionSelect from 'components/inputs/checkboxOptionSelect';
import { ReactComponent as IconDesc } from 'assets/icons/icon-Info_duotone_line.svg';
import { animateScroll } from 'react-scroll';
// import { animateScroll } from 'react-scroll';

const { WEBFORM, FACEBOOK, SMS, FACEBOOK_LEAD_ADS, INSTAGRAM, EBOT, ZAPIER } =
  choices.ChannelChoices;

const AutomationStuffWhen = ({
  options,
  setSelectedOption,
  selectedOption,
  setAutomationName,
  automationName,
  channelType,
  parentRef,
  onEdit,
  tempChannelType
}) => {
  const [editName, setEditName] = useState(!automationName);
  const { error, isPreview } = useSelector((state) => state.automationStuff);
  const [extraOptions, setExtraOptions] = useState([]);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  // const { onEdit } = useSelector((state) => state.automationStuff);
  // const dispatch = useDispatch()

  const formSchema = {
    headder: string()
      .required(requiredMsg('automation name'))
      .max(100, 'maxmimum 100 characters allowed')
  };

  const childRef = useRef();

  const {
    register,
    handleSubmit,
    // setValue,
    watch,
    formState: { errors }
  } = useForm({
    defaultValues: { headder: automationName },
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const watchAutomationName = watch('headder');
  const onSubmit = (data) => {
    setAutomationName(data.headder || '');
    setEditName(false);
  };

  useEffect(() => {
    if (isEmpty(onEdit)) {
      setIsAllSelected(false);
    }
  }, [onEdit]);

  useEffect(() => {
    setAutomationName(watchAutomationName);
  }, [watchAutomationName]);

  useEffect(() => {
    if (
      channelType === WEBFORM ||
      (channelType === SMS && tempChannelType === WEBFORM)
    ) {
      setExtraOptions([
        { value: 'anyWebform', label: 'Any WebForm' },
        ...extraOptions
      ]);
    }
    if (!isEmpty(options) && tempChannelType !== EBOT) {
      const nonAnyoptions = [];
      const filtereAnyOption = [];
      options.map((item) => {
        if (!item.label.toLowerCase().includes('any')) {
          nonAnyoptions.push(item);
        } else {
          filtereAnyOption.push(item);
        }
      });
      if (channelType !== WEBFORM) {
        setExtraOptions(filtereAnyOption);
      }

      setFilteredOptions(nonAnyoptions);
    }
  }, [options, tempChannelType]);

  useEffect(() => {
    if (!isEmpty(onEdit) && channelType === EBOT) {
      const allEnquiry = options?.map((item) => item.et.option_id);
      const allSubEnquiry = options?.map((item) =>
        item.sub_enquiry_types.map((sub) => sub.option_id)
      );
      const allOptions = [...allEnquiry, ...allSubEnquiry.flat(1)];
      if (allOptions.length === selectedOption.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    }
  }, [onEdit, options, selectedOption]);

  // const automationNameRef = useRef();

  useEffect(() => {
    document.getElementById('input-headder')?.focus();
  }, [editName]);

  const onSelectAll = (e) => {
    const checked = e.target.checked;
    if (checked) {
      const allSelectedEnquiry = options?.map((item) => item.et.option_id);
      const allSelectedSubEnquiry = options?.map((item) =>
        item.sub_enquiry_types.map((sub) => sub.option_id)
      );
      const allSelected = [
        ...allSelectedEnquiry,
        ...allSelectedSubEnquiry.flat(1)
      ];
      setSelectedOption(allSelected);
      setIsAllSelected(true);
    } else {
      setSelectedOption([]);
      setIsAllSelected(false);
    }
  };

  const onHandleEnquiryCheck = (enquiry, e) => {
    const checked = e.target.checked;
    const allEnquiry = options?.map((item) => item.et.option_id);
    const allSubEnquiry = options?.map((item) =>
      item.sub_enquiry_types.map((sub) => sub.option_id)
    );
    const allOptions = [...allEnquiry, ...allSubEnquiry.flat(1)];
    if (checked) {
      const enquiryOptionId = enquiry?.et?.option_id;
      let checkedOptions = [...selectedOption, enquiryOptionId];
      if (allOptions.length === checkedOptions.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
      // const subEnquiryOptionId = enquiry?.sub_enquiry_types.map(
      //   (item) => item.option_id
      // );
      // const allOptionId = [enquiryOptionId, ...subEnquiryOptionId.flat(1)];
      setSelectedOption([...selectedOption, enquiryOptionId]);
    } else {
      const updatedOptions = selectedOption.filter(
        (item) => item !== enquiry?.et?.option_id
      );
      setSelectedOption(updatedOptions);
      // const subEnquiryOptionId = enquiry?.sub_enquiry_types.map(
      //   (item) => item.option_id
      // );
      // const finalOptions = updatedOptions.filter(
      //   (item) => !subEnquiryOptionId.includes(item)
      // );
      if (allOptions.length === updatedOptions.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    }
  };

  const onHandleSubEnquiryCheck = (id, e) => {
    const checked = e.target.checked;
    const allEnquiry = options?.map((item) => item.et.option_id);
    const allSubEnquiry = options?.map((item) =>
      item.sub_enquiry_types.map((sub) => sub.option_id)
    );
    const allOptions = [...allEnquiry, ...allSubEnquiry.flat(1)];
    if (checked) {
      setSelectedOption([...selectedOption, id]);
      let checkedOptions = [...selectedOption, id];
      if (allOptions.length === checkedOptions.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
    } else {
      const updatedOptions = selectedOption.filter((item) => item !== id);
      setSelectedOption(updatedOptions);
      if (allOptions.length === updatedOptions.length) {
        setIsAllSelected(true);
      } else {
        setIsAllSelected(false);
      }
      if (updatedOptions.length === 0) {
        setIsAllSelected(false);
      }
    }
  };

  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'automationContainer', // id
        smooth: true,
        duration: 0
      });
    }, 100);
  };

  return (
    <div id="when-select" className="mb-6">
      {/* <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div
          className={`${
            !editName
              ? 'width-fill-available flex items-center '
              : 'width-fill-available'
          } relative`}
        >
          {!editName ? (
            <h4 className="font-semibold">
              {!automationName ? 'Name your automation' : automationName}
            </h4>
          ) : (
            <>
              <div>
                <InputField
                  className="input-field--sm "
                  placeholder={'Name your automation'}
                  name="headder"
                  error={!isEmpty(errors.username)}
                  register={register}
                  id="input-headder"
                  // ref={autoFocusFn}
                />
              </div>
              <div className="mt-1 ml-1">
                {errors.headder && (
                  <Error className="flex" message={errors.headder.message} />
                )}
              </div>
              <div className="mt-1 ml-1" style={{ width: 'inherit' }}>
                {error.automationName &&
                  !errors.headder &&
                  !watchAutomationName && (
                    <Error className="flex" message={error.automationName} />
                  )}
              </div>
            </>
          )}
          {!editName && (
            <CustomToolTip tooltipText={'Edit'}>
              <img
                src={iconEdit}
                alt=""
                style={{ width: 14, height: 14, marginTop: '5px' }}
                className="ml-1 cursor-pointer"
                onClick={() => setEditName(true)}
              />
            </CustomToolTip>
          )}
        </div>
      </form> */}
      <div
        className={`${
          !isEmpty(selectedOption)
            ? 'box-settings__automation-card'
            : 'box-settings__automation-template__automation-stuff'
        } mt-4 px-5 pt-5 pb-6`}
      >
        <div className="flex">
          <img
            src={iconNewAutomation}
            alt=""
            style={{ width: 52, height: 50 }}
          />
          <div className="ml-4">
            <h4 className="font-semibold">When...</h4>

            <p className="text-sm text-grey-800">
              {channelType === WEBFORM
                ? 'An enquiry comes in from this webform'
                : channelType === FACEBOOK
                ? 'A message is sent to this Facebook Page'
                : channelType === INSTAGRAM
                ? 'A message is sent to this Instagram Page'
                : channelType === FACEBOOK_LEAD_ADS
                ? 'An enquiry comes in from this Facebook Lead Ad form'
                : channelType === EBOT
                ? 'An enquiry comes in from this Chatbot'
                : channelType === ZAPIER
                ? 'An enquiry comes in from this zapier'
                : channelType === SMS && tempChannelType === SMS
                ? 'A message is sent to this number'
                : channelType === SMS && tempChannelType === FACEBOOK_LEAD_ADS
                ? 'An enquiry comes in from this Facebook Lead Ad form'
                : channelType === SMS && tempChannelType === WEBFORM
                ? 'An enquiry comes in from this webform'
                : channelType === SMS && tempChannelType === ZAPIER
                ? 'An enquiry comes in from this zapier'
                : channelType === SMS &&
                  tempChannelType === EBOT &&
                  'An enquiry comes in from this Chatbot'}
            </p>
          </div>
        </div>
        {channelType === EBOT && (
          <div className="flex items-center mt-5">
            <p className="text-grey-800 text-xs font-medium">
              And the selected options are
            </p>
            <CustomToolTip
              position="top"
              tooltipText="When an enquiry comes in with any of the below bot options selected then this automation will start. Read more here"
              tooltipStyle={{ maxWidth: 222 }}
            >
              <IconDesc className="ml-1 cursor-pointer" />
            </CustomToolTip>
          </div>
        )}
        {(channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          tempChannelType === FACEBOOK_LEAD_ADS ||
          tempChannelType === WEBFORM) && (
          <div
            className="relative mt-4"
            // onClick={() => handleScroll()}
            onClick={scrollDown}
            ref={childRef}
          >
            <form autoComplete="off">
              <Select
                name="automation-when"
                options={filteredOptions}
                onChange={(value) => {
                  setSelectedOption(value);
                  handleScrollIntoview('thenId', 'end');
                }}
                dropDownClassName="p-3"
                inputClassName="input-field--md"
                isWhenSelect={true}
                value={selectedOption?.label}
                selectedValue={selectedOption}
                extraOption={extraOptions[0]}
                labelDividerText={'Or select a specific webform below'}
                labelDividerTextClass={'text-primary'}
                style={{ paddingRight: '35px' }}
                isSelectDisabled={isPreview}
                disabled={isPreview}
              />

              {/* {(channelType === FACEBOOK ||
              channelType === SMS ||
              channelType === INSTAGRAM) && (
              <InputField
                name="email"
                placeholder="Add the email address for the invitation"
                className="input-field--md"
                value={selectedOption?.label}
                readOnly={true}
              />
            )} */}
            </form>
          </div>
        )}
        {(channelType === EBOT || tempChannelType === EBOT) && (
          <div
            className="relative mt-1"
            // onClick={() => handleScroll()}
            onClick={scrollDown}
            ref={childRef}
          >
            <form autoComplete="off">
              <CheckboxOptionSelect
                options={options}
                name="automation-when"
                dropDownClassName="p-2"
                onSelectAll={onSelectAll}
                selectedOptions={selectedOption}
                onHandleSubEnquiryCheck={onHandleSubEnquiryCheck}
                onHandleEnquiryCheck={onHandleEnquiryCheck}
                isAllSelected={isAllSelected}
                isSelectDisabled={isPreview}
                disabled={isPreview}
                inputClassName={
                  !isEmpty(selectedOption)
                    ? 'input-field--select__checkbox'
                    : ''
                }
                onChange={(value) => {
                  handleScrollIntoview('thenId', 'end');
                }}
              />
            </form>
          </div>
        )}
      </div>
    </div>
  );
};

export default AutomationStuffWhen;

import React from 'react';
import PropTypes from 'prop-types';
import DropDownWrapper from 'components/dropdown/wrapper';
import { Link } from 'react-router-dom';
import { ReactComponent as LabelTag } from './icons/label.svg';
import { ReactComponent as PlusWhite } from 'assets/icons/icon-plus-white.svg';
import { route } from 'utils/route';

const EmptyLabels = ({ open, dropDownref }) => {
  return (
    <DropDownWrapper
      className="dropdown-menu--with-border pt-9 pb-12"
      open={open}
      style={{
        right: '228px',
        left: 'auto',
        width: '316px',
        top: '33px'
      }}
      dropDownref={dropDownref}
    >
      <div className="flex justify-center">
        <figure
          className="bg-grey-300 flex justify-center items-center"
          style={{ width: 71, height: 71, borderRadius: '50%' }}
        >
          <LabelTag />
        </figure>
      </div>
      <p className="font-medium mt-5 text-center">
        You currently have no labels !
      </p>
      <div className="flex justify-center mt-6">
        <Link
          className="btn btn--primary btn--sm-34 gap-2"
          to={`${route.dashboard}${route.settings}/labels`}
        >
          <PlusWhite />
          <span className="text-13 font-semibold">Create one</span>
        </Link>
      </div>
    </DropDownWrapper>
  );
};

EmptyLabels.propTypes = {
  open: PropTypes.bool.isRequired
};

export default EmptyLabels;

import React from 'react';
import PropTypes from 'prop-types';

const UserLevel = (props) => {
  const { title, id, desc, value, name, required, register, checked } = props;

  return (
    <li className="mt-5">
      <input
        type="radio"
        className="action-box-list__radio-btn"
        id={id}
        name={name}
        value={value}
        checked={checked}
        {...register(name, { required })}
      />
      <label className="action-box-list" htmlFor={id}>
        <code className="action-box-list__circle mt-1"></code>
        <div className="flex flex-col ml-3">
          <h4 className="text-16 font-semibold text-grey-900 mb-2">{title}</h4>
          <p className="text-sm text-grey-800">{desc}</p>
        </div>
      </label>
    </li>
  );
};

UserLevel.propTypes = {
  title: PropTypes.string,
  id: PropTypes.number,
  desc: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  name: PropTypes.string.isRequired
};

UserLevel.defaultProps = {
  register: () => {}
};

export default UserLevel;

import React from 'react';
import PropTypes from 'prop-types';

const CheckBox = (props) => {
  const {
    small,
    medium,
    large,
    rounded,
    className,
    register,
    name,
    required,
    ...rest
  } = props;

  const getClassName = () => {
    if (small) return `custom-checkbox--sm ${className}`;
    if (medium) return `custom-checkbox--md ${className}`;
    if (large) return `custom-checkbox--lg ${className}`;
    if (rounded) return `custom-checkbox--circle ${className}`;
  };

  return (
    <input
      data-testid="checkbox-field"
      type="checkbox"
      className={`custom-checkbox ${getClassName()}`}
      {...register(name, { required })}
      {...rest}
    />
  );
};

CheckBox.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  rounded: PropTypes.bool,
  className: PropTypes.string,
  required: PropTypes.bool
};

CheckBox.defaultProps = {
  small: false,
  medium: false,
  large: false,
  className: '',
  required: false,
  register: () => {}
};

export default CheckBox;

import React from 'react';

const TeamPerformanceState = () => {
  return (
    <>
      <div className="conditional-popup conditional-popup--bg-light">
        <h4 className="text-lg font-semibold letterspacing24em mt-4">
          Replying to enquiries will turn on this report
        </h4>
        <p className="text-13 letterspacing24em mt-1">
          <span className="text-primary font-semibold cursor-pointer">
            <a
              href="https://help.enquirybox.io/en/articles/7229105-team-performance-report"
              target="_blank"
              rel="noreferrer"
            >
              Learn more
            </a>
          </span>{' '}
          about how this report is calculated
        </p>
      </div>
      <tbody>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        {/* <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr> */}
      </tbody>
    </>
  );
};

export default TeamPerformanceState;

/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { SuccessToast, ErrorToast } from 'components/toast';
import queryString from 'query-string';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import PasswordInput from 'components/inputs/password';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import { redirectPostCreds } from 'utils/redirect';
import {
  useCreatePasswordMutation,
  useForgotPasswordMutation,
  useVerifyForgotPasswordMutation
} from 'services/authApi';
import Loader from 'components/loader';
import ResetPasswordExpired from 'modules/registration/password/resetPassword/resetPassword';
import { useDispatch } from 'react-redux';
import { setAuthToken } from 'slices/authSlice';
import RenderError from 'components/errorMessage/renderer';

const SetPassword = () => {
  const formSchema = getFormSchema(['password', 'passwordConfirmation']);
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const [
    createPassword,
    {
      data: passwordData,
      isLoading: isPasswordLoading,
      isSuccess: isPasswordSuccess,
      isError: isPasswordError,
      error: passwordError
    }
  ] = useCreatePasswordMutation();
  const [
    verifyForgotPassword,
    {
      data: forgotData,
      isLoading: isForgotLoading,
      isSuccess: isForgotSuccess,
      isError: isForgotError,
      error: forgotError
    }
  ] = useVerifyForgotPasswordMutation();
  const [
    forgotPassword,
    {
      isSuccess: isForgotPassWordSuccess,
      isError: isForgotPassWordError,
      error: forgotPasswordError,
      isLoading: isForgotPassWordLoading
    }
  ] = useForgotPasswordMutation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = queryString.parse(location.search);
  const [token, setToken] = useState(null);

  useEffect(() => {
    onVerifyForgotCreds();
  }, []);

  useEffect(() => {
    if (isForgotSuccess) {
      setToken(forgotData?.token);
    }
  }, [isForgotSuccess]);

  useEffect(() => {
    if (isPasswordError) {
      onPasswordError();
    }
    if (isPasswordSuccess) {
      onPasswordSuccess();
    }
  }, [isPasswordError, isPasswordSuccess]);

  const onPasswordError = () => {
    passwordError?.data?.non_field_errors.forEach((err) => {
      ErrorToast(err);
    });
  };

  const onVerifyForgotCreds = async () => {
    await verifyForgotPassword({
      token: queryParams.key,
      user_id: queryParams.user_id
    });
  };

  const resendLink = async () => {
    const formData = {
      user_id: queryParams.user_id
    };
    await forgotPassword(formData);
  };

  const RedirectOnError = () => {
    return (
      <p className="mr-5 flex justify-center z-10">
        <span className="text-blue-900 mr-1">
          <NavLink to="/forgot-password">click here</NavLink>
        </span>{' '}
        <span> to resend email</span>
      </p>
    );
  };

  const OnForgotError = () => {
    if (
      forgotError?.data?.non_field_errors &&
      (forgotError?.data?.non_field_errors[0] === 'invalid link' ||
        forgotError?.data?.non_field_errors[0] === 'link expired')
    ) {
      return (
        <ResetPasswordExpired
          title="This reset password link has expired"
          subTitle="If you don’t see your reset email check your spam for an email from"
          onClick={resendLink}
          isSuccess={isForgotPassWordSuccess}
          isLoading={isForgotPassWordLoading}
          isResetPassword={true}
        >
          {isForgotPassWordError && (
            <RenderError error={forgotPasswordError?.data?.non_field_errors} />
          )}
        </ResetPasswordExpired>
      );
    }
    if (forgotError?.status === 'PARSING_ERROR') {
      return (
        <HeaderLayout hideLogo>
          <div className="flex justify-center">
            <Error message="There was a error in parsing" />
          </div>
          <RedirectOnError />
        </HeaderLayout>
      );
    }
    return (
      <HeaderLayout hideLogo>
        <div className="flex justify-center">
          {forgotError?.data?.non_field_errors?.map((err) => {
            return <Error key={err} message={err} />;
          })}
        </div>
        <RedirectOnError />
      </HeaderLayout>
    );
  };

  const onPasswordSuccess = () => {
    SuccessToast(passwordData?.message);
    dispatch(setAuthToken(token));
    navigate(redirectPostCreds(passwordData?.fronted_redirect_uri));
  };

  const onSubmit = async (data) => {
    const { password, passwordConfirmation } = data;
    const formData = {
      password: password,
      password2: passwordConfirmation
    };
    await createPassword({ body: formData, token });
  };

  if (isForgotLoading) return <Loader />;

  if (isForgotError) return <OnForgotError />;

  if (token)
    return (
      <HeaderLayout>
        <CardLayout style={{ maxWidth: '502px' }}>
          <CardTitle title="Reset your password" />
          <div className="mt-3 text-center">
            {passwordError &&
              passwordError?.data?.non_field_errors[0] ===
                'Please use a new password.' && (
                <Error
                  message="Your new password cannot be the same as
            your old password. Please create a new one."
                />
              )}
          </div>
          <div className={`${isPasswordError ? 'mt-7' : 'mt-12'}`}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <PasswordInput
                name="password"
                placeholder="Enter a new password"
                error={errors.password && touchedFields.password}
                register={register}
              />
              {errors.password && touchedFields.password && (
                <Error message={errors.password.message} />
              )}
              <div className="mt-14">
                <PasswordInput
                  name="passwordConfirmation"
                  placeholder="Confirm your password"
                  error={
                    errors.passwordConfirmation &&
                    touchedFields.passwordConfirmation
                  }
                  register={register}
                />
              </div>
              {errors.passwordConfirmation &&
                touchedFields.passwordConfirmation && (
                  <Error message={errors.passwordConfirmation.message} />
                )}

              <div style={{ marginTop: '5px' }}></div>
              <PrimaryButton
                className="w-full mt-6"
                label="Save password & login"
                type="submit"
                isLoading={isPasswordLoading}
                enableLoader={true}
              />
            </form>
          </div>
        </CardLayout>
      </HeaderLayout>
    );
  return <></>;
};

export default SetPassword;

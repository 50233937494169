/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ConversationHeader from 'modules/enquirybox/ebox/module/box/conversation/header';
import Avatar from 'components/avatar';
import FbLeadAdsContainer from '../../components/fbLeadAdsContainer';
import { useParams, Navigate, useLocation } from 'react-router-dom';
import {
  getLeadConversation,
  getLeadData,
  setActiveTab,
  setConversation
} from 'slices/conversationSlice';
import choices from 'choices';
// import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';
// import WebformMapFields from 'modules/modals/webformMapFields';
import FbLeadAdsMapFields from 'modules/modals/fbLeadAdsField';
import NoteContainer from '../../components/noteContainer';
import { timeFormatter } from 'utils/utils';
import EmailBody from '../../email/new/index';
import ImageModalContainer from 'modules/modals/ImageModal';
// import TaskContainer from '../../components/taskContainer';
import ChatFooterInputBox from '../../footer/chatFooter/main';
import { setShowContactModal, setShowInboxActions } from 'slices/eboxLead';
import Actions from 'modules/enquirybox/ebox/module/box/conversation/actions/conversationActions';
import CustomToolTip from 'components/CustomTooltip';
import { route } from 'utils/route';
import { animateScroll } from 'react-scroll';
import SmsMessageContainer from '../component/smsMessageContainer';
import { getAllWebformMappingQuestions } from 'slices/webformMappingSlice';
// import DeleteConfirmation from 'components/alerts/deleteConfirmation';
// import { useSnackbar } from 'components/Snackbar';
import queryString from 'query-string';
import { TASK } from 'utils/constants';
import { handleScroll } from 'modules/enquirybox/helper/scrollConversation';
import { setFetchConversation } from 'slices/conversationSlice';

const JUST_TO_STORE_DATA = choices.LeadReplyChoice.JUST_TO_STORE_DATA;
const EMAIL_REPLY = choices.LeadReplyChoice.EMAIL_REPLY;
const NOTE_REPLY = choices.LeadReplyChoice.NOTE_REPLY;
const TASK_REPLY = choices.LeadReplyChoice.TASK_REPLY;
const MOVED_TO_BIN = choices.LeadStatusChoices.MOVED_TO_BIN;
const SMS_REPLY = choices.LeadReplyChoice.SMS_REPLY;

const FBLeadAdsConversations = (props) => {
  const { isBin } = props;
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const [isLoading, setIsLoading] = useState(false);
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const messageRef = useRef();
  // const navigate = useNavigate();
  // const { openSuccessSnackBar } = useSnackbar();
  // const [showDelete, setShowDelete] = useState(false);
  const [showMapFields, setShowMapFields] = useState(false);
  const { editorChannel } = useSelector((state) => state.editor);
  const { conversation, activeLead, showCardNotFound, fetchConversation } =
    useSelector((state) => state.leadConversation);

  const { showInboxActions } = useSelector((state) => state.leads);

  const { showImagePreview } = useSelector((state) => state.app);

  useEffect(() => {
    if (queryParams && queryParams?.activeTab === 'task') {
      dispatch(setShowContactModal(true));
      dispatch(setActiveTab(TASK));
    }
  }, [queryParams]);

  const scrollDown = () => {
    animateScroll.scrollToBottom({
      containerId: 'Conversation',
      smooth: true,
      duration: 0
    });
  };

  const loadAllQuestions = () => {
    dispatch(
      getAllWebformMappingQuestions(() => {
        setIsLoading(false);
      })
    );
  };

  const loadConversationCallback = () => {
    scrollDown();
  };

  useEffect(() => {
    if (!showInboxActions) {
      scrollDown();
    }
  }, [showInboxActions]);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        handleScroll(conversation);
      }, 300);
    }
  }, [isLoading, conversation.results]);

  const onReplySentSuccess = () => {
    dispatch(setShowInboxActions(true));
    dispatch(getLeadConversation({ leadId: cardId }, loadConversationCallback));
    dispatch(getLeadData({ leadId: cardId }));
  };
  const scrollToBottom = () => {
    // const temp = messageRef.current;
    // if (temp) {
    //   temp.scrollTop = temp.scrollHeight;
    // }
  };

  // useEffect(() => {
  //   if (!isLoading) {
  //     setTimeout(() => scrollToBottom(), 0);
  //   }
  // }, [cardId, isLoading]);

  const callback = () => {
    loadAllQuestions();
  };

  const loadLeadConversation = () => {
    setIsLoading(true);
    dispatch(getLeadConversation({ leadId: cardId }, callback));
  };

  const showMoreActions =
    !isEmpty(conversation) &&
    conversation?.results?.some((item) => item.is_reply_sent === true);

  useEffect(() => {
    loadLeadConversation();
  }, []);

  useEffect(() => {
    if (fetchConversation) {
      dispatch(
        getLeadConversation({ leadId: cardId }, () => {
          setTimeout(() => {
            handleScroll(conversation);
          }, 300);
          dispatch(setFetchConversation(false));
        })
      );
    }
    return () => {
      dispatch(setFetchConversation(false));
    };
  }, [fetchConversation]);

  // const onBinItClick = () => {
  //   const data = {
  //     lead: cardId,
  //     is_current: true,
  //     status: choices.LeadStatusChoices.MOVED_TO_BIN
  //   };
  //   dispatch(
  //     changeLeadStatus({ body: data }, () => {
  //       openSuccessSnackBar('Moved to bin');
  //       navigate('/dashboard/enquirybox/eb-box');
  //     })
  //   );
  // };

  if (isLoading) return <Loader />;

  if (showCardNotFound) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-not-found`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-not-found`}
      />
    );
  }

  if (!isEmpty(activeLead) && activeLead?.status === MOVED_TO_BIN && !isBin) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-moved-to-bin`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-moved-to-bin`}
      />
    );
  }

  return (
    <>
      {/* <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={onBinItClick}
        title="Are you sure you want to bin this enquiry?"
        description="You can see this enquiry in bin."
      /> */}
      <FbLeadAdsMapFields
        showMapFields={showMapFields}
        activeLead={activeLead}
        onClose={() => setShowMapFields(false)}
      />
      <ConversationHeader activeLead={activeLead} isBin={isBin} />
      <div
        className="chat-message-body"
        style={{ padding: 25 }}
        id="Conversation"
        ref={messageRef}
      >
        {!isEmpty(conversation) &&
          conversation.results.map((item, idx) => {
            if (item.reply_type === JUST_TO_STORE_DATA)
              return (
                <div
                  key={item.id}
                  className="chat-message-list flex"
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                  id={item.id}
                >
                  <CustomToolTip
                    position="right"
                    style={{ height: '35px' }}
                    tooltipText={item?.lead_name || item?.lead?.first_name}
                    // renderAvatar={() => (
                    //   <Avatar
                    //     initial={
                    //       item?.lead_name ||
                    //       item?.lead.name ||
                    //       item?.lead?.first_name
                    //     }
                    //     smaller
                    //     singleChar
                    //     contain
                    //     border={false}
                    //   />
                    // )}
                  >
                    <Avatar
                      initial={item?.lead_name || item?.lead?.first_name}
                      small
                      singleChar
                      contain
                      border={false}
                    />
                  </CustomToolTip>
                  <FbLeadAdsContainer
                    onClick={() => setShowMapFields(true)}
                    time={item?.created_at}
                    isFieldsMapped={item?.form?.is_mapped}
                    data={item}
                    isShowMapping={activeLead?.is_mapping_necessary}
                    // isCollapse={idx !== conversation?.results.length - 1}
                    isCollapse={false}
                    isBin={isBin}
                    lead={activeLead}
                  />
                </div>
              );
            if (EMAIL_REPLY == item.reply_type)
              return (
                <div
                  key={item.id}
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                >
                  <EmailBody
                    data={item}
                    // isCollapse={idx !== conversation?.results.length - 1}
                    isCollapse={false}
                  />
                </div>
              );
            if (NOTE_REPLY === item.reply_type)
              return (
                // <div key={item.id} id={item.id}></div>
                <div
                  key={item.id}
                  style={{
                    marginBottom:
                      showInboxActions &&
                      idx === conversation?.results.length - 1
                        ? '80px'
                        : '25px'
                  }}
                >
                  <NoteContainer
                    created_at={timeFormatter(item.created_at)}
                    data={item.note}
                    item={item}
                    createdBy={item.created_by}
                    isBin={isBin}
                    idx={idx}
                  />
                </div>
              );
            if (TASK_REPLY === item.reply_type)
              return (
                <div key={item.id} id={item.id}></div>
                // <div
                //   key={item.id}
                //   style={{
                //     marginBottom:
                //       showInboxActions &&
                //       idx === conversation?.results.length - 1
                //         ? '80px'
                //         : '25px'
                //   }}
                // >
                //   <TaskContainer
                //     created_at={timeFormatter(item.created_at)}
                //     data={item.task}
                //     item={item}
                //     createdBy={item.created_by}
                //     isBin={isBin}
                //   />
                // </div>
              );
            if (SMS_REPLY === item.reply_type)
              return (
                <SmsMessageContainer
                  key={item.id}
                  item={item}
                  isReplyRecieved={item.is_reply_received}
                  isReplySent={item.is_reply_sent}
                  index={idx}
                  conversation={conversation.results}
                  showInboxActions={showInboxActions}
                  id={item.id}
                />
              );
            return (
              <div
                key={item.id}
                style={{
                  marginBottom:
                    idx === conversation?.results.length - 1 ? '80px' : '25px'
                }}
                id={item.id}
              />
            );
          })}
      </div>
      {showInboxActions && !isBin && (
        <Actions
          onOpenEditor={() => dispatch(setShowInboxActions(false))}
          showMoreActions={showMoreActions}
          // onBinIt={() => setShowDelete(true)}
        />
      )}
      {!showInboxActions && !isBin && (
        <ChatFooterInputBox
          onClose={() => dispatch(setShowInboxActions(true))}
          onReplySentSuccess={onReplySentSuccess}
          hideAttachment={editorChannel === 'Sms' ? true : false}
          hideImage={editorChannel === 'Sms' ? true : false}
        />
      )}
      <ImageModalContainer open={showImagePreview} />
    </>
  );
};

export default FBLeadAdsConversations;

import EnquiriesConvertedToSales from 'modules/enquirybox/reports/components/enquiriesConvertedToSales';
import FilterSectionPublic from 'modules/enquirybox/reports/components/FilterSectionPublic';
import TotalEnquiriesPerStage from 'modules/enquirybox/reports/components/totalEnquiriesPerStage';
import TotalEnquirySales from 'modules/enquirybox/reports/components/totalEnquirySalesReport';
import React from 'react';
import { useSelector } from 'react-redux';

const BoxReportPublic = (props) => {
  const { startDate, endDate, reportType, date_format } = props;
  const { boxReport } = useSelector((state) => state.reports);

  return (
    <div
      className="bg-grey-300 rounded-lg"
      style={{
        margin: 'auto',
        marginTop: 50,
        maxWidth: '1000px',
        padding: 25,
        maxHeight: 'calc(100vh - 115px)'
      }}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Snapshot</h3>
        <FilterSectionPublic
          endDate={endDate}
          startDate={startDate}
          reportType={reportType}
          date_format={date_format}
        />
      </div>
      <div className="flex items-center -mx-3 mt-6">
        <TotalEnquirySales
          total={boxReport?.report?.total_enquiries}
          percentage={boxReport?.report?.total_enquiries_percentage}
          selectedDay={date_format}
        />
        <EnquiriesConvertedToSales
          total={boxReport?.report?.total_enquiries_converted_to_sale}
          totalPrice={boxReport?.report?.total_sales_value}
          percentage={
            boxReport?.report?.total_enquiries_converted_to_sale_percentage
          }
          selectedDay={date_format}
        />
      </div>
      <TotalEnquiriesPerStage
        data={boxReport?.report?.stages_data}
        total={boxReport?.report?.total_enquiries}
        isPublic={true}
      />
    </div>
  );
};

export default BoxReportPublic;

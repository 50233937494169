import React from 'react';
import { ReactComponent as IconToolBox } from 'assets/icons/toolbox-icon-gray.svg';

const ToolBoxHeader = () => {
  return (
    <div className="dashboard__main__header" style={{ padding: '20px 24px' }}>
      <div className="flex items-center">
        <figure
          className="avatar-container avatar-container--contain"
          style={{
            width: 48,
            height: 48,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <IconToolBox height={22} width={22} className="toolboxheader" />
        </figure>
        <h1 className="text-2xl font-semibold ml-4">Toolbox</h1>
      </div>
    </div>
  );
};

export default ToolBoxHeader;

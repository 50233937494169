import React from 'react';
import Modal from 'components/modal';
import { ReactComponent as AiAssistantLogo } from 'assets/icons/ai-assistant-logo.svg';

const AIAssistantLoader = (props) => {
  //const { onClose, setHidden } = props;
  const { open } = props;
  return (
    <Modal.Main
      open={open}
      className="modal--ai-assistant modal bg-transparent"
    >
      <Modal.Body style={{ padding: '0' }}>
        <div className="flex flex-col items-center justify-center">
          <AiAssistantLogo style={{ height: 40 }} />
          <p className="text-white text-lg font-semibold text-center mt-2">
            Creating your AI assistant...
          </p>
        </div>
      </Modal.Body>
    </Modal.Main>
  );
};

export default AIAssistantLoader;

import React from 'react';
import HistoryLayout from './layout';
import iconRecieved from 'assets/icons/reply-recieved.svg';
import choices from 'choices';
import { timeFormatter } from 'utils/utils';

export const HistoryReply = ({ desc }) => {
  return (
    <div className="flex">
      <div className="flex py-3 text-13 px-3 justify-center align-center">
        {desc}
      </div>
    </div>
  );
};

const ReplyReceived = (props) => {
  let {
    data: { title, created_at, description }
  } = props;

  return (
    <HistoryLayout
      title={choices.TitleChoices.CHOICES[title]}
      icon={iconRecieved}
      time={timeFormatter(created_at)}
      renderData={() => <HistoryReply desc={description} />}
      className="history__assignTo"
    />
  );
};
export default ReplyReceived;

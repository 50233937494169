/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputs/textField';
import PasswordInput from 'components/inputs/password';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import CheckBox from 'components/inputs/checkbox';
import { useForm } from 'react-hook-form';
import { object, boolean } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';
import { ReactComponent as IconUser } from 'assets/icons/icon-user.svg';

const SignUpForm = (props) => {
  const { onSubmit, email, isLoading } = props;
  const formSchema = {
    ...getFormSchema(['name', 'email', 'password']),
    tandc: boolean().oneOf([true], 'You must accept the terms and conditions')
  };
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    defaultValues: { tandc: false },
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  useEffect(() => {
    if (email) {
      setValue('email', email, { shouldTouch: true });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <InputField
        id="sign-up-full-name"
        name="name"
        placeholder="Your Full Name"
        renderIcon={() => <IconUser />}
        icon={true}
        error={errors.name && touchedFields.name}
        register={register}
      >
        {errors.name && touchedFields.name && (
          <Error message={errors.name.message} />
        )}
      </InputField>
      <div className="mt-3">
        <InputField
          id="sign-up-email"
          name="email"
          renderIcon={() => <EmailIcon />}
          placeholder="Email"
          icon={true}
          error={errors.email && touchedFields.email}
          register={register}
        >
          {errors.email && touchedFields.email && (
            <Error message={errors.email.message} />
          )}
        </InputField>
      </div>
      <div className="mt-4">
        <PasswordInput
          id="sign-up-password"
          name="password"
          placeholder="Enter password (min 8 characters)"
          register={register}
        />
        {errors.password && touchedFields.password && (
          <Error message={errors.password.message} />
        )}
      </div>
      <div className="flex items-center mt-5">
        <CheckBox
          id="tandc"
          className="mr-2"
          small
          name="tandc"
          required
          register={register}
        />
        <label htmlFor="tandc" className="text-sm text-grey-800">
          <span>
            I agree to the{' '}
            <span className="text-primary font-medium cursor-pointer">
              <a
                href="https://enquirybox.io/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of service
              </a>
            </span>{' '}
            and{' '}
            <span className="text-primary font-medium cursor-pointer">
              <a
                href="https://enquirybox.io/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </span>
          </span>
        </label>
      </div>
      {!errors.email &&
        !errors.name &&
        !errors.password &&
        errors.tandc &&
        touchedFields.name && <Error message={errors.tandc.message} />}
      <PrimaryButton
        id="sign-up-submit-btn"
        type="submit"
        label="Create my account"
        className="w-full mt-6"
        isLoading={isLoading}
        enableLoader={true}
      />
    </form>
  );
};

SignUpForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  email: PropTypes.string,
  isLoading: PropTypes.bool
};

SignUpForm.defaultProps = {
  isLoading: false
};

export default SignUpForm;

/* eslint-disable no-unused-vars */
import { useEffect, useRef } from 'react';
import { useLocation, useParams, useMatch, matchPath } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { io } from 'socket.io-client';
import APIRequest from 'apiRequest';
import { GET_LEAD_DATA, LEAD_UPDATE_STATUS } from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import { isEmpty } from 'utils/utils';
import { setStageLeads } from 'slices/boxes/slice';
import CHOICES from 'choices';
import { getAllCounts } from 'slices/allCount/slice';
import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';
import {
  setUnReadLeads,
  setReadLeads,
  setReplyLaterLeads
} from 'slices/eboxLead';
import { setSocket } from 'slices/sockets/slice';
import {
  getLeadData,
  getLeadConversation,
  setFetchConversation
} from 'slices/conversationSlice';
import { getMyInfo } from 'slices/myInfoSlice';
import { getOrganizationUser } from 'slices/organizationuserSlice';

const { MANUALLY_ADDED, NEW, VIEWED, RESTORED } = CHOICES.LeadStatusChoices;

const Sockets = () => {
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { stageLeads } = useSelector((state) => state.boxes);
  const { unReadLeads, readLeads, replyLaterLeads, currentCardList } =
    useSelector((state) => state.leads);
  const { assignedTo } = useSelector((state) => state.leadsFilter);
  // console.log({ replyLaterLeads, readLeads, unReadLeads });
  const location = useLocation();
  // const matchRoute = useMatch();
  let match;
  const stageLeadsRef = useRef();
  const locationRef = useRef();
  const unReadLeadsRef = useRef();
  const readLeadsRef = useRef();
  const replyLaterLeadsRef = useRef();
  const assignedToRef = useRef();
  const currentCardListRef = useRef();

  useEffect(() => {
    currentCardListRef.current = currentCardList;
  }, [currentCardList]);

  useEffect(() => {
    locationRef.current = location;
  }, [location]);

  useEffect(() => {
    // console.log({ unReadLeads });
    unReadLeadsRef.current = unReadLeads;
  }, [unReadLeads]);

  useEffect(() => {
    readLeadsRef.current = readLeads;
  }, [readLeads]);

  useEffect(() => {
    replyLaterLeadsRef.current = replyLaterLeads;
  }, [replyLaterLeads]);

  useEffect(() => {
    stageLeadsRef.current = stageLeads;
  }, [stageLeads]);

  useEffect(() => {
    assignedToRef.current = assignedTo;
  }, [assignedTo]);

  const getRouteMatch = () => {
    match = matchPath(
      '/dashboard/boxes/:box_type/:box_id',
      locationRef.current.pathname
    );
    if (!match) {
      match = matchPath(
        '/dashboard/boxes/:box_type/:box_id/:stageId',
        locationRef.current.pathname
      );
    }
    if (!match) {
      match = matchPath(
        '/dashboard/boxes/:box_type/:box_id/:stageId/:channel/:cardId',
        locationRef.current.pathname
      );
    }
    if (!match) {
      match = matchPath(
        '/dashboard/enquirybox/eb-box',
        locationRef.current.pathname
      );
    }
    if (!match) {
      match = matchPath(
        '/dashboard/enquirybox/eb-box/lead/:channel/:cardId',
        locationRef.current.pathname
      );
    }
    return match;
  };

  const getCountOfStageLeads = (data) => {
    return (
      data.seen_replied.length +
      data.new_unread.length +
      data.reply_later.length
    );
  };

  const getUserFilterThingsToDo = () => {
    if (!isEmpty(assignedToRef.current)) {
      return assignedToRef.current;
    }
  };

  const getTheLeadData = async (leadId) => {
    return await new APIRequest()
      .get(replaceUrl(GET_LEAD_DATA, 'leadId', leadId))
      .then((resp) => {
        if (resp.status == 200) {
          if (!isEmpty(resp?.data?.results)) {
            const [lead_data] = resp.data.results;
            return Promise.resolve(lead_data);
          }
        }
      });
  };
  // to handle lead based on the current route
  const handleLead = (lead) => {
    const route = getRouteMatch();
    const user = getUserFilterThingsToDo();
    if (route) {
      dispatch(getAllCounts());
      dispatch(getTaskEnquiryCount());
      const { params } = route;
      // if a lead is in things to do
      if (isEmpty(params)) {
        const unReadLeads = unReadLeadsRef.current;
        const newLeads = unReadLeadsRef?.current.results;
        const readLeads = readLeadsRef.current;
        const replyLaterLeads = replyLaterLeadsRef.current;
        const isLeadExist = newLeads.find((l) => l.id == lead.id);
        console.log({ isLeadExist });
        if (isLeadExist) {
          dispatch(
            setUnReadLeads({
              ...unReadLeads,
              results: newLeads.map((l) => {
                if (l.id == lead.id) {
                  return lead;
                }
                return l;
              })
            })
          );
          return;
        }
        if (!isLeadExist) {
          console.log({ user, lead });
          if (!isEmpty(user)) {
            if (!isEmpty(lead.assigned_to)) {
              if (lead.assigned_to.id !== user.user_id) {
                return;
              }
            }
          }
          dispatch(
            setUnReadLeads({
              ...unReadLeads,
              results: [lead, ...unReadLeads.results]
            })
          );
          if (!isEmpty(readLeads.results)) {
            dispatch(
              setReadLeads({
                ...readLeads,
                results: readLeads.results.filter((l) => l.id != lead.id)
              })
            );
          }
          if (!isEmpty(replyLaterLeads.results)) {
            dispatch(
              setReplyLaterLeads({
                ...replyLaterLeads,
                results: replyLaterLeads.results.filter((l) => l.id != lead.id)
              })
            );
          }
          return;
        }
        if (!isLeadExist) {
          console.log('LEAD');
        }
      }
      // add the lead if the card is in thingstodo with lead open
      if (params.cardId && !params.box_id) {
        if (params.cardId === lead.id) {
          // TODO if opened lead is same as new incoming lead
        } else {
          if (!isEmpty(user)) {
            if (!isEmpty(lead.assigned_to)) {
              if (lead.assigned_to.id !== user.user_id) {
                return;
              }
            }
          }
          if (lead.status === NEW || lead.status === RESTORED) {
            const unReadLeads = unReadLeadsRef.current;
            const readLeads = readLeadsRef.current;
            const replyLaterLeads = replyLaterLeadsRef.current;
            const isLeadExist = unReadLeads.results.find(
              (l) => l.id == lead.id
            );
            if (isLeadExist) {
              return;
            }
            dispatch(
              setUnReadLeads({
                ...unReadLeads,
                results: [lead, ...unReadLeads.results]
              })
            );
            if (!isEmpty(readLeads.results)) {
              dispatch(
                setReadLeads({
                  ...readLeads,
                  results: readLeads.results.filter((l) => l.id != lead.id)
                })
              );
            }
            if (!isEmpty(replyLaterLeads.results)) {
              dispatch(
                setReplyLaterLeads({
                  ...replyLaterLeads,
                  results: replyLaterLeads.results.filter(
                    (l) => l.id != lead.id
                  )
                })
              );
            }
          }
        }
      }
      // if a lead is for box
      if (params.box_id) {
        if (lead.box == params.box_id) {
          const stages = stageLeadsRef.current;
          const stageData = stages[lead.box_stage];
          if (params.stageId && params.cardId === lead.id) {
            const data = {
              lead: lead.id,
              is_current: true,
              status: VIEWED
            };
            return;
          }
          if (stageData) {
            console.log({ stageData, lead });
            const obj = new Object();
            let updatedStageData = {
              ...stageData
            };
            if (lead.status === MANUALLY_ADDED) {
              updatedStageData = {
                ...updatedStageData,
                seen_replied: [
                  lead,
                  ...stageData.seen_replied.filter((l) => l.id !== lead.id)
                ],
                new_unread: stageData.new_unread.filter(
                  (l) => l.id !== lead.id
                ),
                reply_later: stageData.reply_later.filter(
                  (l) => l.id !== lead.id
                )
              };
              updatedStageData = {
                ...updatedStageData,
                count: getCountOfStageLeads(updatedStageData)
              };
            }
            if (lead.status === NEW || lead.status === RESTORED) {
              updatedStageData = {
                ...updatedStageData,
                new_unread: [
                  lead,
                  ...stageData.new_unread.filter((l) => l.id !== lead.id)
                ],
                reply_later: stageData.reply_later.filter(
                  (l) => l.id !== lead.id
                ),
                seen_replied: stageData.seen_replied.filter(
                  (l) => l.id !== lead.id
                )
              };
              updatedStageData = {
                ...updatedStageData,
                count: getCountOfStageLeads(updatedStageData)
              };
              console.log('updatedStageData', { updatedStageData });
            }
            obj[lead.box_stage] = updatedStageData;
            console.log({ obj, updatedStageData });
            dispatch(
              setStageLeads({
                ...stages,
                ...obj
              })
            );
          }
        }
      }

      // add the lead if the card is in thingstodo without any lead open
      console.log('route.pathname', route.pathname);
      if (route.pathname == '/dashboard/enquirybox/eb-box') {
        if (!isEmpty(user)) {
          if (!isEmpty(lead.assigned_to)) {
            if (lead.assigned_to.id !== user.user_id) {
              return;
            }
          }
        }
        if (lead.status === NEW || lead.status === RESTORED) {
          const unReadLeads = unReadLeadsRef.current;
          const readLeads = readLeadsRef.current;
          const replyLaterLeads = replyLaterLeadsRef.current;
          dispatch(
            setUnReadLeads({
              ...unReadLeads,
              results: [lead, ...unReadLeads.results]
            })
          );
          if (!isEmpty(readLeads.results)) {
            dispatch(
              setReadLeads({
                ...readLeads,
                results: readLeads.results.filter((l) => l.id != lead.id)
              })
            );
          }
          if (!isEmpty(replyLaterLeads.results)) {
            dispatch(
              setReplyLaterLeads({
                ...replyLaterLeads,
                results: replyLaterLeads.results.filter((l) => l.id != lead.id)
              })
            );
          }
        }
      }
    }
  };

  const onLeadIncoming = (e) => {
    console.log('new_lead', e);
    if (e.lead_id) {
      const route = getRouteMatch();
      if (route) {
        const { params } = route;
        // console.log({ params });
        // if (isEmpty(params)) {
        //   const newLeads = unReadLeadsRef?.current.results;
        //   const isLeadExist = newLeads.find((lead) => lead.id == e.lead_id);
        //   console.log('175', { isLeadExist });
        //   if (isLeadExist) {
        //     return;
        //   }
        // }
        // check lead if the card is in thingstodo with lead open
        if (params.cardId && !params.box_id) {
          const newLeads = unReadLeadsRef?.current.results;
          const isLeadExist = newLeads.find((lead) => lead.id == e.lead_id);
          // console.log('175', { isLeadExist });
          if (isLeadExist) {
            return;
          }
        }
        // check lead if the card is in box with lead open
        if (params.cardId && params.stageId) {
          const stages = stageLeadsRef.current;
          const stageData = stages[params.stageId];
          if (stageData) {
            if (!isEmpty(stageData.new_unread)) {
              const isLeadExist = stageData.new_unread.find(
                (lead) => lead.id == e.lead_id
              );
              // console.log('188', { isLeadExist });
              if (isLeadExist) {
                return;
              }
            }
          }
        }
      }

      const leadId = e.lead_id;
      new APIRequest()
        .get(replaceUrl(GET_LEAD_DATA, 'leadId', leadId))
        .then((resp) => {
          if (resp.status == 200) {
            if (!isEmpty(resp?.data?.results)) {
              const [lead_data] = resp.data.results;
              handleLead(lead_data);
            }
          }
        });
    }
  };

  const onMoveLeadIncoming = async (e) => {
    console.log('move_lead', e);
    if (e.lead_id) {
      const unReadLeads = unReadLeadsRef.current;
      const newLeads = unReadLeadsRef?.current.results;
      const readLeads = readLeadsRef.current;
      const replyLaterLeads = replyLaterLeadsRef.current;
      const leadId = e.lead_id;
      const route = getRouteMatch();
      console.log({ route, newLeads, unReadLeads, unReadLeadsRef });
      const user = getUserFilterThingsToDo();
      console.log({ user });
      if (route) {
        dispatch(getAllCounts());
        dispatch(getTaskEnquiryCount());
        const { params } = route;
        // if lead is in things to do and not in box
        if (params.cardId && !params.box_id) {
          const lead_data = await getTheLeadData(leadId);
          if (params.cardId !== leadId) {
            const isLeadExist = newLeads.find((lead) => lead.id == leadId);
            console.log('351', { isLeadExist });
            if (isLeadExist) {
              dispatch(
                setUnReadLeads({
                  ...unReadLeads,
                  results: unReadLeads.results.map((l) => {
                    if (l.id == lead_data.id) {
                      return lead_data;
                    }
                    return l;
                  })
                })
              );
              return;
            }
            if (!isLeadExist) {
              if (!isEmpty(user)) {
                if (!isEmpty(lead_data.assigned_to)) {
                  if (lead_data.assigned_to.id !== user.user_id) {
                    return;
                  }
                }
              }
              dispatch(
                setUnReadLeads({
                  ...unReadLeads,
                  results: [lead_data, ...unReadLeads.results]
                })
              );
              if (!isEmpty(readLeads.results)) {
                dispatch(
                  setReadLeads({
                    ...readLeads,
                    results: readLeads.results.filter((l) => l.id != leadId)
                  })
                );
              }
              if (!isEmpty(replyLaterLeads.results)) {
                dispatch(
                  setReplyLaterLeads({
                    ...replyLaterLeads,
                    results: replyLaterLeads.results.filter(
                      (l) => l.id != leadId
                    )
                  })
                );
              }
            }
          }
          console.log('HERE');
          return;
        }
        // if lead is not opened and is not in box section, should be in things to do
        if (!params.cardId && !params.box_id) {
          console.log({ newLeads });
          const isLeadExist = newLeads.find((lead) => lead.id == leadId);
          const lead_data = await getTheLeadData(leadId);
          if (isLeadExist) {
            dispatch(
              setUnReadLeads({
                ...unReadLeads,
                results: newLeads.map((l) => {
                  if (l.id == leadId) {
                    return lead_data;
                  }
                  return l;
                })
              })
            );
            return;
          }
          if (!isLeadExist) {
            if (!isEmpty(user)) {
              if (!isEmpty(lead_data.assigned_to)) {
                if (lead_data.assigned_to.id !== user.user_id) {
                  return;
                }
              }
            }
            dispatch(
              setUnReadLeads({
                ...unReadLeads,
                results: [lead_data, ...unReadLeads.results]
              })
            );
            if (!isEmpty(readLeads.results)) {
              dispatch(
                setReadLeads({
                  ...readLeads,
                  results: readLeads.results.filter((l) => l.id !== leadId)
                })
              );
            }
            if (!isEmpty(replyLaterLeads.results)) {
              dispatch(
                setReplyLaterLeads({
                  ...replyLaterLeads,
                  results: replyLaterLeads.results.filter(
                    (l) => l.id !== leadId
                  )
                })
              );
            }
          }
          // await new APIRequest()
          //   .get(replaceUrl(GET_LEAD_DATA, 'leadId', leadId))
          //   .then((resp) => {
          //     if (resp.status == 200) {
          //       if (!isEmpty(resp?.data?.results)) {
          //         const [lead_data] = resp.data.results;
          //         console.log({ replyLaterLeads });

          //       }
          //     }
          //   });
          return;
        }
        // if box is opened
        if (params.box_id) {
          if (params.box_id == e.next_box_id) {
            if (params.cardId == leadId) {
              // return if that lead is opened
              return;
            }
            const lead_data = await getTheLeadData(leadId);
            const stages = stageLeadsRef.current;
            const stageData = stages[e.next_stage_id];
            if (stageData) {
              if (!isEmpty(stageData.new_unread)) {
                const isLeadExist = stageData.new_unread.find(
                  (lead) => lead.id == leadId
                );
                console.log('188', { isLeadExist });
                if (isLeadExist) {
                  return;
                }
              }
              const obj = new Object();
              let updatedStageData = {
                ...stageData
              };
              updatedStageData = {
                ...updatedStageData,
                new_unread: [
                  lead_data,
                  ...stageData.new_unread.filter((l) => l.id !== leadId)
                ],
                reply_later: stageData.reply_later.filter(
                  (l) => l.id !== leadId
                ),
                seen_replied: stageData.seen_replied.filter(
                  (l) => l.id !== leadId
                )
              };
              updatedStageData = {
                ...updatedStageData,
                count: getCountOfStageLeads(updatedStageData)
              };
              obj[lead_data.box_stage] = updatedStageData;
              console.log({ obj, updatedStageData });
              dispatch(
                setStageLeads({
                  ...stages,
                  ...obj
                })
              );
            }
          }
          // if the user is on previous box were the lead was moved from
          if (params.box_id == e.previous_box_id && params.cardId !== leadId) {
            const stages = stageLeadsRef.current;
            const stageData = stages[e.previous_stage_id];
            if (stageData) {
              const obj = new Object();
              let updatedStageData = {
                ...stageData
              };
              updatedStageData = {
                ...updatedStageData,
                seen_replied: stageData.seen_replied.filter(
                  (l) => l.id !== leadId
                ),
                new_unread: stageData.new_unread.filter((l) => l.id !== leadId),
                reply_later: stageData.reply_later.filter(
                  (l) => l.id !== leadId
                )
              };
              updatedStageData = {
                ...updatedStageData,
                count: getCountOfStageLeads(updatedStageData)
              };
              obj[e.previous_stage_id] = updatedStageData;
              dispatch(
                setStageLeads({
                  ...stages,
                  ...obj
                })
              );
            }
          }
        }
      }
    }
  };

  const onLeadReplyRecieved = async (e) => {
    console.log('reply_recieved', e);
    if (e.lead_id) {
      const unReadLeads = unReadLeadsRef.current;
      const newLeads = unReadLeadsRef?.current.results;
      const readLeads = readLeadsRef.current;
      const replyLaterLeads = replyLaterLeadsRef.current;
      const leadId = e.lead_id;
      const route = getRouteMatch();
      console.log({ route, newLeads, unReadLeads, unReadLeadsRef });
      const user = getUserFilterThingsToDo();
      const lead_data = await getTheLeadData(leadId);
      if (route) {
        dispatch(getAllCounts());
        dispatch(getTaskEnquiryCount());
        const { params } = route;
        // if lead is in things to do and not in box
        if (params.cardId && !params.box_id) {
          if (params.cardId !== leadId) {
            const isLeadExist = newLeads.find((lead) => lead.id == leadId);
            console.log('649', { isLeadExist });
            if (isLeadExist) {
              dispatch(
                setUnReadLeads({
                  ...unReadLeads,
                  results: unReadLeads.results.map((l) => {
                    if (l.id == lead_data.id) {
                      return lead_data;
                    }
                    return l;
                  })
                })
              );
              return;
            }
            if (!isLeadExist) {
              if (!isEmpty(user)) {
                if (!isEmpty(lead_data.assigned_to)) {
                  if (lead_data.assigned_to.id !== user.user_id) {
                    return;
                  }
                }
              }
              dispatch(
                setUnReadLeads({
                  ...unReadLeads,
                  results: [lead_data, ...unReadLeads.results]
                })
              );
              if (!isEmpty(readLeads.results)) {
                dispatch(
                  setReadLeads({
                    ...readLeads,
                    results: readLeads.results.filter((l) => l.id != leadId)
                  })
                );
              }
              if (!isEmpty(replyLaterLeads.results)) {
                dispatch(
                  setReplyLaterLeads({
                    ...replyLaterLeads,
                    results: replyLaterLeads.results.filter(
                      (l) => l.id != leadId
                    )
                  })
                );
              }
            }
          } else {
            // when your in same lead opened
            dispatch(setFetchConversation(true));
          }
          console.log('REPLY RECIVED');
          return;
        }
        // if lead is not opened and is not in box section, should be in things to do
        if (!params.cardId && !params.box_id) {
          console.log({ newLeads });
          const isLeadExist = newLeads.find((lead) => lead.id == leadId);
          const lead_data = await getTheLeadData(leadId);
          if (isLeadExist) {
            dispatch(
              setUnReadLeads({
                ...unReadLeads,
                results: newLeads.map((l) => {
                  if (l.id == leadId) {
                    return lead_data;
                  }
                  return l;
                })
              })
            );
            return;
          }
          if (!isLeadExist) {
            if (!isEmpty(user)) {
              if (!isEmpty(lead_data.assigned_to)) {
                if (lead_data.assigned_to.id !== user.user_id) {
                  return;
                }
              }
            }
            dispatch(
              setUnReadLeads({
                ...unReadLeads,
                results: [lead_data, ...unReadLeads.results]
              })
            );
            if (!isEmpty(readLeads.results)) {
              dispatch(
                setReadLeads({
                  ...readLeads,
                  results: readLeads.results.filter((l) => l.id !== leadId)
                })
              );
            }
            if (!isEmpty(replyLaterLeads.results)) {
              dispatch(
                setReplyLaterLeads({
                  ...replyLaterLeads,
                  results: replyLaterLeads.results.filter(
                    (l) => l.id !== leadId
                  )
                })
              );
            }
          }
          return;
        }

        // check lead if the card is in box with lead open
        if (params.cardId && params.stageId) {
          if (leadId == params.cardId) {
            // when your in same lead opened
            dispatch(setFetchConversation(true));
            return;
          }
          if (leadId !== params.cardId) {
            const stages = stageLeadsRef.current;
            const stageData = stages[params.stageId];
            if (stageData) {
              if (!isEmpty(stageData.new_unread)) {
                const isLeadExist = stageData.new_unread.find(
                  (l) => l.id == leadId
                );
                console.log('782', { isLeadExist });
                if (isLeadExist) {
                  const obj = new Object();
                  let updatedStageData = {
                    ...stageData
                  };
                  updatedStageData = {
                    ...updatedStageData,
                    new_unread: [
                      ...stageData.new_unread.map((l) => {
                        if (l.id == leadId) {
                          return lead_data;
                        }
                        return l;
                      })
                    ]
                  };
                  updatedStageData = {
                    ...updatedStageData,
                    count: getCountOfStageLeads(updatedStageData)
                  };
                  obj[lead_data.box_stage] = updatedStageData;
                  dispatch(
                    setStageLeads({
                      ...stages,
                      ...obj
                    })
                  );
                }
                if (!isLeadExist) {
                  const obj = new Object();
                  let updatedStageData = {
                    ...stageData
                  };
                  updatedStageData = {
                    ...updatedStageData,
                    new_unread: [
                      lead_data,
                      ...stageData.new_unread.filter((l) => l.id !== leadId)
                    ],
                    reply_later: stageData.reply_later.filter(
                      (l) => l.id !== leadId
                    ),
                    seen_replied: stageData.seen_replied.filter(
                      (l) => l.id !== leadId
                    )
                  };
                  updatedStageData = {
                    ...updatedStageData,
                    count: getCountOfStageLeads(updatedStageData)
                  };
                  obj[lead_data.box_stage] = updatedStageData;
                  dispatch(
                    setStageLeads({
                      ...stages,
                      ...obj
                    })
                  );
                }
              }
            }
          }
        }
        console.log('params', lead_data);
        // when only main box is opened
        if (params.box_id && !params.stageId && !params.cardId) {
          if (lead_data.box == params.box_id) {
            const stages = stageLeadsRef.current;
            const stageData = stages[lead_data.box_stage];
            if (stageData) {
              console.log({ stageData });
              const obj = new Object();
              let updatedStageData = {
                ...stageData
              };
              // if (lead.status === MANUALLY_ADDED) {
              //   updatedStageData = {
              //     ...updatedStageData,
              //     seen_replied: [
              //       lead,
              //       ...stageData.seen_replied.filter((l) => l.id !== lead.id)
              //     ],
              //     new_unread: stageData.new_unread.filter(
              //       (l) => l.id !== lead.id
              //     ),
              //     reply_later: stageData.reply_later.filter(
              //       (l) => l.id !== lead.id
              //     )
              //   };
              //   updatedStageData = {
              //     ...updatedStageData,
              //     count: getCountOfStageLeads(updatedStageData)
              //   };
              // }
              if (lead_data.status === NEW || lead_data.status === RESTORED) {
                updatedStageData = {
                  ...updatedStageData,
                  new_unread: [
                    lead_data,
                    ...stageData.new_unread.filter((l) => l.id !== leadId)
                  ],
                  reply_later: stageData.reply_later.filter(
                    (l) => l.id !== leadId
                  ),
                  seen_replied: stageData.seen_replied.filter(
                    (l) => l.id !== leadId
                  )
                };
                updatedStageData = {
                  ...updatedStageData,
                  count: getCountOfStageLeads(updatedStageData)
                };
                console.log('updatedStageData', { updatedStageData });
              }
              obj[lead_data.box_stage] = updatedStageData;
              console.log({ obj, updatedStageData });
              dispatch(
                setStageLeads({
                  ...stages,
                  ...obj
                })
              );
            }
          }
        }
      }
    }
  };

  const onPlanUpgradeOrDegrade = (e) => {
    console.log('plan upgrade or degrade', e);
    if (e.subs_plan_id) {
      dispatch(getMyInfo());
      dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
    }
  };

  // SOCKET CONNECTION
  useEffect(() => {
    if (subOrganizationId) {
      const url = process.env.REACT_APP_LIVE_SOCKET;
      const transportOptions = {
        transportOptions: {
          polling: {
            extraHeaders: {
              sub_organization_id: subOrganizationId
            }
          }
        }
      };
      const socket = io(url, transportOptions);

      socket.on('connect', (e) => {
        console.log('connected');
        dispatch(setSocket(socket));
      });

      socket.on('disconnect', () => {
        console.log('disconnect');
        dispatch(setSocket(socket));
      });

      socket.on('connect_error', () => {
        console.log('connect_error');
        socket.connect();
      });

      socket.on('new_lead_come', onLeadIncoming);

      socket.on('move_lead', onMoveLeadIncoming);

      socket.on('reply_received', onLeadReplyRecieved);

      socket.on('new_subs_plan_upgrade', onPlanUpgradeOrDegrade);
    }
  }, [subOrganizationId]);

  return <></>;
};

export default Sockets;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CustomToolTip from 'components/CustomTooltip';
import iconDelete from 'assets/icons/bin.svg';
import iconPlusGreen from 'assets/icons/icon-plus-green.svg';
import TextInputField from 'components/inputs/textField';
import Toggle from 'components/inputs/toggle';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch, useSelector } from 'react-redux';
import { getBoxFormData, updateBoxFormData } from 'slices/boxes/slice';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';
import { getQuestionsList } from 'slices/questionSlice';
import Select from 'components/inputs/select';
import { useSnackbar } from 'components/Snackbar';
import { ReactComponent as IconOut } from 'assets/icons/icon-out.svg';
import { useAnalytics } from 'use-analytics';
import UpgradePlanTopbar from 'components/UpgradePlanTopbar';
import usePlanAccess from 'hooks/usePlanAccess';

const BoxForm = () => {
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showRenameInput, setShowRenameInput] = useState(false);
  const [showDeleteIcon, SetShowDeleteIcon] = useState(null);
  const [addedQuestion, setAddedQuestion] = useState([]);
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const [zIndexForDropDown, setZindexforDropdown] = useState();
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const { boxFormData } = useSelector((state) => state.boxes);
  const [localBoxFormData, setLocalBoxFormData] = useState(boxFormData);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { questionsData } = useSelector((state) => state.question);
  const [isChecked, setIsChecked] = useState(boxFormData?.note);
  const [questionWiseOptions, setQuestionWiseOptions] = useState([]);
  // const { analytics } = useSelector((state) => state.segments);
  const BOX_FORM_URL = `${process.env.REACT_APP_BOX_FORM_PREVIEW_URL}/${subOrganizationId}/${box_id}`;
  const { track } = useAnalytics();

  const { has_custom_enquiry_form } = usePlanAccess();
  const questionOptions = questionsData.map((item) => {
    return {
      id: item.id,
      label: item.text,
      value: item.form_value
    };
  });

  useEffect(() => {
    setLocalBoxFormData(boxFormData);
    setIsChecked(boxFormData?.note);
  }, [boxFormData]);

  const loadCallback = () => {
    setIsLoading(false);
  };

  const callback = () => {
    const payload = {
      subOrgId: subOrganizationId,
      query: {
        box_id: box_id
      }
    };
    dispatch(getQuestionsList(payload, loadCallback));
  };

  const loadFormData = () => {
    dispatch(getBoxFormData({ boxId: box_id, callback }));
  };

  useEffect(() => {
    setIsLoading(true);
    loadFormData();
  }, []);

  const onAddQuestion = () => {
    const formData = {
      id: `question-${addedQuestion.length + 1}`,
      label: `Question ${addedQuestion.length + 1}`,
      value: ''
    };
    setAddedQuestion([...addedQuestion, formData]);
    setQuestionWiseOptions([
      ...questionWiseOptions,
      {
        label: `question${selectedQuestionId.length + 1}`,
        value:
          questionWiseOptions.length >= 1
            ? questionOptions.filter((item) => {
                return !selectedQuestionId.includes(item.id);
              })
            : questionOptions
      }
    ]);
  };

  const onOptionSelect = (data, position) => {
    setSelectedQuestionId([...selectedQuestionId, data.id]);
    const filterOptions = questionWiseOptions.map((item, index) => {
      if (position !== index) {
        return {
          label: item?.label,
          value: questionWiseOptions[index]?.value?.filter((ques) => {
            return ques.id !== data?.id;
          })
        };
      } else {
        return questionWiseOptions[index];
      }
    });
    setQuestionWiseOptions(filterOptions);
  };

  // const onDeleteUdpateQuestionOptions = (filteredQuestionOptions,selectedquestionId, position) => {
  //   // const filterSelectedQuestionValue = questionOptions.filter((item) => item.id === selectedQuestionId)
  //   const filterOptions = filteredQuestionOptions.map((item, index) => {
  //     if (position !== index) {
  //       return {
  //         label:`Question ${index+1}`,
  //         value: item?.value?.filter((ques) => {
  //           return ques.id !== selectedquestionId;
  //         })
  //       };
  //     } else {
  //       return questionWiseOptions[index];
  //     }
  //   });
  //   return filterOptions
  // }

  const OnDeleteTempQuestion = (id, ind) => {
    const remainingQuestions = addedQuestion.filter((item) => item.id !== id);
    setAddedQuestion(remainingQuestions);
    const remainingQuestionId = selectedQuestionId.filter(
      (item, index) => index !== ind
    );

    setSelectedQuestionId(remainingQuestionId);

    const filteredQuestionOptions = questionWiseOptions.filter(
      (item, index) => {
        return index !== ind;
      }
    );
    // const filteredQuestionQption = remainingQuestionId.map((item, index) => {
    //     return onDeleteUdpateQuestionOptions(filteredQuestionOptions,item,index,ind)
    // });
    // console.log({ filteredQuestionQption });
    setQuestionWiseOptions(filteredQuestionOptions);
  };

  const onDeleteQuestion = (id) => {
    const remainingQuestionData = localBoxFormData?.question_data.filter(
      (item) => item.id !== id
    );
    const remainingQuestionId = localBoxFormData?.questions.filter(
      (item) => item !== id
    );
    setLocalBoxFormData({
      ...localBoxFormData,
      question_data: remainingQuestionData,
      questions: remainingQuestionId
    });
  };

  const updateCallback = () => {
    setIsButtonLoading(false);
    track('custom_box_form', {}, { groupId: subOrganizationId });

    setAddedQuestion([]);
    setSelectedQuestionId([]);
    setQuestionWiseOptions([]);

    openSuccessSnackBar('form updated successfully', 'large');
  };

  const onDeleteDefaultQuestion = (name) => {
    const remainingFields = localBoxFormData?.fields.filter(
      (item) => item !== name
    );
    setLocalBoxFormData({ ...localBoxFormData, fields: remainingFields });
  };

  const onCopyLink = () => {
    navigator.clipboard.writeText(BOX_FORM_URL);
    openSuccessSnackBar('Link copied');
  };

  const onSave = () => {
    setIsButtonLoading(true);
    const totalId = !isEmpty(localBoxFormData.questions)
      ? [...localBoxFormData.questions, ...selectedQuestionId]
      : selectedQuestionId;
    const formData = {
      box: box_id,
      fields: localBoxFormData?.fields,
      questions: totalId,
      note: isChecked
    };
    dispatch(updateBoxFormData({ body: formData, callback: updateCallback }));
  };

  const handleOpenLink = () => {
    window.open(BOX_FORM_URL);
  };
  if (isLoading) return <Loader />;
  return (
    <div className="dashboard__main__body">
      <div className="dashboard__main__body__section-right">
        <div>
          <h2 className="text-2xl font-semibold text-center">Box form</h2>
          <p className="text-grey-800 text-15 text-center mt-3 px-16">
            Create the perfect form to add enquiries to your box
          </p>
        </div>
        <div
          className="border-radius-14 bg-grey-300 mt-10"
          style={{ padding: '35px  60px' }}
        >
          {!isEmpty(localBoxFormData) &&
            !isEmpty(localBoxFormData.fields) &&
            localBoxFormData.fields.map((item, index) => {
              if (item === 'First Name') {
                return (
                  <div
                    key={index}
                    style={{
                      width: '105%',
                      display: 'flex'
                    }}
                  >
                    <div
                      className="rounded-xl bg-color-on-hover bg-color-on-hover--xxs pt-2 pb-4 w-1/2"
                      onMouseEnter={() => SetShowDeleteIcon(item)}
                      onMouseLeave={() => SetShowDeleteIcon(null)}
                    >
                      <div className="w-full">
                        <label className="input-field-label">{item}</label>
                        <div>
                          <TextInputField
                            name={item}
                            className="input-field--type2 input-field--sm"
                            placeholder={item}
                            containerStyle={{ width: '91%' }}
                            readOnly
                            style={{ padding: '10px 15px' }}
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className="rounded-xl bg-color-on-hover bg-color-on-hover--xxs pt-2 pb-4 w-1/2"
                      onMouseEnter={() => SetShowDeleteIcon('Last Name')}
                      onMouseLeave={() => SetShowDeleteIcon(null)}
                    >
                      <div className="w-full">
                        <label className="input-field-label">
                          {'Last Name'}
                        </label>
                        <div>
                          <TextInputField
                            name={'Last Name'}
                            className="input-field--type2 input-field--sm"
                            placeholder={'Last Name'}
                            containerStyle={{ width: '91%' }}
                            readOnly
                            style={{ padding: '10px 15px' }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                if (item !== 'Last Name') {
                  return (
                    <div
                      className="rounded-xl bg-color-on-hover pt-2 pb-4"
                      key={index}
                      onMouseEnter={() => SetShowDeleteIcon(item)}
                      onMouseLeave={() => SetShowDeleteIcon(null)}
                      style={{ width: '105%' }}
                    >
                      <div className="w-full">
                        <label className="input-field-label">{item}</label>
                        <div className="flex items-center">
                          <TextInputField
                            name={item}
                            className="input-field--type2 input-field--sm"
                            placeholder={item}
                            style={{ padding: '10px 15px' }}
                            containerStyle={
                              showDeleteIcon === item &&
                              item !== 'Last Name' &&
                              item !== 'Stage'
                                ? { marginRight: 8, width: '95%' }
                                : { width: '95%' }
                            }
                            readOnly
                          />
                          {showDeleteIcon === item &&
                            item !== 'Last Name' &&
                            item !== 'Stage' && (
                              <>
                                {localBoxFormData.fields.includes('Email') &&
                                localBoxFormData.fields.includes(
                                  'Phone Number'
                                ) ? (
                                  <img
                                    style={{
                                      width: '14px',
                                      height: '16px',
                                      position: 'absolute',
                                      top: '43px',
                                      right: '-5px'
                                    }}
                                    src={iconDelete}
                                    alt=""
                                    className="cursor-pointer"
                                    onClick={() =>
                                      onDeleteDefaultQuestion(item)
                                    }
                                  />
                                ) : (
                                  <CustomToolTip
                                    position="top--delete-icon"
                                    tooltipText="You can't delete the field. The system needs to have at least one contact."
                                  >
                                    <img
                                      src={iconDelete}
                                      alt=""
                                      className="cursor-pointer"
                                    />
                                  </CustomToolTip>
                                )}
                              </>
                            )}
                        </div>
                      </div>
                    </div>
                  );
                }
              }
            })}
          {!isEmpty(localBoxFormData.question_data) &&
            !isEmpty(localBoxFormData.questions) &&
            localBoxFormData.question_data.map((item) => {
              return (
                <div
                  className="rounded-xl bg-color-on-hover pt-2 pb-4"
                  key={item.id}
                  onMouseEnter={() => SetShowDeleteIcon(item.id)}
                  onMouseLeave={() => SetShowDeleteIcon(null)}
                >
                  <div className="w-full">
                    <label className="input-field-label">{item.text}</label>
                    <div className="flex items-center">
                      <TextInputField
                        name={item.id}
                        className="input-field--type2"
                        placeholder={item.text}
                        style={{ padding: '9px 15px' }}
                        containerStyle={
                          showDeleteIcon === item.id
                            ? { marginRight: 8, width: '100%' }
                            : { width: '100%' }
                        }
                        readOnly
                      />
                      {showDeleteIcon === item.id && (
                        <img
                          src={iconDelete}
                          alt=""
                          className="cursor-pointer"
                          onClick={() => onDeleteQuestion(item.id)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {!isEmpty(addedQuestion) &&
            addedQuestion.map((item, index) => {
              return (
                <div
                  className="rounded-xl bg-color-on-hover pt-2 pb-4"
                  key={item.id}
                  style={{
                    zIndex: zIndexForDropDown === index ? '1' : '0',
                    width: '105%'
                  }}
                  onMouseEnter={() => SetShowDeleteIcon(item.id)}
                  onMouseLeave={() => SetShowDeleteIcon(null)}
                  onClick={() => setZindexforDropdown(index)}
                >
                  <h3 className="text-sm font-semibold mb-1">
                    Question {index + 1}
                  </h3>
                  <div className="flex items-center" style={{ width: '95%' }}>
                    <Select
                      name="options"
                      options={questionOptions}
                      inputClassName="input-field--xs-34"
                      dropDownClassName="p-2"
                      placeholder="Select a question"
                      readOnly={true}
                      style={{ padding: '9px 15px' }}
                      containerStyle={{ width: '100%' }}
                      onChange={(value) => onOptionSelect(value, index)}
                    />
                    {showDeleteIcon === item.id && (
                      <img
                        style={{
                          width: '14px',
                          height: '16px',
                          position: 'absolute',
                          top: '43px',
                          right: '-5px'
                        }}
                        src={iconDelete}
                        alt=""
                        className="cursor-pointer"
                        onClick={() => OnDeleteTempQuestion(item.id, index)}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          {localBoxFormData?.question_data &&
          [...localBoxFormData.question_data, ...questionWiseOptions].length <
            questionOptions.length ? (
            <div className="flex mt-4 items-center">
              <img
                src={iconPlusGreen}
                alt=""
                style={{ marginRight: 8, width: 10, height: 10 }}
              />
              <div
                className="text-sm font-semibold text-primary cursor-pointer"
                onClick={onAddQuestion}
              >
                Add Question
              </div>
            </div>
          ) : (
            <>
              {!isEmpty(questionOptions) && (
                <p className="text-13 text-grey-800 ">
                  Maximum number of questions for this form reached
                </p>
              )}
            </>
          )}
          <div className="flex mt-6 items-center justify-between">
            <div className="text-15 font-semibold">Note</div>
            <Toggle
              id="notes"
              small
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
          </div>
        </div>
        <div className="flex justify-end pb-5 mt-5">
          <PrimaryButton
            label="Save"
            className={
              !has_custom_enquiry_form
                ? 'btn--blocked min-w-120 cursor-not-allowed'
                : 'btn--md min-w-120'
            }
            onClick={has_custom_enquiry_form ? onSave : () => {}}
            enableLoader
            loaderInline
            isLoading={isButtonLoading}
          />
        </div>
        <div className="card-box mt-5 px-6 pt-5 pb-6">
          <div className="flex items-center justify-between">
            <h2 className="font-semibold text-lg">Enquiry form page</h2>
            <div
              className="cursor-pointer items-center flex"
              onClick={(e) => {
                e.stopPropagation();
                handleOpenLink();
              }}
            >
              <CustomToolTip
                position="top"
                tooltipText="Open in new tab"
                tooltipStyle={{ maxWidth: 160, left: '30%' }}
              >
                <IconOut />
              </CustomToolTip>
            </div>
          </div>
          <p className="text-15 text-grey-800 mt-1">
            Add enquiries to this box without having to login.
          </p>
          <div className="mt-5 relative">
            <TextInputField
              name="formPage"
              className={`input-field--sm-38 ${
                !has_custom_enquiry_form ? 'pointer-events-none' : ''
              }`}
              placeholder="folders02/1axsRtXwQ_H6JOxbYzQPqPeXDrEshsjjlqiu54_jiql68TdEJYlhj..."
              style={{
                fontWeight: 400,
                color: '#4C5254',
                paddingRight: 80
              }}
              value={BOX_FORM_URL}
              readOnly
            />
            <PrimaryButton
              label="Copy"
              className={
                !has_custom_enquiry_form
                  ? 'btn--blocked btn--sm btn--copy btn--copy--sm'
                  : 'btn--sm btn--copy btn--copy--sm'
              }
              onClick={has_custom_enquiry_form ? onCopyLink : () => {}}
            />
          </div>
        </div>
        {/* <div className="card-box mt-6 px-6 pt-5 pb-6">
          <h2 className="font-semibold text-lg">Change the button text</h2>
          <p className="text-15 text-grey-800 mt-1">
            Customize the word used on your add enquiry button
          </p>
          <div className="flex mt-5 items-center">
            <CustomColorButtonWithLogo
              label="Enquiry"
              className="btn--default text-primary text-13 mr-2"
              render={() => {
                return (
                  <img src={iconPlusGreen} alt="" style={{ marginRight: 6 }} />
                );
              }}
            />
            <div
              className="text-primary text-xs font-semibold cursor-pointer"
              onClick={() => setShowRenameInput(!showRenameInput)}
            >
              Change
            </div>
          </div>
          {showRenameInput && (
            <div className="flex mt-5 items-center">
              <TextInputField
                className="input-field--md mr-3"
                style={{ width: 240 }}
                placeholder=""
                name="renameButton"
              />
              <p className="text-grey-800 text-xs">Maximum 10 characters</p>
            </div>
          )}
        </div> */}
        {/* <div className="flex justify-end pb-5 mt-5">
          <PrimaryButton
            label="Save"
            className="btn--md min-w-120"
            onClick={onSave}
            enableLoader
            loaderInline
            isLoading={isButtonLoading}
          />
        </div> */}
      </div>
    </div>
    // </UpgradePlanTopbar>
  );
};

export default BoxForm;

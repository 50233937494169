import ErrorMessage from 'components/errorMessage';
import TextInputField from 'components/inputs/textField';
import React from 'react';

const UserInfoForm = (props) => {
  const { placeholder, name, register, errors, touchedFields } = props;
  return (
    <>
      <TextInputField
        autoComplete={'off'}
        className={`h-[42px] mb-6`}
        placeholder={placeholder}
        name={name}
        error={errors[name] && touchedFields[name]}
        register={register}
      >
        <div className="absolute top-[57%]">
          {errors[name] && touchedFields[name] && (
            <ErrorMessage
              className="ml-1 mb-2"
              message={errors[name].message}
            />
          )}
        </div>
      </TextInputField>
    </>
  );
};

export default UserInfoForm;

import React from 'react';
import formIcon from 'assets/icons/icon-fb-form-icon.svg';
import webformFormIcon from 'assets/icons/icon-webform-your-form.svg';
import { ReactComponent as EditIcon } from 'assets/icons/icon-automation-edit.svg';
import { ReactComponent as DelteIcon } from 'assets/icons/icon-automation-delete.svg';
import Toggle from 'components/inputs/toggle';
import { useForm } from 'react-hook-form';
import CustomToolTip from 'components/CustomTooltip';

const FormItem = (props) => {
  const {
    title,
    item,
    status,
    handleStatus,
    handleDeleteForm,
    onEdit,
    isWebform = false,
    formPage
  } = props;

  const { register } = useForm({
    mode: 'onChange'
  });

  return (
    <div className="border border-grey-400 rounded-xl py-4 pr-6 pl-4 flex justify-between mb-4">
      <div className="flex">
        <div className="mr-4">
          <figure
            className="bg-grey-300 border-radius-10 flex justify-center items-center"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.05)',
              height: 50,
              width: 52
            }}
          >
            <img
              src={!isWebform ? formIcon : webformFormIcon}
              alt="form-icon"
            />
          </figure>
        </div>
        {!isWebform ? (
          <div className="flex items-center" style={{ maxWidth: 700 }}>
            <h2 className="font-semibold truncate">{title}</h2>
          </div>
        ) : (
          <div style={{ maxWidth: 700 }}>
            <h2 className="font-semibold truncate">{title}</h2>
            <p className="text-sm text-grey-800 mt-1">Form page: {formPage}</p>
          </div>
        )}
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <CustomToolTip position="top" tooltipText="Edit mapping">
            <EditIcon className="cursor-pointer" onClick={() => onEdit(item)} />
          </CustomToolTip>
        </div>
        <div className="mr-2">
          <CustomToolTip position="top" tooltipText="Delete mapping">
            <DelteIcon
              className="cursor-pointer"
              onClick={() => handleDeleteForm(item.id)}
            />
          </CustomToolTip>
        </div>
        {!isWebform && (
          <Toggle
            id={item.id}
            register={register}
            checked={status}
            small={false}
            onChange={(evt) => handleStatus(evt, item)}
          />
        )}
      </div>
    </div>
  );
};

export default FormItem;

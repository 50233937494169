import React from 'react';
// import iconVideo from 'assets/icons/icon-landing-video.svg';
import PropTypes from 'prop-types';

const EmptyState = (props) => {
  const { title, description } = props;

  return (
    <div className="dashboard__main__body__section-right layout-1">
      <h2 className="text-2xl font-semibold text-center">{title}</h2>
      <p className="text-center text-sm text-grey-800 mt-4">{description}</p>
      {/* <figure className="mt-9">
        <img
          src={iconVideo}
          alt="landing-video"
          style={{ width: 620, height: 420 }}
        />
      </figure> */}
      <div className="mt-9">
        <iframe
          src="https://player.vimeo.com/video/817353100?h=514657c98e&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen={true}
          title="iframe"
          className="cursor-pointer"
          style={{
            position: 'absolute',
            width: '620px',
            height: '420px'
          }}
        ></iframe>
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};

export default EmptyState;

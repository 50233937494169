/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { isEmpty, getKiloBytes } from 'utils/utils';
import { isValidFileFormat, isValidFileSize } from 'utils/file';
import Uploading from './uploadingContainer';
import FileTooLarge from './fileTooLarge';
import UnsupportedFile from './unsupportedFile';
import CustomColorButton from 'components/buttons/customColorButton';
// import FileNameTooLarge from './fileNameTooLarge';
import { animateScroll } from 'react-scroll';
// import OverLayLoader from 'components/loader/overLayLoader';

const Upload = (props) => {
  const {
    open,
    onClose,
    onUpload,
    filesList,
    isBtnLoading,
    isFileLibrary,
    setAlreadyUploadedFiles
  } = props;
  const [uploadedFiles, setUploadedFiles] = useState(filesList);
  const [isLargeFile, setIsLargeFile] = useState(false);
  const [unsupportedFile, setUnsupportedFile] = useState(false);
  const [showDropDown, setShowdropDown] = useState(null);
  // const [maxCharacterFile, setMaxCharacterFile] = useState(false);
  const inputRef = useRef();

  useEffect(() => {
    setUploadedFiles(filesList);
  }, [filesList]);

  const handleScroll = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'modal-body',
        smooth: true,
        duration: 0
      });
    }, 0);
  };

  useEffect(() => {
    if (!isEmpty(showDropDown)) {
      handleScroll();
    }
  }, [showDropDown]);

  // useEffect(() => {
  //   if (!isEmpty(showDropDown)) {
  //     const activeTime = document.getElementById(`modal-body`);
  //     activeTime.scrollTop = activeTime.offsetTop + 10;
  //   }
  // }, [showDropDown]);

  // const checkIsValidCharacter = (name) => {
  //   const isValid = name.length <= 30;
  //   if (!isValid) {
  //     setMaxCharacterFile(true);
  //   }
  //   return isValid;
  // };

  const checkIsValidFile = (type) => {
    const isValid = isValidFileFormat(type);
    if (!isValid) {
      setUnsupportedFile(true);
    }
    return isValid;
  };

  const checkIsValidFileSize = (size) => {
    const isValid = isValidFileSize(size);
    if (!isValid) {
      setIsLargeFile(true);
    }
    return isValid;
  };

  const handleDrop = (event) => {
    event.preventDefault();
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      // at least one file has been dropped so do something
      // handleFiles(e.dataTransfer.files);
      // console.log('at least one file has been dropped so do something');
      const file = event.dataTransfer.files[0];
      // const isValidCharacter = checkIsValidCharacter(file.name);
      // if (!isValidCharacter) return;
      const isValidFile = checkIsValidFile(file.name);
      if (!isValidFile) return;
      const isFileSizeValid = checkIsValidFileSize(file.size);
      if (!isFileSizeValid) return;
      const fileData = {
        file: file,
        isUploading: true,
        file_name: file.name,
        type: file.type,
        file_size: getKiloBytes(file.size, 2)
      };
      setUploadedFiles([...uploadedFiles, fileData]);
    }
  };

  const handleInputChange = (event) => {
    const file = event.target.files[0];
    event.target.value = '';
    // const isValidCharacter = checkIsValidCharacter(file.name);
    // if (!isValidCharacter) return;
    const isValidFile = checkIsValidFile(file.name);
    if (!isValidFile) return;
    const isFileSizeValid = checkIsValidFileSize(file.size);
    if (!isFileSizeValid) return;
    const fileData = {
      file: file,
      isUploading: true,
      file_name: file.name,
      type: file.type,
      file_size: getKiloBytes(file.size, 2)
    };
    setUploadedFiles([...uploadedFiles, fileData]);
  };

  const handleUpdate = () => {
    const selectedFiles = uploadedFiles.filter(
      (elem) => !filesList.find(({ file_url }) => elem.file_url === file_url)
    );
    if (!isEmpty(filesList)) {
      if (!isEmpty(selectedFiles)) {
        onUpload(isFileLibrary, selectedFiles);
      } else {
        onUpload(isFileLibrary, selectedFiles);
        onClose();
      }
    } else {
      onUpload(isFileLibrary, uploadedFiles);
    }
  };

  const isAllFilesUploaded = () => {
    const isAllFilesUplodedStatus = uploadedFiles.some(
      (file) => file.isUploading
    );
    return isAllFilesUplodedStatus;
  };

  return (
    <Modal.Main open={open}>
      {/* {isBtnLoading && <OverLayLoader />} */}
      <Modal.Header
        title="Upload File"
        onClose={() => {
          setUnsupportedFile(false);
          setIsLargeFile(false);
          // setMaxCharacterFile(false);
          onClose();
        }}
      />
      <Modal.Body className="overflow-y-auto" id="modal-body">
        {isLargeFile && (
          <FileTooLarge onTryAgain={() => setIsLargeFile(false)} />
        )}
        {unsupportedFile && (
          <UnsupportedFile onTryAgain={() => setUnsupportedFile(false)} />
        )}
        {/* {maxCharacterFile && (
          <FileNameTooLarge onTryAgain={() => setMaxCharacterFile(false)} />
        )} */}
        {!isLargeFile && !unsupportedFile && (
          <>
            <div
              onClick={() => inputRef?.current?.click()}
              className="file-upload-wrapper"
              onDrop={(e) => handleDrop(e)}
              onDragOver={(e) => handleDrop(e)}
              onChange={handleInputChange}
            >
              <input ref={inputRef} type="file" name="file-browser-input" />
              <h3 className="text-sm font-semibold">
                Click to Upload or Drag and Drop
              </h3>
              <p className="text-sm text-grey-800 mt-2">
                Max file size{' '}
                <span className="font-medium text-grey-900">4 MB</span>
              </p>
              <button
                className="btn btn--grey btn--sm w-2/4 mx-auto mt-5"
                onClick={(event) => {
                  event.stopPropagation();
                  inputRef?.current?.click();
                }}
              >
                Upload
              </button>
            </div>
            <Uploading
              uploadedFiles={uploadedFiles}
              setUploadedFiles={setUploadedFiles}
              showDropDown={showDropDown}
              setShowdropDown={setShowdropDown}
              setAlreadyUploadedFiles={setAlreadyUploadedFiles}
            />
          </>
        )}
      </Modal.Body>
      {!isLargeFile && !unsupportedFile && !isEmpty(uploadedFiles) && (
        // <div className="flex items-center justify-end gap-3 py-5 px-5">
        //   <button
        //     className="btn btn--sm btn--secondary w-1/4"
        //     onClick={onClose}
        //   >
        //     Cancel
        //   </button>
        //   <button
        //     className="btn btn--sm btn--primary w-1/4"
        //     onClick={() => {
        //       handleUpdate();
        //       setUploadedFiles([]);
        //     }}
        //     disabled={isEmpty(uploadedFiles) || isAllFilesUploaded()}
        //   >
        //     Upload
        //   </button>
        // </div>
        <div className="flex items-center justify-end gap-3 py-5 px-5">
          <CustomColorButton
            className="btn--sm btn--secondary w-1/4"
            onClick={onClose}
            label="Cancel"
            isDisable={isAllFilesUploaded()}
          />
          <CustomColorButton
            label="Upload"
            className="btn--sm btn--primary w-1/4"
            onClick={() => {
              if (!isFileLibrary) {
                handleUpdate();
                setUploadedFiles([]);
              } else {
                onUpload(uploadedFiles);
              }
            }}
            isDisable={isEmpty(uploadedFiles) || isAllFilesUploaded()}
            isLoading={isBtnLoading}
            enableLoader
          />
        </div>
      )}
    </Modal.Main>
  );
};

Upload.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  isBtnLoading: PropTypes.bool,
  isFileLibrary: PropTypes.bool
};

Upload.defaultProps = {
  isFileLibrary: false
};

export default Upload;

import React from 'react';
import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';

const DeleteBoxModel = ({ showConfirmation, onCancel, onConfirm }) => {
  return (
    <Modal.Main open={showConfirmation} className="modal--channels-request">
      <Modal.Header
        style={{ backgroundColor: '#fff', boxShadow: 'none' }}
        title=""
        onClose={onCancel}
      />
      <Modal.Body className="pb-10 pt-0 overflow-y-auto">
        <React.Fragment>
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">
              Are you sure you want to delete this box?
            </h2>
          </div>
          <div className="text-13 text-center mt-3 px-16 ">
            If you delete the box all the enquiries and automations will be
            deleted and you cannot get them back. Please be sure as there is no
            point of return once you click delete!
          </div>
          <div className="flex items-center gap-2 justify-center px-8 mt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--wide-md-40 btn--cancel text-base-1 mr-3"
              onClick={onCancel}
            />
            <CustomColorButton
              label="Delete"
              className="btn--wide-md-40 text-base-1 bg-red-900 text-white mr-3"
              onClick={onConfirm}
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default DeleteBoxModel;

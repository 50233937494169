import React from 'react';
import ContractValue from 'modules/boxes/stages/stage/contractValue';
import StageHeader from 'modules/boxes/stages/stage/header';
import StageEmptyState from 'modules/boxes/stages/stage/emptyState';
import { isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';

const PreviewBoxTemplate = ({ showPreview }) => {
  // const [showDelete,setShowDelete] = useState()
  const { myInformation } = useSelector((state) => state.myInfo);
  return (
    <div className="box-card-wrapper py-5 px-6">
      {!isEmpty(showPreview) &&
        showPreview.stages?.map((item, index) => {
          return (
            <div
              className="box-card-container"
              style={{ maxHeight: '197px' }}
              key={index}
            >
              <ContractValue
                value={0}
                currency={myInformation?.my_org_info?.currency?.code}
              />
              <div className={`mt-3`} style={{ width: '420px' }}>
                <StageHeader
                  name={item?.stage_name}
                  count={0}
                  stageId={0}
                  isIncoming={item?.stage_name === 'Incoming'}
                  isPreview={true}
                />
              </div>
              <div
                className="px-4 overflow_y pl-5"
                style={{
                  height: 'calc(100% - 57px)'
                }}
              >
                <div>
                  <StageEmptyState
                    incomingStage={item?.stage_name === 'Incoming'}
                    boxName={item.stage_name}
                    box={''}
                    dragStart={() => {}}
                    stageId={12321}
                    isAppliedFilter={false}
                  />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default PreviewBoxTemplate;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  getAllPendingChannels,
  connectChannelsAsDraft
} from 'slices/channelsSlice';
// import { useSnackbar } from 'components/Snackbar';

const WebformPreDraft = ({ isEbox }) => {
  const { allPendingChannels } = useSelector((state) => state.channels);
  const { channelId } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { openSuccessSnackBar } = useSnackbar();

  const subOrganizationId = useSelector(
    (state) => state.myInfo.subOrganizationId
  );

  const channel = allPendingChannels?.find((data) => data.id === channelId);

  useEffect(() => {
    if (channel && !channel.is_it_deleteable) {
      connectWebformAsDraft();
    }
    if (channel && channel.is_it_deleteable) {
      redirect(channel);
    }
  }, [channel]);

  const redirect = (data) => {
    if (isEbox) {
      navigate(`/channels/webforms/4/pending/${data.id}/draft`);
    } else {
      navigate(`/channels/webforms/4/pending/${data.id}/draft`);
    }
  };

  const onSuccess = (data) => {
    // openSuccessSnackBar('Channel created');
    dispatch(
      getAllPendingChannels({ subOrgId: subOrganizationId }, () => {
        redirect(data);
      })
    );
  };

  const connectWebformAsDraft = async () => {
    const formData = {
      body: {
        channel: channel?.id
      },
      subOrgId: subOrganizationId
    };
    dispatch(connectChannelsAsDraft(formData, onSuccess));
  };

  return <></>;
};

export default WebformPreDraft;

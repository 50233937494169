import iconHand from 'assets/icons/icon-hand.svg';
import NotesEditor from '../../footer/notes';
import Note from './note';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getAllNotes } from 'slices/notes/slice';
import { isEmpty } from 'utils/utils';
import { animateScroll } from 'react-scroll';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { deleteTaskNotes, resetNoteStore } from 'slices/noteSlice';
import { setAllNotes } from 'slices/notes/slice';
import { useSnackbar } from 'components/Snackbar';
import { setFetchConversation } from 'slices/conversationSlice';

const Notes = () => {
  const dispatch = useDispatch();
  const { cardId } = useParams(); // to get the leadId from the url
  const { openSuccessSnackBar } = useSnackbar(); // to show the success toaster after success
  const [openDeleteModal, setOpenDeleteModal] = useState(null); //state to open or close note delete confirmation modal
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); //state for loader inside delete button
  const [isRefetch, setIsRefetch] = useState(false);

  const { allNotes } = useSelector((state) => state.allNotes);

  useEffect(() => {
    dispatch(getAllNotes({ leadId: cardId }));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    // to reset the note editor when note screen loads
    dispatch(resetNoteStore());
    setIsRefetch(true);
  }, []);

  // function to scroll the view automatically to the bottom using id
  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToTop({
        containerId: 'notesContainer', // id
        smooth: true,
        duration: 0
      });
    }, 100);
  };

  // callback function of delete note api
  const callback = (data) => {
    setIsDeleteLoading(false);
    // deleting the note from local state instead of calling api
    const updatedNoteData = allNotes.filter((item) => item.id !== data.note_id);
    dispatch(setAllNotes(updatedNoteData));
    openSuccessSnackBar('Note deleted'); // delete success toaster
    setOpenDeleteModal(null); // close the delete modal
    dispatch(resetNoteStore());
    dispatch(setFetchConversation(true));
    setIsRefetch(true);
  };

  // function to delete the note
  const onDelete = () => {
    setIsRefetch(false);
    setIsDeleteLoading(true);
    dispatch(
      deleteTaskNotes({
        noteId: openDeleteModal,
        callback: callback
      })
    );
  };

  return (
    <>
      {openDeleteModal && (
        <DeleteConfirmation
          showConfirmation={openDeleteModal !== null ? true : false}
          onCancel={() => setOpenDeleteModal(null)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this note?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ width: 536, height: 'initial', top: 'auto' }}
          isLoading={isDeleteLoading}
        />
      )}
      <div style={{ height: 'calc(100% - 50px)' }}>
        <section className="contact-notes" id="notesContainer">
          {isEmpty(allNotes) && (
            <div className="notes-empty-container">
              <p className="text-lg font-semibold">
                A note a day keeps lost sales away
              </p>
              <img
                src={iconHand}
                alt="icon-hand"
                style={{ transform: 'rotate(-90deg)', marginTop: 10 }}
              />
            </div>
          )}
          <ul>
            {!isEmpty(allNotes) &&
              allNotes.map((note) => {
                const {
                  id,
                  sent_by_automation,
                  created_at,
                  description,
                  created_by,
                  sent_by_zapier
                } = note;
                let tempDesc = description.replaceAll('*', '');
                tempDesc = tempDesc.replaceAll('#', '');
                return (
                  <Note
                    isAutomated={sent_by_automation}
                    key={id}
                    description={sent_by_zapier ? tempDesc : description}
                    createdAt={created_at}
                    createdBy={created_by?.first_name}
                    id={id}
                    setOpenDeleteModal={setOpenDeleteModal}
                    src={created_by?.avatar}
                    isZapier={sent_by_zapier}
                  />
                );
              })}
          </ul>
        </section>
        <span
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%'
            // marginBottom: '-5px'
          }}
          className="chat-message-footer__note"
        >
          <NotesEditor
            bodyStyle={{ height: 140 }}
            inputStyle={{ height: 95 }}
            autoScroll={scrollDown}
            isRefetch={isRefetch}
          />
        </span>
      </div>
    </>
  );
};

export default Notes;

import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as CaretUp } from 'assets/icons/icon-select-caret-up.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { setSelectedFromEmail } from 'slices/editor/slice';
import InlineEmail from 'modules/enquirybox/ebox/module/box/conversation/footer/replyEmail/inlineEmail';
import TextArea from 'components/inputs/textarea';

const SendEmail = (props) => {
  const { emailData, setEmailData, error, textAreaRow } = props;
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const [showFromUsers, setShowFromUsers] = useState(false);
  //   const [showCcInput, setShowCcInput] = useState(false);
  //   const [showBccInput, setShowBccInput] = useState(false);
  const { id: currentUser, reply_sending_mail_address: userEmail } =
    useSelector((state) => state.myInfo.myInformation);
  const dispatch = useDispatch();
  const userEmailRef = useRef();
  const sectionTopRef = useRef();
  const emailBodyRef = useRef();

  useEffect(() => {
    dispatch(
      setSelectedFromEmail({
        user_id: currentUser,
        email: userEmail
      })
    );
  }, []);

  const setFromEmail = (user) => {
    dispatch(setSelectedFromEmail(user));
    // setSelectedUserSignature('');
    // dispatch(
    //   handleChangeSignature(user.user_id, editor, (data, signature) => {
    //     dispatch(setEmailBody(data));
    //     setSelectedUserSignature(signature);
    //   })
    // );
    setShowFromUsers(false);
  };

  const { selectedFromEmail } = useSelector((state) => state.editor);

  return (
    <div>
      <div className="chat-reply">
        <div className="section-top" ref={sectionTopRef}>
          <div
            className="info-block space"
            style={
              error.fromEmail
                ? {
                    borderColor: '#ff0000'
                  }
                : {}
            }
          >
            <div className="flex items-center justify-center">
              <span className="label mr-3">From</span>
              <div className="from-email relative">
                <section
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => setShowFromUsers(!showFromUsers)}
                >
                  {isEmpty(selectedFromEmail) && <span>User Email</span>}
                  {!isEmpty(selectedFromEmail) && (
                    <span>{selectedFromEmail?.email}</span>
                  )}
                  <figure className="ml-2">
                    {showFromUsers ? <CaretUp /> : <CaretBlackIcon />}
                  </figure>
                </section>
                <div
                  ref={userEmailRef}
                  className={`dropdown-menu dropdown-menu--sm ${
                    showFromUsers ? 'open' : ''
                  }`}
                  style={{
                    width: 'max-content',
                    top: 'calc(100% + 2px)',
                    left: 0,
                    right: 0
                  }}
                >
                  {organizationUser?.results?.map((user) => {
                    const { user_id, reply_sending_mail_address } = user;
                    return (
                      <div
                        key={user_id}
                        className="dropdown-menu__list"
                        onClick={() => {
                          delete error.fromEmail;
                          // setError({ ...error, fromEmail: null });
                          setFromEmail({
                            user_id,
                            email: reply_sending_mail_address
                          });
                        }}
                      >
                        <p className="text-sm">{reply_sending_mail_address}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
          <div className={`${error.to ? 'border-b border-red-900' : ''}`}>
            <InlineEmail
              title="To"
              placeholder="Add your developer or agency email here..."
              list={emailData?.to}
              setList={(list) => {
                delete error.to;
                // setError({ ...error, to: null });
                setEmailData({ ...emailData, to: list });
              }}
              hideFlex={true}
            />
          </div>
          <div
            className="info-block w-full items-center"
            style={
              error.subject
                ? {
                    borderColor: '#ff0000'
                  }
                : {}
            }
          >
            <div className="label mr-3">Subject</div>
            <div className="flex items-center justify-center w-full">
              <form className="w-full">
                <input
                  type="text"
                  className="email-text"
                  placeholder="Subject"
                  value={emailData?.subject}
                  onChange={(e) => {
                    delete error.subject;
                    // setError({ ...error, subject: null });
                    setEmailData({ ...emailData, subject: e.target.value });
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div ref={emailBodyRef}>
          <div className="flex items-center justify-center w-full">
            <form className="w-full">
              <TextArea
                name={'sendEmail'}
                value={emailData?.body}
                onChange={(e) => {
                  delete error.emailBody;
                  // setError({ ...error, emailBody: null });
                  setEmailData({ ...emailData, body: e.target.value });
                }}
                style={{
                  fontWeight: 400,
                  border: 'transparent',
                  boxShadow: 'none',
                  padding: '11px 28px'
                }}
                className="border text-base-1 resize-none focus:border-none pre"
                rows={textAreaRow ? textAreaRow : 14}
                cols="5"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

SendEmail.propTypes = {};

export default SendEmail;

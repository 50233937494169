import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import starAvatarIcon from 'assets/icons/sup-avatar-star-green.svg';
import userEmptyState from 'assets/icons/icon-user-empty-state.svg';
import Avatar from 'components/avatar';
import { convertToHoursAndMinutes } from 'utils/utils';

const TopResponder = (props) => {
  const { isShowEmptyState, data } = props;
  return (
    <div
      className="card-box-shadow w-full p-5 pb-3 ml-3 mr-3"
      style={{ width: '50%' }}
    >
      <div className="flex items-center justify-between">
        <CustomToolTip
          style={{ width: 'max-content' }}
          tooltipStyle={{
            width: 222,
            top: -97,
            bottom: 'initial',
            left: -25,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="The team member that has responded to incoming enquiries the fastest."
        >
          <h4 className="font-semibold letterspacing24em">Top responder</h4>
        </CustomToolTip>

        <div
          className="border-radius-20 px-3 py-1 text-11 text-white font-semibold letterspacing08em"
          style={{ backgroundColor: '#DE4E83' }}
        >
          Top responder
        </div>
      </div>
      {isShowEmptyState ? (
        <div className="flex items-center mt-6">
          <figure
            className="avatar-container avatar-container--contain"
            style={{
              width: 68,
              height: 68,
              backgroundColor: '#FAF9F8',
              borderWidth: 4,
              borderColor: '#DE4E83'
            }}
          >
            <img src={userEmptyState} />
            <img
              src={starAvatarIcon}
              className="absolute"
              style={{
                width: 'initial',
                height: 'initial',
                top: -14,
                right: -16
              }}
            />
          </figure>
          <div className="ml-4">
            <div className="data-overlay opacity-6"></div>
            <div
              className="data-overlay  mt-2"
              style={{ width: 130, height: 12 }}
            ></div>
          </div>
        </div>
      ) : (
        <div className="flex items-center mt-6">
          <Avatar
            style={{
              width: 68,
              height: 68,
              padding: 4,
              backgroundColor: '#DE4E83'
            }}
            src={data?.user?.avatar}
            initial={data?.user?.name || data?.user?.first_name}
            singleChar
            renderOuterIcon={() => {
              return (
                <img
                  src={starAvatarIcon}
                  className="absolute"
                  style={{
                    width: 'initial',
                    height: 'initial',
                    top: -11,
                    right: -13
                  }}
                />
              );
            }}
          />
          <div className="ml-4">
            <h3 className="font-semibold">
              {data?.user?.name || data?.user?.first_name}
            </h3>
            <p className="text-sm font-medium text-primary mt-1">
              Avg response time of{' '}
              {convertToHoursAndMinutes(data?.respond_time)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopResponder;

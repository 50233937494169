export const handleScroll = (conversation) => {
  let decreaseCount = 60;
  let id = conversation?.results
    ? conversation?.results[conversation?.results?.length - 1]?.id
    : undefined;
  if (id) {
    var scrollDiv = document.getElementById(id)?.offsetTop;
    if (scrollDiv) {
      var conversationBody = document.getElementById('Conversation');
      conversationBody.scrollTo({
        top: scrollDiv - decreaseCount,
        left: 0,
        behavior: 'smooth'
      });
    }
  }
};

/* eslint-disable no-unused-vars */
import CHOICES from 'choices';
import { isEmpty } from 'utils/utils';

const { GBP, EUR, USD, AUD, AED } = CHOICES.CurrencyChoices;

export const cardDesign = (design) => {
  if (typeof design === 'string') {
    const parsedData = JSON.parse(design);
    return parsedData;
  }
  return design;
};

export const checkStageIsEmpty = (stageLeads) => {
  const { new_unread, reply_later, seen_replied } = stageLeads;
  return isEmpty(new_unread) && isEmpty(reply_later) && isEmpty(seen_replied);
};

export const getIdAndType = (leadId) => {
  const [id, key] = leadId.split('_');
  return { id, key };
};

export const getValuePlaceHolder = (text) => {
  switch (text) {
    case GBP:
      return '£ 0.00';
    case EUR:
      return '€ 0.00';
    case USD:
      return '$ 0.00';
    case AUD:
      return 'AU$ 0.00';
    case AED:
      return ' د.إ 0.00';
    default:
      return '0.00';
  }
};

export const getCurrencyText = (text) => {
  switch (text) {
    case GBP:
      return '£';
    case EUR:
      return '€';
    case USD:
      return '$';
    case AUD:
      return 'AU$';
    case AED:
      return 'د.إ';
    default:
      return '';
  }
};

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import QuestionHeader from '../../../../component/questionHeader';
import { ReactComponent as ValueIcon } from 'assets/icons/icon-question-value-small.svg';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AddQuestion,
  setIsShowQuestionForm,
  setIsShowQuestionsToAdd,
  setSelectedQuestion
} from 'slices/questionSlice';
import Select from 'components/inputs/select';
import iconAUD from 'assets/icons/icon-currency-aud.svg';
import iconUSD from 'assets/icons/icon-currency-us-dollar.svg';
import iconEURO from 'assets/icons/icon-currency-euro.svg';
import iconAED from 'assets/icons/icon-currency-aed.svg';
import iconPOUND from 'assets/icons/icon-currency-pound.svg';
import CheckBox from 'components/inputs/checkbox';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { requiredMsg, requiredSelectMsg } from 'utils/messages';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';
import { useSnackbar } from 'components/Snackbar';
import { useAnalytics } from 'use-analytics';

const Value = (props) => {
  const { data, addQuestion } = props;
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { questionsData } = useSelector((state) => state.question);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { VALUE } = CHOICES.QuestionChoices;
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const renderIcon = (value) => {
    switch (value) {
      case CHOICES.CurrencyChoices.GBP:
        return iconPOUND;
      case CHOICES.CurrencyChoices.EUR:
        return iconEURO;
      case CHOICES.CurrencyChoices.USD:
        return iconUSD;
      case CHOICES.CurrencyChoices.AUD:
        return iconAUD;
      case CHOICES.CurrencyChoices.AED:
        return iconAED;
      default:
        return <></>;
    }
  };

  const CurrencyOptions = CHOICES.CurrencyChoices.CHOICE_LIST.map((item) => {
    return {
      label: item.label,
      value: item.value,
      icon: renderIcon(item.value)
    };
  });

  const formSchema = {
    value: string().strict().required(requiredMsg('question')),
    currency: object().nullable().required(requiredSelectMsg('currency'))
  };
  const {
    register,
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { errors, touchedFields },
    reset,
    clearErrors
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const onCancel = () => {
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
  };

  const callback = (data) => {
    track(
      'question_created',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    addQuestion(data);
    // openSuccessSnackBar('Question created');
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const formData = {
      text: data.value,
      position: questionsData.length,
      kind: VALUE,
      choice_set: [
        {
          text: data?.currency?.value,
          position: 0
        }
      ],
      sub_organization: subOrganizationId,
      box: box_id
    };
    dispatch(AddQuestion({ body: formData, callback }));
  };

  return (
    <div className="mt-8 border border-secondary border-radius-10 bg-grey-300 w-full">
      <QuestionHeader label={data.label} renderSvg={() => <ValueIcon />} />
      <form
        className="p-5"
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        <h3 className="text-sm font-semibold">Question</h3>
        <TextInputField
          name="value"
          className="mt-2 input-field--xs-34"
          placeholder="Type your question here"
          register={register}
          error={errors.value && touchedFields.value}
        >
          {errors.value && touchedFields.value && (
            <Error message={errors.value.message} />
          )}
        </TextInputField>
        <div className="mt-5">
          <h3 className="text-15 font-semibold mb-2">Currency options</h3>
          <div style={{ width: '60%' }}>
            <Select
              name="currency"
              isHaveIcon={true}
              options={CurrencyOptions}
              iconStyle={{ width: 28, height: 18, marginRight: 10 }}
              inputClassName="input-field--xs-34"
              dropDownClassName="p-2"
              control={control}
              setValue={setValue}
              selectedIconStyle={{
                position: 'absolute',
                top: 8,
                zIndex: 1,
                left: 8
              }}
              inputStyle={{ paddingLeft: 46 }}
              placeholder="Select currency"
              error={!isEmpty(errors.currency)}
              readOnly={true}
            />
            {errors.currency && <Error message={errors.currency.message} />}
          </div>
        </div>
        <div className="xxxl:flex xxxl:gap-2 mt-8 items-center justify-between">
          <div className="flex gap-2 xxxl:mb-0 mb-5">
            <CheckBox
              name="totalCount"
              small
              style={{ minWidth: 18, minHeight: 18 }}
            />
            <label className="text-13" htmlFor="totalCount">
              This question will represent the stage total count on each stage.
            </label>
          </div>
          <div className="flex justify-end gap-2">
            <CustomColorButton
              label="Cancel"
              className="btn--grey min-w-120 btn--md"
              onClick={onCancel}
            />
            <PrimaryButton
              label="Save"
              className="min-w-120 btn--md"
              enableLoader
              isLoading={isLoading}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Value;

import React, { useRef, useState } from 'react';
import Avatar from 'components/avatar';
import useClickOutside from 'hooks/useOutsideClick';
import ButtonItem from '../buttonItem';
import CHOICES from 'choices';
import { useDispatch, useSelector } from 'react-redux';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as ResendEmailIcon } from 'assets/icons/icon-resend-email-icon.svg';
import { reInviteUser } from 'slices/teamMember/slice';
import { useSnackbar } from 'components/Snackbar';
import pendingIcon from 'assets/icons/icon-pending-sup-icon.svg';
import useGetUserRole from 'hooks/useGetUserRole';

const {
  TEAM_SUPER_ADMIN,
  TEAM_MEMBER,
  TEAM_ADMIN: ADMIN
} = CHOICES.TeamMemberRoleChoice;
const PENDING = CHOICES.OnboardingTeamMemberChoice.PENDING;

const UserItem = (props) => {
  const { user, onUpdateRoles, onDeleteUser } = props;
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const statusDropdownRef = useRef();
  const { openSuccessSnackBar } = useSnackbar();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openStatusDropdown, setOpenStatusDropdown] = useState(false);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const { isTeamMember } = useGetUserRole();
  useClickOutside(() => setOpenDropdown(false), dropdownRef.current);
  useClickOutside(
    () => setOpenStatusDropdown(false),
    statusDropdownRef.current
  );

  const onSelect = (userId, selectedRole) => {
    if (selectedRole === 'Team member') {
      onUpdateRoles(userId, TEAM_MEMBER);
    } else {
      onUpdateRoles(userId, ADMIN);
    }
  };

  const onDelete = (userId) => {
    onDeleteUser(
      userId,
      user?.no_of_leads_assigned_to_me,
      user?.name,
      user?.role
    );
  };

  const callback = () => {
    setIsBtnLoading(false);
    openSuccessSnackBar('re-invitation sent');
  };

  const resendEmail = () => {
    setIsBtnLoading(true);
    const formData = {
      email: user.invitation_email || user.email,
      role: user.role
    };
    dispatch(
      reInviteUser({
        body: formData,
        subOrgId: subOrganizationId,
        OrgUserId: user?.organization_user_id,
        callback: callback
      })
    );
  };

  return (
    <div
      className={`relative ${
        user?.is_disabled
          ? 'user_item-disabled bg-grey-300 cursor-not-allowed'
          : 'user_item'
      }`}
      style={{ marginBottom: 10 }}
    >
      <div className="flex gap-4 relative">
        <Avatar
          src={user.avatar}
          initial={user.name || user.invitation_email || user.email}
          medium42
          singleChar
        />
        {user?.onboarding_status === PENDING && (
          <div
            className="bg-white absolute flex justify-center items-center"
            style={{
              width: 17,
              height: 17,
              borderRadius: '50%',
              bottom: '0px',
              left: '28px'
            }}
          >
            <img
              className="rounded-md"
              width={13}
              height={13}
              src={pendingIcon}
            />
          </div>
        )}
        <div>
          <div className={`flex ${user?.is_disabled && 'text-[#AAAAAA]'}`}>
            <h3 className="font-semibold">{user.name || user.first_name}</h3>
            {user?.onboarding_status === PENDING && (
              <span className="font-semibold text-secondary">Pending</span>
            )}
          </div>
          <p
            className={`text-sm ${
              user?.is_disabled ? 'text-[#AAAAAA]' : 'text-grey-800'
            }`}
          >
            {user.email || user.invitation_email}
          </p>
        </div>
      </div>
      <div className="flex gap-1">
        <ButtonItem
          onClick={
            user?.is_disabled || isTeamMember || user?.role === TEAM_SUPER_ADMIN
              ? () => {}
              : () => setOpenDropdown(true)
          }
          // onClick={
          //   isAdmin || isSuperUser ? () => setOpenDropdown(true) : () => {}
          // }
          dropdownRef={dropdownRef}
          open={openDropdown}
          style={
            user?.is_disabled
              ? {
                  width: 115,
                  cursor: 'not-allowed',
                  backgroundColor: 'rgba(170,170,170,0.16)',
                  color: '#AAAAAA'
                }
              : { width: 115 }
          }
          isStatus={false}
          dropdownStyle={{ width: '100%', right: 0, padding: '4px 7px' }}
          onSelect={onSelect}
          user={user}
          onClose={() => setOpenDropdown(false)}
          isHideDropdown={
            isTeamMember || user?.role === TEAM_SUPER_ADMIN || user?.is_disabled
          }
          disabled={user?.is_disabled}
        />
        {user?.onboarding_status === PENDING ? (
          <CustomColorButtonWithLogo
            label="Resend"
            renderRightSvg={() => <ResendEmailIcon />}
            className={`text-11 text-white gap-2 ${
              user?.is_disabled ? 'bg-[#AAAAAA]' : 'bg-secondary'
            }`}
            style={{ padding: '7px 12px', width: 95 }}
            onClick={resendEmail}
            enableLoader
            isLoading={isBtnLoading}
            disabled={user?.is_disabled}
          />
        ) : (
          <ButtonItem
            onClick={
              myInformation?.id === user?.user_id ||
              isTeamMember ||
              user?.role === TEAM_SUPER_ADMIN
                ? () => {}
                : () => setOpenStatusDropdown(true)
            }
            isHideDropdown={
              myInformation?.id === user?.user_id ||
              isTeamMember ||
              user?.role === TEAM_SUPER_ADMIN
                ? true
                : false
            }
            dropdownRef={statusDropdownRef}
            open={openStatusDropdown}
            selected="Active"
            option="Delete team member"
            isStatus={true}
            style={{ width: 95 }}
            dropdownStyle={{ width: '163px', right: 0, padding: '4px 7px' }}
            user={user}
            onSelect={onDelete}
            onClose={() => setOpenStatusDropdown(false)}
            disabled={user?.is_disabled}
          />
        )}
      </div>
    </div>
  );
};

export default UserItem;

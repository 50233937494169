/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import iconNote from 'assets/icons/icon-note-reply.svg';
import { ReactComponent as IconMore } from 'assets/icons/icon-note-more.svg';
import { ReactComponent as RenameIcon } from 'assets/icons/icon-select-rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-Delete.svg';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsEdit,
  setIsEditText,
  setEditedNote,
  deleteTaskNotes
} from 'slices/noteSlice';
import { setShowInboxActions, setEditorActiveTab } from 'slices/eboxLead';
import { getLeadConversation } from 'slices/conversationSlice';
import { useParams } from 'react-router-dom';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { MentionsInput, Mention } from 'react-mentions';
import classNames from './index.module.css';
import { isEmpty } from 'utils/utils';
import { NOTE } from 'utils/constants';
import AvatarContainer from '../avatarContainer';
import { ReactComponent as AutomateIcon } from 'assets/icons/icon-automated-email-icon.svg';
import CustomToolTip from 'components/CustomTooltip';
import Avatar from 'components/avatar';

const NoteContainer = (props) => {
  const { created_at, data, createdBy, isBin, item, idx } = props;
  const { cardId } = useParams();
  const nodeRef = useRef();
  const dispatch = useDispatch();
  const [showMoreDropdown, setShowMoreDropdown] = useState(false);
  const [showMoreBackgroundColour, setShowMoreBackgroundColour] =
    useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const users = organizationUser?.results?.map((item) => {
    return {
      id: item.first_name,
      display: item.first_name,
      userId: item.user_id
    };
  });

  useClickOutside(() => setShowMoreDropdown(false), nodeRef.current);

  const OnEdit = (item) => {
    dispatch(setIsEdit(true));
    dispatch(setIsEditText(item.description));
    dispatch(setShowInboxActions(false));
    dispatch(setEditorActiveTab(NOTE));
    dispatch(setEditedNote(item.id));
  };

  const callback = () => {
    setOpenDeleteModal(null);
    dispatch(getLeadConversation({ leadId: cardId }));
  };

  const onDelete = () => {
    dispatch(
      deleteTaskNotes({
        noteId: openDeleteModal,
        callback: callback
      })
    );
  };

  return (
    <>
      {!isEmpty(data) && (
        <>
          <DeleteConfirmation
            showConfirmation={openDeleteModal !== null ? true : false}
            onCancel={() => setOpenDeleteModal(null)}
            onConfirm={onDelete}
            title="Are you sure you want to delete this note?"
            description="Once deleted you will not be able to get it back. You will have to
      add it again."
          />
          <div className="flex justify-end gap-1" id={item.id}>
            <div
              className="bg-yellow-200 border-radius-10 flex pr-2 pl-2"
              ref={nodeRef}
              style={{ maxWidth: '80%' }}
            >
              <MentionsInput
                className="mentions"
                value={data?.description}
                classNames={classNames}
                forceSuggestionsAboveCursor={true}
                readOnly
              >
                <Mention
                  trigger="@"
                  data={users}
                  className={classNames.mentions__mention}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
              {/* <div
                className={`${
                  showMoreBackgroundColour ? 'bg-white' : ''
                } rounded-md ml-3 cursor-pointer relative mt-2`}
                style={{ padding: '11px 6px', maxHeight: 26, maxWidth: 28 }}
                onClick={
                  isBin
                    ? () => {}
                    : () => setShowMoreDropdown(!showMoreDropdown)
                }
              >
                <IconMore
                  onMouseOver={() => setShowMoreBackgroundColour(true)}
                  onMouseLeave={() => setShowMoreBackgroundColour(false)}
                />
                <div
                  className={`dropdown-menu dropdown-menu--more ${
                    showMoreDropdown ? 'open' : ''
                  }`}
                  style={
                    idx === 0
                      ? { bottom: 'initial', top: 30 }
                      : { top: 'initial', bottom: 30 }
                  }
                >
                  <div
                    className="dropdown-menu__list gap-3"
                    onClick={() => OnEdit(data)}
                  >
                    <RenameIcon />
                    Edit
                  </div>
                  <div
                    className="dropdown-menu__list gap-3 red-text"
                    onClick={() => setOpenDeleteModal(data.id)}
                  >
                    <DeleteIcon />
                    Delete
                  </div>
                </div>
              </div> */}
            </div>
            <CustomToolTip
              position="left"
              tooltipText={
                item?.sent_by_automation
                  ? 'Automated Note'
                  : createdBy?.first_name
              }
              // renderAvatar={() => (
              //   <Avatar
              //     src={
              //       user?.avatar
              //         ? user.avatar
              //         : user?.customer_avatar
              //         ? user?.customer_avatar
              //         : null
              //     }
              //     initial={user?.first_name}
              //     border={false}
              //     smallest
              //     cover
              //     singleChar
              //     initialStyle={{
              //       fontSize: '14px'
              //     }}
              //   />
              // )}
            >
              <Avatar
                src={
                  createdBy?.avatar
                    ? createdBy.avatar
                    : createdBy?.customer_avatar
                    ? createdBy?.customer_avatar
                    : null
                }
                initial={
                  item?.sent_by_automation ? 'A N' : createdBy?.first_name
                }
                border={false}
                cover
                small
                style={{
                  backgroundColor: item?.sent_by_automation
                    ? 'rgb(83, 186, 192)'
                    : ''
                }}
                singleChar={!item?.sent_by_automation}
              />
            </CustomToolTip>
          </div>
          {item?.sent_by_automation ? (
            <div className="flex mr-9 justify-end mt-1 items-center mb-6">
              <AutomateIcon style={{ marginRight: 5 }} />
              <div className="text-xs text-grey-700 mr-2">Automated Note</div>
              <code
                style={{
                  background: '#C4C2C0',
                  width: '3.5px',
                  height: '3.5px',
                  borderRadius: '50%'
                }}
              ></code>
              <p className="text-xs text-grey-700 ml-1">{created_at}</p>
            </div>
          ) : (
            <div className="flex mr-9 justify-end mt-1 items-center mb-6">
              <img src={iconNote} style={{ width: 10, height: 10 }} />
              <p className="text-xs text-grey-700 ml-1 mr-1">Note</p>
              <code
                style={{
                  width: 4,
                  height: 4,
                  backgroundColor: '#C4C2C0',
                  borderRadius: '50%'
                }}
              ></code>
              <p className="text-xs text-grey-700 ml-1">{created_at}</p>
            </div>
          )}
        </>
      )}
    </>
  );
};

NoteContainer.propTypes = {
  created_at: PropTypes.string
};

export default NoteContainer;

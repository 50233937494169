import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import fileLibraryIcon from 'assets/icons/icon-file-library.svg';
import FileLibrary from 'modules/modals/attachFiles/fileLibrary';
import Upload from 'modules/modals/attachFiles/upload';
import { ReactComponent as ArrowRight } from 'assets/icons/icon-arrow-right.svg';
import UpgradePlanTooltip from '../UpgradePlanTooltip';
import usePlanAccess from 'hooks/usePlanAccess';

const AttachItem = ({ title, onClick, addMargin }) => {
  return (
    <div
      className={addMargin ? 'attach-type-list mb-[15px]' : 'attach-type-list'}
      onClick={onClick}
    >
      <img src={fileLibraryIcon} alt="" />
      <p className="font-semibold ml-5">{title}</p>
      <div className="icon-arrow-container">
        <ArrowRight />
      </div>
    </div>
  );
};

const AttachFiles = ({
  open,
  onClose,
  onUpload,
  uploadedFiles,
  setUploadedFiles
}) => {
  const [showFileLibrary, setShowFileLibrary] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);
  const { has_file_library } = usePlanAccess();

  return (
    <>
      <Modal.Main open={open}>
        <Modal.Header
          title="Attach Files"
          onClose={onClose}
          style={{ zindex: 99 }}
        />
        <Modal.Body className="p-8">
          <UpgradePlanTooltip alignment="right" show={!has_file_library}>
            <AttachItem
              title="From file library"
              onClick={() => {
                if (has_file_library) {
                  setShowFileLibrary(true);
                }
              }}
              addMargin={true}
            />
          </UpgradePlanTooltip>
          <AttachItem
            title="Upload new file"
            onClick={() => setShowFileUpload(true)}
            addMargin={false}
          />
        </Modal.Body>
      </Modal.Main>
      <FileLibrary
        open={showFileLibrary}
        onClose={() => setShowFileLibrary(false)}
        filesList={uploadedFiles}
        onUpload={(isFileLibrary, file) => {
          setShowFileLibrary(false);
          onUpload(isFileLibrary, file);
        }}
      />
      <Upload
        open={showFileUpload}
        onClose={() => setShowFileUpload(false)}
        filesList={uploadedFiles}
        setAlreadyUploadedFiles={setUploadedFiles}
        onUpload={(isFileLibrary, file) => {
          setShowFileUpload(false);
          onUpload(isFileLibrary, file);
        }}
      />
    </>
  );
};

export default AttachFiles;

AttachFiles.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

AttachFiles.defaultProps = {
  open: false
};

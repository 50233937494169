/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { route } from 'utils/route';

const UnprotectedRoute = ({ children }) => {
  const authToken = useSelector((state) => state.auth.authToken);
  const analytics = useSelector((state) => state.segments.analytics);

  useEffect(() => {
    if (!authToken) {
      if (
        localStorage.getItem('__user_id') ||
        localStorage.getItem('ajs_user_id')
      ) {
        if (analytics?.reset) {
          analytics.reset();
        }
      }
    }
  }, [analytics]);

  return !authToken ? children : <Navigate to={`${route.dashboard}`} />;
};

UnprotectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object
  ]).isRequired
};

export default UnprotectedRoute;

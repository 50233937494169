/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { ReactComponent as IconTeamPerformance } from 'assets/icons/icon-team-report-header.svg';
import ReportHeader from '../component/reportHeader';
import NavLink from '../component/navLink';
import { Route, Routes } from 'react-router-dom';
import TeamPerformanceReport from 'modules/enquirybox/reports/modules/report/teamperformancereport';
import { useDispatch } from 'react-redux';
import { setTempDateFormat } from 'slices/reports/slice';
import Wrapper from '../wrapper';
import usePlanAccess from 'hooks/usePlanAccess';

const TeamReport = () => {
  const dispatch = useDispatch();
  const { has_team_performance_report } = usePlanAccess();
  useEffect(() => {
    dispatch(setTempDateFormat('This month to date'));
  }, []);

  return (
    <section className="dashboard__main">
      <ReportHeader
        renderSvg={() => <IconTeamPerformance />}
        title="Performance report"
      />
      <div
        className="dashboard__main__body"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <div className="dashboard__main__body__section-left">
          <NavLink isTeamReport={true} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Routes>
            <Route
              path="/team-performance"
              element={
                <Wrapper showUpgradeTopbar={!has_team_performance_report}>
                  <TeamPerformanceReport />
                </Wrapper>
              }
            />
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default TeamReport;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import ConnectedChannelTab from '../settingsTab';
import { useDispatch, useSelector } from 'react-redux';
import CHOICES from 'choices';
import {
  deletefbLeadAdsFormMapping,
  getFacebookLeadAdsForms,
  hideGuideForFbLeadAds,
  setFbLeadAdsForms,
  syncNewFbLeadAdsForm,
  updatefbLeadAdsFormStatus
} from 'slices/automationStuffSlice';
import { useParams } from 'react-router-dom';
import { ReactComponent as IconSync } from 'assets/icons/icon-sync.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import FormItem from '../automations/components/formItem';
import { isEmpty } from 'utils/utils';
import { setMyInformation } from 'slices/myInfoSlice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import EditForm from './components/editForm';
import { useSnackbar } from 'components/Snackbar';

const { FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const Forms = (props) => {
  const { channelType } = props;
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { fbLeadAdsForms } = useSelector((state) => state.automationStuff);
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [showMapFields, setShowMapFields] = useState(false);
  const [formId, setFormId] = useState(null);
  const [selectedForm, setSelectedForm] = useState();

  const { activeLead } = useSelector((state) => state.leadConversation);

  useEffect(() => {
    if (channelType === FACEBOOK_LEAD_ADS) {
      dispatch(getFacebookLeadAdsForms({ channelId: channelId }));
    }
  }, [channelId]);

  const handleStatus = (evt, selectedItem) => {
    dispatch(
      updatefbLeadAdsFormStatus({
        channelId: channelId,
        formId: selectedItem.id,
        is_connected: evt.target.checked
      })
    );
    if (!isEmpty(fbLeadAdsForms)) {
      const updatedfbLeadAdsForms = fbLeadAdsForms.map((item, index) => {
        if (item?.id === selectedItem.id) {
          return { ...item, is_connected: evt.target.checked };
        }
        return item;
      });
      dispatch(setFbLeadAdsForms([...updatedfbLeadAdsForms]));
    }
  };

  const handleDeleteForm = (id) => {
    setShowDeleteConfirm(id);
  };

  const onDeleteCallback = () => {
    openSuccessSnackBar('Deleted successfully', 'large');
    dispatch(getFacebookLeadAdsForms({ channelId: channelId }));
    setIsDeleteLoading(false);
    setShowDeleteConfirm(null);
  };

  const confirmDelete = () => {
    setIsDeleteLoading(true);
    dispatch(
      deletefbLeadAdsFormMapping({
        channelId: channelId,
        formId: showDeleteConfirm,
        isDeleteForm: false,
        mapping_format: {},
        callback: onDeleteCallback
      })
    );
  };

  const callback = (data) => {
    dispatch(setMyInformation(data));
  };

  const onHideGuide = () => {
    dispatch(
      hideGuideForFbLeadAds({
        userId: myInformation?.id,
        isHideGuide: true,
        callback
      })
    );
  };

  const syncCallback = () => {
    setIsLoading(false);
  };

  const onSync = () => {
    setIsLoading(true);
    dispatch(
      syncNewFbLeadAdsForm({
        subOrgId: subOrganizationId,
        channelId: channelId,
        callback: syncCallback
      })
    );
  };

  const onEdit = (item) => {
    setFormId(item.id);
    setSelectedForm(item);
    setShowMapFields(true);
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDeleteConfirm === null ? false : true}
        onCancel={() => {
          setShowDeleteConfirm(null);
        }}
        title="Are you sure you want to delete this form mapping?"
        onConfirm={confirmDelete}
        isLoading={isDeleteLoading}
      />
      <EditForm
        showMapFields={showMapFields}
        activeLead={activeLead}
        onClose={() => setShowMapFields(false)}
        formId={formId}
        selectedForm={selectedForm}
      />
      <div
        className="h-full w-full"
        style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}
      >
        {/* <ConnectedChannelTab activeTab={2} channelType={channelType} /> */}
        <div className="py-6 pr-6 pl-8">
          {!myInformation?.is_fb_lead_ads_setup_guide_viewed && (
            <div
              className="flex justify-between py-3 pr-4 pl-6 rounded-lg"
              style={{ background: 'rgba(217, 217, 217, 0.26)' }}
            >
              <p className="text-sm">
                All Lead Ad forms in your account are synced below. You can turn
                a Lead form off if you do not want to receive enquiries from it.
                If you create a new form in your account and want to list it
                below, click the Sync new forms button.{' '}
                <span className="text-primary">
                  To learn, more read our setup guide
                </span>
              </p>
              <span
                className="text-primary text-sm font-medium ml-8 cursor-pointer"
                onClick={onHideGuide}
              >
                Hide
              </span>
            </div>
          )}
          <CustomColorButtonWithLogo
            label="Sync new forms"
            renderSvg={() => <IconSync style={{ marginRight: 6 }} />}
            className="btn--sm text-white mt-5"
            style={{ background: '#4267B2' }}
            onClick={onSync}
            loaderInline
            isLoading={isLoading}
          />
          <div className="mt-5">
            {fbLeadAdsForms.map((item) => {
              const { name, is_connected } = item;
              return (
                <FormItem
                  key={item.id}
                  item={item}
                  title={name}
                  status={is_connected}
                  handleStatus={handleStatus}
                  handleDeleteForm={handleDeleteForm}
                  onEdit={onEdit}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Forms;

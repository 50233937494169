// DON'T EDIT. THIS FILE IS GENERATED BY
// ./manage.py export_choices
// CHANGE MADES MANUALLY TO THIS FILE WILL BE LOST
// backend/contribs/management/commands/export_choices.py
const CHOICES = {
  AIToneChoices: {
    CHOICES: {
      1: 'Friendly and Professional',
      2: 'Helpful and Technical',
      3: 'Casual and Humorous',
      4: 'Kind and Supportive'
    },
    CHOICE_LIST: [
      {
        label: 'Friendly and Professional',
        value: 1
      },
      {
        label: 'Helpful and Technical',
        value: 2
      },
      {
        label: 'Casual and Humorous',
        value: 3
      },
      {
        label: 'Kind and Supportive',
        value: 4
      }
    ],
    HUMOROUS: 3,
    PROFESSIONAL: 1,
    SUPPORTIVE: 4,
    TECHNICAL: 2
  },
  AddSourceChoices: {
    CHOICES: {
      1: 'Facebook',
      2: 'Google',
      3: 'Google My Business',
      4: 'Instagram',
      5: 'Twitter',
      6: 'Facebook Lead Ads',
      7: 'Manual',
      8: 'Direct Traffic',
      9: 'SMS',
      10: 'Zapier',
      11: 'Whatsapp'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'Google My Business',
        value: 3
      },
      {
        label: 'Instagram',
        value: 4
      },
      {
        label: 'Twitter',
        value: 5
      },
      {
        label: 'Facebook Lead Ads',
        value: 6
      },
      {
        label: 'Manual',
        value: 7
      },
      {
        label: 'Direct Traffic',
        value: 8
      },
      {
        label: 'SMS',
        value: 9
      },
      {
        label: 'Zapier',
        value: 10
      },
      {
        label: 'Whatsapp',
        value: 11
      }
    ],
    DirectTraffic: 8,
    Facebook: 1,
    FacebookLeadAds: 6,
    Google: 2,
    GoogleMyBusiness: 3,
    Instagram: 4,
    Manual: 7,
    SMS: 9,
    Twitter: 5,
    Zapier: 10,
    whatsapp: 11
  },
  AutomationChoice: {
    ASSIGN_MEMBER: 2,
    AUTORESPONDER_EMAIL: 1,
    CHOICES: {
      1: 'AUTORESPONDER_EMAIL',
      2: 'ASSIGN_MEMBER',
      3: 'MOVE_TO_BOX',
      4: 'FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER',
      5: 'DELAY_TIMER',
      6: 'SMS',
      7: 'WHATSAPP'
    },
    CHOICE_LIST: [
      {
        label: 'AUTORESPONDER_EMAIL',
        value: 1
      },
      {
        label: 'ASSIGN_MEMBER',
        value: 2
      },
      {
        label: 'MOVE_TO_BOX',
        value: 3
      },
      {
        label: 'FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER',
        value: 4
      },
      {
        label: 'DELAY_TIMER',
        value: 5
      },
      {
        label: 'SMS',
        value: 6
      },
      {
        label: 'WHATSAPP',
        value: 7
      }
    ],
    DELAY_TIMER: 5,
    FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER: 4,
    MOVE_TO_BOX: 3,
    SMS: 6,
    WHATSAPP: 7
  },
  BotPlacement: {
    CENTER: 3,
    CHOICES: {
      1: 'Left',
      2: 'Right',
      3: 'Center'
    },
    CHOICE_LIST: [
      {
        label: 'Left',
        value: 1
      },
      {
        label: 'Right',
        value: 2
      },
      {
        label: 'Center',
        value: 3
      }
    ],
    LEFT: 1,
    RIGHT: 2
  },
  BotVersionStatusChoices: {
    CHOICES: {
      1: 'DRAFT',
      2: 'READY_TO_PUBLISH',
      3: 'PUBLISHED'
    },
    CHOICE_LIST: [
      {
        label: 'DRAFT',
        value: 1
      },
      {
        label: 'READY_TO_PUBLISH',
        value: 2
      },
      {
        label: 'PUBLISHED',
        value: 3
      }
    ],
    DRAFT: 1,
    PUBLISHED: 3,
    READY_TO_PUBLISH: 2
  },
  BoxAutomationChoice: {
    ASSIGN_MEMBER: 2,
    AUTORESPONDER_EMAIL: 1,
    CHOICES: {
      1: 'AUTORESPONDER_EMAIL',
      2: 'ASSIGN_MEMBER',
      3: 'MOVE_TO_BOX',
      4: 'FACEBOOK',
      5: 'DELAY_TIMER',
      6: 'INSTAGRAM',
      7: 'SMS',
      8: 'NOTES',
      9: 'TASK',
      10: 'WHATSAPP'
    },
    CHOICE_LIST: [
      {
        label: 'AUTORESPONDER_EMAIL',
        value: 1
      },
      {
        label: 'ASSIGN_MEMBER',
        value: 2
      },
      {
        label: 'MOVE_TO_BOX',
        value: 3
      },
      {
        label: 'FACEBOOK',
        value: 4
      },
      {
        label: 'DELAY_TIMER',
        value: 5
      },
      {
        label: 'INSTAGRAM',
        value: 6
      },
      {
        label: 'SMS',
        value: 7
      },
      {
        label: 'NOTES',
        value: 8
      },
      {
        label: 'TASK',
        value: 9
      },
      {
        label: 'WHATSAPP',
        value: 10
      }
    ],
    DELAY_TIMER: 5,
    FACEBOOK: 4,
    INSTAGRAM: 6,
    MOVE_TO_BOX: 3,
    NOTES: 8,
    SMS: 7,
    TASK: 9,
    WHATSAPP: 10
  },
  BoxType: {
    CHOICES: {
      1: 'Sales',
      2: 'General',
      3: 'Custom'
    },
    CHOICE_LIST: [
      {
        label: 'Sales',
        value: 1
      },
      {
        label: 'General',
        value: 2
      },
      {
        label: 'Custom',
        value: 3
      }
    ],
    CUSTOM: 3,
    GENERAL: 2,
    SALES: 1
  },
  ChannelChoices: {
    AI_CHAT_BOT: 11,
    CHOICES: {
      1: 'Facebook',
      2: 'Instagram',
      3: 'WhatsApp',
      4: 'Webform',
      5: 'Facebook lead ads',
      6: 'Zapier',
      7: 'Sms',
      8: 'Ebot',
      9: 'Google business reviews',
      10: 'Website chat bot',
      11: 'AI chat bot'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Instagram',
        value: 2
      },
      {
        label: 'WhatsApp',
        value: 3
      },
      {
        label: 'Webform',
        value: 4
      },
      {
        label: 'Facebook lead ads',
        value: 5
      },
      {
        label: 'Zapier',
        value: 6
      },
      {
        label: 'Sms',
        value: 7
      },
      {
        label: 'Ebot',
        value: 8
      },
      {
        label: 'Google business reviews',
        value: 9
      },
      {
        label: 'Website chat bot',
        value: 10
      },
      {
        label: 'AI chat bot',
        value: 11
      }
    ],
    EBOT: 8,
    FACEBOOK: 1,
    FACEBOOK_LEAD_ADS: 5,
    GOOGLE_BUSINESS_REVIEWS: 9,
    INSTAGRAM: 2,
    SMS: 7,
    WEBFORM: 4,
    WEBSITE_CHAT_BOT: 10,
    WHATSAPP: 3,
    ZAPIER: 6
  },
  CountryChoices: {
    CHOICES: {
      1: 'United Kingdom'
    },
    CHOICE_LIST: [
      {
        label: 'United Kingdom',
        value: 1
      }
    ],
    UNITED_KINGDOM: 1
  },
  CurrencyChoice: {
    AUD: 4,
    CHOICES: {
      1: 'GBP',
      2: 'EUR',
      3: 'USD',
      4: 'AUD'
    },
    CHOICE_LIST: [
      {
        label: 'GBP',
        value: 1
      },
      {
        label: 'EUR',
        value: 2
      },
      {
        label: 'USD',
        value: 3
      },
      {
        label: 'AUD',
        value: 4
      }
    ],
    EUR: 2,
    GBP: 1,
    USD: 3
  },
  CurrencyChoices: {
    CHOICES: {
      1: 'GBP',
      2: 'USD',
      3: 'EURO'
    },
    CHOICE_LIST: [
      {
        label: 'GBP',
        value: 1
      },
      {
        label: 'USD',
        value: 2
      },
      {
        label: 'EURO',
        value: 3
      }
    ],
    EURO: 3,
    GBP: 1,
    USD: 2
  },
  DelayChoices: {
    CHOICES: {
      1: 'Minutes',
      2: 'Days',
      3: 'Hours'
    },
    CHOICE_LIST: [
      {
        label: 'Minutes',
        value: 1
      },
      {
        label: 'Days',
        value: 2
      },
      {
        label: 'Hours',
        value: 3
      }
    ],
    DAYS: 2,
    HOURS: 3,
    MINUTE: 1
  },
  DeviceChoices: {
    ANDROID: 2,
    CHOICES: {
      1: 'Ios',
      2: 'Android',
      3: 'Web'
    },
    CHOICE_LIST: [
      {
        label: 'Ios',
        value: 1
      },
      {
        label: 'Android',
        value: 2
      },
      {
        label: 'Web',
        value: 3
      }
    ],
    IOS: 1,
    WEB: 3
  },
  EBotStatus: {
    ARCHIVED: 5,
    CHOICES: {
      1: 'Draft',
      2: 'Review',
      3: 'Declined',
      4: 'Published',
      5: 'Archived',
      6: 'Deleted'
    },
    CHOICE_LIST: [
      {
        label: 'Draft',
        value: 1
      },
      {
        label: 'Review',
        value: 2
      },
      {
        label: 'Declined',
        value: 3
      },
      {
        label: 'Published',
        value: 4
      },
      {
        label: 'Archived',
        value: 5
      },
      {
        label: 'Deleted',
        value: 6
      }
    ],
    DECLINED: 3,
    DELETED: 6,
    DRAFT: 1,
    PUBLISHED: 4,
    REVIEW: 2
  },
  EmailSignatureChoice: {
    CHOICES: {
      1: 'Default',
      2: 'Custom'
    },
    CHOICE_LIST: [
      {
        label: 'Default',
        value: 1
      },
      {
        label: 'Custom',
        value: 2
      }
    ],
    CUSTOM: 2,
    DEFAULT: 1
  },
  EmailType: {
    ACCOUNT_CONFIRMATION_EMAIL: 3,
    CHOICES: {
      1: 'LEAD_REPLY',
      2: 'INVITE_EMAIL',
      3: 'ACCOUNT_CONFIRMATION_EMAIL',
      4: 'FORGOT_EMAIL',
      5: 'CSV_SEND',
      6: 'FACEBOOK_INCOMING_MESSAGE',
      7: 'NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED',
      8: 'SENDING_TASK_DUE_EMAIL_REMINDER',
      9: 'NEW_WEBFORM_ENQUIRY_ARRIVED',
      10: 'NEW_SMS_ENQUIRY_ARRIVED',
      11: 'NEW_BOT_ENQUIRY_ARRIVED',
      12: 'NEW_ZAPIER_ENQUIRY_ARRIVED',
      13: 'SIMPLE_MAIL_FROM_TO_USER'
    },
    CHOICE_LIST: [
      {
        label: 'LEAD_REPLY',
        value: 1
      },
      {
        label: 'INVITE_EMAIL',
        value: 2
      },
      {
        label: 'ACCOUNT_CONFIRMATION_EMAIL',
        value: 3
      },
      {
        label: 'FORGOT_EMAIL',
        value: 4
      },
      {
        label: 'CSV_SEND',
        value: 5
      },
      {
        label: 'FACEBOOK_INCOMING_MESSAGE',
        value: 6
      },
      {
        label: 'NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED',
        value: 7
      },
      {
        label: 'SENDING_TASK_DUE_EMAIL_REMINDER',
        value: 8
      },
      {
        label: 'NEW_WEBFORM_ENQUIRY_ARRIVED',
        value: 9
      },
      {
        label: 'NEW_SMS_ENQUIRY_ARRIVED',
        value: 10
      },
      {
        label: 'NEW_BOT_ENQUIRY_ARRIVED',
        value: 11
      },
      {
        label: 'NEW_ZAPIER_ENQUIRY_ARRIVED',
        value: 12
      },
      {
        label: 'SIMPLE_MAIL_FROM_TO_USER',
        value: 13
      }
    ],
    CSV_SEND: 5,
    FACEBOOK_INCOMING_MESSAGE: 6,
    FORGOT_EMAIL: 4,
    INVITE_EMAIL: 2,
    LEAD_REPLY: 1,
    NEW_BOT_ENQUIRY_ARRIVED: 11,
    NEW_FACEBOOK_LEAD_ADS_ENQUIRY_ARRIVED: 7,
    NEW_SMS_ENQUIRY_ARRIVED: 10,
    NEW_WEBFORM_ENQUIRY_ARRIVED: 9,
    NEW_ZAPIER_ENQUIRY_ARRIVED: 12,
    SENDING_TASK_DUE_EMAIL_REMINDER: 8,
    SIMPLE_MAIL_FROM_TO_USER: 13
  },
  EmailVeririfedThroughChoice: {
    CHOICES: {
      1: 'Ses',
      2: 'Google',
      3: 'Microsoft'
    },
    CHOICE_LIST: [
      {
        label: 'Ses',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'Microsoft',
        value: 3
      }
    ],
    GOOGLE: 2,
    MICROSOFT: 3,
    SES: 1
  },
  FbAttachmentTypeChoices: {
    AUDIO: 'audio',
    CHOICES: {
      'application/pdf': 'Pdf',
      audio: 'Audio',
      file: 'File',
      gif: 'Gif',
      image: 'Image',
      'image/gif': 'Gif',
      'image/jpeg': 'Image',
      'text/csv': 'Csv',
      video: 'Video',
      'video/mp4': 'Video'
    },
    CHOICE_LIST: [
      {
        label: 'Pdf',
        value: 'application/pdf'
      },
      {
        label: 'Audio',
        value: 'audio'
      },
      {
        label: 'File',
        value: 'file'
      },
      {
        label: 'Gif',
        value: 'gif'
      },
      {
        label: 'Image',
        value: 'image'
      },
      {
        label: 'Gif',
        value: 'image/gif'
      },
      {
        label: 'Image',
        value: 'image/jpeg'
      },
      {
        label: 'Csv',
        value: 'text/csv'
      },
      {
        label: 'Video',
        value: 'video'
      },
      {
        label: 'Video',
        value: 'video/mp4'
      }
    ],
    FILE: 'file',
    FILE_1: 'application/pdf',
    FILE_2: 'text/csv',
    GIF: 'gif',
    GIF_1: 'image/gif',
    IMAGE: 'image',
    IMAGE_1: 'image/jpeg',
    VIDEO: 'video',
    VIDEO_1: 'video/mp4'
  },
  FbPermissionStatusChoices: {
    CHOICES: {
      declined: 'Declined',
      expired: 'Expired',
      granted: 'Granted'
    },
    CHOICE_LIST: [
      {
        label: 'Declined',
        value: 'declined'
      },
      {
        label: 'Expired',
        value: 'expired'
      },
      {
        label: 'Granted',
        value: 'granted'
      }
    ],
    DECLINED: 'declined',
    EXPIRED: 'expired',
    GRANTED: 'granted'
  },
  FileUploadingChoices: {
    CHOICES: {
      1: 'Profile photo',
      2: 'File library',
      3: 'Email outgoing',
      4: 'Email incoming',
      5: 'Webform',
      6: 'Facebook incoming',
      7: 'Lead import from csv',
      8: 'Instagram story',
      9: 'zapier_files',
      10: 'Whatsapp_files'
    },
    CHOICE_LIST: [
      {
        label: 'Profile photo',
        value: 1
      },
      {
        label: 'File library',
        value: 2
      },
      {
        label: 'Email outgoing',
        value: 3
      },
      {
        label: 'Email incoming',
        value: 4
      },
      {
        label: 'Webform',
        value: 5
      },
      {
        label: 'Facebook incoming',
        value: 6
      },
      {
        label: 'Lead import from csv',
        value: 7
      },
      {
        label: 'Instagram story',
        value: 8
      },
      {
        label: 'zapier_files',
        value: 9
      },
      {
        label: 'Whatsapp_files',
        value: 10
      }
    ],
    EMAIL_INCOMING: 4,
    EMAIL_OUTGOING: 3,
    FACEBOOK_INCOMING: 6,
    FILE_LIBRARY: 2,
    INSTAGRAM_STORY: 8,
    LEAD_IMPORT_FROM_CSV: 7,
    PROFILE_PHOTO: 1,
    WEBFORM: 5,
    WHATSAPP_FILES: 10,
    ZAPIER_FILES: 9
  },
  FrontendRedirectChoice: {
    BUSINESS_INFO: 2,
    CHOICES: {
      1: 'Dashboard',
      2: 'Business info',
      3: 'Login screen',
      4: 'Create new password'
    },
    CHOICE_LIST: [
      {
        label: 'Dashboard',
        value: 1
      },
      {
        label: 'Business info',
        value: 2
      },
      {
        label: 'Login screen',
        value: 3
      },
      {
        label: 'Create new password',
        value: 4
      }
    ],
    CREATE_NEW_PASSWORD_SCREEN: 4,
    DASHBOARD: 1,
    LOGIN_SCREEN: 3
  },
  IndustryChoices: {
    CHOICES: {
      1: 'Dental',
      2: 'Golf',
      3: 'Leisure',
      4: 'Hotel',
      5: 'Wedding',
      6: 'Cosmetic & Aesthetic',
      7: 'IT Services',
      8: 'Other'
    },
    CHOICE_LIST: [
      {
        label: 'Dental',
        value: 1
      },
      {
        label: 'Golf',
        value: 2
      },
      {
        label: 'Leisure',
        value: 3
      },
      {
        label: 'Hotel',
        value: 4
      },
      {
        label: 'Wedding',
        value: 5
      },
      {
        label: 'Cosmetic & Aesthetic',
        value: 6
      },
      {
        label: 'IT Services',
        value: 7
      },
      {
        label: 'Other',
        value: 8
      }
    ],
    COSMETIC_AND_AESTHETIC: 6,
    DENTAL: 1,
    GOLF: 2,
    HOTEL: 4,
    IT_SERVICES: 7,
    LEISURE: 3,
    OTHER: 8,
    WEDDING: 5
  },
  LeadChannelChoice: {
    AI_CHAT_BOT: 12,
    CHOICES: {
      1: 'Facebook',
      2: 'Instagram',
      3: 'Manually added',
      4: 'Web Forms',
      5: 'Facebook Lead Ads',
      6: 'Imported from file',
      7: 'SMS',
      8: 'Zapier',
      9: 'Ebot',
      10: 'Whatsapp',
      11: 'Website chat bot',
      12: 'AI chat bot'
    },
    CHOICE_LIST: [
      {
        label: 'Facebook',
        value: 1
      },
      {
        label: 'Instagram',
        value: 2
      },
      {
        label: 'Manually added',
        value: 3
      },
      {
        label: 'Web Forms',
        value: 4
      },
      {
        label: 'Facebook Lead Ads',
        value: 5
      },
      {
        label: 'Imported from file',
        value: 6
      },
      {
        label: 'SMS',
        value: 7
      },
      {
        label: 'Zapier',
        value: 8
      },
      {
        label: 'Ebot',
        value: 9
      },
      {
        label: 'Whatsapp',
        value: 10
      },
      {
        label: 'Website chat bot',
        value: 11
      },
      {
        label: 'AI chat bot',
        value: 12
      }
    ],
    EBOT: 9,
    FACEBOOK: 1,
    FACEBOOK_LEADGEN: 5,
    IMPORTED_FROM_FILE: 6,
    INSTAGRAM: 2,
    MANUALLY_ADDED: 3,
    SMS: 7,
    WEBSITE_CHAT_BOT: 11,
    WEB_FORMS: 4,
    WHATSAPP: 10,
    ZAPIER: 8
  },
  LeadReplyChoice: {
    CHOICES: {
      1: 'Normal reply',
      2: 'Note Reply',
      3: 'Just to store data',
      4: 'task reply',
      5: 'Email Reply',
      6: 'SMS Reply',
      7: 'Ig story mention',
      8: 'Ig story reply',
      9: 'Whatsapp reply',
      10: 'Whatsapp default reply'
    },
    CHOICE_LIST: [
      {
        label: 'Normal reply',
        value: 1
      },
      {
        label: 'Note Reply',
        value: 2
      },
      {
        label: 'Just to store data',
        value: 3
      },
      {
        label: 'task reply',
        value: 4
      },
      {
        label: 'Email Reply',
        value: 5
      },
      {
        label: 'SMS Reply',
        value: 6
      },
      {
        label: 'Ig story mention',
        value: 7
      },
      {
        label: 'Ig story reply',
        value: 8
      },
      {
        label: 'Whatsapp reply',
        value: 9
      },
      {
        label: 'Whatsapp default reply',
        value: 10
      }
    ],
    EMAIL_REPLY: 5,
    IG_STORY_MENTION: 7,
    IG_STORY_REPLY: 8,
    JUST_TO_STORE_DATA: 3,
    NORMAL_REPLY: 1,
    NOTE_REPLY: 2,
    SMS_REPLY: 6,
    TASK_REPLY: 4,
    WHATSAPP_DEFAULT_REPLY: 10,
    WHATSAPP_REPLY: 9
  },
  LeadStatusChoices: {
    CHOICES: {
      1: 'New',
      2: 'Viewed',
      3: 'Reply Later',
      4: 'Moved to Bin',
      5: 'RESTORED',
      6: 'Reply sent',
      7: 'Task added',
      8: 'Manually added',
      9: 'Note added',
      10: 'Move to box'
    },
    CHOICE_LIST: [
      {
        label: 'New',
        value: 1
      },
      {
        label: 'Viewed',
        value: 2
      },
      {
        label: 'Reply Later',
        value: 3
      },
      {
        label: 'Moved to Bin',
        value: 4
      },
      {
        label: 'RESTORED',
        value: 5
      },
      {
        label: 'Reply sent',
        value: 6
      },
      {
        label: 'Task added',
        value: 7
      },
      {
        label: 'Manually added',
        value: 8
      },
      {
        label: 'Note added',
        value: 9
      },
      {
        label: 'Move to box',
        value: 10
      }
    ],
    MANUALLY_ADDED: 8,
    MOVED_TO_BIN: 4,
    MOVE_TO_BOX: 10,
    NEW: 1,
    NOTE_ADDED: 9,
    REPLY_LATER: 3,
    REPLY_SENT: 6,
    RESTORED: 5,
    TASK_ADDED: 7,
    VIEWED: 2
  },
  MessageStatus: {
    CHOICES: {
      1: 'Sent',
      2: 'Queued',
      3: 'Delivered',
      4: 'FAILED',
      5: 'Seen'
    },
    CHOICE_LIST: [
      {
        label: 'Sent',
        value: 1
      },
      {
        label: 'Queued',
        value: 2
      },
      {
        label: 'Delivered',
        value: 3
      },
      {
        label: 'FAILED',
        value: 4
      },
      {
        label: 'Seen',
        value: 5
      }
    ],
    DELIVERED: 3,
    FAILED: 4,
    QUEUED: 2,
    SEEN: 5,
    SENT: 1
  },
  OnboardingTeamMemberChoice: {
    ACCEPTED: 1,
    CHOICES: {
      1: 'Accepted',
      2: 'Pending',
      3: 'Default'
    },
    CHOICE_LIST: [
      {
        label: 'Accepted',
        value: 1
      },
      {
        label: 'Pending',
        value: 2
      },
      {
        label: 'Default',
        value: 3
      }
    ],
    DEFAULT: 3,
    PENDING: 2
  },
  OptionCategory: {
    BUTTON: 2,
    CHOICES: {
      0: 'Text',
      1: 'Image',
      2: 'Button',
      3: 'Others'
    },
    CHOICE_LIST: [
      {
        label: 'Text',
        value: 0
      },
      {
        label: 'Image',
        value: 1
      },
      {
        label: 'Button',
        value: 2
      },
      {
        label: 'Others',
        value: 3
      }
    ],
    IMAGE: 1,
    OTHERS: 3,
    TEXT: 0
  },
  PlanType: {
    CHOICES: {
      1: 'Free Trial',
      2: 'Starter',
      3: 'Pro'
    },
    CHOICE_LIST: [
      {
        label: 'Free Trial',
        value: 1
      },
      {
        label: 'Starter',
        value: 2
      },
      {
        label: 'Pro',
        value: 3
      }
    ],
    FREE_TRIAL: 1,
    PRO: 3,
    STARTER: 2
  },
  QuestionChoices: {
    CHOICES: {
      1: 'Text',
      2: 'Dropdown',
      3: 'Date',
      4: 'Multiple Options',
      5: 'Value',
      6: 'MCQ_MUTIPLE_SELECT',
      7: 'DATE_RANGE'
    },
    CHOICE_LIST: [
      {
        label: 'Text',
        value: 1
      },
      {
        label: 'Dropdown',
        value: 2
      },
      {
        label: 'Date',
        value: 3
      },
      {
        label: 'Multiple Options',
        value: 4
      },
      {
        label: 'Value',
        value: 5
      },
      {
        label: 'MCQ_MUTIPLE_SELECT',
        value: 6
      },
      {
        label: 'DATE_RANGE',
        value: 7
      }
    ],
    DATETIME: 3,
    DATE_RANGE: 7,
    DROPDOWN: 2,
    MCQ: 4,
    MCQ_MUTIPLE_SELECT: 6,
    TEXT: 1,
    VALUE: 5
  },
  ReplySource: {
    AUTOMATION: 1,
    CHOICES: {
      1: 'Automation',
      2: 'Email'
    },
    CHOICE_LIST: [
      {
        label: 'Automation',
        value: 1
      },
      {
        label: 'Email',
        value: 2
      }
    ],
    EMAIL: 2
  },
  ReplyTemplateChoice: {
    CHOICES: {
      1: 'Email',
      2: 'SMS',
      3: 'Facebook and Instagram',
      4: 'Whatsapp'
    },
    CHOICE_LIST: [
      {
        label: 'Email',
        value: 1
      },
      {
        label: 'SMS',
        value: 2
      },
      {
        label: 'Facebook and Instagram',
        value: 3
      },
      {
        label: 'Whatsapp',
        value: 4
      }
    ],
    EMAIL: 1,
    FACEBOOK_AND_INSTAGRAM: 3,
    SMS: 2,
    WHATSAPP: 4
  },
  ReportType: {
    CHOICES: {
      1: 'INCOMING_REPORT',
      2: 'SALES_REPORT',
      3: 'SOURCE_REPORT'
    },
    CHOICE_LIST: [
      {
        label: 'INCOMING_REPORT',
        value: 1
      },
      {
        label: 'SALES_REPORT',
        value: 2
      },
      {
        label: 'SOURCE_REPORT',
        value: 3
      }
    ],
    INCOMING_SALES_REPORT: 1,
    SALES_REPORT: 2,
    SOURCE_REPORT: 3
  },
  ResponseLengthChoices: {
    CHOICES: {
      1: 'Short and Concise',
      2: 'Medium and Descriptive',
      3: 'Long and Detailed'
    },
    CHOICE_LIST: [
      {
        label: 'Short and Concise',
        value: 1
      },
      {
        label: 'Medium and Descriptive',
        value: 2
      },
      {
        label: 'Long and Detailed',
        value: 3
      }
    ],
    LONG: 3,
    MEDIUM: 2,
    SHORT: 1
  },
  SEND_EMAIL: {
    CHOICES: {
      1: 'SES'
    },
    CHOICE_LIST: [
      {
        label: 'SES',
        value: 1
      }
    ],
    SES: 1
  },
  SignupChoice: {
    CHOICES: {
      1: 'Email',
      2: 'Google',
      3: 'microsoft'
    },
    CHOICE_LIST: [
      {
        label: 'Email',
        value: 1
      },
      {
        label: 'Google',
        value: 2
      },
      {
        label: 'microsoft',
        value: 3
      }
    ],
    EMAIL: 1,
    GOOGLE: 2,
    MICROSOFT: 3
  },
  StageKind: {
    CHOICES: {
      1: 'Incoming',
      2: 'Contacting',
      3: 'Converted Sale',
      4: 'Lost',
      5: 'Waiting on Decision',
      6: 'Custom',
      7: 'Suitable',
      8: 'Not Suitable',
      9: 'Contacted',
      10: 'Interview arranged',
      11: 'First Round',
      12: 'Second Round',
      13: 'Final Round',
      14: 'Dealing With',
      15: 'Completed',
      16: 'Waiting on Customer'
    },
    CHOICE_LIST: [
      {
        label: 'Incoming',
        value: 1
      },
      {
        label: 'Contacting',
        value: 2
      },
      {
        label: 'Converted Sale',
        value: 3
      },
      {
        label: 'Lost',
        value: 4
      },
      {
        label: 'Waiting on Decision',
        value: 5
      },
      {
        label: 'Custom',
        value: 6
      },
      {
        label: 'Suitable',
        value: 7
      },
      {
        label: 'Not Suitable',
        value: 8
      },
      {
        label: 'Contacted',
        value: 9
      },
      {
        label: 'Interview arranged',
        value: 10
      },
      {
        label: 'First Round',
        value: 11
      },
      {
        label: 'Second Round',
        value: 12
      },
      {
        label: 'Final Round',
        value: 13
      },
      {
        label: 'Dealing With',
        value: 14
      },
      {
        label: 'Completed',
        value: 15
      },
      {
        label: 'Waiting on Customer',
        value: 16
      }
    ],
    COMPLETED: 15,
    CONTACTED: 9,
    CONTACTING: 2,
    CONVERTED_SALE: 3,
    CUSTOM: 6,
    DEALING_WITH: 14,
    FINAL_ROUND: 13,
    FIRST_ROUND: 11,
    INCOMING: 1,
    INTERVIEW_ARRANGED: 10,
    LOST: 4,
    NOT_SUITABLE: 8,
    SECOND_ROUND: 12,
    SUITABLE: 7,
    WAITING_ON_CUSTOMER: 16,
    WAITING_ON_DECISION: 5
  },
  SubscriptionStatusChoices: {
    ACTIVE: 1,
    CANCELLED: 2,
    CHOICES: {
      1: 'Active',
      2: 'Cancelled',
      3: 'Pending'
    },
    CHOICE_LIST: [
      {
        label: 'Active',
        value: 1
      },
      {
        label: 'Cancelled',
        value: 2
      },
      {
        label: 'Pending',
        value: 3
      }
    ],
    PENDING: 3
  },
  SystemNotificationTypeChoices: {
    CHOICES: {
      1: 'New Lead',
      2: 'Task Added',
      3: 'Task Reminder',
      4: 'Notes Added',
      5: 'Lead Assigned',
      6: 'Reply Received',
      7: 'Sign up invitation accepted'
    },
    CHOICE_LIST: [
      {
        label: 'New Lead',
        value: 1
      },
      {
        label: 'Task Added',
        value: 2
      },
      {
        label: 'Task Reminder',
        value: 3
      },
      {
        label: 'Notes Added',
        value: 4
      },
      {
        label: 'Lead Assigned',
        value: 5
      },
      {
        label: 'Reply Received',
        value: 6
      },
      {
        label: 'Sign up invitation accepted',
        value: 7
      }
    ],
    LEAD_ASSIGNED: 5,
    NEW_LEAD: 1,
    NOTES_ADDED: 4,
    REPLY_RECEIVED: 6,
    SIGN_UP_INVITATION_ACCEPTED: 7,
    TASK_ADDED: 2,
    TASK_REMINDER: 3
  },
  TaskDueChoices: {
    CHOICES: {
      1: 'Due Immediately',
      2: 'Due in days'
    },
    CHOICE_LIST: [
      {
        label: 'Due Immediately',
        value: 1
      },
      {
        label: 'Due in days',
        value: 2
      }
    ],
    DUE_IMMEDIATELY: 1,
    DUE_IN_DAYS: 2
  },
  TaskLeadApiFilterChoices: {
    CHOICES: {
      DUE_TODAY: 'DUE_TODAY',
      OVERDUE: 'OVERDUE'
    },
    CHOICE_LIST: [
      {
        label: 'DUE_TODAY',
        value: 'DUE_TODAY'
      },
      {
        label: 'OVERDUE',
        value: 'OVERDUE'
      }
    ],
    DUE_TODAY: 'DUE_TODAY',
    OVERDUE: 'OVERDUE'
  },
  TaskStatusChoices: {
    CHOICES: {
      1: 'New',
      2: 'Completed'
    },
    CHOICE_LIST: [
      {
        label: 'New',
        value: 1
      },
      {
        label: 'Completed',
        value: 2
      }
    ],
    COMPLETED: 2,
    NEW: 1
  },
  TasksTypeChoices: {
    CALL: 1,
    CHOICES: {
      1: 'Call',
      2: 'Follow up',
      3: 'Others'
    },
    CHOICE_LIST: [
      {
        label: 'Call',
        value: 1
      },
      {
        label: 'Follow up',
        value: 2
      },
      {
        label: 'Others',
        value: 3
      }
    ],
    FOLLOW_UP: 2,
    OTHERS: 3
  },
  TeamMemberRoleChoice: {
    CHOICES: {
      1: 'Team admin',
      2: 'Team member',
      3: 'Team super admin'
    },
    CHOICE_LIST: [
      {
        label: 'Team admin',
        value: 1
      },
      {
        label: 'Team member',
        value: 2
      },
      {
        label: 'Team super admin',
        value: 3
      }
    ],
    TEAM_ADMIN: 1,
    TEAM_MEMBER: 2,
    TEAM_SUPER_ADMIN: 3
  },
  TeamSizeChoice: {
    CHOICES: {
      1: '1-5 Employees',
      2: '6-10 Employees',
      3: '11-20 Employees',
      4: 'Greater than 20 Employees'
    },
    CHOICE_LIST: [
      {
        label: '1-5 Employees',
        value: 1
      },
      {
        label: '6-10 Employees',
        value: 2
      },
      {
        label: '11-20 Employees',
        value: 3
      },
      {
        label: 'Greater than 20 Employees',
        value: 4
      }
    ],
    FROM_11_20: 3,
    FROM_1_5: 1,
    FROM_20_100: 4,
    FROM_6_10: 2
  },
  TitleChoices: {
    ASSIGNED: 5,
    AUTOMATED_REPLY_SENT: 7,
    CHOICES: {
      1: 'New Enquiry',
      2: 'Reply Received',
      3: 'Note Added',
      4: 'Reminder',
      5: 'Assigned',
      6: 'Moved To Box',
      7: 'Automated Reply Sent',
      8: 'Reply Sent',
      9: 'Moved To Bin',
      10: 'Deleted From Bin',
      11: 'Task Added',
      12: 'Task Completed',
      13: 'Label Added',
      14: 'Label Removed',
      15: 'Unassigned',
      16: 'Restored',
      17: 'Failure'
    },
    CHOICE_LIST: [
      {
        label: 'New Enquiry',
        value: 1
      },
      {
        label: 'Reply Received',
        value: 2
      },
      {
        label: 'Note Added',
        value: 3
      },
      {
        label: 'Reminder',
        value: 4
      },
      {
        label: 'Assigned',
        value: 5
      },
      {
        label: 'Moved To Box',
        value: 6
      },
      {
        label: 'Automated Reply Sent',
        value: 7
      },
      {
        label: 'Reply Sent',
        value: 8
      },
      {
        label: 'Moved To Bin',
        value: 9
      },
      {
        label: 'Deleted From Bin',
        value: 10
      },
      {
        label: 'Task Added',
        value: 11
      },
      {
        label: 'Task Completed',
        value: 12
      },
      {
        label: 'Label Added',
        value: 13
      },
      {
        label: 'Label Removed',
        value: 14
      },
      {
        label: 'Unassigned',
        value: 15
      },
      {
        label: 'Restored',
        value: 16
      },
      {
        label: 'Failure',
        value: 17
      }
    ],
    DELETED_FROM_BIN: 10,
    FAILURE: 17,
    LABEL_ADDED: 13,
    LABEL_REMOVED: 14,
    MOVED_TO_BIN: 9,
    MOVED_TO_STAGE: 6,
    NEW_ENQUIRY: 1,
    NOTE_ADDED: 3,
    REMINDER: 4,
    REPLY_RECEIVED: 2,
    REPLY_SENT: 8,
    RESTORED: 16,
    TASK_ADDED: 11,
    TASK_COMPLETED: 12,
    UNASSIGNED: 15
  },
  TypeOfMesChoices: {
    CHOICES: {
      1: 'New lead created',
      2: 'Mention in Lead',
      3: 'Lead Assigned',
      4: 'New reply from facebook',
      5: 'New reply from instagram',
      6: 'New reply from sms',
      7: 'New reply from email',
      8: 'Task assigned',
      9: 'Mentioned in the task',
      10: 'Mentioned in the task comment',
      11: 'Task reminder',
      12: 'Mentioned in the note has been updated',
      13: 'Mentioned in the note comment has been updated',
      17: 'New lead created from facebook',
      18: 'New lead created from instagram',
      19: 'New lead created from Web forms',
      20: 'New lead created from fb leadgen'
    },
    CHOICE_LIST: [
      {
        label: 'New lead created',
        value: 1
      },
      {
        label: 'Mention in Lead',
        value: 2
      },
      {
        label: 'Lead Assigned',
        value: 3
      },
      {
        label: 'New reply from facebook',
        value: 4
      },
      {
        label: 'New reply from instagram',
        value: 5
      },
      {
        label: 'New reply from sms',
        value: 6
      },
      {
        label: 'New reply from email',
        value: 7
      },
      {
        label: 'Task assigned',
        value: 8
      },
      {
        label: 'Mentioned in the task',
        value: 9
      },
      {
        label: 'Mentioned in the task comment',
        value: 10
      },
      {
        label: 'Task reminder',
        value: 11
      },
      {
        label: 'Mentioned in the note has been updated',
        value: 12
      },
      {
        label: 'Mentioned in the note comment has been updated',
        value: 13
      },
      {
        label: 'New lead created from facebook',
        value: 17
      },
      {
        label: 'New lead created from instagram',
        value: 18
      },
      {
        label: 'New lead created from Web forms',
        value: 19
      },
      {
        label: 'New lead created from fb leadgen',
        value: 20
      }
    ],
    LEAD_ASSIGNED: 3,
    LEAD_CREATED: 1,
    LEAD_CREATED_FROM_FACEBOOK: 17,
    LEAD_CREATED_FROM_FB_LEADGEN: 20,
    LEAD_CREATED_FROM_FORM: 19,
    LEAD_CREATED_FROM_INSTAGRAM: 18,
    MENTION_IN_LEAD: 2,
    NEW_REPLY_FROM_EMAIL: 7,
    NEW_REPLY_FROM_FACEBOOK: 4,
    NEW_REPLY_FROM_INSTAGRAM: 5,
    NEW_REPLY_FROM_SMS: 6,
    NOTE_COMMENT_HAS_BEEN_MENTIONED_UPDATE: 13,
    NOTE_HAS_BEEN_MENTIONED_UPDATE: 12,
    TASK_ASSIGNED: 8,
    TASK_COMMENT_HAS_BEEN_MENTIONED: 10,
    TASK_HAS_BEEN_MENTIONED: 9,
    TASK_REMINDER: 11
  },
  URLAccessChoices: {
    ALLOWED_URL: 2,
    ALLOW_ALL: 0,
    BLOCKED_URL: 1,
    CHOICES: {
      0: 'Show the bot on all pages',
      1: "Don't show the bot on specific pages",
      2: 'Only show the bot on specific pages'
    },
    CHOICE_LIST: [
      {
        label: 'Show the bot on all pages',
        value: 0
      },
      {
        label: "Don't show the bot on specific pages",
        value: 1
      },
      {
        label: 'Only show the bot on specific pages',
        value: 2
      }
    ]
  },
  UserTypeChoice: {
    ADMIN: 1,
    CHOICES: {
      1: 'Admin',
      2: 'Team Member'
    },
    CHOICE_LIST: [
      {
        label: 'Admin',
        value: 1
      },
      {
        label: 'Team Member',
        value: 2
      }
    ],
    TEAM_MEMBER: 2
  }
};
export default CHOICES;

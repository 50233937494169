import React from 'react';
import { Routes, Route } from 'react-router-dom';
// import EbBoxChannelLinks from 'modules/enquirybox/ebox/module/channel/channelLinks';
import EmptyState from 'modules/enquirybox/components/emptyState';
import Facebook from 'modules/enquirybox/channels/module/settings/channel/facebook';
import Instagram from 'modules/enquirybox/channels/module/settings/channel/instagram';
import FacebookLeads from 'modules/enquirybox/channels/module/settings/channel/facebookAds';
import Webform from 'modules/enquirybox/channels/module/settings/channel/webform';
import WhatsApp from 'modules/enquirybox/channels/module/settings/channel/whatsApp';
import SMS from 'modules/enquirybox/channels/module/settings/channel/sms';
import EmptyChannelConnection from 'modules/boxes/lead/channelConnection';

const Welcome = () => {
  return (
    <EmptyState
      title="Welcome to your Enquirybox"
      description=" Start connecting the channels that you receive enquiries from to get all your
          enquiries into your enquirybox 😎"
    />
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="dashboard__main__body__section-right layout-1">
      {children}
    </div>
  );
};

const EbBoxChannels = () => {
  return (
    <>
      <div className="dashboard__main__body__section-left overflow-overlay">
        <EmptyChannelConnection />
        {/* <EbBoxChannelLinks /> */}
      </div>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route
          path="/facebook/*"
          element={
            <Wrapper>
              <Facebook />
            </Wrapper>
          }
        />
        <Route
          path="/instagram/*"
          element={
            <Wrapper>
              <Instagram />
            </Wrapper>
          }
        />
        <Route
          path="/facebook-leads/*"
          element={
            <Wrapper>
              <FacebookLeads />
            </Wrapper>
          }
        />
        <Route
          path="/webforms/*"
          element={
            <Wrapper>
              <Webform isEbox={true} />
            </Wrapper>
          }
        />
        <Route
          path="/whatsApp/*"
          element={
            <Wrapper>
              <WhatsApp />
            </Wrapper>
          }
        />
        <Route
          path="/sms/*"
          element={
            <Wrapper>
              <SMS />
            </Wrapper>
          }
        />
      </Routes>
    </>
  );
};

export default EbBoxChannels;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Select from 'components/inputs/select';
import Avatar from 'components/avatar';
import { isEmpty } from 'utils/utils';
import { setThen } from 'slices/boxAutomation/slice';
import { useDispatch } from 'react-redux';
import { setAutomationErrors } from 'slices/boxAutomation/slice';
import ErrorMessage from 'components/errorMessage';
import { omit } from 'lodash';

const AssignTeamMember = ({ condition, index }) => {
  const dispatch = useDispatch();
  const [assignedUserInfo, setAssignedUserInfo] = useState(null);
  const { then, selectedAutomation, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const errors = automationErrors[index];
  const { organizationUser } = useSelector((state) => state.organizationUser);

  // when exiting automation is selected setting its value
  useEffect(() => {
    if (condition.data.assign_user) {
      setAssignedUserInfo({
        label: condition.data.assign_user?.name,
        value: condition.data.assign_user?.id,
        avatarPic: condition.data.assign_user?.avatar
      });
    }
  }, [selectedAutomation]);

  const users = organizationUser?.results
    ?.filter((i) => !i?.is_disabled)
    ?.map((item) => {
      return {
        label: item?.name,
        value: item?.user_id,
        avatarPic: item?.avatar
      };
    });

  const onSelectUser = (option) => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.assign_user) {
      let result = omit(errorObj[index], ['assign_user']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    const currentThen = then[index];
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        assign_user: option
      }
    };
    automationThen.splice(index, 1, updatedData);
    setAssignedUserInfo(option);
    dispatch(setThen(automationThen));
  };

  return (
    <div className="relative mt-4">
      <form autoComplete="off">
        <Select
          name="automation-then"
          options={users}
          renderIcon={() => (
            <Avatar
              className="mr-2"
              src={assignedUserInfo?.avatarPic}
              border={false}
              initial={assignedUserInfo?.label}
              initialStyle={{ fontSize: 'smaller' }}
              smallest={true}
              full
            />
          )}
          onChange={(value) => onSelectUser(value)}
          dropDownClassName="p-2"
          inputClassName="input-field--md pl-10"
          style={!isEmpty(assignedUserInfo) ? { paddingLeft: '55px' } : {}}
          isAvatarSelect={true}
          value={assignedUserInfo?.label}
          selectedValue={assignedUserInfo}
          isShowUserDetails={true}
        />
      </form>
      {errors?.assign_user && <ErrorMessage message="Please select user" />}
    </div>
  );
};

export default AssignTeamMember;

import Avatar from 'components/avatar';
import TeamPerformanceState from 'modules/enquirybox/components/reportsConditionalState/TeamPerformanceState';
import React from 'react';
import { numberFormat } from 'utils/number';
import { convertToHoursAndMinutes, isEmpty } from 'utils/utils';

const TeamMemberResponseTable = (props) => {
  const { data, isPublic = false } = props;
  return (
    <div className="card-box px-5 mt-8 relative">
      <table className="table-reports">
        <thead
          style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}
        >
          <tr>
            <td>Team members</td>
            <td className="">Avg respone time</td>
            <td className="">Total sales</td>
          </tr>
        </thead>
        {isEmpty(data) ? (
          <TeamPerformanceState />
        ) : (
          <tbody className={`${isPublic ? 'card-box-report-sales' : ''}`}>
            {!isEmpty(data) &&
              data.map((item, index) => {
                return (
                  <tr
                    key={index}
                    style={{
                      display: 'table',
                      width: '100%',
                      tableLayout: 'fixed'
                    }}
                  >
                    <td>
                      <div className="flex items-center">
                        <Avatar
                          smaller
                          src={item?.user?.avatar}
                          initial={item?.user?.name || item?.user?.first_name}
                          singleChar
                        />
                        {/* <figure
                          className="avatar-container"
                          style={{ width: 28, height: 28 }}
                        >
                          <img src={item?.user?.avatar} />
                        </figure> */}
                        <h5 className="text-sm font-semibold ml-3">
                          {item?.user?.name || item?.user?.first_name}
                        </h5>
                      </div>
                    </td>
                    <td className="text-sm font-medium">
                      {convertToHoursAndMinutes(item.average_time)}
                    </td>
                    <td className="text-sm font-bold">
                      £{numberFormat(item.total_sales, 2)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default TeamMemberResponseTable;

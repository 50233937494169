import React, { useRef } from 'react';
import TaskItem from '../taskItem';
import Styles from '../../module/feed/index.module.scss';

const RenderDueTomorrowTask = ({ data }) => {
  const nodeRef = useRef();

  return (
    <div className="relative">
      <div className={Styles.header__sm}>
        <span
          className="bg-grey-300"
          style={{
            padding: '2px 9px',
            borderRadius: 5,
            position: 'relative',
            zIndex: 9
          }}
        >
          Due Tomorrow
        </span>
      </div>
      <div className="mt-6">
        <div className="flex">
          <div className="team-chat-feed__right-side ml-2">
            <div className={`flex justify-between team-chat-feed__task`}>
              <div className="text-sm font-semibold text-grey-800">
                Tasks due tomorrow
              </div>
            </div>
            <div className="py-3 px-3" ref={nodeRef}>
              {data.map((item) => {
                return (
                  <TaskItem
                    key={item.id}
                    description={item.note}
                    dueDate={item.due_date}
                    leadId={item.lead}
                    id={item.id}
                    taskDue={3}
                    channelType={item.channel_type}
                    url={item.view_it}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RenderDueTomorrowTask;

/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setMessageDispalayed } from 'slices/ai-assistant/assistant/slice';

const Typewriter = React.forwardRef((props, bubbleRef) => {
  const {
    text,
    delay,
    disableText,
    setDisableText,
    singleMessage,
    noOfElement,
    idx,
    setIsPrinting,
    autoScroll
  } = props;
  const initialBlinking = text.length % 2 === 0 ? false : true;
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [blinking, setBlinking] = useState(initialBlinking);
  const dispatch = useDispatch();
  const { isShowTestBotPopup } = useSelector((state) => state.assistant);

  useEffect(() => {
    autoScroll();
  }, [bubbleRef?.current?.scrollHeight]);

  useEffect(() => {
    if (currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setBlinking(!blinking);
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);

        if (currentIndex === text.length - 1 && singleMessage) {
          if (!isShowTestBotPopup) {
            setDisableText(!disableText);
          }
          setBlinking(false);
          dispatch(setMessageDispalayed(true));
        } else if (currentIndex === text.length - 1 && !singleMessage) {
          setIsPrinting(idx + 1);
          if (idx === noOfElement - 1) {
            if (!isShowTestBotPopup) {
              setDisableText(!disableText);
            }
            setBlinking(false);
            setIsPrinting(0);
            dispatch(setMessageDispalayed(true));
          }
        }
      }, delay);

      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  return (
    <>
      <div style={{ display: 'flex' }}>
        <div
          dangerouslySetInnerHTML={{
            __html: `${currentText}${
              blinking
                ? `<span style='border-left: 2px solid black; margin-left: 2px' id='cursor'>
      </span>`
                : ''
            }`
          }}
        />
      </div>
    </>
  );
});

export default Typewriter;

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import CHOICES from 'choices';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';

const { FREE_TRIAL, STARTER, PRO } = CHOICES.PlanType;
// This custom hook is to know what feature are allowed to the organization.

const usePlanAccess = () => {
  const { currentPlan } = useSelector((state) => state.subscription);

  let feature = {
    has_box_report: false,
    has_card_design: false,
    has_custom_enquiry_form: false,
    has_file_library: false,
    has_reply_templates: false,
    has_sales_report: false,
    has_source_report: false,
    has_tasks: false,
    has_team_chat: false,
    has_team_performance_report: false,
    has_unlimited_automation: false,
    has_unlimited_boxes: false,
    has_unlimited_channel_connections: false,
    has_unlimited_enquiries: false,
    has_unlimited_user: false,
    is_free_plan: false,
    is_starter_plan: false,
    is_pro_plan: false
  };

  const planFeature = useMemo(() => {
    if (!isEmpty(currentPlan)) {
      const { plan } = currentPlan;
      feature.has_box_report = plan?.box_report;
      feature.has_card_design = plan?.card_design;
      feature.has_custom_enquiry_form = plan?.custom_enquiry_form;
      feature.has_file_library = plan?.file_library;
      feature.has_reply_templates = plan?.reply_templates;
      feature.has_sales_report = plan?.sales_report;
      feature.has_source_report = plan?.source_report;
      feature.has_tasks = plan?.tasks;
      feature.has_team_chat = plan?.team_chat;
      feature.has_team_performance_report = plan?.team_performance_report;
      feature.has_unlimited_automation = plan?.unlimited_automation;
      feature.has_unlimited_boxes = plan?.unlimited_boxes;
      feature.has_unlimited_channel_connections =
        plan?.unlimited_channel_connections;
      feature.has_unlimited_user = plan?.unlimited_user;
      feature.is_free_plan = plan?.plan_type == FREE_TRIAL;
      feature.is_starter_plan = plan?.plan_type == STARTER;
      feature.is_pro_plan = plan?.plan_type == PRO;
    }
    return feature;
  }, [currentPlan]);

  return planFeature;
};

export default usePlanAccess;

import { toast } from 'react-toastify';

const getConfig = (config) => ({
  theme: 'colored',
  ...config
});

export const InfoToast = (message, config = { className: 'infoToast' }) => {
  let configurations = { className: 'infoToast', toastId: message, ...config };
  return toast.info(message, getConfig(configurations));
};

export const SuccessToast = (
  message,
  config = { className: 'successToast' }
) => {
  let configurations = {
    className: 'successToast',
    toastId: message,
    ...config
  };
  return toast.success(message, getConfig(configurations));
};

export const WarningToast = (
  message,
  config = { className: 'warningToast' }
) => {
  let configurations = {
    className: 'warningToast',
    toastId: message,
    ...config
  };
  return toast.warn(message, getConfig(configurations));
};

export const ErrorToast = (message, config = { className: 'errorToast' }) => {
  let configurations = { className: 'errorToast', toastId: message, ...config };
  return toast.error(message, getConfig(configurations));
};

import React from 'react';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch } from 'react-redux';
import { setTypeofAutomation, setOnEdit } from 'slices/automationStuffSlice';
import choices from 'choices';

const MoveEnquiriesToBox = () => {
  const dispatch = useDispatch();
  return (
    <div
      className={`box-settings__automation-template__automation-stuff mt-4  py-3 px-3 flex justify-between items-center`}
    >
      <div className="font-semibold text-base">
        Move enquiries directly to a box
      </div>
      <PrimaryButton
        className="btn--xs  flex items-center"
        label="setup"
        onClick={() => {
          dispatch(setTypeofAutomation(choices.AutomationChoice.MOVE_TO_BOX));
          dispatch(setOnEdit({}));
        }}
      />
    </div>
  );
};

export default MoveEnquiriesToBox;

import { isEmpty } from 'utils/utils';

const Options = ({ options, handleStartConversation, history }) => {
  return (
    <div className="flex items-center flex-wrap mt-5">
      {!isEmpty(options)
        ? options.map((option, index) => {
            return (
              <div
                key={index}
                className={`chat-bot-list__bubble chat-bot-list__bubble--normal-msg ${
                  !isEmpty(history) ? '!pointer-events-none' : ''
                }`}
                disabled={!isEmpty(history)}
                onClick={() => {
                  if (isEmpty(history)) {
                    handleStartConversation(option?.description);
                  }
                }}
              >
                {option?.description}
              </div>
            );
          })
        : []}
    </div>
  );
};

export default Options;

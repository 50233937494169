import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import OpenEye from 'assets/icons/eye-open.svg';
import CloseEye from 'assets/icons/eye-close.svg';
import { ReactComponent as LockIcon } from 'assets/icons/icon-lock.svg';

const PasswordInput = (props) => {
  const {
    inputClassName,
    className,
    placeholder,
    error,
    name,
    register,
    required,
    ...rest
  } = props;

  const [showIcon, setShowIcon] = useState(false);
  const isError = error ? 'error' : '';

  return (
    <div className={`${styles.password_field} ${className}`}>
      <span className={styles.eye} onClick={() => setShowIcon(!showIcon)}>
        {showIcon ? (
          <img data-testid="open-eye" src={OpenEye} alt="open-eye" />
        ) : (
          <img data-testid="close-eye" src={CloseEye} alt="close-eye" />
        )}
      </span>
      <input
        name={name}
        data-testid="password-field"
        type={showIcon ? 'text' : 'password'}
        className={`input-field input-field--icon-pos-left input-field--icon-pos-right ${isError} ${inputClassName}`}
        placeholder={placeholder}
        {...register(name, { required })}
        {...rest}
      />
      <div className="input-svg-wrapper">
        <LockIcon />
      </div>
    </div>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool
};

PasswordInput.defaultProps = {
  className: '',
  inputClassName: '',
  placeholder: 'Enter Password',
  required: false,
  register: () => {}
};

export default PasswordInput;

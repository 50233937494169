/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SideNav from 'modules/dashboard/sideNav';
import EnquiryBin from 'modules/enquiryBin/route';
import NavBar from 'components/navigation/navbar';

const EnquiryBinRoutes = () => {
  return (
    <>
      <NavBar />
      <SideNav />
      <Routes>
        <Route path="/*" element={<EnquiryBin />} />
      </Routes>
    </>
  );
};

export default EnquiryBinRoutes;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import TaskDate from './date';
import TaskTime from './time';
import TaskAssignUser from './assignUser';
import TaskType from './call';
import { getLeadData, setActiveLead } from 'slices/conversationSlice';
import {
  addLeadTask,
  updateLeadTask,
  setEditableTaskId,
  resetTaskStore,
  setAllNewTasks
} from 'slices/task/slice';
import {
  getLeadTasks,
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads,
  setActiveNewLead
} from 'slices/eboxLead';
import { getAllTasks, getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';
import { REPLY, TASK } from 'utils/constants';
import { setEditorActiveTab, setShowInboxActions } from 'slices/eboxLead';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
import { formatDateTime, getTimeIn24Format } from 'utils/dateFormat';
import { useSnackbar } from 'components/Snackbar';
import PrimaryButton from 'components/buttons/primary';
import { getLeadConversation } from 'slices/conversationSlice';
import { changeLeadStatus } from 'slices/eboxLead';
import choices from 'choices';
import CustomToolTip from 'components/CustomTooltip';
import { route } from 'utils/route';
import CustomColorButton from 'components/buttons/customColorButton';

const REPLY_LATER = choices.LeadStatusChoices.REPLY_LATER;
const VIEWED = choices.LeadStatusChoices.VIEWED;

const { NEW } = choices.LeadStatusChoices;

const TaskFooter = ({ autoScroll }) => {
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { activeNewLead } = useSelector((state) => state.leads);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { activeTab } = useSelector((state) => state.leadsFilter);
  const {
    taskDate,
    taskTime,
    assignUser,
    taskTitle,
    editableTaskId,
    taskType,
    allNewTasks
  } = useSelector((state) => state.tasks);
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  // const updateLeadStatusIfInReplyLater = async () => {
  //   if (activeLead.status === REPLY_LATER) {
  //     const data = {
  //       lead: cardId,
  //       is_current: true,
  //       status: VIEWED
  //     };
  //     dispatch(setActiveLead({ ...activeLead, status: VIEWED }));
  //     await dispatch(changeLeadStatus({ body: data }));
  //   }
  // };

  const onError = () => {
    setIsLoading(false);
  };
  const scrollDown = () => {
    setTimeout(() => {
      animateScroll.scrollToBottom({
        containerId: 'Conversation',
        smooth: true,
        duration: 0
      });
    }, 100);
  };

  const onSubmit = () => {
    const DUE_DATE = `${formatDateTime(
      taskDate,
      'yyyy-mm-dd'
    )} ${getTimeIn24Format(taskTime)}`;
    let payload = {
      lead: cardId,
      created_by: myInformation.id,
      due_date: DUE_DATE,
      note: taskTitle,
      task_type: taskType,
      sub_organization: subOrganizationId,
      assigned_to: myInformation.id
    };
    if (!isEmpty(assignUser)) {
      payload = {
        ...payload,
        assigned_to: assignUser.user_id
      };
    }
    setIsLoading(true);
    if (!editableTaskId) {
      dispatch(
        addLeadTask({ ...payload, is_reply_sent: true }, onTaskSuccess, onError)
      );
    } else {
      const data = {
        taskId: editableTaskId,
        data: payload
      };
      dispatch(updateLeadTask(data, onTaskUpdate, onError));
    }
  };

  // const getAllConversations = () => {
  //   dispatch(getLeadConversation({ leadId: cardId }, () => scrollDown()));
  // };

  const resetTasks = () => {
    dispatch(resetTaskStore());
  };

  const onTaskUpdate = (data) => {
    // getAllConversations();

    // update the task to local state
    const updatedLeadTask = allNewTasks.map((item) => {
      if (item.id === data.id) {
        return {
          ...data
        };
      }
      return item;
    });
    dispatch(setAllNewTasks(updatedLeadTask));
    // calling lead data api if the overdue count is more than 0
    if (activeLead?.no_of_overdue_task_count > 0) {
      dispatch(getLeadData({ leadId: cardId }));
    }
    dispatch(setEditableTaskId(null));
    setIsLoading(false);
    // updateLeadStatus();
    dispatch(getAllTasks());
    dispatch(getTaskEnquiryCount());
    // dispatch(setEditorActiveTab(REPLY));
    // dispatch(setShowInboxActions(true));
    openSuccessSnackBar('Task updated');
    resetTasks();
  };

  // const updateLeadStatus = () => {
  //   if (!isBox) {
  //     if (VIEWED === activeLead?.status) {
  //       dispatch(getReadLeads());
  //     }
  //     if (REPLY_LATER === activeLead?.status) {
  //       dispatch(getReplyLaterLeads());
  //       //updating the status even after calling the reply later api status is not updating
  //     }
  //     if (activeNewLead === activeLead?.id) {
  //       dispatch(setActiveNewLead());
  //       dispatch(getUnReadLeads());
  //       dispatch(getReadLeads());
  //     }
  //     if (activeTab !== TASK) {
  //       navigate(`${route.dashboard}${route.enquirybox}/eb-box`);
  //     }
  //   }
  // };

  const onTaskSuccess = (data) => {
    // add the new added task to local state
    const updateLeadTask = [data, ...allNewTasks];
    dispatch(setAllNewTasks(updateLeadTask));
    dispatch(getLeadData({ leadId: cardId }));
    // updateLeadStatus();
    // updateLeadStatusIfInReplyLater();
    setIsLoading(false);
    dispatch(getAllTasks());
    dispatch(getTaskEnquiryCount());
    // dispatch(setEditorActiveTab(REPLY));
    openSuccessSnackBar('Task added');
    // dispatch(setShowInboxActions(true));
    // getAllConversations();
    resetTasks();
  };

  return (
    <div className="chat-message-footer__footer flex items-center justify-between">
      <div className="flex items-center gap-2">
        <TaskDate />
        <TaskTime />
        <TaskAssignUser />
        <TaskType />
      </div>
      <div className="flex">
        {editableTaskId && (
          <CustomColorButton
            label="Cancel"
            style={{ padding: '4px 10px', borderRadius: 6 }}
            className="btn btn--grey text-sm mr-2"
            onClick={() => {
              dispatch(resetTaskStore());
            }}
          />
        )}
        <PrimaryButton
          label={!editableTaskId ? 'Add task' : 'Update task'}
          className="text-sm text-white"
          onClick={onSubmit}
          style={{ padding: '4px 10px', borderRadius: 6 }}
          enableLoader={true}
          isLoading={isLoading}
          loaderInline
          disabled={isEmpty(taskTitle)}
        />
      </div>
      {/* <button
        className="btn btn--primary text-sm text-white"
        style={{ padding: '4px 10px', borderRadius: 6 }}
        onClick={onSubmit}
      >
        Add task
      </button> */}
    </div>
  );
};

export default TaskFooter;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Settings from 'modules/enquirybox/channels/module/settings';
import EnquiryboxHome from 'modules/enquirybox/home';

const Enquirybox = () => {
  return (
    <Routes>
      <Route path="/*" element={<EnquiryboxHome />} />
      <Route path="/settings/*" element={<Settings />} />
    </Routes>
  );
};

export default Enquirybox;

import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { formatDateTime } from 'utils/dateFormat';
import { isEmpty } from 'utils/utils';
import InfoIconComponent from '../../components/InfoIcon';

const ListQna = () => {
  const navigate = useNavigate();

  const qnaData = useSelector((state) => state.qna.qnaData);

  return (
    <div className="mt-9">
      <h3 className="text-lg font-semibold">Learned ✨</h3>
      <p className="text-grey-800 mt-3">
        Click on the question name to view or edit the learned information
      </p>
      <table className="table-basic-info mt-8">
        <thead>
          <tr>
            <td>Questions & Answers ({qnaData?.length})</td>
            <td style={{ width: '130px' }}>
              <span>Times used</span>
              <InfoIconComponent
                tooltipText="Times used"
                tooltipStyle={{ top: '-6px' }}
                inofIconStyle={{
                  marginTop: '5.5px',
                  marginLeft: '7px'
                }}
              />
            </td>
            <td style={{ width: '110px' }}>Last updated</td>
          </tr>
        </thead>
        <tbody>
          {qnaData?.map((qna, index) => {
            return (
              <tr key={index}>
                <td>
                  <a onClick={() => navigate(qna.id)} className="link-text">
                    {qna.question}
                  </a>{' '}
                  {!qna.is_embedded && (
                    <span style={{ color: '#4C5254' }}>( learning... )</span>
                  )}
                </td>
                <td className="text-center">{qna?.times_used}</td>
                <td>
                  {!isEmpty(qna.last_edited_on) &&
                    formatDateTime(qna?.last_edited_on, 'mmm dd, yyyy')}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ListQna;

import React, { useEffect } from 'react';
import DeleteChannel from 'modules/enquirybox/channels/module/settings/channel/deleteChannel';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedChannelById } from 'slices/channelsSlice';
import Reconnect from './component/reconnect';
import { isEmpty } from 'utils/utils';

const Settings = (props) => {
  const { channelType, onReconnect, isLoading } = props;
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { connectedChannelById } = useSelector((state) => state.channels);

  useEffect(() => {
    dispatch(
      getConnectedChannelById({
        subOrgId: subOrganizationId,
        channelId: channelId
      })
    );
  }, [channelId]);

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/channels');
  };

  return (
    <div className="w-full">
      {/* <ConnectedChannelTab activeTab={1} channelType={channelType} /> */}
      <div className="py-6 px-8" style={{ maxWidth: '70%' }}>
        {!isEmpty(connectedChannelById) &&
          connectedChannelById?.has_error_in_connection && (
            <Reconnect
              channelType={channelType}
              description={connectedChannelById?.error_description}
              onReconnect={onReconnect}
              isLoading={isLoading}
            />
          )}
        <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
      </div>
    </div>
  );
};

export default Settings;

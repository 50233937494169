import React from 'react';
import Card from './card';

import profilePic from 'assets/icons/icon-avatar-3.svg';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';

const PostLogin = () => {
  const cardDetails = [
    {
      name: 'Madison Inouye',
      icon: profilePic
    },
    {
      name: 'Madison Inouye',
      icon: profilePic
    },
    {
      name: 'Madison Inouye',
      icon: profilePic
    },
    {
      name: 'Madison Inouye',
      icon: profilePic
    },
    {
      name: 'Madison Inouye',
      icon: profilePic
    },
    {
      name: 'Madison Inouye',
      icon: profilePic
    }
  ];
  return (
    <HeaderLayout>
      <CardLayout>
        <CardTitle title="Select your profile to login" />
        <ul className="post-login__card-wrapper">
          {cardDetails.map((item, index) => (
            <Card name={item.name} icon={item.icon} key={index} />
          ))}
        </ul>
      </CardLayout>
    </HeaderLayout>
  );
};

export default PostLogin;

import ErrorMessage from 'components/errorMessage';
import closeIcon from 'assets/icons/icon-close-red.svg';

export const ErrorPopUpBackdrop = () => {
  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(2px)',
        zIndex: 1,
        borderBottomLeftRadius: '20px',
        borderBottomRightRadius: '20px'
      }}
    />
  );
};

export const ErrorMessagePopUp = ({ message, show, onClose }) => {
  return (
    <div className={`match-field-error-popup ${show ? 'show' : ''}`}>
      <ErrorMessage message={message} />
      <img
        src={closeIcon}
        alt="icon-close"
        className="absolute top-3 right-3 cursor-pointer"
        onClick={onClose}
      />
    </div>
  );
};

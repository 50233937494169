import React from 'react';
// import EnquiryBotHome from './home';
import { Route, Routes } from 'react-router-dom';
import EnquiryBotConnectedSettings from './connectedSettings';
// import AnimationWrapper from 'animation/wrapper';
import EnquiryBotChannel from './enquiryBotChannel';

import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
// import AnimationWrapper from 'animation/wrapper';

const { EBOT } = CHOICES.ChannelChoices;

const EnquiryBot = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      {/* <Route path="/pending/:channelId" element={<EnquiryBotHome />} /> */}
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={EBOT} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<EnquiryBotConnectedSettings channelType={EBOT} />}
      />
      <Route path="/pending/:channelId" element={<EnquiryBotChannel />} />
      {/* </Route> */}
    </Routes>
  );
};

export default EnquiryBot;

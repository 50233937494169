/* eslint-disable react-hooks/exhaustive-deps */
import Tab from './component/tab';
import React, { useEffect } from 'react';
import Header from './component/header';
import { ReactComponent as FeedActive } from 'assets/icons/icon-feed-tab-primary.svg';
import { ReactComponent as FeedGrey } from 'assets/icons/icon-feed-tab-grey.svg';
import { ReactComponent as TaskTab } from 'assets/icons/icon-task-tab-grey.svg';
import { ReactComponent as TaskTabActive } from 'assets/icons/icon-task-tab-active.svg';
// import { ReactComponent as MyStuffTab } from 'assets/icons/icon-my-stuff-tab-grey.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveTab } from 'slices/teamChatSlice';
import UserFeed from './module/feed';
import UserTasks from './module/tasks';
// import UserStuff from './module/myStuff';
import {
  getOlderNotificationList,
  getTodayNotificationList,
  getYesterdayNotificationList
} from 'slices/userNotificationSlice';
import { useParams } from 'react-router-dom';
import { markNotificationAsRead } from 'slices/allCount/slice';

const TeamChat = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const { activeTab } = useSelector((state) => state.teamChat);
  const { users_count } = useSelector((state) => state.allCount);

  const tabsData = [
    {
      id: 1,
      title: 'Feed',
      renderIcon: FeedGrey,
      activeIcon: FeedActive
    },
    {
      id: 2,
      title: 'Tasks',
      renderIcon: TaskTab,
      activeIcon: TaskTabActive
    }
    // {
    //   id: 3,
    //   title: 'My stuff',
    //   renderIcon: MyStuffTab,
    //   activeIcon: MyStuffTab
    // }
  ];

  const handleClick = (id) => {
    dispatch(setActiveTab(id));
  };

  const loadNotifications = () => {
    dispatch(getTodayNotificationList({ userId: userId }));
    dispatch(getYesterdayNotificationList({ userId: userId }));
    dispatch(getOlderNotificationList({ userId: userId }));
  };

  const resetNotificationCount = () => {
    if (users_count[userId] > 0) {
      dispatch(markNotificationAsRead({ body: { user_id: userId } }));
    }
  };

  useEffect(() => {
    dispatch(setActiveTab(1));
    loadNotifications();
    resetNotificationCount();
  }, []);

  return (
    <section className="dashboard__main">
      <Header activeTab={activeTab} />
      <div>
        <div className="tabs-wrapper pl-12">
          {tabsData.map((item, index) => {
            const { title, id } = item;
            return (
              <Tab
                key={index}
                id={id}
                title={title}
                renderSvg={() =>
                  activeTab === id ? <item.activeIcon /> : <item.renderIcon />
                }
                onClick={handleClick}
                isActive={activeTab === id}
              />
            );
          })}
        </div>
      </div>
      {activeTab === 1 && <UserFeed />}
      {activeTab === 2 && <UserTasks />}
      {/* {activeTab === 3 && <UserStuff />} */}
    </section>
  );
};

export default TeamChat;

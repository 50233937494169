/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import EnquiryBinEnquiries from './component/enquiries';
import EnquiryBinHeader from './component/header';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { emptyBin, getAllBinLeads } from 'slices/enquiryBin/slice';
import { useDispatch, useSelector } from 'react-redux';
import ConversationMain from 'modules/enquirybox/ebox/module/box/conversation/main';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { ReactComponent as DeleteAll } from 'assets/icons/icon-delete-all.svg';
import EnquiryBinEmptyState from './component/emptyState';
import { route } from 'utils/route';
import { isEmpty } from 'utils/utils';

const EnquiryBinHome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isRestoreAll, setIsRestoreAll] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const { allBinLeads } = useSelector((state) => state.enquiryBin);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllBinLeads = () => {
    setIsLoading(true);
    dispatch(getAllBinLeads({ callback: callback }));
  };

  useEffect(() => {
    loadAllBinLeads();
  }, []);

  const deleteCallback = () => {
    setIsDeleteLoading(false);
    setIsRestoreAll(false);
    navigate(`${route.enquiryBin}`);
  };

  const onEmptyBin = () => {
    setIsDeleteLoading(true);
    dispatch(emptyBin({ callback: deleteCallback }));
  };

  return (
    <section className="dashboard__main">
      <DeleteConfirmation
        showConfirmation={isRestoreAll}
        onCancel={() => setIsRestoreAll(false)}
        title={`Are you sure you want to delete these ${
          !isEmpty(allBinLeads) ? allBinLeads?.count : ''
        } enquiries?`}
        description="Once deleted you will not be able to get it back."
        renderSvg={() => <DeleteAll />}
        onConfirm={onEmptyBin}
        isLoading={isDeleteLoading}
      />
      <EnquiryBinHeader
        onClick={() => setIsRestoreAll(true)}
        isHideBtn={
          isEmpty(allBinLeads) || isEmpty(allBinLeads?.results) ? true : false
        }
      />
      <div
        className="dashboard__main__body"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <EnquiryBinEnquiries isLoading={isLoading} />
        <Routes>
          <Route path="/" element={<EnquiryBinEmptyState />} />
          <Route path="/lead/*" element={<ConversationMain isBin={true} />} />
        </Routes>
      </div>
    </section>
  );
};

export default EnquiryBinHome;

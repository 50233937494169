/* eslint-disable react/no-unescaped-entities */
const SmsDescription = () => {
  return (
    <div className="mt-0 product-description">
      <p className="text-15 leading-6 font-semibold">
        Reach Customers Where They Are - on Their Phones
      </p>
      <p className="text-15 leading-6 mt-5">
        Today's customers live mobile-first lives. They're rarely at their
        computers, but nearly always have their phones by their side.
      </p>
      <p className="text-15 leading-6 mt-5">
        That's why connecting via SMS is so powerful - open rates for text
        messages average 95%, compared to just 20% for emails. Customers are 20x
        more likely to read your messages when you reach them on their preferred
        mobile channel.
      </p>
      <p className="text-15 leading-6 mt-5">
        Now you can engage customers in real-time, back-and-forth text
        conversations right from your desktop with our new Two-Way SMS feature.
      </p>
      <p className="text-15 leading-6 mt-5">
        Whether customers have questions, want to make a sales enquiry or
        provide feedback, your team can respond immediately via SMS. Timely
        mobile responses keep customers happy and loyal to your brand.
      </p>
      <p className="text-15 leading-6 mt-5">
        Automated text messages like sales follow ups, appointment reminders or
        offers keep customers informed without extra work from you.
      </p>
      <p className="text-15 leading-6 mt-5">
        It's time to meet customers where they are - on their phones. Boost
        satisfaction and drive results by tapping into the power of two-way SMS
        conversations today.
      </p>
    </div>
  );
};

export default SmsDescription;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_WELCOME_MESSAGES,
  OPENING_MESSAGE,
  OPENING_OPTIONS
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';

const initialState = {
  welcomeData: {},
  opening_message: [],
  opening_options: [],
  messageIsLoading: false,
  optionIsLoading: false,
  dragDropMessageLoading: false,
  dragDropOptionLoading: false
};

const reducers = {
  setWelcomeData: (state, action) => {
    state.welcomeData = action.payload;
    state.opening_message = action?.payload.opening_messages;
    state.opening_options = action?.payload.opening_options;
  },
  setOpeningMessage: (state, action) => {
    state.opening_message = action.payload;
  },
  setOpeningStarterOptions: (state, action) => {
    state.opening_options = action.payload;
  },
  setMessageIsLoading: (state, action) => {
    state.messageIsLoading = action.payload;
  },
  setOptionIsLoading: (state, action) => {
    state.optionIsLoading = action.payload;
  },
  setDragDropMessageLoading: (state, action) => {
    state.dragDropMessageLoading = action.payload;
  },
  setdragDropOptionLoading: (state, action) => {
    state.dragDropOptionLoading = action.payload;
  },
  resetWelcomePage: () => initialState
};

export const welcomePage = createSlice({
  name: 'welcomePage',
  initialState,
  reducers
});
export const {
  setWelcomeData,
  setOpeningMessage,
  setOpeningStarterOptions,
  setMessageIsLoading,
  setOptionIsLoading,
  setDragDropMessageLoading,
  setdragDropOptionLoading,
  resetWelcomePage
} = welcomePage.actions;

export default welcomePage.reducer;

export const getWelcomeList = (assistant_id) => {
  return async (dispatch) => {
    dispatch(setDragDropMessageLoading(true));
    dispatch(setdragDropOptionLoading(true));
    try {
      new APIRequest()
        .get(replaceUrl(GET_WELCOME_MESSAGES, 'assistant_id', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setWelcomeData(res.data));
            dispatch(setDragDropMessageLoading(false));
            dispatch(setdragDropOptionLoading(false));
          }
        })
        .catch((err) => {
          console.log(err);
          dispatch(setDragDropMessageLoading(false));
          dispatch(setdragDropOptionLoading(false));
        });
    } catch (err) {
      console.log(err);
      dispatch(setMessageIsLoading(false));
      dispatch(setOptionIsLoading(false));
    }
  };
};
export const addOpeningMessage = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { welcomePage, assistant } = getState();
    const { opening_message } = welcomePage;
    const { currentVersionId } = assistant;
    try {
      const { body } = payload;
      let currPayload = {
        ...body,
        version_id: currentVersionId
      };
      new APIRequest()
        .post(OPENING_MESSAGE, currPayload)
        .then((resp) => {
          if (resp.status === 200) {
            const data = opening_message.map((item, index) => {
              if (payload.index === index) return { ...resp.data };
              return item;
            });
            dispatch(setOpeningMessage(data));
            dispatch(setVersionStatus(resp?.data?.version_status));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};
// based on updateType(content or position), content or postion will be updated to slice state
export const updateOrReorderOpeningMessage = (
  payload,
  updateType,
  successCb,
  failedCb
) => {
  return async (dispatch, getState) => {
    try {
      const { body, index, source, destination } = payload;
      const { welcomePage, assistant } = getState();
      const { opening_message } = welcomePage;
      const { currentVersionId } = assistant;
      let currPayload = {
        ...body,
        version_id: currentVersionId
      };
      new APIRequest()
        .put(OPENING_MESSAGE, currPayload)
        .then((resp) => {
          if (resp.status === 200) {
            if (updateType === 'content') {
              let data = [...opening_message];
              let newData = data.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    ['content']: resp.data.content
                  };
                }
                return {
                  ...item
                };
              });
              dispatch(setOpeningMessage(newData));
              dispatch(setMessageIsLoading(false));
              dispatch(setVersionStatus(resp?.data?.version_status));
              if (successCb) {
                successCb();
              }
            } else if (updateType === 'reorder') {
              let newdata = Array.from(opening_message);
              const [reorderedItem] = newdata.splice(source.index, 1);
              newdata.splice(destination.index, 0, reorderedItem);
              // newdata[resp.data.index].position = resp.data.position;
              dispatch(setOpeningMessage(newdata));
              dispatch(setDragDropMessageLoading(false));
              dispatch(setVersionStatus(resp?.data?.version_status));
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          dispatch(setMessageIsLoading(false));
          dispatch(setDragDropMessageLoading(false));
          console.log(e);
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      dispatch(setMessageIsLoading(false));
      dispatch(setDragDropMessageLoading(false));
      console.log(e);
    }
  };
};

export const deleteOpeningMessage = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      const { welcomePage, assistant } = getState();
      const { opening_message } = welcomePage;
      const { currentVersionId } = assistant;
      const { id } = payload;
      let body = {
        version_id: currentVersionId,
        message_id: id
      };
      new APIRequest()
        .delete(OPENING_MESSAGE, body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(
              setOpeningMessage(
                opening_message.filter(
                  (item) => item.id !== resp.data.message_id
                )
              )
            );
            dispatch(setVersionStatus(resp?.data?.version_status));
            successCb();
          }
        })
        .catch((e) => {
          failedCb();
          console.log(e);
        });
    } catch (e) {
      failedCb();
      console.log(e);
    }
  };
};

export const addOpeningOption = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      const { body } = payload;
      const { welcomePage, assistant } = getState();
      const { opening_options } = welcomePage;
      const { currentVersionId } = assistant;
      let currPayload = {
        ...body,
        version_id: currentVersionId
      };
      new APIRequest()
        .post(OPENING_OPTIONS, currPayload)
        .then((resp) => {
          if (resp.status === 200) {
            const data = opening_options.map((item, index) => {
              if (payload.index === index) return { ...resp.data };
              return item;
            });
            dispatch(setVersionStatus(resp?.data?.version_status));
            dispatch(setOpeningStarterOptions(data));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};
// based on updateType(content or position), content or postion will be updated to slice state
export const updateOrReorderOpeningOption = (
  payload,
  updateType,
  successCb,
  failedCb
) => {
  return async (dispatch, getState) => {
    try {
      const { body, index, source, destination } = payload;
      const { welcomePage, assistant } = getState();
      const { opening_options } = welcomePage;
      const { currentVersionId } = assistant;
      let currPayload = {
        ...body,
        version_id: currentVersionId
      };
      new APIRequest()
        .put(OPENING_OPTIONS, currPayload)
        .then((resp) => {
          if (resp.status === 200) {
            if (updateType === 'content') {
              let data = [...opening_options];
              let newData = data.map((item, i) => {
                if (i === index) {
                  return {
                    ...item,
                    ['content']: resp.data.content
                  };
                }
                return {
                  ...item
                };
              });
              dispatch(setOpeningStarterOptions(newData));
              dispatch(setMessageIsLoading(true));
              dispatch(setVersionStatus(resp?.data?.version_status));
              if (successCb) {
                successCb(payload?.index, resp);
              }
            } else if (updateType === 'reorder') {
              let newdata = Array.from(opening_options);
              const [reorderedItem] = newdata.splice(source.index, 1);
              newdata.splice(destination.index, 0, reorderedItem);
              // newdata[resp.data.index].position = resp.data.position;
              dispatch(setOpeningStarterOptions(newdata));
              dispatch(setdragDropOptionLoading(false));
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          dispatch(setMessageIsLoading(false));
          dispatch(setdragDropOptionLoading(false));
          console.log(e);
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      dispatch(setMessageIsLoading(false));
      dispatch(setdragDropOptionLoading(false));
      console.log(e);
    }
  };
};

export const deleteOpeningOption = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      const { welcomePage, assistant } = getState();
      const { opening_options } = welcomePage;
      const { currentVersionId } = assistant;
      const { id } = payload;
      let body = {
        version_id: currentVersionId,
        option_id: id
      };
      new APIRequest()
        .delete(OPENING_OPTIONS, body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(
              setOpeningStarterOptions(
                opening_options.filter(
                  (item) => item.id !== resp.data.option_id
                )
              )
            );
            dispatch(setVersionStatus(resp?.data?.version_status));
            successCb();
          }
        })
        .catch((e) => {
          failedCb();
          console.log(e);
        });
    } catch (e) {
      failedCb();
      console.log(e);
    }
  };
};

/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import Modal from 'components/modal';
import {
  ErrorMessagePopUp,
  ErrorPopUpBackdrop
} from 'modules/modals/webformMapFields/components/errorPopup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAdsFormQuestionFields,
  sendLeadAdsMapFields
} from 'slices/facebook/slice';
import {
  editFbLeadAdsMapping,
  getAllFbLeadsMappingFields,
  getFacebookLeadAdsForms
} from 'slices/automationStuffSlice';
import { isEmpty, handleScrollIntoview } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import CustomColorButton from 'components/buttons/customColorButton';
import Select from 'components/inputs/select';
import FieldListItem from 'modules/modals/webformMapFields/components/fieldListItem';
import ListHeader from 'modules/modals/webformMapFields/components/listHeader';
import useClickOutside from 'hooks/useOutsideClick';
import { object } from 'yup';
import { requiredSelectMsg } from 'utils/messages';
import { useSnackbar } from 'components/Snackbar';
import { Loader } from 'components/loader';
import { useParams } from 'react-router-dom';

const EditForm = (props) => {
  const dispatch = useDispatch();
  const { showMapFields, onClose, formId, selectedForm } = props;
  const nodeRef = useRef();
  const parentRef = useRef();
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const { adsFormQuestionFields } = useSelector((state) => state.fb);
  const { allFbLeadsFormFields } = useSelector(
    (state) => state.automationStuff
  );
  const [errorPopUp, setErrorPopUp] = useState(false);
  const [, setOpenDropdown] = useState(false);
  const [resetValue, setResetValue] = useState(false);
  const [callbackError, setCallbackError] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isMapping, setIsMapping] = useState(false);

  const getFormFields = !isEmpty(adsFormQuestionFields)
    ? adsFormQuestionFields?.map((e) => e.key)
    : [];

  const selectFormSchema = getFormFields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: object().nullable().required(requiredSelectMsg(field))
    };
  }, {});

  const formSchema = {
    ...selectFormSchema
  };
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });
  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  const getSelectedKeyValue = (item) => {
    if (
      item.key === 'email' ||
      item.key === 'first_name' ||
      item.key === 'last_name'
    ) {
      const selectedLabelValue = selectedForm?.mapping_format[item.key];
      const selectedKeyValue = allFbLeadsFormFields?.filter(
        (item) => item.label.toLowerCase() === selectedLabelValue.toLowerCase()
      );
      return selectedKeyValue[0];
    }
    if (item.key === 'phone') {
      const selectedLabelValue = selectedForm?.mapping_format.phone_number;
      const selectedKeyValue = allFbLeadsFormFields?.filter(
        (item) => item.label.toLowerCase() === selectedLabelValue.toLowerCase()
      );
      return selectedKeyValue[0];
    }
    if (item.key === 'full_name') {
      const selectedLabelValue = selectedForm?.mapping_format.name;
      const selectedKeyValue = allFbLeadsFormFields?.filter(
        (item) => item.label.toLowerCase() === selectedLabelValue.toLowerCase()
      );
      return selectedKeyValue[0];
    }
    setIsMapping(false);
    const selectedLabelValue = selectedForm?.mapping_format[item.id];
    const selectedKeyValue = allFbLeadsFormFields?.filter(
      (item) => item.label.toLowerCase() === selectedLabelValue.toLowerCase()
    );
    return selectedKeyValue[0];
  };

  useEffect(() => {
    if (
      showMapFields &&
      formId &&
      !isEmpty(adsFormQuestionFields) &&
      !isEmpty(allFbLeadsFormFields) &&
      !isEmpty(selectedForm?.mapping_format)
    ) {
      setIsMapping(true);
      setTimeout(() => {
        adsFormQuestionFields?.map((item) => {
          return setValue(item.key, getSelectedKeyValue(item), {
            shouldTouch: true
          });
        });
      }, 2000);
    }
  }, [
    showMapFields,
    formId,
    adsFormQuestionFields,
    allFbLeadsFormFields,
    selectedForm
  ]);

  useEffect(() => {
    if (showMapFields && formId) {
      dispatch(getAllFbLeadsMappingFields({ formId: formId }));
      dispatch(getAdsFormQuestionFields());
    }
  }, [showMapFields, formId]);

  useEffect(() => {
    const keys = Object.keys(selectFormSchema);
    clearErrors(keys);
    reset();
    if (showMapFields) {
      setResetValue(true);
      setErrorPopUp(false);
    }
  }, [showMapFields]);

  useEffect(() => {
    if (!isEmpty(errors)) {
      setErrorPopUp(true);
    }
  }, [errors, touchedFields]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    setCallbackError([]);
    const form_fields = Object.keys(data);
    const mappedFields = form_fields.map((e) => ({
      question_form_value: e,
      form_key: data[e].key
    }));

    const formData = {
      data: {
        mapping_format: mappedFields
      },
      formId: formId
    };
    dispatch(editFbLeadAdsMapping(formData, onSuccess, onError));
  };

  const onError = (err) => {
    console.log('here');
    setIsLoading(false);
    setCallbackError(err.mapping);
  };

  const onSuccess = () => {
    setIsLoading(false);
    dispatch(getFacebookLeadAdsForms({ channelId: channelId }));
    onClose();
    openSuccessSnackBar('Mapping success');
  };

  const getSelectedAnswer = (key, id) => {
    if (key === 'email' || key === 'first_name' || key === 'last_name') {
      return selectedForm?.mapping_format[key];
    }
    if (key === 'phone') {
      return selectedForm?.mapping_format.phone_number;
    }
    if (key === 'full_name') {
      return selectedForm?.mapping_format.name;
    }
    return selectedForm?.mapping_format[id];
  };

  return (
    <Modal.Main open={showMapFields} className="modal--match-fields">
      <Modal.Header title="Map your fields" onClose={onClose} />
      <ErrorMessagePopUp
        show={errorPopUp}
        onClose={() => setErrorPopUp(false)}
        message="Complete all the red fields to save & finish mapping"
      />
      <Modal.Body className="px-0 pt-0 pb-8 relative">
        {errorPopUp && <ErrorPopUpBackdrop />}
        {isMapping ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div
              className="overflow-y-auto scroll-smooth px-12 pt-3"
              ref={parentRef}
            >
              <div style={{ maxHeight: 300 }}>
                <div className="flex">
                  <ListHeader title="Enquirybox questions" className="w-3/6" />
                  <ListHeader
                    title="Facebook Lead Ads fields"
                    className="w-3/6 pl-3"
                  />
                </div>
                {!isEmpty(allFbLeadsFormFields) &&
                  !isEmpty(adsFormQuestionFields) &&
                  adsFormQuestionFields?.map((item, index) => {
                    const { label, key, id } = item;
                    return (
                      <div className="flex mt-4 gap-5" key={index}>
                        <FieldListItem name={label} />
                        <div
                          className="relative w-3/6"
                          ref={nodeRef}
                          onClick={() =>
                            handleScrollIntoview(`dropDownId-${index}`, 'start')
                          }
                        >
                          <Select
                            id={`dropDownId-${index}`}
                            inputClassName={
                              isEmpty(errors[key]) && !isEmpty(getValues(key))
                                ? 'input-field--selected'
                                : 'input-field--md'
                            }
                            placeholder="Select a field"
                            options={allFbLeadsFormFields || []}
                            name={key}
                            control={control}
                            setValue={setValue}
                            error={!isEmpty(errors[key])}
                            showGreenBorder={
                              isEmpty(errors[key]) && !isEmpty(getValues(key))
                            }
                            required
                            dropDownClassName="dropdown-menu__map-fields"
                            resetValue={resetValue}
                            isModalOpen={showMapFields}
                            selectedAnswer={() => getSelectedAnswer(key, id)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className="mt-5">
                {!isEmpty(callbackError) &&
                  callbackError.map((err) => (
                    <ErrorMessage className="mb-5" message={err} key={err} />
                  ))}
              </div>
            </div>
            <div className="flex items-center justify-end gap-3 px-12 pt-6">
              <CustomColorButton
                label="Cancel"
                className="btn--secondary btn--md min-w-120"
                onClick={onClose}
              />
              <CustomColorButton
                type="submit"
                label="Save & finish"
                className="btn--green btn--md btn--wide-20 min-w-120"
                isLoading={isLoading}
                enableLoader={isLoading}
                loaderInline={true}
              />
            </div>
          </form>
        )}
      </Modal.Body>
    </Modal.Main>
  );
};

export default EditForm;

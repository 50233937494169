import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as SelectedIcon } from 'assets/icons/icon-question-selected.svg';

const QuestionItem = (props) => {
  const { label, renderSvg, question, onClick, isSelected, id } = props;
  return (
    <div
      className="bg-white border border-grey-400 rounded-lg flex justify-between mb-1 p-2 items-center cursor-pointer"
      onClick={() => onClick(question)}
    >
      <div className="flex gap-4 items-center">
        <figure
          className="bg-grey-100 flex justify-center items-center"
          style={{ width: 32, height: 32, borderRadius: 5 }}
        >
          {renderSvg && typeof renderSvg === 'function' && renderSvg()}
        </figure>
        <p className="text-15 font-semibold">{label}</p>
      </div>
      {isSelected === id && <SelectedIcon />}
    </div>
  );
};

QuestionItem.propTypes = {
  label: PropTypes.string,
  isSelected: PropTypes.number,
  question: PropTypes.object,
  renderSvg: PropTypes.func,
  onClick: PropTypes.func,
  id: PropTypes.any
};

QuestionItem.defaultProps = {
  isSelected: false
};

export default QuestionItem;

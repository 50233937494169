import React, { useEffect, useRef, useState } from 'react';
import TextInputField from 'components/inputs/textField';
import addIcon from 'assets/icons/icon-add-plus.svg';
import CustomToolTip from 'components/CustomTooltip';
import UploadImage from 'modules/chatbotAi/modal/uploadImage';
import { SketchPicker } from 'react-color';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import {
  getBotValues,
  updateBotValues
} from 'slices/ai-assistant/deploy/websiteLinks/controls/slice';
import { useParams } from 'react-router-dom';
import CropImage from 'modules/chatbotAi/modal/cropImage';
import APIRequest from 'apiRequest';
import { FILE_UPLOAD } from 'urls';
import CHOICES from 'choices';
import { useSnackbar } from 'components/Snackbar';
import OverLayLoader from 'components/loader/overLayLoader';
import { ADD_CUSTOM_AVATAR } from 'urls/ai-assistant';
import { setAvatarList } from 'slices/ai-assistant/assistant/slice';

const AppearancePage = () => {
  const [showColourPicker, setShowColorPicker] = useState(false);
  const { botValues } = useSelector((state) => state.botControls);
  const [background, setBackground] = useState('');
  const [colorCodeerror, setColorCodeError] = useState('');
  const nodeRef = useRef(null);
  const { assistant_id } = useParams();
  const [uploadImageFlag, setUploadImageFlag] = useState(false);
  const [cropImageFlag, setCropImageFlag] = useState(false);
  const [imageCrop, setImageCrop] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { currentVersionId } = useSelector((state) => state.assistant);
  const { avatarList } = useSelector((state) => state.assistant);
  const [avatars, setAvatar] = useState([]);
  const dispatch = useDispatch();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  useClickOutside(() => setShowColorPicker(false), nodeRef.current);

  const chekIsValideColorCode = (bgColor) => {
    let isValid = true;
    if (isEmpty(bgColor)) {
      setColorCodeError('Please fill the color code');
    } else {
      setColorCodeError('');
    }
    if (bgColor?.length > 7 || bgColor?.length <= 3) {
      setColorCodeError('Please enter a valid color code');
      isValid = false;
    } else {
      setColorCodeError('');
    }
    return isValid;
  };

  const successCb = () => {
    setIsLoading(false);
    document.activeElement.blur();
    openSuccessSnackBar('Theme color updated');
  };

  const failedCb = () => {
    setIsLoading(false);
    document.activeElement.blur();
    openSuccessSnackBar('Update failed');
  };

  const handleColourChange = (color) => {
    chekIsValideColorCode(color);
    setBackground(color.hex);
  };

  const handleInputColorChange = (e) => {
    const { value } = e.target;
    chekIsValideColorCode(value);
    if (value.length === 1 && !value.includes('#')) {
      setBackground(`#${value}`);
      return;
    }
    if (value.length > 7) {
      setBackground(background);
      return;
    }
    if (value.length > 1 && !value.includes('#')) {
      setBackground(`#${value}`);
      return;
    }
    setBackground(value);
  };

  const avatarUpdateSuccessCb = () => {
    openSuccessSnackBar('Avatar updated');
    setIsLoading(false);
  };

  const avatarUpdateFailedCb = () => {
    openErrorSnackBar('Avatar upload failed');
    setIsLoading(false);
  };

  const handleFileUplad = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageCrop(event.target.result);
        setUploadImageFlag(false);
        setCropImageFlag(true);
      };
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = '';
    }
  };

  const handleSaveCroppedImage = () => {
    canvas.toBlob(
      (blob) => {
        const anchor = document.createElement('a');
        if (!isEmpty(blob)) {
          anchor.href = URL.createObjectURL(blob);
        }
        const file = blob;
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'uploading_type',
          CHOICES.FileUploadingChoices.PROFILE_PHOTO
        );
        setIsLoading(true);
        setCropImageFlag(false);
        new APIRequest()
          .post(FILE_UPLOAD, formData, {
            'Content-Type': 'multipart/form-data'
          })
          .then((res) => {
            const payload = {
              sub_org_id: subOrganizationId,
              s3_url: res.data.imageUrl
            };

            new APIRequest().post(ADD_CUSTOM_AVATAR, payload).then((res) => {
              dispatch(setAvatarList([...avatars, res.data]));
              const payload = {
                botId: assistant_id,
                data: {
                  theme: {
                    selected_avatar: res.data.s3_url
                  },
                  version_id: currentVersionId
                }
              };
              dispatch(
                updateBotValues(
                  payload,
                  avatarUpdateSuccessCb,
                  avatarUpdateFailedCb
                )
              );
            });
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      },
      'image/png',
      ' image/jpeg',
      1
    );
  };

  const handleChangeFile = (s3_url) => {
    if (botValues.selected_avatar !== s3_url) {
      setIsLoading(true);
      const payload = {
        botId: assistant_id,
        data: {
          theme: {
            selected_avatar: s3_url
          },
          version_id: currentVersionId
        }
      };
      dispatch(
        updateBotValues(payload, avatarUpdateSuccessCb, avatarUpdateFailedCb)
      );
    }
  };

  const handleSubmitColor = (e) => {
    e.preventDefault();
    if (colorCodeerror === '') {
      if (botValues.primary_color !== background) {
        const payload = {
          data: {
            theme: {
              primary_color: background
            },
            version_id: currentVersionId
          },
          botId: assistant_id
        };
        dispatch(updateBotValues(payload, successCb, failedCb));
      }
    }
  };

  const handleDropFile = (e) => {
    e.preventDefault();
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setImageCrop(event.target.result);
        setUploadImageFlag(false);
        setCropImageFlag(true);
      };
      reader.readAsDataURL(e.dataTransfer.files[0]);
      e.target.value = '';
    }
  };

  useEffect(() => {
    if (avatarList.length) setAvatar(avatarList);
  }, [avatarList]);

  useEffect(() => {
    if (!isEmpty(botValues)) setBackground(botValues.primary_color);
  }, [botValues]);

  useEffect(() => {
    dispatch(getBotValues(assistant_id));
  }, []);

  useEffect(() => {
    if (colorCodeerror === '' && background !== '') {
      if (botValues.primary_color !== background) {
        setIsLoading(true);
        const payload = {
          data: {
            theme: {
              primary_color: background
            },
            version_id: currentVersionId
          },
          botId: assistant_id
        };
        dispatch(updateBotValues(payload, successCb, failedCb));
      }
    }
  }, [showColourPicker]);

  return (
    <section
      className="appearance-screen"
      style={{ width: 720, padding: '25px 38px 40px' }}
    >
      {isLoading && <OverLayLoader style={{ top: 0, left: 0 }} />}
      <div className="card-box py-5 px-6">
        <h3 className="text-lg font-semibold">Theme Colour</h3>
        <p className="text-grey-800 mt-3">
          Choose a theme colour that matches your brand
        </p>
        <div className="flex items-center mt-6">
          <div
            className="theme-color-box relative cursor-pointer"
            style={{ background: background }}
            onClick={() => setShowColorPicker(!showColourPicker)}
          ></div>
          <div
            className="absolute top-[325px]"
            ref={nodeRef}
            style={{ zIndex: 2 }}
          >
            {showColourPicker && (
              <SketchPicker
                color={background}
                onChange={handleColourChange}
                presetColors={[]}
                disableAlpha={true}
              />
            )}
          </div>
          <form onSubmit={handleSubmitColor}>
            <TextInputField
              autoComplete={'off'}
              name="colorInput"
              className="input-field input-field--border2 text-sm input-field--h42 ml-1"
              placeholder=""
              style={{ width: 82, height: 32, padding: '4px 8px' }}
              onChange={handleInputColorChange}
              value={background}
              onBlur={handleSubmitColor}
            ></TextInputField>
          </form>
          {colorCodeerror && (
            <ErrorMessage className="ml-2" message={colorCodeerror} />
          )}
        </div>
      </div>
      <div className="card-box py-5 px-6 mt-8">
        <h3 className="text-lg font-semibold">Avatar Image</h3>
        <p className="text-grey-800 mt-3">
          Choose from one of our tested Avatars below or upload your own
        </p>
        <div className="flex items-center mt-4">
          <div className="flex flex-wrap items-center">
            {avatars.map((avatar) => (
              <figure
                key={avatar.id}
                className={`avatar-container avatar-pic-container mt-2 cursor-pointer
                  ${
                    botValues.selected_avatar === avatar.s3_url ? 'active' : ''
                  }`}
                onClick={() => handleChangeFile(avatar.s3_url)}
              >
                <img src={avatar.s3_url} className="avatar-pic" />
              </figure>
            ))}
            <CustomToolTip
              position="top"
              tooltipText="Upload your own"
              tooltipStyle={{
                padding: '4px 8px'
              }}
              style={{ marginTop: '8px' }}
            >
              <div
                className="btn-upload-pic"
                onClick={() => setUploadImageFlag(true)}
              >
                <img src={addIcon} alt="add-icon" />
              </div>
            </CustomToolTip>
          </div>
        </div>
      </div>
      <UploadImage
        open={uploadImageFlag}
        setOpen={setUploadImageFlag}
        handleFileUplad={handleFileUplad}
        handleDropFile={handleDropFile}
      />
      <CropImage
        open={cropImageFlag}
        setOpen={setCropImageFlag}
        src={imageCrop}
        handleSaveCroppedImage={handleSaveCroppedImage}
        setCanvas={setCanvas}
      />
    </section>
  );
};

export default AppearancePage;

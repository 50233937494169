/* eslint-disable no-unused-vars */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Lead from '../../../lead';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { onUnreadLeadFocused, setCurrentBoxCardList } from 'slices/boxes/slice';
import choices from 'choices';
import { setActiveBoxNewLead } from 'slices/boxes/slice';

const { VIEWED } = choices.LeadStatusChoices;

const StageViewedLead = (props) => {
  const dispatch = useDispatch();
  const { stageLeads, activeBoxNewLead } = useSelector((state) => state.boxes);
  const { stageId, box, dragStart } = props;
  const viewedLeads = stageLeads[stageId]?.seen_replied;
  let startIndex = stageLeads[stageId]?.reply_later;
  startIndex = Array.isArray(startIndex) ? startIndex.length : 0;
  let newUnread = stageLeads[stageId]?.new_unread;
  startIndex += Array.isArray(newUnread) ? newUnread.length : 0;

  // if (isEmpty(viewedLeads) && !isEmpty(dragStart)) {
  //   if (dragStart.draggableId.includes('SEEN')) {
  //     return (
  //       <Droppable droppableId={`${stageId}_SEEN`} direction="horizontal">
  //          {(provided) => {
  //             return (
  //               <div style={{
  //                 background: 'red',
  //                 height: 50,
  //                 width: '100%'
  //               }} ref={provided.innerRef} {...provided.droppableProps}>
  //                 seen
  //                 {provided.placeholder}
  //               </div>
  //             )
  //          }}
  //       </Droppable>
  //     )
  //   }
  // }

  const onLeadClick = () => {
    dispatch(setCurrentBoxCardList(VIEWED));
    if (!isEmpty(activeBoxNewLead)) {
      dispatch(setActiveBoxNewLead(null));
      dispatch(onUnreadLeadFocused({ stageId }));
    }
  };

  if (isEmpty(viewedLeads)) {
    return <></>;
  }

  return (
    <>
      <label className="text-xs font-medium text-grey-700 uppercase ml-1">
        seen or replied
      </label>
      <div className="mt-2">
        {viewedLeads.map((lead, index) => {
          return (
            <Draggable
              key={`${lead.id}_SEEN`}
              draggableId={`${lead.id}_SEEN`}
              index={index + startIndex}
            >
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={onLeadClick}
                  >
                    <Lead
                      lead={lead}
                      box={box}
                      stageId={stageId}
                      index={index}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        })}
      </div>
    </>
  );
};

export default StageViewedLead;

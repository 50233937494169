import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CreateChannelModal from '../../selectChannels/modal';
import {
  connectChannelsAsDraft,
  getAllPendingChannels
} from 'slices/channelsSlice';
import { useSelector, useDispatch } from 'react-redux';
import { getRoutePath } from 'utils/channels';
import { useSnackbar } from 'components/Snackbar';
import choices from 'choices';
import { useState } from 'react';

export const CreateChannel = (props) => {
  const { onCancel } = props;
  const { channelType } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { allChannels } = useSelector((state) => state.channels);
  const [isLoading, setIsLoading] = useState(false);

  const { openSuccessSnackBar } = useSnackbar();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSuccess = (data) => {
    openSuccessSnackBar('Channel created');
    dispatch(getAllPendingChannels({ subOrgId: subOrganizationId }));
    setIsLoading(false);
    onCancel();
    const channel = allChannels?.find((channel) => channel.id === channelType);
    if (channel?.channel_type === choices.ChannelChoices.WEBFORM) {
      navigate(
        `/dashboard/enquirybox/settings/${getRoutePath(
          data.channel_type
        )}/pending/${data.id}/draft`
      );
    } else {
      navigate(
        `/dashboard/enquirybox/settings/${getRoutePath(
          data.channel_type
        )}/pending/${data.id}`
      );
    }
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = {
      body: {
        channel: channelType,
        channel_name: data.name
      },
      subOrgId: subOrganizationId
    };
    dispatch(connectChannelsAsDraft(formData, onSuccess));
  };

  return (
    <CreateChannelModal
      open={true}
      onClose={onCancel}
      onSubmit={onSubmit}
      isLoading={isLoading}
    />
  );
};

export default CreateChannel;

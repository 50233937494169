/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TopSalesPerformer from '../../components/topSalesPerformer';
import TopResponder from '../../components/topResponder';
import AverageResponseTime from '../../components/averageResponseTime';
import TeamMemberResponseTable from '../../components/teamMemberResponseTable';
import FilterSection from '../../components/filterSection';
import { Loader } from 'components/loader';
import { getAllTeamReport } from 'slices/reports/slice';
import { formatDateTime } from 'utils/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { isEmpty } from 'utils/utils';

const TeamPerformanceReport = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDay, setSelectedDay] = useState({});
  const { teamReport } = useSelector((state) => state.reports);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const callback = () => {
    setIsLoading(false);
  };

  const loadTeamReport = () => {
    const formData = {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      date_format: 'This month to date',
      subOrgId: subOrganizationId
    };
    dispatch(getAllTeamReport(formData, callback));
  };

  useEffect(() => {
    setIsLoading(true);
    loadTeamReport();
  }, []);

  const onSelectDay = (data) => {
    setStartDate();
    setEndDate();
    if (data.value === 'This month to date') {
      loadTeamReport();
    }
    if (data.value === 'Last 7 days') {
      const formData = {
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        date_format: data.value,
        subOrgId: subOrganizationId
      };
      dispatch(getAllTeamReport(formData, callback));
    }
  };

  const onCustomDateSelect = (data) => {
    if (data.startDate && data.endDate) {
      const start_date = formatDateTime(data.startDate, 'yyyy-mm-dd');
      const end_date = formatDateTime(data.endDate, 'yyyy-mm-dd');
      const formData = {
        startDate: start_date,
        endDate: end_date,
        date_format: 'Custom',
        subOrgId: subOrganizationId
      };
      dispatch(getAllTeamReport(formData, callback));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="team-performance-report">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Team performance report</h3>
        <FilterSection
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          onSelectDay={onSelectDay}
          onCustomDateSelect={onCustomDateSelect}
        />
      </div>
      <div className="flex items-center -mx-3 mt-6">
        <TopSalesPerformer
          isShowEmptyState={isEmpty(teamReport) ? true : false}
          data={teamReport?.top_sales_performer}
        />
        <TopResponder
          isShowEmptyState={isEmpty(teamReport) ? true : false}
          data={teamReport?.top_responder}
        />
      </div>
      <div className="mt-8">
        <AverageResponseTime
          isShowEmptyState={isEmpty(teamReport) ? true : false}
          averageTime={teamReport?.average_response_time}
          averageTimeChange={teamReport?.average_response_time_change}
          selectedDay={selectedDay?.value}
        />
      </div>
      <TeamMemberResponseTable
        isShowEmptyState={isEmpty(teamReport) ? true : false}
        data={teamReport?.other_user_data}
      />
    </div>
  );
};

export default TeamPerformanceReport;

import React from 'react';
import HistoryLayout from './layout';
import taskIcon from 'assets/icons/white-union-task.svg';
import choices from 'choices';
import { timeFormatter } from 'utils/utils';

export const CompletedDesign = ({ desc }) => {
  return (
    <div className="flex truncate" style={{ maxWidth: '97%' }}>
      <div className="flex py-3 text-13 px-3 justify-center align-center">
        {desc}
      </div>
    </div>
  );
};
const TaskCompleted = (props) => {
  let {
    data: { title, created_at, description }
  } = props;

  return (
    <div>
      <HistoryLayout
        icon={taskIcon}
        title={choices.TitleChoices.CHOICES[title]}
        time={timeFormatter(created_at)}
        className="history__new-enquiry"
        renderData={() => <CompletedDesign desc={description} />}
      />
    </div>
  );
};
export default TaskCompleted;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import contactIcon from 'assets/icons/icon-contact-tab.svg';
import fileIcon from 'assets/icons/icon-file-tab.svg';
import historyActiveIcon from 'assets/icons/icon-history-tab.svg';
import {
  getAllAnswer,
  getQuestionsList,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm
} from 'slices/questionSlice';
import History from './history';
import Files from './files';
import Contact from './contact';
import BoxContact from 'modules/boxes/leadDetail/conversation/contact';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import contactActiveIcon from 'assets/icons/icon-modal-contact-primary.svg';
import fileActiveIcon from 'assets/icons/icon-modal-file-primary.svg';
import historyIcon from 'assets/icons/icon-modal-history-grey.svg';
import noteIcon from 'assets/icons/icon-tab-note.svg';
import noteActiveIcon from 'assets/icons/icon-tab-note-active.svg';
import taskIcon from 'assets/icons/icon-tab-task.svg';
import taskActiveIcon from 'assets/icons/icon-tab-task-active.svg';
import Notes from './notes';
import Tasks from './tasks';
import { setActiveTab } from 'slices/conversationSlice';
import { CONTACT, NOTE, TASK, HISTORY, FILE } from 'utils/constants';

const Details = ({
  // activeTab,
  // setActiveTab,
  isBin,
  isSms
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { activeTab } = useSelector((state) => state.leadConversation);
  const { showContactModal } = useSelector((state) => state.leads);

  useEffect(() => {
    dispatch(setIsEdit(false));
    dispatch(setEditedData({}));
    dispatch(setIsShowQuestionForm(false));
  }, [showContactModal]);
  // useEffect(() => {
  //   dispatch(getQuestionsList({ subOrgId: subOrganizationId }));
  //   dispatch(getAllAnswer({ leadId: cardId }));
  // }, []);

  return (
    <>
      <div className="tabs-wrapper tabs-wrapper--contact">
        <div
          className={`tabs-header ${
            activeTab === CONTACT ? 'tabs-header__active' : ''
          }`}
          onClick={() => dispatch(setActiveTab(CONTACT))}
        >
          {activeTab === CONTACT ? (
            <img src={contactActiveIcon} alt="" />
          ) : (
            <img src={contactIcon} alt="" />
          )}
          <div className="ml-1">Contact</div>
        </div>
        <div
          className={`tabs-header ${
            activeTab === NOTE ? 'tabs-header__active' : ''
          }`}
          onClick={() => dispatch(setActiveTab(NOTE))}
        >
          {activeTab === NOTE ? (
            <img src={noteActiveIcon} alt="" />
          ) : (
            <img src={noteIcon} alt="" />
          )}
          <div className="ml-1">Notes</div>
        </div>
        <div
          className={`tabs-header ${
            activeTab === TASK ? 'tabs-header__active' : ''
          }`}
          onClick={() => dispatch(setActiveTab(TASK))}
        >
          {activeTab === TASK ? (
            <img src={taskActiveIcon} alt="" />
          ) : (
            <img src={taskIcon} alt="" />
          )}
          <div className="ml-1">Tasks</div>
        </div>
        <div
          className={`tabs-header ${
            activeTab === HISTORY ? 'tabs-header__active' : ''
          }`}
          onClick={() => dispatch(setActiveTab(HISTORY))}
        >
          {activeTab === HISTORY ? (
            <img src={historyActiveIcon} alt="" />
          ) : (
            <img src={historyIcon} alt="" />
          )}
          <div className="ml-1">History</div>
        </div>
        <div
          className={`tabs-header ${
            activeTab === FILE ? 'tabs-header__active' : ''
          }`}
          onClick={() => dispatch(setActiveTab(FILE))}
        >
          {activeTab === FILE ? (
            <img src={fileActiveIcon} alt="" />
          ) : (
            <img src={fileIcon} alt="" />
          )}
          <div className="ml-1">Files</div>
        </div>
      </div>
      {activeTab === CONTACT && (
        <>
          {stageId ? (
            <BoxContact isSms={isSms} />
          ) : (
            <Contact isBin={isBin} isSms={isSms} />
          )}
        </>
      )}
      {activeTab === NOTE && <Notes />}
      {activeTab === TASK && <Tasks />}
      {activeTab === HISTORY && <History />}
      {activeTab === FILE && <Files />}
    </>
  );
};

export default Details;

import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import Avatar from 'components/avatar';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/utils';

const TooltipContainer = (props) => {
  const { user, position, automatedMessage } = props;
  return (
    <CustomToolTip
      style={{ height: '35px' }}
      position={position}
      tooltipText={automatedMessage || user?.first_name || user?.name}
    >
      <Avatar
        src={
          user?.avatar
            ? user.avatar
            : user?.customer_avatar
            ? user?.customer_avatar
            : null
        }
        initial={automatedMessage ? 'A M' : user?.first_name || user?.name}
        border={false}
        cover
        small
        singleChar={isEmpty(automatedMessage)}
        style={{ background: automatedMessage && '#53BAC0' }}
      />
    </CustomToolTip>
  );
};

TooltipContainer.propTypes = {
  user: PropTypes.object,
  position: PropTypes.string,
  automatedMessage: PropTypes.string
};

TooltipContainer.defaultProps = {
  position: 'left',
  automatedMessage: ''
};

export default TooltipContainer;

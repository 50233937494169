import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/loader';

const CustomColorButton = (props) => {
  const {
    label,
    className,
    onClick,
    style,
    isLoading,
    enableLoader,
    loaderInline,
    isDisable,
    loaderStyle,
    type,
    isLoaderWhite
  } = props;
  return (
    <button
      className={`btn ${className}`}
      onClick={onClick}
      style={style}
      disabled={isLoading || isDisable}
      type={type}
    >
      {label}
      {isLoading && enableLoader && (
        <Loader
          loaderInline={loaderInline}
          smaller
          loaderStyle={{ marginLeft: 'auto', ...loaderStyle }}
          isLoaderWhite={isLoaderWhite}
        />
      )}
    </button>
  );
};
CustomColorButton.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  enableLoader: PropTypes.bool,
  loaderInline: PropTypes.bool,
  type: PropTypes.string,
  loaderStyle: PropTypes.object,
  isLoaderWhite: PropTypes.bool
};

CustomColorButton.defaultProps = {
  className: '',
  label: '',
  onClick: () => {},
  isLoading: false,
  enableLoader: false,
  loaderInline: false,
  type: 'button',
  loaderStyle: {},
  isLoaderWhite: false
};
export default CustomColorButton;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Automation from './automation/main';
import AutomationsList from './automationsList';
import plusIcon from 'assets/icons/icon-plus-white.svg';
import { useSelector, useDispatch } from 'react-redux';
import {
  setAddAutomation,
  getBoxAutomations,
  resetAutomationStore
} from 'slices/boxAutomation/slice';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
// import { Loader } from 'components/loader'
import Loader from 'components/loader/overLayLoader';

const BoxAutomationStuff = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { box_id } = useParams();
  const { addAutomation, selectedAutomation, boxAutomations } = useSelector(
    (state) => state.boxAutomation
  );

  const onSuccess = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    dispatch(getBoxAutomations({ query: { box_id } }, onSuccess, onError));
    return () => {
      dispatch(resetAutomationStore());
    };
  }, [box_id]);

  const onNewAutomation = () => {
    dispatch(setAddAutomation(true));
  };

  return (
    <>
      <div>
        <div>
          <h2 className="text-2xl font-semibold text-center">Automate stuff</h2>
          <p className="text-grey-800 text-15 text-center mt-3 px-16">
            Automatically send email, Assign enquiries and more...
          </p>
        </div>
        {!addAutomation && !selectedAutomation && (
          <div className="flex justify-between items-center bg-grey-300 border-radius-14 py-4 pr-5 pl-6 mt-10">
            <div className="font-semibold">Automate repetitive things</div>
            <div>
              <button
                className="btn btn--primary text-13 text-white"
                style={{ padding: '5px 14px', borderRadius: 6 }}
                onClick={onNewAutomation}
              >
                <img
                  src={plusIcon}
                  alt=""
                  style={{ width: 8, height: 8, marginRight: 6 }}
                />
                Automation
              </button>
            </div>
          </div>
        )}
      </div>
      {isLoading && isEmpty(boxAutomations) && (
        <Loader style={{ width: '75%', height: '100px' }} />
      )}
      {!addAutomation && !selectedAutomation && <AutomationsList />}
      {addAutomation && <Automation />}
      {selectedAutomation && <Automation />}
    </>
  );
};

export default BoxAutomationStuff;

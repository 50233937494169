/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import OverLayLoader from 'components/loader/overLayLoader';
import Select from 'components/inputs/select';
import {
  getCurrenciesData,
  updateBusinessInfoData
} from 'slices/businessInfoSlice';
import CHOICES from 'choices';
import { isEmpty } from 'utils/utils';
import { useSnackbar } from 'components/Snackbar';
import { getMyInfo } from 'slices/myInfoSlice';

const CurrencyPicker = ({ currency }) => {
  const { currenciesData } = useSelector((state) => state.businessInfoSettings);
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [selectedValue, setSelectedValue] = useState(currency);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrenciesData());
  }, []);

  useEffect(() => {
    if (!isEmpty(currency)) {
      setSelectedValue({
        value: currency?.id,
        label: `${CHOICES.CurrencyChoice.CHOICES[`${currency?.code}`]} - ${
          currency?.name
        }`,
        icon: currency?.image_link
      });
    }
  }, [currency]);

  const CurrencyOptions = currenciesData.map((item, index) => {
    return {
      label: `${CHOICES.CurrencyChoice.CHOICES[`${item?.code}`]}  -  ${
        item?.name
      }`,
      value: item?.id,
      icon: item?.image_link
    };
  });

  const handleCurrencyChange = (currency) => {
    setIsLoading(true);
    const payload = {
      subOrgId: subOrganizationId,
      data: {
        currency_id: currency?.value
      }
    };
    setSelectedValue(currency);
    dispatch(updateBusinessInfoData(payload, onSuccess, onError));
  };

  const onSuccess = () => {
    dispatch(getMyInfo());
    setIsLoading(false);
    openSuccessSnackBar('Sucess');
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
  };

  return (
    <div className="card-box mt-6 px-6 py-5 relative">
      {isLoading && <OverLayLoader style={{ zIndex: 2 }} />}
      <h3 className="text-lg font-semibold">Your default currency</h3>
      <p className="text-base-1 text-grey-800 mt-2">
        This will be used in boxes to calculate and report on sales
      </p>

      <div className="flex justify-between mt-10 items-center">
        <label className="text-md font-semibold">Default currency</label>
        <div className="relative">
          <div>
            <Select
              name="currency"
              isHaveIcon={true}
              options={CurrencyOptions}
              iconStyle={{ width: 28, height: 18, marginRight: 10 }}
              inputClassName="input-field--sm-38"
              dropDownClassName="p-2"
              value={selectedValue?.label}
              selectedIconStyle={{
                position: 'absolute',
                top: 7,
                zIndex: 1,
                left: 8,
                width: '35px'
              }}
              renderIcon={() => {
                return (
                  <img
                    style={{
                      position: 'absolute',
                      top: 7,
                      zIndex: 1,
                      left: 8,
                      width: '35px'
                    }}
                    src={selectedValue?.icon}
                  />
                );
              }}
              selectedIcon={selectedValue?.icon}
              onChange={(value) => handleCurrencyChange(value)}
              inputStyle={{ paddingLeft: 46 }}
              placeholder="Select currency"
              disabled={true}
              //   error={!isEmpty(errors.currency)}
              readOnly={true}
              isSelectDisabled={true}
              style={{ paddingLeft: 50, cursor: 'auto' }}
            />
            {/* {errors.currency && <Error message={errors.currency.message} />} */}
          </div>
        </div>
      </div>
    </div>
  );
};

CurrencyPicker.propTypes = {};

export default CurrencyPicker;

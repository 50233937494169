import React from 'react';
import { useSelector } from 'react-redux';

const TaskEmptyState = () => {
  const { myInformation } = useSelector((state) => state.myInfo);
  return (
    <div className="flex">
      <div className="team-chat-feed__right-side ml-2">
        <div className={`flex justify-between team-chat-feed__task`}>
          <div className="text-sm font-semibold text-grey-800">
            One place to see any tasks you need to do ✅{' '}
          </div>
        </div>
        <div className="py-3 px-3">
          <div className="team-chat-feed__right-side__task-list">
            <p className="text-sm">
              {`Hi ${myInformation?.name}, keeping track of the tasks you need to do is hard. I’m
              going to make it easy for you. Every morning, I’ll send you a
              summary of the tasks you need to do today, tomorrow and in the
              future. I’ll even let you know if you have any overdue tasks. Once
              you have a task to do, It will show here. Looking forward to
              helping you get it done 👊`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskEmptyState;

import React from 'react';
import Styles from '../../module/feed/index.module.scss';
import LeadAssigned from '../leadAssigned';
import Mentions from '../mentions';
import NewEnquiry from '../newEnquiry';
import CHOICES from 'choices';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import InvitedUserSignedUp from '../invitedUserSignedUp';

const YesterdayFeed = () => {
  const { yesterdayNotification } = useSelector(
    (state) => state.usersNotification
  );
  const { NEW_LEAD, NOTES_ADDED, LEAD_ASSIGNED, SIGN_UP_INVITATION_ACCEPTED } =
    CHOICES.SystemNotificationTypeChoices;

  const renderData = (item) => {
    switch (item.notification_type) {
      case NEW_LEAD:
        return <NewEnquiry data={item} key={item.id} />;
      case NOTES_ADDED:
        return <Mentions data={item} key={item.id} />;
      case LEAD_ASSIGNED:
        return <LeadAssigned data={item} key={item.id} />;
      case SIGN_UP_INVITATION_ACCEPTED:
        return <InvitedUserSignedUp data={item} key={item.id} />;
      default:
        <></>;
    }
  };

  return (
    <>
      {!isEmpty(yesterdayNotification?.results) && (
        <div className="relative">
          <div className={Styles.header__sm}>
            <span
              className="bg-grey-300"
              style={{
                padding: '2px 9px',
                borderRadius: 5,
                position: 'relative',
                zIndex: 9
              }}
            >
              Yesterday
            </span>
          </div>
          <div className="mt-6">
            {yesterdayNotification?.results &&
              yesterdayNotification?.results.map((item) => {
                return renderData(item);
              })}
          </div>
        </div>
      )}
    </>
  );
};

export default YesterdayFeed;

import Avatar from 'components/avatar';
import { isEmpty } from 'utils/utils';
import Typewriter from '../../components/typeWriter';
import MultpleMessageTypewriter from './MultpleMessageTypewriter';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  continueChatConversation,
  setSuggestedReplies
} from 'slices/ai-assistant/assistant/slice';
import { useParams } from 'react-router-dom';
import DataCollectionBox from './dataCollectionBox';
import SuggestedReplies from './SuggestedReplies';
import { formValidation } from './formValidatonSchema';

const Response = ({
  history,
  name,
  avatar,
  disableText,
  setDisableText,
  autoScroll,
  setLoading
}) => {
  const avatarAlignment = (item, i) => {
    if (
      item.role === 'assistant' &&
      history[i - 1]?.role === 'assistant' &&
      i !== 0
    )
      return true;
    return false;
  };

  const [isPrinting, setIsPrinting] = useState(0);
  const { userDataThatBotNeeds } = useSelector((state) => state.assistant);
  const { botConversation } = useSelector((state) => state.assistant);
  const { isShowTestBotPopup } = useSelector((state) => state.assistant);
  const { assistant_id } = useParams();
  const { messageDisplayed } = useSelector((state) => state.assistant);
  const { dataCollectionType } = useSelector((state) => state.assistant);
  const { suggestedReplies } = useSelector((state) => state.assistant);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const successCb = () => {
    setIsLoading(false);
    reset();
  };

  const failedCb = () => {
    setIsLoading(false);
    setDisableText(false);
  };

  useEffect(() => {
    reset();
  }, [isShowTestBotPopup]);

  const onSubmit = (data) => {
    setIsLoading(true);
    dispatch(setSuggestedReplies([]));
    document.activeElement.blur();
    const answerArray = Object.keys(data)
      .filter((field_name) => !isEmpty(data[field_name]))
      .map((field_name) => ({
        field_name,
        value: data[field_name]
      }));

    const payload = {
      answer: answerArray,
      details: dataCollectionType,
      lead_id: botConversation.lead_id
    };

    dispatch(
      continueChatConversation(assistant_id, payload, successCb, failedCb)
    );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(formValidation(userDataThatBotNeeds)),
    mode: 'onChange'
  });

  useEffect(() => {
    if (autoScroll) autoScroll();
  }, [messageDisplayed]);

  return (
    <>
      {!isEmpty(history) ? (
        history?.map((item, i, arr) => {
          const getSecondAssistantIndex = () => {
            return arr.findIndex((item) => item.role === 'user');
          };
          return (
            <div
              className={`chat-bot-list ${
                item.role === 'assistant' &&
                history[i + 1]?.role === 'assistant' &&
                history[i - 1]?.role !== 'assistant'
                  ? '!py-[20px] !px-[0] !border-0'
                  : ''
              } ${
                item.role === 'assistant' &&
                history[i - 1]?.role === 'assistant'
                  ? '!p-0 !pb-[20px] !px-[0]'
                  : ''
              }
              ${
                item.role === 'assistant' &&
                history[i + 1]?.role === 'assistant' &&
                history[i - 1]?.role === 'assistant'
                  ? '!border-0'
                  : ''
              }
              ${item.role === 'assistant' ? '' : 'chat-bot-list--reverse'}`}
              key={i}
            >
              <div className="chat-bot-list__container">
                <div
                  className={`chat-bot-list__item ${
                    avatarAlignment(item, i) ? '!ml-[32px]' : ''
                  }`}
                >
                  {((i === 0 && item.role === 'assistant') ||
                    (item.role === 'assistant' &&
                      history[i + 1]?.role !== 'assistant' &&
                      history[i - 1]?.role !== 'assistant') ||
                    (history[i - 1]?.role !== 'assistant' &&
                      item.role === 'assistant')) && (
                    <Avatar
                      className="chat-bot-list__avatar avatar-container avatar-container--full"
                      src={avatar}
                      initial={name}
                      singleChar
                      small
                      initialStyle={{ fontSize: '16px' }}
                    />
                  )}
                  <div className="chat-bot-list__content">
                    {arr.length - 1 === i &&
                    !Array.isArray(item.message) &&
                    item.role === 'assistant' ? (
                      <>
                        <div className="chat-bot-list__bubble">
                          <Typewriter
                            text={item.message}
                            delay={10}
                            disableText={disableText}
                            setDisableText={setDisableText}
                            singleMessage={true}
                            autoScroll={autoScroll}
                            idx={0}
                            noOfElement={1}
                          />
                        </div>
                        {messageDisplayed && isShowTestBotPopup && (
                          <DataCollectionBox
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            register={register}
                            touchedFields={touchedFields}
                            errors={errors}
                            isLoading={isLoading}
                          />
                        )}
                        {messageDisplayed && !isEmpty(suggestedReplies) && (
                          <SuggestedReplies
                            suggestedReplies={suggestedReplies}
                            autoScroll={autoScroll}
                            setLoading={setLoading}
                          />
                        )}
                      </>
                    ) : arr.length - 1 === i &&
                      Array.isArray(item.message) &&
                      item.role === 'assistant' ? (
                      <>
                        {item?.message?.map((message, index) => (
                          <div
                            className={index !== 0 ? 'mt-[10px]' : ''}
                            key={index}
                          >
                            <MultpleMessageTypewriter
                              text={message}
                              delay={10}
                              disableText={disableText}
                              setDisableText={setDisableText}
                              singleMessage={false}
                              idx={index}
                              noOfElement={item?.message?.length}
                              isPrinting={isPrinting}
                              setIsPrinting={setIsPrinting}
                              autoScroll={autoScroll}
                            />
                          </div>
                        ))}
                        {messageDisplayed && isShowTestBotPopup && (
                          <DataCollectionBox
                            handleSubmit={handleSubmit}
                            onSubmit={onSubmit}
                            register={register}
                            touchedFields={touchedFields}
                            errors={errors}
                            isLoading={isLoading}
                          />
                        )}
                        {messageDisplayed && !isEmpty(suggestedReplies) && (
                          <SuggestedReplies
                            suggestedReplies={suggestedReplies}
                            autoScroll={autoScroll}
                            setLoading={setLoading}
                          />
                        )}
                      </>
                    ) : Array.isArray(item.message) &&
                      item.role === 'assistant' ? (
                      <>
                        {item.message.map((message, index) => (
                          <div className="chat-bot-list__bubble" key={index}>
                            {message}
                          </div>
                        ))}
                      </>
                    ) : (
                      <div
                        className="chat-bot-list__bubble"
                        dangerouslySetInnerHTML={{ __html: item.message }}
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default Response;

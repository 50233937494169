/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as IconAddBoxTemplate } from 'assets/icons/add-box-template.svg';
import { deleteBox, setBoxes } from 'slices/boxes/slice';
import { useNavigate, useParams } from 'react-router-dom';
// import { getQuestionsList } from 'slices/questionSlice';
import { ReactComponent as IconEdit } from 'assets/icons/icon-edit-white.svg';
import { ReactComponent as IconDelete } from 'assets/icons/icon-delete-white.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import DeleteBoxModel from './modals/DeleteBoxModel';
import InputField from 'components/inputs/textField';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import { updateCardDesigne } from 'slices/cardDesignSettingsSlice';
import { useSnackbar } from 'components/Snackbar';
import { route } from 'utils/route';
import RenameBox from './modals/renameBox';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';

const BoringStuff = () => {
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { boxes, currentBox } = useSelector((state) => state.boxes);
  const [showDeleteModel, setShowDeleteModel] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [isNameError, setIsNameError] = useState('');
  const [renameValue, setRenameValue] = useState(currentBox.box_name);
  const [open, setOpen] = useState(false);
  const { openSuccessSnackBar } = useSnackbar();
  const navigate = useNavigate();

  const { isTeamMember } = useGetUserRole();
  useEffect(() => {
    setRenameValue(currentBox.box_name);
    return () => {
      setRenameValue('');
    };
  }, [currentBox]);

  const handleChange = (value) => {
    setIsNameError('');
    if (value.length > 30) {
      setIsNameError('Name is too long, should be 30 characters maximum');
    } else {
      setRenameValue(value);
    }
  };

  const onSave = () => {
    setIsLoading(true);
    if (isEmpty(renameValue)) {
      setIsNameError('Name is required');
    } else {
      const formData = {
        box_id: box_id,
        data: {
          box_name: renameValue,
          sub_organization: subOrganizationId
        }
      };
      dispatch(updateCardDesigne(formData, onSuccess));
    }
  };

  const onSuccess = () => {
    setIsLoading(false);
    openSuccessSnackBar('success');
    setIsNameError('');
    setShowRename(false);
  };

  const handleCancelBtn = () => {
    setIsNameError('');
    setShowRename(false);
  };

  const onDelete = () => {
    dispatch(deleteBox({ boxId: box_id }, onSuccessDelete));
  };

  const onSuccessDelete = (boxId) => {
    setIsLoading(false);
    dispatch(setBoxes(boxes?.filter((box) => box.id !== boxId)));
    openSuccessSnackBar('Success');
    setShowDeleteModel(false);
    navigate(`${route.dashboard}`);
  };

  // if (isLoading) return <Loader />;

  return (
    <>
      <div className=" overflow-overlay  p-10">
        <h2 className="text-2xl font-semibold text-center">Boring stuff</h2>
        <div className="card-box mt-10 px-6 py-5">
          <div className="flex items-center justify-between">
            <h3 className="font-semibold">Change Box name and icon</h3>
            <div className="flex items-center gap-2">
              {!showRename ? (
                <CustomColorButtonWithLogo
                  className="btn btn--primary btn--sm text-13"
                  style={{
                    paddingLeft: 15,
                    paddingRight: 15,
                    paddingTop: 6,
                    paddingBottom: 6
                  }}
                  renderSvg={() => <IconEdit className="mr-1" />}
                  label="Change"
                  isLoading={isLoading}
                  onClick={() => {
                    // setShowRename(true);
                    setOpen(true);
                  }}
                />
              ) : (
                <>
                  <CustomColorButtonWithLogo
                    className="btn btn--grey btn--sm text-13"
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 6,
                      paddingBottom: 6
                    }}
                    label="Cancel"
                    isLoading={isLoading}
                    onClick={() => {
                      handleCancelBtn();
                    }}
                  />
                  <CustomColorButtonWithLogo
                    className="btn btn--primary btn--sm text-13"
                    style={{
                      paddingLeft: 15,
                      paddingRight: 15,
                      paddingTop: 6,
                      paddingBottom: 6
                    }}
                    label="Save"
                    isLoading={isLoading}
                    enableLoader={true}
                    loaderInline={true}
                    onClick={() => {
                      onSave();
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {showRename && (
            <>
              <InputField
                type="text"
                className="input-field input-field--md mt-5"
                name="rename"
                placeholder=""
                value={renameValue}
                onChange={(e) => handleChange(e.target.value)}
                error={!isEmpty(isNameError)}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    e.preventDefault();
                    onSave();
                  }
                }}
              />
              {isNameError && <Error message={isNameError} />}
            </>
          )}
        </div>
        {/* <div className="card-box mt-5 px-6 py-5">
        <div className="flex items-center justify-between">
          <h3 className="font-semibold">Delete Box</h3>
          <AccessRestricted
            show={isTeamMember}
            alignment="left"
            customStyle={{
              bottom: '-12px',
              left: 'calc(50% - 244px)'
            }}
          >
            <CustomColorButtonWithLogo
              className={`btn--sm text-13 ${
                isTeamMember ? 'btn--blocked' : 'btn--error'
              }`}
              style={{
                paddingLeft: 15,
                paddingRight: 15,
                paddingTop: 6,
                paddingBottom: 6
              }}
              renderSvg={() => <IconDelete className="mr-1" />}
              label="Delete"
              isLoading={isLoading}
              onClick={() => {
                setShowDeleteModel(!showDeleteModel);
              }}
            />
          </AccessRestricted>
        </div>
      </div> */}
        <DeleteBoxModel
          showConfirmation={showDeleteModel}
          onCancel={() => setShowDeleteModel(false)}
          onConfirm={onDelete}
        />
      </div>
      <RenameBox
        open={open}
        onClose={() => setOpen(false)}
        handleCreateBox={() => {}}
        setIsCreateLoading={() => {}}
        isCreateLoading={false}
        box={currentBox}
        iconStyle={{ width: 20 }}
      />
    </>
  );
};

export default BoringStuff;

/* eslint-disable react-hooks/exhaustive-deps */
import PrimaryButton from 'components/buttons/primary';
import { Loader } from 'components/loader';
import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getBoxData, getBoxFormData } from 'slices/boxes/slice';
import Form from '../../form';
import { ReactComponent as SuccessIcon } from 'assets/icons/icon-success-green.svg';
import { ReactComponent as EbLogo } from 'assets/icons/icon-enquiry-eb-box-logo.svg';
import { getBackgroundColor } from 'slices/businessInfoSlice';

const AddManualEnquiry = (props) => {
  const { open, onClose, isPublicPage, handleOpenNewEnquiry } = props;
  const dispatch = useDispatch();
  const { box_id, subOrgId } = useParams();
  const { activeBoxData } = useSelector((state) => state.boxes);
  const [isLoading, setIsLoading] = useState(false);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const { backgroundColor } = useSelector(
    (state) => state.businessInfoSettings
  );

  const loadCallback = () => {
    setIsLoading(false);
  };

  const callback = () => {
    dispatch(
      getBoxData({
        boxId: box_id,
        callback: loadCallback
      })
    );
  };

  const loadFormData = () => {
    setIsLoading(true);
    dispatch(getBoxFormData({ boxId: box_id, callback }));
  };

  const loadBusinessInfoData = () => {
    setIsLoading(true);
    const payload = {
      subOrgId: subOrgId
    };
    dispatch(getBackgroundColor(payload, loadCallback));
  };

  useEffect(() => {
    if (open) {
      loadFormData();
    }
  }, [box_id, open]);

  useEffect(() => {
    if (isPublicPage) {
      loadFormData();
      loadBusinessInfoData();
    }
  }, [isPublicPage]);

  const onAddOther = () => {
    setShowSuccessPage(false);
  };

  if (isLoading && isPublicPage)
    return (
      <div className="bg-primary w-full h-full">
        <Loader />
      </div>
    );
  return (
    <>
      {isPublicPage ? (
        <div
          className="w-full overflow-y-auto"
          style={
            backgroundColor && backgroundColor?.brand_color
              ? {
                  backgroundColor: backgroundColor?.brand_color,
                  height: '100vh'
                }
              : { height: '100vh', backgroundColor: '#005C6F' }
          }
        >
          <div
            className="bg-white m-auto mt-12 mb-16 rounded-xl"
            style={
              showSuccessPage
                ? { height: 654, maxWidth: 520, width: 520 }
                : { maxWidth: 520, width: 520 }
            }
          >
            {showSuccessPage && (
              <div className="h-full flex justify-center items-center">
                <div>
                  <div className="flex justify-center mb-8">
                    <SuccessIcon />
                  </div>
                  <h3 className="font-medium text-xl text-center">
                    {`Your enquiry has been added to your ${activeBoxData?.box_name} box`}
                  </h3>
                  <div className="flex justify-center mt-8">
                    <PrimaryButton
                      label="Add another"
                      className="btn--md"
                      onClick={onAddOther}
                    />
                  </div>
                </div>
              </div>
            )}
            {!showSuccessPage && (
              <>
                <div
                  className="py-5 px-8 bg-grey-200"
                  style={{
                    boxShadow: 'inset 0px -1px 0px rgba(0, 0, 0, 0.05)',
                    borderTopLeftRadius: 12,
                    borderTopRightRadius: 12
                  }}
                >
                  <h2 className="font-semibold text-xl">
                    {`Add a enquiry to your ${activeBoxData?.box_name} box`}
                  </h2>
                </div>
                <Form
                  isPublicPage={isPublicPage}
                  showSuccess={() => setShowSuccessPage(true)}
                />
              </>
            )}
          </div>
          <div
            className="flex bg-white justify-center rounded-md py-1 px-2 m-auto mb-10 cursor-pointer"
            style={{ maxWidth: 170 }}
            onClick={() => window.open('http://enquirybox.io/', '_blank')}
          >
            <span className="text-13">Built with</span>
            <EbLogo className="mx-1" />
            <span className="text-13">Enquirybox</span>
          </div>
        </div>
      ) : (
        <Modal.Main open={open} className="modal--channels-request">
          <Modal.Header title="Add enquiry" onClose={onClose} />
          <Modal.Body style={{ padding: 0 }}>
            {isLoading ? (
              <div style={{ height: 546 }}>
                <Loader />
              </div>
            ) : (
              <Form
                isPublicPage={isPublicPage}
                onClose={onClose}
                handleOpenNewEnquiry={handleOpenNewEnquiry}
              />
            )}
          </Modal.Body>
        </Modal.Main>
      )}
    </>
  );
};

AddManualEnquiry.defaultProps = {
  handleOpenNewEnquiry: () => {}
};

export default AddManualEnquiry;

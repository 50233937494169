import React from 'react';
import { useSelector } from 'react-redux';

const PlaceHolderFeed = () => {
  const { myInformation } = useSelector((state) => state.myInfo);
  return (
    <div className="flex">
      <div className="team-chat-feed__right-side ml-2">
        <div className={`flex justify-between team-chat-feed__new-enquiry`}>
          <div className="text-sm font-semibold text-grey-800">
            {`Welcome to Your Feed, ${myInformation?.name} 👋`}
          </div>
        </div>
        <div className="py-3 px-3">
          <div className="team-chat-feed__right-side__task-list">
            <p className="text-sm">
              {`Hi ${myInformation?.name}, my name is Amy and I’m your personal enquiry assistant. My job is to make your life easier, help you stay organised and not miss anything important.
                I’ll notify you here in your feed about upcoming tasks,
                when you are mentioned by one of the team and I’ll even send you automated reports if you like 😎`}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaceHolderFeed;

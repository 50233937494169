import React, { useEffect, useState } from 'react';
import BackArrow from '../../components/backArrow';
import { useNavigate, useParams } from 'react-router-dom';
import AddProductInfo from 'modules/chatbotAi/modal/addProductInfo';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { useDispatch } from 'react-redux';
import {
  listAllProducts,
  uploadMultipleProducts
} from 'slices/ai-assistant/productInfo/slice';
import OverLayLoader from 'components/loader/overLayLoader';
import { formatDateTime } from 'utils/dateFormat';
import { numberWithCommas } from 'utils/number';
import InfoIconComponent from '../../components/InfoIcon';
import swal from 'sweetalert';
import { isFileValid } from 'utils/aiAssistant';

const ProductInfo = () => {
  const { assistant_id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [infoModal, setInfoModal] = useState(false);
  const { currentVersionId } = useSelector((state) => state.assistant);
  const { productData } = useSelector((state) => state.productInfo);
  const id = useSelector((state) => state?.myInfo?.myInformation?.id);
  const [isLoading, setIsLoading] = useState(false);

  const handleResponse = () => {
    dispatch(listAllProducts(assistant_id));
    setIsLoading(false);
  };

  const handleFailed = (error) => {
    setIsLoading(false);
    swal({
      title: 'Error',
      text: error?.response?.data?.message,
      icon: 'error',
      className: 'custom-swal',
      button: {
        text: 'Ok',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      }
    });
  };
  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const checkValidFile = isFileValid(file);
    setIsLoading(true);
    if (checkValidFile.isValid) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('edited_by_id', id);
      formData.append('version_id', currentVersionId);
      dispatch(
        uploadMultipleProducts(
          formData,
          assistant_id,
          handleResponse,
          handleFailed
        )
      );
      e.target.value = null;
    } else {
      swal({
        title: 'Error',
        text: checkValidFile.message,
        icon: 'error',
        button: {
          text: 'Ok',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      });
      setIsLoading(false);
    }
  };
  useEffect(() => {
    dispatch(listAllProducts(assistant_id));
  }, []);
  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <section>
        <BackArrow />
        <div className="" style={{ padding: 28 }}>
          <div className="file-upload-container">
            <h4 className="font-semibold">Add product info</h4>
            <p className="text-grey-800 text-xs mt-2">
              Upload multiple products at once or add one by one
            </p>

            <div className="flex items-center justify-center mt-4">
              <div className="">
                <input
                  name="file-upload"
                  type="file"
                  id="uploadMultiple"
                  className="hidden"
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  onChange={handleFileUpload}
                />
                <label
                  htmlFor="uploadMultiple"
                  className="btn btn--blue-outline btn--h30 cursor-pointer"
                >
                  Upload multiple
                </label>
              </div>
              <div className="or-text">or</div>
              <button
                className="btn btn--blue-outline btn--h30"
                onClick={() => setInfoModal(true)}
              >
                Add one by one
              </button>
            </div>
            <div className="text-grey-800 text-xs mt-4">
              Max file size <span className="font-semibold">100MB</span> (Excel,
              CSV)
            </div>
            <div className="text-grey-800 text-xs mt-3">
              <a className="text-grey-800 text-xs font-medium underline cursor-pointer">
                Download
              </a>{' '}
              our product upload template
            </div>
          </div>
          {!isEmpty(productData) && (
            <div className="mt-9">
              <h3 className="text-lg font-semibold">Learned ✨</h3>
              <p className="text-grey-800 mt-3">
                Click on the product or service name to view or edit the learned
                information
              </p>
              <table className="table-basic-info mt-8">
                <thead>
                  <tr>
                    <td style={{ width: '25%' }}>
                      Products or services ({productData?.length})
                    </td>
                    <td>Product Description</td>
                    <td style={{ width: '10%' }}>Price</td>
                    <td className="text-center" style={{ minWidth: '130px' }}>
                      <span>Times used</span>
                      <InfoIconComponent
                        tooltipText="Times used"
                        tooltipStyle={{ top: '-6px' }}
                        infoIconStyle={{
                          marginTop: '5.5px',
                          marginLeft: '7px',
                          zIndex: 100
                        }}
                      />
                    </td>
                    <td style={{ minWidth: '110px' }}>Last updated</td>
                  </tr>
                </thead>
                <tbody>
                  {productData.map((product, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <a
                            className="link-text"
                            onClick={() => navigate(product.id)}
                          >
                            {product.name}
                          </a>{' '}
                          {!product.is_embedded && (
                            <span style={{ color: '#4C5254' }}>
                              ( learning... )
                            </span>
                          )}
                        </td>
                        <td>
                          {product.description?.length <= 140
                            ? product.description
                            : product.description?.substring(0, 140) + '...'}
                        </td>
                        <td>{numberWithCommas(product.price)}</td>
                        <td className="text-center">{product.times_used}</td>
                        <td>
                          {!isEmpty(product.last_edited_on) &&
                            formatDateTime(
                              product?.last_edited_on,
                              'mmm dd, yyyy'
                            )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {infoModal ? (
          <AddProductInfo
            open={infoModal}
            setInfoModal={setInfoModal}
            currentVersionId={currentVersionId}
            id={id}
          />
        ) : (
          <></>
        )}
      </section>
    </>
  );
};

export default ProductInfo;

import axios from 'axios';
import { toQueryString } from 'utils/toQueryString';
import swal from 'sweetalert';
import { BOT_ID, EBOT_EMAIL } from 'utils/constants';

function getAuthToken() {
  if (window.__STORE__) {
    const { auth: { authToken } = {} } = window.__STORE__.getState();
    return authToken;
  }

  const store = JSON.parse(localStorage.getItem('persist:root'));
  return (JSON.parse(store.auth) || {}).authToken;
}

class APIRequest {
  getConfig(headers, options, params) {
    headers = {
      'Content-Type': 'application/json',
      ...headers
    };
    if (options.withNoToken) {
      return { headers };
    }

    const token = getAuthToken();
    if (!token) {
      return { headers };
    }
    const deleteBody = {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      ...options
    };
    if (params) {
      return {
        ...deleteBody,
        data: {
          ...params
        }
      };
    }

    return {
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`
      },
      ...options
    };
  }

  getAxiosObject() {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (
          !error?.request?.responseURL
            ?.split('/')
            .includes('signing_up_invited_team_member')
        ) {
          swal({
            title: 'Error',
            text: error?.message,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
        }
        if (
          error?.request?.responseURL
            ?.split('/')
            .includes('connect_ebot_to_ebox')
        ) {
          swal({
            title: 'Error',
            text: error?.response?.data?.error,
            icon: 'error',
            button: {
              text: 'Ok',
              value: true,
              visible: true,
              className: '',
              closeModal: true
            }
          });
          localStorage.removeItem(EBOT_EMAIL);
          localStorage.removeItem(BOT_ID);
        }

        return Promise.reject(error);
      }
    );
    return axios;
  }

  get(url, query = {}, headers = {}, options = {}) {
    return this.getAxiosObject().get(
      `${url}${toQueryString(query)}`,
      this.getConfig(headers, options)
    );
  }

  post(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().post(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  put(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().put(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  patch(url, params, headers = {}, options = {}) {
    return this.getAxiosObject().patch(
      url,
      params,
      this.getConfig(headers, options)
    );
  }

  delete(url, params, query = {}, headers = {}, options = {}) {
    return this.getAxiosObject().delete(
      `${url}${toQueryString(query)}`,
      this.getConfig(headers, options, params)
    );
  }
}

export default APIRequest;

import { createSlice } from '@reduxjs/toolkit';
import {
  GET_ALL_GLOBAL_CHANNELS_API,
  GET_ALL_CONNECTED_CHANNELS_API,
  GET_ALL_PENDING_CHANNELS_API,
  DELETE_CHANNEL_API,
  CONNECT_CHANNEL_AS_DRAFT_API,
  GET_FB_LEAD_ADS_CONNECTED_PAGES,
  UPDATE_FB_LEAD_ADS_CONNECTED_PAGES,
  UPDATE_CHANNEL_NAME_STATUS,
  SEND_EMAIL_TO_DEVELOPER
} from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { GET_CONNECTED_CHANNEL_BY_ID } from 'urls';
// import CHOICES from 'choices';

// const ZAPIER = CHOICES.ChannelChoices.ZAPIER;

export const channelsSlice = createSlice({
  name: 'channels',
  initialState: {
    isSuccess: false,
    isError: false,
    error: null,
    allChannels: null,
    allConnectedChannels: null,
    allPendingChannels: [],
    fbLeadAdsConnectedPages: null,
    isScrollDown: false,
    connectedChannelById: {},
    previousRoute: null,
    previousSettingsRoute: null
  },
  reducers: {
    setIsSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setIsError: (state, action) => {
      state.isError = action.payload;
    },
    setChannels: (state, action) => {
      state.allChannels = action.payload;
    },
    setAllConnectedChannels: (state, action) => {
      state.allConnectedChannels = action.payload;
    },
    setAllPendingChannels: (state, action) => {
      state.allPendingChannels = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    onSuccess: (state) => {
      state.isError = false;
      state.isSuccess = true;
    },
    onError: (state) => {
      state.isError = true;
      state.isSuccess = false;
    },
    setFbLeadAdsConnectedPages: (state, action) => {
      state.fbLeadAdsConnectedPages = action.payload;
    },
    setIsScrollDown: (state, action) => {
      state.isScrollDown = action.payload;
    },
    setConnectedChannelById: (state, action) => {
      state.connectedChannelById = action.payload;
    },
    setPreviousRoute: (state, action) => {
      state.previousRoute = action.payload;
    },
    setPreviousSettingsRoute: (state, action) => {
      state.previousSettingsRoute = action.payload;
    }
  }
});

export const {
  setChannels,
  setAllConnectedChannels,
  setAllPendingChannels,
  setIsError,
  setIsSuccess,
  setError,
  onError,
  onSuccess,
  setFbLeadAdsConnectedPages,
  setIsScrollDown,
  setConnectedChannelById,
  setPreviousRoute,
  setPreviousSettingsRoute
} = channelsSlice.actions;

export const getAllChannels = (payload, callback) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(GET_ALL_GLOBAL_CHANNELS_API)
        .then((res) => {
          if (res.status === 200) {
            dispatch(onSuccess());
            dispatch(setChannels(res.data));
            if (callback) {
              callback(res.data);
            }
          }
        })
        .catch((err) => {
          dispatch(onError());
          dispatch(setError(err.response.data.email));
        });
    } catch (e) {
      console.log('e', e);
      dispatch(onError());
      dispatch(setError('something went wrong'));
      return '';
    }
  };
};

export const getAllConnectedChannels = (payload, callback) => {
  return async (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      await new APIRequest()
        .get(replaceUrl(GET_ALL_CONNECTED_CHANNELS_API, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(onSuccess());
            // const updatedData = res.data.filter(
            //   (item) => item.channel.channel_type !== ZAPIER
            // );
            dispatch(setAllConnectedChannels(res.data));
            if (callback) {
              callback(res.data);
            }
          }
        })
        .catch((err) => {
          dispatch(onError());
          dispatch(setError(err.response.data.email));
        });
    } catch (e) {
      console.log('e', e);
      dispatch(onError());
      dispatch(setError('something went wrong'));
      return '';
    }
  };
};

export const getAllPendingChannels = (payload, callback) => {
  return async (dispatch, getState) => {
    try {
      const subOrgId = getState().myInfo.subOrganizationId;
      await new APIRequest()
        .get(replaceUrl(GET_ALL_PENDING_CHANNELS_API, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(onSuccess());
            dispatch(setAllPendingChannels(res.data));
            if (callback) {
              callback(res.data);
            }
          }
        })
        .catch((err) => {
          dispatch(onError());
          dispatch(setError(err.response.data.email));
        });
    } catch (e) {
      console.log('e', e);
      dispatch(onError());
      dispatch(setError('something went wrong'));
      return '';
    }
  };
};

export const connectChannelsAsDraft = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, body } = payload;
      await new APIRequest()
        .post(
          replaceUrl(CONNECT_CHANNEL_AS_DRAFT_API, 'subOrgId', subOrgId),
          body
        )
        .then((res) => {
          if (res.status === 201) {
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb(err);
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const deleteChannel = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      await new APIRequest()
        .delete(
          replaceUrl(
            DELETE_CHANNEL_API,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(getAllPendingChannels({ subOrgId }));
            dispatch(getAllConnectedChannels({ subOrgId }));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const getFbLeadAdsConnectedPages = (payload) => {
  const { channelId } = payload;
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(
          replaceUrl(GET_FB_LEAD_ADS_CONNECTED_PAGES, 'channelId', channelId)
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFbLeadAdsConnectedPages(res.data));
          }
        })
        .catch((err) => {
          dispatch(setError(err.response.data.email));
        });
    } catch (e) {
      console.log('e', e);
      dispatch(setError('something went wrong'));
      return '';
    }
  };
};

export const updateFbleadAdsConnectedPageStatus = (payload, onSuccess) => {
  return async (dispatch) => {
    try {
      const { channelId, formId, body } = payload;
      await new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_FB_LEAD_ADS_CONNECTED_PAGES,
            ['channelId', 'formId'],
            [channelId, formId]
          ),
          body
        )
        .then((res) => {
          if (res.status === 201) {
            if (onSuccess) {
              onSuccess();
            }
          }
        })
        .catch((err) => {
          if (errorCb) {
            errorCb(err);
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const getConnectedChannelById = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      await new APIRequest()
        .get(
          replaceUrl(
            GET_CONNECTED_CHANNEL_BY_ID,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setConnectedChannelById(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const updateChannelNameAndStatus = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId, body } = payload;
      await new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_CHANNEL_NAME_STATUS,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          ),
          body
        )
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const sendEmailToDeveloper = (payload) => {
  return async (dispatch) => {
    try {
      const { data, callback } = payload;
      await new APIRequest()
        .post(SEND_EMAIL_TO_DEVELOPER, data)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default channelsSlice.reducer;

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';

const ButtonItem = ({
  icon,
  text,
  onClick,
  active,
  style,
  src,
  name,
  userAvatar
}) => {
  return (
    <div
      onClick={onClick}
      className={`flex items-center gap-2 button px-3 py-1 ${
        active ? 'button__active' : ''
      } truncate`}
      style={style}
    >
      {userAvatar && (
        <Avatar
          src={src}
          initial={name}
          contain
          border={false}
          smaller
          singleChar={true}
          style={{ width: '20px', height: '20px' }}
          initialStyle={{ fontSize: '13px' }}
        />
      )}
      {!userAvatar && <img src={icon} alt="icon-reminder" />}
      <code
        className="inline-block"
        style={{
          width: 1,
          height: 22,
          backgroundColor: '#E5E5E5'
        }}
      ></code>
      <p className="text-grey-900 text-13 font-medium truncate">{text}</p>
    </div>
  );
};

ButtonItem.propTypes = {
  style: PropTypes.object,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  active: PropTypes.bool,
  icon: PropTypes.string.isRequired
};

ButtonItem.defaultProps = {
  active: false,
  style: {},
  onClick: () => {}
};

export default ButtonItem;

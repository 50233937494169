import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/loader';

const CustomColorButtonWithLogo = (props) => {
  const {
    label,
    className,
    onClick,
    renderSvg,
    style,
    isLoading,
    loaderInline,
    enableLoader,
    render,
    disabled,
    renderRightSvg,
    isLoaderWhite
  } = props;
  return (
    <button
      className={`btn ${className}`}
      onClick={onClick}
      style={style}
      disabled={isLoading || disabled}
    >
      {render && typeof render === 'function' && render()}
      {renderSvg && typeof renderSvg === 'function' && renderSvg()}
      {label}
      {renderRightSvg &&
        typeof renderRightSvg === 'function' &&
        renderRightSvg()}
      {isLoading && enableLoader && (
        <Loader
          loaderInline={loaderInline}
          smaller
          isLoaderWhite={isLoaderWhite}
        />
      )}
    </button>
  );
};

CustomColorButtonWithLogo.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  renderSvg: PropTypes.func,
  onClick: PropTypes.func,
  isLoading: PropTypes.bool,
  enableLoader: PropTypes.bool,
  loaderInline: PropTypes.bool,
  disabled: PropTypes.bool,
  isLoaderWhite: PropTypes.bool
};

CustomColorButtonWithLogo.defaultProps = {
  className: '',
  label: '',
  onClick: () => {},
  isLoading: false,
  enableLoader: false,
  loaderInline: false,
  disabled: false,
  isLoaderWhite: false
};

export default CustomColorButtonWithLogo;

const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// sign up invited new users
export const SIGN_UP_INVITED_USER_NEW = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/signing_up_invited_team_member`;

// update role for organization users
export const UPDATE_OR_DELETE_ROLE_FOR_ORGANIZATION_USER = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/organization_users/:OrgUserId`;

// reinvite organization users
export const REINVITE_ORGANIZATION_USER = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/organization_users/:OrgUserId/re_invite_team_member`;

// sign up existing invited user
export const SIGN_UP_INVITED_USER_EXISTING = `${BASE_API_URL}/organization/sub_organizations/:subOrgId/signing_up_invited_team_member_existing_user`;

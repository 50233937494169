/* eslint-disable no-unused-vars */
import CHOICES from 'choices';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getRoutePath } from 'utils/channels';
import { route } from 'utils/route';

const { WEBFORM, FACEBOOK_LEAD_ADS, SMS, ZAPIER } = CHOICES.ChannelChoices;

const ConnectedChannelTab = (props) => {
  const { activeTab, channelType } = props;
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const channelId = pathname?.split('/')[5];

  const handleTabClick = (activeTab) => {
    if (activeTab === 0) {
      navigate(
        `${route.channels}/${getRoutePath(
          channelType
        )}/${channelType}/connected/${channelId}/automations`
      );
    } else {
      if (activeTab === 1) {
        navigate(
          `${route.channels}/${getRoutePath(
            channelType
          )}/${channelType}/connected/${channelId}/settings`
        );
      } else {
        if (channelType === SMS) {
          navigate(
            `${route.channels}/${getRoutePath(
              channelType
            )}/${channelType}/connected/${channelId}/billing`
          );
        } else {
          navigate(
            `${route.channels}/${getRoutePath(
              channelType
            )}/${channelType}/connected/${channelId}/forms`
          );
        }
      }
    }
  };

  return (
    <div className="tabs-wrapper pt-4 px-8">
      {channelType !== ZAPIER && (
        <div
          className={`tabs-header tabs-header__channel ${
            activeTab === 0 ? 'tabs-header__active' : ''
          }`}
          onClick={() => handleTabClick(0)}
        >
          Automations ✨
        </div>
      )}
      <div
        className={`tabs-header tabs-header__channel ${
          activeTab === 1 ? 'tabs-header__active' : ''
        }`}
        onClick={() => handleTabClick(1)}
      >
        Settings
      </div>
      {(channelType === WEBFORM || channelType === FACEBOOK_LEAD_ADS) && (
        <div
          className={`tabs-header tabs-header__channel ${
            activeTab === 2 ? 'tabs-header__active' : ''
          }`}
          onClick={() => handleTabClick(2)}
        >
          {channelType === WEBFORM ? 'Your forms' : 'Lead Ad Forms'}
        </div>
      )}
      {channelType === SMS && (
        <div
          className={`tabs-header tabs-header__channel ${
            activeTab === 2 ? 'tabs-header__active' : ''
          }`}
          onClick={() => handleTabClick(2)}
        >
          SMS Billing
        </div>
      )}
    </div>
  );
};

export default ConnectedChannelTab;

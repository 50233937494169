import React, { useEffect, useRef } from 'react';
import TaskFooter from '../task/footer';
import { useSelector, useDispatch } from 'react-redux';
import { setTaskTitle } from 'slices/task/slice';

const Task = (props) => {
  const dispatch = useDispatch();
  const textAreaRef = useRef();
  const { taskTitle, editableTaskId } = useSelector((state) => state.tasks);

  useEffect(() => {
    textAreaRef.current.focus();
  }, [editableTaskId]);

  return (
    <div className="chat-message-footer__body" style={props.style}>
      <textarea
        ref={textAreaRef}
        onChange={(e) => dispatch(setTaskTitle(e.target.value))}
        value={taskTitle}
        rows="2"
        cols="5"
        placeholder="Write the name of the task"
        className="text-base-1 text-grey-900 resize-none w-full px-2 py-2 border-none outline-none"
        style={{ height: '90px', fontFamily: 'Roboto', fontSize: '14px' }}
        // autoFocus
      ></textarea>
      <TaskFooter {...props} />
    </div>
  );
};

Task.defaultProps = {
  style: {}
};

export default Task;

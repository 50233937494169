import aiIcon from 'assets/icons/ai-icon.svg';
import AIAssistantLoader from 'modules/chatbotAi/modal/AIAssistantLoader';
import CreateAIAssistant from 'modules/chatbotAi/modal/CreateAIAssistant';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Sidebar from '../sidebar';
import Avatar from 'components/avatar';
const CreateChatAi = () => {
  const [aiModal, setAiModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { listTemplates } = useSelector((state) => state.assistant);
  const [templateId, setTemplateId] = useState('');
  useEffect(() => {
    setTemplateId('');
  }, []);
  const onCloseModal = () => {
    setAiModal(false);
  };

  const loading = (value) => {
    setIsLoading(value);
  };

  return (
    <>
      <Sidebar />
      <div
        className="dashboard__main--create-bot__section-right"
        style={{ padding: '46px' }}
      >
        <h2 className="text-2xl font-semibold text-center">
          Create the perfect AI assistant
        </h2>
        <div className="mt-14" style={{ width: 686, margin: '60px auto 0' }}>
          <div
            className="flex items-center gap-5 bot-template-list"
            onClick={() => {
              setTemplateId('');
              setAiModal(true);
            }}
          >
            <figure
              className="avatar-container"
              style={{ width: 53, height: 53 }}
            >
              <img src={aiIcon} alt="ai-icon" />
            </figure>
            <div className="">
              <h3 className="text-lg font-semibold">Start from scratch</h3>
              <p className="text-grey-800 mt-1">
                Designed to answer questions and supercharge sales enquiries for
                your business...
              </p>
            </div>
          </div>
          <div className="gradient-text mt-7">Use a template</div>
          <span className="ml-1">✨</span>
          <ul className="bot-template-list-wrapper mt-7">
            {listTemplates.map((template) => (
              <li
                key={template.id}
                className="bot-template-list"
                onClick={() => {
                  setTemplateId(template.id);
                  setAiModal(true);
                }}
              >
                <Avatar
                  style={{
                    width: 53,
                    height: 53,
                    backgroundColor: '#FFFFFF',
                    boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                  }}
                  cover
                  src={template?.avatar?.s3_url}
                  large
                />
                <div className="ml-5">
                  <h3 className="bot-template-list__title">
                    {template.template_name}
                  </h3>

                  <p className="bot-template-list__desc">
                    {template.description}
                  </p>
                </div>
              </li>
            ))}
          </ul>
        </div>
        {aiModal ? (
          <CreateAIAssistant
            open={aiModal}
            onClose={onCloseModal}
            loading={loading}
            template_id={templateId}
          />
        ) : (
          <></>
        )}
        {isLoading ? <AIAssistantLoader open={isLoading} /> : <></>}
      </div>
    </>
  );
};

export default CreateChatAi;

import React from 'react';

const DragEmpty = () => {
  return (
    <div
      className="box-card box-card box-card--empty mb-3"
      style={{ height: 78 }}
    >
      <div className="text-sm text-center flex items-center px-12">
        Drag and drop enquires into this stage
      </div>
    </div>
  );
};

export default DragEmpty;

import { useEffect, useRef, useState } from 'react';
import aiIcon from 'assets/icons/ai-icon.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteAiAssistant,
  duplicateAssistant
} from 'slices/ai-assistant/assistant/slice';
import BotListing from './components/BotListing';
import { useSnackbar } from 'components/Snackbar';
import OverLayLoader from 'components/loader/overLayLoader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const Sidebar = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { listAssistants } = useSelector((state) => state.assistant);
  const { assistant_id } = useParams();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [assistantId, setAssistantId] = useState(null);
  const [popUpTop, setpopUpTop] = useState(false);
  const containerRef = useRef(null);
  const h = useRef(0);

  useEffect(() => {
    const div = containerRef.current;
    const divOffset = div.offsetHeight - 50;
    const hasVerticalScrollbar = div.scrollHeight > div.clientHeight;
    if (hasVerticalScrollbar || h.current >= divOffset) setpopUpTop(true);
    else setpopUpTop(false);
  }, [listAssistants, h.current]);

  const duplicateSuccessCb = (id = null) => {
    setIsLoading(false);
    openSuccessSnackBar('Duplicated');
    navigate(`/chatbot-ai/assistant/${id}/personality`);
  };

  const duplicateFailedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('Duplicate failed');
  };

  const deleteSuccesCb = () => {
    openSuccessSnackBar('Deleted');
    setIsLoading(false);
    setShowDelete(false);
    navigate('/chatbot-ai/create');
  };

  const deleteFailedCb = () => {
    openErrorSnackBar('Deletion failed');
    setIsLoading(false);
    setShowDelete(false);
  };

  const handleDelete = (id) => {
    setShowDelete(true);
    setAssistantId(id);
  };

  const deleteAssistant = () => {
    setIsLoading(true);
    dispatch(deleteAiAssistant(assistantId, deleteSuccesCb, deleteFailedCb));
  };

  const handleDuplicate = (id) => {
    setIsLoading(true);
    dispatch(duplicateAssistant(id, duplicateSuccessCb, duplicateFailedCb));
  };

  const callOnMount = (height) => {
    h.current = h.current + height;
  };

  const callOnUnmount = (height) => {
    h.current = h.current - height;
  };

  useEffect(() => {
    setTimeout(() => {
      var element = document.getElementById(assistant_id);
      element?.scrollIntoView({
        block: 'end',
        behavior: 'smooth'
      });
    }, 700);
  }, []);

  return (
    <>
      {isLoading && <OverLayLoader />}
      <div className="dashboard__main--create-bot__section-left">
        <div
          className="flex items-center gap-5 px-6 pt-5 pb-4 border-b border-grey-400"
          style={{ paddingBottom: '14px', paddingTop: '15px' }}
        >
          <figure
            className="avatar-container avatar-container--contain"
            style={{
              width: 52,
              height: 52,
              backgroundColor: '#FFFFFF',
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
          >
            <img src={aiIcon} alt="ai-icon" />
          </figure>
          <p className="text-xl font-semibold">AI Assistant</p>
        </div>
        <div
          className="border-b border-grey-400"
          style={{ padding: '9px 10px' }}
        >
          <div
            className="flex items-center justify-between border-radius-10 hover:bg-grey-90 transition-all cursor-pointer"
            style={{ padding: '10px 16px' }}
            onClick={() => navigate('/chatbot-ai/create')}
          >
            <span className="text-lg font-medium pr-5">
              Create an assistant
            </span>
            <div
              className="flex items-center justify-center bg-white rounded-full"
              style={{ width: 26, height: 26 }}
            >
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none">
                <path
                  d="M5.6817 11.1761L5.71528 1.52883"
                  stroke="#2B3639"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
                <path
                  d="M0.901367 6.33557L10.4956 6.36933"
                  stroke="#2B3639"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <ul
          className="bot-assistant-list-wrapper overflow-overlay"
          ref={containerRef}
        >
          {listAssistants.map((item, index) => {
            const { id, name, avatar, industry } = item.version_bot;
            const lastIndex = listAssistants.length - 1;
            return (
              <span key={index}>
                <BotListing
                  key={index}
                  id={id}
                  name={name}
                  avatar={avatar}
                  industry={industry}
                  item={item}
                  handleDelete={handleDelete}
                  handleDuplicate={handleDuplicate}
                  lastIndex={lastIndex === index && popUpTop ? true : false}
                  callOnMount={callOnMount}
                  callOnUnmount={callOnUnmount}
                  isActiveBot={id === assistant_id}
                />
              </span>
            );
          })}
        </ul>
        <DeleteConfirmation
          showConfirmation={showDelete}
          onCancel={() => setShowDelete(false)}
          onConfirm={() => {
            deleteAssistant();
          }}
          title="Are you sure you want to delete?"
          description="Once an AI bot is deleted, it cannot be recovered"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default Sidebar;

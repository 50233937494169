/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { WHOEVER__IS_AVAILABLE } from 'utils/constants';
import { GET_LIST_ENQUIRIES_WITH_TASKS, GET_ENQUIRY_TASK_COUNT } from 'urls';
import CHOICES from 'choices';

const { OVERDUE, DUE_TODAY } = CHOICES.TaskLeadApiFilterChoices;
const initialState = {
  things_to_do_count: {},
  task_due_today: {},
  task_overdue: {}
};

export const taskThingsToDo = createSlice({
  name: 'taskThingsToDo',
  initialState,
  reducers: {
    setTaskDueToday: (state, action) => {
      state.task_due_today = action.payload;
    },
    setTaskOverdue: (state, action) => {
      state.task_overdue = action.payload;
    },
    setThingsToDoCount: (state, action) => {
      state.things_to_do_count = action.payload;
    }
  }
});

export const { setTaskDueToday, setTaskOverdue, setThingsToDoCount } =
  taskThingsToDo.actions;

export const getAllTasks = (...opt) => {
  return async (dispatch) => {
    dispatch(getTasksDueToday(...opt));
    dispatch(getTasksOverdue(...opt));
  };
};

export const getTasksDueToday = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: DUE_TODAY,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskDueToday(res.data.data));
            //   dispatch(setLeadTasks(res.data));
            //   if (callback) {
            //     callback();
            //   }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTasksOverdue = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      task_status: OVERDUE,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTaskOverdue(res.data.data));
            //   if (callback) {
            //     callback();
            //   }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTaskEnquiryCount = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      return await new APIRequest()
        .get(replaceUrl(GET_ENQUIRY_TASK_COUNT, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setThingsToDoCount(res.data));
            //   if (callback) {
            //     callback();
            //   }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default taskThingsToDo.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from './list';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import Avatar from 'components/avatar';
import { ReactComponent as UserSvg } from '../../../assets/icons/user-circle.svg';
import { ReactComponent as UserSvgGreen } from 'assets/icons/box-filter-assign-user-primary.svg';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';

const AssignedUser = () => {
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(null);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const [notes, setNotes] = useState('');

  const onSubmit = async (isAssigned) => {
    // const formData = {
    //   leadId: cardId,
    //   body: {
    //     assigned_to: selected?.user_id,
    //     note: notes
    //   }
    // };
    // if (isAssigned) {
    //   dispatch(unAssigntoUserLead(formData, userUnAssigned))
    // } else {
    //   dispatch(assigntoUserLead(formData, userAssigned));
    // }
    setShowDropDown(false);
    setSelected(null);
    dispatch(getTaskEnquiryCount());
  };

  useEffect(() => {
    checkAlreadyAssigned();
  }, [cardId, activeLead]);

  const checkAlreadyAssigned = () => {
    const isAlreadyAssigned = !isEmpty(activeLead?.assigned_to);
    isAlreadyAssigned &&
      setActive({
        ...activeLead.assigned_to,
        user_id: activeLead.assigned_to.id
      });
  };

  const RenderButton = () => {
    return (
      <PrimaryWithLogo
        label={
          selected
            ? selected.first_name
            : active
            ? active.first_name
            : `Unassigned`
        }
        renderSvg={() => {
          if (selected)
            return (
              <Avatar
                src={selected?.avatar}
                initial={selected?.first_name}
                border={false}
                initialStyle={{
                  fontSize: 10,
                  fontWeight: 500,
                  marginBottom: 0
                }}
                mini
                contain
                singleChar
              />
            );
          if (!isEmpty(active)) {
            return (
              <Avatar
                src={active.avatar}
                initial={active?.first_name}
                // style={{ background: 'none', border: '1px solid #fff' }}
                initialStyle={{
                  fontSize: 10,
                  fontWeight: 500,
                  marginBottom: 0
                }}
                border={false}
                mini
                singleChar
              />
            );
          }
          return showDropDown ? <UserSvgGreen /> : <UserSvg />;
        }}
        onClick={() => setShowDropDown(!showDropDown)}
        className={`chat-message-header-button ${
          showDropDown ? 'clicked' : ''
        }`}
        style={{ padding: '4px 14px' }}
      />
    );
  };

  const onSelect = (option) => {
    if (active) {
      if (active.user_id === option.user_id) {
        setSelected(null);
      } else {
        setSelected(option);
      }
    } else {
      setSelected(option);
    }
  };

  return (
    <List
      title="Assign To"
      renderButton={RenderButton}
      options={organizationUser?.results.filter((i) => !i.is_disabled)}
      open={showDropDown}
      onSelect={onSelect}
      onClose={(state) => setShowDropDown(state)}
      selected={selected}
      active={active}
      setNotes={setNotes}
      setSelected={setSelected}
      onSubmit={onSubmit}
      notes={notes}
      setActive={setActive}
    />
  );
};

export default AssignedUser;

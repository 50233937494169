/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Routes, Route, useParams } from 'react-router-dom';
import Home from './home';
import BoxSettings from './settings/home';
import { useDispatch } from 'react-redux';
import {
  resetAutomationStore,
  setBoxAutomations
} from 'slices/boxAutomation/slice';
import { setActiveBoxNewLead } from 'slices/boxes/slice';
import {
  setPreviousClickedLead,
  setPreviousClickedLeadList
} from 'slices/eboxLead';

const BoxMain = () => {
  const dispatch = useDispatch();
  const { box_id } = useParams();

  useEffect(() => {
    // reset box automation store when box_id changes
    dispatch(setPreviousClickedLeadList(0));
    dispatch(setPreviousClickedLead(null));
    dispatch(setBoxAutomations([]));
    dispatch(resetAutomationStore());
    dispatch(setActiveBoxNewLead(null));
  }, [box_id]);

  return (
    <Routes>
      <Route path={`/*`} element={<Home />} />
      <Route path={`/settings/*`} element={<BoxSettings />} />
    </Routes>
  );
};

export default BoxMain;

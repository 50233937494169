import React from 'react';
import PropTypes from 'prop-types';
import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';

const DeleteConfirmation = (props) => {
  const {
    showConfirmation,
    onCancel,
    onConfirm,
    title,
    description,
    confirmButtonText,
    containerStyle,
    isLoading,
    hideCancelBtn,
    renderSvg,
    mainStyle
  } = props;
  return (
    <Modal.Main
      open={showConfirmation}
      className="modal--channels-request"
      containerStyle={containerStyle}
      mainStyle={mainStyle}
    >
      {/* <Modal.Header title="" onClose={onCancel} /> */}
      <Modal.Body className="pb-10 py-8 overflow-y-auto">
        <React.Fragment>
          {renderSvg && typeof renderSvg === 'function' && (
            <div className="flex justify-center mb-5">{renderSvg()}</div>
          )}
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">{title}</h2>
          </div>
          <div className="text-13 text-center mt-3 px-16 ">{description}</div>
          <div className="flex items-center gap-2 justify-center px-8 mt-6">
            {!hideCancelBtn && (
              <CustomColorButton
                label="Cancel"
                className="btn--wide-md-40 btn--cancel text-base-1 mr-3 cursor-pointer"
                onClick={onCancel}
              />
            )}
            <CustomColorButton
              label={confirmButtonText}
              className="btn--wide-md-40 text-base-1 bg-red-900 text-white mr-3 cursor-pointer relative"
              onClick={onConfirm}
              enableLoader
              isLoading={isLoading}
              loaderInline
              isLoaderWhite={true}
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

DeleteConfirmation.propTypes = {
  showConfirmation: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  hideCancelBtn: PropTypes.bool,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  isLoading: PropTypes.bool
};

DeleteConfirmation.defaultProps = {
  confirmButtonText: 'Delete',
  isLoading: false,
  hideCancelBtn: false
};

export default DeleteConfirmation;

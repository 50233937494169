/* eslint-disable react/no-unescaped-entities */
import React, { useState } from 'react';
// import { ReactComponent as IconGoogle } from 'assets/icons/icon-gmail.svg';
// import CHOICES from 'choices';
import { ReactComponent as Email } from 'assets/icons/Icon-Email-22.svg';
import PrimaryButton from 'components/buttons/primary';
// import InputField from 'components/inputs/textField';
// import { ReactComponent as IconUser } from 'assets/icons/icon-user-round-grey.svg';
// import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';
// import CustomColorButton from 'components/buttons/customColorButton';
import ConnectEmail from './connectEmail';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSelector } from 'react-redux';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const CustomEmail = ({ switchEmail }) => {
  const [isSetup, setIsSetup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnect, setIsConnect] = useState(false);
  const [showSwitchConfirmation, setShowSwitchConfirmation] = useState(false);

  const onCancel = () => {
    setIsSetup(false);
    setIsConnect(false);
  };
  const { email_provider } = useSelector((state) => state.emailAddress);

  const handleSwitch = () => {
    if (
      !email_provider?.is_active &&
      !isSetup &&
      !isConnect &&
      email_provider?.is_data_submitted &&
      email_provider?.is_ses_verified_for_sending
    ) {
      setShowSwitchConfirmation(true);
    }
  };

  const handleConfirm = () => {
    switchEmail({ id: email_provider?.id });
    setShowSwitchConfirmation(false);
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showSwitchConfirmation}
        onCancel={() => setShowSwitchConfirmation(false)}
        onConfirm={handleConfirm}
        title={`Are you sure you want to Switch to this email ${email_provider?.user?.email} ? `}
        description=""
        confirmButtonText={'Confirm'}
      />
      <li
        className={
          email_provider?.is_active
            ? 'list-hover list-hover__active relative'
            : 'list-hover relative'
        }
        style={{ padding: 0, marginTop: '35px' }}
        onClick={handleSwitch}
      >
        {isLoading && <OverLayLoader />}
        <div className="list-hover__container" style={{ padding: '1.25rem' }}>
          <figure className="general-settings__card-list__avatar-container p-2">
            <Email width={22} />
          </figure>
          {email_provider?.is_ses_verified_for_sending &&
          email_provider.is_data_submitted ? (
            <div className="ml-4">
              <h4 className="text-base-1 font-semibold">
                Your own email address
              </h4>
              <p className="text-sm text-grey-700 mt-1">
                When you reply to enquiries your email will come from:
              </p>
              <span className="font-medium text-sm text-primary">
                {`${email_provider?.user?.name} : ${email_provider?.user?.email}`}
              </span>
              {!isConnect && (
                <PrimaryButton
                  className="btn--xs mt-3"
                  label="Edit sender name"
                  style={{ paddingLeft: 18, paddingRight: 18 }}
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setIsSetup(true);
                    setIsConnect(true);
                  }}
                />
              )}
            </div>
          ) : (
            <div className="ml-4">
              <h4 className="text-base-1 font-semibold">
                Send replies from your own email address
              </h4>
              <p className="text-sm text-grey-700 mt-1">
                Sending email from your own email address will improve the open
                rates of your replies as they are from your domain that your
                customer will recognise
              </p>
              {!isSetup && (
                <PrimaryButton
                  className="btn--xs mt-3"
                  label="Setup"
                  style={{ paddingLeft: 18, paddingRight: 18 }}
                  onClick={() => setIsSetup(true)}
                />
              )}
            </div>
          )}
        </div>
        {email_provider?.is_active && (
          <div
            className="absolute left-0 bg-secondary text-white text-11 font-medium rounded-t-lg"
            style={{ padding: '2px 10px', top: -21 }}
          >
            {' '}
            You're using this
          </div>
        )}
        {isSetup && (
          <ConnectEmail
            setIsConnect={setIsConnect}
            isConnect={isConnect}
            onCancel={onCancel}
            setIsLoading={setIsLoading}
            setIsSetup={isSetup}
          />
        )}
      </li>
    </>
  );
};

export default CustomEmail;

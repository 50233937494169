/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import iconClock from 'assets/icons/icon-clock.svg';
import useClickOutside from 'hooks/useOutsideClick';
import ButtonItem from './button';
import { TaskTimes } from './helper';
import { useSelector, useDispatch } from 'react-redux';
import { setTaskTime } from 'slices/task/slice';
import CustomToolTip from 'components/CustomTooltip';
import moment from 'moment';

const TaskTime = () => {
  const { taskTime, taskDate } = useSelector((state) => state.tasks);
  const [showReminderTime, setShowReminderTime] = useState(false);

  const dispatch = useDispatch();
  const timeDom = useRef();

  useClickOutside(() => setShowReminderTime(false), timeDom.current);

  const checkPastDate = (optionTime) => {
    // Date to check
    const givenDate = moment(taskDate);
    // Get current date
    const currentDate = moment();
    const isSelectedDateToday = givenDate.isSame(currentDate, 'day');
    const isPastDate = givenDate.isBefore(currentDate, 'day');
    // if task is edited and is in past date then all time should be blocked
    if (isPastDate) {
      return true;
    }
    // if same date is selected, then past time should be blocked
    if (isSelectedDateToday) {
      if (optionTime <= currentDate.format('HH')) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (showReminderTime) {
      const activeTime = document.getElementById(`time-${taskTime}`);
      if (activeTime) {
        activeTime.parentNode.scrollTop = activeTime.offsetTop - 10;
      }
    }
  }, [showReminderTime]);

  const onTimeSelect = (title) => {
    setShowReminderTime(false);
    dispatch(setTaskTime(title));
  };

  return (
    <div ref={timeDom}>
      <div
        className={`dropdown-menu dropdown-menu--sm ${
          showReminderTime && 'open'
        }`}
        style={{ width: '125px', left: 'auto', bottom: '42px' }}
      >
        <div className="dropdown-menu__overflow-y-scroll">
          {TaskTimes.map((item, index) => {
            let isSelected = taskTime == item.title;
            const isPastDate = checkPastDate(item['24hourformat']);
            return (
              <div
                id={`time-${item.title}`}
                key={item.title}
                className={`dropdown-menu__list ${
                  isSelected ? 'selected' : ''
                } ${isPastDate ? 'grey-field' : ''}`}
                onClick={isPastDate ? () => {} : () => onTimeSelect(item.title)}
              >
                {item.title}
              </div>
            );
          })}
        </div>
      </div>
      <CustomToolTip
        position="top"
        tooltipText="Due time"
        hideTooltip={showReminderTime}
      >
        <ButtonItem
          active={showReminderTime}
          icon={iconClock}
          text={taskTime}
          onClick={() => {
            setShowReminderTime(!showReminderTime);
          }}
          style={{ width: '109px' }}
        />
      </CustomToolTip>
    </div>
  );
};

export default React.memo(TaskTime);

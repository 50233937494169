/* eslint-disable no-useless-escape */
import { string, object, mixed, ref, lazy } from 'yup';
const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex =
  '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})';
export const urlRegex =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#-]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zAZ0-9_]+=\w+)*)?$/gm;
export const websiteUrl =
  /^((http|https):\/\/.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/g;
export const urlFieldValidation =
  /^(https?|ftp):\/\/(([a-z\d]([a-z\d-]*[a-z\d])?\.)+[a-z]{2,}|localhost)(\/[-a-z\d%_.~+]*)*(\?[;&a-z\d%_.~+=-]*)?(\#[-a-z\d_]*)?$/i;

import {
  minimumCharactersMsg,
  requiredMsg,
  requiredSelectMsg,
  invalidMsg,
  tooLargeMsg,
  unsupportedMsg,
  onlyAlphabetsMsg,
  maximumCharactersMsg,
  doesNotMatch,
  passwordValidation
} from 'utils/messages';

const FILE_SIZE = 5 * 1024 * 1024;
const SUPPORTED_FORMATS = ['png', 'jpeg', 'jpg'];
const MIME_TYPES = SUPPORTED_FORMATS.map((format) => `image/${format}`);

const fieldsAndValidations = {
  fullName: string()
    .strict()
    .trim('Full name must not start or end with white space')
    .min(4, minimumCharactersMsg('Full name', 4))
    .max(30, maximumCharactersMsg('Full name', 30))
    .matches(new RegExp('^[a-zA-Z ]+$', 'i'), onlyAlphabetsMsg('Full name'))
    .required(requiredMsg('Full name')),
  name: string()
    .strict()
    .trim('Name must not start or end with white space')
    .min(4, minimumCharactersMsg('Name', 4))
    .max(30, maximumCharactersMsg('Name', 30))
    .matches(new RegExp('^[a-zA-Z ]+$', 'i'), onlyAlphabetsMsg('Name'))
    .required(requiredMsg('Name')),
  firstName: string()
    .strict()
    .trim('First Name must not start or end with white space')
    .min(4, minimumCharactersMsg('First Name', 4))
    .max(30, maximumCharactersMsg('First Name', 30))
    .matches(new RegExp('^[a-zA-Z ]+$', 'i'), onlyAlphabetsMsg('First Name'))
    .required(requiredMsg('first Name')),
  lastName: string()
    .strict()
    .trim('Last Name must not start or end with white space')
    .min(1, minimumCharactersMsg('Last Name', 1))
    .max(30, maximumCharactersMsg('Last Name', 30))
    .matches(new RegExp('^[a-zA-Z ]+$', 'i'), onlyAlphabetsMsg('Last Name'))
    .nullable(),
  email: string()
    .matches(emailRegex, invalidMsg('Email'))
    .required(requiredMsg('email')),
  password: string()
    .min(8, 'Your password is weak. Use minimum of 8 characters')
    .required(requiredMsg('password'))
    .matches(passwordRegex, passwordValidation()),
  passwordConfirmation: string()
    .oneOf([ref('password')], doesNotMatch('Password'))
    .required(requiredMsg('password confirmation')),
  username: string()
    .min(6, minimumCharactersMsg('Username', 6))
    .required(requiredMsg('username'))
    .matches(new RegExp('^[a-z0-9A-Z_-]+$', 'i'), invalidMsg('username')),
  picture: lazy((val) => {
    if (typeof val === 'string' || val === null || val === undefined) {
      return string().nullable();
    }

    return mixed()
      .test(
        'fileType',
        unsupportedMsg('File type', SUPPORTED_FORMATS),
        (value) => MIME_TYPES.includes(value.type)
      )
      .test(
        'fileSize',
        tooLargeMsg('File size', '5MB'),
        (value) => value.size <= FILE_SIZE
      );
  }),
  timezone: string().required(requiredMsg('time zone')),
  businessName: string().strict().required(requiredMsg('business name')),
  websiteUrl: string()
    .required('website url is required')
    .matches(urlRegex, 'Enter valid url'),
  teamSize: object().nullable().required(requiredSelectMsg('team size')),
  industry: object().nullable().required(requiredSelectMsg('industry')),
  webformName: string().strict().required(requiredMsg('webform name')),
  channelName: string()
    .required('channel name is required')
    .max(30, maximumCharactersMsg('channel name', 30)),
  phone: string()
    .required('Phone number is required')
    .max(15, maximumCharactersMsg('Phone number', 15)),
  countryName: object().nullable().required(requiredSelectMsg('country'))
};

export const getFormSchema = (fields) => {
  if (Array.isArray(fields)) {
    return fields.reduce(
      (schema, field) =>
        !fieldsAndValidations[field]
          ? schema
          : { ...schema, [field]: fieldsAndValidations[field] },
      {}
    );
  }
};

export default fieldsAndValidations;

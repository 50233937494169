import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectedNote from '../chatActions/selectedNote';
import Avatar from 'components/avatar';

const NoteContainer = (props) => {
  const {
    open,
    setNotes,
    onSubmit,
    isLoading,
    src,
    name,
    subName,
    avatarStyles,
    submitBtnText,
    onBack
  } = props;
  const [note, setNote] = useState('');

  const RenderSelectedAvatar = () => {
    return (
      <>
        <Avatar
          src={src}
          initial={name}
          border={false}
          initialStyle={{
            fontSize: '13px'
          }}
          smaller
          className="mr-3"
          contain
          singleChar
          style={avatarStyles}
        />
        <p className="text-sm text-grey-900 font-semibold">
          {name} {subName}
        </p>
      </>
    );
  };

  return (
    <SelectedNote
      open={open}
      renderAvatar={RenderSelectedAvatar}
      placeholder={`Write a note for ${name} (Optional)`}
      submitBtnText={submitBtnText}
      onBack={onBack}
      value={note}
      onChange={(e) => {
        setNote(e.target.value);
        setNotes(e.target.value);
      }}
      onSubmit={() => onSubmit(false)}
      isLoading={isLoading}
    />
  );
};

NoteContainer.propTypes = {
  avatarStyles: PropTypes.object,
  submitBtnText: PropTypes.string,
  subName: PropTypes.string
};

NoteContainer.defaultProps = {
  avatarStyles: {},
  submitBtnText: 'Assign',
  subName: '',
  onBack: () => {}
};

export default NoteContainer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import ErrorMessage from 'components/errorMessage';
import Select from 'components/inputs/select';
import TextArea from 'components/inputs/textarea';
import TextInputField from 'components/inputs/textField';
import { isEmailValid, isEmpty, isPhoneValidWithoutPlus } from 'utils/utils';
import TextTypeForm from '../questions/text';
import DropdownTypeForm from '../questions/dropdown/Index';
import DateTypeForm from '../questions/date';
import OptionTypeForm from '../questions/mcq';
import ValueTypeForm from '../questions/value';
import { useDispatch, useSelector } from 'react-redux';
import CHOICES from 'choices';
import { route } from 'utils/route';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createManualEnquiryFromBoxForm,
  setLoadEnquiryInStage
} from 'slices/boxes/slice';
import moment from 'moment';
// import { animateScroll } from 'react-scroll';
import { getAllCounts } from 'slices/allCount/slice';
import { ReactComponent as CaretIcon } from 'assets/icons/icon-caret-down.svg';
import usePlanAccess from 'hooks/usePlanAccess';
import { getFreeEnquiriesCount } from 'slices/subscription/slice';

const { TEXT, DROPDOWN, DATETIME, MCQ, MCQ_MUTIPLE_SELECT, DATE_RANGE, VALUE } =
  CHOICES.QuestionChoices;

const Form = (props) => {
  const { isPublicPage, onClose, showSuccess, handleOpenNewEnquiry } = props;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const nodeRef = useRef();
  const { box_id, box_type } = useParams();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [isError, setIsError] = useState({});
  const [stageOptions, setStageOptions] = useState([]);
  const [stageId, setStageId] = useState('');
  const [note, setNote] = useState('');
  const [final, setFinal] = useState({});
  const [isStartDateError, setIsStartDateError] = useState({});
  const [isEndDateError, setIsEndDateError] = useState({});
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const { boxFormData, activeBoxData } = useSelector((state) => state.boxes);

  const { is_free_plan } = usePlanAccess();

  useEffect(() => {
    const obj = {};
    boxFormData?.question_data?.forEach((que) => {
      obj[que.id] = {};
    });
    setFinal(obj);
  }, [boxFormData]);

  useEffect(() => {
    if (!isEmpty(activeBoxData?.boxstage_set)) {
      setStageOptions(
        activeBoxData?.boxstage_set.map((item) => {
          return {
            id: item.id,
            label: item.stage_name,
            value: item.stage_name
          };
        })
      );
    }
  }, [activeBoxData]);

  const getValue = (item) => {
    switch (item) {
      case 'First Name':
        return firstName;
      case 'Last Name':
        return lastName;
      case 'Email':
        return email;
      case 'Phone Number':
        return phone;
      default:
        return;
    }
  };

  const handleChange = (e, item) => {
    const value = e.target.value;
    switch (item) {
      case 'First Name':
        if (value.length > 30) {
          return setIsError({
            ...isError,
            'First Name': 'Maximum character limit is 30'
          });
        } else {
          delete isError['First Name'];
          return setFirstName(value);
        }
      case 'Last Name':
        if (value.length > 30) {
          return setIsError({
            ...isError,
            'Last Name': 'Maximum character limit is 30'
          });
        } else {
          delete isError['Last Name'];
          return setLastName(value);
        }
      case 'Email':
        setEmail(value);
        if (isError['Phone Number']) {
          delete isError['Phone Number'];
        }
        if (isEmailValid(value)) {
          return delete isError.Email;
        } else {
          return setIsError({ ...isError, Email: 'Email is not valid' });
        }
      case 'Phone Number':
        if (isError['Email']) {
          delete isError['Email'];
        }
        if (isPhoneValidWithoutPlus(value)) {
          delete isError['Phone Number'];
          return setPhone(value);
        } else {
          return;
        }
      default:
        return;
    }
  };

  const OnStageSelect = (data) => {
    setStageId(data.id);
    delete isError['Stage'];
  };

  const onValueChange = (value, id) => {
    if (!isEmpty(final[id])) {
      if (value === '') {
        const formData = {
          answer: {},
          question: id
        };
        final[id] = formData;
        setFinal({ ...final });
      } else {
        const formData = {
          answer: { description: value },
          question: id
        };
        final[id] = formData;
        setFinal({ ...final });
      }
    } else {
      const formData = {
        answer: { description: value },
        question: id
      };
      final[id] = formData;
      setFinal({ ...final });
    }
  };

  const onQuestionsChange = (value, id) => {
    if (!isEmpty(final[id])) {
      if (value === '') {
        const formData = {
          answer: {},
          question: id
        };
        final[id] = formData;
        setFinal({ ...final });
      } else {
        const formData = {
          answer: { description: value },
          question: id
        };
        final[id] = formData;
        setFinal({ ...final });
      }
    } else {
      const formData = {
        answer: { description: value },
        question: id
      };
      final[id] = formData;
      setFinal({ ...final });
    }
  };

  const onQuestionOptionSelect = (data, id) => {
    const formData = {
      answer: { description: data.value },
      question: id
    };
    final[id] = formData;
    setFinal({ ...final });
  };

  const onDateSelect = (date, id) => {
    const formData = {
      answer: { start_date: moment(date).format('YYYY-MM-DD') },
      question: id
    };
    final[id] = formData;
    setFinal({ ...final });
  };

  const onStartDateSelect = (date, id) => {
    if (!date) {
      setIsStartDateError({
        ...isStartDateError,
        [id]: 'Please select from date'
      });
      return;
    }
    const startError = { ...isStartDateError };
    delete startError[id];
    setIsStartDateError(startError);
    if (!isEmpty(final[id])) {
      final[id] = {
        ...final[id],
        answer: {
          ...final[id]['answer'],
          start_date: moment(date).format('YYYY-MM-DD')
        }
      };
      setFinal({ ...final });
    } else {
      const formData = {
        answer: { start_date: moment(date).format('YYYY-MM-DD') },
        question: id
      };
      final[id] = formData;
      setFinal({ ...final });
      setIsEndDateError({ ...isEndDateError, [id]: 'Please select to date' });
    }
  };

  const onEndDateSelect = (date, id) => {
    if (!date) {
      setIsEndDateError({ ...isEndDateError, [id]: 'Please select to date' });
      return;
    }
    const endError = { ...isEndDateError };
    delete endError[id];
    setIsEndDateError(endError);
    if (!isEmpty(final[id])) {
      final[id] = {
        ...final[id],
        answer: {
          ...final[id]['answer'],
          end_date: moment(date).format('YYYY-MM-DD')
        }
      };
      setFinal({ ...final });
    } else {
      const formData = {
        answer: { end_date: moment(date).format('YYYY-MM-DD') },
        question: id
      };
      final[id] = formData;
      setFinal({ ...final });
      setIsStartDateError({
        ...isStartDateError,
        [id]: 'Please select from date'
      });
    }
  };

  const onRadioSelect = (value, id) => {
    const formData = {
      answer: { description: value },
      question: id
    };
    final[id] = formData;
    setFinal({ ...final });
  };

  const onCheckboxSelect = (status, value, id) => {
    if (isEmpty(final[id]) || isEmpty(final[id]['answer'])) {
      const formData = {
        answer: [{ description: value }],
        question: id
      };
      final[id] = formData;
      setFinal({ ...final });
    } else {
      if (status) {
        final[id] = {
          ...final[id],
          answer: [...final[id]['answer'], { description: value }]
        };
        setFinal({ ...final });
      } else {
        const remainingValue = final[id].answer.filter(
          (item) => item.description !== value
        );
        if (isEmpty(remainingValue)) {
          final[id] = {};
          setFinal({ ...final });
        } else {
          final[id] = { ...final[id], answer: remainingValue };
          setFinal({ ...final });
        }
      }
    }
  };

  const renderQuestions = (item) => {
    switch (item.kind) {
      case TEXT:
        return (
          <TextTypeForm
            data={item}
            key={item.id}
            onChange={onQuestionsChange}
          />
        );
      case DROPDOWN:
        return (
          <DropdownTypeForm
            data={item}
            key={item.id}
            onQuestionOptionSelect={onQuestionOptionSelect}
          />
        );
      case DATETIME:
        return (
          <DateTypeForm data={item} key={item.id} onDateSelect={onDateSelect} />
        );
      case MCQ:
        return (
          <OptionTypeForm
            data={item}
            key={item.id}
            onRadioSelect={onRadioSelect}
          />
        );
      case MCQ_MUTIPLE_SELECT:
        return (
          <OptionTypeForm
            data={item}
            key={item.id}
            onCheckboxSelect={onCheckboxSelect}
          />
        );
      case DATE_RANGE:
        return (
          <DateTypeForm
            data={item}
            key={item.id}
            onStartDateSelect={onStartDateSelect}
            onEndDateSelect={onEndDateSelect}
            isStartDateError={isStartDateError[item.id]}
            isEndDateError={isEndDateError[item.id]}
          />
        );
      case VALUE:
        return (
          <ValueTypeForm data={item} key={item.id} onChange={onValueChange} />
        );
      default:
        <></>;
    }
  };

  const checkIsValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(firstName)) {
      err = {
        ...err,
        'First Name': 'Please provide first name'
      };
      isValid = false;
    }
    if (firstName.length > 30) {
      err = {
        ...err,
        'First Name': 'Maximum character limit is 30'
      };
      isValid = false;
    }
    if (isEmpty(phone) && isEmpty(email)) {
      if (isEmpty(phone) && boxFormData.fields.includes('Phone Number')) {
        err = {
          ...err,
          'Phone Number': 'Please provide phone number'
        };
        isValid = false;
      }
      if (isEmpty(email) && boxFormData.fields.includes('Email')) {
        err = { ...err, Email: 'Please provide email' };
        isValid = false;
      }
      if (
        !isEmpty(email) &&
        !isEmailValid(email) &&
        boxFormData.fields.includes('Email')
      ) {
        err = { ...err, Email: 'Email is not valid' };
        isValid = false;
      }
    }
    if (!isEmpty(email)) {
      if (!isEmailValid(email) && boxFormData.fields.includes('Email')) {
        err = { ...err, Email: 'Email is not valid' };
        isValid = false;
      }
    }

    if (isEmpty(stageId)) {
      err = { ...err, Stage: 'Stage cannot be empty' };
      isValid = false;
    }
    if (!isEmpty(isEndDateError)) {
      isValid = false;
    }
    if (!isEmpty(isStartDateError)) {
      isValid = false;
    }
    setIsError(err);
    return isValid;
  };

  const onCancel = () => {
    setFinal({});
    setIsStartDateError({});
    setIsEndDateError({});
    setIsError({});
    setNote('');
    setStageId('');
    setPhone('');
    setEmail('');
    setLastName('');
    setFirstName('');
    onClose();
  };

  const enquiryCallback = () => {
    setIsBtnLoading(false);
    if (!isPublicPage) {
      onClose();
      dispatch(setLoadEnquiryInStage(true));
      setTimeout(() => {
        dispatch(getAllCounts());
        if (is_free_plan) {
          dispatch(getFreeEnquiriesCount());
        }
      }, 1500);
    } else {
      showSuccess();
    }
    setFinal({});
    setIsError({});
    setNote('');
    setStageId('');
    setPhone('');
    setEmail('');
    setLastName('');
    setFirstName('');
    handleOpenNewEnquiry();
  };

  const onSubmit = () => {
    let tempData = [];
    const isValid = checkIsValid();
    if (isValid) {
      setIsBtnLoading(true);
      Object.keys(final).forEach((item) => {
        if (isEmpty(final[item]) || isEmpty(final[item]['answer'])) {
          return;
        } else {
          tempData.push(final[item]);
        }
      });
      let formData = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phone,
        stage: stageId,
        note: note,
        questions: tempData
      };
      if (isPublicPage) {
        formData = {
          ...formData,
          is_public: true
        };
      }
      dispatch(
        createManualEnquiryFromBoxForm({
          body: formData,
          callback: enquiryCallback
        })
      );
    }
  };

  const onLinkClick = () => {
    navigate(
      `${route.dashboard}${route.boxes}/${box_type}/${box_id}/settings/box-form`
    );
  };

  const handleScroll = () => {
    setTimeout(() => {
      nodeRef.current?.scrollIntoView({
        behavior: 'smooth'
      });
    }, 200);
  };

  return (
    <>
      <div
        className={`${
          isPublicPage ? 'px-8 py-6' : 'overflow-y-auto px-8 pt-6'
        }`}
        style={!isPublicPage ? { maxHeight: 500 } : {}}
        id={'manual-enquiry'}
      >
        <form autoComplete="off">
          {!isEmpty(boxFormData) &&
            boxFormData?.fields.map((item, index) => {
              if (item === 'Last Name') return <div key={item}></div>;
              if (item === 'First Name') {
                return (
                  <div key={index} className="flex w-full pb-4 gap-3">
                    <div className="w-full">
                      <label className="input-field-label">{item}</label>
                      <div>
                        <TextInputField
                          name={item}
                          className="input-field--type2"
                          placeholder=""
                          value={getValue(item)}
                          style={{ padding: '9px 15px' }}
                          onChange={(e) => handleChange(e, item)}
                          error={!isEmpty(isError[`${item}`])}
                        />
                      </div>
                      {!isEmpty(isError) && isError[`${item}`] && (
                        <ErrorMessage error={isError[`${item}`]} />
                      )}
                    </div>
                    <div className="w-full">
                      <label className="input-field-label">Last Name</label>
                      <div>
                        <TextInputField
                          name="Last Name"
                          className="input-field--type2"
                          placeholder=""
                          value={getValue('Last Name')}
                          style={{ padding: '9px 15px' }}
                          onChange={(e) => handleChange(e, 'Last Name')}
                          error={!isEmpty(isError['Last Name'])}
                        />
                      </div>
                      {!isEmpty(isError) && isError['Last Name'] && (
                        <ErrorMessage error={isError['Last Name']} />
                      )}
                    </div>
                  </div>
                );
              }
              if (item === 'Stage') {
                return (
                  <div
                    className="w-full pb-4"
                    key={index}
                    ref={nodeRef}
                    onClick={() => handleScroll()}
                  >
                    <label className="input-field-label">{item}</label>
                    <Select
                      name="Box"
                      options={stageOptions}
                      inputClassName="input-field--type2"
                      dropDownClassName="p-2"
                      placeholder="Select the stage to add the contact to"
                      readOnly={true}
                      error={!isEmpty(isError[`${item}`])}
                      style={{ padding: '9px 15px' }}
                      onChange={OnStageSelect}
                    />
                    {!isEmpty(isError) && isError[`${item}`] && (
                      <ErrorMessage error={isError[`${item}`]} />
                    )}
                  </div>
                );
              }
              return (
                <div className="pb-4" key={index}>
                  <div className="w-full">
                    <label className="input-field-label">{item}</label>
                    <div className="flex items-center relative">
                      {item === 'Phone Number' && (
                        <div
                          style={{
                            position: 'absolute',
                            left: 10,
                            zIndex: 999
                          }}
                          className="flex items-center text-15"
                        >
                          +44
                          <CaretIcon style={{ marginLeft: 2 }} />
                        </div>
                      )}
                      <TextInputField
                        type={item}
                        name={item}
                        className="input-field--type2"
                        placeholder=""
                        value={getValue(item)}
                        containerStyle={{ width: '100%' }}
                        style={
                          item === 'Phone Number'
                            ? { padding: '9px 15px 9px 55px' }
                            : { padding: '9px 15px' }
                        }
                        onChange={(e) => handleChange(e, item)}
                        error={!isEmpty(isError[`${item}`])}
                      />
                    </div>
                    {!isEmpty(isError) && isError[`${item}`] && (
                      <ErrorMessage error={isError[`${item}`]} />
                    )}
                  </div>
                </div>
              );
            })}
        </form>
        {!isEmpty(boxFormData.question_data) &&
          !isEmpty(boxFormData.questions) &&
          boxFormData.question_data.map((item) => {
            return renderQuestions(item);
          })}
        <form autoComplete="off">
          {!isEmpty(boxFormData) && boxFormData.note && (
            <div>
              <label className="input-field-label">Note</label>
              <TextArea
                name="note"
                placeholder="Add note"
                rows={4}
                className="border text-13 resize-none"
                value={note}
                onChange={(e) => setNote(e.target.value)}
                style={{ background: '#FDF7DA' }}
              />
            </div>
          )}
        </form>
        {isPublicPage && (
          <div className="flex justify-end mt-8">
            <PrimaryButton
              label="Submit"
              className=""
              onClick={onSubmit}
              enableLoader
              isLoading={isBtnLoading}
              loaderInline
            />
          </div>
        )}
      </div>
      {!isPublicPage && (
        <div className="flex items-center justify-between px-8 pt-5 pb-6">
          <p
            className="text-15 text-primary font-semibold cursor-pointer"
            onClick={onLinkClick}
          >
            Customise this form
          </p>
          <div className="flex gap-3 ">
            <CustomColorButton
              label="Cancel"
              className="btn--secondary btn--md min-w-120"
              onClick={(e) => {
                e.preventDefault();
                onCancel();
              }}
            />
            <PrimaryButton
              label="Add"
              className="btn--md min-w-120"
              onClick={(e) => {
                e.preventDefault();
                onSubmit();
              }}
              isLoading={isBtnLoading}
              enableLoader={isBtnLoading}
              loaderInline={false}
            />
          </div>
        </div>
      )}
    </>
  );
};

Form.defaultProps = {
  handleOpenNewEnquiry: () => {}
};
export default Form;

import IconClose from 'assets/icons/icon-close-grey.svg';
import { useDispatch } from 'react-redux';
import { clearAuthToken } from 'slices/authSlice';

const PaymentModalLogout = () => {
  const dispatch = useDispatch();

  const onClose = () => {
    dispatch(clearAuthToken());
  };

  return (
    <img
      src={IconClose}
      onClick={onClose}
      alt="close"
      style={{
        position: 'absolute',
        right: 20,
        top: 20,
        height: 15,
        cursor: 'pointer'
      }}
    />
  );
};

export default PaymentModalLogout;

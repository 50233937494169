import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setListAssistants } from 'slices/ai-assistant/assistant/slice';
import { isEmpty } from 'utils/utils';

const Wrapper = ({ children }) => {
  const version_status = useSelector((state) => state.assistant.versionStatus);
  const { assistant_id } = useParams();
  const dispatch = useDispatch();
  const listAssistants = useSelector((state) => state.assistant.listAssistants);

  useEffect(() => {
    if (!isEmpty(version_status)) {
      handleVersionStatus();
    }
  }, [version_status]);

  const handleVersionStatus = () => {
    let tempData = listAssistants.map((item) => {
      if (item.version_bot.id === assistant_id) {
        return {
          ...item,
          version_status
        };
      }
      return item;
    });
    dispatch(setListAssistants(tempData));
  };
  return <>{children}</>;
};

export default Wrapper;

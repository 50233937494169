import { isEmpty } from 'utils/utils';
import { ReactComponent as PhoneIcon } from 'assets/icons/icon-task-type-call.svg';
import { ReactComponent as FollowUpIcon } from 'assets/icons/icon-task-type-follow-up-grey.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/icon-task-type-other-grey.svg';
import CHOICES from 'choices';
import { formatDateTime, prepareDateAndTime } from 'utils/dateFormat';

const { CALL, FOLLOW_UP, OTHERS } = CHOICES.TasksTypeChoices;

const CompleteTask = (props) => {
  const {
    title,
    completedUser,
    outcomeName,
    description,
    taskType,
    completedDate
  } = props;

  const renderSvg = (type) => {
    // render the task type icon
    switch (type) {
      case CALL:
        return <PhoneIcon />;
      case FOLLOW_UP:
        return <FollowUpIcon />;
      case OTHERS:
        return <OtherIcon />;
      default:
        return <PhoneIcon />;
    }
  };

  const getDueDateTime = (due_date) => {
    // function to get the date in Jan 13, 9:00 am format
    const { date, time } = prepareDateAndTime(due_date, { minute: true });
    return `${formatDateTime(date, 'mmm dd')}, ${time}`;
  };

  return (
    <li className="contact-tasks__done-list">
      <div className="flex justify-between">
        <div className="contact-tasks__done-list__left">
          <h4 className="contact-tasks__done-list__title text-grey-700 text-15 font-medium">
            {title}
          </h4>
          <div className="flex items-center mt-1">
            {renderSvg(taskType)}
            <div className="text-grey-800 text-13 font-medium ml-2">
              {completedUser?.name || completedUser?.first_name}{' '}
              <span className="text-grey-700 text-13 font-normal ml-1">
                {`Completed on ${getDueDateTime(completedDate)}`}
              </span>
            </div>
          </div>
        </div>
        {taskType !== OTHERS && (
          <div className="contact-tasks__done-list__right">
            <div className="toggle-check-btn toggle-check-btn--static">
              {outcomeName}
            </div>
          </div>
        )}
      </div>
      {!isEmpty(description) && (
        <div className="contact-tasks__done-list__msg">{description}</div>
      )}
    </li>
  );
};

export default CompleteTask;

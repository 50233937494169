import React, { useEffect } from 'react';
import helpIcon from 'assets/icons/help-icon.svg';
import InputFiled from './components/InputFiled';
import {
  getFbPixelId,
  getGA4
} from 'slices/ai-assistant/deploy/websiteLinks/tracking/slice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

const TrackingPage = () => {
  const { assistant_id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getFbPixelId(assistant_id));
  }, []);
  useEffect(() => {
    dispatch(getGA4(assistant_id));
  }, []);

  return (
    <section style={{ width: 790, padding: '25px 38px 40px' }}>
      <div className="card-box" style={{ padding: '30px 25px' }}>
        <h3 className="text-lg font-semibold">Track your bots performance</h3>
        <p className="text-15 text-grey-800 mt-5 letterspacing24em">
          Track conversions from your bot. Conversions are sent when an email
          address or phone number is captured in the bot conversation.{' '}
        </p>
        <div className="mt-6">
          <h4 className="text-15 font-medium letterspacing24em">
            Google Analytics 4
          </h4>
          <p className="text-15 text-grey-800 letterspacing24em mt-1">
            Send bot conversions to Google Analytics
          </p>
          <InputFiled placeholder="Google Analytics" name="google_analytics" />
        </div>
        <div className="mt-6">
          <h4 className="text-15 font-medium letterspacing24em">
            Facebook Pixel
          </h4>
          <p className="text-15 text-grey-800 letterspacing24em mt-1">
            Send bot conversions to your Facebook Pixel
          </p>
          <InputFiled
            placeholder="Add your Facebook Pixel ID"
            name="fb_pixel"
          />
        </div>
        <div
          className="flex items-center py-4 px-5 mt-9 rounded-lg"
          style={{ backgroundColor: 'rgba(217, 217, 217, 0.26)' }}
        >
          <img src={helpIcon} alt="helpIcon" style={{ height: 16 }} />
          <p className="text-sm ml-3 letterspacing24em">
            Need help or have a question? Read our{' '}
            <span className="text-primary text-sm font-medium letterspacing24em cursor-pointer">
              tracking guide
            </span>
          </p>
        </div>
      </div>
    </section>
  );
};

export default TrackingPage;

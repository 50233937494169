/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CHOICES from 'choices';
import ConnectedChannelTab from '../settingsTab';
import AutomationType from './components/automationType';
import AutomationItem from './components/automationItem';
import TextInputField from 'components/inputs/textField';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  deleteChannelAutomation,
  sendAutomation,
  setAvaliableAutoamtions,
  setTypeofAutomation
} from 'slices/automationStuffSlice';
import { Loader } from 'components/loader';
import EmailAutomation from 'modules/enquirybox/channels/module/settings/automationStuff/templates/sendEmail/emailAutomationSetup';
import { useSnackbar } from 'components/Snackbar';
import { isEmpty } from 'utils/utils';
import AssigneToteamSetup from 'modules/enquirybox/channels/module/settings/automationStuff/templates/assigneTeamMember/assigneToteamSetup';
import MoveEnquiriesToBoxSetup from 'modules/enquirybox/channels/module/settings/automationStuff/templates/moveEnquiries/moveEnquiriesSetup';
import ErrorMessage from 'components/errorMessage';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const {
  FACEBOOK,
  INSTAGRAM,
  WEBFORM,
  FACEBOOK_LEAD_ADS,
  EBOT,
  WHATSAPP,
  ZAPIER,
  SMS
} = CHOICES.ChannelChoices;

const {
  FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER,
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  MOVE_TO_BOX,
  SMS: SMS_REPLY
} = CHOICES.AutomationChoice;

const Automations = (props) => {
  const { channelType } = props;
  const { channelId } = useParams();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { availableAutomations, typeofAutomation, onEdit, error, isPreview } =
    useSelector((state) => state.automationStuff);
  const [isLoading, setIsLoading] = useState(false);
  const [automationName, setAutomationName] = useState('');
  const [tempAutomationName, setTempAutomationName] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useEffect(() => {
    dispatch(setTypeofAutomation(''));
  }, [channelId]);

  const callback = () => {
    setIsLoading(false);
  };
  useEffect(() => {
    if (typeofAutomation) {
      if (onEdit?.automation_name) {
        setAutomationName(onEdit.automation_name);
        setTempAutomationName(onEdit.automation_name);
      } else {
        if (typeofAutomation === FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER) {
          setAutomationName('Send an auto reply message');
          setTempAutomationName('Send an auto reply message');
        }
        if (typeofAutomation === AUTORESPONDER_EMAIL) {
          setAutomationName('Send an auto reply Email');
          setTempAutomationName('Send an auto reply Email');
        }
        if (typeofAutomation === ASSIGN_MEMBER) {
          setAutomationName('Assign enquiries to {{team_member}}');
          setTempAutomationName('Assign enquiries to {{team_member}}');
        }
        if (typeofAutomation === MOVE_TO_BOX) {
          setAutomationName('Move enquiries to {{box_name}} box');
          setTempAutomationName('Move enquiries to {{box_name}} box');
        }
        if (typeofAutomation === SMS_REPLY) {
          setAutomationName('Send an auto reply SMS');
          setTempAutomationName('Send an auto reply SMS');
        }
      }
    }
  }, [typeofAutomation, onEdit]);

  const getChannelAutomation = () => {
    const payload = {
      type: 'get',
      data: {
        channelId: channelId
      }
    };
    dispatch(sendAutomation(payload, callback));
  };

  useEffect(() => {
    if (!typeofAutomation) {
      setIsLoading(true);
      getChannelAutomation();
    }
  }, [typeofAutomation, channelId]);

  const automationTypeData = [
    {
      id: 2,
      automation_type: ASSIGN_MEMBER,
      label: '✨ Assign to team member'
    },
    {
      id: 3,
      automation_type: MOVE_TO_BOX,
      label: '✨ Move to a Box'
    }
  ];

  if (channelType !== SMS) {
    automationTypeData.push({
      id: 1,
      automation_type:
        channelType === FACEBOOK || channelType === INSTAGRAM
          ? FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER
          : AUTORESPONDER_EMAIL,
      label:
        channelType === FACEBOOK || channelType === INSTAGRAM
          ? '✨ Send an auto reply messages'
          : '✨ Send an auto reply email'
    });
  }

  if (
    channelType === WEBFORM ||
    channelType === FACEBOOK_LEAD_ADS ||
    channelType === EBOT ||
    channelType === WHATSAPP ||
    channelType === ZAPIER ||
    channelType === SMS
  ) {
    automationTypeData.push({
      id: 4,
      label: '✨ Send an auto reply SMS',
      automation_type: SMS_REPLY
    });
    // automationTypeData.push({ id: 5, label: '✨ Send an auto reply WhatsApp' });
  }

  const handleAutomationStatus = (evt, selectedItem) => {
    const payload = { id: selectedItem.id, is_active: evt.target.checked };
    dispatch(sendAutomation({ type: 'put', data: payload }));
    if (!isEmpty(availableAutomations)) {
      const updatedavAilableAutomations = availableAutomations.map(
        (item, index) => {
          if (item?.id === selectedItem.id) {
            return { ...item, is_active: evt.target.checked };
          }
          return item;
        }
      );
      dispatch(setAvaliableAutoamtions([...updatedavAilableAutomations]));
    }
    if (evt.target.checked) {
      openSuccessSnackBar(`Automation is live`, 'large');
    } else {
      openSuccessSnackBar(`Automation turned off`, 'large');
    }
  };

  const onDeleteAutomation = (id) => {
    setShowConfirmationModal(id);
  };

  const deleteCallback = () => {
    getChannelAutomation();
    setIsDeleteLoading(false);
    setShowConfirmationModal(null);
  };

  const onDeleteConfirm = () => {
    setIsDeleteLoading(true);
    dispatch(
      deleteChannelAutomation({
        automationId: showConfirmationModal,
        callback: deleteCallback
      })
    );
  };
  console.log();
  return (
    <>
      <DeleteConfirmation
        showConfirmation={showConfirmationModal === null ? false : true}
        title="Are you sure you want to delete this automation?"
        description=""
        onCancel={() => setShowConfirmationModal(null)}
        onConfirm={onDeleteConfirm}
        isLoading={isDeleteLoading}
      />
      <div className="h-full w-full">
        {isLoading && <Loader />}
        <div
          className="px-8 py-6 scroll-smooth"
          style={{ height: 'calc(100% - 45px)', overflowY: 'auto' }}
          id="automationContainer"
        >
          <div>
            <h2 className="font-semibold text-lg">Setup your Automations</h2>
            <p className="text-15 text-grey-800">
              Select what you would like to automate when an enquiry comes into
              this channel👇
            </p>
            <div className="flex mt-4 h-auto justify-start flex-wrap">
              {automationTypeData.map((item) => {
                return (
                  <AutomationType
                    key={item.id}
                    label={item.label}
                    automation_type={item.automation_type}
                  />
                );
              })}
            </div>
          </div>
          {typeofAutomation ? (
            <div className="automation-container">
              <h2 className="text-15 font-medium text-grey-800">
                Automation name
              </h2>
              <TextInputField
                className="input-field--md mt-4"
                value={automationName}
                onChange={(e) => setAutomationName(e.target.value)}
                error={!isEmpty(error.automationName)}
                readOnly={isPreview}
              />
              <div className="mt-1 ml-1" style={{ width: 'inherit' }}>
                {error.automationName && (
                  <ErrorMessage
                    className="flex"
                    message={error.automationName}
                  />
                )}
              </div>
              {(typeofAutomation === AUTORESPONDER_EMAIL ||
                typeofAutomation ===
                  FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER ||
                typeofAutomation === SMS_REPLY) && (
                <EmailAutomation
                  channelType={
                    typeofAutomation === SMS_REPLY ? SMS : channelType
                  } // this channel type depends on type of automation clicked
                  title={automationName}
                  tempAutomationName={tempAutomationName}
                  tempChannelType={channelType}
                />
              )}
              {typeofAutomation === ASSIGN_MEMBER && (
                <AssigneToteamSetup
                  channelType={channelType}
                  title={automationName}
                  tempChannelType={channelType}
                  changeAutomationName={setAutomationName}
                />
              )}
              {typeofAutomation === MOVE_TO_BOX && (
                <MoveEnquiriesToBoxSetup
                  channelType={channelType}
                  title={automationName}
                  tempChannelType={channelType}
                  changeAutomationName={setAutomationName}
                />
              )}
            </div>
          ) : (
            <div className="mt-10">
              <div className="pb-4 border-b border-grey-400">
                <h2 className="text-15 font-medium text-grey-800">
                  Your Automations
                </h2>
              </div>
              <div className="mt-5">
                {availableAutomations.map((item) => {
                  const {
                    automation_type,
                    automation_name,
                    is_active,
                    execution_count,
                    any_web_form,
                    webform_name,
                    fb_lead_ads_form_name
                  } = item;
                  return (
                    <AutomationItem
                      key={item.id}
                      channelType={channelType}
                      automation_type={automation_type}
                      title={automation_name}
                      status={is_active}
                      item={item}
                      handleAutomationStatus={handleAutomationStatus}
                      onDeleteAutomation={onDeleteAutomation}
                      execution_count={execution_count}
                      anyWebForm={any_web_form}
                      webformName={webform_name}
                      fbLeadAdsformName={fb_lead_ads_form_name}
                      isAnyFbLeadForms={fb_lead_ads_form_name ? false : true}
                    />
                  );
                })}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Automations;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useSearchParams, useNavigate, NavLink } from 'react-router-dom';
import {
  useResendLinkMutation,
  useVerifyEmailMutation
} from 'services/authApi';
import HeaderLayout from 'components/layout/headerless';
import Loader from 'components/loader';
import ResetPasswordExpired from '../../password/resetPassword/resetPassword';
import RenderError from 'components/errorMessage/renderer';

const ConfirmEmailLink = () => {
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get('key');
  const userId = queryParams.get('user_id');
  const [verifyEmail, { isLoading, isSuccess, isError }] =
    useVerifyEmailMutation();
  const [
    resendLink,
    {
      isLoading: isResendLinkLoading,
      isSuccess: isResendLinkSuccess,
      isError: isResendLinkError,
      error: resendLinkError
    }
  ] = useResendLinkMutation();

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [isSuccess]);

  useEffect(() => {
    verify();
  }, []);

  const onResendEmail = async () => {
    await resendLink({ user_id: userId });
  };

  const verify = async () => {
    await verifyEmail({ token, user_id: userId });
  };

  if (isLoading)
    return (
      <HeaderLayout hideLogo>
        <Loader />
      </HeaderLayout>
    );

  if (isError)
    return (
      <ResetPasswordExpired
        title="This confirm account link has expired"
        subTitle="If you don’t see your confirm email check your spam for
      an email from"
        onClick={onResendEmail}
        isSuccess={isResendLinkSuccess}
        isLoading={isResendLinkLoading}
      >
        {isResendLinkError && (
          <RenderError error={resendLinkError?.data?.user_id} />
        )}
      </ResetPasswordExpired>
    );

  if (isSuccess)
    return (
      <HeaderLayout hideLogo>
        <div className="flex flex-col items-center justify-center">
          <p>
            Your email is verified successfully, You will be redirected to login
            shortly
          </p>
          <p>
            if not redirected,{' '}
            <NavLink className="text-blue-900" to="/login">
              click here
            </NavLink>{' '}
            for login
          </p>
        </div>
      </HeaderLayout>
    );

  return <></>;
};

export default ConfirmEmailLink;

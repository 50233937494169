import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Count from 'components/count';
import Indicator from 'components/indicator';
import _ from 'lodash';

const Section = (props) => {
  const {
    label,
    icon,
    iconStyle,
    showCount,
    count,
    colorCode,
    renderSvg,
    to,
    subLabel,
    iconString,
    disableRedirect,
    isUser,
    isDisabled
  } = props;

  const RenderIcon = ({
    colorCode,
    icon,
    iconStyle,
    renderSvg,
    iconString
  }) => {
    if (colorCode) {
      return <Indicator style={{ backgroundColor: colorCode }} />;
    }
    if (renderSvg && typeof renderSvg === 'function') {
      return renderSvg();
    }
    const getIcon = () => {
      if (icon?.includes('https://'))
        return <img src={icon} alt="icn" style={iconStyle} />;
      if (!icon?.includes('https://') && iconString)
        return <div dangerouslySetInnerHTML={{ __html: icon }} />;
      if (!iconString) return <img src={icon} alt="icn" style={iconStyle} />;
      return <></>;
    };

    return <figure className="flex">{getIcon()}</figure>;
  };

  const RenderBlock = ({ children }) => {
    return (
      <div
        className={
          isDisabled
            ? 'dashboard__sidebar__nav-list-disabled'
            : 'dashboard__sidebar__nav-list'
        }
      >
        {children}
      </div>
    );
  };

  const RenderContent = () => {
    return (
      <>
        <RenderIcon
          colorCode={colorCode}
          icon={icon}
          iconStyle={iconStyle}
          renderSvg={renderSvg}
          iconString={iconString}
        />
        {isUser && (
          <>
            <div className="flex items-center" style={{ width: '68%' }}>
              <span
                className={`text-base-1 font-medium whitespace-nowrap overflow-ellipsis overflow-hidden ${
                  isDisabled ? 'text-[#AAAAAA]' : 'text-grey-900'
                }`}
              >
                {label}
              </span>
              <span
                className="text-13 font-medium text-grey-600"
                style={{ marginLeft: 5 }}
              >
                {subLabel}
              </span>
            </div>
          </>
        )}
        {!isUser && (
          <span className="text-base-1 font-medium text-grey-900">{label}</span>
        )}
        {!isUser && subLabel && (
          <span
            className="text-13 font-medium text-grey-600"
            style={{ marginTop: 3 }}
          >
            {subLabel}
          </span>
        )}
        {showCount && _.gt(count, 0) && (
          <Count label={count} rounded={true} className="ml-auto" />
        )}
      </>
    );
  };

  if (disableRedirect || isDisabled)
    return (
      <RenderBlock>
        <RenderContent />
      </RenderBlock>
    );

  return (
    <NavLink to={to} className="dashboard__sidebar__nav-list">
      <RenderContent />
    </NavLink>
  );
};

Section.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.string,
  iconStyle: PropTypes.object,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showCount: PropTypes.bool,
  colorCode: PropTypes.string,
  to: PropTypes.string,
  subLabel: PropTypes.string,
  iconString: PropTypes.bool,
  disableRedirect: PropTypes.bool,
  isDisabled: PropTypes.bool
};

Section.defaultProps = {
  label: 'label',
  showCount: false,
  iconStyle: {},
  to: '/',
  iconString: false,
  disableRedirect: false,
  isDisabled: false
};

export default Section;

import React from 'react';
import checkIcon from 'assets/icons/icon-check-fill.svg';
import minusIcon from 'assets/icons/icon-minus-fill.svg';
import smileyIcon from 'assets/icons/icon-smiley1.svg';
import dragAndDropIcon from 'assets/icons/icon-drag-and-drop.svg';
import CustomToolTip from 'components/CustomTooltip';
import { useState, useRef, useEffect } from 'react';
import useClickOutside from 'hooks/useOutsideClick';
import TextInputField from 'components/inputs/textField';
import EmojiModal from '../../components/AiEmojiModal';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
import { useParams } from 'react-router-dom';
import {
  addOpeningMessage,
  addOpeningOption,
  setOpeningStarterOptions,
  updateOrReorderOpeningMessage,
  updateOrReorderOpeningOption
} from 'slices/ai-assistant/welcome/slice';
import { useDispatch, useSelector } from 'react-redux';
import { setWelcomeErrors } from 'slices/ai-assistant/assistant/slice';

const Message = (props) => {
  const {
    index,
    value,
    dragStart,
    id,
    task,
    onRemove,
    setDragDisabled,
    placeholder,
    isLoading,
    setShowDelete,
    setIndex,
    setItem,
    type,
    snapshot,
    handleLoader,
    tooltip
  } = props;
  const textRef = useRef();
  const { assistant_id } = useParams();

  const [text, setText] = useState('');
  const [active, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [openEmoji, setOpenEmoji] = useState(false);
  const { opening_message, opening_options } = useSelector(
    (state) => state.welcomePage
  );
  const nodeRef = useRef(null);
  const welcomeErrors = useSelector((state) => state.assistant.welcomeErrors);
  const lengthOfDataItems = () => {
    let length = 0;
    if (type === 'option')
      opening_options.map((item, index) => {
        if (item.status !== 'New') length += 1;
      });
    else
      opening_message.map((item, index) => {
        if (item.status !== 'New') length += 1;
      });
    return length;
  };
  const inputRef = useRef(null);
  const dispatch = useDispatch();
  useClickOutside(() => {
    setText(textRef.current);
    setActive(false);
  }, nodeRef.current);

  useEffect(() => {
    setText(value);
    textRef.current = value;
  }, [value]);

  useEffect(() => {
    if (snapshot?.isDragging) {
      setHover(true);
    } else {
      setHover(false);
    }
  }, [snapshot?.isDragging]);

  useEffect(() => {
    if (task.status === 'New') {
      setActive(true);
      setDragDisabled(true);
    } else {
      setDragDisabled(false);
    }
  }, [task.status]);

  const onEmojiSelect = (data) => {
    let sym = data.unified.split('-');
    let codesArray = [];
    sym.forEach((el) => codesArray.push('0x' + el));
    let emoji = String.fromCodePoint(...codesArray);
    setText(text + emoji);
    setOpenEmoji(false);
    setDragDisabled(false);
  };
  const onMouseEnter = () => {
    if (parseInt(id) === parseInt(dragStart)) {
      setHover(true);
      return;
    }
    setHover(true);
  };

  const onMouseLeave = () => {
    if (parseInt(id) === parseInt(dragStart)) {
      setHover(false);
      return;
    }
    setHover(false);
  };

  useEffect(() => {
    if (active) {
      inputRef.current.focus();
    }
  }, [active]);

  const onAddEmoji = () => {
    setOpenEmoji(true);
    setDragDisabled(true);
  };

  const successMsgCb = () => {
    let currWelcomeErrors = { ...welcomeErrors };
    currWelcomeErrors['message'] = true;
    dispatch(setWelcomeErrors(currWelcomeErrors));
    setActive(false);
    handleLoader(false);
  };

  const successCb = () => {
    let currWelcomeErrors = { ...welcomeErrors };
    currWelcomeErrors['option'] = true;
    dispatch(setWelcomeErrors(currWelcomeErrors));
    setActive(false);
    handleLoader(false);
  };

  const failedCb = () => {
    setActive(false);
    handleLoader(false);
  };

  const handleMsgInputChange = (item, index, value) => {
    handleLoader(true);
    if (item.status === 'New') {
      const formData = {
        body: {
          ai_bot_id: assistant_id,
          content: value
        },
        index: index
      };
      dispatch(addOpeningMessage(formData, successMsgCb, failedCb));
    } else {
      let data = [...opening_message];
      let payload = {
        body: {
          message_id: data[index].id,
          content: value
        },
        index
      };
      dispatch(
        updateOrReorderOpeningMessage(payload, 'content', successCb, failedCb)
      );
    }
  };

  const handleOptionInputChange = (item, index, value) => {
    handleLoader(true);
    if (item.status === 'New') {
      const formData = {
        body: {
          ai_bot_id: assistant_id,
          content: value
        },
        index: index
      };
      dispatch(addOpeningOption(formData, successCb, failedCb));
    } else {
      let data = [...opening_options];
      let payload = {
        body: {
          option_id: data[index].id,
          content: value
        },
        index
      };
      dispatch(
        updateOrReorderOpeningOption(
          payload,
          'content',
          handleSuccessInputCallback,
          failedCb
        )
      );
    }
  };
  const handleSuccessInputCallback = (index, resp) => {
    let data = [...opening_options];
    let response = { ...resp.data };
    data[index] = { ...response };
    dispatch(setOpeningStarterOptions(data));
    handleLoader(false);
    setActive(false);
  };
  const handleChange = () => {
    if (!isEmpty(text)) {
      if (type === 'message') {
        handleMsgInputChange(task, index, text);
      } else if (type === 'option') {
        handleOptionInputChange(task, index, text);
      }
    }
  };
  return (
    <div
      ref={nodeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`bot-chat-message__list ${active ? 'active' : ''} ${
        hover ? 'hover' : ''
      }`}
    >
      <div className="bot-chat-message__list__container">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleChange();
          }}
        >
          <TextInputField
            name="Botchat Message"
            type="text"
            className="input-field bot-chat-message__input"
            value={text}
            ref={inputRef}
            placeholder={`Please enter ${placeholder}`}
            onChange={(e) => {
              setText(e.target.value);
            }}
            autoComplete={'off'}
          />
        </form>
        <CustomToolTip
          tooltipText="Click to edit"
          position="top"
          tooltipStyle={{
            minWidth: 96,
            left: '18%',
            bottom: '107%',
            padding: 5
          }}
        >
          <div
            className="input-field bot-chat-message__input bot-chat-message__input--container"
            onClick={() => {
              setActive(true);
            }}
          >
            {value ? (
              value
            ) : (
              <span className="text-[#4c5254]">Please enter {placeholder}</span>
            )}
          </div>
        </CustomToolTip>
        <div className="action-icons-container">
          <div className="smiley-icon-wrapper" onClick={onAddEmoji}>
            <img src={smileyIcon} alt="smiley" />
          </div>
          <div className="v-separtor-line"></div>
          <CustomToolTip
            tooltipText={
              task.status !== 'New' && lengthOfDataItems() === 1
                ? `You must have atleast one ${tooltip}`
                : 'Delete'
            }
            position="top"
            tooltipStyle={{ maxWidth: 150, left: '56%', padding: 5 }}
          >
            <div
              className="delete-icon-wrapper cursor-pointer"
              onClick={() => {
                setShowDelete(true);
                setIndex(index);
                setItem(task);
                if (task.status !== 'New') setShowDelete(true);
                else onRemove(task, index);
              }}
              style={
                task.status !== 'New' && lengthOfDataItems() === 1
                  ? {
                      pointerEvents: 'none',
                      opacity: 0.3
                    }
                  : {}
              }
            >
              <img
                src={minusIcon}
                alt="delete-icon"
                style={{ width: 28, height: 28 }}
              />
            </div>
          </CustomToolTip>
          <CustomToolTip
            tooltipText="Save"
            position="top"
            tooltipStyle={{ minWidth: 70, left: '64%', padding: 5 }}
          >
            <div
              className={
                'save-icon-wrapper ml-3 ' +
                (isEmpty(text)
                  ? 'disabled cursor-not-allowed'
                  : 'cursor-pointer')
              }
              onClick={handleChange}
            >
              {isLoading ? (
                <Loader loaderStyle={{ marginTop: '-16px' }} />
              ) : (
                <img src={checkIcon} alt="check-icon" />
              )}
            </div>
          </CustomToolTip>
        </div>
      </div>
      {task.status !== 'New' && (
        <div className="drag-and-drop-container ml-3">
          <img src={dragAndDropIcon} alt="" />
        </div>
      )}
      {openEmoji && (
        <Modal.Main
          open={openEmoji}
          className="modal"
          style={{
            zIndex: 502
          }}
        >
          <Modal.Header
            title="Add emoji"
            onClose={() => {
              setOpenEmoji(false);
              setDragDisabled(false);
            }}
          />
          <Modal.Body style={{ padding: 0, minHeight: 450 }}>
            <EmojiModal
              hideSearch={true}
              className="mt-1"
              setOpenEmoji={setOpenEmoji}
              setDragDisabled={setDragDisabled}
              onEmojiSelect={onEmojiSelect}
              style={{ height: 'calc(100% + 30px)' }}
            />
          </Modal.Body>
        </Modal.Main>
      )}
    </div>
  );
};

export default Message;

Message.propTypes = {
  id: PropTypes.string,
  task: PropTypes.object,
  handleInputChange: PropTypes.func,
  setDragDisabled: PropTypes.func
};

Message.defaultProps = {
  id: '',
  task: {}
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moveIcon from 'assets/icons/moveIcon.svg';
import PrimaryButton from 'components/buttons/primary';
import { ReactComponent as CloseIcon } from 'assets/icons/bin.svg';
import { ReactComponent as AddIcon } from 'assets/icons/icon-plus-green.svg';
import TextInputField from 'components/inputs/textField';
import Select from 'components/inputs/select';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { getQuestionsList } from 'slices/questionSlice';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Avatar from 'components/avatar';
import {
  getCardDesign,
  updateCardDesigne
} from 'slices/cardDesignSettingsSlice';
import { useSnackbar } from 'components/Snackbar';
import Toggle from 'components/inputs/toggle';
import ErrorMessage from 'components/errorMessage';
import { useAnalytics } from 'use-analytics';
import usePlanAccess from 'hooks/usePlanAccess';

const CreateCardDesignSettings = () => {
  const dispatch = useDispatch();
  // const getFormFields = optionsData && optionsData?.map((e) => e.name);
  const [isDragStarted, setIsDragStarted] = useState(false);
  const { box_id } = useParams();
  const [error, setError] = useState(false);
  const [questionOptions, setQuestionOptions] = useState([]);
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [showMoveIcon, setShowMoveIcon] = useState();
  const [zIndexForDropDown, setZindexforDropdown] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPhone, setShowPhone] = useState(false);
  const [showRecentMessage, setShowRecentMessage] = useState(false);

  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { questionsData } = useSelector((state) => state.question);
  const { cardData } = useSelector((state) => state.cardDesignSettings);
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();
  const [questionWiseOptions, setQuestionWiseOptions] = useState([]);

  const { has_card_design } = usePlanAccess();
  useEffect(() => {
    if (!isEmpty(questionsData)) {
      const questionOptions = questionsData.map((question) => {
        return {
          value: question?.id,
          label: question?.text,
          kind: question?.kind
        };
      });
      setQuestionOptions(questionOptions);
      // console.log('questionOptions', { questionOptions });
    }
  }, [questionsData]);

  useEffect(() => {
    if (!isEmpty(box_id)) dispatch(getCardDesign({ box_id: box_id }));
  }, [box_id]);

  const { openSuccessSnackBar } = useSnackbar();

  useEffect(() => {
    setError(false);
  }, [selectedQuestions]);

  const onRemoveOption = (id, key) => {
    const filteredOptions = selectedQuestions.filter(
      (item) => item.value !== id
    );

    if (selectedQuestions.length > 1) {
      setSelectedQuestions([...filteredOptions]);
      setQuestionWiseOptions([{ value: questionOptions, label: `question1` }]);
    } else {
      setSelectedQuestions([]);
      setQuestionWiseOptions([]);
    }
  };

  const onDragStart = () => {
    setIsDragStarted(true);
  };

  const onDragEnd = (result) => {
    setIsDragStarted(false);
    const { destination, source } = result;
    if (!destination) return;
    if (source.index !== destination.index) {
      let tempOptionsData = selectedQuestions;
      const [removed] = tempOptionsData.splice(source.index, 1);
      tempOptionsData.splice(destination.index, 0, removed);
      setSelectedQuestions(tempOptionsData);
    }
    const tempQuestionWiseOptions = questionWiseOptions;
    const [removedQuestionsWiseData] = tempQuestionWiseOptions.splice(
      source.index,
      1
    );
    tempQuestionWiseOptions.splice(
      destination.index,
      0,
      removedQuestionsWiseData
    );
    setQuestionWiseOptions(tempQuestionWiseOptions);
  };

  useEffect(() => {
    const payload = {
      subOrgId: subOrganizationId,
      query: {
        box_id: box_id
      }
    };
    dispatch(getQuestionsList(payload));
  }, []);

  const onAddOption = (index) => {
    const formData = {
      value: `question${selectedQuestions.length + 1}`,
      label: ''
    };
    setSelectedQuestions([...selectedQuestions, formData]);
    setQuestionWiseOptions([
      ...questionWiseOptions,
      {
        label: `question${selectedQuestions.length + 1}`,
        value:
          questionWiseOptions.length >= 1
            ? questionOptions.filter((item) => {
                return item.value !== selectedQuestions[0]?.value && item;
              })
            : questionOptions
      }
    ]);
  };

  const onSubmit = async (evt) => {
    evt.preventDefault();
    const formData = {
      box_id: box_id,
      data: {
        sub_organization: subOrganizationId,
        card_design: JSON.stringify({
          name: true,
          most_recent_message: showRecentMessage,
          show_email: showEmail,
          show_phone: showPhone
        })
      }
    };
    let isError = false;
    if (!isEmpty(selectedQuestions)) {
      selectedQuestions.forEach((question) => {
        if (isEmpty(question.label)) {
          isError = true;
          setError(true);
        }
      });
    }
    if (isError) return;
    setIsLoading(true);
    if (!isEmpty(selectedQuestions) && selectedQuestions.length < 2) {
      formData['data']['card_design'] = JSON.stringify({
        name: true,
        most_recent_message: showRecentMessage,
        show_email: showEmail,
        show_phone: showPhone,
        question1: {
          id: selectedQuestions[0]?.value,
          name: selectedQuestions[0]?.label,
          kind: selectedQuestions[0]?.kind
        }
      });
    } else if (!isEmpty(selectedQuestions) && selectedQuestions.length >= 2) {
      formData['data']['card_design'] = JSON.stringify({
        name: true,
        most_recent_message: showRecentMessage,
        show_email: showEmail,
        show_phone: showPhone,
        question1: {
          id: selectedQuestions[0]?.value,
          name: selectedQuestions[0]?.label,
          kind: selectedQuestions[0]?.kind
        },
        question2: {
          id: selectedQuestions[1]?.value,
          name: selectedQuestions[1]?.label,
          kind: selectedQuestions[1]?.kind
        }
      });
    }
    dispatch(updateCardDesigne(formData, onSuccess));
  };

  const onSuccess = () => {
    track('box_card_design', {}, { groupId: subOrganizationId });
    openSuccessSnackBar('Success');
    setIsLoading(false);
  };

  const handleOptionChange = (option, position) => {
    const x = selectedQuestions?.map((question, idx) => {
      if (idx === position) {
        return {
          ...question,
          value: option.value,
          label: option.label,
          kind: option.kind
        };
      }
      return question;
    });
    setSelectedQuestions(x);
    const filterOptions = questionWiseOptions.map((item, index) => {
      if (position !== index) {
        return {
          label: item?.label,
          value: questionOptions.filter((ques) => {
            if (ques.value !== option?.value) {
              return { label: ques.label, value: ques.value, kind: ques.kind };
            }
          })
        };
      } else {
        return questionWiseOptions[index];
      }
    });
    setSelectedQuestions(x);
    setQuestionWiseOptions(filterOptions);
  };

  const setCardData = (cardData) => {
    let tempSelectedData = new Array();
    let tempQuestionWiseOptions = new Array();
    const keys = ['question1', 'question2'];
    Object.keys(cardData).forEach((item, index) => {
      let i = 0;
      if (keys.includes(item)) {
        if (cardData[item]) {
          const getQuestion = questionsData?.find(
            (question) => question.id === cardData[item].id
          );
          const structuredSelectedData = {
            label: getQuestion?.text,
            value: cardData[item]?.id,
            kind: getQuestion?.kind,
            position: i
          };
          const structuredQuestionWiseOptions = {
            label: cardData[item].name,
            value:
              cardData['question1']?.id === cardData[item].id
                ? questionOptions.filter((item) => {
                    return item.value !== cardData['question2']?.id;
                  })
                : cardData['question2']?.id === cardData[item].id
                ? questionOptions.filter((item) => {
                    return item.value !== cardData['question1']?.id;
                  })
                : questionOptions
          };
          // console.log({ structuredQuestionWiseOptions, questionOptions })
          i++;
          tempSelectedData.push(structuredSelectedData);
          tempQuestionWiseOptions.push(structuredQuestionWiseOptions);
        }
      }
    });
    // console.log({ tempQuestionWiseOptions, cardData });
    setQuestionWiseOptions(tempQuestionWiseOptions);
    setSelectedQuestions(tempSelectedData);
  };
  useEffect(() => {
    if (!isEmpty(cardData) && !isEmpty(questionOptions)) {
      setCardData(cardData);
      setShowRecentMessage(cardData?.most_recent_message);
      setShowEmail(cardData?.show_email || false);
      setShowPhone(cardData?.show_phone || false);
    }
  }, [cardData, questionOptions]);

  return (
    <div className="dashboard__main__body">
      <div className="dashboard__main__body__section-right">
        <h2 className="text-2xl font-semibold text-center">
          Design your enquiry card
        </h2>
        <p className="text-grey-800 text-15 text-center mt-3 px-16">
          Show only the details you need at a glance
        </p>
        <form className=" relative" autoComplete="off">
          <div className="general-settings__box-card-bg  mt-12">
            <div className="pt-8 px-12">
              <div className="px-12 ">
                <h5 className="text-base-1 font-semibold text-grey-800">
                  Preview of the Card
                </h5>
                <div className="flex mt-3 p-3 bg-white general-settings__box-card-bg__preview-card rounded-xl">
                  <Avatar
                    initial={'Customers Name'}
                    style={{ width: 50, height: 50 }}
                    larger={true}
                    className="bg-color-800"
                  />
                  <div className="ml-5">
                    <h4 className="font-semibold">Customers Name</h4>
                    {showRecentMessage && (
                      <p className="text-base-1 text-grey-800">
                        Thanks for your recent enquiry...
                      </p>
                    )}
                    {showEmail && (
                      <p className="text-base-1 text-grey-800">
                        customer@domain.com
                      </p>
                    )}
                    {showPhone && (
                      <p className="text-base-1 text-grey-800">xxxxxxxxxxx</p>
                    )}
                    {!isEmpty(selectedQuestions) &&
                      selectedQuestions.map((item, index) => {
                        return (
                          <p key={index} className="text-base-1 text-grey-800">
                            {item?.label}
                          </p>
                        );
                      })}
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <label className="input-field-label">Name</label>
                <TextInputField
                  className="input-field--xs-34 input-field--responsive-xs-34 disabled"
                  name={'Name'}
                  placeholder={'Customers Name will be show hear'}
                  disabled={true}
                />
              </div>
              <div className="mt-6">
                <div className="flex justify-between">
                  <label className="input-field-label">
                    Most recent message
                  </label>
                  <div className="ml-auto mb-2">
                    <div className="flex items-center toggle-slide">
                      <Toggle
                        id={'isMostRecentMessage'}
                        name="isMostRecentMessage"
                        register={() => {}}
                        checked={showRecentMessage}
                        onChange={(evt) =>
                          setShowRecentMessage(evt.target.checked)
                        }
                      />
                    </div>
                  </div>
                </div>

                <TextInputField
                  className="input-field--xs-34 input-field--responsive-xs-34"
                  name={'MostRecentMessage'}
                  placeholder="The most recent message will be shown here"
                  disabled={true}
                />
              </div>
              <div className="flex mt-6">
                <div className="flex justify-between">
                  <label className="input-field-label">Email</label>
                  <div className="ml-auto mb-2">
                    <div
                      className="flex items-center toggle-slide"
                      style={{ marginTop: '-3px' }}
                    >
                      <Toggle
                        id="Email"
                        name="Email"
                        register={() => {}}
                        checked={showEmail}
                        onChange={(evt) => setShowEmail(evt.target.checked)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex justify-between ml-10">
                  <label className="input-field-label">Phone</label>
                  <div className="ml-auto mb-2">
                    <div
                      className="flex items-center toggle-slide "
                      style={{ marginTop: '-3px' }}
                    >
                      <Toggle
                        id="Phone"
                        name="Phone"
                        register={() => {}}
                        checked={showPhone}
                        onChange={(evt) => setShowPhone(evt.target.checked)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="relative mt-3 ">
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className="pb-2"
                    >
                      {!isEmpty(selectedQuestions) &&
                        selectedQuestions.map((item, index) => (
                          <Draggable
                            key={item.value}
                            draggableId={item.value}
                            index={index}
                          >
                            {(provided, snapshot) => {
                              if (snapshot.isDragging) {
                                const offset = { x: 0, y: 0 };
                                const x =
                                  provided.draggableProps.style.left - offset.x;
                                const y =
                                  provided.draggableProps.style.top - offset.y;
                                provided.draggableProps.style = {
                                  ...provided.draggableProps.style,
                                  left: x,
                                  top: y
                                };
                              }
                              return (
                                <div
                                  className={
                                    !isDragStarted
                                      ? 'chats-preview bg-color-on-hover bg-color-on-hover--sm block'
                                      : ''
                                  }
                                  style={{
                                    display: 'block',
                                    marginLeft: '35px',
                                    paddingLeft: '13px',
                                    marginRight: '33px',
                                    paddingRight: '15px',
                                    paddingBottom: '16px',
                                    zIndex:
                                      zIndexForDropDown === index ? '1' : '0'
                                  }}
                                  onMouseEnter={() => setShowMoveIcon(index)}
                                  onMouseLeave={() => setShowMoveIcon(null)}
                                  onMouseOver={() => setShowMoveIcon(index)}
                                >
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    onClick={() => setZindexforDropdown(index)}
                                  >
                                    <h3 className="text-sm font-semibold ">
                                      {`Question ${index + 1}`}
                                    </h3>
                                    <div className="relative mt-2">
                                      {
                                        !isDragStarted &&
                                          showMoveIcon === index && (
                                            // <CustomToolTip tooltipText={'Drag to reorder'}>
                                            <img
                                              src={moveIcon}
                                              alt="icon-channel"
                                              className="mt-2 absolute"
                                              style={{
                                                top: ' 1px',
                                                zIndex: 1,
                                                left: '-16px'
                                              }}
                                            />
                                          )
                                        // </CustomToolTip>
                                      }
                                      <Select
                                        name={`question${index + 1}`}
                                        options={
                                          questionWiseOptions[index]?.value
                                        }
                                        inputClassName="input-field--xs-34"
                                        dropDownClassName="p-2"
                                        placeholder="select question"
                                        readOnly={true}
                                        onChange={(value) =>
                                          handleOptionChange(value, index)
                                        }
                                        value={item.label}
                                        isWhenSelect={true}
                                        labelDividerText={'Questions'}
                                        isSearchInsideDropDown={true}
                                        labelDividerTextClass={
                                          'text-grey-700 mt-5 mb-5'
                                        }
                                        selectedAnswer={item.label}
                                      />
                                      {!isDragStarted &&
                                        showMoveIcon === index && (
                                          <div
                                            style={{
                                              width: '14px',
                                              height: '16px',
                                              position: 'absolute',
                                              top: '8px',
                                              right: '-20px'
                                            }}
                                          >
                                            <CloseIcon
                                              className="cursor-pointer"
                                              onClick={() =>
                                                onRemoveOption(
                                                  item.value,
                                                  item.key
                                                )
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {error && (
                <div style={{ marginTop: '-13px', marginBottom: '10px' }}>
                  <ErrorMessage
                    message="Please select question"
                    className="ml-12"
                  />
                </div>
              )}
            </div>
            {selectedQuestions.length < 2 ? (
              <div
                className="flex items-center gap-2 cursor-pointer max-w-max pb-10 px-12"
                onClick={onAddOption}
              >
                <AddIcon />
                <p className="text-sm font-semibold text-primary">
                  Add Question
                </p>
              </div>
            ) : (
              <p className="text-13 text-grey-800 pb-10 px-12">
                Maximum number of questions for card reached
              </p>
            )}
          </div>
          <div className="mt-10 flex justify-end mb-5">
            <PrimaryButton
              className={`btn  ${
                !has_card_design
                  ? ' btn--blocked cursor-not-allowed'
                  : 'btn--primary'
              } btn--md`}
              label="Save"
              style={{ minWidth: 120 }}
              onClick={has_card_design ? onSubmit : () => {}}
              isLoading={isLoading}
              disabled={!has_card_design || isLoading}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateCardDesignSettings;

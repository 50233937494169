/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import SideNav from './sideNav';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { setRedirect } from 'slices/authSlice';
import choices from 'choices';
import { route } from 'utils/route';
// import DashboardMain from '../channel/main';
import Enquirybox from 'modules/enquirybox/route';
// import ProductTour from './component/productTour';
import BusinessInfo from 'modules/registration/businessInfo/modal';
import {
  getAllConnectedChannels,
  getAllPendingChannels
} from 'slices/channelsSlice';
import { getMyInfo } from 'slices/myInfoSlice';
import { isEmpty } from 'utils/utils';
// import TeamChat from 'modules/teamChat';
// import { getOrganizationUser } from 'slices/organizationuserSlice';
import Settings from 'modules/settings/route';
import Boxes from 'modules/boxes/route';
import TeamChatRoute from 'modules/teamChat/route';
import { getDeviceInfo } from 'utils/devices';
import { useAnalytics } from 'use-analytics';
import { connectEbotToEbox } from 'slices/confirmEbotToEbox/slice';
import { BOT_ID, EBOT_EMAIL } from 'utils/constants';
import { getOrganizationUser } from 'slices/organizationuserSlice';
import storageFactory, {
  getPlanTypeKey,
  getPartnerId
} from 'utils/localstorage';

const { BUSINESS_INFO } = choices.FrontendRedirectChoice;
const IndustryChoices = choices.IndustryChoices.CHOICES;
const DashBoardRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate to={`${route.dashboard}${route.enquirybox}${route.box}`} />
        }
      />
      <Route path={`${route.enquirybox}/*`} element={<Enquirybox />} />
      <Route path={`${route.settings}/*`} element={<Settings />} />
      <Route path={`${route.teamChat}/*`} element={<TeamChatRoute />} />
      <Route path={`${route.boxes}/*`} element={<Boxes />} />
    </Routes>
  );
};

const Dashboard = () => {
  const dispatch = useDispatch();
  const { allPendingChannels } = useSelector((state) => state.channels);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const redirect = useSelector((state) => state.auth.redirect);
  const { identify, track, plugins } = useAnalytics();
  const [showBusinessInfo, setShowBusinessInfo] = useState(
    redirect === BUSINESS_INFO
  );

  const sendLoginAnalytics = (data) => {
    identify(
      data.id,
      {
        name: data.name,
        first_name: data.first_name,
        last_name: data.last_name || '',
        avatar: data.avatar,
        user_level: choices.TeamMemberRoleChoice.CHOICES[data.my_role],
        email: data.email,
        user_status: data.is_active ? 'active' : 'deactive',
        reply_email: data.reply_sending_mail_address,
        created_at: data.created_at,
        country: data?.country,
        company_name: data?.company_name,
        industry: IndustryChoices[data?.industry],
        login_type: choices.SignupChoice[data.last_login_through],
        email_signature: choices.EmailSignatureChoice[data?.email_signature],
        website: data?.website
        // plan_amount: data?.plan_details?.plan?.amount,
        // plan_name: data?.plan_details?.plan?.plan_name
      },
      {
        groupId: data.sub_organizational_ids[0].sub_organization_id
      }
    );
    plugins.segment.group(data.sub_organizational_ids[0].sub_organization_id, {
      name: data?.company_name,
      industry: IndustryChoices[data?.industry],
      website: data?.website,
      country: data?.country,
      team_size: data?.team_size,
      plan_amount: data?.plan_details?.plan?.amount,
      plan_name: data?.plan_details?.plan?.plan_name
    });
    track(
      'logged_in',
      {
        device: getDeviceInfo()?.currentos?.name || '',
        browser: getDeviceInfo()?.currentbrowser?.name || ''
      },
      {
        groupId: data.sub_organizational_ids[0].sub_organization_id
      }
    );
    if (data.partner_id) {
      track(
        'parnter_signup',
        {
          partner_id: data?.partner_id,
          client_name: data?.name
        },
        {
          groupId: data.sub_organizational_ids[0].sub_organization_id
        }
      );
    }
  };

  const getPendingChannels = () =>
    dispatch(getAllPendingChannels({ subOrgId: subOrganizationId }));

  // const loadUsersList = () => {
  //   dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
  // };

  useEffect(() => {
    if (subOrganizationId && isEmpty(allPendingChannels)) getPendingChannels();
  }, [subOrganizationId]);

  const callback = () => {
    localStorage.removeItem(EBOT_EMAIL);
    localStorage.removeItem(BOT_ID);
    dispatch(getAllConnectedChannels({ subOrgId: subOrganizationId }));
  };

  const loadConnectEbotToEbox = () => {
    const ebotEmail = localStorage.getItem(EBOT_EMAIL);
    const botId = localStorage.getItem(BOT_ID);
    if (ebotEmail && botId) {
      let payload = {
        email: ebotEmail,
        bot_id: botId
      };
      dispatch(connectEbotToEbox(payload, callback));
    }
  };

  useEffect(() => {
    loadConnectEbotToEbox();
  }, []);

  // useEffect(() => {
  //   if (subOrganizationId) {
  //     loadUsersList();
  //   }
  // }, [subOrganizationId]);

  const onSuccessBusiness = () => {
    setShowBusinessInfo(false);
    dispatch(
      getMyInfo((data) => {
        const { sub_organizational_ids } = data;
        const [sub_org] = sub_organizational_ids;
        dispatch(
          getOrganizationUser(
            { subOrgId: sub_org.sub_organization_id },
            () => {},
            true
          )
        );
        dispatch(getAllConnectedChannels({ subOrgId: subOrganizationId }));
        sendLoginAnalytics(data);
        loadConnectEbotToEbox();
      })
    );
    dispatch(setRedirect(null));
    storageFactory().removeItem(getPlanTypeKey());
    storageFactory().removeItem(getPartnerId());
  };

  return (
    <React.Fragment>
      {showBusinessInfo && (
        <BusinessInfo open={showBusinessInfo} onClose={onSuccessBusiness} />
      )}
      {/* <ProductTour open={showTourWindow} onClose={() => setShowTourWindow(false)} /> */}
      {/* <SideNav /> */}
      <Routes>
        <Route path="/*" element={<DashBoardRoute />} />
      </Routes>
    </React.Fragment>
  );
};

export default React.memo(Dashboard);

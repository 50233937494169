import { useEffect, useRef, useState } from 'react';
import { isEmpty } from 'utils/utils';
import PropTypes from 'prop-types';
import TextInputField from 'components/inputs/textField';

const InputResponse = (props) => {
  const inputRef = useRef(null);
  const {
    lead_id,
    options,
    handleContinueConv,
    history,
    text,
    setText,
    error,
    setError,
    isLoading,
    disableText,
    setDisableText
  } = props;

  useEffect(() => {
    if (!(isEmpty(history) || isLoading || disableText)) {
      inputRef.current.focus();
    }
  }, [history, isLoading, disableText]);

  useEffect(() => {
    if (isEmpty(history)) {
      setDisableText(false);
    } else {
      setDisableText(true);
    }
  }, [history]);

  const handleClick = (e) => {
    if (e) {
      e.preventDefault();
      if (e.key === 'Enter') {
        handleContinueConv({ answer: text });
        setText('');
      }
    }
    if (text === '') {
      setError(true);
    } else {
      handleContinueConv({ answer: text });
      setText('');
    }
  };

  return (
    <div
      className="chat-msg-container"
      style={{ padding: 0, marginLeft: 'auto', zIndex: 99 }}
    >
      <form
        onSubmit={(e) => {
          handleClick(e);
        }}
      >
        <TextInputField
          name="Chat Message Input"
          type="text"
          className="chat-msg__input"
          placeholder="Write a response"
          value={text}
          onChange={(e) => setText(e.target.value)}
          // onKeypress={(e) => handleClick(e)}
          ref={inputRef}
          disabled={disableText || isEmpty(history) || isLoading}
          autoComplete={'off'}
        />
      </form>
      <div
        className={`chat-msg__send-icon ${
          isEmpty(text) ? ' !cursor-not-allowed' : '!bg-[#52c26d]'
        }`}
      >
        <svg
          width="19"
          height="18"
          viewBox="0 0 19 18"
          fill="none"
          onClick={() => (!isEmpty(text) ? handleClick() : () => {})}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3.61857 7.78427L2.3065 5.15746C0.935458 2.41258 0.249938 1.04014 0.879837 0.409598C1.50974 -0.220943 2.88078 0.465278 5.62286 1.83772L15.5535 6.80813C17.4829 7.77382 18.4477 8.25666 18.4477 9.02129C18.4477 9.78592 17.4829 10.2688 15.5535 11.2345L5.62285 16.2049L5.62284 16.2049C2.88077 17.5773 1.50973 18.2635 0.879835 17.633C0.249937 17.0024 0.935458 15.63 2.3065 12.8851L3.61839 10.2587L11.2314 10.2587C11.914 10.2587 12.4674 9.70475 12.4674 9.02147C12.4674 8.33818 11.914 7.78427 11.2314 7.78427L3.61857 7.78427Z"
            fill={isEmpty(text) ? '#BBBBBB' : '#fff'}
          />
        </svg>
      </div>
    </div>
  );
};

export default InputResponse;

InputResponse.propTypes = {
  leadId: PropTypes.string,
  options: PropTypes.array,
  handleContinueConv: PropTypes.func,
  history: PropTypes.array,
  isLoading: PropTypes.bool
};

InputResponse.defaultProps = {
  leadId: '',
  options: [],
  handleContinueConv: () => {},
  history: [],
  isLoading: false
};

import Toggle from 'components/inputs/toggle';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSnackbar } from 'components/Snackbar';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setIsNudgeActive, UpdateNudgeActiveStatus } from 'slices/myInfoSlice';

const Nudges = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { isNudgeActive, myInformation } = useSelector((state) => state.myInfo);
  const [isActiveToggle, setIsActiveToggle] = useState(isNudgeActive);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  useEffect(() => {
    setIsActiveToggle(isNudgeActive);
  }, [isNudgeActive]);

  const handleNudgeActive = (evt) => {
    setIsLoading(true);
    setIsActiveToggle(evt.target.checked);

    const payload = {
      userId: myInformation.id,
      data: {
        move_enquiry_stages_in_boxes: evt.target.checked
      }
    };
    dispatch(UpdateNudgeActiveStatus(payload, onSuccess, onError));
  };

  const onSuccess = (status) => {
    setIsLoading(false);
    openSuccessSnackBar('Success');
    dispatch(setIsNudgeActive(status));
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
    setIsActiveToggle(!isActiveToggle);
  };

  return (
    <div className="`dashboard__main__body__section-right overflow-hidden p-10`">
      <div>
        <h2 className="text-2xl font-semibold text-center">Nudges</h2>
        <p className="text-grey-800 text-15 text-center mt-3">
          Change what and when your get nudged
        </p>
      </div>
      <div className="mt-6 card-box py-5 px-6 relative">
        {isLoading && <OverLayLoader />}
        <h3 className="font-semibold text-lg">Nudges</h3>
        <p className="text-sm text-grey-800 mt-1">
          A nudge is a little reminder from your enquiry assistant to do
          something.
        </p>

        <div className="border border-grey-400 rounded-xl p-4 mt-6 bg-grey-300 ">
          <div className="flex justify-between">
            <h3 className="font-semibold">
              Nudge me to move enquiry stages in boxes
            </h3>
            <div className="toggle-slider">
              <Toggle
                id="nudges"
                small
                checked={isActiveToggle}
                onChange={handleNudgeActive}
              />
            </div>
          </div>
          <p className="text-sm text-grey-800 mt-2">
            This is a handy little helper to help you keep track of your
            enquiries
          </p>
        </div>
      </div>
    </div>
  );
};

export default Nudges;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const CardLayout = ({ children, className, style }) => {
  return (
    <div className={`${styles.__card_container} ${className}`} style={style}>
      {children}
    </div>
  );
};

CardLayout.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]).isRequired
};

CardLayout.defaultProps = {
  className: ''
};

export default CardLayout;

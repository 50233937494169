import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImagePreview, setItem } from 'slices/appSlice';
import downloadIcon from 'assets/icons/icon-btn-download.svg';
import closeModalIcon from 'assets/icons/icon-close-full-image.svg';
import nextIcon from 'assets/icons/icon-next-image.svg';
import prevIcon from 'assets/icons/icon-prev-image.svg';
import DownloadLink from 'components/downloadLink';

const ImageModal = ({ showImageModal, showModal, previewImageIndex }) => {
  const dispatch = useDispatch();
  const { imageFileData } = useSelector((state) => state.fileLibrary);

  const onClose = () => {
    dispatch(
      setItem({
        key: 'showImagePreview',
        value: null
      })
    );
    dispatch(
      setItem({
        key: 'previewImageIndex',
        value: null
      })
    );
  };

  const handlePreviousClick = (file) => {
    const selectedIndex = imageFileData.findIndex(
      (item) => item.id === file.id
    );
    dispatch(
      setItem({
        key: 'previewImageIndex',
        value: selectedIndex - 1
      })
    );
    dispatch(setImagePreview(imageFileData[selectedIndex - 1]));
  };

  const handleNextClick = (file) => {
    const selectedIndex = imageFileData.findIndex(
      (item) => item.id === file.id
    );
    dispatch(
      setItem({
        key: 'previewImageIndex',
        value: selectedIndex + 1
      })
    );
    dispatch(setImagePreview(imageFileData[selectedIndex + 1]));
  };

  return (
    <div
      className={`modal modal--image-full flex ${showModal ? 'show' : ''}`}
      style={{ zIndex: 502 }}
    >
      <div
        className="modal__prev-icon"
        style={previewImageIndex !== 0 ? { zIndex: 502 } : {}}
        onClick={() => handlePreviousClick(showImageModal)}
      >
        <img src={prevIcon} alt="" />
      </div>
      <div className="modal__container">
        <div className="modal__header">
          <h2 className="modal__heading pr-5">{showImageModal?.file_name}</h2>
          <div className="flex">
            {/* <div className="flex items-center text-grey-800">32.MB</div> */}
            <DownloadLink
              to={showImageModal?.file_url}
              className="btn btn--primary btn--xs"
            >
              <img src={downloadIcon} alt="" className="mr-1" />
              Download
            </DownloadLink>
          </div>
        </div>
        <div
          className="modal__body w-full"
          style={{ height: 'calc(97vh - 64px)' }}
        >
          <img
            src={showImageModal?.file_url}
            alt=""
            className="object-contain"
            style={{ width: '100%', height: 'calc(97vh - 70px)' }}
          />
        </div>
      </div>
      <div
        className="modal__close-full-image z-50 cursor-pointer"
        style={{ zIndex: 502 }}
        onClick={onClose}
      >
        <img src={closeModalIcon} alt="" />
      </div>
      <div
        className="modal__next-icon"
        style={
          previewImageIndex !== imageFileData.length - 1 ? { zIndex: 502 } : {}
        }
        onClick={() => handleNextClick(showImageModal)}
      >
        <img src={nextIcon} alt="" />
      </div>
    </div>
  );
};

const ImageModalContainer = ({ open }) => {
  const { imagePreview, previewImageIndex } = useSelector((state) => state.app);
  return (
    <ImageModal
      showModal={open}
      showImageModal={imagePreview}
      previewImageIndex={previewImageIndex}
    />
  );
};

export default ImageModalContainer;

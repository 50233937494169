import { isArray } from 'lodash';
import { isEmpty } from 'utils/utils';

export const toQueryString = (params) => {
  if (isEmpty(params)) return '';
  const queryParams = Object.keys(params)
    .filter((key) => !isEmpty(params[key]))
    .map((key) => {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = isArray(params[key])
        ? params[key]
        : encodeURIComponent(params[key]);
      if (isArray(params[key])) {
        return `${encodedKey}=${JSON.stringify(encodedValue)}`;
      } else {
        return `${encodedKey}=${encodedValue}`;
      }
    })
    .join('&');
  if (isEmpty(queryParams)) return `${queryParams}`;
  return `?${queryParams}`;
};

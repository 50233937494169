/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import choices from 'choices';
import { isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';
import iconAddAutomationRound from 'assets/icons/icon-add-automation-round.svg';
import ThenHeader from './thenHeader';
import SendEmailEditor from '../email';
import AssignTeamMember from '../assignTeamMember';
import DelayTimer from '../delayTimer';
import MoveToBox from '../moveToBox';
import MessengerEditor from '../messenger';
import SmsEditor from '../sms';
import RenderLoader from './renderLoader';
import AutomationNote from '../note';
import TaskAutomation from '../task';
import InputField from 'components/inputs/textField';

const {
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  FACEBOOK,
  INSTAGRAM,
  DELAY_TIMER,
  MOVE_TO_BOX,
  SMS,
  NOTES,
  TASK
} = choices.BoxAutomationChoice;

const EditThen = ({ condition, index, addAnotherThen }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { then, selectedAutomation } = useSelector(
    (state) => state.boxAutomation
  );

  const renderThen = (type, condition, index) => {
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return (
          <RenderLoader>
            <SendEmailEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case ASSIGN_MEMBER:
        return <AssignTeamMember condition={condition} index={index} />;
      case FACEBOOK:
        return (
          <RenderLoader>
            <MessengerEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case INSTAGRAM:
        return (
          <RenderLoader>
            <MessengerEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case DELAY_TIMER:
        return <DelayTimer condition={condition} index={index} />;
      case MOVE_TO_BOX:
        return <MoveToBox condition={condition} index={index} />;
      case SMS:
        return (
          <RenderLoader>
            <SmsEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case NOTES:
        return (
          <RenderLoader>
            <AutomationNote condition={condition} index={index} />
          </RenderLoader>
        );
      case TASK:
        return (
          <RenderLoader>
            <TaskAutomation condition={condition} index={index} />
          </RenderLoader>
        );
      default:
        return <div></div>;
    }
  };

  // should we show edit button once the automation is saved
  const shouldIsEditBeShown = (type) => {
    const showEdit = [
      FACEBOOK,
      INSTAGRAM,
      DELAY_TIMER,
      AUTORESPONDER_EMAIL,
      SMS,
      NOTES,
      TASK
    ];
    if (showEdit.includes(type)) return true;
    return false;
  };

  const getThenDescription = (condition) => {
    let type = 0;
    if (selectedAutomation && condition.automation_task_id) {
      type = condition.automation_type;
    }
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return 'Send an email';
      case ASSIGN_MEMBER:
        return 'Assign to team member';
      case DELAY_TIMER:
        return 'Add a delay timer';
      case MOVE_TO_BOX:
        return 'Select the box to move to';
      // case FACEBOOK:
      //   return 'Send a Facebook Message';
      // case INSTAGRAM:
      //   return 'Send a Instagram DM';
      case SMS:
        return 'Send sms';
      case NOTES:
        return 'Add a note';
      case TASK:
        return 'Create a task';
      default:
        return 'This action will happen automatically';
    }
  };

  const checkIfCategoryIsSelected = () => {
    return !isEmpty(condition);
  };

  const checkIfCategoryTypeIsMoveToBox = () => {
    if (isEmpty(condition)) return false;
    if (condition.automation_type === MOVE_TO_BOX) return true;
  };

  const checkIsLastThen = (index) => {
    if (isEmpty(condition)) return false;
    return index === then.length - 1;
  };

  return (
    <div className="relative">
      <div
        className={`box-settings__automation-template__automation-stuff box-settings__automation-card mt-6 px-5 pt-5 pb-6 mb-3 ${
          !checkIfCategoryIsSelected() || checkIfCategoryTypeIsMoveToBox()
            ? 'last'
            : ''
        }`}
      >
        <ThenHeader
          // description={getThenDescription(condition)}
          condition={condition}
          index={index}
        />
        {shouldIsEditBeShown(condition.automation_type) && (
          <>
            <div className="mt-2">
              <div className="relative">
                <InputField
                  name="move to"
                  placeholder="An enquiry move to a particular stage"
                  value={getThenDescription(condition)}
                  disabled={true}
                  style={{ fontSize: 14 }}
                />
                {!isEdit && (
                  <p
                    className="edit-then cursor-pointer"
                    onClick={() => setIsEdit(!isEdit)}
                  >
                    Edit
                  </p>
                )}
              </div>
            </div>
            <div className={`mt-3 ${isEdit ? '' : 'hidden'}`}>
              {renderThen(condition.automation_type, condition, index)}
            </div>
          </>
        )}
        {!shouldIsEditBeShown(condition.automation_type) && (
          <>
            <div className={`mt-3`}>
              {renderThen(condition.automation_type, condition, index)}
            </div>
          </>
        )}
      </div>
      {checkIsLastThen(index) && (
        <>
          {!checkIfCategoryTypeIsMoveToBox() && (
            <div className="flex justify-center">
              <img
                src={iconAddAutomationRound}
                alt=""
                style={{ width: 22, height: 22, marginLeft: 1, zIndex: 1 }}
                className="cursor-pointer"
                onClick={addAnotherThen}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EditThen;

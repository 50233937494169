import { formatMessage } from 'utils/utils';

export const isInViewport = (elem) => {
  var bounding = elem.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const removeSignatureFromContent = (message) => {
  var temp = document.createElement('div');
  temp.innerHTML = formatMessage(message);
  let elementToRemove = temp.querySelector('#user-signature');
  if (elementToRemove) {
    elementToRemove.remove();
  }
  let updatedHtmlString = temp.innerHTML;
  return updatedHtmlString;
};

import CHOICES from 'choices';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionsData } from 'slices/questionSlice';
import Date from './component/date';
import Dropdown from './component/dropdown';
import MultipleOptions from './component/multipleOptions';
import Text from './component/text';
import Value from './component/value';

const QuestionsForm = ({ isSettings = false }) => {
  const dispatch = useDispatch();
  const { selectedQuestion, questionsData } = useSelector(
    (state) => state.question
  );
  const [totalQuestionList, setTotalQuestionList] = useState(questionsData);
  const { TEXT, DROPDOWN, DATETIME, MCQ, VALUE } = CHOICES.QuestionChoices;

  useEffect(() => {
    setTotalQuestionList(questionsData);
  }, [questionsData]);

  const addQuestion = (data) => {
    dispatch(setQuestionsData([...totalQuestionList, data]));
  };

  const renderData = () => {
    switch (selectedQuestion.type) {
      case TEXT:
        return (
          <Text
            data={selectedQuestion}
            key={selectedQuestion.id}
            addQuestion={addQuestion}
          />
        );
      case MCQ:
        return (
          <MultipleOptions
            data={selectedQuestion}
            key={selectedQuestion.id}
            isSettings={isSettings}
            addQuestion={addQuestion}
          />
        );
      case DATETIME:
        return (
          <Date
            data={selectedQuestion}
            key={selectedQuestion.id}
            addQuestion={addQuestion}
          />
        );
      case DROPDOWN:
        return (
          <Dropdown
            data={selectedQuestion}
            key={selectedQuestion.id}
            isSettings={isSettings}
            addQuestion={addQuestion}
          />
        );
      case VALUE:
        return (
          <Value
            data={selectedQuestion}
            key={selectedQuestion.id}
            addQuestion={addQuestion}
          />
        );
      default:
        <></>;
    }
  };
  return renderData();
};

export default QuestionsForm;

import { Navigate, Route, Routes, useParams } from 'react-router-dom';
import PersonalityTab from './Personality';
import WelcomeTab from './Welcome';
import DataCollectionTab from './DataCollection';
import AllRoute from './Brain';
import DeployTab from '../assistant/Deploy';
import TestBot from './Test';
const ChatBotRoutes = () => {
  const { assistant_id } = useParams();
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate
              to={`/chatbot-ai/assistant/${assistant_id}/personality`}
            />
          }
        />
        <Route path="/personality/*" element={<PersonalityTab />} />
        <Route path="/welcome/*" element={<WelcomeTab />} />
        <Route path="/data-collection/*" element={<DataCollectionTab />} />
        <Route path="/Brain/*" element={<AllRoute />} />
        <Route path="/deploy/*" element={<DeployTab />} />
        <Route path="/test-bot/*" element={<TestBot />} />
      </Routes>
    </>
  );
};

export default ChatBotRoutes;

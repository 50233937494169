/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from 'react';
import { useParams, NavLink } from 'react-router-dom';
import Avatar from 'components/avatar';
import SubIcon from 'modules/enquirybox/ebox/module/box/components/enquiryCard/subIcon';
import Status from 'modules/enquirybox/ebox/module/box/components/enquiryCard/status';
import { cardDesign } from '../helper';
import {
  getLastReply,
  getLeadName,
  getLeadTime
} from 'modules/enquirybox/helper/lead';
import { useSelector } from 'react-redux';
import { route } from 'utils/route';
import CHOICES from 'choices';
import { getColorShade } from 'utils/colors';
import { getCurrencyText } from 'utils/currency';
import { numberWithCommas } from 'utils/number';
import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';
import moment from 'moment/moment';

// import { isEmpty } from 'utils/utils';

const RESTORED = CHOICES.LeadStatusChoices.RESTORED;
const { DATETIME, DATE_RANGE, DROPDOWN, MCQ, MCQ_MUTIPLE_SELECT, TEXT, VALUE } =
  CHOICES.QuestionChoices;

const { SMS, MANUALLY_ADDED, IMPORTED_FROM_FILE } = CHOICES.LeadChannelChoice;

const Lead = (props) => {
  const { myInformation } = useSelector((state) => state.myInfo);
  const currency = myInformation?.my_org_info?.currency?.code;
  const params = useParams();
  const { box_id, box_type } = params;
  const { lead, stageId, isNew, index } = props;
  const {
    name,
    first_name,
    channel,
    customer_avatar,
    unseen_reply_count,
    last_reply,
    status,
    answer_set,
    last_name
  } = lead;
  const { currentBox: box } = useSelector((state) => state.boxes);
  const leadRef = useRef();
  const card_design = cardDesign(box.card_design);
  const isRestored = status === RESTORED;

  const getAnswer = (question, answers) => {
    if (question) {
      const q = answer_set?.find((answer) => answer.question === question.id);
      if (q) {
        switch (q.question_details.kind) {
          case VALUE: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}: {getCurrencyText(currency)}{' '}
                {numberWithCommas(q.answer.description)}
              </div>
            );
            answers.push(x);
            break;
          }
          case TEXT: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}: {q.answer.description}
              </div>
            );
            answers.push(x);
            break;
          }
          case DATETIME: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}:{' '}
                {moment(q.answer.start_date).format('DD-MM-YYYY')}
              </div>
            );
            answers.push(x);
            break;
          }
          case DATE_RANGE: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}:{' '}
                {moment(q.answer.start_date).format('DD-MM-YYYY')}
                {' , '}
                {moment(q.answer.end_date).format('DD-MM-YYYY')}
              </div>
            );
            answers.push(x);
            break;
          }
          case DROPDOWN: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}: {q.answer.description}
              </div>
            );
            answers.push(x);
            break;
          }
          case MCQ: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}: {q.answer.description}
              </div>
            );
            answers.push(x);
            break;
          }
          case MCQ_MUTIPLE_SELECT: {
            const x = (
              <div key={question.id} className="box-card__question">
                {q.question_details.text}:{' '}
                {q.answer.map((ans) => ans.description).join(',')}
              </div>
            );
            answers.push(x);
            break;
          }
          default:
            return <div></div>;
        }
      }
    }
  };

  const RenderQuestions = () => {
    const { question1, question2 } = card_design;
    let answers = new Array();
    if (question1) {
      getAnswer(question1, answers);
    }
    if (question2) {
      getAnswer(question2, answers);
    }
    return <>{answers}</>;
  };

  return (
    <NavLink
      className={`box-card ${isNew ? 'box-card--new' : ''} mb-2 relative`}
      to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/${lead.channel}/${lead.id}`}
      activeclassname="active"
      style={
        isRestored
          ? {
              background: 'rgba(245, 94, 94, 0.1)',
              border: '1px solid #f55e5e'
            }
          : {}
      }
    >
      <div className="flex items-start gap-4 flex-1 truncate" ref={leadRef}>
        <div className="relative">
          {lead.channel === SMS ? (
            <figure
              className="general-settings__card-list__avatar-container"
              // style={{ backgroundColor: getColorShade(index) }}
            >
              <IconMyProfile style={{ width: 24, height: 24 }} />
            </figure>
          ) : (
            <Avatar
              src={customer_avatar}
              initial={name || first_name}
              style={{
                width: 48,
                height: 48,
                backgroundColor: customer_avatar ? '' : getColorShade(index)
              }}
              large
              cover
              singleChar
            />
          )}
          <div
            className="bg-white absolute flex justify-center items-center"
            style={{
              width:
                channel === MANUALLY_ADDED || channel === IMPORTED_FROM_FILE
                  ? 20
                  : 20,
              height: 20,
              borderRadius: '50%',
              bottom: '-3px',
              left: '28px'
            }}
          >
            <SubIcon kind={channel} />
          </div>
        </div>
        <div className="truncate w-full">
          <div className="box-card__title">
            {getLeadName(lead) ? getLeadName(lead) : 'DummyName'}
          </div>
          {card_design.most_recent_message && (
            <div
              className="box-card__msg mt-1"
              dangerouslySetInnerHTML={{ __html: getLastReply(lead) }}
            />
          )}
          {card_design.show_email && lead.email && (
            <div className="box-card__msg mt-1">{lead.email}</div>
          )}
          {card_design.show_phone && lead.phone_number && (
            <div className="box-card__msg mt-1">{lead.phone_number}</div>
          )}
          <RenderQuestions />
        </div>
      </div>
      <div className="self-baseline">
        <div className={`${isRestored ? 'flex justify-between gap-1' : ''}`}>
          {isRestored && (
            <label
              className="notification-label notification-label--new-msg"
              style={{
                maxWidth: '100%',
                padding: '2px 6px',
                background: '#F1511B'
              }}
            >
              Restored
            </label>
          )}
          <Status
            isReplied={last_reply?.is_reply_received}
            replyCount={unseen_reply_count}
            status={status}
            style={{ padding: '1px 7px' }}
          />
        </div>
        <p
          className="box-card__time"
          style={{ position: 'absolute', bottom: '13px', right: '11px' }}
        >
          {getLeadTime(lead)}
        </p>
      </div>
    </NavLink>
  );
};

export default Lead;

import React, { useEffect, useRef, useState } from 'react';
import TextInputField from 'components/inputs/textField';
import CustomTextField from './CustomTextField';
import {
  setPersonalityFields,
  createCustomRule,
  deleteCustomRule,
  setCustomRules,
  updateAllPersonalityFields
} from 'slices/ai-assistant/personality/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Select from 'components/inputs/select';
import CHOICES from 'choices';
import { updateCustomRule } from 'slices/ai-assistant/personality/slice';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import {
  setIsError,
  setListAssistants,
  setPersonalityErrors
} from 'slices/ai-assistant/assistant/slice';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSnackbar } from 'components/Snackbar';
import {
  defaultpersonalityErrors,
  showFilteredFields
} from 'utils/aiAssistant';
import InfoIconComponent from '../components/InfoIcon';
import CustomTextArea from './CustomTextArea';
import _ from 'lodash';

export const trimFunction = (str) => {
  let trimString = str.trim();
  return trimString;
};

const { AIToneChoices, ResponseLengthChoices } = CHOICES;
const PersonalityTab = () => {
  const { openSuccessSnackBar } = useSnackbar();
  const { assistant_id } = useParams();
  const personalityFields = useSelector(
    (state) => state.personality.personalityFields
  );
  const personalityErrors = useSelector(
    (state) => state?.assistant?.personalityErrors
  );

  const { isError } = useSelector((state) => state?.assistant);
  const [isFocus, setIsFocus] = useState(false);
  const customRules = useSelector((state) => state.personality.customRules);
  const [customRule, setCustomRule] = useState([]);
  const customRuleRef = useRef(null);

  useEffect(() => {
    setCustomRule(customRules);
  }, [customRules]);

  const { currentVersionId } = useSelector((state) => state.assistant);
  const {
    name,
    persona,
    unknown_question_response,
    desired_outcome,
    tone,
    response_length
  } = personalityFields;
  const [localName, setLocalName] = useState(isEmpty(name) ? '' : name);
  const [localPersona, setLocalPersona] = useState(
    isEmpty(persona) ? '' : persona
  );

  const [localUnknownQuestionResponse, setLocalUnknownQuestionResponse] =
    useState(
      isEmpty(unknown_question_response) ? '' : unknown_question_response
    );
  const [localDesiredOutcome, setLocalDesiredOutcome] = useState(
    isEmpty(desired_outcome) ? '' : desired_outcome
  );
  const [localResponseLength, setLocalResponseLength] = useState(
    isEmpty(response_length) ? '' : response_length
  );
  const ToneEmojis = ['👱‍♀️', '‍👩‍💼️', '😜️', '💁‍♂️'];
  const addCustomOptions = AIToneChoices.CHOICE_LIST.map((item, index) => {
    return {
      ...item,
      label: ToneEmojis[index] + ' ' + item.label
    };
  });
  const [localTone, setLocalTone] = useState(addCustomOptions[tone - 1]);

  const dispatch = useDispatch();
  const HandleInputChange = (item, e, index) => {
    const { value } = e.target;
    let currentCustom_rules = [...customRules];
    if (item.status === 'New') {
      currentCustom_rules.splice(index, 1, {
        id: item.id,
        rule: value,
        status: 'New',
        version_id: currentVersionId
      });
    } else {
      currentCustom_rules.splice(index, 1, { id: item.id, rule: value });
    }
    dispatch(setCustomRules(currentCustom_rules));
  };

  const [isLoading, setIsLoading] = useState(false);
  const { listAssistants } = useSelector((state) => state.assistant);
  const personaRef = useRef(null);
  const personaDivRef = useRef(null);
  const desiredRef = useRef(null);
  const desiredDivRef = useRef(null);
  const questionRef = useRef(null);
  const questionDivRef = useRef(null);
  const [personaFocused, setPersonaFocused] = useState(false);
  const [desiredFocused, setDesiredFocused] = useState(false);
  const [questionFocused, setQuestionFocused] = useState(false);

  useEffect(() => {
    if (!isEmpty(personalityErrors)) {
      dispatch(
        setIsError(
          showFilteredFields(personalityErrors, defaultpersonalityErrors)
        )
      );
    }
  }, [personalityErrors]);

  const handleAdd = (item) => {
    if (customRule.length < customRules.length + 1)
      setCustomRule([...customRule, item]);
    else setIsFocus(true);
  };

  const handleUpdate = (item, index) => {
    let jsonPost = {
      id: item.id,
      index: index,
      ai_bot_id: assistant_id,
      rule: `${item.rule}`,
      version_id: currentVersionId
    };
    if (item.status === 'New') dispatch(createCustomRule(jsonPost));
    else
      dispatch(
        updateCustomRule({
          custom_rule_id: item.id,
          rule: item.rule,
          version_id: currentVersionId
        })
      );
  };
  const handleRemove = (item, ItemIndex) => {
    let jsonPost = {
      custom_rule_id: item.id,
      version_id: currentVersionId
    };
    if (item.status === 'New')
      dispatch(
        setCustomRules(
          customRules.filter((rule, index) => {
            return index !== ItemIndex;
          })
        )
      );
    else dispatch(deleteCustomRule(jsonPost));
  };

  const handleUpdateResponse = (assistant_id, data, objType) => {
    let assistants = [...listAssistants].map((item) => {
      if (item.version_bot.id === assistant_id)
        return {
          ...item,
          version_bot: {
            ...item.version_bot,
            ['name']: data?.name
          },
          ['version_status']: data?.version_status
        };
      return item;
    });
    openSuccessSnackBar('Updated successfully', 'medium');
    dispatch(setListAssistants(assistants));
    setIsLoading(false);
    let currentErrors = { ...personalityErrors };
    currentErrors[objType['type']] = true;
    dispatch(setPersonalityErrors(currentErrors));
    dispatch(
      setPersonalityFields({
        ...personalityFields,
        ...data
      })
    );
    setLocalName(data.name);
    setLocalPersona(data.persona);
    setLocalDesiredOutcome(data.desired_outcome);
    document.activeElement.blur();
  };

  useEffect(() => {
    if (!isEmpty(name)) setLocalName(name);
    if (!isEmpty(persona)) setLocalPersona(persona);
    if (!isEmpty(desired_outcome)) setLocalDesiredOutcome(desired_outcome);
    if (!isEmpty(unknown_question_response))
      setLocalUnknownQuestionResponse(unknown_question_response);
    if (!isEmpty(response_length)) setLocalResponseLength(response_length);
    if (!isEmpty(tone)) setLocalTone(addCustomOptions[tone - 1]);
  }, [name, persona, desired_outcome, unknown_question_response]);

  useEffect(() => {
    let updatedError = { ...isError };
    if (!isEmpty(localName)) {
      updatedError = { ...updatedError, name: '' };
    }
    if (!isEmpty(localPersona)) {
      updatedError = { ...updatedError, persona: '' };
    }
    if (!isEmpty(localUnknownQuestionResponse)) {
      updatedError = { ...updatedError, unknown_question_response: '' };
    }
    if (!isEmpty(localDesiredOutcome)) {
      updatedError = { ...updatedError, desired_outcome: '' };
    }
    if (!isEmpty(localTone)) {
      updatedError = { ...updatedError, tone: null };
    }
    if (!isEmpty(localResponseLength)) {
      updatedError = { ...updatedError, response_length: null };
    }
    dispatch(setIsError(updatedError));
  }, [
    localName,
    localPersona,
    localUnknownQuestionResponse,
    localDesiredOutcome,
    localTone,
    localResponseLength
  ]);

  const failedResponse = () => {
    setIsLoading(false);
    setLocalName(name);
    setLocalPersona(persona);
    setLocalDesiredOutcome(desired_outcome);
    setLocalUnknownQuestionResponse(unknown_question_response);

    setLocalTone(addCustomOptions[tone - 1]);
    setLocalResponseLength(response_length);
    document.activeElement.blur();
  };
  const callApiOnKeyPress = (payload, type) => {
    setIsLoading(true);
    dispatch(
      updateAllPersonalityFields(
        assistant_id,
        payload,
        handleUpdateResponse,
        failedResponse,
        type
      )
    );
  };
  const onKeyPress = (e, payload, type) => {
    payload.version_id = currentVersionId;
    callApiOnKeyPress(payload, type);
  };
  const onNameSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(localName)) {
      dispatch(
        setIsError({
          ...isError,
          name: 'Name cannot be empty'
        })
      );
    } else if (localName.length > 50) {
      dispatch(
        setIsError({
          ...isError,
          name: 'Maximum character length is 50'
        })
      );
    } else if (localName === name) return;
    else onKeyPress(e, { name: localName }, { type: 'name' });
  };

  const onPersonaSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(localPersona)) {
      dispatch(
        setIsError({
          ...isError,
          persona: 'Persona cannot be empty'
        })
      );
    } else if (localPersona === persona) return;
    else
      onKeyPress(
        e,
        { persona: trimFunction(localPersona) },
        { type: 'persona' }
      );
  };

  const onUnknownQuestionResponseSubmit = (e, onBlur) => {
    if (isEmpty(localUnknownQuestionResponse)) {
      dispatch(
        setIsError({
          ...isError,
          unknown_question_response: 'Unknown response cannot be empty'
        })
      );
    } else if (e.charCode == 13)
      onKeyPress(
        e,
        {
          unknown_question_response: localUnknownQuestionResponse
        },
        { type: 'unknown_question_response' }
      );
    else if (onBlur && !isEmpty(localUnknownQuestionResponse))
      onKeyPress(
        e,
        {
          unknown_question_response: localUnknownQuestionResponse
        },
        { type: 'unknown_question_response' }
      );
  };

  const onDesiredOutcomeSubmit = (e, onBlur) => {
    if (isEmpty(localDesiredOutcome)) {
      dispatch(
        setIsError({
          ...isError,
          desired_outcome: 'Desired outcome cannot be empty'
        })
      );
    } else if (localDesiredOutcome === desired_outcome) return;
    else if (e.charCode === 13) {
      onKeyPress(
        e,
        { desired_outcome: trimFunction(localDesiredOutcome) },
        { type: 'desired_outcome' }
      );
      return;
    } else if (onBlur)
      onKeyPress(
        e,
        { desired_outcome: trimFunction(localDesiredOutcome) },
        { type: 'desired_outcome' }
      );
  };

  const setHeightOfTextArea = (divRef, inputRef) => {
    if (divRef?.current) {
      const offsetHeight = divRef?.current?.offsetHeight;
      if (offsetHeight > 190) {
        inputRef.current.style.height = '190px';
      } else {
        inputRef.current.style.height = `${offsetHeight}px`;
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(isError)) {
      const errorKeys = Object.keys(isError).filter((key) => {
        return isError[key] !== null && isError[key] !== '';
      });
      const errorKeysToUpdate = {};
      errorKeys.forEach((key) => {
        errorKeysToUpdate[key] = false;
      });
      const isEqual = _.isEqual(errorKeysToUpdate, personalityErrors);
      if (!isEqual) {
        dispatch(setPersonalityErrors(errorKeysToUpdate));
      }
    }
  }, [isError]);

  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <section className="" style={{ padding: '25px 38px' }}>
        <div className="flex items-start">
          <div className="flex-1">
            <div className="flex -mx-3">
              <div className="col-4 px-3">
                <label
                  className="input-field-label"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Name <InfoIconComponent tooltipText="Name" />
                </label>
                <form onSubmit={(e) => onNameSubmit(e)}>
                  <TextInputField
                    name="name"
                    className="input-field--h50 w-full"
                    onBlur={(e) => {
                      if (name !== localName) onNameSubmit(e);
                    }}
                    value={isEmpty(localName) ? '' : localName}
                    onChange={(e) => {
                      setLocalName(e.target.value);
                    }}
                    error={!isEmpty(isError) && isError['name'] ? true : false}
                    autoComplete={'off'}
                  />
                  {!isEmpty(isError) && isError['name'] && (
                    <ErrorMessage error={isError['name']} />
                  )}
                </form>
              </div>
              <div className="col-4 px-3">
                <label
                  className="input-field-label"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Personality and Tone
                  <InfoIconComponent tooltipText="Personality and Tone" />
                </label>
                <Select
                  name="tone"
                  className="input-field input-field--h50 w-full cursor-pointer"
                  options={addCustomOptions}
                  value={localTone?.label}
                  selectedValue={localTone}
                  dropDownClassName="px-2 py-2"
                  onChange={(data) => {
                    setLocalTone(data);
                    if (data.value === tone) return;
                    else
                      dispatch(
                        updateAllPersonalityFields(
                          assistant_id,
                          {
                            tone: data.value,
                            version_id: currentVersionId
                          },
                          handleUpdateResponse,
                          failedResponse
                        )
                      );
                  }}
                  readOnly={true}
                  resetValue
                  error={!isEmpty(isError) && isError['tone']}
                />
                {!isEmpty(isError) && isError['tone'] && (
                  <ErrorMessage error={isError['tone']} />
                )}{' '}
              </div>
              <div className="col-4 px-3">
                <label
                  className="input-field-label"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  Length of response{' '}
                  <InfoIconComponent tooltipText="Length of response" />
                </label>
                <Select
                  name="response length"
                  className="input-field input-field--h50 w-full cursor-pointer"
                  dropDownClassName="px-2 py-2"
                  options={ResponseLengthChoices.CHOICE_LIST}
                  value={ResponseLengthChoices.CHOICES[localResponseLength]}
                  selectedValue={{
                    label: ResponseLengthChoices.CHOICES[localResponseLength]
                  }}
                  onChange={(data) => {
                    setLocalResponseLength(data.value);
                    if (data.value === response_length) return;
                    else
                      dispatch(
                        updateAllPersonalityFields(
                          assistant_id,
                          {
                            response_length: data.value,
                            version_id: currentVersionId
                          },
                          handleUpdateResponse,
                          failedResponse
                        )
                      );
                  }}
                  readOnly={true}
                  resetValue
                  error={!isEmpty(isError) && isError['response_length']}
                />
                {!isEmpty(isError) && isError['response_length'] && (
                  <ErrorMessage error={isError['response_length']} />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="mt-7 flex flex-col">
          <label
            className="input-field-label"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Persona
            <InfoIconComponent tooltipText="Persona" />
          </label>

          <CustomTextArea
            ref={personaRef}
            name="persona"
            className={`resize-none ${personaFocused ? '' : 'hidden'}`}
            onKeyPress={(e) => {
              if (e.charCode === 13) {
                setPersonaFocused(false);
                if (persona !== localPersona) onPersonaSubmit(e);
              }
            }}
            onBlur={(e) => {
              setPersonaFocused(false);
              if (persona !== localPersona) onPersonaSubmit(e);
            }}
            type="text"
            value={isEmpty(localPersona) ? '' : localPersona}
            onChange={(e) => {
              setLocalPersona(e.target.value);
            }}
            error={!isEmpty(isError) && isError['persona'] ? true : false}
            autoComplete={'off'}
            style={{ minHeight: '54px', width: '100%' }}
          />
          <div
            ref={personaDivRef}
            onClick={() => {
              setHeightOfTextArea(personaDivRef, personaRef);
              setPersonaFocused(true);
              setTimeout(() => {
                personaRef.current.focus();
              }, 10);
            }}
            className={`input-field ${personaFocused ? 'hidden' : ''} ${
              !isEmpty(isError) && isError['persona'] ? 'error' : ''
            } `}
            style={{
              letterSpacing: 'normal',
              minHeight: '54px',
              width: '100%',
              wordWrap: 'break-word'
            }}
          >
            {isEmpty(localPersona) ? '' : localPersona}
          </div>

          {!isEmpty(isError) && isError['persona'] && (
            <ErrorMessage error={isError['persona']} />
          )}
        </div>
        <div className="mt-8 flex flex-col">
          <label
            className="input-field-label"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Desired outcome <InfoIconComponent tooltipText="Desired outcome" />
          </label>
          <CustomTextArea
            ref={desiredRef}
            name="desired_outcome"
            rows={1}
            value={isEmpty(localDesiredOutcome) ? '' : localDesiredOutcome}
            className={`resize-none ${desiredFocused ? '' : 'hidden'}  ${
              !isEmpty(isError) && isError['desired_outcome'] ? 'error' : ''
            } `}
            onKeyPress={(e) => {
              onDesiredOutcomeSubmit(e, false);
            }}
            error={!isEmpty(isError) && isError['desired_outcome']}
            onChange={(e) => {
              setLocalDesiredOutcome(e.target.value);
            }}
            onBlur={(e) => {
              setDesiredFocused(false);
              if (desired_outcome !== localDesiredOutcome)
                onDesiredOutcomeSubmit(e, true);
            }}
            style={{ minHeight: '54px', width: '100%' }}
          />
          <div
            ref={desiredDivRef}
            onClick={() => {
              setHeightOfTextArea(desiredDivRef, desiredRef);
              setDesiredFocused(true);
              setTimeout(() => {
                desiredRef.current.focus();
              }, 10);
            }}
            className={`input-field resize-none
            ${desiredFocused ? 'hidden' : ''} ${
              !isEmpty(isError) && isError['desired_outcome'] ? 'error' : ''
            }`}
            style={{ minHeight: '54px', letterSpacing: 'normal' }}
          >
            {isEmpty(localDesiredOutcome) ? '' : localDesiredOutcome}
          </div>
          {!isEmpty(isError) && isError['desired_outcome'] && (
            <ErrorMessage error={isError['desired_outcome']} />
          )}
        </div>
        {customRule?.map((item, index) => {
          return (
            <li key={index}>
              <CustomTextField
                HandleInputChange={HandleInputChange}
                index={index}
                handleUpdate={handleUpdate}
                item={item}
                handleRemove={handleRemove}
                isFocus={isFocus}
                setIsFocus={setIsFocus}
              />
            </li>
          );
        })}
        <div
          ref={customRuleRef}
          className="text-primary text-lg font-medium cursor-pointer inline-block mt-5"
          onClick={() =>
            handleAdd({
              id: customRule.length + 1,
              rule: '',
              status: 'New',
              version_id: currentVersionId
            })
          }
        >
          + Add a custom rule
        </div>
        <div className="mt-10 flex flex-col">
          <label
            className="input-field-label"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            How to handle unknown questions{' '}
            <InfoIconComponent tooltipText="How to handle unknown questions" />
          </label>
          <CustomTextArea
            ref={questionRef}
            name="unknown_question_response"
            value={
              isEmpty(localUnknownQuestionResponse)
                ? ''
                : localUnknownQuestionResponse
            }
            className={`resize-none ${questionFocused ? '' : 'hidden'} ${
              !isEmpty(isError) && isError['unknown_question_response']
                ? 'error'
                : ''
            }`}
            onKeyPress={(e) => {
              if (unknown_question_response !== localUnknownQuestionResponse)
                onUnknownQuestionResponseSubmit(e, false);
            }}
            onChange={(e) => {
              setLocalUnknownQuestionResponse(e.target.value);
            }}
            onBlur={(e) => {
              setQuestionFocused(false);
              if (unknown_question_response !== localUnknownQuestionResponse)
                onUnknownQuestionResponseSubmit(e, true);
            }}
            error={!isEmpty(isError) && isError['unknown_question_response']}
            style={{ minHeight: '54px', width: '100%' }}
          />
          <div
            ref={questionDivRef}
            onClick={() => {
              setHeightOfTextArea(questionDivRef, questionRef);
              setQuestionFocused(true);
              setTimeout(() => {
                questionRef.current.focus();
              }, 10);
            }}
            className={`input-field ${questionFocused ? 'hidden' : ''} ${
              !isEmpty(isError) && isError['unknown_question_response']
                ? 'error'
                : ''
            }`}
            style={{ minHeight: '54px', letterSpacing: 'normal' }}
          >
            {isEmpty(localUnknownQuestionResponse)
              ? ''
              : localUnknownQuestionResponse}
          </div>

          {!isEmpty(isError) && isError['unknown_question_response'] && (
            <ErrorMessage error={isError['unknown_question_response']} />
          )}
        </div>
        {Object.keys(personalityFields).length === 0 && (
          <OverLayLoader style={{ top: 0, left: '0%' }} />
        )}
      </section>
    </>
  );
};

export default PersonalityTab;

import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import clock from 'assets/icons/icon-clock2.svg';
import { convertToHoursAndMinutes } from 'utils/utils';

const AverageResponseTime = (props) => {
  const { isShowEmptyState, averageTime, averageTimeChange, selectedDay } =
    props;

  return (
    <div className="card-box px-5 py-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center pr-5">
          <figure
            className="avatar-container avatar-container--contain bg-grey-300"
            style={{
              width: 38,
              height: 38,
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
          >
            <img src={clock} />
          </figure>
          <div className="ml-3">
            <CustomToolTip
              style={{ width: '100%' }}
              tooltipStyle={{
                width: 250,
                top: -95,
                bottom: 'initial',
                left: -70,
                transform: 'none',
                textAlign: 'left'
              }}
              position="top"
              tooltipText="The average amount of time if takes the team to get back to an incoming enquiry."
            >
              <h4 className="font-semibold">Average response time</h4>
            </CustomToolTip>
            {isShowEmptyState ? (
              <div
                className="data-overlay  mt-2"
                style={{ width: 130, height: 12 }}
              ></div>
            ) : (
              <div className="flex items-center">
                <span
                  className={`${
                    averageTimeChange >= 0 ? 'text-green-900' : 'text-red-900'
                  } text-13 font-semibold`}
                >
                  {convertToHoursAndMinutes(averageTimeChange)}
                  {averageTimeChange >= 0 ? ' faster' : ' slower'}
                </span>
                <span className="text-xs text-grey-800 ml-1">
                  than{' '}
                  {selectedDay === 'Last 7 days'
                    ? 'last week'
                    : selectedDay === 'This month to date'
                    ? 'last month'
                    : 'last date'}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="text-3xl font-bold letterspacing24em">
          {isShowEmptyState ? 0 : convertToHoursAndMinutes(averageTime)}
          {/* <span className="text-grey-800 text-xs font-normal ml-1">min</span> */}
        </div>
      </div>
    </div>
  );
};

export default AverageResponseTime;

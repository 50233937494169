/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import AssignUser from '../components/assignUser';
import Channels from '../components/channels';
import { useDispatch, useSelector } from 'react-redux';
import Labels from '../components/labels';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close-primary-10x10.svg';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty } from 'utils/utils';
import {
  setChannelsSelected,
  setIsAppliedFilter,
  setIsFiltered,
  setLabelsSelected,
  setUsersSelected
} from 'slices/boxes/slice';

const Filter = ({ isDetailEnabled }) => {
  const dispatch = useDispatch();
  const { isAppliedFilter } = useSelector((state) => state.boxes);
  const [openChannels, setOpenChannels] = useState(false);
  const [openLabels, setOpenLabels] = useState(false);
  const [openAssign, setOpenAssign] = useState(false);
  const [selectedUser, setSelectedUser] = useState([]);
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);

  const onApply = () => {
    dispatch(setIsFiltered(true));
    dispatch(setIsAppliedFilter(true));
  };

  const onClear = () => {
    setSelectedUser([]);
    setSelectedChannels([]);
    setSelectedLabels([]);
    dispatch(setUsersSelected([]));
    dispatch(setChannelsSelected([]));
    dispatch(setLabelsSelected([]));
  };

  return (
    <div
      className={`dashboard__main__body__filter-section flex justify-between py-3 px-6 ${
        isDetailEnabled ? 'hidden' : ''
      }`}
    >
      <div className="flex gap-2">
        <span className="text-13 font-medium text-grey-700 mt-1">
          Filter by
        </span>
        <div className="relative">
          <AssignUser
            openAssign={openAssign}
            setOpenAssign={setOpenAssign}
            setSelectedUser={setSelectedUser}
            selectedUser={selectedUser}
          />
        </div>
        <div className="relative">
          <Channels
            openChannels={openChannels}
            setOpenChannels={setOpenChannels}
            selectedChannels={selectedChannels}
            setSelectedChannels={setSelectedChannels}
          />
        </div>
        <div className="relative">
          <Labels
            openLabels={openLabels}
            setOpenLabels={setOpenLabels}
            selectedLabels={selectedLabels}
            setSelectedLabels={setSelectedLabels}
          />
        </div>
      </div>
      {(!isEmpty(selectedUser) ||
        !isEmpty(selectedChannels) ||
        !isEmpty(selectedLabels)) && (
        <div className="flex">
          {isAppliedFilter && (
            <div
              className="flex mr-4 items-center cursor-pointer"
              onClick={onClear}
            >
              <CloseIcon />
              <span className="text-13 font-medium text-primary ml-2">
                Clear filter
              </span>
            </div>
          )}
          <PrimaryButton
            label="Apply"
            className="btn--xs text-13"
            onClick={onApply}
          />
        </div>
      )}
    </div>
  );
};

export default Filter;

import React, { useState } from 'react';
import Toggle from 'components/inputs/toggle';
import { useSelector, useDispatch } from 'react-redux';
// import iconHour from 'assets/icons/icon-hours.svg';
// import iconSales from 'assets/icons/icon-suitcase.svg';
// import iconGeneral from 'assets/icons/icon-table-tennis.svg';
// import iconReminder from 'assets/icons/icon-settings-reminder.svg';
// import iconMentions from 'assets/icons/icon-settings-mentions.svg';
// import iconAssigned from 'assets/icons/icon-settings-assigned.svg';
// import iconNewEnq from 'assets/icons/icon-settings-new-enq.svg';
// import iconReply from 'assets/icons/icon-settings-reply.svg';
import { togglePushNotifications } from 'slices/userNotificationSlice';
import { useSnackbar } from 'components/Snackbar';
import OverLayLoader from 'components/loader/overLayLoader';

const Notifications = () => {
  const { notify_me_about_everything } = useSelector(
    (state) => state.myInfo.myInformation
  );

  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  //   const [activeNotifyList, setActiveNotifyList] = useState(1);
  //   const [activeWhenList, setActiveWhenList] = useState(1);
  //   const [notifyByEmail, setNotifyByEmail] = useState(true);
  //   const [notifyByBrowser, setNotifyByBrowser] = useState(true);
  //   const [workingHours, setWorkingHours] = useState(false);

  const toggleNotification = () => {
    setIsLoading(true);
    dispatch(
      togglePushNotifications(
        {
          activate: !notify_me_about_everything
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = () => {
    openSuccessSnackBar('Success');
    setIsLoading(false);
  };

  const onError = () => {
    openErrorSnackBar('Error');
    setIsLoading(false);
  };

  return (
    <>
      {/* <div className="dashboard__main__body__section-right overflow-overlay  p-10"> */}
      <div>
        <h2 className="text-2xl font-semibold text-center">Notifications</h2>
        <p className="text-grey-800 text-15 text-center mt-3">
          Change what, when and how you get notified
        </p>
      </div>
      <div className="card-box py-5 px-6 mt-6 relative">
        {isLoading && <OverLayLoader className="justify-center items-center" />}
        <h3 className="font-semibold text-lg">
          What do you want to be notified about?{' '}
        </h3>
        <div className="mt-4 ">
          <div className="border border-grey-400 rounded-xl py-4 px-4 mt-5 ">
            <div className="flex justify-between">
              <div>
                <h3 className="font-semibold">Notify me about everything</h3>
                <p className="text-grey-800 text-sm mt-2">
                  New and assigned enquiries, replies, reminders and mentions{' '}
                </p>
              </div>
              <div className="toggle-slider">
                <Toggle
                  id={'notify'}
                  name="notify"
                  //   register={() => {}}
                  checked={notify_me_about_everything}
                  onChange={() => toggleNotification()}
                />
              </div>
            </div>
          </div>
          {/* <ul>
            <li
              className="email-provider-list"
              style={{ alignItems: 'flex-start' }}
              onClick={() => setActiveNotifyList(1)}
            >
              <div className="flex mt-1">
                <input
                  checked={activeNotifyList === 1}
                  type="radio"
                  className="custom-radio-button"
                  name="radio-btn"
                  onChange={() => {}}
                />
              </div>
              <div className="ml-3">
                <h4 className="font-semibold mb-2">
                  Notify me about everything
                </h4>
                <p className="text-sm text-grey-800">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </li>
            <li
              className="email-provider-list"
              style={{
                alignItems: 'flex-start',
                padding: '16px 20px 16px 16px'
              }}
              onClick={() => setActiveNotifyList(2)}
            >
              <div className="flex mt-1">
                <input
                  checked={activeNotifyList === 2}
                  type="radio"
                  className="custom-radio-button custom-radio-button--sm"
                  name="radio-btn"
                  onChange={() => {}}
                />
              </div>
              <div className="ml-3 w-full">
                <h4 className="font-semibold mb-2">
                  Only notify me about specific things
                </h4>
                <p className="text-sm text-grey-800">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </p>
                {activeNotifyList === 2 && (
                  <>
                    <div className="mt-5 pb-3 border-b border-grey-400">
                      <h3 className="font-semibold mb-1">Channels</h3>
                      <div className="flex justify-between py-3 pl-1 items-center">
                        <div className="flex">
                          <figure className="avatar-container">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                                fill="#4C5254"
                              />
                            </svg>
                          </figure>
                          <p className="ml-3 text-15 font-medium">Stead</p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1 items-center">
                        <div className="flex">
                          <figure className="avatar-container">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M16 8.05031C16 3.57233 12.4 0 8 0C3.6 0 0 3.57233 0 8.05031C0 12.0755 2.95 15.3962 6.75 16V10.3648H4.7V8.05031H6.75V6.28931C6.75 4.27673 7.95 3.16981 9.75 3.16981C10.65 3.16981 11.55 3.32075 11.55 3.32075V5.28302H10.55C9.55 5.28302 9.25 5.88679 9.25 6.54088V8.05031H11.45L11.1 10.3648H9.25V16C13.05 15.3962 16 12.0755 16 8.05031Z"
                                fill="#4C5254"
                              />
                            </svg>
                          </figure>
                          <p className="ml-3 text-15 font-medium">
                            Facebook Pages
                          </p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1 items-center">
                        <div className="flex">
                          <figure className="avatar-container">
                            <svg
                              width="15"
                              height="15"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M7.99945 4.9505C9.68359 4.9505 11.0492 6.31607 11.0492 8.00015C11.0492 9.68422 9.68359 11.0498 7.99945 11.0498C6.31532 11.0498 4.95043 9.68422 4.95043 8.00015C4.95043 6.31607 6.31532 4.9505 7.99945 4.9505ZM7.99699 12.3805C10.4333 12.3805 12.4082 10.4057 12.4082 7.96939C12.4082 5.53314 10.4333 3.55829 7.99699 3.55829C5.56065 3.55829 3.58581 5.533 3.58581 7.96939C3.58581 10.4057 5.56065 12.3805 7.99699 12.3805ZM12.8827 4.21462C13.489 4.21486 13.9807 3.72356 13.981 3.11727C13.9812 2.51098 13.4899 2.01929 12.8836 2.01904C12.2771 2.01933 11.7859 2.51039 11.7853 3.11639C11.7851 3.72268 12.2764 4.21437 12.8827 4.21462ZM2.60453 15.1319C2.1774 14.9657 1.87265 14.7676 1.55223 14.4477C1.23181 14.1277 1.03347 13.8232 0.867917 13.3961C0.742326 13.0741 0.593754 12.5892 0.553208 11.6973C0.508856 10.733 0.5 10.4433 0.5 8.00029C0.5 5.55728 0.509588 5.26842 0.553208 4.30332C0.593827 3.4114 0.743497 2.92742 0.867917 2.60446C1.0342 2.17734 1.23225 1.8726 1.55223 1.55219C1.87221 1.23178 2.17667 1.03345 2.60453 0.867905C2.92656 0.742318 3.4115 0.593751 4.30345 0.553206C5.26777 0.508855 5.55745 0.5 7.99945 0.5C10.4414 0.5 10.7314 0.509441 11.6966 0.553352C12.5885 0.59397 13.0725 0.743635 13.3955 0.868051C13.8226 1.0336 14.1274 1.23237 14.4478 1.55234C14.7682 1.87231 14.9658 2.17749 15.1321 2.6046C15.2577 2.92662 15.4062 3.41155 15.4468 4.30346C15.4911 5.26856 15.5 5.55743 15.5 8.00044C15.5 10.4435 15.4911 10.7323 15.4468 11.6974C15.4062 12.5893 15.2569 13.0741 15.1321 13.3963C14.9658 13.8234 14.7678 14.1281 14.4478 14.4478C14.1278 14.7675 13.8226 14.9658 13.3955 15.1321C13.0734 15.2577 12.5885 15.4062 11.6966 15.4468C10.7322 15.4911 10.4425 15.5 7.99945 15.5C5.55635 15.5 5.26748 15.4911 4.30345 15.4468C3.4115 15.406 2.9267 15.2575 2.60453 15.1319Z"
                                fill="#4C5254"
                              />
                            </svg>
                          </figure>
                          <p className="ml-3 text-15 font-medium">
                            Hunley Weddings
                          </p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1 items-center">
                        <div className="flex">
                          <figure className="avatar-container">
                            <svg
                              width="16"
                              height="14"
                              viewBox="0 0 16 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M0 3.5V10.5C0 12.433 1.59188 14 3.55556 14H12.4444C14.4081 14 16 12.433 16 10.5V3.5C16 1.567 14.4081 0 12.4444 0H3.55556C1.59188 0 0 1.567 0 3.5ZM3.3009 2.82049C2.93328 2.57031 2.43658 2.67171 2.1915 3.04699C1.94642 3.42228 2.04576 3.92932 2.41338 4.17951L6.9223 7.24808C7.5749 7.69221 8.4251 7.69221 9.0777 7.24808L13.5866 4.17951C13.9543 3.92932 14.0536 3.42228 13.8085 3.04699C13.5634 2.67171 13.0667 2.57031 12.6991 2.82049L8.19018 5.88905C8.07502 5.96743 7.92498 5.96743 7.80982 5.88905L3.3009 2.82049Z"
                                fill="#4C5254"
                              />
                            </svg>
                          </figure>
                          <p className="ml-3 text-15 font-medium">
                            Wedding email
                          </p>
                        </div>
                        <div className="flex">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 pb-3 border-b border-grey-400">
                      <h3 className="font-semibold mb-1">Boxes</h3>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex items-center">
                          <img
                            src={iconSales}
                            alt=""
                            style={{ width: 16, height: 16 }}
                          />
                          <p className="ml-3 text-15 font-medium">Sales</p>
                        </div>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex items-center">
                          <img
                            src={iconGeneral}
                            alt=""
                            style={{ width: 16, height: 16 }}
                          />
                          <p className="ml-3 text-15 font-medium">General</p>
                        </div>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4">
                      <h3 className="font-semibold mb-1">
                        Things you get notified about
                      </h3>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex">
                          <img
                            src={iconReminder}
                            alt=""
                            style={{ width: 16, height: 16 }}
                            className="mt-1"
                          />
                          <div className="ml-3">
                            <h3 className="text-15 font-semibold">Reminders</h3>
                            <p className="text-sm text-grey-800">
                              All reminders that you have setup
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex">
                          <img
                            src={iconMentions}
                            alt=""
                            style={{ width: 16, height: 16 }}
                            className="mt-1"
                          />
                          <div className="ml-3">
                            <h3 className="text-15 font-semibold">Mentions</h3>
                            <p className="text-sm text-grey-800">
                              When a team member tags you in a message or note
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex">
                          <img
                            src={iconAssigned}
                            alt=""
                            style={{ width: 16, height: 16 }}
                            className="mt-1"
                          />
                          <div className="ml-3">
                            <h3 className="font-semibold text-15">
                              Assigned enquiries
                            </h3>
                            <p className="text-sm text-grey-800">
                              When a team member tags you in a message or note
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex">
                          <img
                            src={iconNewEnq}
                            alt=""
                            style={{ width: 16, height: 16 }}
                            className="mt-1"
                          />
                          <div className="ml-3">
                            <h3 className="text-15 font-semibold">
                              New enquiries
                            </h3>
                            <p className="text-sm text-grey-800">
                              When a new enquiry comes in
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                      <div className="flex justify-between py-3 pl-1">
                        <div className="flex">
                          <img
                            src={iconReply}
                            alt=""
                            style={{ width: 16, height: 16 }}
                            className="mt-1"
                          />
                          <div className="ml-3">
                            <h3 className="text-15 font-semibold">New reply</h3>
                            <p className="text-sm text-grey-800">
                              When you receive a reply to an enquiry you’re
                              assigned to
                            </p>
                          </div>
                        </div>
                        <div className="mt-1">
                          <input
                            type="checkbox"
                            className="custom-checkbox custom-checkbox--sm"
                            name=""
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </li>
          </ul> */}
        </div>
      </div>
      {/* <div className="card-box py-5 px-6 mt-6">
        <h3 className="font-semibold text-lg">
          How would you like to be notified?
        </h3>
        <p className="text-grey-800 text-sm mt-1">
          By default, all notifications will be on your enquiry assistant
          channel. You can access this on the top left hand corner when logged
          in on desktop.
        </p>
        <div className="border border-grey-400 rounded-xl py-4 px-4 mt-5">
          <div className="flex justify-between">
            <div>
              <h3 className="font-semibold">Also notify me by email</h3>
              <p className="text-grey-800 text-sm mt-2">
                Receive notifications via email
              </p>
            </div>
            <div className="toggle-slider">
              <Toggle
                //   type="checkbox"
                id={'notifyByEmail'}
                name="notifyByEmail"
                //   register={() => {}}
                checked={notifyByEmail}
                onChange={(e) => setNotifyByEmail(!notifyByEmail)}
              />
            </div>
          </div>
        </div>
        <div className="border border-grey-400 rounded-xl py-4 px-4 mt-4">
          <div className="flex justify-between">
            <div>
              <h3 className="font-semibold">
                Also notify me via browser notifications
              </h3>
              <p className="text-grey-800 text-sm mt-2">
                Browser notification are those notofications that slight in on
                the top right hand corner
              </p>
            </div>
            <div className="toggle-slider">
              <Toggle
                //   type="checkbox"
                id={'notifyByBrowser'}
                name="notifyByBrowser"
                //   register={() => {}}
                checked={notifyByBrowser}
                onChange={(e) => setNotifyByBrowser(!notifyByBrowser)}
              />
            </div>
          </div>
        </div>
      </div> */}
      {/* <div className="card-box py-5 px-6 mt-6">
        <h3 className="font-semibold text-lg">When</h3>
        <div className="mt-4">
          <ul>
            <li
              className="email-provider-list"
              style={{ alignItems: 'flex-start' }}
              onClick={() => setActiveWhenList(1)}
            >
              <div className="flex mt-1">
                <input
                  checked={activeWhenList === 1}
                  type="radio"
                  className="custom-radio-button"
                  name="radio-btn-1"
                  onChange={() => {}}
                />
              </div>
              <div className="ml-3">
                <h4 className="font-semibold mb-2">Always! No matter what</h4>
                <p className="text-sm text-grey-800">
                  365 days a year, 24/7, always on
                </p>
              </div>
            </li>
            <li
              className="email-provider-list"
              style={{
                alignItems: 'flex-start',
                padding: '16px 8px',
                display: 'block'
              }}
            >
              <div
                className="flex"
                onClick={(e) => {
                  e.preventDefault;
                  setWorkingHours(!workingHours);
                }}
              >
                <div className="mt-1">
                  <input
                    checked={workingHours}
                    type="radio"
                    className="custom-radio-button custom-radio-button--sm"
                    name="radio-btn-1"
                    onChange={() => {
                      setWorkingHours(!workingHours);
                    }}
                  />
                </div>
                <div className="ml-3">
                  <h4 className="font-semibold mb-2">
                    I need my downtime! Only during my work hours
                  </h4>
                  <p className="text-sm text-grey-800">
                    Setup your work hours to only receive notifications during
                    the times
                  </p>
                </div>
              </div>
              {workingHours && (
                <div className="mt-5">
                  <div className="flex justify-between items-center">
                    <div className="ml-3 flex flex-auto items-center">
                      <img
                        src={iconHour}
                        alt=""
                        style={{ width: 16, height: 16 }}
                      />
                      <h3 className="flex-auto font-semibold text-lg ml-2">
                        Working hours
                      </h3>
                    </div>
                    <h3 className="flex-1 text-13 font-semibold">From</h3>
                    <h3 className="flex-1 text-13 font-semibold">To</h3>
                  </div>
                  <div className="bg-grey-300 border-radius-14 py-5 px-3 mt-2">
                    <div className="flex justify-between">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Sunday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          type="checkbox"
                          id="check3"
                          className="toggle-slider__checkbox"
                          name="check3"
                        />
                        <label
                          htmlFor="check3"
                          className="toggle-slider__label flex text-15 gap-2 items-center"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Off
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Monday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check4"
                          className="toggle-slider__checkbox"
                          name="check4"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check4"
                          className="toggle-slider__label flex text-15 gap-2 items-center"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Working
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Tuesday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check5"
                          className="toggle-slider__checkbox"
                          name="check5"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check5"
                          className="toggle-slider__label flex items-center text-15 gap-2"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Working
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Wednesday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check6"
                          className="toggle-slider__checkbox"
                          name="check6"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check6"
                          className="toggle-slider__label flex items-center text-15 gap-2"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Working
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Thursday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check7"
                          className="toggle-slider__checkbox"
                          name="check7"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check7"
                          className="toggle-slider__label flex items-center text-15 gap-2"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Working
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Friday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check8"
                          className="toggle-slider__checkbox"
                          name="check8"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check8"
                          className="toggle-slider__label flex items-center text-15 gap-2"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Working
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-4">
                      <div className="flex-1 text-15 font-semibold flex items-center">
                        Saturday
                      </div>
                      <div className="toggle-slider flex-1 flex items-center">
                        <input
                          checked
                          type="checkbox"
                          id="check9"
                          className="toggle-slider__checkbox"
                          name="check9"
                          onChange={() => {}}
                        />
                        <label
                          htmlFor="check9"
                          className="toggle-slider__label flex items-center text-15 gap-2"
                        >
                          <code className="toggle-slider__ball toggle-slider__ball__small"></code>
                          Off
                        </label>
                      </div>
                      <div className="flex-1 mr-2">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 AM"
                        />
                      </div>
                      <div className="flex-1">
                        <input
                          type="number"
                          className="input-field input-field--sm-38"
                          placeholder="0.00 PM"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 ml-3">
                    <div className="flex">
                      <div className="mt-1 mr-4">
                        <input
                          type="checkbox"
                          className="custom-checkbox custom-checkbox--sm"
                          name=""
                        />
                      </div>
                      <div>
                        <h3 className="text-15 font-medium">
                          Catch me up if anything happened after hours
                        </h3>
                        <p className="text-sm text-grey-800 mt-1">
                          Your enquiry assistant will send you a summary of what
                          you have missed
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </li>
          </ul>
        </div>
      </div> */}
      {/* </div> */}
    </>
  );
};

export default Notifications;

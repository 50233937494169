/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setType,
  setIsActive,
  setMessage,
  setSize
} from 'slices/snackbarSlice';
import { ERROR, SUCCESS } from './constant';

export const useSnackbar = () => {
  const { isActive } = useSelector((state) => state.snackbar);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActive === true) {
      setTimeout(() => {
        dispatch(setIsActive(false));
        setTimeout(() => {
          dispatch(setMessage(''));
          dispatch(setType(null));
          dispatch(setSize('small'));
        }, 1000);
      }, 2500);
    }
    return () => {
      setTimeout(() => {
        dispatch(setIsActive(false));
        dispatch(setMessage(''));
        dispatch(setType(null));
        dispatch(setSize('small'));
      }, 2500);
    };
  }, [isActive]);

  const openSuccessSnackBar = (
    msg = 'Something went wrong...',
    size = 'small'
  ) => {
    dispatch(setType(SUCCESS));
    dispatch(setMessage(msg));
    dispatch(setIsActive(true));
    dispatch(setSize(size));
  };

  const openErrorSnackBar = (
    msg = 'Something went wrong...',
    size = 'small'
  ) => {
    dispatch(setType(ERROR));
    dispatch(setMessage(msg));
    dispatch(setIsActive(true));
    dispatch(setSize(size));
  };

  return { isActive, openSuccessSnackBar, openErrorSnackBar };
};

import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { isEmpty } from 'utils/utils';
import {
  // useNavigate,
  useLocation
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import CHOICES from 'choices';
import {
  setPostExpiryActiveModal,
  setShowCancelAccountModal,
  setShowDeleteAccountModal,
  setShowEnquiriesExpiredModal,
  setShowFailedPaymentModal,
  setShowPaymentNotCompleted,
  setShowSubscriptionModal,
  setShowSuccessPaymentModal,
  subscriptionContinuePlan
} from 'slices/subscription/slice';
import PaymentFailed from 'modules/modals/PaymentFailed';
import PaymentSuccess from 'modules/modals/PaymentSuccess';
import PaymentNotCompleted from 'modules/modals/paymentNotCompleted';
import { getMyInfo } from 'slices/myInfoSlice';

const Wrapper = (props) => {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const { search } = useLocation();
  const { showFailedPaymentModal, showSuccessPaymentModal } = useSelector(
    (state) => state.subscription
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { currentPlan, showPaymentNotCompleted } = useSelector(
    (state) => state.subscription
  );
  const [paymentNotCompletedFlag, setPaymentNotCompletedFlag] = useState(false);
  const {
    payment_failed = false,
    payment_completed = true,
    subscription_status = 1
  } = currentPlan || {};
  const queryParams = queryString.parse(search);
  const { SubscriptionStatusChoices } = CHOICES;
  // const { CHOICES: choices } = SubscriptionStatusChoices;
  // console.log(choices, 'choices');
  // console.log(queryParams.payment_status);
  // console.log(SubscriptionStatusChoice)

  const handleClearModals = () => {
    dispatch(setPostExpiryActiveModal(''));
    dispatch(setShowCancelAccountModal(false));
    dispatch(setShowDeleteAccountModal(false));
    dispatch(setShowSubscriptionModal(false));
    dispatch(setShowEnquiriesExpiredModal(false));
  };
  useEffect(() => {
    if (!isEmpty(queryParams)) {
      if (queryParams?.payment_status === 'success') {
        handleClearModals();
        dispatch(setShowFailedPaymentModal(false));
        dispatch(setShowSuccessPaymentModal(true));
      } else if (queryParams?.payment_status === 'failed') {
        handleClearModals();
        dispatch(setShowFailedPaymentModal(true));
        dispatch(setShowSuccessPaymentModal(false));
      }
    }
  }, []);

  useEffect(() => {
    if (subscription_status === SubscriptionStatusChoices.ACTIVE) {
      if (payment_failed === true) {
        dispatch(setShowFailedPaymentModal(true));
        dispatch(setShowSuccessPaymentModal(false));
        dispatch(setShowPaymentNotCompleted(false));
      } else if (payment_failed === false && payment_completed === false) {
        //show payment is not completed
        dispatch(setShowPaymentNotCompleted(true));

        dispatch(setShowFailedPaymentModal(false));
        dispatch(setShowSuccessPaymentModal(false));
      }
    }
  }, [subscription_status, payment_failed, payment_completed]);

  // useEffect(() => {
  //   if (payment_failed) {
  //     setShowFailedPaymentModal(true);
  //   }
  // }, [payment_failed]);
  const handleFreeTrialPlan = () => {
    setPaymentNotCompletedFlag(true);
    let data = {
      free_plan: true
    };
    dispatch(
      subscriptionContinuePlan(
        data,
        subOrganizationId,
        closePaymentNotCompleted
      )
    );
  };
  const closePaymentNotCompleted = () => {
    dispatch(getMyInfo());
    setPaymentNotCompletedFlag(false);
    dispatch(setShowPaymentNotCompleted(false));
  };

  const handleCurrentTrialPlan = () => {
    setPaymentNotCompletedFlag(true);
    let data = {
      free_plan: false // revert or reset to current plan by sending this flag as false
    };
    dispatch(
      subscriptionContinuePlan(data, subOrganizationId, closeCurrentTrialPlan)
    );
  };
  const closeCurrentTrialPlan = () => {
    dispatch(getMyInfo());
    setPaymentNotCompletedFlag(false);
    dispatch(setShowPaymentNotCompleted(false));
  };
  return (
    <>
      {props.children}
      {showFailedPaymentModal && (
        <PaymentFailed
          open={showFailedPaymentModal}
          planMode={queryParams.plan_mode}
          paymentNotCompleted={
            payment_failed === false &&
            payment_completed === false &&
            subscription_status === SubscriptionStatusChoices.ACTIVE
          }
        />
      )}
      {showSuccessPaymentModal && (
        <PaymentSuccess
          open={showSuccessPaymentModal}
          planMode={queryParams.plan_mode}
        />
      )}
      {showPaymentNotCompleted && (
        <PaymentNotCompleted
          open={showPaymentNotCompleted}
          isLoading={paymentNotCompletedFlag}
          handleFreeTrialPlan={handleFreeTrialPlan}
          handleCurrentTrialPlan={handleCurrentTrialPlan}
          currentPlan={currentPlan}
        />
      )}
    </>
  );
};

export default Wrapper;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import EnquiryBinHome from './home';

const EnquiryBin = () => {
  return (
    <Routes>
      <Route path="/*" element={<EnquiryBinHome />} />
    </Routes>
  );
};

export default EnquiryBin;

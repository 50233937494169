import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TeamChat from '.';
import TeamChatSettings from './module/settings/home';

const TeamChatHome = () => {
  return (
    <Routes>
      <Route path="/" element={<TeamChat />} />
      <Route path="/settings/*" element={<TeamChatSettings />} />
    </Routes>
  );
};

export default TeamChatHome;

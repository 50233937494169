import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ChannelHeader from 'modules/channel/main/header';
import Contacts from 'modules/enquirybox/contacts/modules/contact';
import Report from 'modules/enquirybox/reports/modules/report';
import EbBox from 'modules/enquirybox/ebox/route';

const EbLandingPage = () => {
  return (
    <section className="dashboard__main">
      <ChannelHeader />
      <div
        className="dashboard__main__body"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <Routes>
          <Route path="/eb-box/*" element={<EbBox />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/reports" element={<Report />} />
        </Routes>
      </div>
    </section>
  );
};

export default EbLandingPage;

/* eslint-disable no-unused-vars */
import CHOICES from 'choices';
import React, { useEffect, useRef, useState } from 'react';
// import QuillMentions from 'components/quill/mentions';
// import Quill from 'components/quill';
// import Header from './header';
import ReplyEmail from './replyEmail';
// import ReplyFooter from './chatFooter/footer/reply';
import choices from 'choices';

const { WEBFORM, FACEBOOK, FACEBOOK_LEAD_ADS, ZAPIER } = choices.ChannelChoices;

const SendEmailEditor = ({
  onClose,
  onReplySentSuccess,
  variables,
  channelType,
  condition,
  setLength,
  length,
  setSmsCount,
  tempChannelType
}) => {
  return (
    <div
      className={`chat-message-footer chat-message-footer bg-white
        `}
      style={{ margin: 'unset' }}
    >
      <ReplyEmail
        onClose={onClose}
        onReplySentSuccess={onReplySentSuccess}
        variables={variables}
        channelType={channelType}
        setLength={setLength}
        length={length}
        setSmsCount={setSmsCount}
        className={
          channelType === FACEBOOK
            ? 'facebook'
            : (channelType === WEBFORM ||
                channelType === FACEBOOK_LEAD_ADS ||
                channelType === ZAPIER) &&
              'webform'
        }
        tempChannelType={tempChannelType}
      />
    </div>
  );
};

export default SendEmailEditor;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import iconCardProfile from 'assets/icons/icon-card-image.svg';
import iconHelperClose from 'assets/icons/icon-helper-close.svg';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { moveLeadtoAnotherStage } from 'slices/boxes/slice';
import { useSnackbar } from 'components/Snackbar';
import { route } from 'utils/route';
import { LoaderRing } from 'components/loader';
import CHOICES from 'choices';
import { getLeadData } from 'slices/conversationSlice';
import { isEmpty } from 'utils/utils';
import { onMoveToBox } from 'slices/eboxLead';

const { MANUALLY_ADDED, IMPORTED_FROM_FILE } = CHOICES.LeadChannelChoice;

const HelperFooter = ({ onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boxes } = useSelector((state) => state.boxes);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { box_id, box_type, cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const { myInformation } = useSelector((state) => state.myInfo);
  const { currentBox } = useSelector((state) => state.boxes);
  const boxStages = useMemo(() => {
    if (isBox) {
      return currentBox.boxstage_set;
    } else {
      const box = boxes.find((box) => box.id === activeLead?.box);
      return box.boxstage_set;
    }
  }, [box_id, activeLead]);

  const onMoveSuccess = () => {
    setIsLoading(false);
    openSuccessSnackBar('Moved sucessfully');
    onClose();
    navigate(
      `${route.dashboard}${route.boxes}/${box_type}/${box_id}/${activeLead?.box_stage}`
    );
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('error');
  };

  const onMove = (id) => {
    setIsLoading(true);
    const formData = {
      box_stage: id,
      lead: cardId,
      is_current: true
    };
    dispatch(
      moveLeadtoAnotherStage(
        {
          body: formData,
          currentStageId: activeLead?.box_stage,
          targetStageId: id,
          lead: activeLead
        },
        onMoveSuccess,
        onError
      )
    );
  };

  const onSuccessMove = (data) => {
    // trac('enquiry_moved', {
    //   user: myInformation.name
    // });
    dispatch(
      getLeadData({ leadId: cardId }, (res) => {
        setIsLoading(false);
        openSuccessSnackBar('Moved successfully');
        // onSubmit();
      })
    );
    onClose();
  };

  const onMoveStageThingsTodo = (stageName, stageId) => {
    const payload = {
      formData: {
        box_id: activeLead?.box,
        box_stage: stageId,
        lead: cardId,
        is_current: true,
        note: ''
      },
      isBox: false,
      box_name: activeLead?.box_name,
      box_stage_name: stageName,
      is_manual_card:
        activeLead.channel == MANUALLY_ADDED ||
        activeLead.channel == IMPORTED_FROM_FILE
    };
    setIsLoading(true);
    dispatch(onMoveToBox(payload, onSuccessMove, onError));
  };

  const dontMove = () => {
    dispatch(getLeadData({ leadId: cardId }));
    onClose();
  };

  const onCloseClick = () => {
    dispatch(getLeadData({ leadId: cardId }));
    onClose();
  };

  return (
    <div className="chat-message-footer relative">
      {isLoading && (
        <div className="footer-loader">
          <LoaderRing small />
        </div>
      )}
      <div className="px-4 pt-4">
        <div className="flex justify-between">
          <div className="flex items-center">
            <figure
              className="avatar-container mr-4"
              style={{ width: 32, height: 32, border: '2px solid #0491AB' }}
            >
              <img src={iconCardProfile} alt="" />
            </figure>
            <div className="font-medium">
              What stage would you like to move the Enquiry to now?
            </div>
          </div>
          <div className="cursor-pointer" onClick={onCloseClick}>
            <img src={iconHelperClose} alt="" style={{ width: 9, height: 9 }} />
          </div>
        </div>
        <div className="flex flex-wrap mt-3" style={{ marginLeft: 42 }}>
          {boxStages
            .filter((stage) => stage.id !== activeLead?.box_stage)
            .map((stage) => {
              return (
                <button
                  key={stage.id}
                  className="btn btn--stage mb-2"
                  onClick={() =>
                    isBox
                      ? onMove(stage.id)
                      : onMoveStageThingsTodo(stage.stage_name, stage.id)
                  }
                >
                  {stage.stage_name}
                </button>
              );
            })}
          <button className="btn btn--stage-red mb-2" onClick={dontMove}>
            Don’t move
          </button>
        </div>
      </div>
      <div
        className="chat-message-footer__footer flex justify-end cursor-pointer"
        onClick={() =>
          navigate(
            `${route.dashboard}${route.teamChat}/${myInformation?.id}${route.settings}/nudges`
          )
        }
      >
        Turn off this nudge
      </div>
    </div>
  );
};

export default HelperFooter;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import moveIcon from 'assets/icons/moveIcon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  createStage,
  getAllBoxes,
  getBoxData,
  getBoxStageLeadCount,
  setBoxes,
  setCurrentBox,
  updatePositionOfStage
} from 'slices/boxes/slice';
import { ReactComponent as AddIcon } from 'assets/icons/icon-plus-green.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import InputWithButton from 'components/inputs/inputWithButton';
import ErrorMessage from 'components/errorMessage';
import { useParams } from 'react-router-dom';
import { maximumCharactersMsg } from 'utils/messages';
import Stages from './stages';
import CHOICES from 'choices';
import { useSnackbar } from 'components/Snackbar';
import ScreenLoader from 'components/loader/screenLoader';
import CustomToolTip from 'components/CustomTooltip';

const { CUSTOM, INCOMING } = CHOICES.StageKind;

const BoxStages = () => {
  const [isDragStarted, setIsDragStarted] = useState(false);

  const [addStage, setAddStage] = useState();
  const { currentBox, activeBoxData, boxStagesLeadCount } = useSelector(
    (state) => state.boxes
  );
  const [isLoading, setIsLoading] = useState();
  const [showMoveIcon, setShowMoveIcon] = useState();
  const { box_id } = useParams();
  const { boxes } = useSelector((state) => state.boxes);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const dispatch = useDispatch();

  const onDragSuccess = (tempOptionsData) => {
    const updatedBoxstage = [];
    boxes.forEach((item) => {
      if (item?.id !== currentBox?.id) {
        updatedBoxstage.push(item);
      } else {
        updatedBoxstage.push({
          ...currentBox,
          boxstage_set: [...tempOptionsData]
        });
      }
    });
    dispatch(setBoxes([...updatedBoxstage]));
    setIsLoading(false);
  };

  const onSuccessGetBox = () => {
    const updatedBoxstage = [];
    boxes.forEach((item) => {
      if (item?.id !== activeBoxData?.id) {
        updatedBoxstage.push(item);
      } else {
        updatedBoxstage.push(activeBoxData);
      }
    });
    dispatch(setBoxes([...updatedBoxstage]));
  };

  const onDragError = () => {
    openErrorSnackBar('error');
    dispatch(getBoxData({ boxId: box_id }, onSuccessGetBox));
    setIsLoading(false);
  };

  const onDragEnd = (result) => {
    setIsDragStarted(false);
    const { destination, source, draggableId } = result;
    if (!destination) return;
    if (destination?.index === 0) return;
    if (source.index !== destination.index) {
      let tempOptionsData = [...currentBox.boxstage_set];
      const [removed] = tempOptionsData.splice(source.index, 1);
      tempOptionsData.splice(destination.index, 0, removed);
      dispatch(
        setCurrentBox({ ...currentBox, boxstage_set: [...tempOptionsData] })
      );
      setIsLoading(true);
      const payload = {
        currentStageId: draggableId,
        position: destination.index
      };
      dispatch(
        updatePositionOfStage(
          payload,
          onDragSuccess,
          onDragError,
          tempOptionsData
        )
      );
    }
  };

  const formSchema = {
    stageName: string().required('Please provide stage name')
    // .max(30, maximumCharactersMsg('stage name', 30))
  };
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const onSuccess = () => {
    setIsLoading(false);
    setAddStage(false);
    setValue('stageName', '', { shouldTouch: false });
    openSuccessSnackBar('Success');
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
  };

  const onDragStart = (data) => {
    setIsDragStarted(true);
    setShowMoveIcon(null);
  };

  useEffect(() => {
    clearErrors('stageName');
    setAddStage(false);
  }, [box_id]);

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = {
      box: box_id,
      stage_name: data.stageName,
      stage_kind: CUSTOM
    };
    dispatch(createStage(formData, onSuccess, onError));
  };

  useEffect(() => {
    if (box_id) dispatch(getBoxStageLeadCount({ boxId: box_id }));
  }, [box_id]);

  const getLeadCount = (id) => {
    return boxStagesLeadCount.filter((stages) => stages?.id === id);
  };

  useEffect(() => {
    if (addStage) {
      document.getElementById('stageName')?.focus();
    }
  }, [addStage]);

  return (
    <div>
      <div className="   p-8">
        <h2 className="text-2xl font-semibold text-center">Box stages</h2>
        <p className="text-grey-800 text-15 text-center mt-3 px-16">
          Drag to reorder,rename or add new stages
        </p>

        <div className="relative mt-6 ">
          <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="pb-2"
                >
                  {currentBox?.boxstage_set?.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                      isDragDisabled={item?.stage_kind === INCOMING}
                    >
                      {(provided, snapshot) => {
                        if (snapshot.isDragging) {
                          const offset = { x: 0, y: 0 };
                          const x =
                            provided.draggableProps.style.left - offset.x;
                          const y =
                            provided.draggableProps.style.top - offset.y;
                          provided.draggableProps.style = {
                            ...provided.draggableProps.style,
                            left: x,
                            top: y
                          };
                        }
                        const isActive = snapshot.isDragging;
                        return (
                          <div
                            style={{
                              display: 'block pl-4'
                            }}
                            onMouseEnter={() =>
                              !isDragStarted && setShowMoveIcon(index)
                            }
                            onMouseLeave={() => setShowMoveIcon(null)}
                          >
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <div className="relative pl-5">
                                <CustomToolTip
                                  position="top"
                                  tooltipText={
                                    item?.stage_kind === INCOMING
                                      ? 'This stage cannot be reorderd'
                                      : 'Drag to reorder'
                                  }
                                  style={{ top: '45px', left: '-18px' }}
                                >
                                  {(showMoveIcon === index || isActive) && (
                                    <img
                                      src={moveIcon}
                                      alt="icon-channel"
                                      className="relative"
                                      style={{
                                        zIndex: 1,
                                        right: -1
                                      }}
                                    />
                                  )}
                                </CustomToolTip>

                                {/*  */}
                                <div>
                                  <Stages
                                    item={item}
                                    index={index}
                                    addStage={addStage}
                                    showMoveIcon={showMoveIcon}
                                    stageleadCount={getLeadCount(item?.id)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          {isLoading && <ScreenLoader />}
          {addStage && (
            <div className="mt-4 ml-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className={`w-full ${addStage ? 'is-open' : ''}`}>
                  <div className="relative">
                    <InputWithButton
                      id="stageName"
                      buttonLabel="Save"
                      inputName="stageName"
                      placeholder="Type your stage name"
                      register={register}
                      buttonClassName="btn--md btn--copy text-md mr-2"
                      medium
                      errors={errors}
                      touchedFields={touchedFields}
                      //   defaultValue={title}
                      isCancelButton={true}
                      cancelButtonClassName="btn--md btn--input-cancel btn--grey text-md mr-2"
                      cancelButtonLabel="Cancel"
                      onCancel={() => {
                        setAddStage(false);
                        clearErrors('stageName');
                      }}
                      isLoading={isLoading}
                      enableLoader={true}
                      style={{
                        paddingRight: '155px',
                        fontSize: '15px',
                        height: '55px'
                      }}
                    />
                  </div>
                  {errors.stageName && touchedFields.stageName && (
                    <ErrorMessage message={errors?.stageName?.message} />
                  )}
                </div>
              </form>
            </div>
          )}
          {!addStage && (
            <div
              className="flex items-center justify-center bg-grey-90 rounded-md cursor-pointer mt-4 ml-5"
              onClick={() => {
                setAddStage(true);
              }}
            >
              <CustomColorButtonWithLogo
                className="btn text-primary text-15 text-xl"
                renderSvg={() => <AddIcon className="mr-1" />}
                label="Add a Stage"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BoxStages;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import ButtonWithLogo from 'components/buttons/buttonWithLogo';
import MicroSoftLogo from 'assets/icons/icon-microsoft.svg';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  INVITED_EMAIL,
  SUB_ORG_ID,
  USER_INVITATION_LINK
} from 'utils/constants';
import queryString from 'query-string';
import { isEmpty } from 'utils/utils';

const Microsoft = (props) => {
  const socialCredentials = useSelector(
    (state) => state.social.socialCredentials
  );
  const location = useLocation();

  const queryParams = queryString.parse(location?.search);

  useEffect(() => {
    if (!isEmpty(queryParams?.email)) {
      localStorage.setItem(INVITED_EMAIL, queryParams?.email);
      localStorage.setItem(USER_INVITATION_LINK, window.location.href);
      localStorage.setItem(SUB_ORG_ID, queryParams?.sub_organization_id);
    }
  }, [queryParams]);

  const REDIRECT_URL = process.env.REACT_APP_MICROSOFT_REDIRECT_URL;

  const handleLogin = () => {
    const {
      authUrl,
      clientId,
      response_type,
      response_mode,
      scope,
      state,
      nonce
    } = socialCredentials.azure;
    const url = `${authUrl}?client_id=${clientId}&response_type=${response_type}&response_mode=${response_mode}&scope=${scope}&state=${state}&nonce=${nonce}&redirect_uri=${REDIRECT_URL}`;
    window.open(url, '_blank');
  };

  return (
    <ButtonWithLogo
      logo={MicroSoftLogo}
      label="Microsoft"
      onClick={handleLogin}
    />
  );
};

export default Microsoft;

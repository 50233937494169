/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MapFieldContainer from '../mapFieldContainer';
import TextInputField from 'components/inputs/textField';
import TextArea from 'components/inputs/textarea';
import { isEmpty, timeFormatter } from 'utils/utils';
import iconExpand from 'assets/icons/icon-expand.svg';
import CustomToolTip from 'components/CustomTooltip';
import AttachmentItem from '../../email/AttachmentItem';
import iconCollapse from 'assets/icons/icon-collapse.svg';

const WebformContainer = (props) => {
  const { onClick, isFieldsMapped, time, data, isCollapse, isBin, lead } =
    props;
  const [conversationMessage, setConversationMessage] = useState({});
  const [webformFieldList, setWebformFieldList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(isCollapse);
  const [showExpandIcon, setShowExpandIcon] = useState(null);
  const [errorLoad, setErrorLoad] = useState(false);

  useEffect(() => {
    evaluteData(data);
    return () => {
      setErrorLoad(false);
    };
  }, []);

  const evaluteData = (data) => {
    if (data.mapped_message) {
      if (data.mapped_message && typeof data.mapped_message === 'string') {
        let tempData = {};
        let tempList = [];
        tempData = data.mapped_message.replaceAll("'", '"');
        try {
          tempData = JSON.parse(tempData);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
          setErrorLoad(true);
          return;
        }
        Object.keys(tempData).forEach((item) => {
          if (!['sub_data', 'form_name'].includes(item)) {
            tempList.push(tempData[item]);
          }
        });
        const finalList = tempList.reduce((acc, obj) => {
          Object.keys(obj).forEach((key) => {
            acc.push({ label: key, [key]: obj[key] });
          });
          return acc;
        }, []);
        setWebformFieldList(finalList);
        setConversationMessage(tempData);
      }
      setIsLoading(false);
    } else {
      if (data.message && typeof data.message === 'string') {
        let tempData = {};
        let tempList = [];

        tempData = data.message.replaceAll("'", '"');
        try {
          tempData = JSON.parse(tempData);
        } catch (e) {
          console.log(e);
          setIsLoading(false);
          setErrorLoad(true);
          return;
        }
        Object.keys(tempData).forEach((item) => {
          if (!['sub_data', 'form_name'].includes(item)) {
            tempList.push(tempData[item]);
          }
        });
        const finalList = tempList.reduce((acc, obj) => {
          Object.keys(obj).forEach((key) => {
            acc.push({ label: key, [key]: obj[key] });
          });
          return acc;
        }, []);
        setWebformFieldList(finalList);
        setConversationMessage(tempData);
      }
      setIsLoading(false);
    }
  };

  if (isLoading) return <></>;

  if (errorLoad) {
    return (
      <div className="" style={{ marginLeft: 6, width: '98%' }}>
        <div
          className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
          style={{
            borderRadius: 10,
            padding: '8px 22px',
            backgroundColor: '#EEF7FC',
            width: 'calc(100% - 100px)',
            marginRight: 0
          }}
        >
          Failed to load data.
        </div>
      </div>
    );
  }
  return (
    <>
      {!isCollapsed ? (
        <div className="" style={{ marginLeft: 6, width: '98%' }}>
          <div
            className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
            style={{
              borderRadius: 10,
              padding: '22px 22px 25px',
              backgroundColor: '#EEF7FC',
              width: 'calc(100% - 100px)',
              marginRight: 0
            }}
          >
            {/* <CustomToolTip
              position="bottom"
              tooltipText="Collapse"
              style={{ position: 'absolute', right: '7px', top: '6px' }}
            >
              <img
                src={iconCollapse}
                alt="icon-expand"
                className="cursor-pointer"
                onClick={() => setIsCollapsed(true)}
              />
            </CustomToolTip> */}
            {!isFieldsMapped && (
              <MapFieldContainer
                onClick={onClick}
                isBin={isBin}
                lead={lead}
                isWebform={true}
              />
            )}
            <div className="email-body--webform">
              {webformFieldList.map((list, index) => {
                const { label } = list;
                if (label === 'Enteryourtextarea:') {
                  return (
                    <div key={index}>
                      <label className="input-field-label">{label}</label>
                      <TextArea
                        name={label}
                        value={
                          conversationMessage?.form_data[label]
                            ? conversationMessage?.form_data[label]
                            : ''
                        }
                        style={{ fontWeight: 400 }}
                        className="border text-base-1 resize-none"
                        readOnly
                        rows="3"
                        cols="5"
                      />
                    </div>
                  );
                }
                if (!['attachments', 'Formname'].includes(label)) {
                  return (
                    <div className="mb-5" key={index}>
                      <label className="input-field-label">{label}</label>
                      <TextInputField
                        className="input-field--md"
                        style={{ fontWeight: 400 }}
                        readOnly
                        value={
                          conversationMessage?.form_data[label]
                            ? conversationMessage?.form_data[label]
                            : ''
                        }
                        name={label}
                      />
                    </div>
                  );
                }
                // if()
              })}
              {!isEmpty(data?.attachments) && (
                <div className="email-body" style={{ padding: 0 }}>
                  <h3 className=" flex text-13 text-grey-900 font-bold mb-2">
                    Attachments
                  </h3>
                  <div className="item__attachment">
                    {data?.attachments.map((attachment, idx) => {
                      return (
                        <AttachmentItem
                          key={idx}
                          attachment={attachment}
                          message={true}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
            {isFieldsMapped && conversationMessage?.form_name && (
              <div className="text-13 mt-5 truncate">
                <span className="font-semibold">Form name: </span>
                {conversationMessage?.form_name}
              </div>
            )}
            <div
              className={`text-13 truncate ${isFieldsMapped ? 'mt-1' : 'mt-5'}`}
            >
              <span className="font-semibold">Submission page: </span>
              <a
                href={conversationMessage?.sub_data[0]?.value}
                target="_blank"
                rel="noreferrer"
              >
                {conversationMessage?.sub_data[0]?.value}
              </a>
            </div>
          </div>
          <div>
            <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
              {timeFormatter(time)}
            </div>
          </div>
        </div>
      ) : (
        <div className="" style={{ marginLeft: 6, width: '98%' }}>
          <div
            className="inline-block border border-grey-400 text-base-1 text-grey-900 cursor-pointer"
            style={{
              borderRadius: 10,
              padding: '9px 24px 9px 16px',
              backgroundColor: '#EEF7FC',
              width: 'calc(100% - 100px)',
              marginRight: 0
            }}
            // onClick={() => setIsCollapsed(false)}
            // onMouseEnter={() => setShowExpandIcon(data.id)}
            // onMouseLeave={() => setShowExpandIcon(null)}
          >
            <div className="flex justify-between items-center">
              <div className="truncate">
                <h3 className="font-semibold">{data?.lead?.name}</h3>
                <div className={`truncate flex`}>
                  <p className="font-medium text-13">Webform submisson:</p>
                  <a
                    href={conversationMessage?.sub_data[0]?.value}
                    target="_blank"
                    rel="noreferrer"
                    className="text-13 truncate"
                    style={{ maxWidth: '60%' }}
                  >
                    {conversationMessage?.sub_data[0]?.value}
                  </a>
                </div>
              </div>
              {showExpandIcon === data.id && (
                <CustomToolTip position="top" tooltipText="Expand">
                  <img
                    src={iconExpand}
                    className="cursor-pointer"
                    alt="icon-expand"
                    style={{ minWidth: 14, minHeight: 14 }}
                    // onClick={() => setIsCollapsed(false)}
                  />
                </CustomToolTip>
              )}
            </div>
          </div>
          <div>
            <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
              {timeFormatter(time)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

WebformContainer.propTypes = {
  onClick: PropTypes.func,
  isFieldsMapped: PropTypes.bool,
  time: PropTypes.string
};

export default WebformContainer;

import moment from 'moment';
const DEFAULT_TIME = '9:00am';
export const getCurrentZone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;
export const makeTwoDigits = (number) => `00${number.toString()}`.slice(-2);
export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];
export const toMonthText = (number) => months[number];

export const formatDateTime = (dateTimeString, format = 'dd/mm/yy hh:MMp') => {
  if (dateTimeString === null) return '-';
  let dateString = moment(dateTimeString, 'DD-MM-YYYY', true).format();
  const dateObj =
    dateString !== 'Invalid date'
      ? new Date(dateString)
      : new Date(dateTimeString);
  const hours = dateObj.getHours();
  const month = dateObj.getMonth();
  return format
    .replace('p', hours > 12 ? 'pm' : 'am')
    .replace('P', hours > 12 ? 'PM' : 'AM')
    .replace('mmm', toMonthText(month))
    .replace('mm', makeTwoDigits(month + 1))
    .replace('dd', makeTwoDigits(dateObj.getDate()))
    .replace('yyyy', dateObj.getFullYear())
    .replace('yy', makeTwoDigits(dateObj.getFullYear()))
    .replace('hh', makeTwoDigits(hours % 12))
    .replace('HH', makeTwoDigits(hours))
    .replace('MM', makeTwoDigits(dateObj.getMinutes()));
};

function get_hours(time_string) {
  return moment(time_string, 'hh:mm A').format('HH');
  //return new Date('2000-01-01 ' + time_string).getHours(); // 22
}

function get_minutes(time_string) {
  return moment(time_string, 'hh:mm A').format('mm');
  // return new Date('2000-01-01 ' + time_string).getMinutes(); // 22
}

function formatTime(time) {
  const t = time.toLowerCase();
  if (t.includes('am')) {
    return t.replace('am', ' am');
  }
  if (t.includes('pm')) {
    return t.replace('pm', ' pm');
  }
}

export const getTimeIn24Format = (time) => {
  const hour = makeTwoDigits(get_hours(formatTime(time)));
  const minutes = makeTwoDigits(get_minutes(formatTime(time)));
  return `${hour}:${minutes}`;
};

export const getTimeIn12Format = (timeString, options = {}) => {
  const opt = {
    minute: true,
    ...options
  };
  const [hourString, minute] = timeString.split(':');
  const hour = +hourString % 24;
  if (!opt.minute) return (hour % 12 || 12) + (hour < 12 ? 'am' : 'pm');
  return (hour % 12 || 12) + ':' + minute + (hour < 12 ? 'am' : 'pm');
};

export const timeZonesToIgnore = ['+00:00', '-00:00'];

export const isDateInUtcFormat = (date) => {
  if (!date) return '';
  const timeZone = date.split('T');
  const timeZoneString = timeZone.toString().slice(-6);
  let isUtcTime = timeZonesToIgnore.includes(timeZoneString);
  const indexOfMinus = timeZoneString.indexOf('-');
  const indexOfPlus = timeZoneString.indexOf('+');
  if (indexOfMinus < 0 && indexOfPlus < 0) isUtcTime = true;
  return isUtcTime;
};

export const prepareDateAndTime = (value, options) => {
  const opt = {
    minute: true,
    ...options
  };
  if (!value) {
    return { date: new Date(), time: DEFAULT_TIME };
  }
  const [date, time] = value.split('T');
  const [hours, mins] = time ? time.split(':') : ['9', '00'];
  const tempTime = getTimeIn12Format(`${makeTwoDigits(hours)}:${mins}`, opt);
  const ne = formatDateTime(date, 'yyyy/mm/dd');
  const dt = new Date(`${ne}`);
  dt.setHours(hours);
  dt.setMinutes(mins);
  return {
    date: new Date(dt),
    time: tempTime
  };
};

export const isDateAndTimeOverdue = (date) => {
  const dueDate = new Date(date).toUTCString();
  const currentUTCTime = new Date().toUTCString();
  // const { date: D } = prepareDateAndTime(date, { minutes: false });
  // const time = moment(D);
  // const now = moment();
  const time = moment(dueDate);
  const now = moment(currentUTCTime);
  const status = time.isSameOrBefore(now);
  return status;
};

import React from 'react';
import AverageResponseTime from 'modules/enquirybox/reports/components/averageResponseTime';
import FilterSectionPublic from 'modules/enquirybox/reports/components/FilterSectionPublic';
import TeamMemberResponseTable from 'modules/enquirybox/reports/components/teamMemberResponseTable';
import TopResponder from 'modules/enquirybox/reports/components/topResponder';
import TopSalesPerformer from 'modules/enquirybox/reports/components/topSalesPerformer';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';

const TeamReportPublic = (props) => {
  const { endDate, startDate, reportType, date_format } = props;
  const { teamReport } = useSelector((state) => state.reports);

  return (
    <div
      className="bg-grey-300 rounded-lg"
      style={{
        margin: 'auto',
        marginTop: 50,
        maxWidth: '1000px',
        padding: 25,
        maxHeight: 'calc(100vh - 60px)'
      }}
    >
      <div className="team-performance-report">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold">Team performance report</h3>
          <FilterSectionPublic
            endDate={endDate}
            startDate={startDate}
            reportType={reportType}
            date_format={date_format}
          />
        </div>
        <div className="flex items-center -mx-3 mt-6">
          <TopSalesPerformer
            isShowEmptyState={isEmpty(teamReport) ? true : false}
            data={teamReport?.top_sales_performer}
          />
          <TopResponder
            isShowEmptyState={isEmpty(teamReport) ? true : false}
            data={teamReport?.top_responder}
          />
        </div>
        <div className="mt-8">
          <AverageResponseTime
            isShowEmptyState={isEmpty(teamReport) ? true : false}
            averageTime={teamReport?.average_response_time}
            averageTimeChange={teamReport?.average_response_time_change}
            selectedDay={date_format}
          />
        </div>
        <TeamMemberResponseTable
          isPublic={true}
          isShowEmptyState={isEmpty(teamReport) ? true : false}
          data={teamReport?.other_user_data}
        />
      </div>
    </div>
  );
};

export default TeamReportPublic;

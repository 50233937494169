import { ReactComponent as InfoIcon } from 'assets/icons/Info_fill.svg';

const NotificationComponent = ({ style, validationCount }) => {
  return (
    <div
      style={{
        ...style,
        backgroundColor: '#F55E5E',
        borderRadius: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'initial',
        height: '20px',
        padding: '12px 1px 12px 7px'
      }}
    >
      <p style={{ color: 'white', fontWeight: 500 }}>4</p>
      <InfoIcon style={{ marginTop: '9px' }} />
    </div>
  );
};

export default NotificationComponent;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import DefaultProfile from 'assets/icons/icon-default-profile.png';
import { ReactComponent as IconProfilePincle } from 'assets/icons/icon-profile-camera.svg';
import { Loader } from 'components/loader';
import APIRequest from 'apiRequest';
import { FILE_UPLOAD } from 'urls';
import { useDispatch } from 'react-redux';
import { getOrganizationUser } from 'slices/organizationuserSlice';

import ReactImageCropModel from 'components/reactImageCrop/reactImageCropModel';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';

const UserProfileUpload = ({
  myInfo,
  updateUserProfileName,
  subOrganizationId
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { id, username, email } = myInfo;
  const [imageCrop, setImageCrop] = useState(null);
  const [canvas, setCanvas] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleFileUplad = (e) => {
    e.preventDefault();
    if (e.target.files && e.target.files.length > 0) {
      // if (!validateImageType(file)) {
      // 	WarningToast(invalidImageFormat());
      // 	setUploading(false);
      // 	setImageUrl(user.profilePic);
      // 	return;
      // }
      // if (!validateImageSize(file)) {
      // 	WarningToast(fileLargeMessage());
      // 	setUploading(false);
      // 	setImageUrl(user.profilePic);
      // 	return;
      // }
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageCrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
      e.target.value = '';
    }
    setShowConfirmation(true);
  };

  const onSuccess = (payload) => {
    dispatch(updateUserProfileName(payload));
    setTimeout(() => {
      dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
    }, 1000);
    setIsLoading(false);
  };

  const handleSaveCroppedImage = () => {
    setShowConfirmation(false);
    canvas.toBlob(
      (blob) => {
        const anchor = document.createElement('a');
        if (!isEmpty(blob)) {
          anchor.href = URL.createObjectURL(blob);
        }
        const file = blob;
        const formData = new FormData();
        formData.append('file', file);
        formData.append(
          'uploading_type',
          CHOICES.FileUploadingChoices.PROFILE_PHOTO
        );
        setIsLoading(true);
        new APIRequest()
          .post(FILE_UPLOAD, formData, {
            'Content-Type': 'multipart/form-data'
          })
          .then((res) => {
            const myInfoUpate = { ...myInfo };
            myInfoUpate['avatar'] = res?.data?.imageUrl;
            const payload = {
              userId: id,
              data: {
                avatar: res?.data?.imageUrl,
                username,
                email
              },
              myInfo: myInfoUpate
            };
            onSuccess(payload);
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
          });
      },
      'image/png',
      ' image/jpeg',
      1
    );
  };

  return (
    <div className="file-upload-profile">
      <input
        name="profile-upload"
        type="file"
        id="fileUpload"
        className="file-upload__input"
        accept="image/png, image/jpeg"
        onChange={handleFileUplad}
      />
      <label
        htmlFor="fileUpload"
        className="file-upload-profile__container"
        onClick={() => {
          setImageCrop(null), setShowConfirmation(false);
        }}
      >
        <div style={{ left: 25, bottom: 40, position: 'absolute' }}>
          {isLoading && <Loader />}
        </div>

        <img
          src={myInfo?.avatar || DefaultProfile}
          alt="icon-default-profile"
        />

        <div className="file-upload-profile__icon-wrapper">
          <IconProfilePincle />
        </div>
      </label>
      <ReactImageCropModel
        showConfirmation={showConfirmation}
        src={imageCrop}
        setImageCrop={setImageCrop}
        handleSaveCroppedImage={handleSaveCroppedImage}
        setCanvas={setCanvas}
        setShowConfirmation={setShowConfirmation}
      />
    </div>
  );
};

export default UserProfileUpload;

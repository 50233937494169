/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import Quill from 'components/quill';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { sendReplyFb, setIsReplyLoading } from 'slices/facebook/slice';
import { useSnackbar } from 'components/Snackbar';
import { isEditorEmpty } from 'utils/utils';
// import { isEmpty } from 'utils/utils';
import {
  setIsForceClose,
  setIsSoftClose,
  setEmailBody
} from 'slices/editor/slice';
import { isEmpty } from 'utils/utils';
// import APIRequest from 'apiRequest';
import { setActiveLead } from 'slices/conversationSlice';
// import { SEND_FB_INSTA_REPLY } from 'urls/facebook';
// import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import {
  getStageLeads,
  composeStageLeads,
  changeBoxLeadStatus
} from 'slices/boxes/slice';
import {
  changeLeadStatus,
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads,
  setActiveNewLead
} from 'slices/eboxLead';
import { useAnalytics } from 'use-analytics';

const REPLY_LATER = CHOICES.LeadStatusChoices.REPLY_LATER;
const VIEWED = CHOICES.LeadStatusChoices.VIEWED;

const ReplyByFacebook = ({
  isFooterExpanded,
  onClose,
  onReplySentSuccess,
  hideImage,
  hideAttachment
}) => {
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const [, setAttachments] = useState([]);
  // const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({});
  const { openSuccessSnackBar } = useSnackbar();
  const { emailBody } = useSelector((state) => state.editor);
  const { isReplyLoading } = useSelector((state) => state.fb);
  const editorRef = useRef();
  editorRef.current = useSelector((state) => state.editor);
  // const { activeNewLead } = useSelector((state) => state.leads);
  // const { analytics } = useSelector((state) => state.segments);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { track } = useAnalytics();

  // useEffect(() => {
  //   dispatch(setIsSoftClose(false));
  //   dispatch(setIsForceClose(true));
  //   return () => {
  //     onUnmountSaveEditorContent(editorRef);
  //   };
  // }, []);

  const updateLeadStatusIfInReplyLater = async () => {
    // if (activeLead.status === REPLY_LATER) {
    //   const data = {
    //     lead: cardId,
    //     is_current: true,
    //     status: VIEWED
    //   };
    //   dispatch(changeLeadStatus({ body: data }));
    //   dispatch(setActiveLead({ ...activeLead, status: VIEWED }));
    // }
    if (activeLead.status === REPLY_LATER) {
      const data = {
        lead: cardId,
        is_current: true,
        status: VIEWED
      };
      if (!isBox) {
        // await Promise.all([
        //   dispatch(changeLeadStatus({ body: data })),
        //   dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        // ]);
      }
      if (isBox) {
        await Promise.all([
          dispatch(changeBoxLeadStatus({ body: data, stageId })),
          dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        ]);
      }
    }
  };

  const replySentCallback = () => {
    dispatch(setIsSoftClose(false));
    dispatch(setIsForceClose(false));
    updateLeadStatusIfInReplyLater();
    dispatch(setEmailBody(''));
    dispatch(setActiveLead({ ...activeLead, draft_reply: {} }));
    updateLeadCardsData();
    dispatch(setIsReplyLoading(false));
    openSuccessSnackBar('Reply sent');
    track(
      'reply_sent',
      {
        reply_type: 'Facebook'
      },
      {
        groupId: subOrganizationId
      }
    );
    onClose();
    onReplySentSuccess();
  };

  // updating the cards status because after the sending the reply right side card data should update
  const updateLeadCardsData = async () => {
    if (!isBox) {
      // if (VIEWED === activeLead?.status) {
      //   dispatch(getReadLeads());
      // }
      // if (REPLY_LATER === activeLead?.status) {
      //   dispatch(getReplyLaterLeads());
      //   //updating the status even after calling the reply later api status is not updating
      // }
      // if (activeNewLead === activeLead?.id) {
      //   dispatch(setActiveNewLead());
      //   dispatch(getUnReadLeads());
      //   dispatch(getReadLeads());
      // }
    }
    if (isBox) {
      if (activeLead.status !== REPLY_LATER) {
        await dispatch(getStageLeads({ stageId }))
          .then((res) => {
            dispatch(composeStageLeads({ stageId, results: res.data }));
            dispatch(getLeadData({ leadId: cardId }));
          })
          .catch((err) => {
            console.log();
          });
      }
    }
  };

  // async function onUnmountSaveEditorContent(editor) {
  //   if (editor.current.is_soft_close) {
  //     draftMessage(editor.current);
  //   }
  //   if (editor.current.is_force_close) {
  //     draftMessage(editor.current);
  //   }
  // }

  // const getDraftData = (editor) => {
  //   var temp = document.createElement('div');
  //   temp.innerHTML = editor.emailBody;
  //   var sanitized = temp.textContent || temp.innerText;
  //   const value = sanitized;
  //   let data = {
  //     message: value,
  //     is_in_draft_state: true
  //   };
  //   if (activeLead?.draft_reply?.is_in_draft_state) {
  //     data = {
  //       ...data,
  //       lead_reply_id: activeLead?.draft_reply?.id
  //     };
  //     if (isEmpty(value)) {
  //       data = {
  //         ...data,
  //         delete_draft: true
  //       };
  //     }
  //   }
  //   return data;
  // };

  // const draftMessage = async (editor) => {
  //   return await new APIRequest()
  //     .post(
  //       replaceUrl(SEND_FB_INSTA_REPLY, 'leadId', cardId),
  //       getDraftData(editor)
  //     )
  //     .then((res) => {
  //       if (editor.is_soft_close) {
  //         dispatch(
  //           setActiveLead({
  //             ...activeLead,
  //             draft_reply: res?.data
  //           })
  //         );
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const onSubmit = () => {
    dispatch(setIsReplyLoading(true));
    if (isEditorEmpty(emailBody)) {
      let err = {};
      err = {
        ...err,
        emailBody: 'Please add message'
      };
      dispatch(setIsReplyLoading(false));
      setError(err);
    } else {
      var temp = document.createElement('div');
      temp.innerHTML = emailBody;
      var sanitized = temp.textContent || temp.innerText;
      const value = sanitized;
      let data = {
        message: value,
        is_in_draft_state: false
      };
      if (!isEmpty(activeLead?.draft_reply)) {
        data = {
          ...data,
          lead_reply_id: activeLead?.draft_reply?.id
        };
      }
      dispatch(
        sendReplyFb({ leadId: cardId, data: data, callback: replySentCallback })
      );
    }
  };

  const onUseTemplate = (subject, body, attachments) => {
    dispatch(setEmailBody(body));
    setAttachments(attachments);
  };

  return (
    <div className="chat-message-footer__body">
      <div className={`chat-reply ${error.emailBody ? 'error-editor' : ''}`}>
        <Quill
          onChange={(data) => {
            setError({ ...error, emailBody: null });
            dispatch(setEmailBody(data));
          }}
          value={emailBody}
          showFontStyle={false}
          placeholder="Type a reply..."
          setAttachments={setAttachments}
          isLoading={isReplyLoading}
          isFooterExpanded={isFooterExpanded}
          onSend={onSubmit}
          hideImage={hideImage}
          hideAttachment={hideAttachment}
          errorMessage={error.emailBody}
          isFbOrInsta={true}
          isRefetch={true}
          onUseTemplate={onUseTemplate}
          isAutoFocus={true}
        />
      </div>
    </div>
  );
};

export default ReplyByFacebook;

import React, { useEffect, useState } from 'react';
import Call from './component/call';
import FollowUp from './component/followUp';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTaskOutcome } from 'slices/task/slice';

const TasksSettings = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    // api call to get all the task outcome
    dispatch(getAllTaskOutcome({ subOrgId: subOrganizationId }));
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  return (
    <>
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Tasks</h2>
        <p className="text-grey-800 text-15 mt-3">
          Make your task outcomes your own
        </p>
      </div>
      <div style={{ marginTop: 45 }}>
        <div className="tabs-wrapper">
          <div
            className={`tabs-header ${
              activeTab === 1 ? 'tabs-header__active' : ''
            }`}
            onClick={() => setActiveTab(1)}
          >
            <div
              className={`ml-1 text-15 ${
                activeTab === 1 ? '' : 'text-grey-900'
              }`}
            >
              Call
            </div>
          </div>
          <div
            className={`tabs-header ${
              activeTab === 2 ? 'tabs-header__active' : ''
            }`}
            onClick={() => setActiveTab(2)}
          >
            <div
              className={`ml-1 text-15 ${
                activeTab === 2 ? '' : 'text-grey-900'
              }`}
            >
              Follow up
            </div>
          </div>
        </div>
        {activeTab === 1 && <Call />}
        {activeTab === 2 && <FollowUp />}
      </div>
    </>
  );
};

export default TasksSettings;

import React from 'react';

const ProgressBar = ({ bgcolor, progress }) => {
  return (
    <div className="progress-bar-container">
      <div
        className="progress-bar-slider"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;

import React from 'react';
import PropTypes from 'prop-types';
import Reply from './reply';
import iconClose from 'assets/icons/icon-close-grey.svg';
import iconExpand from 'assets/icons/icon-expand.svg';
import iconCollapse from 'assets/icons/icon-collapse.svg';
import iconReplyActive from 'assets/icons/icon-reply.svg';
// import iconNoteActive from 'assets/icons/icon-note-active.svg';
// import iconNoteInActive from 'assets/icons/icon-note.svg';
// import iconReminderActive from 'assets/icons/icon-reminder-active.svg';
// import iconReminderInActive from 'assets/icons/icon-reminder.svg';
import iconReplyInActive from 'assets/icons/icon-reply-inactive.svg';
import { useDispatch } from 'react-redux';
import { setEditorActiveTab } from 'slices/eboxLead';
import {
  REPLY
  // NOTE, TASK
} from 'utils/constants';

const allTabs = [
  {
    id: REPLY,
    text: 'Reply',
    iconActive: iconReplyActive,
    iconInactive: iconReplyInActive
  }
  // {
  //   id: NOTE,
  //   text: 'Note',
  //   iconActive: iconNoteActive,
  //   iconInactive: iconNoteInActive
  // },
  // {
  //   id: TASK,
  //   text: 'Task',
  //   iconActive: iconReminderActive,
  //   iconInactive: iconReminderInActive
  // }
];

const Tab = ({ text, active, iconActive, iconInactive, onClick }) => {
  return (
    <div className="flex items-center ml-8 pointer" onClick={onClick}>
      <img src={active ? iconActive : iconInactive} alt="icon-reply" />
      <p
        className={`${
          active ? 'text-primary font-semibold' : 'text-grey font-medium'
        } text-13 ml-2`}
      >
        {text}
      </p>
    </div>
  );
};

const HeaderTab = ({
  active = false,
  onClick = () => {},
  item = {},
  setSubTab,
  subTab,
  isFacebook,
  isInsta,
  isSms
}) => {
  const { text, iconActive, iconInactive } = item;

  return (
    <div className="flex items-center">
      {text === 'Reply' ? (
        <Reply
          item={item}
          onClick={onClick}
          active={active}
          setSubTab={setSubTab}
          subTab={subTab}
          isFacebook={isFacebook}
          isInsta={isInsta}
          isSms={isSms}
        />
      ) : (
        <Tab
          text={text}
          active={active}
          iconActive={iconActive}
          iconInactive={iconInactive}
          onClick={onClick}
        />
      )}
    </div>
  );
};

const Header = ({
  activeTab,
  onExpandClick,
  subTab,
  setSubTab,
  isFooterExpanded,
  isFacebook,
  isInsta,
  onClose,
  isSms
}) => {
  const dispatch = useDispatch();
  return (
    <div className="chat-message-footer__header chat-message-footer__header">
      <div className="flex items-center">
        {allTabs.map((item, index) => {
          return (
            <HeaderTab
              key={index}
              item={item}
              text={item.text}
              active={activeTab === item.id}
              subTab={subTab}
              setSubTab={setSubTab}
              isFacebook={isFacebook}
              isInsta={isInsta}
              isSms={isSms}
              onClick={() => {
                dispatch(setEditorActiveTab(item.id));
              }}
            />
          );
        })}
      </div>
      <div className="flex justify-center items-center">
        {activeTab === REPLY && subTab === 'Email' && (
          <img
            src={!isFooterExpanded ? iconExpand : iconCollapse}
            alt="icon-expand"
            className="cursor-pointer"
            onClick={onExpandClick}
          />
        )}
        <img
          src={iconClose}
          alt="icon-close"
          className="cursor-pointer ml-3"
          onClick={() => onClose({ forceClose: true })}
        />
      </div>
    </div>
  );
};

Header.propTypes = {
  onExpandClick: PropTypes.func,
  onClose: PropTypes.func
};

Header.defaultProps = {
  onExpandClick: () => {},
  onClose: () => {}
};

export default Header;

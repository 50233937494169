/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FacebookHome from './home';
import FacebookSettings from './settings';
import FacebookConnectedSettings from 'modules/channels/components/facebookSettings';
// import AutomateStuff from '../../automate';
// import ChannelsConnectedEmptyState from '../../components/channelsConnectedEmpty';
// import AnimationWrapper from 'animation/wrapper';
import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
import _get from 'lodash/get';

const { FACEBOOK } = CHOICES.ChannelChoices;

const Facebook = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route path="/pending/:channelId" element={<FacebookHome />} />
      <Route path="/connected/:channelId" element={<FacebookSettings />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={FACEBOOK} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<FacebookConnectedSettings channelType={FACEBOOK} />}
      />
      {/* <Route path="/automation/*" element={<AutomateStuff />} /> */}
      {/* </Route> */}
    </Routes>
  );
};

export default Facebook;

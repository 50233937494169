import React from 'react';
import { Loader } from 'components/loader';
import { useSelector } from 'react-redux';
import SubIcon from 'modules/enquirybox/ebox/module/box/components/enquiryCard/subIcon';
import { NavLink } from 'react-router-dom';
import Avatar from 'components/avatar';
import { isEmpty } from 'utils/utils';
import { route } from 'utils/route';
import {
  getLastReply,
  getLeadTime
  // isReplySent
} from 'modules/enquirybox/helper/lead';
import { getColorShade } from 'utils/colors';
// import iconForward from 'assets/icons/icon-forward.svg';

const EnquiryBinEnquiries = (props) => {
  const { isLoading } = props;
  const { allBinLeads } = useSelector((state) => state.enquiryBin);

  return (
    <div className="dashboard__main__body__section-left ebox">
      <div
        className="dashboard__main__body__section-left__body flex flex-col overflow-y-auto"
        style={{ height: '100%' }}
      >
        {isLoading && <Loader />}
        {!isEmpty(allBinLeads) &&
          !isEmpty(allBinLeads?.results) &&
          allBinLeads?.results.map((item, index) => {
            return (
              <div
                className="relative mb-1"
                id={`ebox-${item.id}`}
                key={item.id}
              >
                <NavLink
                  to={`${route.enquiryBin}/lead/${item.channel}/${item.id}`}
                  className={`chats-preview bg-color-on-hover bg-color-on-hover--sm`}
                >
                  <div className="flex items-center gap-4 flex-1 truncate">
                    <div className="relative">
                      <Avatar
                        src={item.customer_avatar}
                        initial={item.name || item.first_name}
                        style={{
                          width: 48,
                          height: 48,
                          backgroundColor: item.customer_avatar
                            ? ''
                            : getColorShade(index)
                        }}
                        large
                        cover
                        singleChar
                      />
                      <div
                        className="bg-white absolute flex justify-center items-center"
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          bottom: '-3px',
                          left: '28px'
                        }}
                      >
                        <SubIcon kind={item.channel} />
                      </div>
                    </div>
                    <div className="truncate pr-4" style={{ width: '75%' }}>
                      <h3 className={`chats-preview__title`}>
                        {item.name || item.first_name}
                      </h3>

                      <div className="flex items-center whitespace-nowrap">
                        {/* {isReplySent(item) && (
                          <img
                            src={iconForward}
                            alt="icon-forward"
                            className="mr-2"
                          />
                        )} */}
                        <p
                          className="chats-preview__msg"
                          dangerouslySetInnerHTML={{
                            __html: getLastReply(item)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="chats-preview__add-info self-baseline">
                    <p
                      className="chats-preview__time"
                      style={{
                        position: 'absolute',
                        top: '36px',
                        right: '0px'
                      }}
                    >
                      {getLeadTime(item)}
                    </p>
                  </div>
                </NavLink>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default EnquiryBinEnquiries;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import QuestionItem from '../components/questionItem';
import { ReactComponent as TextIcon } from 'assets/icons/icon-question-type-text-small.svg';
import { ReactComponent as MultipleOptionsIcon } from 'assets/icons/icon-question-type-multiple-options.svg';
import { ReactComponent as DateIcon } from 'assets/icons/icon-question-type-date.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-question-type-dropdown.svg';
import { ReactComponent as ValueIcon } from 'assets/icons/icon-question-type-value.svg';
import CHOICES from 'choices';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import {
  DeleteQuestion,
  setQuestionsData,
  setEditedData,
  setIsShowQuestionForm,
  setSelectedQuestion,
  setIsEdit
} from 'slices/questionSlice';
import { useDispatch, useSelector } from 'react-redux';
import { questionTypes } from 'utils/questions';

const { TEXT, DROPDOWN, DATETIME, MCQ, VALUE, MCQ_MUTIPLE_SELECT, DATE_RANGE } =
  CHOICES.QuestionChoices;

const QuestionList = ({ data }) => {
  const dispatch = useDispatch();
  const [questionId, setQuestionId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { questionsData } = useSelector((state) => state.question);

  const renderData = (kind) => {
    switch (kind) {
      case TEXT:
        return <TextIcon />;
      case DROPDOWN:
        return <DropdownIcon />;
      case DATETIME:
        return <DateIcon />;
      case MCQ:
        return <MultipleOptionsIcon />;
      case VALUE:
        return <ValueIcon />;
      case MCQ_MUTIPLE_SELECT:
        return <MultipleOptionsIcon />;
      case DATE_RANGE:
        return <DateIcon />;
      default:
        <></>;
    }
  };
  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    const remainingQuestions = questionsData.filter(
      (item) => item.id !== questionId
    );
    dispatch(setQuestionsData(remainingQuestions));
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(DeleteQuestion({ questionId: questionId, callback }));
  };

  const onEdit = (kind, questionData) => {
    let editQuestion = [];
    if (kind === MCQ_MUTIPLE_SELECT) {
      editQuestion = questionTypes.filter((item) => item.type === MCQ);
    } else {
      if (kind === DATE_RANGE) {
        editQuestion = questionTypes.filter((item) => item.type === DATETIME);
      } else {
        editQuestion = questionTypes.filter((item) => item.type === kind);
      }
    }
    dispatch(setSelectedQuestion(editQuestion[0]));
    dispatch(setIsShowQuestionForm(true));
    dispatch(setIsEdit(true));
    dispatch(setEditedData(questionData));
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        onCancel={() => setShowDeleteConfirmation(false)}
        onConfirm={onDelete}
        title="Are you sure you want to delete this question?"
        description="Once deleted you will not be able to get it back. You will have to
      add it again."
        isLoading={isLoading}
      />
      <div className="mt-6 w-full">
        {data?.map((item) => {
          return (
            <QuestionItem
              key={item.id}
              label={item.text}
              renderSvg={() => renderData(item.kind)}
              id={item.id}
              onDelete={onDeleteQuestion}
              type={item.kind}
              questionData={item}
              onEdit={onEdit}
            />
          );
        })}
      </div>
    </>
  );
};

export default QuestionList;

import UpgradePlanTopbar from 'components/UpgradePlanTopbar';

const Wrapper = (props) => {
  const { showUpgradeTopbar, children } = props;
  return (
    <>
      {showUpgradeTopbar && (
        <UpgradePlanTopbar text="Upgrade to use this feature. Click here to upgrade" />
      )}
      <div
        className="dashboard__main__body__section-right layout-1"
        style={{ backgroundColor: 'white', width: '100%' }}
      >
        {children}
      </div>
    </>
  );
};

export default Wrapper;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_FB_CREDENTIALS,
  EXCHANGE_FB_AUTH_CODE,
  GET_INSTAGRAM_CREDENTIALS,
  SEND_FB_INSTA_REPLY,
  GET_FB_LEAD_CREDENTIALS,
  EXCHANGE_FB_LEAD_AUTH_CODE,
  GET_THE_DATA_MAPPING_FIELDS,
  GET_ADS_FORM_FIELDS,
  SEND_FB_LEAD_ADS_MAP_FIELDS,
  GET_ADS_FORM_QUESTIONS_FIELDS
} from 'urls/facebook';
import { replaceUrl } from 'utils/urlReplace';

export const facebookSlice = createSlice({
  name: 'facebook',
  initialState: {
    fbConfigurations: {},
    instagramConfigurations: {},
    fbLeadConfigurations: {},
    dataMappingFields: {},
    adsFormFields: [],
    adsFormQuestionFields: [],
    replyError: '',
    showErrorModal: false,
    isReplyLoading: false
  },
  reducers: {
    setFbConfigurations: (state, action) => {
      state.fbConfigurations = action.payload;
    },
    setFbLeadConfigurations: (state, action) => {
      state.fbLeadConfigurations = action.payload;
    },
    setInstagramConfigurations: (state, action) => {
      state.instagramConfigurations = action.payload;
    },
    setDataMappingFields: (state, action) => {
      state.dataMappingFields = action.payload;
    },
    setAdsFormFields: (state, action) => {
      state.adsFormFields = action.payload;
    },
    setAdsFormQuestionFields: (state, action) => {
      state.adsFormQuestionFields = action.payload;
    },
    setReplyError: (state, action) => {
      state.replyError = action.payload;
    },
    setShowErrorModal: (state, action) => {
      state.showErrorModal = action.payload;
    },
    setIsReplyLoading: (state, action) => {
      state.isReplyLoading = action.payload;
    }
  }
});

export const {
  setFbConfigurations,
  setInstagramConfigurations,
  setFbLeadConfigurations,
  setDataMappingFields,
  setAdsFormFields,
  setReplyError,
  setShowErrorModal,
  setIsReplyLoading,
  setAdsFormQuestionFields
} = facebookSlice.actions;

export default facebookSlice.reducer;

export const getFbCreds = () => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(GET_FB_CREDENTIALS)
        .then((res) => {
          dispatch(setFbConfigurations(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getFbLeadsCreds = () => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(GET_FB_LEAD_CREDENTIALS)
        .then((res) => {
          dispatch(setFbLeadConfigurations(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataMapFields = () => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(GET_THE_DATA_MAPPING_FIELDS)
        .then((res) => {
          dispatch(setDataMappingFields(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAdsFormFields = (leadId) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_ADS_FORM_FIELDS, 'leadId', leadId))
        .then((res) => {
          dispatch(setAdsFormFields(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getAdsFormQuestionFields = (leadId) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .get(replaceUrl(GET_ADS_FORM_QUESTIONS_FIELDS, 'subOrgId', subOrgId))
        .then((res) => {
          dispatch(setAdsFormQuestionFields(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getInstagramCreds = () => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(GET_INSTAGRAM_CREDENTIALS)
        .then((res) => {
          dispatch(setInstagramConfigurations(res?.data));
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendLeadAdsMapFields = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    const { leadId, data } = payload;
    try {
      new APIRequest()
        .post(replaceUrl(SEND_FB_LEAD_ADS_MAP_FIELDS, 'leadId', leadId), data)
        .then((res) => {
          if (onSuccess) onSuccess(res);
        });
    } catch (err) {
      if (onError) onError(err);
    }
  };
};

export const exchangeFaceBookLeadsOauthCode = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { channelId, data } = payload;
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      new APIRequest()
        .post(
          replaceUrl(
            EXCHANGE_FB_LEAD_AUTH_CODE,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          ),
          data
        )
        .then((res) => {
          if (successCb) successCb(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const exchangeFaceBookOauthCode = (payload, successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const { channelId, data } = payload;
    try {
      new APIRequest()
        .post(
          replaceUrl(
            EXCHANGE_FB_AUTH_CODE,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          ),
          data
        )
        .then((res) => {
          if (successCb) successCb(res);
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const sendReplyFb = (payload) => {
  return async (dispatch) => {
    const { leadId, data, callback } = payload;
    try {
      new APIRequest()
        .post(replaceUrl(SEND_FB_INSTA_REPLY, 'leadId', leadId), data)
        .then((res) => {
          dispatch(setShowErrorModal(false));
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          dispatch(setShowErrorModal(true));
          dispatch(setReplyError(err?.response?.data[0]));
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as MoreIcon } from 'assets/icons/icon-more-round.svg';
import { ReactComponent as RenameIcon } from 'assets/icons/icon-select-rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-select-delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import { getLeadLabel, setError, updateLabels } from 'slices/labelsSlice';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';

const LabelItem = (props) => {
  const {
    id,
    title,
    setShowMoreDropdown,
    setOpenEdit,
    setOpenDeleteModal,
    showMoreDropdown,
    openEdit
  } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { error } = useSelector((state) => state.labels);
  const [inputValue, setInputValue] = useState(title);
  const [isError, setIsError] = useState('');

  const { isTeamMember } = useGetUserRole();
  useEffect(() => {
    setIsError('');
    dispatch(setError([]));
  }, [openEdit]);

  const onSuccess = () => {};

  const onError = () => {
    setIsLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (inputValue === title) {
      setOpenEdit(null);
    } else {
      if (inputValue === '') {
        setIsError('label name cannot be empty');
      } else {
        setIsLoading(true);
        const formData = {
          body: {
            label_name: inputValue,
            sub_organization: subOrganizationId
          },
          labelId: id,
          callback: callback
        };
        dispatch(updateLabels(formData, onSuccess, onError));
      }
    }
  };

  const renderCallback = () => {
    setIsLoading(false);
    setOpenEdit(null);
    setShowMoreDropdown(null);
  };

  const callback = () => {
    dispatch(
      getLeadLabel({ subOrgId: subOrganizationId, callback: renderCallback })
    );
  };

  const onCancel = () => {
    setOpenEdit(null);
    setShowMoreDropdown(null);
  };

  return (
    <>
      {!(openEdit === id) ? (
        <div
          className="py-3 px-3 flex justify-between items-center hover-bg-grey"
          style={{ marginTop: 2 }}
        >
          <span className="font-medium">{title}</span>
          <div
            className="cursor-pointer relative p-2"
            onClick={() => setShowMoreDropdown(id)}
          >
            <MoreIcon />
            <div
              className={`dropdown-menu dropdown-menu--more ${
                showMoreDropdown === id ? 'open' : ''
              }`}
              style={{ top: 10 }}
            >
              <div
                className="dropdown-menu__list gap-3"
                onClick={() => setOpenEdit(id)}
              >
                <RenameIcon />
                Rename
              </div>
              <AccessRestricted
                show={isTeamMember}
                alignment="left"
                customStyle={{
                  bottom: '-9px',
                  left: 'calc(50% - 288px)'
                }}
              >
                <div
                  className={`dropdown-menu__list ${
                    isTeamMember ? 'blocked' : 'red-text'
                  } gap-3`}
                  onClick={(e) => {
                    if (!isTeamMember) {
                      e.stopPropagation();
                      setOpenDeleteModal(id);
                      setShowMoreDropdown(null);
                    }
                  }}
                >
                  <DeleteIcon />
                  Delete
                </div>
              </AccessRestricted>
            </div>
          </div>
        </div>
      ) : (
        <form style={{ marginTop: 2 }} autoComplete="off">
          <div className="relative">
            <div>
              <TextInputField
                name="labelName"
                className="input-field input-field--md"
                placeholder=""
                error={!isEmpty(isError) ? true : false}
                defaultValue={title}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
                onChange={(e) => {
                  setIsError('');
                  setInputValue(e.target.value);
                }}
              />
              <CustomColorButton
                className="btn--xs btn--input-cancel btn--grey text-xs"
                label="Cancel"
                onClick={onCancel}
                isLoading={isLoading}
              />
              <PrimaryButton
                label="Save"
                className="btn--xs btn--copy text-xs"
                onClick={(e) => onSubmit(e)}
                enableLoader
                isLoading={isLoading}
              />
            </div>
          </div>
          {isError && <Error message={isError} />}
          {!isEmpty(error) && <Error message={error[0]} />}
        </form>
      )}
    </>
  );
};

LabelItem.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  setShowMoreDropdown: PropTypes.func,
  setOpenEdit: PropTypes.func,
  setOpenDeleteModal: PropTypes.func,
  showMoreDropdown: PropTypes.string,
  openEdit: PropTypes.string
};

export default LabelItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TotalEnquirySales from '../../components/totalEnquirySalesReport';
import EnquiriesConvertedToSales from '../../components/enquiriesConvertedToSales';
import TotalEnquiriesPerStage from '../../components/totalEnquiriesPerStage';
import FilterSection from '../../components/filterSection';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { getAllBoxReport, setTempDateFormat } from 'slices/reports/slice';
import { Loader } from 'components/loader';
import { useParams } from 'react-router-dom';
import { formatDateTime } from 'utils/dateFormat';
import { isEmpty } from 'utils/utils';

const SnapShotReport = (props) => {
  const { hideCount } = props || {};
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [selectedDay, setSelectedDay] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { boxReport } = useSelector((state) => state.reports);

  const callback = () => {
    setIsLoading(false);
  };

  const loadBoxReport = () => {
    const formData = {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      date_format: 'This month to date',
      boxId: box_id
    };
    dispatch(getAllBoxReport(formData, callback));
  };

  useEffect(() => {
    dispatch(setTempDateFormat('This month to date'));
    setIsLoading(true);
    loadBoxReport();
  }, [box_id]);

  const onSelectDay = (data) => {
    setStartDate();
    setEndDate();
    if (data.value === 'This month to date') {
      loadBoxReport();
    }
    if (data.value === 'Last 7 days') {
      const formData = {
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        date_format: data.value,
        boxId: box_id
      };
      dispatch(getAllBoxReport(formData, callback));
    }
  };

  const onCustomDateSelect = (data) => {
    if (data.startDate && data.endDate) {
      const start_date = formatDateTime(data.startDate, 'yyyy-mm-dd');
      const end_date = formatDateTime(data.endDate, 'yyyy-mm-dd');
      const formData = {
        startDate: start_date,
        endDate: end_date,
        date_format: 'Custom',
        boxId: box_id
      };
      dispatch(getAllBoxReport(formData, callback));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Snapshot</h3>
        {!hideCount && (
          <FilterSection
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            onSelectDay={onSelectDay}
            onCustomDateSelect={onCustomDateSelect}
          />
        )}
      </div>
      <div className="flex items-center -mx-3 mt-6">
        <TotalEnquirySales
          total={boxReport?.report?.total_enquiries}
          percentage={boxReport?.report?.total_enquiries_percentage}
          isHidePercentage={
            isEmpty(boxReport?.report?.stages_data) ? true : false
          }
          selectedDay={selectedDay?.value}
        />
        <EnquiriesConvertedToSales
          total={boxReport?.report?.total_enquiries_converted_to_sale}
          totalPrice={boxReport?.report?.total_sales_value}
          percentage={
            boxReport?.report?.total_enquiries_converted_to_sale_percentage
          }
          isHidePercentage={
            isEmpty(boxReport?.report?.stages_data) ? true : false
          }
          selectedDay={selectedDay?.value}
          hideCount={hideCount}
        />
      </div>
      <TotalEnquiriesPerStage
        data={boxReport?.report?.stages_data}
        total={boxReport?.report?.total_enquiries}
        hideCount={hideCount}
      />
    </>
  );
};

export default SnapShotReport;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import DropDownWrapper from 'components/dropdown/wrapper';
import { ReactComponent as BookmarkActive } from '../../assets/bookmark-active.svg';
import ReplyTemplate from './index';
import { ReactComponent as IconPlus } from 'assets/icons/icon-add-item.svg';
import { ReactComponent as IconPlusActive } from '../../assets/icon-plus-active.svg';
import useClickOutside from 'hooks/useOutsideClick';
// import { Loader } from 'components/loader';
// import { getAllReplyTemplates } from 'slices/replyTemplate/slice';
// import { useDispatch, useSelector } from 'react-redux';

const Container = (props) => {
  const { onUseTemplate, isFbOrInsta, isSms } = props;
  const replyTemplateRef = useRef();
  const dropdownRef = useRef();
  // const dispatch = useDispatch();
  const [openReplyTemplate, setOpenReplyTemplate] = useState(false);
  const [openDropDown, setOpenDropDown] = useState(false);
  // const [isLoading, setIsLoading] = useState(false);
  // const { activeLead } = useSelector((state) => state.leadConversation);

  useClickOutside(() => setOpenReplyTemplate(false), replyTemplateRef.current);

  useClickOutside(() => setOpenDropDown(false), dropdownRef.current);

  // const callback = () => {
  //   setIsLoading(false);
  // };

  // const loadAllReplyTemplates = () => {
  //   dispatch(getAllReplyTemplates({ callback: callback }));
  // };

  // useEffect(() => {
  //   setIsLoading(true);
  //   loadAllReplyTemplates();
  // }, [activeLead]);

  // if (isLoading) return <Loader />;

  return (
    <>
      <figure
        className={`avatar-container cursor-pointer ${
          openDropDown || openReplyTemplate ? 'bg-grey-90' : ''
        }`}
        style={{ width: 22, height: 22 }}
        onClick={() => {
          openReplyTemplate
            ? setOpenReplyTemplate(false)
            : setOpenDropDown(true);
        }}
      >
        {openDropDown || openReplyTemplate ? (
          <IconPlusActive />
        ) : (
          <IconPlus height={22} width={22} />
        )}
      </figure>
      <DropDownWrapper
        className="dropdown-menu--sm"
        open={openDropDown}
        style={{
          bottom: '32px',
          right: 'unset',
          top: 'unset',
          left: '0px',
          width: 'fit-content'
        }}
        dropDownref={dropdownRef}
      >
        <div
          className="dropdown-menu__list"
          onClick={() => {
            setOpenReplyTemplate(true);
            setOpenDropDown(!openDropDown);
          }}
        >
          <BookmarkActive className="mr-3" />
          Reply template
        </div>
      </DropDownWrapper>
      <ReplyTemplate
        openReplyTemplate={openReplyTemplate}
        replyTemplateRef={replyTemplateRef}
        setOpenReplyTemplate={setOpenReplyTemplate}
        onUseTemplate={onUseTemplate}
        isFbOrInsta={isFbOrInsta}
        isSms={isSms}
      />
    </>
  );
};

export default Container;

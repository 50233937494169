/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import SettingsHeader from 'components/settings/header';
import { route } from 'utils/route';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import TeamChatSettingsMenu from './modules/menu';
import MyProfile from './modules/myprofile';
import EmailSignature from './modules/emailSignature';
import EmailAddress from './modules/emailAddress';
import Nudges from './modules/nudges';
import Notifications from './modules/notifications';
import AnimationWrapper from 'animation/wrapper';
import ThingsToDoSetting from './modules/thingsToDo';

const TeamChatSettings = () => {
  const navigate = useNavigate();
  const { userId } = useParams();

  const mainRoute = `${route.dashboard}${route.teamChat}/${userId}${route.settings}`;

  const onClose = () => {
    navigate(`${route.dashboard}${route.teamChat}/user/${userId}`);
  };

  return (
    <section className="dashboard__main">
      <SettingsHeader text={`My settings`} onClose={onClose} />
      <div
        className="dashboard__main__body general-settings"
        style={{ height: 'calc(100% - 100px)' }}
      >
        <div className="dashboard__main__body__section-left overflow-overlay">
          <TeamChatSettingsMenu mainRoute={mainRoute} />
        </div>
        <div
          className="dashboard__main__body__section-right layout-1"
          style={{ backgroundColor: 'white' }}
        >
          <Routes>
            <Route element={<AnimationWrapper />}>
              <Route path="/my-profile/*" element={<MyProfile />} />
              <Route path="/email-reply-address/*" element={<EmailAddress />} />
              <Route path="/email-signature/*" element={<EmailSignature />} />
              <Route path="/nudges" element={<Nudges />} />
              <Route path="/things-to-do/*" element={<ThingsToDoSetting />} />
              <Route path="/notifications/*" element={<Notifications />} />
            </Route>
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default TeamChatSettings;

import { createSlice } from '@reduxjs/toolkit';
// import { getAllPendingChannels } from 'slices/channelsSlice';
import {
  COPY_FROM_EXISTING_BOX,
  CREATE_BOX_FORM_STRACH,
  GET_BOX_TEMPLATE
} from 'urls';
// import { replaceUrl } from 'utils/urlReplace';
// import { getAllConnectedChannels } from './channelsSlice';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';

export const customBoxSlice = createSlice({
  name: 'customBox',
  initialState: {
    boxTemplates: [],
    boxName: '',
    boxIcon: '🌳'
  },
  reducers: {
    setboxTemplates: (state, action) => {
      state.boxTemplates = action.payload;
    },
    setBoxIcon: (state, action) => {
      state.boxIcon = action.payload;
    },
    setboxName: (state, action) => {
      state.boxName = action.payload;
    }
  }
});

export const { setboxTemplates, setBoxIcon, setboxName } =
  customBoxSlice.actions;

export const getCustomBoxtemplates = (successCb, errorCb) => {
  return async (dispatch) => {
    try {
      new APIRequest().get(GET_BOX_TEMPLATE).then((res) => {
        if (successCb) {
          successCb(res.data);
        }
        dispatch(setboxTemplates(res.data));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const createCustomBox = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { subOrgId, data } = payload;
      new APIRequest()
        .post(replaceUrl(CREATE_BOX_FORM_STRACH, 'subOrgId', subOrgId), data)
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const copyFromExistingBox = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      const { data } = payload;
      new APIRequest().post(COPY_FROM_EXISTING_BOX, data).then((res) => {
        if (successCb) {
          successCb(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export default customBoxSlice.reducer;

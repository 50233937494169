import React from 'react';
import PropTypes from 'prop-types';

const ListHeader = (props) => {
  const { title, className } = props;
  return (
    <div className={className}>
      <label className="font-semibold">{title}</label>
    </div>
  );
};

ListHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

export default ListHeader;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Quill from 'components/quill';
import { useDispatch, useSelector } from 'react-redux';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';
import { isEmpty } from 'utils/utils';

const Messenger = ({ className, condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const errors = automationErrors[index];
  const {
    data: { message }
  } = condition;
  const [messageBody, setMessageBody] = useState(message);

  const emailBodyRef = useRef();
  const footerBodyRef = useRef();

  const updateThen = () => {
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        message: messageBody
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  useEffect(() => {
    updateThen();
  }, [messageBody]);

  const onMessageChange = (value) => {
    setMessageBody(value);
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.message) {
      let result = omit(errorObj[index], ['message']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
  };

  const onUseTemplate = (sub, body) => {
    setMessageBody(body);
  };

  return (
    <div
      className="chat-message-footer__body bg-white"
      ref={footerBodyRef}
      style={{ height: 'calc(100% - 4px)', borderRadius: 10 }}
    >
      <div className="chat-reply" style={{ border: '1px solid white' }}>
        <div
          className={`${
            errors ? 'error-editor' : ''
          } box-settings ${className}`}
          ref={emailBodyRef}
        >
          <Quill
            onChange={(data) => {
              onMessageChange(data);
            }}
            value={messageBody}
            showSendBtn={false}
            placeholder={'Type your message here'}
            isExpanded={true}
            attachments={[]}
            hideAttachment={true}
            hideVarible={false}
            errorMessage={errors?.message}
            onUseTemplate={onUseTemplate}
            hideImage
            isAutomation
            isFbOrInsta
            isBoxAutomation
            isRefetch
          />
        </div>
      </div>
    </div>
  );
};

export default Messenger;

import React from 'react';
import { getCurrencyText } from 'utils/currency';
import { numberFormat } from 'utils/number';

const ContractValue = ({ value, currency }) => {
  return (
    <>
      <div className="clip-path clip-path--border" />
      <div className="clip-path" />
      <div
        className="stage-value text-sm font-semibold flex items-center px-2 text-secondary ml-6 bg-white"
        style={{
          width: 'fit-content',
          height: 24,
          border: '1px solid rgba(4, 145, 171, 0.3)',
          borderRadius: '20px'
        }}
      >
        {getCurrencyText(currency)} {numberFormat(value, 2)}
      </div>
    </>
  );
};

ContractValue.defaultProps = {
  currency: 1
};

export default ContractValue;

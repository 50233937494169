import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';
import React from 'react';

const RevertConfirmation = ({
  open,
  setOpen,
  onConfirm,
  revertedData,
  isLoading
}) => {
  return (
    <Modal.Main open={open}>
      <Modal.Body className="overflow-y-auto">
        <React.Fragment>
          <div className="items-center">
            <div className="justify-center text-xl flex mt-5 font-semibold">
              <p>Are you sure you want to rollback this import?</p>
            </div>
            <p className="text-sm text-grey-900 mt-3 justify-center  text-center items-center flex">
              {`If you rollback, all the ${revertedData?.count} contacts you added will be removed and you cannot get them back.`}
            </p>
            <div className="relative mt-25 justify-center items-center flex">
              <CustomColorButton
                label="Cancel"
                className="btn btn--grey btn--sm min-w-120 mr-2"
                onClick={() => setOpen(false)}
                isDisabled={isLoading}
              />
              <CustomColorButton
                label="Rollback"
                className="btn btn--primary btn--sm min-w-120 "
                onClick={() => onConfirm(revertedData)}
                isLoading={isLoading}
              />
            </div>
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default RevertConfirmation;

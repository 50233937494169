import React from 'react';
// import { route } from 'utils/route';
import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';
import { ReactComponent as EmailSignatureIcon } from 'assets/icons/email-Signature-Icon.svg';
import { ReactComponent as EmailAdressIcon } from 'assets/icons/icon-email-address.svg';
import { ReactComponent as NudgesIcon } from 'assets/icons/icon-nudges.svg';
// import { ReactComponent as FilterIcon } from 'assets/icons/icon-channel-filter.svg';
import { ReactComponent as IconEbBox } from 'assets/icons/icon-sidebar-eb-box-active.svg';
import { ReactComponent as NotificationsIcon } from 'assets/icons/icon-notification.svg';
import SettingLink from 'modules/settings/components/settingLink';

const TeamChatSettingsMenu = ({ mainRoute }) => {
  const NavLinks = [
    {
      label: 'My profile',
      desc: 'Login details, email signature',
      icon: IconMyProfile,
      route: `${mainRoute}/my-settings/my-profile`
    },
    {
      label: 'Email reply address',
      desc: 'The email address you send replies from',
      icon: EmailAdressIcon,
      route: `${mainRoute}/my-settings/email-reply-address`
    },
    {
      label: 'Email signature',
      desc: 'Setup or edit your email signature',
      icon: EmailSignatureIcon,
      route: `${mainRoute}/my-settings/email-signature`
    },
    {
      label: 'Nudges',
      desc: 'Change what and when your get nudged',
      icon: NudgesIcon,
      route: `${mainRoute}/my-settings/nudges`
    },
    {
      label: 'Things to do',
      desc: 'Manage settings related to things to do',
      icon: IconEbBox,
      route: `${mainRoute}/my-settings/things-to-do`
    },
    {
      label: 'Notifications',
      desc: 'Change what, when and how you get notified',
      icon: NotificationsIcon,
      route: `${mainRoute}/my-settings/notifications`
    }
  ];

  return (
    <>
      {NavLinks.map((item, index) => {
        const { label, desc, route } = item;
        return (
          <SettingLink
            key={index}
            to={route}
            title={label}
            renderSvg={() => <item.icon />}
            description={desc}
          />
        );
      })}
    </>
  );
};

export default TeamChatSettingsMenu;

import React, { useRef, useState } from 'react';
import TaskItem from '../taskItem';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';
import useClickOutside from 'hooks/useOutsideClick';

const { CALL } = CHOICES.TasksTypeChoices;

const Call = () => {
  const dropdownRef = useRef();
  const { taskOutcome } = useSelector((state) => state.tasks);
  const [showMoreDropdown, setShowMoreDropdown] = useState(null);
  const [openEdit, setOpenEdit] = useState(null);

  // function to close the dropdown when clicks outside
  useClickOutside(() => setShowMoreDropdown(null), dropdownRef.current);

  return (
    <div className="pt-2" ref={dropdownRef}>
      {!isEmpty(taskOutcome) &&
        taskOutcome.map((item) => {
          const { options, task_type, id } = item;
          if (task_type === CALL) {
            return (
              <TaskItem
                key={id}
                title={options}
                id={id}
                setShowMoreDropdown={setShowMoreDropdown}
                showMoreDropdown={showMoreDropdown}
                setOpenEdit={setOpenEdit}
                openEdit={openEdit}
              />
            );
          }
        })}
    </div>
  );
};

export default Call;

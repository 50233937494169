/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { setShowSubscriptionModal } from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';
const right = {
  // bottom: '62px',
  // left: 'calc(50% - 54px)'
};
// let top = {
//   bottom: '34px',
//   left: 'calc(50% - 153px)'
// };
let left = {
  // bottom: '22px',
  // left: 'calc(50% - 130px)'
};
const UpgradePlanTooltip = (props) => {
  const dispatch = useDispatch();
  const {
    alignment,
    show,
    //  customStyle
    children
  } = props;

  // const handleStyle = (aligntype, customStyle) => {
  //   switch (aligntype) {
  //     case 'left':
  //       return { ...left, ...customStyle };
  //     case 'right':
  //       return { ...right, ...customStyle };
  //     default:
  //       return { ...top, ...customStyle };
  //   }
  // };
  return (
    <div
      className={
        alignment ? `upgrade-plan-tooltip-${alignment}` : 'upgrade-plan-tooltip'
      }
    >
      {show && (
        <div
          className={
            alignment
              ? `upgrade-plan-tooltip-${alignment}__container`
              : 'upgrade-plan-tooltip__container'
          }
          // style={handleStyle(alignment, customStyle)}
        >
          <div
            className={
              alignment
                ? `upgrade-plan-tooltip-${alignment}__body`
                : 'upgrade-plan-tooltip__body'
            }
          >
            <p className="text-sm font-semibold">Upgrade to use this feature</p>
            <p className="text-11">
              See what's included on the{' '}
              <span className="font-semibold text-blue-dark cursor-pointer">
                Pro Plan
              </span>
            </p>
          </div>
          <div
            className={
              alignment
                ? `upgrade-plan-tooltip-${alignment}__footer`
                : 'upgrade-plan-tooltip__footer'
            }
          >
            <button
              className="btn btn--primary btn--blue btn--xs w-full text-xs"
              onClick={() => dispatch(setShowSubscriptionModal(true))}
            >
              Get feature
            </button>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default UpgradePlanTooltip;
UpgradePlanTooltip.defaultProps = {
  customStyle: {},
  alignment: 'top',
  show: false
};

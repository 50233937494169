/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import SectionHeader from 'modules/dashboard/component/sections/header';
import SectionItem from 'modules/dashboard/component/sections';
import { ReactComponent as IconPlusGreen } from 'assets/icons/icon-plus-green.svg';
import { route } from 'utils/route';
import useClickOutside from 'hooks/useOutsideClick';
// import iconSales from 'assets/icons/icon-suitcase.svg';
import { ReactComponent as GroupIcon } from 'assets/icons/icon-dropdown-group.svg';
import { ReactComponent as HideIcon } from 'assets/icons/icon-dropdown-hide.svg';
import { getAllBoxes } from 'slices/boxes/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
// import AddCustomBoxModal from 'modules/dashboard/module/addCustomBox';
// import {  useParams } from 'react-router-dom';

const dropdownOptions = [
  {
    id: 1,
    icon: GroupIcon,
    title: 'Group box'
  },
  {
    id: 2,
    icon: HideIcon,
    title: 'Hide box'
  }
];

const BoxesSideNav = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { boxes } = useSelector((state) => state.boxes);
  const dropdownRef = useRef();
  const [open, setOpen] = useState(false);
  const { box_count } = useSelector((state) => state.allCount);

  const onClose = () => {
    setOpen(false);
  };
  // const {box_type,box_id} = useParams()

  // const to =`${route.dashboard}${route.boxes}/${box_type}/${box_id}`

  const [showBoxDropdown, setShowBoxDropdown] = useState(false);

  useEffect(() => {
    dispatch(getAllBoxes());
  }, []);

  useClickOutside(() => setShowBoxDropdown(false), dropdownRef.current);

  const getCount = (id) => {
    return box_count[id];
  };

  const handleAddCustomBox = () => {
    navigate(`${route.dashboard}/boxes${route.addBoxTemplate}`);
  };

  return (
    <div className="pt-4">
      <SectionHeader
        label="Boxes"
        onClick={() => setShowBoxDropdown(true)}
        isDropDown={true}
        showMoreIcon={true}
        open={showBoxDropdown}
        dropdownRef={dropdownRef}
        options={dropdownOptions}
        dropdownStyle={{
          minWidth: 196,
          width: 'initial',
          right: 0,
          padding: 8,
          top: 10
        }}
        dropDownClassName="dropdown-menu--more"
      />
      <div className="flex flex-col">
        {boxes?.map((box) => {
          return (
            <SectionItem
              key={box.id}
              to={`${route.dashboard}${route.boxes}/${box.type_of_box}/${box.id}`}
              icon={box.box_avatar}
              label={box.box_name}
              iconStyle={{ width: 20 }}
              iconString
              showCount
              count={getCount(box.id)}
            />
          );
        })}
      </div>
      <div
        className="dashboard__sidebar__nav-list items-center mt-1"
        onClick={() => setOpen(true)}
      >
        <div
          className="flex items-center cursor-pointer width-fill-available"
          onClick={handleAddCustomBox}
        >
          <IconPlusGreen />
          <p className="ml-4 text-sm font-medium text-primary">Add a box</p>
        </div>
      </div>
      {/* <AddCustomBoxModal open={open} onClose={onClose} /> */}
    </div>
  );
};

export default BoxesSideNav;

import React from 'react';
import PropTypes from 'prop-types';
import Avatar from 'components/avatar';

const Card = (props) => {
  const { name, icon } = props;
  return (
    <li className="post-login__card">
      <div className="post-login__card__container">
        <div className="flex justify-center">
          <Avatar src={icon} bigger={true} contain border />
        </div>
        <p className="text-13 font-medium text-center mt-15">{name}</p>
      </div>
    </li>
  );
};

Card.propTypes = {
  name: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default Card;

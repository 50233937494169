import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  GET_ALL_BOTS,
  LIST_AI_ASSISTANTS,
  PUBLISH_ASSISTANT,
  SAVE_ASSISTANT,
  CREATE_DUPLICATE_AI_ASSISTANT,
  DELETE_AI_ASSISTANT,
  LIST_ASSISTANT_VERSIONS,
  RESTORE_ASSISTANT_VERSION,
  AI_CHAT_CONVERSATION,
  ALL_BOT_DATA
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import { NOTHING } from 'utils/constants';
import { isEmpty } from 'utils/utils';
const DRAFT = CHOICES.BotVersionStatusChoices.DRAFT;
const initialState = {
  listTemplates: [],
  listAssistants: [],
  currentVersionId: '',
  versionStatus: DRAFT,
  errorMain: {},
  isError: {},
  personalityErrors: {},
  welcomeErrors: {},
  dataCollectionErrors: {},
  brainErrors: {},
  headerErrorCount: 0,
  versionList: [],
  botConversation: {
    history: [],
    leadId: null
  },
  allBotData: {},
  avatarList: [],
  isShowPopup: false,
  userDataThatBotNeeds: [],
  isShowTestBotPopup: false,
  messageDisplayed: false,
  dataCollectionType: '',
  suggestedReplies: []
};

const reducers = {
  setListTemplate: (state, action) => {
    state.listTemplates = action.payload;
  },
  setListAssistants: (state, action) => {
    state.listAssistants = action.payload;
  },
  setCurrentVersionId: (state, action) => {
    state.currentVersionId = action.payload;
  },
  setVersionStatus: (state, action) => {
    state.versionStatus = action.payload;
  },
  setErrorMain: (state, action) => {
    state.errorMain = action.payload;
    state.personalityErrors = action.payload.personality;
    state.welcomeErrors = action.payload.welcome;
    state.dataCollectionErrors = action.payload.data_collection;
    state.brainErrors = action.payload.brain;
  },
  setWelcomeErrors: (state, action) => {
    state.welcomeErrors = action.payload;
  },
  setPersonalityErrors: (state, action) => {
    state.personalityErrors = action.payload;
  },
  setDataCollectionErrors: (state, action) => {
    state.dataCollectionErrors = action.payload;
  },
  setBrainErrors: (state, action) => {
    state.brainErrors = action.payload;
  },
  setHeaderErrorCount: (state, action) => {
    state.headerErrorCount = action.payload;
  },
  setVersionList: (state, action) => {
    state.versionList = action.payload;
  },
  setBotConversation: (state, action) => {
    state.botConversation = action.payload;
  },
  setAllBotData: (state, action) => {
    state.allBotData = action.payload;
  },
  setAvatarList: (state, action) => {
    state.avatarList = action.payload;
  },
  setIsError: (state, action) => {
    state.isError = action.payload;
  },
  setUserDataThatBotNeeds: (state, action) => {
    state.userDataThatBotNeeds = action.payload;
  },
  setShowTestBotPopup: (state, action) => {
    state.isShowTestBotPopup = action.payload;
  },
  setMessageDispalayed: (state, action) => {
    state.messageDisplayed = action.payload;
  },
  setDataCollectionType: (state, action) => {
    state.dataCollectionType = action.payload;
  },
  setSuggestedReplies: (state, action) => {
    state.suggestedReplies = action.payload;
  }
};

export const createAi = createSlice({
  name: 'assistant',
  initialState,
  reducers
});

export const {
  setListTemplate,
  setListAssistants,
  setCurrentVersionId,
  setVersionStatus,
  setErrorMain,
  setWelcomeErrors,
  setPersonalityErrors,
  setDataCollectionErrors,
  setBrainErrors,
  setHeaderErrorCount,
  setVersionList,
  setBotConversation,
  setAllBotData,
  setAvatarList,
  setIsError,
  setUserDataThatBotNeeds,
  setShowTestBotPopup,
  setMessageDispalayed,
  setDataCollectionType,
  setSuggestedReplies
} = createAi.actions;

export default createAi.reducer;

export const getAssistants = (subOrgId) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(replaceUrl(LIST_AI_ASSISTANTS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setListAssistants(res.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getTemplates = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest().get(GET_ALL_BOTS).then((resp) => {
        dispatch(setListTemplate(resp.data));
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const saveAssistant = (successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    try {
      new APIRequest()
        .put(replaceUrl(SAVE_ASSISTANT, 'version_id', currentVersionId))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setErrorMain(resp.data));
            dispatch(setVersionStatus(resp?.data?.version_status));
          }
          if (successCb) successCb(resp.data);
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      dispatch(setErrorMain({}));
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const duplicateAssistant = (botId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { listAssistants } = getState().assistant;
    try {
      await new APIRequest()
        .post(replaceUrl(CREATE_DUPLICATE_AI_ASSISTANT, 'aiBotId', botId))
        .then((resp) => {
          dispatch(setListAssistants([...listAssistants, resp.data]));
          if (successCb) successCb(resp.data.version_bot.id);
        })
        .catch((e) => {
          if (failedCb) failedCb();
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const publishAssistant = (successCb, failedCb, assistant_id) => {
  return async (dispatch, getState) => {
    const { assistant, myInfo } = getState();
    const currentVersionId = assistant?.currentVersionId;
    const id = myInfo?.myInformation?.id;
    let payload = {
      published_by_id: id //loggedIn userid
    };
    try {
      new APIRequest()
        .post(
          replaceUrl(PUBLISH_ASSISTANT, 'version_id', currentVersionId),
          payload
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (successCb) {
              successCb(resp.data, assistant_id);
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      dispatch(setErrorMain({}));
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

export const deleteAiAssistant = (botId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { listAssistants } = getState().assistant;
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_AI_ASSISTANT, 'aiBotId', botId))
        .then((resp) => {
          const deletedAssistantId = resp.data.ai_bot_id;
          const updatedListAssistants = listAssistants.filter(
            (assistant) => assistant.version_bot.id !== deletedAssistantId
          );
          dispatch(setListAssistants(updatedListAssistants));
          if (successCb) successCb();
        })
        .catch((e) => {
          if (failedCb) failedCb();
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const getVersionList = (botId) => {
  return async (dispatch, getState) => {
    dispatch(setVersionList([]));
    try {
      await new APIRequest()
        .get(replaceUrl(LIST_ASSISTANT_VERSIONS, 'aiBotId', botId))
        .then((resp) => {
          dispatch(setVersionList(resp.data));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const restoreVersion = (payload, successCb, failedCb, assistant_id) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { listAssistants } = assistant;
    try {
      new APIRequest()
        .put(RESTORE_ASSISTANT_VERSION, payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setErrorMain({}));
            dispatch(setPersonalityErrors({}));
            dispatch(setWelcomeErrors({}));
            dispatch(setDataCollectionErrors({}));
            dispatch(setBrainErrors({})); // Error is empty to check if save is clicked
            dispatch(setHeaderErrorCount(0));
            let assistants = [...listAssistants];
            let newdata = assistants.map((item1, i) => {
              if (item1?.version_bot.id === assistant_id) {
                return {
                  ...item1,
                  ['id']: res.data.id,
                  version_status: res.data.version_status,
                  version_bot: {
                    ...res.data.version_bot
                  }
                };
              }
              return {
                ...item1
              };
            });
            dispatch(setListAssistants(newdata));
            dispatch(setCurrentVersionId(res.data?.id));
            dispatch(setVersionStatus(res?.data?.version_status));
            dispatch(getVersionList(res.data?.version_bot?.id));
            if (successCb) {
              successCb(res.data);
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

// this function returns chat history
export const getBotConversations = (assistant_id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      dispatch(
        setBotConversation({
          history: [],
          leadId: null
        })
      );
      new APIRequest()
        .get(replaceUrl(AI_CHAT_CONVERSATION, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBotConversation(res.data));
            if (successCb) {
              successCb(res.data, 'getBotConversations');
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (err) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};
export const startConversation = (
  assistant_id,
  payload,
  successCb,
  failedCb
) => {
  return async (dispatch, getState) => {
    try {
      new APIRequest()
        .post(
          replaceUrl(AI_CHAT_CONVERSATION, 'aiBotId', assistant_id),
          payload
        )
        .then((resp) => {
          if (resp.status === 200) {
            if (successCb) {
              successCb(payload, resp.data, 'startConversation');
            }
            if (resp.data.details !== NOTHING) {
              dispatch(setUserDataThatBotNeeds(resp.data.data_fields));
              dispatch(setDataCollectionType(resp.data.details));
              dispatch(setShowTestBotPopup(true));
            }
            if (!isEmpty(resp.data.suggested_replies)) {
              dispatch(setSuggestedReplies(resp.data.suggested_replies));
            }
          }
        })
        .catch((e) => {
          dispatch(setErrorMain({}));
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      dispatch(setErrorMain({}));
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

export const continueChatConversation = (
  assistant_id,
  payload,
  successCb,
  failedCb
) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { botConversation } = assistant;
    try {
      new APIRequest()
        .post(
          replaceUrl(AI_CHAT_CONVERSATION, 'aiBotId', assistant_id),
          payload
        )
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setMessageDispalayed(false));
            dispatch(setShowTestBotPopup(false));
            if (resp.data.details !== NOTHING) {
              dispatch(setUserDataThatBotNeeds(resp.data.data_fields));
              dispatch(setDataCollectionType(resp.data.details));
              dispatch(setShowTestBotPopup(true));
            }
            if (!isEmpty(resp.data.suggested_replies)) {
              dispatch(setSuggestedReplies(resp.data.suggested_replies));
            }
            let newdata;
            if (!Array.isArray(payload.answer)) {
              newdata = {
                ...botConversation,
                history: [
                  ...botConversation.history,
                  {
                    role: 'assistant',
                    message: resp.data.message
                  }
                ]
              };
            } else {
              const formattedData = payload.answer
                .map((item) => `<p>${item.field_name}: ${item.value}</p>`)
                .join('');
              newdata = {
                ...botConversation,
                history: [
                  ...botConversation.history,
                  {
                    role: 'user',
                    message: formattedData
                  },
                  {
                    role: 'assistant',
                    message: resp.data.message
                  }
                ]
              };
            }

            dispatch(setBotConversation(newdata));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((e) => {
          dispatch(setErrorMain({}));
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (e) {
      dispatch(setErrorMain({}));
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

export const startOverConversation = (assistant_id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      new APIRequest()
        .delete(replaceUrl(AI_CHAT_CONVERSATION, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            getBotConversations(assistant_id);
            if (successCb) {
              successCb(res.data, 'startOverConversation');
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (err) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};
// this function return all bot data like messages, options, etc
export const getAllBotData = (assistant_id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    try {
      dispatch(setAllBotData({}));
      new APIRequest()
        .get(replaceUrl(ALL_BOT_DATA, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAllBotData(res.data?.bot_data));
            if (successCb) {
              successCb(res.data?.bot_data, 'getAllBotData');
            }
          }
        })
        .catch((e) => {
          if (failedCb) {
            failedCb();
          }
          console.log(e);
        });
    } catch (err) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-green.svg';

const ChannelContactLink = (props) => {
  const { title, Subtitle, SubTitle2, plus } = props;

  return (
    <>
      {plus && (
        <div className="flex items-center cursor-pointer py-3">
          <figure
            className="bg-grey-90 flex justify-center items-center"
            style={{ width: 42, height: 42, borderRadius: '50%' }}
          >
            <PlusIcon width={12} height={12} />
          </figure>
          <p className="ml-4 text-15 font-semibold text-primary truncate">
            {title}
          </p>
        </div>
      )}
      {Subtitle && (
        <h2 className="text-13 font-medium text-grey-700 mt-2">{Subtitle}</h2>
      )}
      {SubTitle2 && (
        <h2 className="text-13 font-medium text-grey-700">{SubTitle2}</h2>
      )}
    </>
  );
};

ChannelContactLink.propTypes = {
  title: PropTypes.string,
  Subtitle: PropTypes.string,
  SubTitle2: PropTypes.string,
  plus: PropTypes.bool
};

export default ChannelContactLink;

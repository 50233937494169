import React from 'react';
import UKFlag from 'assets/images/uk-flag.png';
import SmsPlans from 'modules/toolbox/component/smsContent/plans';
import FAQ from 'modules/toolbox/component/faq';
import GuideLink from 'components/guideLink';

const SmsBilling = () => {
  return (
    <div className="h-full w-full">
      <div
        className="px-8 py-6"
        style={{
          height: 'calc(100% - 45px)',
          overflowY: 'auto'
        }}
      >
        <div style={{ maxWidth: '70%' }}>
          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <p className="text-16 font-semibold">SMS Bundles</p>
            </div>
            <div className="flex items-center py-2 px-2 bg-white border-2 border-grey-500 rounded-lg">
              <img src={UKFlag} alt="UK-flag" />
              <span className="text-sm font-medium ml-3">
                United Kingdom (+44)
              </span>
            </div>
          </div>
          <div className="mt-6 mb-5">
            <SmsPlans />
            <FAQ />
          </div>
          <GuideLink url="https://help.enquirybox.io/en/articles/8185103-get-enquiries-in-and-out-of-enquirybox-with-zapier" />
        </div>
      </div>
    </div>
  );
};

export default SmsBilling;

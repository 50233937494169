import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const Radio = (props) => {
  const {
    id,
    value,
    checked,
    small,
    medium,
    large,
    name,
    register,
    required,
    className,
    ...rest
  } = props;

  const getClassName = () => {
    if (small) return `${styles.custom_radio__sm} ${className}`;
    if (medium) return `${styles.custom_radio__md} ${className}`;
    if (large) return `${styles.custom_radio__lg} ${className}`;
  };
  return (
    <input
      type="radio"
      className={`${styles.custom_radio} ${getClassName()}`}
      id={id}
      value={value}
      checked={checked}
      name={name}
      {...register(name, { required })}
      {...rest}
    />
  );
};
Radio.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  required: PropTypes.bool,
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool
};
Radio.defaultProps = {
  register: () => {},
  className: '',
  required: false
};
export default Radio;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setSignUpChoice, setEmail, setCurrentUser } from 'slices/authSlice';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useSignUpMutation } from 'services/authApi';
import { SuccessToast, ErrorToast } from 'components/toast';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import Break from 'components/break/text';
import Google from 'modules/auth/login/google';
import Microsoft from 'modules/auth/login/microsoft';
import SignUpForm from './form';
import choices from 'choices';
import GoogleErrorDraft from 'modules/auth/login/google/errorDraft';
import ErrorRenderer from 'components/errorMessage/renderer';
import queryString from 'query-string';
import storageFactory, {
  getPartnerId,
  getPlanTypeKey
} from 'utils/localstorage';
import { isEmpty } from 'utils/utils';

const EmailSignUp = choices.SignupChoice.EMAIL;

const SignUp = () => {
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = queryString.parse(search);
  const [googleError, setGoogleError] = useState(null);
  const [
    signUp,
    {
      data: signUpData,
      isSuccess: isSignUpSuccess,
      isError: isSignUpError,
      error: signUpError,
      isLoading: isSignUpLoading
    }
  ] = useSignUpMutation();

  useEffect(() => {
    if (isSignUpSuccess && !isSignUpError) {
      onSignUpSuccess();
    }
    // if (isSignUpError) {
    //   signUpError?.data?.email.forEach((err) => {
    //     ErrorToast(err);
    //   });
    // }
  }, [isSignUpSuccess]);

  useEffect(() => {
    if (!isEmpty(queryParams) && queryParams.plan) {
      storageFactory().setItem(getPlanTypeKey(), queryParams.plan);
    }
    if (!isEmpty(queryParams) && queryParams.partner_id) {
      storageFactory().setItem(getPartnerId(), queryParams.partner_id);
    }
    if (isEmpty(queryParams)) {
      storageFactory().removeItem(getPlanTypeKey());
      storageFactory().removeItem(getPartnerId());
    }
    if (!isEmpty(queryParams) && !queryParams.partner_id) {
      storageFactory().removeItem(getPartnerId());
    }
    if (!isEmpty(queryParams) && !queryParams.plan) {
      storageFactory().removeItem(getPlanTypeKey());
    }
  }, []);

  const onSignUpSuccess = () => {
    SuccessToast(signUpData?.message);
    dispatch(setSignUpChoice(EmailSignUp));
    dispatch(setCurrentUser(signUpData.user_id));
    navigate('/email-confirmation');
  };

  const onSubmit = async (data) => {
    const { name, email, password } = data;
    const partnerId = storageFactory().getItem(getPartnerId());
    const formData = {
      first_name: name,
      email: email,
      password: password
    };
    if (partnerId) {
      formData['partner_id'] = partnerId;
    }
    dispatch(setEmail(email));
    await signUp(formData);
  };

  const onGoogleError = (err) => {
    if (err?.message) {
      setGoogleError(err);
    }
    console.log(err);
  };

  return (
    <HeaderLayout>
      <p className="text-2xl font-bold text-grey-900-secondary mb-5">Sign up</p>
      {/* <p className="text-lg font-medium text-grey-900-secondary mb-5">
        No credit card required
      </p> */}
      <CardLayout style={{ maxWidth: '502px' }}>
        <div className="w-full">
          {googleError && <GoogleErrorDraft error={googleError} />}
          {isSignUpError && (
            <div className="mt-2 mb-5">
              <ErrorRenderer
                className="mt-0"
                error={signUpError?.data?.email}
              />
            </div>
          )}
          <SignUpForm onSubmit={onSubmit} isLoading={isSignUpLoading} />
          <div className="mt-5 mb-10">
            <Break label="OR - 1 CLICK SIGN UP WITH" />
          </div>
          <div className="flex items-center justify-center w-full md:w-auto">
            <div className="w-1/2 pr-2">
              <Google signUp={true} onError={onGoogleError} />
            </div>
            <div className="w-1/2">
              <Microsoft signUp={true} />
            </div>
          </div>
        </div>
      </CardLayout>
      <div className="mt-8 text-sm z-10">
        Already have an account?{' '}
        <Link className="font-medium text-primary" to="/login">
          Login
        </Link>
      </div>
    </HeaderLayout>
  );
};

export default SignUp;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import { setShowSuccessPaymentModal } from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
const PaymentSuccess = ({ open, planMode }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  return (
    <Modal.Main className="modal--upgrade-notification show" open={open}>
      <div className="modal__body py-10">
        <h1 className="text-25 font-bold text-center">
          {planMode && planMode === 'upgrade'
            ? 'Your plan has been upgraded'
            : 'Your payment is successful'}
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            The payment is
            {planMode && planMode === 'upgrade' ? ' upgraded ' : ' successful '}
            for your monthly subscription.
          </p>
        </div>
        <div
          className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6"
          style={{
            marginRight: '25px',
            marginLeft: '25px'
          }}
        >
          <div className="mt-2">
            <CustomColorButton
              label="Go to Dashboard"
              onClick={() => {
                navigate(`${route.dashboard}${route.enquirybox}${route.box}`);
                dispatch(setShowSuccessPaymentModal(false));
              }}
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
            />
          </div>
        </div>
      </div>
    </Modal.Main>
  );
};

export default PaymentSuccess;

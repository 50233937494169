export const isFileValid = (file) => {
  const maxFileSize = 100 * 1024 * 1024;
  if (!file) {
    return {
      message: 'Please select a file',
      isValid: false
    };
  }
  const allowedFileTypes = [
    'application/pdf',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-powerpoint',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/msword'
  ];
  if (!allowedFileTypes.includes(file.type)) {
    return {
      message: 'Only PDF, Excel, PPT, and Word files are allowed.',
      isValid: false
    };
  }
  if (file.size > maxFileSize) {
    return {
      message: 'File size exceeds the maximum limit of 100MB',
      isValid: false
    };
  }
  return {
    message: 'Valid file',
    isValid: true
  };
};

/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setImagePreview, setItem } from 'slices/appSlice';
// import previewIcon from 'assets/icons/icon-Preview.svg';
// import downloadIcon from 'assets/icons/icon-download.svg';
// import deleteIcon from 'assets/icons/icon-Delete.svg';
import { getFileAttachedToLead } from 'slices/fileLibrary/slice';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';

import useClickOutside from 'hooks/useOutsideClick';

import Documents from './documents';

const Files = () => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const { cardId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [showMoreDropdown, setShowMoreDropdown] = useState(null);
  const [showAllImage, setShowAllImage] = useState(false);
  const { imageFileData, attachmentsFileData } = useSelector(
    (state) => state.fileLibrary
  );

  useClickOutside(() => setShowMoreDropdown(null), dropdownRef.current);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllFiles = (leadId) => {
    setIsLoading(true);
    dispatch(getFileAttachedToLead({ leadId: leadId, callback }));
  };

  useEffect(() => {
    loadAllFiles(cardId);
  }, []);

  const onOpenPreview = (item, index) => {
    dispatch(setImagePreview(item));
    dispatch(
      setItem({
        key: 'showImagePreview',
        value: true
      })
    );
    dispatch(
      setItem({
        key: 'previewImageIndex',
        value: index
      })
    );
  };

  const onMoreClick = () => {
    setShowAllImage(true);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {isEmpty(imageFileData) && isEmpty(attachmentsFileData) && (
        <div className="justify-center flex h-full mt-10 font-semibold">
          <p> No File Data </p>{' '}
        </div>
      )}
      {(!isEmpty(imageFileData) || !isEmpty(attachmentsFileData)) && (
        <div className="files">
          {!isEmpty(imageFileData) && (
            <div className="files__header">
              <div className="flex justify-between">
                <div className="font-semibold">Images</div>
                {/* <div
            className="text-primary font-medium text-13 cursor-pointer"
            onClick={() => setOpenUpload(true)}
          >
            + Upload file
          </div> */}
              </div>
              <div
                className={`files__header--image-container mt-2 ${
                  showAllImage ? 'overflow-x-auto custom-scrollbar-width' : ''
                }`}
              >
                {!isEmpty(imageFileData) && showAllImage && (
                  <>
                    {imageFileData.map((item, index) => {
                      return (
                        <img
                          onClick={() => onOpenPreview(item, index)}
                          src={item?.file_url}
                          alt=""
                          className="mr-1 cursor-pointer"
                          style={{
                            minWidth: 95,
                            minHeight: 98,
                            width: 95,
                            height: 98
                          }}
                          key={item.id}
                        />
                      );
                    })}
                  </>
                )}
                {!isEmpty(imageFileData) && !showAllImage && (
                  <>
                    {imageFileData.slice(0, 5).map((item, index) => {
                      return (
                        <img
                          onClick={() => onOpenPreview(item, index)}
                          src={item?.file_url}
                          alt=""
                          className="mr-1 cursor-pointer"
                          style={{ width: 95, height: 98 }}
                          key={item.id}
                        />
                      );
                    })}
                  </>
                )}
                {!isEmpty(imageFileData) &&
                  imageFileData.length > 5 &&
                  !showAllImage && (
                    <div className="files__more">
                      <div>
                        <p className="font-medium text-lg">
                          {imageFileData.length - 5}+
                        </p>
                        <p
                          className="font-medium text-xs cursor-pointer txt-blue"
                          onClick={onMoreClick}
                        >
                          More
                        </p>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          {!isEmpty(attachmentsFileData) && (
            <div
              className={`files__body ${isEmpty(imageFileData) ? 'mt-3' : ''}`}
              ref={dropdownRef}
            >
              <div className="pl-4 mb-3 font-semibold">Documents</div>
              {!isEmpty(attachmentsFileData) &&
                attachmentsFileData.map((item, index) => {
                  return (
                    <Documents
                      key={index}
                      item={item}
                      setShowMoreDropdown={setShowMoreDropdown}
                      showMoreDropdown={showMoreDropdown}
                    />
                  );
                })}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Files;

import { createSlice } from '@reduxjs/toolkit';

export const socialCredentialSlice = createSlice({
  name: 'social',
  initialState: {
    socialCredentials: null
  },
  reducers: {
    setSocialCredentials: (state, action) => {
      state.socialCredentials = action.payload;
    }
  }
});

export const { setSocialCredentials } = socialCredentialSlice.actions;

export default socialCredentialSlice.reducer;

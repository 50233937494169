import React, { useState, useEffect } from 'react';
import Modal from 'components/modal';
import TextInputField from 'components/inputs/textField';
import CHOICES from 'choices';
import ChatbotSelect from '../dashboard/assistant/components/chatbotSelect';
import {
  createAssistant,
  createAssistantFromTemplate
} from 'slices/ai-assistant/dataCollection/createAI/slice';
import { useDispatch, useSelector } from 'react-redux';
import ErrorMessage from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import {
  getAssistants,
  getVersionList,
  setErrorMain,
  setHeaderErrorCount
} from 'slices/ai-assistant/assistant/slice';
import { useNavigate } from 'react-router-dom';
import { resetPersonalityFields } from 'slices/ai-assistant/personality/slice';
import { urlFieldValidation } from 'components/fieldsAndValidation';

const CreateAIAssistant = ({ open, onClose, loading, template_id }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [businessType, setBusinessType] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [readOnly, setReadOnly] = useState(true);
  const [websiteUrl, setWebsiteUrl] = useState('');
  const [isError, setIsError] = useState({});
  const {
    IndustryChoices: { CHOICES: NEW_CHOICES, CHOICE_LIST }
  } = CHOICES;
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const handleOptionChange = (option) => {
    if (option.value === 'other') {
      setBusinessType({
        value: 8,
        label: ''
      });
    } else {
      setBusinessType(option.value);
      setIsError({
        ...isError,
        'Business Type': ''
      });
    }
  };

  useEffect(() => {
    if (!isEmpty(inputValue)) {
      setIsError({
        ...isError,
        'Business Type': ''
      });
    }
  }, [inputValue]);

  useEffect(() => {
    if (!isEmpty(websiteUrl)) {
      setIsError({
        ...isError,
        'Website Url': ''
      });
    }
  }, [websiteUrl]);
  const handlecreateAiResponse = (data) => {
    loading(false);
    onClose();
    dispatch(getAssistants(subOrganizationId));
    dispatch(setErrorMain({}));
    dispatch(setHeaderErrorCount(0));
    dispatch(getVersionList(data?.version_bot?.id));
    dispatch(resetPersonalityFields());
    navigate(`/chatbot-ai/assistant/${data?.version_bot?.id}`);
  };

  const handleFailedResponse = () => {
    loading(false);
    onClose();
  };
  const getIndustryName = () => {
    if (typeof businessType === 'number') {
      return NEW_CHOICES[businessType];
    } else {
      return '';
    }
  };
  const getOtherField = () => {
    if (
      typeof businessType === 'number' &&
      businessType === 8 &&
      !isEmpty(inputValue)
    ) {
      return true;
    }
    return false;
  };
  const checkOtherField = () => {
    if (
      typeof businessType === 'number' &&
      businessType === 8 &&
      isEmpty(inputValue)
    ) {
      return true;
    }
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (getIndustryName()?.length === 0 || checkOtherField()) {
      setIsError({
        ...isError,
        'Business Type': 'Business Type cannot be empty'
      });
    } else if (isEmpty(websiteUrl) && !checkOtherField()) {
      setIsError({
        'Business Type': '',
        'Website Url': 'Please add website url'
      });
    } else if (isEmpty(websiteUrl)) {
      setIsError({
        ...isError,
        'Website Url': 'Please add website url'
      });
    } else if (!urlFieldValidation.test(websiteUrl)) {
      setIsError({
        ...isError,
        'Website Url': 'Please provide valid website url'
      });
    } else {
      loading(true);
      onClose();
      let payload;
      let industry_name = '';
      if (getOtherField()) {
        industry_name = inputValue;
      } else {
        industry_name = getIndustryName();
      }
      if (!isEmpty(template_id)) {
        payload = {
          body: {
            template_id: template_id,
            sub_organization_id: subOrganizationId,
            industry_type:
              typeof businessType === 'number' ? businessType : null,
            industry_name,
            website_url: websiteUrl
          }
        };
        dispatch(
          createAssistantFromTemplate(
            payload,
            handlecreateAiResponse,
            handleFailedResponse
          )
        );
      } else {
        payload = {
          body: {
            sub_organization_id: subOrganizationId,
            industry_type:
              typeof businessType === 'number' ? businessType : null,
            industry_name,
            website_url: websiteUrl
          }
        };
        dispatch(
          createAssistant(payload, handlecreateAiResponse, handleFailedResponse)
        );
      }
    }
  };

  return (
    <Modal.Main open={open} className="modal--channels-request">
      <Modal.Header
        title="Create your AI assistant"
        onClose={() => {
          onClose();
          setIsError({});
        }}
      />
      <Modal.Body
        style={{
          padding: '25px 30px 30px',
          backgroundColor: 'white',
          borderBottomLeftRadius: '12px',
          borderBottomRightRadius: '12px'
        }}
      >
        <form onSubmit={handleSubmit}>
          <div>
            <h5 className="text-15 font-semibold">Select your Industry</h5>
            <p className="text-13 text-grey-800 mt-2">
              {"If you don't find your industry, select other and type it in"}
            </p>
            <div className="relative mt-2">
              <ChatbotSelect
                placeholder={
                  businessType.label === ''
                    ? 'Type your business type or industry here'
                    : 'Select your business type'
                }
                value={
                  businessType
                    ? businessType
                    : businessType?.label
                    ? businessType?.label
                    : businessType
                }
                setValue={setBusinessType}
                options={CHOICE_LIST}
                onChange={handleOptionChange}
                readOnly={readOnly}
                setReadOnly={setReadOnly}
                isSearchInsideDropDown={true}
                selectedIconStyle={{ width: 18, marginRight: 7, marginTop: 2 }}
                inputValue={inputValue}
                setInputValue={setInputValue}
                error={
                  !isEmpty(isError) && isError['Business Type'] ? true : false
                }
              />
            </div>
            {!isEmpty(isError) && isError['Business Type'] && (
              <ErrorMessage error={isError['Business Type']} />
            )}
          </div>
          <div className="mt-6">
            <h5 className="text-15 font-semibold">Add your website address</h5>
            <p className="text-13 text-grey-800 mt-2">
              This should be the website where you want to put the assistant to
              work.
              <span className="underline">
                Please add your actual website
              </span>{' '}
              as it will pull in the data from all pages.
            </p>
            <div className="mt-4">
              <TextInputField
                name="Website Url"
                className="input-field--md"
                placeholder="https://mywebsite.com"
                value={websiteUrl}
                onChange={(e) => setWebsiteUrl(e.target.value)}
                autoComplete="off"
                error={
                  !isEmpty(isError) && isError['Website Url'] ? true : false
                }
              />
            </div>
            {!isEmpty(isError) && isError['Website Url'] && (
              <ErrorMessage error={isError['Website Url']} />
            )}
          </div>
          <div className="flex justify-end mt-10">
            <button
              className="btn btn--secondary btn--md min-w-120"
              type="button"
              onClick={() => {
                onClose();
                setIsError({});
              }}
            >
              Cancel
            </button>
            <button
              className="btn btn--primary btn--primary--gradient btn--md min-w-120 ml-3"
              type="submit"
              // onClick={handleSubmit}
              disabled={
                isEmpty(websiteUrl) &&
                (checkOtherField() || isEmpty(businessType))
              }
            >
              Create ✨
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

export default CreateAIAssistant;

/* eslint-disable no-unused-vars */
import React from 'react';
import IconCheck from 'assets/icons/icon-check-black.svg';
import CustomToolTip from 'components/CustomTooltip';
import { updateLeadTaskStatus } from 'slices/task/slice';
import { useDispatch } from 'react-redux';
import choices from 'choices';
import { useSnackbar } from 'components/Snackbar';

const COMPLETED = choices.TaskStatusChoices.COMPLETED;

const Complete = ({ data, getLeadsConversation, isBin }) => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();

  const onComplete = () => {
    const payload = {
      task: data.id,
      status: COMPLETED
    };
    dispatch(updateLeadTaskStatus(payload, onSuccess));
  };

  const onSuccess = () => {
    getLeadsConversation();
    openSuccessSnackBar('Task Completed');
  };

  return (
    <CustomToolTip position="top" tooltipText="Complete Task">
      <div
        className="task-complete-btn"
        onClick={isBin ? () => {} : onComplete}
      >
        <img src={IconCheck} alt="complete" />
        <p>Complete</p>
      </div>
    </CustomToolTip>
  );
};

export default Complete;

import React from 'react';
import PropTypes from 'prop-types';

const Img = (props) => {
  const { src, ...rest } = props;
  return <img src={src} {...rest} />;
};

Img.propTypes = {
  src: PropTypes.string.isRequired
};

export default Img;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, Link } from 'react-router-dom';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { useDispatch } from 'react-redux';
import { requiredMsg } from 'utils/messages';
import { setAuthToken, setRedirect } from 'slices/authSlice';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import InputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import PasswordInput from 'components/inputs/password';
import Break from 'components/break/text';
import Error from 'components/errorMessage';
import Google from './google';
import Microsoft from './microsoft';
import { useUserLoginMutation } from 'services/authApi';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';
// import { SuccessToast } from 'components/toast';
import { redirectPostCreds } from 'utils/redirect';
import GoogleErrorDraft from 'modules/auth/login/google/errorDraft';
import { useSelector } from 'react-redux';

const Login = () => {
  const formSchema = {
    ...getFormSchema(['email']),
    password: string().required(requiredMsg('password'))
  };
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const [loginError, setLoginError] = useState(false);
  const watchEmail = watch('email');
  const watchPassword = watch('password');
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [
    userLogin,
    { data, isLoading, isSuccess, isError, error: loginsError }
  ] = useUserLoginMutation();

  useEffect(() => {
    return () => {
      setValue('email', '', { shouldTouch: false });
      setValue('password', '', { shouldTouch: false });
    };
  }, []);

  useEffect(() => {
    if (location?.state?.email) {
      setValue('email', location?.state?.email, { shouldTouch: true });
    }
  }, []);

  useEffect(() => {
    setLoginError(false);
  }, [watchEmail, watchPassword]);

  useEffect(() => {
    if (isSuccess) {
      onLoginSuccess();
    }
    if (isError) {
      setError(loginsError?.data?.detail);
    }
  }, [isSuccess, isError]);

  const onLoginSuccess = () => {
    dispatch(setAuthToken(data?.access));
    dispatch(setRedirect(data.frontend_redirect_uri));
    navigate(redirectPostCreds(data.frontend_redirect_uri));
  };

  const onSubmit = async (data) => {
    const { email, password } = data;
    const formData = {
      username: email,
      password
    };
    await userLogin(formData);
  };

  const onGoogleError = (err) => {
    if (err?.message) {
      setError(err);
    }
  };

  const RenderError = ({ error }) => {
    if (error) {
      if (error === 'No active account found with the given credentials') {
        return (
          <div className="mt-5">
            <Error
              className="flex justify-center"
              message="Your Email or Password you entered is incorrect. Please try again."
            />
          </div>
        );
      }
      if (
        error ===
        'This email was not verified, so use the forgot password option to confirm and reset your password.'
      ) {
        return (
          <div className="mt-5">
            <Error className="flex justify-center" message={error} />
          </div>
        );
      }
      if (error === 'Your account has been deleted.') {
        return (
          <div className="mt-5">
            <Error className="flex justify-center" message={error} />
          </div>
        );
      }
      return <GoogleErrorDraft error={error} />;
    }
    return <></>;
  };

  return (
    <HeaderLayout>
      <CardLayout className="mt-5">
        <CardTitle title="Login to enquirybox" />
        <div className="w-full">
          {error && <RenderError error={error} />}
          {loginError && (
            <div className="error-msg text-center font-normal my-8">
              “Your Email or Password you entered is incorrect. Please try
              again.”
            </div>
          )}
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-6">
              <InputField
                name="email"
                placeholder="Email"
                icon={true}
                renderIcon={() => <EmailIcon />}
                error={errors.email && touchedFields.email}
                register={register}
              />
            </div>
            <div className="mt-4">
              <PasswordInput
                name="password"
                placeholder="Enter Password"
                register={register}
              />
            </div>
            <PrimaryButton
              type="submit"
              label="Login"
              className="w-full mt-9"
              isLoading={isLoading}
              enableLoader={true}
            />
          </form>
        </div>
        <div className="mt-6 text-center">
          <NavLink
            to="/forgot-password"
            className="inline-block text-sm font-medium text-primary cursor-pointer"
          >
            Forgot your password?
          </NavLink>
        </div>
        <div className="mt-6 mb-10">
          <Break label="OR - 1 CLICK SIGN IN WITH" />
        </div>
        <div className="flex items-center justify-center w-full md:w-auto">
          <div className="w-1/2 pr-2">
            <Google signIn={true} onError={onGoogleError} />
          </div>
          <div className="w-1/2">
            <Microsoft />
          </div>
        </div>
      </CardLayout>
      <div className="mt-8 text-sm z-10">
        Don’t have an account?{' '}
        <Link className="font-medium text-primary" to="/sign-up">
          Sign up
        </Link>
      </div>
    </HeaderLayout>
  );
};

export default Login;

import React, { useState } from 'react';
import { ReactComponent as TextIcon } from 'assets/icons/icon-question-type-text-small.svg';
import QuestionHeader from '../../../../component/questionHeader';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { useParams } from 'react-router-dom';
import {
  AddQuestion,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm,
  setIsShowQuestionsToAdd,
  setSelectedQuestion,
  updateQuestion
} from 'slices/questionSlice';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'components/errorMessage';
import CHOICES from 'choices';
import { isEmpty } from 'utils/utils';
import { useAnalytics } from 'use-analytics';

// import { useSnackbar } from 'components/Snackbar';

const Text = (props) => {
  const { data, addQuestion } = props;
  const dispatch = useDispatch();
  const { box_id } = useParams();
  // const { openSuccessSnackBar } = useSnackbar();
  const { questionsData, isEdit, editedData } = useSelector(
    (state) => state.question
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [questionText, setQuestionText] = useState(
    isEdit && !isEmpty(editedData) ? editedData.text : ''
  );
  const [isError, setIsError] = useState('');
  const { TEXT } = CHOICES.QuestionChoices;
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const onCancel = () => {
    dispatch(setIsEdit(false));
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setEditedData({}));
  };

  const updateCallback = (data) => {
    track(
      'question_updated',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(setIsEdit(false));
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    dispatch(setEditedData({}));
  };

  const callback = (data) => {
    track(
      'question_created',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    addQuestion(data);
    // openSuccessSnackBar('Question created');
  };

  const onSave = (e) => {
    e.preventDefault();
    if (questionText === '') {
      setIsError('Question cannot be empty');
    } else {
      setIsLoading(true);
      if (isEdit) {
        const formData = {
          text: questionText,
          position: editedData.position,
          kind: editedData.kind
        };
        dispatch(
          updateQuestion({
            body: formData,
            questionId: editedData.id,
            callback: updateCallback
          })
        );
      } else {
        const formData = {
          text: questionText,
          position: questionsData.length,
          kind: TEXT,
          choice_set: [],
          sub_organization: subOrganizationId,
          box: box_id
        };
        dispatch(AddQuestion({ body: formData, callback }));
      }
    }
  };

  return (
    <div className="mt-8 border border-secondary border-radius-10 bg-grey-300 w-full">
      <QuestionHeader label={data.label} renderSvg={() => <TextIcon />} />
      <form className="p-5" autoComplete="off">
        <h3 className="text-sm font-semibold">Question</h3>
        <TextInputField
          name="text"
          className="mt-2 input-field--xs-34"
          placeholder="Type your question here"
          value={questionText}
          onChange={(e) => {
            setIsError('');
            setQuestionText(e.target.value);
          }}
          error={!isEmpty(isError) ? true : false}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSave(e);
            }
          }}
        />
        {isError && <Error message={isError} />}
        <div className="flex justify-end mt-8 gap-2">
          <CustomColorButton
            label="Cancel"
            className="btn--grey min-w-120 btn--md"
            onClick={onCancel}
          />
          <PrimaryButton
            label="Save"
            className="min-w-120 btn--md"
            enableLoader
            isLoading={isLoading}
            onClick={(e) => onSave(e)}
          />
        </div>
      </form>
    </div>
  );
};

export default Text;

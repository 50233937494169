import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import { getNameInitial } from 'utils/getNameInitial';
import { ReactComponent as ActiveIcon } from 'assets/icons/icon-team-chat-active.svg';

const Avatar = (props) => {
  const {
    src,
    className,
    cover,
    contain,
    border,
    mini,
    smallest,
    smaller,
    small,
    medium,
    large,
    larger,
    bigger,
    imgStyle,
    style,
    superSrc,
    superClassName,
    superStyle,
    initial,
    outerRing,
    initialStyle,
    singleChar,
    isOnline,
    activeIconStyle,
    medium42,
    renderOuterIcon
  } = props;

  const getContainer = () => {
    if (cover) return styles.full;
    if (contain) return styles.contain;
    return '';
  };

  const getBorder = () => {
    if (border) return styles.border;
    return '';
  };

  const getSize = () => {
    if (bigger) return styles.bigger;
    if (larger) return styles.larger;
    if (large) return styles.large;
    if (small) return styles.small;
    if (smaller) return styles.smaller;
    if (smallest) return styles.smallest;
    if (mini) return styles.mini;
    if (medium42) return styles.medium_42;
    if (medium) return styles.medium;
    return '';
  };
  return (
    <figure
      className={`${styles.avatar_container} ${
        initial && !src ? styles.bgcolor : ''
      } ${getContainer()} ${getBorder()} ${getSize()} ${className}`}
      style={style}
      data-testid="avatar"
    >
      {src?.includes('https://') || src?.includes('.svg') ? (
        <img
          data-testid="avatar-img"
          src={src}
          alt="avatar"
          style={imgStyle}
          referrerPolicy="no-referrer"
        />
      ) : (
        <div
          style={{ fontSize: '18px' }}
          dangerouslySetInnerHTML={{ __html: src }}
        ></div>
      )}
      {initial && !src && (
        <p
          data-testid="avatar-initial"
          className={`text-xl font-bold text-white ${styles.initial_div} ${
            !singleChar ? styles.initial : ''
          }`}
          style={initialStyle}
        >
          {getNameInitial(initial, singleChar)}
        </p>
      )}
      {superSrc && (
        <div className={`${styles.sup_icon} ${superClassName}`}>
          <img
            data-testid="avatar-super"
            src={superSrc}
            alt="super"
            style={superStyle}
          />
        </div>
      )}
      {outerRing && (
        <div data-testid="avatar-outer-ring" className={styles.outer_circle} />
      )}
      {isOnline && (
        <div className="avatar-container__sup-icon--bottom absolute">
          <ActiveIcon style={activeIconStyle} />
        </div>
      )}
      {renderOuterIcon &&
        typeof renderOuterIcon === 'function' &&
        renderOuterIcon()}
    </figure>
  );
};

Avatar.propTypes = {
  className: PropTypes.string,
  superClassName: PropTypes.string,
  style: PropTypes.object,
  imgStyle: PropTypes.object,
  cover: PropTypes.bool,
  contain: PropTypes.bool,
  border: PropTypes.bool,
  mini: PropTypes.bool,
  smallest: PropTypes.bool,
  smaller: PropTypes.bool,
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  larger: PropTypes.bool,
  bigger: PropTypes.bool,
  outerRing: PropTypes.bool,
  singleChar: PropTypes.bool,
  medium42: PropTypes.bool
};

Avatar.defaultProps = {
  className: '',
  cover: false,
  contain: true,
  border: true,
  smaller: false,
  smallest: false,
  mini: false,
  small: false,
  medium: true,
  large: false,
  larger: false,
  bigger: false,
  outerRing: false,
  superClassName: '',
  singleChar: false,
  medium42: false
};

export default Avatar;

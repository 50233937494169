/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { renameStage } from 'slices/boxes/slice';
import { useParams } from 'react-router-dom';
import BoxDeleteConfirmation from 'modules/boxes/stages/stage/deleteStageModal';
import RenderTopBlock from './renderTopBlock';
import ConversionWarning from 'components/alerts/emptyFieldAlert';
const isRename = 'rename';
const isDelete = 'delete';

const Header = (props) => {
  const {
    name,
    count,
    stageId,
    isIncoming,
    isConversaionStage,
    isPreview,
    stage
  } = props;
  const [selectedOption, setSelectedOption] = useState(null);
  const [showConversionAlert, setShowConversionAlert] = useState(false);
  const dispatch = useDispatch();
  const { box_id } = useParams();

  const onError = () => {};

  const onSuccess = () => {
    setSelectedOption(null);
  };

  const onStageNameSubmit = (value) => {
    dispatch(
      renameStage(
        {
          stageId: stageId,
          body: {
            stage_name: value,
            box: box_id
          }
        },
        onSuccess,
        onError
      )
    );
  };

  const onStageNameClose = () => {
    setSelectedOption(null);
  };

  return (
    <>
      <BoxDeleteConfirmation
        showConfirmation={selectedOption === isDelete}
        onCancel={() => {
          setSelectedOption(null);
        }}
        stage_id={stageId}
        hasAutomation={stage?.total_no_of_automation_count > 0}
      />
      <ConversionWarning
        showConfirmation={showConversionAlert}
        onClose={() => setShowConversionAlert(false)}
        title="Warning"
        description="Another stage is already marked as a conversion stage, Please disable the other conversion stage in order to enable this stage as a conversion stage"
      />
      <div className={`flex justify-between px-4 ml-0 mb-3 pl-5`}>
        {
          <RenderTopBlock
            isEdit={isRename === selectedOption}
            isDelete={isDelete}
            isRename={isRename}
            setSelectedOption={setSelectedOption}
            onSubmit={onStageNameSubmit}
            onStageNameClose={onStageNameClose}
            name={name}
            isIncoming={isIncoming}
            isConversaionStage={isConversaionStage}
            stageId={stageId}
            setShowConversionAlert={setShowConversionAlert}
            count={count}
            isPreview={isPreview}
            stage={stage}
          />
        }
      </div>
    </>
  );
};

Header.defaultProps = {
  name: '',
  count: 0,
  isPreview: false
};
export default Header;

/* eslint-disable no-unused-vars */
import React, { useState, useRef } from 'react';
import Modal from 'components/modal';
import iconHand from 'assets/icons/icon-hand.svg';
import Avatar from 'components/avatar';
import SubIcon from 'modules/enquirybox/ebox/module/box/components/enquiryCard/subIcon';
import iconChannelHeader from 'assets/icons/icon-channels-header.svg';
import iconEmojiFire from 'assets/icons/icon-emoji-fire.svg';
import iconClose from 'assets/icons/icon-modal-close.svg';
import { SEARCH_LEADS } from 'urls';
import APIRequest from 'apiRequest';
import { useSelector } from 'react-redux';
import { replaceUrl } from 'utils/urlReplace';
import { debounce, without } from 'lodash';
import { isEmailValid, isEmpty } from 'utils/utils';
import Loader from 'components/loader';
import { getChannelDescription } from 'modules/enquirybox/helper/lead';
import choices from 'choices';
import { useNavigate } from 'react-router';
import { getColorShade } from 'utils/colors';
import { removeSignatureFromContent } from 'utils/dom';
const { JUST_TO_STORE_DATA } = choices.LeadReplyChoice;
const { MOVED_TO_BIN } = choices.LeadStatusChoices;

const formatMessage = (message) => {
  if (message) {
    return message.replaceAll('*', '');
  }
  return '';
};

export const getLastReply = ({ last_message, channel }) => {
  if (!last_message) {
    return getChannelDescription(channel);
  }
  if (!isEmpty(last_message?.attachments) && !last_message?.message) {
    return 'File';
  }
  if (last_message?.reply_type == JUST_TO_STORE_DATA) {
    return getChannelDescription(channel);
  }
  const updatedString = removeSignatureFromContent(last_message?.message);
  return formatMessage(updatedString?.replace(/(<([^>]+)>)/gi, ''));
  // return last_message?.message?.replace(/(<([^>]+)>)/gi, '');
};

const SearchModal = ({ open, onClose }) => {
  // const [searchKeyWord, setSearchKeyWord] = useState('')
  const [searchList, setSearchList] = useState([]);
  const [boxKeys, setBoxKeys] = useState([]); // holds the keys of boxes
  const [boxList, setBoxList] = useState({}); // holds the list of leads based of box keys
  const [incomingSearchList, setIncomingSearchList] = useState([]); // holds the incoming leads
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const inputRef = useRef();

  const onSearch = debounce(async (e) => {
    const { value } = e.target;
    const query = {
      search_parameter: value
    };
    setIsLoading(true);
    await new APIRequest()
      .get(replaceUrl(SEARCH_LEADS, 'subOrgId', subOrganizationId), query)
      .then((res) => {
        if (res.status === 200) {
          extractData(res.data);
        }
      });
  }, 1000);

  const generateBoxLeads = (keys, response) => {
    let obj = {};
    keys.forEach((key) => {
      const box_array = extractBoxData(key, response);
      obj[key] = box_array;
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    return obj;
  };

  const extractBoxData = (key, response) => {
    const current_obj = response[key];
    const res_keys = Object.keys(current_obj);
    let arr = [];
    res_keys.forEach((k) => {
      arr = [...arr, ...current_obj[k]];
    });
    return arr;
  };

  const extractData = (response) => {
    if (typeof response === 'object') {
      const res_keys = Object.keys(response);
      const incoming = response['Incoming'];
      if (!isEmpty(incoming)) {
        setIncomingSearchList(incoming);
      } else {
        setIncomingSearchList([]);
      }
      const remainingKeys = without(res_keys, 'Incoming');
      setBoxKeys(remainingKeys);
      const box_list = generateBoxLeads(remainingKeys, response);
      setBoxList(box_list);
    }
  };

  const EmptyState = () => {
    return (
      <div
        className="flex flex-col items-center justify-center"
        style={{ padding: '145px 170px' }}
      >
        <img
          src={iconHand}
          style={{ transform: 'scaleX(-1) rotate(130deg)' }}
          width={48}
          height={49}
        />

        <p className="font-semibold mt-4 text-center">
          Search by name, email, phone number or any question that has been
          filled in
        </p>
      </div>
    );
  };

  const onClear = async () => {
    if (inputRef) {
      inputRef.current.value = '';
    }
    setIncomingSearchList([]);
    setBoxKeys([]);
    setBoxList({});
  };

  const RenderLead = ({ item, index }) => {
    const navigate = useNavigate();

    const onLeadRedirect = () => {
      if (item.box) {
        navigate(
          `/dashboard/boxes/${item.box.type_of_box}/${item.box.id}/${item.box_stage}/${item.channel}/${item.id}?search=true`
        );
        onClear();
        onClose();
        return;
      }
      if (item.status !== MOVED_TO_BIN) {
        navigate(
          `/dashboard/enquirybox/eb-box/lead/${item.channel}/${item.id}?search=true`
        );
        onClear();
        onClose();
      }
    };

    return (
      <div
        className="search-list bg-color-on-hover bg-color-on-hover--sm py-3"
        onClick={onLeadRedirect}
      >
        <div className="flex items-center truncate">
          <div className="relative">
            <Avatar
              src={item.customer_avatar}
              initial={item.name || item.first_name}
              style={{
                width: 48,
                height: 48,
                backgroundColor: item.customer_avatar
                  ? ''
                  : getColorShade(index)
              }}
              large
              cover
              singleChar
            />
            <div
              className="bg-white absolute flex justify-center items-center"
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                bottom: '-3px',
                left: '28px'
              }}
            >
              <SubIcon kind={item.channel} />
            </div>
          </div>
          <div className="flex-1 truncate ml-4">
            <h3 className="font-semibold letterspacing24em truncate">
              {item.name || item.first_name}
            </h3>
            <p
              className="text-15 letterspacing24em truncate"
              dangerouslySetInnerHTML={{
                __html: formatMessage(getLastReply(item))
              }}
            />
          </div>
        </div>
      </div>
    );
  };
  const RenderIcomingItems = ({ data }) => {
    if (isEmpty(data)) {
      return <></>;
    }
    return (
      <div className="search-results-container">
        <div className="flex items-center">
          {/* <img
            src={iconChannelHeader}
            alt=""
            style={{ maxWidth: 14, maxHeight: 14 }}
          /> */}
          <span className="inline-block text-grey-800 text-sm font-semibold ml-2">
            Incoming
          </span>
        </div>
        <div className="mt-2">
          {data.map((item, idx) => (
            <RenderLead key={item.id} item={item} index={idx} />
          ))}
        </div>
      </div>
    );
  };

  const RenderBoxItems = ({ data, title }) => {
    if (isEmpty(data)) return <></>;

    return (
      <div className="search-results-container">
        <div className="flex items-center">
          {/* <img
            src={iconChannelHeader}
            alt=""
            style={{ maxWidth: 14, maxHeight: 14 }}
          /> */}
          <span className="inline-block text-grey-800 text-sm font-semibold ml-2">
            {title}
          </span>
        </div>
        <div className="mt-2">
          {data?.map((lead, idx) => (
            <RenderLead key={lead.id} item={lead} index={idx} />
          ))}
        </div>
      </div>
    );
  };

  const checkIsEmptyBox = (list) => {
    const values = Object.values(list);
    const x = values.some((value) => !isEmpty(value));
    return !x;
  };

  const checkIsResultEmpty = () => {
    if (inputRef.current) {
      return isEmpty(inputRef.current.value);
    }
    return true;
  };

  return (
    <Modal.Main
      className="modal--search-options"
      open={open}
      mainStyle={{ zIndex: 999999 }}
    >
      <div className="modal__header relative">
        <input
          type="text"
          className="search-input"
          placeholder="Search"
          // value={searchKeyWord}
          onChange={onSearch}
          ref={inputRef}
        />
        <span className="btn-clear" onClick={onClear}>
          Clear
        </span>
      </div>
      <div className="modal__body relative" style={{ height: '100vh' }}>
        {checkIsResultEmpty() &&
          isEmpty(incomingSearchList) &&
          checkIsEmptyBox(boxList) && <EmptyState />}
        {!checkIsResultEmpty() &&
          isEmpty(incomingSearchList) &&
          checkIsEmptyBox(boxList) &&
          !isLoading && (
            <div
              className="flex justify-center items-center"
              style={{ height: '100%' }}
            >
              <p>No Result Found</p>
            </div>
          )}
        {isLoading && (
          <div className="modal-loader">
            <Loader />
          </div>
        )}
        <div className="search-results-wrapper">
          <RenderIcomingItems data={incomingSearchList} />
          {!isEmpty(boxKeys) &&
            boxKeys.map((item) => {
              return (
                <RenderBoxItems key={item} data={boxList[item]} title={item} />
              );
            })}

          {/* <div className="search-results-container">
            <div className="flex items-center">
              <img
                src={iconEmojiFire}
                alt=""
                style={{ maxWidth: 14, maxHeight: 14 }}
              />
              <span className="inline-block text-grey-800 text-sm font-semibold ml-2">
                Post sales
              </span>
            </div>
            <div className="mt-2">
              {searchData.map((item, idx) => (
                <div
                  key={idx}
                  className="search-list bg-color-on-hover bg-color-on-hover--sm py-3"
                >
                  <div className="flex items-center truncate">
                    <div className="relative">
                      <Avatar
                        src={item.img}
                        initial=""
                        style={{ width: 48, height: 48 }}
                        large
                        cover
                        singleChar
                      />
                      <div
                        className="bg-white absolute flex justify-center items-center"
                        style={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          bottom: '-3px',
                          left: '28px'
                        }}
                      >
                        <SubIcon />
                      </div>
                    </div>
                    <div className="flex-1 truncate ml-4">
                      <h3 className="font-semibold letterspacing24em truncate">
                        {item.title}
                      </h3>
                      <p className="text-15 letterspacing24em truncate">
                        {item.desc}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div> */}
        </div>
      </div>
      <div
        className="modal__edge-close-btn"
        onClick={() => {
          onClose();
          onClear();
        }}
      >
        <img src={iconClose} width={8} height={8} />
        <span className="text-primary text-13 font-medium ml-2">Close</span>
      </div>
    </Modal.Main>
  );
};

SearchModal.defaultProps = {
  onClose: () => {}
};

export default SearchModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TextInputField from 'components/inputs/textField';
import QuestionHeader from '../../../../component/questionHeader';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-question-dropdown-small.svg';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-modal-close.svg';
import { ReactComponent as AddIcon } from 'assets/icons/icon-plus-green.svg';
import CustomColorButton from 'components/buttons/customColorButton';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  AddQuestion,
  getAllAnswer,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm,
  setIsShowQuestionsToAdd,
  setSelectedQuestion,
  updateQuestion
} from 'slices/questionSlice';
import PrimaryButton from 'components/buttons/primary';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import CHOICES from 'choices';
import moveIcon from 'assets/icons/moveIcon.svg';
import { useAnalytics } from 'use-analytics';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const Dropdown = (props) => {
  const { data, isSettings, addQuestion } = props;
  const dispatch = useDispatch();
  const { box_id, cardId } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { questionsData, isEdit, editedData } = useSelector(
    (state) => state.question
  );
  const [isLoading, setIsLoading] = useState(false);
  const [optionsData, setOptionsData] = useState(
    isEdit && !isEmpty(editedData)
      ? editedData.choice_set.map((item) => {
          return {
            id: `option-${item.position + 1}`,
            label: `Option ${item.position + 1}`,
            value: item.text,
            choice_id: item.id
          };
        })
      : []
  );
  const [totalOptionData, setTotalOptionsData] = useState(
    isEdit && !isEmpty(editedData)
      ? editedData.choice_set.map((item) => {
          return {
            id: `option-${item.position + 1}`,
            label: `Option ${item.position + 1}`,
            value: item.text,
            choice_id: item.id
          };
        })
      : []
  );
  const [noOptionError, setNoOptionError] = useState('');
  const [noValueError, setNoValueError] = useState([]);
  const [isError, setIsError] = useState('');
  const [questionText, setQuestionText] = useState(
    isEdit && !isEmpty(editedData) ? editedData.text : ''
  );
  const [showMoveIcon, setShowMoveIcon] = useState(null);
  const [, setIsDragStarted] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [selectedOptionToRemove, setSelectedOptionToRemove] = useState(null);
  const { DROPDOWN } = CHOICES.QuestionChoices;
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const onCancel = () => {
    dispatch(setIsEdit(false));
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setEditedData({}));
  };

  useEffect(() => {
    if (!isEmpty(optionsData)) {
      setNoOptionError('');
    }
  }, [optionsData]);

  useEffect(() => {
    if (isEmpty(optionsData)) {
      setNoValueError([]);
    }
  }, [optionsData]);

  const callback = (data) => {
    track(
      'question_created',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    addQuestion(data);
    // openSuccessSnackBar('Question created');
  };

  const updateCallback = (data) => {
    track(
      'question_updated',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    setIsLoading(false);
    dispatch(getAllAnswer({ leadId: cardId }));
    dispatch(setIsEdit(false));
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    dispatch(setEditedData({}));
  };

  const onSave = (e) => {
    e.preventDefault();
    if (questionText === '') {
      setIsError('Question cannot be empty');
    } else {
      if (isEmpty(optionsData)) {
        setNoOptionError('Add atleast one option');
      } else {
        const isValueEmpty = optionsData.filter(
          (element) => element.value === ''
        );
        if (!isEmpty(isValueEmpty)) {
          const errorOption = optionsData.map((option) => {
            return isValueEmpty.some((item) => {
              if (item.id === option.id) {
                return true;
              } else {
                return false;
              }
            });
          });
          setNoValueError(errorOption);
        } else {
          setIsLoading(true);

          if (isEdit) {
            const formData = {
              text: questionText,
              position: editedData.position,
              kind: editedData.kind,
              choices: optionsData.map((item, index) => {
                return {
                  id: item.choice_id,
                  text: item.value,
                  position: index
                };
              })
            };
            dispatch(
              updateQuestion({
                body: formData,
                questionId: editedData.id,
                callback: updateCallback
              })
            );
          } else {
            const formData = {
              text: questionText,
              position: questionsData.length,
              kind: DROPDOWN,
              choice_set: optionsData.map((item, index) => {
                return {
                  text: item.value,
                  position: index
                };
              }),
              sub_organization: subOrganizationId,
              box: box_id
            };
            dispatch(AddQuestion({ body: formData, callback }));
          }
        }
      }
    }
  };

  const onAddOption = () => {
    const formData = {
      id: `option-${totalOptionData.length + 1}`,
      label: `Option ${totalOptionData.length + 1}`,
      value: '',
      choice_id: ''
    };
    setTotalOptionsData([...totalOptionData, formData]);
    setOptionsData([...optionsData, formData]);
  };

  const handleChange = (e, index) => {
    const value = e.target.value;
    optionsData[index].value = value;
    setOptionsData(optionsData);
    if (!isEmpty(noValueError)) {
      setNoValueError([
        ...noValueError.slice(0, index),
        false,
        ...noValueError.slice(index + 1)
      ]);
    }
  };

  const onRemoveOption = (id) => {
    setShowWarning(true);
    setSelectedOptionToRemove(id);
  };

  const onConfirm = () => {
    setShowWarning(false);
    const filteredOptions = optionsData.filter(
      (item) => item.id !== selectedOptionToRemove
    );
    setOptionsData(filteredOptions);
  };

  const onDragStart = () => {
    setIsDragStarted(true);
  };

  const onDragEnd = (result) => {
    setIsDragStarted(false);
    const { destination, source } = result;
    if (!destination) return;
    if (source.index !== destination.index) {
      let tempOptionsData = optionsData;
      const [removed] = tempOptionsData.splice(source.index, 1);
      tempOptionsData.splice(destination.index, 0, removed);
      setOptionsData(tempOptionsData);
      const isValueEmpty = optionsData.filter(
        (element) => element.value === ''
      );
      if (!isEmpty(isValueEmpty) && !isEmpty(noValueError)) {
        const errorOption = optionsData.map((option) => {
          return isValueEmpty.some((item) => {
            if (item.id === option.id) {
              return true;
            } else {
              return false;
            }
          });
        });
        setNoValueError(errorOption);
      }
    }
  };

  return (
    <>
      {showWarning && (
        <DeleteConfirmation
          showConfirmation={showWarning}
          title="Deleting this option will remove it from any past enquiries where it was selected."
          description="Are you sure you want to continue?"
          mainStyle={
            isSettings ? { top: '30%', maxHeight: 315 } : { maxHeight: 340 }
          }
          onCancel={() => setShowWarning(false)}
          onConfirm={onConfirm}
        />
      )}
      <div className="mt-8 border border-secondary border-radius-10 bg-grey-300 w-full">
        <QuestionHeader label={data.label} renderSvg={() => <DropdownIcon />} />
        <form className="p-5 relative" autoComplete="off">
          <h3 className="text-sm font-semibold">Question</h3>
          <TextInputField
            name="question"
            className="mt-2 input-field--xs-34"
            placeholder="Type your question here"
            value={questionText}
            onChange={(e) => {
              setIsError('');
              setQuestionText(e.target.value);
            }}
            error={!isEmpty(isError) ? true : false}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSave(e);
              }
            }}
          >
            {isError && <Error message={isError} />}
          </TextInputField>
          <div className="relative">
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {!isEmpty(optionsData) &&
                      optionsData.map((item, index) => (
                        <Draggable
                          key={item.id}
                          draggableId={item.id}
                          index={index}
                        >
                          {(provided, snapshot) => {
                            if (snapshot.isDragging) {
                              const offset = !isSettings
                                ? { x: 850, y: 300 }
                                : { x: 0, y: 0 };
                              const x =
                                provided.draggableProps.style.left - offset.x;
                              const y =
                                provided.draggableProps.style.top - offset.y;
                              provided.draggableProps.style = {
                                ...provided.draggableProps.style,
                                left: x,
                                top: y
                              };
                            }
                            return (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                onMouseEnter={() => setShowMoveIcon(index)}
                                onMouseLeave={() => setShowMoveIcon(null)}
                              >
                                <h3 className="text-sm font-semibold mt-3">
                                  {item.label}
                                </h3>
                                <div className="flex items-center">
                                  {showMoveIcon === index && (
                                    <img
                                      src={moveIcon}
                                      alt="icon-channel"
                                      className="mt-2"
                                      style={{
                                        width: '10px',
                                        height: '16px',
                                        position: 'absolute',
                                        left: '-13px'
                                      }}
                                    />
                                  )}
                                  <div className="flex items-center gap-4 mt-2">
                                    <TextInputField
                                      className="input-field--xs-34 input-field--responsive-xs-34"
                                      name={item.label}
                                      placeholder="Type option"
                                      defaultValue={item.value}
                                      onChange={(e) => handleChange(e, index)}
                                      error={noValueError[index]}
                                      onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                          onSave(e);
                                        }
                                      }}
                                    />
                                    <CloseIcon
                                      className="cursor-pointer"
                                      onClick={() => onRemoveOption(item.id)}
                                    />
                                    {noValueError[index] && (
                                      <Error message="Add option value" />
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          }}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div
            className="flex items-center mt-4 gap-2 cursor-pointer max-w-max"
            onClick={onAddOption}
          >
            <AddIcon />
            <p className="text-sm font-semibold text-primary">Add option</p>
          </div>
          {noOptionError && <Error message={noOptionError} />}
          <div className="flex justify-end mt-5 gap-2">
            <CustomColorButton
              label="Cancel"
              className="btn--grey min-w-120 btn--md"
              onClick={onCancel}
            />
            <PrimaryButton
              label="Save"
              className="min-w-120 btn--md"
              onClick={(e) => onSave(e)}
              isLoading={isLoading}
              enableLoader
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default Dropdown;

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import {
  SIGN_UP_INVITED_USER_NEW,
  UPDATE_OR_DELETE_ROLE_FOR_ORGANIZATION_USER,
  REINVITE_ORGANIZATION_USER,
  SIGN_UP_INVITED_USER_EXISTING
} from 'urls/teamMember';

export const teamMemberSlice = createSlice({
  name: 'teamMember',
  initialState: {
    usersList: {}
  },
  reducers: {
    setUsersList: (state, action) => {
      state.usersList = action.payload;
    }
  }
});
export const { setUsersList } = teamMemberSlice.actions;

export default teamMemberSlice.reducer;

export const signUpNewInvitedUser = (payload) => {
  return async (dispatch) => {
    try {
      const { body, subOrgId, callback, errorCallback } = payload;
      new APIRequest()
        .post(replaceUrl(SIGN_UP_INVITED_USER_NEW, 'subOrgId', subOrgId), body)
        .then((resp) => {
          if (callback) {
            callback(resp);
          }
        })
        .catch((err) => {
          if (err?.response?.data?.email) {
            errorCallback(err?.response?.data?.email[0]);
          } else {
            errorCallback(err?.response?.data.error);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const signUpExistingInvitedUser = (payload) => {
  return async (dispatch) => {
    try {
      const { body, subOrgId, callback, errorCallback } = payload;
      new APIRequest()
        .post(
          replaceUrl(SIGN_UP_INVITED_USER_EXISTING, 'subOrgId', subOrgId),
          body
        )
        .then((resp) => {
          if (callback) {
            callback();
          }
        })
        .catch((err) => {
          errorCallback(err?.response?.data?.error);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateRoleForOrganizationUser = (payload) => {
  return async (dispatch) => {
    try {
      const { body, subOrgId, OrgUserId, callback } = payload;
      new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_OR_DELETE_ROLE_FOR_ORGANIZATION_USER,
            ['subOrgId', 'OrgUserId'],
            [subOrgId, OrgUserId]
          ),
          body
        )
        .then((res) => {
          if (callback) {
            callback(res?.data?.role);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteOrganizationUser = (payload) => {
  return async (dispatch) => {
    try {
      const { body, subOrgId, OrgUserId, callback } = payload;
      new APIRequest()
        .delete(
          replaceUrl(
            UPDATE_OR_DELETE_ROLE_FOR_ORGANIZATION_USER,
            ['subOrgId', 'OrgUserId'],
            [subOrgId, OrgUserId]
          ),
          body
        )
        .then((res) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const reInviteUser = (payload) => {
  return async (dispatch) => {
    try {
      const { body, subOrgId, OrgUserId, callback } = payload;
      new APIRequest()
        .post(
          replaceUrl(
            REINVITE_ORGANIZATION_USER,
            ['subOrgId', 'OrgUserId'],
            [subOrgId, OrgUserId]
          ),
          body
        )
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

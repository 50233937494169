import React, { useEffect, useState } from 'react';
import { isEmpty } from 'utils/utils';
import moment from 'moment';

const AIChatBotContainer = (props) => {
  const { data, isLastreply, conversation, avatarUrl, index } = props;
  const [isBotMessage, setIsBotMessage] = useState(false);

  useEffect(() => {
    if (data.is_reply_sent) setIsBotMessage(true);
    else setIsBotMessage(false);
  }, []);

  let avatar =
    'https://screenshots-demo-eb.s3-eu-west-1.amazonaws.com/botimage.svg';

  const isShowReplySentAvatar =
    !data.is_reply_received &&
    data.is_reply_sent &&
    moment(conversation[index]?.created_at).format('mm') !==
      moment(conversation[index - 1]?.created_at).format('mm')
      ? true
      : !data.is_reply_received &&
        data.is_reply_sent &&
        conversation[index]?.is_reply_received !==
          conversation[index - 1]?.is_reply_received
      ? true
      : false;

  return (
    <>
      {isBotMessage && (
        <div
          className="chat-message-list chat-message-list--sm flex"
          style={{ marginBottom: 25 }}
        >
          {isShowReplySentAvatar ? (
            <figure
              className="general-settings__card-list__avatar-container"
              style={{ maxHeight: 32, maxWidth: 32, marginRight: 6 }}
            >
              <img
                src={avatarUrl ? avatarUrl : avatar}
                alt="profile-pic"
                loading="lazy"
                style={{
                  objectFit: 'cover',
                  height: '100%',
                  width: '100%'
                }}
              />
            </figure>
          ) : (
            <div style={{ width: 32, height: 32, marginRight: 6 }} />
          )}
          <div className="flex w-[80%]">
            <div
              className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-grey-90"
              style={{
                borderRadius: 10,
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 15,
                paddingRight: 15
              }}
              dangerouslySetInnerHTML={{ __html: data?.message }}
            />
          </div>
        </div>
      )}
      {!isBotMessage && (
        <div
          className="chat-message-list chat-message-list--sm chat-message-list--sender flex"
          style={
            isLastreply && isEmpty(data?.message)
              ? { marginBottom: 80 }
              : { marginBottom: 25 }
          }
        >
          <div className="flex justify-end w-[80%]">
            <div
              className="inline-block text-base-1 text-grey-900 border border-grey-400 bg-automation"
              style={{
                borderRadius: 10,
                paddingTop: 6,
                paddingBottom: 6,
                paddingLeft: 15,
                paddingRight: 15
              }}
            >
              {data?.message}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AIChatBotContainer;

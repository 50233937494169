import React from 'react';
import { fileIcons, zapierFileIcons } from 'components/constants';
import iconDownload from 'assets/icons/icon-download.svg';
import iconTrash from 'assets/icons/icon-trash.svg';
import CustomToolTip from 'components/CustomTooltip';
import { useSelector } from 'react-redux';
import CHOICES from 'choices';
import DownloadLink from 'components/downloadLink';

const FBLEADS = CHOICES.ChannelChoices.FACEBOOK_LEAD_ADS;
const ZAPIER = CHOICES.LeadChannelChoice.ZAPIER;

const AttachmentItem = ({
  attachment = { name: '' },
  message = true, //if this component is used in conversation message keep this as true. If you're in editor and sending a message keep this as false
  isZapierMessage = false //if the reply came from zapier as a first lead reply
}) => {
  const { activeLead } = useSelector((state) => state.leadConversation);
  // const [showIcon, setShowIcon] = useState(false);
  let splittedName =
    activeLead?.channel === FBLEADS
      ? attachment?.file_name?.split('.') || []
      : attachment?.file_url?.split('.') || [];
  return (
    <div className="attachment-item cursor-pointer flex items-center px-4 py-2 bg-white">
      <img
        className="file-icon"
        src={
          activeLead?.channel === ZAPIER && isZapierMessage
            ? zapierFileIcons[attachment.mime_type]
            : fileIcons[splittedName?.slice(-1)[0]]
        }
        style={{ width: 19, height: 24 }}
        alt="file"
      />
      <p
        className="truncate text-13 text-grey-900 ml-1 mr-1"
        style={{ flex: 1 }}
      >
        {attachment?.file_name}
      </p>
      {message ? (
        <CustomToolTip position="top" tooltipText="download">
          <DownloadLink to={attachment?.file_url}>
            <img
              className={`secondary-icon secondary-icon__visible`}
              src={iconDownload}
              alt="download"
            />
          </DownloadLink>
        </CustomToolTip>
      ) : (
        <img
          className={`secondary-icon secondary-icon__visible`}
          src={iconTrash}
          alt="remove"
        />
      )}
    </div>
  );
};

export default AttachmentItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import Select from 'components/inputs/select';
import Avatar from 'components/avatar';

const getBoxData = (boxes, id, box_id) => {
  const box = boxes.find((box) => box.id === id);
  if (!box) return null;
  if (box_id === id)
    return {
      label: `${box.box_name} (current box)`,
      value: box.id,
      icon: box.box_avatar
    };
  return {
    label: box.box_name,
    value: box.id,
    icon: box.box_avatar
  };
};

const getBoxStage = (boxes, box_id, stage_id) => {
  if (!box_id) return null;
  const box = boxes.find((box) => box.id === box_id);
  if (!box) return null;
  const stage = box.boxstage_set.find((stage) => stage.id === stage_id);
  if (stage)
    return {
      label: stage.stage_name,
      value: stage.id
    };
  return null;
};

const MoveToBox = ({ condition, index }) => {
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const { boxes } = useSelector((state) => state.boxes);
  const { data } = condition;
  const [moveBox, setMoveBox] = useState(
    getBoxData(boxes, data.move_to_box, box_id)
  );
  const [moveStage, setMoveStage] = useState(
    getBoxStage(boxes, data.move_to_box, data.move_to_stage)
  );
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );

  const boxes_list = boxes.map((box) => {
    if (box.id === box_id)
      return {
        value: box.id,
        label: `${box.box_name} (current box)`,
        icon: box.box_avatar
      };
    return { value: box.id, label: box.box_name, icon: box.box_avatar };
  });

  const box_stages = useMemo(() => {
    if (moveBox) {
      const box = boxes.find((box) => box.id == moveBox.value);
      if (box) {
        return box.boxstage_set.map((stage) => {
          return { value: stage.id, label: stage.stage_name };
        });
      }
      return [];
    }
  }, [moveBox?.value]);

  const errors = automationErrors[index];

  const updatedThen = (value) => {
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        ...value
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  const onSelectBox = (opt) => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.move_to_box) {
      let result = omit(errorObj[index], ['move_to_box']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    setMoveBox(opt);
    setMoveStage(null);
    updatedThen({ move_to_box: opt.value, move_to_stage: null });
  };

  const onSelectStage = (opt) => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.move_to_stage) {
      let result = omit(errorObj[index], ['move_to_stage']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    setMoveStage(opt);
    updatedThen({ move_to_stage: opt.value });
  };

  return (
    <div className="relative mt-4">
      <form autoComplete="off">
        <Select
          name="automation-then"
          options={boxes_list}
          renderIcon={
            moveBox
              ? () => (
                  <Avatar
                    className="mr-2"
                    src={moveBox?.icon}
                    border={false}
                    full
                    style={{ top: '-2px', left: '-10px', width: 25 }}
                  />
                )
              : null
          }
          onChange={(value) => onSelectBox(value)}
          placeholder="Select the box to move to"
          dropDownClassName="p-2"
          inputClassName="input-field--md pl-10"
          style={!isEmpty(moveBox) ? { paddingLeft: '45px' } : {}}
          selectedIconStyle={{ width: 18, marginRight: 7 }}
          isAvatarSelect={false}
          iconStyle={{ width: 18, marginRight: 12, marginTop: -3 }}
          value={moveBox?.label}
          selectedValue={moveBox}
          isHaveIcon
        />
        {errors?.move_to_box && <ErrorMessage message={errors?.move_to_box} />}
        {moveBox && (
          <div className="mt-4">
            <Select
              name="automation-then"
              options={box_stages}
              onChange={(value) => onSelectStage(value)}
              placeholder="Select the stage to move to"
              dropDownClassName="p-2"
              inputClassName="input-field--md"
              isAvatarSelect={false}
              value={moveStage ? moveStage?.label : ''}
              selectedValue={moveStage}
            />
          </div>
        )}
      </form>
      {errors?.move_to_stage && (
        <ErrorMessage message={errors?.move_to_stage} />
      )}
    </div>
  );
};

export default MoveToBox;

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import { TOMORROW, CALL } from 'utils/constants';
import { LEAD_TASK, LEAD_UPDATE_TASK, LEAD_UPDATE_TASK_STATUS } from 'urls';
import {
  GET_ALL_NEW_TASKS,
  GET_ALL_TASK_OUTCOME,
  GET_ALL_COMPLETED_TASKS
} from 'urls/tasks';
import CHOICES from 'choices';
import { getTomorrowDate } from 'modules/enquirybox/ebox/module/box/conversation/footer/task/footer/helper';

export const taskSlice = createSlice({
  name: 'task',
  initialState: {
    completedTasks: [],
    tasks: [],
    taskDateType: TOMORROW,
    taskDate: null,
    taskTime: '9:00am',
    taskTitle: '',
    assignUser: {},
    editableTaskId: null,
    taskType: CHOICES.TasksTypeChoices.CALL,
    taskOutcome: [],
    allNewTasks: [],
    allCompletedTasks: [],
    taskTypeTitle: 'Call'
  },
  reducers: {
    setTaskDateType: (state, action) => {
      state.taskDateType = action.payload;
    },
    setTaskDate: (state, action) => {
      state.taskDate = action.payload;
    },
    setTaskTime: (state, action) => {
      state.taskTime = action.payload;
    },
    setTaskTitle: (state, action) => {
      state.taskTitle = action.payload;
    },
    setAssignUser: (state, action) => {
      state.assignUser = action.payload;
    },
    setEditableTaskId: (state, action) => {
      state.editableTaskId = action.payload;
    },
    resetTaskStore: (state) => {
      const date = getTomorrowDate();
      state.taskDateType = TOMORROW;
      state.taskDate = new Date(date);
      state.taskTime = '9:00am';
      state.taskTitle = '';
      state.assignUser = {};
      state.editableTaskId = null;
      state.taskTypeTitle = 'Call';
      state.taskType = CHOICES.TasksTypeChoices.CALL;
    },
    setTaskType: (state, action) => {
      state.taskType = action.payload;
    },
    setCompletedTasks: (state, action) => {
      state.completedTasks = action.payload;
    },
    setTasks: (state, action) => {
      state.tasks = action.payload;
    },
    setTaskOutcome: (state, action) => {
      state.taskOutcome = action.payload;
    },
    setAllNewTasks: (state, action) => {
      state.allNewTasks = action.payload;
    },
    setAllCompletedTasks: (state, action) => {
      state.allCompletedTasks = action.payload;
    },
    setTaskTypeTitle: (state, action) => {
      state.taskTypeTitle = action.payload;
    }
  }
});

export const {
  setTaskDateType,
  setTaskDate,
  setTaskTime,
  setTaskTitle,
  setAssignUser,
  setEditableTaskId,
  resetTaskStore,
  setTaskType,
  setTaskOutcome,
  setAllNewTasks,
  setAllCompletedTasks,
  setTaskTypeTitle
} = taskSlice.actions;

export default taskSlice.reducer;

export const addLeadTask = (payload, successCb, errorCb) => {
  return (dispatch) => {
    try {
      new APIRequest()
        .post(LEAD_TASK, payload)
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((e) => {
          if (errorCb) {
            errorCb();
          }
          console.log(e);
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const updateLeadTask = (payload, successCb, errorCb) => {
  return (dispatch) => {
    const { taskId, data } = payload;
    try {
      new APIRequest()
        .put(replaceUrl(LEAD_UPDATE_TASK, 'taskId', taskId), data)
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
          if (errorCb) {
            errorCb();
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const updateLeadTaskStatus = (payload, successCb) => {
  return (dispatch) => {
    try {
      new APIRequest()
        .post(LEAD_UPDATE_TASK_STATUS, payload)
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteLeadTask = (payload, successCb) => {
  return (dispatch) => {
    const { taskId } = payload;
    try {
      new APIRequest()
        .delete(replaceUrl(LEAD_UPDATE_TASK, 'taskId', taskId))
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to call get method of task outcome api
export const getAllTaskOutcome = (payload) => {
  return (dispatch) => {
    const { subOrgId, task_type } = payload;
    try {
      const query = {
        suborganization_id: subOrgId,
        task_type: task_type
      };
      new APIRequest().get(GET_ALL_TASK_OUTCOME, query).then((res) => {
        if (res.status === 200) {
          dispatch(setTaskOutcome(res.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to call put method of task outcome api to change the name
export const updateTaskOutcome = (payload, successCb) => {
  return (dispatch) => {
    try {
      new APIRequest()
        .put(GET_ALL_TASK_OUTCOME, payload)
        .then((res) => {
          if (successCb) {
            successCb(res.data);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to call get method of new tasks in lead
export const getAllNewTasks = (payload, successCb) => {
  return (dispatch) => {
    const { leadId } = payload;
    try {
      const query = {
        lead_id: leadId
      };
      new APIRequest().get(GET_ALL_NEW_TASKS, query).then((res) => {
        if (res.status === 200) {
          dispatch(setAllNewTasks(res.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

// function to call get method of completed tasks in lead
export const getAllCompletedTasks = (payload, successCb) => {
  return (dispatch) => {
    const { leadId } = payload;
    try {
      const query = {
        lead_id: leadId
      };
      new APIRequest().get(GET_ALL_COMPLETED_TASKS, query).then((res) => {
        if (res.status === 200) {
          dispatch(setAllCompletedTasks(res.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

import React from 'react';
// import { ReactComponent as IconToolBox } from 'assets/icons/toolbox-icon-gray.svg';
// import BackWithBackground from 'components/back/withBackground';
// import CustomToolTip from 'components/CustomTooltip';
import GuideDropdown from 'components/guideDropdown';
import { channelGuideOptions } from 'utils/guide';
// import { useSelector } from 'react-redux';
// import { NavLink } from 'react-router-dom';
import { ReactComponent as IconChannels } from 'assets/icons/icon-navbar-channels.svg';

const ChannelHeader = () => {
  // const { previousRoute } = useSelector((state) => state.channels);

  return (
    <div className="dashboard__main__header" style={{ padding: '20px 24px' }}>
      <div className="flex items-center">
        {/* <CustomToolTip position="bottom" tooltipText="Go back">
          <NavLink to={previousRoute || `/`}>
            <BackWithBackground />
          </NavLink>
        </CustomToolTip> */}
        <figure
          className="avatar-container avatar-container--contain"
          style={{
            width: 48,
            height: 48,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <IconChannels height={22} width={22} className="toolboxheader" />
        </figure>
        <h1 className="text-2xl font-semibold ml-4">Channels</h1>
      </div>
      <div className="flex justify-end">
        <div className="flex items-center">
          <GuideDropdown
            description="Channels are the various ways in which your business receives enquiries online."
            guideOptions={channelGuideOptions}
            dropDownWrapperStyle={{
              right: '26px'
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ChannelHeader;

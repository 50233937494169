/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import Quill from 'components/quill';
import { useSelector } from 'react-redux';
import { ReactComponent as CaretUp } from 'assets/icons/icon-select-caret-up.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
// import { ReactComponent as CloseIcon } from 'assets/icons/icon-close-black.svg';
import InlineEmail from './inlineEmail';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty, isEditorEmpty } from 'utils/utils';
import APIRequest from 'apiRequest';
import { LEADS_REPLY } from 'urls';
import { useParams, useNavigate } from 'react-router-dom';
import choices from 'choices';
import { useSnackbar } from 'components/Snackbar';
import {
  changeLeadStatus,
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads,
  setActiveNewLead
} from 'slices/eboxLead';
import { useDispatch } from 'react-redux';
import {
  setActiveLead,
  getLeadData,
  handleChangeSignature
} from 'slices/conversationSlice';
import {
  setCcEmail,
  setBccEmail,
  setSelectedFromEmail,
  setSubject,
  setEmailBody,
  setAttachments,
  setIsForceClose,
  setIsSoftClose
} from 'slices/editor/slice';
import { setAllEditorState } from 'slices/editor/slice';
import {
  getStageLeads,
  composeStageLeads,
  changeBoxLeadStatus
} from 'slices/boxes/slice';
import { useAnalytics } from 'use-analytics';
import { route } from 'utils/route';

const EMAIL_REPLY = choices.LeadReplyChoice.EMAIL_REPLY;
const REPLY_LATER = choices.LeadStatusChoices.REPLY_LATER;
const VIEWED = choices.LeadStatusChoices.VIEWED;
const NEW = choices.LeadStatusChoices.NEW;

const ReplyEmail = (props) => {
  const dispatch = useDispatch();
  const { onClose, onReplySentSuccess, isFooterExpanded, draft } = props;
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const navigate = useNavigate();
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { id: currentUser, reply_sending_mail_address: userEmail } =
    useSelector((state) => state.myInfo.myInformation);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const {
    ccEmail,
    bccEmail,
    selectedFromEmail,
    subject,
    emailBody,
    attachments
  } = useSelector((state) => state.editor);

  const [isLoading, setIsLoading] = useState(false);
  const [showFromUsers, setShowFromUsers] = useState(false);
  const [showCcInput, setShowCcInput] = useState(false);
  const [showBccInput, setShowBccInput] = useState(false);
  const [selectedUserSignature, setSelectedUserSignature] = useState('');
  const [error, setError] = useState({});
  const [sectionTopHeight, setSectionTopHeight] = useState('83');
  const [editor, setEditorRef] = useState(null);
  const userEmailRef = useRef();
  const sectionTopRef = useRef();
  const emailBodyRef = useRef();
  const footerBodyRef = useRef();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { activeNewLead } = useSelector((state) => state.leads);

  useClickOutside(() => setShowFromUsers(false), userEmailRef?.current);

  useEffect(() => {
    dispatch(
      setSelectedFromEmail({
        user_id: currentUser,
        email: userEmail
      })
    );
  }, []);

  useEffect(() => {
    if (!isEmpty(activeLead?.previous_sent_email_subject)) {
      dispatch(setSubject(activeLead?.previous_sent_email_subject));
    }
  }, [activeLead]);

  useEffect(() => {
    if (!isEmpty(ccEmail)) setShowCcInput(true);
    if (!isEmpty(bccEmail)) setShowBccInput(true);
  }, [ccEmail, bccEmail]);

  useEffect(() => {
    if (editor) {
      dispatch(
        handleChangeSignature(currentUser, editor, (data, signature) => {
          dispatch(setEmailBody(data));
          setSelectedUserSignature(signature);
        })
      );
    }
  }, [editor]);

  const setFromEmail = (user) => {
    dispatch(setSelectedFromEmail(user));
    setSelectedUserSignature('');
    dispatch(
      handleChangeSignature(user.user_id, editor, (data, signature) => {
        dispatch(setEmailBody(data));
        setSelectedUserSignature(signature);
      })
    );
    setShowFromUsers(false);
  };

  const updateLeadStatusIfInReplyLater = async () => {
    if (activeLead.status === REPLY_LATER) {
      const data = {
        lead: cardId,
        is_current: true,
        status: VIEWED
      };
      if (!isBox) {
        // await Promise.all([
        //   dispatch(changeLeadStatus({ body: data })),
        //   dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        // ]);
      }
      if (isBox) {
        await Promise.all([
          dispatch(changeBoxLeadStatus({ body: data, stageId })),
          dispatch(setActiveLead({ ...activeLead, status: VIEWED }))
        ]);
      }
    }
  };

  const onSuccess = () => {
    track(
      'reply_sent',
      {
        reply_type: 'Email'
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(
      setAllEditorState({
        emailBody: '',
        subject: '',
        ccEmail: [],
        bccEmail: [],
        attachments: []
      })
    );
    dispatch(setIsForceClose(false));
    dispatch(setIsSoftClose(false));
    updateLeadStatusIfInReplyLater();
    dispatch(setActiveLead({ ...activeLead, draft_reply: {} }));
    openSuccessSnackBar('Reply sent');
    updateLeadCardsData();
    setIsLoading(false);
    onClose();
    onReplySentSuccess();
  };

  const checkIsValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(selectedFromEmail)) {
      err = {
        ...err,
        fromEmail: 'Select from user'
      };
      isValid = false;
    }
    if (isEmpty(subject)) {
      err = {
        ...err,
        subject: 'Please add subject'
      };
      isValid = false;
    }
    if (isEditorEmpty(emailBody)) {
      err = {
        ...err,
        emailBody: 'Please add message'
      };
      isValid = false;
    }
    /**
     * Need to remove all spaces, new line characters and dom content from
     * the email body and the signature to check whether there are any content inside it
     */
    let cleanMailText = emailBody ? emailBody.split('\n').join('').trim() : '';
    cleanMailText = cleanMailText.split('&nbsp;').join('');
    cleanMailText = cleanMailText
      ? cleanMailText.replace(/(<([^>]+)>)/gi, '')
      : '';
    cleanMailText = cleanMailText.split(' ').join('').trim();
    let cleanUserSignature = selectedUserSignature
      ? selectedUserSignature.replace(/(<([^>]+)>)/gi, '')
      : '';
    cleanUserSignature = cleanUserSignature.split('\n').join('').trim();
    cleanUserSignature = cleanUserSignature.split(' ').join('').trim();
    cleanUserSignature = cleanUserSignature.split('&nbsp;').join('').trim();

    if (
      cleanMailText.trim() === cleanUserSignature.trim() &&
      (cleanMailText || cleanUserSignature)
    ) {
      err = {
        ...err,
        emailBody: 'Please add message'
      };
      isValid = false;
    }
    setError(err);
    return isValid;
  };
  const onSendEmail = async () => {
    setIsLoading(true);
    const isValid = checkIsValid();
    if (!isValid) {
      setIsLoading(false);
      return;
    }
    let data = {
      message: emailBody.replaceAll(/<p/g, '<div').replaceAll(/<\/p/g, '</div'),
      lead: cardId,
      subject,
      cc: ccEmail,
      bcc: bccEmail,
      message_sent_by: selectedFromEmail.user_id,
      to: activeLead.email,
      add_attachments: attachments.map((attachment) => {
        const file = {};
        (file['file_name'] = attachment.file_name),
          (file['file_url'] = attachment.file_url);
        return file;
      }),
      reply_type: EMAIL_REPLY,
      is_reply_sent: true
    };
    if (activeLead?.draft_reply?.is_in_draft_state) {
      data = {
        ...data,
        is_in_draft_state: false,
        lead_reply_id: activeLead?.draft_reply?.id
      };
    }
    await new APIRequest()
      .post(LEADS_REPLY, data)
      .then((res) => {
        onSuccess();
      })
      .catch((err) => {
        console.log({ err });
        setIsLoading(false);
        openErrorSnackBar('Error');
      });
  };

  useEffect(() => {
    if (sectionTopRef && footerBodyRef) {
      const clientHeight = sectionTopRef.current.clientHeight;
      setSectionTopHeight(clientHeight);
    }
  }, [sectionTopRef.current, showCcInput, showBccInput]);

  const onAttachmentChanges = (attachments) => {
    dispatch(setAttachments(attachments));
  };

  // updating the cards status because after the sending the reply right side card data should update
  const updateLeadCardsData = async () => {
    if (!isBox) {
      // if (VIEWED === activeLead?.status) {
      //   dispatch(getReadLeads());
      // }
      // if (REPLY_LATER === activeLead?.status) {
      //   dispatch(getReplyLaterLeads());
      //   //updating the status even after calling the reply later api status is not updating
      // }
      // if (activeNewLead === activeLead?.id) {
      //   dispatch(setActiveNewLead());
      //   dispatch(getUnReadLeads());
      //   dispatch(getReadLeads());
      // }
      // navigate(`${route.dashboard}${route.enquirybox}/eb-box`);
    }
    if (isBox) {
      if (activeLead.status !== REPLY_LATER) {
        await dispatch(getStageLeads({ stageId }))
          .then((res) => {
            dispatch(composeStageLeads({ stageId, results: res.data }));
            dispatch(getLeadData({ leadId: cardId }));
          })
          .catch((err) => {
            console.log();
          });
      }
    }
  };

  const onUseTemplate = (subject, body, attachments) => {
    dispatch(setSubject(subject));
    dispatch(
      setEmailBody(
        `${body}<br/><br/>${
          selectedUserSignature
            ? `<div id="user-signature" class="ebox-noneditable" contenteditable="false">${selectedUserSignature}</div>`
            : ''
        }`
      )
    );
    dispatch(setAttachments(attachments));
  };
  return (
    <div
      className="chat-message-footer__body"
      ref={footerBodyRef}
      style={{ height: 'calc(100% - 36px)' }}
    >
      <div className="chat-reply">
        <div className="section-top" ref={sectionTopRef}>
          <div
            className="info-block space"
            style={
              error.fromEmail
                ? {
                    borderColor: '#ff0000'
                  }
                : {}
            }
          >
            <div className="flex items-center justify-center">
              <span className="label mr-3">From</span>
              <div className="from-email relative">
                <section
                  className="flex items-center justify-center cursor-pointer"
                  onClick={() => setShowFromUsers(!showFromUsers)}
                >
                  {isEmpty(selectedFromEmail) && <span>User Email</span>}
                  {!isEmpty(selectedFromEmail) && (
                    <span>{selectedFromEmail?.email}</span>
                  )}
                  <figure className="ml-2">
                    {showFromUsers ? <CaretUp /> : <CaretBlackIcon />}
                  </figure>
                </section>
                <div
                  ref={userEmailRef}
                  className={`dropdown-menu dropdown-menu--sm ${
                    showFromUsers ? 'open' : ''
                  }`}
                  style={{
                    width: 'max-content',
                    top: 'calc(100% + 2px)',
                    left: 0,
                    right: 0
                  }}
                >
                  {organizationUser?.results?.map((user) => {
                    const { user_id, email, reply_sending_mail_address } = user;
                    return (
                      <div
                        key={user_id}
                        className="dropdown-menu__list"
                        onClick={() => {
                          setError({ ...error, fromEmail: null });
                          setFromEmail({
                            user_id,
                            email: reply_sending_mail_address
                          });
                        }}
                      >
                        <p className="text-sm">{reply_sending_mail_address}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="flex">
              <div
                className="label cc cursor-pointer mr-2"
                onClick={() => setShowCcInput(!showCcInput)}
              >
                Cc
              </div>
              <div
                className="label cc cursor-pointer"
                onClick={() => setShowBccInput(!showBccInput)}
              >
                Bcc
              </div>
            </div>
          </div>
          {showCcInput && (
            <InlineEmail
              title="Cc"
              placeholder="example@domain.com"
              list={ccEmail}
              setList={(list) => dispatch(setCcEmail(list))}
            />
          )}
          {showBccInput && (
            <InlineEmail
              title="Bcc"
              placeholder="example@domain.com"
              list={bccEmail}
              setList={(list) => {
                dispatch(setBccEmail(list));
              }}
            />
          )}
          <div
            className="info-block w-full items-center"
            style={
              error.subject
                ? {
                    borderColor: '#ff0000'
                  }
                : {}
            }
          >
            <div className="label mr-3">Subject</div>
            <div className="flex items-center justify-center w-full">
              <form className="w-full">
                <input
                  type="text"
                  className="email-text"
                  placeholder="Subject"
                  value={subject}
                  onChange={(e) => {
                    setError({ ...error, subject: null });
                    dispatch(setSubject(e.target.value));
                  }}
                />
              </form>
            </div>
          </div>
        </div>
        <div
          className={`${error.emailBody ? 'error-editor' : ''}`}
          ref={emailBodyRef}
          style={{ height: `calc(100% - ${sectionTopHeight}px` }}
        >
          <Quill
            onChange={(data) => {
              setError({ ...error, emailBody: null });
              dispatch(setEmailBody(data));
            }}
            value={emailBody}
            setAttachments={(data) => dispatch(setAttachments(data))}
            onSend={onSendEmail}
            isLoading={isLoading}
            placeholder={`${
              activeLead?.first_name
                ? `Reply to ${activeLead?.first_name}`
                : `Reply here`
            }`}
            isExpanded={isFooterExpanded}
            attachments={attachments}
            setEditorRef={setEditorRef}
            onAttachmentChanges={onAttachmentChanges}
            errorMessage={error.emailBody}
            isRefetch={true}
            onUseTemplate={onUseTemplate}
            isAutoFocus={true}
            hideHyperlink={false}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplyEmail;

import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'components/loader';

const PrimaryWithLogo = (props) => {
  const {
    label,
    className,
    style,
    renderSvg,
    isLoading,
    enableLoader,
    loaderInline,
    isBlocked,
    ...rest
  } = props;

  return (
    <button
      data-testid="btn-primary-with-logo"
      className={`btn ${
        isBlocked ? 'btn--blocked' : 'btn--primary'
      } btn--sm gap-2 ${className}`}
      style={{
        borderRadius: 6,
        padding: '2px 11px',
        fontSize: 13,
        fontWeight: '500',
        ...style
      }}
      disabled={isLoading}
      {...rest}
    >
      {renderSvg && typeof renderSvg === 'function' && renderSvg()}
      {label}
      {isLoading && enableLoader && (
        <Loader loaderInline={loaderInline} smaller />
      )}
    </button>
  );
};

PrimaryWithLogo.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  renderSvg: PropTypes.func,
  isLoading: PropTypes.bool,
  enableLoader: PropTypes.bool,
  loaderInline: PropTypes.bool,
  isBlocked: PropTypes.bool
};

PrimaryWithLogo.defaultProps = {
  className: '',
  isLoading: false,
  enableLoader: false,
  loaderInline: false,
  isBlocked: false
};

export default PrimaryWithLogo;

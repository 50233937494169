/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import CustomToolTip from 'components/CustomTooltip';
import InputField from 'components/inputs/textField';
import iconEdit from 'assets/icons/icon-edit.svg';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import {
  setAutomationName,
  setAutomationErrors
} from 'slices/boxAutomation/slice';

const AutomationName = (props) => {
  const { register, errors, watch, setValue, setErrors } = props;
  const inputRef = useRef();
  const dispatch = useDispatch();
  const {
    automation_name,
    selectedAutomation,
    automationErrors,
    addAutomation
  } = useSelector((state) => state.boxAutomation);
  const [editName, setEditName] = useState(!automation_name);

  useEffect(() => {
    if (inputRef.current) {
      if (automationErrors.automation_name) {
        inputRef.current.focus();
      }
    }
  }, [automationErrors]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [addAutomation, inputRef, editName]);

  const onNameChange = (e) => {
    const { value } = e.target;
    const errorObj = { ...automationErrors };
    if (errorObj.automation_name) {
      delete errorObj.automation_name;
      dispatch(setAutomationErrors(errorObj));
    }
    // if (value.length > 30) {
    //   dispatch(
    //     setAutomationErrors({
    //       ...automationErrors,
    //       automation_name: 'maxmimum 30 characters allowed'
    //     })
    //   );
    //   return;
    // }
    dispatch(setAutomationName(value));
  };

  return (
    <div className="width-fill-available flex items-center relative">
      {!editName ? (
        <div className="flex">
          <h4 className="font-semibold">
            {!automation_name ? 'Name your automation' : automation_name}
          </h4>
          <CustomToolTip style={{ height: '35px' }} tooltipText={'Edit'}>
            <img
              src={iconEdit}
              alt=""
              style={{ width: 14, height: 14, marginTop: '5px' }}
              className="ml-1 cursor-pointer"
              onClick={() => setEditName(true)}
            />
          </CustomToolTip>
        </div>
      ) : (
        <div className="w-full">
          <InputField
            className="input-field--sm "
            placeholder={'Name your automation'}
            name="headder"
            value={automation_name}
            error={!isEmpty(automationErrors.automation_name)}
            onChange={onNameChange}
            ref={inputRef}
          />
          {automationErrors.automation_name && (
            <ErrorMessage message={automationErrors.automation_name} />
          )}
        </div>
      )}
    </div>
  );
};

export default AutomationName;

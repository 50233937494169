import React from 'react';
import { ReactComponent as InfoIcon } from 'assets/icons/info-icon.svg';
import CustomToolTip from 'components/CustomTooltip';

const InfoIconComponent = (props) => {
  const { tooltipText, tooltipStyle, infoIconStyle } = props;
  return (
    <>
      <CustomToolTip
        position="right"
        tooltipText={tooltipText}
        tooltipStyle={{ top: '-13px', left: '150%', ...tooltipStyle }}
      >
        <InfoIcon
          style={{
            marginTop: '2.5px',
            marginLeft: '5px',
            height: '12px',
            ...infoIconStyle
          }}
        />
      </CustomToolTip>
    </>
  );
};

export default InfoIconComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import InputField from 'components/inputs/textField';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import Error from 'components/errorMessage';
import { requiredMsg } from 'utils/messages';
import { useForm } from 'react-hook-form';
import { isEmpty } from 'utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { updateUserProfileName } from 'slices/userProfileSlice';
import UserProfileUpload from './profileUpload';
import { Loader } from 'components/loader';
import { useSnackbar } from 'components/Snackbar';
import { getOrganizationUser } from 'slices/organizationuserSlice';

const UserProfile = () => {
  // const [userProfileName,setUserProfileName] = useState()
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const formSchema = {
    username: string()
      .required(requiredMsg('username'))
      .min(4, 'minimum 4 characters required')
      .max(20, 'maxmimum 20 characters allowed')
  };

  const { subOrganizationId, myInformation } = useSelector(
    (state) => state.myInfo
  );

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  useEffect(() => {
    setValue('username', myInformation.name);
  }, [myInformation]);

  const onSuccess = () => {
    setIsLoading(false);
    dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
    openSuccessSnackBar('updated successfully');
    document.activeElement.blur();
  };

  const onSubmit = async (data) => {
    const { username } = data;
    if (myInformation.name === username) {
      return;
    } else {
      setIsLoading(true);
      const usernames = username.split(' ');
      if (usernames.length > 2 && !isEmpty(myInformation)) {
        const { id, username, email } = myInformation;
        const [first, middle, last] = usernames;
        const formData = {
          userId: id,
          data: {
            username: username,
            email: email,
            first_name: first,
            middle_name: middle,
            last_name: last
          },
          myInfo: myInformation
        };
        dispatch(updateUserProfileName(formData, onSuccess));
      }
      if (
        usernames.length > 1 &&
        usernames.length <= 2 &&
        !isEmpty(myInformation)
      ) {
        const { id, username, email } = myInformation;
        const [first, last] = usernames;
        const formData = {
          userId: id,
          data: {
            username: username,
            email: email,
            first_name: first,
            middle_name: null,
            last_name: last
          },
          myInfo: myInformation
        };

        dispatch(updateUserProfileName(formData, onSuccess));
      }
      if (usernames.length === 1) {
        const { id, username, email } = myInformation;
        const formData = {
          userId: id,
          data: {
            username: username,
            email: email,
            first_name: usernames[0],
            middle_name: null,
            last_name: null
          },
          myInfo: myInformation
        };
        dispatch(updateUserProfileName(formData, onSuccess));
      }
    }
  };

  return (
    <>
      <div className="text-center">
        <h2 className="text-2xl font-semibold">My Profile</h2>
        <p className="text-base-1 text-grey-800 mt-2">
          Login details, email signature
        </p>
      </div>
      <div className="card-box py-5 px-6 mt-6">
        <h3 className="text-lg font-semibold">User name & Profile picture</h3>
        <div className="flex mt-6">
          <UserProfileUpload
            myInfo={myInformation}
            updateUserProfileName={updateUserProfileName}
            subOrganizationId={subOrganizationId}
          />
          <div className="ml-8">
            <h4 className="text-base-1 font-semibold">User Name</h4>
            <p className="text-sm text-grey-700 mt-1">
              This is the name your team members will see
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="flex items-center">
                <InputField
                  className="input-field--md mt-4"
                  style={{ paddingRight: 90 }}
                  placeholder="Type user name"
                  name="username"
                  error={!isEmpty(errors.username)}
                  register={register}
                />
                {isLoading && <Loader />}
              </div>
              <div className="mt-1 ml-1">
                {errors.username && (
                  <Error className="flex" message={errors.username.message} />
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserProfile;

const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const FREE_ENQUIRIES_COUNT = `${BASE_API_URL}/plans/get_enquiries_count/:subOrgId`;
export const GET_PLANS = `${BASE_API_URL}/plans/get_plans/:subOrgId`;
export const CANCEL_PLAN_AND_EXPORT_DATA = `${BASE_API_URL}/plans/cancel_plan_and_export_data/:subOrgId`;

// SMS PLANS
export const GET_ALL_SMS_PLANS = `${BASE_API_URL}/plans/get_all_the_sms_plans`;
export const UPGRADE_OR_DOWNGRADE_SMS_PLAN = `${BASE_API_URL}/sms/sub_organizations/:subOrgId/upgrade_or_downgrades/sms_plans/:planId`;
export const GET_SMS_DETAILS = `${BASE_API_URL}/sms/sub_organizations/:subOrgId/smses/get_my_sms_details`;
export const CANCEL_SMS_PLAN = `${BASE_API_URL}/sms/sub_organizations/:subOrgId/cancel_sms_plan`;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  setListAssistants,
  setVersionStatus
} from 'slices/ai-assistant/assistant/slice';
import { setAvatarUrl } from 'slices/ai-assistant/personality/slice';
import { GET_BOT_VALUES } from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';

const initialState = {
  botValues: {}
};

const reducers = {
  setBotValues: (state, action) => {
    state.botValues = action.payload;
  }
};

export const botControls = createSlice({
  name: 'botControls',
  initialState,
  reducers
});

export const { setBotValues } = botControls.actions;

export default botControls.reducer;

export const getBotValues = (botId) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_BOT_VALUES, 'aiBotId', botId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBotValues(res.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBotValues = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const data = payload.data;
    const botId = payload.botId;
    const { listAssistants } = getState().assistant;
    try {
      await new APIRequest()
        .put(replaceUrl(GET_BOT_VALUES, 'aiBotId', botId), data)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBotValues(res.data.theme));
            const updatedData = listAssistants.map((item) => {
              if (item.version_bot.id === botId) {
                return {
                  ...item,
                  version_status: res.data.version_status,
                  version_bot: {
                    ...item.version_bot,
                    avatar: {
                      ...item.version_bot.avatar,
                      s3_url: res.data.theme.selected_avatar
                    }
                  }
                };
              }
              return item;
            });
            if (successCb) successCb();
            dispatch(setListAssistants(updatedData));
            dispatch(setAvatarUrl(res.data.theme.selected_avatar));
            dispatch(setVersionStatus(res.data.version_status));
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

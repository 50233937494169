/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { route } from 'utils/route';
import Incoming from 'modules/report/Incoming';
import BoxReport from './box';
import TeamReport from './team';

const EnquiryReportRoute = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Navigate
            to={`${route.report}${route.boxReport}/all-box/incoming-enquiry-report`}
          />
        }
      />
      <Route path={`${route.boxReport}/all-box/*`} element={<Incoming />} />
      <Route
        path={`${route.boxReport}/:box_type/:box_id/*`}
        element={<BoxReport />}
      />
      <Route path={`${route.teamReport}/*`} element={<TeamReport />} />
    </Routes>
  );
};

const EnquiryReport = () => {
  return (
    <React.Fragment>
      {/* <SideNav /> */}
      <Routes>
        <Route path="/*" element={<EnquiryReportRoute />} />
      </Routes>
    </React.Fragment>
  );
};

export default EnquiryReport;

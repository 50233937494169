import React from 'react';
import '../../../../dashboard-create-bot.scss';
import AddAssistant from './addToWebsite';
import BotsControls from './botsControl';
import TrackingPage from './trackingPage';
import Appearance from './appearance';
import { NavLink, Route, Routes, useParams } from 'react-router-dom';
import BackArrow from '../../components/backArrow';

const WebsiteTab = () => {
  const { assistant_id } = useParams();
  const deployBack = `/chatbot-ai/assistant/${assistant_id}/deploy`;

  return (
    <>
      <BackArrow className="justify-start gap-[35px]" deployBack={deployBack}>
        <div className="website-tabs flex">
          <NavLink
            className="tabs-header"
            to={`/chatbot-ai/assistant/${assistant_id}/deploy/website/add-to-website`}
          >
            🖥️ Add to website
          </NavLink>
          <NavLink
            className="tabs-header"
            to={`/chatbot-ai/assistant/${assistant_id}/deploy/website/controls`}
          >
            🕹️ Controls
          </NavLink>
          <NavLink
            className="tabs-header"
            to={`/chatbot-ai/assistant/${assistant_id}/deploy/website/tracking`}
          >
            🎯 Tracking
          </NavLink>
          <NavLink
            className="tabs-header"
            to={`/chatbot-ai/assistant/${assistant_id}/deploy/website/appearance`}
          >
            🎨 Appearance
          </NavLink>
        </div>
      </BackArrow>
      <Routes>
        <Route path="/add-to-website" element={<AddAssistant />} />
        <Route path="/controls" element={<BotsControls />} />
        <Route path="/tracking" element={<TrackingPage />} />
        <Route path="/appearance" element={<Appearance />} />
      </Routes>
    </>
  );
};

export default WebsiteTab;

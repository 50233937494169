/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';
import GmailLogo from 'assets/icons/icon-gmail.svg';

const ButtonWithLogo = React.forwardRef((props, parentRef) => {
  const { logo, label, onClick, id, ...rest } = props;
  return (
    <button
      id={id}
      data-testid="button-with-logo"
      className="w-full btn btn--google flex items-center justify-center gap-3"
      onClick={onClick}
      {...rest}
    >
      <img data-testid="button-logo" src={logo} />
      <span>{label}</span>
    </button>
  );
});

ButtonWithLogo.propTypes = {
  logo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  label: PropTypes.string,
  onClick: PropTypes.func
};

ButtonWithLogo.defaultProps = {
  logo: GmailLogo,
  label: 'Sign up with Google',
  onClick: () => {}
};

export default ButtonWithLogo;

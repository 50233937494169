/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import QuestionHeader from '../../../../component/questionHeader';
import { ReactComponent as DateIcon } from 'assets/icons/icon-question-date-small.svg';
import TextInputField from 'components/inputs/textField';
import CheckBox from 'components/inputs/checkbox';
import { useParams } from 'react-router-dom';
import {
  setSelectedQuestion,
  setIsShowQuestionForm,
  setIsShowQuestionsToAdd,
  AddQuestion,
  setIsEdit,
  setEditedData,
  updateQuestion
} from 'slices/questionSlice';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { useDispatch, useSelector } from 'react-redux';
import Error from 'components/errorMessage';
import CHOICES from 'choices';
import { isEmpty } from 'utils/utils';
import { useAnalytics } from 'use-analytics';

const Date = (props) => {
  const { data, addQuestion } = props;
  const { DATETIME, DATE_RANGE } = CHOICES.QuestionChoices;
  const { questionsData, isEdit, editedData } = useSelector(
    (state) => state.question
  );
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [questionText, setQuestionText] = useState(
    isEdit && !isEmpty(editedData) ? editedData.text : ''
  );
  const [isError, setIsError] = useState('');
  const [status, setStatus] = useState(
    isEdit && editedData?.kind === DATE_RANGE ? true : false
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const onCancel = () => {
    dispatch(setIsEdit(false));
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setEditedData({}));
  };

  const updateCallback = (data) => {
    track(
      'question_updated',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(setIsEdit(false));
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    dispatch(setEditedData({}));
  };

  const callback = (data) => {
    track(
      'question_created',
      {
        name: data.text,
        type: CHOICES.QuestionChoices.CHOICES[data.kind]
      },
      {
        groupId: subOrganizationId
      }
    );
    setIsLoading(false);
    dispatch(setIsShowQuestionForm(false));
    dispatch(setSelectedQuestion(null));
    dispatch(setIsShowQuestionsToAdd(false));
    addQuestion(data);
    // openSuccessSnackBar('Question created');
  };

  const onSave = (e) => {
    e.preventDefault();
    if (questionText === '') {
      setIsError('Question cannot be empty');
    } else {
      setIsLoading(true);
      if (isEdit) {
        const formData = {
          text: questionText,
          position: editedData.position,
          kind: editedData.kind
        };
        dispatch(
          updateQuestion({
            body: formData,
            questionId: editedData.id,
            callback: updateCallback
          })
        );
      } else {
        const formData = {
          text: questionText,
          position: questionsData.length,
          kind: status ? DATE_RANGE : DATETIME,
          choice_set: [],
          sub_organization: subOrganizationId,
          box: box_id
        };
        dispatch(AddQuestion({ body: formData, callback }));
      }
    }
  };

  return (
    <div className="mt-8 border border-secondary border-radius-10 bg-grey-300 w-full">
      <QuestionHeader label={data.label} renderSvg={() => <DateIcon />} />
      <form className="p-5" autoComplete="off">
        <h3 className="text-sm font-semibold">Question</h3>
        <TextInputField
          name="question"
          className="mt-2 input-field--xs-34"
          placeholder="Type your question here"
          value={questionText}
          onChange={(e) => {
            setIsError('');
            setQuestionText(e.target.value);
          }}
          error={!isEmpty(isError) ? true : false}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              onSave(e);
            }
          }}
        />
        {isError && <Error message={isError} />}
        <div className="xxxl:flex mt-8 items-center justify-between">
          <div className="flex gap-2 xxxl:mb-0 mb-5">
            <CheckBox
              small
              id="tandc"
              name="tandc"
              required
              onChange={(e) => setStatus(e.target.checked)}
              checked={status}
              disabled={isEdit ? true : false}
              style={isEdit ? { cursor: 'not-allowed' } : {}}
            />
            <label className="text-13" htmlFor="tandc">
              Make this a date range instead of a single date
            </label>
          </div>
          <div className="flex justify-end gap-2">
            <CustomColorButton
              label="Cancel"
              className="btn--grey min-w-120 btn--md"
              onClick={onCancel}
            />
            <PrimaryButton
              label="Save"
              className="min-w-120 btn--md"
              enableLoader
              isLoading={isLoading}
              onClick={(e) => onSave(e)}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Date;

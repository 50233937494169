/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { Suspense, useMemo } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AppRoutes from 'routes';
import AppWrapper from './appWrapper';
import Loader from 'components/loader';
import SnackBarContainer from 'components/Snackbar/container';
import { useSelector } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useSocialCredentialsQuery } from 'services/socialApi';
import LogoLoader from 'components/logoLoader';
// import SetUpAnalytics from 'segment_analytics';
import Firebase from 'components/Firebase';
import { useAnalytics } from 'use-analytics';

const AppLayout = () => {
  const authToken = useSelector((state) => state.auth.authToken);
  const { data, isLoading } = useSocialCredentialsQuery('', {
    skip: authToken
  });
  const { reset } = useAnalytics();

  useMemo(() => {
    if (!authToken) {
      reset();
    }
  }, [authToken]);

  if (isLoading) return <LogoLoader />;

  return (
    <GoogleOAuthProvider clientId={data?.google?.clientId}>
      <AppWrapper>
        <SnackBarContainer />
        {/* <SetUpAnalytics /> */}
        {'serviceWorker' in navigator && <Firebase />}
        <Suspense fallback={() => <LogoLoader />}>
          <AppRoutes />
        </Suspense>
      </AppWrapper>
    </GoogleOAuthProvider>
  );
};

export default AppLayout;

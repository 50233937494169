import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import SMSHome from './home';
import SMSChannelSettings from './SMSChannelSettings';
// import AnimationWrapper from 'animation/wrapper';
import SMSContent from 'modules/toolbox/component/smsContent';
import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
import SmsBilling from 'modules/channels/components/smsbilling';

const { SMS: SMS_CHANNEL } = CHOICES.ChannelChoices;

const SMS = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route path="/pending/:channelId" element={<SMSContent />} />
      {/* <Route path="/pending/:channelId" element={<SMSHome />} /> */}
      <Route path="/connected/:channelId" element={<SMSChannelSettings />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={SMS_CHANNEL} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<SMSChannelSettings channelType={SMS_CHANNEL} />}
      />
      <Route path="/connected/:channelId/billing" element={<SmsBilling />} />
      {/* </Route> */}
    </Routes>
  );
};

export default SMS;

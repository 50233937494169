/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import iconPlus from 'assets/icons/icon-plus.svg';
import EmptyStage from './empty';
import InputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import PrimaryButton from 'components/buttons/primary';
import { maximumCharactersMsg } from 'utils/messages';
import { createStage } from 'slices/boxes/slice';
import { useDispatch } from 'react-redux';
import choices from 'choices';

const { CUSTOM } = choices.StageKind;
const { SALES } = choices.BoxType;

const AddStage = () => {
  const [addStage, setAddStage] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { box_id, box_type } = useParams();
  const isSalesBox = box_type == SALES;

  const formSchema = {
    stageName: string().required('Please provide name')
    // .max(30, maximumCharactersMsg('stage name', 30))
  };
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    clearErrors
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const onSuccess = () => {
    setIsLoading(false);
    setAddStage(false);
    setValue('stageName', '', { shouldTouch: false });
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = {
      box: box_id,
      stage_name: data.stageName,
      stage_kind: CUSTOM
    };
    dispatch(createStage(formData, onSuccess, onError));
  };

  useEffect(() => {
    clearErrors('stageName');
    setAddStage(false);
  }, [box_id]);

  return (
    <div
      className={`box-card-container fix-width ${isSalesBox ? 'mt-4' : ''}`}
      style={
        isSalesBox
          ? {
              maxHeight: '161px',
              minWidth: addStage ? '420px' : ''
            }
          : { maxHeight: '161px', minWidth: addStage ? '420px' : '' }
      }
    >
      {!addStage && (
        <>
          <div className="px-4 mt-2">
            <div
              className="flex justify-between bg-grey-90 rounded-lg py-1 px-3 items-center"
              onClick={() => setAddStage(true)}
            >
              <div className="flex items-center cursor-pointer">
                <img
                  src={iconPlus}
                  alt=""
                  className="cursor-pointer mr-3"
                  style={{ width: 12, height: 12 }}
                />
                <div className="font-semibold text-md">Add Stage</div>
              </div>
            </div>
            <EmptyStage />
          </div>
        </>
      )}
      {addStage && (
        <div className="ml-4 mr-4" style={{ maxWidth: '420px' }}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className={`w-full ${addStage ? 'is-open' : ''}`}>
              <InputField
                className="input-field--sm w-full"
                name="stageName"
                placeholder="Type your stage name"
                error={errors.stageName && touchedFields.stageName}
                register={register}
              />
              {errors.stageName && touchedFields.stageName && (
                <ErrorMessage message={errors?.stageName?.message} />
              )}
            </div>
            <div className="flex justify-end mt-15">
              <button
                type="button"
                className="btn btn--sm-60 btn--grey mr-2"
                onClick={() => {
                  setAddStage(false);
                  clearErrors('stageName');
                }}
              >
                Cancel
              </button>
              <PrimaryButton
                type="submit"
                label="Save"
                primaryid="button_submit"
                className="btn--sm-60"
                isLoading={isLoading}
                loaderStyle={{
                  position: 'relative',
                  left: '40px',
                  padding: '0 5px'
                }}
                enableLoader={true}
              />
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default AddStage;

import React from 'react';

const EmptyStage = () => {
  return (
    <div
      className="box-card box-card box-card--empty mb-3"
      style={{ height: 78 }}
    >
      <div className="text-sm text-center flex items-center px-12">
        Add another stage to keep track of your process step by step
      </div>
    </div>
  );
};

export default EmptyStage;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { setVersionStatus } from 'slices/ai-assistant/assistant/slice';
import { FB_PIXEL_API, GA4_API } from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';

const initialState = {
  googleAnalytics: {},
  fbPixelId: {}
};

const reducers = {
  setGoogleAnalytics: (state, action) => {
    state.googleAnalytics = action.payload;
  },
  setFbPixelId: (state, action) => {
    state.fbPixelId = action.payload;
  }
};

export const analytics = createSlice({
  name: 'analytics',
  initialState,
  reducers
});

export const { setGoogleAnalytics, setFbPixelId } = analytics.actions;
export default analytics.reducer;

export const updateGA4 = (data, succesCb, failedCb) => {
  return async (dispatch, getState) => {
    const botId = data.botId;
    const { currentVersionId } = getState().assistant;
    let payload = data.payload;
    payload.version_id = currentVersionId;
    try {
      await new APIRequest()
        .put(replaceUrl(GA4_API, 'aiBotId', botId), payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setGoogleAnalytics(res.data));
            dispatch(setVersionStatus(res.data.version_status));
            if (succesCb) succesCb();
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const getGA4 = (botId) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GA4_API, 'aiBotId', botId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setGoogleAnalytics(res.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateFbPixelId = (data, succesCb, failedCb) => {
  return async (dispatch, getState) => {
    const botId = data.botId;
    const { currentVersionId } = getState().assistant;
    let payload = data.payload;
    payload.version_id = currentVersionId;
    try {
      await new APIRequest()
        .put(replaceUrl(FB_PIXEL_API, 'aiBotId', botId), payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFbPixelId(res.data));
            dispatch(setVersionStatus(res.data.version_status));
            if (succesCb) succesCb();
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const getFbPixelId = (botId) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(FB_PIXEL_API, 'aiBotId', botId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFbPixelId(res.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import PrimaryButton from 'components/buttons/primary';
// import { ReactComponent as DeleteSvg } from 'assets/icons/icon-delete-white.svg';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
// import { Loader } from 'components/loader';
import DeleteChannel from '../deleteChannel';
import { useSnackbar } from 'components/Snackbar';
// import AutomateStuff from '../../automationStuff/templates';
// import CHOICES from 'choices';

// const SMS = CHOICES.ChannelChoices.SMS;

const SMSChannelSettings = () => {
  const { channelId } = useParams();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const { allConnectedChannels } = useSelector((state) => state.channels);
  const [smsChannelData, setSmsChannelData] = useState([]);

  useEffect(() => {
    if (!isEmpty(allConnectedChannels)) {
      setSmsChannelData(
        allConnectedChannels.filter((item) => item.id === channelId)
      );
    }
  }, [allConnectedChannels]);

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/channels');
  };

  const copyToClipboard = (number) => {
    navigator.clipboard.writeText(number);
    openSuccessSnackBar('Number copied');
  };

  // if (isEmpty(smsChannelData)) return <Loader />;

  return (
    <div className="w-full">
      <div className="py-6 px-8" style={{ maxWidth: '70%' }}>
        <div className="card-box px-6 py-4">
          <h3 className="text-lg font-semibold letterspacing24em ">
            Your SMS number
          </h3>
          <p className="text-grey-800 text-sm letterspacing24em mt-1">
            You can use this number to receive SMS message from customers or
            leads
          </p>
          <div className="py-2 px-3 border border-grey-500-secondary bg-grey-300 rounded-lg mt-3">
            <span className="text-lg font-medium">
              {smsChannelData[0]?.sms?.phone_number}
            </span>
          </div>
          <div className="flex items-center justify-between mt-4">
            <PrimaryButton
              className="btn--xs mr-4 flex-shrink-0"
              label="Copy number"
              onClick={() =>
                copyToClipboard(`${smsChannelData[0]?.sms?.phone_number}`)
              }
            />
            <span className="text-primary text-13 font-medium">
              <a
                href="https://help.enquirybox.io/en/articles/7228359-setup-and-connect-an-sms-number"
                target="_blank"
                rel="noreferrer"
              >
                Read more about receiving SMS
              </a>
            </span>
          </div>
        </div>
        {/* <AutomateStuff channelType={SMS} /> */}
        <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
      </div>
    </div>
  );
};

export default SMSChannelSettings;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { MentionsInput, Mention } from 'react-mentions';

// import avatarPic from 'assets/images/avatar-pic.png';
import Avatar from 'components/avatar';

import classNames from './mentions.module.css';
import { isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';

export default function Mentions(props) {
  const { setNote, users, setSelectedUser, setError, note, inputStyle } = props;
  const { isEdit, isEditText, editedNote, isReEdit } = useSelector(
    (state) => state.notes
  );
  const noteRef = useRef();
  const [value, setValue] = useState(note);

  useEffect(() => {
    // to set the state of the value when note changes
    setValue(note);
  }, [note]);

  useEffect(() => {
    setTimeout(() => {
      noteRef.current.focus();
    }, 100);
  }, [editedNote]);

  useEffect(() => {
    if (isEdit) {
      setValue(isEditText);
      setNote(isEditText);
    }
  }, [isEdit, editedNote, isReEdit]);

  const onChange = (event) => {
    setError('');
    const value = event.target.value;
    if (value.length <= 500) {
      setNote(value);
      const regex = /[^{@[}]+(?=])/g;
      const mentions = value.match(regex);
      if (!isEmpty(mentions)) {
        const selectedUsers = users.filter((user) => {
          return mentions.some((item) => {
            return item === user.id;
          });
        });
        setSelectedUser(selectedUsers);
      }
      if (isEmpty(mentions)) {
        setSelectedUser([]);
      }
      setValue(event.target.value);
    } else {
      setError('Maximum characters reached');
    }
  };

  const onUserAdd = (id) => {
    console.log(id);
  };

  const renderUserSuggestion = (event) => {
    return (
      <div className="dropdown-menu__list" style={{ minWidth: 300 }}>
        <Avatar
          src={event.avatar}
          initial={event.display}
          small
          border={false}
          contain
          singleChar
          initialStyle={{
            fontSize: '15px'
          }}
        />
        <p className="ml-2">{event.display}</p>
      </div>
    );
  };

  return (
    <>
      <MentionsInput
        className="mentions"
        value={value}
        onChange={onChange}
        classNames={classNames}
        forceSuggestionsAboveCursor={true}
        placeholder="Write a note here. Tag a team member by typing @"
        // autoFocus
        style={inputStyle}
        inputRef={noteRef}
      >
        <Mention
          trigger="@"
          data={users}
          className={classNames.mentions__mention}
          renderSuggestion={renderUserSuggestion}
          onAdd={onUserAdd}
          appendSpaceOnAdd={true}
          style={inputStyle}
        />
      </MentionsInput>
    </>
  );
}

Mentions.defaultProps = {
  setError: () => {}
};

import React from 'react';

const GoogleErrorDraft = ({ error }) => {
  const { message, description } = error;
  return (
    <div className="google-login-error mt-10 mb-6">
      <p className="text-15 text-red font-medium">{message}</p>
      <p className="text-sm mt-2">{description}</p>
    </div>
  );
};

export default GoogleErrorDraft;

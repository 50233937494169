import editorPic from 'assets/images/editor-pic.svg';

const EditWebsiteLink = () => {
  return (
    <section>
      <div className="mx-auto p-0" style={{ width: 670 }}>
        <h3 className="text-lg font-semibold">Link name</h3>
        <div
          className="py-3 px-5 mt-4 border border-box-border border-radius-10"
          style={{ backgroundColor: '#F2F6F8' }}
        >
          www.kingweddingvenue.com/contact-us
        </div>
        <div className="mt-5">
          <img src={editorPic} alt="editor" />
        </div>
        <div className="mt-5 p-8 border border-box-border border-radius-10">
          <div className="flex flex-wrap -mx-4">
            <div className="col-4 px-4" style={{ color: '#727272' }}>
              Last edited by
            </div>
            <div className="col-8 px-4">
              <div className="flex items-center">
                <figure
                  className="avatar-container avatar-container--full"
                  style={{
                    width: 22,
                    height: 22,
                    boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                  }}
                >
                  <img
                    src="https://images.unsplash.com/photo-1682686581312-506a8b53190e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2940&q=80"
                    alt="profile-pic"
                  />
                </figure>
                <p className="ml-2">James Kelly (james@smilesdental.com)</p>
              </div>
            </div>
            <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
              Last edited on
            </div>
            <div className="col-8 px-4 mt-5">
              <p className="">27 July, 2023</p>
            </div>
            <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
              Times used in answers
            </div>
            <div className="col-8 px-4 mt-5">
              <p className="">24</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EditWebsiteLink;

const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

// to get the report data
export const GET_ALL_REPORT_DATA = `${BASE_API_URL}/reports/get_reports/:subOrgId`;

// to get box report
export const GET_ALL_BOX_REPORT = `${BASE_API_URL}/reports/get_box_report/:boxId`;

// to get team report
export const GET_ALL_TEAM_REPORT = `${BASE_API_URL}/reports/get_team_report/:subOrgId`;

import React from 'react';
import DragEmpty from 'modules/boxes/lead/dragEmpty';

const StageEmptyState = (props) => {
  const { incomingStage, isAppliedFilter } = props;

  const EmptyStage = () => {
    return (
      // <Droppable droppableId={`${stageId}_EMPTY`} type="LEAD">
      //   {(provided) => {
      //     return (
      //       <div ref={provided.innerRef} {...provided.droppableProps}>
      <DragEmpty />
      //       </div>
      //     );
      //   }}
      // </Droppable>
    );
  };

  const EmptyIncoming = () => {
    return (
      <div
        className="box-card box-card box-card--empty mb-3"
        style={{ height: 78 }}
      >
        <div className="text-sm text-center flex items-center px-12">
          All incoming enquiries will come into this stage first
        </div>
      </div>
    );
  };

  if (isAppliedFilter) return <></>;

  return <div>{incomingStage ? <EmptyIncoming /> : <EmptyStage />}</div>;
};

export default StageEmptyState;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { Routes, Route, useParams, useNavigate } from 'react-router-dom';
import Header from './header';
// import iconHeader from 'assets/icons/icon-sales-box-header.svg';
import Stages from './stages';
import Filter from './filter';
import choices from 'choices';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getIdAndType } from './helper';
import {
  moveCardToStageLocally,
  setCurrentBox,
  setCurrentBoxCardList,
  setCurrentBoxId
} from 'slices/boxes/slice';
import LeadDetail from './leadDetail';
import StagesHome from './stages/home';
import { getAllConnectedChannels } from 'slices/channelsSlice';
import { getLeadLabel } from 'slices/labelsSlice';
import { isEmpty } from 'utils/utils';
import AddManualEnquiry from './components/modal/addManualEnquiry';
import { getAllNewBoxesEnquires } from 'slices/boxesNewEnquiresSlice';

const Home = ({ path }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const restParams = params['*'];
  const { box_type, box_id } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { boxes } = useSelector((state) => state.boxes);
  const [dragStart, setDragStart] = useState(null);
  const [isFilter, setIsFilter] = useState(false);
  const [showEnquiryModal, setShowEnquiryModal] = useState(false);
  const [openEnquiryDropdown, setOpenEnquiryDropdown] = useState(false);
  const isDetailEnabled = !isEmpty(restParams);

  // const loadAllLeads = () => {
  //   dispatch(getAllConnectedChannels());
  // };

  const loadAllLabels = (subOrgId) => {
    dispatch(getLeadLabel({ subOrgId: subOrgId }));
  };

  useEffect(() => {
    // loadAllLeads();
    loadAllLabels(subOrganizationId);
  }, []);

  const onBeforeDragStart = (data) => {
    setDragStart(data);
  };

  const onDragStart = (data) => {
    // console.log('onDragStart', data);
  };

  const onDragEnd = (data) => {
    setDragStart(null);
    const { source, destination, draggableId } = data;
    if (!destination) return;
    const info = {
      src: source,
      dest: destination,
      draggableId
    };
    dispatch(moveCardToStageLocally(info));
  };

  const currentBox = useMemo(() => {
    const x = boxes?.find((box) => {
      return box.id === box_id;
    });
    return x;
  }, [box_id, boxes]);

  useEffect(() => {
    dispatch(setCurrentBoxCardList(0));
    dispatch(setCurrentBox(currentBox));
    dispatch(setCurrentBoxId(box_id));
    setIsFilter(false);
  }, [box_id, boxes]);

  if (!currentBox) return <>...</>;

  const onBack = () => {
    navigate(`/dashboard/boxes/${box_type}/${box_id}`);
  };

  const onSuccess = () => {
    setOpenEnquiryDropdown(false);
  };

  const handleOpenNewEnquiry = () => {
    setTimeout(() => {
      dispatch(getAllNewBoxesEnquires({ boxId: box_id }, onSuccess));
    }, 300);
  };

  // const RenderStages = () => {
  //   return (
  //     <div
  //       style={
  //         isFilter
  //           ? { height: 'calc(100% - 145px)' }
  //           : { height: 'calc(100% - 93px)' }
  //       }
  //     >
  //       <div className="h-full width-fill-available pt-5 px-6">
  //         <DragDropContext
  //           onDragStart={onDragStart}
  //           onDragEnd={onDragEnd}
  //           onBeforeCapture={onBeforeDragStart}
  //         >
  //           <Droppable
  //             droppableId="droppable"
  //             direction="horizontal"
  //             type="STAGE"
  //           >
  //             {(provided) => {
  //               return (
  //                 <div
  //                   ref={provided.innerRef}
  //                   className="box-card-wrapper"
  //                   {...provided.droppableProps}
  //                 >
  //                   <Stages
  //                     box={currentBox}
  //                     boxType={box_type}
  //                     stages={currentBox.boxstage_set}
  //                     boxName={currentBox.box_name}
  //                     dragStart={dragStart}
  //                   />
  //                   {provided.placeholder}
  //                 </div>
  //               );
  //             }}
  //           </Droppable>
  //         </DragDropContext>
  //       </div>
  //     </div>
  //   )
  // }

  return (
    <section className="dashboard__main">
      <Header
        title={currentBox.box_name}
        showToggle={true}
        isActive={false}
        isBoxScreen={true}
        setIsActive={() => {}}
        hideText={true}
        setIsFilterClicked={() => setIsFilter(!isFilter)}
        isFilterClicked={isFilter}
        HeaderIcon={() => (
          <figure
            className="avatar-container avatar-container--contain"
            style={{ width: 50, height: 50 }}
          >
            <img
              src={currentBox.box_avatar}
              alt=""
              style={{ width: 28, height: 28 }}
            />
          </figure>
        )}
        onBack={onBack}
        openEnquiryDropdown={openEnquiryDropdown}
        setOpenEnquiryDropdown={setOpenEnquiryDropdown}
        setShowEnquiryModal={setShowEnquiryModal}
        handleOpenNewEnquiry={handleOpenNewEnquiry}
      />
      {isFilter && <Filter isDetailEnabled={isDetailEnabled} />}
      {/* <RenderStages /> */}
      {/* <div
        style={
          isFilter
            ? { height: 'calc(100% - 145px)' }
            : { height: 'calc(100% - 93px)' }
        }
      >
        <div className="h-full width-fill-available pt-5 px-6">
          <DragDropContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onBeforeCapture={onBeforeDragStart}
          >
            <Droppable
              droppableId="droppable"
              direction="horizontal"
              type="STAGE"
            >
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    className="box-card-wrapper"
                    {...provided.droppableProps}
                  >
                    <Stages
                      box={currentBox}
                      boxType={box_type}
                      stages={currentBox.boxstage_set}
                      boxName={currentBox.box_name}
                      dragStart={dragStart}
                    />
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
        </div>
      </div> */}
      {/* <StagesHome
        currentBox={currentBox}
        isFilter={isFilter}
      /> */}
      <StagesHome
        currentBox={currentBox}
        isFilter={isFilter}
        isDetailEnabled={isDetailEnabled}
      />
      <AddManualEnquiry
        open={showEnquiryModal}
        onClose={() => setShowEnquiryModal(false)}
        isPublicPage={false}
        handleOpenNewEnquiry={handleOpenNewEnquiry}
      />
      <Routes>
        {/* <Route
          path="/"
          element={}
        /> */}
        <Route
          path="/:stageId/*"
          element={<LeadDetail currentBox={currentBox} />}
        />
      </Routes>
    </section>
  );
};

export default Home;

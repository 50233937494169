import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  LIST_UPLOAD_QNA,
  CREATE_SINGLE_QNA,
  GET_ALL_QNA,
  SINGLE_QNA
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';

const initialState = {
  qnaData: [],
  singleQnaData: []
};

const reducers = {
  setQnaData: (state, action) => {
    state.qnaData = action.payload;
  },
  setSingleQnaData: (state, action) => {
    state.singleQnaData = action.payload;
  },
  resetQnaData: () => initialState
};

export const qna = createSlice({
  name: 'qna',
  initialState,
  reducers
});
export const { setQnaData, setSingleQnaData, resetQnaData } = qna.actions;

export default qna.reducer;

export const addQna = (payload, assistant_id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { qna } = getState();
    const { qnaData } = qna;
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let currPayload = {
      ...payload,
      version_id: currentVersionId
    };
    try {
      new APIRequest()
        .post(
          replaceUrl(CREATE_SINGLE_QNA, 'aiBotId', assistant_id),
          currPayload
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setQnaData([...qnaData, res.data]));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};
export const uploadMultipleQna = (
  formData,
  assistant_id,
  successCb,
  failedCb
) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .post(replaceUrl(LIST_UPLOAD_QNA, 'aiBotId', assistant_id), formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setQnaData(res.data.data));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((err) => {
          if (failedCb) {
            failedCb(err);
          }
          console.log(err);
        });
    } catch (err) {
      if (failedCb) failedCb();
      console.log(err);
    }
  };
};

export const listAllQna = (assistant_id) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_QNA, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setQnaData(res.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const getQna = (qnaId, callback) => {
  return async (dispatch, getState) => {
    dispatch(setSingleQnaData([]));
    try {
      await new APIRequest()
        .get(replaceUrl(SINGLE_QNA, 'qnaId', qnaId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSingleQnaData(res.data));
            if (callback) callback();
          }
        })
        .catch((err) => {
          console.log(err);
          if (callback) callback();
        });
    } catch (err) {
      if (callback) callback();
      console.log(err);
    }
  };
};
export const updateQna = (payload, qnaId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let currPayload = {
      ...payload,
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .put(replaceUrl(SINGLE_QNA, 'qnaId', qnaId), currPayload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSingleQnaData(res.data));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) {
              successCb('update');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      if (failedCb) failedCb();
      console.log(err);
    }
  };
};
export const deleteQna = (qnaId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let payload = {
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .delete(replaceUrl(SINGLE_QNA, 'qnaId', qnaId), payload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSingleQnaData(res.data));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) successCb('delete');
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};

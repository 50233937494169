import React from 'react';
import PropTypes from 'prop-types';
import HeaderLayout from 'components/layout/headerless';

export const Loader = ({
  small,
  smaller,
  tiny,
  loaderInline,
  loaderStyle,
  isLoaderWhite
}) => {
  const getClassName = () => {
    if (tiny) return 'tiny';
    if (smaller) return 'smaller';
    if (small) return 'small';
    return '';
  };

  return (
    <div
      className="flex h-full justify-center items-center"
      style={loaderStyle}
    >
      <div
        className={`lds-ring ${loaderInline ? 'inline' : ''} ${getClassName()}`}
      >
        <div style={isLoaderWhite ? { borderColor: '#fff' } : {}} />
        <div style={isLoaderWhite ? { borderColor: '#fff' } : {}} />
        <div style={isLoaderWhite ? { borderColor: '#fff' } : {}} />
      </div>
    </div>
  );
};

export const LoaderRing = ({ small }) => {
  const getClassName = () => {
    if (small) return 'small';
    return '';
  };

  return (
    <div className={`lds-ring ${getClassName()}`}>
      <div />
      <div />
      <div />
    </div>
  );
};

export const LayoutLoader = () => {
  return (
    <HeaderLayout hideLogo>
      <LoaderWrap />
    </HeaderLayout>
  );
};

const LoaderWrap = () => {
  return (
    <div className="loader_main">
      <Loader />
    </div>
  );
};

Loader.propTypes = {
  small: PropTypes.bool,
  smaller: PropTypes.bool,
  tiny: PropTypes.bool,
  isLoaderWhite: PropTypes.bool
};

Loader.defaultProps = {
  small: true,
  smaller: false,
  tiny: false,
  isLoaderWhite: false
};

export default LoaderWrap;

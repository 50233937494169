import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import Avatar from 'components/avatar';
import PropTypes from 'prop-types';

const AvatarContainer = (props) => {
  const { user, position } = props;
  return (
    <CustomToolTip
      position={position}
      tooltipText={user?.first_name}
      renderAvatar={() => (
        <Avatar
          src={
            user?.avatar
              ? user.avatar
              : user?.customer_avatar
              ? user?.customer_avatar
              : null
          }
          initial={user?.first_name}
          border={false}
          smallest
          cover
          singleChar
          initialStyle={{
            fontSize: '14px'
          }}
        />
      )}
    >
      <Avatar
        src={
          user?.avatar
            ? user.avatar
            : user?.customer_avatar
            ? user?.customer_avatar
            : null
        }
        initial={user?.first_name}
        border={false}
        cover
        small
        singleChar
      />
    </CustomToolTip>
  );
};

AvatarContainer.propTypes = {
  user: PropTypes.object,
  position: PropTypes.string
};

AvatarContainer.defaultProps = {
  position: 'left'
};

export default AvatarContainer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Select from 'components/inputs/select';
import { isEmpty } from 'utils/utils';
import Calendar from 'components/calendar';
import TextInputField from 'components/inputs/textField';
import { formatDateTime } from 'utils/dateFormat';
import useClickOutside from 'hooks/useOutsideClick';
import { ReactComponent as CalenderIcon } from 'assets/icons/icon-calendar.svg';
import { ReactComponent as CalenderIconPrimary } from 'assets/icons/icon-calendar-primary.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setTempDateFormat,
  setTempEnd_Date,
  setTempStart_date
} from 'slices/reports/slice';
import moment from 'moment';

const FilterSection = (props) => {
  const {
    endDate,
    startDate,
    setEndDate,
    setStartDate,
    setSelectedDay,
    selectedDay,
    onSelectDay,
    onCustomDateSelect
  } = props;
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const dispatch = useDispatch();
  const [openToCalender, setOpenToCalender] = useState(false);
  const [openFromCalender, setOpenFromCalender] = useState(false);
  const [tempFromDate, setTempFromDate] = useState();
  const [tempToDate, setTempToDate] = useState();

  const { tempDateFormat, tempStartDate, tempEndDate } = useSelector(
    (state) => state.reports
  );

  const dayOptions = [
    {
      id: 1,
      label: 'Last 7 days',
      value: 'Last 7 days'
    },
    {
      id: 2,
      label: 'This month',
      value: 'This month to date'
    },
    {
      id: 3,
      label: 'Custom',
      value: 'Custom'
    }
  ];

  useClickOutside(() => setOpenFromCalender(false), fromDateRef.current);

  useClickOutside(() => setOpenToCalender(false), toDateRef.current);

  useEffect(() => {
    setSelectedDay(dayOptions[1]);
  }, []);

  const onDaySelected = (data) => {
    setSelectedDay(data);
    setTempFromDate();
    setTempToDate();
    dispatch(setTempDateFormat(data.value));
    if (data?.value === 'This month to date') {
      dispatch(
        setTempStart_date(moment().startOf('month').format('YYYY-MM-DD'))
      );
      dispatch(
        setTempEnd_Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
      );
    }
    if (data?.value === 'Last 7 days') {
      dispatch(
        setTempStart_date(moment().subtract(7, 'days').format('YYYY-MM-DD'))
      );
      dispatch(
        setTempEnd_Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
      );
    }
    if (data?.value === 'Custom') {
      setOpenFromCalender(true);
    } else {
      onSelectDay(data);
    }
  };

  const onStartDateSelect = (date) => {
    dispatch(setTempStart_date(formatDateTime(date, 'yyyy-mm-dd')));
    const DATE = formatDateTime(date, 'yyyy-mm-dd');
    if (tempToDate) {
      const TODATE = formatDateTime(tempToDate, 'yyyy-mm-dd');
      if (moment(TODATE).isSameOrAfter(DATE, 'day')) {
        setStartDate(formatDateTime(date, 'dd/mm/yyyy'));
        setOpenFromCalender(false);
        onCustomDateSelect({
          startDate: date,
          endDate: tempToDate
        });
        return;
      } else {
        setTempFromDate(date);
        setOpenFromCalender(false);
        setOpenToCalender(true);
        setEndDate(undefined);
        setStartDate(formatDateTime(date, 'dd/mm/yyyy'));
        dispatch(setTempStart_date(formatDateTime(date, 'yyyy-mm-dd')));
        return;
      }
    }
    onCustomDateSelect({
      startDate: date,
      endDate: tempToDate
    });
    setStartDate(formatDateTime(date, 'dd/mm/yyyy'));
    setTempFromDate(date);
    dispatch(setTempDateFormat('Custom'));
    setOpenFromCalender(false);
    setOpenToCalender(true);
  };

  const onEndDateSelect = (date) => {
    setEndDate(formatDateTime(date, 'dd/mm/yyyy'));
    dispatch(setTempEnd_Date(formatDateTime(date, 'yyyy-mm-dd')));
    setTempToDate(date);
    dispatch(setTempDateFormat('Custom'));
    onCustomDateSelect({
      startDate: tempFromDate,
      endDate: date
    });
    setOpenToCalender(false);
  };

  return (
    <div className="flex items-center">
      <Select
        name="day"
        options={dayOptions}
        className="input-field--sm cursor-pointer"
        placeholder=""
        readOnly
        containerStyle={{ maxWidth: 164, marginRight: 6 }}
        dropDownClassName="p-2"
        value={
          tempDateFormat === 'This month to date'
            ? 'This month'
            : tempDateFormat
        }
        onChange={onDaySelected}
        selectedValue={!isEmpty(selectedDay) ? selectedDay : dayOptions[1]}
      />
      <div style={{ maxWidth: 132, minWidth: 132, marginRight: 6 }}>
        <TextInputField
          name="startDate"
          placeholder={formatDateTime(new Date(tempStartDate), 'dd/mm/yyyy')}
          className={`input-field--sm input-field--icon-pos-left--sm ${
            tempDateFormat !== 'Custom' ? 'cursor-not-allowed' : ''
          }`}
          value={startDate ? startDate : ''}
          onClick={() => {
            if (tempDateFormat === 'Custom') {
              setOpenFromCalender(true);
            }
          }}
          renderIcon={() => {
            if (openFromCalender) {
              return (
                <CalenderIconPrimary
                  className="cursor-pointer"
                  onClick={() => setOpenFromCalender(true)}
                />
              );
            } else {
              return (
                <CalenderIcon
                  className="cursor-pointer"
                  onClick={() => {
                    if (tempDateFormat === 'Custom') {
                      setOpenFromCalender(true);
                    }
                  }}
                />
              );
            }
          }}
          iconClassName="input-icon-left"
          style={{ paddingLeft: 0 }}
          readOnly={true}
        >
          <div
            className={`dropdown-menu ${
              openFromCalender ? 'open' : ''
            } xxl:right-0 xxl:left-auto xxl:max-w-xs`}
            ref={fromDateRef}
            style={{ left: '-50px', minWidth: 280, maxWidth: 280, top: 40 }}
          >
            <div className="calender-select">
              <Calendar
                enableFutureDates={false}
                onDateClick={onStartDateSelect}
                selectedDate={new Date(tempStartDate)}
                containerStyle={{ width: '100%' }}
                hideToday={true}
              />
            </div>
          </div>
        </TextInputField>
      </div>
      <div style={{ maxWidth: 132, minWidth: 132 }}>
        <TextInputField
          name="endDate"
          placeholder={formatDateTime(new Date(tempEndDate), 'dd/mm/yyyy')}
          className={`input-field--sm input-field--icon-pos-left--sm ${
            tempDateFormat !== 'Custom' ? 'cursor-not-allowed' : ''
          }`}
          value={endDate ? endDate : ''}
          onClick={() => {
            if (tempDateFormat === 'Custom') {
              setOpenToCalender(true);
            }
          }}
          renderIcon={() => {
            if (openToCalender) {
              return (
                <CalenderIconPrimary
                  className="cursor-pointer"
                  onClick={() => setOpenToCalender(true)}
                />
              );
            } else {
              return (
                <CalenderIcon
                  className="cursor-pointer"
                  onClick={() => {
                    if (tempDateFormat === 'Custom') {
                      setOpenToCalender(true);
                    }
                  }}
                />
              );
            }
          }}
          iconClassName="input-icon-left"
          style={{ paddingLeft: 0 }}
          readOnly={true}
        >
          <div
            className={`dropdown-menu ${
              openToCalender ? 'open' : ''
            } xxl:right-0 xxl:left-auto xxl:max-w-xs`}
            ref={toDateRef}
            style={{ left: '-150px', minWidth: 280, maxWidth: 280, top: 40 }}
          >
            <div className="calender-select">
              <Calendar
                enableFutureDates={false}
                onDateClick={onEndDateSelect}
                selectedDate={new Date(tempEndDate)}
                containerStyle={{ width: '100%' }}
                hideToday={true}
                minDate={new Date(tempStartDate)}
              />
            </div>
          </div>
        </TextInputField>
      </div>
    </div>
  );
};

export default FilterSection;

import choices from 'choices';
import iconFbReply from 'assets/icons/icon-automation-fb.svg';
import iconAssignTo from 'assets/icons/icon-automation-assign.svg';
import iconMoveTo from 'assets/icons/icon-automation-moveTo-box.svg';
import iconEmailReply from 'assets/icons/icon-automation-email-reply.svg';
import iconSmsReply from 'assets/icons/icon-automation-sms-reply.svg';

const FACEBOOK = choices.ChannelChoices.FACEBOOK;
const INSTAGRAM = choices.ChannelChoices.INSTAGRAM;
const WATSAPP = choices.ChannelChoices.WHATSAPP;
const FACEBOOK_LEAD_ADS = choices.ChannelChoices.FACEBOOK_LEAD_ADS;
const WEBFORM = choices.ChannelChoices.WEBFORM;
const ZAPIER = choices.ChannelChoices.ZAPIER;
const SMS = choices.ChannelChoices.SMS;
const EBOT = choices.ChannelChoices.EBOT;

const {
  FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER,
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  MOVE_TO_BOX,
  SMS: SMS_REPLY
} = choices.AutomationChoice;

export const getRoutePath = (type) => {
  switch (type) {
    case WEBFORM:
      return 'webforms';
    case FACEBOOK:
      return 'facebook';
    case INSTAGRAM:
      return 'instagram';
    case WATSAPP:
      return 'whatsApp';
    case FACEBOOK_LEAD_ADS:
      return 'facebook-leads';
    case ZAPIER:
      return 'zapier';
    case SMS:
      return 'sms';
    case EBOT:
      return 'ebot';
  }
};

export const getConnectedChannel = (type) => {
  switch (type) {
    case WEBFORM:
      return 'Webform';
    case FACEBOOK:
      return 'Facebook Page';
    case INSTAGRAM:
      return 'Instagram';
    case WATSAPP:
      return 'Whats App';
    case FACEBOOK_LEAD_ADS:
      return 'Facebook Leads';
    case ZAPIER:
      return 'Zapier';
    case SMS:
      return 'sms';
    case EBOT:
      return 'Enquiry Bot';
  }
};

export const getChannelIcon = (type) => {
  switch (type) {
    case FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER:
      return iconFbReply;
    case ASSIGN_MEMBER:
      return iconAssignTo;
    case MOVE_TO_BOX:
      return iconMoveTo;
    case AUTORESPONDER_EMAIL:
      return iconEmailReply;
    case SMS_REPLY:
      return iconSmsReply;
    default:
      return iconEmailReply;
  }
};

import React, { useEffect, useState } from 'react';
import DescriptionAside from 'modules/toolbox/component/descriptionAside';
import { useSnackbar } from 'components/Snackbar';
import CustomColorButton from 'components/buttons/customColorButton';
import ToolboxVideoContent from 'modules/toolbox/component/videoContent';
import { useSelector, useDispatch } from 'react-redux';
import { AddWhatsAppToWaitList } from 'slices/toolbox/slice';
import { getMyInfo } from 'slices/myInfoSlice';

const WhatsApp = () => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const [isOnWaitList, setIsOnWaitList] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const [isHideBtn, setIsHideBtn] = useState(
    myInformation ? myInformation?.is_whatsapp_segment_triggered : false
  );

  useEffect(() => {
    if (myInformation) {
      setIsHideBtn(myInformation?.is_whatsapp_segment_triggered);
    }
  }, [myInformation]);

  const pricingFeatures = [
    'Send WhatsApp messages',
    'Receive WhatsApp messages',
    'Setup automated message'
  ];

  const callback = () => {
    dispatch(
      getMyInfo((data) => {
        openSuccessSnackBar('You’re on the waitlist!');
        setIsLoading(false);
        setIsOnWaitList(true);
      })
    );
  };

  const onClick = () => {
    setIsLoading(true);
    dispatch(AddWhatsAppToWaitList({ subOrgId: subOrganizationId, callback }));
  };
  return (
    <>
      <div className="toolbox__main overflow-overlay">
        <ToolboxVideoContent />
      </div>
      <div className="toolbox__aside overflow-overlay">
        <div>
          <div className="flex items-start justify-between">
            <div className="flex items-center">
              <span className="text-30 font-bold line-height-initial">£25</span>
              <span className="text-sm font-medium">&nbsp;&nbsp;/ month</span>
            </div>
            <div className="label-coming-soon ml-2">Coming soon</div>
          </div>
          <p className="text-13 letterspacing24em mt-4">
            Additional fees are charged directly by WhatsApp per message.{' '}
          </p>
          <ul className="mt-4">
            {pricingFeatures.map((item, index) => {
              return (
                <li
                  className="flex items-center text-13 letterspacing24em mt-1"
                  key={index}
                >
                  <code
                    className="inline-block bg-grey-900 rounded-full mr-2"
                    style={{ width: 3, height: 2 }}
                  ></code>
                  {item}
                </li>
              );
            })}
          </ul>
          {!isHideBtn && (
            <CustomColorButton
              label={
                isOnWaitList ? "You're on the waitlist 😎" : 'Join the waitlist'
              }
              className="btn bg-blue-dark text-white text-sm mt-6 w-full"
              onClick={!isOnWaitList ? onClick : () => {}}
              enableLoader
              isLoading={isLoading}
              loaderInline
            />
          )}
        </div>
        <div className="mt-8">
          <DescriptionAside />
        </div>
      </div>
    </>
  );
};

export default WhatsApp;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import Select from 'components/inputs/select';
import TextInputField from 'components/inputs/textField';
import Modal from 'components/modal';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, removeWhiteSpace } from 'utils/utils';
import { ReactComponent as SelectIcon } from 'assets/icons/icon-arrow-down.svg';
import CustomRadio from 'components/inputs/radio';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import EmojiModal from '../addCustomBox/modules/EmojiModal';
import { copyFromExistingBox, setBoxIcon } from 'slices/customBoxSlice';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import { useSnackbar } from 'components/Snackbar';
import { getAllBoxes } from 'slices/boxes/slice';
import ErrorMessage from 'components/errorMessage';

const CopyFromBoxModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const { boxes } = useSelector((state) => state.boxes);
  const [boxOptions, setBoxOptions] = useState([]);
  const [openEmoji, setOpenEmoji] = useState(false);
  const [selectedEmoji, setSelectedEmoji] = useState('&#127795;');
  const [selectedBox, setSelectedBox] = useState();
  const [boxName, setBoxName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopyAutomation, setIsCopyAutomation] = useState(false);
  const [error, setError] = useState({});

  useEffect(() => {
    setSelectedEmoji('&#127795;');
    setSelectedBox();
    setBoxName('');
    setIsCopyAutomation(false);
    setError({});
  }, [open]);

  useEffect(() => {
    if (!isEmpty(boxes)) {
      setBoxOptions(
        boxes.map((item) => {
          return {
            id: item.id,
            label: item.box_name,
            value: item.id,
            icon: item.box_avatar
          };
        })
      );
    }
  }, [boxes]);

  const checkValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(selectedBox)) {
      err = {
        ...err,
        box: 'box cannot be empty'
      };
      isValid = false;
    }
    if (isEmpty(boxName)) {
      err = {
        ...err,
        boxName: 'box name cannot be empty'
      };
      isValid = false;
    }
    if (isEmpty(selectedEmoji)) {
      err = {
        ...err,
        icon: 'icon cannot be empty'
      };
      isValid = false;
    }
    setError(err);
    return isValid;
  };

  const onBoxSelect = (data) => {
    delete error.box;
    setSelectedBox(data);
  };

  const onEmojiSelect = (data) => {
    delete error.icon;
    dispatch(setBoxIcon(data.emoji));
    setSelectedEmoji(data.emoji);
    setOpenEmoji(false);
  };

  const onSuccess = (data) => {
    setIsLoading(false);
    openSuccessSnackBar('Success');
    dispatch(getAllBoxes());
    setSelectedEmoji('&#127795;');
    navigate(
      `${route.dashboard}${route.boxes}/${data?.type_of_box}/${data?.id}`
    );
  };

  const onAdd = () => {
    const isValid = checkValid();
    if (isValid) {
      setIsLoading(true);
      const formData = {
        box_to_copy: selectedBox?.value,
        box_name: removeWhiteSpace(boxName),
        icon: selectedEmoji,
        copy_automations: isCopyAutomation
      };
      dispatch(copyFromExistingBox({ data: formData }, onSuccess));
    }
  };
  return (
    <Modal.Main open={open} className="modal--channels-request">
      <Modal.Header
        title="Copy from an existing box"
        onClose={() => {
          onClose();
        }}
      />
      <Modal.Body>
        <div
          className="w-full pb-6"
          // onClick={() => handleScroll()}
        >
          <label className="input-field-label">
            Select the box to copy from
          </label>
          <Select
            name="box"
            options={boxOptions}
            inputClassName="input-field--type2"
            dropDownClassName="p-2 dropdown-menu__box-list"
            placeholder="Select the box"
            readOnly={true}
            value={selectedBox?.label}
            error={!isEmpty(error) && !isEmpty(error.box)}
            style={{ padding: '9px 15px' }}
            onChange={onBoxSelect}
            resetValue
            isModalOpen={open}
            isHaveIcon={true}
            iconStyle={{
              fontSize: 16,
              marginRight: 8,
              maxWidth: 18,
              maxHeight: 18
            }}
          />
          {!isEmpty(error) && !isEmpty(error.box) && (
            <ErrorMessage message={error.box} />
          )}
        </div>
        <form className="w-full pb-6 gap-3" autoComplete="off">
          <label className="input-field-label">Box name</label>
          <TextInputField
            name="boxName"
            className="input-field--type2"
            placeholder="Give it a name that reflects what you will put in it"
            value={boxName}
            style={{ padding: '9px 15px' }}
            onChange={(e) => {
              delete error.boxName;
              setBoxName(e.target.value);
            }}
            error={!isEmpty(error) && !isEmpty(error.boxName)}
          />
          {!isEmpty(error) && !isEmpty(error.boxName) && (
            <ErrorMessage message={error.boxName} />
          )}
        </form>
        <div
          className="w-full pb-6 cursor-pointer"
          // onClick={() => handleScroll()}
        >
          <label className="input-field-label">Add an icon</label>
          <figure
            className="avatar-container avatar-container--contain cursor-pointer"
            style={{
              width: 70,
              height: 70,
              fontSize: '40px',
              boxShadow: 'inset 0px 0px 0px 1px rgb(0 0 0 / 5%)'
            }}
            onClick={() => setOpenEmoji(!openEmoji)}
          >
            <div dangerouslySetInnerHTML={{ __html: selectedEmoji }}></div>
          </figure>
          {/* <div className="cursor-pointer" onClick={() => setOpenEmoji(true)}>
            <TextInputField
              data-testid="search-input"
              name="icon"
              className={`input-field--select input-field--type2`}
              placeholder={`${isEmpty(selectedEmoji) ? 'Select the icon' : ''}`}
              readOnly={true}
              disabled={true}
              error={!isEmpty(error) && !isEmpty(error.icon)}
            >
              <div
                className="input-select-svg-dropdown"
                dangerouslySetInnerHTML={{ __html: selectedEmoji }}
                style={{ left: 20 }}
              ></div>
              <div className="input-select-svg-dropdown">
                <SelectIcon />
              </div>
            </TextInputField>
          </div> */}
          {openEmoji && (
            <div>
              <EmojiModal
                hideSearch={true}
                className="mt-1"
                setOpenEmoji={setOpenEmoji}
                onEmojiSelect={onEmojiSelect}
                style={{ height: 'calc(100% + 30px)' }}
              />
            </div>
          )}
          {!isEmpty(error) && !isEmpty(error.icon) && (
            <ErrorMessage message={error.icon} />
          )}
        </div>
        <div>
          <h3 className="text-15 font-semibold">
            Would you like to copy all existing automations
          </h3>
          <p className="text-xs text-grey-600">
            Automations will be turned off by default once copied
          </p>
          <div className="flex gap-10 mt-4">
            <label className="flex cursor-pointer items-center gap-1">
              <CustomRadio
                type="radio"
                value="yes"
                name="radio"
                checked={isCopyAutomation}
                medium
                onChange={() => setIsCopyAutomation(true)}
              />
              <p className="text-sm font-medium">Yes</p>
            </label>
            <label className="flex cursor-pointer items-center gap-1">
              <CustomRadio
                type="radio"
                value="no"
                name="radio"
                checked={!isCopyAutomation}
                medium
                onChange={() => setIsCopyAutomation(false)}
              />
              <p className="text-sm font-medium">No</p>
            </label>
          </div>
          <div className="flex justify-end gap-2 mt-9">
            <CustomColorButton
              label="Cancel"
              className="btn--grey min-w-120 btn--md"
              onClick={onClose}
            />
            <PrimaryButton
              label="Add"
              className="min-w-120 btn--md"
              enableLoader
              onClick={onAdd}
              isLoading={isLoading}
              loaderInline
            />
          </div>
        </div>
      </Modal.Body>
    </Modal.Main>
  );
};

export default CopyFromBoxModal;

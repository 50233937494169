/* eslint-disable no-unused-vars */
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import TextInputField from 'components/inputs/textField';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnswer,
  DeleteQuestion,
  updateAnswer,
  setIsShowQuestionForm,
  setSelectedQuestion,
  setIsEdit,
  setEditedData
} from 'slices/questionSlice';
import QuestionHeader from '../../../../component/questionHeader';
import Error from 'components/errorMessage';
import { isEmpty } from 'lodash';
import _debounce from 'lodash.debounce';
import { useParams } from 'react-router-dom';
import OverLayLoader from 'components/loader/overLayLoader';
import { questionTypes } from 'utils/questions';
import CHOICES from 'choices';

const TEXT = CHOICES.QuestionChoices.TEXT;

const TextQuestionAdded = ({
  questionData,
  title,
  answer,
  deleteQuestion,
  isBin
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId, box_id } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [inputValue, setInputValue] = useState(
    !isEmpty(answer) ? answer[0]?.answer?.description : ''
  );

  useEffect(() => {
    setInputValue(!isEmpty(answer) ? answer[0]?.answer?.description : '');
  }, [answer]);

  const { myInformation } = useSelector((state) => state.myInfo);

  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = () => {
    setIsLoading(false);
  };

  const onDeleteCallback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    deleteQuestion(questionId);
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(
      DeleteQuestion({
        questionId: questionId,
        box_id,
        callback: onDeleteCallback
      })
    );
  };

  // const onAnswerChange = _debounce((value) => {
  //   onSubmit(value);
  // }, 1500);

  const onAnswerChange = (value) => {
    setInputValue(value);
    setError('');
  };

  const onSubmit = () => {
    const previousAnswer = answer[0]?.answer?.description;
    if (previousAnswer === inputValue) return;
    if (inputValue === '') {
      setError('Answer cannot be empty');
    } else {
      if (isEmpty(answer)) {
        setIsLoading(true);
        const formData = {
          answer: { description: inputValue },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(createAnswer({ body: formData, stageId, callback }));
      } else {
        setIsLoading(true);
        const formData = {
          answer: { description: inputValue },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(
          updateAnswer({
            body: formData,
            answerId: answer[0]?.id,
            stageId,
            callback
          })
        );
      }
    }
  };

  const onEdit = () => {
    const editQuestion = questionTypes.filter((item) => item.type === TEXT);
    dispatch(setIsEdit(true));
    dispatch(setEditedData(questionData));
    dispatch(setIsShowQuestionForm(true));
    dispatch(setSelectedQuestion(editQuestion[0]));
  };

  return (
    <>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          showConfirmation={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this question?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ height: 'max-content' }}
          isLoading={isLoading}
        />
      )}
      <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
        {isLoading && <OverLayLoader />}
        <QuestionHeader
          label={title}
          isShowMoreIcon={true}
          id={questionData.id}
          onDelete={onDeleteQuestion}
          isBin={isBin}
          onEdit={onEdit}
        />
        <form className="py-4 px-5" autoComplete="off">
          <TextInputField
            name="answer"
            placeholder="Add answer"
            className="input-field--xs-34"
            onChange={(e) => onAnswerChange(e.target.value)}
            value={inputValue}
            onBlur={onSubmit}
            error={error ? true : false}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                e.preventDefault();
                onSubmit();
              }
            }}
            readOnly={isBin}
          />
          {error && <Error message={error} />}
        </form>
      </div>
    </>
  );
};

export default TextQuestionAdded;

/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import TextArea from 'components/inputs/textarea';
import { useDispatch, useSelector } from 'react-redux';
import {
  // getAllTaskOutcome,
  setAllCompletedTasks,
  setAllNewTasks,
  updateLeadTaskStatus
} from 'slices/task/slice';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';
import PrimaryButton from 'components/buttons/primary';
import { useSnackbar } from 'components/Snackbar';
import { getLeadData } from 'slices/conversationSlice';
import { useParams } from 'react-router-dom';
import { getLeadTasks } from 'slices/eboxLead';
import { getAllTasks, getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';

const { CALL, OTHERS } = CHOICES.TasksTypeChoices;
const { COMPLETED } = CHOICES.TaskStatusChoices;

const CompleteTask = ({ taskId, setCurrentTaskCompleted, task_type, data }) => {
  const dispatch = useDispatch();
  const { cardId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const { myInformation } = useSelector((state) => state.myInfo);
  const { taskOutcome, allCompletedTasks, allNewTasks } = useSelector(
    (state) => state.tasks
  );
  const { activeLead } = useSelector((state) => state.leadConversation);
  const [outComeId, setOutComeId] = useState(null); // state for storing outcome id
  const [note, setNote] = useState(''); // state for storing text area input value
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   // call task outcome api for specific task type
  //   if (task_type) {
  //     dispatch(
  //       getAllTaskOutcome({ subOrgId: subOrganizationId, task_type: task_type })
  //     );
  //   }
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, [task_type]);

  // function to execute after complete task api success
  const onSuccess = (data) => {
    // adding response to completed task state locally
    const updatedCompletedTasks = [data, ...allCompletedTasks];
    dispatch(setAllCompletedTasks(updatedCompletedTasks));
    // removing completed task from new task state locally
    const updatedNewTasks = allNewTasks.filter((item) => item.id !== data.id);
    dispatch(setAllNewTasks(updatedNewTasks));
    // calling lead data api if the overdue count is more than 0
    if (activeLead?.no_of_overdue_task_count > 0) {
      dispatch(getLeadData({ leadId: cardId }));
    }
    setCurrentTaskCompleted(false); // close the complete task screen
    dispatch(getAllTasks());
    dispatch(getTaskEnquiryCount());
    openSuccessSnackBar('Task completed');
  };

  const onComplete = () => {
    if (!outComeId && task_type !== OTHERS) {
      // throw error if there is no outcome selected, not for others task type
      setIsError(true);
    } else {
      setIsLoading(true);
      const payload = {
        task_id: taskId,
        status: COMPLETED,
        outcome_id: outComeId,
        outcome_descriptions: note,
        completed_by: myInformation?.id
      };
      // task status update api
      dispatch(updateLeadTaskStatus(payload, onSuccess));
    }
  };

  return (
    <div className="outcome-types">
      {!isEmpty(data) && (
        <h4 className="text-15 font-medium">
          {`${
            task_type === CALL
              ? 'What was the outcome of your call? 👇'
              : 'How did you follow up? 👇'
          }`}
        </h4>
      )}
      <ul className="flex items-center flex-wrap mt-4">
        {!isEmpty(data) &&
          data.map((item) => (
            <li
              key={item.id}
              className="toggle-check-btn-container"
              onClick={() => {
                setIsError(false);
                setOutComeId(item.id);
              }}
            >
              <input
                id={item.id}
                type="radio"
                className="toggle-check-btn__input"
                name="outcome-types"
              />
              <label htmlFor={item.id} className="toggle-check-btn">
                {item.options}
              </label>
            </li>
          ))}
      </ul>
      {/* <p className="text-primary text-15 mt-1">
        Click each outcome box to edit the text (20 characters max)
      </p> */}
      <div className="mt-3">
        <TextArea
          name="tasks"
          value={note}
          onChange={(e) => setNote(e.target.value)}
          style={{ color: '#636566', fontWeight: 400 }}
          className="border text-base-1 resize-none"
          rows="2"
          cols="2"
          placeholder={`${
            task_type === CALL
              ? 'Add some details about the call'
              : task_type === OTHERS
              ? 'Add message'
              : 'What was the outcome of your follow up?'
          }`}
        />
      </div>
      <div className="flex items-center mt-3">
        <PrimaryButton
          label="Complete task"
          className="btn--xs"
          onClick={onComplete}
          enableLoader
          isLoading={isLoading}
          loaderInline
        />
        <button
          className="btn btn--secondary btn--xs ml-2"
          onClick={() => {
            setCurrentTaskCompleted(false);
          }}
        >
          Cancel
        </button>
        {isError && (
          <p className="text-red-900 text-base-1 ml-4">
            {task_type === CALL
              ? 'Select an outcome to complete the task'
              : 'Select a follow up to complete the task'}
          </p>
        )}
      </div>
    </div>
  );
};

export default CompleteTask;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unreachable */
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { setCurrentBoxId } from 'slices/boxes/slice';
// import Boxes from './index';
// import Home from './home';
import { isEmpty } from 'utils/utils';
import { useSelector, useDispatch } from 'react-redux';
import { Loader } from 'components/loader';
// import GeneralBoxSettings from './settings/home';
import AddBoxTemplate from './addCustomBox/home';
import Main from './main';

const BoxesRoute = () => {
  const { boxes } = useSelector((state) => state.boxes);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setCurrentBoxId(null));
    };
  }, []);

  if (isEmpty(boxes)) {
    return (
      <section className="dashboard__main">
        <div className="flex justify-center items-center h-full w-full">
          <Loader />
        </div>
      </section>
    );
  }

  return (
    <Routes>
      <Route path={`/:box_type/:box_id/*`} element={<Main />} />
      {/* <Route
        path={`/:box_type/:box_id/settings/*`}
        element={<GeneralBoxSettings />}
      /> */}
      <Route path={`/add-box-template/*`} element={<AddBoxTemplate />} />
    </Routes>
  );
};

export default BoxesRoute;

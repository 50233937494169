import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { BRAIN_CONTENT_COUNT } from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';

const initialState = {
  brainData: []
};

const reducers = {
  setBrainData: (state, action) => {
    state.brainData = action.payload;
  }
};
export const brainPage = createSlice({
  name: 'brainPage',
  initialState,
  reducers
});
export const { setBrainData } = brainPage.actions;

export default brainPage.reducer;
export const getBrainCount = (assistant_id) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(BRAIN_CONTENT_COUNT, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBrainData(res.data));
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
};

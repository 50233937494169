import Avatar from 'components/avatar';
import Message from './messages';
import Options from './options';
const BotResponse = ({
  messages,
  options,
  handleStartConversation,
  history,
  avatar,
  name
}) => {
  return (
    <div className="chat-bot-list">
      <div className="chat-bot-list__container">
        <div className="chat-bot-list__item">
          <Avatar
            className="chat-bot-list__avatar avatar-container avatar-container--full"
            src={avatar}
            initial={name}
            singleChar
            small
            initialStyle={{ fontSize: '16px' }}
          />
          <div className="chat-bot-list__content">
            <Message messages={messages} />
            <Options
              options={options}
              handleStartConversation={handleStartConversation}
              history={history}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BotResponse;

/* eslint-disable no-unused-vars */
import React from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-green.svg';
// import { Route, Routes, Link } from 'react-router-dom';
// import Settings from './settings';
// import ChannelEbBox from './list/ebBox';
import ChannelSettingsLink from 'modules/enquirybox/channels/components/channelSettingsLink';
import { route } from 'utils/route';
import { useSelector, useDispatch } from 'react-redux';
import { getConnectedChannel } from 'utils/channels';
import { getRoutePath } from 'utils/channels';
import EmptyChannelState from './emptyState';
import { isEmpty } from 'utils/utils';
import { Link } from 'react-router-dom';
import CHOICES from 'choices';

const { WEBFORM, ZAPIER, FACEBOOK_LEAD_ADS, EBOT } = CHOICES.ChannelChoices;

const ConnectedChannelsList = () => {
  const { allPendingChannels, allConnectedChannels } = useSelector(
    (state) => state.channels
  );

  if (isEmpty(allConnectedChannels)) {
    return (
      <div
        className="dashboard__main__body__section-left overflow-overlay toolbox__navigation channels-navigation"
        style={{ padding: '22px 34px 24px' }}
      >
        <EmptyChannelState />
      </div>
    );
  }

  return (
    <div className="dashboard__main__body__section-left overflow-overlay toolbox__navigation channels-navigation">
      <Link
        className="flex items-center cursor-pointer py-3"
        to={`${route.channels}/connect-channel`}
      >
        <figure
          className="bg-grey-90 flex justify-center items-center"
          style={{ width: 42, height: 42, borderRadius: '50%' }}
        >
          <PlusIcon width={12} height={12} />
        </figure>
        <p className="ml-4 text-15 font-semibold text-primary truncate">
          Connect a new channel
        </p>
      </Link>
      <div className="mt-6">
        <h2 className="text-13 font-medium text-grey-700">
          CONNECTED CHANNELS
        </h2>
        {allConnectedChannels.map((item) => {
          const { id, channel, name, has_error_in_connection } = item;
          // const isHide = channel.channel_type === ZAPIER ? true : false;
          // if (isHide) return <div key={id}></div>;
          return (
            <ChannelSettingsLink
              key={id}
              title={name || channel?.channel_name}
              subTitle={getConnectedChannel(channel.channel_type)}
              icon={channel.avatar_link}
              to={`${route.channels}/${getRoutePath(channel.channel_type)}/${
                channel.channel_type
              }/connected/${id}${
                channel.channel_type === WEBFORM ||
                channel.channel_type === EBOT ||
                channel.channel_type === ZAPIER
                  ? '/settings'
                  : '/automations'
              }`}
              channelId={id}
              isReconnect={has_error_in_connection}
            />
          );
        })}
      </div>
    </div>
  );
};

export default ConnectedChannelsList;

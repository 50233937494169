import React, { useRef, useState } from 'react';
import dotsIndicator from 'assets/icons/icon-more-round.svg';
import iconDelete from 'assets/icons/icon-Delete.svg';
import iconEdit from 'assets/icons/icon-edit.svg';
import CustomToolTip from 'components/CustomTooltip';
import iconCircle from 'assets/icons/icon-circle.svg';
import iconCircleCheck from 'assets/icons/icon-circle-check.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/icon-task-type-call.svg';
import { ReactComponent as FollowUpIcon } from 'assets/icons/icon-task-type-follow-up-grey.svg';
import { ReactComponent as OtherIcon } from 'assets/icons/icon-task-type-other-grey.svg';
import { ReactComponent as PhoneIconPrimary } from 'assets/icons/icon-task-type-call-primary.svg';
import { ReactComponent as FollowUpIconPrimary } from 'assets/icons/icon-task-type-follow-up.svg';
import { ReactComponent as OtherIconPrimary } from 'assets/icons/icon-task-type-other.svg';
// import moment from 'moment/moment';
import CHOICES from 'choices';
import { useDispatch } from 'react-redux';
import {
  setAssignUser,
  setEditableTaskId,
  setTaskDate,
  setTaskDateType,
  setTaskTime,
  setTaskTitle,
  setTaskType,
  setTaskTypeTitle
} from 'slices/task/slice';
import useClickOutside from 'hooks/useOutsideClick';
import {
  formatDateTime,
  isDateAndTimeOverdue,
  prepareDateAndTime
} from 'utils/dateFormat';
import { CUSTOM_DATE } from 'utils/constants';
import { whoever_is_available } from 'slices/organizationuserSlice';
import automateIcon from 'assets/icons/icon-automated-email-icon.svg';

const { CALL, FOLLOW_UP, OTHERS } = CHOICES.TasksTypeChoices;

const Task = (props) => {
  const {
    setCurrentTaskCompleted,
    title,
    dueDate,
    taskType,
    id,
    assignTo,
    assignedUser,
    setOpenDeleteModal,
    createdUser,
    isAutomation,
    isHover
  } = props;
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const [showOptions, setShowOptions] = useState(false);

  // to close the dropdown when user click outside
  useClickOutside(() => setShowOptions(false), dropdownRef.current);

  const renderSvg = (type) => {
    // function to render icons according to task_type
    switch (type) {
      case CALL:
        return id === isHover ? <PhoneIconPrimary /> : <PhoneIcon />;
      case FOLLOW_UP:
        return id === isHover ? <FollowUpIconPrimary /> : <FollowUpIcon />;
      case OTHERS:
        return id === isHover ? <OtherIconPrimary /> : <OtherIcon />;
      default:
        return <PhoneIcon />;
    }
  };

  // edit task function
  const onEdit = (e) => {
    e.stopPropagation();
    const { date, time } = prepareDateAndTime(dueDate);
    setShowOptions(false);
    dispatch(setEditableTaskId(id));
    dispatch(setTaskTitle(title));
    dispatch(setTaskType(taskType));
    dispatch(setTaskTypeTitle(CHOICES.TasksTypeChoices.CHOICES[taskType]));
    dispatch(setTaskDateType(CUSTOM_DATE));
    dispatch(setTaskDate(date));
    dispatch(setTaskTime(time));
    if (assignTo === null) {
      dispatch(setAssignUser(whoever_is_available));
    } else {
      dispatch(setAssignUser({ ...assignedUser, user_id: assignedUser.id }));
    }
  };

  const getDueDateTime = (due_date) => {
    // function to get the date in Jan 13, 9:00 am format
    const { date, time } = prepareDateAndTime(due_date, { minute: true });
    return `${formatDateTime(date, 'mmm dd')}, ${time}`;
  };

  return (
    <div
      className="flex items-center justify-between"
      onMouseLeave={() => setShowOptions(false)}
    >
      <div className="contact-tasks__list__left">
        <h4 className="contact-tasks__list__title text-15 font-medium pre">
          {title}
        </h4>
        <div className="flex items-center mt-1">
          {isAutomation ? (
            <figure
              className="avatar-container"
              style={{ width: 25, height: 25, backgroundColor: '#fff' }}
            >
              <img
                src={automateIcon}
                alt=""
                style={{ width: 10, height: 13 }}
              />
            </figure>
          ) : (
            renderSvg(taskType)
          )}
          {/* <img src={phone} alt="phone" /> */}
          <div className="font-medium text-13 ml-2">
            {isAutomation
              ? 'Automated task'
              : `${createdUser?.name || createdUser.first_name}`}{' '}
            <span
              className={`${
                isDateAndTimeOverdue(dueDate)
                  ? 'text-red-900 '
                  : 'text-grey-700'
              }text-13 font-normal ml-1`}
            >
              {`Due on ${getDueDateTime(dueDate)}`}
            </span>
          </div>
        </div>
      </div>
      <div className="contact-tasks__list__right">
        <div
          className={`relative dots-action-btn mr-3`}
          onClick={() => {
            setShowOptions(true);
          }}
        >
          <img src={dotsIndicator} alt="" style={{ width: 16, height: 4 }} />
          <div
            className={`dropdown-menu dropdown-menu--more ${
              showOptions ? 'open' : ''
            }`}
            style={{
              top: '100%',
              right: 0,
              padding: '8px',
              width: '150px'
            }}
            ref={dropdownRef}
          >
            <div className="dropdown-menu__list" onClick={(e) => onEdit(e)}>
              <img
                src={iconEdit}
                alt=""
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Edit task
            </div>
            <div
              className="dropdown-menu__list red-text"
              onClick={(e) => {
                e.stopPropagation();
                setShowOptions(false);
                setOpenDeleteModal(id);
              }}
            >
              <img
                src={iconDelete}
                alt=""
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Delete task
            </div>
          </div>
        </div>
        <CustomToolTip
          position="left"
          tooltipText="Complete task"
          tooltipStyle={{
            width: 'initial',
            top: -2,
            right: '115%',
            bottom: 'initial',
            whiteSpace: 'noWrap',
            padding: '5px 20px 5px 10px'
          }}
        >
          <div
            className="task-complete-circle"
            onClick={() => {
              setCurrentTaskCompleted(id);
            }}
          >
            <img
              src={iconCircle}
              alt=""
              className="task-complete-circle__outline"
              style={{ width: 25, height: 25 }}
            />
            <img
              src={iconCircleCheck}
              className="task-complete-circle__check"
              alt=""
              style={{
                width: 25,
                height: 25
              }}
            />
          </div>
        </CustomToolTip>
      </div>
    </div>
  );
};

export default Task;

import React from 'react';
import PropTypes from 'prop-types';

const ListItem = (props) => {
  const { title, className, isPrimaryText } = props;

  return (
    <li className={className}>
      <code className="inline-block w-2 h-2 bg-grey-900 rounded-full mr-15"></code>
      {title}
      <span className="text-primary cursor-pointer">
        {isPrimaryText ? isPrimaryText : ''}
      </span>
    </li>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  isPrimaryText: PropTypes.string
};

export default ListItem;

import React from 'react';
import EnquirySource from 'modules/enquirybox/reports/components/enquirySource';
import EnquiryTime from 'modules/enquirybox/reports/components/enquiryTime';
import FilterSectionPublic from 'modules/enquirybox/reports/components/FilterSectionPublic';
import { useSelector } from 'react-redux';

const SourceReportPublic = (props) => {
  const { startDate, endDate, reportType, date_format } = props;
  const { sourceReport } = useSelector((state) => state.reports);
  return (
    <div
      className="bg-grey-300 rounded-lg"
      style={{
        margin: 'auto',
        marginTop: 50,
        maxWidth: '1000px',
        padding: 25,
        maxHeight: 'calc(100vh - 125px)'
      }}
    >
      <div className="source-report">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold">Source report</h3>
          <FilterSectionPublic
            endDate={endDate}
            startDate={startDate}
            reportType={reportType}
            date_format={date_format}
          />
        </div>
        <div className="flex items-center -mx-3 mt-6">
          <EnquirySource
            paidTotal={sourceReport?.report?.total_paid_enquiries}
            paidPercentage={
              sourceReport?.report?.total_paid_enquiries_percentage
            }
            unPaidTotal={sourceReport?.report?.total_unpaid_enquiries}
            unPaidPercentage={
              sourceReport?.report?.total_unpaid_enquiries_percentage
            }
          />
          <EnquiryTime
            duringBusinessHourTotal={
              sourceReport?.report?.total_enquiries_in_business_hours
            }
            duringBusinessHourPercentage={
              sourceReport?.report?.total_enquiries_in_business_hours_percentage
            }
            outsideBusinessHourTotal={
              sourceReport?.report?.total_enquiries_out_of_business_hours
            }
            outsideBusinessHourPercentage={
              sourceReport?.report
                ?.total_enquiries_out_of_business_hours_percentage
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SourceReportPublic;

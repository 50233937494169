import React, { useEffect, useState, useRef } from 'react';
import Toggle from 'components/inputs/toggle';
import DataCollectionList from './DataCollectionList';
import { useParams } from 'react-router-dom';
import {
  getAllDataCollectionFields,
  reorderDataItems,
  setComplianceMessage,
  setDataFields,
  setPrivacyPolicyLink,
  updateDataCollectionFields
} from 'slices/ai-assistant/dataCollection/slice';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import TextInputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import { isEmpty } from 'utils/utils';
import { useSnackbar } from 'components/Snackbar';
import { setDataCollectionErrors } from 'slices/ai-assistant/assistant/slice';
import {
  defaultDataCollectionErrors,
  showFilteredFields
} from 'utils/aiAssistant';
import InfoIconComponent from '../components/InfoIcon';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import CustomTextArea from '../Personality/CustomTextArea';

const DataCollectionTab = () => {
  const dispatch = useDispatch();
  const { assistant_id } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const [dragStart, setDragStart] = useState(null);
  const { currentVersionId } = useSelector((state) => state.assistant);
  const dataCollection = useSelector((state) => state.dataCollection);
  const dataCollectionErrors = useSelector(
    (state) => state.assistant.dataCollectionErrors
  );
  const [isComplianceFocused, setIscomplianceFocused] = useState(false);
  const {
    data_fields,
    compliance_enabled,
    compliance_message,
    privacy_policy_link
  } = dataCollection;
  const privacyPolicyRef = useRef();
  const compliancInputRef = useRef(null);
  const complianceDivRef = useRef(null);
  const [isError, setIsError] = useState(
    showFilteredFields(dataCollectionErrors, defaultDataCollectionErrors)
  );
  const [localPrivacyPolicyLink, setLocalPrivacyPolicyLink] = useState(
    isEmpty(privacy_policy_link) ? '' : privacy_policy_link
  );
  const [localComplianceMessage, setLocalComplianceMessage] =
    useState(compliance_message);
  const [loading, setLoading] = useState();
  const { data_field = true } = dataCollectionErrors || {};
  const onDragEnd = (result) => {
    const { destination, source } = result;
    if (!result.destination) {
      setDragStart(false);
      return;
    }
    dispatch(
      reorderDataItems({
        data_field_id: data_fields[source.index].id,
        version_id: currentVersionId,
        position: destination.index
      })
    );
    let newdata = Array.from(data_fields);
    const [reorderedItem] = newdata.splice(source.index, 1);
    newdata.splice(destination.index, 0, reorderedItem);
    dispatch(setDataFields(newdata));
    setDragStart(false);
  };
  const handleAdd = (id) => {
    dispatch(
      setDataFields([
        ...data_fields,
        {
          id: id,
          field_name: '',
          status: 'New',
          need_compliance: false,
          is_mandatory: false
        }
      ])
    );
  };

  useEffect(() => {
    if (!isEmpty(dataCollectionErrors)) {
      setIsError(
        showFilteredFields(dataCollectionErrors, defaultDataCollectionErrors)
      );
    }
  }, [dataCollectionErrors]);

  const HandleInputChange = (item, e, index) => {
    const { value } = e.target;
    let currentData_fields = [...data_fields];
    if (item.status === 'New') {
      currentData_fields.splice(index, 1, {
        ...currentData_fields[index],
        id: item.id,
        field_name: value
      });
    } else {
      currentData_fields.splice(index, 1, {
        ...currentData_fields[index],
        data_field_id: item.id,
        field_name: value
      });
    }
    dispatch(setDataFields(currentData_fields));
  };

  useEffect(() => {
    dispatch(getAllDataCollectionFields(assistant_id));
  }, [assistant_id]);

  const handleSuccessGDPR = (id, resData) => {
    if (!isEmpty(dataCollectionErrors)) {
      let errorFields = {
        gdpr_field: resData?.compliance_enabled
          ? resData?.data_fields?.some((i) => i.need_compliance)
          : true,
        compliance_message: resData?.compliance_enabled
          ? !isEmpty(localComplianceMessage)
            ? true
            : false
          : true,
        privacy_policy_link: resData?.compliance_enabled
          ? !isEmpty(localPrivacyPolicyLink)
            ? true
            : false
          : true
      };
      if (resData.compliance_enabled) {
        dispatch(
          setDataCollectionErrors({
            ...dataCollectionErrors,
            ...errorFields
          })
        );
      } else if (!resData.compliance_enabled) {
        let newObj = {
          data_field: !isEmpty(resData?.data_fields) ? true : false
        };
        dispatch(setDataCollectionErrors(newObj));
      }
    }
  };
  const handleSubmit = (payload) => {
    payload.version_id = currentVersionId;
    dispatch(
      updateDataCollectionFields(assistant_id, payload, handleSuccessGDPR)
    );
  };

  const successResponse = (id, data, objType) => {
    openSuccessSnackBar('Updated successfully', 'medium');
    let currentErrors = { ...dataCollectionErrors };
    currentErrors[objType['type']] = true;
    dispatch(setDataCollectionErrors(currentErrors));
    document.activeElement.blur();
    dispatch(setPrivacyPolicyLink(localPrivacyPolicyLink));
    dispatch(setComplianceMessage(localComplianceMessage));
  };

  useEffect(() => {
    if (!isEmpty(privacy_policy_link))
      setLocalPrivacyPolicyLink(privacy_policy_link);
    if (!isEmpty(compliance_message))
      setLocalComplianceMessage(compliance_message);
  }, [privacy_policy_link, compliance_message]);

  useEffect(() => {
    if (!isEmpty(localPrivacyPolicyLink)) {
      setIsError({
        ...isError,
        privacy_policy_link: ''
      });
      privacyPolicyRef.current.style.border = '';
    }
  }, [localPrivacyPolicyLink]);

  useEffect(() => {
    if (!isEmpty(localComplianceMessage)) {
      setIsError({
        ...isError,
        compliance_message: ''
      });
    }
  }, [localComplianceMessage]);

  const failedResponse = () => {
    document.activeElement.blur();
    setLocalPrivacyPolicyLink(privacy_policy_link);
    setLocalComplianceMessage(compliance_message);
  };

  const callApiOnKeyPress = (payload, type) => {
    dispatch(
      updateDataCollectionFields(
        assistant_id,
        payload,
        successResponse,
        failedResponse,
        type
      )
    );
  };
  const onKeyPress = (e, payload, type) => {
    let newPayload = {
      ...payload,
      version_id: currentVersionId
    };
    callApiOnKeyPress(newPayload, type);
  };
  const handleLoading = (loading) => {
    setLoading(loading);
  };
  const onComplianceMessageSubmit = (e, onBlur) => {
    if (isEmpty(localComplianceMessage)) {
      setIsError({
        ...isError,
        compliance_message: 'Compliance messege cannot be empty'
      });
    } else if (e.charCode === 13) {
      onKeyPress(
        e,
        { compliance_message: localComplianceMessage },
        { type: 'compliance_message' }
      );
      return;
    } else if (onBlur && !isEmpty(localComplianceMessage)) {
      onKeyPress(
        e,
        { compliance_message: localComplianceMessage },
        { type: 'compliance_message' }
      );
      return;
    }
  };
  const onPrivacyPolicySubmit = (e) => {
    e.preventDefault();
    document.activeElement.blur();
    if (urlFieldValidation.test(localPrivacyPolicyLink))
      onKeyPress(
        e,
        { privacy_policy_link: localPrivacyPolicyLink },
        { type: 'privacy_policy_link' }
      );
    else {
      setIsError({
        ...isError,
        privacy_policy_link: 'Enter valid URL'
      });
    }
  };
  const checkGDPR = () => {
    return (
      !isEmpty(dataCollectionErrors) &&
      Object?.prototype?.hasOwnProperty.call(
        dataCollectionErrors,
        'gdpr_field'
      ) &&
      !dataCollectionErrors?.gdpr_field
    );
  };
  const setHeightOfTextArea = (divRef, inputRef) => {
    if (divRef?.current) {
      const offsetHeight = divRef?.current?.offsetHeight;
      if (offsetHeight > 190) {
        inputRef.current.style.height = '190px';
      } else {
        inputRef.current.style.height = `${offsetHeight}px`;
      }
    }
  };

  return (
    <section className="" style={{ padding: '25px 0' }}>
      <div
        className="border-b border-grey-400"
        style={{ padding: '0 38px 40px' }}
      >
        <label className="input-field-label mb-3">
          Select the data you want to collect{' '}
          <InfoIconComponent tooltipText="Select the data you want to collect" />
        </label>
        <p className="text-grey-800 text-base">
          Select the data you want to collect. Your bot will ask for this data
          in a question format in the order defined below from top to bottom.
        </p>
        {isEmpty(data_fields) && !data_field && (
          <p
            className="text-base mt-7"
            style={{
              color: '#F55E5E',
              fontFamily: 'SF Pro Text'
            }}
          >
            Please fill the fields
          </p>
        )}
        <div className="mt-9">
          <DragDropContext
            onBeforeDragStart={(e) => {
              setDragStart(e.draggableId);
            }}
            onDragEnd={onDragEnd}
          >
            <Droppable droppableId="data-collection">
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={!isEmpty(data_fields) ? 'inline-block' : ''}
                >
                  {data_fields?.map((item, index) => {
                    return (
                      <Draggable
                        key={index}
                        draggableId={index.toString()}
                        index={index}
                        isDragDisabled={item.status === 'New' ? true : false}
                      >
                        {(provided, snapshotDrag) => {
                          // Restrict dragging to vertical axis
                          let transform =
                            provided.draggableProps.style.transform;

                          if (snapshotDrag.isDragging && transform) {
                            transform = transform.replace(/\(.+,/, '(0,');
                          }

                          const style = {
                            ...provided.draggableProps.style,
                            transform
                          };
                          return (
                            <div
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              ref={provided.innerRef}
                              style={style}
                            >
                              <DataCollectionList
                                assistant_id={assistant_id}
                                HandleInputChange={HandleInputChange}
                                item={item}
                                value={item.field_name}
                                index={index}
                                dragStart={dragStart}
                                id={index.toString()}
                                isComplianceField={false}
                                isLoading={loading}
                                handleLoading={handleLoading}
                              />
                            </div>
                          );
                        }}
                      </Draggable>
                    );
                  })}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>

        <div
          onClick={() => {
            handleAdd(data_fields.length + 1);
          }}
          className={`text-primary text-lg font-medium mt-5 ml-9 cursor-pointer ${
            !isEmpty(data_fields) ? 'inline-block' : ''
          }`}
        >
          + Add a question
        </div>
      </div>
      <div style={{ padding: '36px 38px 40px' }}>
        <div className="flex items-center">
          <label className="input-field-label mb-0">
            GDPR Compliance <InfoIconComponent tooltipText="GDPR Compliance" />
          </label>
          <Toggle
            checked={compliance_enabled}
            onChange={(e) => {
              handleSubmit({ compliance_enabled: e.target.checked });
            }}
            register={() => {}}
            id="compliance_enabled"
            small
          />
        </div>
        <p className="text-grey-800 text-base mt-3">
          Select the questions to show a compliance message before asking. It
          will only show once.
        </p>
        {checkGDPR() && (
          <p
            className="text-base mt-5"
            style={{
              color: '#F55E5E',
              fontFamily: 'SF Pro Text'
            }}
          >
            {isEmpty(data_fields)
              ? 'Please add data field and select any checkbox from below'
              : 'Please select any checkbox below'}
          </p>
        )}
        <div className="mt-8">
          {data_fields.map((item, index) => {
            return (
              <li key={index}>
                <DataCollectionList
                  item={item}
                  index={index}
                  isComplianceField={true}
                />
              </li>
            );
          })}
        </div>
        <div className="mt-9 flex flex-col">
          <label className="input-field-label">
            Compliance message{' '}
            <InfoIconComponent tooltipText="Compliance message" />
          </label>
          <CustomTextArea
            ref={compliancInputRef}
            name="Compliance message"
            value={
              isEmpty(localComplianceMessage) ? '' : localComplianceMessage
            }
            className={`resize-none ${isComplianceFocused ? '' : 'hidden'}`}
            error={!isEmpty(isError) && isError['compliance_message']}
            onChange={(e) => {
              setLocalComplianceMessage(e.target.value);
            }}
            onKeyPress={(e) => {
              if (localComplianceMessage !== compliance_message)
                onComplianceMessageSubmit(e, false);
            }}
            onBlur={(e) => {
              setIscomplianceFocused(false);
              if (localComplianceMessage !== compliance_message)
                onComplianceMessageSubmit(e, true);
            }}
          />
          <div
            ref={complianceDivRef}
            onClick={() => {
              setHeightOfTextArea(complianceDivRef, compliancInputRef);
              setIscomplianceFocused(true);
              setTimeout(() => {
                compliancInputRef.current.focus();
              }, 10);
            }}
            className={`input-field ${isComplianceFocused ? 'hidden' : ''} ${
              !isEmpty(isError) && isError['compliance_message'] ? 'error' : ''
            } `}
            style={{
              letterSpacing: 'normal',
              minHeight: '54px',
              width: '100%',
              wordWrap: 'break-word'
            }}
          >
            {isEmpty(localComplianceMessage) ? '' : localComplianceMessage}
          </div>
          {!isEmpty(isError) && isError['compliance_message'] && (
            <ErrorMessage error={isError['compliance_message']} />
          )}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (localPrivacyPolicyLink !== privacy_policy_link)
                document.activeElement.blur();
            }}
          >
            <TextInputField
              autoComplete={'off'}
              name="Privacy policy"
              ref={privacyPolicyRef}
              type="text"
              onBlur={(e) => {
                if (localPrivacyPolicyLink !== privacy_policy_link)
                  onPrivacyPolicySubmit(e);
              }}
              className="input-field mt-3"
              value={localPrivacyPolicyLink}
              placeholder="Add your privacy policy url or terms and conditions link"
              onChange={(e) => {
                setLocalPrivacyPolicyLink(e.target.value);
              }}
              error={
                !isEmpty(isError) && isError['privacy_policy_link']
                  ? true
                  : false
              }
            />
            <p className="text-sm text-grey-500">
              * Link should start with https:// or http://
            </p>
          </form>
        </div>
      </div>
    </section>
  );
};

export default DataCollectionTab;

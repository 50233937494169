import React from 'react';
import choices from 'choices';
import FacebookSubIcon from 'assets/icons/fb-sub-icon2.png';
import InstagramSubIcon from 'assets/icons/instagram-subIcon.svg';
// import { ReactComponent as WhatappSubIcon } from 'assets/icons/whatappSubIcon.svg';
import { ReactComponent as WebfromSubIcon } from 'assets/icons/webfromSubIcon.svg';
import IconManualEnquiry from 'assets/icons/icon-manual-enquiry.png';
import FacebookLeadAdsIcon from 'assets/icons/fb-lead-ads.png';
import smsIcon from 'assets/icons/icon-sms-msg.svg';
import ebotIcon from 'assets/icons/eb_icon_1.svg';
import zapierIcon from 'assets/icons/icon-channel-zapier.svg';

const SubIcon = ({ kind }) => {
  const ChannelType = (kind) => {
    switch (kind) {
      case choices.LeadChannelChoice.FACEBOOK:
        return (
          <img
            className="rounded-md"
            width={16}
            height={16}
            src={FacebookSubIcon}
          />
        );
      case choices.LeadChannelChoice.INSTAGRAM:
        return (
          <img
            className="rounded-md"
            width={20}
            height={18}
            src={InstagramSubIcon}
          />
        );
      // case choices.LeadChannelChoice.WHATSAPP:
      //   return <WhatappSubIcon />;
      case choices.LeadChannelChoice.WEB_FORMS:
        return <WebfromSubIcon />;
      case choices.LeadChannelChoice.FACEBOOK_LEADGEN:
        return <img className="rounded-md" src={FacebookLeadAdsIcon} />;
      case choices.LeadChannelChoice.MANUALLY_ADDED:
        return (
          <img
            className="rounded-md"
            src={IconManualEnquiry}
            style={{ padding: '0px', width: 15, height: 15 }}
          />
        );
      case choices.LeadChannelChoice.IMPORTED_FROM_FILE:
        return (
          <img
            className="rounded-md"
            src={IconManualEnquiry}
            style={{ padding: '0px', width: 15, height: 15 }}
          />
        );
      case choices.LeadChannelChoice.SMS:
        return (
          <img
            className="rounded-md"
            src={smsIcon}
            style={{ width: 14, height: 14 }}
          />
        );
      case choices.LeadChannelChoice.EBOT:
        return (
          <img
            className="rounded-md"
            src={ebotIcon}
            style={{ width: 14, height: 14 }}
          />
        );
      case choices.LeadChannelChoice.ZAPIER:
        return (
          <img
            className="rounded-md"
            src={zapierIcon}
            style={{ width: 14, height: 14 }}
          />
        );
      case choices.LeadChannelChoice.AI_CHAT_BOT:
        return <WebfromSubIcon />; // this is webform icon. Will change this to chatbot icon later
    }
  };
  return <>{ChannelType(kind)}</>;
};

export default SubIcon;

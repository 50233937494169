import React from 'react';
import PrimaryButton from 'components/buttons/primary';
import FacebookRounded from 'assets/icons/facebook_small_rounded.svg';
import InstagramRounded from 'assets/icons/instagram_small_rounded.svg';
import SmsRounded from 'assets/icons/sms_small_rounded.svg';
import WebformRounded from 'assets/icons/webform_small_rounded.svg';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';

const EmptyChannelConnection = () => {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-center bg-white p-4 rounded-xl mb-2">
      <div className="avatar-stack mt-5">
        <div className="avatar">
          <img
            src={WebformRounded}
            alt="Avatar 1"
            style={{ width: '20px', height: '20px' }}
          />
        </div>
        <div className="avatar">
          <img
            src={SmsRounded}
            alt="Avatar 1"
            style={{ width: '18px', height: '18px' }}
          />
        </div>
        <div className="avatar">
          <img
            src={InstagramRounded}
            alt="Avatar 1"
            style={{ width: '18px', height: '18px' }}
          />
        </div>
        <div className="avatar">
          <img
            src={FacebookRounded}
            alt="Avatar 1"
            style={{ width: '18px', height: '18px' }}
          />
        </div>
      </div>
      <p className="font-semibold text-[16px] mt-4">
        Connect your first channel and
      </p>
      <p className="font-semibold text-[16px] mb-4">
        start sorting your enquiries
      </p>
      <PrimaryButton
        label="Connect a channel"
        className="btn--connect mb-5"
        style={{ padding: '4px 18px' }}
        onClick={() => navigate(`${route.channels}`)}
      />
    </div>
  );
};

export default EmptyChannelConnection;

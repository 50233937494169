import React, { useRef, useState } from 'react';
import DropDownWrapper from 'components/dropdown/wrapper';
import { ReactComponent as CheckIcon } from 'assets/icons/icon-complete-check.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/icon-task-tab-clock.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/icon-preview-eye.svg';
import { ReactComponent as ClockIconWhite } from 'assets/icons/icon-task-tab-clock-white.svg';
import { useDispatch } from 'react-redux';
import choices from 'choices';
import { updateFeedTask, updateFeedTaskStatus } from 'slices/feedTask/slice';
import { useParams } from 'react-router-dom';
import { Loader } from 'components/loader';
import {
  formatDateTime,
  getTimeIn12Format,
  getTimeIn24Format
} from 'utils/dateFormat';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import Calendar from 'components/calendar';
import useClickOutside from 'hooks/useOutsideClick';
import { useSnackbar } from 'components/Snackbar';
import {
  getComingMonday,
  isTodayMonday
} from 'modules/enquirybox/ebox/module/box/conversation/footer/task/footer/helper';
import { isEmpty } from 'utils/utils';

const COMPLETED = choices.TaskStatusChoices.COMPLETED;

const TaskItem = ({
  description,
  leadId,
  id,
  taskDue,
  dueDate,
  channelType,
  url
}) => {
  const dropdownRef = useRef();
  const calendarRef = useRef();
  const { userId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const [showDropDown, setShowDropDown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCalender, setOpenCalender] = useState(false);
  const [isDeleted, setIsDeleted] = useState(null);
  const [isMoved, setIsMoved] = useState('');

  useClickOutside(() => setShowDropDown(false), dropdownRef.current);

  useClickOutside(() => setOpenCalender(false), calendarRef.current);

  const getTitle = () => {
    if (isTodayMonday()) {
      return 'Upcoming monday';
    }
    return 'Monday morning';
  };

  const dayOptions = [
    {
      id: 1,
      name: 'Tomorrow morning'
    },
    {
      id: 2,
      name: getTitle()
    }
  ];

  const onClick = (to) => {
    if (to.includes('boxes')) {
      const data = to.split('/');
      const boxRoutePath = data.slice(5).join('/');
      navigate(`${route.dashboard}${route.boxes}/${boxRoutePath}`);
    } else {
      const data = to.split('/');
      const leadId = data[data.length - 1];
      const leadType = data[data.length - 2];
      navigate(
        `${route.dashboard}${route.enquirybox}${route.box}/lead/${leadType}/${leadId}`
      );
    }
  };

  const onComplete = () => {
    setIsLoading(true);
    const payload = {
      task: id,
      status: COMPLETED
    };
    dispatch(updateFeedTaskStatus(payload, onSuccess));
  };

  const renderDueTime = () => {
    const timeData = dueDate.split('T');
    const getTime = getTimeIn12Format(timeData[1]);
    return getTime;
  };

  const onSuccess = () => {
    setIsDeleted(id);
    setIsLoading(false);
    openSuccessSnackBar('Task completed');
  };

  const onDaySelect = (data) => {
    if (data.id === 1) {
      const DUE_DATE = moment().add(1, 'days').format('YYYY-MM-DD');
      let payload = {
        lead: leadId,
        created_by: userId,
        due_date: `${DUE_DATE} 09:00`,
        note: description,
        assigned_to: userId
      };
      dispatch(
        updateFeedTask({
          taskId: id,
          data: payload,
          callback: onDaySelectCallback(data.id)
        })
      );
    } else {
      const date = getComingMonday();
      const DUE_DATE = moment(new Date(date)).format('YYYY-MM-DD');
      let payload = {
        lead: leadId,
        created_by: userId,
        due_date: `${DUE_DATE} 09:00`,
        note: description,
        assigned_to: userId
      };
      dispatch(
        updateFeedTask({
          taskId: id,
          data: payload,
          callback: onDaySelectCallback(data.id)
        })
      );
    }
  };

  const onDaySelectCallback = (id) => {
    if (id === 1) {
      setIsMoved('Moved to Tomorrow morning at 9am 👍');
    } else {
      setIsMoved('Moved to Monday morning at 9am 👍');
    }
    setShowDropDown(false);
    setOpenCalender(false);
  };

  const onDateSelectCallback = (date) => {
    const movedDate = moment(date).format('MMMM Do, h:mm:ss a');
    setIsMoved(`Moved to ${movedDate}`);
    setIsLoading(false);
    setShowDropDown(false);
    setOpenCalender(false);
  };

  const onDateSelect = (date) => {
    setIsLoading(true);
    const timeData = dueDate.split('T');
    const getTime = getTimeIn12Format(timeData[1]);
    const get24HrTime = getTimeIn24Format(getTime);
    const DUE_DATE = `${formatDateTime(date, 'yyyy-mm-dd')} ${get24HrTime}`;
    let payload = {
      lead: leadId,
      created_by: userId,
      due_date: DUE_DATE,
      note: description,
      assigned_to: userId
    };
    dispatch(
      updateFeedTask({
        taskId: id,
        data: payload,
        callback: onDateSelectCallback(DUE_DATE)
      })
    );
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {isDeleted === id ? (
        <div className="text-sm py-3">Task completed 👍</div>
      ) : (
        <>
          {!isEmpty(isMoved) ? (
            <div className="text-sm py-3">{isMoved} 👍</div>
          ) : (
            <div className="team-chat-feed__right-side__task-list">
              <button
                className="btn bg-white btn--xs-26 gap-1 mr-1"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  fontWeight: 500,
                  minWidth: 90
                }}
                onClick={onComplete}
              >
                <CheckIcon />
                Complete
              </button>
              {taskDue === 2 && (
                <button
                  className={`btn ${
                    showDropDown
                      ? 'btn--primary btn--xs-26'
                      : 'bg-white btn--xs-26'
                  } gap-1 mr-3 relative`}
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    fontWeight: 500,
                    minWidth: 90
                  }}
                  ref={dropdownRef}
                  onClick={() => setShowDropDown(true)}
                >
                  {showDropDown ? <ClockIconWhite /> : <ClockIcon />}
                  <p>Not today</p>
                  <DropDownWrapper
                    className="dropdown-menu--feed-filter p-2 px-1"
                    open={showDropDown}
                    style={{
                      right: 'auto',
                      left: '0px',
                      width: '240px',
                      bottom: '30px',
                      top: 'auto'
                    }}
                  >
                    <div>
                      {dayOptions.map((item, index) => {
                        const { name } = item;
                        return (
                          <div
                            className="dropdown-menu__list gap-4"
                            key={index}
                            onClick={() => onDaySelect(item)}
                          >
                            <span className="text-sm">{name}</span>
                          </div>
                        );
                      })}
                    </div>
                  </DropDownWrapper>
                </button>
              )}
              {taskDue !== 2 && (
                <div
                  className="btn bg-white btn--xs-26 gap-1 mr-1 cursor-pointer relative"
                  style={{
                    border: '1px solid rgba(0, 0, 0, 0.05)',
                    fontWeight: 500,
                    minWidth: 90
                  }}
                  onClick={() => setOpenCalender(true)}
                  ref={calendarRef}
                >
                  <ClockIcon />
                  {moment(dueDate).format('MMM Do')}
                  <div
                    className={`dropdown-menu ${openCalender ? 'open' : ''}`}
                    style={{ maxWidth: 320, top: 25, width: 300 }}
                  >
                    <div className="calender-select">
                      <Calendar
                        enableFutureDates={true}
                        onDateClick={onDateSelect}
                        selectedDate={new Date(dueDate)}
                        containerStyle={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <button
                onClick={() => onClick(url)}
                className="btn bg-white btn--xs-26 gap-1 mr-3"
                style={{
                  border: '1px solid rgba(0, 0, 0, 0.05)',
                  fontWeight: 500,
                  minWidth: 82
                }}
              >
                <EyeIcon />
                View it
              </button>
              <p className="text-sm truncate" style={{ maxWidth: '75%' }}>
                <span className="text-primary font-semibold">
                  {renderDueTime()}
                </span>
                <span> {description} </span>
              </p>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default TaskItem;

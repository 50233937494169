/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import Quill from 'components/quill';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CaretUp } from 'assets/icons/icon-select-caret-up.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
import InlineEmail from 'modules/enquirybox/ebox/module/box/conversation/footer/replyEmail/inlineEmail';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import { setEmailData, setEmailError } from 'slices/automationStuffSlice';
import Error from 'components/errorMessage';
import choices from 'choices';
import { setAttachments } from 'slices/editor/slice';
import { handleChangeSignature } from 'slices/conversationSlice';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';

const { WEBFORM, FACEBOOK, FACEBOOK_LEAD_ADS, SMS, INSTAGRAM, EBOT, ZAPIER } =
  choices.ChannelChoices;

const ReplyEmail = ({
  className,
  channelType,
  setLength,
  length,
  setSmsCount,
  tempChannelType
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    id: currentUser,
    reply_sending_mail_address: userEmail,
    purchased_phone_number
  } = useSelector((state) => state.myInfo.myInformation);
  const { emailData, onEdit, isPreview } = useSelector(
    (state) => state.automationStuff
  );
  // const [isLoading, setIsLoading] = useState(false);
  const [showFromUsers, setShowFromUsers] = useState(false);
  const [showCcInput, setShowCcInput] = useState(false);
  const [showBccInput, setShowBccInput] = useState(false);
  const [ccEmail, setCcEmail] = useState(
    !isEmpty(emailData) && channelType !== SMS ? [...emailData.cc] : []
  );
  const [bccEmail, setBccEmail] = useState(
    !isEmpty(emailData) && channelType !== SMS ? [...emailData.bcc] : []
  );
  const [selectedFromEmail, setSelectedFromEmail] = useState();
  const { attachments } = useSelector((state) => state.editor);
  const [selectedUserSignature, setSelectedUserSignature] = useState('');
  const [editor, setEditorRef] = useState(null);
  // const [attachments, setAttachments] = useState([]);
  // const [sectionTopHeight, setSectionTopHeight] = useState('300');
  const userEmailRef = useRef();
  const sectionTopRef = useRef();
  const emailBodyRef = useRef();
  const footerBodyRef = useRef();

  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { error } = useSelector((state) => state.automationStuff);
  const { allChannels } = useSelector((state) => state.channels);
  useClickOutside(() => setShowFromUsers(false), userEmailRef?.current);

  useEffect(() => {
    if (
      editor &&
      (channelType === WEBFORM ||
        channelType === FACEBOOK_LEAD_ADS ||
        channelType === EBOT ||
        channelType === ZAPIER)
    ) {
      // dispatch(
      //   handleChangeSignature(currentUser, editor, (data, signature) => {
      //     setSelectedUserSignature(signature);
      //     dispatch(setEmailData({ ...emailData, message: data, signature }));
      //   })
      // );
      if (emailData.send_from && typeof emailData.send_from == 'object') {
        setSelectedFromEmail({
          user_id: emailData.send_from.user_id,
          email: emailData.send_from.reply_sending_mail_address
        });
        if (
          channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === EBOT ||
          channelType === ZAPIER
        ) {
          dispatch(
            handleChangeSignature(
              emailData.send_from.id,
              editor,
              (data, signature) => {
                setSelectedUserSignature(signature);
                dispatch(
                  setEmailData({ ...emailData, message: data, signature })
                );
              }
            )
          );
        }
        return;
      }
      setSelectedFromEmail({
        user_id: currentUser,
        email: userEmail
      });
      dispatch(
        handleChangeSignature(currentUser, editor, (data, signature) => {
          setSelectedUserSignature(signature);
          dispatch(
            setEmailData({
              ...emailData,
              send_from: currentUser,
              message: data,
              signature
            })
          );
        })
      );
    }
  }, [editor]);

  const setFromEmail = (user) => {
    setSelectedFromEmail(user);
    setShowFromUsers(false);
    setSelectedUserSignature('');
    dispatch(
      handleChangeSignature(user.user_id, editor, (data, signature) => {
        setSelectedUserSignature(signature);
        dispatch(
          setEmailData({
            ...emailData,
            send_from: user.user_id,
            message: data,
            signature
          })
        );
      })
    );
  };

  const onAttachmentChanges = (attachments) => {
    dispatch(setAttachments(attachments));
  };

  useEffect(() => {
    dispatch(
      setEmailData({
        ...emailData,
        cc: [...ccEmail],
        bcc: [...bccEmail]
      })
    );
  }, [ccEmail, bccEmail]);

  const onUseTemplate = (subject, body, attachments) => {
    const bodyContent = `${body}<br/><br/>${
      selectedUserSignature
        ? `<div id="user-signature" class="ebox-noneditable" contenteditable="false">${selectedUserSignature}</div>`
        : ''
    }`;
    dispatch(
      setEmailData({
        ...emailData,
        message: bodyContent,
        subject: subject,
        signature: emailData.signature
      })
    );
    dispatch(setAttachments(attachments));
  };

  const onPurchaseClick = () => {
    const sms = allChannels.filter((item) => item.channel_type === SMS);
    if (!isEmpty(sms)) {
      navigate(`${route.channels}/sms/${SMS}/pending/${sms[0].id}`);
    }
  };

  // console.log({ emailData, selectedFromEmail })
  return (
    <div
      className="chat-message-footer__body"
      ref={footerBodyRef}
      style={{ height: 'calc(100% - 250px)' }}
    >
      <div className="chat-reply">
        {(channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === EBOT ||
          channelType === ZAPIER) && (
          <div className="section-top" ref={sectionTopRef}>
            <div
              className="info-block space"
              style={
                error.fromEmail
                  ? {
                      borderColor: '#ff0000'
                    }
                  : {}
              }
            >
              <div className="flex items-center justify-center">
                <span className="label mr-3">From</span>
                <div className="from-email relative">
                  <section
                    className="flex items-center justify-center cursor-pointer"
                    onClick={() =>
                      isPreview ? () => {} : setShowFromUsers(!showFromUsers)
                    }
                  >
                    {isEmpty(selectedFromEmail) && <span>User Email</span>}
                    {!isEmpty(selectedFromEmail) && (
                      <span>{selectedFromEmail?.email}</span>
                    )}
                    <figure className="ml-2">
                      {showFromUsers ? <CaretUp /> : <CaretBlackIcon />}
                    </figure>
                  </section>
                  <div
                    ref={userEmailRef}
                    className={`dropdown-menu dropdown-menu--sm ${
                      showFromUsers ? 'open' : ''
                    }`}
                    style={{
                      width: 'max-content',
                      top: 'calc(100% + 2px)',
                      left: 0,
                      right: 0
                    }}
                  >
                    {organizationUser.results?.map((user) => {
                      const { user_id, email, reply_sending_mail_address } =
                        user;
                      return (
                        <div
                          key={user_id}
                          className="dropdown-menu__list"
                          onClick={() => {
                            dispatch(
                              setEmailError({ ...error, fromEmail: null })
                            );
                            setFromEmail({
                              user_id,
                              email: reply_sending_mail_address
                            });
                            dispatch(
                              setEmailData({ ...emailData, send_from: user_id })
                            );
                          }}
                        >
                          <p className="text-sm">
                            {reply_sending_mail_address}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="label cc cursor-pointer mr-2"
                  onClick={() =>
                    isPreview ? () => {} : setShowCcInput(!showCcInput)
                  }
                >
                  Cc
                </div>
                <div
                  className="label cc cursor-pointer"
                  onClick={() =>
                    isPreview ? () => {} : setShowBccInput(!showBccInput)
                  }
                >
                  Bcc
                </div>
              </div>
            </div>
            {(showCcInput || !isEmpty(emailData?.cc)) && (
              <InlineEmail
                title="Cc"
                placeholder="example@domain.com"
                list={ccEmail}
                setList={setCcEmail}
              />
            )}
            {(showBccInput || !isEmpty(emailData?.bcc)) && (
              <InlineEmail
                title="Bcc"
                placeholder="example@domain.com"
                list={bccEmail}
                setList={setBccEmail}
              />
            )}
            <div
              className="info-block w-full items-center"
              style={
                error.subject
                  ? {
                      borderColor: '#ff0000'
                    }
                  : {}
              }
            >
              <div className="label mr-3">Subject</div>
              <div className="flex items-center justify-center w-full">
                <form className="w-full">
                  <input
                    type="text"
                    className="email-text"
                    placeholder="Subject"
                    value={emailData?.subject}
                    onChange={(e) => {
                      dispatch(setEmailError({ ...error, subject: null }));
                      dispatch(
                        setEmailData({ ...emailData, subject: e.target.value })
                      );
                    }}
                    readOnly={isPreview}
                  />
                </form>
              </div>
              {error.subject && (
                <div className="mt-1 ml-1" style={{ width: 'inherit' }}>
                  <Error className="flex justify-end" message={error.subject} />
                </div>
              )}
            </div>
          </div>
        )}
        {channelType === SMS &&
          (tempChannelType === FACEBOOK_LEAD_ADS ||
            tempChannelType === WEBFORM ||
            tempChannelType === ZAPIER ||
            tempChannelType === EBOT ||
            tempChannelType === SMS) && (
            <div
              className="info-block w-full items-center"
              style={
                error.phoneNumber
                  ? {
                      borderColor: '#ff0000'
                    }
                  : {}
              }
            >
              <div className="label mr-3 ml-4">From</div>
              <div className="flex items-center justify-center w-full">
                <form className="w-full">
                  {purchased_phone_number === null ? (
                    <div className="text-sm">
                      Please{' '}
                      <span
                        style={{ color: '#4A3AFF' }}
                        className="cursor-pointer"
                        onClick={onPurchaseClick}
                      >
                        purchase an SMS bundle
                      </span>{' '}
                      to send this message
                    </div>
                  ) : (
                    <input
                      type="text"
                      className="email-text"
                      placeholder="number"
                      value={purchased_phone_number}
                      readOnly
                    />
                  )}
                </form>
              </div>
              {error.phoneNumber && (
                <div className="mt-1 ml-1" style={{ width: 'inherit' }}>
                  <Error
                    className="flex justify-end"
                    message={error.phoneNumber}
                  />
                </div>
              )}
            </div>
          )}
        <div
          className={`${
            error.message ? 'error-editor' : ''
          } box-settings ${className}`}
          ref={emailBodyRef}
        >
          <Quill
            onChange={(data) => {
              dispatch(setEmailError({ ...error, message: null }));
              dispatch(setEmailData({ ...emailData, message: data }));
            }}
            value={emailData?.message}
            showSendBtn={false}
            // isLoading={isLoading}
            placeholder={'Type your message here'}
            isExpanded={true}
            attachments={attachments}
            hideImage={
              channelType === FACEBOOK ||
              channelType === SMS ||
              channelType === INSTAGRAM
            }
            hideAttachment={
              channelType === FACEBOOK ||
              channelType === SMS ||
              channelType === INSTAGRAM
            }
            setAttachments={(data) => dispatch(setAttachments(data))}
            onAttachmentChanges={onAttachmentChanges}
            hideVarible={false}
            setEditorRef={setEditorRef}
            showFontStyle={
              channelType !== FACEBOOK && channelType !== INSTAGRAM
            }
            channelType={channelType}
            errorMessage={error.message}
            isAutomation
            isRefetch={true}
            onUseTemplate={onUseTemplate}
            enableCharacterLimit={channelType === SMS ? true : false}
            maxCharacters={channelType === SMS && 160}
            setLength={setLength}
            length={length}
            showCharLimitTop={channelType === SMS ? true : false}
            isSms={channelType === SMS ? true : false}
            setSmsCount={setSmsCount}
            isFbOrInsta={channelType === FACEBOOK || channelType === INSTAGRAM}
            hideHyperlink={false}
            readOnly={isPreview}
          />
        </div>
      </div>
    </div>
  );
};

export default ReplyEmail;

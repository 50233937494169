import React from 'react';
// import PropTypes from 'prop-types'
import { ReactComponent as DownloadIcon } from 'assets/icons/icon-download.svg';
import {
  fileIcons,
  getFbFileExtension,
  getFbFileName,
  getFileExtension
} from 'utils/file';
import { useSnackbar } from 'components/Snackbar';
import copyIcon from 'assets/icons/icon-Copy.svg';
import CHOICES from 'choices';
import moreIcon from 'assets/icons/icon-more-icon.svg';
import { useSelector } from 'react-redux';
import DownloadLink from 'components/downloadLink';
import { zapierFileIcons } from 'components/constants';

const FB = CHOICES.ChannelChoices.FACEBOOK;
const FBLEADS = CHOICES.ChannelChoices.FACEBOOK_LEAD_ADS;
const ZAPIER = CHOICES.LeadChannelChoice.ZAPIER;

const Documents = (props) => {
  const { item, setShowMoreDropdown, showMoreDropdown } = props;

  const { activeLead } = useSelector((state) => state.leadConversation);
  const { openSuccessSnackBar } = useSnackbar();

  const copyToClipboard = (file_url) => {
    navigator.clipboard.writeText(file_url);
    openSuccessSnackBar('Link copied');
    // setShowMoreDropdown(null);
  };

  return (
    <div>
      <div
        className="flex files__card items-center justify-between"
        key={item.id}
      >
        <div className="flex items-center">
          <div>
            <img
              src={
                activeLead?.channel === ZAPIER
                  ? zapierFileIcons[item.mime_type]
                  : activeLead?.channel === FB
                  ? fileIcons[getFbFileExtension(item.file_url)]
                  : activeLead?.channel === FBLEADS
                  ? fileIcons[getFileExtension(item.file_name)]
                  : fileIcons[getFileExtension(item.file_url)]
              }
              alt=""
              style={{ width: 25, height: 32 }}
            />
          </div>
          <div className="ml-3 text-sm font-medium">
            {item.file_name ? item.file_name : getFbFileName(item.file_url)}
          </div>
        </div>
        <div
          className="relative flex items-center files__card--right-side cursor-pointer"
          onClick={() => setShowMoreDropdown(item.id)}
        >
          <img src={moreIcon} alt="" style={{ width: 25, height: 32 }} />
          <div
            className={`dropdown-menu dropdown-menu--more ${
              showMoreDropdown === item.id ? 'open' : ''
            }`}
          >
            {/* <div className="dropdown-menu__list">
                      <img
                        src={previewIcon}
                        alt=""
                        className="mr-2"
                        style={{ width: 16, height: 16 }}
                      />
                      Preview
                    </div> */}
            {/* <a
                            className="dropdown-menu__list"
                            href={item.file_url}
                            // onClick={(e) => {
                            //   e.stopPropagation();
                            //   // setShowMoreDropdown(null);
                            // }}
                          >
                            < e.stopPropagation();
                              copyToClipboard(item.file_url);span className="flex width-fill-available">
                              <img
                                src={downloadIcon}
                                alt=""
                                className="mr-2"
                                style={{ width: 16, height: 16 }}
                              />
                              <span>Download</span>
                            </span>
                          </a> */}
            {/* <div
              className="dropdown-menu__list"
              onClick={(e) => {
                e.stopPropagation();
                setShowMoreDropdown(null);
              }}
            >

              <DownloadLink to={item.file_url} className="flex gap-3">
                <DownloadIcon />
                <span>Download</span>
              </DownloadLink>
            </div> */}
            <DownloadLink
              to={item.file_url}
              className="dropdown-menu__list"
              onClick={(e) => {
                e.stopPropagation();
                setShowMoreDropdown(null);
              }}
            >
              <div className="flex gap-3">
                <DownloadIcon />
                <span>Download</span>
              </div>
            </DownloadLink>
            <div
              className="dropdown-menu__list"
              onClick={(e) => {
                e.stopPropagation();
                copyToClipboard(item.file_url);
              }}
            >
              <img
                src={copyIcon}
                alt=""
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Copy link
            </div>
            {/* <div className="dropdown-menu__list red-text">
                      <img
                        src={deleteIcon}
                        alt=""
                        className="mr-2"
                        style={{ width: 16, height: 16 }}
                      />
                      Delete
                    </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

// Documents.propTypes = {}

export default Documents;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { dayNames, monthNames } from 'utils/calendar';
import moment from 'moment';
import { ReactComponent as CaretLeft } from 'assets/icons/icon-caret-left.svg';
import { ReactComponent as CaretRight } from 'assets/icons/icon-caret-right.svg';

const range = (start, end) => {
  // https://dev.to/ycmjason/how-to-create-range-in-javascript-539i
  return new Array(end - start + 1).fill(undefined).map((_, i) => i + start);
};

const Calendar = ({
  selectedDate,
  responseDate,
  selectedEndDate,
  onDateClick,
  enableFutureDates,
  className,
  minDate,
  containerStyle,
  hideToday
}) => {
  const today = new Date();
  const redoDate = responseDate && responseDate.split('-');
  const yearRange = range(
    today.getUTCFullYear() - 5,
    today.getUTCFullYear() + 5
  );
  const [displayedYear, setDisplayedYear] = useState(
    responseDate ? parseInt(redoDate[2]) : today.getUTCFullYear()
  );
  const [displayedMonth, setDisplayedMonth] = useState(
    responseDate ? parseInt(redoDate[1]) - 1 : today.getMonth()
  );
  const startOfMonth = new Date(displayedYear, displayedMonth, 1);
  const endOfMonth = new Date(displayedYear, displayedMonth + 1, 0);

  // Compute dates to be displayed
  let lastMonthDates = [];
  let nextMonthDates = [];
  const thisMonthDates = new Array(endOfMonth.getDate())
    .fill(undefined)
    .map((_, i) => new Date(displayedYear, displayedMonth, i + 1));

  if (startOfMonth.getDay() !== 0) {
    lastMonthDates = new Array(startOfMonth.getDay())
      .fill(undefined)
      .map((_, i) => new Date(displayedYear, displayedMonth, -i));
    lastMonthDates.reverse();
  }

  if (endOfMonth.getDay() !== 6) {
    nextMonthDates = new Array(6 - endOfMonth.getDay())
      .fill(undefined)
      .map((_, i) => new Date(displayedYear, displayedMonth + 1, i + 1));
  }

  const dates = lastMonthDates.concat(thisMonthDates).concat(nextMonthDates);

  const dateClicked = (date) => {
    onDateClick(date);
  };

  const lastMonth = () => {
    if (displayedMonth === 0 && displayedYear === yearRange[0]) {
      return;
    }
    if (displayedMonth !== 0) {
      return setDisplayedMonth(displayedMonth - 1);
    }
    setDisplayedYear(displayedYear - 1);
    setDisplayedMonth(11);
  };

  const nextMonth = () => {
    if (
      displayedMonth === 11 &&
      displayedYear === yearRange[yearRange.length - 1]
    ) {
      return;
    }
    if (displayedMonth !== 11) {
      return setDisplayedMonth(displayedMonth + 1);
    }
    setDisplayedYear(displayedYear + 1);
    setDisplayedMonth(0);
  };

  const isDisable = (value) => {
    if (value) return 'disabled';
    return '';
  };

  const isSelected = (value) => {
    if (value) return 'selected';
    return '';
  };
  const isToday = (value, isSelectedStart, isSelectedEnd) => {
    if (value && (isSelectedStart || isSelectedEnd)) return 'selected';
    if (value && !isSelectedStart && !isSelectedEnd) return 'today';
    return '';
  };

  const isNextMonthDisabled = () => {
    if (
      displayedMonth === 11 &&
      displayedYear === yearRange[yearRange.length - 1]
    ) {
      return true;
    }
    return false;
  };

  const isPreviousMonthDisabled = () => {
    if (displayedMonth === 0 && displayedYear === yearRange[0]) {
      return true;
    }
    return false;
  };

  const Day = ({ date }) => {
    const disabled = date.getMonth() !== displayedMonth;
    const isFutureDate = enableFutureDates ? false : moment().isBefore(date);
    const isHideToday = hideToday
      ? moment(date, 'YYYY-MM-DD').isSame(moment().format('YYYY-MM-DD'))
      : false;
    const selectedStart =
      selectedDate &&
      moment(date, 'yyyy-mm-dd').isSame(
        moment(selectedDate, 'yyyy-mm-dd'),
        'day'
      );
    const selectedEnd =
      selectedEndDate &&
      moment(date, 'yyyy-mm-dd').isSame(
        moment(selectedEndDate, 'yyyy-mm-dd'),
        'day'
      );
    const presentday = moment(date, 'yyyy-mm-dd').isSame(
      moment(new Date(), 'yyyy-mm-dd'),
      'day'
    );
    let isPastDate = minDate ? new Date(minDate) : false;
    if (!isPastDate) isPastDate = '';
    else {
      isPastDate = moment(date).isBefore(isPastDate) ? 'disabled' : '';
    }

    return (
      <div
        className={`day ${isDisable(disabled)} ${isDisable(
          isFutureDate
        )} ${isDisable(isHideToday)}
        ${isSelected(selectedStart)} ${isToday(
          presentday,
          selectedStart,
          selectedEnd
        )}
        ${isSelected(selectedEnd)} ${isPastDate}`}
        onClick={() => dateClicked(date)}
      >
        {date.getDate()}
      </div>
    );
  };

  return (
    <div className={`calander-container ${className}`} style={containerStyle}>
      {/* {hasCustomHeader && (
        <>
          {customHeader({
            lastMonth,
            nextMonth,
            displayedMonth,
            displayedYear
          })}
        </>
      )} */}
      <div className="header">
        <div className="top">
          <button
            className="nav-left"
            onClick={lastMonth}
            disabled={isPreviousMonthDisabled()}
          >
            <CaretLeft />
          </button>
          <div className="flex">
            <select
              name="months"
              id="months"
              value={displayedMonth}
              onChange={(e) => setDisplayedMonth(parseInt(e.target.value))}
              style={{ marginRight: '-4px' }}
            >
              {monthNames.map((month, idx) => (
                <option key={month} value={idx}>
                  {month}
                </option>
              ))}
            </select>
            <select
              name="years"
              id="years"
              value={displayedYear}
              onChange={(e) => setDisplayedYear(parseInt(e.target.value))}
            >
              {yearRange.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
          <button
            className="nav-right"
            onClick={nextMonth}
            disabled={isNextMonthDisabled()}
          >
            <CaretRight />
          </button>
        </div>
        <div className="bottom">
          {dayNames.map((dayName) => (
            <div key={dayName} className="day-label">
              {dayName}
            </div>
          ))}
        </div>
      </div>

      <div className="days">
        {dates.map((date) => (
          <Day key={date} date={date} />
        ))}
      </div>
    </div>
  );
};

Calendar.propTypes = {
  onDateClick: PropTypes.func,
  enableFutureDates: PropTypes.bool,
  className: PropTypes.string,
  hideToday: PropTypes.bool
};

Calendar.defaultProps = {
  onDateClick: () => {},
  enableFutureDates: false,
  className: '',
  hideToday: false
};

export default Calendar;

import React from 'react';
import iconAttachment from 'assets/icons/icon-attachment.svg';
// import AttachFiles from 'modules/modals/attachFiles';

const Attachment = ({ onClick }) => {
  // const node = useRef();
  // const [showAttachFiles, setShowAttachFiles] = useState(false);

  return (
    <div>
      <button className="toolbar-button ql-insertAttachment flex">
        <img src={iconAttachment} alt="icon-attachment" onClick={onClick} />
      </button>
      {/* <div ref={node}>
        <AttachFiles
          onClose={() => setShowAttachFiles(!showAttachFiles)}
          open={showAttachFiles}
        />
      </div> */}
    </div>
  );
};

export default Attachment;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import UserIcon from 'assets/icons/user-icon-grey.svg';
// import iconAdd from 'assets/icons/icon-add.svg';
import useClickOutside from 'hooks/useOutsideClick';
import UserList from 'modules/enquirybox/ebox/module/box/conversation/actions/assignUser/renderList';
import { useSelector, useDispatch } from 'react-redux';
// import { setAssignUser } from 'slices/task/slice';
import ButtonItem from 'modules/enquirybox/ebox/module/box/conversation/footer/task/footer/button';
import { isEmpty } from 'utils/utils';
import { whoever_is_available } from 'slices/organizationuserSlice';

const TaskAssignUser = ({ setUser, assigned_to }) => {
  const [showAssignUser, setShowAssignUser] = useState(false);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  // const { assignUser } = useSelector((state) => state.tasks);
  let user = {};
  if (assigned_to) {
    user = organizationUser.results.find(
      (user) => user.user_id === assigned_to
    );
  }
  const [assignUser, setAssignUser] = useState(user);
  // const dispatch = useDispatch();
  const userRef = useRef();

  useClickOutside(() => setShowAssignUser(false), userRef.current);
  useEffect(() => {
    if (assigned_to === null) {
      setAssignUser(whoever_is_available);
    }
  }, [assigned_to]);

  useEffect(() => {
    setUser(assignUser);
  }, [assignUser]);
  const onSelect = (option) => {
    setAssignUser(option);
    setShowAssignUser(false);
  };

  const onDelete = () => {
    setAssignUser({});
    setShowAssignUser(false);
  };

  return (
    <div ref={userRef}>
      <UserList
        open={showAssignUser}
        options={
          organizationUser?.results
            ? [
                whoever_is_available,
                ...organizationUser.results.filter((i) => !i?.is_disabled)
              ]
            : [whoever_is_available]
        }
        onSelect={onSelect}
        active={assignUser}
        onSubmit={onDelete}
        wrapperStyle={{
          right: '0px',
          left: '50px',
          top: 'unset',
          bottom: '70px',
          width: '350px'
        }}
        deleteVisible={false}
      />
      <ButtonItem
        active={showAssignUser}
        icon={UserIcon}
        src={!isEmpty(assignUser) ? assignUser.avatar : null}
        text={!isEmpty(assignUser) ? assignUser.name : 'Assign to me'}
        name={!isEmpty(assignUser) ? assignUser.name : ''}
        userAvatar={!isEmpty(assignUser)}
        onClick={() => {
          setShowAssignUser(!showAssignUser);
        }}
      />
    </div>
  );
};

export default TaskAssignUser;

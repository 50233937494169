import React, { useEffect } from 'react';

// import '../../dashboard-create-bot.scss';
import ChatbotRoutes from './routes';
import Header from './components/header';
import ChatbotTabs from './chatbotTabs';
import { useParams } from 'react-router-dom';
import Sidebar from '../sidebar';
import { useDispatch, useSelector } from 'react-redux';
import {
  getVersionList,
  setCurrentVersionId,
  setVersionStatus
} from 'slices/ai-assistant/assistant/slice';
import { isEmpty } from 'utils/utils';
import { getAllPersonalityFields } from 'slices/ai-assistant/personality/slice';
const BotAIMain = () => {
  const { assistant_id } = useParams();
  const dispatch = useDispatch();
  let { listAssistants } = useSelector((state) => state.assistant);

  useEffect(() => {
    if (!isEmpty(listAssistants)) {
      if (assistant_id) {
        let currVersion = listAssistants.find(
          (i) => i.version_bot.id === assistant_id
        );
        dispatch(setCurrentVersionId(currVersion?.id));
        dispatch(setVersionStatus(currVersion?.version_status));
      }
    }
  }, [assistant_id, listAssistants]);

  useEffect(() => {
    if (!isEmpty(assistant_id)) {
      dispatch(getAllPersonalityFields(assistant_id));
      dispatch(getVersionList(assistant_id));
    }
  }, []);

  return (
    <>
      <Sidebar />
      <div className="dashboard__main--create-bot__section-right create-bot-section-right">
        <Header />
        <div className="create-bot-section-right__body" id="bot-container">
          <ChatbotTabs />
          <ChatbotRoutes />
        </div>
      </div>
    </>
  );
};

export default BotAIMain;

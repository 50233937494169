import React, { useState } from 'react';
import { ReactComponent as LabelIcon } from '../../assets/icons/icon-settings-labels.svg';
import { ReactComponent as QuestionIcon } from 'assets/icons/icon-settings-questions.svg';
import { ReactComponent as FileLibraryIcon } from 'assets/icons/icon-settings-file-library.svg';
// import { ReactComponent as EmailSignatureIcon } from 'assets/icons/email-Signature-Icon.svg';
import { ReactComponent as TeamMemberIcon } from 'assets/icons/icon-settings-team-member.svg';
import { ReactComponent as ReplyTemplateIcon } from 'assets/icons/icon-settings-reply-template.svg';
import { ReactComponent as BusinesInfoIcon } from 'assets/icons/icon-business-info.svg';
// import { ReactComponent as IconMyProfile } from 'assets/icons/icon-my-profile.svg';
import { ReactComponent as TaskIcon } from 'assets/icons/icon-tab-task-active.svg';
import { ReactComponent as iconBilling } from 'assets/icons/icon-subscription-billing.svg';
import { subscriptionStripeRedirect } from 'slices/subscription/slice';
import SettingLink from 'modules/settings/components/settingLink';
import CHOICES from 'choices';
import { Loader } from 'components/loader';
const { FREE_TRIAL } = CHOICES.PlanType;
import { useSelector, useDispatch } from 'react-redux';

const AccountSettingsMenu = ({ mainRoute }) => {
  const { currentPlan } = useSelector((state) => state.subscription);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [stripeRedirectFlag, setStripeRedirectFlag] = useState(false);

  const NavLinks = [
    {
      label: 'Team members',
      desc: 'Invite new, manage access',
      icon: TeamMemberIcon,
      route: `${mainRoute}/account-settings/team-member`
    },
    // {
    //   label: 'My profile',
    //   desc: 'Login details, email signature',
    //   icon: IconMyProfile,
    //   route: `${mainRoute}/my-profile`
    // },
    {
      label: 'Reply templates',
      desc: 'Frequently sent templates for replies',
      icon: ReplyTemplateIcon,
      route: `${mainRoute}/account-settings/reply-template`
    },
    {
      label: 'Labels',
      desc: 'Add new, manage labels',
      icon: LabelIcon,
      route: `${mainRoute}/account-settings/labels`
    },
    {
      label: 'File library',
      desc: 'Store files for quick access',
      icon: FileLibraryIcon,
      route: `${mainRoute}/account-settings/file-library`
    },
    {
      label: 'Subscription & billing',
      desc: 'Invoices, payment method, upgrade',
      icon: iconBilling,
      route: `${mainRoute}/account-settings/subscription-billing`
    },
    {
      label: 'Business info',
      desc: 'Opening hours, logo & branding',
      icon: BusinesInfoIcon,
      route: `${mainRoute}/account-settings/business-info`
    },
    {
      label: 'Questions',
      desc: 'Add and manage questions',
      icon: QuestionIcon,
      route: `${mainRoute}/account-settings/questions`
    },
    {
      label: 'Tasks',
      desc: 'Make your task outcomes your own',
      icon: TaskIcon,
      route: `${mainRoute}/account-settings/tasks`
    }
  ];

  const dispatch = useDispatch();

  const handleSubscriptionBilling = () => {
    if (stripeRedirectFlag) {
      return;
    }
    setStripeRedirectFlag(true);
    dispatch(
      subscriptionStripeRedirect(
        subOrganizationId,
        closeStripeRedirect,
        handleStripeFailedRedirect
      )
    );
  };
  const closeStripeRedirect = (data) => {
    if (data.link) {
      setStripeRedirectFlag(false);
      window.open(data.link, '_blank').focus();
    }
  };

  const handleStripeFailedRedirect = () => {
    setStripeRedirectFlag(false);
  };

  const onSubcriptionBillingClick = () => {
    console.log('SUbs');
    handleSubscriptionBilling();
  };

  return (
    <>
      {NavLinks?.map((item, index) => {
        const { label, desc, route } = item;
        if (
          currentPlan?.plan?.plan_type === FREE_TRIAL &&
          label === 'Subscription & billing'
        ) {
          return <div key={index} />;
        }
        if (
          currentPlan?.plan?.plan_type !== FREE_TRIAL &&
          label === 'Subscription & billing'
        ) {
          return (
            <div key={index} onClick={onSubcriptionBillingClick}>
              <div
                className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block mb-1`}
              >
                <div className="flex items-center gap-4 flex-1 truncate relative">
                  <figure className="general-settings__card-list__avatar-container">
                    <item.icon />
                  </figure>
                  <div className="truncate pr-4">
                    <h2 className="general-settings__card-list__title">
                      {label}
                    </h2>
                    <p className="general-settings__card-list__desc">{desc}</p>
                  </div>
                  {stripeRedirectFlag && (
                    <Loader
                      loaderStyle={{
                        position: 'absolute',
                        right: '15px',
                        bottom: '10px'
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
          );
        }
        return (
          <SettingLink
            key={index}
            to={route}
            title={label}
            renderSvg={() => (
              <item.icon
                style={label === 'Tasks' ? { width: 21, height: 22 } : {}}
              />
            )}
            description={desc}
          />
        );
      })}
    </>
  );
};

export default AccountSettingsMenu;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Modal from 'components/modal';
import Avatar from 'components/avatar';
import CHOICES from 'choices';
import styles from './index.module.scss';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import ErrorMessage from 'components/errorMessage';

const ADMIN = CHOICES.TeamMemberRoleChoice.TEAM_ADMIN;
const TEAM_SUPER_ADMIN = CHOICES.TeamMemberRoleChoice.TEAM_SUPER_ADMIN;
const PENDING = CHOICES.OnboardingTeamMemberChoice.PENDING;

const ReassignModal = (props) => {
  const { open, onClose, leadsAssigned, userName, organizationUser, onDone } =
    props;
  const [selectedUser, setSelectedUser] = useState(null);
  const [isError, setIsError] = useState('');

  const onClick = () => {
    if (!selectedUser) {
      setIsError('Select any user to reassign the enquiry');
    } else {
      onDone(selectedUser);
      onClose();
    }
  };

  const onCancel = () => {
    setIsError('');
    setSelectedUser(null);
    onClose();
  };

  return (
    <Modal.Main open={open}>
      <Modal.Header title="Reassign enquiries" onClose={onCancel} />
      <div
        style={{ background: 'rgba(255, 219, 125, 0.42)' }}
        className="py-4 px-8 text-13 font-medium"
      >
        {`There are ${leadsAssigned} enquiries assigned to ${userName}. In order to delete, you
        must first reassign these enquiries to one team member. Select a team
        member to reassign to below.`}
      </div>
      <Modal.Body
        className="py-4 px-4 overflow-y-auto"
        style={{ maxHeight: 450, height: 450 }}
      >
        {open &&
          organizationUser?.results.map((user) => {
            if (user?.name !== userName) {
              if (user?.onboarding_status !== PENDING) {
                return (
                  <div
                    key={user.organization_user_id}
                    className="flex justify-between items-center py-2 pl-4 pr-5"
                  >
                    <label
                      className="flex gap-4 width-fill-available cursor-pointer"
                      htmlFor={user.organization_user_id}
                    >
                      <Avatar
                        src={user.avatar}
                        initial={user.name}
                        medium42
                        singleChar
                      />
                      <div>
                        <div className="flex">
                          <h3 className="font-semibold text-15">
                            {user.name || user.first_name}
                          </h3>
                          {` `}
                        </div>
                        <p className="text-grey-800 text-13">
                          {user.role === ADMIN || user.role === TEAM_SUPER_ADMIN
                            ? 'Team Admin'
                            : 'Team Member'}
                        </p>
                      </div>
                    </label>
                    <input
                      type="radio"
                      className={styles.custom_radio_button}
                      id={user.organization_user_id}
                      value={user.organization_user_id}
                      name="users"
                      onChange={() => {
                        setIsError('');
                        setSelectedUser(user.organization_user_id);
                      }}
                    />
                  </div>
                );
              }
            }
          })}
      </Modal.Body>
      {isError && (
        <div className="flex justify-end pr-8 pb-1">
          <ErrorMessage message={isError} />
        </div>
      )}
      <div className="flex justify-end pr-8 mb-8 gap-3">
        <CustomColorButton
          label="Cancel"
          className="btn--secondary btn--md min-w-120"
          onClick={onCancel}
        />
        <PrimaryButton
          label="Done"
          className="btn--md min-w-120"
          onClick={onClick}
        />
      </div>
    </Modal.Main>
  );
};

export default ReassignModal;

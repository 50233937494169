import React from 'react';
import HistoryLayout from './layout';
import historyEnquiry from 'assets/icons/icon-history-enquiry.svg';
import choices from 'choices';
import { timeFormatter } from 'utils/utils';

export const RestoreDesigne = ({ desc }) => {
  return (
    <div>
      <div className="flex">
        <div className="flex py-3 text-13 px-3 justify-center align-center">
          {desc}
        </div>
      </div>
      {/* <button className="btn btn--history bg-yellow-200 border br-note mt-1 ml-4 mb-3">
        <img src={btnIcon} alt="" />
        <span className="text-11 ml-1 text-grey-700">{boxText}</span>
      </button> */}
    </div>
  );
};

const Restore = (props) => {
  let {
    data: { title, created_at, description }
  } = props;

  return (
    <HistoryLayout
      icon={historyEnquiry}
      title={choices.TitleChoices.CHOICES[title]}
      time={timeFormatter(created_at)}
      className="history__new-enquiry"
      renderData={() => <RestoreDesigne desc={description} />}
    />
  );
};
export default Restore;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Count = (props) => {
  const { label, className, rounded, style } = props;

  if (rounded)
    return (
      <div
        data-testid="count-rounded"
        className={`${styles.count_indicator_rounded} ${className}`}
        style={style}
      >
        {label}
      </div>
    );

  return (
    <span
      data-testid="count"
      className={`${styles.count_indicator} text-base-1 ${className}`}
    >
      {label}
    </span>
  );
};

Count.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  rounded: PropTypes.bool
};

Count.defaultProps = {
  label: 0,
  className: '',
  rounded: true
};

export default Count;

import React from 'react';
import computer from 'assets/icons/icon-ai-computer.svg';
import link from 'assets/icons/icon-link.svg';
import facebook from 'assets/icons/icon-facebook-blue.svg';
import instagram from 'assets/icons/icon-Instagram-black.svg';
import whatsapp from 'assets/icons/icon-whatsapp1.svg';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';

const Deploy = () => {
  const { assistant_id } = useParams();
  const botsDeployList = [
    {
      id: 'add-to-website',
      icon: computer,
      title: 'Website links',
      to: `/chatbot-ai/assistant/${assistant_id}/deploy/website/add-to-website`,
      desc: 'Let your assistant go to work on your website or landing pages'
    },
    {
      id: 'shareable-page',
      icon: link,
      title: 'Shareable Page',
      desc: 'Share a link to your assistants dedicated page'
    },
    {
      id: 'facebook-messenger',
      icon: facebook,
      title: 'Facebook Messenger',
      desc: 'Put your assistant to work handling your Facebook enquiries'
    },
    {
      id: 'Instagram',
      icon: instagram,
      title: 'Instagram',
      desc: 'Put your assistant to work handling your Instagram enquiries'
    },
    {
      id: 'WhatsApp',
      icon: whatsapp,
      title: 'WhatsApp',
      desc: 'Put your assistant to work handling your WhatsApp enquiries'
    }
  ];
  const navigate = useNavigate();
  return (
    <section className="" style={{ padding: '25px 38px 40px' }}>
      <h2 className="text-xl font-semibold">
        Select where you would like to deploy your Assistant
      </h2>
      <ul className="bots-brain-list-wrapper">
        {botsDeployList.map((item, index) => (
          <li
            className="bots-brain-list"
            style={!isEmpty(item.to) ? {} : { pointerEvents: 'none' }}
            key={index}
            onClick={() => {
              if (!isEmpty(item.to)) navigate(item.to);
              else return;
            }}
          >
            <div
              className="flex items-center justify-center flex-shrink-0"
              style={{ minWidth: 33 }}
            >
              <img src={item.icon} alt="icon" />
            </div>
            <div className="flex flex-col ml-4 pr-8">
              <h4 className="text-xl font-semibold">{item.title}</h4>
              <p className="text-grey-800">{item.desc}</p>
            </div>
            {item.id !== 'add-to-website' && (
              <div className="bots-brain-list__label">Coming soon</div>
            )}
          </li>
        ))}
      </ul>
    </section>
  );
};

export default Deploy;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
// import { useDispatch } from 'react-redux';
import { Routes, Route } from 'react-router-dom';
// import ChannelList from './list';
const ChannelList = lazy(() => import('./list/main'));
import EnquiryHeader from './header';
import { Loader } from 'components/loader';
// import { getTotalOrganisationLeads } from 'slices/eboxLead';
// import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';

const Enquiries = () => {
  // const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(getTaskEnquiryCount());
  // }, []);

  return (
    <>
      <div className="dashboard__main__body__section-left ebox">
        <EnquiryHeader />
        {/* <div className="w-full flex items-center h-full">
              <Loader small />
            </div> */}
        <Suspense
          fallback={
            <div className="w-full flex items-center justify-center h-full">
              <Loader small />
            </div>
          }
        >
          <Routes>
            <Route path="/*" element={<ChannelList />} />
          </Routes>
        </Suspense>
      </div>
      <Outlet />
    </>
  );
};

export default Enquiries;

import React, { useRef, useState } from 'react';
import avatarPic from 'assets/icons/icon-variables.svg';
// import iconMyProfile from 'assets/icons/icon-my-profile.svg';
// import { useDispatch } from 'react-redux';
import useClickOutside from 'hooks/useOutsideClick';
import { useSelector } from 'react-redux';
// import { setEmailVariable } from 'slices/automationStuffSlice';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';

const facebookVariables = [
  {
    form_value: 'First_Name',
    id: 'a52aef17-89f5-4cdb-9d52-e1349f4fe99f',
    text: 'First Name'
  },

  {
    id: 'ad183914-29ae-405b-91dc-cb8334e61433',
    text: 'Last Name',
    form_value: 'Last_Name'
  }
];

const FACEBOOK = CHOICES.ChannelChoices.FACEBOOK;

const Variables = ({ onVariableClick, channelType }) => {
  const [openDropDown, SetOpenDropDown] = useState(false);
  const nodeRef = useRef();
  useClickOutside(() => SetOpenDropDown(false), nodeRef.current);

  const { allQuestions } = useSelector((state) => state.mapFields);
  return (
    <div
      className=" items-center flex ml-2 "
      onClick={() => {
        SetOpenDropDown(!openDropDown);
      }}
      ref={nodeRef}
    >
      <button className="toolbar-button">
        <img src={avatarPic} className="flex items-center" alt="avatar-pic" />
      </button>

      {openDropDown && (
        <div
          className={`dropdown-menu no-translate p-6 ${'show'}`}
          style={{
            width: 260,
            maxHeight: '90vh',
            overflow: 'auto',
            left: 15,
            bottom: 15
          }}
        >
          <span
            className="text-grey-800 py-2"
            style={{
              justifyContent: 'flex-start',
              fontSize: '12px',
              fontWeight: 500
            }}
          >
            Contact details
          </span>
          <ul>
            {!isEmpty(allQuestions) && channelType !== FACEBOOK
              ? allQuestions.map((item, idx) => {
                  return (
                    <li
                      className="flex py-2 bg-color-on-hover"
                      onClick={() => {
                        onVariableClick(item);
                      }}
                      key={idx}
                    >
                      {item.text}
                    </li>
                  );
                })
              : facebookVariables.map((item, idx) => {
                  return (
                    <li
                      className="flex py-2 bg-color-on-hover"
                      onClick={() => {
                        onVariableClick(item);
                      }}
                      key={idx}
                    >
                      {item.text}
                    </li>
                  );
                })}
            {/* <li
            className="relative pb-5 mb-5 border-b border-grey-400"
            style={{ width: '100%'}}
          ></li> */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Variables;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import SetUpVideo from 'components/setUpVideo';
import Header from '../../../../components/layout/header';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as FbIcon } from 'assets/icons/icon-button-fb.svg';
import ListItem from '../../../../components/layout/listItem';
import ListHeader from '../../../../components/layout/listHeader';
import { useNavigate, useParams } from 'react-router-dom';
import { route } from 'utils/route';
import { exchangeFaceBookOauthCode, getFbCreds } from 'slices/facebook/slice';
import { useDispatch, useSelector } from 'react-redux';
import useFacebook from 'hooks/useFacebook';
import _get from 'lodash/get';
import {
  getAllPendingChannels,
  getAllConnectedChannels
} from 'slices/channelsSlice';
import { useSnackbar } from 'components/Snackbar';
import { Loader } from 'components/loader';
import GuideLink from 'components/guideLink';
import { useAnalytics } from 'use-analytics';
import ChannelBack from '../back';
import CHOICES from 'choices';
import { ReactComponent as NewTabIcon } from 'assets/icons/icon-new-tab-white.svg';

const { FACEBOOK } = CHOICES.ChannelChoices;

const FacebookHome = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { channelId } = useParams();
  const { fbConfigurations } = useSelector((state) => state.fb);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const { track } = useAnalytics();
  const appId = _get(fbConfigurations, 'app_id', undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [FB, isReady] = useFacebook(appId);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    dispatch(getFbCreds());
  }, []);

  const onSuccessLogin = (res) => {
    if (res?.data?.connected_channel_id) {
      dispatch(getAllPendingChannels());
      dispatch(getAllConnectedChannels());
      openSuccessSnackBar('Connected');
      track(
        'channel_created',
        {
          channel_name: 'Facebook'
        },
        {
          groupId: subOrganizationId
        }
      );
      navigate(
        `${route.channels}/facebook/${FACEBOOK}/connected/${res.data.connected_channel_id}/automations`
      );
      setIsLoading(false);
    } else if (
      res?.data?.message.includes('no link, so stay on the same page.')
    ) {
      openErrorSnackBar('invalid token');
      setIsLoading(false);
    }
  };

  const onConnect = () => {
    if (FB && isReady && fbConfigurations && fbConfigurations.scope) {
      setIsLoading(true);
      try {
        FB.login(
          (resp) => {
            const signedRequest = _get(
              resp,
              'authResponse.signedRequest',
              false
            );
            if (signedRequest)
              dispatch(
                exchangeFaceBookOauthCode(
                  {
                    channelId: channelId,
                    data: {
                      signed_request: signedRequest
                    }
                  },
                  onSuccessLogin
                )
              );
            else {
              setIsLoading(false);
            }
          },
          {
            scope: fbConfigurations.scope
          }
        );
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  return (
    <div className="toolbox__main overflow-overlay channels">
      <div className="card-container--new connect-new">
        <ChannelBack />
        <Header
          title="Connect Facebook Pages"
          description="By connecting your Facebook page you will be able to receive and reply to messages, right
            here on this channel."
        />
        <div className="mt-4">
          <ListHeader
            title="To connect your account, you need these 2 things"
            className="text-grey-900 font-semibold"
          />
          <ul className="mt-4">
            <ListItem
              title="A Facebook Business page"
              className="text-sm text-grey-900 font-medium"
            />
            <ListItem
              title="Admin level access to that page"
              className="text-sm text-grey-900 font-medium mt-3"
            />
          </ul>
          <div className="bg-grey-200 rounded-xl py-3 px-4 mt-8 pr-16">
            <p className="text-grey-800 text-13">
              When you connect your page, we only access your page messages.
              Your personal messages will never be accessed by EnquiryBox.
            </p>
          </div>
          {(!FB ||
            !isReady ||
            !fbConfigurations ||
            !fbConfigurations.scope) && <Loader />}
          {FB && isReady && fbConfigurations && fbConfigurations.scope && (
            <CustomColorButtonWithLogo
              label="Connect my Facebook Page"
              className="btn--sm btn--facebook gap-2 mt-8 text-15"
              renderSvg={() => <FbIcon />}
              onClick={onConnect}
              isLoading={isLoading}
              enableLoader={true}
              loaderInline={true}
              renderRightSvg={() => <NewTabIcon />}
              isLoaderWhite={true}
            />
          )}
        </div>
        <p className="mt-4 text-grey-800 text-sm">
          A new tab will open and ask you to login with Facebook
        </p>
        <div className="mt-5">
          {/* <SetUpVideo
          src="https://player.vimeo.com/video/815596953?h=d7b8eb072a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          articleLink="https://help.enquirybox.io/en/articles/7228337-connect-facebook-pages"
        /> */}
          <GuideLink url="https://help.enquirybox.io/en/articles/7228337-connect-facebook-pages" />
        </div>
      </div>
    </div>
  );
};

export default FacebookHome;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SettingsHeader from 'components/settings/header';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
// import CreateCardSettings from './modules/carddesign/createCard';
import GeneralBoxSettingsMenu from './modules/menu';
import { useParams } from 'react-router-dom';
import BoxForm from './modules/boxForm';
import CreateCardDesignSettings from './modules/carddesign/createCardDesign';
import BoxContacts from './modules/box-contacs';
import BoringStuff from './modules/boringStuff';

import BoxAutomationStuff from './modules/automateStuff';
import { setCurrentBox, setCurrentBoxId } from 'slices/boxes/slice';
import BoxStages from './modules/boxStages';
import Wrapper from './wrapper';
import usePlanAccess from 'hooks/usePlanAccess';
import AnimationWrapper from 'animation/wrapper';

const GeneralBoxSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { box_type, box_id } = useParams();
  const { boxes } = useSelector((state) => state.boxes);
  const { has_custom_enquiry_form, has_card_design } = usePlanAccess();
  const currentBox = useMemo(() => {
    const x = boxes?.find((box) => {
      return box.id === box_id;
    });
    return x;
  }, [box_id, boxes]);

  useEffect(() => {
    dispatch(setCurrentBox(currentBox));
    dispatch(setCurrentBoxId(box_id));
  }, [currentBox]);

  const mainRoute = `${route.dashboard}${route.boxes}/${box_type}/${box_id}${route.settings}`;

  const onClose = () => {
    navigate(`${route.dashboard}${route.boxes}/${box_type}/${box_id}`);
  };

  if (!currentBox) return <></>;

  return (
    <section className="dashboard__main">
      <SettingsHeader
        text={`${currentBox?.box_name} Box Settings`}
        onClose={onClose}
      />
      <div
        className="dashboard__main__body general-settings"
        style={{ height: 'calc(100% - 100px)' }}
      >
        <div className="dashboard__main__body__section-left overflow-overlay">
          <GeneralBoxSettingsMenu mainRoute={mainRoute} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: 1
          }}
        >
          <Routes>
            <Route element={<AnimationWrapper />}>
              <Route
                path="/box-form"
                element={
                  <Wrapper showUpgradeTopbar={!has_custom_enquiry_form}>
                    <BoxForm />
                  </Wrapper>
                }
              />
              <Route
                path={`/card-design`}
                element={
                  <Wrapper showUpgradeTopbar={!has_card_design}>
                    <CreateCardDesignSettings />
                  </Wrapper>
                }
              />
              <Route
                path={`/boring-stuff`}
                element={
                  <Wrapper>
                    <BoringStuff />
                  </Wrapper>
                }
              />
              <Route
                path={`/box-contacts`}
                element={
                  <Wrapper>
                    <BoxContacts />
                  </Wrapper>
                }
              />
              <Route
                path={`/automation-stuff`}
                element={
                  <Wrapper>
                    <BoxAutomationStuff />
                  </Wrapper>
                }
              />
              <Route
                path={`/box-stages`}
                element={
                  <Wrapper>
                    <BoxStages
                      currentBox={currentBox}
                      setCurrentBox={setCurrentBox}
                    />
                  </Wrapper>
                }
              />
            </Route>
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default GeneralBoxSettings;

import React from 'react';
import PropTypes from 'prop-types';
import modalCloseIcon from 'assets/icons/icon-modal-close.svg';

const Main = (props) => {
  const { open, children, className, containerStyle, mainStyle } = props;
  return (
    <div
      className={`modal ${open ? 'show' : ''} ${className}`}
      style={mainStyle}
    >
      <div className="modal__container" style={containerStyle}>
        {children}
      </div>
    </div>
  );
};

const Header = (props) => {
  const { title, onClose, style, hideClose } = props;
  return (
    <div className="modal__header" style={style}>
      <h2 className="modal__heading pr-5">{title}</h2>
      {!hideClose && (
        <div className="modal__close-icon" onClick={onClose}>
          <img src={modalCloseIcon} alt="" />
        </div>
      )}
    </div>
  );
};

const Body = ({ className, id, children, style }) => {
  return (
    <div className={`modal__body px-8 py-6 ${className}`} id={id} style={style}>
      {children}
    </div>
  );
};

const Footer = ({ className, children }) => {
  return <div className={`modal__body px-8 py-6 ${className}`}>{children}</div>;
};

export default { Main, Header, Body, Footer };

Main.propTypes = {
  open: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Main.defaultProps = {
  className: '',
  mainStyle: {}
};

Header.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  style: PropTypes.object,
  hideClose: PropTypes.bool
};

Header.defaultProps = {
  title: 'title',
  onClose: () => {},
  hideClose: false
};

Body.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Body.defaultProps = {
  className: ''
};

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

Footer.defaultProps = {
  className: ''
};

import React, { useState } from 'react';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { ReactComponent as DeleteSvg } from 'assets/icons/icon-delete-white.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { deleteChannel } from 'slices/channelsSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getMyInfo } from 'slices/myInfoSlice';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';

const DeleteChannel = (props) => {
  const { channelId, onSuccess } = props;
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [showDelete, setShowDelete] = useState(false);
  const { isTeamMember } = useGetUserRole();

  const onDeleteSuccess = () => {
    setShowDelete(false);
    dispatch(getMyInfo());
    onSuccess();
    setIsLoading(false);
  };

  const onDeleteChannel = () => {
    setIsLoading(true);
    const formData = {
      subOrgId: subOrganizationId,
      channelId: channelId
    };
    dispatch(
      deleteChannel(
        {
          subOrgId: subOrganizationId,
          channelId: channelId,
          body: formData
        },
        onDeleteSuccess
      )
    );
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={onDeleteChannel}
        title="Are you sure you want to delete this channel?"
        description="Once deleted you will not be able to get it back. You will have to
      reconnect it again. Contacts in the channel will be stored in your
      all contacts list."
        isLoading={isLoading}
      />
      <div className="card-box mt-5 px-6 py-3">
        <div>
          <div className="flex justify-between items-center">
            <h3 className="font-semibold">Channel connection</h3>
            <AccessRestricted
              show={isTeamMember}
              alignment="left"
              customStyle={{
                bottom: '-12px',
                left: 'calc(50% - 244px)'
              }}
            >
              <CustomColorButtonWithLogo
                label="Delete"
                className={`btn--sm text-13 ${
                  isTeamMember ? 'btn--blocked' : 'btn--error'
                }`}
                style={{
                  paddingLeft: 15,
                  paddingRight: 15,
                  paddingTop: 6,
                  paddingBottom: 6
                }}
                renderSvg={() => <DeleteSvg />}
                onClick={() => {
                  if (!isTeamMember) {
                    setShowDelete(true);
                  }
                }}
              />
            </AccessRestricted>
          </div>
          {/* {isError && (
            <div className="flex error-view mt-4">
              <div className="self-center">
                <img src={iconAlert} alt="" />
              </div>
              <span className="text-13 text-red-900 text-center ml-3">
                The page connection has expired, please click the reconnect
                page button{' '}
              </span>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default DeleteChannel;

import { NavLink, useParams } from 'react-router-dom';
import NotificationComponent from './components/validationNotification';
import errorIcon from 'assets/icons/error-icon.svg';

import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorLength } from 'utils/aiAssistant';
import { isEmpty } from 'utils/utils';
import { setHeaderErrorCount } from 'slices/ai-assistant/assistant/slice';

const ChatbotTabs = () => {
  const location = window.location.pathname;
  let { assistant_id } = useParams();

  const isTabExists = location.includes('website-links');
  const hideOnProductInfo = location.includes('product-info');
  const hideOnDeployTab = location.includes('deploy/');
  const [error] = useState(false);
  const dispatch = useDispatch();
  const hideOnQna = location.includes('q-and-a');
  const testPage = location.includes('test-bot');
  const {
    personalityErrors,
    welcomeErrors,
    dataCollectionErrors,
    brainErrors
    // errorMain
  } = useSelector((state) => state.assistant);

  const [currPersonalityErrors, setCurrPersonalityErrors] = useState(0);
  const [currWelcomeErrors, setCurrWelcomeErrors] = useState(0);
  const [currDataCollectionErrors, setCurrDataCollectionErrors] = useState(0);
  const [currBrainErrors, setCurrBrainErrors] = useState(0);
  useEffect(() => {
    if (!isEmpty(personalityErrors)) {
      setCurrPersonalityErrors(getErrorLength(personalityErrors));
    } else {
      setCurrPersonalityErrors(0);
    }
  }, [personalityErrors]);

  useEffect(() => {
    if (!isEmpty(welcomeErrors)) {
      setCurrWelcomeErrors(getErrorLength(welcomeErrors));
    } else {
      setCurrWelcomeErrors(0);
    }
  }, [welcomeErrors]);

  useEffect(() => {
    if (!isEmpty(dataCollectionErrors)) {
      setCurrDataCollectionErrors(getErrorLength(dataCollectionErrors));
    } else {
      setCurrDataCollectionErrors(0);
    }
  }, [dataCollectionErrors]);

  useEffect(() => {
    if (!isEmpty(brainErrors)) {
      if (!brainErrors?.content) {
        setCurrBrainErrors(1);
      } else {
        setCurrBrainErrors(0);
      }
    } else {
      setCurrBrainErrors(0);
    }
  }, [brainErrors]);

  useEffect(() => {
    let totalCount =
      currPersonalityErrors +
      currWelcomeErrors +
      currDataCollectionErrors +
      currBrainErrors;
    if (totalCount > 0) {
      dispatch(setHeaderErrorCount(totalCount));
    } else {
      dispatch(setHeaderErrorCount(0));
    }
  }, [
    currPersonalityErrors,
    currWelcomeErrors,
    currDataCollectionErrors,
    currBrainErrors
  ]);

  const fileUploadPage = location.includes('/files');
  return (
    <>
      {!isTabExists &&
        !hideOnProductInfo &&
        !hideOnQna &&
        !hideOnDeployTab &&
        !testPage &&
        !fileUploadPage && (
          <div className="tabs-wrapper" style={{ zIndex: 10 }}>
            <div className="tabs-list-item">
              <NavLink
                className={({ isActive }) =>
                  'tabs-header ' + (isActive ? 'active' : '')
                }
                style={
                  error
                    ? {
                        paddingRight: '0px',
                        marginRight: '0px'
                      }
                    : {}
                }
                to={`/chatbot-ai/assistant/${assistant_id}/personality`}
              >
                🤓 Personality
              </NavLink>
              {currPersonalityErrors !== 0 && (
                <div className="error-count-wrapper">
                  {currPersonalityErrors}
                  <code className="error-icon-container">
                    <img src={errorIcon} alt="error-icon" />
                  </code>
                </div>
              )}
            </div>
            {error && (
              <NotificationComponent
                style={{ marginLeft: '10px', marginRight: '24px' }}
              />
            )}
            <div className="tabs-list-item">
              <NavLink
                className={({ isActive }) =>
                  'tabs-header ' + (isActive ? 'active' : '')
                }
                style={
                  error
                    ? {
                        paddingRight: '0px',
                        marginRight: '0px'
                      }
                    : {}
                }
                to={`/chatbot-ai/assistant/${assistant_id}/welcome`}
              >
                👋 Welcome
              </NavLink>
              {currWelcomeErrors !== 0 && (
                <div className="error-count-wrapper">
                  {currWelcomeErrors}
                  <code className="error-icon-container">
                    <img src={errorIcon} alt="error-icon" />
                  </code>
                </div>
              )}
            </div>
            {error && (
              <NotificationComponent
                style={{ marginLeft: '10px', marginRight: '24px' }}
              />
            )}{' '}
            <div className="tabs-list-item">
              <NavLink
                className={({ isActive }) =>
                  'tabs-header ' + (isActive ? 'active' : '')
                }
                style={
                  error
                    ? {
                        paddingRight: '0px',
                        marginRight: '0px'
                      }
                    : {}
                }
                to={`/chatbot-ai/assistant/${assistant_id}/data-collection`}
              >
                ✍️ Data collection
              </NavLink>
              {currDataCollectionErrors !== 0 && (
                <div className="error-count-wrapper">
                  {currDataCollectionErrors}
                  <code className="error-icon-container">
                    <img src={errorIcon} alt="error-icon" />
                  </code>
                </div>
              )}
            </div>
            {error && (
              <NotificationComponent
                style={{ marginLeft: '10px', marginRight: '24px' }}
              />
            )}{' '}
            <div className="tabs-list-item">
              <NavLink
                className={({ isActive }) =>
                  'tabs-header ' + (isActive ? 'active' : '')
                }
                style={
                  error
                    ? {
                        paddingRight: '0px',
                        marginRight: '0px'
                      }
                    : {}
                }
                to={`/chatbot-ai/assistant/${assistant_id}/brain`}
              >
                🧠 Brain
              </NavLink>
              {currBrainErrors !== 0 && (
                <div className="error-count-wrapper">
                  {currBrainErrors}
                  <code className="error-icon-container">
                    <img src={errorIcon} alt="error-icon" />
                  </code>
                </div>
              )}
            </div>
            {error && (
              <NotificationComponent
                style={{ marginLeft: '10px', marginRight: '24px' }}
              />
            )}{' '}
            <div className="tabs-list-item">
              <NavLink
                className={({ isActive }) =>
                  'tabs-header ' + (isActive ? 'active' : '')
                }
                style={
                  error
                    ? {
                        paddingRight: '0px',
                        marginRight: '0px'
                      }
                    : {}
                }
                to={`/chatbot-ai/assistant/${assistant_id}/deploy`}
              >
                🎯 Deploy
              </NavLink>
            </div>
            {error && (
              <NotificationComponent
                style={{ marginLeft: '10px', marginRight: '24px' }}
              />
            )}
          </div>
        )}
    </>
  );
};

export default ChatbotTabs;

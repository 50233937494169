import React from 'react';
import PropTypes from 'prop-types';
// import iconMoreRound from 'assets/icons/icon-more-round.svg';
// import DropDownWrapper from 'components/dropdown/wrapper';
// import DropDownBody from 'components/dropdown/body';

const SectionHeader = (props) => {
  const {
    label
    // onClick,
    // isDropDown,
    // open,
    // dropdownRef,
    // options,
    // dropdownStyle,
    // dropDownClassName,
    // showMoreIcon
  } = props;
  return (
    <div className="flex items-center justify-between pb-2">
      <h3 className="text-lg font-semibold text-grey-900">{label}</h3>
      {/* <figure onClick={onClick} className="cursor-pointer relative">
        {showMoreIcon && <img src={iconMoreRound} alt="icon-more" />}
        {isDropDown && (
          <DropDownWrapper
            dropDownref={dropdownRef}
            open={open}
            className={dropDownClassName}
            style={dropdownStyle}
          >
            <DropDownBody open={open} className="">
              {options.map((item) => {
                return (
                  <div className="dropdown-menu__list gap-3" key={item.id}>
                    <item.icon />
                    {item.title}
                  </div>
                );
              })}
            </DropDownBody>
          </DropDownWrapper>
        )}
      </figure> */}
    </div>
  );
};

SectionHeader.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  isDropDown: PropTypes.bool,
  showMoreIcon: PropTypes.bool,
  open: PropTypes.bool,
  options: PropTypes.array,
  dropDownClassName: PropTypes.string
};

SectionHeader.defaultProps = {
  showMoreIcon: false,
  isDropDown: false,
  open: false
};

export default SectionHeader;

/* eslint-disable no-unused-vars */

import React, { useState } from 'react';
import DropDownWrapper from 'components/dropdown/wrapper';
import DropDownBody from 'components/dropdown/body';
import { ReactComponent as CaretIconDown } from 'assets/icons/icon-caret-down.svg';
import { ReactComponent as UserIcon } from 'assets/icons/icon-team-user.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-delete-user-red.svg';
import CHOICES from 'choices';

const { TEAM_ADMIN, TEAM_SUPER_ADMIN } = CHOICES.TeamMemberRoleChoice;

const ButtonItem = (props) => {
  const {
    onClick,
    dropdownRef,
    open,
    style,
    isStatus,
    dropdownStyle,
    onSelect,
    user,
    onClose,
    isHideDropdown,
    disabled
  } = props;
  const [selectedRole, setSelectedRole] = useState(
    user?.role === TEAM_ADMIN || user?.role === TEAM_SUPER_ADMIN
      ? 'Team admin'
      : 'Team member'
  );
  const [roleOption, setRoleOption] = useState(
    user?.role === TEAM_ADMIN || user?.role === TEAM_SUPER_ADMIN
      ? 'Team member'
      : 'Team admin'
  );

  const onOptionSelect = (e) => {
    e.stopPropagation();
    if (!isStatus) {
      setSelectedRole(roleOption);
      setRoleOption(selectedRole);
      onSelect(user?.organization_user_id, roleOption);
    } else {
      onSelect(user?.organization_user_id);
    }
    onClose();
  };

  return (
    <div
      className="bg-grey-300 border border-grey-400 rounded-md relative flex items-center px-2 cursor-pointer justify-between"
      onClick={onClick}
      ref={dropdownRef}
      style={{
        fontSize: 11,
        fontWeight: 500,
        maxHeight: 30,
        height: 30,
        ...style
      }}
    >
      <DropDownWrapper open={open} style={dropdownStyle}>
        <DropDownBody>
          <div
            className="dropdown-menu__list gap-1"
            style={{ padding: '4px 2px' }}
            onClick={(e) => onOptionSelect(e)}
          >
            {!isStatus ? <UserIcon /> : <DeleteIcon />}
            <p
              style={{ fontSize: 11, fontWeight: 500 }}
              className={`${isStatus ? 'text-red-900' : ''}`}
            >
              {!isStatus ? roleOption : 'Delete team member'}
            </p>
          </div>
        </DropDownBody>
      </DropDownWrapper>
      {isStatus && (
        <code
          style={
            disabled
              ? {
                  borderRadius: '50%',
                  width: 6,
                  height: 6,
                  background: '#AAAAAA'
                }
              : {
                  borderRadius: '50%',
                  width: 6,
                  height: 6,
                  background: '#219653'
                }
          }
        />
      )}
      <p>{!isStatus ? selectedRole : disabled ? 'Disabled' : 'Active'}</p>
      <CaretIconDown className={`${isHideDropdown ? 'opacity-0' : ''}`} />
    </div>
  );
};

export default ButtonItem;

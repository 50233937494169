/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as IconMore } from 'assets/icons/icon-more-icon.svg';
import useClickOutside from 'hooks/useOutsideClick';
import iconEdit from 'assets/icons/icon-edit.svg';

const QuestionHeader = (props) => {
  const {
    label,
    renderSvg,
    isShowMoreIcon,
    id,
    onDelete,
    isBin,
    hideOptions,
    onEdit
  } = props;
  const nodeRef = useRef();
  const [openDropdown, setOpenDropdown] = useState(false);

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  return (
    <div
      className={`py-3 px-5 bg-white border-t-r-10 border-t-l-10 flex ${
        isShowMoreIcon ? 'justify-between items-center' : ''
      }`}
    >
      <div className="flex gap-2">
        {renderSvg && typeof renderSvg === 'function' && (
          <figure
            className="bg-grey-100 flex justify-center items-center"
            style={{ width: 24, height: 24, borderRadius: 5 }}
          >
            {renderSvg()}
          </figure>
        )}
        <h3 className="text-15 font-medium">{label}</h3>
      </div>
      {!isBin && isShowMoreIcon && !hideOptions && (
        <div
          className="cursor-pointer relative p-2"
          onClick={() => setOpenDropdown(true)}
          ref={nodeRef}
        >
          <IconMore />
          <div
            className={`dropdown-menu dropdown-menu--more ${
              openDropdown ? 'open' : ''
            }`}
            style={{ top: 10, zIndex: 10 }}
          >
            <div
              className="dropdown-menu__list dropdown-menu__list--sm"
              onClick={onEdit}
            >
              <img
                src={iconEdit}
                alt=""
                style={{ width: 16, height: 16 }}
                className="mr-1"
              />
              Edit
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

QuestionHeader.propTypes = {
  label: PropTypes.string,
  renderSvg: PropTypes.func,
  hideOptions: PropTypes.bool
};
QuestionHeader.defaultProps = {
  hideOptions: false
};

export default QuestionHeader;

import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/buttons/primary';
import TextInputField from '../textField';
import CustomColorButton from 'components/buttons/customColorButton';

const InputWithButton = (props) => {
  const {
    small,
    medium,
    large,
    buttonClassName,
    style,
    placeholder,
    buttonLabel,
    onClick,
    inputName,
    errors,
    touchedFields,
    register,
    defaultValue,
    isCancelButton,
    cancelButtonClassName,
    cancelButtonLabel,
    onCancel,
    onKeyPress,
    isLoading,
    enableLoader,
    onChange,
    id
  } = props;

  const getSize = () => {
    if (large) return 'input-field--lg';
    if (medium) return 'input-field--md';
    if (small) return 'input-field--sm';
  };
  return (
    <div>
      <TextInputField
        id={id}
        name={inputName}
        className={`input-field ${getSize()}`}
        style={style}
        placeholder={placeholder}
        error={errors[inputName] && touchedFields[inputName]}
        register={register}
        defaultValue={defaultValue}
        onKeyPress={onKeyPress}
        onChange={onChange}
      />
      {isCancelButton && (
        <CustomColorButton
          type="button"
          className={cancelButtonClassName}
          label={cancelButtonLabel}
          onClick={onCancel}
          isLoading={isLoading}
        />
      )}
      <PrimaryButton
        type="submit"
        label={buttonLabel}
        className={buttonClassName}
        onClick={onClick}
        enableLoader={enableLoader}
        isLoading={isLoading}
      />
    </div>
  );
};

InputWithButton.propTypes = {
  small: PropTypes.bool,
  medium: PropTypes.bool,
  large: PropTypes.bool,
  style: PropTypes.object,
  buttonClassName: PropTypes.string,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string.isRequired,
  inputName: PropTypes.string.isRequired,
  isCancelButton: PropTypes.bool,
  cancelButtonLabel: PropTypes.string,
  cancelButtonClassName: PropTypes.string,
  onCancel: PropTypes.func
};

InputWithButton.defaultProps = {
  small: true,
  medium: false,
  large: false,
  placeholder: 'folders02/1axsRtXwQ_H6JOxbYzQPqPeXDrEs...',
  buttonLabel: 'Copy',
  isCancelButton: false
};

export default InputWithButton;

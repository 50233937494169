/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-white.svg';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import AddQuestions from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/component/addQuestions';
import QuestionsForm from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/module/questionsForm';
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionsList } from 'slices/questionSlice';
import { isEmpty } from 'utils/utils';
import QuestionList from './questionList';

const QuestionSettings = () => {
  const dispatch = useDispatch();
  const [showQuestionType, setShowQuestionType] = useState(false);
  const { isShowQuestionForm, questionsData } = useSelector(
    (state) => state.question
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const onClick = () => {
    setShowQuestionType(true);
  };

  useEffect(() => {
    dispatch(getQuestionsList({ subOrgId: subOrganizationId }));
  }, []);

  return (
    <div className="flex flex-col items-center">
      <h2 className="text-2xl font-semibold mb-9">Questions</h2>
      <div className="flex justify-between bg-grey-300 border-radius-10 pt-4 pr-3 pb-3 pl-5">
        <p className="text-15 font-medium pr-2">
          Questions help you collect and store any information about your
          contacts
        </p>
        <PrimaryWithLogo
          label="Create a question"
          renderSvg={() => <PlusIcon />}
          style={{ maxHeight: 35, minWidth: 150 }}
          onClick={onClick}
        />
      </div>
      {!isEmpty(questionsData) && !showQuestionType && !isShowQuestionForm && (
        <QuestionList data={questionsData} />
      )}
      {showQuestionType && !isShowQuestionForm && (
        <AddQuestions
          closeQuestionType={() => {
            setShowQuestionType(false);
          }}
        />
      )}
      {isShowQuestionForm && <QuestionsForm isSettings={true} />}
    </div>
  );
};

export default QuestionSettings;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import ErrorMessage from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import { ReactComponent as MoreIcon } from 'assets/icons/icon-more-round.svg';
import { ReactComponent as RenameIcon } from 'assets/icons/icon-select-rename.svg';
import { isEmpty } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { setTaskOutcome, updateTaskOutcome } from 'slices/task/slice';

const TaskItem = (props) => {
  const {
    id,
    title,
    setShowMoreDropdown,
    setOpenEdit,
    showMoreDropdown,
    openEdit
  } = props;
  const dispatch = useDispatch();
  const [isError, setIsError] = useState(''); // state to show error messages
  const [inputValue, setInputValue] = useState(title); // state to store outcame name
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { taskOutcome } = useSelector((state) => state.tasks);

  const callback = (data) => {
    // update the task outcome data instead of calling api
    const updatedData = taskOutcome.map((item) => {
      if (item.id === data.id) {
        return {
          ...data
        };
      }
      return item;
    });
    setIsLoading(false);
    dispatch(setTaskOutcome(updatedData));
    setOpenEdit(null);
    setShowMoreDropdown(null);
  };

  // function for save outcome name
  const onSubmit = (e) => {
    e.preventDefault();
    if (inputValue === title) {
      // if the typed name is equal to previous name do nothing
      setOpenEdit(null);
      setShowMoreDropdown(null);
    } else {
      if (inputValue.length > 20) {
        // if the typed name is greater that 20 characters show this error
        setIsError('outcome name cannot be more than 20 characters');
        return;
      }
      if (inputValue === '') {
        // if the typed name is empty show this error
        setIsError('outcome name cannot be empty');
      } else {
        setIsLoading(true);
        const formData = {
          outcome_id: id,
          options: inputValue,
          sub_org: subOrganizationId
        };
        // function to call update outcame name api
        dispatch(updateTaskOutcome(formData, callback));
      }
    }
  };

  // function to call when clicked on cancel button
  const onCancel = () => {
    setOpenEdit(null);
    setShowMoreDropdown(null);
    setIsError('');
  };

  return (
    <>
      {!(openEdit === id) ? (
        <div
          className="py-3 px-3 flex justify-between items-center hover-bg-grey"
          style={{ marginTop: 2 }}
        >
          <span className="font-medium">{title}</span>
          <div
            className="cursor-pointer relative p-2"
            onClick={() => setShowMoreDropdown(id)}
          >
            <MoreIcon />
            <div
              className={`dropdown-menu dropdown-menu--more ${
                showMoreDropdown === id ? 'open' : ''
              }`}
              style={{ top: 20 }}
            >
              <div
                className="dropdown-menu__list gap-3"
                onClick={() => setOpenEdit(id)}
              >
                <RenameIcon />
                Rename
              </div>
            </div>
          </div>
        </div>
      ) : (
        <form style={{ marginTop: 2 }} autoComplete="off">
          <div className="relative">
            <div>
              <TextInputField
                name="labelName"
                className="input-field input-field--md"
                placeholder=""
                error={!isEmpty(isError) ? true : false}
                defaultValue={title}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onSubmit(e);
                  }
                }}
                onChange={(e) => {
                  setIsError('');
                  setInputValue(e.target.value);
                }}
              />
              <CustomColorButton
                className="btn--xs btn--input-cancel btn--grey text-xs"
                label="Cancel"
                onClick={onCancel}
                isLoading={isLoading}
              />
              <PrimaryButton
                label="Save"
                className="btn--xs btn--copy text-xs"
                onClick={(e) => onSubmit(e)}
                enableLoader
                isLoading={isLoading}
              />
            </div>
          </div>
          {isError && <ErrorMessage message={isError} />}
        </form>
      )}
    </>
  );
};

export default TaskItem;

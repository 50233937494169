import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  DELETE_SINGLE_FILE,
  GET_FILE_DATA,
  UPLOAD_FILE
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';

const initialState = {
  fileData: []
};

const reducers = {
  setFileData: (state, action) => {
    state.fileData = action.payload;
  },
  resetFileData: () => initialState
};

export const fileData = createSlice({
  name: 'fileData',
  initialState,
  reducers
});

export const { setFileData, resetFileData } = fileData.actions;
export default fileData.reducer;

export const updloadFile = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { fileData } = getState();
    const fileDetails = fileData;
    const formData = payload.data;
    const assistant_id = payload.assistant_id;

    try {
      new APIRequest()
        .post(replaceUrl(UPLOAD_FILE, 'aiBotId', assistant_id), formData, {
          'Content-Type': 'multipart/form-data'
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFileData([...fileDetails.fileData, res.data]));
            dispatch(setVersionStatus(res?.data?.version_status));
            if (successCb) successCb();
          }
        })
        .catch((err) => {
          if (failedCb) failedCb();
          console.log(err);
        });
    } catch (e) {
      if (failedCb) failedCb();
      console.log(e);
    }
  };
};

export const getFileData = (assistant_id, callBack) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .get(replaceUrl(GET_FILE_DATA, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setFileData(res.data));
            if (callBack) callBack();
          }
        })
        .catch((err) => {
          if (callBack) callBack();
          console.log(err);
        });
    } catch (e) {
      if (callBack) callBack();
      console.log(e);
    }
  };
};

export const deleteSingleFile = (fileId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { currentVersionId } = getState().assistant;
    const { fileData } = getState().fileData;
    const payload = {
      version_id: currentVersionId
    };
    try {
      new APIRequest()
        .delete(replaceUrl(DELETE_SINGLE_FILE, 'fileId', fileId), payload)
        .then((res) => {
          if (res.status === 200) {
            const filteredFiles = fileData.filter(
              (file) => file.id !== res.data.file_id
            );
            dispatch(setFileData(filteredFiles));
            dispatch(setVersionStatus(res.data.version_status));
            if (successCb) successCb();
          }
        })
        .catch((err) => {
          if (failedCb) failedCb();
          console.log(err);
        });
    } catch (e) {
      if (failedCb) failedCb();
      console.log(e);
    }
  };
};

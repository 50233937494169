/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import TextInputField from 'components/inputs/textField';
import TextArea from 'components/inputs/textarea';
import MapFieldContainer from '../mapFieldContainer';
import { isEmpty, timeFormatter } from 'utils/utils';
// import iconExpand from 'assets/icons/icon-expand.svg';
// import CustomToolTip from 'components/CustomTooltip';
// import iconCollapse from 'assets/icons/icon-collapse.svg';

const FbLeadAdsContainer = (props) => {
  const { onClick, time, data, isShowMapping, isCollapse, isBin, lead } = props;
  const [conversationMessage, setConversationMessage] = useState({});
  const [fbLeadAdsFieldList, setFbLeadAdsFieldList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(isCollapse);
  const [showExpandIcon, setShowExpandIcon] = useState(null);
  const [errorLoad, setErrorLoad] = useState(false);

  useEffect(() => {
    evaluteData(data);
    return () => {
      setErrorLoad(false);
    };
  }, []);

  const evaluteData = (data) => {
    if (data.mapped_message) {
      if (data.mapped_message && typeof data.mapped_message === 'string') {
        let tempData = {};
        let tempList = [];
        tempData = data.mapped_message.replaceAll("'", '"');
        try {
          tempData = JSON.parse(tempData);
        } catch (e) {
          console.log(e);
          setErrorLoad(true);
          return;
        }
        Object.keys(tempData).forEach((item) => {
          if (!['sub_data', 'form_name'].includes(item)) {
            tempList.push(tempData[item]);
          }
        });
        const finalList = tempList.reduce((acc, obj) => {
          Object.keys(obj).forEach((key) => {
            acc.push({ label: key, [key]: obj[key] });
          });
          return acc;
        }, []);
        setFbLeadAdsFieldList(finalList);
        setConversationMessage(tempData);
      }
    } else {
      if (data.message && typeof data.message === 'string') {
        let tempData = {};
        let tempList = [];

        tempData = data.message.replaceAll("'", '"');
        try {
          tempData = JSON.parse(tempData);
        } catch (e) {
          console.log(e);
          setErrorLoad(true);
          return;
        }
        Object.keys(tempData).forEach((item) => {
          if (!['main_data'].includes(item)) {
            if (Array.isArray(tempData[item])) {
              tempData[item].map((keys) => {
                tempList.push({ label: keys?.key, [keys.key]: keys.value });
              });
            } else {
              tempList.push(tempData[item]);
            }
          }
        });
        const finalList = tempList.reduce((acc, obj) => {
          Object.keys(obj).forEach((key) => {
            acc.push({ label: key, [key]: obj[key] });
          });
          return acc;
        }, []);

        setFbLeadAdsFieldList(finalList);
        setConversationMessage(tempData);
      }
    }
  };

  if (errorLoad) {
    return (
      <div className="" style={{ marginLeft: 6, width: '98%' }}>
        <div
          className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
          style={{
            borderRadius: 10,
            padding: '8px 22px',
            backgroundColor: '#EEF7FC',
            width: 'calc(100% - 100px)',
            marginRight: 0
          }}
        >
          Failed to load data.
        </div>
      </div>
    );
  }

  return (
    <>
      {isCollapsed ? (
        <div className="" style={{ marginLeft: 6, width: '100%' }}>
          <div
            className="inline-block border border-grey-400 text-base-1 text-grey-900 cursor-pointer"
            style={{
              borderRadius: 10,
              padding: '9px 24px 9px 16px',
              backgroundColor: '#EEF7FC',
              width: 'calc(100% - 100px)',
              marginRight: 0
            }}
            // onClick={() => setIsCollapsed(false)}
            // onMouseEnter={() => setShowExpandIcon(data.id)}
            // onMouseLeave={() => setShowExpandIcon(null)}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="font-semibold">
                  {data.lead.name ? data?.lead.name : data.lead.first_name}
                </h3>
                <div className={`truncate flex`}>
                  <p className="font-medium text-13">Lead ad form: </p>
                  <span className="text-13">
                    {!isEmpty(conversationMessage) &&
                      conversationMessage?.main_data.map((data) => {
                        return data.key === 'Form name' && data.value;
                      })}
                  </span>
                </div>
              </div>
              {/* {showExpandIcon === data.id && (
                <CustomToolTip position="top" tooltipText="Expand">
                  <img
                    src={iconExpand}
                    className="cursor-pointer"
                    alt="icon-expand"
                    // onClick={() => setIsCollapsed(false)}
                  />
                </CustomToolTip>
              )} */}
            </div>
          </div>
          <div>
            <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
              {timeFormatter(time)}
            </div>
          </div>
        </div>
      ) : (
        <div className="" style={{ marginLeft: 6, width: '100%' }}>
          <div
            className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
            style={{
              borderRadius: 10,
              padding: '22px 22px 25px',
              backgroundColor: '#EEF7FC',
              width: 'calc(100% - 30px)',
              marginRight: 0
            }}
          >
            {/* <CustomToolTip
              position="bottom"
              tooltipText="Collapse"
              style={{ position: 'absolute', right: '7px', top: '6px' }}
            >
              <img
                src={iconCollapse}
                alt="icon-expand"
                className="cursor-pointer"
                onClick={() => setIsCollapsed(true)}
              />
            </CustomToolTip> */}
            {isShowMapping && (
              <MapFieldContainer
                onClick={onClick}
                isBin={isBin}
                lead={lead}
                isFbLeads={true}
              />
            )}
            <div className="email-body--webform">
              {fbLeadAdsFieldList.map((list, index) => {
                const { label } = list;
                if (label === 'Enteryourtextarea:') {
                  return (
                    <div key={index}>
                      <label className="input-field-label">{label}</label>
                      <TextArea
                        name={label}
                        value={
                          conversationMessage?.form_data[label]
                            ? conversationMessage?.form_data[label]
                            : ''
                        }
                        style={{ fontWeight: 400 }}
                        className="border text-base-1 resize-none"
                        readOnly
                        rows="3"
                        cols="5"
                      />
                    </div>
                  );
                }
                if (!['Form name', 'Page name'].includes(label)) {
                  return (
                    <div className="mb-5" key={index}>
                      <label className="input-field-label">{label}</label>
                      <TextInputField
                        className="input-field--md"
                        style={{ fontWeight: 400 }}
                        readOnly
                        value={
                          conversationMessage?.lead_data[label]
                            ? conversationMessage?.lead_data[label]
                            : ''
                        }
                        name={label}
                      />
                    </div>
                  );
                }
              })}
            </div>
            <div
              className={`text-13 truncate ${isShowMapping ? 'mt-1' : 'mt-5'}`}
            >
              <span className="font-semibold">Lead ad form: </span>
              {!isEmpty(conversationMessage) &&
                conversationMessage?.main_data.map((data) => {
                  return data.key === 'Form name' && data.value;
                })}
            </div>
          </div>
          <div>
            <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
              {timeFormatter(time)}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FbLeadAdsContainer;

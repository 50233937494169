import pdfIcon from 'assets/icons/icon-file-upload-pdf.svg';
import pngIcon from 'assets/icons/icon-file-upload-png.svg';
import docxIcon from 'assets/icons/icon-file-upload-docx.svg';
import defaultIcon from 'assets/icons/icon-file-upload-default.svg';

export const fileIcons = {
  pdf: pdfIcon,
  png: pngIcon,
  doc: docxIcon,
  docs: docxIcon,
  docx: docxIcon,
  jpeg: pngIcon,
  jpg: pngIcon,
  xlsx: defaultIcon,
  csv: defaultIcon,
  xml: defaultIcon,
  xls: defaultIcon,
  pptx: defaultIcon,
  ppt: defaultIcon
};

export const zapierFileIcons = {
  'text/csv': defaultIcon,
  'image/jpeg': pngIcon,
  'application/octet-stream': defaultIcon,
  'application/msword': docxIcon,
  'application/pdf': pdfIcon,
  'image/png': pngIcon,
  'application/vnd.ms-excel': docxIcon
};

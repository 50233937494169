import React from 'react';
import Layout from '../layout';
import user1Icon from 'assets/icons/icon-avatar-image-1.svg';
import { timeFormatter } from 'utils/utils';

const LeadAssigned = ({ data }) => {
  const { description, created_at, title, url } = data;
  return (
    <Layout
      title={title}
      desc={description}
      className="team-chat-feed__new-enquiry"
      icon={user1Icon}
      time={timeFormatter(created_at)}
      showViewEnquiryBtn={true}
      to={url}
    />
  );
};

export default LeadAssigned;

import { Route, Routes } from 'react-router-dom';
import BrainTab from './BrainTab';
import Files from './files';
import WebsiteLinkRoutes from './WebsiteLinkRoutes';
import QuestionsAndAnswers from './qna';
import EditQna from './qna/editQna';
import ProductHome from './productInfo/home';
const AllRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<BrainTab />} />
        <Route path="/website-links/*" element={<WebsiteLinkRoutes />} />
        <Route path="/product-info/*" element={<ProductHome />}></Route>
        <Route path={'/q-and-a/*'} element={<QuestionsAndAnswers />} />
        <Route path={'/q-and-a/:qnaId/*'} element={<EditQna />} />
        <Route path="/files/*" element={<Files />} />
      </Routes>
    </>
  );
};

export default AllRoute;

import React from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import InputField from 'components/inputs/textField';
import Error from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';

const RenameForm = (props) => {
  const { fileName, onCancel, onSave } = props;
  const formSchema = {
    newFileName: string()
      .required('Please provide file name')
      .max(50, 'File name can be maximum 50 characters')
  };

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    defaultValues: {
      newFileName: fileName
    },
    mode: 'all'
  });

  const onSubmit = (val) => {
    onSave(val.newFileName);
  };

  const onKeyEnter = (name) => {
    onSave(name);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      {/* <input
                  // onFocus={() => setIsInputFocused(true)}
                  // onBlur={() => setIsInputFocused(false)}
                  placeholder={newFileName}
                  value={newFileName}
                  onChange={(e) => setNewFileName(e.target.value)}
                  className="input-field input-field--edit"
                /> */}
      <InputField
        name="newFileName"
        placeholder="File name"
        icon={false}
        // renderIcon={() => <EmailIcon />}
        error={errors.newFileName && touchedFields.newFileName}
        className="input-field input-field--md"
        register={register}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            onKeyEnter(getValues('newFileName'));
          }
        }}
      />
      {errors.newFileName && touchedFields.newFileName && (
        <Error message={errors.newFileName.message} />
      )}
      <div className="flex justify-end mt-3">
        <button
          className="btn bg-grey-300 btn--edit-buttons"
          onClick={onCancel}
        >
          Cancel
        </button>
        {/* <button type="submit" className="btn btn--primary btn--edit-buttons"
                  >Save</button> */}
        <PrimaryButton
          type="submit"
          label="Save"
          className="btn--edit-buttons"
          // isLoading={isLoading}
          // enableLoader={true}
        />
      </div>
    </form>
  );
};

export default RenameForm;

import React, { useRef, useState } from 'react';
import iconUploadPic from 'assets/icons/icon-upload-pic.svg';
import APIRequest from 'apiRequest';
import { FILE_UPLOAD } from 'urls';
import { Loader } from 'components/loader';
import CHOICES from 'choices';
import { useLocation } from 'react-router-dom';
import { route } from 'utils/route';

const ImageUpload = ({ onImageUpload }) => {
  const inputRef = useRef();
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const checkUrl = location && location.pathname;

  const onClick = () => {
    inputRef.current.click();
  };

  const onFileSelect = (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('file', file);
    if (checkUrl.split('/').includes(route.myProfile)) {
      formData.append(
        'uploading_type',
        CHOICES.FileUploadingChoices.PROFILE_PHOTO
      );
    } else {
      formData.append(
        'uploading_type',
        CHOICES.FileUploadingChoices.EMAIL_OUTGOING
      );
    }
    setIsLoading(true);
    new APIRequest()
      .post(FILE_UPLOAD, formData, {
        'Content-Type': 'multipart/form-data'
      })
      .then((res) => {
        onImageUpload(res?.data?.imageUrl);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
    // onImageUpload()
  };

  return (
    <div className="relative">
      {isLoading && (
        <div
          style={{
            position: 'relative',
            top: '-4px',
            left: '-13px',
            width: '32px'
          }}
        >
          <Loader tiny />
        </div>
      )}
      {!isLoading && (
        <>
          <input
            className="hidden"
            type="file"
            onChange={onFileSelect}
            ref={inputRef}
            accept="image/*"
          />
          <button className="toolbar-button flex" onClick={onClick}>
            <img src={iconUploadPic} alt="icon-upload-pic" />
          </button>
        </>
      )}
    </div>
  );
};

export default ImageUpload;

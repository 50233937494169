/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import TotalEnquiry from '../../components/totalEnquiries';
import TotalEnquiryPerChannel from '../../components/enquiryPerChannel';
import { isEmpty } from 'utils/utils';
import { formatDateTime } from 'utils/dateFormat';
import { useDispatch, useSelector } from 'react-redux';
import { getAllIncomingEnquiryReport } from 'slices/reports/slice';
import moment from 'moment';
import { Loader } from 'components/loader';
import FilterSection from '../../components/filterSection';

const IncomingEnquiryReport = () => {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { incomingReport } = useSelector((state) => state.reports);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllReport = () => {
    const formData = {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      date_format: 'This month to date',
      subOrgId: subOrganizationId
    };
    dispatch(getAllIncomingEnquiryReport(formData, callback));
  };

  useEffect(() => {
    setIsLoading(true);
    loadAllReport();
  }, []);

  const onSelectDay = (data) => {
    setStartDate();
    setEndDate();
    if (data.value === 'This month to date') {
      loadAllReport();
    }
    if (data.value === 'Last 7 days') {
      const formData = {
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        date_format: data.value,
        subOrgId: subOrganizationId
      };
      dispatch(getAllIncomingEnquiryReport(formData, callback));
    }
  };

  const onCustomDateSelect = (data) => {
    if (data.startDate && data.endDate) {
      const start_date = formatDateTime(data.startDate, 'yyyy-mm-dd');
      const end_date = formatDateTime(data.endDate, 'yyyy-mm-dd');
      const formData = {
        startDate: start_date,
        endDate: end_date,
        date_format: 'Custom',
        subOrgId: subOrganizationId
      };
      dispatch(getAllIncomingEnquiryReport(formData, callback));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Snapshot</h3>
        <FilterSection
          endDate={endDate}
          startDate={startDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
          setSelectedDay={setSelectedDay}
          selectedDay={selectedDay}
          onSelectDay={onSelectDay}
          onCustomDateSelect={onCustomDateSelect}
        />
      </div>
      {!isEmpty(incomingReport) && (
        <>
          <div className="mt-6">
            <TotalEnquiry
              total={incomingReport?.report?.total_enquiries}
              percentage={incomingReport?.report?.total_enquiries_percentage}
              selectedDay={selectedDay?.value}
            />
          </div>
          <div className="mt-8">
            <TotalEnquiryPerChannel
              data={incomingReport?.report?.channel_report}
            />
          </div>
        </>
      )}
    </>
  );
};

export default IncomingEnquiryReport;

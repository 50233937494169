import CHOICES from 'choices';
import { ReactComponent as TextIcon } from 'assets/icons/icon-question-type-text.svg';
import { ReactComponent as MultipleOptionsIcon } from 'assets/icons/icon-question-type-multiple-options.svg';
import { ReactComponent as DateIcon } from 'assets/icons/icon-question-type-date.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-question-type-dropdown.svg';

const { TEXT, DROPDOWN, DATETIME, MCQ } = CHOICES.QuestionChoices;

export const questionTypes = [
  {
    id: 1,
    label: CHOICES.QuestionChoices.CHOICES[TEXT],
    type: TEXT,
    icon: TextIcon
  },
  {
    id: 2,
    label: CHOICES.QuestionChoices.CHOICES[DROPDOWN],
    type: DROPDOWN,
    icon: DropdownIcon
  },
  {
    id: 3,
    label: CHOICES.QuestionChoices.CHOICES[DATETIME],
    type: DATETIME,
    icon: DateIcon
  },
  {
    id: 4,
    label: CHOICES.QuestionChoices.CHOICES[MCQ],
    type: MCQ,
    icon: MultipleOptionsIcon
  }
];

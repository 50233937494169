import assistantReducer from '../../slices/ai-assistant/assistant/slice';
import createAssistantReducer from '../../slices/ai-assistant/dataCollection/createAI/slice';
import welcomePageReducer from '../../slices/ai-assistant/welcome/slice';
import personalityReducer from '../../slices/ai-assistant/personality/slice';
import dataCollectionReducer from '../../slices/ai-assistant/dataCollection/slice';
import productInfoReducer from '../../slices/ai-assistant/productInfo/slice';
import websiteLinkReducer from '../../slices/ai-assistant/websiteLinks/slice';
import brainPageReducer from '../../slices/ai-assistant/brain/slice';
import qnaReducer from '../../slices/ai-assistant/qna/slice';
import fileDataReducer from '../../slices/ai-assistant/fileUpload/slice';
import analyticsReducer from '../../slices/ai-assistant/deploy/websiteLinks/tracking/slice';
import botControlsReducer from '../../slices/ai-assistant/deploy/websiteLinks/controls/slice';
import botOnPagesReducer from '../../slices/ai-assistant/deploy/websiteLinks/addToWebsite/slice';

export const aiReducers = {
  assistant: assistantReducer,
  createAi: createAssistantReducer,
  personality: personalityReducer,
  welcomePage: welcomePageReducer,
  dataCollection: dataCollectionReducer,
  productInfo: productInfoReducer,
  websiteLinks: websiteLinkReducer,
  brainPage: brainPageReducer,
  qna: qnaReducer,
  fileData: fileDataReducer,
  analytics: analyticsReducer,
  botControls: botControlsReducer,
  botOnPages: botOnPagesReducer
};

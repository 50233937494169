import React from 'react';
// import { route } from 'utils/route';
import { ReactComponent as IconGroupDesign } from 'assets/icons/Group13220.svg';
import { ReactComponent as BoxFormIcon } from 'assets/icons/icon-box-form-settings.svg';

import { ReactComponent as BoringStuffIcon } from 'assets/icons/Group13245.svg';
import { ReactComponent as BoxContactIcon } from 'assets/icons/icon-box-contacts.svg';
import { ReactComponent as AutomateIcon } from 'assets/icons/Group13224.svg';
import { ReactComponent as BoxStagesIcon } from 'assets/icons/icon-boxstage-settings.svg';
import SettingLink from 'modules/settings/components/settingLink';

const GeneralBoxSettingsMenu = ({ mainRoute }) => {
  const NavLinks = [
    {
      label: 'Card design',
      desc: 'Design the perfect Enquiry card',
      icon: IconGroupDesign,
      route: `${mainRoute}/card-design`
    },
    {
      label: 'Automate stuff',
      desc: 'Replies, Moving stuff & more',
      icon: AutomateIcon,
      route: `${mainRoute}/automation-stuff`
    },
    {
      label: 'Box forms',
      desc: 'Disconnect, Delete, Rename etc',
      icon: BoxFormIcon,
      route: `${mainRoute}/box-form`
    },
    {
      label: 'Box contacts',
      desc: 'Import existing contacts to the box',
      icon: BoxContactIcon,
      route: `${mainRoute}/box-contacts`
    },
    {
      label: 'Box Stages',
      desc: 'Reorder, rename or add new stages',
      icon: BoxStagesIcon,
      route: `${mainRoute}/box-stages`
    },
    {
      label: 'Boring stuff',
      desc: 'Disconnect, Delete, Rename etc',
      icon: BoringStuffIcon,
      route: `${mainRoute}/boring-stuff`
    }
  ];

  return (
    <>
      {NavLinks.map((item, index) => {
        const { label, desc, route } = item;
        return (
          <SettingLink
            key={index}
            to={route}
            title={label}
            renderSvg={() => <item.icon />}
            description={desc}
          />
        );
      })}
    </>
  );
};

export default GeneralBoxSettingsMenu;

import React from 'react';
import PropTypes from 'prop-types';

const ErrorMessage = ({ error, keyValue, message, className }) => {
  const ErrorRenderer = ({ message, className }) => {
    return (
      <p
        data-testid="error-message"
        className={`error-msg error-msg--sm mt-1 ${className}`}
      >
        {message}
      </p>
    );
  };

  if (message) {
    return <ErrorRenderer className={className} message={message} />;
  }

  if (error) {
    if (typeof error === 'string') {
      return <ErrorRenderer className={className} message={error} />;
    }
    if (Array.isArray(error)) {
      return error.map((err, idx) => {
        if (typeof err === 'object')
          return (
            <ErrorRenderer
              key={`a-${idx}`}
              className={className}
              message={err[keyValue]}
            />
          );
        return (
          <ErrorRenderer key={`a-${idx}`} className={className} message={err} />
        );
      });
    }
    return <></>;
  }
  return <></>;
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
  keyValue: PropTypes.string
};

ErrorMessage.defaultProps = {
  className: ''
};

export default ErrorMessage;

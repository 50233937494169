import React, { useRef, useState, useEffect } from 'react';
import DropDownWrapper from 'components/dropdown/wrapper';
import { ReactComponent as BookOpen } from 'assets/icons/book_open.svg';
import { ReactComponent as CarotDown } from 'assets/icons/icon-carot-down-full.svg';
import PropTypes from 'prop-types';
import useClickOutside from 'hooks/useOutsideClick';
import { useIntercom } from 'react-use-intercom';
import { useSelector } from 'react-redux';

const GuideDropdown = (props) => {
  const { description, guideOptions, dropDownWrapperStyle } = props;
  const [open, setOpen] = useState(false);
  const { boot, show, isOpen } = useIntercom();
  const { myInformation } = useSelector((state) => state.myInfo);
  const onGuideSelect = (item) => {
    if (item.label === 'Get some help') {
      boot({ name: myInformation?.name });
      let element = document.getElementById('intercom-container');
      if (element) {
        element.style.display = 'block';
      }
      show();
    } else {
      window.open(item.url, '_blank').focus();
    }
  };
  const nodeRef = useRef();
  useClickOutside(() => setOpen(false), nodeRef.current);

  useEffect(() => {
    if (!isOpen) {
      let element = document.getElementById('intercom-container');
      if (element) {
        element.style.display = 'none';
      }
    }
  }, [isOpen]);
  return (
    <div onClick={() => setOpen(!open)} ref={nodeRef}>
      <div
        className={
          'btn btn--xs cursor-pointer border border-basic-button mx-1 ' +
          (open ? 'bg-[#F1F1F1]' : '')
        }
      >
        <BookOpen
          className="cursor-pointer mt-[3px]"
          style={{ height: 18, width: 17 }}
        />
        <p
          className="text-13 font-medium ml-1"
          style={{
            lineHeight: '2px'
          }}
        >
          Help
        </p>
        <CarotDown className="ml-1" />
      </div>
      <div></div>
      <DropDownWrapper
        className="dropdown-menu--feed-filter p-2 px-1"
        open={open}
        style={{
          right: '115px',
          width: '259px',
          top: '58px',
          marginTop: '10px',
          ...dropDownWrapperStyle
        }}
      >
        <div>
          <div className="p-2 text-[12.84px] bb-[#E6E4E1] text-[#4C5254]">
            {description}
          </div>
          <div className="border-b-[2px] border-b-[#E6E4E1] mb-1"></div>
          {guideOptions.map((item, index) => {
            const { label, Icon } = item;
            return (
              <div
                className="dropdown-menu__list gap-2"
                key={index}
                onClick={() => onGuideSelect(item)}
                id={item.label === 'Get some help' ? 'intercomlauncher' : ''}
              >
                <Icon />
                <span className="text-sm">{label}</span>
              </div>
            );
          })}
        </div>
      </DropDownWrapper>
    </div>
  );
};

GuideDropdown.propTypes = {
  description: PropTypes.string,
  guideOptions: PropTypes.array,
  styleOptions: PropTypes.object
};

GuideDropdown.defaultProps = {
  description: '',
  guideOptions: [],
  styleOptions: {},
  dropDownWrapperStyle: {}
};

export default GuideDropdown;

import Message from './inputField/index';
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  deleteOpeningMessage,
  setDragDropMessageLoading,
  setOpeningMessage,
  updateOrReorderOpeningMessage
} from 'slices/ai-assistant/welcome/slice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { isEmpty } from 'utils/utils';
import InfoIconComponent from '../components/InfoIcon';

const OpeningMessage = () => {
  const dispatch = useDispatch();
  const { opening_message } = useSelector((state) => state.welcomePage);

  const [loading, setLoading] = useState();
  const handleLoader = (loading) => {
    setLoading(loading);
  };
  const [dragStart, setDragStart] = useState(null);
  const [dragDisabled, setDragDisabled] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currItemIndex, setCurritemIndex] = useState();
  const [currMsgItem, setCurrMsgItem] = useState({});
  const welcomeErrors = useSelector((state) => state.assistant.welcomeErrors);

  const onDragEnd = (result) => {
    const { destination, source } = result;
    dispatch(setDragDropMessageLoading(true));
    if (!destination || destination.index === source.index) {
      setDragStart(false);
      dispatch(setDragDropMessageLoading(false));
      return;
    }
    if (source.droppableId !== destination.droppableId) {
      setDragStart(false);
      dispatch(setDragDropMessageLoading(false));
      return;
    }

    let payload = {
      body: {
        message_id: opening_message[source.index].id,
        position: destination.index
      },
      source,
      destination
    };
    dispatch(
      updateOrReorderOpeningMessage(
        payload,
        'reorder',
        reorderSuccessResponse,
        reorderFailedResponse
      )
    );
  };

  const OnAddMessage = (formData) => {
    dispatch(setOpeningMessage([...opening_message, formData]));
  };

  const reorderSuccessResponse = () => {
    dispatch(setDragDropMessageLoading(false));
  };

  const reorderFailedResponse = () => {
    dispatch(setDragDropMessageLoading(false));
  };

  const successResponse = () => {
    setLoading(false);
    setShowDelete(false);
  };
  const failedResponse = () => {
    setLoading(false);
  };
  const onRemoveMessage = (currItem, itemIndex) => {
    if (currItem.status === 'New') {
      let currOptions = opening_message.filter((item, index) => {
        return index !== itemIndex;
      });
      dispatch(setOpeningMessage(currOptions));
      setShowDelete(false);
      setCurritemIndex();
      setCurrMsgItem({});
    } else {
      setLoading(true);
      setCurritemIndex();
      setCurrMsgItem({});
      dispatch(deleteOpeningMessage(currItem, successResponse, failedResponse));
    }
  };

  const handleDragDisabled = (data) => {
    setDragDisabled(data);
  };

  return (
    <div
      className="border-b border-grey-400"
      style={{ padding: '0 38px 26px' }}
    >
      <label className="input-field-label">
        Opening message <InfoIconComponent tooltipText="Opening message" />
      </label>
      {isEmpty(opening_message) &&
        !isEmpty(welcomeErrors?.message) &&
        !welcomeErrors?.message && (
          <p
            className="text-base mt-7"
            style={{
              color: '#F55E5E',
              fontFamily: 'SF Pro Text'
            }}
          >
            Please provide atleast one opening message
          </p>
        )}
      <div className="bot-chat-message-wrapper">
        <DragDropContext
          onBeforeDragStart={(e) => {
            setDragStart(e.draggableId);
          }}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="opening_message">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'inline-block' }}
              >
                {opening_message?.map((message, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={dragDisabled}
                  >
                    {(provided, snapshotDrag) => {
                      // Restrict dragging to vertical axis
                      let transform = provided.draggableProps.style.transform;

                      if (snapshotDrag.isDragging && transform) {
                        transform = transform.replace(/\(.+,/, '(0,');
                      }

                      const style = {
                        ...provided.draggableProps.style,
                        transform
                      };
                      return (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={style}
                        >
                          <Message
                            index={index}
                            id={index.toString()}
                            value={message.content}
                            dragStart={dragStart}
                            task={message}
                            onRemove={onRemoveMessage}
                            setDragDisabled={handleDragDisabled}
                            placeholder={'message'}
                            isLoading={loading}
                            type={'message'}
                            showDelete={showDelete}
                            setShowDelete={setShowDelete}
                            setIndex={setCurritemIndex}
                            setItem={setCurrMsgItem}
                            snapshot={snapshotDrag}
                            handleLoader={handleLoader}
                            tooltip={'welcome message'}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div
        className={`text-primary text-lg font-medium mt-4 cursor-pointer inline-block ${
          isEmpty(opening_message) ? 'inline-block' : ''
        }`}
        onClick={() => {
          OnAddMessage({
            id: opening_message.length + 1,
            content: '',
            status: 'New'
          });
        }}
      >
        + Add a message
      </div>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => onRemoveMessage(currMsgItem, currItemIndex)}
        title="Are you sure you want to delete?"
        description=""
        isLoading={loading}
      />
    </div>
  );
};

export default OpeningMessage;

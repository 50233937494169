/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { useLocation, useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import EmptyTemplate from './component/emptyState';
import TextInputField from 'components/inputs/textField';
import TemplateItem from './component/templateItem';
import EmptySelectedTemplate from './component/notemplateSelected';
import SelectedReplyTemplate from './component/selectedReplyTemplate';
import CHOICES from 'choices';

const { EMAIL, SMS, FACEBOOK_AND_INSTAGRAM } = CHOICES.ReplyTemplateChoice;

const ReplyTemplate = ({
  openReplyTemplate,
  replyTemplateRef,
  setOpenReplyTemplate,
  onUseTemplate,
  isFbOrInsta = false,
  isSms = false
}) => {
  const navigate = useNavigate();
  let location = useLocation();
  const [selectedReplyTemplate, setSelectedReplyTemplate] = useState({});
  const { allReplyTemplates } = useSelector((state) => state.replyTemplate);
  const [replyTemplateList, setReplyTemplateList] = useState([]);
  const [tempReplyTemplateList, setTempReplyTemplateList] = useState([]);

  const isChannelSettings = location && location.pathname.includes('connected');

  const isAutomateStuff =
    location && location.pathname.includes('automation-stuff');

  useEffect(() => {
    if (isFbOrInsta) {
      const fbOrInstaReplyTemplates = allReplyTemplates.filter(
        (item) => item.reply_type === FACEBOOK_AND_INSTAGRAM
      );
      setReplyTemplateList(fbOrInstaReplyTemplates);
      setTempReplyTemplateList(fbOrInstaReplyTemplates);
    } else {
      if (isSms) {
        const smsReplyTemplates = allReplyTemplates.filter(
          (item) => item.reply_type === SMS
        );
        setReplyTemplateList(smsReplyTemplates);
        setTempReplyTemplateList(smsReplyTemplates);
      } else {
        const emailReplyTemplates = allReplyTemplates.filter(
          (item) => item.reply_type === EMAIL
        );
        setReplyTemplateList(emailReplyTemplates);
        setTempReplyTemplateList(emailReplyTemplates);
      }
    }
  }, [allReplyTemplates, isFbOrInsta, isSms]);

  const onCreate = () => {
    navigate(`${route.dashboard}${route.settings}/reply-template`);
  };

  const onSearch = (value) => {
    if (value === '') {
      setReplyTemplateList(tempReplyTemplateList);
    } else {
      setSelectedReplyTemplate({});
      const filteredList = tempReplyTemplateList.filter((item) =>
        item.name.toLowerCase().includes(value.toLowerCase())
      );
      setReplyTemplateList(filteredList);
    }
  };

  return (
    <div
      className={`dropdown-menu dropdown-menu--reply-template ${
        openReplyTemplate && 'open'
      }`}
      style={
        isChannelSettings || isAutomateStuff
          ? {
              bottom: 35,
              left: isAutomateStuff ? -100 : 0,
              maxHeight: 353,
              height: 353
            }
          : {
              bottom: 35,
              minWidth: 600,
              left: 15,
              width: 632,
              maxHeight: 353,
              height: 353
            }
      }
      ref={replyTemplateRef}
    >
      {isEmpty(allReplyTemplates) ? (
        <EmptyTemplate onClick={onCreate} />
      ) : (
        <>
          <div className="pb-3 border-b border-grey-400 px-2 pt-2">
            <TextInputField
              name="search"
              className="input-field input-field--icon-search--sm text-13"
              placeholder="Search reply templates"
              style={{ padding: '6px 32px', fontWeight: 400 }}
              onChange={(e) => onSearch(e.target.value)}
            />
          </div>
          <div className="w-full flex" style={{ height: 'calc(100% - 52px)' }}>
            <div
              className="py-2 px-2 w-1/2 border-r border-grey-400 truncate"
              style={
                isChannelSettings || isAutomateStuff
                  ? { maxWidth: '50%', width: '50%', overflowY: 'auto' }
                  : { maxWidth: 316, width: 316, overflowY: 'auto' }
              }
            >
              {!isEmpty(replyTemplateList) &&
                replyTemplateList.map((item) => {
                  return (
                    <TemplateItem
                      key={item.id}
                      id={item.id}
                      setSelectedReplyTemplate={setSelectedReplyTemplate}
                      selectedReplyTemplate={selectedReplyTemplate}
                      name={item.name}
                      item={item}
                    />
                  );
                })}
              {isEmpty(replyTemplateList) && (
                <div className="flex justify-center items-center h-full">
                  <p className="text-sm font-semibold">
                    Reply template not found
                  </p>
                </div>
              )}
            </div>
            {isEmpty(selectedReplyTemplate) ? (
              <EmptySelectedTemplate onCreate={onCreate} />
            ) : (
              <SelectedReplyTemplate
                data={selectedReplyTemplate}
                setOpenReplyTemplate={setOpenReplyTemplate}
                isChannelSettings={isChannelSettings}
                onUseTemplate={onUseTemplate}
                isAutomateStuff={isAutomateStuff}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default ReplyTemplate;

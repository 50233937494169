import React from 'react';
import iconCross from 'assets/icons/Group12629.svg';
import iconCheck from 'assets/icons/Group5569.svg';
import SnackBarWrapper from './SnackbarWrapper';

export const SucessSnackBar = ({ message, isActive, size }) => {
  return (
    <SnackBarWrapper
      message={message}
      icon={iconCheck}
      isActive={isActive}
      size={size}
    />
  );
};

export const ErrorSnackBar = ({ message, isActive, size }) => {
  return (
    <SnackBarWrapper
      message={message}
      icon={iconCross}
      isActive={isActive}
      size={size}
    />
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import useClickOutside from 'hooks/useOutsideClick';
import DropDownWrapper from 'components/dropdown/wrapper';
import CHOICE from 'choices';
import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
import { ReactComponent as Close } from 'assets/icons/icon-close-green.svg';

const Filter = ({
  title,
  onClick,
  onClearFilter,
  showFilter,
  setShowFilter
}) => {
  const nodeRef = useRef();
  const [filterData, setFilterData] = useState([]);
  const titleList = CHOICE.TitleChoices.CHOICE_LIST;

  useClickOutside(() => setShowFilter(false), nodeRef.current);

  useEffect(() => {
    const tempList = [];
    titleList.map((item) => {
      if (
        ![
          'Automated Reply Sent',
          'Moved To Bin',
          'Deleted From Bin',
          'Unassigned',
          'Reply Sent',
          'Reply Received',
          'Reminder',
          'Restored',
          'Failure'
        ].includes(item.label)
      ) {
        tempList.push(item);
      }
    });
    setFilterData([...filterData, ...tempList]);
  }, []);

  if (isEmpty(filterData)) return <Loader />;

  return (
    <div ref={nodeRef} className="relative">
      <div
        className={`text-13 font-medium text-primary cursor-pointer ${
          title !== 'Filter' ? 'flex items-center justify-between ' : ''
        }`}
      >
        {title !== 'Filter' && (
          <Close width={8} height={8} onClick={onClearFilter} />
        )}
        <p className="ml-2" onClick={() => setShowFilter(!showFilter)}>
          {title}
        </p>
      </div>
      <DropDownWrapper
        open={showFilter}
        className="dropdown-menu--filter"
        style={{
          right: 0,
          width: 'initial',
          maxHeight: 320,
          overflowY: 'auto'
        }}
      >
        {filterData.map((item, index) => {
          return (
            <div
              key={index}
              className={`dropdown-menu__list ${
                title === item.label ? 'selected' : ''
              }`}
              onClick={() => onClick(item)}
            >
              {item.label}
            </div>
          );
        })}
      </DropDownWrapper>
    </div>
  );
};
export default Filter;

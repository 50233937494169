import React from 'react';
// import { route } from 'utils/route';
import { Routes, Route } from 'react-router-dom';
import UserChat from './module/userChat';
import TeamChatHome from './home';

const TeamChatRoute = () => {
  return (
    <Routes>
      <Route path="/:userId/*" element={<TeamChatHome />} />
      <Route path="/user/:userId/*" element={<UserChat />} />
    </Routes>
  );
};

export default TeamChatRoute;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { GET_ALL_NOTES_BY_LEAD } from 'urls/notes';

const notesSlice = createSlice({
  name: 'notes',
  initialState: {
    allNotes: []
  },
  reducers: {
    setAllNotes: (state, action) => {
      state.allNotes = action.payload;
    }
  }
});

export const { setAllNotes } = notesSlice.actions;

export default notesSlice.reducer;

// function to call the get method on all notes by leadId
export const getAllNotes = (payload) => {
  return (dispatch) => {
    const { leadId } = payload;
    try {
      const query = {
        lead_id: leadId
      };
      new APIRequest().get(GET_ALL_NOTES_BY_LEAD, query).then((res) => {
        if (res.status === 200) {
          dispatch(setAllNotes(res.data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

const Break = ({ label, className }) => {
  return (
    <div data-testid="break-text" className={styles.or_text}>
      <span
        className={`text-sm bg-grey-200 font-bold text-grey-700 px-4 ${className}`}
      >
        {label}
      </span>
    </div>
  );
};

Break.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
};

Break.defaultProps = {
  label: 'OR',
  className: ''
};

export default Break;

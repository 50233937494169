/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { bool } from 'prop-types';
import InputField from 'components/inputs/textField';
import useClickOutside from 'hooks/useOutsideClick';
import { Controller } from 'react-hook-form';
import CheckBox from '../checkbox';
import { isEmpty } from 'utils/utils';

const CheckboxOptionSelect = (props) => {
  const {
    register,
    setValue,
    control,
    selectedValue,
    options,
    name,
    value,
    inputClassName,
    placeholder,
    error,
    onChange,
    onBlur,
    required,
    renderIcon,
    showGreenBorder,
    dropDownClassName,
    isAvatarSelect,
    isWhenSelect,
    iconStyle,
    isHaveIcon,
    selectedIconStyle,
    containerStyle,
    inputStyle,
    showText,
    text,
    onSubmit,
    selectedAnswer,
    readOnly,
    extraOption,
    labelDividerText,
    isSearchInsideDropDown,
    labelDividerTextClass,
    disabled,
    showSelectIcon,
    isBin,
    resetValue,
    isModalOpen,
    isSelectDisabled,
    isShowUserDetails,
    avatarStyle,
    onSelectAll,
    selectedOptions,
    onHandleSubEnquiryCheck,
    onHandleEnquiryCheck,
    isAllSelected,
    ...rest
  } = props;
  const nodeRef = useRef();
  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState(
    !isSearchInsideDropDown ? value : ''
  );
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [selectedIcon, setSelectedIcon] = useState(null);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  useEffect(() => {
    if (resetValue) {
      setInputValue('');
    }
  }, [resetValue, isModalOpen]);

  useEffect(() => {
    if (!isSearchInsideDropDown) {
      setInputValue(value);
    }
  }, [value, isSearchInsideDropDown]);

  useClickOutside(() => setOpen(false), nodeRef.current);

  const toggle = () => {
    setOpen(!open);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (!isSearchInsideDropDown) {
      setInputValue(value);
    } else {
      setDropDownSerchValue(value);
    }

    setFilteredOptions(
      options.filter((option) =>
        option.label.toLowerCase().includes(value.toLowerCase())
      )
    );
    if (setValue) {
      setValue(name, value, { shouldTouch: true });
    }
  };

  const getCheckedStatus = (id) => {
    if (selectedOptions?.includes(id)) {
      return true;
    } else {
      false;
    }
  };

  return (
    <div
      data-testid="search-container"
      className="relative"
      // onClick={!isSearchInsideDropDown ? toggle : () => {}}
      ref={nodeRef}
      style={containerStyle}
    >
      <InputField
        data-testid="search-input"
        name={name}
        className={`input-field--select ${inputClassName}`}
        placeholder={
          !isEmpty(selectedOptions)
            ? `${selectedOptions?.length} options selected`
            : placeholder
        }
        value={selectedAnswer ? selectedAnswer : inputValue}
        onChange={onSearch}
        onBlur={onBlur}
        error={error}
        selectedIcon={selectedIcon ? selectedIcon : null}
        showSuccessBorder={showGreenBorder}
        selectedIconStyle={selectedIconStyle}
        style={selectedIcon ? inputStyle : {}}
        register={register}
        readOnly={readOnly}
        disabled={disabled}
        onClick={toggle}
        {...rest}
      />
      <div
        style={open ? { height: 300, position: 'absolute', width: '100%' } : {}}
      >
        <div
          data-testid="select-dropdown-menu"
          className={`dropdown-menu ${open ? 'open' : ''}
          ${dropDownClassName ? dropDownClassName : ''}
          `}
          onBlur={onBlur}
          style={{ maxHeight: 286, overflowY: 'auto' }}
        >
          <div
            className={`dropdown-menu__list`}
            style={{ borderBottom: '1px solid #E6E4E1', padding: '8px' }}
            // onClick={() => onOptionSelect(option, onChange)}
          >
            <CheckBox
              id="allOptions"
              name="allOptions"
              checked={isAllSelected}
              style={{ marginRight: 12 }}
              small
              onChange={onSelectAll}
            />
            Select all options
          </div>
          {filteredOptions.map((option, idx) => {
            return (
              <div key={idx}>
                <div
                  className={`dropdown-menu__list`}
                  style={{ padding: '8px' }}
                  // onClick={() => onOptionSelect(option, onChange)}
                >
                  <CheckBox
                    id={`${option?.et.option_id}`}
                    name={`${option?.et.option_id}`}
                    checked={getCheckedStatus(option?.et.option_id)}
                    style={{ marginRight: 12 }}
                    onChange={(e) => onHandleEnquiryCheck(option, e)}
                    small
                  />
                  {option?.et.text}
                </div>
                {!isEmpty(option?.sub_enquiry_types) &&
                  option?.sub_enquiry_types.map((sub) => {
                    return (
                      <div
                        key={sub?.option_id}
                        className={`dropdown-menu__list ml-8`}
                        style={{ padding: '8px' }}
                        // onClick={() => onOptionSelect(option, onChange)}
                      >
                        <CheckBox
                          id={`${sub?.option_id}`}
                          name={`${sub?.option_id}`}
                          checked={getCheckedStatus(sub?.option_id)}
                          onChange={(e) =>
                            onHandleSubEnquiryCheck(sub?.option_id, e)
                          }
                          style={{ marginRight: 12 }}
                          small
                        />
                        {sub?.text}
                      </div>
                    );
                  })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

CheckboxOptionSelect.propTypes = {
  open: PropTypes.bool.isRequired,
  isAvatarSelect: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  renderIcon: PropTypes.func,
  showGreenBorder: PropTypes.bool,
  isHaveIcon: PropTypes.bool,
  showText: PropTypes.bool,
  text: PropTypes.string,
  isWhenSelect: bool,
  isSelectDisabled: bool,
  isShowUserDetails: PropTypes.bool,
  avatarStyle: PropTypes.object
};

CheckboxOptionSelect.defaultProps = {
  open: false,
  placeholder: 'Select',
  inputClassName: '',
  register: () => {},
  required: false,
  isHaveIcon: false,
  showText: false,
  text: '',
  isWhenSelect: false,
  showSelectIcon: false,
  isSelectDisabled: false,
  isShowUserDetails: false,
  avatarStyle: {}
};

export default CheckboxOptionSelect;

import React from 'react';
import PropTypes from 'prop-types';

const Toggle = (props) => {
  const { id, small, smaller, register, checked, onChange } = props;

  const getClassName = () => {
    if (smaller) return '__smaller';
    if (small) return '__small';
  };

  return (
    <div className="toggle-slider">
      <input
        type="checkbox"
        id={id}
        className="toggle-slider__checkbox"
        {...register(id)}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={id} className="toggle-slider__label">
        <code
          className={`toggle-slider__ball toggle-slider__ball${getClassName()}`}
        />
      </label>
    </div>
  );
};

Toggle.propTypes = {
  id: PropTypes.string.isRequired,
  small: PropTypes.bool,
  smaller: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func
};

Toggle.defaultProps = {
  small: true,
  checked: false,
  register: () => {}
};

export default Toggle;

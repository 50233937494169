import { useState } from 'react';
import usePlanAccess from 'hooks/usePlanAccess';
import UpgradePlanTooltip from 'modules/modals/UpgradePlanTooltip';
import { ReactComponent as IconPlusGreen } from 'assets/icons/icon-plus-green.svg';
import { ReactComponent as IconPlusGray } from 'assets/icons/icon-plus-gray.svg';
import InviteNewTeamMember from 'modules/dashboard/module/inviteTeamMember';

const EmptyChannelState = () => {
  const { has_unlimited_user } = usePlanAccess();
  const [openModal, setOpenModal] = useState(false);

  return (
    <div>
      <h3 className="text-2xl font-semibold">Connect your first channel </h3>
      <div className="mt-5 text-base">
        Channels are the various ways in which your business receives enquiries.
        👉
      </div>
      <div className="mt-8">
        <p>
          Don’t have the right permissions or want another team member to
          connect channels?
        </p>
        <UpgradePlanTooltip alignment="top" show={!has_unlimited_user}>
          <div
            className={`flex items-center mt-5 ${
              has_unlimited_user ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            onClick={() => {
              if (has_unlimited_user) {
                setOpenModal(true);
              }
            }}
          >
            {has_unlimited_user ? <IconPlusGreen /> : <IconPlusGray />}
            <p
              className={`ml-4 text-sm font-medium ${
                has_unlimited_user ? 'text-primary' : 'text-[#AAAAAA]'
              }`}
            >
              Invite a team member
            </p>
          </div>
        </UpgradePlanTooltip>
      </div>
      <InviteNewTeamMember
        open={openModal}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default EmptyChannelState;

/* eslint-disable no-debugger */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DragDropContext } from 'react-beautiful-dnd';
import Stages from './index';
import { useDispatch } from 'react-redux';
import { handleMoveLead } from 'slices/boxes/slice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import OverlayLoader from 'components/loader/overLayLoader';
import { getMyInfo } from 'slices/myInfoSlice';

const StagesHome = (props) => {
  const { currentBox, isFilter, isDetailEnabled } = props;
  const { box_type } = useParams();
  const [showWarning, setShowWarning] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [dragStart, setDragStart] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    dispatch(getMyInfo());
  }, []);

  useEffect(() => {
    if (showConfetti) {
      setTimeout(() => {
        setShowConfetti(false);
      }, 2000);
    }
  }, [showConfetti]);

  const onBeforeDragStart = (data) => {
    setDragStart(data);
  };

  const onDragStart = (data) => {
    // console.log('onDragStart', data);
  };

  const onDragEnd = async (data) => {
    const { source, destination, draggableId } = data;
    const sourceStageId = source.droppableId;
    if (!destination.droppableId) {
      return;
    }
    const destinationStageId = destination.droppableId;
    const isReplyLater = draggableId.includes('REPLY') ? true : false;
    if (isReplyLater && sourceStageId != destinationStageId) {
      setShowWarning(true);
      return;
    }
    setIsLoading(true);
    dispatch(
      handleMoveLead(data, () => {
        setIsLoading(false);
        setShowConfetti(destinationStageId);
      })
    );
  };

  return (
    <>
      {isLoading && <OverlayLoader />}
      <DeleteConfirmation
        showConfirmation={showWarning}
        onCancel={() => {
          setShowWarning(null);
        }}
        onConfirm={() => {
          setShowWarning(null);
        }}
        hideCancelBtn={true}
        confirmButtonText="OKAY"
        title="You cannot move a enquiry that is in reply later to another stage"
        description=""
      />
      <div
        className={`width-fill-available pt-5 px-5 box-container ${
          isFilter ? 'with-filter' : ''
        } ${isDetailEnabled ? 'hidden' : ''}`}
      >
        <DragDropContext
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onBeforeCapture={onBeforeDragStart}
        >
          <div
            className={`box-card-wrapper ${
              isFilter ? 'is-filter-enabled' : ''
            }`}
          >
            <Stages
              box={currentBox}
              boxType={box_type}
              stages={currentBox.boxstage_set}
              boxName={currentBox.box_name}
              dragStart={dragStart}
              isDetailEnabled={isDetailEnabled}
              setShowConfetti={setShowConfetti}
              showConfetti={showConfetti}
            />
          </div>
        </DragDropContext>
      </div>
    </>
  );
};

export default StagesHome;

/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { ReactComponent as IconGoogle } from 'assets/icons/icon-gmail.svg';
import CHOICES from 'choices';

const LoginGoogle = ({ userEmail, loginWays }) => {
  return (
    <>
      <li className="list-hover">
        <div className="list-hover__container">
          <figure className="general-settings__card-list__avatar-container p-2">
            <IconGoogle />
          </figure>
          <div className="ml-4">
            <h4 className="text-base-1 font-semibold">Login with Google</h4>
            <p className="text-sm text-grey-700 mt-1">
              You are using your Google account{' '}
              <span className="text-primary font-medium">
                {`(${userEmail})`}
              </span>{' '}
              to log in
            </p>
          </div>
        </div>
        {loginWays?.preferred_signup_type === CHOICES.SignupChoice.GOOGLE && (
          <div
            className="absolute left-0 bg-secondary text-white text-11 font-medium rounded-t-lg"
            style={{ padding: '2px 10px', top: -20 }}
          >
            {' '}
            You're using this
          </div>
        )}
      </li>
    </>
  );
};

export default LoginGoogle;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import HeaderLess from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import PrimaryButton from 'components/buttons/primary';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useResendLinkMutation } from 'services/authApi';
import { SuccessToast } from 'components/toast';
import RenderError from 'components/errorMessage/renderer';
import Message from 'components/errorMessage';
import LinkSent from './linkSent';

const EmailConfirmation = () => {
  const navigate = useNavigate();
  const authEmail = useSelector((state) => state.auth.email);
  const currentUser = useSelector((state) => state.auth.currentUser);
  const [resendLink, { data, isLoading, isSuccess, isError, error }] =
    useResendLinkMutation();

  useEffect(() => {
    if (!authEmail || !currentUser) {
      navigate('/sign-up');
    }
  }, []);

  // useEffect(() => {
  //   if (isSuccess) {
  //     onSuccess();
  //   }
  //   if (isError) {
  //     onError();
  //   }
  // }, [isSuccess]);

  // const onSuccess = () => {
  //   SuccessToast(data?.message);
  // };

  // const onError = () => {
  //   error?.data?.user_id.forEach((err) => {
  //     ErrorToast(err);
  //   });
  // };

  const onResendEmail = async () => {
    await resendLink({ user_id: currentUser });
  };

  if (!authEmail || !currentUser) return <></>;

  if (isSuccess) {
    return <LinkSent />;
  }

  return (
    <HeaderLess>
      <CardLayout
        className="mt-8"
        style={{ paddingLeft: '6rem', paddingRight: '6rem' }}
      >
        <CardTitle title="Check your email" />
        <div className="mt-6" data-testid="email-confirmation-modal">
          <p className="text-sm text-grey-800 text-center">
            <span>We&apos;ve sent an email to </span>
            <span className="text-primary font-semibold">{authEmail}</span>
          </p>
          <p className="text-sm text-grey-800 text-center mt-4">
            Click the link in the email to confirm your address and activate
            your account.
          </p>
          <p className="text-sm text-grey-900 font-semibold text-center mt-10">
            Didn’t get the email?
          </p>
        </div>
        {isError && (
          <div className="mb-2 mt-2">
            <RenderError error={error?.data?.user_id} />
          </div>
        )}
        <PrimaryButton
          label="Resend email"
          className="w-full mt-6"
          onClick={onResendEmail}
          isLoading={isLoading}
          enableLoader={true}
        />
      </CardLayout>
    </HeaderLess>
  );
};

export default EmailConfirmation;

import React from 'react';
import PropTypes from 'prop-types';

const SnackBarWrapper = (props) => {
  const { message, icon, isActive, size } = props;

  const getSnackbarSize = () => {
    if (size === 'small') return `__sm`;
    if (size === 'large') return `__lg`;
  };

  return (
    <div
      className={`snackbarWrap snackbarWrap${getSnackbarSize()} ${
        isActive ? 'fadeIn' : 'fadeOut'
      }`}
    >
      <div className={`snackbar_content snackbar_content${getSnackbarSize()}`}>
        <div className="symbol">
          <img src={icon} alt="icon" style={{ width: 24, height: 24 }} />
        </div>
        <div className="message">{message}</div>
      </div>
    </div>
  );
};

SnackBarWrapper.propTypes = {
  message: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired
};

export default SnackBarWrapper;

import React from 'react';
// import iconEmail from 'assets/icons/icon-email.svg';
// import iconEyeClose from 'assets/icons/icon-eye-close.svg';
import UserProfile from './userprofile/userProfille';
import HowYouLogin from './howYouLogin';

const MyProfile = () => {
  return (
    <>
      <UserProfile />
      <HowYouLogin />
      {/* <div className="mt-5"><GlobalSettingsEmailSignature /></div> */}
    </>
  );
};

export default MyProfile;

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  EXPORT_CSV_VIA_MAIL,
  FINAL_MAPPING,
  GET_ALL_UPLOADED_LEADS_COUNT,
  GET_FILE_UPLOAD_HISTORY,
  GET_MAPPING_FIELD_FROM_CSV_FILE,
  GET_MODAL_FIELDS_FOR_CSV_MAPPING,
  REVERT_LEAD
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';

export const uploadContactsSettings = createSlice({
  name: 'uploadContactsSettings',
  initialState: {
    uploadedFileId: null,
    uploadedLeadsCount: '',
    mappingFieldsFromCsv: [],
    mappingFieldsForCsvMapping: [],
    onUploadeSucessMessage: '',
    onUploadeErrorMessage: '',
    uploadedFilesHistory: [],
    uploadFileErrorMessage: ''
  },
  reducers: {
    setUploadedFileId: (state, action) => {
      state.uploadedFileId = action.payload;
    },
    setUploadedLeadCount: (state, action) => {
      state.uploadedLeadsCount = action.payload;
    },
    setMappingFieldsFromCsv: (state, action) => {
      state.mappingFieldsFromCsv = action.payload;
    },
    setMappingFieldsForCsvMapping: (state, action) => {
      state.mappingFieldsForCsvMapping = action.payload;
    },
    setOnUploadSucessMessage: (state, action) => {
      state.onUploadeSucessMessage = action.payload;
    },
    setOnUploadErrorMessage: (state, action) => {
      state.onUploadeErrorMessage = action.payload;
    },
    setUploadedFileHistory: (state, action) => {
      state.uploadedFilesHistory = action.payload;
    },
    setUploadedFileError: (state, action) => {
      state.uploadFileErrorMessage = action.payload;
    }
  }
});

export const {
  setUploadedFileId,
  setUploadedLeadCount,
  setMappingFieldsFromCsv,
  setMappingFieldsForCsvMapping,
  setOnUploadSucessMessage,
  setOnUploadErrorMessage,
  setUploadedFileHistory,
  setUploadedFileError
} = uploadContactsSettings.actions;

export const exportCsv = (payload, onSuccessDownloadCsv) => {
  return async (dispatch) => {
    try {
      const { box_id, subOrgId } = payload;
      await new APIRequest()
        .post(
          replaceUrl(
            EXPORT_CSV_VIA_MAIL,
            ['boxId', 'subOrgId'],
            [box_id, subOrgId]
          )
        )
        .then((res) => {
          if (res.status === 200) {
            if (onSuccessDownloadCsv) {
              onSuccessDownloadCsv();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllUploadedLeadsCount = (payload) => {
  return async (dispatch) => {
    try {
      const { box_id } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_ALL_UPLOADED_LEADS_COUNT, 'boxId', box_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setUploadedLeadCount(res?.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getMappingFieldsFromCsv = (onSuccess) => {
  return async (dispatch, getState) => {
    try {
      const uploadedFileId = getState().uploadContactsSettings.uploadedFileId;
      await new APIRequest()
        .get(
          replaceUrl(
            GET_MAPPING_FIELD_FROM_CSV_FILE,
            'uploadeFileId',
            uploadedFileId
          )
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setMappingFieldsFromCsv(res?.data?.csv_columns));
            if (onSuccess) {
              onSuccess();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getMappingFieldsForCsvMapping = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_MODAL_FIELDS_FOR_CSV_MAPPING, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setMappingFieldsForCsvMapping(res?.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendMappedFields = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    try {
      const uploadedFileId = getState().uploadContactsSettings.uploadedFileId;
      await new APIRequest()
        .post(
          replaceUrl(FINAL_MAPPING, 'uploadeFileId', uploadedFileId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            onSuccess(res?.data);
            if (res?.data?.message) {
              dispatch(setOnUploadSucessMessage(res?.data?.message));
            } else {
              dispatch(setOnUploadErrorMessage(res?.data?.error));
            }
          }
        });
    } catch (e) {
      if (onError) {
        onError(e);
      }
    }
  };
};

export const revertLead = (payload, onSuccess, onError) => {
  return async (dispatch, getState) => {
    try {
      const { id } = payload;
      await new APIRequest()
        .delete(replaceUrl(REVERT_LEAD, 'uploadeFileId', id))
        .then((res) => {
          if (res.status === 200 && onSuccess) {
            onSuccess();
          }
        });
    } catch (e) {
      console.log({ e });
      if (onError) {
        onError(e);
      }
    }
  };
};

export const getFileUploadHistory = (payload) => {
  return async (dispatch) => {
    try {
      const { box_id } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_FILE_UPLOAD_HISTORY, 'boxId', box_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setUploadedFileHistory(res?.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default uploadContactsSettings.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/buttons/primary';
import TextArea from 'components/inputs/textarea';
import ErrorMessage from 'components/errorMessage';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getConnectedWebform } from 'slices/webformSlice';
// import { Loader } from 'components/loader';

const WebFormScript = ({ scriptCode, isConnected, newChannelText }) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(scriptCode);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { channelId } = useParams();
  const codeRef = useRef();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    codeRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  const onSuccess = (data) => {
    setData(data);
    setIsLoading(false);
  };

  const onError = (err) => {
    setIsLoading(false);
    setError(err);
  };

  const getScript = () => {
    setIsLoading(true);
    dispatch(
      getConnectedWebform(
        { subOrgId: subOrganizationId, channelId },
        onSuccess,
        onError
      )
    );
  };

  useEffect(() => {
    if (!scriptCode) {
      getScript();
    }
  }, [channelId]);

  // if (isLoading)
  //   return (
  //     <div className="flex items-center justify-center">
  //       <Loader small />
  //     </div>
  //   );

  const handleReadMore = () => {
    window.open(
      'https://help.enquirybox.io/en/articles/7228336-connect-your-webforms'
    );
  };

  if (error) return <ErrorMessage message={error} />;

  const getWebFormScript = () => {
    if (isLoading) return 'Loading ...';
    if (data) return data?.expose_script;
    return '';
  };

  return (
    <div>
      <TextArea
        name="webform"
        rows="3"
        cols="5"
        value={getWebFormScript()}
        style={{ padding: '15px 20px', fontWeight: 'normal' }}
        className="border text-13 mt-4 resize-none"
        codeRef={codeRef}
      />
      {isConnected && (
        <div className="flex justify-between">
          <PrimaryButton
            className="btn--xs mt-1"
            label={copied ? 'Copied' : 'Copy Code'}
            onClick={copyToClipboard}
          />
          <p
            className="flex justify-center items-center text-13 font-medium text-primary align-center cursor-pointer"
            onClick={handleReadMore}
          >
            {newChannelText ? newChannelText : 'Read more on adding the code'}
          </p>
        </div>
      )}
      {!isConnected && (
        <PrimaryButton
          className="btn--xs mt-1"
          label={copied ? 'Copied' : 'Copy Code'}
          onClick={copyToClipboard}
        />
      )}
    </div>
  );
};

WebFormScript.propTypes = {
  scriptCode: PropTypes.object,
  isConnected: PropTypes.bool
};

export default React.memo(WebFormScript);

import React from 'react';
import PropTypes from 'prop-types';
import PrimaryButton from 'components/buttons/primary';

const ListItem = (props) => {
  const {
    id,
    title,
    description,
    renderSvg,
    className,
    onClick,
    icon,
    channel_type,
    isLoading,
    enableLoader
  } = props;
  return (
    <li className="modal__channel-list">
      <figure
        className={`modal__channel-list__avatar-container modal__channel-list__avatar-container--${className}`}
      >
        {renderSvg && typeof renderSvg === 'function' && renderSvg()}
        {icon && <img src={icon} alt="title" />}
      </figure>
      <div className="modal__channel-list__content-wrapper">
        <h4 className="font-semibold">{title}</h4>
        <p className="text-sm text-grey-800">{description}</p>
      </div>
      <PrimaryButton
        label="Connect"
        className="btn--connect"
        onClick={() => onClick(id, channel_type)}
        style={{ padding: '4px 18px', position: 'absolute', right: '10px' }}
        enableLoader={enableLoader}
        isLoading={isLoading}
        loaderInline
        disabled={isLoading}
      />
    </li>
  );
};

ListItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  renderSvg: PropTypes.func,
  className: PropTypes.string,
  onClick: PropTypes.func
};

export default ListItem;

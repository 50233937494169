import React, { useEffect, useRef, useState } from 'react';
import Typewriter from '../../components/typeWriter';

const MultpleMessageTypewriter = (props) => {
  const {
    text,
    delay,
    disableText,
    setDisableText,
    singleMessage,
    idx,
    noOfElement,
    isPrinting,
    setIsPrinting,
    autoScroll
  } = props;

  const [hide, setHide] = useState(true);
  const bubbleRef = useRef(null);

  useEffect(() => {
    if (isPrinting === idx) setHide(false);
  }, [isPrinting]);

  return (
    <>
      {!hide && (
        <div ref={bubbleRef} className="chat-bot-list__bubble">
          <Typewriter
            ref={bubbleRef}
            text={text}
            delay={delay}
            disableText={disableText}
            setDisableText={setDisableText}
            singleMessage={singleMessage}
            idx={idx}
            noOfElement={noOfElement}
            setIsPrinting={setIsPrinting}
            autoScroll={autoScroll}
          />
        </div>
      )}
    </>
  );
};

export default MultpleMessageTypewriter;

import React, { useEffect, useState } from 'react';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import TextInputField from 'components/inputs/textField';
import Modal from 'components/modal';
import PropTypes from 'prop-types';
import Error from 'components/errorMessage';
import { isEmpty } from 'utils/utils';

const CreateChannel = (props) => {
  const { open, onClose, onSubmit, isLoading } = props;
  const [newChannelName, setNewChannelName] = useState('');
  const [isNameError, setIsNameError] = useState('');

  useEffect(() => {
    if (!isEmpty(newChannelName)) {
      setIsNameError('');
    }
  }, [newChannelName]);

  const handleChange = (value) => {
    if (value.length > 30) {
      setIsNameError(
        'channel name is too long, should be 30 characters maximum'
      );
    } else {
      setNewChannelName(value);
    }
  };

  const onSave = () => {
    if (newChannelName === '') {
      setIsNameError('channel name is required');
    } else {
      onSubmit({
        name: newChannelName
      });
    }
  };

  return (
    <Modal.Main open={open}>
      <Modal.Header title="Create a channel" onClose={onClose} />
      <Modal.Body>
        <form autoComplete="off">
          <div className="mt-4">
            <label className="input-field-label">Channel name</label>
            <TextInputField
              name="channelName"
              className="input-field--md"
              placeholder="Give it a name that reflects the software it’s coming from"
              value={newChannelName}
              onChange={(e) => handleChange(e.target.value)}
              error={!isEmpty(isNameError)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onSave();
                }
              }}
            />
            {!isEmpty(isNameError) && <Error message={isNameError} />}
          </div>
          <div className="flex items-center justify-end gap-3 mt-12">
            <CustomColorButton
              label="Cancel"
              onClick={onClose}
              className="btn--grey btn--sm min-w-120"
            />
            <PrimaryButton
              className="btn--sm min-w-120"
              label="Next"
              isLoading={isLoading}
              loaderInline={true}
              enableLoader={isLoading}
              onClick={(e) => {
                e.preventDefault();
                onSave();
              }}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

CreateChannel.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default CreateChannel;

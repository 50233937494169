import React, { useEffect, useState } from 'react';
import Mentions from 'components/editor/mentions';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
// import { getLeadConversation } from 'slices/conversationSlice';
import {
  addTaskNotes,
  setIsEdit,
  updateTaskNotes,
  resetNoteStore
} from 'slices/noteSlice';
import { setEditorActiveTab, setShowInboxActions } from 'slices/eboxLead';
import PrimaryButton from 'components/buttons/primary';
import ErrorMessage from 'components/errorMessage';
import { REPLY } from 'utils/constants';
import { useSnackbar } from 'components/Snackbar';
import { setAllNotes } from 'slices/notes/slice';
import CustomColorButton from 'components/buttons/customColorButton';
import { getLeadData, setFetchConversation } from 'slices/conversationSlice';
// import { changeLeadStatus } from 'slices/eboxLead';
// import { setActiveLead } from 'slices/conversationSlice';
// import choices from 'choices';

// const REPLY_LATER = choices.LeadStatusChoices.REPLY_LATER;
// const VIEWED = choices.LeadStatusChoices.VIEWED;

const Notes = ({ autoScroll, bodyStyle, inputStyle, isRefetch }) => {
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const [note, setNote] = useState('');
  const { openSuccessSnackBar } = useSnackbar();
  // const { activeLead } = useSelector((state) => state.leadConversation);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { isEdit, editedNote } = useSelector((state) => state.notes);
  const [selectedUser, setSelectedUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [users] = useState(
    organizationUser?.results
      ?.filter((i) => !i.is_disabled)
      ?.map((item) => {
        return {
          id: item.first_name || '',
          display: item.first_name || '',
          avatar: item.avatar,
          userId: item.user_id
        };
      })
  );
  const { allNotes } = useSelector((state) => state.allNotes);

  useEffect(() => {
    if (isRefetch) {
      setNote('');
    }
  }, [isRefetch]);

  // const updateLeadStatusIfInReplyLater = () => {
  //   if (activeLead.status === REPLY_LATER) {
  //     const data = {
  //       lead: cardId,
  //       is_current: true,
  //       status: VIEWED
  //     };
  //     dispatch(changeLeadStatus({ body: data }));
  //     dispatch(setActiveLead({ ...activeLead, status: VIEWED }));
  //   }
  // };

  const callback = (data) => {
    // if (!isEdit) {
    //   updateLeadStatusIfInReplyLater();
    // }
    setError('');
    if (!isEdit) {
      // adding the created note to local state instead of calling api
      const updatedNotesData = [data, ...allNotes];
      dispatch(setAllNotes(updatedNotesData));
    } else {
      // adding the updated note to local state instead of calling api
      const updatedNotes = allNotes.map((item) => {
        if (item.id === data.id) {
          return {
            ...data
          };
        }
        return item;
      });
      dispatch(setAllNotes(updatedNotes));
    }
    setIsLoading(false);
    setNote('');
    dispatch(setShowInboxActions(true));
    dispatch(getLeadData({ leadId: cardId }));
    // dispatch(getLeadConversation({ leadId: cardId }));
    dispatch(setFetchConversation(true));
    dispatch(setIsEdit(false));
    dispatch(resetNoteStore());
    if (editedNote) {
      openSuccessSnackBar('Note updated');
    } else {
      openSuccessSnackBar('Note added');
    }
    dispatch(setEditorActiveTab(REPLY));
    autoScroll();
  };

  const handleUpdate = () => {
    if (isEmpty(note)) {
      setError('Please add a note');
    } else {
      if (error) {
        return;
      } else {
        setIsLoading(true);
        if (!isEdit) {
          const selectedUserId =
            !isEmpty(selectedUser) && selectedUser.map((item) => item.userId);
          dispatch(
            addTaskNotes({
              body: {
                lead: cardId,
                description: note,
                tagged_users: selectedUserId ? selectedUserId : [],
                is_reply_sent: true
              },
              callback: callback
            })
          );
        } else {
          const regex = /[^{@[}]+(?=])/g;
          const mentions = note.match(regex);
          if (!isEmpty(mentions)) {
            const selectedUsers = users.filter((user) => {
              return mentions.some((item) => {
                return item === user.id;
              });
            });
            const selectedUserId =
              !isEmpty(selectedUsers) &&
              selectedUsers.map((item) => item.userId);
            setSelectedUser(selectedUsers);
            const formData = {
              lead: cardId,
              description: note,
              tagged_users: selectedUserId ? selectedUserId : []
            };
            dispatch(
              updateTaskNotes({
                body: formData,
                noteId: editedNote,
                callback: callback
              })
            );
          }
          if (isEmpty(mentions)) {
            setSelectedUser([]);
            const formData = {
              lead: cardId,
              description: note,
              tagged_users: []
            };
            dispatch(
              updateTaskNotes({
                body: formData,
                noteId: editedNote,
                callback: callback
              })
            );
          }
        }
      }
    }
  };
  return (
    <div className="chat-message-footer__body" style={bodyStyle}>
      <Mentions
        note={note}
        setNote={setNote}
        users={users}
        setSelectedUser={setSelectedUser}
        setError={setError}
        inputStyle={inputStyle}
      />
      <div className="chat-message-footer__footer flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center ps px-2 py-1">
            <strong>PS:</strong>
            <p className="ml-1">The customer wont see this note</p>
          </div>
          {error && <ErrorMessage message={error} />}
        </div>
        <div className="flex">
          {isEdit && (
            <CustomColorButton
              label="Cancel"
              style={{ padding: '4px 10px', borderRadius: 6 }}
              className="btn btn--grey text-sm mr-2"
              onClick={() => {
                setNote('');
                dispatch(resetNoteStore());
              }}
            />
          )}
          <PrimaryButton
            className="text-sm text-white"
            style={{ padding: '4px 10px', borderRadius: 6 }}
            onClick={handleUpdate}
            enableLoader
            label={isEdit ? 'Update' : 'Add note'}
            isLoading={isLoading}
            loaderInline
            disabled={isEmpty(note) || !isEmpty(error)}
          />
        </div>
      </div>
    </div>
  );
};

export default Notes;

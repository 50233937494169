/* eslint-disable react/no-unescaped-entities */
import React, { useMemo } from 'react';
import Modal from 'components/modal';
import circularCancelBtn from 'assets/icons/circular-cancel-btn.svg';
import TeamUserEmoji from 'assets/icons/team-user-emoji.png';
import CustomColorButton from 'components/buttons/customColorButton';
import { useSelector } from 'react-redux';
import choices from 'choices';
import { getCurrencyText } from 'utils/currency';
import PaymentModalLogout from 'components/paymentModalLogOut';

const { STARTER } = choices.PlanType;

const UpgradePlanNotification = ({ open, onShowPlans, onCancel }) => {
  const { subscriptionPlans, currentPlan } = useSelector(
    (state) => state.subscription
  );
  // const analytics = useSelector((state) => state.segments.analytics);

  const starter_plan = useMemo(() => {
    const plan = subscriptionPlans.find((plan) => plan.plan_type == STARTER);
    return plan;
  }, [subscriptionPlans]);

  return (
    <Modal.Main className="modal--upgrade-notification" open={open}>
      <div className="modal__body px-11 py-10 relative">
        <PaymentModalLogout />
        <h1 className="text-25 font-bold text-center">
          Your 50 Free enquiries are used
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            We couldn’t log you in because your 50 free enquiries have now been
            used up.
          </p>
          <p className="text-lg leading-6 mt-6">
            All your data is safe, but no one will be able to log in until your
            account is upgraded.
          </p>
        </div>
        <div className="bg-grey-90 border-radius-14 py-5 px-6 mt-6">
          <p className="text-lg font-medium">
            Upgrade now and pick up where you left off
          </p>
          <CustomColorButton
            label={`See our plans, starting from ${getCurrencyText(
              currentPlan?.plan?.currency
            )}${starter_plan?.amount}`}
            className="w-full text-white text-lg font-bold bg-green-dark mt-4 cursor-pointer"
            style={{ fontWeight: '700' }}
            onClick={onShowPlans}
          />
        </div>
        <div className="mt-6">
          <p className="text-lg font-medium text-center">You can also...</p>
          <div
            className="flex items-center py-3 border-b border-b-grey-400 cursor-pointer"
            onClick={onCancel}
          >
            <img src={circularCancelBtn} alt="cancel-btn" />
            <p className="text-lg ml-4">Cancel and export your data</p>
          </div>
          <div className="flex items-center py-3 cursor-pointer">
            <img src={TeamUserEmoji} alt="cancel-btn" />
            <p className="text-lg ml-4">Speak to our team</p>
          </div>
        </div>
      </div>
    </Modal.Main>
  );
};

export default UpgradePlanNotification;

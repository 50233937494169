import { createSlice } from '@reduxjs/toolkit';

export const snackBarSlice = createSlice({
  name: 'snackbar',
  initialState: {
    isActive: false,
    type: '',
    message: '',
    size: 'small'
  },
  reducers: {
    setIsActive: (state, action) => {
      state.isActive = action.payload;
    },
    setType: (state, action) => {
      state.type = action.payload;
    },
    setMessage: (state, action) => {
      state.message = action.payload;
    },
    setSize: (state, action) => {
      state.size = action.payload;
    }
  }
});

export const { setIsActive, setType, setMessage, setSize } =
  snackBarSlice.actions;

export default snackBarSlice.reducer;

import TextInputField from 'components/inputs/textField';
import React from 'react';

const TextTypeForm = ({ data, onChange }) => {
  return (
    <form className="pb-4" autoComplete="off">
      <label className="input-field-label">{data.text}</label>
      <TextInputField
        name="answer"
        placeholder=""
        className="input-field--type2"
        style={{ padding: '9px 15px' }}
        onChange={(e) => onChange(e.target.value, data.id)}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
      />
    </form>
  );
};

export default TextTypeForm;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Mentions from 'components/editor/mentions';
import Error from 'components/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';

const AutomationNote = ({ className, condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const errors = automationErrors[index];
  const {
    data: { description }
  } = condition;
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const users = organizationUser?.results
    ?.filter((i) => !i.is_disabled)
    ?.map((item) => {
      return {
        id: item.first_name,
        display: item.first_name,
        avatar: item.avatar,
        userId: item.user_id
      };
    });

  const [note, setNote] = useState(description);
  const [selectedUser, setSelectedUser] = useState([]);

  const getTaggedUsers = () => {
    const regex = /[^{@[}]+(?=])/g;
    const mentions = note.match(regex);
    if (!isEmpty(mentions)) {
      const selectedUsers = users.filter((user) => {
        return mentions.some((item) => {
          return item === user.id;
        });
      });
      const selectedUserId =
        !isEmpty(selectedUsers) && selectedUsers.map((item) => item.userId);
      return selectedUserId;
    }
  };

  const updateThen = () => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.description) {
      let result = omit(errorObj[index], ['description']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        description: note,
        tagged_users: getTaggedUsers()
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  useEffect(() => {
    updateThen();
  }, [note, selectedUser]);

  return (
    <>
      <div className="box-automation-notes" style={{ height: '100px' }}>
        <Mentions
          note={note}
          setNote={setNote}
          users={users}
          setSelectedUser={setSelectedUser}
        />
      </div>
      <Error message={errors?.description} />
    </>
  );
};

export default React.memo(AutomationNote);

import React from 'react';
import DeleteChannel from '../deleteChannel';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import AutomateStuff from '../../automationStuff/templates';
import CHOICES from 'choices';

const { FACEBOOK, INSTAGRAM } = CHOICES.ChannelChoices;

const Settings = ({ isInsta }) => {
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const navigate = useNavigate();

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/dashboard/enquirybox/settings');
  };

  return (
    <>
      <h2 className="text-2xl font-semibold text-center">Channel Settings</h2>
      <AutomateStuff channelType={isInsta ? INSTAGRAM : FACEBOOK} />
      <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
    </>
  );
};

export default Settings;

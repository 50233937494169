/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import useClickOutside from 'hooks/useOutsideClick';
import {
  setActiveTab,
  setAssignedTo,
  getTotalUserLeads
} from 'slices/leadsFilter';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CaretPrimaryIcon } from 'assets/icons/icon-select-caret-primary.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
import { ASSIGNED_TO, WHOEVER__IS_AVAILABLE } from 'utils/constants';
import Avatar from 'components/avatar';
import UserList from 'modules/enquirybox/ebox/module/box/conversation/actions/assignUser/renderList';
import { isEmpty } from 'utils/utils';
import { ReactComponent as FilterIcon } from 'assets/icons/icon-channel-filter.svg';
import { ReactComponent as FilterIconActive } from 'assets/icons/icon-channel-filter-primary.svg';
import closeIcon from 'assets/icons/icon-close-black.svg';
import { whoever_is_available } from 'slices/organizationuserSlice';
import { getTaskEnquiryCount, getAllTasks } from 'slices/taskThingsToDo/slice';

const AsignedTo = ({ loadAllLeads }) => {
  // const nodeRef = useRef();
  const channelRef = useRef();
  const dispatch = useDispatch();
  const [openAssign, setOpenAssign] = useState();
  const { activeTab, assignedTo } = useSelector((state) => state.leadsFilter);
  const { organizationUser } = useSelector((state) => state.organizationUser);

  useClickOutside(() => setOpenAssign(false), channelRef.current);

  const onUserSelect = (option) => {
    setOpenAssign(false);
    dispatch(setAssignedTo(option));
    // dispatch(setActiveTab(ASSIGNED_TO));
    // dispatch(getTotalUserLeads());
    dispatch(getTaskEnquiryCount());
    loadAllLeads();
    dispatch(getAllTasks());
  };

  const onRemoveUser = (e) => {
    e.stopPropagation();
    dispatch(setAssignedTo(null));
    dispatch(getTaskEnquiryCount());
    loadAllLeads();
    dispatch(getAllTasks());
  };

  return (
    <>
      <div
        className={`${
          openAssign || !isEmpty(assignedTo) ? 'bg-grey-90' : 'bg-grey-300'
        } ${isEmpty(assignedTo) ? 'px-3' : 'px-1'} channels-btn`}
        ref={channelRef}
        style={!isEmpty(assignedTo) ? { minWidth: 150 } : {}}
      >
        {isEmpty(assignedTo) && (
          <>
            <figure
              onClick={() => {
                setOpenAssign(!openAssign);
              }}
            >
              {!openAssign ? <FilterIcon /> : <FilterIconActive />}
            </figure>
            <p
              className={`text-13 font-medium ml-1 ${
                openAssign ? 'text-primary' : ''
              }`}
              onClick={() => {
                setOpenAssign(!openAssign);
              }}
            >
              Assigned to
            </p>
          </>
        )}
        {!isEmpty(assignedTo) && (
          <>
            <Avatar
              src={assignedTo?.avatar}
              style={{ width: 20, height: 20, marginRight: '5px' }}
              initial={assignedTo?.name}
              initialStyle={{ fontSize: 'smaller', lineHeight: 'unset' }}
              contain
              singleChar
            />
            <span
              onClick={() => {
                setOpenAssign(!openAssign);
              }}
              className={`selected-user text-grey-900 text-sm whitespace-nowrap overflow-ellipsis`}
              style={
                !isEmpty(assignedTo) &&
                assignedTo?.name === WHOEVER__IS_AVAILABLE
                  ? {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }
                  : {
                      width: '90px',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden'
                    }
              }
            >
              {assignedTo.name}
            </span>
            <img
              src={closeIcon}
              alt=""
              style={{ paddingLeft: 4, paddingRight: 5 }}
              onClick={onRemoveUser}
            />
            {/* <figure
          className="border-radius-20 bg-grey-100 flex justify-center items-center mr-1"
          style={{ width: 22, height: 16 }}
        >
          <span className="text-xs font-semibold text-primary">
            {assignedToCount}
          </span>
        </figure> */}
          </>
        )}
        <UserList
          open={openAssign}
          options={
            organizationUser?.results
              ? [whoever_is_available, ...organizationUser.results]
              : [whoever_is_available]
          }
          onSelect={onUserSelect}
          active={assignedTo}
          wrapperStyle={{
            right: 0,
            left: 0,
            width: 350,
            top: '15px',
            transition: 'all 300ms ease-in'
          }}
          showDelete={false}
          deleteVisible={false}
        />
      </div>
    </>
  );
  // return (
  //   <div
  //     className={`tabs-header relative ${
  //       activeTab === ASSIGNED_TO ? 'tabs-header__active' : ''
  //     }`}
  //     style={{ paddingBottom: 19, fontSize: 14 }}
  // ref={nodeRef}
  //   >
  //     <div
  //       className="assigned-to"
  //       onClick={() => {
  //         setOpenAssign(!openAssign);
  //       }}
  //     >
  //       {assignedTo === null && (
  //         <span
  //           className={`mr-2 ${
  //             activeTab === ASSIGNED_TO ? 'text-primary' : 'text-grey-900'
  //           }`}
  //         >
  //           Assigned to
  //         </span>
  //       )}
  //       {assignedTo && (
  //         <>
  //           <Avatar
  //             src={assignedTo?.avatar}
  //             style={{ width: 20, height: 20, marginRight: '5px' }}
  //             initial={assignedTo?.name}
  //             initialStyle={{ fontSize: 'smaller', lineHeight: 'unset' }}
  //             contain
  //             singleChar
  //           />
  //           <span
  //             className={`selected-user ${
  //               activeTab === ASSIGNED_TO ? 'text-primary' : 'text-grey-900'
  //             }`}
  //           >
  //             {assignedTo.name}
  //           </span>
  //           <figure
  //             className="border-radius-20 bg-grey-100 flex justify-center items-center mr-1"
  //             style={{ width: 22, height: 16 }}
  //           >
  //             <span className="text-xs font-semibold text-primary">
  //               {assignedToCount}
  //             </span>
  //           </figure>
  //         </>
  //       )}
  //       <figure>
  //         {openAssign ? <CaretPrimaryIcon /> : <CaretBlackIcon />}
  //       </figure>
  //     </div>
  //     <UserList
  //       open={openAssign}
  //       options={organizationUser?.results}
  //       onSelect={onUserSelect}
  //       active={assignedTo}
  //       wrapperStyle={{
  //         right: 0,
  //         left: 0,
  //         width: 350,
  //         top: '15px',
  //         transition: 'all 300ms ease-in'
  //       }}
  //       showDelete={false}
  //       deleteVisible={false}
  //     />
  //   </div>
  // );
};

export default AsignedTo;

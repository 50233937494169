import React, { useEffect, useState } from 'react';
import QuillEditor from 'components/quill';
import PrimaryButton from 'components/buttons/primary';
import CHOICES from 'choices';

const { EMAIL } = CHOICES.ReplyTemplateChoice;

const SelectedReplyTemplate = (props) => {
  const {
    data,
    setOpenReplyTemplate,
    isChannelSettings,
    onUseTemplate,
    isAutomateStuff
  } = props;
  const [renderData, setRenderData] = useState(data);

  useEffect(() => {
    setRenderData(data);
  }, [data]);

  const onUseThis = () => {
    onUseTemplate(
      renderData?.subject,
      renderData?.reply_type === EMAIL
        ? renderData?.body
        : renderData?.html_body,
      renderData?.attachments
    );
    setOpenReplyTemplate(false);
  };
  return (
    <div
      className="pb-4"
      style={
        isChannelSettings || isAutomateStuff
          ? { maxWidth: '50%', width: '50%' }
          : { maxWidth: 316, width: 316 }
      }
    >
      <div
        className="chat-reply w-full hideScrollBar"
        style={{ height: 'calc(100% - 51px)' }}
      >
        <QuillEditor
          placeholder=""
          isAutomation
          showSendBtn={false}
          showBottomBorder={false}
          onChange={(data) => {}}
          value={
            renderData?.reply_type === EMAIL
              ? renderData?.body
              : renderData?.html_body
          }
          hideImage={true}
          hideVarible={true}
          hideHyperlink={true}
          hideReplyTemplate={true}
          hideAttachment={false}
          attachments={renderData?.attachments}
          showCustomToolbar={false}
          readOnly={true}
          isChannelSettings={isChannelSettings}
          isAutomateStuff={isAutomateStuff}
        />
      </div>
      <div className="flex justify-end mr-6 mt-4">
        <PrimaryButton
          label="Use this"
          className="input-field--sm"
          onClick={onUseThis}
        />
      </div>
    </div>
  );
};

export default SelectedReplyTemplate;

/* eslint-disable no-unused-vars */
import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import ConversationInbox from 'modules/enquirybox/ebox/module/box/conversation/channel/inbox';
import Webformconversation from 'modules/enquirybox/ebox/module/box/conversation/channel/webform';
import WrapperConversation from 'modules/enquirybox/ebox/module/box/conversation/mainWrapper';
import choices from 'choices';
import FBInstaConversation from 'modules/enquirybox/ebox/module/box/conversation/channel/facebookAndInsta';
import FBLeadAdsConversations from 'modules/enquirybox/ebox/module/box/conversation/channel/facebookleadAds';
import CardNotFound from 'components/cardNotFound';
import CardMovedToBin from 'components/cardMovedToBin';
import SmsConversation from 'modules/enquirybox/ebox/module/box/conversation/channel/sms';
import EnquiryBotConversation from 'modules/enquirybox/ebox/module/box/conversation/channel/enquiryBot';
import ZapierConversation from 'modules/enquirybox/ebox/module/box/conversation/channel/zapier';
import AiChatbotConversation from 'modules/enquirybox/ebox/module/box/conversation/channel/aiChatbot';

const Conversation = () => {
  const {
    WEB_FORMS,
    MANUALLY_ADDED,
    FACEBOOK,
    FACEBOOK_LEADGEN,
    INSTAGRAM,
    SMS,
    IMPORTED_FROM_FILE,
    EBOT,
    ZAPIER,
    AI_CHAT_BOT
  } = choices.LeadChannelChoice;
  return (
    <>
      <Routes>
        <Route
          path={`/${MANUALLY_ADDED}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBox={true} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${IMPORTED_FROM_FILE}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBox={true} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${WEB_FORMS}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBox={true} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${FACEBOOK}/:cardId`}
          element={
            <WrapperConversation>
              <FBInstaConversation isFacebook={true} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${FACEBOOK_LEADGEN}/:cardId`}
          element={
            <WrapperConversation>
              <FBLeadAdsConversations />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${INSTAGRAM}/:cardId`}
          element={
            <WrapperConversation>
              <FBInstaConversation isInsta={true} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${SMS}/:cardId`}
          element={
            <WrapperConversation>
              <SmsConversation />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${EBOT}/:cardId`}
          element={
            <WrapperConversation>
              <EnquiryBotConversation />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${ZAPIER}/:cardId`}
          element={
            <WrapperConversation>
              <ZapierConversation />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${AI_CHAT_BOT}/:cardId`}
          element={
            <WrapperConversation>
              <AiChatbotConversation />
            </WrapperConversation>
          }
        />
        <Route path="/card-not-found" element={<CardNotFound />} />
        <Route path="/card-moved-to-bin" element={<CardMovedToBin />} />
      </Routes>
    </>
  );
};

export default Conversation;

/* eslint-disable react-hooks/exhaustive-deps */
import { Loader } from 'components/loader';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSourceReport } from 'slices/reports/slice';
import EnquirySource from '../../components/enquirySource';
import EnquiryTime from '../../components/enquiryTime';
import FilterSection from '../../components/filterSection';
import { formatDateTime } from 'utils/dateFormat';

const SourceReport = ({ hideCount }) => {
  const dispatch = useDispatch();
  const [selectedDay, setSelectedDay] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const { sourceReport } = useSelector((state) => state.reports);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllReport = () => {
    const formData = {
      startDate: moment().startOf('month').format('YYYY-MM-DD'),
      endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      subOrgId: subOrganizationId
    };
    dispatch(getAllSourceReport(formData, callback));
  };

  useEffect(() => {
    setIsLoading(true);
    loadAllReport();
  }, []);

  const onSelectDay = (data) => {
    setStartDate();
    setEndDate();
    if (data.value === 'This month to date') {
      loadAllReport();
    }
    if (data.value === 'Last 7 days') {
      const formData = {
        startDate: moment().subtract(7, 'days').format('YYYY-MM-DD'),
        endDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
        subOrgId: subOrganizationId
      };
      dispatch(getAllSourceReport(formData, callback));
    }
  };

  const onCustomDateSelect = (data) => {
    if (data.startDate && data.endDate) {
      const start_date = formatDateTime(data.startDate, 'yyyy-mm-dd');
      const end_date = formatDateTime(data.endDate, 'yyyy-mm-dd');
      const formData = {
        startDate: start_date,
        endDate: end_date,
        subOrgId: subOrganizationId
      };
      dispatch(getAllSourceReport(formData, callback));
    }
  };

  if (isLoading) return <Loader />;

  return (
    <div className="source-report">
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Marketing report</h3>
        {!hideCount && (
          <FilterSection
            endDate={endDate}
            startDate={startDate}
            setEndDate={setEndDate}
            setStartDate={setStartDate}
            setSelectedDay={setSelectedDay}
            selectedDay={selectedDay}
            onSelectDay={onSelectDay}
            onCustomDateSelect={onCustomDateSelect}
          />
        )}
      </div>
      <div className="flex items-center -mx-3 mt-6">
        <EnquirySource
          paidTotal={sourceReport?.report?.total_paid_enquiries}
          paidPercentage={sourceReport?.report?.total_paid_enquiries_percentage}
          unPaidTotal={sourceReport?.report?.total_unpaid_enquiries}
          unPaidPercentage={
            sourceReport?.report?.total_unpaid_enquiries_percentage
          }
          hideCount={hideCount}
        />
        <EnquiryTime
          duringBusinessHourTotal={
            sourceReport?.report?.total_enquiries_in_business_hours
          }
          duringBusinessHourPercentage={
            sourceReport?.report?.total_enquiries_in_business_hours_percentage
          }
          outsideBusinessHourTotal={
            sourceReport?.report?.total_enquiries_out_of_business_hours
          }
          outsideBusinessHourPercentage={
            sourceReport?.report
              ?.total_enquiries_out_of_business_hours_percentage
          }
          hideCount={hideCount}
        />
      </div>
    </div>
  );
};

export default SourceReport;

import React from 'react';
import HistoryLayout from './layout';
import taskIcon from 'assets/icons/white-union-task.svg';
import choices from 'choices';
import { timeFormatter } from 'utils/utils';

export const TaskDesign = ({ desc }) => {
  return (
    <div className="flex truncate" style={{ maxWidth: '97%' }}>
      <div
        className="flex py-3 text-13 px-3 justify-center align-center"
        style={{ overflowWrap: 'anywhere' }}
      >
        {desc}
      </div>
    </div>
  );
};
const Task = (props) => {
  let {
    data: { title, created_at, description }
  } = props;
  return (
    <div>
      <HistoryLayout
        icon={taskIcon}
        title={choices.TitleChoices.CHOICES[title]}
        time={timeFormatter(created_at)}
        renderData={() => <TaskDesign desc={description} />}
        className="history__new-enquiry"
      />
    </div>
  );
};
export default Task;

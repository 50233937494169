// DON'T EDIT.

export let STEP_TYPES_MAP = {};
const STEP_TYPES = [
  {
    id: 'AUTOCOMPLETE',
    name: 'Autocomplete',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'BUTTON',
    name: 'Button',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'CALENDAR',
    name: 'Calendar',
    validators: [
      {
        id: 'DATE',
        name: 'Date'
      }
    ]
  },
  {
    id: 'CAROUSEL',
    name: 'Carousel',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'DATE_RANGE',
    name: 'Date Range',
    validators: [
      {
        id: 'DATE',
        name: 'Date'
      }
    ]
  },
  {
    id: 'DECIDER',
    name: 'Decider',
    validators: []
  },
  {
    id: 'EMAIL',
    name: 'Email',
    validators: []
  },
  {
    id: 'ENTRY',
    name: 'Entry',
    validators: []
  },
  {
    id: 'EXIT',
    name: 'Exit',
    validators: []
  },
  {
    id: 'FAQ',
    name: 'FAQ',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'FILE_UPLOAD',
    name: 'File Upload',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'FIRST_NAME',
    name: 'First Name',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'IMAGE_MCQ',
    name: 'Image MCQ',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'LAST_NAME',
    name: 'Last Name',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'LOCATION',
    name: 'Location',
    validators: [
      {
        id: 'LOCATION',
        name: 'Location'
      }
    ]
  },
  {
    id: 'LONG_TEXT',
    name: 'Long Text',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'MCQ',
    name: 'MCQ',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'NAME',
    name: 'Name',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'NUMBER',
    name: 'Number',
    validators: [
      {
        id: 'MAX_VALUE',
        name: 'Maximum Value'
      },
      {
        id: 'MIN_VALUE',
        name: 'Minimum Value'
      }
    ]
  },
  {
    id: 'PHONE_NUMBER',
    name: 'Phone Number',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'RATING',
    name: 'Rating',
    validators: [
      {
        id: 'MAX_RANGE',
        name: 'Maximum Range'
      },
      {
        id: 'MIN_RANGE',
        name: 'Minimum Range'
      }
    ]
  },
  {
    id: 'RETURN',
    name: 'Return',
    validators: []
  },
  {
    id: 'SHORT_TEXT',
    name: 'Short Text',
    validators: [
      {
        id: 'MAX_LENGTH',
        name: 'Maximum Length'
      },
      {
        id: 'MIN_LENGTH',
        name: 'Minimum Length'
      }
    ]
  },
  {
    id: 'SLIDER',
    name: 'Slider',
    validators: [
      {
        id: 'MAX_RANGE',
        name: 'Maximum Range'
      },
      {
        id: 'MIN_RANGE',
        name: 'Minimum Range'
      }
    ]
  },
  {
    id: 'STRIPE_PAYMENTS',
    name: 'Stripe Payment',
    validators: []
  },
  {
    id: 'TIME',
    name: 'Time',
    validators: []
  },
  {
    id: 'TIME_SLOTS',
    name: 'Time Slot',
    validators: [
      {
        id: 'MAX_OPTION',
        name: 'Maximum Options'
      },
      {
        id: 'MIN_OPTION',
        name: 'Minimum Options'
      }
    ]
  },
  {
    id: 'WEB_CONTENT',
    name: 'Web Content',
    validators: []
  }
];

STEP_TYPES.forEach((step_type) => {
  STEP_TYPES_MAP[step_type.id] = step_type;
});

export default STEP_TYPES;
// export {STEP_TYPES_MAP};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// import { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { useLocation, Outlet } from 'react-router-dom';

const PageLayout = ({ children }) => children;

const pageVariants = {
  initial: {
    opacity: 0
  },
  in: {
    opacity: 1
  },
  out: {
    opacity: 0
  }
};

const pageTransition = {
  type: 'tween',
  ease: 'linear',
  duration: 0.5
};

const AnimationWrapper = ({ style }) => {
  const { pathname } = useLocation();

  return (
    <PageLayout>
      <motion.div
        key={pathname}
        initial="initial"
        animate="in"
        variants={pageVariants}
        transition={pageTransition}
        style={{ height: '100%', flex: 1, ...style }}
      >
        <Outlet />
      </motion.div>
    </PageLayout>
  );
};

AnimationWrapper.defaultProps = {
  style: {}
};

export default AnimationWrapper;

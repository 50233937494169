/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';

const CancelSubscription = ({
  open,
  setIsConnected,
  onClose,
  setActiveTab
}) => {
  return (
    <Modal.Main className="modal--cancel-subscription" open={open}>
      <div className="modal__body px-11 py-10">
        <h1 className="text-25 font-bold text-center">
          Are you sure you want to cancel?
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            If you cancel, your EnquiryBot will stop collecting enquiries from
            your website.
          </p>
          <p className="text-lg leading-6 mt-6">
            Your EnquiryBot will be deleted and you will not be able to get it
            back.
          </p>
        </div>
        <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
          <p className="text-lg font-medium text-center">
            It’s still not too late to keep it
          </p>
          <div className="mt-4">
            <CustomColorButton
              label="Keep my Enquirybot"
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
              onClick={onClose}
            />
            <CustomColorButton
              label="No thanks, cancel it"
              className="w-full bg-white text-lg mt-3 cursor-pointer"
              style={{
                color: '#F03636',
                fontWeight: '400',
                border: '1px solid #F03636',
                padding: '10px 18px'
              }}
              onClick={() => {
                setActiveTab(0);
                setIsConnected(false);
                onClose();
              }}
            />
          </div>
        </div>
      </div>
    </Modal.Main>
  );
};

export default CancelSubscription;

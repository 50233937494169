import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import ConversationInbox from './channel/inbox';
import Webformconversation from './channel/webform';
import WrapperConversation from './mainWrapper';
import choices from 'choices';
import FBInstaConversation from './channel/facebookAndInsta';
import FBLeadAdsConversations from './channel/facebookleadAds';
import CardNotFound from 'components/cardNotFound';
import CardMovedToBin from 'components/cardMovedToBin';
import SmsConversation from './channel/sms';
import EnquiryBotConversation from './channel/enquiryBot';
import ZapierConversation from './channel/zapier';
import AiChatbotConversation from './channel/aiChatbot';

const ConversationMain = (props) => {
  const { isBin } = props;
  const {
    WEB_FORMS,
    FACEBOOK,
    FACEBOOK_LEADGEN,
    INSTAGRAM,
    SMS,
    MANUALLY_ADDED,
    IMPORTED_FROM_FILE,
    EBOT,
    ZAPIER,
    AI_CHAT_BOT
  } = choices.LeadChannelChoice;
  return (
    <div className="dashboard__main__body__section-right chat-message-container relative">
      <Routes>
        <Route
          path={`/${WEB_FORMS}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${IMPORTED_FROM_FILE}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${MANUALLY_ADDED}/:cardId`}
          element={
            <WrapperConversation>
              <Webformconversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${FACEBOOK}/:cardId`}
          element={
            <WrapperConversation>
              <FBInstaConversation isFacebook={true} isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${FACEBOOK_LEADGEN}/:cardId`}
          element={
            <WrapperConversation>
              <FBLeadAdsConversations isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${INSTAGRAM}/:cardId`}
          element={
            <WrapperConversation>
              <FBInstaConversation isInsta={true} isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${SMS}/:cardId`}
          element={
            <WrapperConversation>
              <SmsConversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${EBOT}/:cardId`}
          element={
            <WrapperConversation>
              <EnquiryBotConversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${ZAPIER}/:cardId`}
          element={
            <WrapperConversation>
              <ZapierConversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route
          path={`/${AI_CHAT_BOT}/:cardId`}
          element={
            <WrapperConversation>
              <AiChatbotConversation isBin={isBin} />
            </WrapperConversation>
          }
        />
        <Route path="/card-not-found" element={<CardNotFound />} />
        <Route path="/card-moved-to-bin" element={<CardMovedToBin />} />
      </Routes>
    </div>
  );
};

export default ConversationMain;

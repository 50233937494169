import { createSlice } from '@reduxjs/toolkit';

export const teamChatSlice = createSlice({
  name: 'teamChat',
  initialState: {
    activeTab: 1
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  }
});
export const { setActiveTab } = teamChatSlice.actions;

export default teamChatSlice.reducer;

/* eslint-disable no-empty-pattern */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';
import { CREATE_BOX_AUTOMATION, UPDATE_BOX_AUTOMATION } from 'urls/boxes';
import { omit } from 'lodash';

const initialState = {
  boxAutomations: [],
  addAutomation: false,
  selectedAutomation: null,
  automation_name: '',
  isAutomationActive: true,
  box: null,
  automationStage: null,
  connected_channel: [],
  then: [{}],
  automationErrors: {},
  isDelayTypeSelected: false,
  stageIndex: null
};

const reducers = {
  setBoxAutomations: (state, action) => {
    state.boxAutomations = action.payload;
  },
  setAddAutomation: (state, action) => {
    state.addAutomation = action.payload;
  },
  setSelectedAutomation: (state, action) => {
    state.selectedAutomation = action.payload;
  },
  setAutomationName: (state, action) => {
    state.automation_name = action.payload;
  },
  setBox: (state, action) => {
    state.box = action.payload;
  },
  setConnectedChannel: (state, action) => {
    state.connected_channel = action.payload;
  },
  setThen: (state, action) => {
    state.then = action.payload;
  },
  setAutomationStage: (state, action) => {
    state.automationStage = action.payload;
  },
  setAutomationErrors: (state, action) => {
    state.automationErrors = action.payload;
  },
  setIsAutomationActive: (state, action) => {
    state.isAutomationActive = action.payload;
  },
  setIsDelayTypeSelected: (state, action) => {
    state.isDelayTypeSelected = action.payload;
  },
  resetAutomationStore: (state) => {
    state.addAutomation = initialState.addAutomation;
    state.selectedAutomation = initialState.selectedAutomation;
    state.automation_name = initialState.automation_name;
    state.box = initialState.box;
    state.automationStage = initialState.automationStage;
    state.connected_channel = initialState.connected_channel;
    state.then = initialState.then;
    state.automationErrors = initialState.automationErrors;
    state.isAutomationActive = initialState.isAutomationActive;
    state.isDelayTypeSelected = initialState.isDelayTypeSelected;
  },
  setStageIndex: (state, action) => {
    state.stageIndex = action.payload;
  }
};

export const boxAutomation = createSlice({
  name: 'boxAutomation',
  initialState,
  reducers
});

export const {
  setAddAutomation,
  setSelectedAutomation,
  setAutomationName,
  setBox,
  setConnectedChannel,
  setThen,
  setAutomationStage,
  setBoxAutomations,
  resetAutomationStore,
  setAutomationErrors,
  setIsAutomationActive,
  setIsDelayTypeSelected,
  setStageIndex
} = boxAutomation.actions;

export default boxAutomation.reducer;

export const getBoxAutomations = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const { query = {} } = payload;
    try {
      await new APIRequest()
        .get(CREATE_BOX_AUTOMATION, {
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBoxAutomations(res.data));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((e) => {
          if (errorCb) {
            errorCb(e);
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const addBoxAutomation = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    try {
      await new APIRequest().post(CREATE_BOX_AUTOMATION, data).then((res) => {
        if (res.status === 200) {
          if (successCb) {
            successCb();
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBoxAutomation = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const { selectedAutomation } = getState().boxAutomation;
    try {
      await new APIRequest()
        .put(
          replaceUrl(
            UPDATE_BOX_AUTOMATION,
            'automation_id',
            selectedAutomation.id
          ),
          data
        )
        .then((res) => {
          if (successCb) {
            successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteThen = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { data, query, index, box_id } = payload;
    const { selectedAutomation } = getState().boxAutomation;
    try {
      await new APIRequest()
        .delete(
          CREATE_BOX_AUTOMATION,
          {},
          {
            ...query
          }
        )
        .then((res) => {
          dispatch(removeThen({ index })).then(() => {
            if (box_id) {
              dispatch(getBoxAutomations({ query: { box_id } }));
            }
            if (successCb) successCb();
          });
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const removeThen = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { then, automationErrors } = getState().boxAutomation;
    const { index } = payload;
    try {
      // const result = omit(automationErrors, [index]);
      const thenCopy = [...then];
      thenCopy.splice(index, 1);
      if (thenCopy.length === 0) {
        thenCopy.push({});
      }
      dispatch(setAutomationErrors({}));
      await dispatch(setThen(thenCopy));
      if (successCb) successCb();
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAutomationStatus = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    const { data, automation_id } = payload;
    // const { selectedAutomation } = getState().boxAutomation;
    const { stageIndex } = getState().boxAutomation;
    try {
      await new APIRequest()
        .put(
          replaceUrl(UPDATE_BOX_AUTOMATION, 'automation_id', automation_id),
          data
        )
        .then((res) => {
          if (successCb) {
            successCb(res, stageIndex);
          }
        })
        .catch((e) => {
          if (errorCb) {
            errorCb(e);
          }
        });
    } catch (e) {
      if (errorCb) {
        errorCb(e);
      }
      console.log(e);
    }
  };
};

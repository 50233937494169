/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/loader';
import { useParams } from 'react-router-dom';
import {
  getLeadData,
  setActiveLead,
  setHover,
  setIsModalOpen
} from 'slices/conversationSlice';
import { setAllAnswer, setQuestionsData } from 'slices/questionSlice';
import {
  updateUnreadLeadStatus,
  onNewCardPostClick,
  setCurrentCardList,
  setShowContactModal,
  setPreviousActiveNewLead,
  setActiveNewLead,
  setPreviousClickedLead,
  setPreviousClickedLeadList,
  shiftTheLeadPostClick
} from 'slices/eboxLead';
import {
  updateBoxUnreadLeadStatus,
  onNewBoxCardPostClick,
  setCurrentBoxCardList
} from 'slices/boxes/slice';
import { getAllCounts } from 'slices/allCount/slice';
import choices from 'choices';
import { getAllNewBoxesEnquires } from 'slices/boxesNewEnquiresSlice';
import ScreenLoader from 'components/loader/screenLoader';
import { isEmpty } from 'utils/utils';
import { setAllNewTasks, setAllCompletedTasks } from 'slices/task/slice';
import { getAndComposeStageLeads } from 'slices/boxes/slice';

const { VIEWED, NEW, RESTORED } = choices.LeadStatusChoices;

const WrapperConversation = (props) => {
  const { cardId, stageId, box_id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const dispatch = useDispatch();
  const { currentBoxCardList } = useSelector((state) => state.boxes);
  const { currentCardList } = useSelector((state) => state.leads);
  const {
    unReadLeads,
    activeNewLead,
    previousActiveNewLead,
    previousClickedLeadList,
    previousClickedLead
  } = useSelector((state) => state.leads);
  const { activeBoxNewLead } = useSelector((state) => state.boxes);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const activeLeadRef = useRef();
  const prevCurrentListRef = useRef();
  // update Status of incoming section Cards

  useEffect(() => {
    if (activeLead) {
      activeLeadRef.current = activeLead;
    }
  }, [activeLead]);

  useEffect(() => {
    prevCurrentListRef.current = currentCardList;
  }, [currentCardList]);

  useEffect(() => {
    if (previousClickedLead && !stageId) {
      if (previousClickedLead.id !== cardId) {
        dispatch(shiftTheLeadPostClick());
      }
    }
    if (previousClickedLead && stageId) {
      setTimeout(() => {
        if (previousClickedLead.id !== cardId) {
          if (
            previousClickedLead.status == NEW ||
            previousClickedLead.status == RESTORED
          ) {
            setTimeout(() => {
              dispatch(getAndComposeStageLeads({ stageId }));
            }, 100);
          }
        }
      }, 1000);
    }
  }, [previousClickedLead]);

  const updateStatus = async (id, currentLead) => {
    // dispatch(setCurrentCardList(NEW));
    // prevCurrentListRef.current = NEW
    const data = {
      body: {
        status: VIEWED,
        lead: id,
        is_current: true
      }
    };
    if (cardId) {
      if (activeNewLead == id) {
        if (currentLead.status === NEW || currentLead.status === RESTORED) {
          await dispatch(
            updateUnreadLeadStatus(data, NEW, (res, activeLeadData) => {
              dispatch(setActiveLead({ ...activeLeadData, status: VIEWED }));
              if (previousActiveNewLead?.id == activeLeadData?.id) {
                dispatch(
                  setPreviousActiveNewLead({
                    ...activeLeadData,
                    status: VIEWED
                  })
                );
              }
              dispatch(getAllCounts());
            })
          );
          return;
        } else return;
      }
    }

    await dispatch(
      updateUnreadLeadStatus(data, NEW, (res, activeLeadData) => {
        dispatch(setActiveLead({ ...activeLeadData, status: VIEWED }));
        if (previousActiveNewLead?.id == activeLeadData?.id) {
          dispatch(
            setPreviousActiveNewLead({ ...activeLeadData, status: VIEWED })
          );
        }
        if (!isEmpty(box_id)) {
          dispatch(getAllNewBoxesEnquires({ boxId: box_id }));
        }
        dispatch(getAllCounts());
      })
    );
    // we need to check if the previous card that was clicked was a new one, and its
    // status is still the new so that after clicking that card we should not update it to read
    if (
      previousActiveNewLead?.status === NEW ||
      previousActiveNewLead?.status === RESTORED
    ) {
      dispatch(setActiveNewLead(id));
      const x = () => {
        return async (dispatch, getState) => {
          const {
            leadConversation: { activeLead }
          } = getState();
          dispatch(setPreviousActiveNewLead(activeLead));
        };
      };
      dispatch(x());
      return;
    }
    // dispatch(onNewCardPostClick({ id, list: unReadLeads }));

    // dispatch(setActiveNewLead([...activeNewLead, id]));
  };

  const updateBoxLeadStatus = async (id) => {
    dispatch(setCurrentBoxCardList(NEW));
    if (activeBoxNewLead == id) {
      return;
    }
    const data = {
      stageId,
      body: {
        status: VIEWED,
        lead: id,
        is_current: true
      }
    };
    await dispatch(
      updateBoxUnreadLeadStatus(data, () => {
        dispatch(getAllCounts());
      })
    );
    dispatch(onNewBoxCardPostClick({ id, stageId }));
    dispatch(getAllNewBoxesEnquires({ boxId: box_id }));
    // dispatch(setActiveNewLead([...activeNewLead, id]));
  };

  const onSuccess = (res) => {
    const data = res?.results[0];
    activeLeadRef.current = data;
    // prevCurrentListRef.current = data?.status
    setError(null);
    if (stageId) {
      if (data?.status === NEW || data?.status === RESTORED) {
        updateBoxLeadStatus(data?.id);
      }
      if (currentBoxCardList == 0) {
        dispatch(setCurrentBoxCardList(res?.results[0]?.status));
      }
    } else {
      // if (previousClickedLead === null) {
      //   dispatch(setPreviousClickedLead(data))
      // }
      if (data?.status === NEW || data?.status === RESTORED) {
        updateStatus(data?.id, data);
      }
      // if (currentCardList == 0) {
      //   prevCurrentListRef.current = res?.results[0]?.status
      //   dispatch(setCurrentCardList(res?.results[0]?.status));
      // }
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 500);
  };

  const onError = () => {
    setIsLoading(false);
    setError('Failed to get lead data');
  };

  const getLead = () => {
    dispatch(getLeadData({ leadId: cardId }, onSuccess, onError));
  };

  useEffect(() => {
    setIsLoading(true);
    getLead();
    return () => {
      dispatch(setPreviousClickedLeadList(prevCurrentListRef?.current));
      dispatch(setPreviousClickedLead(activeLeadRef?.current));
      dispatch(setHover(false));
      dispatch(setShowContactModal(false));
      // dispatch(setIsModalOpen(false));
      dispatch(setActiveLead(null));
      dispatch(setPreviousActiveNewLead(null));
      dispatch(setQuestionsData([]));
      dispatch(setAllAnswer({}));
      dispatch(setAllNewTasks([]));
      dispatch(setAllCompletedTasks([]));
    };
  }, [cardId]);

  if (isLoading) return <ScreenLoader />;

  if (error) return <div>{error}</div>;

  return <>{props.children}</>;
};

export default WrapperConversation;

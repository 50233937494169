/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SideNav from 'modules/report/sideNav';
import EnquiryReport from 'modules/report';
import NavBar from 'components/navigation/navbar';

const ReportRoutes = () => {
  return (
    <>
      <NavBar />
      <SideNav />
      <Routes>
        <Route path="/*" element={<EnquiryReport />} />
      </Routes>
    </>
  );
};

export default ReportRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import usePlanAccess from 'hooks/usePlanAccess';

const useIsFreeEnquiriesExpired = () => {
  const { freeEnquiriesCount, currentPlan } = useSelector(
    (state) => state.subscription
  );
  const { is_free_plan } = usePlanAccess();

  let isFreeEnquiriesExpired = useMemo(() => {
    if (is_free_plan && freeEnquiriesCount > currentPlan.plan.enquiries_limit) {
      return true;
    }
    return false;
  }, [freeEnquiriesCount]);

  return { isFreeEnquiriesExpired };
};

export default useIsFreeEnquiriesExpired;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import Stage from './stage';
import { useParams } from 'react-router-dom';
// import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/loader';
import AddStage from './stage/addStage';

const Stages = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const {
    stages,
    boxType,
    boxName,
    box,
    dragStart,
    isDetailEnabled,
    showConfetti,
    setShowConfetti
  } = props;
  const params = useParams();
  const restParams = params['*'];
  // const dispatch = useDispatch();
  // const stageLeads = useSelector((state) => state.boxes.stageLeads);

  // const getAllStageLeads = () => {
  //   const promises = []
  //   stages.forEach(stage => {
  //     promises.push(dispatch(getStageLeads({ stageId: stage.id })))
  //   });
  //   return promises
  // }

  // const generateStageLeads = () => {

  // }

  // useEffect(() => {
  //   setIsLoading(true)
  //   const promises = getAllStageLeads()
  //   const leads = {}
  //   promises.forEach(promise => {
  //     promise.then((res) => {
  //       console.log({ res })
  //       leads[]
  //     })
  //   })
  // }, [stages])

  // if (isLoading)
  //   return (
  //     <div className="flex items-center justify-center w-full">
  //       <Loader />
  //     </div>)

  return (
    <>
      {isLoading && (
        <div className="flex items-center justify-center w-full">
          <Loader />
        </div>
      )}
      {/* <Droppable droppableId="droppable" type="STAGE">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              // style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {stages.map((stage) => {
                return <Stage key={stage.id} stage={stage} id={1} />;
              })}
            </div>)}
        </Droppable > */}
      {stages.map((stage, idx) => {
        if (isDetailEnabled && restParams.includes(stage.id))
          return <div key={stage.id} />;
        return (
          <Stage
            key={stage.id}
            stage={stage}
            id={1}
            boxName={boxName}
            box={box}
            dragStart={dragStart}
            index={idx}
            isDetailEnabled={isDetailEnabled}
            setShowConfetti={setShowConfetti}
            showConfetti={showConfetti}
            fixWidth
          />
        );
      })}
      <AddStage />
    </>
  );
};

export default Stages;

import { createSlice } from '@reduxjs/toolkit';
import {
  GET_CONNECTED_CHANNEL_API,
  SEND_AUTOMATION,
  GET_AUTOMATIONS,
  UPDATE_AUTOMATIONS,
  SEND_FACEBOOK_AUTOMATION_AUTO_REPLY,
  UPADTE_FACEBOOK_AUTOMATION_AUTO_REPLY,
  SEND_FACEBOOK_AUTOMATION_ASSIGN_USER,
  GET_FB_LEAD_ADS_CONNECTED_CHANNELS,
  SEND_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY,
  SEND_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER,
  UPDATE_FACEBOOK_AUTOMATION_ASSIGN_USER,
  UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER,
  UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY,
  AUTOMATION_FILE_UPLOAD_DELETE,
  GET_ALL_THE_ENQUIRY_AND_SUB_ENQUIRY_TYPE,
  DELETE_CHANNEL_AUTOMATION,
  GET_FB_LEAD_ADS_FORMS,
  UPDATE_FB_LEAD_ADS_FORM_STATUS,
  HIDE_GUIDE_FOR_FB_LEADS,
  SYNC_NEW_FB_LEAD_ADS,
  GET_ALL_FB_LEADS_MAPPING_FIELDS,
  EDIT_FB_LEAD_ADS_MAPPING,
  GET_ALL_WEBFORM_YOUR_FORMS,
  GET_WEBFORM_MAPPING_DATA,
  DELETE_WEBFORM_FORM_DATA,
  EDIT_WEBFORM_MAPPED_DATA,
  HIDE_GUIDE_FOR_WEBFORMS
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

export const automationStuff = createSlice({
  name: 'automationStuff',
  initialState: {
    typeofAutomation: '',
    connectedChannels: [],
    availableAutomations: [],
    automationVariable: '',
    emailData: { send_from: '', subject: '', message: '', cc: [], bcc: [] },
    error: {},
    onEdit: {},
    enquiryAndSubEnquiries: [],
    fbLeadAdsForms: [],
    allFbLeadsFormFields: [],
    webformYourForms: [],
    allWebformFormFields: [],
    isPreview: false
  },
  reducers: {
    setTypeofAutomation: (state, action) => {
      state.typeofAutomation = action.payload;
    },
    setConnectedChannels: (state, action) => {
      state.connectedChannels = action.payload;
    },
    setAvaliableAutoamtions: (state, action) => {
      state.availableAutomations = action.payload;
    },
    setEmailVariable: (state, action) => {
      state.automationVariable = action.payload;
    },
    setEmailData: (state, action) => {
      state.emailData = action.payload;
    },
    setEmailError: (state, action) => {
      state.error = action.payload;
    },
    setOnEdit: (state, action) => {
      state.onEdit = action.payload;
    },
    setEnquiriesAndSubEnquiries: (state, action) => {
      state.enquiryAndSubEnquiries = action.payload;
    },
    setFbLeadAdsForms: (state, action) => {
      state.fbLeadAdsForms = action.payload;
    },
    setAllFbLeadsFormFields: (state, action) => {
      state.allFbLeadsFormFields = action.payload;
    },
    setWebformYourForms: (state, action) => {
      state.webformYourForms = action.payload;
    },
    setAllWebformFormFields: (state, action) => {
      state.allWebformFormFields = action.payload;
    },
    setIsPreview: (state, action) => {
      state.isPreview = action.payload;
    }
  }
});

export const {
  setTypeofAutomation,
  setConnectedChannels,
  setAvaliableAutoamtions,
  setEmailVariable,
  setEmailData,
  setEmailError,
  setOnEdit,
  setEnquiriesAndSubEnquiries,
  setFbLeadAdsForms,
  setAllFbLeadsFormFields,
  setWebformYourForms,
  setAllWebformFormFields,
  setIsPreview
} = automationStuff.actions;

export const getConnectedChannel = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_CONNECTED_CHANNEL_API, ['channelId'], [channelId]))
        .then(({ data }) => {
          const { results } = data;
          // console.log(results)
          dispatch(setConnectedChannels(results));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFbLeadsAdsConnectedChannels = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_FB_LEAD_ADS_CONNECTED_CHANNELS,
            ['channelId'],
            [channelId]
          )
        )
        .then(({ data }) => {
          // console.log(results)
          dispatch(setConnectedChannels(data));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendAutomation = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { data, type } = payload;
      const { channelId } = data;
      if (type === 'post') {
        new APIRequest().post(SEND_AUTOMATION, data).then(({ data }) => {
          dispatch(setTypeofAutomation(''));
          dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
        });
      } else if (type === 'get') {
        new APIRequest()
          .get(replaceUrl(GET_AUTOMATIONS, ['channelId'], [channelId]))
          .then((resp) => {
            if (resp.status === 200) {
              const { data } = resp;
              dispatch(setAvaliableAutoamtions(data));
              if (callback) {
                callback();
              }
            }
          });
      } else if (type === 'put') {
        new APIRequest()
          .put(
            replaceUrl(UPDATE_AUTOMATIONS, ['automationId'], [data?.id]),
            data
          )
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      }
    } catch (e) {
      console.log(e);
      if (callback) {
        callback();
      }
    }
  };
};

export const sendFacebookAutomationAutoReply = (payload) => {
  return async (dispatch) => {
    try {
      const { data, type } = payload;

      // const { channelId } = data;
      if (type === 'post') {
        new APIRequest()
          .post(SEND_FACEBOOK_AUTOMATION_AUTO_REPLY, data)
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      } else if (type === 'put') {
        const { automation_name, message, fb_raw_message } = data;
        payload = {
          automation_name: automation_name,
          message: message,
          fb_raw_message: fb_raw_message
        };
        new APIRequest()
          .put(
            replaceUrl(
              UPADTE_FACEBOOK_AUTOMATION_AUTO_REPLY,
              ['automationId'],
              [data?.id]
            ),
            payload
          )
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendFacebookAutomationAssignUser = (payload) => {
  return async (dispatch) => {
    try {
      const { data, type } = payload;
      if (type === 'post') {
        new APIRequest()
          .post(SEND_FACEBOOK_AUTOMATION_ASSIGN_USER, data)
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      } else if (type === 'put') {
        const { automation_name, assign_user } = data;
        payload = {
          automation_name: automation_name,
          assign_user: assign_user
        };
        new APIRequest()
          .put(
            replaceUrl(
              UPDATE_FACEBOOK_AUTOMATION_ASSIGN_USER,
              ['automationId'],
              [data?.id]
            ),
            payload
          )
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendFacebookLeadAdsAutomationAutoReply = (payload) => {
  return async (dispatch) => {
    try {
      const { data, type, automationId } = payload;

      // const { channelId } = data;
      if (type === 'post') {
        new APIRequest()
          .post(SEND_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY, data)
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      } else if (type === 'put') {
        const { automation_name, message } = data;
        payload = {
          automation_name: automation_name,
          message: message
        };
        new APIRequest()
          .put(
            replaceUrl(
              UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_AUTO_REPLY,
              ['automationId'],
              [automationId]
            ),
            data
          )
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const sendFacebookLeadAdsAutomationAssignUser = (payload) => {
  return async (dispatch) => {
    try {
      const { data, type } = payload;

      if (type === 'post') {
        new APIRequest()
          .post(SEND_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER, data)
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      } else if (type === 'put') {
        const { automation_name, assign_user } = data;
        payload = {
          automation_name: automation_name,
          assign_user: assign_user
        };
        new APIRequest()
          .put(
            replaceUrl(
              UPADTE_FACEBOOK_LEAD_ADS_AUTOMATION_ASSIGN_USER,
              ['automationId'],
              [data?.id]
            ),
            payload
          )
          .then(({ data }) => {
            dispatch(setTypeofAutomation(''));
            dispatch(setEmailData({ send_from: '', subject: '', message: '' }));
          });
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteUploadedFile = (payload) => {
  return async (dispatch) => {
    try {
      const { fileId } = payload;
      new APIRequest()
        .delete(replaceUrl(AUTOMATION_FILE_UPLOAD_DELETE, 'fileId', fileId))
        .then(({ data }) => {
          return data;
        });
    } catch (e) {
      return e;
    }
  };
};

export const getAllEnquiryAndSubEnquiry = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            GET_ALL_THE_ENQUIRY_AND_SUB_ENQUIRY_TYPE,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then(({ data }) => {
          dispatch(
            setEnquiriesAndSubEnquiries(
              data?.enquiry_type_with_sub_enquiry_types
            )
          );
        });
    } catch (e) {
      return e;
    }
  };
};

export const deleteChannelAutomation = (payload) => {
  return async (dispatch) => {
    try {
      const { automationId, callback } = payload;
      new APIRequest()
        .delete(
          replaceUrl(DELETE_CHANNEL_AUTOMATION, 'automationId', automationId)
        )
        .then((res) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFacebookLeadAdsForms = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_FB_LEAD_ADS_FORMS, 'channelId', channelId))
        .then((res) => {
          dispatch(setFbLeadAdsForms(res.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updatefbLeadAdsFormStatus = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId, formId, is_connected, callback } = payload;
      const data = {
        is_connected: is_connected
      };
      new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_FB_LEAD_ADS_FORM_STATUS,
            ['channelId', 'formId'],
            [channelId, formId]
          ),
          data
        )
        .then((res) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const hideGuideForFbLeadAds = (payload) => {
  return async (dispatch) => {
    try {
      const { userId, isHideGuide, callback } = payload;
      const data = {
        is_fb_lead_ads_setup_guide_viewed: isHideGuide
      };
      new APIRequest()
        .patch(replaceUrl(HIDE_GUIDE_FOR_FB_LEADS, 'userId', userId), data)
        .then((res) => {
          if (callback) {
            callback(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const syncNewFbLeadAdsForm = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, channelId, callback } = payload;
      new APIRequest()
        .get(
          replaceUrl(
            SYNC_NEW_FB_LEAD_ADS,
            ['subOrgId', 'channelId'],
            [subOrgId, channelId]
          )
        )
        .then((resp) => {
          dispatch(setFbLeadAdsForms(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deletefbLeadAdsFormMapping = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId, formId, isDeleteForm, callback, mapping_format } =
        payload;
      const data = {
        is_this_form_mapped_to_lead_fields: isDeleteForm,
        mapping_format: mapping_format
      };
      new APIRequest()
        .patch(
          replaceUrl(
            UPDATE_FB_LEAD_ADS_FORM_STATUS,
            ['channelId', 'formId'],
            [channelId, formId]
          ),
          data
        )
        .then((res) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllFbLeadsMappingFields = (payload) => {
  return async (dispatch) => {
    try {
      const { formId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ALL_FB_LEADS_MAPPING_FIELDS, 'formId', formId))
        .then((resp) => {
          dispatch(setAllFbLeadsFormFields(resp.data));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const editFbLeadAdsMapping = (payload, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { formId, data } = payload;
      new APIRequest()
        .post(replaceUrl(EDIT_FB_LEAD_ADS_MAPPING, 'formId', formId), data)
        .then((resp) => {
          if (onSuccess) onSuccess(resp);
        })
        .catch((err) => {
          if (onError) onError(err);
        });
    } catch (e) {
      console.log('here');
      if (onError) onError(e);
    }
  };
};

export const getAllWebformForms = (payload) => {
  return async (dispatch) => {
    try {
      const { channelId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_ALL_WEBFORM_YOUR_FORMS, 'channelId', channelId))
        .then((resp) => {
          dispatch(setWebformYourForms(resp.data));
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFormMappingData = (payload) => {
  return async (dispatch) => {
    try {
      const { formId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_WEBFORM_MAPPING_DATA, 'formId', formId))
        .then((resp) => {
          dispatch(setAllWebformFormFields(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteWebformMapping = (payload) => {
  return async (dispatch) => {
    try {
      const { subOrgId, formId, channelId, callback } = payload;
      new APIRequest()
        .delete(
          replaceUrl(
            DELETE_WEBFORM_FORM_DATA,
            ['subOrgId', 'channelId', 'formId'],
            [subOrgId, channelId, formId]
          )
        )
        .then((res) => {
          dispatch(setAllWebformFormFields([]));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const editWebformMappingFields = (payload, onSuccess, onError) => {
  return async (dispatch) => {
    try {
      const { formId, body } = payload;
      new APIRequest()
        .put(replaceUrl(EDIT_WEBFORM_MAPPED_DATA, 'formId', formId), body)
        .then((resp) => {
          if (onSuccess) {
            onSuccess();
          }
        });
    } catch (err) {
      if (onError) {
        onError(err);
      }
    }
  };
};

export const hideGuideForWebforms = (payload) => {
  return async (dispatch) => {
    try {
      const { isHideGuide, callback } = payload;
      const data = {
        hide_web_form_message: isHideGuide
      };
      new APIRequest().post(HIDE_GUIDE_FOR_WEBFORMS, data).then((res) => {
        if (callback) {
          callback(res.data);
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export default automationStuff.reducer;

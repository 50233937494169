import React from 'react';
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus-green.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import PropTypes from 'prop-types';

const EmptyState = (props) => {
  const { onClick, isBin } = props;
  return (
    <div className="mt-6 border border-grey-400 border-radius-14 text-center py-10">
      <h3 className="font-semibold text-lg">Questions</h3>
      <div className="text-15 font-medium mt-3">
        <p>Create a question to store additional</p>
        <p>information you collected about your contacts</p>
      </div>
      <div className="flex justify-center mt-7">
        <CustomColorButtonWithLogo
          label="Create a question"
          className="bg-grey-90 gap-2 text-primary text-15 btn--wide-md"
          style={{ minWidth: 250 }}
          renderSvg={() => <IconPlus />}
          onClick={isBin ? () => {} : onClick}
        />
      </div>
    </div>
  );
};

EmptyState.propTypes = {
  onClick: PropTypes.func
};

export default EmptyState;

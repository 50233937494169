/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes, { array, func, string } from 'prop-types';
import Modal from 'components/modal';
import FieldListItem from '../webformMapFields/components/fieldListItem';
import Select from 'components/inputs/select';
import ListHeader from 'modules/enquirybox/channels/components/layout/listHeader';
import ErrorMessage from 'components/errorMessage';
import CustomColorButton from 'components/buttons/customColorButton';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object } from 'yup';
import { handleScroll, handleScrollIntoview, isEmpty } from 'utils/utils';
import { requiredSelectMsg, requiredMsg } from 'utils/messages';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch } from 'react-redux';
import {
  getAllUploadedLeadsCount,
  getFileUploadHistory,
  sendMappedFields,
  setMappingFieldsForCsvMapping,
  setMappingFieldsFromCsv
} from 'slices/boxSettings/uploadContactsSlice';
import closeIcon from 'assets/icons/icon-close-red.svg';
import { useSnackbar } from 'components/Snackbar';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSelector } from 'react-redux';
import { useAnalytics } from 'use-analytics';
import { getAllCounts } from 'slices/allCount/slice';
import { getMyInfo } from 'slices/myInfoSlice';
import usePlanAccess from 'hooks/usePlanAccess';
import { getFreeEnquiriesCount } from 'slices/subscription/slice';
import {
  ErrorMessagePopUp,
  ErrorPopUpBackdrop
} from '../webformMapFields/components/errorPopup';

const MappingFieldsModal = (props) => {
  const {
    leftSideFieldsHeadder,
    rightSideFieldsHeadder,
    leftSideFields,
    rightSideFields,
    submitBtnLabel,
    submitBtnClassName,
    showMapFields,
    onClose,
    setShowMapStatusModal,
    boxId,
    setShowMapFields,
    uploadedFiles,
    uploadedCount,
    setUploadedCount,
    setUploadedFiles
  } = props;
  const [errorPopUp, setErrorPopUp] = useState(false);
  const nodeRef = useRef();
  const parentRef = useRef();
  const { openErrorSnackBar } = useSnackbar();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();
  const { is_free_plan } = usePlanAccess();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  //replacing mapping feild key with label
  const newRightSideMappingFields = rightSideFields.map(
    ({ key: value, ...rest }) => ({
      value,
      ...rest
    })
  );

  const dispatch = useDispatch();
  const keysleftSideFields = leftSideFields?.map((item) => {
    return item?.key;
  });

  const selectFormSchema = keysleftSideFields?.reduce((acc, field) => {
    return {
      ...acc,
      [field]: object().required(requiredSelectMsg(field))
    };
  }, {});

  const defaultValues = keysleftSideFields?.reduce((acc, field) => {
    return {
      ...acc,
      [field]: ''
    };
  }, {});

  const [formSchema, setFormSchema] = useState({
    ...selectFormSchema
  });

  const [, setOpenDropdown] = useState(false);

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: { ...defaultValues },
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    let isx = false;
    const empatyCheck = keysleftSideFields.forEach((item) => {
      if (isEmpty(getValues(item))) {
        isx = true;
      }
    });
    if (isx) {
      setErrorPopUp(true);
      return;
    }

    setIsLoading(true);
    const finalMappedData = Object.keys(data).map((item) => {
      return { lead_key: item, csv_field: data[item]?.value };
    });
    const formData = {
      mapping_format: finalMappedData
    };
    dispatch(sendMappedFields(formData, onSuccess, onError));
  };

  const onSuccess = (data) => {
    if (data?.message) {
      track(
        'box_contact_upload',
        {
          number: uploadedCount
        },
        {
          groupId: subOrganizationId
        }
      );
    }
    setUploadedCount(0);
    dispatch(getFileUploadHistory({ box_id: boxId }));
    dispatch(getAllUploadedLeadsCount({ box_id: boxId }));
    reset();
    setIsLoading(false);
    dispatch(setMappingFieldsFromCsv([]));
    dispatch(setMappingFieldsForCsvMapping([]));
    keysleftSideFields.forEach((element) => {
      setValue(element, '');
    });
    setShowMapStatusModal(true);
    setShowMapFields(false);
    if (is_free_plan) {
      dispatch(getFreeEnquiriesCount());
    }
    dispatch(getAllCounts());
    setUploadedFiles([]);
  };

  const onError = (e) => {
    openErrorSnackBar(e?.response?.data?.error);
    dispatch(setMappingFieldsFromCsv([]));
    dispatch(setMappingFieldsForCsvMapping([]));
    keysleftSideFields.forEach((element) => {
      setValue(element, '');
    });
    if (is_free_plan) {
      dispatch(getFreeEnquiriesCount());
    }
    setFormSchema({});
    setIsLoading(false);
    setShowMapFields(false);
    setUploadedFiles([]);
  };

  useEffect(() => {
    if (!isEmpty(errors)) {
      setErrorPopUp(true);
    }
  }, [errors]);

  return (
    <Modal.Main
      open={showMapFields}
      className="modal--match-fields"
      containerStyle={{ height: '100%' }}
    >
      <Modal.Header title="Map your fields" onClose={onClose} />
      <ErrorMessagePopUp
        show={errorPopUp}
        onClose={() => setErrorPopUp(false)}
        message="Please match all imported fields to enquirybox questions. Unmatched fields are marked in red"
      />
      {isEmpty(leftSideFields) && <OverLayLoader />}
      <Modal.Body
        className={`px-0 pt-0 pb-8 relative`}
        style={{ height: '100%' }}
      >
        {errorPopUp && <ErrorPopUpBackdrop />}
        <form
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
          style={{ height: '100%' }}
        >
          <div
            className="overflow-y-auto scroll-smooth pt-3 pl-2 pr-7"
            ref={parentRef}
            style={{ height: 'calc(100% - 50px)' }}
          >
            <div style={{ maxHeight: 300 }}>
              <div className="flex">
                <ListHeader
                  title={leftSideFieldsHeadder}
                  className="w-3/6 font-semibold"
                />
                <ListHeader
                  title={rightSideFieldsHeadder}
                  className="w-3/6 pl-3 font-semibold"
                />
              </div>
              {!isEmpty(leftSideFields) &&
                leftSideFields.map((item, index) => {
                  return (
                    <div className="flex mt-4 gap-5" key={index}>
                      <FieldListItem name={item?.key} />
                      <div
                        className="relative w-[50%] min-w-[50%] pb-[10px]"
                        ref={nodeRef}
                        onClick={() =>
                          handleScrollIntoview(`dropDownId-${index}`, 'start')
                        }
                      >
                        <Select
                          id={`dropDownId-${index}`}
                          inputClassName={
                            isEmpty(errors[item?.key]) &&
                            !isEmpty(getValues(item?.key))
                              ? 'input-field--selected'
                              : 'input-field--md'
                          }
                          placeholder="Select a field"
                          options={
                            (item.key === 'stage'
                              ? newRightSideMappingFields.filter((field) => {
                                  return field.label !== 'Do not require';
                                })
                              : newRightSideMappingFields) || []
                          }
                          name={item?.key}
                          control={control}
                          setValue={setValue}
                          error={!isEmpty(errors[item?.key])}
                          showGreenBorder={
                            isEmpty(errors[item?.key]) &&
                            !isEmpty(getValues(item?.key))
                          }
                          required
                          register={register}
                          dropDownClassName="dropdown-menu__map-fields"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="flex items-center justify-end gap-3 px-12 pr-2 pt-6">
            <CustomColorButton
              type="button"
              label="Cancel"
              className="btn--secondary btn--md min-w-120"
              onClick={() => onClose()}
            />
            <CustomColorButton
              type="submit"
              label={submitBtnLabel}
              className={`btn--green btn--md btn--wide-20 min-w-120 ${submitBtnClassName}`}
              loaderInline={true}
              isLoading={isLoading}
              enableLoader={isLoading}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

MappingFieldsModal.propTypes = {
  showMapFields: PropTypes.bool,
  onClose: PropTypes.func,
  leftSideFields: array,
  rightSideFields: array,
  rightSideFieldsHeadder: string,
  leftSideFieldsHeadder: string,
  submitBtnLabel: string
};

MappingFieldsModal.defaultProps = {
  leftSideFields: [],
  rightSideFields: [],
  rightSideFieldsHeadder: '',
  leftSideFieldsHeadder: '',
  submitBtnLabel: 'Submit'
};

export default MappingFieldsModal;

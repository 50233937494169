import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { addWebsiteLink } from 'slices/ai-assistant/websiteLinks/slice';
import { useParams } from 'react-router-dom';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import InputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import PrimaryButton from 'components/buttons/primary';
import { useSnackbar } from 'components/Snackbar';

const AddWebsiteLink = () => {
  const { assistant_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const dispatch = useDispatch();

  const formSchema = {
    url: string().matches(urlFieldValidation, 'Please provide a valid URL link')
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const successCb = () => {
    setIsLoading(false);
    openSuccessSnackBar('Link added');
  };

  const failedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('Link upload failed');
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const { url } = data;
    const payload = { websiteLink: url, assistant_id: assistant_id };
    dispatch(addWebsiteLink(payload, successCb, failedCb));
    reset();
    document.activeElement.blur();
  };

  return (
    <div
      className="border-b border-grey-400"
      style={{ padding: '28px 38px 40px' }}
    >
      <label className="input-field-label mb-0">
        Import all page on your website or a single page
      </label>
      <p className="text-grey-800 mt-3">
        To import all pages of your website Paste the root domain without
        anything after it. For example (https://mywebsite.com rather than
        https://mywebsite.com/home). If you only want to import a single page
        only paste the link to that page. For example
        (https://mywebsite.com/pricing)
      </p>
      <form
        className="flex items-center mt-9"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="w-full">
          <InputField
            autoComplete={'off'}
            type="text"
            id="url"
            name="url"
            placeholder="Paste your website URL"
            className="input-field--h42"
            register={register}
            error={errors.url && touchedFields.url}
          >
            {errors.url && touchedFields.url && (
              <div className="absolute">
                <ErrorMessage message={errors?.url?.message} />
              </div>
            )}
          </InputField>
        </div>
        <PrimaryButton
          type="submit"
          label="Import"
          className="btn btn--primary btn--md min-w-120 ml-2"
          isLoading={isLoading}
          enableLoader={true}
          loaderInline={true}
        />
      </form>
    </div>
  );
};

export default AddWebsiteLink;

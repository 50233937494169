import React from 'react';
import { ReactComponent as AutomateIcon } from 'assets/icons/icon-automated-email-icon.svg';
import CHOICES from 'choices';
import TooltipContainer from '../../../components/tooltipContainer';
import { timeFormatter } from 'utils/utils';

const { DELIVERED, SENT, QUEUED, FAILED } = CHOICES.MessageStatus;

const SmsMessageContainer = ({
  item,
  isReplyRecieved,
  isReplySent,
  index,
  conversation,
  showInboxActions,
  id
}) => {
  const isShowReplyRecievedAvatar = isReplyRecieved;
  const isShowReplySentAvatar = isReplySent;

  const convertMessage = (message) => {
    if (message) {
      return message.replaceAll(/\n/g, '<br />');
    }
    return '';
  };

  const MessageTime = ({
    time,
    isShowReplyRecievedAvatar,
    isShowReplySentAvatar
  }) => {
    return (
      <div
        className={`${isReplySent ? 'flex justify-end items-center' : ''}`}
        id={id}
      >
        {item.sent_by_automation && (
          <div
            style={{ marginTop: 4 }}
            className="flex items-center gap-2 mr-2"
          >
            <AutomateIcon style={{ marginTop: 2 }} />
            <div className="text-xs text-grey-700">Automated Message</div>
            <code
              style={{
                background: '#C4C2C0',
                width: '3.5px',
                height: '3.5px',
                borderRadius: '50%',
                marginTop: '0px'
              }}
            ></code>
          </div>
        )}
        {isReplySent && (
          <div className="flex items-center mr-1">
            <span className="text-xs text-grey-700 mt-1 mr-1">
              {item.message_status === SENT
                ? 'Sent'
                : item.message_status === QUEUED
                ? 'Queued'
                : item.message_status === DELIVERED
                ? 'Delivered'
                : item.message_status === FAILED
                ? 'Failed'
                : ''}
            </span>
            <code
              style={{
                background: '#C4C2C0',
                width: '3.5px',
                height: '3.5px',
                borderRadius: '50%',
                marginTop: '4px',
                display: 'block'
              }}
            ></code>
          </div>
        )}
        <div
          className={`text-xs text-grey-700  ${
            isReplyRecieved && !isShowReplyRecievedAvatar && index !== 0
              ? 'ml-8'
              : ''
          } ${
            isReplySent && !isShowReplySentAvatar && index !== 0 ? 'mr-8' : ''
          }`}
          style={
            isReplySent
              ? {
                  marginTop: 4,
                  display: 'flex',
                  justifyContent: 'flex-end'
                }
              : {}
          }
        >
          {time}
        </div>
      </div>
    );
  };

  const Message = ({
    message,
    isShowReplyRecievedAvatar,
    isShowReplySentAvatar,
    index
  }) => (
    <div className="flex justify-end">
      <div
        className={`inline-block text-base-1 text-grey-900 border border-grey-400 ${
          isReplyRecieved
            ? 'bg-grey-90'
            : item.sent_by_automation
            ? 'bg-automation'
            : 'bg-automation'
        } ${
          isReplyRecieved && !isShowReplyRecievedAvatar && index !== 0
            ? 'ml-8'
            : ''
        } ${
          isReplySent && !isShowReplySentAvatar && index !== 0 ? 'mr-8' : ''
        }`}
        style={{
          borderRadius: 10,
          paddingTop: 6,
          paddingBottom: 6,
          paddingLeft: 15,
          paddingRight: 15
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </div>
    </div>
  );

  return (
    <div
      className={`chat-message-list flex ${
        isReplySent ? 'chat-message-list--sender ml-10-percent' : ''
      } ${isShowReplyRecievedAvatar}`}
      style={{
        marginBottom:
          showInboxActions && index === conversation?.length - 1
            ? '80px'
            : '25px'
      }}
    >
      <TooltipContainer
        automatedMessage={item?.sent_by_automation ? 'Automated Message' : ''}
        user={
          item?.sent_by_automation
            ? {}
            : item?.is_reply_sent
            ? item?.message_sent_by
            : item.lead
        }
        position={item?.is_reply_sent ? 'left' : 'right'}
      />
      <div
        style={
          isReplyRecieved
            ? { marginLeft: 6 }
            : isReplySent
            ? { marginRight: 6 }
            : {}
        }
      >
        <Message
          message={convertMessage(item.message)}
          isShowReplyRecievedAvatar={isShowReplyRecievedAvatar}
          isShowReplySentAvatar={isShowReplySentAvatar}
          index={index}
        />
        <MessageTime
          time={timeFormatter(item?.created_at)}
          isShowReplyRecievedAvatar={isShowReplyRecievedAvatar}
          isShowReplySentAvatar={isShowReplySentAvatar}
        />
      </div>
    </div>
  );
};

export default SmsMessageContainer;

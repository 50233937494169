import Avatar from 'components/avatar';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
import { ReactComponent as UserIcon } from 'assets/icons/icon-team-chat-users.svg';

const UserChat = () => {
  const { userId } = useParams();
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const [user, setUser] = useState({});

  useEffect(() => {
    if (!isEmpty(organizationUser) && userId) {
      const selectedUser = organizationUser?.results.filter(
        (item) => item.user_id === userId
      );
      setUser(selectedUser[0]);
    }
  }, [userId, organizationUser]);

  return (
    <section className="dashboard__main">
      <div
        className="dashboard__main__header"
        style={{ padding: '27px 38px 21px 41px' }}
      >
        <div className="flex items-center gap-4">
          <Avatar
            src={user?.avatar}
            isOnline={true}
            initial={user?.name}
            singleChar
            initialStyle={{ fontSize: 24 }}
            style={{ width: 50, height: 50 }}
            activeIconStyle={{ width: '19px', height: '19px' }}
          />
          <h2 className="font-semibold text-xl">{user?.name}</h2>
        </div>
      </div>
      <div className="flex justify-center h-full" style={{ marginTop: '25vh' }}>
        <div className="text-center">
          <UserIcon width={119} height={42} className="m-auto" />
          <h2 className="mt-6 text-xl font-semibold">
            Team Chat is Coming Soon!
          </h2>
          <p className="text-15 text-center mt-6">
            Say goodbye to email chaos and hello to seamless communication with
            our team chat feature! <br />
            Chat in real-time, collaborate efficiently, stay organised, and
            resolve enquiries faster - all in one place!
          </p>
        </div>
      </div>
    </section>
  );
};

export default UserChat;

import React from 'react';
import HeaderLess from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import iconCheckConfirmation from 'assets/icons/icon-check-confirmation-msg.svg';
import PropTypes from 'prop-types';

const ResetLinkSent = (props) => {
  const { isResetPassword } = props;
  return (
    <HeaderLess>
      <CardLayout>
        <div className="text-center">
          <img src={iconCheckConfirmation} alt="" className="m-auto" />
          <h2 className="text-2xl font-semibold mt-8">{`${
            isResetPassword ? 'Reset' : 'Confirm'
          } link sent`}</h2>
          <p className="text-sm mt-2">
            {`Check your email for the ${
              isResetPassword ? 'reset password' : 'confirm account'
            } link`}
          </p>
          <p className="text-13 font-medium mt-10 ">
            {`If you don’t see your ${
              isResetPassword ? 'reset email' : 'confirm email'
            } check your spam for an email from`}{' '}
            <span className="text-primary">notifications@enquirybox.io</span>
          </p>
        </div>
      </CardLayout>
    </HeaderLess>
  );
};

ResetLinkSent.propTypes = {
  isResetPassword: PropTypes.bool
};

ResetLinkSent.defaultProps = {
  isResetPassword: false
};

export default ResetLinkSent;

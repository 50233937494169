import React from 'react';
//choose positions between "top", "left", "bottom", "right"

const CustomToolTip = ({
  children,
  position = 'top',
  tooltipText = 'tooltipText',
  tooltipStyle = {},
  img = false,
  hideTooltip = false,
  renderAvatar,
  style
}) => {
  return (
    <div className="custom-tooltip" style={style}>
      {children}
      {!hideTooltip && (img || renderAvatar) && (
        <div
          className={`flex justify-between items-center h-10 tooltiptext w-f-max tooltiptext__${position} `}
          style={tooltipStyle}
        >
          {renderAvatar && renderAvatar()}
          {!renderAvatar && (
            <img src={img} alt="user" className="" style={{ width: 30 }} />
          )}
          <p className=" flex justify-center text-center text-13 p-1 self-center text-white">
            {tooltipText}
          </p>
        </div>
      )}
      {!hideTooltip && !(img || renderAvatar) && (
        <p
          className={`tooltiptext tooltiptext__${position} text-13 text-grey-600`}
          style={tooltipStyle}
        >
          {tooltipText}
        </p>
      )}
    </div>
  );
};

export default CustomToolTip;

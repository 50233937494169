/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllConnectedChannels } from 'slices/channelsSlice';
import AssignedTo from './actions/assignedTo';
// import Channels from './actions/channels';
import {
  setActiveTab,
  setAssignedTo,
  setIsFilterByChannel,
  setAssignedToCount,
  setChannelId,
  setChannel
} from 'slices/leadsFilter';
import { ALL, ENQUIRIES, TASK } from 'utils/constants';
import { numberFormat } from 'utils/number';
import {
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads,
  getLeadTasks,
  setShowContactModal,
  setPreviousClickedLead,
  setPreviousClickedLeadList
} from 'slices/eboxLead';
import queryString from 'query-string';
import { setHover } from 'slices/conversationSlice';
import { getAllTasks, getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';
import Count from 'components/count';

const EnquiryHeader = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { previousClickedLeadList, previousClickedLead } = useSelector(
    (state) => state.leads
  );
  const { activeTab } = useSelector((state) => state.leadsFilter);
  const { things_to_do_count } = useSelector((state) => state.taskThingsToDo);
  const navigate = useNavigate();

  // const getConnectedChannels = (subOrgId) =>
  //   dispatch(getAllConnectedChannels({ subOrgId }));

  // useEffect(() => {
  //   getConnectedChannels(subOrganizationId);
  // }, []);

  // to check if this is from coming search box using search key in query string
  // resetting the the filter store
  useEffect(() => {
    if (queryParams.search) {
      resetToAll();
    }
  }, [queryParams.search]);

  const resetToAll = () => {
    dispatch(setActiveTab(ENQUIRIES));
    dispatch(setAssignedTo(null));
    dispatch(setAssignedToCount(0));
    dispatch(setIsFilterByChannel(true));
    dispatch(setChannel(null));
    dispatch(setChannelId(null));

    // reset modal
    dispatch(setHover(false));
    dispatch(setShowContactModal(false));
    Promise.all([
      dispatch(getReadLeads()),
      dispatch(getUnReadLeads()),
      dispatch(getReplyLaterLeads())
    ]).then(() => {
      dispatch(setIsFilterByChannel(false));
    });
  };

  const loadAllLeads = () => {
    resetRoute();
    dispatch(setIsFilterByChannel(true));
    Promise.all([
      dispatch(getReadLeads()),
      dispatch(getUnReadLeads()),
      dispatch(getReplyLaterLeads())
    ]).then(() => {
      dispatch(setIsFilterByChannel(false));
    });
  };

  const resetRoute = () => {
    // reset modal
    dispatch(setHover(false));
    dispatch(setShowContactModal(false));
    navigate('/dashboard/enquirybox/eb-box');
  };

  return (
    <div className="flex dashboard__main__body__section-left__header">
      <div className="tabs-wrapper">
        <div
          className={`tabs-header ${
            activeTab === ENQUIRIES ? 'tabs-header__active' : ''
          }`}
          style={{ paddingBottom: 13, paddingTop: 5, fontSize: 14 }}
          onClick={() => {
            resetRoute();
            dispatch(setActiveTab(ENQUIRIES));
            // dispatch(setAssignedTo(null));
            // dispatch(setAssignedToCount(0));
            // loadAllLeads();
          }}
        >
          <p
            className={
              activeTab === ENQUIRIES
                ? 'text-primary'
                : 'text-grey-900 font-semibold'
            }
          >
            Enquiries
          </p>
          {things_to_do_count?.enquiry_count > 0 && (
            <Count
              rounded
              label={numberFormat(things_to_do_count?.enquiry_count)}
              style={{
                background: '#005C6F17',
                color: '#005C6F',
                marginLeft: 5,
                borderRadius: 7
              }}
            />
          )}
        </div>
        <div
          className={`tabs-header ${
            activeTab === TASK ? 'tabs-header__active' : ''
          }`}
          style={{ paddingBottom: 13, paddingTop: 5, fontSize: 14 }}
          onClick={() => {
            resetRoute();
            dispatch(setActiveTab(TASK));
            // dispatch(setAssignedTo(null));
            // dispatch(setAssignedToCount(0));
            // loadAllLeads();
            // dispatch(getLeadTasks());
            // dispatch(getAllTasks());
            dispatch(getTaskEnquiryCount());
            // dispatch(getTaskEnquiryCount())
            // setTimeout(() => {
            //   dispatch(setPreviousClickedLeadList(0))
            //   dispatch(setPreviousClickedLead(null))
            // }, 100)
          }}
        >
          <p
            className={
              activeTab === TASK
                ? 'text-primary'
                : 'text-grey-900 font-semibold'
            }
          >
            Tasks
          </p>
          {things_to_do_count?.task_count > 0 && (
            <Count
              rounded
              label={numberFormat(things_to_do_count?.task_count)}
              style={{
                background: '#005C6F17',
                color: '#005C6F',
                marginLeft: 5,
                borderRadius: 7
              }}
            />
          )}
        </div>
        {/* <AssignedTo loadAllLeads={loadAllLeads} /> */}
      </div>
      <AssignedTo loadAllLeads={loadAllLeads} />
      {/* <Channels resetRoute={resetRoute} /> */}
    </div>
  );
};

export default EnquiryHeader;

/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

const CustomTextArea = React.forwardRef((props, ref) => {
  const {
    name,
    error,
    value,
    className,
    style,
    placeholder,
    onChange,
    register,
    required,
    ...rest
  } = props;
  const isError = error ? 'error' : '';
  return (
    <textarea
      data-testid="input-text-area"
      name={name}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      style={style}
      ref={ref}
      {...register(name, { required })}
      className={`input-field input-field__textarea ${className} ${isError}`}
      {...rest}
    />
  );
});

export default CustomTextArea;

CustomTextArea.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

CustomTextArea.defaultProps = {
  style: {},
  onChange: () => {},
  placeholder: 'Type here',
  className: '',
  register: () => {},
  required: false
  // value: ''
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import DropDownWrapper from 'components/dropdown/wrapper';
import DropDownBody from 'components/dropdown/body';
import Status from 'modules/enquirybox/ebox/module/box/components/enquiryCard/status';
import SubIcon from 'modules/enquirybox/ebox/module/box/components/enquiryCard/subIcon';
import Avatar from 'components/avatar';
import { NavLink, useParams } from 'react-router-dom';
import { route } from 'utils/route';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import { getAllNewBoxesEnquires } from 'slices/boxesNewEnquiresSlice';
import OverLayLoader from 'components/loader/overLayLoader';
import CHOICES from 'choices';
import { getColorShade } from 'utils/colors';
import {
  getLastReply,
  getLeadName,
  getLeadTime
} from 'modules/enquirybox/helper/lead';

const NEW = CHOICES.LeadStatusChoices.NEW;

const RESTORED = CHOICES.LeadStatusChoices.RESTORED;

const NewEnquiriesList = (props) => {
  const { open, onClose, handleOpenNewEnquiry } = props;
  const [isLoading, setIsLoading] = useState();
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const { box_id, box_type } = useParams();
  const { boxesNewEnquires } = useSelector((state) => state.boxesNewEnquires);
  const selectedLeadPath = Object.values(useParams())[0];

  const onSuccess = () => {
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      dispatch(getAllNewBoxesEnquires({ boxId: box_id }, onSuccess, onError));
    }, 300);
  }, [box_id, box_type]);

  useClickOutside(() => onClose(), dropdownRef.current);

  const RenderOption = (props) => {
    const { isRestored, isNew, lead, index } = props;
    const {
      id,
      name,
      first_name,
      customer_avatar,
      status,
      channel,
      unseen_reply_count,
      last_reply,
      box_stage,
      last_name
    } = lead;

    return (
      <NavLink
        className={`box-card ${isNew ? 'box-card--new' : ''} mb-3 relative`}
        to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${box_stage}/${channel}/${id}`}
        activeclassname="active"
        style={
          isRestored
            ? {
                background: 'rgba(245, 94, 94, 0.1)',
                border: '1px solid #f55e5e'
              }
            : {}
        }
        onClick={onClose}
      >
        <div className="flex items-start gap-4 flex-1 truncate">
          <div className="relative">
            <Avatar
              src={customer_avatar}
              initial={name || first_name}
              style={{
                width: 48,
                height: 48,
                backgroundColor: customer_avatar ? '' : getColorShade(index)
              }}
              large
              cover
              singleChar
            />
            <div
              className="bg-white absolute flex justify-center items-center"
              style={{
                width: 20,
                height: 20,
                borderRadius: '50%',
                bottom: '-3px',
                left: '28px'
              }}
            >
              <SubIcon kind={channel} />
            </div>
          </div>
          <div className="truncate w-full">
            <div className="box-card__title">{getLeadName(lead)}</div>
            <div
              className="box-card__msg mt-1"
              dangerouslySetInnerHTML={{ __html: getLastReply(lead) }}
            />
            {/* <RenderQuestions /> */}
          </div>
        </div>
        <div className="self-baseline">
          {isRestored && (
            <label
              className="notification-label notification-label--new-msg"
              style={{
                maxWidth: '100%',
                padding: '2px 6px',
                background: '#F1511B'
              }}
            >
              Restored
            </label>
          )}
          <Status
            isReplied={last_reply?.is_reply_received}
            replyCount={unseen_reply_count}
            status={status}
            style={{ padding: '1px 7px' }}
          />
          <p
            className="box-card__time"
            style={{ position: 'absolute', bottom: '13px', right: '11px' }}
          >
            {getLeadTime(lead)}
          </p>
        </div>
      </NavLink>
    );
  };

  return (
    <DropDownWrapper
      open={open}
      className="dropdown-menu--with-border mt-3"
      dropDownref={dropdownRef}
      style={{
        right: 'auto',
        maxWidth: 415,
        padding: '16px 12px 4px 16px',
        background: '#FAF9F8',
        border: '1px solid #E6E4E1',
        boxShadow:
          '0px -2px 4px -2px rgba(85, 83, 80, 0.04), 0px 12px 30px -4px rgba(85, 83, 80, 0.25)',
        maxHeight: 470,
        left: -200
      }}
    >
      {isLoading && <OverLayLoader />}
      <DropDownBody
        open={open}
        className="overflow-y-auto"
        style={{ maxHeight: 445 }}
      >
        <div
          className="font-medium mr-1"
          // onClick={() => onSelect(option)}
          style={{ color: '#2B3639' }}
        >
          {boxesNewEnquires?.map((lead, index) => {
            const { status, id } = lead;
            return (
              <div key={id}>
                <RenderOption
                  isNew={status === NEW}
                  isRestored={status === RESTORED}
                  lead={lead}
                  index={index}
                />
              </div>
            );
          })}
        </div>
      </DropDownBody>
    </DropDownWrapper>
  );
};

export default NewEnquiriesList;

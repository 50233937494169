/* eslint-disable react/display-name */
import React from 'react';
import PropTypes from 'prop-types';

const TextInputField = React.forwardRef((props, ref) => {
  const {
    name,
    error,
    className,
    placeholder,
    icon,
    renderIcon,
    children,
    register,
    required,
    showSuccessBorder,
    onChange,
    selectedIcon,
    selectedIconStyle,
    iconClassName,
    readOnly,
    containerStyle,
    value,
    ...rest
  } = props;

  const isError = error ? 'error' : showSuccessBorder ? 'success' : '';

  const isIcon = icon || renderIcon ? 'input-field--icon-pos-left' : '';

  return (
    <div className="relative" style={containerStyle}>
      {selectedIcon && (
        <img src={selectedIcon} alt="selected-icon" style={selectedIconStyle} />
      )}
      <input
        ref={ref}
        data-testid="input-text-field"
        type="text"
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        className={`input-field ${isIcon} ${className} ${isError}`}
        readOnly={readOnly}
        value={value}
        {...register(name, { required })}
        {...rest}
      />
      {renderIcon && (
        <div className={`input-svg-wrapper ${iconClassName}`}>
          {renderIcon()}
        </div>
      )}
      {children}
    </div>
  );
});

TextInputField.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.bool,
  icon: PropTypes.bool,
  required: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  renderIcon: PropTypes.func,
  showSuccessBorder: PropTypes.bool,
  onChange: PropTypes.func
};

TextInputField.defaultProps = {
  placeholder: 'Name',
  icon: false,
  required: false,
  register: () => {},
  iconClassName: ''
  // onChange:() =>{}
};

export default TextInputField;

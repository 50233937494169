import React from 'react';
import TextInputField from 'components/inputs/textField';
import Select from 'components/inputs/select';
import { ReactComponent as CalenderIcon } from 'assets/icons/icon-calendar.svg';
import { formatDateTime } from 'utils/dateFormat';

const FilterSectionPublic = (props) => {
  const { startDate, endDate, date_format } = props;
  return (
    <div className="flex items-center">
      <Select
        name="day"
        options={[]}
        className="input-field--sm cursor-pointer"
        placeholder=""
        readOnly
        containerStyle={{ maxWidth: 164, marginRight: 6 }}
        dropDownClassName="p-2"
        value={
          date_format === 'This month to date' ? 'This month' : date_format
        }
        isBin={true}
      />
      <div style={{ maxWidth: 132, minWidth: 132, marginRight: 6 }}>
        <TextInputField
          name="startDate"
          placeholder={formatDateTime(new Date(), 'dd/mm/yyyy')}
          className="input-field--sm input-field--icon-pos-left--sm"
          value={startDate ? formatDateTime(startDate, 'dd/mm/yyyy') : ''}
          renderIcon={() => {
            return <CalenderIcon className="cursor-pointer" />;
          }}
          iconClassName="input-icon-left"
          style={{ paddingLeft: 0 }}
          readOnly={true}
        ></TextInputField>
      </div>
      <div style={{ maxWidth: 132, minWidth: 132 }}>
        <TextInputField
          name="endDate"
          placeholder={formatDateTime(new Date(), 'dd/mm/yyyy')}
          className="input-field--sm input-field--icon-pos-left--sm"
          value={endDate ? formatDateTime(endDate, 'dd/mm/yyyy') : ''}
          renderIcon={() => {
            return <CalenderIcon className="cursor-pointer" />;
          }}
          iconClassName="input-icon-left"
          style={{ paddingLeft: 0 }}
          readOnly={true}
        ></TextInputField>
      </div>
    </div>
  );
};

export default FilterSectionPublic;

/* eslint-disable no-unused-vars */
import React, { useState, useRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import useClickOutside from 'hooks/useOutsideClick';
import { ReactComponent as CaretPrimaryIcon } from 'assets/icons/icon-select-caret-primary.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
import { ReactComponent as CaretUpBlackIcon } from 'assets/icons/icon-select-caret-up.svg';

// const replyBy = [
//   {
//     title: 'Email'
//   }
// ];

// const replyByFacebook = [
//   {
//     title: 'Facebook'
//   }
// ];

// const replyByInsta = [
//   {
//     title: 'Instagram'
//   }
// ];

const Reply = ({
  active,
  item,
  subTab,
  setSubTab,
  // showReplyOptions,
  onClick,
  // setShowReplyOptions
  isFacebook,
  isInsta,
  isSms
}) => {
  const { text, iconActive, iconInactive } = item;
  const [showReplyOptions, setShowReplyOptions] = useState(false);
  const replyDom = useRef();
  const { is_sms_connected } = useSelector(
    (state) => state.myInfo.myInformation
  );
  const { activeLead } = useSelector((state) => state.leadConversation);
  const hasEmail = activeLead?.email ? true : false;
  const hasMobileNumber = activeLead?.phone_number ? true : false;

  useClickOutside(() => {
    setShowReplyOptions(false);
  }, replyDom.current);

  const replyOptions = useMemo(() => {
    let temp = [];

    if (isFacebook) {
      temp.push({
        title: 'Facebook'
      });
    } else if (isInsta) {
      temp.push({
        title: 'Instagram'
      });
    }

    if (hasEmail) {
      temp.push({
        title: 'Email'
      });
    }

    if (isSms || (hasMobileNumber && is_sms_connected)) {
      temp.push({
        title: 'Sms'
      });
    }
    return temp;
  }, [isFacebook, isInsta, isSms, hasEmail, hasMobileNumber, is_sms_connected]);

  return (
    <div className="relative" ref={replyDom}>
      <div
        className="relative flex items-center pointer"
        onClick={() => {
          /**
           * Should not open the dropdown when we are switching tabs
           */
          if (active) setShowReplyOptions(!showReplyOptions);
          onClick();
        }}
      >
        <img src={active ? iconActive : iconInactive} alt="icon-reply" />
        <p
          className={`${
            active ? 'text-primary font-semibold' : 'text-grey font-medium'
          } text-13 ml-2`}
        >
          {text} by {subTab}
        </p>
        {replyOptions.length !== 1 && (
          <div className="ml-1">
            <CaretBlackIcon />
          </div>
        )}
      </div>
      {replyOptions.length !== 1 && (
        <div
          className={`dropdown-menu dropdown-menu--sm ${
            showReplyOptions ? 'open' : ''
          }`}
          style={{
            width: 'max-content',
            top: 'calc(100% + 2px)',
            left: 'initial',
            right: 0
          }}
        >
          {/* {!isFacebook &&
          !isInsta &&
          replyBy.map((item, index) => {
            return (
              <div
                className="dropdown-menu__list"
                key={index}
                onClick={() => {
                  setSubTab(item.title);
                  setShowReplyOptions(false);
                }}
              >
                <p className="text-sm">Reply by {item.title}</p>
              </div>
            );
          })}
        {isFacebook &&
          replyByFacebook.map((item, index) => {
            return (
              <div
                className="dropdown-menu__list"
                key={index}
                onClick={() => {
                  setSubTab(item.title);
                  setShowReplyOptions(false);
                }}
              >
                <p className="text-sm">Reply by {item.title}</p>
              </div>
            );
          })}
        {isInsta && */}
          {replyOptions.map((item, index) => {
            return (
              <div
                className="dropdown-menu__list"
                key={index}
                onClick={() => {
                  setSubTab(item.title);
                  setShowReplyOptions(false);
                }}
              >
                <p className="text-sm">Reply by {item.title}</p>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default Reply;

import React, { useEffect, useState } from 'react';
import { ReactComponent as NoEnqSvg } from 'assets/icons/icon-channels-no-enquiry.svg';
import { ReactComponent as HandSvg } from 'assets/icons/icon-hand.svg';

import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
// import { Loader } from 'components/loader';

const ChannelsConnectedEmptyState = () => {
  const { unReadLeads, replyLaterLeads, readLeads } = useSelector(
    (state) => state.leads
  );
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      if (
        isEmpty(unReadLeads) &&
        isEmpty(replyLaterLeads) &&
        isEmpty(readLeads)
      ) {
        setIsLoading(true);
      } else {
        setIsLoading(false);
      }
    }, 100);
  }, [unReadLeads, replyLaterLeads, readLeads]);

  if (isLoading)
    return (
      <div className="dashboard__main__body__section-right chat-message-container relative">
        {/* <Loader small/> */}
      </div>
    );

  if (
    isEmpty(unReadLeads?.results) &&
    isEmpty(replyLaterLeads?.results) &&
    isEmpty(readLeads?.results)
  )
    return (
      <div
        className="text-center mt-24"
        style={{
          width: '300px',
          margin: 'auto',
          marginTop: '100px'
        }}
      >
        <figure className="flex justify-center">
          <NoEnqSvg />
        </figure>
        <h3 className="mt-5 text-lg font-semibold">No enquiries yet...</h3>
        <p className="text-13 mt-4">
          Once you receive an enquiry from a connected channel it will show
          here. In the meantime, connect another channel
        </p>
      </div>
    );

  return (
    <div
      className="text-center mt-24"
      style={{
        width: '300px',
        margin: 'auto',
        marginTop: '100px'
      }}
    >
      <figure className="flex justify-center">
        <HandSvg />
      </figure>
      <h3 className="mt-2 text-lg font-semibold">Pick up another enquiry</h3>
      {/* <p className="text-13 mt-4">
        Once you receive an enquiry from a connected channel it will show here.
        In the meantime, connect another channel
      </p> */}
    </div>
  );
};

export default ChannelsConnectedEmptyState;

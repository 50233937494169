/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { object } from 'yup';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import { useForm } from 'react-hook-form';
import Select from 'components/inputs/select';
import ListHeader from './components/listHeader';
import useClickOutside from 'hooks/useOutsideClick';
import { yupResolver } from '@hookform/resolvers/yup';
import FieldListItem from './components/fieldListItem';
import TextInputField from 'components/inputs/textField';
import { getFormSchema } from 'components/fieldsAndValidation';
import CustomColorButton from 'components/buttons/customColorButton';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
import { useDispatch, useSelector } from 'react-redux';
import { updateWebformMappingFields } from 'slices/webformMappingSlice';
import { useSnackbar } from 'components/Snackbar';
import { requiredSelectMsg } from 'utils/messages';
import { getLeadConversation } from 'slices/conversationSlice';
import { useParams } from 'react-router-dom';
import ErrorMessage from 'components/errorMessage';
// import closeIcon from 'assets/icons/icon-close-red.svg';
import { ErrorMessagePopUp, ErrorPopUpBackdrop } from './components/errorPopup';

// import { animateScroll } from 'react-scroll';

// import { animateScroll } from 'react-scroll';

const WebformMapFields = (props) => {
  const { showMapFields, onClose } = props;
  const nodeRef = useRef();
  let webRef = useRef();
  const parentRef = useRef();
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [, setOpenDropdown] = useState(false);
  const [callbackError, setCallbackError] = useState([]);
  const [resetValue, setResetValue] = useState(false);
  const { webFormMappingQuestions, allFields } = useSelector(
    (state) => state.mapFields
  );
  const { conversation } = useSelector((state) => state.leadConversation);
  const getFormFields = !isEmpty(webFormMappingQuestions)
    ? webFormMappingQuestions?.map((e) => e.form_value)
    : [];
  const [errorPopUp, setErrorPopUp] = useState(false);

  const selectFormSchema = getFormFields.reduce((acc, field) => {
    return {
      ...acc,
      [field]: object().nullable().required(requiredSelectMsg(field))
    };
  }, {});

  const formSchema = {
    ...getFormSchema(['webformName']),
    ...selectFormSchema
  };

  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const webformName = register('webformName', { required: true });

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  useEffect(() => {
    const keys = Object.keys(selectFormSchema);
    keys.push('webformName');
    clearErrors(keys);
    reset();
    if (showMapFields) {
      setResetValue(true);
    }
  }, [showMapFields]);

  const loadLeadConversation = () => {
    dispatch(getLeadConversation({ leadId: cardId }));
  };

  const getQuestionIdFromValue = (value) => {
    const selectedQuestion = webFormMappingQuestions.filter(
      (e) => e.form_value === value
    );
    return selectedQuestion[0]?.id;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    setCallbackError([]);
    const form_fields = Object.keys(data);
    form_fields.shift();
    const mappedFields = form_fields.map((e) => ({
      field_name: data[e].value,
      question: getQuestionIdFromValue(e)
    }));
    if (mappedFields.some((item) => item.field_name === 'donotmap')) {
      const removeDonotMap = mappedFields.filter(
        (e) => e.field_name !== 'donotmap'
      );
      const formData = {
        body: {
          mapping: removeDonotMap,
          name: data?.webformName
        },
        formId: conversation?.results[0]?.form?.id
      };
      dispatch(updateWebformMappingFields(formData, onSuccess, onError));
    } else {
      const formData = {
        body: {
          mapping: mappedFields,
          name: data?.webformName
        },
        formId: conversation?.results[0]?.form?.id
      };
      dispatch(updateWebformMappingFields(formData, onSuccess, onError));
    }
  };

  const onError = (err) => {
    setIsLoading(false);
    setCallbackError(err.mapping);
  };

  const onSuccess = () => {
    setIsLoading(false);
    setValue('webformName', '');
    onClose();
    openSuccessSnackBar('Mapping Success');
    loadLeadConversation();
  };

  const webName = watch('webformName');

  // const testRef = useRef();
  // const webformName = watch('webformName')
  useEffect(() => {
    if (webRef.current) {
      setTimeout(() => {
        webRef.current?.focus();
      }, 100);
    }
  }, [webRef, webRef.current, showMapFields]);

  useEffect(() => {
    setValue('webformName', '');
  }, []);

  useEffect(() => {
    if (!isEmpty(errors) && touchedFields.webformName) {
      setErrorPopUp(true);
    }
  }, [errors, touchedFields]);

  return (
    <Modal.Main open={showMapFields} className="modal--match-fields">
      <Modal.Header
        title="Map your fields"
        onClose={() => {
          setValue('webformName', '');
          onClose();
        }}
      />
      <ErrorMessagePopUp
        show={errorPopUp}
        onClose={() => setErrorPopUp(false)}
        message="Please match all webform fields to enquirybox questions. Unmatched fields are marked in red"
      />
      <Modal.Body className="px-0 pt-0 pb-8 relative">
        {errorPopUp && <ErrorPopUpBackdrop />}
        <div className="mt-5 px-12 ">
          <label htmlFor="webformName" className="font-semibold">
            Webform name
          </label>
          <TextInputField
            name="webformName"
            placeholder="Name your webform so you recognise submissions in the future"
            className="input-field--md mb-6 mt-3"
            style={{ backgroundColor: '#FAF9F8' }}
            onChange={(evt) => {
              webformName.onChange(evt);
              setValue('webformName', evt.target.value);
            }}
            value={webName}
            onBlur={webformName.onBlur}
            ref={webRef}
            error={errors.webformName && touchedFields.webformName}
          />
        </div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div
            className="overflow-y-auto px-12 pt-3 scroll-smooth "
            ref={parentRef}
          >
            <div style={{ maxHeight: 300 }}>
              <div className="flex">
                <ListHeader title="Enquirybox questions" className="w-3/6" />
                <ListHeader title="Webform fields" className="w-3/6 pl-3" />
              </div>
              {!isEmpty(webFormMappingQuestions) &&
                webFormMappingQuestions?.map((item, index) => {
                  const { text, form_value } = item;
                  return (
                    <div
                      className="flex mt-4 gap-5"
                      id={`${index}-select`}
                      key={index}
                    >
                      <FieldListItem name={text} />
                      <div
                        className="relative w-3/6"
                        ref={nodeRef}
                        onClick={() =>
                          handleScrollIntoview(`dropDownId-${index}`, 'start')
                        }
                      >
                        <Select
                          id={`dropDownId-${index}`}
                          inputClassName={
                            isEmpty(errors[form_value]) &&
                            !isEmpty(getValues(form_value))
                              ? 'input-field--selected'
                              : 'input-field--md'
                          }
                          placeholder="Select a field"
                          options={allFields?.form_fields || []}
                          name={form_value}
                          control={control}
                          setValue={setValue}
                          register={register}
                          // selectedValue={getValues(form_value)}
                          error={!isEmpty(errors[form_value])}
                          showGreenBorder={
                            isEmpty(errors[form_value]) &&
                            !isEmpty(getValues(form_value))
                          }
                          required
                          dropDownClassName="dropdown-menu__map-fields"
                          resetValue={resetValue}
                          isModalOpen={showMapFields}
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="mt-5">
              {!isEmpty(callbackError) &&
                callbackError.map((err) => (
                  <ErrorMessage className="mb-5" message={err} key={err} />
                ))}
            </div>
          </div>
          <div className="flex items-center justify-between gap-3 px-12 pt-6">
            <div>
              <a
                style={{ color: '#296c7d' }}
                href="https://help.enquirybox.io/en/articles/7241966-mapping-forms"
                target="_blank"
                className="font-bold cursor-pointer text-20"
                rel="noreferrer"
              >
                Learn about mapping
              </a>
            </div>
            <div className="flex gap-2">
              <CustomColorButton
                label="Cancel"
                className="btn--secondary btn--md min-w-100"
                onClick={() => {
                  setValue('webformName', '');
                  onClose();
                }}
              />
              <CustomColorButton
                type="submit"
                label="Save & finish"
                className="btn--green btn--md btn--wide-20 min-w-100"
                isLoading={isLoading}
                enableLoader={isLoading}
                loaderInline={true}
              />
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

WebformMapFields.propTypes = {
  showMapFields: PropTypes.bool,
  onClose: PropTypes.func
};

export default WebformMapFields;

import React from 'react';
// import EmptyState from 'modules/enquirybox/components/emptyState';
import ReportLink from './links';
// import { Route, Routes } from 'react-router-dom';
import IncomingEnquiryReport from './incomingenquiryreport';
import SalesReport from './salesreport';
import SourceReport from './sourcereport';
import TeamPerformanceReport from './teamperformancereport';
import SnapShotReport from './snapshotreport';

const ChannelReport = () => {
  return (
    <>
      <div className="dashboard__main__body__section-left overflow-overlay">
        <ReportLink />
      </div>
      {/* <EmptyState
        title="Enquirybox reports"
        description="One central place to view and find all the contacts in
									enquirybox account. 😎"
      /> */}

      <div
        className="dashboard__main__body__section-right layout-1 bg-grey-300"
        style={{ padding: 25 }}
      >
        <IncomingEnquiryReport />
        <SalesReport />
        <SourceReport />
        <TeamPerformanceReport />
        <SnapShotReport />
      </div>
    </>
  );
};

export default ChannelReport;

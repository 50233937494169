import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Conversation from './main';
import ChannelsConnectedEmptyState from 'modules/enquirybox/channels/module/settings/components/channelsConnectedEmpty';

const ConversationHome = () => {
  return (
    <Routes>
      <Route path="/" element={<ChannelsConnectedEmptyState />} />
      <Route path="/lead/*" element={<Conversation isBin={false} />} />
    </Routes>
  );
};

export default ConversationHome;

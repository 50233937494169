import React from 'react';
import Sender from './sender';
// import CustomToolTip from 'components/CustomTooltip';
// import iconCollapse from 'assets/icons/icon-collapse.svg';

const EmailBody = ({ sender = false, data, isCollapsed, setIsCollapsed }) => {
  return (
    <div
      className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
      style={{
        borderRadius: 10,
        padding: 26,
        backgroundColor: sender
          ? '#FEF2F7'
          : data.sent_by_automation
          ? '#FEF8F6'
          : data.is_reply_sent
          ? '#FEF8F6'
          : '#f4f9f9',
        width: sender ? '100%' : '90%',
        marginRight: sender ? 6 : 0
      }}
    >
      {/* <CustomToolTip
        position="bottom"
        tooltipText="Collapse"
        style={{ position: 'absolute', right: '7px', top: '6px' }}
      >
        <img
          src={iconCollapse}
          alt="icon-expand"
          className="cursor-pointer"
          onClick={() => {
            setIsCollapsed(!isCollapsed);
          }}
        />
      </CustomToolTip> */}
      <Sender data={data} />
      {/* {sender ? <Sender /> : <Receiver />} */}
    </div>
  );
};

export default EmailBody;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/icon-edit-primary-color.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateContactDetailsInBox } from 'slices/boxes/slice';
// import { getLeadData } from 'slices/conversationSlice';
import { setIsLoading, updateContactDetails } from 'slices/questionSlice';
import { isEmpty, isPhoneValid, isPhoneValidWithoutPlus } from 'utils/utils';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import TextInputField from 'components/inputs/textField';
import { ReactComponent as CaretIcon } from 'assets/icons/icon-caret-down.svg';
import iconAUD from 'assets/icons/icon-currency-aud.svg';
import iconUSD from 'assets/icons/icon-currency-us-dollar.svg';
import iconAED from 'assets/icons/icon-currency-aed.svg';
import iconPOUND from 'assets/icons/icon-currency-pound.svg';
import useClickOutside from 'hooks/useOutsideClick';
import InputWithIcon from 'components/inputs/inputWithIcon';
import search from 'assets/icons/icon-search.svg';
import ErrorMessage from 'components/errorMessage';
import { useSnackbar } from 'components/Snackbar';

const renderIcon = (value) => {
  switch (value) {
    case 1:
      return iconPOUND;
    case 2:
      return iconUSD;
    case 3:
      return iconAUD;
    case 4:
      return iconAED;
    default:
      return <></>;
  }
};

const CHOICE_LIST = [
  {
    id: 1,
    label: 'United Kingdom (+44)',
    value: '+44',
    icon: renderIcon(1)
  },
  {
    id: 2,
    label: 'United States (+1)',
    value: '+1',
    icon: renderIcon(2)
  },
  {
    id: 3,
    label: 'Australia (+61)',
    value: '+61',
    icon: renderIcon(3)
  },
  {
    id: 4,
    label: 'United Arab Emirates (+971)',
    value: '+971',
    icon: renderIcon(4)
  }
];

const PhoneItem = ({ phone, isBin }) => {
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const dropdownRef = useRef();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState(phone);
  const [inputError, setInputError] = useState('');
  const [openDropDowm, setOpenDropDown] = useState(false);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const [selectedCountry, setSelectedCountry] = useState('+44');
  const [searchValue, setSearchValue] = useState('');
  const [countryList, setCountryList] = useState(CHOICE_LIST);
  const [countryEmptyError, setCountryEmptyError] = useState('');
  const { openSuccessSnackBar } = useSnackbar();

  useClickOutside(() => setOpenDropDown(false), dropdownRef.current);

  const callback = () => {
    setIsEdit(false);
    dispatch(setIsLoading(false));
    // dispatch(getLeadData({ leadId: cardId }));
  };

  useEffect(() => {
    if (!isEmpty(activeLead)) {
      setInputValue(activeLead?.phone_number);
    }
  }, [activeLead]);

  useEffect(() => {
    setInputError('');
  }, [isEdit]);

  const onPhoneSubmit = (e) => {
    e.preventDefault();
    if (inputValue === '' || !inputValue) {
      setInputError('Phone number should not be empty');
    } else {
      if (inputValue.length > 20) {
        setInputError('Phone number should be less than 20');
      } else {
        if (inputValue === phone) {
          setIsEdit(false);
          return;
        } else {
          let formData = {};
          if (phone !== '' || phone) {
            if (
              inputValue.includes('+1') ||
              inputValue.includes('+44') ||
              inputValue.includes('+61') ||
              inputValue.includes('+971')
            ) {
              dispatch(setIsLoading(true));
              formData['phone_number'] = inputValue;
              if (isBox) {
                dispatch(
                  updateContactDetailsInBox({
                    body: formData,
                    leadId: cardId,
                    callback: callback
                  })
                );
              }
              if (!isBox) {
                dispatch(
                  updateContactDetails({
                    body: formData,
                    leadId: cardId,
                    callback: callback
                  })
                );
              }
            } else {
              setCountryEmptyError(
                'Add country code within this (+44, +1, +61, +971)'
              );
            }
          } else {
            dispatch(setIsLoading(true));
            formData['phone_number'] = `${selectedCountry}${inputValue}`;
            if (isBox) {
              dispatch(
                updateContactDetailsInBox({
                  body: formData,
                  leadId: cardId,
                  callback: callback
                })
              );
            }
            if (!isBox) {
              dispatch(
                updateContactDetails({
                  body: formData,
                  leadId: cardId,
                  callback: callback
                })
              );
            }
          }
        }
      }
    }
  };

  const onCancel = () => {
    setInputValue(phone);
    setIsEdit(false);
  };

  const onChange = (e) => {
    setInputError('');
    const value = e.target.value;
    if (phone === '' || !phone) {
      if (isPhoneValidWithoutPlus(value)) {
        setInputValue(value);
      }
    } else {
      if (isPhoneValid(value)) {
        setInputValue(value);
      }
    }
  };

  const onOptionSelect = (value) => {
    setSelectedCountry(value);
    if (inputValue === '' || !inputValue) {
      setIsEdit(true);
      setOpenDropDown(false);
    } else {
      dispatch(setIsLoading(true));
      const formData = {
        phone_number: `${value}${inputValue}`
      };
      if (isBox) {
        dispatch(
          updateContactDetailsInBox({
            body: formData,
            leadId: cardId,
            callback: callback
          })
        );
      }
      if (!isBox) {
        dispatch(
          updateContactDetails({
            body: formData,
            leadId: cardId,
            callback: callback
          })
        );
      }
    }
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = CHOICE_LIST.filter((item) =>
        item.label.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setCountryList(filtered);
    } else {
      setCountryList(CHOICE_LIST);
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard.writeText(inputValue);
    openSuccessSnackBar('Phone number copied', 'large');
  };

  return (
    <>
      <form autoComplete="off">
        {isEdit ? (
          <>
            <div className="relative flex">
              {(phone === '' || !phone) && (
                <div className="font-medium text-sm flex items-center mr-1">
                  Phone: {selectedCountry}
                </div>
              )}
              <TextInputField
                name="phone"
                className="input-field--md"
                onChange={onChange}
                value={inputValue || ''}
                error={!isEmpty(inputError) ? true : false}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    onPhoneSubmit(e);
                  }
                }}
                placeholder=""
                containerStyle={
                  phone === '' || !phone ? { width: '80%' } : { width: '100%' }
                }
              >
                <CustomColorButton
                  className="btn--xs btn--input-cancel btn--input-cancel--sm btn--grey text-xs"
                  label="Cancel"
                  onClick={onCancel}
                />
                <PrimaryButton
                  label="Save"
                  className="btn--xs btn--copy btn--copy--xs text-xs"
                  onClick={(e) => {
                    onPhoneSubmit(e);
                  }}
                />
              </TextInputField>
            </div>
            {!isEmpty(countryEmptyError) && (
              <ErrorMessage message={countryEmptyError} />
            )}
          </>
        ) : (
          <div
            className="flex justify-between items-center bg-color-on-hover bg-color-on-hover--sm__left bg-color-on-hover--bg-grey-100 bg-color-on-hover--br-6 mt-1 relative"
            onMouseEnter={() => setShowEditIcon(true)}
            onMouseLeave={() => setShowEditIcon(false)}
            style={{ paddingBottom: 6, paddingTop: 6 }}
          >
            <div className="font-medium text-sm flex">
              {showEditIcon && !isEmpty(phone) ? (
                <span
                  className="font-medium text-sm text-primary"
                  onClick={copyToClipboard}
                >
                  Click to copy:{' '}
                </span>
              ) : (
                <span className="font-medium text-sm">Phone: </span>
              )}
              {(inputValue === '' || !inputValue) && (
                <span
                  ref={dropdownRef}
                  className="flex items-center ml-1"
                  onClick={() => {
                    if (!isBin) {
                      setOpenDropDown(true);
                    }
                  }}
                >
                  {selectedCountry} <CaretIcon className="ml-1" />
                  <div
                    data-testid="select-controlled-dropdown"
                    className={`dropdown-menu p-2 ${
                      openDropDowm ? 'open' : ''
                    } p-2`}
                    style={{ top: 35, maxWidth: 285, minHeight: 189 }}
                  >
                    <div>
                      <InputWithIcon
                        icon={search}
                        placeholder="Search"
                        value={searchValue}
                        onChange={handleSearch}
                        iconClassName="mr-2"
                        small
                      />
                    </div>
                    {countryList.map((option, idx) => {
                      return (
                        <div
                          data-testid="select-controlled-dropdown-list"
                          key={idx}
                          className={`dropdown-menu__list mt-2`}
                          onClick={() => onOptionSelect(option.value)}
                        >
                          <img
                            src={option.icon}
                            alt="icon"
                            style={{ width: 28, height: 18, marginRight: 10 }}
                          />
                          {option.label}
                        </div>
                      );
                    })}
                  </div>
                  <span className="text-15 text-grey-600 ml-1">
                    Phone number
                  </span>
                </span>
              )}{' '}
              <span className="text-sm font-normal ml-1">{inputValue}</span>
            </div>
            {showEditIcon && !isBin && (
              <EditIcon
                className="cursor-pointer mr-3"
                onClick={() => setIsEdit(true)}
              />
            )}
          </div>
        )}
      </form>
    </>
  );
};

export default PhoneItem;

import { useNavigate } from 'react-router-dom';
import arrowLeft from 'assets/icons/icon-back-arrow-black.svg';

const BackArrow = ({ children, className, deployBack }) => {
  const navigate = useNavigate();
  className = className ? className : 'justify-between';

  return (
    <div className={`page-back-btn-container flex ${className}`}>
      <div
        className="flex"
        onClick={() => (deployBack ? navigate(deployBack) : navigate(-1))}
      >
        <div className="flex items-center justify-center flex-shrink-0 w-8 h-8 bg-grey-300 rounded-full cursor-pointer">
          <img src={arrowLeft} alt="arrow-left" />
        </div>
        <span className="text-lg font-medium ml-5 cursor-pointer">Go back</span>
      </div>
      {children}
    </div>
  );
};

export default BackArrow;

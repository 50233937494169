/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate, useLocation } from 'react-router-dom';
import SettingsHeader from 'components/settings/header';
import ChannelMenu from 'modules/enquirybox/channels/module/settings/menu/channels';
import Facebook from './channel/facebook';
import Instagram from './channel/instagram';
import FacebookLeads from './channel/facebookAds';
import Webform from './channel/webform';
import WhatsApp from './channel/whatsApp';
import SelectChannels from './modules/selectChannels';
import CreateChannel from './modules/selectChannels/modal';
import { route } from 'utils/route';
import { useSelector } from 'react-redux';
import SMS from './channel/sms';
import EnquiryBot from './channel/enquiryBot';
import Zapier from './channel/zapier';
// import { animateScroll } from 'react-scroll';

const Settings = () => {
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [createTitle, setCreateTitle] = useState('');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { isScrollDown } = useSelector((state) => state.channels);

  const onClose = () => {
    navigate(`${route.dashboard}${route.enquirybox}/eb-box`);
  };

  const onSelectChannel = (title) => {
    setCreateTitle(title);
    setOpenCreateModal(true);
  };

  const onCreateChannel = () => {};

  const connectedChannelStyle = () => {
    let style = {};
    if (pathname.includes('connected')) {
      style = {
        background: '#ffffff'
      };
    }
    return style;
  };

  // useEffect(()=>{
  //   if(isScrollDown){

  //   }
  // },[isScrollDown])

  return (
    <section className="dashboard__main">
      <SettingsHeader text="Channel settings" onClose={onClose} />
      <div
        className="dashboard__main__body general-settings"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <div className="dashboard__main__body__section-left overflow-overlay">
          <ChannelMenu />
        </div>
        {/* <div className="dashboard__main__body__section-left overflow-overlay">
          {isLoading || isPendingLoading ? (
            <div className="flex justify-center items-center">
              <Loader />
            </div>
          ) : (
            <ChannelMenu
            allConnectedChannels={data}
            allPendingChannels={pendingChannels}
            />
          )}
        </div> */}
        <div
          className="dashboard__main__body__section-right layout-1"
          style={connectedChannelStyle()}
          id="channel-settings"
        >
          <Routes>
            <Route path="/facebook/*" element={<Facebook />} />
            <Route path="/instagram/*" element={<Instagram />} />
            <Route path="/facebook-leads/*" element={<FacebookLeads />} />
            <Route path="/zapier/*" element={<Zapier />} />
            <Route path="/webforms/*" element={<Webform isSettings={true} />} />
            <Route path="/whatsApp/*" element={<WhatsApp />} />
            <Route path="/sms/*" element={<SMS />} />
            <Route path="/ebot/*" element={<EnquiryBot />} />
            <Route
              path="/select-channels/*"
              element={<SelectChannels onClick={onSelectChannel} />}
            />
          </Routes>
        </div>
      </div>
      <CreateChannel
        open={openCreateModal}
        onClose={() => {
          setOpenCreateModal(false);
          setCreateTitle('');
        }}
        onSubmit={onCreateChannel}
      />
    </section>
  );
};

export default Settings;

/* eslint-disable no-unused-vars */
import { useState } from 'react';
import ProgressBar from 'components/progressBar';
import PrimaryButton from 'components/buttons/primary';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrencyText } from 'utils/currency';
import ConfirmBusinessDetails from 'modules/modals/confirmBusinessDetails';
import { upgradeSmsPlans } from 'slices/subscription/slice';
import { isEmpty } from 'utils/utils';
import { useSnackbar } from 'components/Snackbar';
import { ConnectSmsChannel } from 'slices/sms/slice';
import {
  getAllConnectedChannels,
  getAllPendingChannels
} from 'slices/channelsSlice';
import { getMyInfo } from 'slices/myInfoSlice';
import swal from 'sweetalert';
import { getSmsDetails } from 'slices/subscription/slice';
import CancelSmsSubscription from 'modules/modals/CancelSmsSubscription';
import { useAnalytics } from 'use-analytics';
import UpgradePlanTooltip from 'modules/modals/UpgradePlanTooltip';
import CHOICES from 'choices';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';

const { FREE_TRIAL } = CHOICES.PlanType;
const { SMS } = CHOICES.ChannelChoices;

const SmsPlans = () => {
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const { smsPlans, smsDetails, currentPlan } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showBusinessModal, setShowBusinessModal] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const { track } = useAnalytics();

  const triggerBusinessModal = (plan_id) => {
    if (myInformation.is_sms_address_required) {
      setShowBusinessModal(!showBusinessModal);
      setSelectedPlan(plan_id);
    } else {
      setSelectedPlan(plan_id);
      triggerBuyPlan(plan_id);
    }
  };

  const callback = (data) => {
    dispatch(getAllPendingChannels());
    dispatch(getAllConnectedChannels());
    dispatch(getMyInfo());
    dispatch(getSmsDetails());
    openSuccessSnackBar('Connected');
    navigate(`${route.channels}/sms/${SMS}/connected/${data.id}/automations`);
    setIsLoading(false);
  };

  const errorCallback = (err) => {
    setIsLoading(false);
    swal({
      title: 'Error',
      text: err?.response?.data?.error,
      icon: 'error',
      button: {
        text: 'Ok',
        value: true,
        visible: true,
        className: '',
        closeModal: true
      }
    });
  };

  const triggerBuyPlan = (plan_id) => {
    setIsLoading(true);
    dispatch(
      ConnectSmsChannel({
        body: { sms_plan_id: plan_id },
        subOrgId: subOrganizationId,
        callback,
        errorCallback
      })
    );
  };

  const onCloseBusinessModal = () => {
    setShowBusinessModal(false);
    setSelectedPlan(null);
  };

  const upgradeOrDowngradeSmsSubscription = (planId, payload) => {
    setIsLoading(true);
    const { type, price, tier_name } = payload;
    dispatch(
      upgradeSmsPlans({
        planId,
        successCb: () => {
          setIsLoading(false);
          openSuccessSnackBar(type === 'downgrade' ? 'Downgraded' : 'Upgraded');
          if (type == 'downgrade') {
            track(
              'SMS_downgrade',
              {
                tier_name: tier_name,
                price: price
              },
              {
                groupId: subOrganizationId
              }
            );
          } else {
            track(
              'SMS_upgrade',
              {
                tier_name: tier_name,
                price: price
              },
              {
                groupId: subOrganizationId
              }
            );
          }
        },
        errorCb: () => {
          setIsLoading(false);
          openErrorSnackBar('Failed');
        }
      })
    );
  };

  const onSmsPlanCancel = () => {
    setShowCancelModal(true);
  };

  // to get the active sms plan if it is from next plan
  const getActivePlan = (planId) => {
    if (isEmpty(smsDetails?.current_plan)) {
      return false;
    }
    return smsDetails.current_plan.id === planId;
  };

  // to get the not the active sms plan if it is from next plan or current plan
  const showUpgradeStatus = (planId) => {
    if (isEmpty(smsDetails?.current_plan)) {
      return false;
    }
    if (smsDetails?.current_plan.id !== planId) {
      return true;
    }
    return false;
  };

  const shouldShowDowngrade = (plan) => {
    if (plan.default_sms_count < smsDetails?.current_plan?.default_sms_count) {
      return true;
    }
    return false;
  };

  const PlanItem = ({
    smsDetails,
    plan,
    showUpgrade,
    showCancel,
    showBuy,
    isActive,
    triggerBusinessModal,
    updgradeOrDowngradePlan,
    cancelPlan,
    showDowngrade // to show downgrade btn
  }) => {
    const getCurrentSmsProgressBar = () => {
      if (!isEmpty(smsDetails?.current_plan)) {
        const total_sms = smsDetails?.current_plan?.default_sms_count || 0;
        const sms_triggered = smsDetails?.total_sms_triggered || 0;
        return Math.floor(
          (parseInt(sms_triggered) / parseInt(total_sms)) * 100
        );
      }
      return 0;
    };

    return (
      <>
        <tr>
          <td>{plan.default_sms_count}</td>
          <td>
            {getCurrencyText(plan.currency)}
            {plan.price_per_sms}
          </td>
          <td>
            {getCurrencyText(plan.currency)}
            {plan.price_per_month}
          </td>
          <td className="flex justify-end">
            {showCancel && (
              <PrimaryButton
                className="btn--cancel btn--xs"
                label="Cancel Auto top up"
                onClick={cancelPlan}
                disabled={
                  smsDetails.is_eligible_to_auto_delete_when_balance_is_zero
                }
              />
            )}
            {showDowngrade && smsDetails.next_plan?.id === plan.id && (
              <div className="text-15 font-semibold ">Upcoming plan</div>
            )}
            {showDowngrade && smsDetails.next_plan?.id !== plan.id && (
              <PrimaryButton
                className="btn--outline btn--downgrade btn--xs"
                label="Downgrade"
                onClick={() =>
                  updgradeOrDowngradePlan(plan.id, {
                    type: 'downgrade',
                    tier_name: plan.name,
                    price: plan.price_per_month
                  })
                }
                disabled={isLoading}
                enableLoader
                loaderInline
                isLoading={selectedPlan === plan.id && isLoading}
              />
            )}
            {!showDowngrade &&
              showUpgrade &&
              smsDetails.next_plan?.id === plan.id && (
                <div className="text-15 font-semibold ">Upcoming plan</div>
              )}
            {!showDowngrade &&
              showUpgrade &&
              smsDetails.next_plan?.id !== plan.id && (
                <PrimaryButton
                  className="btn--outline btn--upgrade btn--xs"
                  label="Upgrade"
                  onClick={() =>
                    updgradeOrDowngradePlan(plan.id, {
                      type: 'upgrade',
                      tier_name: plan.name,
                      price: plan.price_per_month
                    })
                  }
                  disabled={isLoading}
                  enableLoader
                  loaderInline
                  isLoading={selectedPlan === plan.id && isLoading}
                />
              )}
            {showBuy && (
              <UpgradePlanTooltip
                alignment="top"
                show={currentPlan?.plan?.plan_type === FREE_TRIAL}
              >
                <PrimaryButton
                  className="btn--outline btn--upgrade btn--xs"
                  label="Buy now"
                  onClick={() => triggerBusinessModal(plan.id)}
                  disabled={
                    isLoading || currentPlan?.plan?.plan_type === FREE_TRIAL
                  }
                  enableLoader
                  loaderInline
                  isLoading={selectedPlan === plan.id && isLoading}
                  // isLoading
                />
              </UpgradePlanTooltip>
            )}
          </td>
        </tr>
        {isActive && (
          <tr>
            <td colSpan="4">
              <p className="text-grey-800 text-sm font-medium">
                {smsDetails?.total_sms_triggered}{' '}
                <span className="text-grey-600"> / </span>
                {smsDetails?.current_plan?.default_sms_count} SMS used
                {/* <span> - Counter resets on 14/03/23</span> */}
              </p>
              <div className="mt-2">
                <ProgressBar
                  bgcolor="#039855"
                  progress={getCurrentSmsProgressBar()}
                />
              </div>
            </td>
          </tr>
        )}
      </>
    );
  };

  return (
    <>
      <ConfirmBusinessDetails
        open={showBusinessModal}
        onCancel={onCloseBusinessModal}
        plan_id={selectedPlan}
      />
      <CancelSmsSubscription
        open={showCancelModal}
        onCancel={onSmsPlanCancel}
        setShowCancelModal={setShowCancelModal}
      />
      <div className="table-sms-plans-wrapper">
        <table className="table-sms-plans w-full">
          <thead>
            <tr>
              <td>SMS Sent / Received</td>
              <td>Price per SMS</td>
              <td>Bundle Price</td>
              <td style={{ width: '30%' }}></td>
            </tr>
          </thead>
          <tbody className="">
            {smsPlans?.map((plan) => {
              return (
                <PlanItem
                  key={plan.id}
                  plan={plan}
                  isActive={getActivePlan(plan.id)}
                  showBuy={isEmpty(smsDetails?.current_plan)}
                  showUpgrade={showUpgradeStatus(plan.id)}
                  showCancel={getActivePlan(plan.id)}
                  triggerBusinessModal={triggerBusinessModal}
                  updgradeOrDowngradePlan={upgradeOrDowngradeSmsSubscription}
                  cancelPlan={onSmsPlanCancel}
                  showDowngrade={shouldShowDowngrade(plan)}
                  smsDetails={smsDetails}
                />
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default SmsPlans;

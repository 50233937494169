/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import SettingsHeader from 'components/settings/header';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  Navigate
} from 'react-router-dom';
import { route } from 'utils/route';
import LabelSettings from './module/labels';
import AccountSettingsMenu from './module/menu';
import QuestionSettings from './module/questions';
import FileLibrary from './module/fileLibrary';
import TeamMember from './module/teamMember';
import ReplyTemplate from './module/replyTemplate';
import BusinesInfoSettings from './module/businessInfo';
import TasksSettings from './module/tasks';
import Wrapper from './wrapper';
import usePlanAccess from 'hooks/usePlanAccess';
import { useDispatch, useSelector } from 'react-redux';
import { subscriptionStripeRedirect } from 'slices/subscription/slice';
import ScreenLoader from 'components/loader/screenLoader';
// import EmailSignature from '../teamChat/module/settings/modules/emailSignature';
// import { AnimatePresence } from 'framer-motion';
import AnimationWrapper from 'animation/wrapper';

const AccountSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [stripeRedirectFlag, setStripeRedirectFlag] = useState(false);
  const params = useParams();
  const restParams = params['*'];
  const { has_file_library } = usePlanAccess();
  const mainRoute = `${route.dashboard}${route.settings}`;
  const onClose = () => {
    navigate(`${route.dashboard}${route.enquirybox}/eb-box`);
  };
  useEffect(() => {
    if (restParams === 'subscription-billing') {
      setStripeRedirectFlag(true);
      dispatch(
        subscriptionStripeRedirect(subOrganizationId, closeStripeRedirect)
      );
    }
  }, [restParams]);

  const closeStripeRedirect = (data) => {
    if (data.link) {
      // window.location.href = data.link;
      setStripeRedirectFlag(false);
      window.open(data.link, '_blank').focus();
    }
  };
  return (
    <section className="dashboard__main">
      {stripeRedirectFlag && <ScreenLoader />}
      <SettingsHeader text="Account settings" onClose={onClose} />
      <div
        className="dashboard__main__body general-settings"
        style={{ height: 'calc(100% - 100px)' }}
      >
        <div className="dashboard__main__body__section-left overflow-overlay">
          <AccountSettingsMenu mainRoute={mainRoute} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flex: 1
          }}
        >
          <Routes>
            <Route element={<AnimationWrapper />}>
              <Route
                path="/labels/*"
                element={
                  <Wrapper>
                    <LabelSettings />
                  </Wrapper>
                }
              />
              <Route
                path="/questions/*"
                element={
                  <Wrapper>
                    <QuestionSettings />
                  </Wrapper>
                }
              />
              <Route
                path="/file-library/*"
                element={
                  <Wrapper showUpgradeTopbar={!has_file_library}>
                    <FileLibrary />
                  </Wrapper>
                }
              />
              <Route
                path="/team-member/*"
                element={
                  <Wrapper>
                    <TeamMember />
                  </Wrapper>
                }
              />
              <Route
                path="reply-template/*"
                element={
                  <Wrapper>
                    <ReplyTemplate />
                  </Wrapper>
                }
              />
              <Route
                path="/tasks/*"
                element={
                  <Wrapper>
                    <TasksSettings />
                  </Wrapper>
                }
              />
              <Route
                path="/business-info/*"
                element={
                  <Wrapper>
                    <BusinesInfoSettings />
                  </Wrapper>
                }
              />
              <Route
                path="/"
                element={
                  <Navigate
                    to={`${route.dashboard}${route.settings}/team-member`}
                  />
                }
              />
              {/* <Route path="/email-signature/*" element={<EmailSignature />} /> */}
            </Route>
          </Routes>
          {/* </AnimatePresence> */}
        </div>
      </div>
    </section>
  );
};

export default AccountSettings;

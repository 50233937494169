/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from 'react';
import useClickOutside from 'hooks/useOutsideClick';
import RenderList from './renderList';
import NoteContainer from '../chatActions/noteContainer';
import StageContainer from '../chatActions/stageContainer';
import { useParams } from 'react-router-dom';
import { onMoveToBox } from 'slices/eboxLead';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import {
  getStageLeads,
  composeStageLeads,
  removeLeadFromList
} from 'slices/boxes/slice';
import { getLeadData, setActiveLead } from 'slices/conversationSlice';
import { isEmpty } from 'utils/utils';
import { getStageContractValue } from 'slices/boxes/slice';
import { useAnalytics } from 'use-analytics';
import choices from 'choices';

const { MANUALLY_ADDED, IMPORTED_FROM_FILE } = choices.LeadChannelChoice;

const MoveToList = (props) => {
  const {
    options,
    onSelect,
    open,
    onClose,
    renderButton,
    title,
    selected,
    active,
    onSubmit,
    setSelected
  } = props;
  const RenderButton = renderButton;

  const { cardId, stageId, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const [notes, setNotes] = useState('');
  const [stageSelected, setStageSelected] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const nodeRef = useRef();
  const { activeNewLead } = useSelector((state) => state.leads);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  // const { analytics } = useSelector((state) => state.segments);
  const { myInformation } = useSelector((state) => state.myInfo);
  const { track } = useAnalytics();
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useClickOutside(() => {
    setNotes('');
    onSelect(null);
    setStageSelected(null);
    onClose(false);
  }, nodeRef.current);

  useEffect(() => {
    if (!open) {
      setNotes('');
      onSelect(null);
      setStageSelected(null);
    }
  }, [open]);

  const onSuccessMove = (data) => {
    track(
      'enquiry_moved',
      {
        user: myInformation.name
      },
      {
        groupId: subOrganizationId
      }
    );
    if (isBox) {
      // dispatch(getStageLeads({ stageId }))
      //   .then((res) => {
      //     dispatch(composeStageLeads({ stageId, results: res.data }));
      //     setIsLoading(false);
      //     openSuccessSnackBar('Moved successfully');
      //     onSubmit();
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
      if (data?.box_id === box_id) {
        dispatch(getStageLeads({ stageId: data?.box_stage })).then((res) => {
          dispatch(
            composeStageLeads({ stageId: data?.box_stage, results: res.data })
          );
        });
      }
      dispatch(removeLeadFromList({ stageId, cardId }));
      dispatch(getStageContractValue({ stageId }));
      setIsLoading(false);
      openSuccessSnackBar('Moved successfully');
      onSubmit();
    } else {
      dispatch(
        getLeadData({ leadId: cardId }, (res) => {
          console.log({ res });
          setIsLoading(false);
          openSuccessSnackBar('Moved successfully');
          onSubmit();
        })
      );
    }
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Failed');
  };

  const onStageSelect = (stage) => {
    setStageSelected(stage);
  };

  const onSave = () => {
    const payload = {
      formData: {
        box_id: selected.id,
        box_stage: stageSelected.id,
        lead: cardId,
        is_current: true,
        note: notes
      },
      isBox: isBox,
      box_name: selected.box_name,
      box_stage_name: stageSelected.stage_name,
      is_manual_card:
        activeLead.channel == MANUALLY_ADDED ||
        activeLead.channel == IMPORTED_FROM_FILE
    };
    setIsLoading(true);
    dispatch(onMoveToBox(payload, onSuccessMove, onError));
  };

  return (
    <div ref={nodeRef}>
      <RenderButton />
      {selected && !stageSelected && (
        <StageContainer
          open={open}
          selected={selected}
          setSelected={setSelected}
          setNotes={setNotes}
          onSubmit={onSave}
          onSelect={onStageSelect}
          isLoading={isLoading}
          src={selected?.box_avatar}
          name={selected?.box_name}
          avatarStyles={{ width: 20 }}
          submitBtnText="Move"
        />
      )}
      {stageSelected && (
        <NoteContainer
          open={open}
          selected={selected}
          setSelected={setSelected}
          setStageSelected={setStageSelected}
          onBack={() => setStageSelected(null)}
          setNotes={setNotes}
          onSubmit={onSave}
          isLoading={isLoading}
          src={selected?.box_avatar}
          name={selected?.box_name}
          avatarStyles={{ width: 20 }}
          subName={`(${stageSelected.stage_name})`}
          submitBtnText="Move"
        />
      )}
      {!selected && (
        <RenderList
          open={open}
          options={options}
          onSelect={onSelect}
          title={title}
          active={active}
          onSubmit={onSave}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

MoveToList.defaultProps = {
  onSelect: () => {},
  onClose: () => {}
};

export default MoveToList;

import Select from 'components/inputs/select';
import React, { useState } from 'react';

const DropdownTypeForm = ({ data, onQuestionOptionSelect }) => {
  const [options] = useState(
    data?.choice_set?.map((item) => {
      return {
        id: item?.position,
        label: item?.text,
        value: item?.text
      };
    })
  );
  const onChange = (item) => {
    onQuestionOptionSelect(item, data.id);
  };

  return (
    <div className="pb-4">
      <label className="input-field-label">{data.text}</label>
      <Select
        name="options"
        options={options}
        inputClassName="input-field--type2"
        dropDownClassName="p-2"
        placeholder="Select an option"
        style={{ padding: '9px 15px' }}
        onChange={onChange}
        readOnly={true}
      />
    </div>
  );
};

export default DropdownTypeForm;

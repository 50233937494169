/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo } from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import { useSelector } from 'react-redux';
import choices from 'choices';
import subscription_user from 'assets/icons/subscription_user.svg';
import APIRequest from 'apiRequest';
import { CANCEL_PLAN_AND_EXPORT_DATA } from 'urls/plans';
import { replaceUrl } from 'utils/urlReplace';
import { getCurrencyText } from 'utils/currency';
import { deleteSubscriptionAccount } from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';
import PaymentModalLogout from 'components/paymentModalLogOut';

const { STARTER } = choices.PlanType;

const CancelMyAccount = ({ open, onCancel, onShowPlan }) => {
  const { subscriptionPlans, currentPlan } = useSelector(
    (state) => state.subscription
  );
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const starter_plan = useMemo(() => {
    const plan = subscriptionPlans.find((plan) => plan.plan_type == STARTER);
    return plan;
  }, [subscriptionPlans]);

  const handleCancelCallback = () => {
    setIsLoading(false);
    onCancel();
  };
  const onCancelAccount = async () => {
    setIsLoading(true);
    await new APIRequest()
      .post(
        replaceUrl(CANCEL_PLAN_AND_EXPORT_DATA, 'subOrgId', subOrganizationId)
      )
      .then((res) => {
        if (res.status === 200) {
          let payload = {
            delete_now: false
          };
          dispatch(deleteSubscriptionAccount(payload, handleCancelCallback));
        }
      })
      .catch((e) => {
        setIsLoading(false);
      });
  };

  return (
    <Modal.Main className="modal--upgrade-notification" open={open}>
      <div className="modal__body px-11 py-10">
        <PaymentModalLogout />
        <h1 className="text-25 font-bold text-center">
          Are you sure you want to cancel?
        </h1>
        <div className="mt-5 text-center px-6">
          <p className="text-lg leading-6">
            We’ll be sorry to see you go, but thanks for giving Enquirybox a
            good try.
          </p>
          <p className="text-lg leading-6 mt-6">
            Your account will be closed immediately and you can export your
            data.
          </p>
        </div>
        <div className="bg-grey-90 border-radius-14 py-4 pb-5 px-6 mt-6">
          <p className="text-lg font-medium text-center">
            It’s still not too late to upgrade
          </p>
          <div className="mt-4">
            <CustomColorButton
              label={`See our plans, starting from ${getCurrencyText(
                currentPlan?.plan?.currency
              )}${starter_plan?.amount}`}
              className="w-full text-white text-lg font-bold bg-green-dark cursor-pointer"
              style={{ fontWeight: '700' }}
              onClick={onShowPlan}
            />
            <CustomColorButton
              label="No thanks, cancel my account"
              className="w-full bg-white text-lg mt-3 cursor-pointer relative"
              style={{
                color: '#F03636',
                fontWeight: '400',
                border: '1px solid #F03636',
                padding: '10px 18px'
              }}
              isLoading={isLoading}
              enableLoader
              loaderStyle={{ marginLeft: 'none' }}
              onClick={onCancelAccount}
            />
          </div>
        </div>
        <div className="mt-6">
          <p className="font-semibold text-center px-8">
            “Our sales have tripled! We’ve definitely seen a massive increase in
            enquiries just with Enquirybox on our website”
          </p>
        </div>
        <div className="flex items-center justify-center mt-8">
          <figure
            className="avatar-container avatar-container--full"
            style={{ width: 38, height: 38 }}
          >
            <img src={subscription_user} alt="avatar" />
          </figure>
          <div className="ml-3">
            <h4 className="text-15 font-semibold">Claire Simpson</h4>
            <p className="text-grey-800 text-sm">Kelham House Hotel</p>
          </div>
        </div>
      </div>
    </Modal.Main>
  );
};

export default CancelMyAccount;

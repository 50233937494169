import React from 'react';
import PropTypes from 'prop-types';

const CardTitle = ({ title, subTitle }) => {
  return (
    <div className="text-center">
      <h1 className="text-2xl font-bold">{title}</h1>
      {subTitle && <p className="text-sm text-grey-800 mt-3">{subTitle}</p>}
    </div>
  );
};

CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string
};

CardTitle.defaultProps = {
  title: ''
};

export default CardTitle;

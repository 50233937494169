/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react-hooks/exhaustive-deps */
import CHOICES from 'choices';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLoginWays } from 'slices/userProfileSlice';
import { isEmpty } from 'utils/utils';
import GoogleLogin from './loginGoogle';
import LoginMicroSoft from './loginMicroSoft';
import { ReactComponent as IconEmail } from 'assets/icons/Icon-Email-22.svg';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import PasswordInput from 'components/inputs/password';
import ErrorMessage from 'components/errorMessage';
import { getFormSchema } from 'components/fieldsAndValidation';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { useCreatePasswordMutation } from 'services/authApi';
import { useSnackbar } from 'components/Snackbar';

const HowYouLogin = () => {
  const dispatch = useDispatch();
  const { loginWays } = useSelector((state) => state.userProfile);
  const [showPassowrdFields, setShowPasswordFields] = useState(false);
  const authToken = useSelector((state) => state.auth.authToken);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const { email: userEmail } = useSelector(
    (state) => state.myInfo.myInformation
  );

  const formSchema = getFormSchema(['password', 'passwordConfirmation']);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const [
    createPassword,
    { isLoading: isPasswordLoading, isError: isPasswordError }
  ] = useCreatePasswordMutation();

  const onSubmit = async (data) => {
    const { password, passwordConfirmation } = data;
    const formData = {
      password: password,
      password2: passwordConfirmation
    };
    await createPassword({ body: formData, token: authToken }).then((res) => {
      if (res?.data?.message) {
        openSuccessSnackBar(res?.data?.message);
        dispatch(getLoginWays());
        setShowPasswordFields(false);
      } else if (res?.error?.data) {
        openErrorSnackBar('Please use a new password');
      }
    });
  };

  useEffect(() => {
    dispatch(getLoginWays());
  }, []);

  return (
    <>
      <div className="card-box py-5 px-6 mt-5">
        <h3 className="text-lg font-semibold">How you login</h3>
        <ul className="mt-10">
          {/* <LoginGoogle />
          <LoginMicroSoft /> */}
          {!isEmpty(loginWays) &&
            loginWays.ways.map((loginTypes, index) => {
              if (loginTypes.signup_type === CHOICES.SignupChoice.GOOGLE) {
                return (
                  <div className="mb-8" key={index}>
                    <GoogleLogin
                      loginWays={loginWays}
                      userEmail={userEmail || 'jonkinger86@gmail.com'}
                    />
                  </div>
                );
              } else if (
                loginTypes.signup_type === CHOICES.SignupChoice.MICROSOFT
              ) {
                return (
                  <div className="mb-8" key={index}>
                    <LoginMicroSoft
                      loginWays={loginWays}
                      userEmail={userEmail || 'jonkinger86@gmail.com'}
                    />
                  </div>
                );
              } else if (
                loginTypes.signup_type === CHOICES.SignupChoice.EMAIL &&
                !showPassowrdFields
              ) {
                return (
                  <div className="list-hover " key={index}>
                    <div className="list-hover__container">
                      <figure className="general-settings__card-list__avatar-container p-2">
                        <IconEmail width={20} height={24} />
                      </figure>
                      <div className="ml-4">
                        <h4 className="text-base-1 font-semibold">
                          Your Email & Password
                        </h4>
                        <p className="text-sm text-grey-700 mt-1">
                          You'll be prompted to set up a password
                        </p>
                        {loginWays?.preferred_signup_type !==
                          CHOICES.SignupChoice.EMAIL && (
                          <PrimaryButton
                            className="btn btn--primary text-13 mt-4"
                            style={{ padding: '6px 20px' }}
                            label={'Switch to email'}
                            onClick={() => setShowPasswordFields(true)}
                          />
                        )}
                      </div>
                    </div>
                    {loginWays?.preferred_signup_type ===
                      CHOICES.SignupChoice.EMAIL && (
                      <div
                        className="absolute left-0 bg-secondary text-white text-11 font-medium rounded-t-lg"
                        style={{ padding: '2px 10px', top: -20 }}
                      >
                        {' '}
                        You're using this
                      </div>
                    )}
                  </div>
                );
              }
            })}
          {showPassowrdFields && (
            <li className="list-hover">
              <div className="list-hover__container">
                <figure className="general-settings__card-list__avatar-container">
                  <IconEmail />
                </figure>
                <div className="ml-4">
                  <h4 className="text-base-1 font-semibold text-grey-900-secondary">
                    You will login with{' '}
                    <span className="font-normal">{userEmail}</span>
                  </h4>
                </div>
              </div>
              <div className="w-9/12" style={{ marginLeft: 72 }}>
                <div className={`${isPasswordError ? 'mt-7' : 'mt-5'}`}>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <PasswordInput
                      name="password"
                      placeholder="Enter a new password"
                      error={errors.password && touchedFields.password}
                      register={register}
                    />
                    {errors.password && touchedFields.password && (
                      <ErrorMessage message={errors.password.message} />
                    )}
                    <div className="mt-7">
                      <PasswordInput
                        name="passwordConfirmation"
                        placeholder="Confirm your password"
                        error={
                          errors.passwordConfirmation &&
                          touchedFields.passwordConfirmation
                        }
                        register={register}
                      />
                    </div>
                    {errors.passwordConfirmation &&
                      touchedFields.passwordConfirmation && (
                        <ErrorMessage
                          message={errors.passwordConfirmation.message}
                        />
                      )}

                    <div style={{ marginTop: '5px' }}></div>
                    <div className="flex items-center gap-3 mt-5">
                      <CustomColorButton
                        className="btn btn--primary text-base-1 btn--md min-w-170"
                        label="Save Password"
                        type="submit"
                        isLoading={isPasswordLoading}
                        enableLoader={true}
                      />
                      <PrimaryButton
                        className="btn btn--grey text-base-1 btn--md min-w-120"
                        label={'Cancel'}
                        type="button"
                        onClick={() => setShowPasswordFields(false)}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default HowYouLogin;

/* eslint-disable no-unused-vars */
import Select from 'components/inputs/select';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import QuestionHeader from '../../../../component/questionHeader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object } from 'yup';
import { requiredSelectMsg } from 'utils/messages';
import { isEmpty } from 'utils/utils';
import Error from 'components/errorMessage';
import {
  createAnswer,
  DeleteQuestion,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm,
  setSelectedQuestion,
  updateAnswer
} from 'slices/questionSlice';
import { Loader } from 'components/loader';
import { useParams } from 'react-router-dom';
import OverLayLoader from 'components/loader/overLayLoader';
import CHOICES from 'choices';
import { questionTypes } from 'utils/questions';

const DROPDOWN = CHOICES.QuestionChoices.DROPDOWN;

const DropdownQuestionAdded = ({
  questionData,
  title,
  answer,
  deleteQuestion,
  isBin
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId, box_id } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const nodeRef = useRef();
  const [inputValue, setInputValue] = useState(
    !isEmpty(answer) ? answer[0]?.answer?.description : ''
  );
  const [options, setOptions] = useState(
    questionData?.choice_set?.map((item) => {
      return {
        id: item?.position,
        label: item?.text,
        value: item?.text,
        choice_id: item.id
      };
    })
  );
  const { myInformation } = useSelector((state) => state.myInfo);

  useEffect(() => {
    setInputValue(!isEmpty(answer) ? answer[0]?.answer?.description : '');
    setOptions(
      questionData?.choice_set?.map((item) => {
        return {
          id: item?.position,
          label: item?.text,
          value: item?.text,
          choice_id: item.id
        };
      })
    );
  }, [answer]);

  const formSchema = {
    options: object().nullable().required(requiredSelectMsg('options'))
  };

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = () => {
    setIsLoading(false);
  };

  const onDeleteCallback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    deleteQuestion(questionId);
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(
      DeleteQuestion({
        questionId: questionId,
        box_id,
        callback: onDeleteCallback
      })
    );
  };
  const onSubmit = async (data) => {
    if (isEmpty(answer)) {
      setIsLoading(true);
      const formData = {
        answer: { description: data.value, choice_id: data.choice_id },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(createAnswer({ body: formData, stageId, callback }));
    } else {
      setIsLoading(true);
      const formData = {
        answer: { description: data.value, choice_id: data.choice_id },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(
        updateAnswer({
          body: formData,
          answerId: answer[0]?.id,
          stageId,
          callback
        })
      );
    }
  };

  const handleScroll = () => {
    nodeRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const onEdit = () => {
    const editQuestion = questionTypes.filter((item) => item.type === DROPDOWN);
    dispatch(setIsEdit(true));
    dispatch(setEditedData(questionData));
    dispatch(setIsShowQuestionForm(true));
    dispatch(setSelectedQuestion(editQuestion[0]));
  };

  return (
    <>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          showConfirmation={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this question?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ height: 'max-content' }}
          isLoading={isLoading}
        />
      )}
      <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
        {isLoading && <OverLayLoader />}
        <QuestionHeader
          label={title}
          isShowMoreIcon={true}
          onDelete={onDeleteQuestion}
          id={questionData.id}
          isBin={isBin}
          onEdit={onEdit}
        />
        <form
          className="py-4 px-5"
          autoComplete="off"
          onSubmit={handleSubmit(onSubmit)}
          onClick={() => handleScroll()}
          ref={nodeRef}
        >
          <Select
            name="options"
            options={options}
            inputClassName="input-field--xs-34"
            dropDownClassName="p-2"
            placeholder="Select an option"
            containerStyle={{ maxWidth: '75%' }}
            control={control}
            setValue={setValue}
            selectedAnswer={inputValue}
            onSubmit={onSubmit}
            readOnly={true}
            isBin={isBin}
          />
        </form>
      </div>
    </>
  );
};

export default DropdownQuestionAdded;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import TextInputField from 'components/inputs/textField';
import Error from 'components/errorMessage';
import { useDispatch, useSelector } from 'react-redux';
import { addNewLabel, setLabels } from 'slices/labelsSlice';
import { isEmpty } from 'utils/utils';
import { useAnalytics } from 'use-analytics';

const AddLabel = (props) => {
  const { addLabel, onClose } = props;
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { allLabels, error } = useSelector((state) => state.labels);
  const [isError, setIsError] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [labelData, setLabelData] = useState(allLabels);
  const { track } = useAnalytics();
  // const { analytics } = useSelector((state) => state.segments);
  const dispatch = useDispatch();

  useEffect(() => {
    setLabelData(allLabels);
  }, [allLabels]);

  useEffect(() => {
    if (!isEmpty(error)) {
      setIsLoading(false);
    }
  }, [error]);

  const callback = (data) => {
    track(
      'label_created',
      {
        name: data.label_name
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(setLabels([...labelData, data]));
    setIsLoading(false);
    setInputValue('');
    onClose();
  };

  const onCancel = (e) => {
    e.preventDefault();
    setIsError('');
    setInputValue('');
    onClose();
  };

  const handleChange = (e) => {
    setIsError('');
    setInputValue(e.target.value);
  };

  const onError = () => {
    setIsLoading(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (inputValue === '') {
      setIsError('label name cannot be empty');
    } else {
      setIsLoading(true);
      dispatch(
        addNewLabel(
          {
            body: {
              label_name: inputValue,
              sub_organization: subOrganizationId
            },
            callback: callback
          },
          () => {},
          onError
        )
      );
    }
  };

  return (
    <Modal.Main open={addLabel} className="modal--CustomBox">
      <Modal.Header
        title="Create label"
        onClose={!isLoading ? onCancel : () => {}}
      />
      <Modal.Body style={{ padding: '25px 30px 30px' }}>
        <form autoComplete="off">
          <label htmlFor="labelName" className="text-15 font-semibold">
            Label name
          </label>
          <TextInputField
            placeholder="Type name"
            className="input-field--md mt-2"
            name="labelName"
            value={inputValue}
            onChange={(e) => handleChange(e)}
            error={!isEmpty(isError) ? true : false}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onSubmit(e);
              }
            }}
          >
            {!isEmpty(isError) && <Error message={isError} />}
            {!isEmpty(error) && <Error message={error[0]} />}
          </TextInputField>
          <div className="flex items-center justify-end gap-3 mt-10">
            <CustomColorButton
              className="btn--grey btn--md w-1/4"
              onClick={(e) => onCancel(e)}
              label="Cancel"
              isLoading={isLoading}
            />
            <PrimaryButton
              label="Create"
              className="btn--md w-1/4"
              enableLoader
              loaderInline
              isLoading={isLoading}
              disabled={isLoading}
              onClick={(e) => onSubmit(e)}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

AddLabel.propTypes = {
  addLabel: PropTypes.bool,
  onClose: PropTypes.func
};

export default AddLabel;

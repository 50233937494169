/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { ReactComponent as MoreIcon } from 'assets/icons/icon-more-round.svg';
import editIcon from 'assets/icons/icon-edit.svg';
import useClickOutside from 'hooks/useOutsideClick';
import EditQuestionItem from '../editQuestionItem';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-select-delete.svg';
import CHOICES from 'choices';

const { VALUE } = CHOICES.QuestionChoices;

const QuestionItem = ({
  label,
  renderSvg,
  onDelete,
  id,
  type,
  questionData,
  onEdit
}) => {
  const nodeRef = useRef();
  const { isTeamMember } = useGetUserRole();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useClickOutside(() => setOpenDropdown(false), nodeRef.current);

  return (
    <>
      {/* {isEdit ? (
        <EditQuestionItem
          renderSvg={renderSvg}
          question={label}
          onClose={() => setIsEdit(false)}
          id={id}
        />
      ) : ( */}
      <div
        className={`flex justify-between py-2 px-3 items-center ${
          openDropdown ? 'bg-grey-90 rounded-md' : ''
        }`}
      >
        <div className="flex gap-2 items-center">
          {renderSvg && typeof renderSvg === 'function' && (
            <figure
              className={`${
                !openDropdown ? 'bg-grey-100' : ''
              } flex justify-center items-center`}
              style={{ width: 30, height: 30, borderRadius: 5 }}
            >
              {renderSvg()}
            </figure>
          )}
          <h3 className="text-15 font-medium">{label}</h3>
        </div>
        {type !== VALUE && (
          <div
            className="relative cursor-pointer p-2"
            onClick={() => setOpenDropdown(true)}
            ref={nodeRef}
          >
            <MoreIcon />
            <div style={{ height: 100, position: 'absolute', width: '100%' }}>
              <div
                className={`dropdown-menu dropdown-menu--more ${
                  openDropdown ? 'open' : ''
                }`}
                style={{ top: 10, minWidth: 180 }}
              >
                <div
                  className="dropdown-menu__list dropdown-menu__list--sm"
                  onClick={() => {
                    onEdit(type, questionData);
                  }}
                >
                  <img
                    src={editIcon}
                    alt=""
                    className="mr-2"
                    style={{ width: 12, height: 12 }}
                  />
                  Edit question
                </div>
                <AccessRestricted
                  show={isTeamMember}
                  alignment="left"
                  customStyle={{
                    bottom: '-9px',
                    left: 'calc(50% - 288px)'
                  }}
                >
                  <div
                    className={`dropdown-menu__list dropdown-menu__list--sm ${
                      isTeamMember ? 'blocked' : 'red-text'
                    }`}
                    onClick={(e) => {
                      if (!isTeamMember) {
                        e.stopPropagation();
                        setOpenDropdown(false);
                        onDelete(id);
                      }
                    }}
                  >
                    <DeleteIcon
                      className="mr-2"
                      style={{ width: 12, height: 12 }}
                    />
                    Delete question
                  </div>
                </AccessRestricted>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* )} */}
    </>
  );
};

export default QuestionItem;

import CustomToolTip from 'components/CustomTooltip';
import dragAndDropIcon from 'assets/icons/icon-drag-and-drop.svg';
import checkIcon from 'assets/icons/icon-check-fill.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createDataFields,
  deleteDataCollectionItem,
  setDataFields,
  updateDataItemFields
} from 'slices/ai-assistant/dataCollection/slice';
import minusIcon from 'assets/icons/icon-minus-fill.svg';
import TextInputField from 'components/inputs/textField';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';
import { Loader } from 'components/loader';
import { setDataCollectionErrors } from 'slices/ai-assistant/assistant/slice';

const DataCollectionList = (props) => {
  const dispatch = useDispatch();
  const { currentVersionId } = useSelector((state) => state.assistant);
  const { data_fields } = useSelector((state) => state.dataCollection);
  const nodeRef = useRef(null);
  const inputRef = useRef(null);
  const {
    id,
    value,
    dragStart,
    assistant_id,
    item,
    isComplianceField,
    index,
    HandleInputChange,
    isLoading,
    handleLoading
  } = props;
  const dataCollection = useSelector((state) => state.dataCollection);
  const { compliance_enabled } = dataCollection;
  const [iconLoading, setIconLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [active, setActive] = useState(false);
  const [text, setText] = useState();
  const [hover, setHover] = useState(false);
  useClickOutside(() => setActive(false), nodeRef.current);

  const dataCollectionErrors = useSelector(
    (state) => state.assistant.dataCollectionErrors
  );
  const { openSuccessSnackBar } = useSnackbar();
  const successResponse = () => {
    handleLoading(false);
    setShowDelete(false);
  };
  const failedResponse = () => {
    handleLoading(false);
  };

  const lengthOfDataItems = () => {
    let length = 0;
    data_fields.map((item, index) => {
      if (item.status !== 'New') length += 1;
    });
    return length;
  };
  const onRemoveMessage = (currentIndex) => {
    if (item.status === 'New') {
      dispatch(
        setDataFields(
          data_fields.filter((item, index) => {
            return currentIndex !== index;
          })
        )
      );
      setShowDelete(false);
    } else {
      handleLoading(true);
      dispatch(
        deleteDataCollectionItem(
          { data_field_id: item.id },
          successResponse,
          failedResponse
        )
      );
    }
  };
  useEffect(() => {
    if (item.status === 'New' && index == data_fields.length - 1) {
      setActive(true);
    }
  }, [item.status]);

  useEffect(() => {
    const numericId = parseInt(id);
    const numericDragStart = parseInt(dragStart);
    if (!dragStart) {
      setHover(false);
    } else if (numericId === numericDragStart)
      setHover(numericId === numericDragStart);
  }, [dragStart, id]);

  const onMouseEnter = () => {
    if (parseInt(id) === parseInt(dragStart)) {
      setHover(true);
      return;
    }
    setHover(true);
  };
  const onMouseLeave = () => {
    if (parseInt(id) === parseInt(dragStart)) {
      setHover(true);
      return;
    }
    setHover(false);
  };

  useEffect(() => {
    if (active) {
      inputRef?.current?.focus();
    }
  }, [active]);

  const HandleChange = (e, index, compliaceField) => {
    if (item.status === 'New') {
      let currentData_fields = [...data_fields];
      if (compliaceField)
        currentData_fields.splice(index, 1, {
          ...currentData_fields[index],
          need_compliance: e.target.checked
        });
      else
        currentData_fields.splice(index, 1, {
          ...currentData_fields[index],
          is_mandatory: e.target.checked
        });

      dispatch(setDataFields(currentData_fields));
      return;
    }
    let payload = {
      version_id: currentVersionId,
      data_field_id: item.id,
      index: index
    };
    if (compliaceField) payload.need_compliance = e.target.checked;
    else payload.is_mandatory = e.target.checked;
    dispatch(updateDataItemFields(payload, sucessCb, failedCb));
  };

  const sucessCb = (resData) => {
    let currErrors = {};
    let dataFieldErrors = {};
    if (compliance_enabled) {
      currErrors = {
        ...dataCollectionErrors,
        gdpr_field: resData?.some((i) => i.need_compliance)
      };
    }
    if (resData?.field_name && !isEmpty(resData?.field_name)) {
      dataFieldErrors = {
        ...dataCollectionErrors,
        data_field: true
      };
    }
    dispatch(setDataCollectionErrors({ ...currErrors, ...dataFieldErrors }));
    setIconLoading(false);
    setActive(false);
    openSuccessSnackBar('Updated successfully');
  };

  const failedCb = () => {
    setIconLoading(false);
    setActive(false);
  };
  const onSave = () => {
    setIconLoading(true);
    let payload = {
      ai_bot_id: assistant_id,
      version_id: currentVersionId,
      index: index,
      is_mandatory: item.is_mandatory,
      field_name: text,
      need_compliance: item.need_compliance
    };
    if (item.status === 'New') {
      dispatch(createDataFields(payload, sucessCb, failedCb));
    } else {
      let payload = {
        version_id: currentVersionId,
        data_field_id: item.id,
        field_name: text,
        index: index
      };
      dispatch(updateDataItemFields(payload, sucessCb, failedCb));
    }
  };
  return (
    <div
      ref={nodeRef}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={`data-collection-list bot-chat-message__list ${
        active ? 'active' : ''
      } ${hover ? 'hover' : ''}`}
      style={isComplianceField ? { paddingBottom: '13px' } : {}}
    >
      {isComplianceField && item?.status !== 'New' && (
        <input
          name="checkbox"
          id={`${index}`}
          type="checkbox"
          className="custom-checkbox custom-checkbox--xs"
          onChange={(e) => {
            HandleChange(e, index, true);
          }}
          checked={item?.need_compliance}
        />
      )}
      {!isComplianceField && (
        <CustomToolTip
          tooltipText="Mandatory"
          position="top"
          tooltipStyle={{
            minWidth: 96,
            left: '50%',
            bottom: '126%',
            padding: 5
          }}
        >
          <input
            id={`${index}`}
            type="checkbox"
            className="custom-checkbox custom-checkbox--xs"
            onChange={(e) => {
              HandleChange(e, index, false);
            }}
            checked={item?.is_mandatory}
          />
        </CustomToolTip>
      )}

      {isComplianceField && item?.status !== 'New' && (
        <input
          type="text"
          className="input-field ml-4"
          value={item?.field_name}
          ref={inputRef}
          onChange={(e) => {
            HandleInputChange(item, e, index);
          }}
          readOnly
          disabled={true}
        />
      )}
      {!isComplianceField && (
        <>
          <div
            className="bot-chat-message__list__container"
            style={{ marginLeft: '8px' }}
          >
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (!isEmpty(text)) onSave();
              }}
            >
              <TextInputField
                autoComplete={'off'}
                name="Botlist"
                type="text"
                className="input-field pl-[5px] bot-chat-message__input"
                value={item?.field_name}
                ref={inputRef}
                onChange={(e) => {
                  HandleInputChange(item, e, index);
                  setText(e.target.value);
                }}
                placeholder="Please provide data you want to collect"
              />
            </form>
            <CustomToolTip
              tooltipText="Click to edit"
              position="top"
              tooltipStyle={{
                minWidth: 96,
                left: '18%',
                bottom: '107%',
                padding: 5
              }}
            >
              <div
                className="input-field bot-chat-message__input bot-chat-message__input--container"
                onClick={() => {
                  setActive(true);
                }}
              >
                {value ? (
                  value
                ) : (
                  <span className="text-[#4c5254]">
                    Please provide data you want to collect
                  </span>
                )}
              </div>
            </CustomToolTip>
            <div className="action-icons-container">
              <CustomToolTip
                tooltipText={
                  item.status !== 'New' && lengthOfDataItems() === 1
                    ? 'You must collect at least one piece of data'
                    : 'Delete'
                }
                position="top"
                tooltipStyle={{ maxWidth: 150, left: '56%', padding: 5 }}
              >
                <div
                  className="delete-icon-wrapper cursor-pointer"
                  onClick={() => {
                    if (item.status !== 'New') setShowDelete(true);
                    else onRemoveMessage(index);
                  }}
                  style={
                    item.status !== 'New' && lengthOfDataItems() === 1
                      ? {
                          pointerEvents: 'none',
                          opacity: 0.3
                        }
                      : {}
                  }
                >
                  <img
                    src={minusIcon}
                    alt="delete-icon"
                    style={{ width: 28, height: 28 }}
                  />
                </div>
              </CustomToolTip>
              <CustomToolTip
                tooltipText="Save"
                position="top"
                tooltipStyle={{ minWidth: 70, left: '64%', padding: 5 }}
              >
                <div
                  className={
                    'save-icon-wrapper ml-3 ' +
                    (isEmpty(text)
                      ? 'disabled cursor-not-allowed'
                      : 'cursor-pointer')
                  }
                  onClick={() => {
                    !isEmpty(text) ? onSave() : () => {};
                  }}
                >
                  {iconLoading ? (
                    <Loader loaderStyle={{ marginTop: '-16px' }} />
                  ) : (
                    <img src={checkIcon} alt="check-icon" />
                  )}{' '}
                </div>
              </CustomToolTip>
            </div>
          </div>

          {item.status !== 'New' && (
            <CustomToolTip
              tooltipText="Drag to reorder"
              position="top"
              tooltipStyle={{
                minWidth: 115,
                left: '79%',
                bottom: '145%',
                padding: 5
              }}
            >
              <div className="drag-and-drop-container">
                <img src={dragAndDropIcon} alt="" />
              </div>
            </CustomToolTip>
          )}
        </>
      )}
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          onRemoveMessage(index);
        }}
        title={'Are you sure you want to delete? '}
        description=""
        isLoading={isLoading}
      />
    </div>
  );
};

export default DataCollectionList;

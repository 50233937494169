/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import AutomationStuffWhen from '../../components/when';
import AutomationStuffThen from '../../components/then';
import { useParams } from 'react-router';
import {
  getConnectedChannel,
  sendAutomation,
  sendFacebookAutomationAssignUser,
  setEmailError,
  getFbLeadsAdsConnectedChannels,
  sendFacebookLeadAdsAutomationAssignUser,
  getAllEnquiryAndSubEnquiry,
  setIsPreview
} from 'slices/automationStuffSlice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { setTypeofAutomation } from 'slices/automationStuffSlice';
import CHOICES from 'choices';
import ErrorMessage from 'components/errorMessage';
// import { isEmpty } from 'utils/utils';

const { WEBFORM, FACEBOOK, FACEBOOK_LEAD_ADS, INSTAGRAM, EBOT, ZAPIER, SMS } =
  CHOICES.ChannelChoices;

const AssigneToteamSetup = ({
  channelType,
  title,
  tempChannelType,
  changeAutomationName
}) => {
  const parentRef = useRef();
  const { connectedChannels, enquiryAndSubEnquiries, isPreview } = useSelector(
    (state) => state.automationStuff
  );
  const { typeofAutomation } = useSelector((state) => state.automationStuff);
  const { onEdit } = useSelector((state) => state.automationStuff);
  const [assignedUserInfo, setAssignedUserInfo] = useState();
  const [isError, setIsError] = useState('');
  const [automationName, setAutomationName] = useState(title);
  // const { usersList } = useSelector((state) => state.usersInfo);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const users = organizationUser?.results?.map((item) => {
    return {
      label: item?.name,
      value: item?.user_id,
      avatarPic: item?.avatar
    };
  });

  const [selectedChannelOption, setSelectedChannelOption] = useState();
  const { channelId } = useParams();
  const dispatch = useDispatch();

  const [filterdConnectedChannel, setFilteredConnectedChannel] = useState([]);
  const [selectedBotOptions, setSelectedBotOptions] = useState([]);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    if (isEmpty(onEdit)) {
      setAssignedUserInfo();
      setSelectedChannelOption();
      setSelectedBotOptions([]);
    }
  }, [onEdit]);

  useEffect(() => {
    setAutomationName(title);
  }, [title]);

  useEffect(() => {
    if (channelType === EBOT) {
      const payload = {
        subOrgId: subOrganizationId,
        channelId: channelId
      };
      dispatch(getAllEnquiryAndSubEnquiry(payload));
    }
  }, [channelType]);

  useEffect(() => {
    const payload = {
      channelId
    };
    if (channelType === WEBFORM) {
      dispatch(getConnectedChannel(payload));
    } else if (channelType === FACEBOOK_LEAD_ADS) {
      dispatch(getFbLeadsAdsConnectedChannels(payload));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(assignedUserInfo)) {
      setIsError('');
    }
  }, [assignedUserInfo]);

  useEffect(() => {
    if (!isEmpty(connectedChannels)) {
      setFilteredConnectedChannel(
        connectedChannels?.map((item) => {
          return {
            label: item?.name,
            value: item?.id
          };
        })
      );
      // !isEmpty(newConnectdChannel) && setFilteredConnectedChannel(newConnectdChannel)
    }
  }, [connectedChannels]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === CHOICES.AutomationChoice.ASSIGN_MEMBER &&
      filterdConnectedChannel
    ) {
      const updatedChannelSelect = filterdConnectedChannel.filter((item) => {
        return item.value === onEdit.forms;
      });
      !isEmpty(updatedChannelSelect) &&
        setSelectedChannelOption(updatedChannelSelect[0]);
      if (onEdit.any_web_form) {
        setSelectedChannelOption({ value: 'anyWebform', label: 'Any WebForm' });
      }
      const updatedAssignedUserInfo =
        users &&
        users.filter((item) => {
          return item.value === onEdit?.assign_user;
        });
      !isEmpty(updatedAssignedUserInfo) &&
        setAssignedUserInfo(updatedAssignedUserInfo[0]);
    }
    (channelType === FACEBOOK ||
      channelType === INSTAGRAM ||
      channelType === ZAPIER ||
      channelType === SMS) &&
      setSelectedChannelOption({
        value: 'anyMessage',
        label: 'Any Message'
      });
  }, [filterdConnectedChannel]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === CHOICES.AutomationChoice.ASSIGN_MEMBER &&
      channelType === EBOT
    ) {
      const selectedOptionId = onEdit?.ebot_options.map((item) => item.id);
      setSelectedBotOptions(selectedOptionId);
    }
  }, [onEdit]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === CHOICES.AutomationChoice.ASSIGN_MEMBER &&
      channelType === ZAPIER
    ) {
      setSelectedChannelOption({
        value: 'anyMessage',
        label: 'Any Message'
      });
    }
  }, [onEdit]);

  const checkIsValid = () => {
    let isValid = true;
    let err = {};
    // if (
    //   !isEmpty(automationName) &&
    //   (automationName.length < 4 || automationName.length > 20)
    // ) {
    //   isValid = false;
    // }
    if (isEmpty(automationName)) {
      err = {
        ...err,
        automationName: 'Please add and save your automation name'
      };
      isValid = false;
    }
    if (isEmpty(assignedUserInfo)) {
      setIsError('Please add user you want to assign the enquiry');
      isValid = false;
    }
    dispatch(setEmailError(err));
    return isValid;
  };

  const handleSave = () => {
    if (
      channelType === WEBFORM ||
      channelType === FACEBOOK_LEAD_ADS ||
      channelType === EBOT
    ) {
      const isValid = checkIsValid();

      if (isValid) {
        const payload = {
          type: 'post',
          data: {
            any_web_form: selectedChannelOption?.value === 'anyWebform',
            automation_type: typeofAutomation,
            automation_name: automationName,
            is_active: true,
            connected_channel: channelId,
            assign_user: assignedUserInfo.value
          }
        };
        if (
          selectedChannelOption?.value !== 'anyWebform' &&
          channelType !== FACEBOOK_LEAD_ADS
        ) {
          payload.data['forms'] = selectedChannelOption?.value;
        }
        if (channelType === FACEBOOK_LEAD_ADS) {
          payload.data['fb_lead_ads_form'] = selectedChannelOption?.value;
        }
        if (channelType === EBOT) {
          payload.data['ebot_options'] = selectedBotOptions;
        }
        if (!isEmpty(onEdit)) {
          payload['type'] = 'put';
          payload['data']['id'] = onEdit.id;
          payload['data']['is_active'] = onEdit.is_active;
        }

        if (channelType === WEBFORM || channelType === EBOT) {
          dispatch(sendAutomation(payload));
        } else {
          dispatch(sendFacebookLeadAdsAutomationAssignUser(payload));
        }
      }
    } else if (
      channelType === FACEBOOK ||
      channelType === INSTAGRAM ||
      channelType === ZAPIER ||
      channelType === SMS
    ) {
      const isValid = checkIsValid();
      if (isValid) {
        const payload = {
          type: 'post',
          data: {
            // any_web_form: selectedChannelOption?.value === 'anyWebform',
            automation_type: typeofAutomation,
            automation_name: automationName,
            is_active: true,
            connected_channel: channelId,
            assign_user: assignedUserInfo.value
          }
        };
        if (!isEmpty(onEdit)) {
          payload['type'] = 'put';
          payload['data']['id'] = onEdit.id;
          payload['data']['is_active'] = onEdit.is_active;
        }
        dispatch(sendFacebookAutomationAssignUser(payload));
      }
    }
  };

  return (
    <>
      <div className="mt-6" ref={parentRef}>
        {(channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === ZAPIER ||
          channelType === EBOT ||
          channelType === SMS) && (
          <AutomationStuffWhen
            options={
              channelType === EBOT
                ? enquiryAndSubEnquiries
                : filterdConnectedChannel
            }
            automationName={automationName}
            setAutomationName={setAutomationName}
            selectedOption={
              channelType === EBOT ? selectedBotOptions : selectedChannelOption
            }
            setSelectedOption={
              channelType === EBOT
                ? setSelectedBotOptions
                : setSelectedChannelOption
            }
            channelType={channelType}
            parentRef={parentRef}
            onEdit={onEdit}
            tempChannelType={tempChannelType}
          />
        )}
        {(selectedChannelOption || !isEmpty(selectedBotOptions)) && (
          <AutomationStuffThen
            options={users}
            assignedUserInfo={assignedUserInfo}
            setSelectedOption={setAssignedUserInfo}
            title="Assign to team member"
            changeAutomationName={changeAutomationName}
            automationName={automationName}
          />
        )}
        {isError && <ErrorMessage message={isError} />}
        <div className="flex justify-end mt-2">
          <CustomColorButton
            className="btn--xs btn--grey  mr-2"
            label={`${isPreview ? 'Close Preview' : 'Cancel'}`}
            onClick={() => {
              dispatch(setIsPreview(false));
              dispatch(setTypeofAutomation(''));
            }}
          />
          {(selectedChannelOption || !isEmpty(selectedBotOptions)) &&
            !isPreview && (
              <PrimaryButton
                className="btn--xs"
                label="Save"
                onClick={() => handleSave()}
              />
            )}
        </div>
      </div>
    </>
  );
};

export default AssigneToteamSetup;

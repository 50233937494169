const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_FB_CREDENTIALS = `${BASE_API_URL}/fb_and_ig_messenger/get_facebook_messenger_credentials`;
export const EXCHANGE_FB_AUTH_CODE = `${BASE_API_URL}/fb_and_ig_messenger/sub_organizations/:subOrgId/connected_channels/:channelId/exchange_fb_messenger_oauth_code`;
export const SEND_FB_INSTA_REPLY = `${BASE_API_URL}/fb_and_ig_messenger/send_reply_back_to_the_fb_customer/leads/:leadId/`;

//INSTAGRAM
export const GET_INSTAGRAM_CREDENTIALS = `${BASE_API_URL}/fb_and_ig_messenger/get_instagram_messenger_credentials`;

//FB_LEADS
export const GET_FB_LEAD_CREDENTIALS = `${BASE_API_URL}/facebook_lead_ads/get_facebook_lead_ads_credentials`;
export const EXCHANGE_FB_LEAD_AUTH_CODE = `${BASE_API_URL}/facebook_lead_ads/sub_organizations/:subOrgId/connected_channels/:channelId/exchange_fb_lead_ads_oauth_code`;
export const GET_THE_DATA_MAPPING_FIELDS = `${BASE_API_URL}/leads/get_the_data_mapping_fields`;
export const GET_ADS_FORM_FIELDS = `${BASE_API_URL}/facebook_lead_ads/ads_form_fields/leads/:leadId`;
export const GET_ADS_FORM_QUESTIONS_FIELDS = `${BASE_API_URL}/facebook_lead_ads/sub_organizations/:subOrgId/get_the_question_fields_for_mapping`;
export const SEND_FB_LEAD_ADS_MAP_FIELDS = `${BASE_API_URL}/facebook_lead_ads/custom_map_from_fb_lead_ads_form_fields_to_lead_field/leads/:leadId`;

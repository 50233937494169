import React, { useRef, useState } from 'react';
import CHOICES from 'choices';
import Calendar from 'components/calendar';
import TextInputField from 'components/inputs/textField';
import { ReactComponent as CalenderIcon } from 'assets/icons/icon-date-picker.svg';
import useClickOutside from 'hooks/useOutsideClick';
import { formatDateTime } from 'utils/dateFormat';
import { isEmpty } from 'utils/utils';
import ErrorMessage from 'components/errorMessage';
import moment from 'moment';

const { DATETIME } = CHOICES.QuestionChoices;

const DateTypeForm = ({
  data,
  onDateSelect,
  onStartDateSelect,
  onEndDateSelect,
  isEndDateError,
  isStartDateError
}) => {
  const dropdownRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const [openCalender, setOpenCalender] = useState(false);
  const [openFromCalender, setOpenFromCalender] = useState(false);
  const [openToCalender, setOpenToCalender] = useState(false);
  const [selectDate, setSelectDate] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  useClickOutside(() => {
    setOpenCalender(false),
      setOpenFromCalender(false),
      setOpenToCalender(false);
  }, dropdownRef.current);

  const onSelectDate = (date) => {
    setSelectDate(formatDateTime(date, 'mmm dd, yyyy'));
    onDateSelect(date, data.id);
    setOpenCalender(false);
  };

  const onSelectStartDate = (date) => {
    const DATE = formatDateTime(date, 'yyyy-mm-dd');
    if (endDate) {
      if (moment(endDate).isSameOrAfter(moment(DATE), 'day')) {
        onStartDateSelect(date, data.id);
        setStartDate(formatDateTime(date, 'mmm dd, yyyy'));
        setOpenFromCalender(false);
        return;
      } else {
        setOpenFromCalender(false);
        setOpenToCalender(true);
        setEndDate(undefined);
        onEndDateSelect(undefined, data.id);
        toDateRef?.current?.focus();
        return;
      }
    }
    onStartDateSelect(date, data.id);
    setStartDate(formatDateTime(date, 'mmm dd, yyyy'));
    setOpenFromCalender(false);
    if (!endDate) {
      // if no end date
      setOpenFromCalender(false);
      setOpenToCalender(true);
      fromDateRef?.current?.focus();
    }
  };

  const onSelectEndDate = (date) => {
    setEndDate(formatDateTime(date, 'mmm dd, yyyy'));
    onEndDateSelect(date, data.id);
    setOpenToCalender(false);
    if (!startDate) {
      // if start end date
      setOpenFromCalender(true);
      setOpenToCalender(false);
      toDateRef?.current?.focus();
    }
  };

  const onOpenFromCalender = () => {
    setOpenFromCalender(true);
    setOpenToCalender(false);
  };

  const onOpenToCalender = () => {
    if (!startDate) {
      setOpenFromCalender(true);
      toDateRef?.current?.blur();
      fromDateRef?.current?.focus();
      return;
    }
    setOpenFromCalender(false);
    setOpenToCalender(true);
  };

  return (
    <>
      {data.kind === DATETIME ? (
        <div className="pb-4">
          <label className="input-field-label">{data.text}</label>
          <TextInputField
            name="date"
            placeholder="Select Date"
            className="input-field--type2 input-icon-pos-right"
            onClick={() => setOpenCalender(true)}
            value={selectDate ? selectDate : ''}
            renderIcon={() => (
              <CalenderIcon
                className="cursor-pointer"
                onClick={() => setOpenCalender(true)}
              />
            )}
            iconClassName="input-icon-right"
            style={{ paddingLeft: 0, padding: '9px 15px' }}
            readOnly={true}
          >
            <div
              className={`dropdown-menu ${
                openCalender ? 'open' : ''
              } xxl:right-0 xxl:left-auto xxl:max-w-xs`}
              ref={dropdownRef}
              style={{ top: 50 }}
            >
              <div className="calender-select">
                <Calendar
                  enableFutureDates={true}
                  onDateClick={onSelectDate}
                  selectedDate={new Date(selectDate)}
                  containerStyle={{ width: '100%' }}
                />
              </div>
            </div>
          </TextInputField>
        </div>
      ) : (
        <div className="pb-4">
          <label className="input-field-label">{data.text}</label>
          <div className="flex gap-2" ref={dropdownRef}>
            <div style={{ width: '50%' }}>
              <TextInputField
                ref={fromDateRef}
                name="startDate"
                placeholder="From"
                className="input-field--type2 input-icon-pos-right"
                value={startDate ? startDate : ''}
                onClick={onOpenFromCalender}
                renderIcon={() => (
                  <CalenderIcon
                    className="cursor-pointer"
                    onClick={onOpenFromCalender}
                  />
                )}
                iconClassName="input-icon-right"
                style={{ paddingLeft: 0, padding: '9px 15px' }}
                readOnly={true}
                error={!isEmpty(isStartDateError) ? true : false}
              >
                <div
                  className={`dropdown-menu ${openFromCalender ? 'open' : ''}`}
                  style={{ top: 50 }}
                >
                  <div className="calender-select">
                    <Calendar
                      enableFutureDates={true}
                      onDateClick={onSelectStartDate}
                      selectedDate={new Date(startDate)}
                      containerStyle={{ width: '100%' }}
                    />
                  </div>
                </div>
              </TextInputField>
              {!isEmpty(isStartDateError) && (
                <ErrorMessage error={isStartDateError} />
              )}
            </div>
            <div style={{ width: '50%' }}>
              <TextInputField
                ref={toDateRef}
                name="endDate"
                placeholder="To"
                className="input-field--type2 input-icon-pos-right"
                value={endDate ? endDate : ''}
                onClick={onOpenToCalender}
                renderIcon={() => (
                  <CalenderIcon
                    className="cursor-pointer"
                    onClick={onOpenToCalender}
                  />
                )}
                iconClassName="input-icon-right"
                style={{ paddingLeft: 0, padding: '9px 15px' }}
                readOnly={true}
                error={!isEmpty(isEndDateError) ? true : false}
              >
                <div
                  className={`dropdown-menu ${openToCalender ? 'open' : ''}`}
                  style={{ top: 50 }}
                >
                  <div className="calender-select">
                    <Calendar
                      enableFutureDates={true}
                      onDateClick={onSelectEndDate}
                      selectedDate={new Date(endDate)}
                      containerStyle={{ width: '100%' }}
                      minDate={new Date(startDate)}
                    />
                  </div>
                </div>
              </TextInputField>
              {!isEmpty(isEndDateError) && (
                <ErrorMessage error={isEndDateError} />
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DateTypeForm;

export const checkValid = (name, value) => {
  if (!isNaN(value) && value !== '') {
    if (name === 'side_spacing' || name === 'bottom_spacing') {
      if (0 <= value && value <= 100) {
        return {
          isValid: true,
          errorMessage: ''
        };
      } else {
        return {
          isValid: false,
          errorMessage: 'Value should be less than or equal to 100'
        };
      }
    } else if (
      name === 'automatically_open_the_bot_on_desktop_delay' ||
      name === 'automatically_open_the_bot_on_mobile_delay'
    ) {
      if (0 <= value && value <= 10) {
        return {
          isValid: true,
          errorMessage: ''
        };
      } else {
        return {
          isValid: false,
          errorMessage: 'Value should be less than or equal to 10'
        };
      }
    }
  } else {
    return {
      isValid: false,
      errorMessage: 'Value should be a number'
    };
  }
  return {
    isValid: true,
    errorMessage: ''
  };
};

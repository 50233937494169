/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, Fragment } from 'react';

// import RightArrow from './icons/RightArrow';
// import LeftArrow from './icons/LeftArrow';

import styles from './index.module.scss';

/**
 * This component is a simple image MCQ carousel. The card has a minimum size of 350 px
 *
 * @param {Array[Objects]} items should be an array of objects were each objects should have the following structure
 * {
 *  title
 *  sub_title
 *  highlight_text
 *  price
 *  button_text
 *  url
 * }
 * @param {color code} color background color of the buttons
 * @param {integer pixels} cardSize is the size of the card. The card will be a square
 * @param {color code} buttonHoverColor is the color that the buttons have to look when they are being hovered
 */
export default function ImageMCQCarousel({
  items,
  buttonColor = '#11a1ae',
  buttonTextColor = '#ffffff',
  cardSize = 350,
  buttonHoverColor = '#0e838d',
  activeItem,
  onActiveItemChange,
  currency
}) {
  const tempItems =
    items && typeof items === 'object' && items.length > 0 ? items : [];

  const defaultTextColor = '#000000';
  /**
   * If card size is not provided or if card size is less than 350 then set the card size as 350
   */
  const size = cardSize && cardSize > 350 ? cardSize : 350;
  /**
   * Based on the size of the card we need to calculate the offet that will to be used
   * when switching between the cards. The default value is 246, when size is 350
   */
  const offset = cardSize && cardSize > 350 ? 246 + (cardSize - 350) : 246;

  const [showLoader, setShowLoader] = useState(false);
  const [downloadedImages, setDownloadedImages] = useState({});
  const [hoverCtrl, setHoverCtrl] = useState({});
  const [swipeCtrl, setSwipeCtrl] = useState({});
  const itemCount = tempItems.length;

  /**
   * A function used to lazy load images
   * https://stackoverflow.com/questions/51607043/how-to-lazy-load-the-background-image-inside-the-inline-style-property-react
   * @param {Array} urls array of objects that has the URL string and ID
   * @param {Function} callBack a call back function that gets called after downloading all the images
   */
  const downloadImage = (imageItems, callback) => {
    // if (!urls || urls.length < 0)
    //   return;
    // let count = 0;

    // urls.forEach(url => {
    //   const imageLoader = new Image();
    //   imageLoader.src = url;
    //   imageLoader.onload = () => {
    //     count++;
    //     setDownloadedImages(prevState => {
    //       const tempState = JSON.parse(JSON.stringify(prevState));
    //       tempState[url] = url;
    //       return tempState;
    //     });
    //     if (callBack && count === urls.length) {
    //       callBack();
    //     }
    //   };
    // });
    if (!imageItems || imageItems.length <= 0) {
      return;
    }
    let count = 0;
    const response = {};
    const downloadSingle = async (url) => {
      const imageResponse = await fetch(url);
      const data = await imageResponse.blob();
      const reader = new FileReader();
      reader.onloadend = function () {
        response[url] = reader.result;
        count += 1;
        if (count === imageItems.length && callback) {
          callback(response);
        }
      };
      reader.readAsDataURL(data);
    };
    imageItems.forEach((url) => {
      downloadSingle(url);
    });
  };

  useEffect(() => {
    if (
      items &&
      items.length > 0 &&
      activeItem >= 0 &&
      activeItem <= items.length - 1
    ) {
      if (items[activeItem].url && !downloadedImages[items[activeItem].url]) {
        setShowLoader(true);
        const imagesDownload = [items[activeItem].url];
        downloadImage(imagesDownload, (data) => {
          setShowLoader(false);
          setDownloadedImages((prevState) => {
            const tempState = { ...prevState, ...data };
            return tempState;
          });
        });
      }
      const imagesToDownload = [];

      if (
        items[activeItem + 1] &&
        !downloadedImages[items[activeItem + 1].url]
      ) {
        imagesToDownload.push(items[activeItem + 1].url);
      }
      if (
        items[activeItem - 1] &&
        !downloadedImages[items[activeItem - 1].url]
      ) {
        imagesToDownload.push(items[activeItem - 1].url);
      }
      if (
        items[activeItem + 2] &&
        !downloadedImages[items[activeItem + 2].url]
      ) {
        imagesToDownload.push(items[activeItem + 2].url);
      }
      if (
        items[activeItem - 2] &&
        !downloadedImages[items[activeItem - 2].url]
      ) {
        imagesToDownload.push(items[activeItem - 2].url);
      }
      if (imagesToDownload.length > 0)
        downloadImage(imagesToDownload, (data) => {
          setDownloadedImages((prevState) => {
            const tempState = { ...prevState, ...data };
            return tempState;
          });
        });
    }
  }, [activeItem, items]);

  const next = () => {
    if (activeItem === itemCount - 1) {
      return;
    }
    onActiveItemChange(activeItem + 1);
  };

  const previous = () => {
    if (activeItem === 0) {
      return;
    }
    onActiveItemChange(activeItem - 1);
  };

  const onHover = (buttonNumber) => {
    setHoverCtrl({ [buttonNumber]: true });
  };

  const onTouchStart = (event) => {
    setSwipeCtrl({
      startX: event.nativeEvent.touches[0].clientX,
      startY: event.nativeEvent.touches[0].clientY
    });
  };

  const onTouchMove = (event) => {
    const clientX = event.nativeEvent.touches[0].clientX;
    const clientY = event.nativeEvent.touches[0].clientY;
    setSwipeCtrl((prevState) => {
      const temp = JSON.parse(JSON.stringify(prevState));
      temp.endX = clientX;
      temp.endY = clientY;
      return temp;
    });
  };

  const onTouchEnd = () => {
    if (swipeCtrl.startX > swipeCtrl.endX) {
      next();
    } else {
      previous();
    }
  };

  return (
    <div
      // style={{
      // 	width: size,
      // 	maxWidth: size,
      // 	minWidth: size
      // }}
      className={styles['mcq-slide']}
    >
      <div className={styles['mcq-slide__wrap']}>
        <div
          className={styles['mcq-slide__wrap__scroll-wrapper']}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          style={{
            transform: `translateX(${
              activeItem > 0 ? -(activeItem * offset) : 0
            }px)`
            // transition: '0.4s'
          }}
        >
          {!showLoader && (
            <Fragment>
              {tempItems.map((option, index) => (
                <Fragment key={index}>
                  <div
                    style={{
                      backgroundImage: `url("${
                        downloadedImages[option.url] || ''
                      }")`,
                      width: size - (activeItem === index ? 100 : 120),
                      height: size - (activeItem === index ? 100 : 120),
                      minWidth: size - (activeItem === index ? 100 : 120),
                      minHeight: size - (activeItem === index ? 100 : 120)
                    }}
                    className={
                      activeItem === index
                        ? styles['mcq-slide__item__active']
                        : styles['mcq-slide__item']
                    }
                  >
                    <div
                      className={styles['mcq-slide__item__overlay']}
                      style={{
                        backgroundColor: option['overlay_color']
                          ? option['overlay_color']
                          : undefined,
                        opacity:
                          typeof option['overlay_opacity'] === 'number'
                            ? option['overlay_opacity']
                            : undefined
                      }}
                    ></div>
                    <div className={styles['mcq-slide__item__tag-container']}>
                      <div>
                        {option['highlight_text'] && (
                          <div
                            className={styles['mcq-slide__item__tag']}
                            style={{
                              backgroundColor: buttonColor,
                              color: buttonTextColor
                            }}
                          >
                            {option['highlight_text']}
                          </div>
                        )}
                      </div>
                    </div>
                    <div
                      className={styles['mcq-slide__item__title']}
                      style={{
                        color: option.text_color
                          ? option.text_color
                          : defaultTextColor
                      }}
                    >
                      {option.title}
                    </div>
                    <div
                      className={styles['mcq-slide__item__sub-title']}
                      style={{
                        color: option.text_color
                          ? option.text_color
                          : defaultTextColor
                      }}
                    >
                      {option['sub_title']}
                    </div>
                    <div className={styles['mcq-slide__item__footer']}>
                      <div
                        className={styles['slide-footer-currency']}
                        style={{
                          color: option.text_color
                            ? option.text_color
                            : defaultTextColor
                        }}
                      >
                        {currency && option.price ? (
                          <Fragment>
                            <span
                              dangerouslySetInnerHTML={{ __html: currency }}
                            ></span>
                            &nbsp;
                          </Fragment>
                        ) : (
                          ''
                        )}
                        {option.price}
                      </div>
                      {option['button_text'] && (
                        <button
                          onMouseOut={() => setHoverCtrl({})}
                          onMouseOver={() => onHover(3)}
                          type="button"
                          className={styles['slide-footer-btn']}
                          style={{
                            backgroundColor: hoverCtrl['3']
                              ? buttonHoverColor
                              : buttonColor,
                            color: buttonTextColor
                          }}
                        >
                          {option['button_text']}
                        </button>
                      )}
                    </div>
                  </div>
                </Fragment>
              ))}
            </Fragment>
          )}
        </div>
      </div>
      {!showLoader && activeItem > 0 && (
        <div
          style={{
            backgroundColor: hoverCtrl['0'] ? buttonHoverColor : buttonColor,
            color: buttonTextColor
          }}
          className={`${styles['mcq-slide__controls-item']} ${styles['mcq-slide__controls-item__left']}`}
          onClick={previous}
          onMouseOver={() => onHover(0)}
          onMouseOut={() => setHoverCtrl({})}
        >
          <i className={styles['carosel-action-left-icon']}>
            {/* <LeftArrow color={buttonTextColor} /> */}
          </i>
        </div>
      )}
      {!showLoader && activeItem < itemCount - 1 && (
        <div
          style={{
            backgroundColor: hoverCtrl['1'] ? buttonHoverColor : buttonColor,
            color: buttonTextColor
          }}
          className={`${styles['mcq-slide__controls-item']} ${styles['mcq-slide__controls-item__right']}`}
          onClick={next}
          onMouseOver={() => onHover(1)}
          onMouseOut={() => setHoverCtrl({})}
        >
          <i className={styles['carosel-action-right-icon']}>
            {/* <RightArrow color={buttonTextColor} /> */}
          </i>
        </div>
      )}
    </div>
  );
}

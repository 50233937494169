/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import iconReminderInActive from 'assets/icons/icon-reminder.svg';
import useClickOutside from 'hooks/useOutsideClick';
import Calendar from 'components/calendar';
import ButtonItem from './button';
import {
  TaskDateList,
  getComingMonday,
  getTomorrowDate,
  isTodayMonday
} from './helper';
import { TaskTimes } from './helper';
import { formatDateTime } from 'utils/dateFormat';
import { CUSTOM_DATE, MONDAY, TOMORROW } from 'utils/constants';
import { setTaskDateType, setTaskDate, setTaskTime } from 'slices/task/slice';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import CustomToolTip from 'components/CustomTooltip';

const TaskDate = () => {
  const dispatch = useDispatch();
  const [showReminderOptions, setShowReminderOptions] = useState(false);
  const [showCalendar, setShowCalendar] = useState(false);
  const { taskDateType, taskDate } = useSelector((state) => state.tasks);
  const reminderDom = useRef();

  useClickOutside(() => {
    setShowReminderOptions(false);
    setShowCalendar(false);
  }, reminderDom.current);

  const getButtonText = () => {
    if (taskDateType !== CUSTOM_DATE) {
      return taskDateType;
    }
    if (taskDate) {
      return formatDateTime(taskDate, 'mmm dd, yyyy');
    }
    return CUSTOM_DATE;
  };

  // to check if time is past 11 pm
  const checkIfTimeisPast11 = () => {
    const currentDate = moment();
    const currentTime = currentDate.format('HH');
    if (parseInt(currentTime) >= 23) {
      return true;
    }
    return false;
  };

  // if selected date is today, then set time to the next upcoming hour
  const setUpcomingHour = (date) => {
    const givenDate = moment(date);
    // Get current date
    const currentDate = moment();
    const currentTime = currentDate.format('HH');
    const isSelectedDateToday = givenDate.isSame(currentDate, 'day');
    if (isSelectedDateToday) {
      const index = parseInt(currentTime) + 1;
      if (index == 24) {
        dispatch(setTaskTime(TaskTimes[0].title));
      } else {
        dispatch(setTaskTime(TaskTimes[index].title));
      }
    } else {
      dispatch(setTaskTime(TaskTimes[9].title));
    }
  };

  const onDateSelect = (date) => {
    dispatch(setTaskDate(date));
    setUpcomingHour(date);
    setShowCalendar(false);
    setShowReminderOptions(false);
  };

  const onButtonClick = () => {
    setShowReminderOptions(!showReminderOptions);
    setShowCalendar(false);
  };

  useEffect(() => {
    if (taskDateType === MONDAY) {
      getNextMonday();
    }
    if (taskDateType === TOMORROW) {
      getTomorrow();
    }
  }, [taskDateType]);

  const getNextMonday = () => {
    const date = getComingMonday();
    dispatch(setTaskDate(new Date(date)));
    setUpcomingHour(new Date(date));
  };

  const getTomorrow = () => {
    const date = getTomorrowDate();
    dispatch(setTaskDate(new Date(date)));
    setUpcomingHour(new Date(date));
  };

  const getResponseDate = (date) => {
    if (date) return formatDateTime(date, 'dd-mm-yyyy');
    return false;
  };

  const onOptionSelect = (item) => {
    if (item.id !== CUSTOM_DATE) {
      dispatch(setTaskDateType(item.id));
      setShowReminderOptions(false);
    } else {
      //open Date Picker here
      dispatch(setTaskDateType(item.id));
      setShowCalendar(true);
      // if the previous type is not custom date then reset it to todays date
      if (taskDateType !== CUSTOM_DATE) {
        const date = new Date();
        if (checkIfTimeisPast11()) {
          dispatch(setTaskDate(moment().add(1, 'days')));
        } else {
          dispatch(setTaskDate(date));
        }
        setUpcomingHour(date);
      }
    }
  };

  const getTitle = (title) => {
    if (isTodayMonday() && title === MONDAY) {
      return 'Upcoming monday';
    }
    return title;
  };

  return (
    <div ref={reminderDom}>
      <div
        className={`dropdown-menu dropdown-menu--sm ${
          showReminderOptions && 'open'
        }`}
        style={{
          width: showCalendar ? 'fit-content' : '200px',
          bottom: '42px',
          left: 'auto'
        }}
      >
        {!showCalendar &&
          TaskDateList.map((item, index) => {
            let isSelected = item.id === taskDateType;
            return (
              <div
                key={index}
                className={`dropdown-menu__list ${
                  isSelected ? 'selected' : ''
                }`}
                onClick={() => onOptionSelect(item)}
              >
                {getTitle(item.title)}
              </div>
            );
          })}
        {showCalendar && (
          <div className="calender-select">
            <Calendar
              selectedDate={taskDate}
              onDateClick={onDateSelect}
              responseDate={getResponseDate(taskDate)}
              minDate={
                checkIfTimeisPast11() ? moment() : moment().subtract(1, 'days')
              }
              enableFutureDates
            />
          </div>
        )}
      </div>
      <CustomToolTip
        position="top"
        tooltipText="Due date"
        hideTooltip={showReminderOptions}
      >
        <ButtonItem
          active={showReminderOptions}
          icon={iconReminderInActive}
          text={getButtonText()}
          onClick={onButtonClick}
        />
      </CustomToolTip>
    </div>
  );
};

export default React.memo(TaskDate);

import React, { useRef, useState, useEffect } from 'react';
import Avatar from 'components/avatar';
// import avatarPic from 'assets/images/avatar-pic.png';
import iconLogout from 'assets/icons/icon-logout.svg';
// import iconMyProfile from 'assets/icons/icon-my-profile.svg';
import iconTeamMembers from 'assets/icons/icon-team-members.svg';
import iconBilling from 'assets/icons/icon-subscription-billing.svg';
// import iconEnquiryAssistant from "assets/icons/icon-enquiry-assistant.svg";
import iconSettings from 'assets/icons/icon-settings-green.svg';
// import iconHelp from "assets/icons/icon-get-help.svg";
// import IconApp from "assets/icons/icon-get-app.svg";
import { useDispatch } from 'react-redux';
import { clearAuthToken } from 'slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import useClickOutside from 'hooks/useOutsideClick';
import { useSelector } from 'react-redux';
import { ReactComponent as NotificationsIcon } from 'assets/icons/icon-notification.svg';
// import { ReactComponent as ChannelSettingsIcon } from 'assets/icons/icon-source-report.svg';
import { Loader } from 'components/loader';
import CHOICES from 'choices';
import { subscriptionStripeRedirect } from 'slices/subscription/slice';
const { FREE_TRIAL } = CHOICES.PlanType;
import { useIntercom } from 'react-use-intercom';
// import { useAnalytics } from 'use-analytics';
import { ReactComponent as IconChannels } from 'assets/icons/icon-navbar-channels.svg';

const Logout = () => {
  const [openDropDown, SetOpenDropDown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const nodeRef = useRef();
  const { myInformation, subOrganizationId } = useSelector(
    (state) => state.myInfo
  );
  const { socket } = useSelector((state) => state.sockets);
  const { currentPlan } = useSelector((state) => state.subscription);
  useClickOutside(() => SetOpenDropDown(false), nodeRef.current);
  const [stripeRedirectFlag, setStripeRedirectFlag] = useState(false);
  // const { reset } = useAnalytics();
  const { shutdown } = useIntercom();

  const onCheckout = () => {
    // if (reset) {
    //   reset();
    // }
    shutdown(); // closes the intercom launcher
    dispatch(clearAuthToken());
    // Disconnect the live socket when logout
    if (socket && socket.connected) {
      socket.close();
    }
  };
  useEffect(() => {
    setStripeRedirectFlag(false);
  }, []);

  const handleSubscriptionBilling = () => {
    if (stripeRedirectFlag) {
      return;
    }
    setStripeRedirectFlag(true);
    dispatch(
      subscriptionStripeRedirect(
        subOrganizationId,
        closeStripeRedirect,
        handleStripeFailedRedirect
      )
    );
  };
  const closeStripeRedirect = (data) => {
    if (data.link) {
      setStripeRedirectFlag(false);
      window.open(data.link, '_blank').focus();
    }
  };

  const handleStripeFailedRedirect = () => {
    setStripeRedirectFlag(false);
  };
  const handleNavigate = (url) => {
    navigate(url);
  };

  if (myInformation) {
    const { name } = myInformation;
    return (
      <div
        className="dashboard__sidebar__links__list--profile-avatar cursor-pointer"
        onMouseEnter={() => SetOpenDropDown(true)}
        onMouseLeave={() => SetOpenDropDown(false)}
        ref={nodeRef}
      >
        <Avatar
          src={myInformation?.avatar}
          initial={name}
          border={false}
          medium
          full
          singleChar
        />
        <div
          className={`dropdown-menu no-translate pl-6 pr-6 pt-3 pb-3  ${
            openDropDown ? 'show' : ''
          }`}
          style={{
            width: 390,
            maxHeight: '90vh',
            overflow: 'auto',
            left: 30,
            bottom: 35
          }}
        >
          <div
            className="flex bg-color-on-hover pb-[8px] pt-[8px] mb-1"
            onClick={() => {
              handleNavigate(`${route.settings}/my-settings/my-profile`);
              SetOpenDropDown(false);
            }}
          >
            <Avatar
              className={'avatar-container avatar-container--cover'}
              style={{
                width: 42,
                height: 42,
                boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
              }}
              src={myInformation?.avatar}
              initial={name}
              border={false}
              medium
              full
              singleChar
            />
            <div className="flex-1 ml-4">
              <h4 className="font-semibold">{name}</h4>
              <p className="text-sm text-grey-700">
                Login details, email signature
              </p>
            </div>
          </div>
          <li
            className="flex py-2 bg-color-on-hover pb-2"
            onClick={() => {
              handleNavigate(`${route.settings}/my-settings/notifications`);
              SetOpenDropDown(false);
            }}
          >
            <figure
              className="avatar-container avatar-container--contain"
              style={{
                width: 42,
                height: 42,
                backgroundColor: '#FAF9F8',
                boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
              }}
            >
              <NotificationsIcon />
            </figure>

            <div className="flex-1 ml-4">
              <h4 className="text-base-1 font-semibold">My Notifications</h4>
              <p className="text-sm text-grey-800">
                Manage how & when you get notified
              </p>
            </div>
          </li>

          <ul>
            <li
              className="relative pb-5 mb-5 border-b border-grey-400"
              style={{ width: '80%', left: '17%' }}
            ></li>
            <li
              className="flex py-2 bg-color-on-hover"
              onClick={() => {
                handleNavigate(
                  `${route.settings}/account-settings/team-member`
                );
                SetOpenDropDown(false);
              }}
            >
              <figure
                className="avatar-container avatar-container--contain"
                style={{
                  width: 42,
                  height: 42,
                  backgroundColor: '#FAF9F8',
                  boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                }}
              >
                <img src={iconSettings} alt="" className="avatar-pic" />
              </figure>
              <div className="flex-1 ml-4">
                <h4 className="text-base-1 font-semibold">Account Settings</h4>
                <p className="text-sm text-grey-800">
                  View all account settings
                </p>
              </div>
            </li>
            {currentPlan?.plan?.plan_type !== FREE_TRIAL && (
              <li
                className={
                  stripeRedirectFlag
                    ? 'flex py-2 mt-1 cursor-not-allowed'
                    : 'flex py-2 bg-color-on-hover mt-1'
                }
                onClick={handleSubscriptionBilling}
                style={{ position: 'relative' }}
              >
                <figure
                  className="avatar-container avatar-container--contain"
                  style={{
                    width: 42,
                    height: 42,
                    backgroundColor: '#FAF9F8',
                    boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                  }}
                >
                  <img
                    src={iconBilling}
                    alt=""
                    style={{ width: 19, height: 21 }}
                  />
                </figure>
                <div className="flex-1 ml-4">
                  <h4 className="text-base-1 font-semibold">
                    Subscription & Billing
                  </h4>
                  <p className="text-sm text-grey-800">
                    Invoices, payment method, upgrade
                  </p>
                </div>
                {stripeRedirectFlag && (
                  <Loader
                    style={{
                      position: 'absolute',
                      right: '15px',
                      bottom: '10px'
                    }}
                  />
                )}
              </li>
            )}
            <li
              className="flex py-2 bg-color-on-hover mt-1"
              onClick={() => {
                handleNavigate(
                  `${route.settings}/account-settings/team-member`
                );
                SetOpenDropDown(false);
              }}
            >
              <figure
                className="avatar-container avatar-container--contain bg-grey-300"
                style={{
                  width: 42,
                  height: 42,
                  boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                }}
              >
                <img src={iconTeamMembers} alt="" />
              </figure>
              <div className="flex-1 ml-4">
                <h4 className="text-base-1 font-semibold">Team Members</h4>
                <p className="text-sm text-grey-800">
                  Invite new, manage access
                </p>
              </div>
            </li>
            <li
              className="flex py-2 bg-color-on-hover mt-1"
              onClick={() => {
                handleNavigate(`${route.channels}`);
                SetOpenDropDown(false);
              }}
            >
              <figure
                className="avatar-container avatar-container--contain bg-grey-300"
                style={{
                  width: 42,
                  height: 42,
                  boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
                }}
              >
                <IconChannels
                  height={20}
                  width={20}
                  className="channels-icon"
                />
              </figure>
              <div className="flex-1 ml-4">
                <h4 className="text-base-1 font-semibold">
                  Channel Connections
                </h4>
                <p className="text-sm text-grey-800">
                  Connect and manage channels
                </p>
              </div>
            </li>
            {/* <li
              className="relative pb-5 mb-5 border-b border-grey-400"
              style={{ width: "80%", left: "17%" }}
            ></li> */}
            {/* <li className="flex py-2 bg-color-on-hover">
              <figure
                className="avatar-container avatar-container--contain bg-grey-300"
                style={{
                  width: 42,
                  height: 42,
                  boxShadow: "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={iconHelp} alt="" className="avatar-pic" />
              </figure>
              <div className="flex-1 ml-4">
                <h4 className="text-base-1 font-semibold">Get some help</h4>
                <p className="text-sm text-grey-800">
                  Learn about features, get support
                </p>
              </div>
            </li> */}
            {/* <li className="flex py-2 bg-color-on-hover mt-1">
              <figure
                className="avatar-container avatar-container--contain bg-grey-300"
                style={{
                  width: 42,
                  height: 42,
                  boxShadow: "inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
                }}
              >
                <img src={IconApp} alt="" />
              </figure>
              <div className="flex-1 ml-4">
                <h4 className="text-base-1 font-semibold">Get the app</h4>
                <p className="text-sm text-grey-800">Download the app</p>
              </div>
            </li> */}
            {/* <li
              className="relative pb-5 mb-5 border-b border-grey-400"
              style={{ width: "80%", left: "17%" }}
            ></li> */}
            <li
              className="relative pb-5 mb-2 border-b border-grey-400"
              style={{ width: '80%', left: '17%' }}
            ></li>
          </ul>
          <div
            className="flex items-center cursor-pointer bg-color-on-hover pb-[8px] pt-[8px]"
            onClick={onCheckout}
          >
            <figure
              className="flex items-center justify-center"
              style={{ width: 40, height: 40 }}
            >
              <img src={iconLogout} className="" alt="icon-logout" />
            </figure>
            <h4 className="text-base-1 font-semibold ml-4">Log Out</h4>
          </div>
        </div>
      </div>
    );
  }
  return <></>;
};

export default Logout;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSocialCredentialsQuery } from 'services/socialApi';
import { setSocialCredentials } from 'slices/socialCredentialSlice';
import Loader from 'components/loader';
import HeaderLayout from 'components/layout/headerless';

const AppWrapper = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const authToken = useSelector((state) => state.auth.authToken);
  const { data, isLoading, isSuccess, isError } = useSocialCredentialsQuery(
    '',
    { skip: authToken }
  );

  useEffect(() => {
    if (isSuccess) {
      onSuccess();
    }
  }, [isSuccess]);

  const onSuccess = () => {
    dispatch(setSocialCredentials(data));
  };

  if (isLoading)
    return (
      <HeaderLayout hideLogo>
        <Loader />
      </HeaderLayout>
    );

  if (isError)
    return <div>Sorry there was as error in fetching social credentials</div>;

  return <>{children}</>;
};

export default AppWrapper;

import React from 'react';
import fileTooLargeIcon from 'assets/icons/icon-file-too-large.svg';

const UnsupportedFile = ({ onTryAgain }) => {
  return (
    <div className="modal__unsupported-section">
      <img src={fileTooLargeIcon} alt="" style={{ width: 32, height: 32 }} />
      <div>
        <h3 className="flex justify-center text-sm text-red-600 font-semibold mt-3">
          Unsupported file
        </h3>
        <div className="mt-1 text-xs ml-4 txt-grey text-center">{`This type of file is not supported. You can upload PDF, Word Doc, Powerpoint & Image files such as PNG & JPEG`}</div>
        <div className="flex justify-center mt-5">
          <button
            className="btn btn--primary btn--try-again"
            onClick={onTryAgain}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default UnsupportedFile;

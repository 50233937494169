/* eslint-disable no-unused-vars */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import InputField from 'components/inputs/textField';
import useClickOutside from 'hooks/useOutsideClick';
import { Controller } from 'react-hook-form';
import { ReactComponent as SelectIcon } from 'assets/icons/icon-arrow-down.svg';
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search-grey.svg';

const ChatbotSelect = (props) => {
  const {
    register,
    setValue,
    control,
    options,
    name,
    value,
    inputClassName,
    placeholder,
    error,
    onChange,
    onBlur,
    required,
    renderIcon,
    readOnly,
    setReadOnly,
    isSearchInsideDropDown,
    selectedIconStyle,
    dropDownClassName,
    isSelectDisabled,
    isBin,
    inputValue,
    setInputValue,
    ...rest
  } = props;

  const [open, setOpen] = useState(false);
  // const [inputValue, setInputValue] = useState('');
  const [dropDownSearchValue, setDropDownSearchValue] = useState('');
  const [filteredOptions, setFilteredOptions] = useState(options);
  const nodeRef = useRef();
  const inputRef = useRef();
  const searchRef = useRef();
  useClickOutside(() => {
    setOpen(false);
    setDropDownSearchValue('');
    setFilteredOptions(options);
  }, nodeRef.current);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  const toggle = () => {
    setOpen(!open);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    setInputValue(value);
  };

  const onDropDownSearch = (e) => {
    const { value } = e.target;
    setDropDownSearchValue(value);
    let filteredOptions = options.filter((option) =>
      option.label.toLowerCase().includes(value.toLowerCase())
    );
    let otherOption = [
      {
        label: 'Other',
        value: 8
      }
    ];
    if (filteredOptions.length === 0) {
      setFilteredOptions(otherOption);
    } else {
      setFilteredOptions(filteredOptions);
    }
  };
  const isSelected = (optionValue, value) => {
    if (value && typeof value == 'object') {
      if (value.label === optionValue) return 'selected';
    }
    if (value && typeof value == 'string') {
      if (value === optionValue) return 'selected';
    }
  };

  const onOptionSelect = (option, onChange) => {
    if (control && setValue) {
      setValue(name, option.label, { shouldTouch: true });
    }
    if (onChange) {
      onChange(option);
      setFilteredOptions(options);
      setDropDownSearchValue('');
    }
    // if value is other
    if (option.value === 8) {
      setReadOnly(false);
      inputRef?.current.focus();
      setInputValue('');
    } else {
      setInputValue(option.label);
      setReadOnly(true);
    }
    if (isSearchInsideDropDown) {
      toggle();
    }
  };
  return (
    <div data-testid="search-container" className="relative" ref={nodeRef}>
      <InputField
        data-testid="search-input"
        name="search"
        className={`input-field--select ${inputClassName}`}
        placeholder={placeholder}
        value={inputValue}
        onChange={onSearch}
        error={error}
        readOnly={readOnly}
        ref={inputRef}
        onClick={toggle}
        autoComplete={'off'}
        {...rest}
      />
      <div className="input-select-svg-dropdown" onClick={toggle}>
        <SelectIcon />
      </div>
      {renderIcon && (
        <div className="input-select-svg-icon">{renderIcon()}</div>
      )}
      <div
        data-testid="select-dropdown-menu"
        className={`dropdown-menu ${open ? 'open' : ''}
      ${dropDownClassName ? dropDownClassName : ''}
    `}
        style={{ maxHeight: '231px' }}
      >
        {isSearchInsideDropDown && (
          <div className="mt-3 mb-2" style={{ padding: '0px 8px' }}>
            <InputField
              data-testid="search-input"
              name={'search'}
              className={`input-field--select input-field--sm ${inputClassName}`}
              placeholder={'Search'}
              value={dropDownSearchValue}
              onChange={onDropDownSearch}
              selectedIconStyle={selectedIconStyle}
              renderIcon={() => (
                <div
                  className="input-svg-wrapper"
                  style={{ top: '-7px', left: '-4px' }}
                >
                  <SearchIcon />
                </div>
              )}
              style={{ paddingLeft: '40px !important' }}
              ref={searchRef}
              autoComplete={'off'}
            />
          </div>
        )}
        <div
          className="pl-2 pr-2"
          style={{
            maxHeight: '160px',
            overflowY: 'scroll',
            paddingBottom: '10px'
          }}
        >
          {filteredOptions.map((option, idx) => {
            return (
              <div
                key={idx}
                className={`dropdown-menu__list 
                ${option.value === value ? 'selected' : ''}`}
                onClick={() => onOptionSelect(option, onChange)}
              >
                {option.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ChatbotSelect.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ).isRequired,
  selected: PropTypes.string,
  register: PropTypes.func,
  required: PropTypes.bool,
  renderIcon: PropTypes.func,
  selectedIconStyle: PropTypes.object,
  error: PropTypes.bool
};

ChatbotSelect.defaultProps = {
  open: false,
  placeholder: 'Select',
  inputClassName: '',
  register: () => {},
  required: false,
  selectedIconStyle: {},
  error: false
};

export default ChatbotSelect;

import React from 'react';
import PropTypes from 'prop-types';

const ListHeader = (props) => {
  const { title, className } = props;
  return <h3 className={className}>{title}</h3>;
};

ListHeader.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string
};

export default ListHeader;

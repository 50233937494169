import React from 'react';
// import SetUpVideo from 'components/setUpVideo';
import Header from '../../../../components/layout/header';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import zapierIcon from 'assets/icons/icon-channel-zappier-white.svg';
import GuideLink from 'components/guideLink';
import ChannelBack from '../back';

const ZapierHome = () => {
  const onConnectZapier = () => {
    window.open(process.env.REACT_APP_ZAPIER_CONNECT_URL);
  };

  return (
    <div className="toolbox__main overflow-overlay channels">
      <div className="card-container--new connect-new">
        <ChannelBack />
        <Header
          title="Connect Zapier"
          description="By connecting your zapier, you will be able to receive and reply to messages, right
            here on this channel."
        />
        <div className="mt-4">
          <CustomColorButtonWithLogo
            label="Connect Zapier"
            className="btn--sm btn--zapier gap-2 mt-8 text-15"
            renderSvg={() => (
              <>
                <img
                  src={zapierIcon}
                  alt="zapier"
                  style={{
                    height: '20px'
                  }}
                />
              </>
            )}
            onClick={onConnectZapier}
          />
        </div>
        <div className="mt-8">
          {/* <SetUpVideo
          src="https://player.vimeo.com/video/815596953?h=d7b8eb072a&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          articleLink="https://help.enquirybox.io/en/articles/7228337-connect-facebook-pages"
        /> */}
          <GuideLink url="https://help.enquirybox.io/en/articles/8185103-get-enquiries-in-and-out-of-enquirybox-with-zapier" />
        </div>
      </div>
    </div>
  );
};

export default ZapierHome;

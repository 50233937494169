import { useDispatch, useSelector } from 'react-redux';
import ChannelOption from './item';
import { useEffect } from 'react';
import { getAllPendingChannels } from 'slices/channelsSlice';
import CHOICES from 'choices';
import { getRoutePath } from 'utils/channels';

const {
  WHATSAPP,
  EBOT,
  SMS,
  FACEBOOK,
  FACEBOOK_LEAD_ADS,
  INSTAGRAM,
  ZAPIER,
  WEBFORM
} = CHOICES.ChannelChoices;

const ChannelsOptions = () => {
  const { allPendingChannels } = useSelector((state) => state.channels);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllPendingChannels());
  }, []);

  const allPendingChannelsCopy = [
    ...allPendingChannels,
    {
      avatar_link:
        'https://ebox-static-files.s3.eu-west-1.amazonaws.com/logos/intercom.svg',
      channel_name: 'suggestAChannel',
      channel_type: 10,
      connected_channel_name: null,
      description: 'Click here to suggest a channel you’d like to connect',
      id: 'suggest-channel-9',
      is_it_deleteable: false,
      title: 'Suggest a channel'
    }
  ];
  return (
    <div
      className="toolbox__main overflow-overlay"
      style={{ padding: '20px 20px', paddingRight: '10px' }}
    >
      <div className="font-medium">Most Popular 🔥</div>
      <div className="channels-new-wrapper">
        {allPendingChannelsCopy?.map((item, index) => {
          if (item?.is_most_popular) {
            return (
              <ChannelOption
                icon={item?.avatar_link}
                key={index}
                title={item.title}
                paid={item.channel_type === EBOT || item.channel_type === SMS}
                description={item.description}
                commingSoon={item.is_coming_soon}
                channel_type={item.channel_type}
                isDisabled={item.is_coming_soon}
                to={`/channels/${
                  item.channel_type === EBOT
                    ? getRoutePath(EBOT)
                    : item.channel_type === WHATSAPP
                    ? getRoutePath(WHATSAPP)
                    : item.channel_type === FACEBOOK
                    ? getRoutePath(FACEBOOK)
                    : item.channel_type === FACEBOOK_LEAD_ADS
                    ? getRoutePath(FACEBOOK_LEAD_ADS)
                    : item.channel_type === INSTAGRAM
                    ? getRoutePath(INSTAGRAM)
                    : item.channel_type === SMS
                    ? getRoutePath(SMS)
                    : item.channel_type === ZAPIER
                    ? getRoutePath(ZAPIER)
                    : item.channel_type === WEBFORM
                    ? getRoutePath(WEBFORM)
                    : 'suggestAchannel'
                }/${item.channel_type}/pending/${item.id}`}
              />
            );
          }
        })}
      </div>
      <div className="font-medium mt-4">Other Channels</div>
      <div className="channels-new-wrapper">
        {allPendingChannelsCopy?.map((item, index) => {
          if (!item?.is_most_popular) {
            return (
              <ChannelOption
                icon={item?.avatar_link}
                key={index}
                title={item.title}
                paid={item.channel_type === EBOT || item.channel_type === SMS}
                description={item.description}
                commingSoon={item.is_coming_soon}
                channel_type={item.channel_type}
                isDisabled={item.is_coming_soon}
                to={`/channels/${
                  item.channel_type === EBOT
                    ? getRoutePath(EBOT)
                    : item.channel_type === WHATSAPP
                    ? getRoutePath(WHATSAPP)
                    : item.channel_type === FACEBOOK
                    ? getRoutePath(FACEBOOK)
                    : item.channel_type === FACEBOOK_LEAD_ADS
                    ? getRoutePath(FACEBOOK_LEAD_ADS)
                    : item.channel_type === INSTAGRAM
                    ? getRoutePath(INSTAGRAM)
                    : item.channel_type === SMS
                    ? getRoutePath(SMS)
                    : item.channel_type === ZAPIER
                    ? getRoutePath(ZAPIER)
                    : item.channel_type === WEBFORM
                    ? getRoutePath(WEBFORM)
                    : 'suggestAchannel'
                }/${item.channel_type}/pending/${item.id}`}
              />
            );
          }
        })}
      </div>
    </div>
  );
};

export default ChannelsOptions;

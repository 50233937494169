import React, { useEffect } from 'react';
import OpeningMessage from './openingMessage';
import OpeningStarterOptions from './openingStarterOptions';
import { getWelcomeList } from 'slices/ai-assistant/welcome/slice';
import { useDispatch, useSelector } from 'react-redux';
import OverLayLoader from 'components/loader/overLayLoader';
import { useParams } from 'react-router-dom';

const WelcomeTab = () => {
  const { assistant_id } = useParams();
  const dispatch = useDispatch();
  const { dragDropMessageLoading, dragDropOptionLoading } = useSelector(
    (state) => state.welcomePage
  );
  useEffect(() => {
    dispatch(getWelcomeList(assistant_id));
  }, []);
  return (
    <section className="" style={{ padding: '25px 0' }}>
      {(dragDropMessageLoading || dragDropOptionLoading) && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
            // background: 'white'
          }}
        />
      )}
      {(!dragDropMessageLoading || !dragDropOptionLoading) && (
        <>
          <OpeningMessage />
          <OpeningStarterOptions />
        </>
      )}
    </section>
  );
};

export default WelcomeTab;

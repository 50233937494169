import React from 'react';

export const ScreenLoader = ({ small, smaller, tiny }) => {
  const getClassName = () => {
    if (tiny) return 'tiny';
    if (smaller) return 'smaller';
    if (small) return 'small';
    return '';
  };

  return (
    <div
      style={{
        position: 'fixed',
        height: '100%',
        width: '-webkit-fill-available',
        top: 0,
        left: 0,
        // backdropFilter: 'blur(1px)',
        zIndex: 9999999999999
      }}
    >
      <div className="flex h-full justify-center items-center">
        <div className={`lds-ring ${getClassName()}`}>
          <div />
          <div />
          <div />
        </div>
      </div>
    </div>
  );
};

export default ScreenLoader;

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  CREATE_CUSTOM_RULE,
  CREATE_NEW_AVATAR,
  DELETE_CUSTOM_RULE,
  GET_ALL_AVATARS,
  GET_ALL_PERSONALITY_FIELDS,
  UPDATE_CUSTOM_RULE,
  UPDATE_PERSONALITY_FIELDS
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setAvatarList, setVersionStatus } from '../assistant/slice';

const initialState = {
  personalityFields: {},
  customRules: [],
  avatarUrl: ''
};

const reducers = {
  setPersonalityFields: (state, action) => {
    state.personalityFields = action.payload;
  },
  setCustomRules: (state, action) => {
    state.customRules = action.payload;
  },
  setAvatarUrl: (state, action) => {
    state.avatarUrl = action.payload;
  },
  resetPersonalityFields: () => initialState
};

export const personalitySlice = createSlice({
  name: 'personality',
  initialState,
  reducers
});

export const {
  setPersonalityFields,
  setCustomRules,
  resetPersonalityFields,
  setAvatarUrl
} = personalitySlice.actions;

export default personalitySlice.reducer;

export const getAllPersonalityFields = (assistant_id) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_PERSONALITY_FIELDS, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setPersonalityFields(res.data));
            dispatch(setCustomRules(res.data.custom_rules));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateAllPersonalityFields = (
  assistant_id,
  personalityFields,
  successCb,
  failedCb,
  type //type (name) of field
) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .put(
          replaceUrl(UPDATE_PERSONALITY_FIELDS, 'aiBotId', assistant_id),
          personalityFields
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res.data.version_status));
            dispatch(setPersonalityFields(res.data));
            dispatch(setCustomRules(res.data.custom_rules));
            if (successCb) {
              if (type) {
                successCb(assistant_id, res.data, type);
              } else {
                successCb(assistant_id, res.data, {});
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      if (failedCb) failedCb();
      console.log(err);
    }
  };
};

export const createCustomRule = (customRule) => {
  return async (dispatch, getState) => {
    const { customRules } = getState().personality;
    try {
      let jsonPost = {
        ai_bot_id: customRule.ai_bot_id,
        rule: customRule.rule,
        version_id: customRule.version_id
      };
      await new APIRequest().post(CREATE_CUSTOM_RULE, jsonPost).then((res) => {
        if (res.status === 200) {
          const data = customRules.map((item, index) => {
            if (customRule.index === index) return { ...res.data };
            return item;
          });
          dispatch(setVersionStatus(res.data.version_status));
          dispatch(setCustomRules(data));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateCustomRule = (customRule) => {
  return async (dispatch, getState) => {
    const { customRules } = getState().personality;
    try {
      await new APIRequest().put(UPDATE_CUSTOM_RULE, customRule).then((res) => {
        if (res.status === 200) {
          let updatedCustomRules = customRules.map((customRule) => {
            if (customRule.id === res.data.custom_rule_id)
              return { ...customRule, rule: res.data.rule };
            return customRule;
          });
          dispatch(setCustomRules(updatedCustomRules));
          dispatch(setVersionStatus(res.data.version_status));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAvatarList = (subOrgId) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_AVATARS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAvatarList(res.data));
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
};

export const addNewAvatar = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      new APIRequest()
        .post(CREATE_NEW_AVATAR, payload)
        .then((resp) => {
          if (resp.status === 200) {
            if (successCb) {
              successCb(resp.data);
            }
            dispatch(setVersionStatus(resp.data.version_status));
          }
        })
        .catch((err) => console.log(err));
    } catch (e) {
      console.log(err);
    }
  };
};
export const deleteCustomRule = (customRule) => {
  return async (dispatch, getState) => {
    const { customRules } = getState().personality;
    try {
      await new APIRequest()
        .delete(DELETE_CUSTOM_RULE, customRule)
        .then((res) => {
          if (res.status === 200) {
            let data = customRules.filter((rule) => {
              return rule.id != res.data.custom_rule_id;
            });
            dispatch(setCustomRules(data));
            dispatch(setVersionStatus(res.data.version_status));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

import React from 'react';
import enquiry from 'assets/icons/icon-assign-enquiries.svg';
import CustomToolTip from 'components/CustomTooltip';
import arrowUp from 'assets/icons/arrow-up-green.svg';
import arrowDown from 'assets/icons/icon-report-decrease.svg';

const TotalEnquiry = (props) => {
  const { total, percentage, selectedDay } = props;

  return (
    <div className="card-box-shadow p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center pr-4">
          <figure
            className="avatar-container avatar-container--contain bg-grey-300"
            style={{
              width: 38,
              height: 38,
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
          >
            <img src={enquiry} width={17} height={17} />
          </figure>
          <CustomToolTip
            style={{ width: '100%' }}
            tooltipStyle={{
              width: 222,
              top: -97,
              bottom: 'initial',
              left: -58,
              transform: 'none',
              textAlign: 'left'
            }}
            position="top"
            tooltipText="The total number of enquiries that you have received in enquirybox from any channel"
          >
            <div className="ml-3">
              <p className="letterspacing24em font-semibold">Total enquiries</p>
              {total === 0 ? (
                <div className="data-overlay opacity-30"></div>
              ) : (
                <div className="flex items-center">
                  {percentage > 0 && <img src={arrowUp} />}
                  {percentage < 0 && <img src={arrowDown} />}
                  <span
                    className={`text-13 font-semibold ${
                      percentage >= 0 ? 'text-green-900' : 'text-red-900'
                    } letterspacing24em`}
                    style={{ marginLeft: 2 }}
                  >
                    {percentage
                      ? parseFloat(percentage).toFixed(2).replace(/\.00$/, '')
                      : 0}
                    %{/* {percentage}% */}
                  </span>
                  <span className="text-xs text-grey-800 ml-1 letterspacing24em">
                    than{' '}
                    {selectedDay === 'Last 7 days'
                      ? 'last week'
                      : selectedDay === 'This month to date'
                      ? 'last month'
                      : 'last date'}
                  </span>
                </div>
              )}
            </div>
          </CustomToolTip>
        </div>
        <span className="text-28 font-bold letterspacing24em">
          {total ? total : 0}
        </span>
      </div>
    </div>
  );
};

export default TotalEnquiry;

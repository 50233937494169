import { createSlice } from '@reduxjs/toolkit';
import {
  ORGANIZATION_USER,
  ASSIGN_USER_TO_LEAD,
  UNASSIGN_USER_TO_LEAD
} from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import CHOICES from 'choices';
import whoever_is_available_icon from 'assets/icons/whoever-is-available.svg';
import { setAssignedTo } from './leadsFilter';
import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';

const PENDING = CHOICES.OnboardingTeamMemberChoice.PENDING;

export const organizationUser = createSlice({
  name: 'organizationUser',
  initialState: {
    organizationUser: {},
    teamMembers: {}
  },
  reducers: {
    setOrganizationUser: (state, action) => {
      state.organizationUser = action.payload;
    },
    setTeamMembers: (state, action) => {
      state.teamMembers = action.payload;
    }
  }
});

export const whoever_is_available = {
  user_id: null,
  first_name: 'Whoever is available',
  avatar: whoever_is_available_icon,
  email: '',
  name: 'Whoever is available'
};

export const { setOrganizationUser, setTeamMembers } = organizationUser.actions;

export const getOrganizationUser = (payload, successCb, setUserFilter) => {
  return async (dispatch, getState) => {
    try {
      const { myInformation } = getState().myInfo;
      const { id } = myInformation;
      const { subOrgId } = payload;
      new APIRequest()
        .get(replaceUrl(ORGANIZATION_USER, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            const signedupUserData = res.data.results.filter(
              (item) => item.onboarding_status !== PENDING
            );
            const getCurrentUser = signedupUserData.find(
              (user) => user.user_id === id
            );
            const filteredWithoutCurrentUser = signedupUserData.filter(
              (user) => user.user_id !== id
            );
            const finalUsers = getCurrentUser
              ? [getCurrentUser, ...filteredWithoutCurrentUser]
              : signedupUserData;
            dispatch(setOrganizationUser({ ...res.data, results: finalUsers }));
            if (setUserFilter) {
              if (myInformation.is_assigned_to_filter_in_things_to_do_active) {
                dispatch(setAssignedTo(getCurrentUser));
              }
              dispatch(getTaskEnquiryCount());
            }
            dispatch(setTeamMembers(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const assigntoUserLead = (payload, successCb) => {
  return async () => {
    try {
      const { leadId, body } = payload;
      new APIRequest()
        .put(replaceUrl(ASSIGN_USER_TO_LEAD, 'leadId', leadId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const unAssigntoUserLead = (payload, successCb) => {
  return async () => {
    try {
      const { leadId, body } = payload;
      new APIRequest()
        .put(replaceUrl(UNASSIGN_USER_TO_LEAD, 'leadId', leadId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export default organizationUser.reducer;

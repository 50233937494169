import CHOICES from 'choices';
import InvitedUserSignedUp from 'modules/teamChat/component/invitedUserSignedUp';
import LeadAssigned from 'modules/teamChat/component/leadAssigned';
import Mentions from 'modules/teamChat/component/mentions';
import NewEnquiry from 'modules/teamChat/component/newEnquiry';
import OlderFeeds from 'modules/teamChat/component/older';
import PlaceHolderFeed from 'modules/teamChat/component/placeholderFeed';
import TodayFeed from 'modules/teamChat/component/today';
import YesterdayFeed from 'modules/teamChat/component/yesterday';
import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';

const UserFeed = () => {
  const {
    isFiltered,
    filteredNotification,
    todayNotification,
    yesterdayNotification,
    olderNotification
  } = useSelector((state) => state.usersNotification);
  const { NEW_LEAD, NOTES_ADDED, LEAD_ASSIGNED, SIGN_UP_INVITATION_ACCEPTED } =
    CHOICES.SystemNotificationTypeChoices;

  const renderData = (item) => {
    switch (item.notification_type) {
      case NEW_LEAD:
        return <NewEnquiry data={item} key={item.id} />;
      case NOTES_ADDED:
        return <Mentions data={item} key={item.id} />;
      case LEAD_ASSIGNED:
        return <LeadAssigned data={item} key={item.id} />;
      case SIGN_UP_INVITATION_ACCEPTED:
        return <InvitedUserSignedUp data={item} key={item.id} />;
      default:
        <></>;
    }
  };

  return (
    <div
      className="dashboard__main__body pt-6 px-11 relative overflow-y-auto"
      style={{ height: 'calc(100% - 145px)' }}
    >
      <div className="w-full">
        {isEmpty(todayNotification?.results) &&
          isEmpty(yesterdayNotification?.results) &&
          isEmpty(olderNotification?.results) &&
          isEmpty(filteredNotification) && <PlaceHolderFeed />}
        {isFiltered ? (
          <>
            {filteredNotification?.results &&
              filteredNotification?.results.map((item) => {
                return renderData(item);
              })}
          </>
        ) : (
          <>
            <TodayFeed />
            <YesterdayFeed />
            <OlderFeeds />
          </>
        )}
      </div>
    </div>
  );
};

export default UserFeed;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import { isEmpty } from 'utils/utils';
import { ReactComponent as Plus } from 'assets/icons/icon-plus.svg';
import EmptyLabelsNew from './emptyContainer';
import LabelContainer from './listContainer';
import LabelSelected from './selected';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  addLabelsToLeads,
  removeLabelsFromLeads,
  setSelectedLabels,
  setTempSelected,
  setRemovedLabels
} from 'slices/labelsSlice';
import { getLeadData } from 'slices/conversationSlice';
import { getLeadsHistory } from 'slices/historyLeadSlice';

const Label = () => {
  const lableNode = useRef();
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const { allLabels, selectedLabels, tempSelected, removedLabels } =
    useSelector((state) => state.labels);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const [labelActive, setLabelActive] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(allLabels);
  const [inputValue, setInputValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useClickOutside(() => setLabelActive(false), lableNode.current);

  const LabelButton = () => {
    return (
      <button
        className={`btn border border-grey-400 ${
          labelActive ? 'bg-grey-90' : 'bg-white'
        }`}
        style={{ padding: '4px 10px' }}
        onClick={() => setLabelActive(!labelActive)}
      >
        <Plus height={10} width={10} />
        <p className="text-13 font-medium ml-2">Label</p>
      </button>
    );
  };

  useEffect(() => {
    if (!labelActive && !isEmpty(tempSelected)) {
      onOptionChange();
    }
  }, [labelActive, tempSelected]);

  const onOptionChange = () => {
    if (isEmpty(activeLead?.labels)) {
      // if no labels attached to lead
      if (isEmpty(selectedLabels)) {
        // if no labels selected to attach to lead
        return;
      } else {
        // if labels selected to attach to lead
        setIsLoading(true);
        const formData = {
          data: selectedLabels.map((item) => {
            return {
              lead: cardId,
              label: item.id
            };
          })
        };
        dispatch(
          addLabelsToLeads({
            body: formData,
            callback: callback
          })
        );
      }
    } else {
      // if there are labels already attached to lead
      const results = activeLead?.labels.filter(({ label_name: name1 }) =>
        selectedLabels.some(({ label_name: name2 }) => name2 === name1)
      );
      if (!isEmpty(removedLabels)) {
        // if unchecked the already attached labels
        setIsLoading(true);
        const formData = {
          data: removedLabels.map((item) => item.id)
        };
        dispatch(
          removeLabelsFromLeads({
            body: formData,
            callback: deleteCallback
          })
        );
      }
      if (!isEmpty(selectedLabels)) {
        if (!isEmpty(results)) {
          // if there are same labels checked from previously added labels
          const filteredLabel = selectedLabels.filter(
            ({ label_name: name1 }) =>
              !results.some(({ label_name: name2 }) => name2 === name1)
          );
          if (isEmpty(filteredLabel)) {
            return;
          } else {
            // if checked labels are different from already attached labels
            setIsLoading(true);
            const formData = {
              data: filteredLabel.map((item) => {
                return {
                  lead: cardId,
                  label: item.id
                };
              })
            };
            dispatch(
              addLabelsToLeads({
                body: formData,
                callback: callback
              })
            );
          }
        } else {
          // if there are no same labels checked from previously added labels
          setIsLoading(true);
          const formData = {
            data: selectedLabels.map((item) => {
              return {
                lead: cardId,
                label: item.id
              };
            })
          };
          dispatch(
            addLabelsToLeads({
              body: formData,
              callback: callback
            })
          );
        }
      }
    }
  };

  const handleRemove = (id) => {
    if (labelActive) {
      // if the dropdown is open and try to remove
      if (!isEmpty(activeLead?.labels)) {
        // if there are already labels attached to leads
        const isLabelAssigned = activeLead?.labels.filter(
          (item) => item.id === id
        );
        if (isEmpty(isLabelAssigned)) {
          // if the removing label is not attached to lead
          const removedSelectedLabel = selectedLabels.filter(
            (item) => item.id !== id
          );
          dispatch(setSelectedLabels(removedSelectedLabel));
          const removeFromTempData = tempSelected.filter(
            (item) => item.id !== id
          );
          dispatch(setTempSelected(removeFromTempData));
        } else {
          // if the removing label is already attached to lead
          const removedLabels = activeLead?.labels.filter(
            (item) => item.id !== id
          );
          dispatch(setSelectedLabels(removedLabels));
          setIsLoading(true);
          const formData = {
            data: [id]
          };
          dispatch(
            removeLabelsFromLeads({
              body: formData,
              callback: callback
            })
          );
        }
      } else {
        // if there are already labels attached to leads
        const removedSelectedLabel = selectedLabels.filter(
          (item) => item.id !== id
        );
        dispatch(setSelectedLabels(removedSelectedLabel));
        const removeFromTempData = tempSelected.filter(
          (item) => item.id !== id
        );
        dispatch(setTempSelected(removeFromTempData));
      }
    } else {
      // if the dropdown is close and try to remove
      setIsLoading(true);
      const formData = {
        data: [id]
      };
      dispatch(
        removeLabelsFromLeads({
          body: formData,
          callback: callback
        })
      );
    }
  };

  const callback = () => {
    setIsLoading(false);
    dispatch(getLeadData({ leadId: cardId }));
    dispatch(getLeadsHistory({ leadId: cardId }));
    dispatch(setTempSelected([]));
    dispatch(setRemovedLabels([]));
  };

  const deleteCallback = () => {
    setIsLoading(false);
    dispatch(getLeadData({ leadId: cardId }));
    dispatch(getLeadsHistory({ leadId: cardId }));
    dispatch(setRemovedLabels([]));
    dispatch(setTempSelected([]));
  };

  const onSearchLabels = (e) => {
    setInputValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = allLabels.filter((item) =>
        item.label_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setSelectedLabel(filtered);
    } else {
      setSelectedLabel(allLabels);
    }
  };

  return (
    <div ref={lableNode} className="flex">
      <LabelSelected
        selectedOptions={
          !isEmpty(selectedLabels) && labelActive
            ? selectedLabels
            : !labelActive
            ? activeLead?.labels
            : selectedLabels
        }
        handleRemove={handleRemove}
        isLoading={isLoading}
      />
      <LabelButton />
      <LabelContainer
        open={labelActive && !isEmpty(allLabels)}
        options={selectedLabel}
        onOptionChange={onOptionChange}
        onSearchLabels={onSearchLabels}
        inputValue={inputValue}
      />
      <EmptyLabelsNew
        open={isEmpty(selectedLabel) && labelActive && inputValue === ''}
      />
    </div>
  );
};

export default Label;

/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { ReactComponent as IconPreview } from 'assets/icons/icon-preview-green.svg';
import { ReactComponent as IconPlus } from 'assets/icons/icon-plus-white.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy_link.svg';
// import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import CustomColorButton from 'components/buttons/customColorButton';
import { isEmpty } from 'utils/utils';
// import { getCustomBoxtemplates } from 'slices/customBoxSlice';

// import { Link } from 'react-router-dom';

const BoxTemplates = ({
  setShowPreview,
  handleCreateBox,
  handleCopyFromBox,
  setOpen,
  setSelectedTemplate
}) => {
  // const dispatch = useDispatch();

  const { boxTemplates } = useSelector((state) => state.addcustomBox);

  // useEffect(() => {
  //   dispatch(getCustomBoxtemplates());
  // }, []);
  return (
    <div className="h-full">
      <div
        className=" overflow-y-auto "
        style={{ height: 'calc(100% - 90px)', padding: '25px 30px' }}
      >
        <div className="text-center">
          <h2 className="font-semibold text-xl">
            Create the perfect box to manage specific things
          </h2>
          <p className="mt-2 text-15 text-grey-800">
            Use one of our expert built, tried and tested templates or build a
            box from scratch
          </p>
        </div>
        <div
          className="flex mt-9"
          style={{
            height: 'auto',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'
          }}
        >
          <div
            className="dashboard__main--box-card py-10 flex justify-center mt-2"
            style={{ width: '100px' }}
            onClick={() => {
              setOpen(true);
            }}
          >
            <div>
              <figure
                className="bg-primary flex justify-center items-center mb-2 ml-5"
                style={{ width: 38, height: 38, borderRadius: '50%' }}
              >
                <IconPlus width={16} height={16} />
              </figure>
              <div>
                <p className="text-15 font-medium">
                  Build a Box <br />
                  from scratch
                </p>
              </div>
            </div>
          </div>
          <div
            className="dashboard__main--box-card py-10 flex justify-center mt-2"
            style={{ width: '100px' }}
            onClick={handleCopyFromBox}
          >
            <div>
              <figure
                className="flex justify-center items-center mb-2 ml-5"
                style={{ width: 40, height: 40 }}
              >
                <CopyIcon />
              </figure>
              <div>
                <p className="text-15 font-medium">
                  Copy from an <br />
                  existing box
                </p>
              </div>
            </div>
          </div>
          {!isEmpty(boxTemplates) &&
            boxTemplates.map((item, index) => {
              return (
                <div
                  key={index}
                  className="dashboard__main--box-card p-5 mt-2 "
                  style={{ width: '100px' }}
                >
                  <h3 className="dashboard__main--box-card__header">
                    {item?.name}
                  </h3>
                  <p className="dashboard__main--box-card__desc">
                    {item.description}
                  </p>
                  <div className="flex justify-between mt-12 items-center">
                    <CustomColorButton
                      className="btn text-13 text-primary border border-grey-400"
                      style={{ padding: '6px 15px' }}
                      label={'Use this'}
                      onClick={() => {
                        setSelectedTemplate(item);
                        setOpen(true);
                      }}
                    />
                    <div
                      className="flex items-center"
                      onClick={() => setShowPreview(item)}
                    >
                      <IconPreview />
                      <span className="ml-1 text-13 font-semibold text-primary">
                        Preview
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default BoxTemplates;

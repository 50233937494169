/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useEffect, useState } from 'react';
import { ReactComponent as IconAddBoxTemplate } from 'assets/icons/add-box-template.svg';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
// import CreateCardSettings from './modules/carddesign/createCard';
// import GeneralBoxSettingsMenu from './modules/menu';
import AddboxTemplateHeadder from './modules/headders';
import BoxTemplates from './modules/addBoxTemplate';
import PreviewBoxTemplate from './modules/previewBoxTemplate';
import {
  createCustomBox,
  getCustomBoxtemplates,
  setBoxIcon,
  setboxName
} from 'slices/customBoxSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import { getAllBoxes } from 'slices/boxes/slice';
import { isEmpty } from 'lodash';
import { Loader } from 'components/loader';
import CopyFromBoxModal from 'modules/dashboard/module/copyFromBoxModal';
import AddCustomBoxModal from 'modules/dashboard/module/addCustomBox';
import { useAnalytics } from 'use-analytics';
import { removeWhiteSpace } from 'utils/utils';

const AddBoxTemplate = () => {
  const navigate = useNavigate();
  const [showPreview, setShowPreview] = useState('');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openCopyFromBoxModal, setOpenCopyFromBoxModal] = useState(false);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [selectedTemplate, setSelectedTemplate] = useState();
  // const { analytics } = useSelector((state) => state.segments);
  const dispatch = useDispatch();
  const { track } = useAnalytics();

  const { boxName, boxIcon, boxTemplates } = useSelector(
    (state) => state.addcustomBox
  );

  const onClose = () => {
    navigate(`${route.dashboard}`);
  };

  const onCloseModal = () => {
    setOpen(false);
    setSelectedTemplate();
  };

  const { openSuccessSnackBar } = useSnackbar();

  // useEffect(() => {
  //   if (isEmpty(boxName)) {
  //     navigate(`${route.dashboard}`);
  //   }
  // }, [boxName]);

  const onSuccessTemplate = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (isEmpty(boxTemplates)) {
      setIsLoading(true);
      dispatch(getCustomBoxtemplates(onSuccessTemplate));
    }
  }, []);

  const handleCreateBox = () => {
    const payload = {
      subOrgId: subOrganizationId,
      data: {
        name: removeWhiteSpace(boxName),
        avatar: boxIcon,
        use_preview_template_id: selectedTemplate?.id || showPreview?.id
      }
    };
    dispatch(
      createCustomBox(payload, (data) => {
        onSuccess(data);
        if (selectedTemplate) {
          track(
            'box_created',
            {
              box_name: boxName,
              template_name: selectedTemplate.name
            },
            {
              groupId: subOrganizationId
            }
          );
        }
      })
    );
  };

  const onSuccess = (data) => {
    setIsCreateLoading(false);
    openSuccessSnackBar('Success');
    dispatch(getAllBoxes());
    dispatch(setBoxIcon('🌳'));
    navigate(
      `${route.dashboard}${route.boxes}/${selectedTemplate?.type_of_box}/${data[0]?.box}`
    );
    dispatch(setboxName(''));
    onCloseModal();
  };

  const handleCopyFromBox = () => {
    setOpenCopyFromBoxModal(true);
  };

  return (
    <>
      <section className="dashboard__main">
        <AddboxTemplateHeadder
          title={showPreview ? showPreview?.name : 'Add a box'}
          handleCreateBox={handleCreateBox}
          setOpen={setOpen}
          setSelectedTemplate={setSelectedTemplate}
          HeaderIcon={() => {
            return (
              <>
                {!showPreview ? (
                  <IconAddBoxTemplate />
                ) : (
                  <figure
                    className="avatar-container avatar-container--contain"
                    style={{
                      width: 50,
                      height: 50,
                      fontSize: '30px',
                      boxShadow: 'inset 0px 0px 0px 1px rgb(0 0 0 / 5%)'
                    }}
                  >
                    {boxIcon}
                  </figure>
                )}
              </>
            );
          }}
          showPreview={showPreview}
          onClose={onClose}
          setShowPreview={setShowPreview}
        />
        <div className="" style={{ height: '100%' }}>
          {isLoading && <Loader />}
          {!showPreview ? (
            <BoxTemplates
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              handleCreateBox={handleCreateBox}
              handleCopyFromBox={handleCopyFromBox}
              setOpen={setOpen}
              setSelectedTemplate={setSelectedTemplate}
            />
          ) : (
            <PreviewBoxTemplate
              setShowPreview={setShowPreview}
              showPreview={showPreview}
            />
          )}
        </div>
      </section>
      <CopyFromBoxModal
        open={openCopyFromBoxModal}
        onClose={() => setOpenCopyFromBoxModal(false)}
      />
      <AddCustomBoxModal
        open={open}
        onClose={onCloseModal}
        handleCreateBox={handleCreateBox}
        setIsCreateLoading={setIsCreateLoading}
        isCreateLoading={isCreateLoading}
      />
    </>
  );
};

export default AddBoxTemplate;

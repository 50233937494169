import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { urlRegex } from 'components/fieldsAndValidation';
import { setVersionStatus } from 'slices/ai-assistant/assistant/slice';
import { BOT_ON_PAGES, GET_BOT_SCRIPT } from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';

const initialState = {
  botOnPages: {},
  botScript: '',
  masterBotId: ''
};

const reducers = {
  setBotOnPages: (state, action) => {
    state.botOnPages = action.payload;
  },
  setBotSript: (state, action) => {
    state.botScript = action.payload;
  },
  setMasterBotId: (state, action) => {
    state.masterBotId = action.payload;
  }
};

export const botOnPages = createSlice({
  name: 'botOnPages',
  initialState,
  reducers
});

export const { setBotOnPages, setBotSript, setMasterBotId } =
  botOnPages.actions;
export default botOnPages.reducer;

export const getBotScript = (botId, succesCb) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_BOT_SCRIPT, 'aiBotId', botId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBotSript(res.data.scrpit));
            dispatch(setMasterBotId(res.data.master_bot_id));
            if (succesCb) succesCb(res.data.master_bot_id);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateBotOnPages = (data, succesCb, failedCb) => {
  return async (dispatch, getState) => {
    const botId = data.botId;
    const option = data.option;
    const urlList = data.payload.url_list;
    const { currentVersionId } = getState().assistant;
    const updatedUrlList = urlList.filter((url) => {
      return url !== '' && url.match(urlRegex);
    });
    const payload = {
      access_type: data.payload.access_type,
      url_list: updatedUrlList,
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .put(replaceUrl(BOT_ON_PAGES, 'aiBotId', botId), payload)
        .then((res) => {
          if (res.status === 200) {
            const data = {
              access_type: res.data.access_type,
              url_list: [...res.data.url_list]
            };
            dispatch(setBotOnPages(data));
            dispatch(setVersionStatus(res.data.version_status));
            if (succesCb) succesCb(option);
          }
        })
        .catch((e) => {
          console.log(e);
          if (failedCb) failedCb();
        });
    } catch (e) {
      console.log(e);
      if (failedCb) failedCb();
    }
  };
};

export const getBotUrlsOnPages = (botId) => {
  return async (dispatch) => {
    try {
      await new APIRequest()
        .get(replaceUrl(BOT_ON_PAGES, 'aiBotId', botId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBotOnPages(res.data));
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import List from './list';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import { ReactComponent as MoveToSvg } from '../../../assets/icons/move.svg';
import { ReactComponent as MoveToGreenSvg } from 'assets/icons/icon-move-green.svg';
import { useNavigate } from 'react-router-dom';
// import Avatar from 'components/avatar';
// import { ReactComponent as UserSvg } from '../../../assets/icons/user-circle.svg';
import { useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

const MoveTo = (props) => {
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selected, setSelected] = useState(null);
  const [active, setActive] = useState(null);
  const { boxes } = useSelector((state) => state.boxes);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const [notes, setNotes] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { box, box_stage } = activeLead;

  const onSubmit = async (isAssigned) => {
    // const formData = {
    //   leadId: cardId,
    //   body: {
    //     assigned_to: selected?.user_id,
    //     note: notes
    //   }
    // };
    // if (isAssigned) {
    //   dispatch(unAssigntoUserLead(formData, userUnAssigned))
    // } else {
    //   dispatch(assigntoUserLead(formData, userAssigned));
    // }
    setShowDropDown(false);
    setSelected(null);
    if (isBox) {
      navigate(`/dashboard/boxes/${box_type}/${box_id}/${stageId}`);
    } else {
      navigate('/dashboard/enquirybox/eb-box');
    }
  };

  // useEffect(() => {
  //   checkAlreadyAssigned();
  // }, [cardId]);

  // const checkAlreadyAssigned = () => {
  //   const isAlreadyAssigned = !isEmpty(activeLead?.assigned_to);
  //   isAlreadyAssigned &&
  //     setActive({
  //       ...activeLead.assigned_to,
  //       user_id: activeLead.assigned_to.id
  //     });
  // };

  // const RenderButton = () => {
  //   return (
  //     <PrimaryWithLogo
  //       label={
  //         selected
  //           ? selected.first_name
  //           : active
  //           ? active.first_name
  //           : `Unassigned`
  //       }
  //       renderSvg={() => {
  //         if (selected)
  //           return (
  //             <Avatar
  //               src={selected?.avatar}
  //               initial={selected?.first_name}
  //               border={false}
  //               initialStyle={{
  //                 fontSize: 10,
  //                 fontWeight: 500,
  //                 marginBottom: 0
  //               }}
  //               mini
  //               contain
  //               singleChar
  //             />
  //           );
  //         if (!isEmpty(active)) {
  //           return (
  //             <Avatar
  //               src={active.avatar}
  //               initial={active?.first_name}
  //               // style={{ background: 'none', border: '1px solid #fff' }}
  //               initialStyle={{
  //                 fontSize: 10,
  //                 fontWeight: 500,
  //                 marginBottom: 0
  //               }}
  //               border={false}
  //               mini
  //               singleChar
  //             />
  //           );
  //         }
  //         return <UserSvg />;
  //       }}
  //       onClick={() => setShowDropDown(!showDropDown)}
  //       className={`chat-message-header-button ${
  //         showDropDown ? 'clicked' : ''
  //       }`}
  //       style={{ padding: '4px 14px' }}
  //     />
  //   );
  // };

  const RenderButton = () => {
    return (
      <PrimaryWithLogo
        label="Move To"
        renderSvg={() => {
          // if (selected) return <Avatar src={avatarPic} mini contain border={false} />;
          return !showDropDown ? <MoveToSvg /> : <MoveToGreenSvg />;
        }}
        onClick={() => setShowDropDown(!showDropDown)}
        className={`chat-message-header-button ${
          showDropDown ? 'clicked' : ''
        }`}
        style={{ padding: '4px 14px' }}
      />
    );
  };

  const onSelect = (option) => {
    // if (active) {
    //   if (active.user_id === option.user_id) {
    //     setSelected(null);
    //   } else {
    //     setSelected(option);
    //   }
    // } else {
    //   setSelected(option);
    // }
    setSelected(option);
  };

  const getBoxOptions = () => {
    // if (isBox) {
    //   return boxes.filter((box) => box.id !== box_id);
    // }
    return boxes;
  };

  return (
    <List
      title="Move To"
      renderButton={RenderButton}
      options={getBoxOptions()}
      open={showDropDown}
      onSelect={onSelect}
      onClose={(state) => setShowDropDown(state)}
      selected={selected}
      active={active}
      setNotes={setNotes}
      setSelected={setSelected}
      onSubmit={onSubmit}
      notes={notes}
      setActive={setActive}
    />
  );
};

export default MoveTo;

import ProgressBar from 'components/progressBar';
import { useDispatch, useSelector } from 'react-redux';
import { setShowSubscriptionModal } from 'slices/subscription/slice';

const FreeEnquiriesCounter = () => {
  const dispatch = useDispatch();
  const { currentPlan, freeEnquiriesCount } = useSelector(
    (state) => state.subscription
  );

  const getProgressStatus = () => {
    if (currentPlan?.plan?.enquiries_limit) {
      return Math.floor(
        (parseInt(freeEnquiriesCount) /
          parseInt(currentPlan.plan.enquiries_limit)) *
          100
      );
    }
    return 0;
  };
  // console.log(getProgressStatus())
  return (
    <div className="enquiries-progress-bar">
      <div className="flex items-center justify-between">
        <span className="text-15 font-medium">
          {freeEnquiriesCount > currentPlan?.plan?.enquiries_limit
            ? currentPlan?.plan?.enquiries_limit
            : freeEnquiriesCount}{' '}
          <span className="text-grey-600">/</span>{' '}
          {currentPlan?.plan?.enquiries_limit} Enquiries
        </span>
        <span
          className="text-primary text-15 font-medium cursor-pointer"
          onClick={() => dispatch(setShowSubscriptionModal(true))}
        >
          Upgrade
        </span>
      </div>
      <div className="mt-2">
        <ProgressBar bgcolor="#eef1f3" progress={getProgressStatus()} />
      </div>
      <div className="enquiries-progress-bar__tooltip-container">
        <p className="text-white text-xs text-center letterspacing24em">
          Upgrade your plan<br></br> for unlimited enquiries
        </p>
      </div>
    </div>
  );
};

export default FreeEnquiriesCounter;

/* eslint-disable no-prototype-builtins */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllChannels,
  getAllPendingChannels,
  getAllConnectedChannels
} from 'slices/channelsSlice';
// import { useMyInfoQuery } from 'services/myInfoApi';
import { getMyInfo, getNudges } from 'slices/myInfoSlice';
import Loader from 'components/loader';
// import { setMyInformation } from 'slices/myInfoSlice';
import { isMobile } from 'utils/utils';
// import { Route } from 'react-router-dom';
import NavBar from './navbar';
import MainRoutes from './routes';
import { isEmpty } from 'lodash';
import DashboardRoutes from './routes/dashboardRoutes';
import EnquiryBinRoutes from './routes/enquiryBinRoutes';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { route } from 'utils/route';
import ReportRoutes from './routes/reportRoutes';
// import { getTotalOrganisationLeads } from 'slices/eboxLead';
import { getAllReplyTemplates } from 'slices/replyTemplate/slice';
import { getAllCounts } from 'slices/allCount/slice';
import {
  getFreeEnquiriesCount,
  getSubscriptionPlans,
  setPostExpiryActiveModal,
  setShowDeleteAccountModal,
  setShowSubscriptionModal
} from 'slices/subscription/slice';
// import Intercom from 'react-intercom';
import CHOICES from 'choices';
import { getDeviceInfo } from 'utils/devices';
import ToolBox from 'modules/toolbox';
import { useAnalytics } from 'use-analytics';
import ConfirmEbotToEboxConnection from 'modules/confirmEbotToEbox';
import PlansSubscription from 'modules/subscription/plans';
import FreeEnquiriesExpired from 'modules/subscription/freeEnquiriesExpired';
import { getAllTaskOutcome } from 'slices/task/slice';
import usePlanAccess from 'hooks/usePlanAccess';
import { getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';
import { getOrganizationUser } from 'slices/organizationuserSlice';
import { getSmsPlans, getSmsDetails } from 'slices/subscription/slice';
import LogoLoader from 'components/logoLoader';
import Intercom from 'react-intercom';
import Sockets from 'modules/sockets';
import { IntercomProvider } from 'react-use-intercom';

const { BUSINESS_INFO, LOGIN_SCREEN } = CHOICES.FrontendRedirectChoice;
const IndustryChoices = CHOICES.IndustryChoices.CHOICES;
const { FREE_TRIAL } = CHOICES.PlanType;
import choices from 'choices';
import { DELETE_ACCOUNT, LOGOUT } from 'utils/constants';
import ChannelsHome from 'modules/channels/home';
import HomeNewSettings from 'modules/settingsNew/home';
// import ChatbotAiRoutes from 'modules/aiAssistant/chatbotAiRoutes/ChatbotAiRoutes';
import Index from 'modules/chatbotAi';
import Preview from 'modules/chatbotAi/dashboard/assistant/preview';

const { CANCELLED } = choices.SubscriptionStatusChoices;
const Navigation = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [intercomUser, setintercomUser] = useState({});
  const planFeature = usePlanAccess();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { myInformation, subOrganizationId, infoCount } = useSelector(
    (state) => state.myInfo
  );
  const { currentPlan } = useSelector((state) => state.subscription);
  const redirect = useSelector((state) => state.auth.redirect);
  const { identify, plugins, track } = useAnalytics();

  useEffect(() => {
    requestMyInfo();
  }, []);

  const sendLoginAnalytics = (data) => {
    if (redirect !== BUSINESS_INFO && redirect !== LOGIN_SCREEN) {
      identify(
        data.id,
        {
          name: data.name,
          first_name: data.first_name,
          last_name: data.last_name || '',
          avatar: data.avatar,
          user_level: CHOICES.TeamMemberRoleChoice.CHOICES[data.my_role],
          email: data.email,
          user_status: data.is_active ? 'active' : 'deactive',
          reply_email: data.reply_sending_mail_address,
          created_at: data.created_at,
          country: data?.country,
          company_name: data?.company_name,
          industry: IndustryChoices[data?.industry],
          login_type: CHOICES.SignupChoice[data.last_login_through],
          email_signature: CHOICES.EmailSignatureChoice[data?.email_signature],
          website: data?.website
          // plan_amount: data?.plan_details?.plan?.amount,
          // plan_name: data?.plan_details?.plan?.plan_name
        },
        {
          groupId: data.sub_organizational_ids[0].sub_organization_id
        }
      );
      plugins.segment.group(
        data.sub_organizational_ids[0].sub_organization_id,
        {
          name: data?.company_name,
          industry: IndustryChoices[data?.industry],
          website: data?.website,
          country: data?.country,
          team_size: data?.team_size,
          plan_amount: data?.plan_details?.plan?.amount,
          plan_name: data?.plan_details?.plan?.plan_name
        }
      );
      track(
        'logged_in',
        {
          device: getDeviceInfo()?.currentos?.name || '',
          browser: getDeviceInfo()?.currentbrowser?.name || ''
        },
        {
          groupId: data.sub_organizational_ids[0].sub_organization_id
        }
      );
    }
  };

  const requestMyInfo = () => {
    dispatch(
      getMyInfo((data) => {
        const { sub_organizational_ids, is_disabled } = data;
        const [sub_org] = sub_organizational_ids;
        if (is_disabled) {
          dispatch(setShowSubscriptionModal(true));
          dispatch(setPostExpiryActiveModal(LOGOUT));
        }
        if (sub_org) {
          dispatch(
            getOrganizationUser(
              { subOrgId: sub_org.sub_organization_id },
              () => {
                setIsLoading(false);
              },
              true
            )
          );
        }
        getGlobalChannels(data);
        dispatch(getNudges({ userId: data?.id }));
        if (infoCount === 0) {
          sendLoginAnalytics(data);
        }
        if (!sub_org) {
          setIsLoading(false);
        }
      })
    );
  };

  const getGlobalChannels = (data) => {
    dispatch(getAllChannels());
    if (!isEmpty(data.sub_organizational_ids)) {
      dispatch(getAllConnectedChannels());
    }
  };

  useEffect(() => {
    if (subOrganizationId) {
      dispatch(getAllCounts());
      if (currentPlan?.plan?.plan_type == FREE_TRIAL) {
        dispatch(getFreeEnquiriesCount());
      }
      dispatch(getTaskEnquiryCount());
    }
  }, [pathname]);

  useEffect(() => {
    if (currentPlan?.plan?.plan_type == FREE_TRIAL) {
      dispatch(getFreeEnquiriesCount());
    }
  }, [currentPlan]);

  useEffect(() => {
    if (subOrganizationId) {
      dispatch(getAllReplyTemplates());
      // dispatch(getAllConnectedChannels());
      // dispatch(getTotalOrganisationLeads());
      dispatch(getSmsPlans());
      dispatch(getSmsDetails());
      dispatch(getAllCounts());
      dispatch(getSubscriptionPlans(subOrganizationId));
      if (currentPlan?.plan?.plan_type == FREE_TRIAL) {
        dispatch(getFreeEnquiriesCount());
      }
      dispatch(getAllTaskOutcome({ subOrgId: subOrganizationId }));
    }
  }, [subOrganizationId]);

  useEffect(() => {
    if (!isEmpty(myInformation)) {
      let user = {
        user_id: myInformation.id,
        email: myInformation.email,
        name: myInformation.name,
        created_at: myInformation.date_joined
      };
      setintercomUser(user);
      let currPlan = myInformation.plan_details;
      let payment_failed = false;
      let payment_completed = true;

      if (!isEmpty(currPlan)) {
        payment_failed = currPlan?.payment_failed;
        payment_completed = currPlan?.payment_completed;
      }
      if (
        myInformation.subscription_status === CANCELLED &&
        payment_failed === false &&
        payment_completed === true
      ) {
        dispatch(setShowDeleteAccountModal(true));
        dispatch(setPostExpiryActiveModal(DELETE_ACCOUNT));
      }
    }
  }, [myInformation]);

  // useEffect(() => {
  //   if (infoCount === 1) {
  //     // sendLoginAnalytics({
  //     //   id: myInformation?.id,
  //     //   name: myInformation?.name,
  //     //   email: myInformation?.email
  //     // })
  //   }
  // }, [infoCount])

  if (isLoading) return <LogoLoader />;

  return (
    <>
      <Sockets />
      <IntercomProvider
        appId={process.env.REACT_APP_INTERCOM_APP_ID}
        // autoBootProps={{ name: myInformation?.name }}
        // autoBoot
      >
        <main className="dashboard">
          <section className="dashboard__container flex items-start">
            {/* <NavBar /> */}
            <PlansSubscription />
            <FreeEnquiriesExpired />
            <Routes>
              <Route
                path="/"
                element={<Navigate to={`${route.dashboard}`} />}
              />
              <Route
                path={`${route.dashboard}/*`}
                element={<DashboardRoutes />}
              />
              <Route
                path={`${route.enquiryBin}/*`}
                element={<EnquiryBinRoutes />}
              />
              <Route path={`${route.report}/*`} element={<ReportRoutes />} />
              <Route path={`${route.toolBox}/*`} element={<ToolBox />} />
              <Route path={`${route.channels}/*`} element={<ChannelsHome />} />
              <Route path={`${route.chatbotAi}/*`} element={<Index />} />
              <Route path="/preview/:masterBotId" element={<Preview />} />
              <Route
                path={`${route.settings}/*`}
                element={<HomeNewSettings />}
              />
              <Route
                path={`${route.confirmEbotToEbox}/*`}
                element={<ConfirmEbotToEboxConnection />}
              />
            </Routes>
          </section>
        </main>
      </IntercomProvider>
    </>
  );
};

export default Navigation;

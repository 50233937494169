/* eslint-disable react-hooks/exhaustive-deps */
import TextInputField from 'components/inputs/textField';
import React, { useState, useRef, useEffect } from 'react';
import QuestionHeader from '../../../../component/questionHeader';
import { ReactComponent as CalenderIcon } from 'assets/icons/icon-date-picker.svg';
import CHOICES from 'choices';
import useClickOutside from 'hooks/useOutsideClick';
import Calendar from 'components/calendar';
import { formatDateTime } from 'utils/dateFormat';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import {
  createAnswer,
  DeleteQuestion,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm,
  setSelectedQuestion,
  updateAnswer
} from 'slices/questionSlice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import ErrorMessage from 'components/errorMessage';
import { useParams } from 'react-router-dom';
import OverLayLoader from 'components/loader/overLayLoader';
import { questionTypes } from 'utils/questions';

const { DATETIME, DATE_RANGE } = CHOICES.QuestionChoices;

const DateQuestionAdded = ({
  questionData,
  title,
  answer,
  deleteQuestion,
  isBin
}) => {
  const { cardId, stageId, box_id } = useParams();
  const dropdownRef = useRef();
  const fromDateRef = useRef();
  const toDateRef = useRef();
  const dispatch = useDispatch();
  const date = new Date();
  const [openCalender, setOpenCalender] = useState(false);
  const [openFromCalender, setOpenFromCalender] = useState(false);
  const [openToCalender, setOpenToCalender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [startDate, setStartDate] = useState(
    !isEmpty(answer)
      ? formatDateTime(answer[0]?.answer?.start_date, 'mmm dd, yyyy')
      : undefined
  );
  const [endDate, setEndDate] = useState(
    !isEmpty(answer)
      ? formatDateTime(answer[0]?.answer?.end_date, 'mmm dd, yyyy')
      : undefined
  );

  useEffect(() => {
    setStartDate(
      !isEmpty(answer)
        ? formatDateTime(answer[0]?.answer?.start_date, 'mmm dd, yyyy')
        : undefined
    );
    setEndDate(
      !isEmpty(answer)
        ? formatDateTime(answer[0]?.answer?.end_date, 'mmm dd, yyyy')
        : undefined
    );
  }, [answer]);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [error, setError] = useState('');
  const { myInformation } = useSelector((state) => state.myInfo);

  useClickOutside(() => {
    setOpenCalender(false),
      setOpenFromCalender(false),
      setOpenToCalender(false);
  }, dropdownRef.current);

  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = () => {
    setIsLoading(false);
  };

  const onDeleteCallback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    deleteQuestion(questionId);
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(
      DeleteQuestion({
        questionId: questionId,
        box_id,
        callback: onDeleteCallback
      })
    );
  };

  const onDateSelect = (date) => {
    setStartDate(formatDateTime(date, 'mmm dd, yyyy'));
    if (isEmpty(answer)) {
      setIsLoading(true);
      setOpenCalender(false);
      const formData = {
        answer: { start_date: moment(date).format('YYYY-MM-DD') },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(createAnswer({ body: formData, callback }));
    } else {
      setIsLoading(true);
      setOpenCalender(false);
      const formData = {
        answer: { start_date: moment(date).format('YYYY-MM-DD') },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(
        updateAnswer({ body: formData, answerId: answer[0]?.id, callback })
      );
    }
  };

  const onStartDateSelect = (date) => {
    const DATE = formatDateTime(date, 'yyyy-mm-dd');
    setStartDate(formatDateTime(DATE, 'mmm dd, yyyy'));
    setError('');
    if (!endDate) {
      // if no end date
      setOpenFromCalender(false);
      setOpenToCalender(true);
      toDateRef?.current?.focus();
    } else {
      const start_date = moment(date).format('YYYY-MM-DD');
      const end_date = moment(endDate).format('YYYY-MM-DD');
      if (isEmpty(answer)) {
        // setIsLoading(true);
        // setOpenFromCalender(false);
        // const formData = {
        //   answer: { start_date: start_date, end_date: end_date },
        //   user: myInformation.id,
        //   question: questionData.id,
        //   lead: cardId
        // };
        // dispatch(createAnswer({ body: formData, callback }));
      } else {
        if (moment(endDate).isSameOrAfter(moment(DATE), 'day')) {
          setIsLoading(true);
          const formData = {
            answer: { start_date: start_date, end_date: end_date },
            user: myInformation.id,
            question: questionData.id,
            lead: cardId
          };
          setOpenFromCalender(false);
          dispatch(
            updateAnswer({ body: formData, answerId: answer[0]?.id, callback })
          );
        } else {
          setOpenFromCalender(false);
          setOpenToCalender(true);
          setEndDate(undefined);
          toDateRef?.current?.focus();
        }
        // setOpenFromCalender(true);
        // setOpenToCalender(false);
        // setEndDate(undefined)
        // const formData = {
        //   answer: { start_date: start_date, end_date: end_date },
        //   user: myInformation.id,
        //   question: questionData.id,
        //   lead: cardId
        // };
        // dispatch(
        //   updateAnswer({ body: formData, answerId: answer[0]?.id, callback })
        // );
      }
    }
  };

  const onEndDateSelect = (date) => {
    setEndDate(formatDateTime(date, 'mmm dd, yyyy'));
    const end_date = moment(date).format('YYYY-MM-DD');
    setOpenToCalender(false);
    if (startDate) {
      const start_date = moment(startDate).format('YYYY-MM-DD');
      if (isEmpty(answer)) {
        setIsLoading(true);
        const formData = {
          answer: { start_date: start_date, end_date: end_date },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(createAnswer({ body: formData, stageId, callback }));
      } else {
        setIsLoading(true);
        const formData = {
          answer: { start_date: start_date, end_date: end_date },
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(
          updateAnswer({
            body: formData,
            answerId: answer[0]?.id,
            stageId,
            callback
          })
        );
      }
    } else {
      setError('Select From date');
    }
  };

  const onOpenFromCalender = () => {
    setOpenFromCalender(true);
    setOpenToCalender(false);
  };

  const onOpenToCalender = () => {
    if (!startDate) {
      setOpenFromCalender(true);
      toDateRef?.current?.blur();
      fromDateRef?.current?.focus();
      return;
    }
    setOpenFromCalender(false);
    setOpenToCalender(true);
  };

  const onEdit = () => {
    const editQuestion = questionTypes.filter(
      (item) => item.type === DATETIME || item.type === DATE_RANGE
    );
    dispatch(setIsEdit(true));
    dispatch(setEditedData(questionData));
    dispatch(setIsShowQuestionForm(true));
    dispatch(setSelectedQuestion(editQuestion[0]));
  };

  return (
    <>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          showConfirmation={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this question?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ height: 'max-content' }}
          isLoading={isLoading}
        />
      )}
      {questionData?.kind === DATETIME ? (
        <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
          {isLoading && <OverLayLoader />}
          <QuestionHeader
            label={title}
            isShowMoreIcon={true}
            onDelete={onDeleteQuestion}
            id={questionData.id}
            isBin={isBin}
            onEdit={onEdit}
          />
          <div className="py-4 px-5">
            <TextInputField
              name="date"
              placeholder={formatDateTime(date, 'mmm dd, yyyy')}
              className="input-field--xs-34 input-icon-pos-right"
              value={startDate ? startDate : ''}
              onClick={isBin ? () => {} : () => setOpenCalender(true)}
              renderIcon={() => (
                <CalenderIcon
                  className="cursor-pointer"
                  onClick={isBin ? () => {} : () => setOpenCalender(true)}
                />
              )}
              iconClassName="input-icon-right"
              style={{ paddingLeft: 0 }}
              readOnly={true}
            >
              <div
                className="bg-white xxl:right-0 xxl:left-auto xxl:max-w-xs"
                style={
                  openCalender
                    ? {
                        height: '260px',
                        width: '430px',
                        position: 'absolute',
                        top: 40,
                        boxShadow:
                          '0px -2px 4px -2px rgba(85, 83, 80, 0.04),0px 12px 30px -4px rgba(85, 83, 80, 0.25)',
                        borderRadius: '10px',
                        zIndex: 99999
                      }
                    : {}
                }
              >
                <div
                  style={
                    openCalender
                      ? {
                          display: 'inline-block'
                        }
                      : { display: 'none' }
                  }
                  // className={`dropdown-menu ${
                  //   openCalender ? 'open' : ''
                  // } xxl:right-0 xxl:left-auto xxl:max-w-xs`}
                  ref={dropdownRef}
                  // style={{ top: 40 }}
                >
                  <div className="calender-select">
                    <Calendar
                      enableFutureDates={true}
                      onDateClick={onDateSelect}
                      selectedDate={new Date(startDate)}
                      containerStyle={{ width: '100%' }}
                    />
                  </div>
                </div>
              </div>
            </TextInputField>
          </div>
        </div>
      ) : (
        <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
          {isLoading && <OverLayLoader />}
          <QuestionHeader
            label={title}
            isShowMoreIcon={true}
            onDelete={onDeleteQuestion}
            id={questionData.id}
            isBin={isBin}
            onEdit={onEdit}
          />
          <div
            className={`${error ? 'pt-4' : 'py-4'} px-5 flex gap-2`}
            ref={dropdownRef}
          >
            <div style={{ width: '50%' }}>
              <label htmlFor="startDate" className="text-sm font-semibold">
                From
              </label>
              <TextInputField
                ref={fromDateRef}
                name="startDate"
                placeholder={formatDateTime(date, 'mmm dd, yyyy')}
                className="input-field--xs-34 input-icon-pos-right"
                value={startDate ? startDate : ''}
                onClick={isBin ? () => {} : onOpenFromCalender}
                renderIcon={() => (
                  <CalenderIcon
                    className="cursor-pointer"
                    onClick={isBin ? () => {} : onOpenFromCalender}
                  />
                )}
                iconClassName="input-icon-right"
                style={{ paddingLeft: 0 }}
                readOnly={true}
              >
                <div
                  className="bg-white xxl:right-0 xxl:left-auto xxl:max-w-xs"
                  style={
                    openFromCalender
                      ? {
                          height: '260px',
                          position: 'absolute',
                          top: 40,
                          boxShadow:
                            '0px -2px 4px -2px rgba(85, 83, 80, 0.04),0px 12px 30px -4px rgba(85, 83, 80, 0.25)',
                          borderRadius: '10px',
                          zIndex: 99999
                        }
                      : {}
                  }
                >
                  <div
                    style={
                      openFromCalender
                        ? {
                            display: 'inline-block'
                          }
                        : { display: 'none' }
                    }
                    // className={`dropdown-menu ${openFromCalender ? 'open' : ''}`}
                    // style={{ top: 40 }}
                  >
                    <div className="calender-select">
                      <Calendar
                        enableFutureDates={true}
                        onDateClick={onStartDateSelect}
                        selectedDate={new Date(startDate)}
                        containerStyle={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              </TextInputField>
            </div>
            <div style={{ width: '50%' }}>
              <label htmlFor="endDate" className="text-sm font-semibold">
                To
              </label>
              <TextInputField
                ref={toDateRef}
                name="endDate"
                placeholder={formatDateTime(date, 'mmm dd, yyyy')}
                className="input-field--xs-34 input-icon-pos-right"
                value={endDate ? endDate : ''}
                onClick={isBin ? () => {} : onOpenToCalender}
                renderIcon={() => (
                  <CalenderIcon
                    className="cursor-pointer"
                    onClick={isBin ? () => {} : onOpenToCalender}
                  />
                )}
                iconClassName="input-icon-right"
                style={{ paddingLeft: 0 }}
                readOnly={true}
              >
                <div
                  className="bg-white xxl:right-0 xxl:left-auto xxl:max-w-xs"
                  style={
                    openToCalender
                      ? {
                          height: '260px',
                          position: 'absolute',
                          top: 40,
                          boxShadow:
                            '0px -2px 4px -2px rgba(85, 83, 80, 0.04),0px 12px 30px -4px rgba(85, 83, 80, 0.25)',
                          borderRadius: '10px',
                          zIndex: 99999
                        }
                      : {}
                  }
                >
                  <div
                    style={
                      openToCalender
                        ? {
                            display: 'inline-block'
                          }
                        : { display: 'none' }
                    }
                    // className={`dropdown-menu ${openToCalender ? 'open' : ''}`}
                    // style={{ top: 40 }}
                  >
                    <div className="calender-select">
                      <Calendar
                        minDate={new Date(startDate)}
                        enableFutureDates={true}
                        onDateClick={onEndDateSelect}
                        selectedDate={new Date(endDate)}
                        containerStyle={{ width: '100%' }}
                      />
                    </div>
                  </div>
                </div>
              </TextInputField>
            </div>
          </div>
          {error && <ErrorMessage message={error} className="px-5 mb-2" />}
        </div>
      )}
    </>
  );
};

export default DateQuestionAdded;

import React, { useEffect, useState } from 'react';
import Task from './task';
import CompleteTask from './completeTask';
import OtherTasks from './otherTasks';
import TaskEditor from '../../footer/task';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteLeadTask,
  getAllCompletedTasks,
  getAllNewTasks,
  resetTaskStore,
  setAllNewTasks
} from 'slices/task/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';
import iconHand from 'assets/icons/icon-hand.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';
import { getLeadData } from 'slices/conversationSlice';
import { TASK } from 'utils/constants';
import { route } from 'utils/route';
import { getAllTasks, getTaskEnquiryCount } from 'slices/taskThingsToDo/slice';

const Tasks = () => {
  const dispatch = useDispatch();
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const [taskCompleted, setTaskCompleted] = useState(null); // state to store the task id selected for completion
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(null); //state to open or close note delete confirmation modal
  const [isDeleteLoading, setIsDeleteLoading] = useState(false); //state for loader inside delete button
  const { allCompletedTasks, allNewTasks, editableTaskId, taskOutcome } =
    useSelector((state) => state.tasks);
  const { activeLead, activeTab } = useSelector(
    (state) => state.leadConversation
  );
  const { showContactModal } = useSelector((state) => state.leads);
  const [isHover, setIsHover] = useState(null);
  const loadAllNewTasks = async (leadId) => {
    // api call to get all new task
    return await dispatch(getAllNewTasks({ leadId }));
  };

  const loadAllCompletedTasks = async (leadId) => {
    // api call to get all completed task
    return await dispatch(getAllCompletedTasks({ leadId }));
  };

  const loadTasks = () => {
    setIsLoading(true);
    Promise.all([loadAllNewTasks(cardId), loadAllCompletedTasks(cardId)]).then(
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    loadTasks();
    // to reset the task editor when task screen loads
    dispatch(resetTaskStore());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (activeTab === TASK && showContactModal)
        if (isBox) {
          navigate(
            `${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/${activeLead.channel}/${activeLead.id}`
          );
        }
    }, 1000);
  }, [activeTab, showContactModal]);

  // function to delete the task
  const onDelete = () => {
    setIsDeleteLoading(true);
    const payload = {
      taskId: openDeleteModal
    };
    dispatch(deleteLeadTask(payload, onDeleteSuccess));
  };

  // function to execute on delete task success
  const onDeleteSuccess = (data) => {
    // removing deleted task from locally
    const updatedLeadTask = allNewTasks.filter(
      (item) => item.id !== data.task_id
    );
    // calling lead data api if the overdue count is more than 0
    if (activeLead?.no_of_overdue_task_count > 0) {
      dispatch(getLeadData({ leadId: cardId }));
    }
    if (data.task_id === editableTaskId) {
      dispatch(resetTaskStore());
    }
    dispatch(setAllNewTasks(updatedLeadTask));
    setIsDeleteLoading(false);
    setOpenDeleteModal(null);
    dispatch(getAllTasks());
    dispatch(getTaskEnquiryCount());
    openSuccessSnackBar('Task deleted');
  };

  if (isLoading) return <Loader />;

  return (
    <>
      {openDeleteModal && (
        <DeleteConfirmation
          showConfirmation={openDeleteModal !== null ? true : false}
          onCancel={() => setOpenDeleteModal(null)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this task?"
          description="Once deleted you will not be able to get it back. You will have to
        add it again."
          containerStyle={{ width: 536, height: 'initial', top: 'auto' }}
          isLoading={isDeleteLoading}
        />
      )}
      <div style={{ height: 'calc(100% - 50px)' }}>
        <section className="contact-tasks">
          {isEmpty(allCompletedTasks) && isEmpty(allNewTasks) ? (
            <div className="notes-empty-container">
              <p className="text-lg font-semibold">
                Add a task and close sales fast
              </p>
              <img
                src={iconHand}
                alt="icon-hand"
                style={{ transform: 'rotate(-90deg)', marginTop: 10 }}
              />
            </div>
          ) : (
            <>
              <ul className="">
                {!isEmpty(allNewTasks) &&
                  allNewTasks.map((task) => {
                    const {
                      note,
                      due_date,
                      task_type,
                      assigned_to,
                      assigned_user,
                      created_user,
                      sent_by_automation
                    } = task;
                    return (
                      <li
                        className={`contact-tasks__list ${
                          taskCompleted === task.id ||
                          editableTaskId === task.id
                            ? 'active'
                            : ''
                        }`}
                        style={{ zIndex: 999 }}
                        key={task.id}
                        onMouseEnter={() => setIsHover(task.id)}
                        onMouseLeave={() => setIsHover(null)}
                      >
                        {taskCompleted === task.id ? (
                          <CompleteTask
                            taskCompleted={taskCompleted}
                            setCurrentTaskCompleted={setTaskCompleted}
                            taskId={task.id}
                            task_type={task_type}
                            data={taskOutcome?.filter(
                              (item) => item.task_type === task_type
                            )}
                          />
                        ) : (
                          <Task
                            setCurrentTaskCompleted={setTaskCompleted}
                            title={note}
                            dueDate={due_date}
                            taskType={task_type}
                            id={task.id}
                            assignTo={assigned_to}
                            assignedUser={assigned_user}
                            setOpenDeleteModal={setOpenDeleteModal}
                            createdUser={created_user}
                            isAutomation={sent_by_automation}
                            isHover={isHover}
                          />
                        )}
                      </li>
                    );
                  })}
              </ul>
              {!isEmpty(allCompletedTasks) && (
                <div className={`${!isEmpty(allNewTasks) ? 'mt-6' : ''}`}>
                  <label className="text-grey-700 text-xs font-medium block uppercase">
                    Completed
                  </label>
                  <ul className="mt-5">
                    {allCompletedTasks.map((task) => {
                      const {
                        note,
                        task_type,
                        completed_by_user,
                        outcome,
                        outcome_descriptions,
                        status,
                        sent_by_automation
                      } = task;
                      return (
                        <OtherTasks
                          key={task.id}
                          title={note}
                          completedUser={completed_by_user}
                          taskType={task_type}
                          outcomeName={outcome?.options}
                          description={outcome_descriptions}
                          completedDate={status?.created_at}
                          isAutomation={sent_by_automation}
                        />
                      );
                    })}
                  </ul>
                </div>
              )}
            </>
          )}
        </section>
        <div
          style={{
            position: 'absolute',
            bottom: 0,
            width: '100%',
            margin: 0,
            borderRadius: '0px'
            // marginBottom: '-5px' // this is added as alternative to not alter modal_container from modal component
          }}
          className="chat-message-footer chat-message-footer__reminder"
        >
          <TaskEditor style={{ height: '140px' }} />
        </div>
      </div>
    </>
  );
};

export default Tasks;

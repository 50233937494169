import React from 'react';
import { route } from 'utils/route';
import SettingLink from 'modules/settings/components/settingLink';
import { ReactComponent as SnapshotIcon } from 'modules/channel/assets/icons/icon-report-snapshot.svg';
import { ReactComponent as SalesIcon } from 'assets/icons/icon-sales-report.svg';
import { ReactComponent as SourceIcon } from 'assets/icons/icon-source-report.svg';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  setTempDateFormat,
  setTempEnd_Date,
  setTempStart_date
} from 'slices/reports/slice';
import moment from 'moment';

const NavLink = (props) => {
  const { isIncoming, isTeamReport, isBoxReport } = props;
  const { box_id, box_type } = useParams();
  const dispatch = useDispatch();

  const IncomingLink = [
    {
      label: 'Snapshot',
      desc: 'Full Report of all Incoming enquiries',
      icon: SnapshotIcon,
      route: `${route.report}${route.boxReport}/all-box/incoming-enquiry-report`
    },
    {
      label: 'Sales Report',
      desc: 'See what channels are working for you',
      icon: SalesIcon,
      route: `${route.report}${route.boxReport}/all-box/sales-report`
    },
    {
      label: 'Marketing Report',
      desc: 'Understand where your enquires come from',
      icon: SourceIcon,
      route: `${route.report}${route.boxReport}/all-box/source-report`
    }
  ];

  const TeamReportLink = [
    {
      label: 'Team performance reports',
      desc: 'Keep tabs on response time and sales',
      icon: SnapshotIcon,
      route: `${route.report}${route.teamReport}/team-performance`
    }
  ];

  const BoxReportLink = [
    {
      label: 'Snapshot',
      desc: 'Full snapshot of enquiries in this box',
      icon: SnapshotIcon,
      route: `${route.report}${route.boxReport}/${box_type}/${box_id}/snapshot`
    }
  ];

  const onClick = () => {
    dispatch(setTempStart_date(moment().startOf('month').format('YYYY-MM-DD')));
    dispatch(
      setTempEnd_Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
    );
    dispatch(setTempDateFormat('This month to date'));
  };

  return (
    <>
      {isIncoming &&
        IncomingLink.map((item, index) => {
          const { label, desc, route } = item;
          return (
            <SettingLink
              key={index}
              to={route}
              title={label}
              renderSvg={() => <item.icon />}
              description={desc}
              onClick={onClick}
            />
          );
        })}
      {isTeamReport &&
        TeamReportLink.map((item, index) => {
          const { label, desc, route } = item;
          return (
            <SettingLink
              key={index}
              to={route}
              title={label}
              renderSvg={() => <item.icon />}
              description={desc}
              onClick={onClick}
            />
          );
        })}
      {isBoxReport &&
        BoxReportLink.map((item, index) => {
          const { label, desc, route } = item;
          return (
            <SettingLink
              key={index}
              to={route}
              title={label}
              renderSvg={() => <item.icon />}
              description={desc}
              onClick={onClick}
            />
          );
        })}
    </>
  );
};

NavLink.propTypes = {
  isIncoming: PropTypes.bool,
  isTeamReport: PropTypes.bool,
  isBoxReport: PropTypes.bool
};

NavLink.defaultProps = {
  isIncoming: false,
  isTeamReport: false,
  isBoxReport: false
};

export default NavLink;

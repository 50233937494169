/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import moment from 'moment';
import {
  GET_ALL_BOX_REPORT,
  GET_ALL_REPORT_DATA,
  GET_ALL_TEAM_REPORT
} from 'urls/reports';
import { replaceUrl } from 'utils/urlReplace';

const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    incomingReport: {},
    salesReport: {},
    sourceReport: {},
    boxReport: {},
    teamReport: {},
    tempStartDate: moment().startOf('month').format('YYYY-MM-DD'),
    tempEndDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    tempDateFormat: 'This month to date'
  },
  reducers: {
    setIncomingReport: (state, action) => {
      state.incomingReport = action.payload;
    },
    setSalesReport: (state, action) => {
      state.salesReport = action.payload;
    },
    setSourceReport: (state, action) => {
      state.sourceReport = action.payload;
    },
    setBoxReport: (state, action) => {
      state.boxReport = action.payload;
    },
    setTempStart_date: (state, action) => {
      state.tempStartDate = action.payload;
    },
    setTempEnd_Date: (state, action) => {
      state.tempEndDate = action.payload;
    },
    setTempDateFormat: (state, action) => {
      state.tempDateFormat = action.payload;
    },
    setTeamReport: (state, action) => {
      state.teamReport = action.payload;
    }
  }
});

export const {
  setIncomingReport,
  setSalesReport,
  setSourceReport,
  setBoxReport,
  setTempStart_date,
  setTempEnd_Date,
  setTempDateFormat,
  setTeamReport
} = reportsSlice.actions;

export default reportsSlice.reducer;

export const getAllIncomingEnquiryReport = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { startDate, endDate, date_format, subOrgId, query = {} } = payload;
      const params = {
        start_date: startDate,
        end_date: endDate,
        date_format: date_format,
        report_type: 'incoming_report'
      };
      new APIRequest()
        .get(replaceUrl(GET_ALL_REPORT_DATA, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((resp) => {
          dispatch(setIncomingReport(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllSalesReport = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { startDate, endDate, date_format, subOrgId, query = {} } = payload;
      const params = {
        start_date: startDate,
        end_date: endDate,
        date_format: date_format,
        report_type: 'sales_report'
      };
      new APIRequest()
        .get(replaceUrl(GET_ALL_REPORT_DATA, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((resp) => {
          dispatch(setSalesReport(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllSourceReport = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { startDate, endDate, subOrgId, query = {} } = payload;
      const params = {
        start_date: startDate,
        end_date: endDate,
        report_type: 'source_report'
      };
      new APIRequest()
        .get(replaceUrl(GET_ALL_REPORT_DATA, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((resp) => {
          dispatch(setSourceReport(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllBoxReport = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { startDate, endDate, date_format, boxId, query = {} } = payload;
      const params = {
        start_date: startDate,
        end_date: endDate,
        date_format: date_format
      };
      new APIRequest()
        .get(replaceUrl(GET_ALL_BOX_REPORT, 'boxId', boxId), {
          ...params,
          ...query
        })
        .then((resp) => {
          dispatch(setBoxReport(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAllTeamReport = (payload, callback) => {
  return async (dispatch) => {
    try {
      const { startDate, endDate, date_format, subOrgId, query = {} } = payload;
      const params = {
        start_date: startDate,
        end_date: endDate,
        date_format: date_format
      };
      new APIRequest()
        .get(replaceUrl(GET_ALL_TEAM_REPORT, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((resp) => {
          dispatch(setTeamReport(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import QuillEditor from 'components/quill';
import TextInputField from 'components/inputs/textField';
import Select from 'components/inputs/select';
import CHOICES from 'choices';
import {
  createReplyTemplate,
  setEmailData,
  setTemplateName,
  setSubject,
  setReplyType,
  updateReplyTemplate,
  setAllReplyTemplates,
  resetData
} from 'slices/replyTemplate/slice';
import { useDispatch, useSelector } from 'react-redux';
import iconSms from 'assets/icons/icon-template-sms-new.svg';
import iconFb from 'assets/icons/icon-template-fb.svg';
import iconEmail from 'assets/icons/icon-template-email.svg';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty, sanatizeMessageBody } from 'utils/utils';
import { setAttachments } from 'slices/editor/slice';
import ErrorMessage from 'components/errorMessage';

const { SMS, EMAIL, FACEBOOK_AND_INSTAGRAM } = CHOICES.ReplyTemplateChoice;
const SMS_MAX_LIMIT = 160;
const FB_MAX_LIMIT = 1000;
const TemplateEditor = (props) => {
  const { onCancel, replyTemplateData, setReplyTemplateData, openEdit } = props;
  const dispatch = useDispatch();
  const { emailData, templateName, subject, replyType } = useSelector(
    (state) => state.replyTemplate
  );
  const { attachments } = useSelector((state) => state.editor);
  const [renderAttachments, setRenderAttachments] = useState(attachments);
  const [length, setLength] = useState(0);
  const [channelOptions, setChannelOptions] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    const channels = [];
    CHOICES.ReplyTemplateChoice.CHOICE_LIST.forEach((item) => {
      // if (item.value !== FACEBOOK_AND_INSTAGRAM) {
      channels.push({
        id: item.value,
        label: item.label,
        value: item.label,
        icon:
          item.value === SMS
            ? iconSms
            : item.value === EMAIL
            ? iconEmail
            : iconFb
      });
      // }
    });
    setChannelOptions(channels);
  }, [CHOICES.ReplyTemplateChoice.CHOICE_LIST]);

  useEffect(() => {
    setRenderAttachments(attachments);
  }, [attachments]);

  const onOptionChange = (data) => {
    dispatch(setReplyType(data?.id));
  };

  const checkValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(templateName)) {
      err = {
        ...err,
        templateName: 'template name cannot be empty'
      };
      isValid = false;
    }
    if (templateName.length > 30) {
      err = {
        ...err,
        templateName: 'template name should be less than 30 characters'
      };
    }
    if (isEmpty(subject) && replyType === EMAIL) {
      err = {
        ...err,
        subject: 'subject cannot be empty'
      };
      isValid = false;
    }

    if (isEmpty(emailData)) {
      err = {
        ...err,
        emailBody: 'body cannot be empty'
      };
      isValid = false;
    }
    // if (length > SMS_MAX_LIMIT && replyType === SMS) {
    //   err = {
    //     ...err,
    //     emailBody: 'maximum character limit exceeded'
    //   };
    //   isValid = false;
    // }
    if (length > FB_MAX_LIMIT && replyType === FACEBOOK_AND_INSTAGRAM) {
      err = {
        ...err,
        emailBody: 'maximum character limit exceeded'
      };
      isValid = false;
    }
    setError(err);
    return isValid;
  };

  const onClose = () => {
    dispatch(resetData());
    onCancel();
  };

  const callback = (data) => {
    dispatch(resetData());
    if (!openEdit) {
      dispatch(setAllReplyTemplates([data, ...replyTemplateData]));
      setReplyTemplateData([data, ...replyTemplateData]);
      onCancel();
    } else {
      const index = replyTemplateData.findIndex((item) => item.id === openEdit);
      const updatedData = [
        ...replyTemplateData.slice(0, index),
        data,
        ...replyTemplateData.slice(index + 1)
      ];
      dispatch(setAllReplyTemplates(updatedData));
      setReplyTemplateData(updatedData);
      onCancel();
    }
  };

  const onSave = () => {
    const tempAttachments = attachments.map((item) => item.id);
    const isValid = checkValid();
    if (isValid) {
      const formData = {
        name: templateName,
        reply_type: replyType,
        subject: replyType === EMAIL ? subject : '',
        body: replyType === EMAIL ? emailData : sanatizeMessageBody(emailData),
        attachments: replyType === EMAIL ? tempAttachments : [],
        html_body: emailData
      };
      if (!openEdit) {
        dispatch(createReplyTemplate({ body: formData, callback }));
      } else {
        dispatch(
          updateReplyTemplate({
            body: formData,
            templateId: openEdit,
            callback
          })
        );
      }
    }
  };

  const onAttachmentChanges = (attachments) => {
    dispatch(setAttachments(attachments));
  };

  return (
    <div
      className="border border-primary border-radius-10 mt-8"
      style={{ boxShadow: '0px 6px 14px -2px rgba(85, 83, 80, 0.15)' }}
    >
      <div className="pt-4 px-5">
        {!isEmpty(error) && (
          <div className="mb-3">
            <ErrorMessage message="Please complete the fields in red before saving" />
          </div>
        )}
        <TextInputField
          name="templateName"
          className="input-field--md"
          placeholder="Name your template"
          value={templateName}
          onChange={(e) => {
            delete error.templateName;
            dispatch(setTemplateName(e.target.value));
          }}
          error={!isEmpty(error.templateName)}
        />
        {channelOptions && !isEmpty(channelOptions) && (
          <Select
            options={channelOptions}
            name="channel"
            isHaveIcon={true}
            dropDownClassName="p-2"
            iconStyle={{ marginRight: 12 }}
            inputClassName="input-field--md"
            selectedIconStyle={{
              position: 'absolute',
              zIndex: 9,
              top: 14,
              left: 14
            }}
            onChange={onOptionChange}
            value={
              replyType === EMAIL
                ? 'Email'
                : replyType === SMS
                ? 'SMS'
                : 'Facebook and Instagram'
            }
            placeholder=""
            selectedIcon={
              replyType === EMAIL
                ? iconEmail
                : replyType === SMS
                ? iconSms
                : iconFb
            }
            style={{ paddingLeft: 44 }}
            containerStyle={{ marginTop: '12px' }}
            readOnly
          />
        )}
        {replyType === EMAIL && (
          <TextInputField
            name="subject"
            className="input-field--md mt-3"
            placeholder="Email subject"
            value={subject}
            onChange={(e) => {
              delete error.subject;
              dispatch(setSubject(e.target.value));
            }}
            error={!isEmpty(error.subject)}
          />
        )}
      </div>
      <div
        className="bg-grey-300 pt-5 px-5 pb-7 mt-4"
        style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}
      >
        <div
          className={`bg-white border rounded-lg chat-reply ${
            !isEmpty(error.emailBody) ? 'border-red-300' : 'border-grey-500'
          }`}
        >
          <QuillEditor
            placeholder=""
            isAutomation
            showSendBtn={false}
            showBottomBorder={true}
            onChange={(data) => {
              delete error.emailBody;
              dispatch(setEmailData(data));
            }}
            value={emailData}
            hideImage={true}
            hideVarible={false}
            hideHyperlink={replyType !== EMAIL ? true : false}
            hideReplyTemplate={true}
            showFontStyle={replyType === EMAIL ? true : false}
            hideAttachment={replyType !== EMAIL ? true : false}
            attachments={renderAttachments}
            setAttachments={(data) => dispatch(setAttachments(data))}
            onAttachmentChanges={onAttachmentChanges}
            showCharLimitTop={
              replyType === EMAIL || replyType === FACEBOOK_AND_INSTAGRAM
                ? false
                : true
            }
            enableCharacterLimit={replyType !== EMAIL ? true : false}
            maxCharacters={replyType === SMS ? SMS_MAX_LIMIT : FB_MAX_LIMIT}
            setLength={setLength}
            length={length}
          />
        </div>
        <div className="flex justify-end mt-5">
          <CustomColorButton
            label="Cancel"
            onClick={onClose}
            className="btn--grey btn--sm min-w-120 mr-3"
          />
          <PrimaryButton
            className="btn--sm min-w-120"
            label="Save"
            onClick={onSave}
          />
        </div>
      </div>
    </div>
  );
};

export default TemplateEditor;

import React from 'react';
import PropTypes from 'prop-types';

const HeaderItem = (props) => {
  const { title, description } = props;

  return (
    <div>
      <h2 className="font-semibold text-lg">{title}</h2>
      <p className="text-grey-800 text-sm mt-2">{description}</p>
    </div>
  );
};
HeaderItem.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string
};
export default HeaderItem;

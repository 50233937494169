/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useEffect } from 'react';
import Stage from '../stages/stage';
import { handleMoveLead, setActiveBoxNewLead } from 'slices/boxes/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Routes, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Conversation from './conversation';
import EmptyState from './conversation/emptyState';
import { isEmpty } from 'utils/utils';

const LeadDetail = (props) => {
  const { currentBox } = props;
  const navigate = useNavigate();
  const { stageId, box_type, box_id } = useParams();
  const dispatch = useDispatch();
  const { stageLeads } = useSelector((state) => state.boxes);

  const currentStage = useMemo(() => {
    const x = currentBox.boxstage_set?.find((stage) => stage.id === stageId);
    return x;
  }, [stageId, currentBox]);

  const onBeforeDragStart = (data) => {
    // setDragStart(data);
  };

  const onDragStart = (data) => {
    // console.log('onDragStart', data);
  };

  const onDragEnd = async (data) => {
    const { source, destination, draggableId } = data;
    const sourceStageId = source.droppableId;
    const destinationStageId = destination.droppableId;
    const isReplyLater = draggableId.includes('REPLY') ? true : false;
    if (isReplyLater && sourceStageId != destinationStageId) {
      setShowWarning(true);
      return;
    }
    dispatch(handleMoveLead(data));
    navigate(`/dashboard/boxes/${box_type}/${box_id}/${stageId}`);
  };

  useEffect(() => {
    return () => {
      dispatch(setActiveBoxNewLead(null));
    };
  }, []);

  return (
    <div className="dashboard__main__body">
      <div
        className="dashboard__main__body__section-left box"
        style={{
          // maxWidth: '464px',
          paddingBottom: '7px'
        }}
      >
        <div
          className="box-card-wrapper box-detail"
          style={{ overflowX: 'hidden !important' }}
        >
          <DragDropContext
            onDragStart={onDragStart}
            onDragEnd={onDragEnd}
            onBeforeCapture={onBeforeDragStart}
          >
            <Stage
              key={currentStage.id}
              stage={currentStage}
              fetchLeads={false}
              box={currentBox}
            />
          </DragDropContext>
        </div>
        {/* <div className="box-card-container mr-5 clip-path mt-15" style={{ minWidth: '100%' }}>
          <div className="text-sm font-semibold flex justify-center text-secondary ml-3 bg-white" style={{
            width: 61,
            height: 24,
            border: "1px solid rgba(4, 145, 171, 0.3)",
            borderRadius: "20px",
          }}>$153
          </div>
          <div className="flex justify-between ml-3 mt-2">
            <div className='font-semibold text-lg'>Won</div>
            <div className='flex items-center'>
              <div className='cursor-pointer relative' onClick={() => setShowDelete(1)}>
                <img src={iconMoreRound} alt='' style={{ width: 16, height: 4 }} />
                <div className={`dropdown-menu dropdown-menu--more ${showDelete === 1 ? 'open' : ''}`} style={{ top: 10 }}>
                  <div className="dropdown-menu__list red-text">
                    <img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />Delete
                  </div>
                </div>
              </div>
              <div>
                <div className="text-sm flex justify-center text-white ml-3 font-semibold bg-secondary" style={{
                  width: 32,
                  height: 22,
                  borderRadius: "20px",
                }}>3</div>
              </div>
            </div>
          </div>
          <div className='mt-5'>
            <div className='box-card' style={{ width: "100%" }}>
              <div className='flex mt-1'>
                <figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
                  <img src={user2Icon} alt='' />
                  <figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
                    <img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
                  </figure>
                </figure>
                <div>
                  <div className='font-semibold'>Alex Foster</div>
                  <div className='text-15 truncate'>How are you !</div>
                </div>
              </div>
              <div>
                <div className='flex justify-end text-sm' style={{ marginTop: 30 }}>May 29</div>
              </div>
            </div>
            <div className='box-card mt-3' style={{ width: "100%" }}>
              <div className='flex mt-1'>
                <figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
                  <img src={user1Icon} alt='' />
                  <figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
                    <img src={iconInsta} alt='' className="" style={{ width: 12, height: 12 }} />
                  </figure>
                </figure>
                <div>
                  <div className='font-semibold'>Lisa Fotios</div>
                  <div className='text-15 truncate'>Hey</div>
                </div>
              </div>
              <div>
                <div className='flex justify-end text-sm' style={{ marginTop: 30 }}>30m</div>
              </div>
            </div>
            <div className='box-card mt-3' style={{ width: "100%" }}>
              <div className='flex mt-1'>
                <figure className="avatar-container avatar-container--contain mr-4" style={{ width: 48, height: 48 }}>
                  <img src={user3Icon} alt='' />
                  <figure className='avatar-container bg-white' style={{ width: 20, height: 20, position: "absolute", bottom: 0, right: 0 }}>
                    <img src={iconFb} alt='' className="" style={{ width: 12, height: 12 }} />
                  </figure>
                </figure>
                <div>
                  <div className='font-semibold'>Merva Sahin</div>
                  <div className='text-15 truncate'>Good night</div>
                </div>
              </div>
              <div>
                <div className="text-white text-10 font-semibold flex justify-center bg-green-700" style={{
                  width: 36,
                  height: 16,
                  borderRadius: "20px",
                  marginLeft: "calc(100% - 35px)"
                }}>
                  New
                </div>
                <div className='flex justify-end text-sm' style={{ marginTop: 14 }}>May 31</div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <div className="dashboard__main__body__section-right chat-message-container relative">
        <Routes>
          <Route
            path="/"
            element={<EmptyState currentStage={currentStage} />}
          />
          <Route
            path="/*"
            element={
              <>{!isEmpty(stageLeads[stageId]) ? <Conversation /> : <></>}</>
            }
          />
        </Routes>
      </div>
      {/* <div className="dashboard__main__body__section-right chat-message-container relative"> */}
      {/* <ChatMessageHeader setOpenTabs={setOpenTabs} openTabs={openTabs} /> */}
      {/* <div className="chat-message-body">
          <div className="chat-message-list flex">
            <figure
              className="avatar-container avatar-container--full"
              style={{ width: 32, height: 32 }}
            >
              <img src={avatarPic} alt="avatar-pic" />
            </figure>
            <div className="" style={{ marginLeft: 6 }}>
              <div>
                <div
                  className="inline-block border border-grey-400 text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  Hi dude...
                </div>
              </div>
              <div>
                <div
                  className="inline-block border border-grey-400 text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 6,
                  }}
                >
                  How is your job?
                </div>
              </div>
              <div>
                <div
                  className="inline-block border border-grey-400 text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 6,
                  }}
                >
                  Are you doing anything fun this weekend at home?
                </div>
              </div>
              <div>
                <div
                  className="text-xs text-grey-700"
                  style={{ marginTop: 6 }}
                >
                  2d
                </div>
              </div>
            </div>
          </div>
          <div className="chat-message-list chat-message-list--sender flex">
            <figure
              className="avatar-container avatar-container--full"
              style={{ width: 32, height: 32 }}
            >
              <img src={avatarPic} alt="avatar-pic" />
            </figure>
            <div className="" style={{ marginRight: 6 }}>
              <div>
                <div
                  className="inline-block  text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    backgroundColor: "#F4F9F9",
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  Hi dude...
                </div>
              </div>
              <div>
                <div
                  className="inline-block  text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 6,
                    backgroundColor: "#F4F9F9",
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  How is your job?
                </div>
              </div>
              <div>
                <div
                  className="inline-block text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                    marginTop: 6,
                    backgroundColor: "#F4F9F9",
                    border: "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                >
                  Are you doing anything fun this weekend at home?
                </div>
              </div>
              <div>
                <div
                  className="text-xs text-grey-700"
                  style={{ marginTop: 6 }}
                >
                  2d
                </div>
              </div>
            </div>
          </div>
          <div className="chat-message-list flex">
            <figure
              className="avatar-container avatar-container--full"
              style={{ width: 32, height: 32 }}
            >
              <img src={avatarPic} alt="avatar-pic" />
            </figure>
            <div className="" style={{ marginLeft: 6 }}>
              <div>
                <div
                  className="inline-block border border-grey-400 text-base-1 text-grey-900"
                  style={{
                    borderRadius: 10,
                    paddingTop: 6,
                    paddingBottom: 6,
                    paddingLeft: 15,
                    paddingRight: 15,
                  }}
                >
                  Tristique bibendum finibus nec, consectetu.
                </div>
              </div>
              <div>
                <div
                  className="text-xs text-grey-700"
                  style={{ marginTop: 6 }}
                >
                  Now
                </div>
              </div>
            </div>
          </div>
        </div> */}
      {/* <ChatFooterInputBox showMoveHelper={showMoveHelper} setShowMoveHelper={setShowMoveHelper} /> */}
      {/* <section className={`modal modal--contact ${openTabs ? 'show' : ''}`}>
          <div className="modal__container">
            <Details setShowImageModal={setShowImageModal} setOpenUpload={setOpenUpload} />
          </div>
        </section> */}
      {/* </div> */}
    </div>
  );
};

export default LeadDetail;

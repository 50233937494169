/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import Filter from './filter';
import Note from './note';
import NewEnquiry from './newEnquiry';
// import ReplySent from './replySent';
import Assigned from './assigned';
import MoveTo from './moveTo';
import { useDispatch, useSelector } from 'react-redux';
import { getFilteredHistory, getLeadsHistory } from 'slices/historyLeadSlice';
import { isEmpty } from 'utils/utils';
import { useParams } from 'react-router-dom';
import CHOICES from 'choices';
import { Loader } from 'components/loader';
import ReplyReceived from './replyRecived';
import AutomatedReplySent from './automatedReplySent';
import DeletedFromBin from './deletedFromBin';
import MovedToBin from './movedToBin';
import Reminder from './reminder';
import TaskCompleted from './taskCompleted';
import HistoryLabel from './historyLabel';
import Task from './task';
import Restore from './restore';

const History = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [filteredTitle, setFilteredTitle] = useState('Filter');
  const { leadHistory } = useSelector((state) => state.historyLead);
  const { cardId } = useParams();

  const loadHistoryLeads = () => {
    setLoading(true);
    dispatch(getLeadsHistory({ leadId: cardId }, onSuccess()));
  };

  const onSuccess = () => {
    setLoading(false);
    setShowFilter(false);
  };

  useEffect(() => {
    loadHistoryLeads();
  }, []);

  const renderData = (item) => {
    switch (item.title) {
      case CHOICES.TitleChoices.NEW_ENQUIRY:
        return <NewEnquiry data={item} key={item.id} />;
      // case CHOICES.TitleChoices.REPLY_SENT:
      //   return <ReplySent data={item} key={item.id} />;
      case CHOICES.TitleChoices.AUTOMATED_REPLY_SENT:
        return <AutomatedReplySent data={item} key={item.id} />;
      case CHOICES.TitleChoices.TASK_ADDED:
        return <Task data={item} key={item.id} />;
      case CHOICES.TitleChoices.ASSIGNED:
        return <Assigned data={item} key={item.id} />;
      case CHOICES.TitleChoices.NOTE_ADDED:
        return <Note data={item} key={item.id} />;
      case CHOICES.TitleChoices.MOVED_TO_STAGE:
        return <MoveTo data={item} key={item.id} />;
      case CHOICES.TitleChoices.REPLY_RECEIVED:
        return <ReplyReceived data={item} key={item.id} />;
      case CHOICES.TitleChoices.DELETED_FROM_BIN:
        return <DeletedFromBin data={item} key={item.id} />;
      case CHOICES.TitleChoices.MOVED_TO_BIN:
        return <MovedToBin data={item} key={item.id} />;
      case CHOICES.TitleChoices.REMINDER:
        return <Reminder data={item} key={item.id} />;
      case CHOICES.TitleChoices.TASK_COMPLETED:
        return <TaskCompleted data={item} key={item.id} />;
      case CHOICES.TitleChoices.LABEL_ADDED:
        return <HistoryLabel data={item} key={item.id} />;
      case CHOICES.TitleChoices.LABEL_REMOVED:
        return <HistoryLabel data={item} key={item.id} />;
      case CHOICES.TitleChoices.RESTORED:
        return <Restore data={item} key={item.id} />;
      default:
        <></>;
    }
  };

  const onFilter = (item) => {
    if (item.label === 'All history') {
      if (filteredTitle === 'Filter') {
        setFilteredTitle('Filter');
        setShowFilter(false);
        return;
      } else {
        setFilteredTitle(item.label);
        loadHistoryLeads();
      }
    } else {
      setFilteredTitle(item.label);
      setLoading(true);
      dispatch(
        getFilteredHistory({
          leadId: cardId,
          title: item.value,
          callback: onSuccess
        })
      );
    }
  };

  const onClearFilter = () => {
    setFilteredTitle('Filter');
    if (filteredTitle === 'All history') {
      return;
    } else {
      loadHistoryLeads();
    }
  };

  if (loading) return <Loader />;

  if (!isEmpty(leadHistory)) {
    return (
      <div className="h-full">
        <div className="history flex justify-between items-center">
          <div className="text-15 font-semibold">All History</div>
          <Filter
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            onClick={onFilter}
            title={filteredTitle}
            onClearFilter={onClearFilter}
          />
        </div>
        <div
          className="history__body overflow-y-auto"
          style={{ height: 'calc(100% - 60px)' }}
        >
          {leadHistory?.results.map((item) => {
            return renderData(item);
          })}
        </div>
      </div>
    );
  }
  return <></>;
};

export default React.memo(History);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import Modal from 'components/modal';
import CustomColorButton from 'components/buttons/customColorButton';
import ImageCrop from '.';
import { isEmpty } from 'utils/utils';

const ReactImageCropModel = (props) => {
  const {
    showConfirmation,
    src,
    handleSaveCroppedImage,
    setCanvas,
    setShowConfirmation
  } = props;

  const handleOnCancle = () => {
    setShowConfirmation(false);
    // setImageCrop(null)
  };
  useEffect(() => {
    if (isEmpty(src)) {
      setShowConfirmation(false);
    }
  }, [src]);

  return (
    <Modal.Main open={showConfirmation && !isEmpty(src)}>
      {/* <Modal.Header title="" onClose={onCancel} /> */}
      <Modal.Body className="pb-10 py-8 overflow-y-auto">
        <React.Fragment>
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">
              Update profile image
            </h2>
          </div>
          {/* <div className="text-13 text-center mt-3 px-16 ">xyz</div> */}
          <div className="flex items-center justify-center">
            <ImageCrop
              src={src}
              cropPositionX={0}
              cropPositionY={0}
              cropWidthInPercent={20}
              onComplete={setCanvas}
            />
          </div>

          <div className="flex items-center gap-2 justify-center px-8 mt-6">
            <CustomColorButton
              label="Cancel"
              className="btn--wide-md-40 btn--cancel text-base-1 mr-3 cursor-pointer"
              onClick={handleOnCancle}
            />
            <CustomColorButton
              label={'Confirm'}
              className="btn--wide-md-40 text-base-1 bg-red-900 text-white mr-3 cursor-pointer"
              onClick={handleSaveCroppedImage}
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default ReactImageCropModel;

import Option from './inputField/index';
import React, { useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  deleteOpeningOption,
  setOpeningStarterOptions,
  setdragDropOptionLoading,
  updateOrReorderOpeningOption
} from 'slices/ai-assistant/welcome/slice';
import { useDispatch, useSelector } from 'react-redux';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { isEmpty } from 'utils/utils';
import InfoIconComponent from '../components/InfoIcon';

const OpeningOption = () => {
  const dispatch = useDispatch();
  const { opening_options } = useSelector((state) => state.welcomePage);
  const [dragStart, setDragStart] = useState(null);
  const [dragDisabled, setDragDisabled] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [currItemIndex, setCurritemIndex] = useState();
  const [currOptionItem, setCurrOptionItem] = useState({});
  const [loading, setLoading] = useState(false);
  const welcomeErrors = useSelector((state) => state.assistant.welcomeErrors);

  const handleLoader = (loading) => {
    setLoading(loading);
  };
  const onDragEnd = (result) => {
    const {
      destination,
      source
      // , draggableId
    } = result;
    dispatch(setdragDropOptionLoading(true));
    if (!destination || destination.index === source.index) {
      setDragStart(false);
      dispatch(setdragDropOptionLoading(false));
      return;
    }

    if (source.droppableId !== destination.droppableId) {
      setDragStart(false);
      dispatch(setdragDropOptionLoading(false));
      return;
    }

    let payload = {
      body: {
        option_id: opening_options[source.index].id,
        position: destination.index
      },
      source,
      destination
    };
    dispatch(
      updateOrReorderOpeningOption(
        payload,
        'reorder',
        reorderSuccessResponse,
        reorderFailedResponse
      )
    );
  };

  const reorderSuccessResponse = () => {
    dispatch(setdragDropOptionLoading(false));
  };

  const reorderFailedResponse = () => {
    dispatch(setdragDropOptionLoading(false));
  };

  const successResponse = () => {
    setLoading(false);
    setShowDelete(false);
  };
  const failedResponse = () => {
    setLoading(false);
  };
  const OnAddOption = (formData) => {
    dispatch(setOpeningStarterOptions([...opening_options, formData]));
  };
  const onRemoveOption = (currItem, itemIndex) => {
    if (currItem.status === 'New') {
      let currOptions = opening_options.filter((item, index) => {
        return index !== itemIndex;
      });
      dispatch(setOpeningStarterOptions(currOptions));
      setShowDelete(false);
      setCurritemIndex();
      setCurrOptionItem({});
    } else {
      setLoading(true);
      dispatch(deleteOpeningOption(currItem, successResponse, failedResponse));
      setCurritemIndex();
      setCurrOptionItem({});
    }
  };

  const handleDragDisabled = (data) => {
    setDragDisabled(data);
  };

  return (
    <div className="" style={{ padding: '20px 38px 26px' }}>
      <label className="input-field-label">
        Opening starter options{' '}
        <InfoIconComponent tooltipText="Opening starter options" />
      </label>
      {isEmpty(opening_options) &&
        !isEmpty(welcomeErrors?.option) &&
        !welcomeErrors?.option && (
          <p
            className="text-base mt-7"
            style={{
              color: '#F55E5E',
              fontFamily: 'SF Pro Text'
            }}
          >
            Please provide the starter option
          </p>
        )}
      <div className="bot-chat-message-wrapper">
        <DragDropContext
          onBeforeDragStart={(e) => {
            setDragStart(e.draggableId);
          }}
          onDragEnd={onDragEnd}
        >
          <Droppable droppableId="opening_options">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                style={{ display: 'inline-block' }}
              >
                {opening_options?.map((option, index) => (
                  <Draggable
                    key={index}
                    draggableId={index.toString()}
                    index={index}
                    isDragDisabled={dragDisabled}
                  >
                    {(provided, snapshotDrag) => {
                      // Restrict dragging to vertical axis
                      let transform = provided.draggableProps.style.transform;

                      if (snapshotDrag.isDragging && transform) {
                        transform = transform.replace(/\(.+,/, '(0,');
                      }

                      const style = {
                        ...provided.draggableProps.style,
                        transform
                      };
                      return (
                        <div
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                          style={style}
                        >
                          <Option
                            index={index}
                            id={index.toString()}
                            value={option.content}
                            dragStart={dragStart}
                            task={option}
                            onRemove={onRemoveOption}
                            setDragDisabled={handleDragDisabled}
                            placeholder={'option'}
                            isLoading={loading}
                            type={'option'}
                            showDelete={showDelete}
                            setShowDelete={setShowDelete}
                            setIndex={setCurritemIndex}
                            setItem={setCurrOptionItem}
                            snapshot={snapshotDrag}
                            handleLoader={handleLoader}
                            tooltip={'starter option'}
                          />
                        </div>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div
        className={`text-primary text-lg font-medium mt-4 cursor-pointer ${
          isEmpty(opening_options) ? 'inline-block' : ''
        }`}
        onClick={() => {
          OnAddOption({
            id: opening_options.length + 1,
            content: '',
            status: 'New'
          });
        }}
      >
        + Add an option
      </div>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => onRemoveOption(currOptionItem, currItemIndex)}
        title="Are you sure you want to delete?"
        description=""
        isLoading={loading}
      />
    </div>
  );
};

export default OpeningOption;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from 'react';
import { Loader } from 'components/loader';
import RenderDueFutureTask from 'modules/teamChat/component/dueFutureTask';
import RenderDueTomorrowTask from 'modules/teamChat/component/dueTomorrowTask';
import TaskEmptyState from 'modules/teamChat/component/emptyStateTask';
import RenderOverDueTask from 'modules/teamChat/component/overdueTask';
import RenderOverDueTodayTask from 'modules/teamChat/component/overdueTodayTask';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getDueFutureTask,
  getDueTomorrowTask,
  getOverdueTask,
  getOverdueTodayTask
} from 'slices/feedTask/slice';
import { isEmpty } from 'utils/utils';

const UserTasks = () => {
  const dispatch = useDispatch();
  const { userId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const {
    overdueTask,
    overdueTodayTask,
    dueTomorrowTask,
    dueFuture,
    isEmptyOverdueTask,
    isEmptyOverdueTodayTask,
    isEmptyDueTomorrowTask,
    isEmptyDueFuture
  } = useSelector((state) => state.feedTask);

  const loadOverdueTask = async (userId) => {
    return await dispatch(getOverdueTask({ userId }));
  };

  const loadOverdueTodayTask = async (userId) => {
    return await dispatch(getOverdueTodayTask({ userId }));
  };

  const loadDueTomorrowTask = async (userId) => {
    return await dispatch(getDueTomorrowTask({ userId }));
  };

  const loadDueFutureTask = async (userId) => {
    return await dispatch(getDueFutureTask({ userId }));
  };

  const getAllTask = () => {
    setIsLoading(true);
    Promise.all([
      loadOverdueTask(userId),
      loadOverdueTodayTask(userId),
      loadDueTomorrowTask(userId),
      loadDueFutureTask(userId)
    ]).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getAllTask();
  }, []);

  if (isLoading) return <Loader />;

  return (
    <div
      className="dashboard__main__body pt-6 px-11 relative overflow-y-auto"
      style={{ height: 'calc(100% - 145px)' }}
    >
      <div className="w-full">
        {isEmptyOverdueTask &&
        isEmptyOverdueTodayTask &&
        isEmptyDueTomorrowTask &&
        isEmptyDueFuture ? (
          <TaskEmptyState />
        ) : (
          <>
            {!isEmpty(overdueTask) && <RenderOverDueTask data={overdueTask} />}
            {!isEmpty(overdueTodayTask) && (
              <RenderOverDueTodayTask data={overdueTodayTask} />
            )}
            {!isEmpty(dueTomorrowTask) && (
              <RenderDueTomorrowTask data={dueTomorrowTask} />
            )}
            {!isEmpty(dueFuture) && <RenderDueFutureTask data={dueFuture} />}
          </>
        )}
      </div>
    </div>
  );
};

export default UserTasks;

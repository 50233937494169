import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';

const ReportLink = (props) => {
  const { title, description, to, renderSvg } = props;

  return (
    <NavLink
      to={to}
      className={`general-settings__card-list bg-color-on-hover bg-color-on-hover--sm block`}
    >
      <div className="flex items-center gap-4 flex-1 truncate">
        <figure className="general-settings__card-list__avatar-container">
          {renderSvg && typeof renderSvg === 'function' && renderSvg()}
        </figure>
        <div className="truncate pr-4">
          <h2 className="general-settings__card-list__title">{title}</h2>
          <p className="general-settings__card-list__desc">{description}</p>
        </div>
      </div>
    </NavLink>
  );
};

ReportLink.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  renderSvg: PropTypes.func,
  to: PropTypes.string
};

export default ReportLink;

import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from '.';

const OverLayLoader = (props) => {
  const { className, style } = props;
  return (
    <div
      className={`justify-center item-center absolute flex ${className}`}
      style={{
        width: '100%',
        height: '100%',
        backdropFilter: 'blur(0px)',
        zIndex: 1,
        ...style
      }}
    >
      <Loader />
    </div>
  );
};

OverLayLoader.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object
};

export default OverLayLoader;

import React from 'react';
import { useSelector } from 'react-redux';
import { SucessSnackBar, ErrorSnackBar } from './Snackbar';
import { ERROR, SUCCESS } from './constant';

const SnackBarContainer = () => {
  const { isActive, message, type, size } = useSelector(
    (state) => state.snackbar
  );

  if (type == SUCCESS) {
    return <SucessSnackBar isActive={isActive} message={message} size={size} />;
  }

  if (type == ERROR) {
    return <ErrorSnackBar isActive={isActive} message={message} size={size} />;
  }
  return <></>;
};

export default React.memo(SnackBarContainer);

import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

const Preview = () => {
  const { masterBotId } = useParams();

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = process.env.REACT_APP_CHATBOT_AI_SCRIPT;
    script.setAttribute('data-bot-id', masterBotId);
    script.defer = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, [masterBotId]);

  return <></>;
};

export default Preview;

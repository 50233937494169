/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import FileUploading from './fileUploading';
import { isEmpty } from 'utils/utils';
import { useSelector } from 'react-redux';

const Uploading = ({
  uploadedFiles,
  setUploadedFiles,
  setShowdropDown,
  showDropDown,
  isContactUploadModal,
  setIsContactModalUploading,
  isContactModalUploaded,
  uploadedFileTotalRows,
  setAlreadyUploadedFiles
}) => {
  const files = useRef([]);
  files.current = uploadedFiles;
  const [isUploading, setIsUploading] = useState(false);
  const getUploadingState = () => {
    const isStillUploading = (files.current || [])?.some((file) =>
      isEmpty(file.file_url)
    );
    setIsUploading(isStillUploading);
  };

  const { uploadFileErrorMessage } = useSelector(
    (state) => state.uploadContactsSettings
  );

  useEffect(() => {
    getUploadingState();
  }, [uploadedFiles]);

  const onFileUploaded = (payload) => {
    const { index, data } = payload;
    const uploadedData = files.current.map((file, idx) => {
      if (idx === index) {
        return { ...file, ...data };
      }
      return file;
    });
    // const uploadedData = [...uploadedFiles, data];
    // console.log('s', payload)
    // uploadedData.splice(index, 1, data);
    // console.log({ uploadedData })
    setUploadedFiles(uploadedData);
  };

  const onFileDelete = (payload) => {
    const { index } = payload;
    const uploadedData = [...files.current];
    uploadedData.splice(index, 1);
    setUploadedFiles([...uploadedData]);
    if (setAlreadyUploadedFiles) {
      setAlreadyUploadedFiles([...uploadedData]);
    }
  };

  if (isEmpty(files.current)) return <></>;
  const uploadingStatus = () => {
    if (!isContactModalUploaded && isUploading) {
      if (isEmpty(uploadFileErrorMessage)) {
        return 'Uploading...';
      } else {
        return uploadFileErrorMessage;
      }
    } else {
      if (isContactUploadModal) {
        return `${uploadedFileTotalRows - 1} Contacts Uploaded`;
      } else {
        return 'Uploaded files';
      }
    }
  };
  if (isEmpty(uploadedFiles)) return <></>;

  return (
    <div className="mt-25">
      <div className="font-semibold mr-2">{uploadingStatus()}</div>
      {files.current?.map((item, idx) => {
        if (item.isFileLibrary) return <div key={`file-${idx}`} />;
        return (
          <FileUploading
            key={`file-${idx}`}
            data={item}
            index={idx}
            onFileUploaded={onFileUploaded}
            onFileDelete={onFileDelete}
            showDropDown={showDropDown}
            setShowdropDown={setShowdropDown}
            isContactUploadModal={isContactUploadModal}
            setIsContactModalUploading={setIsContactModalUploading}
          />
        );
      })}

      {/* <div className="font-semibold">{isUploaded ? 'Uploaded files' : 'Uploading'}</div> */}
      {/* <div className={`${isUploaded ? 'modal__uploaded-section' : 'modal__progress-section'}`}>
        <div className="flex justify-between">
          <div className={`${isUploaded ? 'flex items-center' : ''}`}>
            {isUploaded && !isEdit && <img src={docsIcon} alt='' className="mr-3" style={{ width: 25, height: 32 }} />}
            {!isEdit && <div className={`${isUploaded ? 'text-sm font-medium' : 'text-13 font-semibold'}`}>Fixed assets.docx</div>}
            {isEdit &&
              <div>
                <input
                  onFocus={() => setIsInputFocused(true)}
                  onBlur={() => setIsInputFocused(false)}
                  placeholder={inputVal}
                  value={inputVal}
                  onChange={(e) => setInputVal(e.target.value)}
                  className="input-field input-field--edit"
                />
                <div className="flex justify-end mt-3">
                  <button className="btn bg-grey-300 btn--edit-buttons " onClick={() => setIsEdit(false)}>
                    Cancel
                  </button>
                  <button className="btn btn--primary btn--edit-buttons ">Save</button>
                </div>
              </div>
            }
          </div>
          {isUploaded && !isEdit &&
            <>
              <div className='relative flex items-center files__card--right-side cursor-pointer' onClick={() => setShowdropDown(!showDropDown)}>
                <img src={moreIcon} alt='' />
                <div className={`dropdown-menu dropdown-menu--edit ${showDropDown ? 'open' : ''}`}>
                  <div className="dropdown-menu__list text-grey-800" onClick={() => {
                    setShowdropDown(!showDropDown);
                    setIsEdit(true)
                  }}>
                    <img src={renameIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
                    Rename</div>
                  <div className="dropdown-menu__list">
                    <img src={deleteIcon} alt='' className='mr-2' style={{ width: 16, height: 16 }} />
                    Delete</div>
                </div>
              </div>
            </>}
          {!isUploaded && !isEdit && <div className="flex">
            <div className="text-13 text-grey-800 mr-2">8.5 MB</div>
            <div className="txt-blue files__cancel" onClick={() => setIsUploaded(true)} >Cancel</div>
          </div>}
        </div>
        {!isUploaded && !isEdit && <div>
          <img src={progressIcon} alt='' className="mt-2" style={{ width: 430, height: 7 }} />
        </div>}
      </div> */}
    </div>
  );
};

export default Uploading;

import React from 'react';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import logo from 'assets/images/eb-logo.svg';
import vector from 'assets/images/Vector.png';

const HeaderLess = ({ children, hideLogo, hideBgIcon }) => {
  return (
    <main
      data-testid="testing-company-logo"
      className="authentication-module bg-grey-200 relative"
    >
      {!hideBgIcon && <img src={vector} alt="vector" className="img-vector" />}
      {!hideLogo && (
        <div className="logo flex items-center justify-center mb-2 z-50">
          <NavLink to="/">
            <img id="testing-company-logo" src={logo} alt="logo" />
          </NavLink>
        </div>
      )}
      <section className="flex items-center flex-col z-50">{children}</section>
    </main>
  );
};

HeaderLess.propTypes = {
  hideBgIcon: PropTypes.bool,
  hideLogo: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ])
};

HeaderLess.defaultProps = {
  hideBgIcon: false,
  hideLogo: false
};

export default HeaderLess;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changeLeadStatus, setShowContactModal } from 'slices/eboxLead';
import { changeBoxLeadStatus, setCurrentBoxCardList } from 'slices/boxes/slice';
import { getLeadData, setActiveTab, setHover } from 'slices/conversationSlice';
import styles from './index.module.scss';
import { ReactComponent as ReplyIcon } from '../../../assets/icons/icon-reply-filled.svg';
import { ReactComponent as Clock } from 'assets/icons/icon-clock-filled.svg';
import { ReactComponent as Note } from 'assets/icons/note-icon.svg';
import { ReactComponent as Reminder } from 'assets/icons/tasks-icon.svg';
import { ReactComponent as Trash } from 'assets/icons/icon-trash.svg';
import choices from 'choices';
import { isEmpty } from 'utils/utils';
import { convertAttachmentsStructure } from 'utils/file';
import { useSnackbar } from 'components/Snackbar';
import { setEditorActiveTab, setShowInboxActions } from 'slices/eboxLead';
import { REPLY, NOTE, TASK, SECOND, THIRD } from 'utils/constants';
import { setAllEditorState, setEditorChannel } from 'slices/editor/slice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import EmptyFieldAlert from 'components/alerts/emptyFieldAlert';
import Count from 'components/count';
const { REPLY_LATER } = choices.LeadStatusChoices;

const {
  FACEBOOK_LEADGEN,
  FACEBOOK,
  INSTAGRAM,
  SMS,
  WEB_FORMS,
  MANUALLY_ADDED,
  IMPORTED_FROM_FILE,
  EBOT,
  ZAPIER,
  AI_CHAT_BOT
} = choices.LeadChannelChoice;
const { EMAIL_REPLY, NORMAL_REPLY } = choices.LeadReplyChoice;

const Actions = (props) => {
  const { onOpenEditor, showMoreActions, onBinIt, onNavigate } = props;
  const { cardId, stageId, box_type, box_id } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { showContactModal } = useSelector((state) => state.leads);
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const [isActive, setIsActive] = useState('');
  // const showDraft = false;
  const [showDelete, setShowDelete] = useState(false);
  const { draftState } = useSelector((state) => state.editor);
  const { is_sms_connected } = useSelector(
    (state) => state.myInfo.myInformation
  );
  const isReplyActive =
    choices.LeadStatusChoices.REPLY_LATER == activeLead?.status;
  const [showEmailEmptyPopUp, setShowEmailEmptyPopup] = useState(false);
  // const isClockActive = false;
  // const isNoteActive = !hideNote;
  // const isReminderActive = !hideReminder;
  // const isTrashActive = false;

  // const convertAttachmentsStructure = (attachments = []) => {
  //   return attachments.map((item) => {
  //     return { ...item, isUploading: false };
  //   });
  // };

  const checkIfPhoneEmailAvailable = () => {
    if (activeLead?.channel === FACEBOOK || activeLead?.channel === INSTAGRAM)
      return true;
    if (
      activeLead?.channel === WEB_FORMS ||
      activeLead?.channel === FACEBOOK_LEADGEN ||
      activeLead?.channel === MANUALLY_ADDED ||
      activeLead?.channel === IMPORTED_FROM_FILE ||
      activeLead?.channel === SMS ||
      activeLead?.channel === EBOT ||
      activeLead?.channel === ZAPIER ||
      activeLead?.channel === AI_CHAT_BOT
    ) {
      if (isEmpty(activeLead?.email) && isEmpty(activeLead?.phone_number)) {
        return false;
      }
      if (
        isEmpty(activeLead?.email) &&
        !isEmpty(activeLead?.phone_number) &&
        is_sms_connected
      ) {
        return true;
      }
      if (!isEmpty(activeLead?.email)) {
        return true;
      }
      return false;
    }
  };

  const checkEmailAndPhoneAlongChannel = (lead) => {
    if (
      lead?.channel === WEB_FORMS ||
      lead?.channel === FACEBOOK_LEADGEN ||
      lead?.channel === MANUALLY_ADDED ||
      lead?.channel === IMPORTED_FROM_FILE ||
      lead?.channel === SMS ||
      lead?.channel === ZAPIER ||
      lead?.channel === AI_CHAT_BOT
    ) {
      if (isEmpty(lead?.email) && isEmpty(lead?.phone_number)) {
        return;
      }
      if (
        isEmpty(lead?.email) &&
        !isEmpty(lead?.phone_number) &&
        is_sms_connected
      ) {
        dispatch(setEditorChannel('Sms'));
        return;
      }
      if (!isEmpty(lead?.email)) {
        dispatch(setEditorChannel('Email'));
        return;
      }
    }
  };

  const getAlertBoxDescription = (lead) => {
    if (
      lead?.channel === WEB_FORMS ||
      lead?.channel === FACEBOOK_LEADGEN ||
      lead?.channel === MANUALLY_ADDED ||
      lead?.channel === IMPORTED_FROM_FILE ||
      lead?.channel === SMS ||
      lead?.channel === EBOT ||
      lead?.channel === ZAPIER ||
      lead?.channel === AI_CHAT_BOT
    ) {
      if (isEmpty(lead?.email) && isEmpty(lead?.phone_number)) {
        return 'Currently there is no Email and Phone number present for this lead';
      }
      if (
        isEmpty(lead?.email) &&
        !isEmpty(lead?.phone_number) &&
        !is_sms_connected
      ) {
        return 'Please connect SMS channel to reply';
      }
      if (!isEmpty(lead?.phone_number) && !is_sms_connected) {
        return 'Please connect SMS channel to reply';
      }
    }
  };

  useEffect(() => {
    if (
      !isEmpty(activeLead?.draft_reply) &&
      activeLead?.draft_reply?.reply_type
    ) {
      const { cc, bcc, attachments, subject, message_sent_by, reply_type } =
        activeLead.draft_reply;
      let { message } = activeLead.draft_reply;
      if (reply_type === EMAIL_REPLY) {
        dispatch(setEditorChannel('Email'));
      } else if (reply_type === NORMAL_REPLY) {
        let temp = '';
        message.split('\n').forEach((element) => {
          temp += `<p>${element}</p>`;
        });
        message = temp;
        if (activeLead?.channel === FACEBOOK) {
          dispatch(setEditorChannel('Facebook'));
        }
        if (activeLead?.channel === INSTAGRAM) {
          dispatch(setEditorChannel('Instagram'));
        }
        if (activeLead?.channel === SMS) {
          dispatch(setEditorChannel('Sms'));
        }
      } else {
        switch (activeLead?.channel) {
          case FACEBOOK:
            dispatch(setEditorChannel('Facebook'));
            break;
          case INSTAGRAM:
            dispatch(setEditorChannel('Instagram'));
            break;
          default:
            checkEmailAndPhoneAlongChannel(activeLead);
            // dispatch(setEditorChannel('Email'));
            break;
        }
      }
      setIsActive('reply');
      dispatch(
        setAllEditorState({
          bccEmail: bcc || [],
          ccEmail: cc || [],
          attachments: convertAttachmentsStructure(attachments),
          subject: subject || '',
          emailBody: message || '',
          selectedFromEmail: message_sent_by
        })
      );
    } else {
      // dispatch(setDraftState(false));
      switch (activeLead?.channel) {
        case FACEBOOK:
          dispatch(setEditorChannel('Facebook'));
          break;
        case INSTAGRAM:
          dispatch(setEditorChannel('Instagram'));
          break;
        case SMS:
          checkEmailAndPhoneAlongChannel(activeLead);
          // dispatch(setEditorChannel('Sms'));
          break;
        default:
          checkEmailAndPhoneAlongChannel(activeLead);
          // dispatch(setEditorChannel('Email'));
          break;
      }
      setIsActive('');
      dispatch(
        setAllEditorState({
          ccEmail: [],
          bccEmail: [],
          attachments: [],
          subject: 'Thanks for your enquiry',
          emailBody: '',
          selectedFromEmail: {}
        })
      );
    }
  }, [activeLead]);

  // useEffect(() => {
  //   if (draftState) {
  //     setIsActive('reply');
  //   }
  // }, [draftState]);

  const onReplyNowClick = () => {
    onOpenEditor();
    setIsActive('reply');
    dispatch(setEditorActiveTab(REPLY));
  };

  const onNoteClick = () => {
    // dispatch(setEditorActiveTab(NOTE));
    // onOpenEditor();
    setHover(true);
    dispatch(setShowContactModal(true));
    dispatch(setActiveTab(NOTE));
  };

  const onTaskClick = () => {
    // dispatch(setEditorActiveTab(TASK));
    // onOpenEditor();
    setHover(true);
    dispatch(setShowContactModal(true));
    dispatch(setActiveTab(TASK));
  };

  const onReplyLaterClick = () => {
    const data = {
      lead: cardId,
      is_current: true,
      status: choices.LeadStatusChoices.REPLY_LATER
    };
    if (isBox) {
      dispatch(
        changeBoxLeadStatus({ body: data, stageId }, () => {
          openSuccessSnackBar('Moved to reply later');
          dispatch(setCurrentBoxCardList(REPLY_LATER));
          dispatch(getLeadData({ leadId: cardId }));
        })
      );
      return;
    }
    dispatch(
      changeLeadStatus({ body: data }, () => {
        openSuccessSnackBar('Moved to reply later');
        navigate('/dashboard/enquirybox/eb-box');
      })
    );
  };

  const onBinItClick = () => {
    const data = {
      lead: cardId,
      is_current: true,
      status: choices.LeadStatusChoices.MOVED_TO_BIN
    };
    if (isBox) {
      dispatch(
        changeBoxLeadStatus({ body: data, stageId }, () => {
          openSuccessSnackBar('Moved to bin');
          navigate(`/dashboard/boxes/${box_type}/${box_id}/${stageId}`);
        })
      );
      return;
    }
    dispatch(
      changeLeadStatus({ body: data }, () => {
        openSuccessSnackBar('Moved to bin');
        navigate('/dashboard/enquirybox/eb-box');
      })
    );
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={onBinItClick}
        title="Are you sure you want to bin this enquiry?"
        description="You can see this enquiry in bin."
      />
      <EmptyFieldAlert
        showConfirmation={showEmailEmptyPopUp}
        onClose={() => setShowEmailEmptyPopup(false)}
        title={getAlertBoxDescription(activeLead)}
      />
      <div className={`${showContactModal ? 'hidden' : ''}`}>
        <div className={`${styles.action_container}`}>
          {/* {data.map((item) => {
        if (item.visible) {
          return (
            <div
              key={item.name}
              className={`${styles.action} ${item.active ? styles.active : ''}`}
              onClick={item.onClick}
            >
              {IconName[item.icon]}
              <span className={styles.label}>{item.label}</span>
              {item.name === 'reply' && item.showDraft && <div className={styles.draft}>Draft</div>}
            </div>
          );
        }
        return <></>;
      })} */}
          {checkIfPhoneEmailAvailable() && (
            <div
              className={`${styles.action} ${
                isActive === 'reply' ? styles.active : ''
              }`}
              onClick={onReplyNowClick}
            >
              <ReplyIcon />
              <span className={styles.label}>Reply</span>
              {activeLead?.draft_reply?.is_in_draft_state && (
                <div className={styles.draft}>Draft</div>
              )}
            </div>
          )}
          {!checkIfPhoneEmailAvailable() && (
            <div
              className={`${styles.action} ${
                isActive === 'reply' ? styles.active : ''
              }`}
              onClick={() => setShowEmailEmptyPopup(true)}
            >
              <ReplyIcon />
              <span className={styles.label}>Reply</span>
            </div>
          )}
          {/* {(!isEmpty(activeLead?.email) ||
            activeLead?.channel === FACEBOOK ||
            activeLead?.channel === INSTAGRAM ||
            activeLead?.channel === SMS) && (
              <div
                className={`${styles.action} ${isActive === 'reply' ? styles.active : ''
                  }`}
                onClick={onReplyNowClick}
              >
                <ReplyIcon />
                <span className={styles.label}>Reply</span>
                {activeLead?.draft_reply?.is_in_draft_state && (
                  <div className={styles.draft}>Draft</div>
                )}
              </div>
            )} */}
          {/* {isEmpty(activeLead?.email) &&
            activeLead?.channel === FACEBOOK_LEADGEN && (
              <div
                className={`${styles.action} ${isActive === 'reply' ? styles.active : ''
                  }`}
                onClick={() => setShowEmailEmptyPopup(true)}
              >
                <ReplyIcon />
                <span className={styles.label}>Reply</span>
              </div>
            )} */}
          {!isReplyActive && (
            <div
              className={`${styles.action} ${
                isActive === 'replyLater' ? styles.active : ''
              }`}
              onClick={onReplyLaterClick}
            >
              <Clock />
              <span className={styles.label}>
                {/* Reply  */}
                Later
              </span>
            </div>
          )}
          <div className={`${styles.action}`} onClick={onNoteClick}>
            <Note />
            <span className={styles.label}>Note</span>
          </div>

          <div className={`${styles.action}`} onClick={onTaskClick}>
            {_.gt(activeLead?.no_of_overdue_task_count, 0) && (
              <span
                style={{
                  position: 'absolute',
                  marginLeft: '25px',
                  marginTop: '-11px',
                  zIndex: 99999
                }}
              >
                <Count
                  label={activeLead.no_of_overdue_task_count}
                  rounded={true}
                  // className="ml-auto"
                />
              </span>
            )}
            <Reminder style={{ width: '18px', height: '18px' }} />
            <span className={styles.label}>Task</span>
          </div>
          <div
            className={`${styles.action} ${
              isActive === 'binIt' ? styles.active : ''
            }`}
            onClick={() => setShowDelete(true)}
          >
            <Trash className={styles.trash} height={18} width={18} />
            <span className={styles.label}>Bin it</span>
          </div>
        </div>
      </div>
    </>
  );
};

Actions.defaultProps = {
  showMoreActions: false,
  onNavigate: () => {}
};

export default Actions;

// Actions.propTypes = {
//   data: PropTypes.arrayOf(PropTypes.shape({})).isRequired
// };

// Actions.defaultProps = {
//   data: [
//     {
//       name: 'reply',
//       icon: 'reply',
//       label: 'Reply',
//       onClick: () => { },
//       active: true,
//       showDraft: true,
//       visible: true
//     },
//     {
//       name: 'reply later',
//       icon: 'clock',
//       label: 'Reply later',
//       onClick: () => { },
//       visible: true
//     },
//     {
//       name: 'note',
//       icon: 'note',
//       label: 'Note',
//       onClick: () => { },
//       visible: true
//     },
//     {
//       name: 'reminder',
//       icon: 'reminder',
//       label: 'Reminder',
//       onClick: () => { },
//       visible: true
//     },
//     {
//       name: 'bin',
//       icon: 'trash',
//       label: 'Bin it',
//       onClick: () => { },
//       visible: false
//     }
//   ]
// };

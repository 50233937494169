import React from 'react';
import PropTypes from 'prop-types';

const Form = (props) => {
  const { onClick, title } = props;
  return (
    <div className="text-center mt-4">
      <div
        className="text-primary font-normal cursor-pointer text-15"
        onClick={onClick}
      >
        {title}
      </div>
    </div>
  );
};

Form.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string
};

export default Form;

import React from 'react';
import { ReactComponent as HandSvg } from 'assets/icons/icon-hand.svg';

const EmptyStateChannel = () => {
  return (
    <div className="flex justify-center items-center w-full">
      <div
        className="text-center "
        style={{
          width: '300px',
          marginTop: 200
        }}
      >
        <figure className="flex justify-center">
          <HandSvg style={{ marginLeft: '-30px' }} />
        </figure>
        <h3 className="mt-2 text-lg font-semibold">
          Click on a connected channel to setup automations and manage settings
        </h3>
      </div>
    </div>
  );
};

export default EmptyStateChannel;

import Navbar from 'components/navigation/navbar';
import { Route, Routes } from 'react-router-dom';
import DashBoard from './dashboard';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAssistants,
  getTemplates
} from 'slices/ai-assistant/assistant/slice';
import { getAvatarList } from 'slices/ai-assistant/personality/slice';
import { useEffect } from 'react';
import { isEmpty } from 'utils/utils';

function Index() {
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const listAssistants = useSelector((state) => state.assistant.listAssistants);

  useEffect(() => {
    if (isEmpty(listAssistants)) {
      dispatch(getAssistants(subOrganizationId));
      dispatch(getTemplates());
      dispatch(getAvatarList(subOrganizationId));
    }
  }, [subOrganizationId]);
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/*" element={<DashBoard />}></Route>
      </Routes>
    </>
  );
}

export default Index;

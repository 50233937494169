import React from 'react';
import HistoryLayout from './layout';
import binIcon from 'assets/icons/icon-white-bin.svg';
import choices from 'choices';
import { timeFormatter } from 'utils/utils';

export const Bin = ({ desc }) => {
  return (
    <div className="flex">
      <div className="flex py-3 text-13 px-3 justify-center align-center">
        {desc}
      </div>
    </div>
  );
};

const MovedToBin = (props) => {
  let {
    data: { title, created_at, description }
  } = props;

  return (
    <HistoryLayout
      title={choices.TitleChoices.CHOICES[title]}
      icon={binIcon}
      time={timeFormatter(created_at)}
      renderData={() => <Bin desc={description} />}
      className="history__new-enquiry"
    />
  );
};
export default MovedToBin;

import React from 'react';
import fileTooLargeIcon from 'assets/icons/icon-file-too-large.svg';

const FileTooLarge = ({ onTryAgain }) => {
  const handleCompress = () => {
    window.open('https://www.adobe.com/ie/acrobat/online/compress-pdf.html');
  };
  return (
    <div className="modal__error-section">
      <img src={fileTooLargeIcon} alt="" style={{ width: 32, height: 32 }} />
      <div>
        <h3 className="flex justify-center text-sm text-red-600 font-semibold mt-3">
          File too large
        </h3>
        <div className="mt-1 text-xs ml-4 text-grey-800">
          This file is larger than the 5MB limit. Please compress the file here
          to make it smaller
        </div>
        <div
          className="flex justify-center mt-2 txt-blue text-13 font-medium"
          onClick={handleCompress}
        >
          Compress the file here
        </div>
        <div className="flex justify-center mt-25">
          <button
            className="btn btn--primary btn--try-again"
            onClick={onTryAgain}
          >
            Try again
          </button>
        </div>
      </div>
    </div>
  );
};

export default FileTooLarge;

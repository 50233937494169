/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import search from 'assets/icons/icon-search.svg';
import InputWithIcon from 'components/inputs/inputWithIcon';
import { ReactComponent as CaretDownIcon } from 'assets/icons/caret-down-grey.svg';
import { ReactComponent as CaretDownIconPrimary } from 'assets/icons/caret-down-primary.svg';
import { ReactComponent as CaretUpIconPrimary } from 'assets/icons/caret-up-primary.svg';
import { ReactComponent as CloseIconPrimary } from 'assets/icons/icon-close-primary.svg';
import { ReactComponent as LabelsIcon } from 'assets/icons/icon-box-filter-labels.svg';
import { ReactComponent as LabelsIconPrimary } from 'assets/icons/icon-box-filter-labels-primary.svg';
import CheckBox from 'components/inputs/checkbox';
import { isEmpty } from 'utils/utils';
import useClickOutside from 'hooks/useOutsideClick';
import { useDispatch, useSelector } from 'react-redux';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import { setLabelsSelected } from 'slices/boxes/slice';
import { useParams } from 'react-router-dom';

const Labels = ({
  openLabels,
  setOpenLabels,
  selectedLabels,
  setSelectedLabels
}) => {
  const labelsRef = useRef();
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const { allLabels } = useSelector((state) => state.labels);
  const [totalLabels, setTotalLabels] = useState(allLabels);
  const { labelsSelected } = useSelector((state) => state.boxes);

  useClickOutside(() => setOpenLabels(false), labelsRef.current);

  useEffect(() => {
    setSelectedLabels([]);
    dispatch(setLabelsSelected([]));
  }, [box_id]);

  const getCheckedStatus = (id) => {
    if (!isEmpty(selectedLabels)) {
      const isLabelChecked = selectedLabels?.some((item) => item.id === id);
      return isLabelChecked;
    } else {
      return false;
    }
  };

  const handleChange = (e, channel) => {
    if (e.target.checked) {
      setSelectedLabels([...selectedLabels, channel]);
      dispatch(setLabelsSelected([...labelsSelected, channel.id]));
    } else {
      const filteredLabels = selectedLabels.filter(
        (item) => item.id !== channel.id
      );
      setSelectedLabels(filteredLabels);
      const remainedLabels = [];
      filteredLabels.forEach((item) => {
        return remainedLabels.push(item.id);
      });
      dispatch(setLabelsSelected(remainedLabels));
    }
  };

  const handleRemoveLabels = (id) => {
    const filteredLabels = selectedLabels.filter((item) => item.id !== id);
    setSelectedLabels(filteredLabels);
    const remainedLabels = [];
    filteredLabels.forEach((item) => {
      return remainedLabels.push(item.id);
    });
    dispatch(setLabelsSelected(remainedLabels));
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = allLabels?.filter((item) =>
        item.label_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setTotalLabels(filtered);
    } else {
      setTotalLabels(allLabels);
    }
  };

  return (
    <>
      <CustomColorButtonWithLogo
        className={`border btn--xs-28 ${
          openLabels
            ? 'bg-grey-90 border-primary-border '
            : 'border-grey-400 btn--xs-28'
        } ${
          selectedLabels.length > 0 && !openLabels
            ? 'bg-grey-90 border-primary-border btn--xs-28-p-1'
            : ''
        }`}
        style={{ borderRadius: 20 }}
        render={() => {
          return (
            <>
              <div
                className="flex items-center mr-2"
                onClick={() => {
                  setOpenLabels(!openLabels);
                }}
              >
                <figure className="mr-2">
                  {!openLabels ? (
                    selectedLabels.length > 0 ? (
                      <LabelsIconPrimary />
                    ) : (
                      <LabelsIcon />
                    )
                  ) : (
                    <LabelsIconPrimary />
                  )}
                </figure>
                {selectedLabels.length > 0 ? (
                  <></>
                ) : (
                  <span
                    className={`text-13 font-medium mr-2 ${
                      openLabels ? 'text-primary' : ''
                    }`}
                  >
                    Label
                  </span>
                )}
                <figure>
                  {!openLabels ? (
                    selectedLabels.length > 0 ? (
                      <CaretDownIconPrimary />
                    ) : (
                      <CaretDownIcon />
                    )
                  ) : (
                    <CaretUpIconPrimary />
                  )}
                </figure>
              </div>
              {selectedLabels.length > 0 &&
                selectedLabels.map((item, index) => {
                  return (
                    <div className="box-filter-assignTo" key={index}>
                      <p className="text-xs font-medium mr-2 truncate">
                        {item.label_name}
                      </p>
                      <figure onClick={() => handleRemoveLabels(item.id)}>
                        <CloseIconPrimary />
                      </figure>
                    </div>
                  );
                })}
            </>
          );
        }}
      />
      <div
        className={`dropdown-menu dropdown-menu--with-border mt-3 ${
          openLabels ? 'open' : ''
        } p-2`}
        style={{
          right: '0px',
          width: '316px',
          top: '20px',
          transition: 'all 300ms ease-in'
        }}
        ref={labelsRef}
      >
        <div style={{ margin: '10px 10px 0px' }}>
          <InputWithIcon
            icon={search}
            placeholder="Search"
            value={searchValue}
            onChange={(e) => handleSearch(e)}
            iconClassName="mr-2"
            medium
          />
        </div>
        {/* <p
          className="text-grey-700 text-xs font-medium text-left mt-4"
          style={{ marginLeft: 10 }}
        >
          Labels
        </p> */}
        <div className={`no-hover dropdown-menu__overflow-y-scroll mt-1`}>
          {isEmpty(totalLabels) ? (
            <div className="flex justify-center text-sm font-semibold h-full items-center">
              label not found
            </div>
          ) : (
            <>
              {totalLabels.map((label, index) => {
                return (
                  <label
                    htmlFor={`${label.id}`}
                    key={index}
                    className="dropdown-menu__list font-medium"
                    style={{ color: '#2B3639' }}
                  >
                    <CheckBox
                      name={`${label.id}`}
                      checked={getCheckedStatus(label.id)}
                      onChange={(e) => handleChange(e, label)}
                      style={{ marginRight: 12 }}
                      small
                      id={`${label.id}`}
                    />
                    <p>{label.label_name}</p>
                  </label>
                );
              })}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Labels;

import React from 'react';
import { Routes, Route } from 'react-router-dom';
import SignUp from 'modules/registration/signup';
import CreatePassword from 'modules/registration/password/createPassword';
import ForgotPassword from 'modules/registration/password/forgotPassword';
import Login from 'modules/auth/login';
import PersonalInfo from 'modules/registration/invitation/personalInfo';
import InviteUser from 'modules/registration/invitation/createAccount';
import EmailConfirmation from 'modules/registration/email/confirmation';
import ResetLinkSent from 'modules/registration/password/forgotPassword/resetLink';
import ResendEmail from 'modules/registration/email/resend';
import Navigation from 'components/navigation';
import ProtectedRoute from 'components/routes/protected';
import UnprotectedRoute from 'components/routes/unprotected';
import PostLogin from 'modules/auth/login/postLogin';
import ConfirmEmailLink from 'modules/registration/email/linkConfirm';
import ConfirmMicrosoft from 'modules/auth/login/microsoft/confirm';
// import BoxEnquiryFormPreview from 'modules/boxes/components/enquiryForm';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <Navigation />
          </ProtectedRoute>
        }
      />
      <Route
        path="sign-up"
        element={
          <UnprotectedRoute>
            <SignUp />
          </UnprotectedRoute>
        }
      />
      <Route
        path="login"
        element={
          <UnprotectedRoute>
            <Login />
          </UnprotectedRoute>
        }
      />
      <Route
        path="post-login"
        element={
          <ProtectedRoute>
            <PostLogin />
          </ProtectedRoute>
        }
      />
      <Route
        path="confirm-account"
        element={
          <UnprotectedRoute>
            <ConfirmEmailLink />
          </UnprotectedRoute>
        }
      />
      <Route
        path="confirm-microsoft"
        element={
          <UnprotectedRoute>
            <ConfirmMicrosoft />
          </UnprotectedRoute>
        }
      />
      <Route
        path="reset-password"
        element={
          <UnprotectedRoute>
            <CreatePassword />
          </UnprotectedRoute>
        }
      />
      <Route
        path="forgot-password"
        element={
          <UnprotectedRoute>
            <ForgotPassword />
          </UnprotectedRoute>
        }
      />
      <Route path="personal-info" element={<PersonalInfo />} />
      <Route
        path="invite"
        element={
          <UnprotectedRoute>
            <InviteUser />
          </UnprotectedRoute>
        }
      />
      <Route
        path="email-confirmation"
        element={
          <UnprotectedRoute>
            <EmailConfirmation />
          </UnprotectedRoute>
        }
      />
      <Route path="reset-link" element={<ResetLinkSent />} />
      <Route
        path="resend-email"
        element={
          <UnprotectedRoute>
            <ResendEmail />
          </UnprotectedRoute>
        }
      />
      {/* <Route path="enquiry/:boxId" element={<BoxEnquiryFormPreview />} /> */}
    </Routes>
  );
};

export default AppRoutes;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import ConnectedSetting from './connectedSetting';
import ChannelsConnectedEmptyState from '../../components/channelsConnectedEmpty';
import { useSelector, useDispatch } from 'react-redux';
import { getAllPendingChannels } from 'slices/channelsSlice';
import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
import WebformPreDraft from './preDraft';
import WebformPostDraft from './postDraft';
import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
import WebformSettings from 'modules/channels/components/webformSettings';
import WebformForms from 'modules/channels/components/webformForms';
// import AnimationWrapper from 'animation/wrapper';

const { WEBFORM } = CHOICES.ChannelChoices;

const Webform = ({ isEbox }) => {
  const dispatch = useDispatch();
  const { allPendingChannels } = useSelector((state) => state.channels);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [isLoading, setIsLoading] = useState(
    isEmpty(allPendingChannels) || isEmpty(subOrganizationId)
  );

  const getPendingChannels = () =>
    dispatch(
      getAllPendingChannels({ subOrgId: subOrganizationId }, () =>
        setIsLoading(false)
      )
    );

  useEffect(() => {
    if (isEmpty(allPendingChannels) && !isEmpty(subOrganizationId)) {
      getPendingChannels();
    } else {
      setIsLoading(false);
    }
  }, []);
  // const allConnectedChannels = useSelector(
  //   (state) => state.channels.allConnectedChannels
  // );
  // const WebformConnected =
  //   allConnectedChannels &&
  //   allConnectedChannels.filter((item) => item.channel.channel_type === 1);

  if (isLoading) return <Loader />;

  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route
        path="/pending/:channelId/draft"
        element={<WebformPostDraft isEbox={isEbox} />}
      />
      <Route
        path="/pending/:channelId"
        element={<WebformPreDraft isEbox={isEbox} />}
      />
      <Route path="/connected" element={<ChannelsConnectedEmptyState />} />
      <Route path="/connected/:channelId" element={<ConnectedSetting />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={WEBFORM} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<WebformSettings />}
      />
      <Route path="/connected/:channelId/forms" element={<WebformForms />} />
      {/* </Route> */}
    </Routes>
  );
};

export default React.memo(Webform);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Suspense, lazy, useMemo } from 'react';
import { ReactComponent as IconEbBox } from 'assets/icons/icon-sidebar-eb-box.svg';
import { ReactComponent as IconPlusGreen } from 'assets/icons/icon-plus-green.svg';
import { ReactComponent as IconPlusGray } from 'assets/icons/icon-plus-gray.svg';
import { route } from 'utils/route';
import { getInitialLetter } from 'utils/utils';
import SectionItem from 'modules/dashboard/component/sections';
import SectionHeader from 'modules/dashboard/component/sections/header';
import Avatar from 'components/avatar';
import { NavLink, useLocation, Routes, Route } from 'react-router-dom';
import InviteTeamMember from '../module/inviteTeamMember';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import BoxesSideNav from './boxes/index';
import { getOrganizationUser } from 'slices/organizationuserSlice';
import ProgressBar from 'components/progressBar';
import FreeEnquiriesCounter from 'modules/subscription/freeEnquiriesCounter';
import usePlanAccess from 'hooks/usePlanAccess';
import UpgradePlanTooltip from 'modules/modals/UpgradePlanTooltip';
import { numberFormat } from 'utils/number';
import CHOICES from 'choices';
const { FREE_TRIAL } = CHOICES.PlanType;
const SideNav = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { has_unlimited_user, is_free_plan } = usePlanAccess();
  const { myInformation } = useSelector((state) => state.myInfo);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  // const { totalLeadCount } = useSelector((state) => state.leads);
  const { users_count } = useSelector((state) => state.allCount);
  const { things_to_do_count } = useSelector((state) => state.taskThingsToDo);
  const [openModal, setOpenModal] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const { currentPlan } = useSelector((state) => state.subscription);
  useMemo(() => {
    if (things_to_do_count) {
      const enq_count = things_to_do_count?.enquiry_count || 0;
      const task_count = things_to_do_count?.task_count || 0;
      setTotalCount(enq_count + task_count);
    }
  }, [things_to_do_count]);

  const loadUsersList = () => {
    const setUserFilter = true;
    dispatch(
      getOrganizationUser(
        { subOrgId: subOrganizationId },
        () => {},
        setUserFilter
      )
    );
  };

  // useEffect(() => {
  //   if (subOrganizationId) {
  //     loadUsersList();
  //   }
  // }, [subOrganizationId]);

  const isActiveUser = (id) => {
    return myInformation?.id === id ? 'You' : null;
  };

  const getCount = (id) => {
    return users_count[id];
  };

  const getRoutePath = (id) => {
    // if (myInformation?.id === id) {
    //   return `${route.dashboard}${route.teamChat}/${id}`;
    // } else {
    return `${route.dashboard}${route.teamChat}/user/${id}`;
    // }
  };

  const isEnquiryBox = () => {
    return location.pathname.includes(route.enquirybox) ? 'active' : '';
  };

  return (
    <div
      className="dashboard__sidebar__expanded-section"
      style={{ overflowX: 'hidden' }}
    >
      <NavLink
        to={`${route.dashboard}${route.enquirybox}/eb-box`}
        className={`flex justify-between py-3 bg-color-on-hover bg-color-on-hover--bg-grey-100 ${isEnquiryBox()}`}
      >
        <div className="flex items-center">
          <IconEbBox width={16} height={16} />
          <h2 className="text-15 font-semibold text-grey-900 ml-3">
            Things to do
          </h2>
        </div>
        {_.gt(totalCount, 0) && (
          <div>
            <p
              className="text-xs text-white font-semibold px-2 rounded-xl "
              style={{
                paddingBottom: '0.1rem',
                paddingTop: '0.1rem',
                backgroundColor: '#da1729'
              }}
            >
              {numberFormat(totalCount)}
            </p>
          </div>
        )}
      </NavLink>
      <div className="bg-grey-400 mt-4 mb-2" style={{ height: 1 }}></div>
      {/* <h2 className="text-2xl font-semibold text-grey-900">Enquirybox</h2>
      <EnquiryAssistant visibility={!tourWindow} /> */}
      {subOrganizationId && <BoxesSideNav />}
      {subOrganizationId && (
        <div className="bg-grey-400 mb-6 mt-5" style={{ height: 1 }} />
      )}
      {subOrganizationId && (
        <div
          className={
            currentPlan?.plan?.plan_type === FREE_TRIAL ? 'mb-[50px]' : ''
          }
        >
          <SectionHeader label="Team chat" />
          <div className="flex flex-col">
            {organizationUser.results &&
              organizationUser?.results.map((item, index) => {
                const {
                  user_id,
                  avatar,
                  name,
                  first_name,
                  last_name,
                  is_disabled
                } = item;
                return (
                  <SectionItem
                    key={index}
                    label={`${first_name} ${getInitialLetter(last_name)}`}
                    subLabel={isActiveUser(user_id)}
                    to={getRoutePath(user_id)}
                    // disableRedirect={myInformation?.id !== user_id}
                    showCount={false}
                    count={getCount(user_id)}
                    isUser={true}
                    isDisabled={is_disabled}
                    renderSvg={() => {
                      return (
                        // <figure
                        //   className="avatar-container avatar-container--contain bg-white"
                        //   style={{
                        //     width: 20,
                        //     height: 20
                        //   }}
                        // >
                        //   <img
                        //     src={avatar}
                        //     alt=""
                        //     style={{ width: 20, height: 20 }}
                        //   />
                        //   {myInformation?.id === user_id && (
                        //     <div className="avatar-container__sup-icon--bottom absolute">
                        //       <ActiveIcon />
                        //     </div>
                        //   )}
                        // </figure>
                        <Avatar
                          src={
                            myInformation?.id === user_id
                              ? myInformation.avatar
                              : avatar
                          }
                          className={'bg-white'}
                          isOnline={myInformation?.id === user_id}
                          imgStyle={{ borderRadius: '6px' }}
                          initialStyle={{ fontSize: 'medium' }}
                          style={{ width: 26, height: 26, borderRadius: '6px' }}
                          initial={name}
                          border={false}
                          medium
                          full
                          contain
                          singleChar
                        />
                      );
                    }}
                  />
                );
              })}
          </div>
          <UpgradePlanTooltip alignment="top" show={!has_unlimited_user}>
            <div
              className={`flex items-center mt-3 ${
                has_unlimited_user ? 'cursor-pointer' : 'cursor-not-allowed'
              }`}
              onClick={() => {
                if (has_unlimited_user) {
                  setOpenModal(true);
                }
              }}
            >
              {has_unlimited_user ? <IconPlusGreen /> : <IconPlusGray />}
              <p
                className={`ml-4 text-sm font-medium ${
                  has_unlimited_user ? 'text-primary' : 'text-[#AAAAAA]'
                }`}
              >
                Add a team member
              </p>
            </div>
          </UpgradePlanTooltip>
        </div>
      )}
      <InviteTeamMember open={openModal} onClose={() => setOpenModal(false)} />
      {is_free_plan && <FreeEnquiriesCounter />}
    </div>
  );
};

export default SideNav;

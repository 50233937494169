/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Automation from './item';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedAutomation,
  setAutomationName,
  setConnectedChannel,
  setThen,
  setAutomationStage,
  setIsAutomationActive,
  updateAutomationStatus,
  setBoxAutomations,
  setIsDelayTypeSelected
} from 'slices/boxAutomation/slice';
import choices from 'choices';
import ScreenLoader from 'components/loader/screenLoader';
import { useParams } from 'react-router-dom';
import { getBoxData, setBoxes } from 'slices/boxes/slice';
import { setTaskTypeTitle } from 'slices/task/slice';
import CHOICES from 'choices';

const {
  ASSIGN_MEMBER,
  FACEBOOK,
  INSTAGRAM,
  AUTORESPONDER_EMAIL,
  DELAY_TIMER,
  MOVE_TO_BOX,
  SMS,
  TASK,
  NOTES
} = choices.BoxAutomationChoice;

const AutomationsList = () => {
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const { boxAutomations, isDelayTypeSelected } = useSelector(
    (state) => state.boxAutomation
  );
  const { boxes } = useSelector((state) => state.boxes);
  const { allConnectedChannels } = useSelector((state) => state.channels);
  const [isLoading, setIsLoading] = useState(null);
  const [automationLoading, setAutomationLoading] = useState(false);

  // build connected channel data
  const buildConnectedChannelList = (data, cb) => {
    if (data.any_channel) {
      dispatch(
        setConnectedChannel([{ id: 'any_channel', name: 'Any channel' }])
      );
      cb();
      return;
    }
    const connectedChannels = [];
    function getChannel(channel) {
      const current_channel = allConnectedChannels.find(
        (c) => c.id === channel
      );
      if (current_channel) {
        connectedChannels.push(current_channel);
      }
    }
    data.connected_channel.forEach(getChannel);
    dispatch(setConnectedChannel(connectedChannels));
    cb();
  };

  const getAutomationTypeData = (currentData, type) => {
    if (type === DELAY_TIMER) {
      dispatch(setIsDelayTypeSelected(true));
    }
    switch (type) {
      case AUTORESPONDER_EMAIL: {
        const [emailData] = currentData.autoresponderemail_set;
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            email_data_id: emailData.id,
            send_from: {
              user_id: emailData.from_data.id,
              email: emailData.from_data.reply_sending_mail_address
            },
            cc: emailData.cc,
            bcc: emailData.bcc,
            subject: emailData.subject,
            message: emailData.message,
            attachments: emailData.attachments.map((file) => {
              return { ...file, isFileLibrary: true };
            })
          }
        };
      }
      case ASSIGN_MEMBER:
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            assign_user: currentData.assign_user
          }
        };
      case DELAY_TIMER:
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            delay_id: currentData.task_delay.id,
            delay_kind: currentData.task_delay.delay_kind,
            delay_value: currentData.task_delay.delay_value
          }
        };
      case MOVE_TO_BOX:
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            move_to_box: currentData.move_to_box.id,
            move_to_stage: currentData.move_to_stage
          }
        };
      case FACEBOOK: {
        const [facebookData] = currentData.replytofborigcustomer_set;
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            fb_ig_data_id: facebookData.id,
            message: facebookData.message
          }
        };
      }
      case INSTAGRAM: {
        const [instagramData] = currentData.replytofborigcustomer_set;
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            fb_ig_data_id: instagramData.id,
            message: instagramData.message
          }
        };
      }
      case SMS: {
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            message: currentData.sms_message,
            sms_count: currentData.sms_count,
            raw_sms_message: currentData.raw_sms_message
          }
        };
      }
      case NOTES: {
        const [notesData] = currentData.notesautomation_set;
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            notes_id: notesData.id,
            description: notesData.description,
            tagged_users: notesData.tagged_users
          }
        };
      }
      case TASK: {
        const [taskData] = currentData.tasksautomation_set;
        dispatch(
          setTaskTypeTitle(CHOICES.TasksTypeChoices.CHOICES[taskData.task_type])
        );
        return {
          automation_task_id: currentData.id,
          automation_type: type,
          position: currentData.position,
          data: {
            task_id: taskData.id,
            note: taskData.note,
            assigned_to: taskData.assigned_to,
            due_in: taskData.due_in,
            due_days_value: taskData.due_days_value,
            task_type: taskData.task_type
          }
        };
      }
      default:
        return 'Select what you would like to do now';
    }
  };

  // build then data
  const buildThenData = (data, cb) => {
    const then = [{}];
    data.boxautomationtask_set.forEach((automation, index) => {
      const thenData = getAutomationTypeData(
        automation,
        automation.automation_type
      );
      then[index] = thenData;
    });
    dispatch(setThen(then));
    cb();
  };

  // building the data after automation is selected
  const buildSelectedAutomationData = (data) => {
    const { automation_name, stage, is_active } = data;
    dispatch(setAutomationName(automation_name));
    dispatch(setAutomationStage({ value: stage.id, label: stage.stage_name }));
    dispatch(setIsAutomationActive(is_active));
    buildConnectedChannelList(data, () => {
      buildThenData(data, () => {
        setTimeout(() => {
          setIsLoading(null);
          dispatch(setSelectedAutomation(data));
        }, 1000);
      });
    });
  };

  const onAutomationClick = (data) => {
    setIsLoading(data.id);
    buildSelectedAutomationData(data);
  };

  const onStatusFail = () => {
    setAutomationLoading(false);
  };

  const loadCallback = (data) => {
    const updatedIndex = boxes.findIndex((item) => item.id === data?.id);
    const updatedBoxData = [
      ...boxes.slice(0, updatedIndex),
      data,
      ...boxes.slice(updatedIndex + 1)
    ];
    dispatch(setBoxes(updatedBoxData));
  };

  const onStatusChangeSuccess = (res, StageIndex) => {
    const {
      data: { id, is_active }
    } = res;
    const updatedAutomations = boxAutomations[StageIndex].automations.map(
      (automation) => {
        if (automation.id === id) {
          return {
            ...automation,
            is_active
          };
        }
        return automation;
      }
    );
    const temp = boxAutomations.map((item, index) => {
      if (index === StageIndex) {
        return {
          ...item,
          automations: updatedAutomations
        };
      }
      return item;
    });
    dispatch(setBoxAutomations(temp));
    dispatch(
      getBoxData({
        boxId: box_id,
        callback: loadCallback
      })
    );
    setAutomationLoading(false);
  };

  const onChangeActive = (automation) => {
    setAutomationLoading(automation.id);
    dispatch(
      updateAutomationStatus(
        {
          automation_id: automation.id,
          data: {
            is_active: !automation.is_active
          }
        },
        onStatusChangeSuccess,
        onStatusFail
      )
    );
  };

  return (
    <>
      {isLoading && <ScreenLoader />}
      {boxAutomations.map((item, ind) => {
        return (
          <div key={ind}>
            <h2 className="text-primary text-xs font-semibold mt-4">
              {item?.stage_name}
            </h2>
            {item?.automations.map((automation) => {
              return (
                <Automation
                  parentIndex={ind}
                  key={automation.id}
                  automation={automation}
                  title={automation.automation_name}
                  onClick={onAutomationClick}
                  onChangeActive={onChangeActive}
                  isLoading={automation.id === automationLoading}
                />
              );
            })}
          </div>
        );
      })}
      {/* {boxAutomations.map((automation) => {
        return (
          <Automation
            key={automation.id}
            automation={automation}
            title={automation.automation_name}
            onClick={onAutomationClick}
            onChangeActive={onChangeActive}
            isLoading={automation.id === automationLoading}
          />
        );
      })} */}
    </>
  );
};

export default AutomationsList;

// import { ReactComponent as VideoIcon } from 'assets/icons/Video_fill.svg';
import { ReactComponent as ChatIcon } from 'assets/icons/Chat_icon.svg';
import { ReactComponent as BookOpen } from 'assets/icons/book_open.svg';

export const boxGuideOptions = [
  // {
  //   id: 1,
  //   label: 'Watch a tutorial',
  //   Icon: VideoIcon,
  //   url: 'https://app.enquirybox.io/dashboard/enquirybox/eb-box?product_tour_id=472241'
  // },
  {
    id: 2,
    label: 'Setup stage automations ⚡',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7228547-box-automations'
  },
  {
    id: 3,
    label: 'Box form for adding enquiries',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7228563-collect-enquiries-easily-with-box-forms'
  },
  {
    id: 4,
    label: 'Design your enquiry cards',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7228555-card-design'
  },
  {
    id: 5,
    label: 'Get some help',
    Icon: ChatIcon,
    url: 'https://www.intercom.com/help/en/articles/2894-create-a-custom-launcher'
  }
];
export const channelGuideOptions = [
  // {
  //   id: 1,
  //   label: 'Watch a tutorial',
  //   Icon: VideoIcon,
  //   url: 'https://app.enquirybox.io/dashboard/enquirybox/eb-box?product_tour_id=472241'
  // },
  {
    id: 4,
    label: 'My channel connection failed',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/8504975-troubleshooting-channel-connections'
  },
  {
    id: 5,
    label: 'Get some help',
    Icon: ChatIcon,
    url: 'https://www.intercom.com/help/en/articles/2894-create-a-custom-launcher'
  }
];
export const reportGuideOptions = [
  // {
  //   id: 1,
  //   label: 'Watch a tutorial',
  //   Icon: VideoIcon,
  //   url: 'https://app.enquirybox.io/dashboard/enquirybox/eb-box?product_tour_id=472241'
  // },
  {
    id: 2,
    label: 'Incoming enquiry report',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7229099-incoming-enquiry-report'
  },
  {
    id: 3,
    label: 'Sales report',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7229100-sales-reports'
  },
  {
    id: 4,
    label: 'Team performance report',
    Icon: BookOpen,
    url: 'https://help.enquirybox.io/en/articles/7229105-team-performance-report'
  },
  {
    id: 5,
    label: 'Get some help',
    Icon: ChatIcon,
    url: 'https://www.intercom.com/help/en/articles/2894-create-a-custom-launcher'
  }
];

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteOrganizationUser,
  updateRoleForOrganizationUser
} from 'slices/teamMember/slice';
import { Loader } from 'components/loader';
import { isEmpty } from 'utils/utils';
import UserItem from './component/userItem';
import InviteTeamMember from 'modules/dashboard/module/inviteTeamMember';
import { getOrganizationUser } from 'slices/organizationuserSlice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import ReassignModal from './component/modal/resassign';
import { useSnackbar } from 'components/Snackbar';
import CHOICES from 'choices';
import UpgradePlanTooltip from 'modules/modals/UpgradePlanTooltip';
import usePlanAccess from 'hooks/usePlanAccess';

const ADMIN = CHOICES.TeamMemberRoleChoice.TEAM_ADMIN;

const TeamMember = () => {
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const { has_unlimited_user } = usePlanAccess();
  const [isLoading, setIsLoading] = useState(false);
  const [openInviteModal, setOpenInviteModal] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [openReassignModal, setOpenReassignModal] = useState(false);
  const [leadsAssigned, setLeadsAssigned] = useState(0);
  const [deletingUser, setDeletingUser] = useState('');
  const [assignToUser, setAssignToUser] = useState(null);
  const [deletingUserRole, setDeletingUserRole] = useState(null);
  const { teamMembers } = useSelector((state) => state.organizationUser);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const onSuccess = () => {
    setIsLoading(false);
  };

  const loadUsersList = () => {
    dispatch(getOrganizationUser({ subOrgId: subOrganizationId }, onSuccess));
  };

  const callback = (role) => {
    openSuccessSnackBar(
      `${role === ADMIN ? 'Changed to Team Admin' : 'Changed to Team Member'}`,
      'large'
    );
    loadUsersList();
  };

  useEffect(() => {
    setIsLoading(true);
    loadUsersList();
  }, []);

  const onUpdateRoles = (userId, role) => {
    const formdata = {
      role: role
    };
    dispatch(
      updateRoleForOrganizationUser({
        body: formdata,
        subOrgId: subOrganizationId,
        OrgUserId: userId,
        callback: callback
      })
    );
  };

  const onDeleteUser = (userId, leadsNumber, userName, userRole) => {
    if (leadsNumber > 0) {
      setDeletingUser(userName);
      setLeadsAssigned(leadsNumber);
      setDeletingUserRole(userRole);
      setOpenReassignModal(true);
    } else {
      setOpenDeleteConfirm(true);
      setAssignToUser(null);
    }
    setDeleteUserId(userId);
  };

  const deleteCallback = () => {
    setIsDeleteLoading(false);
    openSuccessSnackBar(
      `${
        deletingUserRole === ADMIN
          ? 'Team admin deleted'
          : 'Team member deleted'
      }`,
      'large'
    );
    setOpenDeleteConfirm(false);
    loadUsersList();
  };

  const onConfirm = () => {
    setIsDeleteLoading(true);
    const formdata = {
      assigned_to_organization_user_id: assignToUser
    };
    dispatch(
      deleteOrganizationUser({
        body: formdata,
        subOrgId: subOrganizationId,
        OrgUserId: deleteUserId,
        callback: deleteCallback
      })
    );
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <DeleteConfirmation
        showConfirmation={openDeleteConfirm}
        onCancel={() => setOpenDeleteConfirm(false)}
        onConfirm={onConfirm}
        title="Are you sure you want to delete this user?"
        description="Once deleted you will not be able to get it back. You will have to
      add it again."
        isLoading={isDeleteLoading}
      />
      <ReassignModal
        open={openReassignModal}
        onClose={() => setOpenReassignModal(false)}
        leadsAssigned={leadsAssigned}
        userName={deletingUser}
        organizationUser={teamMembers}
        onDone={(id) => {
          setAssignToUser(id);
          setOpenDeleteConfirm(true);
        }}
      />
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Team members</h2>
        <p className="text-grey-800 text-15 mt-3">Invite new, manage access</p>
      </div>
      <div className="mt-6">
        <div className="flex justify-between">
          <h4 className="font-semibold text-lg">Team members</h4>

          <UpgradePlanTooltip alignment="left" show={!has_unlimited_user}>
            <PrimaryWithLogo
              label="Add team member"
              className="btn--xs"
              isBlocked={!has_unlimited_user}
              onClick={() => has_unlimited_user && setOpenInviteModal(true)}
              renderSvg={() => {
                return <PlusIcon />;
              }}
              style={{ fontWeight: 600 }}
            />
          </UpgradePlanTooltip>
        </div>
      </div>
      <div className="mt-6">
        {!isEmpty(teamMembers) &&
          !isEmpty(teamMembers?.results) &&
          teamMembers?.results.map((item) => {
            return (
              <UserItem
                key={item.organization_user_id}
                user={item}
                onUpdateRoles={onUpdateRoles}
                onDeleteUser={onDeleteUser}
              />
            );
          })}
      </div>
      <InviteTeamMember
        open={openInviteModal}
        onClose={() => setOpenInviteModal(false)}
      />
    </>
  );
};

export default TeamMember;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Error from 'components/errorMessage';
import { ReactComponent as MoreIcon } from 'assets/icons/icon-more-round.svg';
import { ReactComponent as RenameIcon } from 'assets/icons/icon-select-rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-select-delete.svg';
// import { ReactComponent as PreviewIcon } from 'assets/icons/icon-Preview.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/icon-download.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/icon-Copy.svg';
import { useSnackbar } from 'components/Snackbar';
import InputWithButton from 'components/inputs/inputWithButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { editFileName, getFileLibraryData } from 'slices/fileLibrary/slice';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { fileIcons, getFileExtension } from 'utils/file';
import DownloadLink from 'components/downloadLink';
import useGetUserRole from 'hooks/useGetUserRole';
import AccessRestricted from 'modules/modals/AccessRestricted';

const FileItem = (props) => {
  const {
    id,
    title,
    file_url,
    showMoreDropdown,
    setOpenEdit,
    setOpenDeleteModal,
    setShowMoreDropdown,
    openEdit
  } = props;
  const { openSuccessSnackBar } = useSnackbar();
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const { isTeamMember } = useGetUserRole();
  const formSchema = {
    fileName: string().required('file name is required')
  };

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    reset,
    getValues,
    clearErrors
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  useEffect(() => {
    clearErrors('fileName');
    setError('');
  }, [openEdit]);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(file_url);
    openSuccessSnackBar('Link copied');
    setShowMoreDropdown(null);
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const formData = {
      file_name: data.fileName,
      file_url: file_url
    };
    dispatch(editFileName({ body: formData, fileId: id, callback }));
  };

  const fileCallback = () => {
    setOpenEdit(null);
  };

  const callback = () => {
    setIsLoading(false);
    setShowMoreDropdown(null);
    dispatch(
      getFileLibraryData({
        subOrgId: subOrganizationId,
        callback: fileCallback
      })
    );
  };

  const onCancel = () => {
    reset({ fileName: title });
    setOpenEdit(null);
    setShowMoreDropdown(null);
  };

  const onKeyEnter = (name) => {
    if (name === '') {
      setError('File name is required');
    } else {
      setIsLoading(true);
      const formData = {
        file_name: name,
        file_url: file_url
      };
      dispatch(editFileName({ body: formData, fileId: id, callback }));
    }
  };

  return (
    <>
      {!(openEdit === id) ? (
        <div
          className="py-3 px-3 flex justify-between items-center hover-bg-grey"
          style={{ marginTop: 2 }}
        >
          <div className="flex items-center">
            <img
              src={fileIcons[getFileExtension(file_url)]}
              alt=""
              className="mr-3"
              style={{ width: 25, height: 32 }}
            />
            <span className="font-medium text-sm">{title}</span>
          </div>
          <div
            className="cursor-pointer relative px-1 py-2 bg-white rounded-md"
            onClick={() => setShowMoreDropdown(id)}
          >
            <MoreIcon />
            <div
              className={`dropdown-menu dropdown-menu--more ${
                showMoreDropdown === id ? 'open' : ''
              }`}
              style={{ top: 20 }}
            >
              <div
                className="dropdown-menu__list gap-3"
                onClick={() => setOpenEdit(id)}
              >
                <RenameIcon />
                Rename
              </div>
              {/* <div className="dropdown-menu__list gap-3">
                <PreviewIcon />
                Preview
              </div> */}
              {/* <div
                className="dropdown-menu__list"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoreDropdown(null);
                }}
              >
                <DownloadLink to={file_url} className="flex gap-3">
                  <DownloadIcon />
                  <span>Download</span>
                </DownloadLink>
              </div> */}
              <DownloadLink
                to={file_url}
                className="dropdown-menu__list"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoreDropdown(null);
                }}
              >
                <div className="flex gap-3">
                  <DownloadIcon />
                  <span>Download</span>
                </div>
              </DownloadLink>
              <div
                className="dropdown-menu__list gap-3"
                onClick={(e) => {
                  e.stopPropagation();
                  copyToClipboard();
                }}
              >
                <CopyIcon />
                Copy link
              </div>
              <AccessRestricted
                show={isTeamMember}
                alignment="left"
                customStyle={{
                  bottom: '-9px',
                  left: 'calc(50% - 288px)'
                }}
              >
                <div
                  className={`dropdown-menu__list ${
                    isTeamMember ? 'blocked ' : 'red-text '
                  } gap-3`}
                  onClick={(e) => {
                    if (!isTeamMember) {
                      e.stopPropagation();
                      setShowMoreDropdown(null);
                      setOpenDeleteModal(id);
                    }
                  }}
                >
                  <DeleteIcon />
                  Delete
                </div>
              </AccessRestricted>
            </div>
          </div>
        </div>
      ) : (
        <form
          style={{ marginTop: 2 }}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="off"
        >
          <div className="relative">
            <InputWithButton
              buttonLabel="Save"
              inputName="fileName"
              placeholder=""
              register={register}
              buttonClassName="btn--xs btn--copy text-xs"
              medium
              errors={errors}
              touchedFields={touchedFields}
              defaultValue={title}
              isCancelButton={true}
              cancelButtonClassName="btn--xs btn--input-cancel btn--grey text-xs"
              cancelButtonLabel="Cancel"
              onCancel={onCancel}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  onKeyEnter(getValues('fileName'));
                }
              }}
              isLoading={isLoading}
              enableLoader={true}
              style={{ paddingRight: '155px' }}
            />
          </div>
          {errors.fileName && touchedFields.fileName && (
            <Error message={errors.fileName.message} />
          )}
          {!isEmpty(error) && <Error message={error} />}
        </form>
      )}
    </>
  );
};

export default FileItem;

FileItem.propTypes = {
  title: PropTypes.string,
  index: PropTypes.number,
  setShowMoreDropdown: PropTypes.func,
  setOpenEdit: PropTypes.func,
  setOpenDeleteModal: PropTypes.func,
  showMoreDropdown: PropTypes.string,
  openEdit: PropTypes.string
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { ReactComponent as TextIcon } from 'assets/icons/icon-question-type-text.svg';
import { ReactComponent as MultipleOptionsIcon } from 'assets/icons/icon-question-type-multiple-options.svg';
import { ReactComponent as DateIcon } from 'assets/icons/icon-question-type-date.svg';
import { ReactComponent as DropdownIcon } from 'assets/icons/icon-question-type-dropdown.svg';
import { ReactComponent as ValueIcon } from 'assets/icons/icon-question-type-value.svg';
import QuestionItem from '../questionItem';
import { useDispatch } from 'react-redux';
import {
  setIsShowQuestionsToAdd,
  setIsShowQuestionForm,
  setSelectedQuestion
} from 'slices/questionSlice';
import { useParams } from 'react-router-dom';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty } from 'utils/utils';
import CHOICES from 'choices';
import ErrorMessage from 'components/errorMessage';

const AddQuestions = ({ closeQuestionType }) => {
  const dispatch = useDispatch();
  const { stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const { TEXT, DROPDOWN, DATETIME, MCQ, VALUE } = CHOICES.QuestionChoices;
  const [isSelected, setIsSelected] = useState(null);
  const [error, setError] = useState('');
  const questions = [
    {
      id: 1,
      label: CHOICES.QuestionChoices.CHOICES[TEXT],
      type: TEXT,
      icon: TextIcon
    },
    {
      id: 2,
      label: CHOICES.QuestionChoices.CHOICES[DROPDOWN],
      type: DROPDOWN,
      icon: DropdownIcon
    },
    {
      id: 3,
      label: CHOICES.QuestionChoices.CHOICES[DATETIME],
      type: DATETIME,
      icon: DateIcon
    },
    {
      id: 4,
      label: CHOICES.QuestionChoices.CHOICES[MCQ],
      type: MCQ,
      icon: MultipleOptionsIcon
    }
  ];

  if (isBox) {
    // questions.push({
    //   id: 5,
    //   label: CHOICES.QuestionChoices.CHOICES[VALUE],
    //   type: VALUE,
    //   icon: ValueIcon
    // });
  }

  const onAddQuestion = () => {
    if (isSelected) {
      dispatch(setIsShowQuestionForm(true));
      dispatch(setSelectedQuestion(isSelected));
      if (closeQuestionType) {
        closeQuestionType();
      }
    } else {
      setError('Select atleast one question');
    }
  };

  const onClick = (item) => {
    setError('');
    setIsSelected(item);
  };

  return (
    <div className="mt-8 border border-secondary border-radius-10 bg-grey-300 w-full">
      <div className="py-3 px-5 bg-white border-t-r-10 border-t-l-10">
        <h3 className="text-15 font-medium">Select your question type</h3>
      </div>
      <div className="p-5">
        {questions.map((item) => {
          return (
            <QuestionItem
              key={item.id}
              label={item.label}
              renderSvg={() => <item.icon />}
              question={item}
              onClick={onClick}
              isSelected={!isEmpty(isSelected) ? isSelected.id : null}
              id={item.id}
            />
          );
        })}
        {error && <ErrorMessage message={error} />}
        <div className="flex justify-end mt-5 gap-1">
          <CustomColorButton
            label="Cancel"
            className="btn--grey min-w-120 btn--md"
            onClick={() => {
              dispatch(setIsShowQuestionsToAdd(false));
              if (closeQuestionType) {
                closeQuestionType();
              }
            }}
          />
          <PrimaryButton
            label="Add"
            className="min-w-120 btn--md"
            onClick={onAddQuestion}
          />
        </div>
      </div>
    </div>
  );
};

export default AddQuestions;

/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { ReactComponent as IconSms } from 'assets/icons/icon-template-sms-new.svg';
import { ReactComponent as IconFb } from 'assets/icons/icon-template-fb.svg';
import { ReactComponent as IconEmail } from 'assets/icons/icon-template-email.svg';
import { ReactComponent as MoreIcon } from 'assets/icons/icon-more-round.svg';
import { ReactComponent as RenameIcon } from 'assets/icons/icon-select-rename.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/icon-select-delete.svg';
import CHOICES from 'choices';
import {
  setEmailData,
  setReplyType,
  setSubject,
  setTemplateName
} from 'slices/replyTemplate/slice';
import { useDispatch } from 'react-redux';
import { setAttachments } from 'slices/editor/slice';
import useClickOutside from 'hooks/useOutsideClick';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';

const { SMS, EMAIL } = CHOICES.ReplyTemplateChoice;

const TemplateItem = (props) => {
  const { id, setOpenEdit, replyType, title, onDeleteReplyTemplate, item } =
    props;
  const dispatch = useDispatch();

  const templateNode = useRef();
  const [showMoreDropdown, setShowMoreDropdown] = useState(null);
  useClickOutside(() => setShowMoreDropdown(null), templateNode.current);

  const { isTeamMember } = useGetUserRole();
  const onDelete = (e, id) => {
    e.stopPropagation();
    e.preventDefault();
    onDeleteReplyTemplate(id);
    setShowMoreDropdown(null);
  };
  return (
    <div
      className="border border-grey-400 border-radius-10 flex justify-between py-5 pr-5 pl-4 mb-3"
      ref={templateNode}
    >
      <div className="flex items-center gap-4" style={{ maxWidth: '95%' }}>
        {replyType === SMS ? (
          <IconSms
            style={{ minHeight: 16, minWidth: 16, maxWidth: 16, maxHeight: 16 }}
          />
        ) : replyType === EMAIL ? (
          <IconEmail
            style={{ minHeight: 16, minWidth: 16, maxWidth: 16, maxHeight: 16 }}
          />
        ) : (
          <IconFb
            style={{ minHeight: 16, minWidth: 16, maxWidth: 16, maxHeight: 16 }}
          />
        )}
        <p className="font-semibold truncate" style={{ width: '90%' }}>
          {title}
        </p>
      </div>
      <div
        className={`hover:bg-grey-90 rounded-md flex items-center justify-center cursor-pointer relative ${
          showMoreDropdown === id ? 'bg-grey-90' : ''
        }`}
        style={{ width: 24, height: 24 }}
        onClick={() => setShowMoreDropdown(id)}
      >
        <div>
          <MoreIcon />
        </div>

        <div
          className={`dropdown-menu dropdown-menu--more ${
            showMoreDropdown === id ? 'open' : ''
          }`}
          style={{ top: 30, width: 180 }}
        >
          <div
            className="dropdown-menu__list gap-3"
            onClick={(e) => {
              e.stopPropagation();
              setOpenEdit(id);
              dispatch(
                setEmailData(
                  item?.reply_type === EMAIL ? item?.body : item?.html_body
                )
              );
              dispatch(setAttachments(item?.attachments));
              dispatch(setTemplateName(item?.name));
              dispatch(setSubject(item?.subject));
              dispatch(setReplyType(item?.reply_type));
            }}
          >
            <RenameIcon />
            Edit template
          </div>
          <AccessRestricted
            show={isTeamMember}
            alignment="left"
            customStyle={{
              bottom: '-9px',
              left: 'calc(50% - 288px)'
            }}
          >
            <div
              className={`dropdown-menu__list ${
                isTeamMember ? 'blocked ' : 'red-text '
              } gap-3`}
              onClick={(e) => {
                if (!isTeamMember) {
                  onDelete(e, id);
                }
              }}
            >
              <DeleteIcon />
              Delete template
            </div>
          </AccessRestricted>
        </div>
      </div>
    </div>
  );
};

export default TemplateItem;

import React from 'react';
import fileTooLargeIcon from 'assets/icons/icon-file-too-large.svg';

const CardMovedToBin = () => {
  return (
    <div className="flex h-full justify-center items-center">
      <div>
        <div className="flex justify-center">
          <img
            src={fileTooLargeIcon}
            alt=""
            style={{ width: 32, height: 32 }}
          />
        </div>
        <p className="mt-2 font-semibold">Card moved to bin</p>
      </div>
    </div>
  );
};

export default CardMovedToBin;

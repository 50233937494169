/* eslint-disable no-unused-vars */
import React from 'react';
import Avatar from 'components/avatar';
// import Search from 'modules/channel/main/header/search';
import iconEnquiryBin from 'assets/icons/icon-enquiry-bin.svg';
import CustomColorButton from 'components/buttons/customColorButton';
import AccessRestricted from 'modules/modals/AccessRestricted';
import useGetUserRole from 'hooks/useGetUserRole';

const EnquiryBinHeader = (props) => {
  const { onClick, isHideBtn } = props;
  const { isTeamMember } = useGetUserRole();
  return (
    <div
      className="dashboard__main__header"
      style={{ padding: '20px 25px 20px 40px' }}
    >
      <div className="flex items-center dashboard__main__header__section-left">
        <Avatar
          src={iconEnquiryBin}
          imgStyle={{ width: '24px', height: '24px' }}
          style={{ width: 50, height: 50 }}
          larger={true}
          className="bg-color-800"
        />
        <div className="ml-5">
          <h3 className="text-xl font-semibold">Enquiry Bin</h3>
          <p className="text-15 font-medium text-grey-800">
            Binned enquiries are permanently deleted after 30 days
          </p>
        </div>
      </div>
      <div className="flex items-center dashboard__main__header__section-right justify-end">
        <div className="search-container">
          {/* <Search /> */}
          {/* <code className="bg-grey-400 mx-4" style={{ width: 1, height: 26 }} /> */}
          {/* {!isHideBtn && ( */}
          <AccessRestricted alignment="left" show={isTeamMember}>
            <CustomColorButton
              label="Empty Bin"
              className={
                isTeamMember ? 'btn--blocked ' : 'btn--default text-primary'
              }
              style={{ padding: '5px 20px' }}
              onClick={onClick}
            />
          </AccessRestricted>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

export default EnquiryBinHeader;

/* eslint-disable no-unused-vars */
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import Lead from '../../../lead';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import {
  updateBoxUnreadLeadStatus,
  onNewBoxCardPostClick,
  setCurrentBoxCardList
} from 'slices/boxes/slice';
import choices from 'choices';
import { getAllCounts } from 'slices/allCount/slice';

const { VIEWED, NEW } = choices.LeadStatusChoices;

const StageNewLead = (props) => {
  const dispatch = useDispatch();
  const { stageLeads, activeBoxNewLead } = useSelector((state) => state.boxes);
  const { stageId, box } = props;

  const newLeads = stageLeads[stageId]?.new_unread;
  let startIndex = stageLeads[stageId]?.reply_later;
  startIndex = Array.isArray(startIndex) ? startIndex.length : 0;

  if (isEmpty(newLeads)) {
    return <></>;
  }

  // const updateStatus = async (id) => {
  //   dispatch(setCurrentBoxCardList(NEW));
  //   if (activeBoxNewLead == id) {
  //     return;
  //   }
  //   const data = {
  //     stageId,
  //     body: {
  //       status: VIEWED,
  //       lead: id,
  //       is_current: true
  //     }
  //   };
  //   await dispatch(updateBoxUnreadLeadStatus(data));
  //   dispatch(onNewBoxCardPostClick({ id, stageId }));
  //   dispatch(getAllCounts());
  //   // dispatch(setActiveNewLead([...activeNewLead, id]));
  // };

  return (
    <>
      {newLeads.map((lead, index) => {
        return (
          <Draggable
            key={lead.id}
            draggableId={lead.id}
            index={index + startIndex}
            isDragDisabled={false}
          >
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  // onClick={() => updateStatus(lead.id)}
                >
                  <Lead
                    lead={lead}
                    box={box}
                    stageId={stageId}
                    isNew={true}
                    index={index}
                  />
                </div>
              );
            }}
          </Draggable>
        );
      })}
    </>
  );
};

export default StageNewLead;

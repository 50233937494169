import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import PrimaryButton from 'components/buttons/primary';
import TextArea from 'components/inputs/textarea';
import DropDownWrapper from 'components/dropdown/wrapper';
import { ReactComponent as IconBackArrow } from 'assets/icons/icon-back-arrow.svg';

const SelectedActionNote = (props) => {
  const {
    open,
    renderAvatar,
    placeholder,
    value,
    onBack,
    onChange,
    onSubmit,
    submitBtnText,
    isLoading
  } = props;
  const RenderAvatar = renderAvatar;

  return (
    <DropDownWrapper
      open={open}
      className="dropdown-menu--with-border mt-3"
      style={{ padding: '11px 0px 18px' }}
    >
      <section className={`${styles.unassigned}`}>
        <section
          className={`${styles.back} text-13 text-primary font-medium cursor-pointer`}
          onClick={onBack}
        >
          <IconBackArrow
            width="10px"
            height="10px"
            style={{ marginRight: '5px' }}
          />
          <span className="text-13 text-primary font-medium cursor-pointer">
            Back
          </span>
        </section>
      </section>
      <section className={`${styles.unassigned} ${styles.selected}`}>
        <div>
          <RenderAvatar />
        </div>
      </section>
      <section className={styles.notes}>
        <TextArea
          name="notes"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      </section>
      <section className={styles.footer}>
        <PrimaryButton
          className="btn--grey btn--md mr-2"
          style={{ paddingLeft: '25px', paddingRight: '25px' }}
          label="Cancel"
          onClick={onBack}
        />
        <PrimaryButton
          className="btn--md"
          style={{ paddingLeft: '25px', paddingRight: '25px' }}
          label={submitBtnText}
          onClick={onSubmit}
          isLoading={isLoading}
          enableLoader
          loaderInline
        />
      </section>
    </DropDownWrapper>
  );
};

SelectedActionNote.propTypes = {
  open: PropTypes.bool.isRequired,
  renderAvatar: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  submitBtnText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired
};

SelectedActionNote.defaultProps = {
  submitBtnText: 'Submit'
};

export default SelectedActionNote;

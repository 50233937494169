/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Quill from 'components/quill';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';

// const maxCharacters = 160;

const Messenger = ({ className, condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const errors = automationErrors[index];
  const {
    data: { raw_sms_message }
  } = condition;
  const [messageBody, setMessageBody] = useState(raw_sms_message);
  const [length, setLength] = useState(0);
  const [smsCount, setSmsCount] = useState();
  const emailBodyRef = useRef();
  const footerBodyRef = useRef();

  const updateThen = () => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.message) {
      let result = omit(errorObj[index], ['message']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    // if (length <= maxCharacters) {

    // } else {
    //   const errorObj = { ...automationErrors };
    //   const smsError = errorObj[index];
    //   dispatch(
    //     setAutomationErrors({
    //       ...errorObj,
    //       [index]: { ...smsError, message: 'Characters limit exceeded' }
    //     })
    //   );
    // }
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        message: messageBody,
        sms_count: smsCount
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  useEffect(() => {
    updateThen();
  }, [messageBody, length]);

  const onUseTemplate = (subject, body, attachments) => {
    setMessageBody(body);
  };

  return (
    <div
      className="chat-message-footer__body bg-white"
      ref={footerBodyRef}
      style={{ height: 'calc(100% - 4px)', borderRadius: 10 }}
    >
      <div className="chat-reply" style={{ border: '1px solid white' }}>
        <div
          className={`${
            errors ? 'error-editor' : ''
          } box-settings ${className}`}
          ref={emailBodyRef}
        >
          <Quill
            onChange={(data) => {
              setMessageBody(data);
            }}
            value={messageBody}
            showSendBtn={false}
            isRefetch={true}
            placeholder={'Type your message here'}
            isExpanded={true}
            hideImage={true}
            hideAttachment={true}
            hideVarible={false}
            onUseTemplate={onUseTemplate}
            showFontStyle
            enableCharacterLimit
            maxCharacters={160}
            length={length}
            setLength={setLength}
            errorMessage={
              errors?.message
                ? errors?.message.includes('provide')
                  ? errors?.message
                  : ''
                : ''
            }
            // isAutomation
            // isFbOrInsta
            setSmsCount={setSmsCount}
            showCharLimitTop
            isBoxAutomation
            isSms
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Messenger);

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Deploy from './deploy';
import WebsiteTab from './websiteTab';
const AllRoute = () => {
  return (
    <>
      <Routes>
        <Route path="/*" element={<Deploy />} />
        <Route path="/website/*" element={<WebsiteTab />} />
      </Routes>
    </>
  );
};

export default AllRoute;

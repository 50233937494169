/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as IconSuccess } from 'assets/icons/icon-success-green.svg';
import { ReactComponent as IconFailure } from 'assets/icons/icon-red-cross.svg';
import { useEffect } from 'react';
import APIRequest from 'apiRequest';

const EmailVerificationStatus = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const isSucess = location?.pathname.includes('success');
  const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

  const redirectToLogin = () => {
    setTimeout(() => {
      navigate('/login');
    }, 4000);
  };

  useEffect(() => {
    if (isSucess) {
      new APIRequest()
        .get(
          `${BASE_API_URL}/emails/check_the_latest_status_from_ses_email_identity`
        )
        .then(() => {
          redirectToLogin();
        })
        .catch(() => {
          redirectToLogin();
        });
    }
  }, [isSucess]);

  return (
    <div className="flex justify-center items-center mt-11">
      <div
        className="mb-3 flex items-center justify-center"
        style={{ maxWidth: '70%' }}
      >
        {isSucess ? (
          <div>
            <div className="flex justify-center mb-3">
              <IconSuccess />
            </div>
            <p className="font-semibold mb-3 flex justify-center">
              Email has been verified Sucessfully
            </p>
            <p className="font-semibold mb-3 flex justify-center">
              This will take up to 1 hour to reflect in your system
            </p>
            <div className="flex justify-center">
              <p className="mr-1">You will be redirected to login shortly </p>
              <p>
                if not redirected,{' '}
                <NavLink className="text-blue-900" to="/login">
                  click here
                </NavLink>{' '}
                for login
              </p>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex justify-center mb-3">
              <IconFailure />
            </div>
            <div className="font-semibold mb-3 flex justify-center">
              Email verifcation failed{' '}
            </div>
            <div className="flex justify-center">
              <p className="mr-1">You will be redirected to login shortly</p>
              <p>
                if not redirected,{' '}
                <NavLink className="text-blue-900" to="/login">
                  click here
                </NavLink>{' '}
                for login
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default EmailVerificationStatus;

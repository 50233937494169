/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import InputField from 'components/inputs/textField';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import { ReactComponent as SearchIcon } from 'assets/icons/icon-search-grey.svg';
import { ReactComponent as SelectIcon } from 'assets/icons/icon-arrow-down.svg';

const MultiSelect = (props) => {
  const {
    register,
    setValue,
    selectedOptions,
    options,
    name,
    value,
    inputClassName,
    placeholder,
    onChange,
    onBlur,
    renderIcon,
    showGreenBorder,
    dropDownClassName,
    selectedIconStyle,
    containerStyle,
    inputStyle,
    showdropdownIcon,
    readOnly,
    extraOption,
    labelDividerText,
    isSearchInsideDropDown,
    labelDividerTextClass,
    hideInput,
    onMenuToggle,
    labelKey,
    valueKey,
    renderChildren,
    ...rest
  } = props;

  const nodeRef = useRef();
  const [open, setOpen] = useState(false);
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [filteredOptions, setFilteredOptions] = useState(options);
  const [dropDownSerchValue, setDropDownSerchValue] = useState();

  const [inputValue, setInputValue] = useState(
    !isSearchInsideDropDown ? value : ''
  );

  useEffect(() => {
    if (onMenuToggle) onMenuToggle(open);
  }, [open]);

  useClickOutside(() => {
    setOpen(false);
  }, nodeRef.current);

  const toggle = () => {
    setOpen(!open);
  };

  const onSearch = (e) => {
    const { value } = e.target;
    if (!isSearchInsideDropDown) {
      setInputValue(value);
    } else {
      setDropDownSerchValue(value);
    }

    setFilteredOptions(
      options.filter((option) =>
        option[labelKey].toLowerCase().includes(value.toLowerCase())
      )
    );
    if (setValue) {
      setValue(name, value, { shouldTouch: true });
    }
  };

  const checkIfAlreadySelected = (option) => {
    const isOptionSelected = selectedOptions.find(
      (opt) => opt[valueKey] === option[valueKey]
    );
    if (isOptionSelected) return true;
    return false;
  };

  const isAnyChannelSelected = (selectedOptions) => {
    return selectedOptions.find((opt) => opt[valueKey] === 'any_channel');
  };

  const onOptionSelect = (option) => {
    if (option[valueKey] === 'any_channel') {
      onChange([option]);
      toggle();
      return;
    }
    if (isAnyChannelSelected(selectedOptions)) {
      onChange([option]);
      return;
    }
    if (checkIfAlreadySelected(option)) {
      const updatedSelectedOptions = selectedOptions.filter(
        (opt) => opt[valueKey] !== option[valueKey]
      );
      onChange(updatedSelectedOptions);
    } else {
      onChange([...selectedOptions, option]);
    }
  };

  const isSelected = (option) => {
    if (checkIfAlreadySelected(option)) return 'selected';
    return '';
  };

  const getSelectedList = (selectedOptions) => {
    if (isEmpty(selectedOptions)) return '';
    const options = selectedOptions.map((option) => {
      return option[labelKey];
    });
    return options.join(',');
  };

  return (
    <div
      data-testid="search-multi-container"
      className="relative"
      onClick={!isSearchInsideDropDown ? toggle : () => {}}
      ref={nodeRef}
      style={containerStyle}
    >
      {renderChildren && renderChildren(toggle)}
      <InputField
        data-testid="search-input-multi"
        name={name}
        className={`input-field--select ${inputClassName} ${
          hideInput ? 'hidden' : ''
        }`}
        placeholder={placeholder}
        value={
          !isEmpty(selectedOptions)
            ? getSelectedList(selectedOptions)
            : dropDownSerchValue
        }
        onChange={onSearch}
        onBlur={onBlur}
        showSuccessBorder={showGreenBorder}
        selectedIconStyle={selectedIconStyle}
        style={selectedIcon ? inputStyle : {}}
        register={register}
        readOnly={readOnly}
        onClick={toggle}
        disabled={!isEmpty(selectedOptions)}
        {...rest}
      />
      {showdropdownIcon && (
        <div className="input-select-svg-dropdown">
          <SelectIcon />
        </div>
      )}
      <div
        data-testid="select-dropdown-menu"
        className={`dropdown-menu ${open ? 'open' : ''}
          ${dropDownClassName ? dropDownClassName : ''}
        `}
        onBlur={onBlur}
        style={{ maxHeight: '215px' }}
      >
        {isSearchInsideDropDown && (
          <div className="mb-2" style={{ padding: '0px 8px' }}>
            <InputField
              data-testid="search-input"
              name={'search'}
              className={`input-field--xs-34 input-field--responsive-xs-34`}
              placeholder={'Search'}
              value={dropDownSerchValue}
              onChange={onSearch}
              selectedIconStyle={selectedIconStyle}
              renderIcon={() => (
                <div
                  className="input-svg-wrapper"
                  style={{ top: '-6px', left: '-4px' }}
                >
                  <SearchIcon />
                </div>
              )}
              style={{ paddingLeft: '20px !important' }}
            />
          </div>
        )}
        <div className="overflow-y-auto" style={{ height: '135px' }}>
          {extraOption && (
            <div
              className={`dropdown-menu__list ${isSelected(extraOption)}`}
              onClick={(e) => {
                e.stopPropagation();
                onOptionSelect(extraOption);
              }}
            >
              {extraOption[labelKey]}
            </div>
          )}
          {labelDividerText && (
            <span
              className={` text-xs font-medium text-primary ${labelDividerTextClass}`}
              style={{ padding: '9px 12px', marginBottom: '10px' }}
            >
              {labelDividerText}
            </span>
          )}
          {isEmpty(filteredOptions) && (
            <div className="text-sm flex justify-center w-full mt-4">
              <p>No options available</p>
            </div>
          )}
          {filteredOptions.map((option, idx) => {
            return (
              <div
                key={idx}
                className={`dropdown-menu__list ${isSelected(option)} mt-2`}
                onClick={(e) => {
                  e.stopPropagation();
                  onOptionSelect(option);
                }}
              >
                {renderIcon && <div className="mr-3">{renderIcon(option)}</div>}
                {option[labelKey]}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

MultiSelect.defaultProps = {
  labelKey: 'label',
  valueKey: 'value',
  showdropdownIcon: true
};

export default MultiSelect;

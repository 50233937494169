import checkmark from 'assets/icons/checkmark.svg';
import { setShowSubscriptionModal } from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';

const UpgradePlanTopbar = (props) => {
  const { text } = props;
  const dispatch = useDispatch();
  return (
    <div
      className="upgrade-plan-topbar cursor-pointer"
      onClick={() => dispatch(setShowSubscriptionModal(true))}
    >
      <div className="upgrade-plan-topbar__container w-full text-xs">
        <img
          src={checkmark}
          alt="checkmark"
          className="w-[15px] h-[15px] mr-2"
        />
        {text}
      </div>
    </div>
  );
};

export default UpgradePlanTopbar;

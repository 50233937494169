import {
  deleteSingleFile,
  getFileData,
  updloadFile
} from 'slices/ai-assistant/fileUpload/slice';
import BackArrow from '../../components/backArrow';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isFileValid } from './FileValidation';
import { useEffect, useState } from 'react';
import swal from 'sweetalert';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSnackbar } from 'components/Snackbar';
import { formatDateTime } from 'utils/dateFormat';
import trashIcon from 'assets/icons/icon-trash-grey-700.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import InfoIconComponent from '../../components/InfoIcon';

const Files = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { assistant_id } = useParams();
  const { currentVersionId } = useSelector((state) => state.assistant);
  const { fileData } = useSelector((state) => state.fileData);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const dispatch = useDispatch();
  const id = useSelector((state) => state.myInfo.myInformation.id);
  const [deleteId, setDeleteId] = useState(null);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const fileUpload = (file) => {
    const checkValidFile = isFileValid(file);
    if (checkValidFile.isValid) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      formData.append('edited_by_id', id);
      formData.append('version_id', currentVersionId);
      const payload = { data: formData, assistant_id: assistant_id };
      dispatch(updloadFile(payload, successCb, failedCb));
    } else {
      swal({
        title: 'Error',
        text: checkValidFile.message,
        icon: 'error',
        button: {
          text: 'Ok',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      });
      setIsLoading(false);
    }
  };

  const successCb = () => {
    setIsLoading(false);
    openSuccessSnackBar('File uploaded');
  };

  const failedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('File upload failed');
  };

  const callBack = () => {
    setIsLoading(false);
  };

  const handleFileChange = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    fileUpload(file);
    e.target.value = '';
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length) fileUpload(files[0]);
  };

  useEffect(() => {
    dispatch(getFileData(assistant_id, callBack));
  }, []);

  const handleDeletePopup = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  const deleteFailedCb = () => {
    setShowDelete(false);
    setDeleteLoading(false);
    openErrorSnackBar('Deletion failed');
  };

  const deleteSuccessCb = () => {
    setShowDelete(false);
    setDeleteLoading(false);
    openSuccessSnackBar('Deleted');
  };

  const handleDeleteFile = () => {
    setDeleteLoading(true);
    dispatch(deleteSingleFile(deleteId, deleteSuccessCb, deleteFailedCb));
  };

  return (
    <div>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <BackArrow />
      <div style={{ padding: '28px 0' }}>
        <div
          className="border-b border-grey-400"
          style={{ padding: '0 28px 30px' }}
        >
          <div
            className="file-upload-container"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <h4 className="text-sm font-semibold letterspacing24em">
              Click Upload or Drag and Drop
            </h4>
            <p className="text-grey-800 text-xs letterspacing24em mt-2">
              Max file size{' '}
              <span className="text-grey-900 text-xs font-medium letterspacing08em">
                100MB
              </span>
            </p>
            <div className="flex items-center justify-center mt-5">
              <input
                onChange={handleFileChange}
                accept=".pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx"
                type="file"
                id="uploadMultiple"
                className="hidden"
              />
              <label
                htmlFor="uploadMultiple"
                className="btn btn--blue-outline btn--h30 min-w-120 cursor-pointer"
              >
                Upload
              </label>
            </div>
            <div className="text-grey-800 text-xs mt-5">
              Supported file types (PDF, Word, PPT, Excel)
            </div>
          </div>
        </div>
        {fileData.length ? (
          <div className="mt-9" style={{ padding: '0 28px 30px' }}>
            <h3 className="text-lg font-semibold">Learned ✨</h3>
            <p className="text-grey-800 mt-3">
              Click on a file name to download and view
            </p>

            <table className="table-basic-info mt-8">
              <thead>
                <tr>
                  <td>Files ({fileData.length})</td>
                  <td
                    style={{ minWidth: '130px' }}
                    className="text-center flex justify-center"
                  >
                    <span>Times used</span>
                    <InfoIconComponent
                      tooltipText="Times used"
                      tooltipStyle={{ top: '-7px' }}
                      infoIconStyle={{
                        marginTop: '5.5px',
                        marginLeft: '7px'
                      }}
                    />
                  </td>
                  <td style={{ minWidth: '120px' }} className="text-center">
                    Last updated
                  </td>
                  <td></td>
                </tr>
              </thead>

              <tbody>
                {fileData?.map((file) => (
                  <tr key={file.id}>
                    <td
                      style={{
                        width: '73%',
                        maxWidth: '73%'
                      }}
                    >
                      <a href={file.s3_url} className="link-text">
                        {file.name}
                      </a>{' '}
                      {!file.is_embedded && (
                        <span style={{ color: '#4C5254' }}>
                          ( learning... )
                        </span>
                      )}
                    </td>
                    <td className="text-center">{file.times_used}</td>
                    <td className="text-center">
                      {formatDateTime(file.last_edited_on, 'mmm dd, yyyy')}
                    </td>
                    <td onClick={() => handleDeletePopup(file.id)}>
                      <img
                        className="cursor-pointer"
                        src={trashIcon}
                        alt="delete-icon"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <></>
        )}
      </div>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          handleDeleteFile();
        }}
        title="Are you sure you want to delete?"
        description="Once a file is deleted, it cannot be recovered"
        isLoading={deleteLoading}
      />
    </div>
  );
};

export default Files;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllAnswer,
  getQuestionsList,
  setIsShowQuestionsToAdd
} from 'slices/questionSlice';
import { isEmpty } from 'utils/utils';
import AddQuestions from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/component/addQuestions';
import ContactDetails from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/component/contactDetails';
import QuestionsAdded from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/module/questionsAdded';
import QuestionsForm from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/module/questionsForm';
import EmptyState from 'modules/enquirybox/ebox/module/box/conversation/contactModal/contact/module/questionsForm/emptyState';
import { getLeadName } from 'modules/enquirybox/helper/lead';

const Contact = ({ isSms }) => {
  const { cardId, box_id } = useParams();
  const dispatch = useDispatch();
  const { questionsData, isShowQuestionsToAdd, isShowQuestionForm } =
    useSelector((state) => state.question);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    dispatch(
      getQuestionsList({ subOrgId: subOrganizationId, query: { box: box_id } })
    );
    dispatch(getAllAnswer({ leadId: cardId }));
  }, []);

  const onAddQuestion = () => {
    dispatch(setIsShowQuestionsToAdd(true));
  };

  return (
    <div className="contacts-tab">
      <ContactDetails
        avatarName={activeLead?.name || activeLead?.first_name}
        name={getLeadName(activeLead)}
        email={activeLead?.email}
        phone={activeLead?.phone_number}
        src={activeLead?.customer_avatar}
        isSms={isSms}
      />
      {isEmpty(questionsData)
        ? !isShowQuestionsToAdd &&
          !isShowQuestionForm && <EmptyState onClick={onAddQuestion} />
        : !isShowQuestionsToAdd &&
          !isShowQuestionForm && <QuestionsAdded data={questionsData} />}
      {isShowQuestionsToAdd && !isShowQuestionForm && <AddQuestions />}
      {isShowQuestionForm && <QuestionsForm />}
    </div>
  );
};
export default Contact;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import SetUpVideo from 'components/setUpVideo';
import React, { useEffect, useState } from 'react';
import { ReactComponent as IconDownload } from 'assets/icons/icon-new-download.svg';
import { ReactComponent as IconUpload } from 'assets/icons/icon-upload.svg';
import { ReactComponent as IconRollBack } from 'assets/icons/icon-rollback.svg';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import OnDownloadModal from './modals/onDownloadModal';
import ContactModalUpload from './modals/modalUpload';
import { useDispatch, useSelector } from 'react-redux';
import {
  exportCsv,
  getAllUploadedLeadsCount,
  getFileUploadHistory,
  revertLead,
  setUploadedFileHistory
} from 'slices/boxSettings/uploadContactsSlice';
import { useParams } from 'react-router-dom';
import MappingFieldsModal from 'modules/modals/uploadModelMapping';
import MappedFeildsStatus from './modals/mappedFeildsStatus';
import { isEmpty } from 'utils/utils';
import moment from 'moment/moment';
import RevertConfirmation from './modals/revertConfirmation';
import { useSnackbar } from 'components/Snackbar';
import useGetUserRole from 'hooks/useGetUserRole';
import { getAllCounts } from 'slices/allCount/slice';
import AccessRestricted from 'modules/modals/AccessRestricted';
import GuideLink from 'components/guideLink';

const BoxContacs = () => {
  const { isAdmin, isSuperUser } = useGetUserRole();
  const [showOnSucessContactDownloadModal, setShowOnSucessDownloadModal] =
    useState(false);
  const [showUploadContactModal, setShowContactModal] = useState(false);
  const [showMapStatusModal, setShowMapStatusModal] = useState(false);
  const [showMapFields, setShowMapFields] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showConfirmationPopupForRevert, setShowConfirmationPopupForRevert] =
    useState(false);
  const [revertedData, setRevertedData] = useState();
  const [isRevertLoading, setIsRevertLoading] = useState(false);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const dispatch = useDispatch();
  const { box_id } = useParams();
  const [uploadedCount, setUploadedCount] = useState(0);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const {
    uploadedLeadsCount,
    mappingFieldsForCsvMapping,
    mappingFieldsFromCsv,
    uploadedFilesHistory
  } = useSelector((state) => state.uploadContactsSettings);

  const [isLoading, setIsLoading] = useState(false);

  const { isTeamMember } = useGetUserRole();
  const onSuccessDownloadCsv = () => {
    setShowOnSucessDownloadModal(true);
    setIsLoading(false);
  };

  const downloaCsvFileTroughMail = () => {
    setIsLoading(true);
    const payload = {
      box_id: box_id,
      subOrgId: subOrganizationId
    };
    dispatch(exportCsv(payload, onSuccessDownloadCsv));
  };

  useEffect(() => {
    dispatch(getAllUploadedLeadsCount({ box_id: box_id }));
  }, [box_id]);

  const onCloseMappingModal = () => {
    if (!isEmpty(uploadedFiles)) {
      dispatch(
        revertLead({ id: uploadedFiles[0]?.id }, () => {
          setUploadedFiles([]);
          setShowMapFields(false);
        })
      );
    } else {
      setShowMapFields(false);
    }
  };

  useEffect(() => {
    if (!isEmpty(box_id)) {
      dispatch(getFileUploadHistory({ box_id: box_id }));
    }
  }, [box_id]);

  const handleRevert = (data) => {
    setIsRevertLoading(true);
    dispatch(revertLead({ id: data?.id }, onRevertSucess, onRevertFailure));
  };

  const onRevertSucess = () => {
    dispatch(getFileUploadHistory({ box_id: box_id }));
    setShowConfirmationPopupForRevert(false);
    const updatedFileHistory = uploadedFilesHistory.filter((fileData) => {
      return fileData.id !== revertedData.id;
    });
    dispatch(setUploadedFileHistory(updatedFileHistory));
    dispatch(getAllUploadedLeadsCount({ box_id: box_id }));
    openSuccessSnackBar('Success');
    setIsRevertLoading(false);
    dispatch(getAllCounts());
  };

  const onRevertFailure = () => {
    setIsRevertLoading(false);
    openErrorSnackBar('Failed Try again');
  };

  return (
    <div>
      <OnDownloadModal
        open={showOnSucessContactDownloadModal}
        setOpen={setShowOnSucessDownloadModal}
      />
      <ContactModalUpload
        uploadedFiles={uploadedFiles}
        setUploadedFiles={setUploadedFiles}
        open={showUploadContactModal}
        setOpen={setShowContactModal}
        setShowMapFields={setShowMapFields}
        setUploadedCount={setUploadedCount}
      />
      {showMapFields && (
        <MappingFieldsModal
          showMapFields={showMapFields}
          onClose={onCloseMappingModal}
          setShowMapFields={setShowMapFields}
          setUploadedFiles={setUploadedFiles}
          leftSideFields={mappingFieldsForCsvMapping}
          rightSideFields={mappingFieldsFromCsv}
          leftSideFieldsHeadder="Enquirybox questions"
          rightSideFieldsHeadder="Imported fields"
          submitBtnLabel="Finish upload"
          submitBtnClassName="btn btn--error"
          setShowMapStatusModal={setShowMapStatusModal}
          boxId={box_id}
          uploadedCount={uploadedCount}
          setUploadedCount={setUploadedCount}
        />
      )}
      <MappedFeildsStatus
        open={showMapStatusModal}
        setOpen={setShowMapStatusModal}
      />

      <RevertConfirmation
        setOpen={setShowConfirmationPopupForRevert}
        open={showConfirmationPopupForRevert}
        onConfirm={handleRevert}
        revertedData={revertedData}
        isLoading={isRevertLoading}
      />

      <div className="">
        <h2 className="text-2xl font-semibold text-center">Box contacts</h2>
        <p className="text-grey-800 text-sm text-center mt-3 px-16">
          Download or upload contacts
        </p>
        <div className="mt-8 mx-auto">
          {/* <SetUpVideo
            src="https://player.vimeo.com/video/817382111?h=341d462675&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
            articleLink="https://help.enquirybox.io/en/articles/7228593-box-contacts"
          /> */}
          <GuideLink url="https://help.enquirybox.io/en/articles/7228593-box-contacts" />
        </div>
        <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
          <h3 className="font-semibold">
            Download all box contacts {`(${uploadedLeadsCount?.count})`}
          </h3>
          <AccessRestricted
            show={isTeamMember}
            alignment="left"
            customStyle={{
              bottom: '-12px',
              left: 'calc(50% - 302px)'
            }}
          >
            <CustomColorButtonWithLogo
              className={`${
                isTeamMember ? 'btn--blocked' : 'btn--primary'
              } btn--sm text-13 w-[147px]`}
              render={() => <IconDownload className="mr-1" />}
              label="Download"
              isLoading={isLoading}
              loaderInline={true}
              disabled={uploadedLeadsCount?.count <= 0}
              onClick={() => {
                if (!isTeamMember) {
                  downloaCsvFileTroughMail();
                }
              }}
            />
          </AccessRestricted>
        </div>
        <div className="flex justify-between border border-grey-400 border-radius-14 items-center mt-10 px-6 py-4">
          <h3 className="font-semibold">Upload new contacts</h3>
          <CustomColorButtonWithLogo
            className="btn btn--primary btn--sm text-13 w-[147px] "
            render={() => <IconUpload className="mr-1" />}
            label="Upload"
            onClick={() => setShowContactModal(true)}
          />
        </div>
        {!isEmpty(uploadedFilesHistory) && (
          <div className="border border-grey-400 border-radius-14 bg-white mt-10 py-5">
            <h3 className="text-lg font-semibold ml-6">Upload history</h3>
            <div className="px-4 w-full">
              <table className="tabel-upload-history">
                <thead>
                  <tr className="bg-grey-200 rounded-md">
                    <th>File</th>
                    <th>Contacts</th>
                    <th>Date</th>
                    <th style={{ textAlign: 'right' }}>Rollback</th>
                  </tr>
                </thead>
                {!isEmpty(uploadedFilesHistory) &&
                  uploadedFilesHistory.map((lead, index) => {
                    return (
                      <tbody key={index}>
                        <tr
                          style={{
                            border: 0,
                            borderRadius: 0,
                            backgroundColor: 'transparent'
                          }}
                        >
                          <td
                            style={{
                              paddingTop: 4,
                              paddingBottom: 4,
                              border: 0,
                              borderRadius: 0
                            }}
                          ></td>
                        </tr>
                        <tr>
                          <td className="break-all">{lead?.file_name}</td>
                          <td>{lead?.no_of_leads}</td>
                          <td>
                            {moment(lead?.created_at).format('MMM DD YYYY')}
                          </td>
                          <td>
                            <div
                              className="flex items-center justify-center bg-white ml-auto cursor-pointer"
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: 6
                              }}
                              onClick={() => {
                                setShowConfirmationPopupForRevert(true);
                                setRevertedData({
                                  id: lead?.id,
                                  count: lead?.no_of_leads
                                });
                              }}
                            >
                              <IconRollBack />
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    );
                  })}
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoxContacs;

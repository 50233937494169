/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react';
import {
  useNavigate,
  useParams,
  Navigate,
  useLocation
} from 'react-router-dom';
import { io } from 'socket.io-client';
import { useDispatch, useSelector } from 'react-redux';

import {
  getLeadConversation,
  fetchReplyById,
  getLeadData,
  setActiveTab
} from 'slices/conversationSlice';
import ImageModalContainer from 'modules/modals/ImageModal';
import ChatFooterInputBox from '../../footer/chatFooter/main';
import ConversationHeader from 'modules/enquirybox/ebox/module/box/conversation/header';
import Actions from 'modules/enquirybox/ebox/module/box/conversation/actions/conversationActions';
import {
  changeLeadStatus,
  setShowContactModal,
  setShowInboxActions
} from 'slices/eboxLead';
import { isEmpty, timeFormatter } from 'utils/utils';
import { Loader } from 'components/loader';
import PropTypes from 'prop-types';
import AvatarContainer from '../../components/avatarContainer';
import EmailBody from '../../email/new/index';
import choices from 'choices';
import NoteContainer from '../../components/noteContainer';
import TaskContainer from '../../components/taskContainer';
import pdfIcon from 'assets/icons/icon-file-upload-pdf.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';
import { route } from 'utils/route';
// import { SUPPORTED_FORMATS } from 'utils/file';
import { animateScroll } from 'react-scroll';
import { ReactComponent as AutomateIcon } from 'assets/icons/icon-automated-email-icon.svg';
import TooltipContainer from '../../components/tooltipContainer';
import DownloadLink from 'components/downloadLink';
import WarningAlert from 'components/alerts/warningAlert';
import { ReactComponent as FileTooLargeIcon } from 'assets/icons/icon-file-too-large.svg';
import { setIsReplyLoading, setShowErrorModal } from 'slices/facebook/slice';
import SmsMessageContainer from '../component/smsMessageContainer';
import moment from 'moment';
import queryString from 'query-string';
import { TASK } from 'utils/constants';
import { handleScroll } from 'modules/enquirybox/helper/scrollConversation';
import { setFetchConversation } from 'slices/conversationSlice';
const {
  EMAIL_REPLY,
  NORMAL_REPLY,
  NOTE_REPLY,
  TASK_REPLY,
  MOVED_TO_BIN,
  SMS_REPLY,
  IG_STORY_MENTION,
  IG_STORY_REPLY
} = choices.LeadReplyChoice;

const FBInstaConversation = (props) => {
  const { isFacebook, isInsta, isBin } = props;
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const { cardId, stageId, box_type, box_id } = useParams();
  // const navigate = useNavigate();
  const messageRef = useRef();
  const isBox = !isEmpty(stageId);
  // const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(true);
  // const [showDelete, setShowDelete] = useState(false);
  const { showImagePreview } = useSelector((state) => state.app);
  const { conversation, activeLead, showCardNotFound, fetchConversation } =
    useSelector((state) => state.leadConversation);
  const { showInboxActions } = useSelector((state) => state.leads);
  const { replyError, showErrorModal } = useSelector((state) => state.fb);

  useEffect(() => {
    if (queryParams && queryParams?.activeTab === 'task') {
      dispatch(setShowContactModal(true));
      dispatch(setActiveTab(TASK));
    }
  }, [queryParams]);

  const loadLeadConversation = () => {
    setIsLoading(true);
    dispatch(getLeadConversation({ leadId: cardId }, callback));
  };

  const callback = () => {
    setIsLoading(false);
  };

  const scrollDown = () => {
    animateScroll.scrollToBottom({
      containerId: 'Conversation',
      smooth: true,
      duration: 0
    });
  };

  const loadConversationCallback = () => {
    scrollDown();
  };

  useEffect(() => {
    if (!showInboxActions) {
      scrollDown();
    }
  }, [showInboxActions]);

  const onReplySentSuccess = () => {
    dispatch(getLeadConversation({ leadId: cardId }, loadConversationCallback));
    dispatch(getLeadData({ leadId: cardId }));
  };

  const scrollToBottom = () => {
    // const temp = messageRef.current;
    // if (temp) {
    //   temp.scrollTop = temp.scrollHeight;
    // }
  };

  useEffect(() => {
    if (fetchConversation) {
      dispatch(
        getLeadConversation({ leadId: cardId }, () => {
          setTimeout(() => {
            handleScroll(conversation);
          }, 300);
          dispatch(setFetchConversation(false));
        })
      );
    }
    return () => {
      dispatch(setFetchConversation(false));
    };
  }, [fetchConversation]);

  useEffect(() => {
    if (!cardId) return;

    // const url = process.env.REACT_APP_FB_WEBSOCKET;
    // const transportOptions = {
    //   transportOptions: {
    //     polling: {
    //       extraHeaders: {
    //         lead_id: cardId
    //       }
    //     }
    //   }
    // };
    // const socket = io(url, transportOptions);

    // function onConnect() {
    //   console.log('Socket connected');
    // }

    // function onDisconnect() {
    //   console.log('Socket disconnected');
    // }

    // function onNewReply(msg) {
    //   if (msg?.reply_id) dispatch(fetchReplyById({ replyId: msg.reply_id }));
    // }

    // socket.on('connect', onConnect);
    // socket.on('disconnect', onDisconnect);
    // socket.on('new_reply_come', onNewReply);

    // return () => {
    //   socket.off('connect', onConnect);
    //   socket.off('disconnect', onDisconnect);
    //   socket.off('new_reply_come', onNewReply);
    //   socket.disconnect();
    // };
  }, [cardId]);

  // useEffect(() => {
  //   if (!isLoading) {
  //     setTimeout(() => scrollToBottom(), 0);
  //   }
  // }, [cardId, isLoading]);

  useEffect(() => {
    scrollToBottom();
  }, [conversation?.results?.length]);

  const resetReplyStore = () => {
    dispatch(setShowInboxActions(true));
  };

  useEffect(() => {
    loadLeadConversation();
    resetReplyStore();
  }, []);

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        handleScroll(conversation);
      }, 300);
    }
  }, [isLoading, conversation.results]);

  const convertMessage = (message) => {
    if (message) {
      return message.replaceAll(/\n/g, '<br />');
    }
    return '';
  };

  const MessageContainer = ({
    item,
    isReplyRecieved,
    isReplySent,
    index,
    conversation
  }) => {
    const MessageTime = ({
      time,
      isShowReplyRecievedAvatar,
      isShowReplySentAvatar
    }) => {
      return (
        <div
          className={`${isReplySent ? 'flex justify-end items-center' : ''}`}
        >
          {item.sent_by_automation && (
            <div
              style={{ marginTop: 4 }}
              className="flex items-center gap-2 mr-2"
            >
              <AutomateIcon style={{ marginTop: 2 }} />
              <div className="text-xs text-grey-700">Automated Message</div>
              <code
                style={{
                  background: '#C4C2C0',
                  width: '3.5px',
                  height: '3.5px',
                  borderRadius: '50%',
                  marginTop: '0px'
                }}
              ></code>
            </div>
          )}
          <div
            className={`text-xs text-grey-700  ${
              isReplyRecieved && !isShowReplyRecievedAvatar && index !== 0
                ? 'ml-8'
                : ''
            } ${
              isReplySent && !isShowReplySentAvatar && index !== 0 ? 'mr-8' : ''
            }`}
            style={
              isReplySent
                ? {
                    marginTop: 4,
                    display: 'flex',
                    justifyContent: 'flex-end'
                  }
                : {}
            }
          >
            {time}
          </div>
        </div>
      );
    };

    const Message = ({
      message,
      isShowReplyRecievedAvatar,
      isShowReplySentAvatar,
      index
    }) => (
      <div className={`flex ${isReplySent ? 'justify-end' : ''}`}>
        <div
          className={`inline-block text-base-1 text-grey-900 border border-grey-400 ${
            isReplyRecieved
              ? 'bg-grey-90'
              : item.sent_by_automation
              ? 'bg-automation'
              : isReplySent
              ? 'bg-automation'
              : 'bg-green-200'
          } ${
            isReplyRecieved && !isShowReplyRecievedAvatar && index !== 0
              ? 'ml-8'
              : ''
          } ${
            isReplySent && !isShowReplySentAvatar && index !== 0 ? 'mr-8' : ''
          }`}
          style={{
            borderRadius: 10,
            paddingTop: 6,
            paddingBottom: 6,
            paddingLeft: 15,
            paddingRight: 15
          }}
        >
          {item.reply_type === IG_STORY_MENTION && (
            <div className="pre mb-2">{`you're mentioned in the story`}</div>
          )}
          {item.reply_type === IG_STORY_REPLY && (
            <div className="pre  mb-2">{`you received a reply for your story`}</div>
          )}
          {message?.includes('<script') ? (
            <div className="pre">{message}</div>
          ) : (
            <div
              className="pre"
              dangerouslySetInnerHTML={{ __html: message || '' }}
            />
          )}
          {!isEmpty(item.attachments) &&
            item.attachments.map((attachment, index) => {
              if (
                attachment.file_url.includes('.pdf') ||
                attachment.file_url.includes('.docx') ||
                attachment.file_url.includes('.doc') ||
                attachment.file_url.includes('.csv') ||
                attachment.file_url.includes('.ppt') ||
                attachment.file_url.includes('.xlsx') ||
                attachment.file_url.includes('.xml') ||
                attachment.file_url.includes('.xls') ||
                attachment.file_url.includes('.txt')
              ) {
                return (
                  <div
                    className="cursor-pointer mt-2"
                    key={index}
                    style={{ width: 120 }}
                  >
                    <DownloadLink
                      to={attachment.file_url}
                      className="flex gap-2 items-center"
                    >
                      <img src={pdfIcon} alt="" />
                      <span className="font-semibold width-fill-available">
                        File
                      </span>
                    </DownloadLink>
                  </div>
                );
              }
              {
                if (attachment.mime_type === 'video') {
                  return (
                    <div
                      className="cursor-pointer"
                      key={index}
                      style={{ width: 120 }}
                    >
                      <DownloadLink
                        to={attachment.file_url}
                        className="flex gap-2 items-center"
                        target="_blank"
                      >
                        <span className="font-semibold width-fill-available">
                          View video
                        </span>
                      </DownloadLink>
                    </div>
                  );
                }
              }
              if (
                [IG_STORY_MENTION, IG_STORY_REPLY].includes(item.reply_type)
              ) {
                return (
                  <div
                    className="cursor-pointer mt-2"
                    key={index}
                    style={{ width: 120 }}
                  >
                    <DownloadLink
                      to={attachment.file_url}
                      className="flex gap-2 items-center"
                      target="_blank"
                    >
                      {/* <img src={pdfIcon} alt="" /> */}
                      <span className="font-semibold width-fill-available">
                        View story
                      </span>
                    </DownloadLink>
                  </div>
                );
              }
              return (
                <img
                  alt=""
                  src={attachment.file_url}
                  key={index}
                  className="mr-2"
                  style={{
                    objectFit: 'contain',
                    maxHeight: '300px'
                  }}
                />
              );
            })}
        </div>
      </div>
    );
    const isShowReplyRecievedAvatar =
      isReplyRecieved &&
      !isReplySent &&
      moment(conversation[index]?.created_at).format('mm') !==
        moment(conversation[index - 1]?.created_at).format('mm')
        ? true
        : isReplyRecieved &&
          !isReplySent &&
          conversation[index]?.is_reply_received !==
            conversation[index - 1]?.is_reply_received
        ? true
        : false;

    const isShowReplySentAvatar =
      isReplySent &&
      index !== conversation?.length - 1 &&
      conversation[index + 1]?.reply_type !== NORMAL_REPLY &&
      moment(conversation[index]?.created_at).format('mm') !==
        moment(conversation[index - 1]?.created_at).format('mm')
        ? true
        : isReplySent &&
          moment(conversation[index]?.created_at).format('mm') !==
            moment(conversation[index - 1]?.created_at).format('mm') &&
          !isReplyRecieved &&
          index !== 0
        ? true
        : isReplySent &&
          index !== 0 &&
          conversation[index]?.is_reply_sent !==
            conversation[index - 1]?.is_reply_sent
        ? true
        : false;

    const isShowLastReplyTime =
      conversation[index + 1]?.reply_type !== NORMAL_REPLY
        ? true
        : index !== conversation.length - 1 &&
          moment(conversation[index]?.created_at).format('mm') !==
            moment(conversation[index + 1]?.created_at).format('mm')
        ? true
        : index !== conversation.length - 1 &&
          conversation[index]?.is_reply_sent !==
            conversation[index + 1]?.is_reply_sent
        ? true
        : false;

    return (
      <div
        className={`chat-message-list chat-message-list--sm flex ${
          isReplySent ? 'chat-message-list--sender ml-10-percent' : ''
        } ${isShowReplyRecievedAvatar}`}
        style={{
          marginBottom:
            showInboxActions && index === conversation?.length - 1
              ? '80px'
              : isShowLastReplyTime
              ? '25px'
              : '6px',
          maxWidth: 'calc(100% - 75px)'
        }}
      >
        {index === 0 && (
          <TooltipContainer
            position={`${isReplySent ? 'left' : 'right'}`}
            automatedMessage={
              item?.sent_by_automation ? 'Automated Message' : ''
            }
            user={isReplySent ? item?.message_sent_by : item.lead}
          />
        )}
        {isShowReplyRecievedAvatar && index !== 0 && (
          <TooltipContainer user={activeLead} position="right" />
        )}
        {isShowReplySentAvatar && (
          <TooltipContainer
            automatedMessage={
              item?.sent_by_automation ? 'Automated Message' : ''
            }
            user={item?.message_sent_by}
          />
        )}
        <div className="flex">
          <div
            className="w-full"
            style={
              isReplyRecieved || index === 0
                ? { marginLeft: 6 }
                : isReplySent
                ? { marginRight: 6 }
                : {}
            }
          >
            <Message
              message={item.message}
              isShowReplyRecievedAvatar={isShowReplyRecievedAvatar}
              isShowReplySentAvatar={isShowReplySentAvatar}
              index={index}
            />
            {index === conversation.length - 1 && (
              <MessageTime
                time={timeFormatter(item?.created_at)}
                isShowReplyRecievedAvatar={isShowReplyRecievedAvatar}
                isShowReplySentAvatar={isShowReplySentAvatar}
              />
            )}
            {isShowLastReplyTime && index !== conversation.length - 1 && (
              <MessageTime
                time={timeFormatter(item?.created_at)}
                isShowReplyRecievedAvatar={isShowReplyRecievedAvatar}
                isShowReplySentAvatar={isShowReplySentAvatar}
              />
            )}
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) return <Loader />;

  const showMoreActions = conversation?.results?.some(
    (item) => item.is_reply_sent === true
  );

  // const onBinItClick = () => {
  //   const data = {
  //     lead: cardId,
  //     is_current: true,
  //     status: choices.LeadStatusChoices.MOVED_TO_BIN
  //   };
  //   dispatch(
  //     changeLeadStatus({ body: data }, () => {
  //       openSuccessSnackBar('Moved to bin');
  //       navigate('/dashboard/enquirybox/eb-box');
  //     })
  //   );
  // };

  if (showCardNotFound) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-not-found`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-not-found`}
      />
    );
  }

  if (!isEmpty(activeLead) && activeLead?.status === MOVED_TO_BIN && !isBin) {
    if (isBox) {
      return (
        <Navigate
          to={`${route.dashboard}${route.boxes}/${box_type}/${box_id}/${stageId}/card-moved-to-bin`}
        />
      );
    }
    return (
      <Navigate
        to={`${route.dashboard}${route.enquirybox}${route.box}/lead/card-moved-to-bin`}
      />
    );
  }

  return (
    <>
      {/* <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={onBinItClick}
        title="Are you sure you want to bin this enquiry?"
        description="You can see this enquiry in bin."
      /> */}
      <ConversationHeader activeLead={activeLead} isBin={isBin} />
      <div
        className="chat-message-body"
        style={{ padding: 25 }}
        id="Conversation"
        ref={messageRef}
      >
        {conversation.results.map((item, idx) => {
          if (
            [NORMAL_REPLY, IG_STORY_MENTION, IG_STORY_REPLY].includes(
              item.reply_type
            )
          )
            return (
              <MessageContainer
                key={item.id}
                item={item}
                isReplyRecieved={item.is_reply_received}
                isReplySent={item.is_reply_sent}
                index={idx}
                conversation={conversation.results}
              />
            );
          if (EMAIL_REPLY == item.reply_type)
            return (
              <div
                key={item.id}
                style={{
                  marginBottom:
                    showInboxActions && idx === conversation?.results.length - 1
                      ? '80px'
                      : '25px'
                }}
              >
                <EmailBody data={item} isCollapse={false} />
              </div>
            );
          if (NOTE_REPLY === item.reply_type)
            return (
              // <div key={item.id}></div>
              <div
                key={item.id}
                style={{
                  marginBottom:
                    showInboxActions && idx === conversation?.results.length - 1
                      ? '80px'
                      : '25px'
                }}
              >
                <NoteContainer
                  created_at={timeFormatter(item.created_at)}
                  data={item.note}
                  item={item}
                  createdBy={item.created_by}
                  isBin={isBin}
                  idx={idx}
                />
              </div>
            );
          if (TASK_REPLY === item.reply_type)
            return (
              <div key={item.id}></div>
              // <div
              //   key={item.id}
              //   style={{
              //     marginBottom:
              //       showInboxActions && idx === conversation?.results.length - 1
              //         ? '80px'
              //         : '25px'
              //   }}
              // >
              //   <TaskContainer
              //     created_at={timeFormatter(item.created_at)}
              //     data={item.task}
              //     item={item}
              //     createdBy={item.created_by}
              //     isBin={isBin}
              //   />
              // </div>
            );
          if (SMS_REPLY === item.reply_type)
            return (
              <SmsMessageContainer
                key={item.id}
                item={item}
                isReplyRecieved={item.is_reply_received}
                isReplySent={item.is_reply_sent}
                index={idx}
                conversation={conversation.results}
                showInboxActions={showInboxActions}
              />
            );
          return (
            <div
              key={item.id}
              style={{
                marginBottom:
                  showInboxActions && idx === conversation?.results.length - 1
                    ? '80px'
                    : '25px'
              }}
            />
          );
        })}
      </div>
      {showInboxActions && !isBin && (
        <Actions
          onOpenEditor={() => dispatch(setShowInboxActions(false))}
          showMoreActions={showMoreActions}
          // onBinIt={() => setShowDelete(true)}
        />
      )}
      {!showInboxActions && !isBin && (
        <ChatFooterInputBox
          onClose={() => dispatch(setShowInboxActions(true))}
          isFacebook={isFacebook}
          isInsta={isInsta}
          onReplySentSuccess={onReplySentSuccess}
          hideAttachment={true}
          hideImage={true}
        />
      )}
      <ImageModalContainer open={showImagePreview} />

      <WarningAlert
        showConfirmation={showErrorModal}
        description={replyError}
        title="reply error"
        renderSvg={() => <FileTooLargeIcon />}
        onCancel={() => {
          dispatch(setIsReplyLoading(false));
          dispatch(setShowErrorModal(false));
        }}
      />
    </>
  );
};

FBInstaConversation.propTypes = {
  isFacebook: PropTypes.bool,
  isInsta: PropTypes.bool
};

FBInstaConversation.defaultProps = {
  isFacebook: false,
  isInsta: false
};

export default FBInstaConversation;

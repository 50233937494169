/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import { ALL, ENQUIRIES } from 'utils/constants';
import { GET_TOTAL_LEADS } from 'urls';
import APIRequest from 'apiRequest';

export const leadsFilter = createSlice({
  name: 'leadsFilter',
  initialState: {
    activeTab: ENQUIRIES, // current active tab in things to do
    isFilterByChannel: false,
    channel: null,
    channelId: null,
    assignedTo: null,
    assignedToCount: 0
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIsFilterByChannel: (state, action) => {
      state.isFilterByChannel = action.payload;
    },
    setChannel: (state, action) => {
      state.channel = action.payload;
    },
    setChannelId: (state, action) => {
      state.channelId = action.payload;
    },
    setAssignedTo: (state, action) => {
      state.assignedTo = action.payload;
    },
    setAssignedToCount: (state, action) => {
      state.assignedToCount = action.payload;
    }
  }
});

export const {
  setActiveTab,
  setChannel,
  setAssignedTo,
  setChannelId,
  setAssignedToCount,
  setIsFilterByChannel
} = leadsFilter.actions;

export default leadsFilter.reducer;

export const getTotalUserLeads = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assignedTo = getState().leadsFilter.assignedTo;
    const channelId = getState().leadsFilter.channelId;
    try {
      await new APIRequest()
        .get(GET_TOTAL_LEADS, {
          sub_organization_id: subOrgId,
          assigned_to: assignedTo?.user_id,
          channel_id: channelId
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAssignedToCount(res?.data?.lead_count));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

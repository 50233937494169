// import TextInputField from "components/inputs/textField";
import EmojiPicker from 'emoji-picker-react';
import React from 'react';
import modalCloseIcon from 'assets/icons/icon-modal-close.svg';

const EmojiModal = ({ className, setOpenEmoji, onEmojiSelect, style }) => {
  return (
    <div
      className={`${'dashboard__emoji-dropdown'} ${className}`}
      style={{
        height: 'calc(100% + 130px)',
        width: '100%',
        top: -4,
        left: 0,
        ...style
      }}
    >
      <div
        className="modal__close-icon mt-4 ml-auto"
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '17px'
        }}
        onClick={() => setOpenEmoji(false)}
      >
        <img src={modalCloseIcon} alt="" />
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      >
        <EmojiPicker
          height={450}
          width={480}
          previewConfig={{ showPreview: false }}
          lazyLoadEmojis={true}
          onEmojiClick={onEmojiSelect}
          autoFocusSearch={false}
          searchDisabled={false}
          categories={[]}
        />
      </div>
    </div>
  );
};

export default EmojiModal;

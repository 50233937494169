import choices from 'choices';

const BUSINESS_REDIRECT = choices.FrontendRedirectChoice.BUSINESS_INFO;
const DASHBOARD_REDIRECT = choices.FrontendRedirectChoice.DASHBOARD;
const LOGIN_REDIRECT = choices.FrontendRedirectChoice.LOGIN_SCREEN;

export const redirectPostCreds = (redirect) => {
  if (BUSINESS_REDIRECT === redirect) return '/dashboard';
  if (DASHBOARD_REDIRECT === redirect) return '/dashboard';
  if (LOGIN_REDIRECT === redirect) return '/login';
  return '/dashboard';
};

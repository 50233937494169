import PlansModal from 'modules/modals/plansSubscription';
import { useSelector } from 'react-redux';
import {
  setShowSubscriptionModal,
  setShowEnquiriesExpiredModal,
  setShowCancelAccountModal,
  setShowDeleteAccountModal,
  setShowPaymentNotCompleted
} from 'slices/subscription/slice';
import { useDispatch } from 'react-redux';
import useIsFreeEnquiriesExpired from 'hooks/useIsFreeEnquiriesExpired';
import {
  DELETE_ACCOUNT,
  CANCEL_ACCOUNT,
  PAYMENT_INCOMPLETE,
  LOGOUT
} from 'utils/constants';
import { clearAuthToken } from 'slices/authSlice';
import { useIntercom } from 'react-use-intercom';

const Plans = () => {
  const dispatch = useDispatch();
  const { isFreeEnquiriesExpired } = useIsFreeEnquiriesExpired();
  const {
    showSubscriptionModal,
    showEnquiriesExpiredModal,
    postExpiryActiveModal
  } = useSelector((state) => state.subscription);
  const { socket } = useSelector((state) => state.sockets);
  const { shutdown } = useIntercom();

  const onClose = () => {
    dispatch(setShowSubscriptionModal(!showSubscriptionModal));
    if (postExpiryActiveModal === DELETE_ACCOUNT) {
      dispatch(setShowDeleteAccountModal(true));
      return;
    }
    if (postExpiryActiveModal === CANCEL_ACCOUNT) {
      dispatch(setShowCancelAccountModal(true));
      return;
    }
    if (postExpiryActiveModal === PAYMENT_INCOMPLETE) {
      dispatch(setShowPaymentNotCompleted(true));
      return;
    }
    if (postExpiryActiveModal === LOGOUT) {
      shutdown(); // closes the intercom launcher
      dispatch(clearAuthToken());
      // Disconnect the live socket when logout
      if (socket && socket.connected) {
        socket.close();
      }
      return;
    }
    if (isFreeEnquiriesExpired) {
      dispatch(setShowEnquiriesExpiredModal(!showEnquiriesExpiredModal));
    }
  };

  return <PlansModal open={showSubscriptionModal} onClose={onClose} />;
};

export default Plans;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import HeaderLayout from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import SignUpForm from 'modules/registration/signup/form';
import { useSearchParams, useNavigate, NavLink } from 'react-router-dom';
import { useSignUpInviteUserMutation } from 'services/authApi';
import choices from 'choices';
// import { SuccessToast, ErrorToast } from 'components/toast';
import ErrorRenderer from 'components/errorMessage/renderer';
import { useDispatch } from 'react-redux';
import {
  signUpExistingInvitedUser,
  signUpNewInvitedUser
} from 'slices/teamMember/slice';
import ErrorMessage from 'components/errorMessage';
import { useSelector } from 'react-redux';
import GoogleErrorDraft from 'modules/auth/login/google/errorDraft';
import Google from 'modules/auth/login/google';
import Microsoft from 'modules/auth/login/microsoft';
import Break from 'components/break/text';
import { isEmpty } from 'utils/utils';
import { SOCIAL_AUTH_ERROR } from 'utils/constants';
import { useAnalytics } from 'use-analytics';

const CreateAccount = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const email = queryParams.get('email');
  const level = queryParams.get('level');
  const role = queryParams.get('role');
  const invitedBy = queryParams.get('invited_by');
  const sub_organization_id = queryParams.get('sub_organization_id');
  const is_this_user_alreday_created = queryParams.get(
    'is_this_user_alreday_created'
  );
  const emailFromUrl = window.location.search.split('&')[0].split('=')[1];
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [newSignupLoading, setNewSignupLoading] = useState(false);
  const [googleError, setGoogleError] = useState(null);
  const microsoftSocialError = JSON.parse(
    localStorage.getItem(SOCIAL_AUTH_ERROR)
  );
  const { identify, track } = useAnalytics();
  // const [signUpInviteUser, { isSuccess, isLoading, isError, error }] =
  //   useSignUpInviteUserMutation();

  // useEffect(() => {
  //   if (isSuccess) {
  //     onSuccess();
  //   }
  // }, [isSuccess]);

  useEffect(() => {
    if (!isEmpty(microsoftSocialError)) {
      localStorage.removeItem(SOCIAL_AUTH_ERROR);
    }
  }, [microsoftSocialError]);

  const errorCallback = (err) => {
    setError(err);
  };

  const loadSignUpExistingUser = () => {
    const formData = {
      email: emailFromUrl
    };
    dispatch(
      signUpExistingInvitedUser({
        body: formData,
        subOrgId: sub_organization_id,
        callback: callback,
        errorCallback: errorCallback
      })
    );
  };

  useEffect(() => {
    if (is_this_user_alreday_created === 'True') {
      setIsLoading(true);
      loadSignUpExistingUser();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  }, [error]);

  const fireAnalytics = (data) => {
    if (analytics) {
      identify(
        data.user_id,
        {},
        {
          groupId: sub_organization_id
        }
      );
      track(
        'invite_accepted',
        {
          user_type: choices.TeamMemberRoleChoice.CHOICES[role]
        },
        {
          groupId: sub_organization_id
        }
      );
    }
  };

  const callback = (data) => {
    // SuccessToast(data?.message);
    if (analytics) {
      fireAnalytics(data.data);
    }
    setTimeout(() => {
      setIsLoading(false);
      navigate('/login');
    }, 3000);
  };

  const onSubmit = async (data) => {
    setNewSignupLoading(true);
    const { name, email, password } = data;
    const formdata = {
      first_name: name,
      email: email,
      password: password
    };
    dispatch(
      signUpNewInvitedUser({
        body: formdata,
        subOrgId: sub_organization_id,
        callback: callback,
        errorCallback: errorCallback
      })
    );
  };

  const onGoogleError = (err) => {
    if (err?.message) {
      setGoogleError(err);
    }
    console.log(err);
  };

  if (isLoading)
    return (
      <HeaderLayout hideLogo>
        <div className="flex flex-col items-center justify-center">
          <p>
            {error
              ? `${error} You will be redirected to login shortly`
              : 'User is signing up automatically, You will be redirected to login shortly'}
          </p>
          <p>
            if not redirected,{' '}
            <NavLink className="text-blue-900" to="/login">
              click here
            </NavLink>{' '}
            for login
          </p>
        </div>
      </HeaderLayout>
    );

  if (newSignupLoading && error) {
    return (
      <HeaderLayout hideLogo>
        <div className="flex flex-col items-center justify-center">
          <p>{`${error} You will be redirected to login shortly`}</p>
          <p>
            if not redirected,{' '}
            <NavLink className="text-blue-900" to="/login">
              click here
            </NavLink>{' '}
            for login
          </p>
        </div>
      </HeaderLayout>
    );
  }

  return (
    <HeaderLayout>
      <CardLayout className="mt-5">
        <CardTitle title="Create Your account" />
        <div className="w-full mt-8">
          {googleError && <GoogleErrorDraft error={googleError} />}
          {microsoftSocialError && (
            <GoogleErrorDraft error={microsoftSocialError} />
          )}
          {error && (
            <div className="mb-2">
              <ErrorMessage error={error} />
            </div>
          )}
          <SignUpForm
            onSubmit={onSubmit}
            email={emailFromUrl}
            isLoading={newSignupLoading}
          />
          <div className="mt-5 mb-10">
            <Break label="OR - 1 CLICK CREATE YOUR ACCOUNT WITH" />
          </div>
          <div className="flex items-center justify-center w-full md:w-auto mt-4">
            <div className="w-1/2 pr-2">
              <Google
                signUp={true}
                onError={onGoogleError}
                invitedAccount={true}
                fireAnalytics={fireAnalytics}
              />
            </div>
            <div className="w-1/2">
              <Microsoft signUp={true} />
            </div>
          </div>
        </div>
      </CardLayout>
    </HeaderLayout>
  );
};

export default CreateAccount;

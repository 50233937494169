/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import AppProvider from 'store/store';
import AppLayout from 'appLayout';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AddManualEnquiry from 'modules/boxes/components/modal/addManualEnquiry';
import ReportPublic from 'modules/report/component/reportPublic';
import EmailVerificationStatus from 'modules/teamChat/module/settings/modules/emailAddress/emailVerificationStatus';
import { useAnalytics } from 'use-analytics';

function AppWrapper() {
  const analyt = useAnalytics();

  useEffect(() => {
    analyt.ready(() => {
      analyt.page();
      console.log('Analytics is ready');
    });
  }, []);

  return (
    <AppProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<AppLayout />} />
          <Route
            path="/enquiry/:subOrgId/:box_id"
            element={<AddManualEnquiry isPublicPage={true} />}
          />
          <Route
            path="/ses-email-verifications/*"
            element={<EmailVerificationStatus />}
          />
          <Route path="/report-public/:reportType" element={<ReportPublic />} />
        </Routes>
      </BrowserRouter>
    </AppProvider>
  );
}

export default AppWrapper;

import { useEffect, useRef } from 'react';

/**
 * This custom hook is used to trigger a function when it is being clicked outside
 * anywhere on the screen. Suitable for drop down controls
 *
 * @param {Function} callback function that needs to be called when clicked
 * @param {DOMElement} domElement html element
 * @returns undefined
 */
export default function useClickOutside(callback, domElement) {
  const domRef = useRef(domElement);

  if (domElement) domRef.current = domElement;

  const handleClickOutside = (element) => {
    if (!domRef.current) return;
    if (domRef.current.contains(element.target)) return;

    if (callback) callback();
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return;
}

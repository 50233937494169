import { createSlice } from '@reduxjs/toolkit';
import { GET_LEADS_HISTORY } from 'urls';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';

export const historyLead = createSlice({
  name: 'historyLead',
  initialState: {
    leadHistory: {}
  },
  reducers: {
    setLeadHistory: (state, action) => {
      state.leadHistory = action.payload;
    }
  }
});

export const { setLeadHistory } = historyLead.actions;

export const getLeadsHistory = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { leadId } = payload;
      new APIRequest()
        .get(replaceUrl(GET_LEADS_HISTORY, 'leadId', leadId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLeadHistory(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getFilteredHistory = (payload) => {
  return async (dispatch) => {
    try {
      const { leadId, title, callback, query = {} } = payload;
      new APIRequest()
        .get(replaceUrl(GET_LEADS_HISTORY, 'leadId', leadId), {
          filter_by: title,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLeadHistory(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export default historyLead.reducer;

import React from 'react';
import { useSelector } from 'react-redux';
import { ReactComponent as HandSvg } from 'assets/icons/icon-hand.svg';
import { ReactComponent as NoLeadsSvg } from 'assets/icons/icon-no-leads-enquiry-bin.svg';
import { isEmpty } from 'utils/utils';

const EnquiryBinEmptyState = () => {
  const { allBinLeads } = useSelector((state) => state.enquiryBin);

  return (
    <div
      className="text-center mt-24"
      style={{
        width: '300px',
        margin: 'auto',
        marginTop: '100px'
      }}
    >
      <figure className="flex justify-center">
        {isEmpty(allBinLeads?.results) ? <NoLeadsSvg /> : <HandSvg />}
      </figure>
      <h3 className="mt-2 text-lg font-semibold">
        {isEmpty(allBinLeads?.results)
          ? 'Nothing to show here, it’s empty'
          : 'Pick up another enquiry'}
      </h3>
    </div>
  );
};

export default EnquiryBinEmptyState;

import React, { useState } from 'react';
import Modal from 'components/modal';
import TextArea from 'components/inputs/textarea';
import { useDispatch } from 'react-redux';
import ErrorMessage from 'components/errorMessage';
import InputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import { addQna } from 'slices/ai-assistant/qna/slice';
import { useSnackbar } from 'components/Snackbar';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { urlFieldValidation } from 'components/fieldsAndValidation';

const AddQAndA = (props) => {
  const { open, setQnaModal, id } = props;
  const { assistant_id } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const formSchema = {
    question: string().required('Please provide question'),
    answer: string().required('Please provide answer'),
    link: string().matches(urlFieldValidation, {
      message: 'Please provide valid URL link',
      excludeEmptyString: true
    })
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const handleResponse = () => {
    setIsLoading(false);
    setQnaModal(false);
    openSuccessSnackBar('Question & answer added');
    reset({
      question: '',
      answer: '',
      link: ''
    });
  };
  const handleFailed = () => {
    setIsLoading(false);
    reset({
      question: '',
      answer: '',
      link: ''
    });
  };
  const onSubmit = async (data) => {
    setIsLoading(true);
    const { question, answer, link } = data;
    let payload = {
      edited_by_id: id,
      link,
      question,
      answer
    };
    dispatch(addQna(payload, assistant_id, handleResponse, handleFailed));
  };
  return (
    <Modal.Main open={open} className="modal">
      <Modal.Header title="Add a Q&A" onClose={() => setQnaModal(false)} />
      <Modal.Body style={{ padding: '20px 30px 30px' }}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <label className="block text-base-1 font-semibold mb-1">
              Question
            </label>
            <TextArea
              name="question"
              rows="3"
              cols="4"
              placeholder="Add your question here"
              className="input-field--border2 resize-none"
              error={errors.question && touchedFields.question}
              register={register}
              autoComplete="off"
            ></TextArea>
            {errors.question && touchedFields.question && (
              <ErrorMessage message={errors?.question?.message} />
            )}
          </div>
          <div className="mt-3">
            <label className="block text-base-1 font-semibold mb-1">
              Answer
            </label>
            <TextArea
              name="answer"
              rows="3"
              cols="4"
              placeholder="Add your answer here"
              className="input-field--border2 resize-none"
              error={errors.answer && touchedFields.answer}
              register={register}
              autoComplete="off"
            ></TextArea>
            {errors.answer && touchedFields.answer && (
              <ErrorMessage message={errors?.answer?.message} />
            )}
          </div>
          <div className="mt-3">
            <label className="block text-base-1 font-semibold mb-1">
              Add a link{' '}
              <span className="text-grey-600 text-base-1 font-normal">
                {' '}
                (optional)
              </span>
            </label>
            <InputField
              name="link"
              type="text"
              className="input-field input-field--border2 input-field--h42"
              placeholder="Add a link to more info or a booking page"
              error={errors.link && touchedFields.link}
              register={register}
              autoComplete={'off'}
            />
          </div>

          <div className="flex items-center justify-end mt-6">
            <button
              className="btn btn--secondary btn--md btn--h42 min-w-120"
              onClick={() => setQnaModal(false)}
            >
              Cancel
            </button>
            <PrimaryButton
              type="submit"
              label="Add"
              className="btn btn--primary btn--md btn--h42 min-w-120 ml-3"
              isLoading={isLoading}
              enableLoader={true}
              loaderInline={true}
            />
          </div>
        </form>
      </Modal.Body>
    </Modal.Main>
  );
};

export default AddQAndA;

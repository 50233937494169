import React from 'react';
import { Route, Routes } from 'react-router-dom';
// import AutomateStuff from '../../automate';
// import ChannelsConnectedEmptyState from '../../components/channelsConnectedEmpty';
import InstagramHome from './home';
import InstagramSettings from './settings';
// import AnimationWrapper from 'animation/wrapper';
import Automations from 'modules/channels/components/automations';
import CHOICES from 'choices';
import InstagramConnectedSettings from 'modules/channels/components/instagramSettings';

const { INSTAGRAM } = CHOICES.ChannelChoices;

const Instagram = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route path="/pending/:channelId" element={<InstagramHome />} />
      <Route path="/connected/:channelId" element={<InstagramSettings />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={INSTAGRAM} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<InstagramConnectedSettings channelType={INSTAGRAM} />}
      />
      {/* <Route path="/automation/*" element={<AutomateStuff />} /> */}
      {/* </Route> */}
    </Routes>
  );
};

export default Instagram;

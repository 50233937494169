import React, { useState } from 'react';
import choices from 'choices';
import { isEmpty } from 'utils/utils';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { useDispatch, useSelector } from 'react-redux';
import { omit } from 'lodash';
import ErrorMessage from 'components/errorMessage';

const { CHOICE_LIST } = choices.DelayChoices;

const DelayTimer = ({ condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const { data } = condition;
  const [delayKind, setDelayKind] = useState(data.delay_kind);
  const [delay, setDelay] = useState(data.delay_value);

  const errors = automationErrors[index];

  const updatedThen = (value) => {
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        ...value
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  const onDelayChange = (e) => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.delay_value) {
      let result = omit(errorObj[index], ['delay_value']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    const { value } = e.target;
    setDelay(value);
    updatedThen({ delay_value: value });
  };

  const onDelayKindChange = (e) => {
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.delay_kind) {
      let result = omit(errorObj[index], ['delay_kind']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    const { value } = e.target;
    setDelayKind(value);
    updatedThen({ delay_kind: value });
  };

  return (
    <div className="border-radius-10 bg-white border border-grey-400 p-4">
      {/* <div className='font-medium'>Wait for 2 hours and then run on weekdays</div> */}
      <div>
        <div className="items-baseline flex w-full">
          <div className="w-1/4">
            <label className="text-15 font-semibold">Wait for</label>
          </div>
          <div className="flex gap-2" style={{ width: '35%' }}>
            <input
              type="number"
              className="input-field input-field__timer-input"
              min="1"
              placeholder=""
              value={delay}
              onChange={onDelayChange}
            />
            <div className="flex">
              <select
                defaultValue={delayKind}
                className="custom-select__automation text-15"
                onChange={onDelayKindChange}
              >
                {CHOICE_LIST.map((choice) => {
                  return (
                    <option key={choice.value} value={choice.value}>
                      {choice.label}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        <div>
          {errors?.delay_kind && <ErrorMessage message={errors.delay_kind} />}
          {errors?.delay_value && <ErrorMessage message={errors.delay_value} />}
        </div>
        {/* <div className="items-baseline flex w-full mt-3">
          <div className="w-1/4">
            <label className="text-15 font-semibold">And run on</label>
          </div>
          <div className="flex">
            <select defaultValue="WEEKDAYS" className="custom-select__automation text-15" style={{ width: 222 }}>
              <option value='ANYTIME'>Anytime</option>
              <option value='WEEKDAYS'>Weekdays</option>
              <option value='WEEKENDS'>Weekends</option>
            </select>
          </div>
        </div> */}
      </div>
      {/* <div className='flex justify-end mt-5'>
        <button
          className="btn btn--primary text-sm"
          style={{ padding: "5px 14px", borderRadius: 6 }}
        >
          Save
        </button>
      </div> */}
    </div>
  );
};

export default DelayTimer;

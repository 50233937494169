import PrimaryButton from 'components/buttons/primary';
import React from 'react';

const SaveAndcancel = (props) => {
  const { handleCancel, handleSave, isLoading } = props;
  return (
    <>
      <div
        className="absolute flex items-center top-0 bottom-0 right-2"
        style={{
          left: '81.5%'
        }}
      >
        <button
          className="btn btn--secondary text-13"
          style={{ padding: '5px 8px' }}
          onClick={handleCancel}
        >
          Cancel
        </button>
        <PrimaryButton
          type="submit"
          label="Save"
          onClick={handleSave}
          enableLoader={true}
          style={{
            fontSize: 13
          }}
          className="btn--xs ml-1"
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default SaveAndcancel;

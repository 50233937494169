import { isEmpty } from './utils';

export const getNameInitial = (text, singleChar) => {
  const name = text === '' || text === null || !text ? 'NA' : text.split(' ');
  if (isEmpty(text)) {
    return 'NA';
  }
  const [firstName, lastName] = name;
  if (isEmpty(firstName) && isEmpty(lastName)) {
    return 'NA';
  }
  if (isEmpty(firstName) && !isEmpty(lastName)) {
    return `${lastName[0].toUpperCase()}`;
  }
  if (firstName && lastName) {
    const name = `${firstName[0].toUpperCase()}${lastName[0].toUpperCase()}`;
    if (singleChar) return `${firstName[0].toUpperCase()}`;
    return name;
  } else {
    return `${firstName[0].toUpperCase()}`;
  }
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsMovedSuccess,
  setActiveNewLead,
  onUnreadLeadFocused
} from 'slices/eboxLead';
// import EnquiryCard from '../../../components/enquiryCard';
// import iconAvatar from 'assets/images/avatar-pic2.png';
import { ReactComponent as Clock } from 'assets/icons/icon-clock-filled.svg';
import { ReactComponent as RedIcon } from 'assets/icons/icon-reply-later-sup-icon.svg';
import { isEmpty, timeFormatter } from 'utils/utils';
import { Draggable } from 'react-beautiful-dnd';
import choices from 'choices';
import { useLocation } from 'react-router-dom';

const ReplyLater = (props) => {
  const { count, open, style, children, replyLaterLeads } = props;
  const [isOpenReplyLater, setIsOpenReplyLater] = useState(open);
  const [isFocused, setIsFocused] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setIsOpenReplyLater(open);
  }, [open]);

  useEffect(() => {
    const path = location.pathname.split('/');
    const leadId = path[path.length - 1];
    const find = replyLaterLeads?.find((item) => item.id === leadId);
    if (!isEmpty(find)) {
      setIsOpenReplyLater(true);
    }
  }, [location, replyLaterLeads]);

  return (
    <div
      className={`card-box card-box--br-10 cursor-pointer
        ${isFocused && !isOpenReplyLater ? 'card-box--br-secondary' : ''} ${
        isOpenReplyLater ? 'card-box--br-primary active' : ''
      } pt-2 pr-3 pb-3 pl-3 mb-3`}
      style={style}
      onMouseEnter={() => setIsFocused(true)}
      onMouseLeave={() => setIsFocused(false)}
      onClick={() => {
        if (!isOpenReplyLater) {
          setIsOpenReplyLater(true);
        }
      }}
    >
      <div
        className="flex justify-between items-center"
        onClick={() => setIsOpenReplyLater(!isOpenReplyLater)}
      >
        <div className="flex gap-2">
          <figure
            className="avatar-container avatar-container--contain"
            style={{
              width: 25,
              height: 23
            }}
          >
            <Clock />
            <div className="avatar-container__sup-icon--left absolute">
              <RedIcon width={10} height={10} />
            </div>
          </figure>
          <p className="text-15 font-semibold">Reply Later</p>
        </div>
        <div className="text-15 font-semibold">{count || 0}</div>
      </div>
      {isOpenReplyLater && <>{children}</>}
    </div>
  );
};

ReplyLater.defaultProps = {
  style: {}
};
export default React.memo(ReplyLater);

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { setIsError } from 'slices/ai-assistant/assistant/slice';
import {
  CREATE_ASSISTANT,
  CREATE_ASSISTANT_FROM_TEMPLATE
} from 'urls/ai-assistant';
const initialState = {
  aiData: []
};

const reducers = {
  setAiData: (state, action) => {
    state.aiData = action.payload;
  }
};

export const createAi = createSlice({
  name: 'createAi',
  initialState,
  reducers
});

export const { setAiData } = createAi.actions;

export default createAi.reducer;

export const createAssistant = (payload, successCb, failedCb) => {
  return async (dispatch) => {
    try {
      const { body } = payload;
      new APIRequest()
        .post(CREATE_ASSISTANT, body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setIsError({}));
            if (successCb) {
              successCb(resp.data);
            }
          }
        })
        .catch((err) => {
          if (failedCb) failedCb();
          console.log(e);
        });
    } catch (e) {
      if (failedCb) failedCb();
      console.log(e);
    }
  };
};

export const createAssistantFromTemplate = (payload, successCb, failedCb) => {
  return async (dispatch) => {
    try {
      const { body } = payload;
      new APIRequest()
        .post(CREATE_ASSISTANT_FROM_TEMPLATE, body)
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setIsError({}));
            if (successCb) {
              successCb(resp.data);
            }
          }
        })
        .catch((e) => {
          if (failedCb) failedCb();
          console.log(e);
        });
    } catch (e) {
      if (failedCb) failedCb();
      console.log(e);
    }
  };
};

import React, { useState } from 'react';
// import { ReactComponent as FbSvg } from 'assets/icons/icon-select-fb.svg';
// import { ReactComponent as InstaSvg } from 'assets/icons/icon-select-insta.svg';
// import { ReactComponent as WebformSvg } from 'assets/icons/icon-select-webforms.svg';
// import { ReactComponent as WhatsAppSvg } from 'assets/icons/icon-select-whatsApp.svg';
// import { ReactComponent as FbLeadsSvg } from 'assets/icons/icon-facebook-leads.svg';
import ListItem from './listItem';
import PropTypes from 'prop-types';
import { Routes, Route, useNavigate } from 'react-router-dom';
import CreateChannel from './createChannel';
import choices from 'choices';
import { useDispatch, useSelector } from 'react-redux';
import {
  connectChannelsAsDraft,
  getAllPendingChannels
} from 'slices/channelsSlice';
import { useSnackbar } from 'components/Snackbar';
import { getRoutePath } from 'utils/channels';
import { route } from 'utils/route';

// const FACEBOOK = choices.ChannelChoices.FACEBOOK;
// const INSTAGRAM = choices.ChannelChoices.INSTAGRAM;
// const WHATSAPP = choices.ChannelChoices.WHATSAPP;
// const FACEBOOK_LEAD_ADS = choices.ChannelChoices.FACEBOOK_LEAD_ADS;
// const WEBFORM = choices.ChannelChoices.WEBFORM;
// const ZAPIER = choices.ChannelChoices.ZAPIER;
// const SMS = c

const {
  FACEBOOK,
  INSTAGRAM,
  FACEBOOK_LEAD_ADS,
  WEBFORM,
  ZAPIER,
  SMS,
  WHATSAPP,
  EBOT
} = choices.ChannelChoices;
const SelectChannels = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { allChannels } = useSelector((state) => state.channels);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { is_sms_connected } = useSelector(
    (state) => state.myInfo?.myInformation
  );

  const onCancel = () => {
    navigate(`/dashboard/enquirybox/settings/select-channels`);
  };

  const RenderChannelList = () => {
    const onSuccess = (data) => {
      openSuccessSnackBar('Channel created');
      dispatch(getAllPendingChannels({ subOrgId: subOrganizationId }));
      setIsLoading(false);
      onCancel();
      navigate(
        `/dashboard/enquirybox/settings/${getRoutePath(
          data.channel_type
        )}/pending/${data.id}`
      );
    };

    const onChannelSelect = (id, channel_type) => {
      if (
        channel_type === FACEBOOK ||
        channel_type === INSTAGRAM ||
        channel_type === FACEBOOK_LEAD_ADS
      ) {
        setIsLoading(true);
        const formData = {
          body: {
            channel: id,
            channel_name: ''
          },
          subOrgId: subOrganizationId
        };
        dispatch(connectChannelsAsDraft(formData, onSuccess));
      } else {
        if (channel_type === SMS) {
          navigate(`${route.toolBox}/sms`);
        } else {
          navigate(`/dashboard/enquirybox/settings/select-channels/${id}`);
        }
      }
    };

    const getClassName = (type) => {
      if (type === FACEBOOK) {
        return 'facebook-page';
      }
      if (type === INSTAGRAM) {
        return 'instagram-account';
      }
      if (type === WHATSAPP) {
        return 'whatsApp';
      }
      if (type === WEBFORM) {
        return 'web-forms';
      }
      if (type === FACEBOOK_LEAD_ADS) {
        return 'facebook-lead';
      }
      if (type === SMS) {
        return 'sms-channel';
      }
    };

    return (
      <>
        {allChannels?.map((channel) => {
          const { channel_name, id, description, channel_type, avatar_link } =
            channel;
          const className = getClassName(channel_type);
          // let isHide =
          //   channel_type === WHATSAPP ||
          //   channel_type === ZAPIER ||
          //   channel_type === FACEBOOK ||
          //   channel_type === INSTAGRAM
          //     ? true
          //     : false;
          let isHide =
            channel_type === WHATSAPP ||
            channel_type === ZAPIER ||
            channel_type === EBOT
              ? true
              : false;
          if (channel_type === SMS && is_sms_connected) {
            isHide = true;
          }
          if (isHide) return <div key={id}></div>;
          if (!isHide) {
            return (
              <ListItem
                key={id}
                id={id}
                title={channel_name}
                description={description}
                className={className}
                onClick={onChannelSelect}
                icon={avatar_link}
                channel_type={channel_type}
                isLoading={isLoading}
                enableLoader={isLoading}
                // renderSvg={() => getIcon(channel_type)}
              />
            );
          }
        })}
      </>
    );
  };

  return (
    <>
      <h2 className="text-2xl font-semibold text-center">Select a Channel</h2>
      <ul className="mt-5">
        <RenderChannelList />
      </ul>
      <Routes>
        <Route
          path="/:channelType"
          element={<CreateChannel onCancel={onCancel} />}
        />
      </Routes>
    </>
  );
};

SelectChannels.propType = {
  onClick: PropTypes.func
};

export default SelectChannels;

/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
// import propTypes from 'prop-types';
// import userIcon from "../../assets/icons/usericon1.svg";
// import searchCloseIcon from "../../assets/icons/icon-modal-close.svg";
// import iconSearch from "../../assets/icons/icon-search.svg";
// import ToggleHeaderNew from "./ToggleHeaderNew";
// import { useHistory } from "react-router-dom";
import { isEmpty } from 'utils/utils';
import iconSettings from 'assets/icons/icon-settings.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-white.svg';
import backIcon from 'assets/icons/icon-back-green.svg';
import { ReactComponent as FilterIcon } from 'assets/icons/icon-channel-filter.svg';
import { ReactComponent as FilterIconPrimary } from 'assets/icons/icon-channel-filter-primary.svg';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import CustomToolTip from 'components/CustomTooltip';
import iconSearch from 'assets/icons/icon-search.svg';
import Avatar from 'components/avatar';
import SearchModal from 'modules/search';
import { ReactComponent as TaskIcon } from 'assets/icons/icon-box-header-task.svg';
import Count from 'components/count';
import NewEnquiriesList from '../components/newEnquiriesList';
import { useSelector } from 'react-redux';
import GuideDropdown from 'components/guideDropdown';
import { boxGuideOptions } from 'utils/guide';

const BoxHeader = (props) => {
  const {
    title,
    HeaderIcon,
    showToggle,
    addReminderBtn,
    setIsActive,
    isActive,
    hideText,
    isBoxScreen,
    isFilterClicked,
    setIsFilterClicked,
    onBack,
    setShowEnquiryModal,
    handleOpenNewEnquiry,
    openEnquiryDropdown,
    setOpenEnquiryDropdown
  } = props;
  const params = useParams();
  const navigate = useNavigate();
  const restParams = params['*'];
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [searchInputValue, setSearchInputValue] = useState('');
  const [activateSearch, setActivateSearch] = useState(false);
  const [mouseIn, setMouseIn] = useState(false);
  const { boxesNewEnquires } = useSelector((state) => state.boxesNewEnquires);
  const { box_id, box_type } = params;
  // let history = useHistory();

  const nextScreen = () => {
    // history.push("/dashboard-facebook");
  };

  const isDetailEnabled = !isEmpty(restParams);

  const OnSettingsClick = () => {
    navigate(`/dashboard/boxes/${box_type}/${box_id}/settings/card-design`);
  };

  return (
    <>
      <SearchModal
        open={activateSearch}
        onClose={() => setActivateSearch(false)}
      />
      <div
        className="dashboard__main__header"
        style={{ padding: '20px 25px 20px 40px', height: 91 }}
      >
        <div className="flex items-center dashboard__main__header__section-left">
          {!isDetailEnabled && (
            <>
              <CustomToolTip
                position="right"
                tooltipText="Search"
                tooltipStyle={{ top: 5, left: '117%' }}
              >
                <div
                  onClick={() => setActivateSearch(true)}
                  onMouseEnter={() => setMouseIn(true)}
                  onMouseLeave={() => setMouseIn(false)}
                >
                  <Avatar
                    src={iconSearch}
                    imgStyle={{ width: '35px', height: '20px' }}
                    style={{ width: 50, height: 50 }}
                    larger={true}
                    className={`cursor-pointer ${
                      mouseIn ? 'bg-grey-90' : 'bg-color-800'
                    }`}
                  />
                </div>
              </CustomToolTip>
              <div className="ml-5">
                <h3 className="text-xl font-semibold">{title}</h3>
              </div>
            </>
          )}
          {isDetailEnabled && (
            <>
              <div
                className="flex items-center justify-center p-2 border-radius-20 cursor-pointer"
                onClick={onBack}
                style={{
                  border: '1px solid #E5E5E5',
                  boxShadow: '0px 3px 6px -2px rgba(85, 83, 80, 0.15)',
                  height: '33px'
                }}
              >
                <img src={backIcon} alt="arrow-right" className="ml-2" />
                <p
                  className="text-13 font-medium ml-2 mr-2"
                  style={{ color: '#005C6F' }}
                >
                  Back to Stages
                </p>
              </div>
            </>
          )}
        </div>
        {/* {showToggle && (
        <ToggleHeaderNew setIsActive={setIsActive} isActive={isActive} />
      )} */}
        <div className="flex items-center dashboard__main__header__section-right mr-4">
          {/* <div
          className={`search-container ${showSearchInput ? "is-open" : ""
            } pr-3`}
        >
          <div className="relative search-input-wrapper-sm">
            <input
              onFocus={() => setIsInputFocused(true)}
              onBlur={() => setIsInputFocused(false)}
              placeholder="Search...."
              value={searchInputValue}
              onChange={(e) => setSearchInputValue(e.target.value)}
              className="search-input input-field input-field--md input-field--icon-search"
            />
            <div
              className={`dropdown-menu dropdown-menu--with-border py-4 ${searchInputValue.length >= 1 && "open"
                }`}
              style={{
                top: "calc(100% + 4px)",
                maxHeight: 250,
                overflowY: "auto",
              }}
            >
              <p className="text-13 text-grey-600 px-3">Searching...</p>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
              <div className="dropdown-menu__list">
                <img src={iconSearch} alt="file" className="mr-3" />
                alexfoster_dp@gmail.com
              </div>
            </div>
          </div>
          {!addReminderBtn && (
            <figure className="flex items-center" style={{ height: 40 }}>
              <img
                onClick={() => setShowSearchInput(true)}
                src={iconSearch}
                alt="icon-search"
                className="search-icon ml-5"
              />
            </figure>
          )}
          <img
            src={searchCloseIcon}
            alt=""
            onClick={() => setShowSearchInput(false)}
            className="search-close-icon ml-5"
          />
        </div> */}
          {/* <div
            className="bg-grey-400 ml-1 mr-4"
            style={{ height: 26, width: 1 }}
          ></div> */}
          {/* <Link
            to={`/dashboard/boxes/${box_type}/${box_id}/settings`}
            className="flex items-center cursor-pointer ml-1"
          >
            <img
              src={iconSettings}
              alt="icon-settings"
              className="cursor-pointer"
              style={{ height: 18, width: 17 }}
            />
          </Link> */}
          {isBoxScreen && (
            <div className="flex ml-3 gap-1">
              <div className="relative">
                {boxesNewEnquires?.length > 0 && (
                  <CustomColorButtonWithLogo
                    label="New"
                    className={`btn--xs gap-1 border border-basic-button text-center justify-center`}
                    renderSvg={() => {
                      return (
                        <Count
                          rounded
                          label={boxesNewEnquires?.length}
                          style={{
                            height: 17,
                            width: 16,
                            fontSize: 13,
                            fontWeight: 700,
                            padding: 9,
                            borderRadius: '4.5px'
                          }}
                        />
                      );
                    }}
                    onClick={() => setOpenEnquiryDropdown(true)}
                    style={{ fontSize: 13, fontWeight: 500 }}
                  />
                )}
                <NewEnquiriesList
                  open={openEnquiryDropdown}
                  onClose={() => setOpenEnquiryDropdown(false)}
                  handleOpenNewEnquiry={handleOpenNewEnquiry}
                />
              </div>
              {/* <CustomColorButtonWithLogo
                label="Tasks"
                className={`btn--xs gap-1 border border-basic-button`}
                renderSvg={() => {
                  return (
                    <div
                      className="flex justify-center items-center"
                      style={{
                        border: '1px dashed #908F8D',
                        height: 20,
                        width: 20
                      }}
                    >
                      <TaskIcon />
                    </div>
                  );
                }}
                style={{ fontSize: 13, fontWeight: 500 }}
              /> */}
              {!isDetailEnabled && (
                <CustomColorButtonWithLogo
                  label="Filter"
                  className={`btn--xs gap-1 ${
                    isFilterClicked
                      ? 'bg-grey-90 text-primary'
                      : 'border border-basic-button'
                  }`}
                  onClick={() => setIsFilterClicked(!isFilterClicked)}
                  renderSvg={() =>
                    isFilterClicked ? <FilterIconPrimary /> : <FilterIcon />
                  }
                  style={{ fontSize: 13, fontWeight: 500 }}
                />
              )}
              <CustomToolTip
                position="bottom-left"
                tooltipText="Custom enquiry cards, create box forms, setup automations."
              >
                <CustomColorButtonWithLogo
                  label="Settings"
                  className={`btn--xs gap-1 border border-basic-button`}
                  onClick={OnSettingsClick}
                  renderSvg={() => {
                    return (
                      <img
                        src={iconSettings}
                        alt="icon-settings"
                        className="cursor-pointer"
                        style={{ height: 11, width: 13 }}
                      />
                    );
                  }}
                  style={{ fontSize: 13, fontWeight: 500 }}
                />
              </CustomToolTip>
              <GuideDropdown
                description="Boxes help keep the right enquiries in the right place.Organised by stages to help stay on top of things."
                guideOptions={boxGuideOptions}
              />
              <CustomToolTip
                tooltipText="Add enquiries to this box"
                position="bottom"
                tooltipStyle={{ maxWidth: 105, top: '110%' }}
              >
                <PrimaryWithLogo
                  label="Enquiry"
                  className="btn--xs gap-1 text-13 font-medium"
                  renderSvg={() => <PlusIcon />}
                  style={{ padding: '5px 11px' }}
                  onClick={() => setShowEnquiryModal(true)}
                />
              </CustomToolTip>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

BoxHeader.defaultProps = {
  title: '',
  HeaderIcon: () => <></>,
  showToggle: false,
  addReminderBtn: false,
  setIsActive: () => {},
  isActive: false,
  hideText: false,
  isBoxScreen: false,
  isFilterClicked: false,
  setIsFilterClicked: () => {},
  onBack: () => {}
};

export default BoxHeader;

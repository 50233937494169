import TextArea from 'components/inputs/textarea';
import BackArrow from '../../components/backArrow';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deleteProduct,
  getProduct,
  updateProduct
} from 'slices/ai-assistant/productInfo/slice';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import TextInputField from 'components/inputs/textField';
import ErrorMessage from 'components/errorMessage';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'utils/utils';
import { formatDateTime } from 'utils/dateFormat';
import CustomColorButtonWithLogo from 'components/buttons/customColorButtonWithLogo';
import CustomColorButton from 'components/buttons/customColorButton';
import { ReactComponent as RedDeleteIcon } from 'assets/icons/icon-bin-it-red.svg';
import OverLayLoader from 'components/loader/overLayLoader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import Avatar from 'components/avatar';
import { urlFieldValidation } from 'components/fieldsAndValidation';
import { showNameEmail } from 'utils/aiAssistant';
import { useSnackbar } from 'components/Snackbar';
import Select from 'components/inputs/select';
import CHOICES from 'choices';

const EditProductInfo = () => {
  const { productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const singleProductData = useSelector(
    (state) => state.productInfo.singleProductData
  );
  const { currenciesData } = useSelector((state) => state.businessInfoSettings);
  const [selectedValue, setSelectedValue] = useState({});
  const { openSuccessSnackBar } = useSnackbar();
  const id = useSelector((state) => state?.myInfo?.myInformation?.id);
  const updateProductCallback = () => {
    setIsLoading(false);
  };

  const CurrencyOptions = currenciesData.map((item, index) => {
    return {
      label: `${CHOICES.CurrencyChoice.CHOICES[`${item?.code}`]}`,
      value: CHOICES.CurrencyChoice.CHOICES[`${item?.code}`],
      icon: item?.image_link
    };
  });

  useEffect(() => {
    setIsLoading(true);
    reset({
      name: '',
      price: 0,
      description: '',
      bookingLink: '',
      moreinfoLink: '',
      productOrServiceLink: '',
      last_edited_on: '',
      last_edited_by: {},
      times_used: 0
    });
    dispatch(getProduct(productId, updateProductCallback));
  }, []);

  const formSchema = {
    name: string().required('Please provide name'),
    price: string()
      .required('Please provide price')
      .test('greater than zero', 'Price must be greater than zero', (value) => {
        return parseFloat(value) > 0;
      }),
    description: string().required('Please provide description'),
    bookingLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid link url',
      excludeEmptyString: true
    }),
    moreinfoLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid link url',
      excludeEmptyString: true
    }),
    productOrServiceLink: string().matches(urlFieldValidation, {
      message: 'Please provide valid link url',
      excludeEmptyString: true
    })
  };
  const {
    register,
    reset,
    getValues,
    formState: { errors, touchedFields }
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(object().shape(formSchema))
  });

  useEffect(() => {
    if (!isEmpty(singleProductData)) {
      const {
        name,
        price,
        description,
        link1,
        link2,
        link3,
        last_edited_on,
        times_used,
        last_edited_by,
        currency
      } = singleProductData;
      reset({
        name,
        price,
        description,
        bookingLink: link1,
        moreinfoLink: link2,
        productOrServiceLink: link3,
        last_edited_on,
        last_edited_by,
        times_used,
        currency
      });
      let selectedCurrency = CurrencyOptions.find((i) => i.value === currency);
      setSelectedValue(selectedCurrency);
    }

    return () => {
      reset({
        name: '',
        price: '',
        currency: 'GBP',
        description: '',
        bookingLink: '',
        moreinfoLink: '',
        productOrServiceLink: ''
      });
    };
  }, [singleProductData]);

  const successCb = (type) => {
    setIsLoading(false);
    if (type === 'update') {
      openSuccessSnackBar('Updated Successfully', 'large');
    } else if (type === 'delete') {
      openSuccessSnackBar('Deleted Successfully', 'large');
    }
    navigate(-1);
  };

  const onRemove = () => {
    setIsLoading(true);
    dispatch(deleteProduct(productId, successCb, failedCb));
  };

  const failedCb = () => {
    setShowDelete(false);
    setIsLoading(false);
  };
  const onSubmit = async () => {
    if (!isEmpty(errors)) return;
    setIsLoading(true);
    const {
      name,
      price,
      description = '',
      bookingLink = '',
      moreinfoLink = '',
      productOrServiceLink
    } = getValues();
    const payload = {
      name,
      price,
      description,
      currency: selectedValue?.value,
      link1: bookingLink,
      link2: moreinfoLink,
      link3: productOrServiceLink,
      edited_by_id: id
    };
    dispatch(updateProduct(payload, productId, successCb, failedCb));
  };
  const { name: userName, email, avatar } = getValues('last_edited_by') || {};

  const showAvatar = () => {
    if (!isEmpty(getValues('last_edited_by'))) {
      if (!isEmpty(avatar) || !isEmpty(userName)) {
        return (
          <Avatar
            className="avatar-container avatar-container--full mr-2"
            style={{
              width: 22,
              height: 22,
              boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
            }}
            src={avatar}
            initial={userName}
            singleChar
            small
            initialStyle={{ fontSize: '12px' }}
          />
        );
      }
    }
    return '';
  };

  const handleCurrencyChange = (currency) => {
    setSelectedValue(currency);
  };

  const handlePriceInputChange = (e) => {
    const { value } = e.target;
    const numericValue = value.replace(/[^\d.]+/g, '');
    const parts = numericValue.split('.');

    if (parts.length > 1) {
      const integerPart = parts[0];
      let fractionalPart = parts[1].slice(0, 2);

      e.target.value = `${integerPart}.${fractionalPart}`;
    } else {
      e.target.value = numericValue;
    }
  };

  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <section>
        <BackArrow>
          <div className="flex">
            <CustomColorButtonWithLogo
              label="Delete"
              renderSvg={() => <RedDeleteIcon />}
              className="w-[99.6px] bg-[#FEF2F2] !font-medium h-[42px] text-[16.8px] text-[#EB4F54] gap-1 mr-2 hover:bg-[#f8d4d5]"
              style={{ padding: '7px 12px' }}
              onClick={() => setShowDelete(true)}
              enableLoader
              isLoading={false}
            />
            <CustomColorButton
              label="Save & Sync"
              className="btn--wide-md-40 text-base-1 bg-[#5e60ed94] h-[42px] text-white mr-3 cursor-pointer relative hover:bg-[#585aebc2] "
              onClick={onSubmit}
              type="submit"
              enableLoader={isLoading}
            />
          </div>
        </BackArrow>
        <div className="mx-auto p-0 py-5" style={{ width: 670 }}>
          <h3 className="text-lg font-semibold">Product or service name</h3>
          <form onSubmit={onSubmit}>
            <div className="mt-5">
              <TextInputField
                autoComplete={'off'}
                placeholder="Add your product or service name"
                name="name"
                type="text"
                className="input-field input-field--h48"
                error={errors.name && touchedFields.name}
                register={register}
              />
              {errors.name && touchedFields.name && (
                <ErrorMessage message={errors?.name?.message} />
              )}
            </div>
            <div className="mt-5">
              <label className="block text-base-1 font-semibold mb-1">
                Price
              </label>
              <div className="flex  justify-between">
                <div
                  className={`flex justify-between items-center
              }`}
                >
                  <div className="relative">
                    <div>
                      <Select
                        name="currency"
                        isHaveIcon={true}
                        options={CurrencyOptions}
                        iconStyle={{ width: 28, height: 18, marginRight: 10 }}
                        inputClassName={`input-field--sm-38  
                    ${selectedValue?.label ? '!pl-[50px]' : ''}`}
                        dropDownClassName="p-2 !w-[300px]"
                        value={selectedValue?.label}
                        selectedIconStyle={{
                          position: 'absolute',
                          top: 10,
                          zIndex: 1,
                          left: 8,
                          width: '35px'
                        }}
                        renderIcon={() => {
                          return (
                            <img
                              style={{
                                position: 'absolute',
                                top: 10,
                                zIndex: 1,
                                left: 8,
                                width: '35px'
                              }}
                              src={selectedValue?.icon}
                            />
                          );
                        }}
                        selectedIcon={selectedValue?.icon}
                        onChange={(value) => handleCurrencyChange(value)}
                        inputStyle={{ paddingLeft: 46 }}
                        placeholder="Select currency"
                        disabled={false}
                        readOnly={true}
                        style={{
                          cursor: 'auto',
                          width: 130,
                          height: 41,
                          border: '1.5px solid #c3c2c0'
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="w-[520px]">
                  <TextInputField
                    autoComplete={'off'}
                    name="price"
                    type="text"
                    className="input-field input-field--h48"
                    placeholder="Use the correct currency sign, such as (£,$,€)"
                    error={errors.price && touchedFields.price}
                    register={register}
                    onInput={handlePriceInputChange}
                  />
                </div>
              </div>
              {errors.price && touchedFields.price && (
                <ErrorMessage
                  className="ml-[150px]"
                  message={errors?.price?.message}
                />
              )}
            </div>
            <div className="mt-5">
              <label className="block text-base-1 font-semibold mb-1">
                Description
              </label>
              <TextArea
                name="description"
                rows="3"
                cols="5"
                placeholder="Write a description for your product or service"
                className="resize-none"
                error={errors.description && touchedFields.description}
                register={register}
              ></TextArea>
              {errors.description && touchedFields.description && (
                <ErrorMessage message={errors?.description?.message} />
              )}
            </div>
            <div className="mt-5">
              <label className="block text-base-1 font-semibold mb-1">
                Add links
              </label>
              <TextInputField
                name="bookingLink"
                type="text"
                placeholder="Add a booking link"
                className="input-field input-field--h48"
                error={errors.bookingLink && touchedFields.bookingLink}
                register={register}
              />
              {errors.bookingLink && touchedFields.bookingLink && (
                <ErrorMessage message={errors?.bookingLink?.message} />
              )}
            </div>
            <div className="mt-5">
              <TextInputField
                autoComplete={'off'}
                name="moreinfoLink"
                className="input-field input-field--border2 input-field--h42 mt-2"
                placeholder="Add a link for more information"
                error={errors.moreinfoLink && touchedFields.moreinfoLink}
                register={register}
              />
              {errors.moreinfoLink && touchedFields.moreinfoLink && (
                <ErrorMessage message={errors?.moreinfoLink?.message} />
              )}
            </div>
            <div className="mt-5">
              <TextInputField
                autoComplete={'off'}
                name="productOrServiceLink"
                className="input-field input-field--border2 input-field--h42 mt-2"
                placeholder="Add a link for product or service image"
                error={
                  errors.productOrServiceLink &&
                  touchedFields.productOrServiceLink
                }
                register={register}
              />
              {errors.productOrServiceLink &&
                touchedFields.productOrServiceLink && (
                  <ErrorMessage
                    message={errors?.productOrServiceLink?.message}
                  />
                )}
            </div>
          </form>
          <div className="mt-7 p-8 border border-box-border border-radius-10">
            <div className="flex flex-wrap -mx-4">
              <div className="col-4 px-4" style={{ color: '#727272' }}>
                Last edited by
              </div>
              <div className="col-8 px-4">
                <div className="flex items-center">
                  {showAvatar()}
                  <p className={!isEmpty(avatar) ? 'ml-2' : ''}>
                    {showNameEmail(userName, email)}
                  </p>
                </div>
              </div>
              <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
                Last edited on
              </div>
              <div className="col-8 px-4 mt-5">
                <p className="">
                  {!isEmpty(getValues('last_edited_on')) &&
                    formatDateTime(getValues('last_edited_on'), 'mmm dd, yyyy')}
                </p>
              </div>
              <div className="col-4 px-4 mt-5" style={{ color: '#727272' }}>
                Times used in answers
              </div>
              <div className="col-8 px-4 mt-5">
                <p className="">{getValues('times_used')}</p>
              </div>
            </div>
          </div>
        </div>
        <DeleteConfirmation
          showConfirmation={showDelete}
          onCancel={() => setShowDelete(false)}
          onConfirm={() => onRemove()}
          title="Are you sure you want to delete?"
          description=""
          isLoading={isLoading}
        />
      </section>
    </>
  );
};

export default EditProductInfo;

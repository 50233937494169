import { createSlice } from '@reduxjs/toolkit';

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    number: 0,
    showImagePreview: false,
    showDeleteConfirmation: false,
    imagePreview: null,
    previewImageIndex: null
  },
  reducers: {
    setNumber: (state, action) => {
      state.number = action.payload;
    },
    increment: (state, action) => {
      state.number = state.number + 1;
    },
    decrement: (state, action) => {
      state.number = state.number - 1;
    },
    setItem: (state, action) => {
      state[action.payload.key] = action.payload.value;
    },
    setImagePreview: (state, action) => {
      state.imagePreview = action.payload;
    }
  }
});

export const { setNumber, increment, decrement, setItem, setImagePreview } =
  appSlice.actions;

export const makeApiCall = () => {
  return async (dispatch) => {
    try {
      // const resp = await axios
      //   .get('http://localhost:6318/data.json');
      const response = await fetch('http://localhost:6318/data.json');
      const data = await response.json();
      dispatch(setNumber(data.data.number));
      return data.data.number;
    } catch (e) {
      dispatch(setNumber(10));
      return '';
    }
  };
};

export default appSlice.reducer;

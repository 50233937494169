import React, { useState, useRef } from 'react';
import UserIcon from 'assets/icons/user-icon-grey.svg';
// import iconAdd from 'assets/icons/icon-add.svg';
import useClickOutside from 'hooks/useOutsideClick';
import UserList from 'modules/enquirybox/ebox/module/box/conversation/actions/assignUser/renderList';
import { useSelector, useDispatch } from 'react-redux';
import { setAssignUser } from 'slices/task/slice';
import ButtonItem from './button';
import { isEmpty } from 'utils/utils';
import CustomToolTip from 'components/CustomTooltip';
import { whoever_is_available } from 'slices/organizationuserSlice';

const TaskAssignUser = () => {
  const [showAssignUser, setShowAssignUser] = useState(false);
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { assignUser } = useSelector((state) => state.tasks);
  const dispatch = useDispatch();
  const userRef = useRef();

  useClickOutside(() => setShowAssignUser(false), userRef.current);

  const onSelect = (option) => {
    dispatch(setAssignUser(option));
    setShowAssignUser(false);
  };

  const onDelete = () => {
    dispatch(setAssignUser({}));
    setShowAssignUser(false);
  };

  return (
    <div ref={userRef}>
      <UserList
        open={showAssignUser}
        options={
          organizationUser?.results
            ? [
                whoever_is_available,
                ...organizationUser.results.filter((i) => !i?.is_disabled)
              ]
            : [whoever_is_available]
        }
        onSelect={onSelect}
        active={assignUser}
        onSubmit={onDelete}
        wrapperStyle={{
          right: '0px',
          left: '50px',
          top: 'unset',
          bottom: '42px',
          width: '350px'
        }}
        deleteVisible={false}
      />
      <CustomToolTip
        position="top"
        tooltipText="Who's assigned"
        hideTooltip={showAssignUser}
      >
        <ButtonItem
          active={showAssignUser}
          icon={UserIcon}
          src={!isEmpty(assignUser) ? assignUser.avatar : null}
          text={!isEmpty(assignUser) ? assignUser.name : 'Assign to me'}
          name={!isEmpty(assignUser) ? assignUser.name : ''}
          userAvatar={!isEmpty(assignUser)}
          onClick={() => {
            setShowAssignUser(!showAssignUser);
          }}
          style={{ maxWidth: 150 }}
        />
      </CustomToolTip>
    </div>
  );
};

export default TaskAssignUser;

import React, { useEffect, useRef, useState } from 'react';
import checkIcon from 'assets/icons/icon-check-fill.svg';
import minusIcon from 'assets/icons/icon-minus-fill.svg';
import pencilIcon from 'assets/icons/pencil.svg';
import CustomToolTip from 'components/CustomTooltip';
import TextInputField from 'components/inputs/textField';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const CustomTextField = ({
  HandleInputChange,
  // setCustomField,
  customField,
  item,
  handleUpdate,
  index,
  handleRemove,
  isFocus,
  setIsFocus
}) => {
  const inputRefs = useRef([]);
  // const EditCustomRule = () => {
  //   inputRef?.current?.focus();
  // };
  const [editFlag, setEditFlag] = useState(false);
  const [showCheck, setShowCheck] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    if (item.status === 'New') {
      setEditFlag(true);
      setShowCheck(true);
    }
  }, []);

  useEffect(() => {
    if (isFocus) {
      inputRefs.current[index]?.focus();
    }
  }, [isFocus]);

  useEffect(() => {
    setTimeout(() => {
      if (editFlag) {
        inputRefs.current[index]?.focus();
      } else {
        inputRefs.current[index]?.blur();
      }
    }, 100);
  }, [editFlag]);

  return (
    <div className="flex items-center mt-3">
      <TextInputField
        ref={(ref) => (inputRefs.current[index] = ref)}
        name="CustomTextField"
        type="text"
        className="input-field w-full"
        placeholder="Type your custom rule"
        value={item.rule}
        onChange={(e) => HandleInputChange(item, e, index)}
        containerStyle={{ width: '100%' }}
        disabled={!editFlag}
        autoComplete={'off'}
        onBlur={() => setIsFocus(false)}
      />
      {showCheck ? (
        <CustomToolTip
          tooltipText="Save"
          position="top"
          tooltipStyle={{ minWidth: 70, left: '69%', padding: 5 }}
        >
          <div
            className={
              'flex items-center ml-4  ' +
              (item.rule === ''
                ? 'cursor-not-allowed disabled'
                : 'cursor-pointer')
            }
            onClick={() => {
              if (item.rule !== '') {
                setEditFlag(false);
                setShowCheck(false);
                handleUpdate(item, index);
              }
            }}
            disabled={item.rule === ''}
          >
            <img src={checkIcon} alt="check-icon" />
          </div>
        </CustomToolTip>
      ) : (
        <CustomToolTip
          tooltipText="Edit rule"
          position="top"
          tooltipStyle={{ minWidth: 70, left: '69%', padding: 5 }}
        >
          <div
            className="flex items-center justify-center w-6 h-6 ml-4 cursor-pointer"
            onClick={() => {
              setEditFlag(true);
              setShowCheck(true);
              // EditCustomRule();
            }}
          >
            <img src={pencilIcon} alt="pencil-icon" style={{ height: 15 }} />
          </div>
        </CustomToolTip>
      )}
      <CustomToolTip
        tooltipText="Delete"
        position="top"
        tooltipStyle={{ minWidth: 70, left: '69%', padding: 5 }}
      >
        <div
          className="flex items-center ml-4 cursor-pointer"
          onClick={() => {
            if (item.status === 'New') handleRemove(item, index);
            else setShowDelete(true);
          }}
        >
          <img src={minusIcon} alt="delete-icon" />
        </div>
      </CustomToolTip>
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          handleRemove(item, index);
          setShowDelete(false);
        }}
        title="Are you sure you want to delete ?"
        description="Once deleted you will not be able to get it back."
      />
    </div>
  );
};

export default CustomTextField;

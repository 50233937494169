import { Routes, Route } from 'react-router-dom';
import WebsiteLinks from './websiteLinks';
import EditWebsiteLink from './EditLink';
import BackArrow from '../components/backArrow';

const WebsiteLinkRoutes = () => {
  return (
    <>
      <BackArrow />
      <Routes>
        <Route path="/" element={<WebsiteLinks />} />
        <Route path="/edit" element={<EditWebsiteLink />} />
      </Routes>
    </>
  );
};

export default WebsiteLinkRoutes;

import React from 'react';
import HeaderLess from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import CardTitle from 'components/layout/cardTitle';
import InputField from 'components/inputs/textField';
import PrimaryButton from 'components/buttons/primary';
import Error from 'components/errorMessage';
import { object } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getFormSchema } from 'components/fieldsAndValidation';
import { ReactComponent as EmailIcon } from 'assets/icons/icon-email.svg';

const ResendEmail = () => {
  const formSchema = getFormSchema(['email']);
  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields }
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'onChange'
  });

  const onSubmit = () => {};

  return (
    <HeaderLess>
      <CardLayout
        className="mt-8"
        style={{ paddingLeft: '6rem', paddingRight: '6rem' }}
      >
        <CardTitle title="Don’t see the confirmation email?" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6">
            <p className="text-sm text-grey-800 text-center">
              We&apos;ve sent it to{' '}
              <span className="text-primary">madisoninouye@gmail.com</span>
            </p>
            <p className="text-sm text-grey-800 text-center mt-4">
              Try checking again in a minute, check your spam folder, or request
              another email here.
            </p>
            <div className="mt-10">
              <InputField
                name="email"
                renderIcon={() => <EmailIcon />}
                placeholder="madisoninouye@gmail.com"
                icon={true}
                register={register}
                error={errors.email && touchedFields.email}
                required
              />
              {errors.email && touchedFields.email && (
                <Error message={errors.email.message} />
              )}
            </div>
          </div>
          <PrimaryButton
            type="submit"
            className="w-full mt-6"
            label="Resend Email"
          />
        </form>
      </CardLayout>
    </HeaderLess>
  );
};

export default ResendEmail;

import {
  TOMORROW,
  CUSTOM_DATE,
  MONDAY,
  CALL,
  FOLLOW_UP,
  OTHER
} from 'utils/constants';
import moment from 'moment';

export const TaskTimes = [
  {
    '24hourformat': '00',
    title: '12:00am'
  },
  {
    '24hourformat': '01',
    title: '1:00am'
  },
  {
    '24hourformat': '02',
    title: '2:00am'
  },
  {
    '24hourformat': '03',
    title: '3:00am'
  },
  {
    '24hourformat': '04',
    title: '4:00am'
  },
  {
    '24hourformat': '05',
    title: '5:00am'
  },
  {
    '24hourformat': '06',
    title: '6:00am'
  },
  {
    '24hourformat': '07',
    title: '7:00am'
  },
  {
    '24hourformat': '08',
    title: '8:00am'
  },
  {
    '24hourformat': '09',
    title: '9:00am'
  },
  {
    '24hourformat': '10',
    title: '10:00am'
  },
  {
    '24hourformat': '11',
    title: '11:00am'
  },
  {
    '24hourformat': '12',
    title: '12:00pm'
  },
  {
    '24hourformat': '13',
    title: '1:00pm'
  },
  {
    '24hourformat': '14',
    title: '2:00pm'
  },
  {
    '24hourformat': '15',
    title: '3:00pm'
  },
  {
    '24hourformat': '16',
    title: '4:00pm'
  },
  {
    '24hourformat': '17',
    title: '5:00pm'
  },
  {
    '24hourformat': '18',
    title: '6:00pm'
  },
  {
    '24hourformat': '19',
    title: '7:00pm'
  },
  {
    '24hourformat': '20',
    title: '8:00pm'
  },
  {
    '24hourformat': '21',
    title: '9:00pm'
  },
  {
    '24hourformat': '22',
    title: '10:00pm'
  },
  {
    '24hourformat': '23',
    title: '11:00pm'
  }
];

export const TaskDateList = [
  {
    title: TOMORROW,
    id: TOMORROW
  },
  {
    title: MONDAY,
    id: MONDAY
  },
  {
    title: CUSTOM_DATE,
    id: CUSTOM_DATE
  }
];

export const CallList = [
  {
    title: CALL
  },
  {
    title: FOLLOW_UP
  },
  {
    title: OTHER
  }
];

export const getComingMonday = () => {
  const dayINeed = 1; // for Monday
  return moment().add(1, 'weeks').isoWeekday(dayINeed);
};

export const isTodayMonday = () => {
  const dayINeed = 1; // for Monday
  const today = moment().isoWeekday();
  if (today <= dayINeed) {
    return true;
  }
};

export const getTomorrowDate = () => {
  return moment().add(1, 'day');
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { ReactComponent as EditIcon } from 'assets/icons/icon-edit-primary-color.svg';
import InputWithButton from 'components/inputs/inputWithButton';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
// import { getFormSchema } from 'components/fieldsAndValidation';
import { updateContactDetailsInBox } from 'slices/boxes/slice';
import { setIsLoading, updateContactDetails } from 'slices/questionSlice';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getLeadData } from 'slices/conversationSlice';
import {
  getReadLeads,
  getReplyLaterLeads,
  getUnReadLeads
} from 'slices/eboxLead';
import TextInputField from 'components/inputs/textField';
import CustomColorButton from 'components/buttons/customColorButton';
import PrimaryButton from 'components/buttons/primary';
import { isEmpty } from 'utils/utils';

const NameItem = ({ name, isBin }) => {
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [inputError, setInputError] = useState('');
  const [inputValue, setInputValue] = useState(name);

  useEffect(() => {
    setInputValue(name);
  }, [cardId, name]);

  const onCancel = () => {
    setIsEdit(false);
    setInputError('');
    setInputValue(name);
  };

  const getFirstName = (value) => {
    if (value.includes(' ')) {
      const firstName = value.split(' ');
      return firstName[0];
    } else {
      return value;
    }
  };

  const getLastName = (value) => {
    if (value.includes(' ')) {
      const lastName = value.split(' ');
      return lastName[lastName.length - 1];
    } else {
      return '';
    }
  };

  const loadAllLeads = () => {
    // Promise.all([
    //   dispatch(getReadLeads({ subOrgId: subOrganizationId })),
    //   dispatch(getUnReadLeads({ subOrgId: subOrganizationId })),
    //   dispatch(getReplyLaterLeads({ subOrgId: subOrganizationId }))
    // ]).then(() => {
    // setIsLoading(false);
    // });
  };

  const callback = () => {
    setIsEdit(false);
    dispatch(setIsLoading(false));
    // dispatch(getLeadData({ leadId: cardId }));
    // loadAllLeads();
  };

  const onChange = (e) => {
    setInputError('');
    const value = e.target.value;
    setInputValue(value);
  };

  const onNameSubmit = (e) => {
    e.preventDefault();
    if (!isEmpty(inputValue)) {
      if (inputValue === name) {
        setIsEdit(false);
      } else {
        dispatch(setIsLoading(true));
        const formData = {
          name: inputValue,
          first_name: getFirstName(inputValue),
          last_name: getLastName(inputValue)
        };
        // dispatch(
        //   updateContactDetails({
        //     body: formData,
        //     leadId: cardId,
        //     callback: callback
        //   })
        // );
        if (isBox) {
          dispatch(
            updateContactDetailsInBox({
              body: formData,
              leadId: cardId,
              callback: callback
            })
          );
        }
        if (!isBox) {
          dispatch(
            updateContactDetails({
              body: formData,
              leadId: cardId,
              callback: callback
            })
          );
        }
      }
    } else {
      setInputError('name is required');
    }
  };

  return (
    <form autoComplete="off">
      {isEdit ? (
        <div className="relative">
          <TextInputField
            name="name"
            className="input-field--md"
            onChange={onChange}
            value={inputValue || ''}
            error={!isEmpty(inputError) ? true : false}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                onNameSubmit(e);
              }
            }}
            placeholder=""
          />
          <CustomColorButton
            className="btn--xs btn--input-cancel btn--input-cancel--sm btn--grey text-xs"
            label="Cancel"
            onClick={onCancel}
          />
          <PrimaryButton
            label="Save"
            className="btn--xs btn--copy btn--copy--xs text-xs"
            onClick={(e) => {
              onNameSubmit(e);
            }}
          />
        </div>
      ) : (
        <div
          className="flex justify-between items-center bg-color-on-hover bg-color-on-hover--sm__left bg-color-on-hover--bg-grey-100 bg-color-on-hover--br-6 py-1"
          onMouseEnter={() => setShowEditIcon(true)}
          onMouseLeave={() => setShowEditIcon(false)}
        >
          <h3 className="font-bold cursor-pointer">{name}</h3>
          {showEditIcon && !isBin && (
            <EditIcon
              className="cursor-pointer mr-3"
              onClick={() => setIsEdit(true)}
            />
          )}
        </div>
      )}
    </form>
  );
};

export default NameItem;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { BOT_ID, EBOT_EMAIL } from 'utils/constants';
import queryString from 'query-string';

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const authToken = useSelector((state) => state.auth.authToken);
  const queryParams = queryString.parse(location?.search);

  useEffect(() => {
    if (location?.pathname.includes('/confirm_ebot_to_ebox_connection')) {
      localStorage.setItem(EBOT_EMAIL, queryParams?.email);
      localStorage.setItem(BOT_ID, queryParams?.bot_id);
    }
  }, []);

  return authToken ? children : <Navigate to="/login" />;
};

ProtectedRoute.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.node,
    PropTypes.object
  ]).isRequired
};

export default ProtectedRoute;

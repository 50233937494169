/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import iconChatBot from 'assets/icons/icon-enquirybot-round.svg';
import CancelSubscription from 'modules/modals/CancelSubscription';
import ToolBoxHeader from './component/header';
import ChannelSettingsLink from 'modules/enquirybox/channels/components/channelSettingsLink';
import iconSms from 'assets/icons/icon-sms-msg.svg';
import iconWhatsApp from 'assets/icons/icon-whatsapp-toolbox.svg';
import { Navigate, Route, Routes } from 'react-router-dom';
import EnquiryBot from './module/enquirybot';
import WhatsApp from './module/whatsapp';
import Sms from './module/sms';
import { route } from 'utils/route';
import NavBar from 'components/navigation/navbar';

const ToolBox = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isConnected, setIsConnected] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const navLinks = [
    {
      id: 1,
      title: 'EnquiryBot',
      desc: 'Custom Built Intelligent Chatbot',
      icon: iconChatBot,
      to: `${route.toolBox}/enquiry-bot`
    },
    {
      id: 2,
      title: 'SMS',
      desc: 'Send and Receive SMS messages',
      icon: iconSms,
      to: `${route.toolBox}/sms`
    },
    {
      id: 3,
      title: 'WhatsApp Business',
      desc: 'Send and reply to WhatsApp messages',
      icon: iconWhatsApp,
      to: `${route.toolBox}/whatsapp`
    }
  ];

  return (
    <>
      <NavBar />
      <section className="dashboard__main toolbox-wrapper">
        <ToolBoxHeader />
        <div className="dashboard__main__body toolbox-body">
          <div className="dashboard__main__body__section-left overflow-overlay toolbox__navigation">
            {navLinks.map((item) => {
              return (
                <ChannelSettingsLink
                  key={item.id}
                  icon={item.icon}
                  title={item.title}
                  subTitle={item.desc}
                  to={item.to}
                  showComingSoon={item.id == 3}
                />
              );
            })}
          </div>
          <Routes>
            <Route
              path="/"
              element={<Navigate to={`${route.toolBox}/enquiry-bot`} />}
            />
            <Route
              path="/enquiry-bot"
              element={
                <EnquiryBot
                  setShowCancelModal={setShowCancelModal}
                  setIsConnected={setIsConnected}
                  isConnected={isConnected}
                  setActiveTab={setActiveTab}
                  activeTab={0}
                />
              }
            />
            <Route path="/whatsapp" element={<WhatsApp />} />
            <Route
              path="/sms"
              element={
                <Sms
                  isConnected={isConnected}
                  setActiveTab={setActiveTab}
                  activeTab={activeTab}
                  setIsConnected={setIsConnected}
                />
              }
            />
          </Routes>
        </div>
      </section>
      <CancelSubscription
        open={showCancelModal}
        setIsConnected={setIsConnected}
        onClose={() => setShowCancelModal(false)}
        setActiveTab={setActiveTab}
      />
    </>
  );
};

export default ToolBox;

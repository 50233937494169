/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { configureStore, createAsyncThunk } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import reducers, { whitelist, getMiddleWare } from './reducers';
// import { authApi } from 'services/auth';
import { setupListeners } from '@reduxjs/toolkit/query/react';

const persistConfig = {
  key: 'root',
  whitelist,
  storage
};

const persistedReducer = persistReducer(persistConfig, reducers);

// const reducerProxy = (state, action) => {
//   if (action.type === 'logout/LOGOUT') {
//     return persistedReducer(undefined, action);
//   }
//   return persistedReducer(state, action);
// };

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getMiddleWare
});

export const clearStore = createAsyncThunk(
  'auth/logout',
  async function (_payload, thunkAPI) {
    // thunkAPI.dispatch({ type: 'logout/LOGOUT' });
  }
);

const persistor = persistStore(store);
setupListeners(store.dispatch);

export default function AppProvider({ children }) {
  useEffect(() => {
    if (typeof window !== 'undefined' && window) {
      window.__STORE__ = store;
    }
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
}

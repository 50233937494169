import UpgradePlanTopbar from 'components/UpgradePlanTopbar';
import PropTypes from 'prop-types';

const Wrapper = (props) => {
  const { showUpgradeTopbar, children } = props;
  return (
    <>
      {showUpgradeTopbar && (
        <UpgradePlanTopbar text="Upgrade to use this feature. Click here to upgrade" />
      )}
      <div
        className="dashboard__main__body__section-right layout-1"
        style={
          showUpgradeTopbar
            ? {
                backgroundColor: 'white',
                overflowX: 'hidden',
                width: '100%',
                height: 'calc(100% - 45px)'
              }
            : {
                backgroundColor: 'white',
                overflowX: 'hidden',
                width: '100%'
              }
        }
      >
        {children}
      </div>
    </>
  );
};

export default Wrapper;

Wrapper.propTypes = {
  showUpgradeTopbar: PropTypes.bool
};
Wrapper.defaultProps = {
  showUpgradeTopbar: false
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import EbBoxChannels from 'modules/enquirybox/ebox/module/channel/eboxChannels';
import EbBox from 'modules/enquirybox/ebox/module/box';
import Wrapper from './wrapper';
import { useSelector, useDispatch } from 'react-redux';
import {
  setPreviousClickedLead,
  setPreviousClickedLeadList
} from 'slices/eboxLead';
import { getMyInfo } from 'slices/myInfoSlice';

const EnquiryboxHome = () => {
  const dispatch = useDispatch();
  const isChannelsConnected = useSelector(
    (state) => state.myInfo.isChannelsConnected
  );

  useEffect(() => {
    dispatch(getMyInfo());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setPreviousClickedLeadList(0));
      dispatch(setPreviousClickedLead(null));
    };
  }, []);

  return (
    <Routes>
      <Route
        path="/*"
        element={
          isChannelsConnected === true ? (
            <Wrapper>
              <EbBox />
            </Wrapper>
          ) : (
            <Wrapper>
              <EbBoxChannels />
            </Wrapper>
          )
        }
      />
      {/* <Route path="/home" element={<EbBox />} /> */}
    </Routes>
  );
};

export default EnquiryboxHome;

import React from 'react';
import ebTeam from 'assets/images/eb-team-pic.png';
import helpGuide from 'assets/icons/icon-help-guide.svg';
// import chatMsg from 'assets/icons/icon-chat-msg.svg';

const DescriptionAside = ({ helpGuideUrl }) => {
  const onHelpClick = () => {
    if (typeof helpGuideUrl == 'string') {
      window.open(helpGuideUrl, '_blank');
    }
    return;
  };

  return (
    <>
      <img src={ebTeam} alt="eb-team-pic" />
      <h3 className="text-15 font-semibold mt-3">Have a question?</h3>
      <p className="text-13 mt-2">The Enquirybox team are here to help</p>
      <ul className="mt-3">
        <li
          className="flex items-center mb-2 cursor-pointer"
          onClick={onHelpClick}
        >
          <img src={helpGuide} alt="icon-help-guide" />
          <span className="text-primary text-13 ml-2">Read the help guide</span>
        </li>
        {/* <li className="flex items-center cursor-pointer">
          <img src={chatMsg} alt="icon-chat-msg" />
          <span className="text-primary text-13 ml-2">Drop us a message</span>
        </li> */}
      </ul>
    </>
  );
};

export default DescriptionAside;

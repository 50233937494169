/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import ErrorMessage from 'components/errorMessage';
import Img from 'components/img';
import InputWithButton from 'components/inputs/inputWithButton';
import React, { useEffect, useRef, useState } from 'react';
import iconMoreRound from 'assets/icons/icon-more-round.svg';
import deleteIcon from 'assets/icons/icon-Delete.svg';
import iconEdit from 'assets/icons/icon-edit.svg';
import useClickOutside from 'hooks/useOutsideClick';
import { useParams } from 'react-router-dom';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { maximumCharactersMsg } from 'utils/messages';
import { isEmpty } from 'utils/utils';
import { useDispatch } from 'react-redux';
import { renameStage } from 'slices/boxes/slice';
import BoxDeleteConfirmation from 'modules/boxes/stages/stage/deleteStageModal';
import { useSnackbar } from 'components/Snackbar';

const Stages = (props) => {
  const { item, index, addStage, stageleadCount } = props;
  const [stageName, setStageName] = useState(item.stage_name);
  const [error, setError] = useState();
  const [showOptions, setShowOptions] = useState(false);
  const nodeRef = useRef();
  const { box_id } = useParams();
  const [isRename, setIsRename] = useState(false);
  const [showDorpDownOptionsIndex, setShowDorpDownIndex] = useState();
  const [isShowStage, setIsShowStage] = useState();
  const [stageId, setStageId] = useState();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const dispatch = useDispatch();

  const formSchema = {
    stageName: string().required('Please provide name')
    // .max(30, maximumCharactersMsg('stage name', 30))
  };

  useEffect(() => {
    setError('');
  }, [box_id]);

  // not using react hook form the below code just to pass tochfields and error as it is mandatory to pass error and touchfields for inputWithButton field
  const {
    formState: { errors, touchedFields },
    setValue
  } = useForm({
    resolver: yupResolver(object().shape(formSchema)),
    mode: 'all'
  });

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
  };

  const onSuccess = () => {
    setIsLoading(false);
    setIsShowStage(false);
    setIsRename(false);
    openSuccessSnackBar('Success');
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(stageName)) {
      setError('Please add stage name');
      return;
    }
    // else {
    //   if (stageName.length > 30) {
    //     setError('name can not be more than 30 characters');
    //     return;
    //   }
    // }
    setIsLoading(true);
    dispatch(
      renameStage(
        {
          stageId: stageId,
          body: {
            stage_name: stageName,
            box: box_id
          }
        },
        onSuccess,
        onError
      )
    );
  };

  useEffect(() => {
    if (isRename && showDorpDownOptionsIndex === index) {
      document.getElementById(`${item?.stage_name}-${index}`)?.focus();
    }
  }, [isRename, showDorpDownOptionsIndex]);

  useClickOutside(() => setShowOptions(false), nodeRef.current);

  return (
    <>
      <BoxDeleteConfirmation
        showConfirmation={showDeleteConfirmation}
        onCancel={() => {
          setShowDeleteConfirmation(false);
        }}
        stage_id={stageId}
        stageleadCount={stageleadCount[0]?.no_of_leads}
      />
      {isRename && showDorpDownOptionsIndex === index && (
        //can not use the react hook form because while mapping for some reson hook form dose allow the submit the form while mapping
        <form onSubmit={onSubmit}>
          <div className={`w-full ${addStage ? 'is-open' : ''} `}>
            <div className="relative">
              <InputWithButton
                id={`${item?.stage_name}-${index}`}
                buttonLabel="Save"
                inputName={`${item?.stage_name}-${index}`}
                placeholder="Type your stage name"
                onChange={(e) => setStageName(e.target.value)}
                buttonClassName="btn--md btn--copy text-md mr-2"
                medium
                defaultValue={item?.stage_name}
                errors={errors}
                touchedFields={touchedFields}
                isCancelButton={true}
                cancelButtonClassName="btn--md btn--input-cancel btn--grey text-md mr-2"
                cancelButtonLabel="Cancel"
                onCancel={() => {
                  setIsRename(false);
                  setValue('stageRename', item?.stage_name);
                  setIsShowStage();
                  setError('');
                }}
                isLoading={isLoading}
                enableLoader={true}
                style={{
                  paddingRight: '155px',
                  fontSize: '15px',
                  height: '55px'
                }}
              />
            </div>

            {error && <ErrorMessage message={error} />}
          </div>
        </form>
      )}
      {isShowStage !== index && (
        <div
          className="card-box hover:bg-grey-90 flex  px-4 py-4 rounded-sm justify-between delay-100"
          style={{ borderRadius: '8px' }}
          ref={nodeRef}
        >
          <span className="truncate">{item?.stage_name}</span>
          <div
            className={`stage-option cursor-pointer hover:bg-white`}
            onClick={() => {
              setShowOptions(!showOptions);
              setShowDorpDownIndex(index);
              setStageId(item.id);
            }}
          >
            <img src={iconMoreRound} alt="" style={{ width: 16, height: 10 }} />
          </div>
          <div
            className={`dropdown-menu dropdown-menu--more ${
              showOptions && showDorpDownOptionsIndex === index ? 'open' : ''
            }`}
            style={{
              top: 30,
              right: '-33px',
              padding: '10px 0 0 0',
              width: '208px'
            }}
          >
            <div
              className="dropdown-menu__list mb-2"
              onClick={() => {
                setIsRename(true);
                setShowOptions(false);
                setIsShowStage(index);
                setStageId(item?.id);
              }}
              style={{
                marginLeft: 8,
                marginRight: 8
              }}
            >
              <Img
                src={iconEdit}
                alt="rename"
                className="mr-2 "
                style={{ width: 16, height: 16 }}
              />
              Rename
            </div>

            {item?.stage_name !== 'Incoming' && (
              <div
                className="dropdown-menu__list red-text mb-2"
                onClick={() => {
                  setShowDeleteConfirmation(true);
                  setStageId(item.id);
                }}
                style={{
                  marginLeft: 8,
                  marginRight: 8
                }}
              >
                <Img
                  src={deleteIcon}
                  alt="delete"
                  className="mr-2 "
                  style={{ width: 16, height: 16 }}
                />
                Delete
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Stages;

import React from 'react';
import PropTypes from 'prop-types';

const Tab = (props) => {
  const { title, renderSvg, onClick, id, isActive } = props;
  return (
    <div
      className={`tabs-header ${isActive ? 'tabs-header__active' : ''} pt-3`}
      style={{ paddingBottom: 12, fontSize: 14 }}
      onClick={() => onClick(id)}
    >
      {renderSvg && typeof renderSvg === 'function' && renderSvg()}
      <p
        className={`font-semibold ml-2 ${
          isActive ? 'text-primary' : 'text-grey-700'
        }`}
      >
        {title}
      </p>
    </div>
  );
};

Tab.propTypes = {
  title: PropTypes.string,
  renderSvg: PropTypes.func,
  onClick: PropTypes.func,
  isActive: PropTypes.bool
};

export default Tab;

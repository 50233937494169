import { createSlice } from '@reduxjs/toolkit';

export const editorSlice = createSlice({
  name: 'editor',
  initialState: {
    draft: {},
    draftState: false,
    ccEmail: [],
    bccEmail: [],
    selectedFromEmail: {},
    subject: '',
    emailBody: '',
    editorChannel: 'Email',
    attachments: [],
    is_force_close: true,
    is_soft_close: false
  },
  reducers: {
    setEditorState: (state, action) => {
      state.draft = action.payload;
    },
    setDraftState: (state, action) => {
      state.draftState = action.payload;
    },
    setCcEmail: (state, action) => {
      state.ccEmail = action.payload;
    },
    setBccEmail: (state, action) => {
      state.bccEmail = action.payload;
    },
    setAttachments: (state, action) => {
      state.attachments = action.payload;
    },
    setSubject: (state, action) => {
      state.subject = action.payload;
    },
    setSelectedFromEmail: (state, action) => {
      state.selectedFromEmail = action.payload;
    },
    setEmailBody: (state, action) => {
      state.emailBody = action.payload;
    },
    setEditorChannel: (state, action) => {
      state.editorChannel = action.payload;
    },
    setAllEditorState: (state, action) => {
      state.ccEmail = action.payload.ccEmail;
      state.bccEmail = action.payload.bccEmail;
      state.attachments = action.payload.attachments;
      state.subject = action.payload.subject;
      state.emailBody = action.payload.emailBody;
      state.selectedFromEmail = action.payload.selectedFromEmail || {};
    },
    setIsForceClose: (state, action) => {
      state.is_force_close = action.payload;
    },
    setIsSoftClose: (state, action) => {
      state.is_soft_close = action.payload;
    }
  }
});

export const {
  setEditorState,
  setCcEmail,
  setBccEmail,
  setAttachments,
  setSubject,
  setSelectedFromEmail,
  setEmailBody,
  setDraftState,
  setAllEditorState,
  setIsForceClose,
  setIsSoftClose,
  setEditorChannel
} = editorSlice.actions;

export default editorSlice.reducer;

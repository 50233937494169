/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
const right = {
  bottom: '62px',
  left: 'calc(50% - 54px)'
};
let top = {
  bottom: '34px',
  left: 'calc(50% - 153px)'
};
let left = {
  bottom: '-6px',
  left: 'calc(50% - 258px)'
};

let bottom = {
  bottom: '22px',
  left: 'calc(50% - 145px)'
};
const AccessRestricted = (props) => {
  const { alignment, show, customStyle, children } = props;

  const handleStyle = (aligntype, customStyle) => {
    switch (aligntype) {
      case 'left':
        return { ...left, ...customStyle };
      case 'right':
        return { ...right, ...customStyle };
      case 'top':
        return { ...top, ...customStyle };
      case 'bottom':
        return { ...bottom, ...customStyle };
      default:
        return { ...top, ...customStyle };
    }
  };
  return (
    <div className="access-restricted-tooltip">
      {show && (
        <div
          className={
            'access-restricted-tooltip__container ' +
            `${
              alignment
                ? `access-restricted-tooltip__container_${alignment}`
                : ''
            }`
          }
          style={handleStyle(alignment, customStyle)}
        >
          <div className="access-restricted-tooltip__body">
            <p className="text-sm font-semibold text-center">
              Access restricted
            </p>
            <p className="text-11">Only available to Team Admin</p>
          </div>
        </div>
      )}
      {children}
    </div>
  );
};

export default AccessRestricted;

AccessRestricted.defaultProps = {
  customStyle: {},
  alignment: 'top',
  show: false
};

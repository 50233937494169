import React from 'react';
import { ReactComponent as PreviewIcon } from 'assets/icons/icon-automation-preview.svg';
import { ReactComponent as EditIcon } from 'assets/icons/icon-automation-edit.svg';
import { ReactComponent as DelteIcon } from 'assets/icons/icon-automation-delete.svg';
import Toggle from 'components/inputs/toggle';
import { useDispatch } from 'react-redux';
import {
  setIsPreview,
  setOnEdit,
  setTypeofAutomation
} from 'slices/automationStuffSlice';
import { getChannelIcon } from 'utils/channels';
import { useForm } from 'react-hook-form';
import CustomToolTip from 'components/CustomTooltip';
import CHOICES from 'choices';

const { WEBFORM, FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const AutomationItem = (props) => {
  const {
    title,
    automation_type,
    status,
    item,
    handleAutomationStatus,
    onDeleteAutomation,
    execution_count,
    channelType,
    anyWebForm,
    webformName,
    fbLeadAdsformName,
    isAnyFbLeadForms
  } = props;
  const dispatch = useDispatch();

  const { register } = useForm({
    mode: 'onChange'
  });

  const renderFormDetails = () => {
    if (channelType === WEBFORM || channelType === FACEBOOK_LEAD_ADS) {
      if (channelType === WEBFORM) {
        if (anyWebForm) {
          return '(Any Webform)';
        } else {
          return `(${webformName})`;
        }
      }
      if (channelType === FACEBOOK_LEAD_ADS) {
        if (isAnyFbLeadForms) {
          return '(Any Lead Ad Form)';
        } else {
          return `(${fbLeadAdsformName})`;
        }
      }
    } else {
      return '';
    }
  };

  return (
    <div className="border border-grey-400 py-4 pr-6 pl-4 flex justify-between rounded-xl mt-3">
      <div className="flex">
        <div className="mr-4">
          <figure
            className="bg-grey-300 border-radius-10 flex justify-center items-center"
            style={{
              border: '1px solid rgba(0, 0, 0, 0.05)',
              height: 50,
              width: 52
            }}
          >
            <img src={getChannelIcon(automation_type)} alt="automation-icon" />
          </figure>
        </div>
        <div style={{ maxWidth: 700 }}>
          <h2 className="font-semibold truncate">
            {title} {renderFormDetails()}
          </h2>
          <p className="text-sm text-grey-800 mt-1">
            {status
              ? `The automation is live and has run ${execution_count} times`
              : 'This automation is not live - flick the switch to turn it on'}
          </p>
        </div>
      </div>
      <div className="flex items-center">
        <div className="mr-5">
          <CustomToolTip position="top" tooltipText="Preview">
            <PreviewIcon
              className="cursor-pointer"
              onClick={() => {
                dispatch(setIsPreview(true));
                dispatch(setTypeofAutomation(automation_type));
                dispatch(setOnEdit(item));
              }}
            />
          </CustomToolTip>
        </div>
        <div className="mr-5">
          <CustomToolTip position="top" tooltipText="Edit">
            <EditIcon
              className="cursor-pointer"
              onClick={() => {
                dispatch(setTypeofAutomation(automation_type));
                dispatch(setOnEdit(item));
              }}
            />
          </CustomToolTip>
        </div>
        <div className="mr-2">
          <CustomToolTip position="top" tooltipText="Delete">
            <DelteIcon
              className="cursor-pointer"
              onClick={() => onDeleteAutomation(item.id)}
            />
          </CustomToolTip>
        </div>
        <div>
          <CustomToolTip
            position="top"
            tooltipText={status ? 'Turn off' : 'Turn on'}
          >
            <Toggle
              id={item.id}
              register={register}
              checked={status}
              small={false}
              onChange={(evt) => handleAutomationStatus(evt, item)}
            />
          </CustomToolTip>
        </div>
      </div>
    </div>
  );
};

export default AutomationItem;

import { useMemo } from 'react';
import CHOICES from 'choices';
import { useSelector } from 'react-redux';
import { isEmpty } from 'utils/utils';

const { TEAM_ADMIN, TEAM_MEMBER, TEAM_SUPER_ADMIN } =
  CHOICES.TeamMemberRoleChoice;

// This custom hook is to know that the logged in user is Team admin or Team member.

const useGetUserRole = () => {
  const { myInformation } = useSelector((state) => state.myInfo);
  const userType = {
    isAdmin: false,
    isTeamMember: false,
    isSuperUser: false
  };
  const userRoles = useMemo(() => {
    if (myInformation?.my_role === TEAM_ADMIN) {
      userType.isAdmin = true;
    } else if (myInformation?.my_role === TEAM_MEMBER) {
      userType.isTeamMember = true;
    } else if (myInformation?.my_role === TEAM_SUPER_ADMIN) {
      userType.isSuperUser = true;
    }
    return userType;
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myInformation]);

  if (!isEmpty(myInformation) && !isEmpty(userRoles)) {
    return userRoles;
  }

  return userType;
};

export default useGetUserRole;

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  continueChatConversation,
  setBotConversation,
  setSuggestedReplies
} from 'slices/ai-assistant/assistant/slice';
import { useParams } from 'react-router-dom';

const SuggestedReplies = ({ suggestedReplies, autoScroll, setLoading }) => {
  const { botConversation } = useSelector((state) => state.assistant);
  const { assistant_id } = useParams();

  const dispatch = useDispatch();

  const succesCb = () => {
    setLoading(false);
  };
  const failedCb = () => {
    setLoading(false);
  };
  const handleSelect = (item) => {
    setLoading(true);
    dispatch(setSuggestedReplies([]));
    const payload = { answer: item, lead_id: botConversation.lead_id };
    let newdata;
    newdata = {
      ...botConversation,
      history: [
        ...botConversation.history,
        {
          role: 'user',
          message: item
        }
      ]
    };
    dispatch(setBotConversation(newdata));
    dispatch(
      continueChatConversation(assistant_id, payload, succesCb, failedCb)
    );
  };

  useEffect(() => {
    setTimeout(() => {
      if (autoScroll) autoScroll();
    }, 0);
  }, []);

  return (
    <div className="flex items-center flex-wrap mt-5">
      {suggestedReplies?.map((item, index) => (
        <div
          key={index}
          className="chat-bot-list__bubble chat-bot-list__bubble--normal-msg"
          onClick={() => handleSelect(item)}
        >
          {item}
        </div>
      ))}
    </div>
  );
};

export default SuggestedReplies;

import React, { useState, useRef } from 'react';

import help from 'assets/icons/help-icon.svg';
import outIcon from 'assets/icons/Out.svg';
import arrowDown from 'assets/icons/icon-caret-down-fill.svg';
import saveIcon from 'assets/icons/icon-save-white.svg';
import history from 'assets/icons/icon-history.svg';
import caretDowWhite from 'assets/icons/icon-caret-down-white.svg';
import checkIcon from 'assets/icons/icon-check-circle-variation1.svg';
import checkIcon2 from 'assets/icons/icon-check-circle-variation2.svg';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as InfoIcon } from 'assets/icons/Info_fill_red.svg';

import useClickOutside from 'hooks/useOutsideClick';
import CHOICES from 'choices';
import CustomColorButton from 'components/buttons/customColorButton';
import {
  // getMasterBotId,
  getVersionList,
  publishAssistant,
  restoreVersion,
  saveAssistant,
  setBrainErrors,
  setCurrentVersionId,
  setDataCollectionErrors,
  setErrorMain,
  setHeaderErrorCount,
  setListAssistants,
  setPersonalityErrors,
  setVersionStatus,
  setWelcomeErrors
} from 'slices/ai-assistant/assistant/slice';
import { useNavigate, useParams } from 'react-router-dom';
import { isEmpty } from 'utils/utils';
import OverLayLoader from 'components/loader/overLayLoader';
import { formatDateTime } from 'utils/dateFormat';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import Avatar from 'components/avatar';
// botScript api for preview, will uncomment this code later
// import { getBotScript } from 'slices/ai-assistant/deploy/websiteLinks/addToWebsite/slice';
import { useSnackbar } from 'components/Snackbar';
// import CustomToolTip from 'components/CustomTooltip';
const DRAFT = CHOICES.BotVersionStatusChoices.DRAFT;
const READY_TO_PUBLISH = CHOICES.BotVersionStatusChoices.READY_TO_PUBLISH;
const PUBLISHED = CHOICES.BotVersionStatusChoices.PUBLISHED;

const Header = () => {
  const nodeRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const personality = useSelector(
    (state) => state.personality.personalityFields
  );
  const { avatarUrl } = useSelector((state) => state.personality);
  const versionStatus = useSelector((state) => state.assistant.versionStatus);
  useClickOutside(() => setShowDropdown(false), nodeRef.current);
  const { name } = personality;
  const headerErrorCount = useSelector(
    (state) => state.assistant.headerErrorCount
  );
  const isError = useSelector((state) => state?.assistant?.isError);
  const listAssistants = useSelector((state) => state.assistant.listAssistants);
  const versionList = useSelector((state) => state.assistant.versionList);
  const [selectedVersion, setSelectedVersion] = useState({});
  const [restoreFlag, setRestoreFlag] = useState(false);
  const currentVersionId = useSelector(
    (state) => state.assistant.currentVersionId
  );
  const userId = useSelector((state) => state?.myInfo?.myInformation?.id);
  const { assistant_id } = useParams();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  // const filteredObject = listAssistants.filter(
  //   (obj) => obj.version_bot.id === assistant_id
  // );
  // const avatarUrl = filteredObject[0]?.version_bot?.avatar?.s3_url;
  const successPublish = (item, assistantId) => {
    dispatch(setErrorMain({}));
    dispatch(setPersonalityErrors({}));
    dispatch(setWelcomeErrors({}));
    dispatch(setDataCollectionErrors({}));
    dispatch(setBrainErrors({}));
    dispatch(setHeaderErrorCount(0));
    let assistants = [...listAssistants];
    let newdata = assistants.map((item1, i) => {
      if (item1?.version_bot?.id === assistantId) {
        return {
          ...item1,
          ['id']: item.id,
          version_status: item.version_status,
          version_bot: {
            ...item.version_bot,
            ['id']: item.version_bot.id
          }
        };
      }
      return {
        ...item1
      };
    });
    dispatch(setListAssistants(newdata));

    dispatch(setCurrentVersionId(item?.version_bot.id));
    dispatch(setVersionStatus(item?.version_status));
    dispatch(getVersionList(item?.version_bot?.id));
    navigate(`/chatbot-ai/assistant/${item.version_bot.id}`);
    setIsLoading(false);
  };

  const failedPublish = () => {
    setIsLoading(false);
  };

  const handleRestoreResponse = (resData) => {
    navigate(`/chatbot-ai/assistant/${resData?.version_bot?.id}`);
    setIsLoading(false);
  };

  const handleRestoreFailed = () => {
    setIsLoading(false);
  };

  const handleRestore = () => {
    setRestoreFlag(false);
    setIsLoading(true);
    let payload = {
      current_version_id: currentVersionId,
      restore_version_id: selectedVersion?.id,
      user_id: userId
    };
    dispatch(
      restoreVersion(
        payload,
        handleRestoreResponse,
        handleRestoreFailed,
        assistant_id
      )
    );
  };

  // preview fucntionalites, we wil unconmment this later
  // const successCb = (masterBotId) => {
  //   window.open(`/preview/${masterBotId}`);
  // };

  // const handlePreview = () => {
  //   dispatch(getBotScript(assistant_id, successCb));
  // };

  const hasError = (obj) => {
    if (isEmpty(obj)) {
      return false;
    } else {
      return Object.values(obj).some((value) => value !== '' && value !== null);
    }
  };
  const sucCb = (data) => {
    if (data.version_status !== 1) openSuccessSnackBar('Saved successfully');
  };

  const failCb = () => {
    openErrorSnackBar('Saving Failed');
  };

  return (
    <>
      {restoreFlag && (
        <DeleteConfirmation
          showConfirmation={restoreFlag}
          onCancel={() => setRestoreFlag(false)}
          onConfirm={handleRestore}
          title="Are you sure you want restore this version?"
          confirmButtonText="Restore"
          description=""
          mainStyle={{ zIndex: 99999999 }}
        />
      )}
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <div className="create-bot-section-right__header">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Avatar
              className="avatar-container avatar-container--full shadow"
              style={{ width: 45, height: 45 }}
              initial={name}
              singleChar
              src={avatarUrl ? avatarUrl : ''}
              alt="profile-avatar"
            />
            <p className="text-xl font-semibold ml-5">{name}</p>
          </div>
          {headerErrorCount > 0 && (
            <div className="flex items-center">
              <p
                style={{
                  color: '#F55E5E',
                  fontFamily: 'SF Pro Text',
                  display: 'flex'
                }}
              >
                <InfoIcon style={{ marginTop: '4px' }} /> Please complete the
                fields in red before publishing
              </p>
            </div>
          )}
          <div className="flex items-center">
            {/* This is preview button, we will uncomment this later, functionalities
            are alredy added*/}
            {/* <button
              onClick={handlePreview}
              className="btn btn-blue h-10 mr-3 bg-blue-400 text-white"
            >
              Preview
            </button> */}
            <button className="btn btn--secondary btn--help">
              <img src={help} alt="help-icon" className="mr-3" />
              Help
              <img
                src={arrowDown}
                style={{ marginTop: 'auto' }}
                alt="icon-arrow-down"
                className="ml-2 mb-1"
              />
            </button>
            {versionStatus === READY_TO_PUBLISH && (
              <button
                className={`btn cursor-pointer`}
                onClick={() => {
                  // if (versionStatus === (READY_TO_PUBLISH || PUBLISHED)) {
                  navigate(`/chatbot-ai/assistant/${assistant_id}/test-bot`);
                  // }
                }}
                style={{
                  border: '1px solid #cfcdcd',
                  height: '40px',
                  width: '138px',
                  marginLeft: '13px'
                }}
              >
                <img
                  src={outIcon}
                  alt="help-icon"
                  className="mr-3"
                  style={{ paddingTop: '6px' }}
                />
                Test bot
              </button>
            )}
            <div
              className="mx-4 bg-grey-400"
              style={{ width: 1, height: 28 }}
            ></div>
            {!isEmpty(versionList) && versionList?.length > 1 && (
              <div
                className="relative flex items-center btn--primary btn--primary--gradient btn--publish rounded-lg cursor-pointer"
                ref={nodeRef}
              >
                <div
                  className={`h-full flex items-center px-4 text-white text-lg font-semibold
                ${
                  (versionStatus === DRAFT || versionStatus === PUBLISHED) &&
                  '!opacity-50 cursor-not-allowed'
                }`}
                  disabled={
                    versionStatus === DRAFT || versionStatus === PUBLISHED
                  }
                  onClick={() => {
                    if (
                      !(versionStatus === DRAFT || versionStatus === PUBLISHED)
                    ) {
                      setIsLoading(true);
                      dispatch(
                        publishAssistant(
                          successPublish,
                          failedPublish,
                          assistant_id
                        )
                      );
                    }
                  }}
                >
                  Publish
                </div>
                <div
                  className="h-full flex items-center border-l border-white pl-3 pr-2 cursor-pointer"
                  onClick={() => setShowDropdown(!showDropdown)}
                >
                  <div className="w-6 h-6 flex items-center justify-center icon-history-container">
                    <img src={history} alt="icon-history" />
                  </div>
                  <img
                    src={caretDowWhite}
                    alt="icon-caret-down-white"
                    className="ml-1"
                  />
                </div>
                {showDropdown && (
                  <div className="dropdown-menu dropdown-menu--publish open !cursor-auto">
                    <div className="py-3 px-7 border-b border-grey-400">
                      <h3 className="text-lg text-grey-900-secondary font-semibold">
                        Published versions
                      </h3>
                      <p className="text-grey-800 text-13 mt-1">
                        Each time you publish a restorable version is created
                      </p>
                    </div>
                    <div className="px-7 py-3 h-full overflow-auto">
                      {versionList?.map((item, i) => {
                        if (item.is_current) {
                          return (
                            <div key={i}>
                              <div>
                                <p className="text-grey-800 text-13 font-medium">
                                  Current Version
                                </p>
                                <ul className="publish-version-list-wrapper">
                                  <li className="publish-version-list">
                                    <div className="publish-version-list__container">
                                      <img
                                        src={checkIcon}
                                        alt="check-icon"
                                        className="publish-version-list__icon"
                                      />
                                      <div className="publish-version-list__content">
                                        <h3 className="font-semibold text-grey-900">
                                          {!isEmpty(item.created_at) &&
                                            formatDateTime(
                                              item?.created_at,
                                              'mmm dd, yyyy at HH:MM'
                                            )}
                                        </h3>
                                        <p className="text-grey-800 text-15">
                                          By {item?.published_by?.name}
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                              <p className="text-grey-800 text-13 font-medium">
                                Restorable Versions
                              </p>
                            </div>
                          );
                        }

                        return (
                          <div className="mt-2" key={i}>
                            <ul className="publish-version-list-wrapper">
                              <li
                                className="publish-version-list"
                                onClick={() => {
                                  setRestoreFlag(true);
                                  setSelectedVersion(item);
                                }}
                              >
                                <div className="publish-version-list__container">
                                  <img
                                    src={checkIcon2}
                                    alt="check-icon"
                                    className="publish-version-list__icon"
                                  />
                                  <div className="publish-version-list__content">
                                    <h3 className="font-semibold text-grey-900">
                                      {!isEmpty(item.created_at) &&
                                        formatDateTime(
                                          item?.created_at,
                                          'mmm dd, yyyy at HH:MM'
                                        )}
                                    </h3>
                                    <p className="text-grey-800 text-15 date-time-text">
                                      {item?.published_by?.name}
                                    </p>
                                    <p
                                      className="text-15 font-semibold restore-text"
                                      style={{ color: '#52C26D' }}
                                    >
                                      Restore this version
                                    </p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            )}
            {(isEmpty(versionList) || versionList?.length === 1) && (
              <CustomColorButton
                className={`btn btn--primary btn--primary--gradient btn--publish ${
                  (versionStatus === DRAFT || versionStatus === PUBLISHED) &&
                  '!opacity-50'
                }`}
                isDisable={
                  versionStatus === DRAFT || versionStatus === PUBLISHED
                }
                label="Publish"
                onClick={() =>
                  dispatch(
                    publishAssistant(
                      successPublish,
                      failedPublish,
                      assistant_id
                    )
                  )
                }
              />
            )}
            <button
              className={`btn btn--primary btn--save ml-2 ${
                versionStatus !== DRAFT && '!opacity-50'
              }`}
              onClick={() => {
                if (!hasError(isError)) dispatch(saveAssistant(sucCb, failCb));
              }}
              disabled={
                versionStatus === READY_TO_PUBLISH ||
                versionStatus === PUBLISHED ||
                hasError(isError)
              }
            >
              <img src={saveIcon} alt="save-icon" className="mr-2" />
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import PrimaryButton from 'components/buttons/primary';
import Select from 'components/inputs/select';
import Toggle from 'components/inputs/toggle';
import React from 'react';

const OfficeHours = (props) => {
  const {
    businessInfoData,
    handleSave,
    structuredTimings,
    updateOfficeHours,
    isLoading
  } = props;
  return (
    <>
      <div className="card-box mt-6 px-6 py-5">
        <h3 className="text-lg font-semibold">Opening hours</h3>
        <p className="text-base-1 text-grey-800 mt-2">
          Your opening hours will be used if you are running time specific
          automations such as emails or notification during or out of your
          opening hours.
        </p>
        <div>
          <div className="flex">
            <h3
              className="flex-1 text-13 font-semibold"
              style={{ marginLeft: '50%' }}
            >
              From
            </h3>
            <h3 className="flex-1 text-13 font-semibold">To</h3>
          </div>
          <div className="bg-grey-300 border-radius-14 p-5 mt-2">
            {businessInfoData?.office_hours?.map((weekday, index) => {
              return (
                <div className="mt-2" key={weekday.id}>
                  <div className="flex justify-between">
                    <div className="flex-1 text-15 font-semibold flex items-center">
                      {weekday?.day_name}
                    </div>
                    <div className="toggle-slider flex-1 flex items-center">
                      <Toggle
                        id={weekday?.id}
                        name={weekday?.day_name}
                        register={() => {}}
                        checked={weekday?.is_on}
                        onChange={(evt) =>
                          updateOfficeHours(
                            index,
                            weekday.id,
                            evt.target.checked,
                            weekday.start_time_str,
                            weekday.end_time_str,
                            weekday?.day_name
                          )
                        }
                      />
                      <label className="ml-2" htmlFor={weekday.id}>
                        {weekday.is_on ? 'Open' : 'Closed'}
                      </label>
                    </div>
                    <div className="flex-1 mr-2">
                      <Select
                        className="input-field--sm-38"
                        placeholder="0.00 PM"
                        readOnly={true}
                        value={
                          weekday?.start_time_str?.toLowerCase() || '9:00am'
                        }
                        options={structuredTimings}
                        name={`startTime${index}`}
                        dropDownClassName="overflow-y-auto h-50 pl-1"
                        onChange={(value) =>
                          updateOfficeHours(
                            index,
                            weekday.id,
                            weekday.is_on,
                            value.label,
                            weekday.end_time_str
                          )
                        }
                        style={{
                          color: !weekday?.is_on && 'grey'
                        }}
                        disabled={!weekday?.is_on}
                        isSelectDisabled={!weekday?.is_on}
                      />
                    </div>
                    <div className="flex-1">
                      <Select
                        className="input-field--sm-38 disabled"
                        placeholder="0.00 PM"
                        readOnly={true}
                        value={weekday?.end_time_str?.toLowerCase() || '5:00pm'}
                        options={structuredTimings}
                        name={`endTime${index}`}
                        dropDownClassName="overflow-y-auto h-50 pl-1"
                        onChange={(value) =>
                          updateOfficeHours(
                            index,
                            weekday.id,
                            weekday.is_on,
                            weekday.start_time_str,
                            value.label
                          )
                        }
                        style={{
                          color: !weekday?.is_on && 'grey'
                        }}
                        disabled={!weekday?.is_on}
                        isSelectDisabled={!weekday?.is_on}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="flex justify-end mt-3">
            <PrimaryButton
              className="btn btn--xs"
              label="Save"
              isLoading={isLoading}
              enableLoader={true}
              loaderInline={true}
              onClick={() => handleSave()}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default OfficeHours;

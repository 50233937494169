import React from 'react';
import { useSelector } from 'react-redux';
import EnquiriesConvertedToSales from 'modules/enquirybox/reports/components/enquiriesConvertedToSales';
import FilterSectionPublic from 'modules/enquirybox/reports/components/FilterSectionPublic';
import SalesEnquiryPerChannel from 'modules/enquirybox/reports/components/salesEnquiryPerChannel';
import TotalEnquirySales from 'modules/enquirybox/reports/components/totalEnquirySalesReport';
import { isEmpty } from 'utils/utils';

const SalesReportPublic = (props) => {
  const { startDate, endDate, reportType, date_format } = props;
  const { salesReport } = useSelector((state) => state.reports);
  return (
    <div
      className="bg-grey-300 rounded-lg"
      style={{
        margin: 'auto',
        marginTop: 50,
        maxWidth: '1000px',
        padding: 25,
        maxHeight: 'calc(100vh - 115px)'
      }}
    >
      <div className="flex items-center justify-between">
        <h3 className="text-xl font-semibold">Sales report</h3>
        <FilterSectionPublic
          endDate={endDate}
          startDate={startDate}
          reportType={reportType}
          date_format={date_format}
        />
      </div>
      {!isEmpty(salesReport) && (
        <>
          <div className="flex items-center -mx-3 mt-6">
            <TotalEnquirySales
              total={salesReport?.report?.total_enquiries}
              percentage={
                salesReport?.report?.total_enquiries_increase_or_decrease
              }
              selectedDay={date_format}
              isHidePercentage={
                isEmpty(salesReport?.report?.channel_report) ? true : false
              }
            />
            <EnquiriesConvertedToSales
              total={salesReport?.report?.enquires_converted_to_sale}
              totalPrice={salesReport?.report?.total_sales_value}
              percentage={
                salesReport?.report?.converted_sale_increase_or_decrease
              }
              selectedDay={date_format}
              isHidePercentage={
                isEmpty(salesReport?.report?.channel_report) ? true : false
              }
            />
          </div>
          <div className="mt-8">
            <SalesEnquiryPerChannel
              data={salesReport?.report?.channel_report}
              isPublic={true}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default SalesReportPublic;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Loader } from 'components/loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteReplyTemplate,
  getAllReplyTemplates,
  resetData,
  setAllReplyTemplates
} from 'slices/replyTemplate/slice';
import { isEmpty } from 'utils/utils';
import TemplateItem from './component/templateItem';
import { ReactComponent as AddIcon } from 'assets/icons/icon-plus-green.svg';
import TemplateEditor from './component/templateEditor';
import { getAllWebformMappingQuestions } from 'slices/webformMappingSlice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import CHOICES from 'choices';

const ReplyTemplate = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [openEdit, setOpenEdit] = useState(null);
  const [openEditor, setOpenEditor] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);
  const [replyTemplateData, setReplyTemplateData] = useState({});

  const { SMS, EMAIL, FACEBOOK_AND_INSTAGRAM } = CHOICES.ReplyTemplateChoice;

  const { allReplyTemplates } = useSelector((state) => state.replyTemplate);

  useEffect(() => {
    setReplyTemplateData(allReplyTemplates);
    return () => {
      dispatch(resetData());
    };
  }, [allReplyTemplates]);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllQuestions = () => {
    dispatch(getAllWebformMappingQuestions());
  };

  useEffect(() => {
    loadAllQuestions();
  }, []);

  const loadAllReplyTemplates = () => {
    dispatch(getAllReplyTemplates({ callback: callback }));
  };

  useEffect(() => {
    setIsLoading(true);
    loadAllReplyTemplates();
  }, []);

  const onDeleteReplyTemplate = (id) => {
    setOpenDeleteModal(id);
  };

  const deleteCallback = () => {
    setIsDeleteLoading(false);
    setOpenDeleteModal(null);
  };

  const onDelete = () => {
    setIsDeleteLoading(true);
    dispatch(
      deleteReplyTemplate({
        templateId: openDeleteModal,
        callback: deleteCallback
      })
    );
    const tempData = replyTemplateData.filter(
      (item) => item.id !== openDeleteModal
    );
    dispatch(setAllReplyTemplates(tempData));
    setReplyTemplateData(tempData);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteConfirmation
        showConfirmation={openDeleteModal !== null ? true : false}
        onCancel={() => setOpenDeleteModal(null)}
        onConfirm={onDelete}
        title="Are you sure you want to delete this reply template?"
        description="Once deleted you will not be able to get it back. You will have to
      add it again."
        isLoading={isDeleteLoading}
      />
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Reply templates</h2>
        <p className="text-grey-800 text-15 mt-3">
          Frequently sent templates for replies
        </p>
      </div>
      {openEditor || openEdit ? (
        <TemplateEditor
          onCancel={() => {
            setOpenEdit(null);
            setOpenEditor(false);
          }}
          setReplyTemplateData={setReplyTemplateData}
          replyTemplateData={replyTemplateData}
          openEdit={openEdit}
        />
      ) : (
        <>
          <div className="mt-6">
            {!isEmpty(replyTemplateData) &&
              replyTemplateData?.map((item) => {
                // if (item.reply_type !== FACEBOOK_AND_INSTAGRAM) {
                return (
                  <TemplateItem
                    key={item.id}
                    id={item.id}
                    setOpenEdit={setOpenEdit}
                    replyType={item.reply_type}
                    title={item.name}
                    onDeleteReplyTemplate={onDeleteReplyTemplate}
                    item={item}
                  />
                );
                // }
              })}
          </div>
          <div
            className="mt-8 bg-grey-90 rounded-md flex items-center justify-center py-3 cursor-pointer"
            onClick={() => setOpenEditor(true)}
          >
            <AddIcon />
            <span className="text-15 font-semibold text-primary ml-2">
              Create a reply template
            </span>
          </div>
        </>
      )}
    </>
  );
};

export default ReplyTemplate;

import {
  continueChatConversation,
  getAllBotData,
  getBotConversations,
  setBotConversation,
  setMessageDispalayed,
  setShowTestBotPopup,
  setSuggestedReplies,
  startConversation,
  startOverConversation
} from 'slices/ai-assistant/assistant/slice';
import BackArrow from '../components/backArrow';
import BotResponse from './BotResponse';
import InputResponse from './InputResponse';
import Response from './Response';
import refreshIcon from 'assets/icons/refresh-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import OverLayLoader from 'components/loader/overLayLoader';
import { isEmpty } from 'utils/utils';
import PropTypes from 'prop-types';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const TestBot = () => {
  const messagesEndRef = useRef(null);
  const dispatch = useDispatch();
  const { assistant_id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [text, setText] = useState('');
  const [error, setError] = useState(false);
  const [startOverFlag, setStartOverFlag] = useState(false);
  const botConversation = useSelector(
    (state) => state.assistant.botConversation
  );
  const allBotData = useSelector((state) => state.assistant.allBotData);
  const [lead_id, setLeadId] = useState(null);

  // disbale text field until typing animation on assistant response is finished typing
  const [disableText, setDisableText] = useState(true);

  const successCb = (resData, type) => {
    if (resData?.lead_id) {
      setLeadId(resData?.lead_id);
    }
    if (type === 'getBotConversations') {
      setTimeout(() => {
        if (!isEmpty(resData?.history)) {
          scrollToBottom();
        } else {
          window.scrollTo(0, 0);
        }
      }, 500);
      setIsLoading(false);
    } else if (type === 'startOverConversation') {
      dispatch(setSuggestedReplies([]));
      dispatch(setMessageDispalayed(false));
      dispatch(setShowTestBotPopup(false));
      dispatch(
        setBotConversation({
          ...botConversation,
          history: []
        })
      );
      setStartOverFlag(false);
      setText('');
      setIsLoading(false);
      window.scrollTo(0, 0);
      setIsLoading(false);
    } else {
      if (!isEmpty(resData?.lead_id)) {
        setLeadId(resData?.lead_id);
      }
      setIsLoading(false);
    }
  };
  const failedCb = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    setIsLoading(true);
    dispatch(getAllBotData(assistant_id, successCb, failedCb));
    dispatch(getBotConversations(assistant_id, successCb, failedCb));
    setTimeout(() => {
      if (!isEmpty(botConversation?.history)) {
        scrollToBottom();
      } else {
        window.scrollTo(0, 0);
      }
    }, 500);
  }, []);

  const { history } = botConversation;

  const { opening_message, opening_option, name, theme } = allBotData || {};

  const ConvoSuccessCb = (payload, resData, type) => {
    if (resData?.lead_id) {
      setLeadId(resData?.lead_id);
    }

    if (type === 'startConversation') {
      setText('');
      let newData = [];
      newData = opening_message.map((message) => ({
        role: 'assistant',
        message: message
      }));
      newData.push({ role: 'user', message: payload.answer });
      newData.push({ role: 'assistant', message: resData.message });
      dispatch(
        setBotConversation({ lead_id: resData?.lead_id, history: newData })
      );
      setIsLoading(false);
    } else {
      setStartOverFlag(false);
      setIsLoading(false);
      setText('');
      scrollToBottom();
    }
  };

  const ConvoFailedCb = () => {
    setIsLoading(false);
  };
  const onStartConversation = (option) => {
    setIsLoading(true);
    let data = JSON.parse(JSON.stringify(botConversation));
    let newdata = {
      ...data,
      history: [
        ...data.history,
        {
          role: 'user',
          message: option
        }
      ]
    };

    dispatch(setBotConversation(newdata));
    let payload = {
      message: opening_message,
      answer: option
    };
    dispatch(
      startConversation(assistant_id, payload, ConvoSuccessCb, ConvoFailedCb)
    );
  };

  const onContinueConv = (payload) => {
    setIsLoading(true);
    dispatch(setSuggestedReplies([]));
    dispatch(setMessageDispalayed(false));
    dispatch(setShowTestBotPopup(false));
    let data = JSON.parse(JSON.stringify(botConversation));
    let newdata = {
      ...data,
      history: [
        ...data.history,
        {
          role: 'user',
          message: payload.answer
        }
      ]
    };
    dispatch(setBotConversation(newdata));
    setTimeout(() => {
      scrollToBottom();
    }, 200);
    dispatch(
      continueChatConversation(
        assistant_id,
        { ...payload, lead_id: botConversation.lead_id },
        ConvoSuccessCb,
        ConvoFailedCb
      )
    );
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <>
      {isLoading && (
        <OverLayLoader
          style={{
            top: '0px',
            left: '150px',
            width: '100%',
            height: '100%'
          }}
        />
      )}
      <section className="h-full m-auto overflow-auto">
        <BackArrow>
          <button
            className={`btn btn--start-over ${
              !isEmpty(history) ? 'cursor-pointer' : 'cursor-not-allowed'
            }`}
            onClick={() => {
              if (!isEmpty(history)) {
                setStartOverFlag(true);
              }
            }}
          >
            <img src={refreshIcon} alt="refresh-icon" />
            <span className="text-lg font-semibold ml-2">Start over</span>
          </button>
        </BackArrow>
        <div
          id="ChatConversations"
          className={`chat-bot-list-wrapper  ${
            !isEmpty(history) ? 'mb-[28px]' : ''
          }`}
        >
          {(isEmpty(history) || history?.length === 1) && (
            <BotResponse
              messages={opening_message}
              options={opening_option}
              handleStartConversation={onStartConversation}
              history={history}
              avatar={theme?.selected_avatar}
              name={name}
            />
          )}
          <Response
            history={history}
            avatar={theme?.selected_avatar}
            name={name}
            disableText={disableText}
            setDisableText={setDisableText}
            autoScroll={scrollToBottom}
            setLoading={setIsLoading}
          />
          <div ref={messagesEndRef} />
        </div>
        <InputResponse
          lead_id={lead_id}
          options={opening_option}
          handleContinueConv={onContinueConv}
          history={history}
          text={text}
          setText={setText}
          setError={setError}
          isLoading={isLoading}
          disableText={disableText}
          setDisableText={setDisableText}
        />
      </section>
      <DeleteConfirmation
        confirmButtonText="Confirm"
        showConfirmation={startOverFlag}
        onCancel={() => setStartOverFlag(false)}
        onConfirm={() => {
          setIsLoading(true);
          dispatch(startOverConversation(assistant_id, successCb, failedCb));
        }}
        title="If you start over, you will lose the conversations"
        description=""
        isLoading={isLoading}
      />
    </>
  );
};

export default TestBot;

TestBot.propTypes = {
  botConversation: PropTypes.object,
  allBotData: PropTypes.object,
  opening_message: PropTypes.array,
  opening_option: PropTypes.array,
  history: PropTypes.array,
  name: PropTypes.string
};

TestBot.defaultProps = {
  botConversation: {},
  allBotData: {},
  opening_message: [],
  opening_option: [],
  history: [],
  name: ''
};

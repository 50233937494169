import React from 'react';
import { timeFormatter } from 'utils/utils';

const InvitedUserSignedUp = ({ data }) => {
  const { title, created_at } = data;
  return (
    <div className="team-chat-feed__right-side ml-2">
      <div className={`flex justify-between team-chat-feed__new-enquiry`}>
        <div className="text-sm font-semibold text-grey-800">
          Invite Accepted
        </div>
        <div className="text-grey-700 text-xs">{timeFormatter(created_at)}</div>
      </div>
      <div className="py-3 px-3">
        <p className="text-sm">{title}</p>
      </div>
    </div>
  );
};

export default InvitedUserSignedUp;

import React from 'react';
import PropTypes from 'prop-types';

const DropDownBody = (props) => {
  const { className, style, children } = props;
  return (
    <div className={`${className}`} style={style}>
      {children}
    </div>
  );
};

DropDownBody.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node)
  ]),
  style: PropTypes.object
};

export default DropDownBody;

import React from 'react';
import HeaderLess from 'components/layout/headerless';
import CardLayout from 'components/layout/card';
import iconCheckConfirmation from 'assets/icons/icon-check-confirmation-msg.svg';

const LinkSent = () => {
  return (
    <HeaderLess>
      <CardLayout>
        <div className="text-center">
          <img src={iconCheckConfirmation} alt="" className="m-auto" />
          <h2 className="text-2xl font-semibold mt-8">
            Confirmation link sent
          </h2>
          <p className="text-sm mt-2">
            Check your email for the confirmation link
          </p>
          <p className="text-13 font-medium mt-10 ">
            If you don’t see your confirmation email check your spam for an
            email from{' '}
            <span className="text-primary">notifications@enquirybox.io</span>
          </p>
        </div>
      </CardLayout>
    </HeaderLess>
  );
};

export default LinkSent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import InputWithIcon from 'components/inputs/inputWithIcon';
import PropTypes from 'prop-types';
import search from 'assets/icons/icon-search.svg';
import DropDownWrapper from 'components/dropdown/wrapper';
import DropDownBody from 'components/dropdown/body';
import { isEmpty } from 'utils/utils';
import Avatar from 'components/avatar';
import closeIcon from 'assets/icons/icon-close-black.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSelector } from 'react-redux';
import CustomToolTip from 'components/CustomTooltip';

const RenderList = (props) => {
  const {
    open,
    title,
    options,
    active,
    onSelect,
    onSubmit,
    wrapperStyle,
    deleteVisible
  } = props;

  const { myInformation } = useSelector((state) => state.myInfo);
  const [userConnected, setUserConnected] = useState(options);
  const [searchValue, setSearchValue] = useState('');
  const [showDelete, setShowDelete] = useState(false);

  useEffect(() => {
    setUserConnected(options);
  }, [options]);

  useEffect(() => {
    setSearchValue('');
  }, [open]);

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (e.target.value !== '') {
      const filtered = options?.filter((item) =>
        item?.first_name.toLowerCase().includes(e.target.value.toLowerCase())
      );
      setUserConnected(filtered);
    } else {
      setUserConnected(options);
    }
  };

  const RenderOption = ({ option, isActive, deleteVisible, isCurrentUser }) => {
    return (
      <>
        <Avatar
          src={option.avatar}
          initial={option?.first_name}
          border={false}
          smaller
          initialStyle={{
            fontSize: '13px'
          }}
          className="mr-3"
          contain
          singleChar
        />
        <p style={{ fontSize: 14 }}>{option.name || option.first_name}</p>
        {isCurrentUser && (
          <span className="ml-1 text-grey-600 text-13 font-medium">You</span>
        )}
        {isActive && deleteVisible && (
          <div
            className="dropdown-menu__list--remove-btn cursor-pinter ml-auto"
            onClick={(e) => {
              e.stopPropagation();
              setShowDelete(true);
            }}
          >
            <CustomToolTip
              position="left"
              tooltipText="Unassign"
              tooltipStyle={{ marginRight: 4 }}
            >
              <img src={closeIcon} alt="" />
            </CustomToolTip>
          </div>
        )}
      </>
    );
  };

  const onDeleteChannel = () => {
    onSubmit(true);
    setShowDelete(false);
  };

  return (
    <>
      {deleteVisible && (
        <DeleteConfirmation
          showConfirmation={showDelete}
          onCancel={() => setShowDelete(false)}
          onConfirm={onDeleteChannel}
          title="Are you sure you want unassign this user?"
          confirmButtonText="Unassign"
          description=""
          mainStyle={{ zIndex: 99999999 }}
        />
      )}
      <DropDownWrapper
        open={open}
        className="dropdown-menu--with-border mt-3"
        style={{ padding: '18px 8px 8px', ...wrapperStyle }}
      >
        <div style={{ marginLeft: 10, marginRight: 10 }}>
          <InputWithIcon
            icon={search}
            placeholder="Search"
            value={searchValue}
            onChange={handleSearch}
            iconClassName="mr-2"
            medium
          />
        </div>
        <DropDownBody
          open={open}
          className="dropdown-menu__overflow-y-scroll mt-4"
        >
          <>
            {title && (
              <p
                className="text-grey-700 px-3 text-xs"
                style={{ marginBottom: 6 }}
              >
                {title}
              </p>
            )}
            {!isEmpty(userConnected) ? (
              userConnected.map((option, index) => {
                return (
                  <div
                    key={index}
                    className={`dropdown-menu__list font-medium ${
                      option.user_id === active?.user_id
                        ? 'dropdown-menu__list--assigned-card'
                        : null
                    } `}
                    onClick={() => onSelect(option)}
                    style={{ color: '#2B3639' }}
                  >
                    <RenderOption
                      option={option}
                      isActive={option.user_id === active?.user_id}
                      deleteVisible={deleteVisible}
                      isCurrentUser={myInformation.id === option.user_id}
                    />
                  </div>
                );
              })
            ) : (
              <div className="flex justify-center text-sm font-semibold h-full items-center">
                User not found
              </div>
            )}
          </>
        </DropDownBody>
      </DropDownWrapper>
    </>
  );
};

RenderList.propTypes = {
  wrapperStyle: PropTypes.object,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  options: PropTypes.array,
  onSelect: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  active: PropTypes.object,
  deleteVisible: PropTypes.bool
};

RenderList.defaultProps = {
  wrapperStyle: {},
  open: false,
  deleteVisible: true,
  onSubmit: () => {}
};

export default RenderList;

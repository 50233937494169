export const numberFormat = (num, digits = 1) => {
  var si = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, '$1') + si[i].symbol;
};

export const numberWithCommas = (value) => {
  if (!value) return '0';
  return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const isNumber = (value) => {
  var pattern = /^\d+$/;
  return pattern.test(value);
};

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  EMPTY_BIN,
  GET_LEADS,
  LEAD_UPDATE_STATUS,
  GET_ALL_BIN_LEADS
} from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

export const enquiryBin = createSlice({
  name: 'enquiryBin',
  initialState: {
    allBinLeads: []
  },
  reducers: {
    setAllLeads: (state, action) => {
      state.allBinLeads = action.payload;
    }
  }
});
export const { setAllLeads } = enquiryBin.actions;

export const getAllBinLeads = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { callback } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_ALL_BIN_LEADS, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setAllLeads(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const restoreSingleLead = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      await new APIRequest().post(LEAD_UPDATE_STATUS, body).then((res) => {
        if (callback) {
          callback();
        }
        dispatch(getAllBinLeads());
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const emptyBin = (payload) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      const { callback } = payload;
      await new APIRequest()
        .delete(replaceUrl(EMPTY_BIN, 'subOrgId', subOrgId))
        .then((res) => {
          if (callback) {
            callback();
          }
          dispatch(getAllBinLeads());
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default enquiryBin.reducer;

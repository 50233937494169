import React from 'react';
import Modal from 'components/modal';
import modalCloseIcon from 'assets/icons/icon-modal-close.svg';

const WarningAlert = (props) => {
  const {
    showConfirmation,
    containerStyle,
    title,
    description,
    renderSvg,
    onCancel
  } = props;
  return (
    <Modal.Main
      open={showConfirmation}
      className="modal--channels-request"
      containerStyle={containerStyle}
    >
      <div
        className="modal__close-icon flex justify-end pr-3 pt-3"
        onClick={onCancel}
      >
        <img src={modalCloseIcon} alt="" />
      </div>
      <Modal.Body className="pb-10 py-8 overflow-y-auto">
        <div className="flex justify-center">
          {renderSvg && typeof renderSvg === 'function' && renderSvg()}
        </div>
        <React.Fragment>
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">{title}</h2>
          </div>
          <div className="text-13 text-center mt-3">{description}</div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

export default WarningAlert;

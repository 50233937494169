// import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { MY_INFO } from 'urls';
// import { ApiHeaders } from '../headerSetup';
import { emptySplitApi } from '../createAPI';

emptySplitApi.enhanceEndpoints({ addTagTypes: ['myInfo'] });

export const myInfoApi = emptySplitApi.injectEndpoints({
  endpoints: (builder) => ({
    myInfo: builder.query({
      query: () => MY_INFO,
      providesTags: ['myInfo']
    })
  })
});

export const { useMyInfoQuery } = myInfoApi;

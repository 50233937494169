import React, { useState, useRef, useEffect } from 'react';
import { isEmpty } from 'utils/utils';
import { useParams } from 'react-router-dom';
import iconMoreRound from 'assets/icons/icon-more-round.svg';
import deleteIcon from 'assets/icons/icon-Delete.svg';
import iconEdit from 'assets/icons/icon-edit.svg';
import Img from 'components/img';
import useClickOutside from 'hooks/useOutsideClick';
import Toggle from 'components/inputs/toggle';
import { useSelector } from 'react-redux';
import ScreenLoader from 'components/loader/screenLoader';
import { useDispatch } from 'react-redux';
import { markAsConversion } from 'slices/boxes/slice';
import CustomToolTip from 'components/CustomTooltip';
import { useAnalytics } from 'use-analytics';

const StageOptions = (props) => {
  const {
    isDelete,
    isRename,
    onOptionSelect,
    isIncoming,
    stageId,
    name,
    isConversaionStage,
    // onMarkAsConversation,
    setShowConversionAlert
  } = props;
  const dispatch = useDispatch();
  const params = useParams();
  const restParams = params['*'];
  const { currentBox } = useSelector((state) => state.boxes);
  const [showOptions, setShowOptions] = useState(false);
  const [isChecked, setIsChecked] = useState(isConversaionStage);
  const isDetailEnabled = !restParams.includes(stageId);
  const [isLoading, setIsLoading] = useState(false);
  const { sales_converted_stage } = currentBox;
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();
  const nodeRef = useRef();

  const onSelect = (option) => {
    onOptionSelect(option);
  };

  useEffect(() => {
    setIsChecked(isConversaionStage);
  }, [isConversaionStage]);

  const onMarkAsConversation = (checkedValue) => {
    dispatch(
      markAsConversion(
        {
          stageId: stageId,
          body: {
            is_sales_converted_stage: checkedValue
          }
        },
        onSuccess,
        onError
      )
    );
  };

  const onSuccess = (data) => {
    if (data.is_sales_converted_stage) {
      track(
        'conversion_stage_on',
        {
          box_name: currentBox.box_name,
          stage_name: name
        },
        {
          groupId: subOrganizationId
        }
      );
    }
    setIsLoading(false);
  };

  const onError = () => {
    setIsLoading(false);
  };

  useClickOutside(() => setShowOptions(false), nodeRef.current);

  const onToggleConversion = (value) => {
    if (isEmpty(sales_converted_stage)) {
      setIsChecked(value);
      setIsLoading(true);
      onMarkAsConversation(value);
      return;
    }
    if (sales_converted_stage === stageId) {
      setIsChecked(value);
      setIsLoading(true);
      onMarkAsConversation(value);
      return;
    }
    if (!isEmpty(sales_converted_stage) && sales_converted_stage !== stageId) {
      setShowConversionAlert(true);
    }
  };

  const getToolTipDescription = () => {
    if (isChecked) {
      return `When you turn this off, cards moved to this stage will
       not be reported as converted sales in your report.`;
    }
    return `When cards are moved into a conversion stage they will be
    reported on this box report as a converted sale.`;
  };

  return (
    <>
      {isLoading && <ScreenLoader />}
      <div className="cursor-pointer relative" ref={nodeRef}>
        <div
          className={`stage-option ${showOptions ? 'active' : ''}`}
          onClick={() => setShowOptions(!showOptions)}
        >
          <img src={iconMoreRound} alt="" style={{ width: 16, height: 10 }} />
        </div>
        <div
          className={`dropdown-menu dropdown-menu--more ${
            showOptions ? 'open' : ''
          }`}
          style={{
            top: 30,
            right: '-33px',
            padding: '10px 0 0 0',
            width: '208px'
          }}
        >
          <div
            className="dropdown-menu__list"
            onClick={() => onSelect(isRename)}
            style={{
              marginLeft: 8,
              marginRight: 8
            }}
          >
            <Img
              src={iconEdit}
              alt="rename"
              className="mr-2"
              style={{ width: 16, height: 16 }}
            />
            Rename
          </div>
          {!isIncoming && (
            <div
              className="dropdown-menu__list red-text"
              onClick={(e) => {
                e.stopPropagation();
                setShowOptions(false);
                onSelect(isDelete);
              }}
              style={{
                marginLeft: 8,
                marginRight: 8
              }}
            >
              <Img
                src={deleteIcon}
                alt="delete"
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Delete
            </div>
          )}
          <div className="conversion-stage-option mt-2 relative">
            <CustomToolTip
              position="bottom"
              tooltipText={getToolTipDescription()}
              tooltipStyle={{
                width: 300,
                top: '195%'
              }}
            >
              <div className="flex justify-between">
                <p>Conversion stage 💰</p>
                <Toggle
                  id={`${
                    isDetailEnabled
                      ? `conversion-stage-detail-${stageId}`
                      : `conversion-stage-${stageId}`
                  }`}
                  smaller
                  checked={isChecked}
                  onChange={(e) => onToggleConversion(e.target.checked)}
                />
              </div>
            </CustomToolTip>
          </div>
        </div>
      </div>
    </>
  );
};

export default StageOptions;

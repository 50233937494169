import { createSlice } from '@reduxjs/toolkit';

const segmentSlice = createSlice({
  name: 'segments',
  initialState: {
    analytics: null
  },
  reducers: {
    setAnalytics: (state, action) => {
      state.analytics = action.payload;
    }
  }
});

export const { setAnalytics } = segmentSlice.actions;

export default segmentSlice.reducer;

/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { GET_CARD_DESIGN, UPDATE_CARD_DESIGN } from 'urls';
import { replaceUrl } from 'utils/urlReplace';
import { setBoxes } from './boxes/slice';

export const cardDesignSettings = createSlice({
  name: 'cardDesignSettings',
  initialState: {
    cardData: null
  },
  reducers: {
    setCardData: (state, action) => {
      state.cardData = action.payload;
    }
  }
});

export const { setCardData } = cardDesignSettings.actions;

export const updateCardDesigne = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const { data, box_id } = payload;
      const {
        boxes: { boxes }
      } = getState();
      await new APIRequest()
        .put(replaceUrl(UPDATE_CARD_DESIGN, 'boxId', box_id), data)
        .then((res) => {
          if (res.status === 200) {
            const updatedBoxes = boxes.map((box) => {
              if (box.id === res.data.id) {
                return { ...box, ...res.data };
              }
              return box;
            });
            dispatch(setBoxes(updatedBoxes));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
      if (errorCb) {
        errorCb();
      }
    }
  };
};

export const getCardDesign = (payload) => {
  return async (dispatch) => {
    try {
      const { box_id } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_CARD_DESIGN, 'boxId', box_id))
        .then((res) => {
          if (res.status === 200) {
            const { card_design } = res?.data;
            if (card_design) {
              if (typeof card_design === 'string') {
                dispatch(setCardData(JSON.parse(card_design)));
              } else {
                dispatch(setCardData(card_design));
              }
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export default cardDesignSettings.reducer;

import React from 'react';
import styles from 'components/inputs/radio/index.module.scss';
import CHOICES from 'choices';

const { MCQ } = CHOICES.QuestionChoices;

const OptionTypeForm = ({ data, onRadioSelect, onCheckboxSelect }) => {
  const onSelect = (text) => {
    onRadioSelect(text, data.id);
  };

  const onMultipleSelectChange = (e, text) => {
    onCheckboxSelect(e.target.checked, text, data.id);
  };
  return (
    <>
      {data?.kind === MCQ ? (
        <div className="pb-3">
          <label className="input-field-label">{data.text}</label>
          <div className="xxl:gap-6 flex py-1 gap-3 flex-wrap">
            {data?.choice_set.map((item) => {
              return (
                <div className="flex items-center" key={item.position}>
                  <label className="flex gap-1 cursor-pointer items-center">
                    <input
                      type="radio"
                      className={`${styles.custom_radio} ${styles.custom_radio__md}`}
                      id={item.text}
                      value={item.text}
                      name={`option-${data?.id}`}
                      onChange={() => onSelect(item.text)}
                    />
                    <p className="text-sm font-medium">{item.text}</p>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="pb-3">
          <label className="input-field-label">{data.text}</label>
          <div className="py-1 flex xxl:gap-6 gap-3 flex-wrap">
            {data?.choice_set.map((item, ind) => {
              return (
                <div className="flex items-center" key={item.position}>
                  <label className="flex gap-1 cursor-pointer items-center">
                    <input
                      id={item.text}
                      data-testid="checkbox-field"
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm"
                      name={item.text}
                      onChange={(e) => onMultipleSelectChange(e, item.text)}
                    />
                    <p className="text-sm font-medium">{item.text}</p>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default OptionTypeForm;

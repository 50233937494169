/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef } from 'react';
import iconReminderInActive from 'assets/icons/icon-reminder.svg';
import useClickOutside from 'hooks/useOutsideClick';
import choices from 'choices';
import DueButton from './dueButton';

const { CHOICE_LIST, DUE_IMMEDIATELY, CHOICES } = choices.TaskDueChoices;

const TaskDate = ({ setDueValue, setDueType, dueType, dueValue, isEdit }) => {
  const [showReminderOptions, setShowReminderOptions] = useState(false);
  const reminderDom = useRef();

  useClickOutside(() => {
    setShowReminderOptions(false);
  }, reminderDom.current);

  const onOptionSelect = (item) => {
    setDueType(item.value);
    setShowReminderOptions(false);
  };

  const onButtonClick = () => {
    setShowReminderOptions(!showReminderOptions);
  };

  const getButtonText = () => {
    if (dueType === DUE_IMMEDIATELY) return CHOICES[DUE_IMMEDIATELY];
  };

  return (
    <>
      <div ref={reminderDom}>
        <div
          className={`dropdown-menu dropdown-menu--sm
          ${showReminderOptions && 'open'}`}
          style={{
            width: '200px',
            bottom: '70px',
            left: 'auto'
          }}
        >
          {CHOICE_LIST.map((item, index) => {
            let isSelected = item.value === dueType;
            return (
              <div
                key={index}
                className={`dropdown-menu__list ${
                  isSelected ? 'selected' : ''
                }`}
                onClick={() => onOptionSelect(item)}
              >
                {item.label}
              </div>
            );
          })}
        </div>
        <DueButton
          active={showReminderOptions}
          icon={iconReminderInActive}
          text={getButtonText()}
          onClick={onButtonClick}
          dueType={dueType}
          dueValue={dueValue}
          setDueValue={setDueValue}
          isEdit={isEdit}
        />
      </div>
    </>
  );
};

export default React.memo(TaskDate);

import choices from 'choices';
import { timeFormatter, formatMessage, isEmpty } from 'utils/utils';
import { removeSignatureFromContent } from 'utils/dom';
const { JUST_TO_STORE_DATA, NORMAL_REPLY } = choices.LeadReplyChoice;
const {
  WEB_FORMS,
  FACEBOOK,
  INSTAGRAM,
  FACEBOOK_LEADGEN,
  MANUALLY_ADDED,
  IMPORTED_FROM_FILE,
  SMS,
  EBOT,
  ZAPIER
} = choices.LeadChannelChoice;

export const getChannelDescription = (channel) => {
  switch (channel) {
    case FACEBOOK:
      return 'Facebook Enquiry';
    case WEB_FORMS:
      return 'Webform Enquiry';
    case INSTAGRAM:
      return 'Instagram Enquiry';
    case FACEBOOK_LEADGEN:
      return 'Facebook Lead Ads Enquiry';
    case MANUALLY_ADDED:
      return 'Manual Enquiry';
    case IMPORTED_FROM_FILE:
      return 'Manual Enquiry';
    case SMS:
      return 'Sms Enquiry';
    case EBOT:
      return 'Bot Enquiry';
    case ZAPIER:
      return 'Zapier Enquiry';
    default:
      return 'Enquiry';
  }
};

export const getLastReply = ({ last_reply, channel }) => {
  if (!isEmpty(last_reply?.attachments) && !last_reply?.message) {
    return 'File';
  }
  if (!last_reply) {
    return getChannelDescription(channel);
  }
  if (channel === ZAPIER && last_reply?.reply_type == NORMAL_REPLY) {
    return getChannelDescription(channel);
  }
  if (last_reply?.reply_type == JUST_TO_STORE_DATA) {
    return getChannelDescription(channel);
  }
  const updatedString = removeSignatureFromContent(last_reply?.message);
  return formatMessage(updatedString?.replace(/(<([^>]+)>)/gi, ''));
};

export const getLeadTime = ({ last_reply, created_at, updated_at }) => {
  if (last_reply?.reply_type == JUST_TO_STORE_DATA) {
    return timeFormatter(created_at);
  }
  return timeFormatter(last_reply?.updated_at || updated_at);
};

export const isReplySent = ({ last_reply }) => {
  return last_reply?.reply_type !== JUST_TO_STORE_DATA;
};

export const getLeadName = (lead) => {
  if (lead.name === null) return null;
  if (lead.name) {
    return lead.name;
  } else {
    return (
      (lead.first_name ? lead.first_name : '') +
      ' ' +
      (lead.last_name ? lead.last_name : '')
    );
  }
};

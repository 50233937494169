import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  LIST_UPLOAD_PRODUCT_OR_SERVICE,
  CREATE_SINGLE_PRODUCT_OR_SERVICE,
  GET_ALL_PRODUCT_OR_SERVICE,
  SINGLE_PRODUCT_OR_SERVICE
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';

const initialState = {
  productData: [],
  singleProductData: []
};

const reducers = {
  setProductInfoData: (state, action) => {
    state.productData = action.payload;
  },
  setSingleProductData: (state, action) => {
    state.singleProductData = action.payload;
  },
  resetProductInfoFields: () => initialState
};

export const productInfo = createSlice({
  name: 'productInfo',
  initialState,
  reducers
});
export const {
  setProductInfoData,
  setSingleProductData,
  resetProductInfoFields
} = productInfo.actions;

export default productInfo.reducer;

export const addProduct = (payload, assistant_id, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { productInfo } = getState();
    const { productData } = productInfo;
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let currPayload = {
      ...payload,
      version_id: currentVersionId
    };
    try {
      new APIRequest()
        .post(
          replaceUrl(CREATE_SINGLE_PRODUCT_OR_SERVICE, 'aiBotId', assistant_id),
          currPayload
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res?.data?.version_status));
            dispatch(setProductInfoData([...productData, res.data]));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};
export const uploadMultipleProducts = (
  formData,
  assistant_id,
  successCb,
  failedCb
) => {
  return async (dispatch) => {
    try {
      new APIRequest()
        .post(
          replaceUrl(LIST_UPLOAD_PRODUCT_OR_SERVICE, 'aiBotId', assistant_id),
          formData,
          {
            'Content-Type': 'multipart/form-data'
          }
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res?.data?.version_status));
            dispatch(setProductInfoData(res.data.data));
            if (successCb) {
              successCb();
            }
          }
        })
        .catch((err) => {
          if (failedCb) {
            failedCb(err);
          }
          console.log(err);
        });
    } catch (err) {
      if (failedCb) failedCb();
      console.log(err);
    }
  };
};

export const listAllProducts = (assistant_id) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_PRODUCT_OR_SERVICE, 'aiBotId', assistant_id))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setProductInfoData(res.data));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const getProduct = (productId, callback) => {
  return async (dispatch, getState) => {
    dispatch(setSingleProductData([]));
    try {
      await new APIRequest()
        .get(replaceUrl(SINGLE_PRODUCT_OR_SERVICE, 'productId', productId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setSingleProductData(res.data));
            if (callback) callback();
          }
        })
        .catch((err) => {
          console.log(err);
          if (callback) callback();
        });
    } catch (err) {
      if (callback) callback();
      console.log(err);
    }
  };
};
export const updateProduct = (payload, productId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let currPayload = {
      ...payload,
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .put(
          replaceUrl(SINGLE_PRODUCT_OR_SERVICE, 'productId', productId),
          currPayload
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res?.data?.version_status));
            dispatch(setSingleProductData(res.data));
            if (successCb) {
              successCb('update');
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      if (failedCb) failedCb();
      console.log(err);
    }
  };
};
export const deleteProduct = (productId, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { currentVersionId } = assistant;
    let payload = {
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .delete(
          replaceUrl(SINGLE_PRODUCT_OR_SERVICE, 'productId', productId),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res?.data?.version_status));
            dispatch(setSingleProductData(res.data));
            if (successCb) successCb('delete');
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) failedCb();
        });
    } catch (err) {
      console.log(err);
      if (failedCb) failedCb();
    }
  };
};

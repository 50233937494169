/* eslint-disable no-unused-vars */
import React, { Suspense, lazy } from 'react';
// import Home from 'modules/enquirybox/ebox/module/box/home';
import ConversationHome from './conversation/home';
import { Routes, Route } from 'react-router-dom';
import Enquiries from './enquiries';
// import loadable from '@loadable/component';
// const Enquiries = lazy(() => import('./enquiries'));
// const ConversationHome = lazy(() => import('./conversation/home'));
// import { Loader } from 'components/loader';

const Box = () => {
  return (
    <>
      <Routes>
        <Route element={<Enquiries />}>
          <Route path="/*" element={<ConversationHome />} />
        </Route>
      </Routes>
    </>
  );
  // return (
  //   <Suspense
  //     fallback={
  //       <div className="w-full">
  //         <span>SUSPENCE LOADING</span>
  //       </div>
  //     }
  //   >
  //     <Routes>
  //       <Route element={<Enquiries />}>
  //         <Route path="/*" element={<ConversationHome />} />
  //       </Route>
  //     </Routes>
  //   </Suspense>
  // );
};

export default Box;

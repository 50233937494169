/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CustomToolTip from 'components/CustomTooltip';
import enquiryOutline from 'assets/icons/icon-assign-enquiries-outline.svg';
import ConnectChannelState from 'modules/enquirybox/components/reportsConditionalState/ConnectChannelState';
import { isEmpty } from 'utils/utils';
import iconFacebook from 'assets/icons/icon-facebook-channel.svg';
import iconInstagram from 'assets/icons/icon-channels-instagram.svg';
import iconWebform from 'assets/icons/icon-webform-channel-blue.svg';
import iconFacebookLeadAd from 'assets/icons/icon-fb-leads-channel.svg';
import iconSms from 'assets/icons/icon-sms-msg.svg';
import CHOICES from 'choices';
import iconManualEnquiry from 'assets/icons/icon-manual-enquiry.png';
import zapierIcon from 'assets/icons/icon-channel-zapier.svg';

const {
  FACEBOOK,
  INSTAGRAM,
  FACEBOOK_LEADGEN,
  WEB_FORMS,
  SMS,
  MANUALLY_ADDED
} = CHOICES.LeadChannelChoice;

const { ZAPIER } = CHOICES.ChannelChoices;

const TotalEnquiryPerChannel = (props) => {
  const { data, isPublic = false } = props;

  const renderIcon = (type) => {
    switch (type) {
      case FACEBOOK:
        return iconFacebook;
      case INSTAGRAM:
        return iconInstagram;
      case WEB_FORMS:
        return iconWebform;
      case FACEBOOK_LEADGEN:
        return iconFacebookLeadAd;
      case SMS:
        return iconSms;
      case MANUALLY_ADDED:
        return iconManualEnquiry;
      case ZAPIER:
        return zapierIcon;
      default:
        return iconWebform;
    }
  };
  return (
    <div className={`card-box px-5 py-4`}>
      <div className="flex items-center pr-4">
        <figure
          className="avatar-container avatar-container--contain bg-grey-300"
          style={{
            width: 38,
            height: 38,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <img src={enquiryOutline} width={17} height={17} />
        </figure>
        <CustomToolTip
          style={{ width: '100%' }}
          tooltipStyle={{
            width: 222,
            top: -100,
            bottom: 'initial',
            left: -58,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="The total number of enquiries you received in this period per channel"
        >
          <p className="ml-3 letterspacing24em font-semibold">
            Total enquiries per channel
          </p>
        </CustomToolTip>
      </div>
      <div className="relative">
        <table className="table-reports">
          <thead
            style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}
          >
            <tr>
              <td>Channels</td>
              <td></td>
            </tr>
          </thead>
          {isEmpty(data) ? (
            <ConnectChannelState isPublic={isPublic} />
          ) : (
            <tbody className={`${isPublic ? 'card-box-report-sales' : ''}`}>
              {!isEmpty(data) &&
                data.map((item, idx) => {
                  const { channel_type, channel_name, total } = item;
                  if (total !== 0) {
                    return (
                      <tr
                        key={idx}
                        style={{
                          display: 'table',
                          width: '100%',
                          tableLayout: 'fixed'
                        }}
                      >
                        <td className="flex-1">
                          <div className="flex items-center">
                            <figure className="flex-shrink-0 flex items-center">
                              <img
                                src={renderIcon(channel_type)}
                                alt="icon-facebook"
                                width={22}
                                height={22}
                              />
                            </figure>
                            <p className="text-sm font-semibold letterspacing24em ml-3">
                              {channel_name}
                            </p>
                          </div>
                        </td>
                        <td className="text-sm font-bold">
                          {total ? total : 0}
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default TotalEnquiryPerChannel;

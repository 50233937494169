import React, { useState } from 'react';
import AutomateOptionIcon from 'assets/icons/icon-flash.svg';
import OverLayLoader from 'components/loader/overLayLoader';
import { useDispatch } from 'react-redux';
import { setStageIndex } from 'slices/boxAutomation/slice';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';

const Automation = (props) => {
  const {
    title,
    id,
    automation,
    onClick,
    onChangeActive,
    isLoading,
    parentIndex
  } = props;
  const dispatch = useDispatch();
  const [showWarning, setShowWarning] = useState(null);

  const getDescription = () => {
    if (automation.is_active) {
      return `The automation is live and has run ${automation.executed_count} times`;
    }
    return `This automation is not live - flick the switch to turn it on`;
  };

  const onToggleChange = () => {
    setShowWarning(id);
  };

  const onToggleConfirm = () => {
    dispatch(setStageIndex(parentIndex));
    onChangeActive(automation);
    setShowWarning(null);
  };

  return (
    <>
      <DeleteConfirmation
        showConfirmation={showWarning === id}
        onCancel={() => setShowWarning(null)}
        title={`Are you sure you want to ${
          automation?.is_active ? 'turn off' : 'turn on'
        } this automation?`}
        onConfirm={onToggleConfirm}
        confirmButtonText={`${automation?.is_active ? 'Turn off' : 'Turn on'}`}
      />
      <div
        className="flex justify-between card-box pt-5 pr-5 pb-4 pl-6 mt-3 relative"
        onClick={() => onClick(automation)}
      >
        {isLoading && <OverLayLoader style={{ top: 0, left: 0 }} />}
        <div className="flex items-start">
          <img
            src={AutomateOptionIcon}
            alt="icon"
            style={{ marginRight: 15, marginTop: 3, height: 21, width: 16 }}
          />
          <div>
            <div className="font-semibold">{title}</div>
            <div className="text-grey-800 text-sm mt-1">{getDescription()}</div>
          </div>
        </div>
        <div
          className="toggle-slider"
          onClick={(e) => {
            e.stopPropagation();
            onToggleChange();
          }}
          // onClick={(e) => {
          //   e.stopPropagation();
          //   dispatch(setStageIndex(parentIndex));
          //   onChangeActive(automation);
          // }}
        >
          <input
            type="checkbox"
            id={id}
            className="toggle-slider__checkbox"
            name="checkedA"
            checked={automation.is_active}
            onChange={() => {}}
          />
          <label htmlFor={id} className="toggle-slider__label">
            <code className="toggle-slider__ball toggle-slider__ball__small"></code>
          </label>
        </div>
      </div>
    </>
  );
};

export default Automation;

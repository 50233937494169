/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React from 'react';
// import { ReactComponent as IconEbBox } from 'assets/icons/icon-sidebar-eb-box.svg';
import { route } from 'utils/route';
import SectionItem from 'modules/dashboard/component/sections';
import SectionHeader from 'modules/dashboard/component/sections/header';
// import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import BoxesSideNav from './boxes/index';
import iconTeamReport from 'assets/icons/icon-team-report.svg';
// import { useDispatch } from 'react-redux';
// import {
//   setTempDateFormat,
//   setTempEnd_Date,
//   setTempStart_date
// } from 'slices/reports/slice';
// import moment from 'moment';

const SideNav = () => {
  // const location = useLocation();
  // const dispatch = useDispatch();

  // const isEnquiryReport = () => {
  //   return location.pathname.includes(`${route.report}${route.incomingReport}`)
  //     ? 'active'
  //     : '';
  // };

  // const onClick = () => {
  //   dispatch(setTempStart_date(moment().startOf('month').format('YYYY-MM-DD')));
  //   dispatch(
  //     setTempEnd_Date(moment().subtract(1, 'days').format('YYYY-MM-DD'))
  //   );
  //   dispatch(setTempDateFormat('This month to date'));
  // };

  return (
    <div className="dashboard__sidebar__expanded-section">
      {/* <div onClick={onClick}>
        <NavLink
          to={`${route.report}${route.boxReport}`}
          className={`flex justify-between py-3 bg-color-on-hover bg-color-on-hover--bg-grey-100 ${isEnquiryReport()}`}
        >
          <div className="flex items-center">
            <IconEbBox width={16} height={16} />
            <h2 className="text-15 font-semibold text-grey-900 ml-3">
              Incoming enquiry report
            </h2>
          </div>
        </NavLink>
      </div> */}
      {/* <div className="bg-grey-400 mt-4 mb-2" style={{ height: 1 }}></div> */}
      <BoxesSideNav />
      <div className="bg-grey-400 mb-6 mt-9" style={{ height: 1 }}></div>
      <div>
        <SectionHeader label="Team Reports" />
        <div className="flex flex-col">
          <SectionItem
            label={'Performance Report'}
            to={`${route.report}${route.teamReport}/team-performance`}
            icon={iconTeamReport}
          />
        </div>
      </div>
    </div>
  );
};

export default SideNav;

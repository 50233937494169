import React from 'react';
import webForm from 'assets/icons/empty-state-icon-web-form.svg';
import chat from 'assets/icons/empty-state-icon-chat.svg';
import instagram from 'assets/icons/empty-state-icon-instagram.svg';
import facebook from 'assets/icons/empty-state-icon-facebook.svg';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';

const ConnectChannelState = ({ isPublic }) => {
  const channelAvatar = [webForm, chat, instagram, facebook];
  const navigate = useNavigate();

  const onCLick = () => {
    if (!isPublic) {
      navigate(`${route.channels}`);
    }
  };

  return (
    <>
      <div className="conditional-popup conditional-popup--bg-light">
        <div className="flex avatar-group">
          {channelAvatar.map((item, idx) => (
            <figure
              key={idx}
              className="avatar-container avatar-container--contain bg-white"
              style={{
                width: 35,
                height: 35,
                borderWidth: 2,
                borderColor: '#E6E4E1'
              }}
            >
              <img src={item} />
            </figure>
          ))}
        </div>
        <h4 className="text-lg font-semibold letterspacing24em mt-4">
          Connect your first channel to turn on this report
        </h4>
        <p className="text-13 letterspacing24em mt-1">
          See how many enquiries you get from each channel
        </p>
        <button className="btn btn--primary btn--xs mt-4" onClick={onCLick}>
          Connect a channel
        </button>
      </div>
      <tbody>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr>
        {/* <tr>
          <td className="flex-1">
            <div className="flex items-center">
              <figure className="avatar-overlay"></figure>
              <div className="data-overlay ml-2"></div>
            </div>
          </td>
          <td>
            <p className="data-overlay data-overlay--sm ml-auto"></p>
          </td>
        </tr> */}
      </tbody>
    </>
  );
};

export default ConnectChannelState;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo } from 'react';
import NavLink from '../component/navLink';
import ReportHeader from '../component/reportHeader';
import { Route, Routes, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SnapShotReport from 'modules/enquirybox/reports/modules/report/snapshotreport';
import { Loader } from 'components/loader';
import usePlanAccess from 'hooks/usePlanAccess';
import Wrapper from '../wrapper';

const BoxReport = () => {
  const { box_id } = useParams();
  const { boxes } = useSelector((state) => state.boxes);
  const { has_box_report } = usePlanAccess();

  const currentBox = useMemo(() => {
    const x = boxes?.find((box) => {
      return box.id === box_id;
    });
    return x;
  }, [box_id, boxes]);

  if (!currentBox) return <Loader />;

  return (
    <section className="dashboard__main">
      <ReportHeader
        renderSvg={() => {
          if (currentBox.box_avatar?.includes('https://'))
            return (
              <img
                src={currentBox.box_avatar}
                alt=""
                style={{ width: 28, height: 28 }}
              />
            );
          return (
            <div dangerouslySetInnerHTML={{ __html: currentBox.box_avatar }} />
          );
        }}
        title={currentBox?.box_name}
      />
      <div
        className="dashboard__main__body"
        style={{ height: 'calc(100% - 91px)' }}
      >
        <div className="dashboard__main__body__section-left">
          <NavLink isBoxReport={true} />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <Routes>
            <Route
              path="/snapshot"
              element={
                <Wrapper showUpgradeTopbar={!has_box_report}>
                  <SnapShotReport hideCount={!has_box_report} />
                </Wrapper>
              }
            />
          </Routes>
        </div>
      </div>
    </section>
  );
};

export default BoxReport;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import InputWithIcon from 'components/inputs/inputWithIcon';
import iconClose from 'assets/icons/icon-close-grey.svg';
import { isEmpty } from 'lodash';
import ErrorMessage from 'components/errorMessage';

const StageName = ({ onSubmit, onClose, name }) => {
  const [stageName, setStageName] = useState(name);
  const [error, setError] = useState();

  const onHandleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(stageName)) {
      setError('Please add stage name');
      return;
    }
    // else {
    //   if (stageName.length > 30) {
    //     setError('name can not be more than 30 characters');
    //     return;
    //   }
    // }
    onSubmit(stageName);
  };

  return (
    <form className="w-full" onSubmit={onHandleSubmit}>
      <InputWithIcon
        name="stageName"
        value={stageName}
        rightIcon={iconClose}
        className="input-field--sm w-full"
        rightIconClassName="ml-3 cursor-pointer"
        onChange={(e) => setStageName(e.target.value)}
        onRightIconClick={onClose}
        medium
      />
      {error && <ErrorMessage message={error} />}
    </form>
  );
};

export default StageName;

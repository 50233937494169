/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createAnswer,
  DeleteQuestion,
  setEditedData,
  setIsEdit,
  setIsShowQuestionForm,
  setSelectedQuestion,
  updateAnswer
} from 'slices/questionSlice';
import QuestionHeader from '../../../../component/questionHeader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
import CHOICES from 'choices';
import { useParams } from 'react-router-dom';
import CustomRadio from 'components/inputs/radio';
import OverLayLoader from 'components/loader/overLayLoader';
import { questionTypes } from 'utils/questions';

const { MCQ, MCQ_MUTIPLE_SELECT } = CHOICES.QuestionChoices;

const OptionsQuestionAdded = ({
  questionData,
  title,
  answer,
  deleteQuestion,
  isBin,
  index
}) => {
  const dispatch = useDispatch();
  const { cardId, stageId, box_id } = useParams();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { myInformation } = useSelector((state) => state.myInfo);

  const getCheckedStatus = (value) => {
    if (Array.isArray(answer[0]?.answer)) {
      const isChecked = answer[0]?.answer.filter(
        (item) => item.description === value
      );
      if (!isEmpty(isChecked)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const getRadioCheckedStatus = (value) => {
    if (Array.isArray(answer)) {
      const isChecked = answer[0]?.answer.description === value ? true : false;
      return isChecked;
    }
  };

  const [singleSelectData, setSingleSelectData] = useState(
    !isEmpty(questionData) && questionData?.kind === MCQ
      ? questionData?.choice_set.map((item) => {
          return {
            id: item.position,
            value: item.text,
            checked: getRadioCheckedStatus(item.text),
            choice_id: item.id
          };
        })
      : []
  );

  const [multipleSelectData, setMultipleSelectData] = useState(
    !isEmpty(questionData) && questionData?.kind === MCQ_MUTIPLE_SELECT
      ? questionData?.choice_set.map((item) => {
          return {
            id: item.position,
            value: item.text,
            checked: getCheckedStatus(item.text),
            choice_id: item.id
          };
        })
      : []
  );

  useEffect(() => {
    setMultipleSelectData(
      !isEmpty(questionData) && questionData?.kind === MCQ_MUTIPLE_SELECT
        ? questionData?.choice_set.map((item) => {
            return {
              id: item.position,
              value: item.text,
              checked: getCheckedStatus(item.text),
              choice_id: item.id
            };
          })
        : []
    );
  }, [answer]);

  useEffect(() => {
    setSingleSelectData(
      !isEmpty(questionData) && questionData?.kind === MCQ
        ? questionData?.choice_set.map((item) => {
            return {
              id: item.position,
              value: item.text,
              checked: getRadioCheckedStatus(item.text),
              choice_id: item.id
            };
          })
        : []
    );
  }, [answer]);

  const onDeleteQuestion = (id) => {
    setQuestionId(id);
    setShowDeleteConfirmation(true);
  };

  const callback = () => {
    setIsLoading(false);
  };

  const onDeleteCallback = (questionId) => {
    setIsLoading(false);
    setShowDeleteConfirmation(false);
    deleteQuestion(questionId);
  };

  const onDelete = async () => {
    setIsLoading(true);
    dispatch(
      DeleteQuestion({
        questionId: questionId,
        box_id,
        callback: onDeleteCallback
      })
    );
  };

  const onChange = (value, ind, item) => {
    const currentStatus = singleSelectData[ind].checked;
    const selected = [...singleSelectData];
    selected[ind].checked = !currentStatus;
    setSingleSelectData(selected);
    if (isEmpty(answer)) {
      setIsLoading(true);
      const formData = {
        answer: { description: value, choice_id: item.choice_id },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(createAnswer({ body: formData, stageId, callback }));
    } else {
      setIsLoading(true);
      const formData = {
        answer: { description: value, choice_id: item.choice_id },
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(
        updateAnswer({
          body: formData,
          answerId: answer[0]?.id,
          stageId,
          callback
        })
      );
    }
  };

  const onMultipleSelectChange = (value, ind, item) => {
    const currentStatus = multipleSelectData[ind].checked;
    multipleSelectData[ind].checked = !currentStatus;
    setMultipleSelectData([...multipleSelectData]);
    if (isEmpty(answer)) {
      setIsLoading(true);
      const formData = {
        answer: [{ choice_id: item.choice_id, description: value }],
        user: myInformation.id,
        question: questionData.id,
        lead: cardId
      };
      dispatch(createAnswer({ body: formData, stageId, callback }));
    } else {
      setIsLoading(true);
      const prevAnswer = answer[0]?.answer;
      const isPrevious = prevAnswer.find((ele) => ele.description === value);
      if (!isEmpty(isPrevious)) {
        const finalAnswer = prevAnswer.filter(
          (item) => item.description !== value
        );
        const formData = {
          answer: finalAnswer,
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(
          updateAnswer({
            body: formData,
            answerId: answer[0]?.id,
            stageId,
            callback
          })
        );
      } else {
        const formData = {
          answer: [
            ...prevAnswer,
            { choice_id: item.choice_id, description: value }
          ],
          user: myInformation.id,
          question: questionData.id,
          lead: cardId
        };
        dispatch(
          updateAnswer({
            body: formData,
            answerId: answer[0]?.id,
            stageId,
            callback
          })
        );
      }
    }
  };

  const onEdit = () => {
    const editQuestion = questionTypes.filter(
      (item) => item.type === MCQ || item.type === MCQ_MUTIPLE_SELECT
    );
    dispatch(setIsEdit(true));
    dispatch(setEditedData(questionData));
    dispatch(setIsShowQuestionForm(true));
    dispatch(setSelectedQuestion(editQuestion[0]));
  };

  return (
    <>
      {showDeleteConfirmation && (
        <DeleteConfirmation
          showConfirmation={showDeleteConfirmation}
          onCancel={() => setShowDeleteConfirmation(false)}
          onConfirm={onDelete}
          title="Are you sure you want to delete this question?"
          description="Once deleted you will not be able to get it back. You will have to
      add it again."
          containerStyle={{ height: 'max-content' }}
          isLoading={isLoading}
        />
      )}
      {questionData?.kind === MCQ ? (
        <div className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative">
          {isLoading && <OverLayLoader />}
          <QuestionHeader
            label={title}
            isShowMoreIcon={true}
            onDelete={onDeleteQuestion}
            id={questionData.id}
            isBin={isBin}
            onEdit={onEdit}
          />
          <div className="xxl:p-5 xxl:gap-6 flex flex-wrap p-4 gap-1">
            {singleSelectData.map((item, ind) => {
              return (
                <div
                  className="mr-3 mb-2"
                  key={`${questionData?.id}-${item.id}`}
                >
                  <label className="flex cursor-pointer items-center gap-1">
                    <CustomRadio
                      type="radio"
                      id={`${questionData?.id}-${item.id}`}
                      value={item.value}
                      checked={item.checked}
                      name={`${questionData?.id}-option`}
                      medium
                      onChange={
                        isBin ? () => {} : () => onChange(item.value, ind, item)
                      }
                    />
                    <p className="text-sm font-medium">{item.value}</p>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <div
          className="mt-8 border border-grey-400 border-radius-10 bg-grey-300 relative"
          autoComplete="off"
        >
          {isLoading && <OverLayLoader />}
          <QuestionHeader
            label={title}
            isShowMoreIcon={true}
            onDelete={onDeleteQuestion}
            id={questionData.id}
            isBin={isBin}
            onEdit={onEdit}
          />
          <div className="xxl:p-5 p-4 flex xxl:gap-6 gap-1 flex-wrap">
            {multipleSelectData.map((item, ind) => {
              return (
                <div className="mr-3 mb-2" key={item.id}>
                  <label className="flex gap-1 cursor-pointer items-center">
                    <input
                      id={item.value}
                      data-testid="checkbox-field"
                      type="checkbox"
                      className="custom-checkbox custom-checkbox--sm"
                      name={item.value}
                      value={item.checked}
                      checked={item.checked}
                      disabled={isBin}
                      onChange={
                        isBin
                          ? () => {}
                          : () => onMultipleSelectChange(item.value, ind, item)
                      }
                    />
                    <p className="text-sm font-medium">{item.value}</p>
                  </label>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default OptionsQuestionAdded;

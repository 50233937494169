/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Toggle from 'components/inputs/toggle';
import OverLayLoader from 'components/loader/overLayLoader';
import { useSnackbar } from 'components/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { updateAssignedToFilter } from 'slices/userProfileSlice';

const ThingsToDoSetting = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const { myInformation } = useSelector((state) => state.myInfo);
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const [assignedToFilter, setAssignedToFilter] = useState(
    myInformation.is_assigned_to_filter_in_things_to_do_active
  );

  const onSuccess = () => {
    setIsLoading(false);
    openSuccessSnackBar('Success');
  };

  const onError = () => {
    setIsLoading(false);
    openErrorSnackBar('Error');
    setAssignedToFilter((prev) => !prev);
  };

  const onToggle = () => {
    setIsLoading(true);
    setAssignedToFilter((prev) => !prev);
    dispatch(
      updateAssignedToFilter(
        {
          data: {
            is_assigned_to_filter_in_things_to_do_active: !assignedToFilter
          }
        },
        onSuccess,
        onError
      )
    );
  };

  return (
    <div className="`dashboard__main__body__section-right overflow-hidden p-10`">
      <div>
        <h2 className="text-2xl font-semibold text-center">Things to do</h2>
        <p className="text-grey-800 text-15 text-center mt-3">
          Manage settings related to things to do
        </p>
      </div>
      <div className="mt-6 card-box py-5 px-6 relative">
        {isLoading && <OverLayLoader />}
        <h3 className="font-semibold text-lg">Filter</h3>
        {/* <p className="text-sm text-grey-800 mt-1">
          A nudge is a little reminder from your enquiry assistant to do
          something.
        </p> */}

        <div className="border border-grey-400 rounded-xl p-4 mt-6 bg-grey-300 ">
          <div className="flex justify-between">
            <h3 className="font-semibold">
              Apply assigned to filter by default to me
            </h3>
            <div className="toggle-slider">
              <Toggle
                id="assigned_to"
                small
                checked={assignedToFilter}
                onChange={onToggle}
              />
            </div>
          </div>
          {/* <p className="text-sm text-grey-800 mt-2">
            This is a handy little helper to help you keep track of your
            enquiries
          </p> */}
        </div>
      </div>
    </div>
  );
};

export default ThingsToDoSetting;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import ZapierHome from './home';
import ZapierSettings from './settings';
// import AutomateStuff from '../../automate';
// import ChannelsConnectedEmptyState from '../../components/channelsConnectedEmpty';
// import AnimationWrapper from 'animation/wrapper';
import CHOICES from 'choices';
import Automations from 'modules/channels/components/automations';
import Settings from 'modules/channels/components/settings';

const { ZAPIER } = CHOICES.ChannelChoices;

const Zapier = () => {
  return (
    <Routes>
      {/* <Route element={<AnimationWrapper style={{ height: 'auto' }} />}> */}
      <Route path="/pending/:channelId" element={<ZapierHome />} />
      <Route path="/connected/:channelId" element={<ZapierSettings />} />
      <Route
        path="/connected/:channelId/automations"
        element={<Automations channelType={ZAPIER} />}
      />
      <Route
        path="/connected/:channelId/settings"
        element={<Settings channelType={ZAPIER} />}
      />
      {/* <Route path="/automation/*" element={<AutomateStuff />} /> */}
      {/* </Route> */}
    </Routes>
  );
};

export default Zapier;

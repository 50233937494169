import React from 'react';
import PropTypes from 'prop-types';
import iconBackArrow from 'assets/icons/icon-back-arrow.svg';

const Back = (props) => {
  const { showIcon, onClick, className } = props;
  return (
    <button
      data-testid="back-button"
      className={`btn btn--back btn--text ${className}`}
      onClick={onClick}
    >
      {showIcon && <img src={iconBackArrow} alt="icon-back-arrow" />}
      <span className="ml-2 text-grey-700 font-medium">Back</span>
    </button>
  );
};

Back.propTypes = {
  showIcon: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string
};

Back.defaultProps = {
  showIcon: false,
  className: ''
};

export default Back;

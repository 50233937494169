import React from 'react';
import HistoryLayout from './layout';
import user3Icon from 'assets/icons/icon-avatar-image-3.svg';
import { timeFormatter } from 'utils/utils';
import choices from 'choices';

export const HistoryReminder = ({ desc }) => {
  return (
    <div className="flex">
      <div className="flex py-3 text-13 px-3 justify-center align-center">
        {desc}
      </div>
    </div>
  );
};

const Reminder = (props) => {
  let {
    data: { title, created_at, description }
  } = props;
  return (
    <div>
      <HistoryLayout
        title={choices.TitleChoices.CHOICES[title]}
        icon={user3Icon}
        time={timeFormatter(created_at)}
        renderData={() => <HistoryReminder desc={description} />}
        className="history__note"
      />
    </div>
  );
};
export default Reminder;

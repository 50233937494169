/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useMemo } from 'react';
import Draggable from 'react-draggable';
import { Resizable } from 'react-resizable';

const getMeta = (url, cb) => {
  const img = new Image();
  img.onload = () => cb(null, img);
  img.onerror = (err) => cb(err);
  img.src = url;
};

const MAX_HEIGHT = 1000;
const MAX_WIDTH = 450;

const ImageResizer = (props) => {
  const { src, onSizeChange, id, imageSizes } = props;

  const [width, setWidth] = useState(100);
  const [height, setHeight] = useState(100);

  useMemo(() => {
    if (!imageSizes[id]) {
      getMeta(src, (err, img) => {
        if (img) {
          let imgWidth = img.naturalWidth;
          let imgHeight = img.naturalHeight;

          if (imgWidth > MAX_WIDTH) {
            imgWidth = MAX_WIDTH;
          }
          if (imgHeight > MAX_HEIGHT) {
            imgHeight = MAX_HEIGHT;
          }
          setWidth(imgWidth);
          setHeight(imgHeight);
          onSizeChange(id, { width: imgWidth, height: imgHeight });
        } else {
          setWidth(100);
          setHeight(100);
          onSizeChange(id, { width: 100, height: 100 });
        }
      });
    } else {
      setWidth(imageSizes[id].width);
      setHeight(imageSizes[id].height);
    }
  }, [src]);
  // return (
  //   <ResizableBox width={200} height={200}
  //       minConstraints={[30, 30]}
  //       onResize={onResize}
  //       >
  //     <img src={src} alt="image" />
  //   </ResizableBox>
  // );

  function handleResize(event, { size }) {
    setWidth(size.width);
    setHeight(size.height);
    onSizeChange(id, { width: size.width, height: size.height });
  }

  // function handleDrag(event, { lastX, lastY }) {
  //   console.log(`Image dragged to (${lastX}, ${lastY})`);
  // }

  return (
    <Resizable
      height={height}
      width={width}
      onResize={handleResize}
      resizeHandles={['s', 'w', 'e', 'n', 'sw', 'se', 'nw', 'ne']}
      // handle={<div className="foo" />}
      lockAspectRatio={false}
      minConstraints={[50, 50]}
      maxConstraints={[MAX_WIDTH, MAX_HEIGHT]}
    >
      <div
        className="box"
        style={{ width: width + 'px', height: height + 'px' }}
      >
        <img
          src={src}
          alt="image"
          style={{ width: width + 'px', height: height + 'px' }}
        />
      </div>
    </Resizable>
  );
};

export default ImageResizer;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unescaped-entities */
import React, { useState, useMemo, useEffect } from 'react';
import choices from 'choices';
import Modal from 'components/modal';
import iconCheck from 'assets/icons/icon-plans-feat-check.svg';
import iconClose from 'assets/icons/icon-modal-close.svg';
import logoVisa from 'assets/icons/logo-visa.svg';
import logoMasterCard from 'assets/icons/logo-mastercard.svg';
import logoAmericanExpress from 'assets/icons/logo-american-express.png';
import logoDirectDebit from 'assets/icons/logo-direct-debit.png';
import iconLock from 'assets/icons/icon-lock.svg';
import { useSelector, useDispatch } from 'react-redux';
import subscription_user from 'assets/icons/subscription_user.svg';
import subscription_user_2 from 'assets/icons/subscription_user_2.svg';
import swal from 'sweetalert';
import Avatar from 'components/avatar';

import iconLockGreen from 'assets/icons/icon-lock-green.svg';
import useGetUserRole from 'hooks/useGetUserRole';
import {
  setShowSubscriptionModal,
  setUpgradePlanMessage,
  subscriptionUpgradePlan
} from 'slices/subscription/slice';
import PrimaryButton from 'components/buttons/primary';
import { getMyInfo } from 'slices/myInfoSlice';
import { getCurrencyText } from 'utils/currency';
import { getOrganizationUser } from 'slices/organizationuserSlice';
const { STARTER, PRO } = choices.PlanType;

const STARTER_PLAN = STARTER;
const PRO_PLAN = PRO;

const paymentData = [
  { img: logoVisa },
  { img: logoMasterCard },
  { img: logoAmericanExpress },
  { img: logoDirectDebit }
];

const PlansSubscription = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const { subscriptionPlans, currentPlan } = useSelector(
    (state) => state.subscription
  );

  const [selectedPlan, setSelectedPlan] = useState(STARTER_PLAN);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const { isTeamMember, isAdmin } = useGetUserRole();

  const plansFeaturesData = [
    'Unlimited user accounts',
    'Lead source tracking reports',
    'Sales conversion reports',
    'Team performance reports',
    'Real time in app team chat',
    'Custom enquiry input forms',
    'Quick reply templates',
    'Tasks and reminders',
    'One on one call with our expert team',
    'Priority live support'
  ];
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const activePlan = useMemo(() => {
    const plan = subscriptionPlans.find(
      (plan) => plan.plan_type == selectedPlan
    );
    return plan;
  }, [selectedPlan, subscriptionPlans]);

  useEffect(() => {
    if (currentPlan && currentPlan?.plan?.plan_name === 'Starter') {
      setSelectedPlan(PRO_PLAN);
    }
  }, [currentPlan]);

  const GetFeatures = ({ activePlan }) => {
    const {
      box_report,
      card_design,
      custom_enquiry_form,
      reply_templates,
      sales_report,
      source_report,
      tasks,
      team_chat,
      team_performance_report,
      unlimited_automation,
      unlimited_boxes,
      unlimited_channel_connections,
      unlimited_enquiries,
      unlimited_user
    } = activePlan;

    const FeatureItem = ({ title }) => {
      return (
        <li className="flex items-center mb-3">
          <img src={iconCheck} />
          <span className="font-semibold ml-6">{title}</span>
        </li>
      );
    };

    if (activePlan.plan_type == STARTER_PLAN) {
      return (
        <ul className="mt-6">
          {!unlimited_user && (
            <li className="flex items-center mb-3">
              <img src={iconCheck} />
              <p className="font-semibold ml-6">
                <span className="underline">One user</span> account
              </p>
            </li>
          )}
          {unlimited_channel_connections && (
            <FeatureItem title="Unlimited channel connections" />
          )}
          {unlimited_enquiries && <FeatureItem title="Unlimited enquiries" />}
          {unlimited_boxes && <FeatureItem title="Unlimited boxes" />}
          {unlimited_automation && (
            <FeatureItem title="Unlimited automations" />
          )}
          <FeatureItem title="Basic enquiry snapshot reporting" />
          <FeatureItem title="Mobile App" />
          <FeatureItem title="Email support" />
        </ul>
      );
    }

    return (
      <ul className="mt-6">
        {unlimited_user && (
          <li className="flex items-center mb-3">
            <img src={iconCheck} />
            <span className="font-semibold ml-6 underline">
              Unlimited user accounts
            </span>
          </li>
        )}
        {source_report && <FeatureItem title="Marketing performance reports" />}
        {sales_report && <FeatureItem title="Sales conversion reports" />}
        {team_performance_report && (
          <FeatureItem title="Team performance reports" />
        )}
        {team_chat && <FeatureItem title="Real time in app team chat" />}
        {custom_enquiry_form && (
          <FeatureItem title="Custom enquiry input forms" />
        )}
        {reply_templates && <FeatureItem title="Quick reply templates" />}
        {tasks && <FeatureItem title="Tasks and reminders" />}
        <FeatureItem title="One on one call with our expert team" />
        <FeatureItem title="Priority live support" />
      </ul>
    );
  };
  const handleSubscription = () => {
    setLoadingFlag(true);
    let plan = subscriptionPlans.find((i) => i.plan_type === selectedPlan);
    let body = {
      plan_id: plan?.id,
      s_url: `${process.env.REACT_APP_FRONT_END_DOMAIN}/dashboard/enquirybox/eb-box?payment_status=success&plan_mode=upgrade`,
      f_url: `${process.env.REACT_APP_FRONT_END_DOMAIN}/dashboard/enquirybox/eb-box?payment_status=failed&plan_mode=upgrade`
    };
    dispatch(subscriptionUpgradePlan(body, handlecallback));
  };
  const handlecallback = (data) => {
    setLoadingFlag(false);
    if (data.response_type === 'checkout_url') {
      window.location.href = data.checkout_url;
    } else if (data.response_type === 'message') {
      setLoadingFlag(false);
      dispatch(setShowSubscriptionModal(false));
      dispatch(setUpgradePlanMessage(data.message));
      swal({
        title: data.message,
        text: '',
        icon: 'info',
        button: {
          text: 'Ok',
          value: true,
          visible: true,
          className: '',
          closeModal: true
        }
      }).then((result) => {
        dispatch(getMyInfo());
        dispatch(getOrganizationUser({ subOrgId: subOrganizationId }));
      });
    }
  };
  return (
    <Modal.Main
      className="modal--plans-subscription"
      mainStyle={{
        zIndex: 9999999
      }}
      open={open}
    >
      {(isTeamMember || isAdmin) && (
        <div
          style={{
            position: 'absolute',
            backgroundColor: 'rgb(255 255 255 / 90%)',
            width: '100%',
            height: '100%',
            zIndex: 9999,
            borderRadius: '50px'
          }}
        >
          <div
            className="flex relative text-[22px] font-semibold"
            style={{ left: '34%', top: '29%' }}
          >
            <div
              className="justify-center"
              style={{
                textAlign: 'center',
                lineHeight: '35px'
              }}
            >
              <img
                src={iconLockGreen}
                alt="icon lock green"
                className="h-[48px] w-[48px] mb-[25px] ml-auto mr-auto block"
              />
              Please contact your account
              <br /> admin to upgrade
            </div>
          </div>
        </div>
      )}
      <div className="modal__body p-8 pl-10">
        <div className="flex -mx-5">
          <div className="px-5 w-6/12">
            <div
              className={
                currentPlan?.plan?.plan_name === 'Starter'
                  ? 'header-toggle-without-border'
                  : 'header-toggle'
              }
              style={{ width: '227px', height: 42 }}
            >
              {currentPlan?.plan?.plan_name === 'Starter' && (
                <div className="header-toggle-without-border__switch header-toggle-without-border__switch--active">
                  <span className="text-sm letterspacing24em text-white font-semibold">
                    Pro Plan
                  </span>
                </div>
              )}
              {currentPlan?.plan?.plan_name !== 'Starter' &&
                subscriptionPlans.map((subscriptionPlan) => {
                  return (
                    <div
                      key={subscriptionPlan.id}
                      className={`header-toggle__switch ${
                        selectedPlan === subscriptionPlan.plan_type
                          ? 'header-toggle__switch--active'
                          : ''
                      }`}
                      onClick={() =>
                        setSelectedPlan(subscriptionPlan.plan_type)
                      }
                    >
                      <span
                        className={`text-sm font-medium letterspacing24em ${
                          selectedPlan === subscriptionPlan.plan_type
                            ? 'text-white font-semibold'
                            : 'text-grey-800'
                        }`}
                      >
                        {subscriptionPlan.plan_name}
                      </span>
                    </div>
                  );
                })}
            </div>
            {/* <div
                className={`header-toggle__switch ${selectedPlan === STARTER_PLAN
                  ? 'header-toggle__switch--active'
                  : ''
                  }`}
                onClick={() => setSelectedPlan(STARTER_PLAN)}
              >
                <span
                  className={`text-sm font-medium letterspacing24em ${selectedPlan === STARTER_PLAN
                    ? 'text-white font-semibold'
                    : 'text-grey-800'
                    }`}
                >
                  Starter Plan
                </span>
              </div> */}
            {/* <div
                className={`header-toggle__switch ${selectedPlan === PRO_PLAN ? 'header-toggle__switch--active' : ''
                  }`}
                onClick={() => setSelectedPlan(PRO_PLAN)}
              >
                <span
                  className={`text-sm font-medium ${selectedPlan === PRO_PLAN
                    ? 'text-white font-semibold'
                    : 'text-grey-800'
                    }`}
                >
                  Pro Plan
                </span>
              </div> */}
            <p className="text-grey-800 mt-4">
              {selectedPlan === STARTER_PLAN &&
                `For soloprenuers, or small businesses with a single member of the team managing enquiries.`}
              {selectedPlan === PRO_PLAN &&
                `For larger businesses with multiple people managing enquiries and providing reports.`}
            </p>
            <div className="mt-6">
              <label className="font-bold">Includes</label>
              {activePlan && <GetFeatures activePlan={activePlan} />}
            </div>
          </div>
          <div className="px-5 w-6/12">
            <div className="plans-payment-section">
              <h3 className="text-xl font-bold">{activePlan?.plan_name}</h3>
              <div className="card-payment-options bg-grey-90 border-radius-14 mt-5 p-5">
                <div className="flex items-start justify-between">
                  <div className="flex items-center">
                    <span className="text-3xl font-bold">
                      {`${getCurrencyText(activePlan?.currency)}${
                        activePlan?.amount
                      }`}
                    </span>
                    {/* <span className="text-grey-700 text-xl font-medium ml-2 line-through">
                      {selectedPlan === STARTER_PLAN
                        ? `${getCurrencyText(activePlan?.currency)}
                        ${activePlan?.amount + 10}`
                        : `${getCurrencyText(activePlan?.currency)}
                        ${activePlan?.amount + 10}`}
                    </span> */}
                    <span className="text-sm font-medium ml-2">/month</span>
                  </div>
                  {/* <div className="text-white text-10 font-semibold uppercase px-2 py-1 bg-primary rounded-md">
                    special offer
                  </div> */}
                </div>
                <p className="text-grey-800 text-xs mt-3">
                  Risk-free, cancel anytime, no long-term lock-in or contracts
                </p>
                <div className="flex -mx-1 mt-6">
                  {paymentData.map((item, idx) => (
                    <div
                      key={idx}
                      className="w-1/4 px-1"
                      style={{ height: 40 }}
                    >
                      <div className="w-full h-full flex items-center justify-center bg-white rounded-lg p-2">
                        <img src={item.img} />
                      </div>
                    </div>
                  ))}
                </div>
                <PrimaryButton
                  className="btn btn--primary w-full mt-6"
                  onClick={handleSubscription}
                  label="Upgrade"
                  isLoading={loadingFlag}
                  enableLoader={true}
                />

                <div className="flex items-center mt-3">
                  <img src={iconLock} width={8} height={8} />
                  <span className="text-xs ml-2" style={{ color: '#828282' }}>
                    Secure checkout using Stripe{' '}
                  </span>
                </div>
              </div>

              <div className="font-semibold mt-8">
                {selectedPlan === STARTER_PLAN && (
                  <p>
                    “Enquiries used to come in at all hours and get lost. Now
                    they all come into one place and we automatically follow up.
                    It's been a game changer!”
                  </p>
                )}
                {selectedPlan === PRO_PLAN && (
                  <p>
                    “Our sales have tripled! We've definately seen a massive
                    increase in enquiries just with Enquirybot on our website”
                  </p>
                )}
              </div>
              <div className="flex items-center mt-8">
                <Avatar
                  src={
                    selectedPlan === STARTER_PLAN
                      ? subscription_user_2
                      : subscription_user
                  }
                  style={{
                    width: 40,
                    height: 40
                  }}
                  large
                  cover
                />
                <div className="ml-3">
                  <h4 className="text-sm font-semibold">
                    {selectedPlan === STARTER_PLAN && 'Dr Chetan Sharma'}
                    {selectedPlan === PRO_PLAN && 'Claire Simpson'}
                  </h4>
                  <p className="text-grey-800 text-sm">
                    {selectedPlan === STARTER_PLAN &&
                      'Clear Smiles Dental Practice'}
                    {selectedPlan === PRO_PLAN && 'Kelham House Hotel'}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal__edge-close-btn cursor-pointer" onClick={onClose}>
        <img src={iconClose} width={8} height={8} />
        <span className="text-primary text-13 font-medium ml-2">Close</span>
      </div>
    </Modal.Main>
  );
};

export default PlansSubscription;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import PrimaryButton from 'components/buttons/primary';
import CustomColorButton from 'components/buttons/customColorButton';
import AutomationStuffWhen from '../../components/when';
import AutomationStuffThen from '../../components/then';
import { useParams } from 'react-router';
import {
  getConnectedChannel,
  sendAutomation,
  sendFacebookAutomationAutoReply,
  setEmailData,
  setEmailError,
  setTypeofAutomation,
  getFbLeadsAdsConnectedChannels,
  sendFacebookLeadAdsAutomationAutoReply,
  getAllEnquiryAndSubEnquiry,
  setIsPreview
} from 'slices/automationStuffSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  isEmpty,
  isEditorEmpty,
  isEditorOnlyContainsUserSignature
} from 'utils/utils';
import { getAllWebformMappingQuestions } from 'slices/webformMappingSlice';
import CHOICES from 'choices';
import { setAttachments } from 'slices/editor/slice';
import ErrorMessage from 'components/errorMessage';

const AUTORESPONDER_EMAIL = CHOICES.AutomationChoice.AUTORESPONDER_EMAIL;
const AUTORESPONDER_MESSAGE = CHOICES.AutomationChoice.SMS;
const FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER =
  CHOICES.AutomationChoice.FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER;
const { FACEBOOK, WEBFORM, SMS, EBOT, INSTAGRAM, FACEBOOK_LEAD_ADS, ZAPIER } =
  CHOICES.ChannelChoices;

const EmailAutomation = ({
  channelType,
  title,
  tempAutomationName,
  tempChannelType
}) => {
  const { connectedChannels, error, enquiryAndSubEnquiries, isPreview } =
    useSelector((state) => state.automationStuff);
  const { onEdit } = useSelector((state) => state.automationStuff);
  const parentRef = useRef();
  const { typeofAutomation, emailData } = useSelector(
    (state) => state.automationStuff
  );
  const [selectedChannelOption, setSelectedChannelOption] = useState();
  const [selectedBotOptions, setSelectedBotOptions] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [automationName, setAutomationName] = useState(title);
  const { channelId } = useParams();
  const dispatch = useDispatch();

  const [length, setLength] = useState(0);
  const [smsCount, setSmsCount] = useState();
  const { message, subject, send_from, signature } = emailData;

  const { attachments } = useSelector((state) => state.editor);

  const [filterdConnectedChannel, setFilteredConnectedChannel] = useState([]);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { purchased_phone_number } = useSelector(
    (state) => state.myInfo.myInformation
  );

  useEffect(() => {
    if (isEmpty(onEdit)) {
      setSelectedChannelOption();
      setSelectedBotOptions([]);
    }
  }, [onEdit]);

  useEffect(() => {
    setAutomationName(title);
  }, [title]);

  useEffect(() => {
    if (channelType === EBOT || tempChannelType === EBOT) {
      const payload = {
        subOrgId: subOrganizationId,
        channelId: channelId
      };
      dispatch(getAllEnquiryAndSubEnquiry(payload));
    }
  }, [channelType, tempChannelType]);

  useEffect(() => {
    dispatch(setEmailError({}));
    const payload = {
      channelId
    };
    if (channelType === WEBFORM || tempChannelType === WEBFORM) {
      dispatch(getConnectedChannel(payload));
    } else if (
      channelType === FACEBOOK_LEAD_ADS ||
      (channelType === SMS && tempChannelType === FACEBOOK_LEAD_ADS)
    ) {
      dispatch(getFbLeadsAdsConnectedChannels(payload));
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(connectedChannels)) {
      setFilteredConnectedChannel(
        connectedChannels?.map((item) => {
          return {
            label: item?.name,
            value: item?.id
          };
        })
      );
      // !isEmpty(newConnectdChannel) && setFilteredConnectedChannel(newConnectdChannel)
    }
  }, [connectedChannels]);

  useEffect(() => {
    dispatch(getAllWebformMappingQuestions());
  }, []);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === AUTORESPONDER_EMAIL &&
      filterdConnectedChannel
    ) {
      if (channelType === WEBFORM || channelType === FACEBOOK_LEAD_ADS) {
        const updatedChannelSelect = filterdConnectedChannel.filter((item) => {
          if (channelType === WEBFORM) {
            return item.value === onEdit.forms;
          } else if (channelType === FACEBOOK_LEAD_ADS) {
            return item.value === onEdit.fb_lead_ads_form;
          }
        });
        !isEmpty(updatedChannelSelect) &&
          setSelectedChannelOption(updatedChannelSelect[0]);
        if (onEdit.any_web_form) {
          setSelectedChannelOption({
            value: 'anyWebform',
            label: 'Any WebForm'
          });
        }
        dispatch(
          setEmailData({
            send_from: onEdit?.email_data[0]?.send_from,
            subject: onEdit?.email_data[0]?.subject,
            message: onEdit?.email_data[0]?.message,
            cc: onEdit?.email_data[0]?.cc,
            bcc: onEdit?.email_data[0]?.bcc
          })
        );
        dispatch(setAttachments(onEdit?.email_data[0]?.attachments));
      }
    } else if (
      onEdit?.automation_type === FB_OR_IG_MESSENGER_AUTO_REPLY_TO_CUSTOMER
    ) {
      dispatch(
        setEmailData({
          send_from: '',
          subject: '',
          message: onEdit?.reply_to_fb_or_ig_customer?.fb_raw_message,
          cc: '',
          bcc: ''
        })
      );
    } else if (onEdit?.automation_type === AUTORESPONDER_MESSAGE) {
      if (channelType === SMS) {
        dispatch(
          setEmailData({
            send_from: '',
            subject: '',
            message: onEdit?.raw_sms_message,
            cc: '',
            bcc: ''
          })
        );
      }
      if (tempChannelType === FACEBOOK_LEAD_ADS) {
        const updatedChannelSelect = filterdConnectedChannel.filter((item) => {
          return item.value === onEdit.fb_lead_ads_form;
        });
        !isEmpty(updatedChannelSelect) &&
          setSelectedChannelOption(updatedChannelSelect[0]);
      }
      if (tempChannelType === WEBFORM) {
        const updatedChannelSelect = filterdConnectedChannel.filter((item) => {
          return item.value === onEdit.forms;
        });
        !isEmpty(updatedChannelSelect) &&
          setSelectedChannelOption(updatedChannelSelect[0]);
        if (onEdit.any_web_form) {
          setSelectedChannelOption({
            value: 'anyWebform',
            label: 'Any WebForm'
          });
        }
      }
    }
    (channelType === FACEBOOK ||
      // channelType === SMS ||
      channelType === INSTAGRAM ||
      channelType === ZAPIER ||
      tempChannelType === ZAPIER) &&
      setSelectedChannelOption({ value: 'anyMessage', label: 'Any Message' });
    if (channelType === SMS && tempChannelType === SMS) {
      setSelectedChannelOption({ value: 'anyMessage', label: 'Any Message' });
    }
  }, [filterdConnectedChannel]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit.automation_type === AUTORESPONDER_EMAIL &&
      (channelType === EBOT || tempChannelType === EBOT)
    ) {
      const selectedOptionId = onEdit?.ebot_options.map((item) => item.id);
      setSelectedBotOptions(selectedOptionId);
      dispatch(
        setEmailData({
          send_from: onEdit?.email_data[0]?.send_from,
          subject: onEdit?.email_data[0]?.subject,
          message: onEdit?.email_data[0]?.message,
          cc: onEdit?.email_data[0]?.cc,
          bcc: onEdit?.email_data[0]?.bcc
        })
      );
      dispatch(setAttachments(onEdit?.email_data[0]?.attachments));
    }
  }, [onEdit]);

  useEffect(() => {
    if (!isEmpty(onEdit) && channelType === SMS && tempChannelType === EBOT) {
      const selectedOptionId = onEdit?.ebot_options.map((item) => item.id);
      setSelectedBotOptions(selectedOptionId);
      dispatch(
        setEmailData({
          send_from: '',
          subject: '',
          message: onEdit?.raw_sms_message,
          cc: '',
          bcc: ''
        })
      );
    }
  }, [onEdit]);

  useEffect(() => {
    if (
      !isEmpty(onEdit) &&
      onEdit?.automation_type === AUTORESPONDER_EMAIL &&
      channelType === ZAPIER
    ) {
      dispatch(
        setEmailData({
          send_from: onEdit?.email_data[0]?.send_from,
          subject: onEdit?.email_data[0]?.subject,
          message: onEdit?.email_data[0]?.message,
          cc: onEdit?.email_data[0]?.cc,
          bcc: onEdit?.email_data[0]?.bcc
        })
      );
      dispatch(setAttachments(onEdit?.email_data[0]?.attachments));
    }
  }, [onEdit]);

  const checkIsWebFormDataValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(automationName)) {
      err = {
        ...err,
        automationName: 'Please add and save your automation name'
      };
      isValid = false;
    }
    if (isEmpty(send_from)) {
      err = {
        ...err,
        fromEmail: 'Select from user'
      };
      isValid = false;
    }
    if (isEmpty(subject)) {
      err = {
        ...err,
        subject: 'Please add subject'
      };
      isValid = false;
    }
    if (isEditorEmpty(message)) {
      err = {
        ...err,
        message: 'Please add message'
      };
      isValid = false;
    }
    if (isEditorOnlyContainsUserSignature(message, signature)) {
      err = {
        ...err,
        message: 'Please add message'
      };
      isValid = false;
    }
    dispatch(setEmailError(err));
    return isValid;
  };

  const onSuccess = () => {
    setIsLoading(false);
    dispatch(setAttachments(''));
  };

  const checkIsFaceBookDataValid = () => {
    let isValid = true;
    let err = {};
    if (isEmpty(automationName)) {
      err = {
        ...err,
        automationName: 'Please add your automation name'
      };
      isValid = false;
    }
    if (isEditorEmpty(message)) {
      err = {
        ...err,
        message: 'Please add message'
      };
      isValid = false;
    }
    dispatch(setEmailError(err));
    return isValid;
  };

  const handleSave = () => {
    setIsLoading(true);
    if (channelType === WEBFORM) {
      const isValid = checkIsWebFormDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      const filterdAttachments = attachments?.map((item) => {
        return item.id;
      });

      const payload = {
        type: 'post',
        data: {
          email_data: {
            ...emailData,
            message: message
              .replaceAll(/<p/g, '<div')
              .replaceAll(/<\/p/g, '</div'),
            send_from:
              typeof emailData.send_from === 'object'
                ? emailData.send_from.id
                : emailData.send_from,
            attachments: filterdAttachments
          },
          automation_name: automationName,
          any_web_form: selectedChannelOption?.value === 'anyWebform',
          automation_type: typeofAutomation,
          is_active: true,
          connected_channel: channelId
        }
      };
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['data']['id'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      if (selectedChannelOption?.value !== 'anyWebform') {
        payload.data['forms'] = selectedChannelOption?.value;
      }
      dispatch(sendAutomation(payload, onSuccess));
    }
    if (channelType === FACEBOOK || channelType === INSTAGRAM) {
      const isValid = checkIsFaceBookDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      var temp = document.createElement('div');
      temp.innerHTML = message;
      var sanitized = temp.textContent || temp.innerText;
      const value = sanitized;
      const payload = {
        type: 'post',
        data: {
          automation_name: automationName,
          // any_web_form: selectedChannelOption?.value === 'anyWebform',
          message: value,
          automation_type: typeofAutomation,
          is_active: true,
          connected_channel_id: channelId,
          fb_raw_message: message
        }
      };
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['data']['id'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      // if (selectedChannelOption?.value !== 'anyWebform') {
      //   payload.data['forms'] = selectedChannelOption?.value;
      // }
      dispatch(sendFacebookAutomationAutoReply(payload));
    }
    if (channelType === FACEBOOK_LEAD_ADS) {
      const isValid = checkIsWebFormDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      const filterdAttachments = attachments?.map((item) => {
        return item.id;
      });
      const payload = {
        type: 'post',

        data: {
          auto_responder_email: {
            ...emailData,
            message: message
              .replaceAll(/<p/g, '<div')
              .replaceAll(/<\/p/g, '</div'),
            send_from:
              typeof emailData.send_from === 'object'
                ? emailData.send_from?.id
                : emailData.send_from,
            attachments: filterdAttachments
          },
          automation: {
            automation_name: automationName,
            automation_type: typeofAutomation,
            is_active: true,
            connected_channel: channelId,
            fb_lead_ads_form: selectedChannelOption?.value
          }
        }
      };
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['automationId'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      dispatch(sendFacebookLeadAdsAutomationAutoReply(payload));
    }
    if (channelType === SMS) {
      const isValid = checkIsFaceBookDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      var tempMessage = document.createElement('div');
      tempMessage.innerHTML = message;
      var sanitizedMessage = tempMessage.textContent || tempMessage.innerText;
      const value = sanitizedMessage;
      const payload = {
        type: 'post',
        data: {
          automation_name: automationName,
          // any_web_form: selectedChannelOption?.value === 'anyWebform',
          sms_message: value,
          automation_type: CHOICES.AutomationChoice.SMS,
          is_active: true,
          connected_channel: channelId,
          sms_count: smsCount,
          raw_sms_message: message,
          from_phone_number: purchased_phone_number
        }
      };
      if (tempChannelType === EBOT) {
        payload['data']['ebot_options'] = selectedBotOptions;
      }
      if (tempChannelType === WEBFORM) {
        payload['data']['any_web_form'] =
          selectedChannelOption?.value === 'anyWebform';
        if (selectedChannelOption?.value !== 'anyWebform') {
          payload['data']['forms'] = selectedChannelOption?.value;
        }
      }
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['data']['id'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      if (channelType === SMS && tempChannelType === FACEBOOK_LEAD_ADS) {
        payload['data']['fb_lead_ads_form'] = selectedChannelOption?.value;
      }
      dispatch(sendAutomation(payload, onSuccess));
    }
    if (channelType === EBOT) {
      const isValid = checkIsWebFormDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      const filterdAttachments = attachments?.map((item) => {
        return item.id;
      });
      const payload = {
        type: 'post',
        data: {
          email_data: {
            ...emailData,
            message: message
              .replaceAll(/<p/g, '<div')
              .replaceAll(/<\/p/g, '</div'),
            send_from:
              typeof emailData.send_from === 'object'
                ? emailData.send_from.id
                : emailData.send_from,
            attachments: filterdAttachments
          },
          automation_name: automationName,
          any_web_form: selectedChannelOption?.value === 'anyWebform',
          automation_type: typeofAutomation,
          is_active: true,
          connected_channel: channelId,
          ebot_options: selectedBotOptions
        }
      };
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['data']['id'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      dispatch(sendAutomation(payload, onSuccess));
    }
    if (channelType === ZAPIER) {
      const isValid = checkIsWebFormDataValid();
      if (!isValid) {
        setIsLoading(false);
        return;
      }
      const filterdAttachments = attachments?.map((item) => {
        return item.id;
      });
      const payload = {
        type: 'post',
        data: {
          email_data: {
            ...emailData,
            message: message
              .replaceAll(/<p/g, '<div')
              .replaceAll(/<\/p/g, '</div'),
            send_from:
              typeof emailData.send_from === 'object'
                ? emailData.send_from.id
                : emailData.send_from,
            attachments: filterdAttachments
          },
          automation_name: automationName,
          any_web_form: selectedChannelOption?.value === 'anyWebform',
          automation_type: typeofAutomation,
          is_active: true,
          connected_channel: channelId
        }
      };
      if (!isEmpty(onEdit)) {
        payload['type'] = 'put';
        payload['data']['id'] = onEdit.id;
        payload['data']['is_active'] = onEdit.is_active;
      }
      dispatch(sendAutomation(payload, onSuccess));
    }
  };

  return (
    <>
      <div className="mt-6" ref={parentRef}>
        {(channelType === WEBFORM ||
          channelType === FACEBOOK_LEAD_ADS ||
          channelType === ZAPIER ||
          channelType === EBOT ||
          channelType === SMS) && (
          <AutomationStuffWhen
            options={
              channelType === EBOT || tempChannelType === EBOT
                ? enquiryAndSubEnquiries
                : filterdConnectedChannel
            }
            automationName={automationName}
            setAutomationName={setAutomationName}
            selectedOption={
              channelType === EBOT || tempChannelType === EBOT
                ? selectedBotOptions
                : selectedChannelOption
            }
            setSelectedOption={
              channelType === EBOT || tempChannelType === EBOT
                ? setSelectedBotOptions
                : setSelectedChannelOption
            }
            channelType={channelType}
            parentRef={parentRef}
            onEdit={onEdit}
            tempChannelType={tempChannelType}
          />
        )}
        {(selectedChannelOption || !isEmpty(selectedBotOptions)) && (
          <AutomationStuffThen
            selectedChannelOption={selectedChannelOption}
            channelType={channelType}
            setLength={setLength}
            length={length}
            setSmsCount={setSmsCount}
            title={tempAutomationName}
            tempChannelType={tempChannelType}
          />
        )}
        <div className="flex justify-between items-center mt-4">
          <div>
            {!isEmpty(error) && error.message && (
              <ErrorMessage message="Please fill all the required fields" />
            )}
          </div>
          <div className="flex justify-end">
            <CustomColorButton
              className="btn--xs btn--grey  mr-2"
              label={`${isPreview ? 'Close Preview' : 'Cancel'}`}
              onClick={() => {
                dispatch(setIsPreview(false));
                dispatch(setTypeofAutomation('')), dispatch(setAttachments(''));
              }}
            />
            {(selectedChannelOption || !isEmpty(selectedBotOptions)) &&
              !isPreview && (
                <PrimaryButton
                  isLoading={isLoading}
                  loaderInline={true}
                  enableLoader={true}
                  className="btn--xs"
                  label="Save"
                  onClick={() => handleSave()}
                  // isDisabled={
                  //   channelType === SMS && purchased_phone_number === null
                  //     ? true
                  //     : false
                  // }
                />
              )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailAutomation;

const invalidMsg = (fieldName) => `${fieldName} is invalid`;
const requiredMsg = (fieldName) => `Please add your ${fieldName}`;
const requiredSelectMsg = (fieldName) => `Please select your ${fieldName}`;
const numericMsg = (fieldName) => `${fieldName} must be numeric`;
const positiveNumMsg = (fieldName) => `${fieldName} must be positive number`;
const moreThanNumMsg = (fieldName, thresholdFieldName) =>
  `${fieldName} must be greater than ${thresholdFieldName}`;
const moreThanOrEqualToMsg = (fieldName, thresholdFieldName) =>
  `${fieldName} must be greater than or equal to ${thresholdFieldName}`;
const minimumCharactersMsg = (fieldName, number) =>
  `${fieldName} is too short, should be ${number} characters minimum`;
const doesNotMatch = (fieldName1) =>
  `${fieldName1} does not match please try again`;
const equalityMsg = (fieldName1, fieldName2) =>
  `${fieldName1} must be equal to ${fieldName2}`;
const inEqualityMsg = (fieldName1, fieldName2) =>
  `${fieldName1} must not be equal to ${fieldName2}`;
const unAvailableMsg = (fieldName) => `${fieldName} is not available`;
const tooLargeMsg = (fieldName, max) =>
  `${fieldName} is too large. It can be maximum ${max}.`;
const unsupportedMsg = (fieldName, supported) =>
  `${fieldName} is unsupported. Only ${(supported || []).join(
    ', '
  )} are supported.`;
const dateInvalidMsg = (fieldName, reason) =>
  `${fieldName} must not be ${reason}.`;
const onlyAlphabetsMsg = (fieldName) =>
  `${fieldName} must contain only alphabets`;
const maximumCharactersMsg = (fieldName, number) =>
  `${fieldName} is too long, should be ${number} characters maximum`;
const passwordValidation =
  () => `Must contain 8 characters, one uppercase, one lowercase,
one number and one special case character`;

export {
  invalidMsg,
  requiredMsg,
  requiredSelectMsg,
  numericMsg,
  positiveNumMsg,
  moreThanNumMsg,
  moreThanOrEqualToMsg,
  minimumCharactersMsg,
  equalityMsg,
  inEqualityMsg,
  unAvailableMsg,
  tooLargeMsg,
  unsupportedMsg,
  dateInvalidMsg,
  onlyAlphabetsMsg,
  maximumCharactersMsg,
  doesNotMatch,
  passwordValidation
};

import React, { useState } from 'react';
import TextInputField from 'components/inputs/textField';
import { isNumberWithDecimal } from 'utils/utils';
import { getValuePlaceHolder } from 'modules/boxes/helper';
import { getCurrencyText } from 'utils/currency';

const ValueTypeForm = ({ data, onChange }) => {
  const [inputValue, setInputValue] = useState('');

  const onhandleChange = (value, id) => {
    if (isNumberWithDecimal(value)) {
      setInputValue(value);
      onChange(value, id);
    }
  };

  return (
    <form className="pb-4" autoComplete="off">
      <label className="input-field-label">{data.text}</label>
      <div className="relative">
        <TextInputField
          name="answer"
          className="input-field--type2"
          style={{ padding: '9px 15px' }}
          value={inputValue}
          placeholder={getValuePlaceHolder(data?.choice_set[0]?.text)}
          onChange={({ target }) => {
            onhandleChange(target.value, data.id);
          }}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault();
            }
          }}
        />
        <div className="input-select-svg-dropdown text-13 font-semibold text-primary">
          {getCurrencyText(data?.choice_set[0]?.text)}
        </div>
      </div>
    </form>
  );
};

export default ValueTypeForm;

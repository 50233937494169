import React, { useRef, useState } from 'react';
import TextArea from 'components/inputs/textarea';
import PrimaryButton from 'components/buttons/primary';
import Toggle from 'components/inputs/toggle';
import CustomColorButton from 'components/buttons/customColorButton';

const EnquiryBotScript = (props) => {
  const { scriptCode, isActiveBot, onChange, botId } = props;
  const codeRef = useRef();
  const [copied, setCopied] = useState(false);

  const copyToClipboard = () => {
    codeRef.current.select();
    document.execCommand('copy');
    setCopied(true);
  };

  const onPreviewBot = () => {
    window.open(
      `${process.env.REACT_APP_BOT_PREVIEW_LINK}/BotType-${botId}`,
      '_blank'
    );
  };

  return (
    <div>
      <TextArea
        name="enquiryBot"
        rows="2"
        cols="5"
        value={scriptCode}
        style={{ padding: '15px 20px', fontWeight: 'normal' }}
        className="border text-13 mt-4 resize-none"
        codeRef={codeRef}
      />
      <div className="flex justify-between mt-3">
        <div className="flex">
          <PrimaryButton
            className="text-13 mr-2"
            label={copied ? 'Copied' : 'Copy Code'}
            onClick={copyToClipboard}
            style={{ padding: '5px 12px' }}
          />
          <CustomColorButton
            className="btn--default"
            label="Preview bot"
            onClick={onPreviewBot}
          />
        </div>
        <div className="flex items-center">
          <p className="text-grey-800 text-15">Turn bot off</p>
          <Toggle id="bot-status" checked={isActiveBot} onChange={onChange} />
        </div>
      </div>
    </div>
  );
};

export default EnquiryBotScript;

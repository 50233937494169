import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import ReplyLater from 'modules/boxes/components/replyLater';
import Lead from '../../../lead';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty } from 'utils/utils';
import { onUnreadLeadFocused, setCurrentBoxCardList } from 'slices/boxes/slice';
import choices from 'choices';
import { setActiveBoxNewLead } from 'slices/boxes/slice';
const { REPLY_LATER } = choices.LeadStatusChoices;

const StageReplyLater = (props) => {
  const dispatch = useDispatch();
  const { stageLeads, activeBoxNewLead } = useSelector((state) => state.boxes);
  const { stageId, box } = props;
  const replyLaterLeads = stageLeads[stageId]?.reply_later;

  // if (isEmpty(replyLaterLeads) && !isEmpty(dragStart)) {
  //   if (dragStart.droppableId.includes('REPLY')) {
  //     return (
  //       <Droppable droppableId={`${stageId}-REPLY`} direction="horizontal">
  //          {(provided) => {
  //             return (
  //               <div style={{
  //                 background: 'red',
  //                 height: 50,
  //                 width: '100%'
  //               }} ref={provided.innerRef} {...provided.droppableProps}>

  //               </div>
  //             )
  //          }}
  //       </Droppable>
  //     )
  //   }
  // }

  const onLeadClick = () => {
    dispatch(setCurrentBoxCardList(REPLY_LATER));
    if (!isEmpty(activeBoxNewLead)) {
      dispatch(setActiveBoxNewLead(null));
      dispatch(onUnreadLeadFocused({ stageId }));
    }
  };

  if (isEmpty(replyLaterLeads)) {
    return <></>;
  }

  return (
    <ReplyLater
      count={replyLaterLeads.length}
      replyLaterLeads={replyLaterLeads}
    >
      <div className="mt-4 mb-4">
        {replyLaterLeads.map((lead, index) => {
          return (
            <Draggable
              key={`${lead.id}_REPLY`}
              draggableId={`${lead.id}_REPLY`}
              index={index}
            >
              {(provided) => {
                return (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    onClick={onLeadClick}
                  >
                    <Lead
                      lead={lead}
                      box={box}
                      stageId={stageId}
                      index={index}
                    />
                  </div>
                );
              }}
            </Draggable>
          );
        })}
      </div>
    </ReplyLater>
  );
};

export default StageReplyLater;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { formatBytes, isEmpty } from 'utils/utils';
import APIRequest from 'apiRequest';
import { FILE_UPLOAD, FILE_UPLOAD_DELETE, UPLOAD_CONTACTS_CSV } from 'urls';
import ProgressBar from 'components/progressBar';
import { fileIcons, getFileExtension } from 'utils/file';
// import docsIcon from 'assets/icons/icon-uploaded-docs.svg';
import moreIcon from 'assets/icons/icon-more-icon.svg';
import renameIcon from 'assets/icons/icon-select-rename.svg';
import deleteIcon from 'assets/icons/icon-Delete.svg';
import useClickOutside from 'hooks/useOutsideClick';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import RenameForm from './renameForm';
import { useSnackbar } from 'components/Snackbar';
import { replaceUrl } from 'utils/urlReplace';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  setUploadedFileError,
  setUploadedFileId
} from 'slices/boxSettings/uploadContactsSlice';
// import { useSelector } from 'react-redux';
import CHOICES from 'choices';
import { route } from 'utils/route';
import { useAnalytics } from 'use-analytics';

const FileUploading = ({
  data,
  index,
  onFileUploaded,
  onFileDelete,
  showDropDown,
  setShowdropDown,
  isContactUploadModal,
  setIsContactModalUploading
}) => {
  const { openErrorSnackBar, openSuccessSnackBar } = useSnackbar();
  const nodeRef = useRef();
  const isUploaded = data.isUploading;
  const fileSize = formatBytes(data?.file?.size);
  const fileName = data?.file_name;
  const [error, setError] = useState({});
  const [progress, setProgress] = useState(0);
  const [showMoreDropDown, setShowMoreDropDown] = useState(showDropDown);
  const [isLoading, setIsLoading] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const { box_id } = useParams();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const dispatch = useDispatch();
  // const { analytics } = useSelector((state) => state.segments);
  const { track } = useAnalytics();

  const location = useLocation();
  const checkUrl = location && location.pathname;

  useClickOutside(() => setShowMoreDropDown(false), nodeRef.current);

  useEffect(() => {
    setShowMoreDropDown(showDropDown);
  }, [showDropDown]);

  const options = {
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent;
      let precentage = Math.floor((loaded * 100) / total);
      if (precentage <= 100) {
        setProgress(precentage);
      }
    }
  };

  useEffect(() => {
    if (data.isUploading) {
      uploadFile();
    }
  }, []);

  // to delete the file
  const fileDelete = () => {
    setIsLoading(true);
    new APIRequest()
      .delete(replaceUrl(FILE_UPLOAD_DELETE, 'attachmentId', data?.id))
      .then((res) => {
        if (res.status === 200) {
          setIsLoading(false);
          onFileDeleteSuccess();
          openSuccessSnackBar('Successfully deleted');
        }
      })
      .catch((err) => {
        setIsLoading(false);
        setShowDeleteConfirmation(null);
        openErrorSnackBar('failed to delete');
        console.log(err);
      });
  };

  const onFileDeleteSuccess = () => {
    setShowDeleteConfirmation(null);
    onFileDelete({
      index
    });
  };

  // on file uploading is success
  const onSuccess = (imageUrl, id) => {
    const fileData = {
      ...data,
      id: id,
      isUploading: false,
      file_url: imageUrl
    };
    onFileUploaded({
      index,
      data: fileData
    });
    if (isContactUploadModal) {
      dispatch(setUploadedFileId(id));
      setIsContactModalUploading(true);
    }
  };

  // to upload file to s3
  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', data.file);
    if (checkUrl.split('/').includes(route.fileLibrary)) {
      formData.append(
        'uploading_type',
        CHOICES.FileUploadingChoices.FILE_LIBRARY
      );
    } else {
      formData.append(
        'uploading_type',
        CHOICES.FileUploadingChoices.EMAIL_OUTGOING
      );
    }
    setIsLoading(true);
    var fileUrl = '';
    if (isContactUploadModal && !isEmpty(box_id)) {
      fileUrl = replaceUrl(UPLOAD_CONTACTS_CSV, 'boxId', box_id);
    } else {
      fileUrl = FILE_UPLOAD;
    }
    new APIRequest()
      .post(
        fileUrl,
        formData,
        {
          'Content-Type': 'multipart/form-data'
        },
        options
      )
      .then((res) => {
        if (
          formData.get('uploading_type') ==
          CHOICES.FileUploadingChoices.FILE_LIBRARY
        ) {
          track(
            'file_upload',
            {
              file_type: getFileExtension(res?.data?.imageUrl)
            },
            {
              groupId: subOrganizationId
            }
          );
        }
        onSuccess(
          res?.data?.imageUrl,
          res?.data?.id || res?.data?.attachment_id
        );
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        setError({ uploading: 'fail' });
        openErrorSnackBar('failed to upload');
        dispatch(
          setUploadedFileError(
            !isEmpty(err?.response?.data?.file?.error)
              ? err?.response?.data?.file?.error
              : 'failed to upload please check your connection and retry'
          )
        );
        setIsLoading(false);
      });
  };

  // to update file name
  const onFileNameSave = (value) => {
    const fileData = {
      ...data,
      file_name: value
    };
    onFileUploaded({
      index,
      data: fileData
    });
    setIsEdit(false);
  };

  const getProgress = () => {
    if (progress >= 95 && isLoading) {
      return 95;
    }
    return progress;
  };

  const onCancel = () => {
    onFileDelete({ index });
    setError({});
    dispatch(setUploadedFileError(''));
  };

  if (data.isUploading) {
    if (data.isUploading || isContactUploadModal)
      return (
        <>
          <div
            className={`${
              isUploaded ? 'modal__uploaded-section' : 'modal__progress-section'
            } mb-0`}
          >
            <div className="flex justify-between items-center">
              <div className={`${isUploaded ? 'flex items-center' : ''}`}>
                <div
                  className={`${
                    isUploaded ? 'text-sm font-medium' : 'text-13 font-semibold'
                  }`}
                  style={{ wordBreak: 'break-all', marginRight: '10px' }}
                >
                  {fileName}
                </div>
              </div>
              <div className="flex justify-end min-w-110">
                <div className="text-13 text-grey-800 mr-2 w-max">
                  {fileSize}
                </div>
                {error?.uploading && (
                  <>
                    <div
                      className="txt-blue files__cancel"
                      onClick={() => {
                        setError({});
                        uploadFile();
                      }}
                    >
                      Retry
                    </div>
                    <div
                      className="txt-blue files__cancel ml-2 error-msg"
                      onClick={onCancel}
                    >
                      Cancel
                    </div>
                  </>
                )}
              </div>
            </div>
            {!error?.uploading && (
              <div className="mt-2">
                <ProgressBar progress={getProgress(progress)} />
              </div>
            )}
          </div>
        </>
      );
  }

  if (!data.isUploading) {
    return (
      <>
        <div className={`modal__progress-section mb-0`}>
          <div className="flex justify-between items-center">
            {!isEdit && (
              <>
                <div className="flex items-center">
                  <img
                    src={fileIcons[getFileExtension(data.file_url)]}
                    alt=""
                    className="mr-3"
                    style={{ width: 25, height: 32 }}
                  />
                  <div
                    className={`${
                      isUploaded
                        ? 'text-sm font-medium'
                        : 'text-13 font-semibold'
                    } break-all mr-2`}
                  >
                    {fileName}
                  </div>
                </div>
                {!data.isFileLibrary && (
                  <div
                    className="relative flex items-center files__card--right-side cursor-pointer"
                    onClick={() => {
                      setShowMoreDropDown(index);
                      setShowdropDown(index);
                    }}
                  >
                    <img src={moreIcon} alt="" />
                    <div
                      ref={nodeRef}
                      className={`dropdown-menu dropdown-menu--edit ${
                        showMoreDropDown === index ? 'show' : ''
                      }`}
                    >
                      <div
                        className="dropdown-menu__list text-grey-800"
                        onClick={(e) => {
                          e.stopPropagation();
                          setIsEdit(true);
                          // setShowdropDown(null);
                        }}
                      >
                        <img
                          src={renameIcon}
                          alt=""
                          className="mr-2"
                          style={{ width: 16, height: 16 }}
                        />
                        Rename
                      </div>
                      {!isContactUploadModal && (
                        <div
                          className="dropdown-menu__list"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowMoreDropDown(null);
                            setShowdropDown(null);
                            setShowDeleteConfirmation(index);
                          }}
                          style={{ color: '#f04438' }}
                        >
                          <img
                            src={deleteIcon}
                            alt=""
                            className="mr-2"
                            style={{ width: 16, height: 16 }}
                          />
                          Delete
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
            {isEdit && (
              <RenameForm
                fileName={data?.file_name}
                onCancel={() => setIsEdit(false)}
                onSave={onFileNameSave}
              />
            )}
          </div>
        </div>
        {showDeleteConfirmation !== null && (
          <DeleteConfirmation
            showConfirmation={showDeleteConfirmation !== null ? true : false}
            onCancel={() => setShowDeleteConfirmation(null)}
            onConfirm={fileDelete}
            title="Are you sure you want to delete this file"
            description="Once deleted you will not be able to get it back. You will have to
      upload it again."
            isLoading={isLoading}
          />
        )}
      </>
    );
  }

  return <></>;
};

export default FileUploading;

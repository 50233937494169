/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes, { func, object, string } from 'prop-types';
import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';
import Select from 'components/inputs/select';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { deleteStage, setCurrentBox } from 'slices/boxes/slice';
import { Loader } from 'components/loader';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';

const BoxDeleteConfirmation = (props) => {
  const {
    showConfirmation,
    onCancel,
    confirmButtonText,
    containerStyle,
    stage_id,
    hasAutomation,
    stageleadCount
  } = props;

  const { currentBox, stageLeads } = useSelector((state) => state.boxes);
  const { box_id, stageId, box_type } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();

  const isStageEmpty = () => {
    const stage_leads = stageLeads[stage_id];
    if (stage_leads || stageLeads) {
      return stage_leads?.count <= 0 || stageleadCount <= 0;
    }
    return false;
  };

  const boxStages = useMemo(() => {
    return currentBox.boxstage_set;
  }, [box_id, currentBox]);

  const [boxStageOptions, setBoxStageOptions] = useState([]);
  const [selectedStage, setSelectedStage] = useState();
  const [isLoading, setIsLoading] = useState();

  useEffect(() => {
    if (!isEmpty(boxStages)) {
      const tempBoxstageoptions = boxStages
        ?.filter((stages) => stages.id !== stage_id)
        .map((item) => {
          // if (item.id !== stage_id) {
          return { label: item?.stage_name, value: item?.id };
          // }
        });
      setBoxStageOptions(tempBoxstageoptions);
    }
  }, [boxStages, stage_id]);

  const onSuccess = (res) => {
    dispatch(setCurrentBox(res?.box));
    openSuccessSnackBar('Success');
  };
  const onError = () => {
    openErrorSnackBar('Error');
  };

  const onDelete = () => {
    setIsLoading(true);
    let payload = {};
    if (!isStageEmpty()) {
      payload = {
        stageId: stage_id,
        data: {
          move_to_stage: selectedStage?.value
        }
      };
    } else {
      payload = {
        stageId: stage_id,
        data: {}
      };
    }
    dispatch(deleteStage(payload, onSuccess, onError));
    if (!isEmpty(stageId)) {
      navigate(`/dashboard/boxes/${box_type}/${box_id}`);
    }
  };

  if (isStageEmpty()) {
    return (
      <DeleteConfirmation
        showConfirmation={showConfirmation}
        onCancel={onCancel}
        onConfirm={onDelete}
        title="Are you sure you want to delete this stage"
        description={
          hasAutomation
            ? 'If this stage is deleted, all the automations applied to this stage will also be deleted.'
            : ''
        }
        isLoading={isLoading}
      />
    );
  }

  return (
    <div className="">
      <Modal.Main
        open={showConfirmation}
        className="modal--channels-request relative "
        containerStyle={containerStyle}
      >
        {isLoading && (
          <div
            className="absolute flex justify-center items-center"
            style={{
              width: '100%',
              height: '100%',
              backdropFilter: 'blur(1px)',
              zIndex: 1
            }}
          >
            <Loader />
          </div>
        )}
        <Modal.Header title="Delete Stage" onClose={onCancel} />
        <div
          className="text-grey-900 text-sm px-8  py-8 font-medium"
          style={{ background: 'rgba(255, 219, 125, 0.42)' }}
        >
          <div className="flex justify-center item-center">
            <p>
              In order to delete this stage you will need to move the enquiry
              cards to another stage in this box. Select the stage below.
            </p>
          </div>
          {hasAutomation && (
            <p className="mt-1">
              If this stage is deleted, all the automations applied to this
              stage will also be deleted.
            </p>
          )}
        </div>
        <Modal.Body className="pb-10 py-8 ">
          <React.Fragment>
            <form autoComplete="off">
              <label className="text-lg font-semibold text-grey-900  ml-1 ">
                Select stage
              </label>
              <Select
                className="mt-2"
                placeholder="Select the stage to move enquiry cards"
                name="automation-then"
                options={boxStageOptions}
                onChange={(value) => setSelectedStage(value)}
                dropDownClassName="p-2"
                inputClassName="input-field--md pl-10"
                selectedValue={selectedStage}
              />
            </form>
            <div className="flex items-center gap-2 justify-end mt-10">
              <CustomColorButton
                label="Cancel"
                className="btn--wide-md-40 btn--cancel text-base-1 mr-3 cursor-pointer"
                onClick={onCancel}
              />
              <CustomColorButton
                label={confirmButtonText}
                className="btn--wide-md-40 text-base-1 disable bg-red-900 text-white  cursor-pointer relative"
                onClick={onDelete}
                enableLoader
                isDisable={isLoading || isEmpty(selectedStage)}
              />
            </div>
          </React.Fragment>
        </Modal.Body>
      </Modal.Main>
    </div>
  );
};

BoxDeleteConfirmation.propTypes = {
  showConfirmation: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string,
  isLoading: PropTypes.bool
};

BoxDeleteConfirmation.defaultProps = {
  confirmButtonText: 'Delete',
  isLoading: false
};

export default BoxDeleteConfirmation;

/* eslint-disable react-hooks/exhaustive-deps */
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as PlusIcon } from 'assets/icons/icon-plus-icon.svg';
import { ReactComponent as EmptyLabelIcon } from 'assets/icons/icon-settings-empty-label.svg';
import LabelItem from 'modules/settings/components/labelItem';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import AddLabel from 'modules/settings/modals/addLabel';
import {
  deleteLabels,
  getLeadLabel,
  setError,
  setLabels
} from 'slices/labelsSlice';
import { Loader } from 'components/loader';

const LabelSettings = () => {
  const labelNode = useRef();
  const dispatch = useDispatch();
  const { allLabels } = useSelector((state) => state.labels);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const [openEdit, setOpenEdit] = useState(null);
  const [addLabel, setAddLabel] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(null);
  const [showMoreDropdown, setShowMoreDropdown] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState(false);

  useClickOutside(() => setShowMoreDropdown(null), labelNode.current);

  const renderCallback = () => {
    setIsLoading(false);
  };

  const loadLabels = (subOrgId) => {
    setIsLoading(true);
    dispatch(getLeadLabel({ subOrgId: subOrgId, callback: renderCallback }));
  };

  useEffect(() => {
    loadLabels(subOrganizationId);
  }, []);

  const onDelete = () => {
    setIsDeleteLoading(true);
    const formData = {
      labelId: openDeleteModal,
      callback: callback
    };
    dispatch(deleteLabels(formData));
  };

  const callback = (id) => {
    const remainingLabel = allLabels.filter((item) => item.id !== id);
    dispatch(setLabels(remainingLabel));
    setIsDeleteLoading(false);
    setOpenDeleteModal(null);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <DeleteConfirmation
        showConfirmation={openDeleteModal !== null ? true : false}
        onCancel={() => setOpenDeleteModal(null)}
        onConfirm={onDelete}
        title="Are you sure you want to delete this label?"
        description="Once deleted you will not be able to get it back. You will have to
      add it again."
        isLoading={isDeleteLoading}
      />
      <AddLabel
        addLabel={addLabel}
        onClose={() => {
          dispatch(setError([]));
          setAddLabel(false);
        }}
      />
      <div className="flex flex-col items-center">
        <h2 className="text-2xl font-semibold">Labels</h2>
        <p className="text-grey-800 text-15 mt-3">Add new, manage labels</p>
      </div>
      <div style={{ marginTop: 55 }}>
        <div className="flex justify-between">
          <h4 className="font-semibold text-lg ml-3">Labels</h4>
          <PrimaryWithLogo
            label="Add label"
            className="btn--xs"
            onClick={() => setAddLabel(true)}
            renderSvg={() => {
              return <PlusIcon />;
            }}
            style={{ fontWeight: 600 }}
          />
        </div>
        <div className="mt-4" ref={labelNode}>
          {isEmpty(allLabels) && (
            <div className="pt-3 text-center">
              <div className="flex justify-center mb-5">
                <figure
                  className="bg-grey-300 flex justify-center items-center"
                  style={{ width: 71, height: 71, borderRadius: '50%' }}
                >
                  <EmptyLabelIcon />
                </figure>
              </div>
              <span className="font-medium">You currently have no labels</span>
              <p className="text-15 text-grey-800 mt-3">
                Keep your enquiries organised and easy to find by creating your
                first label .{' '}
              </p>
              <p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  className="text-primary font-semibold cursor-pointer text-13"
                  href="https://help.enquirybox.io/en/articles/7228605-organise-enquiries-with-labels"
                >
                  Learn more about labels
                </a>
              </p>
            </div>
          )}
          {!isEmpty(allLabels) &&
            allLabels.map((item) => {
              const { label_name, id } = item;
              return (
                <LabelItem
                  id={id}
                  key={id}
                  title={label_name}
                  setShowMoreDropdown={setShowMoreDropdown}
                  setOpenEdit={setOpenEdit}
                  setOpenDeleteModal={setOpenDeleteModal}
                  showMoreDropdown={showMoreDropdown}
                  openEdit={openEdit}
                />
              );
            })}
        </div>
      </div>
    </>
  );
};

export default LabelSettings;

/* eslint-disable no-unused-vars */
import React from 'react';
import CustomToolTip from 'components/CustomTooltip';
import enquiryOutline from 'assets/icons/icon-assign-enquiries-outline.svg';
import ConnectChannelState from 'modules/enquirybox/components/reportsConditionalState/ConnectChannelState';
import { isEmpty } from 'utils/utils';
import iconFacebook from 'assets/icons/icon-facebook-channel.svg';
import iconCircleCheck from 'assets/icons/icon-circle-check.svg';
import iconWhatsapp from 'assets/icons/icon-circle-whatsapp-channel.svg';
import iconInstagram from 'assets/icons/icon-channels-instagram.svg';
import iconWebform from 'assets/icons/icon-webform-channel-blue.svg';
import iconFacebookLeadAd from 'assets/icons/icon-fb-leads-channel.svg';
import iconZapier from 'assets/icons/icon-zapier-channel.svg';
import CHOICES from 'choices';
import iconSms from 'assets/icons/icon-sms-msg.svg';
import iconManualEnquiry from 'assets/icons/icon-manual-enquiry.png';
import zapierIcon from 'assets/icons/icon-channel-zapier.svg';

const {
  FACEBOOK,
  INSTAGRAM,
  FACEBOOK_LEADGEN,
  WEB_FORMS,
  SMS,
  MANUALLY_ADDED
} = CHOICES.LeadChannelChoice;

const { ZAPIER } = CHOICES.ChannelChoices;

const SalesEnquiryPerChannel = (props) => {
  const { data, isPublic = false, hideCount } = props;

  const renderIcon = (type) => {
    switch (type) {
      case FACEBOOK:
        return iconFacebook;
      case INSTAGRAM:
        return iconInstagram;
      case FACEBOOK_LEADGEN:
        return iconFacebookLeadAd;
      case WEB_FORMS:
        return iconWebform;
      case SMS:
        return iconSms;
      case MANUALLY_ADDED:
        return iconManualEnquiry;
      case ZAPIER:
        return zapierIcon;
      default:
        return iconWebform;
    }
  };

  return (
    <div className={`card-box px-5 py-4`}>
      <div className="flex items-center pr-4">
        <figure
          className="avatar-container avatar-container--contain bg-grey-300"
          style={{
            width: 38,
            height: 38,
            boxShadow: 'inset 0px 0px 0px 1px rgba(0, 0, 0, 0.05)'
          }}
        >
          <img src={enquiryOutline} width={17} height={17} />
        </figure>
        <CustomToolTip
          tooltipStyle={{
            width: 222,
            top: -100,
            bottom: 'initial',
            left: -58,
            transform: 'none',
            textAlign: 'left'
          }}
          position="top"
          tooltipText="The total number of enquiries received in this period broken down, channel by channel."
        >
          <p className="ml-3 font-semibold letterspacing24em">
            Total sales per channel
          </p>
        </CustomToolTip>
      </div>
      <div className="relative">
        <table className="table-reports">
          <thead
            style={{ display: 'table', width: '100%', tableLayout: 'fixed' }}
          >
            <tr>
              <td>Channels</td>
              {/* <td className="">Total</td> */}
              <td className="">Sales</td>
              <td className="">Total sales</td>
            </tr>
          </thead>
          {isEmpty(data) ? (
            <ConnectChannelState isPublic={isPublic} />
          ) : (
            <tbody className={`${isPublic ? 'card-box-report-sales' : ''}`}>
              {!isEmpty(data) &&
                data.map((item, idx) => {
                  const {
                    channel_name,
                    channel_type,
                    total,
                    converted_to_sale,
                    total_sales,
                    converted_to_sale_percentage
                  } = item;
                  if (converted_to_sale !== 0) {
                    return (
                      <tr
                        key={idx}
                        style={{
                          display: 'table',
                          width: '100%',
                          tableLayout: 'fixed'
                        }}
                      >
                        <td className="flex-1">
                          <div className="flex items-center">
                            <img
                              src={renderIcon(channel_type)}
                              alt="icon-facebook"
                              width={22}
                              height={22}
                            />
                            <p className="text-sm font-semibold letterspacing24em ml-3">
                              {channel_name}
                            </p>
                          </div>
                        </td>
                        {/* <td className="text-sm font-bold">{total}</td> */}
                        <td className="text-sm font-bold">
                          <div>
                            <span>{hideCount ? 0 : converted_to_sale}</span>
                            {/* <span className="text-13 text-grey-600 font-medium ml-3">
                              {converted_to_sale_percentage
                                ? parseFloat(
                                    converted_to_sale_percentage
                                  ).toFixed(2)
                                : 0}
                              %
                            </span> */}
                          </div>
                        </td>
                        <td className="text-sm font-bold">
                          {hideCount ? 0 : total_sales}
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};

export default SalesEnquiryPerChannel;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Modal from 'components/modal';
import searchIcon from 'assets/icons/icon-search-grey.svg';
import CheckBox from 'components/inputs/checkbox';
import InputWithIcon from 'components/inputs/inputWithIcon';
import closeWhite from 'assets/icons/icon-close-white.svg';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'components/loader';
import { getFileLibraryData } from 'slices/fileLibrary/slice';
import { isEmpty } from 'utils/utils';
import { fileIcons, getFileExtension } from 'utils/file';

const FileItem = ({ title, icon, onChange, item, selectedFiles }) => {
  const getCheckedStatus = (id) => {
    if (!isEmpty(selectedFiles)) {
      const isFilesChecked = selectedFiles.some((item) => item.id === id);
      return isFilesChecked;
    } else {
      return false;
    }
  };
  return (
    <li className="select-files-list">
      <div className="flex items-center truncate" style={{ maxWidth: '90%' }}>
        <img src={fileIcons[getFileExtension(icon)]} alt="" />
        <label
          className="text-sm font-medium ml-3 width-fill-available cursor-pointer truncate"
          htmlFor={item.id}
        >
          {title}
        </label>
      </div>
      <CheckBox
        id={item.id}
        className="ml-auto mr-2"
        small
        onChange={(e) => onChange(e, { ...item, isFileLibrary: true })}
        checked={getCheckedStatus(item.id)}
      />
    </li>
  );
};

const FileLibrary = ({ open, onClose, onUpload, filesList }) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [showSearchInput, setShowSearchInput] = useState(false);
  const [inputVal, setInputVal] = useState('');
  const [orderFileData, setOrderFileData] = useState([]);
  const [selectedFiles, setSelecteFiles] = useState(filesList);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { fileData } = useSelector((state) => state.fileLibrary);
  const [totalFileData, setTotalFileData] = useState(fileData?.attachments);
  const isFileLibrary = true;

  useEffect(() => {
    if (!isEmpty(filesList) && !isEmpty(fileData?.attachments)) {
      const isFileExistInFileData = fileData?.attachments.filter((elem) =>
        filesList.find(({ file_url }) => elem.file_url === file_url)
      );
      if (isEmpty(isFileExistInFileData)) {
        setOrderFileData(fileData?.attachments);
      } else {
        const filterSelectedFiles = fileData?.attachments.filter(
          (elem) => !filesList.find(({ id }) => elem.id === id)
        );
        setOrderFileData([...isFileExistInFileData, ...filterSelectedFiles]);
      }
    }
  }, [filesList, fileData]);

  const callback = () => {
    setIsLoading(false);
  };

  const loadAllFiles = (subOrgId) => {
    setIsLoading(true);
    dispatch(getFileLibraryData({ subOrgId: subOrgId, callback }));
  };

  useEffect(() => {
    if (open) {
      loadAllFiles(subOrganizationId);
    }
  }, [open]);

  useEffect(() => {
    setSelecteFiles(filesList);
  }, [filesList]);

  useEffect(() => {
    setTotalFileData(fileData?.attachments);
  }, [fileData]);

  const onChange = (e, file) => {
    if (e.target.checked) {
      setSelecteFiles([...selectedFiles, file]);
    } else {
      const filteredFiles = selectedFiles.filter((item) => item.id !== file.id);
      setSelecteFiles(filteredFiles);
    }
  };

  const onAttach = () => {
    onUpload(isFileLibrary, selectedFiles);
  };

  const handleSearch = (e) => {
    setInputVal(e.target.value);
    if (e.target.value === '') {
      setTotalFileData(fileData?.attachments);
    } else {
      const filteredFiles = !isEmpty(fileData?.attachments)
        ? fileData?.attachments.filter((item) =>
            item.file_name.toLowerCase().includes(e.target.value.toLowerCase())
          )
        : [];
      setTotalFileData(filteredFiles);
    }
  };

  const onSearchSelect = (file) => {
    const isSelectedfileExist = selectedFiles.filter(
      (item) => item.id === file.id
    );
    if (isEmpty(isSelectedfileExist)) {
      setSelecteFiles([...selectedFiles, file]);
    } else {
      setSelecteFiles(selectedFiles);
    }
    setInputVal('');
    setShowSearchInput(false);
  };

  return (
    <Modal.Main open={open}>
      <Modal.Header
        title="File library"
        onClose={() => {
          setInputVal('');
          setShowSearchInput(false);
          onClose();
          setSelecteFiles(filesList);
        }}
      />
      <Modal.Body>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {!showSearchInput && (
              <>
                <div className="flex justify-between">
                  <div className="font-semibold">Select files</div>
                  <div onClick={() => setShowSearchInput(true)}>
                    <img
                      src={searchIcon}
                      alt=""
                      className="cursor-pointer mr-1"
                    />
                  </div>
                </div>
                <div
                  className="mt-15 overflow-y-auto"
                  style={{ maxHeight: 375, height: 375 }}
                >
                  {!isEmpty(fileData) &&
                    isEmpty(orderFileData) &&
                    fileData?.attachments.map((item) => {
                      return (
                        <FileItem
                          title={item.file_name}
                          icon={item.file_url}
                          key={item.file_url}
                          onChange={onChange}
                          item={item}
                          selectedFiles={selectedFiles}
                        />
                      );
                    })}
                  {!isEmpty(orderFileData) &&
                    orderFileData.map((item) => {
                      return (
                        <FileItem
                          title={item.file_name}
                          icon={item.file_url}
                          key={item.file_url}
                          onChange={onChange}
                          item={item}
                          selectedFiles={selectedFiles}
                        />
                      );
                    })}
                </div>
                <div className="flex items-center justify-end gap-3 mt-5">
                  <button
                    className="btn btn--secondary btn--sm w-1/4"
                    onClick={() => {
                      setSelecteFiles(filesList);
                      onClose();
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn--primary btn--sm w-1/4"
                    onClick={onAttach}
                    disabled={isEmpty(selectedFiles) && isEmpty(filesList)}
                  >
                    Attach
                  </button>
                </div>
              </>
            )}
            {showSearchInput && (
              <>
                <div className="relative" style={{ height: '480px' }}>
                  <div className="input-field--prepend">
                    <InputWithIcon
                      icon={searchIcon}
                      iconClassName="mr-3 ml-2"
                      placeholder="Search your file"
                      medium
                      onChange={handleSearch}
                      value={inputVal}
                    />
                    <div
                      className="modal__close-icon"
                      onClick={() => setInputVal('')}
                    >
                      <img
                        className="close-icon ml-3"
                        src={closeWhite}
                        alt=""
                      />
                    </div>
                  </div>
                  {inputVal.length >= 1 && (
                    <div
                      className={`dropdown-menu dropdown-menu--sm ${
                        !isEmpty(inputVal) ? 'open' : ''
                      }`}
                      style={{ top: 48, width: '96%' }}
                    >
                      {!isEmpty(totalFileData) &&
                        totalFileData.map((item, idx) => {
                          return (
                            <div
                              key={idx}
                              className="dropdown-menu__list"
                              onClick={() => onSearchSelect(item)}
                            >
                              <img
                                src={fileIcons[getFileExtension(item.file_url)]}
                                alt="file"
                              />
                              <p className="text-sm font-medium ml-3">
                                {item.file_name}
                              </p>
                            </div>
                          );
                        })}
                      {isEmpty(totalFileData) && (
                        <div className="text-sm font-semibold py-2 flex justify-center">
                          File not available
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className="flex items-center justify-end gap-3"
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      width: '100%'
                    }}
                  >
                    <button
                      className="btn btn--secondary btn--sm w-1/4"
                      onClick={() => {
                        setInputVal('');
                        setShowSearchInput(false);
                      }}
                    >
                      Cancel
                    </button>
                    <button
                      className="btn btn--primary btn--sm w-1/4"
                      onClick={onAttach}
                      disabled={isEmpty(selectedFiles) && isEmpty(filesList)}
                    >
                      Attach
                    </button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Modal.Body>
    </Modal.Main>
  );
};

export default FileLibrary;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useRef, useEffect } from 'react';
import Quill from 'components/quill';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CaretUp } from 'assets/icons/icon-select-caret-up.svg';
import { ReactComponent as CaretBlackIcon } from 'assets/icons/icon-select-caret-black.svg';
import InlineEmail from 'modules/enquirybox/ebox/module/box/conversation/footer/replyEmail/inlineEmail';
import useClickOutside from 'hooks/useOutsideClick';
import { isEmpty } from 'utils/utils';
import Error from 'components/errorMessage';
import { setThen, setAutomationErrors } from 'slices/boxAutomation/slice';
import { omit } from 'lodash';
import { handleChangeSignature } from 'slices/conversationSlice';

const sanatizeFromUserData = (data, defaultValue) => {
  if (!isEmpty(data)) {
    return {
      user_id: data.user_id,
      email: data.email
    };
  }
  return defaultValue;
};

const ReplyEmail = ({ className, condition, index }) => {
  const dispatch = useDispatch();
  const { then, automationErrors } = useSelector(
    (state) => state.boxAutomation
  );
  const { id: currentUser, reply_sending_mail_address: userEmail } =
    useSelector((state) => state.myInfo.myInformation);
  const { emailData } = useSelector((state) => state.automationStuff);
  const errors = automationErrors[index];
  const {
    data: {
      send_from,
      cc,
      bcc,
      message,
      subject: email_subject,
      attachments: boxAttachments
    }
  } = condition;
  const [attachments, setAttachments] = useState(boxAttachments);
  const [showFromUsers, setShowFromUsers] = useState(false);
  const [showCcInput, setShowCcInput] = useState(!isEmpty(cc));
  const [showBccInput, setShowBccInput] = useState(!isEmpty(bcc));
  const [ccEmail, setCcEmail] = useState(cc);
  const [bccEmail, setBccEmail] = useState(bcc);
  const [emailBody, setEmailBody] = useState(message);
  const [subject, setSubject] = useState(email_subject);
  const [signature, setSignature] = useState('');
  const [selectedFromEmail, setSelectedFromEmail] = useState(
    sanatizeFromUserData(send_from, {
      user_id: currentUser,
      email: userEmail
    })
  );
  const [editor, setEditorRef] = useState(null);
  const userEmailRef = useRef();
  const sectionTopRef = useRef();
  const emailBodyRef = useRef();
  const footerBodyRef = useRef();
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { error } = useSelector((state) => state.automationStuff);

  useClickOutside(() => setShowFromUsers(false), userEmailRef?.current);

  useEffect(() => {
    if (editor) {
      if (condition?.data?.type == 'new') {
        dispatch(
          handleChangeSignature(
            currentUser,
            editor,
            (data, selectedSignature) => {
              setEmailBody(data);
              setSignature(selectedSignature);
            }
          )
        );
      }
    }
  }, [editor]);

  const setFromEmail = (user) => {
    setSelectedFromEmail(user);
    setShowFromUsers(false);
    setSignature('');
    dispatch(
      handleChangeSignature(user.user_id, editor, (data, selectedSignature) => {
        setEmailBody(data);
        setSignature(selectedSignature);
      })
    );
  };

  const onAttachmentChanges = (attachments) => {
    setAttachments(attachments);
  };

  const updateThen = () => {
    const currentThen = condition;
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      data: {
        ...currentThen.data,
        send_from: selectedFromEmail,
        cc: ccEmail,
        bcc: bccEmail,
        subject: subject,
        message: emailBody,
        signature: signature,
        attachments: attachments
      }
    };
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  useEffect(() => {
    updateThen();
  }, [
    ccEmail,
    bccEmail,
    selectedFromEmail,
    subject,
    emailBody,
    attachments,
    signature
  ]);

  const onSubjectChange = (e) => {
    const { value } = e.target;
    setSubject(value);
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.subject) {
      let result = omit(errorObj[index], ['subject']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
  };

  const onEmailBodyChange = (value) => {
    setEmailBody(value);
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.message) {
      let result = omit(errorObj[index], ['message']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
  };

  const onUseTemplate = (subject, body, attachments) => {
    setSubject(subject);
    // setEmailBody(body + emailBody);
    setEmailBody(
      `${body}<br/><br/>${
        signature
          ? `<div id="user-signature" class="ebox-noneditable" contenteditable="false">${signature}</div>`
          : ''
      }`
    );
    setAttachments(attachments);
  };

  return (
    <div
      className="chat-message-footer__body bg-white"
      ref={footerBodyRef}
      style={{ height: 'calc(100% - 4px)', borderRadius: 10 }}
    >
      <div className="chat-reply" style={{ border: '1px solid white' }}>
        {
          <div className="section-top" ref={sectionTopRef}>
            <div
              className="info-block space"
              style={
                error.fromEmail
                  ? {
                      borderColor: '#ff0000'
                    }
                  : {}
              }
            >
              <div className="flex items-center justify-center">
                <span className="label mr-3">From</span>
                <div className="from-email relative">
                  <section
                    className="flex items-center justify-center cursor-pointer"
                    onClick={() => setShowFromUsers(!showFromUsers)}
                  >
                    {isEmpty(selectedFromEmail) && <span>User Email</span>}
                    {!isEmpty(selectedFromEmail) && (
                      <span>{selectedFromEmail?.email}</span>
                    )}
                    <figure className="ml-2">
                      {showFromUsers ? <CaretUp /> : <CaretBlackIcon />}
                    </figure>
                  </section>
                  <div
                    ref={userEmailRef}
                    className={`dropdown-menu dropdown-menu--sm ${
                      showFromUsers ? 'open' : ''
                    }`}
                    style={{
                      width: 'max-content',
                      top: 'calc(100% + 2px)',
                      left: 0,
                      right: 0
                    }}
                  >
                    {organizationUser.results?.map((user) => {
                      const { user_id, email, reply_sending_mail_address } =
                        user;
                      return (
                        <div
                          key={user_id}
                          className="dropdown-menu__list"
                          onClick={() => {
                            setFromEmail({
                              user_id,
                              email: reply_sending_mail_address
                            });
                          }}
                        >
                          <p className="text-sm">
                            {reply_sending_mail_address}
                          </p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div className="flex">
                <div
                  className="label cc cursor-pointer mr-2"
                  onClick={() => setShowCcInput(!showCcInput)}
                >
                  Cc
                </div>
                <div
                  className="label cc cursor-pointer"
                  onClick={() => setShowBccInput(!showBccInput)}
                >
                  Bcc
                </div>
              </div>
            </div>
            {(showCcInput || !isEmpty(emailData?.cc)) && (
              <InlineEmail
                title="Cc"
                placeholder="example@domain.com"
                list={ccEmail}
                setList={setCcEmail}
              />
            )}
            {(showBccInput || !isEmpty(emailData?.bcc)) && (
              <InlineEmail
                title="Bcc"
                placeholder="example@domain.com"
                list={bccEmail}
                setList={setBccEmail}
              />
            )}
            <div
              className="info-block w-full items-center"
              style={
                error.subject
                  ? {
                      borderColor: '#ff0000'
                    }
                  : {}
              }
            >
              <div className="label mr-3">Subject</div>
              <div className="flex items-center justify-center w-full">
                <input
                  type="text"
                  className="email-text"
                  placeholder="Subject"
                  value={subject}
                  onChange={onSubjectChange}
                />
              </div>
              {errors?.subject && (
                <div className="mt-1 ml-1" style={{ width: 'inherit' }}>
                  <Error
                    className="flex justify-end"
                    message={errors?.subject}
                  />
                </div>
              )}
            </div>
          </div>
        }
        <div
          className={`${
            errors ? 'error-editor' : ''
          } box-settings ${className}`}
          ref={emailBodyRef}
        >
          <Quill
            onChange={(data) => {
              onEmailBodyChange(data);
              // dispatch(setEmailError({ ...error, message: null }));
              // dispatch(setEmailData({ ...emailData, message: data }));
            }}
            value={emailBody}
            setEditorRef={setEditorRef}
            showSendBtn={false}
            // onSend={() => console.log('sds')}
            // isLoading={isLoading}
            placeholder={'Type your message here'}
            isExpanded={true}
            attachments={attachments}
            // hideImage={channelType === FACEBOOK}
            // hideAttachment={channelType === FACEBOOK}
            // setAttachments={(data) => dispatch(setAttachments(data))}
            onAttachmentChanges={onAttachmentChanges}
            hideVarible={false}
            // showFontStyle={channelType !== FACEBOOK}
            // channelType={channelType}
            errorMessage={errors?.message}
            isAutomation
            onUseTemplate={onUseTemplate}
            isRefetch={true}
            isSms={false}
            isFbOrInsta={false}
            hideHyperlink={false}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(ReplyEmail);

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
// import iconCollapse from 'assets/icons/icon-collapse.svg';
// import CustomToolTip from 'components/CustomTooltip';
import AttachmentItem from '../../email/AttachmentItem';
import TextInputField from 'components/inputs/textField';
import TextArea from 'components/inputs/textarea';
import { isEmpty, timeFormatter } from 'utils/utils';
// import iconExpand from 'assets/icons/icon-expand.svg';
import _get from 'lodash/get';

const ZapierContainer = (props) => {
  const { onClick, isFieldsMapped, time, data, isCollapse } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [zapierFieldList, setZapierFieldList] = useState([]);
  const [conversationMessage, setConversationMessage] = useState({});
  const [contentRef, setContentRef] = useState(null);

  const handleHeight = () => {
    const temp = _get(contentRef, 'contentDocument.body');
    if (temp && temp.setAttribute) {
      temp.setAttribute(
        'style',
        `font-family: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif;font-size: 12px;font-weight: 400;line-height: 1.25rem;letter-spacing: -0.0024em;overflow-x: hidden;word-wrap: break-word;`
      );
    }
    const HTML_height = _get(
      contentRef,
      'contentWindow.document.childNodes[0].offsetHeight'
    );
    let height =
      HTML_height ||
      _get(contentRef, 'contentWindow.document.body.scrollHeight');
    height = height > 600 ? height + 100 : height + 20;
    contentRef.style.height = `${height}px`;
  };

  useEffect(() => {
    evaluteData(data);
  }, [data]);

  const evaluteData = (data) => {
    if (data.message && typeof data.message === 'string') {
      let tempData = {};
      let tempList = [];
      try {
        tempData = data.message.replaceAll('*', '');
        tempData = tempData.replaceAll('#', '');
        tempData = JSON.parse(tempData);
      } catch (e) {
        console.log(e);
      }
      Object.keys(tempData).forEach((item) => {
        tempList.push(tempData[item]);
      });
      const finalList = tempList.reduce((acc, obj) => {
        Object.keys(obj).forEach((key) => {
          acc.push({ label: key, [key]: obj[key] });
        });
        return acc;
      }, []);
      setZapierFieldList(finalList);
      setConversationMessage(tempData);
    }
    setIsLoading(false);
  };

  if (isLoading) return <></>;

  return (
    <>
      <div className="" style={{ marginLeft: 6, width: '98%' }}>
        <div
          className="inline-block border border-grey-400 text-base-1 text-grey-900 relative"
          style={{
            borderRadius: 10,
            padding: '22px 22px 25px',
            backgroundColor: '#EEF7FC',
            width: 'calc(100% - 100px)',
            marginRight: 0
          }}
        >
          {/* <CustomToolTip
            position="bottom"
            tooltipText="Collapse"
            style={{ position: 'absolute', right: '7px', top: '6px' }}
          >
            <img
              src={iconCollapse}
              alt="icon-expand"
              className="cursor-pointer"
              onClick={() => setIsCollapsed(true)}
            />
          </CustomToolTip> */}
          <div className="email-body--webform">
            {zapierFieldList.map((list, index) => {
              const { label } = list;
              if (label === 'Message') {
                let tempMessage = conversationMessage?.zap_data[
                  label
                ].replaceAll(/\n/g, '');
                return (
                  <div key={index}>
                    <label className="input-field-label">{label}</label>
                    <iframe
                      title="iframe"
                      onLoad={() => handleHeight()}
                      ref={setContentRef}
                      srcDoc={tempMessage}
                      className="w-full border border-grey-400 font-normal"
                      style={{
                        fontWeight: 400,
                        color: '#292929',
                        borderRadius: 8
                      }}
                    ></iframe>
                    {/* <TextArea
                      id="zapier-message"
                      name={label}
                      value={sanatizeMessageBody(tempMessage)}
                      style={{ fontWeight: 400 }}
                      className="border text-base-1 resize-none pre overflow-hidden"
                      readOnly
                      // rows="3"
                      // cols="5"
                    /> */}
                  </div>
                );
              } else {
                return (
                  <div className="mb-5" key={index}>
                    <label className="input-field-label">{label}</label>
                    <TextInputField
                      className="input-field--md"
                      style={{ fontWeight: 400 }}
                      readOnly
                      value={
                        conversationMessage?.zap_data[label]
                          ? conversationMessage?.zap_data[label]
                          : ''
                      }
                      name={label}
                    />
                  </div>
                );
              }
            })}
            {!isEmpty(data?.attachments) && (
              <div className="email-body" style={{ padding: 0 }}>
                <h3 className=" flex text-13 text-grey-900 font-bold mb-2">
                  Attachments
                </h3>
                <div className="item__attachment">
                  {data?.attachments.map((attachment, idx) => {
                    return (
                      <AttachmentItem
                        key={idx}
                        attachment={attachment}
                        message={true}
                        isZapierMessage={true}
                      />
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        </div>
        <div>
          <div className="text-xs text-grey-700" style={{ marginTop: 6 }}>
            {timeFormatter(time)}
          </div>
        </div>
      </div>
    </>
  );
};

export default ZapierContainer;

// import SideNav from 'modules/dashboard/sideNav';
import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AccountSettings from './home';

const Settings = () => {
  return (
    <React.Fragment>
      {/* <SideNav /> */}
      <Routes>
        <Route path="/*" element={<AccountSettings />} />
      </Routes>
    </React.Fragment>
  );
};

export default Settings;

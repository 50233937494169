import React from 'react';
import PropTypes from 'prop-types';
import CustomColorButton from 'components/buttons/customColorButton';
import Modal from 'components/modal';

const EmptyFieldAlert = (props) => {
  const {
    showConfirmation,
    onClose,
    title,
    description,
    confirmButtonText,
    containerStyle
  } = props;

  return (
    <Modal.Main
      open={showConfirmation}
      className="modal--channels-request"
      containerStyle={containerStyle}
    >
      {/* <Modal.Header title="" onClose={onCancel} /> */}
      <Modal.Body className="pb-10 py-8 overflow-y-auto">
        <React.Fragment>
          <div className="flex items-center justify-center gap-2">
            <h2 className="text-xl font-semibold text-center">{title}</h2>
          </div>
          <div className="text-13 text-center mt-3 px-16 ">{description}</div>
          <div className="flex items-center gap-2 justify-center px-8 mt-6">
            <CustomColorButton
              label={confirmButtonText}
              className="btn--wide-md-40 text-base-1 bg-red-900 text-white mr-3 cursor-pointer relative"
              onClick={onClose}
              enableLoader
            />
          </div>
        </React.Fragment>
      </Modal.Body>
    </Modal.Main>
  );
};

EmptyFieldAlert.propTypes = {
  showConfirmation: PropTypes.bool,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  title: PropTypes.string,
  description: PropTypes.string,
  confirmButtonText: PropTypes.string
};

EmptyFieldAlert.defaultProps = {
  confirmButtonText: 'Close',
  isLoading: false
};

export default EmptyFieldAlert;

import React from 'react';
import iconEmptyState from 'assets/icons/icon-reply-template-empty-state.svg';

const EmptyTemplate = (props) => {
  const { onClick } = props;
  return (
    <div className="h-full">
      <h2 className="font-semibold pt-4 px-4 pb-3 border-b border-grey-400 text-base text-grey-900">
        Reply templates
      </h2>
      <div className="w-full flex" style={{ height: 'calc(100% - 53px)' }}>
        <div className="p-5 w-1/2 border-r border-grey-400">
          <div className="pb-4 border-b border-grey-400 text-sm font-medium text-grey-900">
            Save time by saving replies that you frequently send.
          </div>
          <ul>
            <li className="py-4 border-b border-grey-400 text-sm font-medium flex items-baseline text-grey-900">
              <div className="mr-3">
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3.5" cy="3.5" r="3.5" fill="#4C5254" />
                </svg>
              </div>
              You can create a reply template by saving an existing reply 👉
            </li>
            <li className="pt-4 text-sm font-medium flex items-baseline text-grey-900">
              <div className="mr-3">
                <svg
                  width="7"
                  height="7"
                  viewBox="0 0 7 7"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="3.5" cy="3.5" r="3.5" fill="#4C5254" />
                </svg>
              </div>
              <div>
                Or you can create a template in your settings section.{' '}
                <span className="text-primary cursor-pointer" onClick={onClick}>
                  Go to settings
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div className="px-5 w-1/2">
          <img
            src={iconEmptyState}
            alt=""
            style={{ width: 280, height: 195 }}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyTemplate;

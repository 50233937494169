import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteWebsiteLink,
  getWebsiteLinks
} from 'slices/ai-assistant/websiteLinks/slice';
import AddWebsiteLink from './addWebsiteLink';
import { formatDateTime } from 'utils/dateFormat';
import trashIcon from 'assets/icons/icon-trash-grey-700.svg';
import DeleteConfirmation from 'components/alerts/deleteConfirmation';
import { useSnackbar } from 'components/Snackbar';
import InfoIconComponent from '../../components/InfoIcon';

const WebsiteLinks = () => {
  const dispatch = useDispatch();
  const { assistant_id } = useParams();
  const { websiteLinks } = useSelector((state) => state.websiteLinks);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { openSuccessSnackBar } = useSnackbar();

  useEffect(() => {
    dispatch(getWebsiteLinks(assistant_id));
  }, []);

  const successCb = () => {
    setIsLoading(false);
    setShowDelete(false);
    openSuccessSnackBar('URL Deleted');
  };

  const failedCb = () => {
    setIsLoading(false);
    setShowDelete(false);
    openSuccessSnackBar('Deletion failed');
  };

  const handleDeleteUrl = () => {
    setIsLoading(true);
    dispatch(deleteWebsiteLink(deleteId, successCb, failedCb));
  };

  const handleDeletePopup = (id) => {
    setShowDelete(true);
    setDeleteId(id);
  };

  return (
    <section>
      <AddWebsiteLink />
      {/* <div style={{ padding: '28px 38px 40px' }}>
          <div className="progress-bar-gradient-container">
            <div className="flex items-center justify-between">
              <span className="font-medium">www.mywebsite.com</span>
              <div className="flex items-center">
                <span className="text-lg font-medium cursor-pointer">
                  Cancel
                </span>
                <span
                  className="text-lg font-semibold ml-3"
                  style={{
                    backgroundImage:
                      'linear-gradient(90deg, #5D5FEC 0%, #AE52BD 144.45%)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    color: 'transparent',
                    letterSpacing: '0.006em'
                  }}
                >
                  Learning
                </span>
                <span> &nbsp;&nbsp;✨</span>
              </div>
            </div>
            <div className="text-grey-800 mt-1">1 Page</div>
            <div className="progress-bar-gradient mt-2"></div>
          </div>
        </div> */}
      {websiteLinks.length ? (
        <div style={{ padding: '34px 38px 40px' }}>
          <h3 className="text-lg font-semibold">Learned ✨</h3>
          <p className="text-grey-800 mt-3">Click on a link to view</p>
          <table className="table-basic-info mt-5">
            <thead>
              <tr>
                <td>Website links ({websiteLinks.length}) </td>
                <td
                  style={{ minWidth: '130px' }}
                  className="text-center flex justify-center"
                >
                  <span>Times used</span>
                  <InfoIconComponent
                    tooltipText="Times used"
                    tooltipStyle={{ top: '-6px' }}
                    infoIconStyle={{
                      marginTop: '5.5px',
                      marginLeft: '7px'
                    }}
                  />
                </td>
                <td style={{ minWidth: '120px' }} className="text-center">
                  Last updated
                </td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              {websiteLinks?.map((obj) => (
                <tr key={obj.id}>
                  <td
                    style={{
                      width: '73%',
                      maxWidth: '73%'
                    }}
                  >
                    <a
                      href={obj.website_link}
                      className="link-text "
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {obj.website_link}
                    </a>{' '}
                    {!obj.is_embedded && (
                      <span style={{ color: '#4C5254' }}>( learning... )</span>
                    )}
                  </td>
                  <td className="text-center">{obj.times_used}</td>
                  <td className="text-center">
                    {formatDateTime(obj.last_edited_on, 'dd mmm, yyyy')}
                  </td>
                  <td onClick={() => handleDeletePopup(obj.id)}>
                    <img
                      className="cursor-pointer"
                      src={trashIcon}
                      alt="delete-icon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <></>
      )}
      <DeleteConfirmation
        showConfirmation={showDelete}
        onCancel={() => setShowDelete(false)}
        onConfirm={() => {
          handleDeleteUrl();
        }}
        title="Are you sure you want to delete?"
        description="Once a URL is deleted, it cannot be recovered"
        isLoading={isLoading}
      />
    </section>
  );
};

export default WebsiteLinks;

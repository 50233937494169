import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { ReactComponent as ReconnectErrorIcon } from 'assets/icons/icon-reconnect-error.svg';

const ChannelSettingsLink = (props) => {
  const {
    channelId,
    to,
    icon,
    title,
    subTitle,
    renderSvg,
    isDeletable,
    onDelete,
    showComingSoon,
    isReconnect
  } = props;
  const [isIconVisible, setIsIconVisible] = useState(false);
  const { pathname } = useLocation();
  const selectedChannelId = pathname?.split('/')[5];

  return (
    <div
      className="relative "
      onMouseEnter={() => setIsIconVisible(true)}
      onMouseLeave={() => setIsIconVisible(false)}
    >
      {!showComingSoon && (
        <NavLink
          to={to}
          className={`relative chats-preview chats-preview--new bg-color-on-hover bg-color-on-hover--sm mt-2 ${
            selectedChannelId === channelId ? 'active' : ''
          }`}
        >
          <div className="flex items-center gap-4 flex-1 truncate">
            <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
              <img
                src={icon}
                alt="icon-channel"
                style={{ width: 28, height: 28 }}
              />
            </figure>
            <div className="truncate pr-4 width-fill-available">
              <div
                className={`${
                  isReconnect ? 'flex justify-between items-center' : ''
                }`}
              >
                <h3
                  className="chats-preview__title"
                  style={{ fontWeight: 600 }}
                >
                  {title}
                </h3>
                {isReconnect && <ReconnectErrorIcon />}
              </div>
              <p className="chats-preview__msg" style={{ color: '#4C5254' }}>
                {subTitle}
              </p>
            </div>
          </div>
          {isDeletable && (
            <div
              onClick={() => onDelete(channelId)}
              className={`${
                isIconVisible ? 'visible' : 'invisible'
              } absolute right-0 top-6 z-50 cursor-pointer`}
            >
              {renderSvg && typeof renderSvg === 'function' && renderSvg()}
            </div>
          )}
        </NavLink>
      )}
      {showComingSoon && (
        <div
          className="relative chats-preview chats-preview--new mt-2"
          style={{
            cursor: 'not-allowed'
          }}
        >
          <div className="flex items-center gap-4 flex-1 truncate">
            <figure className="chats-preview__avatar-container bg-grey-300 flex justify-center items-center">
              <img
                src={icon}
                alt="icon-channel"
                style={{ width: 28, height: 28 }}
              />
            </figure>
            <div className="truncate pr-4">
              <h3 className="chats-preview__title" style={{ fontWeight: 600 }}>
                {title}
              </h3>
              <p className="chats-preview__msg" style={{ color: '#4C5254' }}>
                {subTitle}
              </p>
              <div className="label-coming-soon w-[100px] mt-1">
                Coming soon
              </div>
            </div>
          </div>
          {isDeletable && (
            <div
              onClick={() => onDelete(channelId)}
              className={`${
                isIconVisible ? 'visible' : 'invisible'
              } absolute right-0 top-6 z-50 cursor-pointer`}
            >
              {renderSvg && typeof renderSvg === 'function' && renderSvg()}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

ChannelSettingsLink.propTypes = {
  channelId: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  renderSvg: PropTypes.func,
  isDeletable: PropTypes.bool,
  onDelete: PropTypes.func,
  showComingSoon: PropTypes.bool
};

ChannelSettingsLink.defaultProps = {
  subTitle: 'One minute setup',
  isDeletable: false,
  onDelete: () => {},
  showComingSoon: false
};

export default ChannelSettingsLink;

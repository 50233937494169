import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CHOICES from 'choices';
import { useDispatch, useSelector } from 'react-redux';
import _get from 'lodash/get';
import { useSnackbar } from 'components/Snackbar';
import useFacebook from 'hooks/useFacebook';
import { useAnalytics } from 'use-analytics';
import {
  exchangeFaceBookLeadsOauthCode,
  getFbLeadsCreds
} from 'slices/facebook/slice';
import { route } from 'utils/route';
import {
  getAllConnectedChannels,
  getConnectedChannelById
} from 'slices/channelsSlice';
import Reconnect from '../settings/component/reconnect';
import DeleteChannel from 'modules/enquirybox/channels/module/settings/channel/deleteChannel';
import { isEmpty } from 'utils/utils';

const { FACEBOOK_LEAD_ADS } = CHOICES.ChannelChoices;

const FbLeadAdsConnectedSettings = (props) => {
  const { channelType } = props;
  const { channelId } = useParams();
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { connectedChannelById } = useSelector((state) => state.channels);
  const { fbLeadConfigurations } = useSelector((state) => state.fb);
  const appId = _get(fbLeadConfigurations, 'app_id', undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [FB, isReady] = useFacebook(appId);
  const { track } = useAnalytics();

  useEffect(() => {
    dispatch(getFbLeadsCreds());
  }, []);

  const onSuccessLogin = (res) => {
    if (res?.data?.connected_channel_id) {
      setIsLoading(false);
      dispatch(getAllConnectedChannels());
      dispatch(
        getConnectedChannelById({
          subOrgId: subOrganizationId,
          channelId: res?.data?.connected_channel_id
        })
      );
      openSuccessSnackBar('Re connected');
      track(
        'channel_reconnected',
        {
          channel_name: 'Facebook lead ads'
        },
        {
          groupId: subOrganizationId
        }
      );
      navigate(
        `${route.channels}/facebook-leads/${FACEBOOK_LEAD_ADS}/connected/${res.data.connected_channel_id}/automations`
      );
    } else if (
      res?.data?.message.includes('no link, so stay on the same page.')
    ) {
      setIsLoading(false);
      openErrorSnackBar('invalid token');
    }
  };

  const onReconnect = () => {
    if (FB && isReady && fbLeadConfigurations && fbLeadConfigurations.scope) {
      setIsLoading(true);
      try {
        FB.login(
          (resp) => {
            const signedRequest = _get(
              resp,
              'authResponse.signedRequest',
              false
            );
            if (signedRequest)
              dispatch(
                exchangeFaceBookLeadsOauthCode(
                  {
                    channelId: channelId,
                    data: {
                      signed_request: signedRequest
                    }
                  },
                  onSuccessLogin
                )
              );
            else {
              setIsLoading(false);
            }
          },
          {
            scope: fbLeadConfigurations.scope
          }
        );
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    }
  };

  useEffect(() => {
    dispatch(
      getConnectedChannelById({
        subOrgId: subOrganizationId,
        channelId: channelId
      })
    );
  }, [channelId]);

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/channels');
  };

  return (
    <div className="w-full">
      {/* <ConnectedChannelTab activeTab={1} channelType={channelType} /> */}
      <div className="py-6 px-8" style={{ maxWidth: '70%' }}>
        {!isEmpty(connectedChannelById) &&
          connectedChannelById?.has_error_in_connection && (
            <Reconnect
              channelType={channelType}
              description={connectedChannelById?.error_description}
              onReconnect={onReconnect}
              isLoading={isLoading}
            />
          )}
        <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
      </div>
    </div>
  );
};

export default FbLeadAdsConnectedSettings;

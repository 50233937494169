/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  GET_LEADS,
  LEAD_UPDATE_STATUS,
  GET_TOTAL_LEADS,
  GET_LIST_ENQUIRIES_WITH_TASKS
} from 'urls';
import { MOVE_TO_BOX } from 'urls/boxes';
import { replaceUrl } from 'utils/urlReplace';
import _ from 'lodash';
import { isEmpty } from 'utils/utils';
import APIRequest from 'apiRequest';
import { REPLY, WHOEVER__IS_AVAILABLE } from 'utils/constants';
import { getAllCounts } from 'slices/allCount/slice';
import { getFreeEnquiriesCount } from './subscription/slice';
import choices from 'choices';

const { FREE_TRIAL } = choices.PlanType;
import { leadConversation, setActiveLead } from './conversationSlice';

const {
  REPLY_LATER,
  NEW,
  VIEWED,
  RESTORED,
  REPLY_SENT,
  TASK_ADDED,
  NOTE_ADDED
} = choices.LeadStatusChoices;

const REPLYLATER = REPLY_LATER;
const SEEN = VIEWED;
const UNSEEN = NEW;

export const eboxLeads = createSlice({
  name: 'leads',
  initialState: {
    currentCardList: 0,
    activeNewLead: null, // only active when the lead is clicked in the new section
    previousActiveNewLead: null, // will be set post the active new lead along with data
    previousClickedLead: null, // this is used to check the data of lead that was previously clicked
    previousClickedLeadList: 0,
    unReadLeads: [],
    readLeads: [],
    replyLaterLeads: [],
    totalLeadCount: 0,
    isReplyLater: false,
    isMovedSuccess: false,
    showInboxActions: true,
    showContactModal: false,
    editorActiveTab: REPLY,
    leadTasks: []
  },
  reducers: {
    setCurrentCardList: (state, action) => {
      state.currentCardList = action.payload;
    },
    setActiveNewLead: (state, action) => {
      state.activeNewLead = action.payload;
    },
    setPreviousActiveNewLead: (state, action) => {
      state.previousActiveNewLead = action.payload;
    },
    setPreviousClickedLead: (state, action) => {
      state.previousClickedLead = action.payload;
    },
    setPreviousClickedLeadList: (state, action) => {
      state.previousClickedLeadList = action.payload;
    },
    setUnReadLeads: (state, action) => {
      state.unReadLeads = action.payload;
    },
    setReadLeads: (state, action) => {
      state.readLeads = action.payload;
    },
    setReplyLaterLeads: (state, action) => {
      state.replyLaterLeads = action.payload;
    },
    setIsReplyLater: (state, action) => {
      state.isReplyLater = action.payload;
    },
    setIsMovedSuccess: (state, action) => {
      state.isMovedSuccess = action.payload;
    },
    setShowInboxActions: (state, action) => {
      state.showInboxActions = action.payload;
    },
    setEditorActiveTab: (state, action) => {
      state.editorActiveTab = action.payload;
    },
    setShowContactModal: (state, action) => {
      state.showContactModal = action.payload;
    },
    setTotalLeadCount: (state, action) => {
      state.totalLeadCount = action.payload;
    },
    setLeadTasks: (state, action) => {
      state.leadTasks = action.payload;
    }
  }
});

export const {
  setUnReadLeads,
  setReadLeads,
  setReplyLaterLeads,
  setIsReplyLater,
  setIsMovedSuccess,
  setActiveNewLead,
  setPreviousActiveNewLead,
  setPreviousClickedLead,
  setPreviousClickedLeadList,
  setShowInboxActions,
  setIsFooterExpanded,
  setEditorActiveTab,
  setShowContactModal,
  setTotalLeadCount,
  setCurrentCardList,
  setLeadTasks
} = eboxLeads.actions;

export const getTotalOrganisationLeads = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(GET_TOTAL_LEADS, {
          sub_organization_id: subOrgId
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res?.data?.lead_count));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReadLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      filter_by: 2,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setReadLeads(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getUnReadLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const { plan } = getState().subscription.currentPlan;
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      filter_by: 1,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setTotalLeadCount(res.data?.results.length));
            dispatch(getAllCounts());
            if (plan.plan_type == FREE_TRIAL) {
              dispatch(getFreeEnquiriesCount());
            }
            dispatch(setUnReadLeads(res.data));
            dispatch(setActiveNewLead(null));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getReplyLaterLeads = (payload = {}, successCb) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const channel_id = getState().leadsFilter.channelId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      filter_by: 3,
      channel: channel_id,
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LEADS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setReplyLaterLeads(res.data));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const onCardMoveUpdateStatus = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const shiftTheLeadPostClick = () => {
  return async (dispatch, getState) => {
    const {
      unReadLeads,
      readLeads,
      replyLaterLeads,
      previousClickedLead,
      previousClickedLeadList
    } = getState().leads;
    const { assignedTo } = getState().leadsFilter;
    if (
      previousClickedLead.status === REPLY_SENT ||
      previousClickedLead.status === TASK_ADDED ||
      previousClickedLead.status === NOTE_ADDED
    ) {
      if (previousClickedLeadList === NEW) {
        const filteredLeads = unReadLeads.results.filter(
          (lead) => lead.id !== previousClickedLead.id
        );
        const updatedData = {
          ...unReadLeads,
          results: filteredLeads,
          count: filteredLeads.length
        };
        dispatch(setUnReadLeads(updatedData));
      }
      if (previousClickedLeadList === VIEWED) {
        const filteredLeads = readLeads.results.filter(
          (lead) => lead.id !== previousClickedLead.id
        );
        const updatedData = {
          ...readLeads,
          results: filteredLeads,
          count: filteredLeads.length
        };
        dispatch(setReadLeads(updatedData));
      }
      if (previousClickedLeadList === REPLY_LATER) {
        const filteredLeads = replyLaterLeads.results.filter(
          (lead) => lead.id !== previousClickedLead.id
        );
        const updatedData = {
          ...replyLaterLeads,
          results: filteredLeads,
          count: filteredLeads.length
        };
        dispatch(setReplyLaterLeads(updatedData));
      }
    }
    if (
      previousClickedLead.status === VIEWED &&
      previousClickedLeadList === NEW
    ) {
      const filteredLeads = unReadLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...unReadLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setUnReadLeads(updatedData));
      // id assigned to filter is applied
      const setLead = () => {
        const leads = [previousClickedLead, ...readLeads.results];
        const updatedLeadData = {
          ...readLeads,
          results: leads,
          count: leads.length
        };
        dispatch(setReadLeads(updatedLeadData));
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
    }
    // when card status is updated to new and the lead is in seen section
    if (
      previousClickedLead.status === NEW &&
      previousClickedLeadList === VIEWED
    ) {
      const filteredLeads = readLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...readLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setReadLeads(updatedData));
      const setLead = () => {
        const leads = [previousClickedLead, ...unReadLeads.results];
        const updatedLeadData = {
          ...unReadLeads,
          results: leads,
          count: leads.length
        };
        dispatch(setUnReadLeads(updatedLeadData));
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
    }
    // when card status is updated to new and the lead is in reply later section
    if (
      previousClickedLead.status === NEW &&
      previousClickedLeadList === REPLYLATER
    ) {
      const filteredLeads = replyLaterLeads.results.filter(
        (lead) => lead.id !== previousClickedLead.id
      );
      const updatedData = {
        ...replyLaterLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setReplyLaterLeads(updatedData));
      const setLead = () => {
        const leads = [previousClickedLead, ...unReadLeads.results];
        const updatedLeadData = {
          ...unReadLeads,
          results: leads,
          count: leads.length
        };
        dispatch(setUnReadLeads(updatedLeadData));
      };
      if (!assignedTo) {
        setLead();
      } else if (
        assignedTo?.user_id == null &&
        previousClickedLead.assigned_to == null
      ) {
        setLead();
      } else if (assignedTo?.user_id) {
        if (assignedTo?.user_id == previousClickedLead.assigned_to?.id) {
          setLead();
        } else if (previousClickedLead.assigned_to == null) {
          setLead();
        }
      }
    }
    if (previousClickedLead.status === NEW && previousClickedLeadList === NEW) {
      // previousClickedLead
      const leads = unReadLeads.results.map((item) => {
        if (item.id === previousClickedLead.id) {
          return previousClickedLead;
        }
        return item;
      });
      const updatedLeadData = {
        ...unReadLeads,
        results: leads,
        count: leads.length
      };
      dispatch(setUnReadLeads(updatedLeadData));
    }
  };
};

// shift the lead on reply later or status change
export const shiftTheLead = (payload) => {
  return async (dispatch, getState) => {
    const { data } = payload;
    const { currentCardList, unReadLeads, readLeads, replyLaterLeads } =
      getState().leads;
    if (currentCardList === VIEWED) {
      const filteredLeads = readLeads.results.filter(
        (lead) => lead.id !== data.id
      );
      const updatedData = {
        ...readLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setReadLeads(updatedData));
    }
    if (currentCardList === NEW) {
      const filteredLeads = unReadLeads.results.filter(
        (lead) => lead.id !== data.id
      );
      const updatedData = {
        ...unReadLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setUnReadLeads(updatedData));
    }
    if (currentCardList === REPLY_LATER) {
      const filteredLeads = replyLaterLeads.results.filter(
        (lead) => lead.id !== data.id
      );
      const updatedData = {
        ...replyLaterLeads,
        results: filteredLeads,
        count: filteredLeads.length
      };
      dispatch(setReplyLaterLeads(updatedData));
    }
    if (data.status === REPLY_LATER) {
      const leads = [data, ...replyLaterLeads.results];
      const updatedData = {
        ...replyLaterLeads,
        results: leads,
        count: leads.length
      };
      dispatch(setReplyLaterLeads(updatedData));
    }
  };
};

export const changeLeadStatus = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              shiftTheLead({
                data: res.data
              })
            );
            dispatch(setActiveLead(res.data));
            // dispatch(
            //   getReplyLaterLeads({
            //     subOrgId: getState().myInfo.subOrganizationId
            //   })
            // );
            // dispatch(
            //   getUnReadLeads({ subOrgId: getState().myInfo.subOrganizationId })
            // );
            // dispatch(
            //   getReadLeads({ subOrgId: getState().myInfo.subOrganizationId })
            // );
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// when clicked on new leads change its status to viewed
export const updateUnreadLeadStatus = (payload, type, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body } = payload;
      const { activeLead } = getState().leadConversation;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            updateUnreadLeads(res.data, getState, dispatch);
            if (successCb) {
              successCb(res.data, activeLead);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const onNewCardPostClick = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id } = payload;
      const {
        leads: { unReadLeads, readLeads, totalLeadCount, activeNewLead },
        myInfo
      } = getState();
      if (!isEmpty(activeNewLead)) {
        const previousActiveLead = unReadLeads?.results.find(
          (lead) => lead.id === activeNewLead
        );
        const unReadLeadList = unReadLeads?.results.filter(
          (lead) => activeNewLead !== lead.id
        );
        const updatedData = {
          ...unReadLeads,
          results: unReadLeadList
        };
        dispatch(setTotalLeadCount(totalLeadCount - 1));
        if (previousActiveLead) {
          dispatch(
            setReadLeads({
              ...readLeads,
              results: [previousActiveLead, ...readLeads.results]
            })
          );
          dispatch(setUnReadLeads(updatedData));
        }
        dispatch(setActiveNewLead(id));
      } else {
        dispatch(setActiveNewLead(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// when clicked on new card and then clicked on another card then
// move the previous viewed card to the viewed section
export const onUnreadLeadFocused = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id } = payload;
      const {
        leads: { unReadLeads, totalLeadCount },
        myInfo
      } = getState();
      dispatch(getUnReadLeads({ subOrgId: myInfo.subOrganizationId }));
      dispatch(getReadLeads({ subOrgId: myInfo.subOrganizationId }));
      // dispatch(getReplyLaterLeads({ subOrgId: myInfo.subOrganizationId }));
    } catch (e) {
      console.log(e);
    }
  };
};

const updateUnreadLeads = (data, getState, dispatch) => {
  const {
    leads: { unReadLeads }
  } = getState();
  const Leads = _.cloneDeep(unReadLeads);
  // Find item index using _.findIndex
  const results = Leads.results;
  if (results) {
    let index = _.findIndex(results, { id: data.id });
    // Replace item at index using native splice
    results.splice(index, 1, data);
    const updatedData = {
      ...Leads,
      results
    };
    dispatch(setUnReadLeads(updatedData));
  }
};

const getLead = (draggableId, src, state) => {
  const { replyLaterLeads, readLeads, unReadLeads } = state;
  if (parseInt(src.droppableId) === REPLYLATER) {
    return replyLaterLeads.results.find((lead) => lead.id === draggableId);
  }
  if (parseInt(src.droppableId) === UNSEEN) {
    return unReadLeads.results.find((lead) => lead.id === draggableId);
  }
  if (parseInt(src.droppableId) === SEEN) {
    return readLeads.results.find((lead) => lead.id === draggableId);
  }
  return null;
};

const getClenedList = (draggableId, src, state, dispatch) => {
  const { replyLaterLeads, readLeads, unReadLeads } = state;
  if (parseInt(src.droppableId) === REPLYLATER) {
    const filteredLeads = replyLaterLeads.results.filter(
      (lead) => lead.id !== draggableId
    );
    const updatedData = {
      ...replyLaterLeads,
      results: filteredLeads,
      count: filteredLeads.length
    };
    dispatch(setReplyLaterLeads(updatedData));
  }
  if (parseInt(src.droppableId) === UNSEEN) {
    const filteredLeads = unReadLeads.results.filter(
      (lead) => lead.id !== draggableId
    );
    const updatedData = {
      ...unReadLeads,
      results: filteredLeads,
      count: filteredLeads.length
    };
    dispatch(setUnReadLeads(updatedData));
  }
  if (parseInt(src.droppableId) === SEEN) {
    const filteredLeads = readLeads.results.filter(
      (lead) => lead.id !== draggableId
    );
    const updatedData = {
      ...readLeads,
      results: filteredLeads,
      count: filteredLeads.length
    };
    dispatch(setReadLeads(updatedData));
  }
};

export const onMoveSuccess = (payload, callback) => {
  return async (dispatch, getState) => {
    const { draggableId, source, destination } = payload;
    const {
      leads: { replyLaterLeads, readLeads, unReadLeads }
    } = getState();
    const lead = getLead(draggableId, source, {
      replyLaterLeads,
      readLeads,
      unReadLeads
    });

    if (lead) {
      if (parseInt(destination.droppableId) === REPLYLATER) {
        const Leads = _.cloneDeep(replyLaterLeads);
        Leads.results.unshift(lead);
        const updatedData = {
          ...Leads,
          count: Leads.results.length
        };
        dispatch(setReplyLaterLeads(updatedData));
        getClenedList(
          draggableId,
          source,
          { replyLaterLeads, readLeads, unReadLeads },
          dispatch
        );
      }
      if (parseInt(destination.droppableId) === SEEN) {
        const Leads = _.cloneDeep(readLeads);
        Leads.results.unshift(lead);
        const updatedData = {
          ...Leads,
          count: Leads.results.length
        };
        dispatch(setReadLeads(updatedData));
        getClenedList(
          draggableId,
          source,
          { replyLaterLeads, readLeads, unReadLeads },
          dispatch
        );
      }
      if (parseInt(destination.droppableId) === UNSEEN) {
        const Leads = _.cloneDeep(unReadLeads);
        Leads.results.unshift(lead);
        const updatedData = {
          ...Leads,
          count: Leads.results.length
        };
        dispatch(setUnReadLeads(updatedData));
        getClenedList(
          draggableId,
          source,
          { replyLaterLeads, readLeads, unReadLeads },
          dispatch
        );
      }
      if (callback) {
        callback();
      }
    }
  };
};

export const updateListOnCardMove = (formData) => {
  return async (dispatch, getState) => {
    const {
      leads: { replyLaterLeads, readLeads, unReadLeads, currentCardList },
      leadConversation: { activeLead }
    } = getState();
    try {
      let data_to_be_updated = {
        box: formData.box_id,
        box_name: formData.box_name,
        box_stage: formData.box_stage,
        box_stage_name: formData.box_stage_name,
        status: 1
      };
      if (formData.box_id === activeLead.box) {
        data_to_be_updated = {
          ...data_to_be_updated,
          status: activeLead.status
        };
      }
      if (formData.box_id === activeLead.box) {
        dispatch(
          setPreviousActiveNewLead({ ...activeLead, status: activeLead.status })
        );
      } else {
        dispatch(
          setPreviousActiveNewLead({ ...activeLead, status: activeLead.status })
        );
      }
      if (currentCardList === NEW || currentCardList === RESTORED) {
        // const updatedNewLeadsList = unReadLeads?.results?.filter(
        //   (lead) => lead.id !== formData.lead
        // );
        // update lead data in local in things to do when card is moved
        let updatedNewLeadsList = [];
        updatedNewLeadsList = unReadLeads?.results?.filter(
          (lead) => lead.id !== formData.lead
        );
        // 791 - 806 commented because the move to box is not changes status to new
        // if (formData.is_manual_card) {
        //   updatedNewLeadsList = unReadLeads?.results?.filter(
        //     (lead) => lead.id !== formData.lead
        //   );
        // } else {
        //   updatedNewLeadsList = unReadLeads?.results?.map((lead) => {
        //     if (lead.id === formData.lead) {
        //       return {
        //         ...lead,
        //         ...data_to_be_updated
        //       };
        //     }
        //     return lead;
        //   });
        // }
        if (updatedNewLeadsList) {
          dispatch(
            setUnReadLeads({
              ...unReadLeads,
              count: unReadLeads.count - 1,
              results: updatedNewLeadsList
            })
          );
        }
      }
      if (currentCardList === REPLYLATER) {
        // const updatedLaterLeadsList = replyLaterLeads?.results?.filter(
        //   (lead) => lead.id !== formData.lead
        // );
        // const updatedNewLeadsList = replyLaterLeads?.results?.map((lead) => {
        //   if (lead.id === formData.lead) {
        //     return {
        //       ...lead,
        //       ...data_to_be_updated
        //     }
        //   } return lead
        // })
        let updatedLaterLeadsList = null;
        updatedLaterLeadsList = replyLaterLeads?.results?.filter(
          (lead) => lead.id !== formData.lead
        );
        // 833 - 849 commented because the move to box does not changes status to new
        // if (formData.box_id === activeLead.box) {
        //   // if it is moved to same box but in different stage
        //   updatedLaterLeadsList = replyLaterLeads?.results?.map((lead) => {
        //     if (lead.id === formData.lead) {
        //       return {
        //         ...lead,
        //         ...data_to_be_updated
        //       };
        //     }
        //     return lead;
        //   });
        // } else {
        //   updatedLaterLeadsList = replyLaterLeads?.results?.filter(
        //     (lead) => lead.id !== formData.lead
        //   );
        // }
        if (updatedLaterLeadsList) {
          dispatch(
            setReplyLaterLeads({
              ...replyLaterLeads,
              count:
                formData.box_id === activeLead.box
                  ? replyLaterLeads.count
                  : replyLaterLeads.count - 1,
              results: updatedLaterLeadsList
            })
          );
          // 861 - 873 commented because the move to box does not changes status to new
          // if (formData.box_id !== activeLead.box && !formData.is_manual_card) {
          //   dispatch(
          //     setUnReadLeads({
          //       ...unReadLeads,
          //       count: unReadLeads.count + 1,
          //       results: [
          //         { ...activeLead, ...data_to_be_updated },
          //         ...unReadLeads.results
          //       ]
          //     })
          //   );
          // }
        }
      }
      if (currentCardList === VIEWED) {
        let updatedViewedLeadsList = null;
        updatedViewedLeadsList = readLeads?.results?.filter(
          (lead) => lead.id !== formData.lead
        );
        // 881 - 906 commented because the move to box does not changes status to new
        // if (formData.box_id === activeLead.box) {
        //   // if it is moved to same box but in different stage
        //   updatedViewedLeadsList = readLeads?.results?.map((lead) => {
        //     if (lead.id === formData.lead) {
        //       return {
        //         ...lead,
        //         ...data_to_be_updated
        //       };
        //     }
        //     return lead;
        //   });
        // } else {
        //   updatedViewedLeadsList = readLeads?.results?.filter(
        //     (lead) => lead.id !== formData.lead
        //   );
        // }

        // const updatedNewLeadsList = readLeads?.results?.map((lead) => {
        //   if (lead.id === formData.lead) {
        //     return {
        //       ...lead,
        //       ...data_to_be_updated
        //     }
        //   } return lead
        // })
        if (updatedViewedLeadsList) {
          dispatch(
            setReadLeads({
              ...readLeads,
              count:
                formData.box_id === activeLead.box
                  ? readLeads.count
                  : readLeads.count - 1,
              results: updatedViewedLeadsList
            })
          );
          // 918 - 930 commented because the move to box does not changes status to new
          // if (formData.box_id !== activeLead.box && !formData.is_manual_card) {
          //   dispatch(
          //     setUnReadLeads({
          //       ...unReadLeads,
          //       count: unReadLeads.count + 1,
          //       results: [
          //         { ...activeLead, ...data_to_be_updated },
          //         ...unReadLeads.results
          //       ]
          //     })
          //   );
          // }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const onMoveToBox = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const { formData, isBox, box_name, box_stage_name, is_manual_card } =
        payload;
      await new APIRequest().post(MOVE_TO_BOX, formData).then((res) => {
        if (res.status === 200) {
          if (!isBox) {
            dispatch(
              updateListOnCardMove({
                ...formData,
                box_name,
                box_stage_name,
                is_manual_card
              })
            );
            // dispatch(getUnReadLeads({ subOrgId: subOrganizationId }));
            // dispatch(getReadLeads({ subOrgId: subOrganizationId }));
            // dispatch(getReplyLaterLeads({ subOrgId: subOrganizationId }));
          }
          if (successCb) {
            successCb(res.data);
          }
        }
      });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

export const updateLeadInList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const {
        leads: { replyLaterLeads, readLeads, unReadLeads, currentCardList },
        leadConversation: { activeLead }
      } = getState();
      if (currentCardList == REPLYLATER) {
        if (replyLaterLeads.results) {
          const leadIndex = replyLaterLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...replyLaterLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...replyLaterLeads,
            results: leads
          };
          dispatch(setReplyLaterLeads(updatedList));
        }
      }
      if (currentCardList == NEW) {
        if (unReadLeads.results) {
          const leadIndex = unReadLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...unReadLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...unReadLeads,
            results: leads
          };
          dispatch(setUnReadLeads(updatedList));
        }
      }
      if (currentCardList == SEEN) {
        if (readLeads.results) {
          const leadIndex = readLeads.results?.findIndex(
            (lead) => lead.id === activeLead.id
          );
          const leads = [...readLeads.results];
          leads.splice(leadIndex, 1, activeLead);
          const updatedList = {
            ...readLeads,
            results: leads
          };
          dispatch(setReadLeads(updatedList));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

export const getLeadTasks = (payload = {}) => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    const assigned_to = getState().leadsFilter.assignedTo;
    const params = {
      assigned_to:
        assigned_to?.name === WHOEVER__IS_AVAILABLE
          ? 'whoever_is_available'
          : assigned_to?.user_id
    };
    try {
      const { callback, query = {} } = payload;
      await new APIRequest()
        .get(replaceUrl(GET_LIST_ENQUIRIES_WITH_TASKS, 'subOrgId', subOrgId), {
          ...params,
          ...query
        })
        .then((res) => {
          if (res.status === 200) {
            dispatch(setLeadTasks(res.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export default eboxLeads.reducer;

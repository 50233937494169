/* eslint-disable no-unreachable */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
// import iconMoreRound from 'assets/icons/icon-more-round.svg';
// import deleteIcon from 'assets/icons/icon-Delete.svg';
import { isEmpty } from 'utils/utils';
import { Loader } from 'components/loader';
// import { Link } from "react-router-dom";
// import iconCardProfile from "assets/icons/icon-card-image.svg";
// import Lead from '../../lead';
// import ReplyLater from 'modules/boxes/components/replyLater';
// import DragEmpty from '../../lead/dragEmpty'
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { useDispatch, useSelector } from 'react-redux';
import {
  getStageLeads,
  composeStageLeads,
  filterBoxLeads,
  setIsFiltered,
  setIsAppliedFilter,
  setLoadEnquiryInStage
} from 'slices/boxes/slice';
import StageReplyLater from './replyLater';
import StageNewLead from './new';
import StageViewedLead from './viewed';
import { useParams } from 'react-router';
import choices from 'choices';
import StageEmptyState from './emptyState';
import ContractValue from './contractValue';
import StageHeader from './header';
import Confetti from 'components/confetti';

import { checkStageIsEmpty, cardDesign } from '../../helper';
import EmptyChannelConnection from 'modules/boxes/lead/channelConnection';
const { SALES, GENERAL } = choices.BoxType;
// const INCOMING = choices.StageKind.INCOMING;
const { CONVERTED_SALE, INCOMING } = choices.StageKind;
const { VALUE } = choices.QuestionChoices;

const Stage = (props) => {
  const [error, setIsError] = useState(false);
  const dispatch = useDispatch();
  const { box_type } = useParams();
  const {
    stage,
    box,
    dragStart,
    index,
    fetchLeads,
    fixWidth,
    isDetailEnabled,
    showConfetti,
    setShowConfetti
  } = props;
  const {
    stageLeads,
    isFiltered,
    isAppliedFilter,
    usersSelected,
    channelsSelected,
    labelsSelected,
    loadEnquiryInStage
  } = useSelector((state) => state.boxes);
  const stageId = stage.id;
  const { currentBox, activeBoxNewLead } = useSelector((state) => state.boxes);
  const { myInformation, isChannelsConnected } = useSelector(
    (state) => state.myInfo
  );
  // console.log({ stageLeads });
  const boxName = currentBox?.box_name;
  const sales_converted_stage = currentBox?.sales_converted_stage;
  const [isLoading, setIsLoading] = useState(true);
  const card_design = cardDesign(box.card_design);

  const getIfCardHasQuestion = () => {
    if (box_type == SALES) return true;
    if (card_design) {
      const { question1, question2 } = card_design;
      if (question1) {
        if (question1?.kind === VALUE) return true;
      }
      if (question2) {
        if (question2?.kind === VALUE) return true;
      }
    }
    return false;
  };

  const isSalesBox = getIfCardHasQuestion();
  // const showDelete = null;
  // const setShowDelete = () => { };
  const getLeads = () => {
    if (!fetchLeads && loadEnquiryInStage) {
      setIsLoading(true);
      dispatch(getStageLeads({ stageId }))
        .then((res) => {
          dispatch(composeStageLeads({ stageId, results: res.data }));
          setTimeout(() => {
            setIsLoading(false);
          }, 10);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(err);
        });
    }
    if (fetchLeads) {
      setIsLoading(true);
      dispatch(getStageLeads({ stageId }))
        .then((res) => {
          dispatch(composeStageLeads({ stageId, results: res.data }));
          setTimeout(() => {
            setIsLoading(false);
          }, 10);
        })
        .catch((err) => {
          setIsLoading(false);
          setIsError(err);
        });
    } else {
      if (!stageLeads[stageId]) {
        setIsLoading(true);
        dispatch(getStageLeads({ stageId }))
          .then((res) => {
            dispatch(composeStageLeads({ stageId, results: res.data }));
            setTimeout(() => {
              setIsLoading(false);
            }, 10);
          })
          .catch((err) => {
            setIsLoading(false);
            setIsError(err);
          });
      }
    }
    dispatch(setLoadEnquiryInStage(false));
  };

  const getFilteredLeads = () => {
    setIsLoading(true);
    Promise.all([
      dispatch(getStageLeads({ stageId })).then((res) => {
        dispatch(setIsFiltered(false));
        dispatch(composeStageLeads({ stageId, results: res.data }));
      })
    ]).then(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getLeads();
  }, []);

  useEffect(() => {
    if (isFiltered) {
      getFilteredLeads();
    }
  }, [isFiltered]);

  useEffect(() => {
    if (loadEnquiryInStage) {
      getLeads();
    }
  }, [loadEnquiryInStage]);

  useEffect(() => {
    if (!fetchLeads) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (
      isAppliedFilter &&
      isEmpty(usersSelected) &&
      isEmpty(channelsSelected) &&
      isEmpty(labelsSelected)
    ) {
      getLeads();
      dispatch(setIsAppliedFilter(false));
    }
  }, [isAppliedFilter, usersSelected, channelsSelected, labelsSelected]);

  const isConvertedSaleStage = () => {
    if (sales_converted_stage === stage.id) return 'active';
    return '';
  };

  if (error)
    return (
      <div className="flex justify-center item-center">Failed to load</div>
    );

  if (isEmpty(stageLeads[stageId])) {
    return (
      <div className="box-card-container fix-width">
        <Loader />
      </div>
    );
  }

  // return (
  //   <div className="box-card-container">
  //     {isSalesBox && (
  //       <ContractValue value={stageLeads[stageId]?.contract_value} />
  //     )}
  //     <div className={`${isSalesBox ? 'mt-3' : 'mt-2'}`}>
  //       <StageHeader
  //         name={stage.stage_name}
  //         count={stageLeads[stageId]?.count}
  //         stageId={stageId}
  //         isIncoming={INCOMING === stage.stage_kind}
  //       />
  //     </div>
  //     <div
  //       className="px-4 overflow_y pl-5"
  //       style={{
  //         height: 'calc(100% - 57px)'
  //       }}
  //     >
  //       <div>
  //         {!isLoading && checkStageIsEmpty(stageLeads[stageId]) && (
  //           <StageEmptyState
  //             incomingStage={INCOMING === stage.stage_kind}
  //             boxName={boxName}
  //             box={box}
  //             dragStart={dragStart}
  //             stageId={stageId}
  //             isAppliedFilter={isAppliedFilter}
  //           />
  //         )}
  //         <StageReplyLater box={box} stageId={stageId} dragStart={dragStart} />
  //         <StageNewLead box={box} stageId={stageId} dragStart={dragStart} />
  //         <StageViewedLead box={box} stageId={stageId} dragStart={dragStart} />
  //       </div>
  //     </div>
  //   </div>
  // );
  // return (
  //   <>
  //     <Draggable
  //       key={`${stageId}`}
  //       draggableId={`${stageId}`}
  //       index={index}
  //       isDragDisabled={true}
  //     >
  //       {(provided) => (
  //         <div
  //           ref={provided.innerRef}
  //           className="box-card-container"
  //           {...provided.draggableProps}
  //           {...provided.dragHandleProps}
  //         >
  //           {isSalesBox && (
  //             <ContractValue value={stageLeads[stageId]?.contract_value} />
  //           )}
  //           <div className={`${isSalesBox ? 'mt-3' : 'mt-2'}`}>
  //             <StageHeader
  //               name={stage.stage_name}
  //               count={stageLeads[stageId]?.contact_value}
  //               stageId={stageId}
  //             />
  //           </div>
  //           <div
  //             className="px-4 overflow_y pl-5"
  //             style={{
  //               height: 'calc(100% - 57px)'
  //             }}
  //           >
  //             {checkStageIsEmpty(stageLeads[stageId]) && (
  //               <StageEmptyState
  //                 incomingStage={INCOMING === stage.stage_kind}
  //                 boxName={boxName}
  //                 box={box}
  //                 dragStart={dragStart}
  //                 stageId={stageId}
  //               />
  //             )}

  //             <StageReplyLater
  //               box={box}
  //               stageId={stageId}
  //               dragStart={dragStart}
  //             />
  //             <StageNewLead box={box} stageId={stageId} dragStart={dragStart} />
  //             <StageViewedLead
  //               box={box}
  //               stageId={stageId}
  //               dragStart={dragStart}
  //             />

  //             {/* <Lead />
  //         <Lead />
  //         <Lead />
  //         <Lead />
  //         <Lead /> */}
  //           </div>
  //           {provided.placeholder}
  //         </div>
  //       )}
  //     </Draggable>
  //     {/* <div className={`box-card-container`}>

  //     </div> */}
  //   </>
  // );
  return (
    <div
      className={`box-card-container ${
        fixWidth ? 'fix-width' : ''
      } ${isConvertedSaleStage()}`}
    >
      {isSalesBox && (
        <ContractValue
          value={stageLeads[stageId]?.contract_value}
          currency={myInformation?.my_org_info?.currency?.code}
        />
      )}
      <div className={`${isSalesBox ? 'mt-3' : 'mt-2'}`}>
        <StageHeader
          name={stage.stage_name}
          count={stageLeads[stageId]?.count}
          stageId={stageId}
          isIncoming={INCOMING === stage.stage_kind}
          isConversaionStage={sales_converted_stage === stage.id}
          stage={stage}
        />
      </div>
      <div className={`box-scroller-container ${!isSalesBox ? 'general' : ''}`}>
        {isConvertedSaleStage() && showConfetti === stage.id && (
          <Confetti onComplete={() => setShowConfetti(false)} />
        )}
        {isLoading && <Loader />}
        {!isLoading && (
          <Droppable droppableId={stageId} type="LEAD">
            {(provided) => {
              return (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  style={{ height: '100%' }}
                >
                  <div
                    id={`enquires-scroll-${stageId}`}
                    className="box-scroller"
                    style={{
                      paddingLeft: '16px',
                      paddingRight: '14px'
                    }}
                  >
                    <InfiniteScroll
                      dataLength={stageLeads[stageId]?.count}
                      next={() => {}}
                      hasMore={false}
                      scrollThreshold={0.8}
                      loader={<div>loading...</div>}
                      scrollableTarget={`enquires-scroll-${stageId}`}
                    >
                      {isChannelsConnected && (
                        <>
                          {!isLoading &&
                            checkStageIsEmpty(stageLeads[stageId]) && (
                              <StageEmptyState
                                incomingStage={INCOMING === stage.stage_kind}
                                boxName={boxName}
                                box={box}
                                dragStart={dragStart}
                                stageId={stageId}
                                isAppliedFilter={isAppliedFilter}
                              />
                            )}
                        </>
                      )}
                      {!isChannelsConnected &&
                        INCOMING !== stage.stage_kind && (
                          <>
                            {!isLoading &&
                              checkStageIsEmpty(stageLeads[stageId]) && (
                                <StageEmptyState
                                  incomingStage={INCOMING === stage.stage_kind}
                                  boxName={boxName}
                                  box={box}
                                  dragStart={dragStart}
                                  stageId={stageId}
                                  isAppliedFilter={isAppliedFilter}
                                />
                              )}
                          </>
                        )}
                      {!isChannelsConnected &&
                        INCOMING === stage.stage_kind && (
                          <EmptyChannelConnection />
                        )}
                      <StageReplyLater
                        index={index}
                        box={box}
                        stageId={stageId}
                        dragStart={dragStart}
                      />
                      <StageNewLead
                        box={box}
                        stageId={stageId}
                        dragStart={dragStart}
                      />
                      <StageViewedLead
                        box={box}
                        stageId={stageId}
                        dragStart={dragStart}
                      />
                    </InfiniteScroll>
                    {provided.placeholder}
                  </div>
                </div>
              );
            }}
          </Droppable>
        )}
      </div>
    </div>
  );
};

Stage.defaultProps = {
  fetchLeads: true,
  index: 0,
  dragStart: false,
  box: {}
};
export default React.memo(Stage);

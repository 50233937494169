/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import choices from 'choices';
import { isNumber } from 'utils/number';
import { isEmpty } from 'utils/utils';

const { DUE_IMMEDIATELY, DUE_IN_DAYS } = choices.TaskDueChoices;

const DueButton = ({
  active,
  onClick,
  icon,
  text,
  dueType,
  dueValue,
  setDueValue,
  isEdit
}) => {
  const inputRef = useRef();
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (dueType == DUE_IN_DAYS) {
      if (inputRef.current) inputRef.current.focus();
      if (!isEdit) {
        setIsFocused(true);
      }
    }
  }, [dueType, inputRef.current]);

  const onDueNumberChange = (e) => {
    const { value } = e.target;
    if (isEmpty(value)) {
      setDueValue(value);
      return;
    }
    if (value == 0) {
      setDueValue(1);
      return;
    }
    if (isNumber(value)) {
      setDueValue(value);
      return;
    }
    setDueValue(dueValue);
  };

  const onBlur = () => {
    if (isEmpty(dueValue)) {
      setDueValue(1);
    }
    setIsFocused(false);
  };

  const onFocus = () => setIsFocused(true);

  return (
    <div
      onClick={dueType === DUE_IN_DAYS ? () => {} : onClick}
      className={`flex items-center gap-2 button px-3 py-1 ${
        active || isFocused ? 'button__active' : ''
      }`}
    >
      <img
        src={icon}
        alt="icon-reminder"
        onClick={dueType === DUE_IN_DAYS ? onClick : () => {}}
      />
      <code
        className="inline-block"
        style={{
          width: 1,
          height: 22,
          backgroundColor: '#E5E5E5'
        }}
      ></code>
      {dueType === DUE_IMMEDIATELY && (
        <p className="text-grey-900 text-13 font-medium">{text}</p>
      )}
      {dueType === DUE_IN_DAYS && (
        <input
          type="text"
          value={dueValue}
          placeholder="Add days"
          className="box-task-input"
          style={{ width: '103px' }}
          onChange={onDueNumberChange}
          onBlur={onBlur}
          onFocus={onFocus}
          ref={inputRef}
        />
      )}
    </div>
  );
};

export default React.memo(DueButton);

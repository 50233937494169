/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import {
  GET_MY_BOX_PROVIDED_EMAIL,
  GET_EMAIL_VERIFICATION_PROVIDERS
} from 'urls/emailAddress';
import { replaceUrl } from 'utils/urlReplace';
import APIRequest from 'apiRequest';

const initialState = {
  box_provided_email: null,
  email_provider: null
};

const reducers = {
  setBoxProvidedEmail: (state, action) => {
    state.box_provided_email = action.payload;
  },
  setEmailProvider: (state, action) => {
    state.email_provider = action.payload;
  }
};

export const emailAddress = createSlice({
  name: 'emailAddress',
  initialState: initialState,
  reducers: reducers
});

export const { setBoxProvidedEmail, setEmailProvider } = emailAddress.actions;

export const getProvidedEmail = (payload) => {
  return async (dispatch, getState) => {
    await new APIRequest().get(GET_MY_BOX_PROVIDED_EMAIL).then((res) => {
      dispatch(setBoxProvidedEmail(res.data));
    });
  };
};

export const getEmailVerificationProvider = (payload) => {
  return async (dispatch, getState) => {
    await new APIRequest().get(GET_EMAIL_VERIFICATION_PROVIDERS).then((res) => {
      dispatch(setEmailProvider(res.data));
    });
  };
};

export default emailAddress.reducer;

export const replaceUrl = (url, id, value) => {
  if (Array.isArray(id) && Array.isArray(value)) {
    let URL = url;
    id.forEach((e, i) => {
      URL = URL.replace(`:${e}`, value[i]);
    });
    return URL;
  }
  return url.replace(`:${id}`, value);
};

import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { TASK_NOTES, TASK_NOTES_UPDATE } from 'urls';
import { replaceUrl } from 'utils/urlReplace';

export const noteSlice = createSlice({
  name: 'notes',
  initialState: {
    isEdit: false,
    isEditText: null,
    editedNote: null,
    isReEdit: false
  },
  reducers: {
    setIsEdit: (state, action) => {
      state.isEdit = action.payload;
    },
    setIsEditText: (state, action) => {
      state.isEditText = action.payload;
    },
    setEditedNote: (state, action) => {
      state.editedNote = action.payload;
    },
    resetNoteStore: (state) => {
      state.isEdit = false;
      state.isEditText = null;
      state.editedNote = null;
    },
    setIsReEdit: (state, action) => {
      state.isReEdit = action.payload;
    }
  }
});

export const {
  setIsEdit,
  setIsEditText,
  setEditedNote,
  resetNoteStore,
  setIsReEdit
} = noteSlice.actions;

export default noteSlice.reducer;

export const addTaskNotes = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest().post(TASK_NOTES, body).then((res) => {
        if (res.status === 201) {
          if (callback) {
            callback(res.data);
          }
          if (successCb) {
            successCb(res.data);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateTaskNotes = (payload) => {
  return async (dispatch) => {
    try {
      const { noteId, body, callback } = payload;
      new APIRequest()
        .patch(replaceUrl(TASK_NOTES_UPDATE, 'noteId', noteId), body)
        .then((res) => {
          if (res.status === 200) {
            if (callback) {
              callback(res.data);
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

export const deleteTaskNotes = (payload) => {
  return async (dispatch) => {
    try {
      const { noteId, callback } = payload;
      new APIRequest()
        .delete(replaceUrl(TASK_NOTES_UPDATE, 'noteId', noteId))
        .then((res) => {
          if (res.status === 200 || res.status === 204) {
            if (callback) {
              callback(res.data);
            }
          }
        });
    } catch (e) {
      console.log('e', e);
      return '';
    }
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import SelectStage from '../chatActions/selectStage';
import Avatar from 'components/avatar';

const StageContainer = (props) => {
  const { open, setSelected, selected, src, name, avatarStyles, onSelect } =
    props;

  const RenderSelectedAvatar = () => {
    return (
      <>
        <Avatar
          src={src}
          initial={name}
          border={false}
          initialStyle={{
            fontSize: '13px'
          }}
          smaller
          className="mr-3"
          contain
          singleChar
          style={avatarStyles}
        />
        <p className="text-sm text-grey-900 font-semibold">{name}</p>
      </>
    );
  };

  return (
    <SelectStage
      open={open}
      renderAvatar={RenderSelectedAvatar}
      onBack={() => setSelected(null)}
      onSelect={onSelect}
      selected={selected}
    />
  );
};

StageContainer.propTypes = {
  avatarStyles: PropTypes.object,
  submitBtnText: PropTypes.string
};

StageContainer.defaultProps = {
  avatarStyles: {},
  submitBtnText: 'Assign'
};

export default StageContainer;

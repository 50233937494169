/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import dotsIndicator from 'assets/icons/icon-more-round.svg';
import iconDelete from 'assets/icons/icon-Delete.svg';
import iconEdit from 'assets/icons/icon-edit.svg';
import noteicon1 from 'assets/icons/icon-automated-email-icon.svg';
import useClickOutside from 'hooks/useOutsideClick';
import moment from 'moment';
import Avatar from 'components/avatar';
import { MentionsInput, Mention } from 'react-mentions';
import classNames from '../../components/noteContainer/index.module.css';
import { useDispatch, useSelector } from 'react-redux';
import {
  setIsEdit,
  setIsEditText,
  setEditedNote,
  deleteTaskNotes,
  setIsReEdit
} from 'slices/noteSlice';

const Note = ({
  isAutomated,
  description,
  createdAt,
  createdBy,
  id,
  setOpenDeleteModal,
  src,
  isZapier = false
}) => {
  const dropdownRef = useRef();
  const dispatch = useDispatch();
  const [showOptions, setShowOptions] = useState(false); // state to open or close dropdown
  const { organizationUser } = useSelector((state) => state.organizationUser);
  const { editedNote } = useSelector((state) => state.notes);
  const users = organizationUser?.results?.map((item) => {
    // variable for mention list
    return {
      id: item.first_name,
      display: item.first_name,
      userId: item.user_id
    };
  });

  // to close the dropdown when user click outside
  useClickOutside(() => setShowOptions(false), dropdownRef.current);

  // function to update the note state with the edit data
  const OnEdit = async () => {
    if (editedNote) {
      dispatch(setIsReEdit(true));
    }
    dispatch(setIsEdit(true));
    dispatch(setIsEditText(description));
    dispatch(setEditedNote(id));
    setTimeout(() => {
      dispatch(setIsReEdit(false));
    }, 10);
  };
  return (
    <li
      className="contact-notes__list"
      onMouseLeave={() => setShowOptions(false)}
    >
      <MentionsInput
        className="mentions"
        value={description}
        classNames={classNames}
        forceSuggestionsAboveCursor={true}
        readOnly
      >
        <Mention
          trigger="@"
          data={users}
          className={classNames.mentions__mention}
          appendSpaceOnAdd={true}
        />
      </MentionsInput>
      <div className="flex items-center justify-between pl-2">
        {isAutomated ? (
          <div className="flex items-center pr-5">
            <figure
              className="avatar-container"
              style={{ width: 25, height: 25, backgroundColor: '#fff' }}
            >
              <img src={noteicon1} alt="" style={{ width: 10, height: 13 }} />
            </figure>
            <p className="text-grey-800 text-13 ml-2">
              <span className="text-13 font-semibold mr-2">Automated note</span>
              {moment(createdAt).format('MMM Do, h:mm a')}
            </p>
          </div>
        ) : (
          <>
            {isZapier ? (
              <div className="flex items-center pr-5">
                <p className="text-grey-800 text-13">
                  <span className="text-13 font-semibold mr-2">
                    Zapier note
                  </span>
                  {moment(createdAt).format('MMM Do, h:mm a')}
                </p>
              </div>
            ) : (
              <div className="flex items-center pr-5">
                <Avatar
                  src={src}
                  initial={createdBy}
                  border={false}
                  smallest
                  cover
                  initialStyle={{
                    fontSize: '10px'
                  }}
                  singleChar
                />
                <p className="text-grey-800 text-13 ml-2">
                  <span className="text-13 font-semibold mr-2">
                    {createdBy}
                  </span>
                  {moment(createdAt).format('MMM Do, h:mm a')}
                </p>
              </div>
            )}
          </>
        )}
        <div
          className={`relative dots-action-btn`}
          onClick={() => {
            setShowOptions(true);
          }}
        >
          <img src={dotsIndicator} alt="" style={{ width: 16, height: 4 }} />

          <div
            className={`dropdown-menu dropdown-menu--more ${
              showOptions ? 'open' : ''
            }`}
            style={{
              top: '100%',
              right: 0,
              padding: '8px',
              width: '150px'
            }}
            ref={dropdownRef}
          >
            <div
              className="dropdown-menu__list"
              onClick={(e) => {
                e.stopPropagation();
                setShowOptions(false);
                OnEdit();
              }}
            >
              <img
                src={iconEdit}
                alt=""
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Edit
            </div>
            <div
              className="dropdown-menu__list red-text"
              onClick={(e) => {
                e.stopPropagation();
                setShowOptions(false);
                setOpenDeleteModal(id);
              }}
            >
              <img
                src={iconDelete}
                alt=""
                className="mr-2"
                style={{ width: 16, height: 16 }}
              />
              Delete
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default Note;

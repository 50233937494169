/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react';
import useClickOutside from 'hooks/useOutsideClick';
import RenderList from './renderList';
import NoteContainer from '../chatActions/noteContainer';
import { useParams } from 'react-router-dom';
import APIRequest from 'apiRequest';
import {
  assigntoUserLead,
  unAssigntoUserLead
} from 'slices/organizationuserSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'components/Snackbar';
import { useAnalytics } from 'use-analytics';
import { isEmpty } from 'utils/utils';
import { getLeadData } from 'slices/conversationSlice';
import choices from 'choices';
import { getAndComposeStageLeads } from 'slices/boxes/slice';
import { LEAD_UPDATE_STATUS } from 'urls';
import { replaceUrl } from 'utils/urlReplace';

const { NEW, VIEWED } = choices.LeadStatusChoices;
const { MANUALLY_ADDED, IMPORTED_FROM_FILE } = choices.LeadChannelChoice;

const ChatActions = (props) => {
  const {
    options,
    onSelect,
    open,
    onClose,
    renderButton,
    title,
    selected,
    active,
    onSubmit,
    setSelected,
    setActive
  } = props;
  const RenderButton = renderButton;
  const { cardId, stageId } = useParams();
  const isBox = !isEmpty(stageId);
  const dispatch = useDispatch();
  const [notes, setNotes] = useState('');
  const { openSuccessSnackBar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  // const { analytics } = useSelector((state) => state.segments);
  const { myInformation } = useSelector((state) => state.myInfo);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const { track } = useAnalytics();

  const nodeRef = useRef();

  useClickOutside(() => onClose(false), nodeRef.current);

  const userAssigned = async () => {
    track(
      'enquiry_assigned',
      {
        user: myInformation.name
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(
      getLeadData({ leadId: cardId }, (res) => {
        setActive(selected);
        setIsLoading(false);
        onSubmit();
        openSuccessSnackBar('Assigned successfully', 'large');
      })
    );
  };

  const userUnAssigned = async () => {
    if (activeLead.status == NEW) {
      const data = {
        lead: cardId,
        is_current: true,
        status: VIEWED
      };
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), data)
        .then((res) => {});
    }
    dispatch(
      getLeadData({ leadId: cardId }, (res) => {
        setActive(selected);
        setActive(null);
        setIsLoading(false);
        onSubmit();
        openSuccessSnackBar('Unassigned successfully', 'large');
      })
    );
  };

  const onAssign = (isAssigned) => {
    const formData = {
      leadId: cardId,
      body: {
        assigned_to: selected?.user_id,
        note: notes
      }
    };
    setIsLoading(true);
    if (isAssigned) {
      dispatch(unAssigntoUserLead(formData, userUnAssigned));
    } else {
      dispatch(assigntoUserLead(formData, userAssigned));
    }
  };

  return (
    <div ref={nodeRef}>
      <RenderButton />
      {selected && (
        <NoteContainer
          open={open}
          selected={selected}
          setSelected={setSelected}
          onBack={() => setSelected(null)}
          setNotes={setNotes}
          onSubmit={onAssign}
          isLoading={isLoading}
          src={selected?.avatar}
          name={selected?.first_name}
        />
      )}
      {!selected && (
        <RenderList
          open={open}
          options={options}
          onSelect={onSelect}
          title={title}
          active={active}
          onSubmit={onAssign}
          isLoading={isLoading}
        />
      )}
    </div>
  );
};

ChatActions.defaultProps = {
  onSelect: () => {},
  onClose: () => {}
};

export default ChatActions;

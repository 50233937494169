import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import {
  CREATE_FIELD_ITEMS,
  DELETE_DATA_COLLECTION_ITEM,
  GET_ALL_DATA_COLLECTION_FIELDS,
  REORDER_DATA_ITEMS,
  UPDATE_DATA_COLLECTION_FIELDS,
  UPDATE_DATA_COLLECTION_ITEMS
} from 'urls/ai-assistant';
import { replaceUrl } from 'utils/urlReplace';
import { setVersionStatus } from '../assistant/slice';
const initialState = {
  data_collection: {},
  data_fields: [],
  compliance_enabled: false,
  compliance_message: '',
  privacy_policy_link: null,
  dataCollectionIsLoading: false
};

const reducers = {
  setDataFields: (state, action) => {
    state.data_fields = action.payload;
  },
  setComplianceEnabled: (state, action) => {
    state.compliance_enabled = action.payload;
  },
  setComplianceMessage: (state, action) => {
    state.compliance_message = action.payload;
  },
  setPrivacyPolicyLink: (state, action) => {
    state.privacy_policy_link = action.payload;
  },
  setDataCollection: (state, action) => {
    state.data_collection = action.payload;
  },
  setDataColletionIsLoading: (state, action) => {
    state.dataCollectionIsLoading = action.payload;
  },
  resetDataCollection: () => initialState
};

export const dataCollection = createSlice({
  name: 'dataCollection',
  initialState,
  reducers
});

export const {
  setComplianceEnabled,
  setComplianceMessage,
  setPrivacyPolicyLink,
  setDataFields,
  setDataCollection,
  setDataColletionIsLoading,
  resetDataCollection
} = dataCollection.actions;

export default dataCollection.reducer;

export const getAllDataCollectionFields = (assistant_id) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .get(
          replaceUrl(GET_ALL_DATA_COLLECTION_FIELDS, 'aiBotId', assistant_id)
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setDataCollection(res.data));
            dispatch(setDataFields(res.data.data_fields));
            dispatch(setComplianceEnabled(res.data.compliance_enabled));
            dispatch(setComplianceMessage(res.data.compliance_message));
            dispatch(setPrivacyPolicyLink(res.data.privacy_policy_link));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateDataCollectionFields = (
  assistant_id,
  payload,
  successCb,
  failedCb,
  type
) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest()
        .put(
          replaceUrl(UPDATE_DATA_COLLECTION_FIELDS, 'aiBotId', assistant_id),
          payload
        )
        .then((res) => {
          if (res.status === 200) {
            dispatch(setVersionStatus(res.data.version_status));
            dispatch(setDataCollection(res.data));
            dispatch(setComplianceEnabled(res.data.compliance_enabled));
            dispatch(setComplianceMessage(res.data.compliance_message));
            dispatch(setPrivacyPolicyLink(res.data.privacy_policy_link));
            if (successCb) {
              successCb(assistant_id, res.data, type);
            }
          }
        })
        .catch((err) => {
          console.log(err);
          if (failedCb) {
            failedCb();
          }
        });
    } catch (e) {
      if (failedCb) {
        failedCb();
      }
      console.log(e);
    }
  };
};

export const createDataFields = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { data_fields } = getState().dataCollection;
    let newPayload = { ...payload };
    delete newPayload.index;
    try {
      await new APIRequest()
        .post(
          replaceUrl(CREATE_FIELD_ITEMS, 'aiBotId', payload.assistant_id),
          newPayload
        )
        .then((res) => {
          if (res.status === 200) {
            const data = data_fields.map((item, index) => {
              if (payload.index === index) return { ...res.data };
              return item;
            });
            dispatch(setVersionStatus(res.data.version_status));
            dispatch(setDataFields(data));
            successCb(res.data);
          }
        })
        .catch(() => {
          failedCb();
        });
    } catch (e) {
      failedCb();
      console.log(e);
    }
  };
};

export const reorderDataItems = (payload) => {
  return async (dispatch, getState) => {
    try {
      await new APIRequest().put(REORDER_DATA_ITEMS, payload).then((res) => {
        if (res.status === 200) {
          dispatch(setVersionStatus(res.data.version_status));
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

export const updateDataItemFields = (payload, sucessCb, failedCb) => {
  return async (dispatch, getState) => {
    const { data_fields } = getState().dataCollection;
    const newPayload = { ...payload };
    delete newPayload.index;
    try {
      await new APIRequest()
        .put(UPDATE_DATA_COLLECTION_ITEMS, newPayload)
        .then((res) => {
          if (res.status === 200) {
            let newCurrentData_fields = [...data_fields];
            let newData = newCurrentData_fields.map((item, i) => {
              if (i === payload.index) {
                return {
                  ...item,
                  ['field_name']: res.data.field_name,
                  ['is_mandatory']: res.data.is_mandatory,
                  ['need_compliance']: res.data.need_compliance,
                  ['position']: res.data.position
                };
              }
              return {
                ...item
              };
            });
            sucessCb(newData);
            dispatch(setDataFields(newData));
            dispatch(setVersionStatus(res.data.version_status));
          }
        })
        .catch(() => {
          failedCb();
        });
    } catch (e) {
      failedCb();
      console.log(e);
    }
  };
};

export const deleteDataCollectionItem = (payload, successCb, failedCb) => {
  return async (dispatch, getState) => {
    const { assistant } = getState();
    const { data_fields } = getState().dataCollection;
    let { currentVersionId } = assistant;
    let newPayload = {
      ...payload,
      version_id: currentVersionId
    };
    try {
      await new APIRequest()
        .delete(DELETE_DATA_COLLECTION_ITEM, newPayload)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              setDataFields(
                data_fields.filter((dataField) => {
                  return dataField.id !== res.data.data_field_id;
                })
              )
            );
            dispatch(setVersionStatus(res.data.version_status));
            successCb();
          }
        })
        .catch(() => {
          failedCb();
        });
    } catch (e) {
      failedCb();
      console.log(e);
    }
  };
};

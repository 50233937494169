import React from 'react';
import DeleteChannel from '../deleteChannel';
import { useParams, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'components/Snackbar';
import AutomateStuff from '../../automationStuff/templates';
import CHOICES from 'choices';
import FbLeadAdsConnectedPages from './connectedPages';

const FACEBOOK_LEAD_ADS = CHOICES.ChannelChoices.FACEBOOK_LEAD_ADS;

const FbLeadAdsSettings = () => {
  const { channelId } = useParams();
  const { openSuccessSnackBar } = useSnackbar();
  const navigate = useNavigate();

  const onDeleteSuccess = () => {
    openSuccessSnackBar('Channel Removed');
    navigate('/dashboard/enquirybox/settings');
  };
  return (
    <>
      <h2 className="text-2xl font-semibold text-center">Channel Settings</h2>
      <AutomateStuff channelType={FACEBOOK_LEAD_ADS} />
      <FbLeadAdsConnectedPages />
      <DeleteChannel channelId={channelId} onSuccess={onDeleteSuccess} />
    </>
  );
};

export default FbLeadAdsSettings;

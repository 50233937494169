/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getAllAnswer,
  getQuestionsList,
  setIsShowQuestionsToAdd
} from 'slices/questionSlice';
import { isEmpty } from 'utils/utils';
import AddQuestions from './component/addQuestions';
import ContactDetails from './component/contactDetails';
import QuestionsAdded from './module/questionsAdded';
import QuestionsForm from './module/questionsForm';
import EmptyState from './module/questionsForm/emptyState';

const Contact = (props) => {
  const { isBin, isSms } = props;
  const { cardId } = useParams();
  const dispatch = useDispatch();
  const { questionsData, isShowQuestionsToAdd, isShowQuestionForm } =
    useSelector((state) => state.question);
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    dispatch(getQuestionsList({ subOrgId: subOrganizationId }));
    dispatch(getAllAnswer({ leadId: cardId }));
  }, []);

  const onAddQuestion = () => {
    dispatch(setIsShowQuestionsToAdd(true));
  };

  return (
    <div className="contacts-tab">
      <ContactDetails
        avatarName={activeLead?.name || activeLead?.first_name}
        name={activeLead?.name || activeLead?.first_name}
        email={activeLead?.email}
        phone={activeLead?.phone_number}
        src={activeLead?.customer_avatar}
        isBin={isBin}
        isSms={isSms}
      />
      {isEmpty(questionsData)
        ? !isShowQuestionsToAdd &&
          !isShowQuestionForm && (
            <EmptyState onClick={onAddQuestion} isBin={isBin} />
          )
        : !isShowQuestionsToAdd &&
          !isShowQuestionForm && (
            <QuestionsAdded data={questionsData} isBin={isBin} />
          )}
      {isShowQuestionsToAdd && !isShowQuestionForm && <AddQuestions />}
      {isShowQuestionForm && <QuestionsForm />}
    </div>
  );
};
export default Contact;

import React from 'react';
import iconReplyWhite from 'assets/icons/icon-reply-white.svg';
import PropTypes from 'prop-types';
import CHOICES from 'choices';
import _ from 'lodash';

const { NEW } = CHOICES.LeadStatusChoices;

const Status = (props) => {
  const { isReplied, replyCount, status, style } = props;

  return (
    <>
      {status === NEW && isReplied && replyCount === 0 && (
        <label
          className="notification-label notification-label--new-msg"
          style={{ maxWidth: '40px', padding: '2px 6px', ...style }}
        >
          New
        </label>
      )}
      {status === NEW && !isReplied && (
        <label
          className="notification-label notification-label--new-msg"
          style={{ maxWidth: '40px', padding: '2px 6px', ...style }}
        >
          New
        </label>
      )}
      {isReplied && _.gt(replyCount, 0) && (
        <label className="notification-label notification-label__reply-recieved">
          {replyCount}
          <img src={iconReplyWhite} alt="arrow-reply" />
        </label>
      )}
    </>
  );
};

Status.propTypes = {
  isNew: PropTypes.bool,
  isReplied: PropTypes.bool,
  replyCount: PropTypes.number,
  status: PropTypes.number
};

Status.defaultProps = {
  isNew: false,
  isReplied: false,
  replyCount: '',
  style: {}
};
export default React.memo(Status);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import DropDownWrapper from 'components/dropdown/wrapper';
import { Link } from 'react-router-dom';
import IconSearch from 'assets/icons/icon-search-grey.svg';
import InputWithIcon from 'components/inputs/inputWithIcon';
import { isEmpty } from 'utils/utils';
import { route } from 'utils/route';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRemovedLabels,
  setSelectedLabels,
  setTempSelected
} from 'slices/labelsSlice';

const LabelContainer = ({ open, options, onSearchLabels, inputValue }) => {
  const dispatch = useDispatch();
  const { activeLead } = useSelector((state) => state.leadConversation);
  const { allLabels, selectedLabels, tempSelected, removedLabels } =
    useSelector((state) => state.labels);

  const getCheckedStatus = (name) => {
    if (!isEmpty(activeLead?.labels)) {
      const isLabelChecked = activeLead?.labels.some(
        (item) => item.label_name === name
      );
      return isLabelChecked;
    } else {
      return false;
    }
  };

  const [labelOptions, setLabelOptions] = useState(
    options.map((item, index) => {
      return {
        ...options[index],
        isChecked: getCheckedStatus(item.label_name)
      };
    })
  );
  useEffect(() => {
    setLabelOptions(
      options.map((item, index) => {
        return {
          ...options[index],
          isChecked: getCheckedStatus(item.label_name)
        };
      })
    );
  }, [activeLead, options]);

  const getCheckedSelectedLabels = (name) => {
    if (!isEmpty(selectedLabels)) {
      const isLabelChecked = selectedLabels.some(
        (item) => item.label_name === name
      );
      return isLabelChecked;
    } else {
      return false;
    }
  };

  useEffect(() => {
    setLabelOptions(
      options.map((item, index) => {
        return {
          ...options[index],
          isChecked: getCheckedSelectedLabels(item.label_name)
        };
      })
    );
  }, [selectedLabels]);

  const handleChange = (ind) => {
    const currentStatus = labelOptions[ind].isChecked;
    labelOptions[ind].isChecked = !currentStatus;
    setLabelOptions([...labelOptions]);
    const currentLabel = labelOptions[ind];
    if (currentLabel.isChecked) {
      if (!isEmpty(activeLead?.labels)) {
        const isCurrentLabelAssigned = activeLead?.labels.filter(
          (item) => item.label_name === currentLabel.label_name
        );
        if (!isEmpty(isCurrentLabelAssigned)) {
          // check if the the checked label is already assigned
          dispatch(
            setSelectedLabels([...selectedLabels, ...isCurrentLabelAssigned])
          );
        } else {
          // if the the checked label is not already assigned
          dispatch(setSelectedLabels([...selectedLabels, allLabels[ind]]));
        }
      } else {
        dispatch(setSelectedLabels([...selectedLabels, allLabels[ind]]));
      }
      dispatch(setTempSelected([...tempSelected, allLabels[ind]]));
      const isCheckedLabelRemoved = removedLabels.filter(
        (item) => item.label_name !== currentLabel.label_name
      );
      dispatch(setRemovedLabels(isCheckedLabelRemoved));
    }
    if (!currentLabel.isChecked) {
      const filteredLabel = selectedLabels.filter(
        (item) => item.label_name !== currentLabel.label_name
      );
      const unCheckedLabel = selectedLabels.filter(
        (item) => item.label_name === currentLabel.label_name
      );
      if (!isEmpty(activeLead?.labels)) {
        const isUnCheckedPresentInLead = activeLead?.labels.filter(
          ({ label_name: name1 }) =>
            unCheckedLabel.some(({ label_name: name2 }) => name2 === name1)
        );
        if (!isEmpty(isUnCheckedPresentInLead)) {
          // if the unchecked label is already present in lead
          dispatch(setRemovedLabels([...removedLabels, ...unCheckedLabel]));
        } else {
          // if the unchecked label is not present in lead
          dispatch(setRemovedLabels([...removedLabels]));
        }
      } else {
        dispatch(setRemovedLabels([]));
      }
      dispatch(setSelectedLabels(filteredLabel));
      dispatch(setTempSelected([...tempSelected, ...unCheckedLabel]));
    }
  };

  return (
    <DropDownWrapper
      className="dropdown-menu--with-border p-2 px-1"
      open={open}
      style={{
        right: '228px',
        left: 'auto',
        width: '316px',
        top: '33px'
      }}
    >
      <div style={{ margin: '10px 10px 8px' }}>
        <InputWithIcon
          icon={IconSearch}
          iconClassName="mr-2"
          placeholder="Search"
          value={inputValue}
          onChange={onSearchLabels}
          small
        />
      </div>
      <Link
        className="font-medium text-primary text-13 cursor-pointer"
        to={`${route.dashboard}${route.settings}/labels`}
        style={{ marginLeft: 10 }}
      >
        Manage Labels
      </Link>
      <div className="no-hover dropdown-menu__overflow-y-scroll mt-2">
        {isEmpty(options) && (
          <div className="flex justify-center text-sm font-semibold">
            Label not found
          </div>
        )}
        {!isEmpty(options) &&
          labelOptions.map((item, index) => {
            const { label_name, id, isChecked } = item;
            return (
              <div className="dropdown-menu__list" key={index}>
                <div className="flex items-center">
                  <input
                    id={id}
                    type="checkbox"
                    className="custom-checkbox custom-checkbox--xs"
                    name={`${id}`}
                    checked={isChecked}
                    onChange={() => handleChange(index)}
                  />
                </div>
                <label
                  htmlFor={id}
                  className="text-sm cursor-pointer pl-4 width-fill-available"
                >
                  {label_name}
                </label>
              </div>
            );
          })}
      </div>
    </DropDownWrapper>
  );
};

LabelContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  onOptionChange: PropTypes.func.isRequired,
  onSearchLabels: PropTypes.func,
  inputValue: PropTypes.string
};

export default LabelContainer;

import DeleteAccountModal from 'modules/modals/deleteAccount';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowSubscriptionModal,
  setShowDeleteAccountModal,
  setPostExpiryActiveModal
} from 'slices/subscription/slice';
import { DELETE_ACCOUNT } from 'utils/constants';

const DeleteAccount = () => {
  const { showDeleteAccountModal } = useSelector((state) => state.subscription);
  const { myInformation } = useSelector((state) => state.myInfo);
  const dispatch = useDispatch();

  const onShowPlan = () => {
    dispatch(setShowSubscriptionModal(true));
    dispatch(setShowDeleteAccountModal(false));
    dispatch(setPostExpiryActiveModal(DELETE_ACCOUNT));
  };

  return (
    <DeleteAccountModal
      open={showDeleteAccountModal}
      title={`All the best, ${myInformation?.name || myInformation?.firstName}`}
      onShowPlan={onShowPlan}
    />
  );
};

export default DeleteAccount;

import React from 'react';

const Tooltip = ({
  children,
  position = 'top',
  tooltipText = 'tooltipText'
}) => {
  return (
    <div className="custom-tooltip__type2">
      {children}
      <div
        className={`flex justify-between items-center h-8 tooltiptext w-f-max tooltiptext__${position} `}
      >
        <p className="flex justify-center text-center text-15 self-center">
          {tooltipText}
        </p>
      </div>
    </div>
  );
};

export default Tooltip;

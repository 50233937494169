import ErrorMessage from 'components/errorMessage';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getBotValues,
  updateBotValues
} from 'slices/ai-assistant/deploy/websiteLinks/controls/slice';
import { checkValid } from './validation';
import TextInputField from 'components/inputs/textField';
import { useSnackbar } from 'components/Snackbar';
import OverLayLoader from 'components/loader/overLayLoader';

const BotsControls = () => {
  const initialValues = {
    placement: '',
    side_spacing: '',
    bottom_spacing: '',
    show_bot_on_desktop: false,
    show_bot_on_mobile: false,
    automatically_open_the_bot_on_desktop: false,
    automatically_open_the_bot_on_desktop_delay: '',
    automatically_open_the_bot_on_mobile: false,
    automatically_open_the_bot_on_mobile_delay: ''
  };
  const { botValues } = useSelector((state) => state.botControls);
  const [botData, setBotData] = useState(initialValues);
  const { assistant_id } = useParams();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState({});
  const { openSuccessSnackBar, openErrorSnackBar } = useSnackbar();
  const { currentVersionId } = useSelector((state) => state.assistant);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(botValues).length) setBotData(botValues);
  }, [botValues]);

  useEffect(() => {
    dispatch(getBotValues(assistant_id));
  }, []);

  const successCb = () => {
    setErrors({});
  };

  const inputSuccessCb = () => {
    setIsLoading(false);
    setErrors({});
    openSuccessSnackBar('Value updated');
  };

  const inputFailedCb = () => {
    setIsLoading(false);
    openErrorSnackBar('Updation failed');
  };

  const handleChange = (e, successCb) => {
    const name = e.target.name;
    const payload = {
      data: {
        theme: {
          [name]: !botData[name]
        },
        version_id: currentVersionId
      },
      botId: assistant_id
    };
    dispatch(updateBotValues(payload, successCb));
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    document.activeElement.blur();
    const name = e.target.name;
    const value = e.target.value;
    const validation = checkValid(name, value);

    if (validation.isValid) {
      if (parseFloat(value) !== parseFloat(botValues[name])) {
        setIsLoading(true);
        const payload = {
          data: {
            theme: {
              [name]: value
            },
            version_id: currentVersionId
          },
          botId: assistant_id
        };
        dispatch(updateBotValues(payload, inputSuccessCb, inputFailedCb));
      }
    }
  };

  const handleSpaceInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    if (!isNaN(value) && value.length <= 3) {
      setBotData({ ...botData, [name]: value });
    }
    const validation = checkValid(name, value);
    setErrors({
      ...errors,
      [name]: validation.errorMessage
    });
  };

  const handleTimeInputChange = (e) => {
    const { value } = e.target;
    const { name } = e.target;
    if (!isNaN(value) && value.length <= 2) {
      setBotData({ ...botData, [name]: value });
    }
    const validation = checkValid(name, value);
    setErrors({
      ...errors,
      [name]: validation.errorMessage
    });
  };

  return (
    <section style={{ padding: '25px 0' }}>
      {isLoading && <OverLayLoader style={{ top: 0, left: 0 }} />}
      <div className="bots-controls-container py-5 px-6">
        <label className="text-lg font-semibold">
          Control your bots position
        </label>
        <div className="mt-9">
          <div className="flex items-center justify-between">
            <div className="flex flex-col">
              <label className="text-15 font-medium">
                Position of the bot on desktop and tablet
              </label>
              <p className="text-grey-800 text-xs">
                The position cannot be changed on mobile
              </p>
            </div>
            <select
              id="placement"
              name="placement"
              className="select-default-dropdown bots-controls__input-container"
              value={botData.placement}
              onChange={(e) => {
                setBotData({ ...botData, placement: parseInt(e.target.value) });
                handlesubmit(e);
              }}
              style={{ width: '80px' }}
            >
              <option value="1">Right</option>
              <option value="2">Left</option>
            </select>
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="flex flex-col">
              <label className="text-15 font-medium">
                Add some side spacing
              </label>
              <p className="text-grey-800 text-xs">
                Move the widget in from the side of the screen
              </p>
            </div>
            <form onSubmit={(e) => handlesubmit(e)}>
              <TextInputField
                type="text"
                className="bots-controls__input-container"
                name="side_spacing"
                placeholder=""
                value={botData.side_spacing}
                onBlur={(e) => handlesubmit(e)}
                onChange={handleSpaceInputChange}
                style={{
                  height: '35px',
                  fontSize: '15px',
                  width: '78px'
                }}
                error={errors.side_spacing ? true : false}
                autoComplete={'off'}
              >
                <span
                  style={{ top: '5px', right: '10px' }}
                  className="absolute text-15 ml-2"
                >
                  px
                </span>
              </TextInputField>
            </form>
          </div>
          <div className="flex justify-end">
            {errors.side_spacing && (
              <ErrorMessage message={errors.side_spacing} />
            )}
          </div>
          <div className="flex items-center justify-between mt-6">
            <div className="flex flex-col">
              <label className="text-15 font-medium">
                Add some bottom spacing
              </label>
              <p className="text-grey-800 text-xs">
                Move the widget up from the bottom of the screen
              </p>
            </div>
            <form onSubmit={(e) => handlesubmit(e)}>
              <TextInputField
                type="text"
                className="bots-controls__input-container"
                name="bottom_spacing"
                placeholder=""
                value={botData.bottom_spacing}
                onChange={handleSpaceInputChange}
                onBlur={(e) => handlesubmit(e)}
                style={{
                  height: '35px',
                  fontSize: '15px',
                  width: '78px'
                }}
                error={errors.bottom_spacing ? true : false}
                autoComplete={'off'}
              >
                <span
                  style={{ top: '5px', right: '10px' }}
                  className="absolute text-15 ml-2"
                >
                  px
                </span>
              </TextInputField>
            </form>
          </div>
          <div className="flex justify-end">
            {errors.bottom_spacing && (
              <div>
                <ErrorMessage message={errors.bottom_spacing} />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="bots-controls-container mt-8">
        <div className="py-5 px-6">
          <label className="text-lg font-semibold">
            Control your bots position
          </label>
          <div className="mt-4">
            <label className="text-15 font-semibold">Show the bot on:</label>
            <div className="flex items-center justify-between mt-3">
              <label className="text-grey-800 text-15 pr-5">Desktop</label>
              <div className="toggle-slider relative flex-shrink-0">
                <input
                  type="checkbox"
                  id="desktop"
                  className="toggle-slider__checkbox"
                  checked={botData.show_bot_on_desktop}
                  name="show_bot_on_desktop"
                  onChange={(e) => handleChange(e, successCb)}
                />
                <label htmlFor="desktop" className="toggle-slider__label">
                  <code className="toggle-slider__ball toggle-slider__ball__small" />
                </label>
              </div>
            </div>
            <div className="flex items-center justify-between mt-4">
              <label className="text-grey-800 text-15 pr-5">
                Mobile devices
              </label>
              <div className="toggle-slider relative flex-shrink-0">
                <input
                  type="checkbox"
                  id="mobileDevices"
                  className="toggle-slider__checkbox"
                  checked={botData?.show_bot_on_mobile}
                  name="show_bot_on_mobile"
                  onChange={(e) => handleChange(e, successCb)}
                />
                <label htmlFor="mobileDevices" className="toggle-slider__label">
                  <code className="toggle-slider__ball toggle-slider__ball__small" />
                </label>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <label className="text-15 font-semibold">
              Automatically open the bot
            </label>
            <div className="flex items-center justify-between mt-3">
              <label className="text-grey-800 text-15 pr-5">Desktop</label>
              <div className="toggle-slider relative flex-shrink-0">
                <input
                  type="checkbox"
                  id="desktop1"
                  className="toggle-slider__checkbox"
                  name="automatically_open_the_bot_on_desktop"
                  checked={botData.automatically_open_the_bot_on_desktop}
                  onChange={(e) => handleChange(e, successCb)}
                />
                <label htmlFor="desktop1" className="toggle-slider__label">
                  <code className="toggle-slider__ball toggle-slider__ball__small" />
                </label>
              </div>
            </div>
            {botData.automatically_open_the_bot_on_desktop && (
              <div className="flex items-center justify-between mt-3">
                <label className="text-grey-800 text-15 pr-5">
                  Delay the bot opening on desktop by (seconds)
                </label>
                <form onSubmit={(e) => handlesubmit(e)}>
                  <TextInputField
                    autoComplete={'off'}
                    type="text"
                    placeholder=""
                    className="bots-controls__input-with-border"
                    value={botData?.automatically_open_the_bot_on_desktop_delay}
                    error={
                      errors.automatically_open_the_bot_on_desktop_delay
                        ? true
                        : false
                    }
                    name="automatically_open_the_bot_on_desktop_delay"
                    onChange={handleTimeInputChange}
                    onBlur={(e) => handlesubmit(e)}
                    style={{
                      height: '27px',
                      width: '32px',
                      fontSize: '15px',
                      padding: '4px 6px',
                      borderRadius: '6px'
                    }}
                  />
                </form>
              </div>
            )}
            <div className="flex justify-end">
              {errors.automatically_open_the_bot_on_desktop_delay && (
                <div>
                  <ErrorMessage
                    message={errors.automatically_open_the_bot_on_desktop_delay}
                  />
                </div>
              )}
            </div>
            <div className="flex items-center justify-between mt-3">
              <label className="text-grey-800 text-15 pr-5">
                Mobile devices
              </label>
              <div className="toggle-slider relative flex-shrink-0">
                <input
                  type="checkbox"
                  id="mobileDevices1"
                  className="toggle-slider__checkbox"
                  name="automatically_open_the_bot_on_mobile"
                  checked={botData.automatically_open_the_bot_on_mobile}
                  onChange={(e) => handleChange(e, successCb)}
                />
                <label
                  htmlFor="mobileDevices1"
                  className="toggle-slider__label"
                >
                  <code className="toggle-slider__ball toggle-slider__ball__small" />
                </label>
              </div>
            </div>
            {botData.automatically_open_the_bot_on_mobile && (
              <div className="flex items-center justify-between mt-3">
                <label className="text-grey-800 text-15 pr-5">
                  Delay the bot opening on mobile by (seconds)
                </label>
                <form onSubmit={(e) => handlesubmit(e)}>
                  <TextInputField
                    autoComplete={'off'}
                    type="text"
                    placeholder=""
                    className="bots-controls__input-with-border"
                    name="automatically_open_the_bot_on_mobile_delay"
                    value={botData.automatically_open_the_bot_on_mobile_delay}
                    error={
                      errors.automatically_open_the_bot_on_mobile_delay
                        ? true
                        : false
                    }
                    onChange={handleTimeInputChange}
                    onBlur={(e) => handlesubmit(e)}
                    style={{
                      height: '27px',
                      width: '32px',
                      fontSize: '15px',
                      padding: '4px 6px',
                      borderRadius: '6px'
                    }}
                  />
                </form>
              </div>
            )}
            <div className="flex justify-end">
              {errors.automatically_open_the_bot_on_mobile_delay && (
                <div>
                  <ErrorMessage
                    message={errors.automatically_open_the_bot_on_mobile_delay}
                  />
                </div>
              )}
            </div>
            <div className="px"></div>
          </div>
        </div>
        <div
          className="text-grey-800 text-15 py-3 px-6 mt-1"
          style={{
            background: 'rgba(248, 184, 13, 0.09)',
            borderTop: '1px solid #E6E4E1',
            borderRadius: '0px 0px 14px 14px'
          }}
        >
          Changes to these settings will only reflect in the embedded website
          version
        </div>
      </div>
    </section>
  );
};

export default BotsControls;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Loader } from 'components/loader';
import { handleScrollIntoview } from 'utils/utils';
import { useParams } from 'react-router-dom';

const RenderLoader = ({ children, index }) => {
  const [showLoader, setShowLoader] = useState(true);
  const { box_id } = useParams();

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
      // for auto scrolling down on selecting the type of automations in then
      handleScrollIntoview(`boxThen-${box_id}`, 'end');
    }, 2000);
  }, []);

  return (
    <div>
      <div className={`${showLoader ? '' : 'hidden'}`}>
        <Loader tiny />
      </div>
      <div className={`${showLoader ? 'hidden' : ''}`}>{children}</div>
    </div>
  );
};

export default RenderLoader;

/* eslint-disable no-unused-vars */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import choices from 'choices';
import Select from 'components/inputs/select';
import { handleScrollIntoview, isEmpty } from 'utils/utils';
import iconAddAutomationRound from 'assets/icons/icon-add-automation-round.svg';
// import iconDots from 'assets/icons/icon-three-dots.svg';
// import iconDelete from 'assets/icons/icon-Delete.svg';
import {
  setThen,
  setAutomationErrors,
  setIsDelayTypeSelected
} from 'slices/boxAutomation/slice';
import SendEmailEditor from '../email';
import MessengerEditor from '../messenger';
import SmsEditor from '../sms';
import AssignTeamMember from '../assignTeamMember';
import DelayTimer from '../delayTimer';
import MoveToBox from '../moveToBox';
import AutomationNote from '../note';
import TaskAutomation from '../task';
import ErrorMessage from 'components/errorMessage';
import { omit } from 'lodash';
import ThenHeader from './thenHeader';
import EditThen from './editThen';
import RenderLoader from './renderLoader';
import { useParams } from 'react-router-dom';

const {
  ASSIGN_MEMBER,
  AUTORESPONDER_EMAIL,
  FACEBOOK,
  INSTAGRAM,
  DELAY_TIMER,
  MOVE_TO_BOX,
  SMS,
  NOTES,
  TASK
} = choices.BoxAutomationChoice;

const automationType = [
  { label: 'Send an email', value: AUTORESPONDER_EMAIL },
  { label: 'Send an sms', value: SMS },
  { label: 'Select the box to move to', value: MOVE_TO_BOX },
  { label: 'Assign to team member', value: ASSIGN_MEMBER },
  { label: 'Add a delay timer', value: DELAY_TIMER },
  { label: 'Add a note', value: NOTES },
  { label: 'Create a task', value: TASK }
  // {
  //   label: 'Send an instagram DM',
  //   value: INSTAGRAM
  // },
  // {
  //   label: 'Send a Facebook Message',
  //   value: FACEBOOK
  // }
];

const BoxAutomationStuffThen = ({ index, condition }) => {
  const dispatch = useDispatch();
  const { automationErrors, selectedAutomation, isDelayTypeSelected, then } =
    useSelector((state) => state.boxAutomation);
  const { box_id } = useParams();
  const { taskType } = useSelector((state) => state.tasks);

  const getThenStatement = (type) => {
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return 'Send an email';
      case ASSIGN_MEMBER:
        return 'Assign to team member';
      case DELAY_TIMER:
        return 'Add a delay timer';
      case MOVE_TO_BOX:
        return 'Select the box to move to';
      // case FACEBOOK:
      //   return 'Send a Facebook Message';
      // case INSTAGRAM:
      //   return 'Send a Instagram DM';
      case SMS:
        return 'Send sms';
      case NOTES:
        return 'Add a note';
      case TASK:
        return 'Create a task';
      default:
        return 'Select what you would like to do now';
    }
  };

  const renderThen = (type, condition, index) => {
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return (
          <RenderLoader index={index}>
            <SendEmailEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case ASSIGN_MEMBER:
        return <AssignTeamMember condition={condition} index={index} />;
      case FACEBOOK:
        return (
          <RenderLoader>
            <MessengerEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case INSTAGRAM:
        return (
          <RenderLoader>
            <MessengerEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case DELAY_TIMER:
        return <DelayTimer condition={condition} index={index} />;
      case MOVE_TO_BOX:
        return <MoveToBox condition={condition} index={index} />;
      case SMS:
        return (
          <RenderLoader>
            <SmsEditor condition={condition} index={index} />
          </RenderLoader>
        );
      case NOTES:
        return (
          <RenderLoader>
            <AutomationNote condition={condition} index={index} />
          </RenderLoader>
        );
      case TASK:
        return (
          <RenderLoader>
            <TaskAutomation condition={condition} index={index} />
          </RenderLoader>
        );
      default:
        return <div></div>;
    }
  };

  const getThenDescription = (condition) => {
    let type = 0;
    if (selectedAutomation && condition.automation_task_id) {
      type = condition.automation_type;
    }
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return 'Send an email';
      case ASSIGN_MEMBER:
        return 'Assign to a team member';
      // case FACEBOOK:
      //   return 'Send a Facebook Message';
      // case INSTAGRAM:
      //   return 'Send a Instagram DM';
      case DELAY_TIMER:
        return 'Add a delay timer';
      case MOVE_TO_BOX:
        return 'Move the enquiry directly to this box';
      case SMS:
        return 'Send sms';
      case NOTES:
        return 'Add a note';
      case TASK:
        return 'Create a task';
      default:
        return 'This action will happen automatically';
    }
  };

  const getAutomationTypeData = (currentData, type) => {
    switch (type) {
      case AUTORESPONDER_EMAIL:
        return {
          ...currentData,
          data: {
            type: 'new',
            send_from: null,
            cc: [],
            bcc: [],
            subject: 'Thank you for your Enquiry',
            message: '',
            attachments: []
          }
        };
      case ASSIGN_MEMBER:
        return {
          ...currentData,
          data: {
            assign_user: null
          }
        };
      case DELAY_TIMER:
        return {
          ...currentData,
          data: {
            delay_kind: 1,
            delay_value: 1
          }
        };
      case MOVE_TO_BOX:
        return {
          ...currentData,
          data: {
            move_to_box: null,
            move_to_stage: null
          }
        };
      case FACEBOOK:
        return {
          ...currentData,
          data: {
            message: ''
          }
        };
      case INSTAGRAM:
        return {
          ...currentData,
          data: {
            message: ''
          }
        };
      case SMS:
        return {
          ...currentData,
          data: {
            message: '',
            sms_count: 1
          }
        };
      case NOTES:
        return {
          ...currentData,
          data: {
            description: '',
            tagged_users: []
          }
        };
      case TASK:
        return {
          ...currentData,
          data: {
            note: '',
            due_in: 1,
            due_days_value: '1',
            assigned_to: '',
            task_type: taskType
          }
        };
      default:
        return 'Select what you would like to do now';
    }
  };

  const onSelectAutomationType = (option) => {
    if (!isEmpty(condition)) {
      if (isDelayTypeSelected) {
        if (option.value !== DELAY_TIMER) {
          dispatch(setIsDelayTypeSelected(false));
        }
      }
    }
    if (option.value !== DELAY_TIMER) {
      const errorObj = { ...automationErrors };
      if (errorObj.last_automation) {
        delete errorObj.last_automation;
        dispatch(setAutomationErrors(errorObj));
      }
    }
    const errorObj = { ...automationErrors };
    if (errorObj[index]?.category) {
      var result = omit(errorObj[index], ['category']);
      if (isEmpty(result)) {
        result = omit(errorObj, [index]);
        dispatch(setAutomationErrors(result));
      } else {
        dispatch(setAutomationErrors({ ...errorObj, [index]: result }));
      }
    }
    if (errorObj[index]) {
      result = omit(errorObj, [index]);
      dispatch(setAutomationErrors(result));
    }
    const currentThen = then[index];
    const automationThen = [...then];
    let updatedData = {
      ...currentThen,
      automation_type: option.value
    };
    if (option.value === DELAY_TIMER) {
      dispatch(setIsDelayTypeSelected(true));
    }
    updatedData = getAutomationTypeData(updatedData, option.value);
    automationThen.splice(index, 1, updatedData);
    dispatch(setThen(automationThen));
  };

  const checkIfCategoryIsSelected = () => {
    return !isEmpty(condition);
  };

  const checkIfCategoryTypeIsMoveToBox = () => {
    if (isEmpty(condition)) return false;
    if (condition.automation_type === MOVE_TO_BOX) return true;
  };

  const checkIsLastThen = (index) => {
    if (isEmpty(condition)) return false;
    return index === then.length - 1;
  };

  const addAnotherThen = () => {
    dispatch(setThen([...then, {}]));
    handleScrollIntoview(`boxThen-${box_id}`, 'end');
  };

  const getErrorsIfAny = () => {
    if (automationErrors[index]?.category)
      return automationErrors[index].category;
    return false;
  };

  const getAutomationTypes = () => {
    if (then[index - 1]?.automation_type === DELAY_TIMER)
      return automationType.filter((type) => type.value !== DELAY_TIMER);
    return automationType;
  };

  if (condition.automation_task_id) {
    return (
      <EditThen
        condition={condition}
        index={index}
        addAnotherThen={addAnotherThen}
      />
    );
  }

  return (
    <div className="relative">
      <div
        className={`box-settings__automation-template__automation-stuff box-settings__automation-card mt-6 px-5 pt-5 pb-6 mb-3 ${
          !checkIfCategoryIsSelected() || checkIfCategoryTypeIsMoveToBox()
            ? 'last'
            : ''
        }`}
      >
        <ThenHeader
          description={getThenDescription(condition)}
          condition={condition}
          index={index}
        />
        <div className="mt-3" id={`selectId-${index}`}>
          <form
            autoComplete="off"
            onClick={() => handleScrollIntoview(`dropDownId-${index}`, 'start')}
          >
            <Select
              id={`dropDownId-${index}`}
              name="automation-then"
              options={getAutomationTypes()}
              isWhenSelect={true}
              onChange={(value) => {
                onSelectAutomationType(value);
              }}
              value={getThenStatement(condition.automation_type)}
              dropDownClassName="p-2"
              inputClassName="input-field--md pl-10"
              labelDividerText={'Select Automation'}
              labelDividerTextClass={'text-grey-700'}
              readOnly
              showSelectIcon
            />
          </form>
          {getErrorsIfAny() && <ErrorMessage message={getErrorsIfAny()} />}
          <div className="mt-3" id={`boxThen-${index}`}>
            {renderThen(condition.automation_type, condition, index)}
          </div>
        </div>
      </div>
      {checkIsLastThen(index) && (
        <>
          {!checkIfCategoryTypeIsMoveToBox() && (
            <div className="flex justify-center ">
              <img
                src={iconAddAutomationRound}
                alt=""
                style={{ width: 22, height: 22, marginLeft: 1, zIndex: 1 }}
                className="cursor-pointer"
                onClick={addAnotherThen}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default BoxAutomationStuffThen;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getHashParams, isEmpty } from 'utils/utils';
import queryString from 'query-string';
// import { SuccessToast } from 'components/toast';
import {
  // createSocialSignUp,
  setAuthToken,
  setError,
  setRedirect,
  setSignUpChoice
} from 'slices/authSlice';
import { useSocialSignMutation } from 'services/authApi';
import { redirectPostCreds } from 'utils/redirect';
import choices from 'choices';
import { LayoutLoader } from 'components/loader';
import HeaderLayout from 'components/layout/headerless';
import {
  INVITATION_LINK,
  INVITED_EMAIL,
  SOCIAL_AUTH_ERROR,
  SUB_ORG_ID,
  USER_INVITATION_LINK
} from 'utils/constants';
import { useAnalytics } from 'use-analytics';
import storageFactory, { getPartnerId } from 'utils/localstorage';

const MICROSOFT = choices.SignupChoice.MICROSOFT;
const BUSINESS_REDIRECT = choices.FrontendRedirectChoice.BUSINESS_INFO;

const ConfirmMicrosoft = () => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [socialSign, { data, isSuccess, isLoading, isError, error }] =
    useSocialSignMutation();
  const { identify, track } = useAnalytics();
  const { id_token: token } = getHashParams(location.hash);
  const invitationLink = localStorage.getItem(USER_INVITATION_LINK);
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  useEffect(() => {
    if (isError) {
      if (error?.data?.description === 'You can try login.') {
        confirmSignIn();
        return;
      }
      if (error?.status === 400) {
        navigate('/login');
      }
      localStorage.setItem(SOCIAL_AUTH_ERROR, JSON.stringify(error.data));
      if (invitationLink) {
        window.location.href = invitationLink;
      }
    }

    if (isSuccess) {
      onSuccess();
    }
  }, [isError, isSuccess]);

  const onSuccess = () => {
    // Invite accepted segment event on Microsoft signup
    const queryParams = queryString.parse(invitationLink);
    identify(
      data.user_id,
      {},
      {
        groupId: subOrganizationId
      }
    );
    const invitedEmail = localStorage.getItem(INVITED_EMAIL);
    if (!isEmpty(invitedEmail)) {
      track(
        'invite_accepted',
        {
          user_type: choices.TeamMemberRoleChoice.CHOICES[queryParams?.role]
        },
        {
          groupId: subOrganizationId
        }
      );
    }
    dispatch(setAuthToken(data?.token));
    dispatch(setRedirect(data?.frontend_redirect_uri));
    // SuccessToast('Successfull');
    navigate(redirectPostCreds(data?.frontend_redirect_uri));
    localStorage.removeItem(INVITED_EMAIL);
  };
  const confirmSignUp = async () => {
    setLoading(false);
    dispatch(setSignUpChoice(MICROSOFT));
    const invitedEmail = localStorage.getItem(INVITED_EMAIL);
    const subOrgId = localStorage.getItem(SUB_ORG_ID);
    const partnerId = storageFactory().getItem(getPartnerId());
    let data = {
      auth_id_token: token,
      which_social_auth: MICROSOFT,
      is_this_signup_request: true
    };
    if (!isEmpty(invitedEmail)) {
      data['invited_email'] = invitedEmail;
      data['sub_organization_id'] = subOrgId;
    }
    if (partnerId) {
      data['partner_id'] = partnerId;
    }
    await socialSign(data);
  };

  const confirmSignIn = async () => {
    dispatch(setSignUpChoice(MICROSOFT));
    let data = {
      auth_id_token: token,
      which_social_auth: MICROSOFT,
      is_this_signup_request: false
    };
    await socialSign(data);
  };

  useEffect(() => {
    if (token) {
      confirmSignUp();
    } else {
      setLoading(false);
    }
  }, []);

  if (loading) return <LayoutLoader />;

  if (!token)
    return (
      <HeaderLayout hideLogo>
        <div className="flex justify-center">
          <p className="error-msg">Error: access permission token not found</p>
        </div>
      </HeaderLayout>
    );

  return <div></div>;
};

export default ConfirmMicrosoft;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import AssignedUser from '../actions/assignUser';
import MoveTo from '../actions/moveTo';
import ActiveBullet from '../actions/activeUserBullet';
import Label from '../actions/label';
import Details from 'modules/enquirybox/ebox/module/box/conversation/contactModal/details';
import { useSelector, useDispatch } from 'react-redux';
import { setShowContactModal } from 'slices/eboxLead';
import PrimaryWithLogo from 'components/buttons/primaryWithLogo';
import { ReactComponent as RestoreIcon } from 'assets/icons/icon-restore-lead.svg';
import CHOICES from 'choices';
import { restoreSingleLead } from 'slices/enquiryBin/slice';
import { useNavigate } from 'react-router-dom';
import { route } from 'utils/route';
import { timeFormatter } from 'utils/utils';
import { useSnackbar } from 'components/Snackbar';
import { setActiveBoxNewLead } from 'slices/boxes/slice';
import { setActiveTab } from 'slices/conversationSlice';
import { CONTACT } from 'utils/constants';
import { getLeadName } from 'modules/enquirybox/helper/lead';
const RESTORED = CHOICES.LeadStatusChoices.RESTORED;

const ConversationHeader = ({ activeLead, isBin, isSms = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { openSuccessSnackBar } = useSnackbar();
  const { showContactModal } = useSelector((state) => state.leads);
  // const [activeTab, setActiveTab] = useState(1);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  const onClick = (status) => {
    dispatch(setShowContactModal(status));
    dispatch(setActiveTab(CONTACT));
  };

  const callback = () => {
    openSuccessSnackBar('Restored successful', 'large');
    setIsBtnLoading(false);
    navigate(`${route.enquiryBin}`);
    dispatch(setActiveBoxNewLead(null));
  };

  const onRestoreLead = () => {
    setIsBtnLoading(true);
    const formData = {
      lead: activeLead?.id,
      status: RESTORED
    };
    dispatch(restoreSingleLead({ body: formData, callback: callback }));
  };

  return (
    <>
      <div
        className={`chat-message-header flex items-center ${
          isBin ? 'justify-between' : ''
        }`}
        style={{ maxHeight: '53px' }}
      >
        <ActiveBullet
          src={activeLead?.customer_avatar}
          label={getLeadName(activeLead)}
          lead={activeLead}
          setIsModalOpen={onClick}
          isModalOpen={showContactModal}
          isSms={isSms}
        />
        {!isBin && (
          <div className="flex items-center gap-2 ml-auto relative">
            <Label />
            <MoveTo />
            <AssignedUser />
          </div>
        )}
        {isBin && (
          <div className="flex items-center">
            <p className="mr-3 text-sm">
              Deleted {timeFormatter(activeLead?.current_status?.updated_at)}{' '}
              ago by{' '}
              <span className="font-semibold">
                {activeLead?.current_status?.done_by_user?.name}
              </span>
            </p>
            <PrimaryWithLogo
              label="Restore to channel"
              renderSvg={() => <RestoreIcon />}
              style={{ padding: '4px 10px' }}
              onClick={onRestoreLead}
              enableLoader
              isLoading={isBtnLoading}
            />
          </div>
        )}
      </div>
      <section
        className={`modal modal--contact ${showContactModal ? 'show' : ''}`}
      >
        <div
          className="modal__container relative"
          style={{
            overflow: 'hidden',
            marginTop: '-5px',
            top: 18,
            width: 'calc(100% - 25px)',
            height: 'calc(100% - 25px)'
          }}
        >
          <Details
            setShowImageModal={() => {}}
            setOpenUpload={() => {}}
            // setActiveTab={setActiveTab}
            // activeTab={activeTab}
            isBin={isBin}
            isSms={isSms}
          />
        </div>
      </section>
    </>
  );
};

export default React.memo(ConversationHeader);

import UpgradePlanTopbar from 'components/UpgradePlanTopbar';
import PropTypes from 'prop-types';

const Wrapper = (props) => {
  const { showUpgradeTopbar, children } = props;
  return (
    <>
      {showUpgradeTopbar && (
        <UpgradePlanTopbar text="Upgrade to calculate this report. Click here to upgrade" />
      )}
      <div
        className="dashboard__main__body__section-right layout-1"
        style={{ padding: '20px 26px', width: '100%' }}
      >
        {children}
      </div>
    </>
  );
};

export default Wrapper;

Wrapper.propTypes = {
  showUpgradeTopbar: PropTypes.bool
};
Wrapper.defaultProps = {
  showUpgradeTopbar: false
};

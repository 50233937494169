/* eslint-disable no-unused-vars */
import Navbar from 'components/navigation/navbar';
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import ChannelHeader from './header';
import ChannelsOptions from './channelList/home';
import ConnectedChannelsList from './connectedList';
import Facebook from 'modules/enquirybox/channels/module/settings/channel/facebook';
import Instagram from 'modules/enquirybox/channels/module/settings/channel/instagram';
import FacebookLeads from 'modules/enquirybox/channels/module/settings/channel/facebookAds';
import Zapier from 'modules/enquirybox/channels/module/settings/channel/zapier';
import Webform from 'modules/enquirybox/channels/module/settings/channel/webform';
import WhatsApp from 'modules/enquirybox/channels/module/settings/channel/whatsApp';
import EnquiryBot from 'modules/enquirybox/channels/module/settings/channel/enquiryBot';
// import SMSContent from 'modules/toolbox/component/smsContent';
import { useDispatch, useSelector } from 'react-redux';
import { getAllBoxes } from 'slices/boxes/slice';
import ConnectedChannelTab from './components/settingsTab';
import CHOICES from 'choices';
import { route } from 'utils/route';
import { isEmpty } from 'utils/utils';
import SMS from 'modules/enquirybox/channels/module/settings/channel/sms';
import EmptyStateChannel from './components/emptyState';
const {
  EBOT,
  SMS: SMS_CHANNEL,
  WEBFORM,
  FACEBOOK,
  FACEBOOK_LEAD_ADS,
  ZAPIER,
  INSTAGRAM,
  WHATSAPP
} = CHOICES.ChannelChoices;

const ChannelsHome = () => {
  const dispatch = useDispatch();
  const { allConnectedChannels } = useSelector((state) => state.channels);
  const { pathname } = useLocation();

  const activeTab = pathname?.split('/');

  useEffect(() => {
    dispatch(getAllBoxes());
  }, []);

  const connectedChannelStyle = () => {
    let style = {};
    if (pathname.includes('connected')) {
      style = {
        background: '#ffffff'
      };
    } else {
      style = {
        overflow: 'auto'
      };
    }
    return style;
  };

  return (
    <>
      <Navbar />
      <section className="dashboard__main toolbox-wrapper">
        <ChannelHeader />
        <div className="dashboard__main__body toolbox-body">
          <ConnectedChannelsList />
          <div
            className="h-full w-full"
            style={connectedChannelStyle()}
            id="channel-settings"
          >
            {activeTab.includes('connected') && (
              <ConnectedChannelTab
                activeTab={
                  activeTab[activeTab.length - 1] === 'settings'
                    ? 1
                    : activeTab[activeTab.length - 1] === 'forms'
                    ? 2
                    : activeTab[activeTab.length - 1] === 'billing'
                    ? 2
                    : 0
                }
                channelType={parseInt(activeTab[3])}
              />
            )}
            <Routes>
              <Route
                path="/"
                element={
                  isEmpty(allConnectedChannels) ? (
                    <ChannelsOptions />
                  ) : (
                    <EmptyStateChannel />
                  )
                }
              />
              <Route path="/connect-channel" element={<ChannelsOptions />} />
              <Route path={`/facebook/${FACEBOOK}/*`} element={<Facebook />} />
              <Route
                path={`/instagram/${INSTAGRAM}/*`}
                element={<Instagram />}
              />
              <Route
                path={`/facebook-leads/${FACEBOOK_LEAD_ADS}/*`}
                element={<FacebookLeads />}
              />
              <Route path={`/zapier/${ZAPIER}/*`} element={<Zapier />} />
              <Route
                path={`/webforms/${WEBFORM}/*`}
                element={<Webform isSettings={true} />}
              />
              <Route path={`/whatsApp/${WHATSAPP}/*`} element={<WhatsApp />} />
              <Route path={`/sms/${SMS_CHANNEL}/*`} element={<SMS />} />
              <Route path={`/ebot/${EBOT}/*`} element={<EnquiryBot />} />
              {/* <Route
                    path="/select-channels/*"
                    element={<SelectChannels onClick={onSelectChannel} />}
                /> */}
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChannelsHome;

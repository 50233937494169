import React from 'react';
import { ReactComponent as IconSms } from 'assets/icons/icon-template-sms-new.svg';
import { ReactComponent as IconFb } from 'assets/icons/icon-template-fb.svg';
import { ReactComponent as IconEmail } from 'assets/icons/icon-template-email.svg';
import CHOICES from 'choices';

const { SMS, EMAIL } = CHOICES.ReplyTemplateChoice;

const TemplateItem = (props) => {
  const { id, selectedReplyTemplate, setSelectedReplyTemplate, name, item } =
    props;
  return (
    <div
      className={`flex px-3 py-2 items-center truncate cursor-pointer bg-color-on-hover rounded-lg mt-1 ${
        selectedReplyTemplate.id === id ? 'bg-grey-90' : ''
      }`}
      onClick={() => setSelectedReplyTemplate(item)}
    >
      <div className="mr-3">
        {item.reply_type === SMS ? (
          <IconSms />
        ) : item.reply_type === EMAIL ? (
          <IconEmail />
        ) : (
          <IconFb />
        )}
      </div>
      <span
        className={`text-sm truncate ${
          selectedReplyTemplate.id === id
            ? 'text-grey-900 font-semibold'
            : 'text-grey-800 font-medium'
        }`}
      >
        {name}
      </span>
    </div>
  );
};

export default TemplateItem;

const { REACT_APP_BASE_URL: BASE_API_URL } = process.env;

export const GET_ALL_BOTS = `${BASE_API_URL}/ai_assistant/template_list`;
export const CREATE_ASSISTANT = `${BASE_API_URL}/ai_assistant/create_custom`;
export const CREATE_ASSISTANT_FROM_TEMPLATE = `${BASE_API_URL}/ai_assistant/create_from_template`;
export const LIST_AI_ASSISTANTS = `${BASE_API_URL}/ai_assistant/:subOrgId/assistants`;
export const GET_WELCOME_MESSAGES = `${BASE_API_URL}/ai_assistant/:assistant_id/welcome`;
export const OPENING_MESSAGE = `${BASE_API_URL}/ai_assistant/welcome/message`;
export const OPENING_OPTIONS = `${BASE_API_URL}/ai_assistant/welcome/option`;
export const GET_ALL_PERSONALITY_FIELDS = `${BASE_API_URL}/ai_assistant/:aiBotId/personality`;
export const UPDATE_PERSONALITY_FIELDS = `${BASE_API_URL}/ai_assistant/:aiBotId/personality`;
export const CREATE_CUSTOM_RULE = `${BASE_API_URL}/ai_assistant/personality/custom_rule`;
export const UPDATE_CUSTOM_RULE = `${BASE_API_URL}/ai_assistant/personality/custom_rule`;
export const DELETE_CUSTOM_RULE = `${BASE_API_URL}/ai_assistant/personality/custom_rule`;
export const GET_ALL_AVATARS = `${BASE_API_URL}/ai_assistant/:subOrgId/avatars`;
export const CREATE_NEW_AVATAR = `${BASE_API_URL}/ai_assistant/add_custom_avatar`;
export const GET_ALL_DATA_COLLECTION_FIELDS = `${BASE_API_URL}/ai_assistant/:aiBotId/data_collection`;
export const UPDATE_DATA_COLLECTION_FIELDS = `${BASE_API_URL}/ai_assistant/:aiBotId/data_collection`;
export const CREATE_FIELD_ITEMS = `${BASE_API_URL}/ai_assistant/data_collection/data_field`;
export const REORDER_DATA_ITEMS = `${BASE_API_URL}/ai_assistant/data_collection/data_field`;
export const UPDATE_DATA_COLLECTION_ITEMS = `${BASE_API_URL}/ai_assistant/data_collection/data_field`;
export const DELETE_DATA_COLLECTION_ITEM = `${BASE_API_URL}/ai_assistant/data_collection/data_field`;

export const LIST_UPLOAD_PRODUCT_OR_SERVICE = `${BASE_API_URL}/ai_chat_engine/:aiBotId/product_or_service/from_file`;
export const CREATE_SINGLE_PRODUCT_OR_SERVICE = `${BASE_API_URL}/ai_chat_engine/:aiBotId/product_or_service`;
export const GET_ALL_PRODUCT_OR_SERVICE = `${BASE_API_URL}/ai_chat_engine/:aiBotId/product_or_service`;
export const SINGLE_PRODUCT_OR_SERVICE = `${BASE_API_URL}/ai_chat_engine/product_or_service/:productId`;

export const POST_NEW_WEBSITE_LINK = `${BASE_API_URL}/ai_chat_engine/:aiBotId/website_link`;
export const GET_WEBSITE_LINKS = `${BASE_API_URL}/ai_chat_engine/:aiBotId/website_link`;
export const DELETE_WEBSITE_LINK = `${BASE_API_URL}/ai_chat_engine/website_link/:websiteLinkId`;

export const BRAIN_CONTENT_COUNT = `${BASE_API_URL}/ai_chat_engine/:aiBotId/brain_content_count`;

export const LIST_UPLOAD_QNA = `${BASE_API_URL}/ai_chat_engine/:aiBotId/qna/from_file`;
export const CREATE_SINGLE_QNA = `${BASE_API_URL}/ai_chat_engine/:aiBotId/qna`;
export const GET_ALL_QNA = `${BASE_API_URL}/ai_chat_engine/:aiBotId/qna`;
export const SINGLE_QNA = `${BASE_API_URL}/ai_chat_engine/qna/:qnaId`;

export const UPLOAD_FILE = `${BASE_API_URL}/ai_chat_engine/:aiBotId/file_content`;
export const GET_FILE_DATA = `${BASE_API_URL}/ai_chat_engine/:aiBotId/file_content`;
export const DELETE_SINGLE_FILE = `${BASE_API_URL}/ai_chat_engine/file_content/:fileId`;

export const CREATE_DUPLICATE_AI_ASSISTANT = `${BASE_API_URL}/ai_assistant/:aiBotId/create_duplicate`;
export const DELETE_AI_ASSISTANT = `${BASE_API_URL}/ai_assistant/:aiBotId/delete_assistant`;

export const GET_BOT_VALUES = `${BASE_API_URL}/ai_assistant/control_bot/:aiBotId`;
export const GET_BOT_SCRIPT = `${BASE_API_URL}/ai_assistant/botscript/:aiBotId`;
export const BOT_ON_PAGES = `${BASE_API_URL}/ai_assistant/bot_access/:aiBotId`;
export const ADD_CUSTOM_AVATAR = `${BASE_API_URL}/ai_assistant/add_custom_avatar`;

export const GA4_API = `${BASE_API_URL}/ai_assistant/track_ga4/:aiBotId`;
export const FB_PIXEL_API = `${BASE_API_URL}/ai_assistant/fbpixel/:aiBotId`;
export const LIST_ASSISTANT_VERSIONS = `${BASE_API_URL}/ai_assistant/:aiBotId/version_list`;
export const SAVE_ASSISTANT = `${BASE_API_URL}/ai_assistant/:version_id/save_changes`;
export const PUBLISH_ASSISTANT = `${BASE_API_URL}/ai_assistant/:version_id/publish_version`;
export const RESTORE_ASSISTANT_VERSION = `${BASE_API_URL}/ai_assistant/restore_version`;

export const AI_CHAT_CONVERSATION = `${BASE_API_URL}/ai_chat_engine/:aiBotId/test_it_out`;
export const ALL_BOT_DATA = `${BASE_API_URL}/ai_assistant/:aiBotId/get_all_data`;

/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import APIRequest from 'apiRequest';
import { replaceUrl } from 'utils/urlReplace';
import {
  GET_ALL_BOX,
  GET_STAGE_LEADS,
  CREATE_UPDATE_BOX_FORM,
  GET_BOX_FORM_DATA,
  GET_BOX_DATA,
  CREATE_MANUAL_ENQUIRY_BOX_FORM,
  ADD_STAGE,
  UPDATE_STAGE,
  MOVE_TO_STAGE_API,
  DELETE_STAGE,
  GET_STAGE_CONTRACT_VALUE,
  DELETE_BOX,
  RENAME_BOX,
  GET_ALL_STAGE_LEAD_COUNT
} from 'urls/boxes';
import {
  LEAD_UPDATE_STATUS,
  UPDATE_BOX_STAGE_POSITION,
  UPDATE_CONTACT_DETAILS
} from 'urls';
import { getIdAndType } from 'modules/boxes/helper';
import { isEmpty } from 'utils/utils';
import { setActiveLead } from 'slices/conversationSlice';

import choices from 'choices';

const {
  REPLY_LATER,
  NEW,
  VIEWED,
  TASK_ADDED,
  REPLY_SENT,
  MANUALLY_ADDED,
  MOVE_TO_BOX,
  NOTE_ADDED,
  RESTORED
} = choices.LeadStatusChoices;

const initialState = {
  currentBoxCardList: 0, // to know the lead is in which array
  activeBoxNewLead: null,
  currentBoxId: null,
  boxes: [],
  stageLeads: {},
  currentBox: {},
  isFiltered: false,
  usersSelected: [],
  channelsSelected: [],
  boxStagesLeadCount: [],
  labelsSelected: [],
  isAppliedFilter: false,
  boxFormData: {},
  activeBoxData: {},
  loadEnquiryInStage: false
};

export const boxesSlice = createSlice({
  name: 'boxes',
  initialState,
  reducers: {
    setCurrentBoxCardList: (state, action) => {
      state.currentBoxCardList = action.payload;
    },
    setCurrentBoxId: (state, action) => {
      state.currentBoxId = action.payload;
    },
    setActiveBoxNewLead: (state, action) => {
      state.activeBoxNewLead = action.payload;
    },
    setBoxes: (state, action) => {
      state.boxes = action.payload;
    },
    setStageLeads: (state, action) => {
      state.stageLeads = action.payload;
    },
    setCurrentBox: (state, action) => {
      state.currentBox = action.payload;
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    setUsersSelected: (state, action) => {
      state.usersSelected = action.payload;
    },
    setChannelsSelected: (state, action) => {
      state.channelsSelected = action.payload;
    },
    setLabelsSelected: (state, action) => {
      state.labelsSelected = action.payload;
    },
    setIsAppliedFilter: (state, action) => {
      state.isAppliedFilter = action.payload;
    },
    setBoxFormData: (state, action) => {
      state.boxFormData = action.payload;
    },
    setActiveBoxData: (state, action) => {
      state.activeBoxData = action.payload;
    },
    setLoadEnquiryInStage: (state, action) => {
      state.loadEnquiryInStage = action.payload;
    },
    setBoxStagesLeadCount: (state, action) => {
      state.boxStagesLeadCount = action.payload;
    }
  }
});

export const {
  setCurrentBoxCardList,
  setBoxes,
  setStageLeads,
  setIsFiltered,
  setUsersSelected,
  setChannelsSelected,
  setLabelsSelected,
  setIsAppliedFilter,
  setBoxFormData,
  setActiveBoxData,
  setCurrentBox,
  setActiveBoxNewLead,
  setCurrentBoxId,
  setBoxStagesLeadCount,
  setLoadEnquiryInStage
} = boxesSlice.actions;

export default boxesSlice.reducer;

// to get all boxes of the organization
export const getAllBoxes = () => {
  return async (dispatch, getState) => {
    const subOrgId = getState().myInfo.subOrganizationId;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_BOX, 'subOrgId', subOrgId))
        .then((res) => {
          if (res.status === 200) {
            dispatch(setBoxes(res.data.results));
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const renameBox = (payload, successCb) => {
  return async (dispatch, getState) => {
    const { data, box_id } = payload;
    const {
      boxes: { boxes }
    } = getState();
    try {
      await new APIRequest()
        .put(replaceUrl(RENAME_BOX, 'boxId', box_id), data)
        .then((res) => {
          if (res.status === 200) {
            const updatedBoxes = boxes.map((box) => {
              if (box.id === res.data.id) {
                return { ...box, ...res.data };
              }
              return box;
            });
            dispatch(setBoxes(updatedBoxes));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const deleteBox = (payload, onSuccessDelete) => {
  return async (dispatch, getState) => {
    const { boxId } = payload;
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_BOX, 'boxId', boxId))
        .then((res) => {
          if (res.status === 200) {
            onSuccessDelete(boxId);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// to get stage leads
export const getStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, query = {} } = payload;
    const assigned_user = getState().boxes.usersSelected;
    const channel = getState().boxes.channelsSelected;
    const labels = getState().boxes.labelsSelected;
    const params = {
      assigned_user: assigned_user,
      channel: channel,
      label: labels
    };
    try {
      return await new APIRequest().get(
        replaceUrl(GET_STAGE_LEADS, 'stageId', stageId),
        {
          ...params,
          ...query
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
};

// this function is called to compose all stage data when it is called multiple times
export const composeStageLeads = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, results } = payload;
    const stageLeads = getState().boxes.stageLeads;
    const obj = new Object();
    obj[stageId] = results;
    await dispatch(
      setStageLeads({
        ...stageLeads,
        ...obj
      })
    );
  };
};

// this function helps to move card locally from one stage to another
export const moveCardToStageLocally = (payload) => {
  return async (dispatch, getState) => {
    const stageLeads = getState().boxes.stageLeads;
    const { src, dest, draggableId } = payload;
    const { key: srcKey, id: srcId } = getIdAndType(src.droppableId);
    const { key: dragKey, id: dragId } = getIdAndType(draggableId);
    const { key: destKey, id: destId } = getIdAndType(dest.droppableId);

    if (destKey === 'EMPTY') {
      dispatch(
        moveCardToEmptyDestination({
          stageLeads,
          destId,
          srcId,
          srcKey,
          dragId
        })
      );
    }
  };
};

const moveCardToEmptyDestination = ({ destId, srcId, srcKey, dragId }) => {
  return async (dispatch, getState) => {
    const stageLeads = getState().boxes.stageLeads;
    let stageDestData = stageLeads[destId];
    let stageSrcData = stageLeads[srcId];

    if (srcKey == 'SEEN') {
      const srcLead = stageSrcData.seen_replied.find(
        (lead) => lead.id === dragId
      );
      const d = {
        ...stageDestData,
        seen_replied: [srcLead, ...stageDestData.seen_replied]
      };
      dispatch(
        setStageLeads({
          ...stageLeads,
          [destId]: d
        })
      );
    }
  };
};

// this function is to hit the api to move the card to that particular stage
export const moveCardToStage = () => {};

// this function is to hit the api for creating box form
export const createBoxForm = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest().post(CREATE_UPDATE_BOX_FORM, body).then((resp) => {
        if (callback) {
          callback();
        }
      });
    } catch (e) {
      console.log(e);
    }
  };
};

const addStageToBoxes = (payload) => {
  return async (dispatch, getState) => {
    const boxes = getState().boxes.boxes;
    const updated_box_stage = boxes.map((box) => {
      if (box.id === payload.box) {
        return {
          ...box,
          boxstage_set: [...box.boxstage_set, payload]
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

const updateStageData = (payload) => {
  return async (dispatch, getState) => {
    const currentBox = getState().boxes.currentBox;
    const boxes = getState().boxes.boxes;
    const updated_current_box_stage = currentBox?.boxstage_set?.map((stage) => {
      if (stage.id === payload.id) {
        return {
          ...stage,
          ...payload
        };
      }
      return stage;
    });
    const updated_box_stage = boxes?.map((box) => {
      if (box.id === payload.box) {
        return {
          ...box,
          boxstage_set: updated_current_box_stage
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

export const createStage = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    try {
      await new APIRequest().post(ADD_STAGE, payload).then((res) => {
        if (res.status === 200) {
          if (successCb) successCb();
          dispatch(addStageToBoxes(res.data));
        }
      });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// this function is to get the box form data from api
export const getBoxFormData = (payload) => {
  return async (dispatch) => {
    try {
      const { boxId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_BOX_FORM_DATA, 'boxId', boxId))
        .then((resp) => {
          if (resp.status === 200) {
            dispatch(setBoxFormData(resp.data));
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// rename the stage
export const renameStage = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { stageId, body } = payload;
    try {
      await new APIRequest()
        .put(replaceUrl(UPDATE_STAGE, 'stageId', stageId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            dispatch(updateStageData(res.data));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

export const getBoxStageLeadCount = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { boxId } = payload;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_ALL_STAGE_LEAD_COUNT, 'boxId', boxId))
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            dispatch(setBoxStagesLeadCount(res.data));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// get stage contract value
export const getStageContractValue = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { stageId } = payload;
    try {
      await new APIRequest()
        .get(replaceUrl(GET_STAGE_CONTRACT_VALUE, 'stageId', stageId))
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb();
            dispatch(updateContractValue({ stageId, data: res.data }));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

const updateContractValue = (payload) => {
  return async (dispatch, getState) => {
    const { stageId, data } = payload;
    try {
      const {
        boxes: { stageLeads }
      } = getState();
      const currentStage = { ...stageLeads[stageId] };
      const updatedData = {
        ...stageLeads,
        [stageId]: {
          ...currentStage,
          contract_value: data.contract_value
        }
      };
      dispatch(setStageLeads(updatedData));
    } catch (e) {
      console.log(e);
    }
  };
};

// mark stage as conversion
export const markAsConversion = (payload, successCb, errorCb) => {
  return async (dispatch) => {
    const { stageId, body } = payload;
    try {
      await new APIRequest()
        .put(replaceUrl(UPDATE_STAGE, 'stageId', stageId), body)
        .then((res) => {
          if (res.status === 200) {
            if (successCb) successCb(res.data);
            dispatch(updateBoxConversionStage(res.data));
          }
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// update box for conversion data
const updateBoxConversionStage = (payload) => {
  return async (dispatch, getState) => {
    const boxes = getState().boxes.boxes;
    const updated_box_stage = boxes?.map((box) => {
      if (box.id === payload.box) {
        const stage_set = box.boxstage_set.map((stage) => {
          if (stage.id === payload.id) {
            return {
              ...stage,
              is_sales_converted_stage: payload.is_sales_converted_stage
            };
          }
          return stage;
        });
        return {
          ...box,
          sales_converted_stage: payload.is_sales_converted_stage
            ? payload.id
            : '',
          boxstage_set: stage_set
        };
      }
      return box;
    });
    dispatch(setBoxes(updated_box_stage));
  };
};

// this function is to update the box form
export const updateBoxFormData = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .put(replaceUrl(GET_BOX_FORM_DATA, 'boxId', body.box), body)
        .then((resp) => {
          dispatch(setBoxFormData(resp.data));
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

const removeStageFromBoxes = (payload) => {
  return async (dispatch, getState) => {
    const { currentBox, boxes } = getState().boxes;
    const updated_current_box_stage = currentBox?.boxstage_set?.filter(
      (stage) => {
        return stage.id !== payload.id;
      }
    );
    const updated_box_stage = boxes?.map((box) => {
      if (box.id === currentBox.id) {
        if (payload.id === box.sales_converted_stage) {
          return {
            ...box,
            boxstage_set: updated_current_box_stage,
            sales_converted_stage: ''
          };
        }
        return {
          ...box,
          boxstage_set: updated_current_box_stage
        };
      }
      return box;
    });
    if (payload.targetStageId) {
      dispatch(getStageLeads({ stageId: payload.targetStageId })).then(
        (res) => {
          dispatch(
            composeStageLeads({
              stageId: payload.targetStageId,
              results: res.data
            })
          );
        }
      );
    }
    dispatch(setBoxes(updated_box_stage));
  };
};

//Function for delete single stage
export const deleteStage = (payload, onSuccess) => {
  return async (dispatch) => {
    const { stageId, data } = payload;
    try {
      await new APIRequest()
        .delete(replaceUrl(DELETE_STAGE, 'stageId', stageId), data)
        .then((res) => {
          // if (res?.status === 200) {
          //   dispatch(setCurrentBox(res?.data?.box));
          // }
          if (data.move_to_stage) {
            dispatch(
              removeStageFromBoxes({
                id: stageId,
                targetStageId: data.move_to_stage
              })
            );
          } else {
            dispatch(
              removeStageFromBoxes({
                id: stageId,
                targetStageId: null
              })
            );
          }
          if (onSuccess) onSuccess(res?.data);
        });
    } catch (e) {
      if (errorCb) errorCb(e);
      console.log(e);
    }
  };
};

// this function is to get the individual box data using boxId
export const getBoxData = (payload) => {
  return async (dispatch) => {
    try {
      const { boxId, callback } = payload;
      new APIRequest()
        .get(replaceUrl(GET_BOX_DATA, 'boxId', boxId))
        .then((resp) => {
          dispatch(setActiveBoxData(resp.data));
          if (callback) {
            callback(resp.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
export const changeBoxLeadStatus = (payload, successCb) => {
  return async (dispatch) => {
    try {
      const { body, stageId } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(getStageLeads({ stageId })).then((res) => {
              dispatch(composeStageLeads({ stageId, results: res.data }));
            });
            if (successCb) successCb();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const getAndComposeStageLeads = ({ stageId }) => {
  return async (dispatch) => {
    dispatch(getStageLeads({ stageId })).then((res) => {
      dispatch(composeStageLeads({ stageId, results: res.data }));
    });
  };
};
// this function is to create manual enquiry from box form
export const createManualEnquiryFromBoxForm = (payload) => {
  return async (dispatch) => {
    try {
      const { body, callback } = payload;
      new APIRequest()
        .post(CREATE_MANUAL_ENQUIRY_BOX_FORM, body)
        .then((resp) => {
          if (callback) {
            callback();
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};
// when clicked on new leads change its status to viewed
export const updateBoxUnreadLeadStatus = (payload, successCb) => {
  return async (dispatch, getState) => {
    try {
      const { body, stageId } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(updateUnreadLeads(res.data, stageId));
            if (successCb) {
              successCb(res.data);
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// update the un read leads of box
const updateUnreadLeads = (data, stageId) => {
  return async (dispatch, getState) => {
    const {
      boxes: { stageLeads }
    } = getState();
    const Leads = stageLeads[stageId];
    // Find item index using _.findIndex
    const results = _.cloneDeep(Leads?.new_unread);
    let index = _.findIndex(results, { id: data.id });
    // Replace item at index using native splice
    results.splice(index, 1, data);
    const updatedData = {
      ...stageLeads,
      [stageId]: {
        ...Leads,
        new_unread: results
      }
    };
    dispatch(setStageLeads(updatedData));
  };
};

// when clicked on new card and then clicked on another card then
// move the previous viewed card to the viewed section
export const onUnreadLeadFocused = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id, stageId } = payload;
      const {
        leads: { unReadLeads, totalLeadCount },
        myInfo
      } = getState();
      dispatch(getStageLeads({ stageId })).then((res) => {
        dispatch(composeStageLeads({ stageId, results: res.data }));
      });
      // dispatch(getUnReadLeads({ subOrgId: myInfo.subOrganizationId }));
      // dispatch(getReadLeads({ subOrgId: myInfo.subOrganizationId }));
      // dispatch(getReplyLaterLeads({ subOrgId: myInfo.subOrganizationId }));
    } catch (e) {
      console.log(e);
    }
  };
};

// when user clicks another card after clicking the new status card
// move the card from new to seen
export const onNewBoxCardPostClick = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { id, stageId } = payload;
      const {
        boxes: { activeBoxNewLead, stageLeads }
      } = getState();
      if (!isEmpty(activeBoxNewLead)) {
        const leadsSection = stageLeads[stageId];
        const previousActiveLead = leadsSection?.new_unread.find(
          (lead) => lead.id === activeBoxNewLead
        );
        const unreadLeadList = leadsSection?.new_unread.filter(
          (lead) => activeBoxNewLead !== lead.id
        );
        const seenLeads = leadsSection?.seen_replied;
        const updatedData = {
          ...stageLeads,
          [stageId]: {
            ...leadsSection,
            new_unread: unreadLeadList,
            seen_replied: previousActiveLead
              ? [previousActiveLead, ...seenLeads]
              : seenLeads
          }
        };
        dispatch(setStageLeads(updatedData));
        dispatch(setActiveBoxNewLead(id));
      } else {
        dispatch(setActiveBoxNewLead(id));
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// move lead from one stage to another through helper functionality nudge
export const moveLeadtoAnotherStage = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const { body, currentStageId, targetStageId, lead } = payload;
      await new APIRequest()
        .post(replaceUrl(MOVE_TO_STAGE_API), body)
        .then((res) => {
          if (res.status === 200) {
            dispatch(
              moveLeadToStage(
                { currentStageId, targetStageId, lead },
                successCb,
                errorCb
              )
            );
          }
        });
    } catch (e) {
      errorCb();
      console.log(e);
    }
  };
};

export const moveLeadToStage = (
  { currentStageId, targetStageId, lead },
  successCb,
  errorCb
) => {
  return async (dispatch, getState) => {
    try {
      const {
        boxes: { stageLeads }
      } = getState();
      const currentStage = stageLeads[currentStageId];
      const targetStage = stageLeads[targetStageId];
      let updated_target_stage = {};
      let final_box_data = {};
      const updated_current_stage = {
        ...currentStage,
        count: currentStage.count - 1,
        seen_replied: currentStage.seen_replied.filter((o) => o.id !== lead.id)
      };
      final_box_data = {
        ...stageLeads,
        [currentStageId]: updated_current_stage
      };
      // if coming directly to lead detail will not have other stages
      if (targetStage) {
        updated_target_stage = {
          ...targetStage,
          count: targetStage.count + 1,
          seen_replied: [lead, ...targetStage.seen_replied]
        };
        final_box_data = {
          ...final_box_data,
          [targetStageId]: updated_target_stage
        };
      }
      dispatch(setStageLeads(final_box_data));
      dispatch(getStageContractValue({ stageId: currentStageId }));
      dispatch(getStageContractValue({ stageId: targetStageId }));
      if (successCb) {
        successCb();
      }
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const updateContactDetailsInBox = (payload) => {
  return async (dispatch, getState) => {
    try {
      const {
        leadConversation: { activeLead }
      } = getState();
      const { body, leadId, callback } = payload;
      new APIRequest()
        .put(replaceUrl(UPDATE_CONTACT_DETAILS, 'leadId', leadId), body)
        .then((resp) => {
          if (resp.status === 201) {
            dispatch(setActiveLead({ ...activeLead, ...resp.data }));
            dispatch(updateContactinLeadsList());
            if (callback) {
              callback();
            }
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

// remove lead from the list
export const removeLeadFromList = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { stageId, cardId } = payload;
      const {
        boxes: { stageLeads, currentBoxCardList }
      } = getState();
      const currentStage = stageLeads[stageId];
      if (currentBoxCardList == NEW) {
        const updatedNewLeadsList = currentStage?.new_unread?.filter(
          (lead) => lead.id !== cardId
        );
        if (updatedNewLeadsList) {
          const updated_current_stage = {
            ...currentStage,
            count: currentStage.count - 1,
            new_unread: updatedNewLeadsList
          };
          const final_box_data = {
            ...stageLeads,
            [stageId]: updated_current_stage
          };
          dispatch(setStageLeads(final_box_data));
        }
      }
      if (currentBoxCardList == REPLY_LATER) {
        const updatedNewLeadsList = currentStage?.reply_later?.filter(
          (lead) => lead.id !== cardId
        );
        if (updatedNewLeadsList) {
          const updated_current_stage = {
            ...currentStage,
            count: currentStage.count - 1,
            reply_later: updatedNewLeadsList
          };
          const final_box_data = {
            ...stageLeads,
            [stageId]: updated_current_stage
          };
          dispatch(setStageLeads(final_box_data));
        }
      }
      if (currentBoxCardList == VIEWED) {
        const updatedNewLeadsList = currentStage?.seen_replied?.filter(
          (lead) => lead.id !== cardId
        );
        if (updatedNewLeadsList) {
          const updated_current_stage = {
            ...currentStage,
            count: currentStage.count - 1,
            seen_replied: updatedNewLeadsList
          };
          const final_box_data = {
            ...stageLeads,
            [stageId]: updated_current_stage
          };
          dispatch(setStageLeads(final_box_data));
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// when contact is updated this function updates the lead in the list
export const updateContactinLeadsList = () => {
  return async (dispatch, getState) => {
    try {
      const {
        boxes: { stageLeads, currentBoxCardList },
        leadConversation: { activeLead }
      } = getState();
      const stageId = activeLead.box_stage;
      if (stageId) {
        const leadsList = stageLeads[stageId];
        if (currentBoxCardList == REPLY_LATER) {
          if (leadsList.reply_later) {
            const leadIndex = leadsList.reply_later?.findIndex(
              (lead) => lead.id === activeLead.id
            );
            const leads = [...leadsList.reply_later];
            leads.splice(leadIndex, 1, activeLead);
            const updatedList = {
              ...stageLeads,
              [stageId]: {
                ...leadsList,
                reply_later: leads
              }
            };
            dispatch(setStageLeads(updatedList));
          }
        }
        if (currentBoxCardList == NEW) {
          if (leadsList.new_unread) {
            const leadIndex = leadsList.new_unread?.findIndex(
              (lead) => lead.id === activeLead.id
            );
            const leads = [...leadsList.new_unread];
            leads.splice(leadIndex, 1, activeLead);
            const updatedList = {
              ...stageLeads,
              [stageId]: {
                ...leadsList,
                new_unread: leads
              }
            };
            dispatch(setStageLeads(updatedList));
          }
        }
        if (currentBoxCardList == VIEWED) {
          if (leadsList.seen_replied) {
            const leadIndex = leadsList.seen_replied?.findIndex(
              (lead) => lead.id === activeLead.id
            );
            const leads = [...leadsList.seen_replied];
            leads.splice(leadIndex, 1, activeLead);
            const updatedList = {
              ...stageLeads,
              [stageId]: {
                ...leadsList,
                seen_replied: leads
              }
            };
            dispatch(setStageLeads(updatedList));
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
};

// Update the status of a lead
export const updateLeadStatus = (payload, successCb) => {
  return async () => {
    try {
      const { body } = payload;
      await new APIRequest()
        .post(replaceUrl(LEAD_UPDATE_STATUS), body)
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb(res.data);
          }
        });
    } catch (e) {
      console.log(e);
    }
  };
};

export const actionsAfterLeadStatusChange = (payload) => {
  return async (dispatch, getState) => {
    try {
      const { lead, source, destination } = payload;
      const tempLead = JSON.parse(JSON.stringify(lead));
      tempLead.status = destination.status;

      const { id: leadId } = lead;
      const {
        boxes: { stageLeads }
      } = getState();
      const updatedData = JSON.parse(JSON.stringify(stageLeads));
      const sourceStage = JSON.parse(JSON.stringify(stageLeads[source.stage]));
      const destinationStage = JSON.parse(
        JSON.stringify(stageLeads[destination.stage])
      );
      const map = {
        [REPLY_LATER]: 'reply_later',
        [NEW]: 'new_unread',
        [RESTORED]: 'new_unread',
        [VIEWED]: 'seen_replied',
        [TASK_ADDED]: 'seen_replied',
        [REPLY_SENT]: 'seen_replied',
        [MANUALLY_ADDED]: 'seen_replied',
        [MOVE_TO_BOX]: 'seen_replied',
        [NOTE_ADDED]: 'seen_replied'
      };
      if (source.stage !== destination.stage) {
        if (Array.isArray(sourceStage[map[source.status]])) {
          const temp = sourceStage[map[source.status]].filter(
            (item) => item.id !== leadId
          );
          sourceStage[map[source.status]] = temp;
        }
        if (Array.isArray(destinationStage[map[source.status]])) {
          const temp = [tempLead, ...destinationStage[map[source.status]]];
          destinationStage[map[source.status]] = temp;
        } else {
          destinationStage[map[source.status]] = [tempLead];
        }
        sourceStage.count -= 1;
        destinationStage.count += 1;
        updatedData[source.stage] = sourceStage;
        updatedData[destination.stage] = destinationStage;
      } else if (source.stage === destination.stage) {
        if (Array.isArray(sourceStage[map[source.status]])) {
          const temp = sourceStage[map[source.status]].filter(
            (item) => item.id !== leadId
          );
          sourceStage[map[source.status]] = temp;
        }
        if (Array.isArray(sourceStage[map[destination.status]])) {
          sourceStage[map[destination.status]] = [
            tempLead,
            ...sourceStage[map[destination.status]]
          ];
        } else {
          sourceStage[map[destination.status]] = [tempLead];
        }
        updatedData[source.stage] = sourceStage;
      }
      dispatch(setStageLeads(updatedData));
    } catch (e) {
      console.log(e);
    }
  };
};

// move lead from one stage to another through helper functionality nudge
export const moveCardtoAnotherStage = (payload, successCb, errorCb) => {
  return async (dispatch, getState) => {
    try {
      const { body, currentStageId, targetStageId, lead } = payload;
      await new APIRequest()
        .post(replaceUrl(MOVE_TO_STAGE_API), body)
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb();
          }
        });
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const updatePositionOfStage = (
  payload,
  successCb,
  errorCb,
  tempOptionsData
) => {
  return async (dispatch, getState) => {
    try {
      const { currentStageId, position } = payload;
      await new APIRequest()
        .post(
          replaceUrl(UPDATE_BOX_STAGE_POSITION, 'stageId', currentStageId),
          { position: position }
        )
        .then((res) => {
          if (res.status === 200 && successCb) {
            successCb(tempOptionsData);
            // dispatch(getAllBoxes())
          }
        });
    } catch (e) {
      if (errorCb) errorCb();
      console.log(e);
    }
  };
};

export const handleMoveLead = (data, callback) => {
  return async (dispatch, getState) => {
    try {
      const { stageLeads } = getState().boxes;
      const { source, destination, draggableId } = data;
      const [cardId] = draggableId.split('_');
      const sourceStageId = source.droppableId;
      const destinationStageId = destination.droppableId;

      const destinationNewLeads = stageLeads[destinationStageId]?.new_unread;
      const destinationReplyLaterLeads =
        stageLeads[destinationStageId]?.reply_later;
      const sourceNewLeads = stageLeads[sourceStageId]?.new_unread;
      const sourceSeenReplied = stageLeads[sourceStageId]?.seen_replied;
      const sourceReplyLaterLeads = stageLeads[sourceStageId]?.reply_later;
      let activeLead;
      if (Array.isArray(sourceSeenReplied)) {
        activeLead = sourceSeenReplied.find((item) => item.id === cardId);
      }
      if (!activeLead && Array.isArray(sourceReplyLaterLeads)) {
        activeLead = sourceReplyLaterLeads.find((item) => item.id === cardId);
      }
      if (!activeLead && Array.isArray(sourceNewLeads)) {
        activeLead = sourceNewLeads.find((item) => item.id === cardId);
      }
      const currentStatus = activeLead.status;
      /**
       * Only change the status of the lead if the source and destination are same
       */
      if (source.droppableId === destination.droppableId) {
        let destinationStatus;
        switch (currentStatus) {
          case NEW:
            {
              let newStartIndex = 0,
                newEndIndex = 0;
              if (
                Array.isArray(destinationReplyLaterLeads) &&
                destinationReplyLaterLeads.length > 0
              ) {
                newStartIndex = destinationReplyLaterLeads.length - 1;
              }
              newEndIndex = destinationNewLeads.length - 1;
              newEndIndex = newStartIndex + newEndIndex;
              if (
                destination.index >= newStartIndex &&
                destination.index <= newEndIndex
              ) {
                if (callback) {
                  callback();
                }
                return;
              }
              if (
                Array.isArray(destinationReplyLaterLeads) &&
                destinationReplyLaterLeads.length > 0 &&
                destination.index <= destinationReplyLaterLeads.length - 1
              ) {
                destinationStatus = REPLY_LATER;
              } else {
                destinationStatus = VIEWED;
              }
            }
            break;
          case RESTORED:
            {
              let newStartIndex = 0,
                newEndIndex = 0;
              if (
                Array.isArray(destinationReplyLaterLeads) &&
                destinationReplyLaterLeads.length > 0
              ) {
                newStartIndex = destinationReplyLaterLeads.length - 1;
              }
              newEndIndex = destinationNewLeads.length - 1;
              newEndIndex = newStartIndex + newEndIndex;
              if (
                destination.index >= newStartIndex &&
                destination.index <= newEndIndex
              ) {
                if (callback) {
                  callback();
                }
                return;
              }
              if (
                Array.isArray(destinationReplyLaterLeads) &&
                destinationReplyLaterLeads.length > 0 &&
                destination.index <= destinationReplyLaterLeads.length - 1
              ) {
                destinationStatus = REPLY_LATER;
              } else {
                destinationStatus = VIEWED;
              }
            }
            break;
          case REPLY_LATER:
            {
              let replyLaterLeadCount = Array.isArray(
                destinationReplyLaterLeads
              )
                ? destinationReplyLaterLeads.length
                : 0;
              replyLaterLeadCount =
                replyLaterLeadCount <= 1
                  ? replyLaterLeadCount
                  : replyLaterLeadCount - 1;
              if (destination.index <= replyLaterLeadCount - 1) {
                if (callback) {
                  callback();
                }
                return;
              }
              destinationStatus = VIEWED;
            }
            break;
          case VIEWED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case MANUALLY_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case TASK_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case REPLY_SENT:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case MOVE_TO_BOX:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
          case NOTE_ADDED:
            if (
              Array.isArray(destinationReplyLaterLeads) &&
              destinationReplyLaterLeads.length > 0 &&
              destination.index <= destinationReplyLaterLeads.length - 1
            ) {
              destinationStatus = REPLY_LATER;
            } else {
              if (callback) {
                callback();
              }
              return;
            }
            break;
        }
        const data = {
          stageId: destinationStageId,
          body: {
            status: destinationStatus,
            lead: cardId,
            is_current: true
          }
        };
        await dispatch(
          updateLeadStatus(data, () => {
            if (callback) {
              callback();
            }
            dispatch(
              actionsAfterLeadStatusChange({
                lead: activeLead,
                source: {
                  stage: sourceStageId,
                  status: currentStatus
                },
                destination: {
                  stage: destinationStageId,
                  status: destinationStatus
                }
              })
            );
          })
        );
      } else {
        const payload = {
          box_stage: destinationStageId,
          lead: cardId,
          is_current: true
        };
        dispatch(
          moveCardtoAnotherStage(
            {
              body: payload,
              currentStageId: sourceStageId,
              targetStageId: destinationStageId,
              lead: activeLead
            },
            () => {
              if (callback) {
                callback();
              }
              dispatch(
                actionsAfterLeadStatusChange({
                  lead: activeLead,
                  source: {
                    stage: sourceStageId,
                    status: currentStatus
                  },
                  destination: {
                    stage: destinationStageId,
                    status: currentStatus
                  }
                })
              );
              dispatch(getStageContractValue({ stageId: sourceStageId }));
              dispatch(getStageContractValue({ stageId: destinationStageId }));
            }
          )
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
};

import choices from 'choices';

const { EUR, GBP, USD, AUD, CHOICES } = choices.CurrencyChoice;

export const getCurrencyText = (currency) => {
  switch (currency) {
    case GBP:
      return '£';
    case EUR:
      return '€';
    case USD:
      return '$';
    case AUD:
      return 'AU$';
    case 'AED':
      return 'د.إ';
    case CHOICES[GBP]:
      return '£';
    case CHOICES[EUR]:
      return '€';
    case CHOICES[USD]:
      return '$';
    case CHOICES[AUD]:
      return 'AU$';
    default:
      return '£';
  }
};

import React from 'react';
import StageOptions from './options';
import StageName from './stageName';
import { numberFormat } from 'utils/number';
import { ReactComponent as AutomateIcon } from 'assets/icons/automation-active.svg';
import { ReactComponent as AutomateIconGrey } from 'assets/icons/automation-inactive.svg';
import CustomToolTip from 'components/CustomTooltip';
import { useNavigate, useParams } from 'react-router-dom';

const RenderTopBlock = ({
  isEdit,
  isDelete,
  isRename,
  setSelectedOption,
  onSubmit,
  onStageNameClose,
  name,
  isConversaionStage,
  stageId,
  onMarkAsConversation,
  setShowConversionAlert,
  isIncoming,
  isPreview,
  count,
  stage
}) => {
  const navigate = useNavigate();
  const { box_type, box_id } = useParams();

  if (isEdit) {
    return (
      <StageName onSubmit={onSubmit} onClose={onStageNameClose} name={name} />
    );
  }

  const onClick = () => {
    navigate(
      `/dashboard/boxes/${box_type}/${box_id}/settings/automation-stuff`
    );
  };

  return (
    <>
      <div
        className="font-semibold text-lg truncate cursor-default"
        title={name}
      >
        {name}
      </div>
      <div className="flex items-center">
        {!isPreview && (
          <StageOptions
            name={name}
            isDelete={isDelete}
            isRename={isRename}
            onOptionSelect={setSelectedOption}
            isIncoming={isIncoming}
            stageId={stageId}
            isConversaionStage={isConversaionStage}
            onMarkAsConversation={onMarkAsConversation}
            setShowConversionAlert={setShowConversionAlert}
          />
        )}
        {!isPreview && (
          <CustomToolTip
            tooltipText={
              stage?.has_automation
                ? `${stage?.no_of_active_automation_count} live automation click to view`
                : 'Click to setup automations'
            }
            position="bottom"
            tooltipStyle={{ maxWidth: 132, padding: 6 }}
          >
            {stage?.has_automation ? (
              <AutomateIcon
                onClick={onClick}
                style={{ marginRight: 6, marginLeft: 6 }}
                className="cursor-pointer"
              />
            ) : (
              <AutomateIconGrey
                style={{ marginRight: 6, marginLeft: 6 }}
                onClick={onClick}
                className="cursor-pointer"
              />
            )}
          </CustomToolTip>
        )}
        <div>
          <div
            className="stage-value text-sm flex justify-center items-center text-white ml-2 font-semibold bg-secondary"
            style={{
              minWidth: '32px',
              width: 'auto',
              height: '22px',
              borderRadius: '20px',
              paddingLeft: '7px',
              paddingRight: '7px'
            }}
          >
            {numberFormat(count, 2)}
          </div>
        </div>
      </div>
    </>
  );
};

export default RenderTopBlock;

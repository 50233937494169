import React, { useState, useRef, useEffect } from 'react';
import { ReactComponent as CloseIcon } from 'assets/icons/icon-close-black.svg';
import { isEmpty } from 'utils/utils';

const InlineEmail = ({
  title,
  placeholder,
  list,
  setList,
  hideFlex = false
}) => {
  const [addMore, setAddMore] = useState(false);
  const [email, setEmail] = useState('');
  const inputRef = useRef();

  const handleEmailChange = (e) => setEmail(e.target.value);

  useEffect(() => {
    if (addMore) {
      if (inputRef) {
        inputRef.current.focus();
      }
    }
  }, [addMore]);

  function onInlineSubmit(e) {
    e.stopPropagation();
    e.preventDefault();
    if (email) {
      setList([...list, email]);
    }
    setEmail('');
  }

  function onDelete(value) {
    const x = list.filter((item) => item !== value);
    setList(x);
  }

  return (
    <div className="info-block w-full items-center">
      <div className="label mr-3">{title}</div>
      <div className={`${hideFlex ? 'width-fill-available' : 'flex'}`}>
        {isEmpty(list) && (
          <form onSubmit={onInlineSubmit}>
            <input
              type="text"
              className="email-text"
              placeholder={placeholder}
              value={email}
              onChange={handleEmailChange}
              onBlur={(e) => {
                setAddMore(false);
                onInlineSubmit(e);
              }}
              ref={inputRef}
            />
          </form>
        )}
        {!isEmpty(list) && (
          <div className="email-pill-wrapper">
            {list.map((email) => (
              <div key={email} className="email-pill">
                <span>{email}</span>
                <CloseIcon
                  className="ml-2 cursor-pointer"
                  onClick={() => onDelete(email)}
                />
              </div>
            ))}
            {!addMore && (
              <div className="add-more" onClick={() => setAddMore(true)}>
                <span>Add more</span>
              </div>
            )}
            {addMore && (
              <form onSubmit={onInlineSubmit}>
                <input
                  type="text"
                  className="email-text"
                  placeholder="enter email"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={(e) => {
                    onInlineSubmit(e);
                    setAddMore(false);
                  }}
                  ref={inputRef}
                />
              </form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InlineEmail;

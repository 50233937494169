import CancelMyAccount from 'modules/modals/CancelMyAccount';
import { useSelector, useDispatch } from 'react-redux';
import {
  setShowSubscriptionModal,
  // setShowEnquiriesExpiredModal,
  setShowCancelAccountModal,
  setShowDeleteAccountModal,
  setPostExpiryActiveModal
} from 'slices/subscription/slice';
import { useAnalytics } from 'use-analytics';
import { CANCEL_ACCOUNT } from 'utils/constants';

const CancelAccount = () => {
  const dispatch = useDispatch();
  const { track } = useAnalytics();
  const { showCancelAccountModal, currentPlan } = useSelector(
    (state) => state.subscription
  );
  const { subOrganizationId } = useSelector((state) => state.myInfo);

  const onCancel = () => {
    track(
      'account_cancellation',
      {
        plan_name: currentPlan?.plan?.plan_name,
        price: currentPlan?.amount
      },
      {
        groupId: subOrganizationId
      }
    );
    dispatch(setShowCancelAccountModal(false));
    dispatch(setShowDeleteAccountModal(true));
  };

  const onShowPlan = () => {
    dispatch(setShowSubscriptionModal(true));
    dispatch(setShowCancelAccountModal(false));
    dispatch(setPostExpiryActiveModal(CANCEL_ACCOUNT));
  };

  return (
    <CancelMyAccount
      open={showCancelAccountModal}
      onCancel={onCancel}
      onShowPlan={onShowPlan}
    />
  );
};

export default CancelAccount;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import queryString from 'query-string';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { connectEbotToEbox } from 'slices/confirmEbotToEbox/slice';
import { route } from 'utils/route';
import { BOT_ID, EBOT_EMAIL } from 'utils/constants';
import { getAllConnectedChannels } from 'slices/channelsSlice';
import OverLayLoader from 'components/loader/overLayLoader';
import vector from 'assets/images/Vector.png';
import CHOICES from 'choices';

const ConfirmEbotToEboxConnection = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const { subOrganizationId } = useSelector((state) => state.myInfo);
  const queryParams = queryString.parse(location?.search);
  const [isLoading, setIsLoading] = useState(true);
  const [res, setRes] = useState({});

  const { EBOT } = CHOICES.ChannelChoices;

  const callback = (resp) => {
    if (resp?.response?.data?.error) {
      navigate(`${route.dashboard}`);
      return;
    }
    if (resp) {
      setRes(resp);
      dispatch(getAllConnectedChannels({ subOrgId: subOrganizationId }));
      setIsLoading(false);
      navigate(
        `${route.channels}/ebot/${EBOT}/connected/${resp?.data?.connected_channel_id}/settings`
      );
    }
  };

  const loadConnectEbotToEbox = () => {
    localStorage.removeItem(EBOT_EMAIL);
    localStorage.removeItem(BOT_ID);
    let payload = {
      email: queryParams?.email,
      bot_id: queryParams?.bot_id
    };
    dispatch(connectEbotToEbox(payload, callback));
  };

  useEffect(() => {
    loadConnectEbotToEbox();
    return () => {
      setIsLoading(false);
      setRes({});
    };
  }, []);

  if (isLoading) {
    return (
      <div className="authentication-module">
        <img src={vector} alt="vector" className="img-vector" />
        <div className="flex justify-center w-full">
          <div className="mt-10">
            <p className="text-lg">
              Please wait, we are processing the bot connection.
            </p>
          </div>
        </div>
        <OverLayLoader />
      </div>
    );
  }

  return (
    <div className="authentication-module">
      <img src={vector} alt="vector" className="img-vector" />
      <div className="flex justify-center w-full mt-10">
        <div>
          <p className="mr-1">You will be redirected shortly </p>
          <p>
            if not redirected,{' '}
            <NavLink
              className="text-blue-900"
              to={`${route.dashboard}${route.enquirybox}${route.settings}/ebot/connected/${res?.data?.connected_channel_id}`}
            >
              click here
            </NavLink>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEbotToEboxConnection;

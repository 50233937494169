import Navbar from 'components/navigation/navbar';
import React, { useEffect, useState } from 'react';
import GolobalSettingsHeader from './components/golobalSettingsHeader';
import TeamChatSettingsMenu from 'modules/teamChat/module/settings/modules/menu';
import { route } from 'utils/route';
import {
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation
} from 'react-router-dom';
import AnimationWrapper from 'animation/wrapper';
import MyProfile from 'modules/teamChat/module/settings/modules/myprofile';
import EmailAddress from 'modules/teamChat/module/settings/modules/emailAddress';
import EmailSignature from 'modules/teamChat/module/settings/modules/emailSignature';
import Nudges from 'modules/teamChat/module/settings/modules/nudges';
import ThingsToDoSetting from 'modules/teamChat/module/settings/modules/thingsToDo';
import Notifications from 'modules/teamChat/module/settings/modules/notifications';
import AccountSettingsMenu from 'modules/settings/module/menu';
import Wrapper from 'modules/settings/wrapper';
import LabelSettings from 'modules/settings/module/labels';
import QuestionSettings from 'modules/settings/module/questions';
import FileLibrary from 'modules/settings/module/fileLibrary';
import TeamMember from 'modules/settings/module/teamMember';
import ReplyTemplate from 'modules/settings/module/replyTemplate';
import TasksSettings from 'modules/settings/module/tasks';
import BusinesInfoSettings from 'modules/settings/module/businessInfo';
import usePlanAccess from 'hooks/usePlanAccess';

const HomeNewSettings = () => {
  const [activeTab, setActiveTab] = useState(1);
  const mainRoute = `${route.settings}`;
  const { has_file_library } = usePlanAccess();
  const location = useLocation();

  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname.includes('account-settings')) setActiveTab(1);
    else if (location.pathname.includes('my-settings')) setActiveTab(2);
  }, [location]);

  return (
    <>
      <Navbar />
      <section className="dashboard__main ">
        <GolobalSettingsHeader />

        <div className="dashboard__main__body ">
          <div
            className="dashboard__main__body__section-left  lg"
            style={{ padding: 0 }}
          >
            <div className="flex dashboard__main__body__section-left__header">
              <div className="tabs-wrapper">
                <div
                  className={`tabs-header ${
                    activeTab === 1 ? 'tabs-header__active' : ''
                  }`}
                  style={{ paddingBottom: 13, paddingTop: 5, fontSize: 14 }}
                  onClick={() => {
                    setActiveTab(1);
                    navigate(`${route.settings}/account-settings/team-member`);
                  }}
                >
                  <p
                    className={
                      activeTab === 1
                        ? 'text-primary'
                        : 'text-grey-900 font-semibold'
                    }
                  >
                    Account settings
                  </p>
                </div>
                <div
                  className={`tabs-header ${
                    activeTab === 2 ? 'tabs-header__active' : ''
                  }`}
                  style={{ paddingBottom: 13, paddingTop: 5, fontSize: 14 }}
                  onClick={() => {
                    setActiveTab(2);
                    navigate(`${route.settings}/my-settings/my-profile`);
                  }}
                >
                  <p
                    className={
                      activeTab === 2
                        ? 'text-primary'
                        : 'text-grey-900 font-semibold'
                    }
                  >
                    My settings
                  </p>
                </div>
              </div>
            </div>
            <div
              className="p-6 overflow-overlay"
              style={{ height: 'calc(100vh - 140px)' }}
            >
              {activeTab === 2 && (
                <TeamChatSettingsMenu mainRoute={mainRoute} />
              )}
              {activeTab === 1 && <AccountSettingsMenu mainRoute={mainRoute} />}
            </div>
          </div>
          <div
            // className={
            //   activeTab === 2
            //     ? 'dashboard__main__body__section-right layout-1'
            //     : ''
            // }
            style={{ backgroundColor: 'white', width: '100%' }}
          >
            <Routes>
              <Route element={<AnimationWrapper />}>
                <Route
                  path="/my-settings/my-profile/*"
                  element={
                    <Wrapper>
                      <MyProfile />
                    </Wrapper>
                  }
                />
                <Route
                  path="/my-settings/email-reply-address/*"
                  element={
                    <Wrapper>
                      <EmailAddress />
                    </Wrapper>
                  }
                />
                <Route
                  path="/my-settings/email-signature/*"
                  element={
                    <Wrapper>
                      <EmailSignature />
                    </Wrapper>
                  }
                />
                <Route
                  path="/my-settings/nudges/*"
                  element={
                    <Wrapper>
                      <Nudges />
                    </Wrapper>
                  }
                />
                <Route
                  path="/my-settings/things-to-do/*"
                  element={
                    <Wrapper>
                      <ThingsToDoSetting />
                    </Wrapper>
                  }
                />
                <Route
                  path="/my-settings/notifications/*"
                  element={
                    <Wrapper>
                      <Notifications />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/labels/*"
                  element={
                    <Wrapper>
                      <LabelSettings />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/questions/*"
                  element={
                    <Wrapper>
                      <QuestionSettings />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/file-library/*"
                  element={
                    <Wrapper showUpgradeTopbar={!has_file_library}>
                      <FileLibrary />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/team-member/*"
                  element={
                    <Wrapper>
                      <TeamMember />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/reply-template/*"
                  element={
                    <Wrapper>
                      <ReplyTemplate />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/tasks/*"
                  element={
                    <Wrapper>
                      <TasksSettings />
                    </Wrapper>
                  }
                />
                <Route
                  path="/account-settings/business-info/*"
                  element={
                    <Wrapper>
                      <BusinesInfoSettings />
                    </Wrapper>
                  }
                />
                {activeTab === 1 && (
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={`${route.settings}/account-settings/team-member`}
                      />
                    }
                  />
                )}
                {activeTab === 2 && (
                  <Route
                    path="/"
                    element={
                      <Navigate
                        to={`${route.settings}/my-settings/my-profile`}
                      />
                    }
                  />
                )}
              </Route>
            </Routes>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeNewSettings;

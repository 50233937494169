import { object, string } from 'yup';

export const formValidation = (data) => {
  let schema = {};

  data.forEach((obj) => {
    const fieldName = obj.field_name;
    const fieldType = obj.field_type;
    const mandatoryField = obj.is_mandatory;
    let fieldValidation;

    if (mandatoryField && fieldType !== 'bool') {
      fieldValidation = string().required('This field is required');
    } else if (fieldType === 'bool') {
      fieldValidation = string().nullable().required('This field is required');
    } else {
      fieldValidation = string();
    }

    if (fieldType === 'number') {
      fieldValidation = fieldValidation.matches(
        /^[0-9]+$/,
        'Please enter a number'
      );
    }
    if (fieldName.toLowerCase() === 'email') {
      fieldValidation = fieldValidation.email(
        'Please enter a valid email address'
      );
    }
    schema[fieldName] = fieldValidation;
  });
  return object().shape(schema);
};
